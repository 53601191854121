import React from 'react'
import Button from 'components/button'
import isEmpty from 'isEmpty';
import { useSelector } from 'react-redux';
const table=[
    {header:'Job Title',field:'JobTitle'},
    {header:'Job Family',field:'LevelName'},
    {header:'Department',field:'Department'},
    {header:'Job Type',field:'EmployeeStatus'},
]
const table2 = [
    {header:'Job Code',field:'JobCode'},
    {header:'Job Role',field:'LevelGroupName'},
    {header:'Branch',field:'Branch'},
    {header:'Report To',field:'ReportsToJobTitle'},
]

function Current({data}) {
    const {employeeProfile} = useSelector(state=>state.employeeProfile);
    const { Department,Branch,EmployeeStatus }=employeeProfile?.EmployeeProfileHeader;
    const newData = {Department,Branch,EmployeeStatus,...data} 
    return (
        <div className="placement-current">
            <div className="placement-details">
                <Table tableData={table} data={newData} />
                <Table tableData={table2} data={newData} />
            </div>
            <div className="placement__footer">
                <Button bg="primary" title="Transfer" />
                <Button bg="primary" title="Change Position" />
            </div>
        </div>
    )
}

const Table = ({tableData,data}) =>{
    const checkEmpty = (item)=>{
        if(!data){
            return '-'
        }
        if(isEmpty(data[item])){
            return '-'
        }
        return data[item]
    }
    return(
        <div className="placement-details__block">
            <div className="placement-details__table">
                {
                    tableData.map(item=>{
                        return(
                            <div key={item.header} className="placement-details__row">
                                <div className="placement-details__col placement-title">
                                    {item.header}
                                </div>
                                <div className="placement-details__col placement-val">
                                    {
                                        checkEmpty(item.field)
                                    }
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        </div>

    )
}

export default Current
