import React, { useRef, useState, useEffect } from "react";
import FormGroup from "components/form";
import {
    getDateISOMDY,
    getDateISO,
} from "components/calendar/helpers/engCalendar";
import classNames from "classnames";
import useOutsideClick from "helpers/customHooks/outsideClickHook";
import { toastMessage } from "actions/validateAction";
import { useDispatch } from "react-redux";
import isEmpty from "helpers/isEmpty";
import moment from "moment";

const RecurringSection = (props) => {
    const { data, onInputChange, onDateChange, onEnter, onPeriodChange, onChange, isValidUser, board } =
        props;
    const [edited, setEdited] = useState(false);
    const [selectedRepeatPeriod, setSelectedRepeatPeriod] = useState('month');
    const [selectedPeriodValues, setSelectedPeriodValues] = useState({});
    const [selectedRepetitionType, setSelectedRepetitionType] = useState('count')

    useEffect(() => {

        const { RepeatPeriodValue, RepeatPeriod, RepeatNos, RepeatDueDate } = data.TmTaskDetailsDto
        setSelectedRepeatPeriod(RepeatPeriod)
        if (!isEmpty(RepeatDueDate)) {
            setSelectedRepetitionType('date')
        }
        else {
            setSelectedRepetitionType('count')
        }

        var periodValues = RepeatPeriodValue && RepeatPeriodValue.split(",") || []
        let periods = {}
        for (let i = 0; i < periodValues.length; i++) {
            periods[periodValues[i]] = periodValues[i]
        }
        setSelectedPeriodValues(periods)

    }, [data])

    const convertDateISO = (date) => {
        return date ? getDateISO(new Date(date)) : "";
    };
    const getFomattedDateISO = (date) => {
        return date
            ? getDateISO(new Date(date)).replace(new RegExp("/", "g"), "-")
            : "";
    };
    const ref = useRef<any>(null);
    const dispatch = useDispatch();
    useOutsideClick(ref, () => {
        edited &&
            onEnter &&
            onEnter(
                { key: "Enter" },
                "EstimatedHour",
                data.TmTaskDetailsDto.EstimatedHour
            );

        edited && setEdited(false);
    });
    const getRecurringPeriod = (type) => {
        if (type === 'month') return [
            { label: 'JAN', value: 1 },
            { label: 'FEB', value: 2 },
            { label: 'MAR', value: 3 },
            { label: 'APR', value: 4 },
            { label: 'MAY', value: 5 },
            { label: 'JUN', value: 6 },
            { label: 'JUL', value: 7 },
            { label: 'AUG', value: 8 },
            { label: 'SEP', value: 9 },
            { label: 'OCT', value: 10 },
            { label: 'NOV', value: 11 },
            { label: 'DEC', value: 12 }
        ]
        else if (type == 'day') return [
            { label: 'SUN', value: 1 },
            { label: 'MON', value: 2 },
            { label: 'TUE', value: 3 },
            { label: 'WED', value: 4 },
            { label: 'THU', value: 5 },
            { label: 'FRI', value: 6 },
            { label: 'SAT', value: 7 }
        ]
        else return []
    }
    const handleChangeRecurringPeriod = (type) => {
        const { RepeatNos } = data.TmTaskDetailsDto
        onInputChange("RepeatPeriod", type)
        updateRecurringPeriods(type, RepeatNos)
    }

    const handleRecurringPeriodValueClick = (item) => {
        
        let value = item.value
        let periods = { ...selectedPeriodValues };
        const { RepeatNos, RepeatPeriod, DueDate } = data.TmTaskDetailsDto
        let val = periods[item.value];
        // if (!isEmpty(DueDate)) {
        //     let date = moment(DueDate)
        //     if (RepeatPeriod === 'month') {
        //         value = date.month() + 1;
        //     }
        //     else {
        //         value = date.weekday() + 1;
        //     }
        // }
        if (isEmpty(val)) {
            periods[item.value] = item.value
        }
        else
            delete periods[item.value]
        //let periodValue = getPeriodValues(periods)

        updateRecurringPeriods(RepeatPeriod, RepeatNos, value, true)
        // if (RepeatNos) {
        //     updateRecurringPeriods(RepeatPeriod, RepeatNos, value)
        // }


        // else {
        //     onInputChange("RepeatPeriodValue", periodValue)
        //     //onEnter && onEnter({ key: "Enter" }, "RepeatPeriodValue", periodValue);
        // }


    }

    const getPeriodValues = (periods) => {
        return Object.keys(periods).toString();
    }

    const updateRecurringPeriods = (repeatPeriod, noOfRepeats, periodValue = 1, update = true) => {
        let len = 0
        let val = parseInt(noOfRepeats)
        if (val <= 0)
            return

        if (repeatPeriod === "day") {
            if (val > 7)
                return false;
            len = 7;
        }
        else if (repeatPeriod === "month") {
            if (val > 12)
                return false;
            len = 12
        }
        else if (val > 9 && repeatPeriod === "week") {
            return false;
        }
        var newPeriodValues = [];
        let inc = 0;
        var start = periodValue;
        while (inc < len) {
            if (start > len) {
                start = start - len;
                newPeriodValues.push(start);
                start = start + val;
            }
            else {
                newPeriodValues.push(start);
                start = start + val;
            }

            inc = inc + val;
        }

        onPeriodChange([
            { name: "RepeatPeriod", value: repeatPeriod },
            { name: "RepeatNos", value: noOfRepeats },
            { name: "RepeatPeriodValue", value: newPeriodValues.toString() }], update)


        return newPeriodValues.toString()
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        const { RepeatPeriod } = data.TmTaskDetailsDto
        setEdited(true);
        if (name === "RepeatNos") {
            updateRecurringPeriods(RepeatPeriod, value, 1, false)
        }
        else {
            onInputChange(name, value)
        }
    };

    const handleKeyUp = (e) => {
        const { name, value } = e.target;
        if (e.key === "Enter") {
            onEnter(e, name, value);
        }
    };

    const onBlur = (e) => {
        const { name, value } = e.target;
        edited && onEnter && onEnter({ key: "Enter" }, name, value);
        setEdited(false);
    };

    return (
        <div className="taskmodal__recurring">

            <div className="recurring-right-section">
                <span className="recurring-label">Repeat every</span>
            </div>
            <div>

                <div className="taskmodal__recurring-section">
                    <div className="recurring-text-group">
                        <input type="text"
                            name="RepeatNos"
                            value={data.TmTaskDetailsDto.RepeatNos || ""}
                            onChange={handleChange}
                            onKeyUp={(e) => handleKeyUp(e)}
                            onBlur={onBlur}
                        />
                    </div>

                    <span className={classNames("recurring-type", { active: selectedRepeatPeriod === 'month' })}
                        onClick={() => handleChangeRecurringPeriod('month')}>Month</span>
                    <span className={classNames("recurring-type", { active: selectedRepeatPeriod === 'week' })}
                        onClick={() => handleChangeRecurringPeriod('week')}>Week</span>
                    <span className={classNames("recurring-type", { active: selectedRepeatPeriod === 'day' })}
                        onClick={() => handleChangeRecurringPeriod('day')}>Day</span>
                </div>
                <div className="taskmodal__recurring-section">
                    {getRecurringPeriod(selectedRepeatPeriod).map(x => (
                        <span className={classNames("recurring-period", { active: !isEmpty(selectedPeriodValues[x.value]) })} onClick={() => handleRecurringPeriodValueClick(x)}>
                            {x.label}
                        </span>))}
                </div>
                <div className="taskmodal__recurring-section">
                    <div className="recurring-radiogroup">
                        <FormGroup
                            formName="radiogroup"
                            onChange={(event, value) => setSelectedRepetitionType(value)}

                            name="repetitionType"
                            value="count"

                            checked={selectedRepetitionType === 'count' ? true : false}
                            label="Repeat"
                            labelPosition="right"
                        />
                        <div className="recurring-text-group">
                            <input type="text"
                                disabled={selectedRepetitionType === 'date'}
                                value={data.TmTaskDetailsDto.RepeatTime || ""}
                                name="RepeatTime"
                                onChange={handleChange}
                                onKeyUp={(e) => handleKeyUp(e)}
                                onBlur={onBlur}
                            />
                        </div>
                        <span>times</span>
                    </div>
                    <div className="recurring-radiogroup">
                        <FormGroup
                            formName="radiogroup"
                            onChange={(event, value) => setSelectedRepetitionType(value)}
                            value="date"
                            name="radiogroup"
                            checked={selectedRepetitionType === 'date' ? true : false}
                            label="Repeat till"
                            labelPosition="right"
                        />
                        {/* <FormGroup /> */}
                        <FormGroup
                            disabled={selectedRepetitionType === 'count'}
                            formName="customdateinput"
                            name="RepeatDueDate"
                            placeholder="YYYY-MM-DD"
                            onChange={onDateChange}
                            value={convertDateISO(data.TmTaskDetailsDto.RepeatDueDate)}
                            enableOkButton
                            hideAction
                        />
                    </div>
                </div>

                <div className="taskmodal__recurring-section">
                    <span className="recurring-label-1">Show the task</span>
                    <div className="recurring-text-group">
                        <input
                            type="text"
                            name="ShowBeforeRepeatDueDate"
                            onChange={handleChange}
                            value={data.TmTaskDetailsDto.ShowBeforeRepeatDueDate || ""}
                            onKeyUp={(e) => handleKeyUp(e)}
                            onBlur={onBlur}

                        /></div>
                    <span className="recurring-label-1">days before due date</span>
                </div>
            </div>
        </div >
    );
};
export default RecurringSection;
