import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";
import isEmpty from "isEmpty";
import { CSSTransition } from "react-transition-group";
import useOutsideClick from "helpers/customHooks/outsideClickHook";
import Icons from "components/icons";

const MenuPopup = (props: any) => {
  const { open, popupTitle, children, className, onChangeOpen } = props;
  const ref = useRef<any>(null);
  useOutsideClick(ref, () => {
    onChangeOpen(false);
  });
  return (
    <CSSTransition unmountOnExit in={open} timeout={400} classNames="taskpop">
      <div
        ref={ref}
        className={classnames({
          "task-popup": true,
          [className]: !isEmpty(className),
        })}
      >
        <div className="task-popup__container">
          {popupTitle && (
            <div className="task-popup__title">
              {popupTitle}
              <div
                className="btn-close"
                onClick={() => {
                  onChangeOpen(false);
                }}
              >
                <Icons name="Close" />
              </div>
            </div>
          )}
          {children}
        </div>
      </div>
    </CSSTransition>
  );
};
export default MenuPopup;
