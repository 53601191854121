export const convertTime12to24 = (time12h: any) => {
  const [time, modifier] = time12h.split(" ");
  let [hours, minutes] = time.split(":");
  if (hours === "12") {
    hours = "00";
  }
  if (modifier.toLowerCase() === "pm") {
    hours = parseInt(hours, 10) + 12;
  }
  if (hours.length <= 1) {
    hours = "0" + hours;
  }
  if (minutes.length <= 1) {
    minutes = "0" + minutes;
  }
  return `${hours}:${minutes}`;
};

export const getTimeDifference = (end: any, start: any)  =>{
  if (!start) start = '12:00 am'; 
  if (!end) end = '12:00 am'; 
  let startTiming = convertTime12to24(start);
  let endTiming = convertTime12to24(end);
  let [sHH, sMM] = startTiming.split(':');
  let [eHH, eMM] = endTiming.split(':');
  let startTime = new Date(new Date().setHours(parseInt(sHH))).setMinutes(parseInt(sMM));
  let endTime = new Date(new Date().setHours(parseInt(eHH))).setMinutes(parseInt(eMM));
  let difference = new Date(endTime).getTime() - new Date(startTime).getTime();
  if (difference >= 0) return difference;
  else return 'Invalid Date Picked';

}
