import React, { Component } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { getJobApplicationForm } from "../helpers/actions";
import PropTypes from "prop-types";
import isEmpty from "isEmpty";
import { Consumer } from "../helpers/context";

export class Applications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applications: [],
      personalInformation: "",
      address: "",
      familyInformation: "optional",
      identificationInformation: "optional",
      educationTraining: "optional",
      workExperience: "optional",
      summary: "optional",
      references: "optional",
      resume: "optional",
    };
    this._renderFormList = this._renderFormList.bind(this);
    this.onOptionClick = this.onOptionClick.bind(this);
  }
  // componentDidMount() {
  //     this.props.getJobApplicationForm()
  // }
  // componentDidUpdate = (prevProps, prevState) => {
  //     const {jobApplicationForm} = this.props;
  //     console.log(jobApplicationForm)
  //     if(!isEmpty(jobApplicationForm.value) && jobApplicationForm.value !== prevProps.jobApplicationForm.value){
  //         this.setState({applications:jobApplicationForm.value})
  //     }
  // };

  // onOptionClick(name,value){
  //     this.setState({[name]:value})
  // }
  // onOptionClick(id,value){
  //     this.setState(prevState=>({
  //         ...prevState,
  //         applications:prevState.applications.map(item=>{
  //             if(item.ComponentId===id){
  //                 let a = item;
  //                 if(value ==='IsMandatory'){
  //                     a.IsMandatory=true
  //                     a.IsOptional=false
  //                     a.IsOff=false
  //                 }
  //                 if(value === 'IsOptional'){
  //                     a.IsMandatory=false
  //                     a.IsOptional=true
  //                     a.IsOff=false
  //                 }
  //                 if(value ==='IsOff'){
  //                     a.IsMandatory=false
  //                     a.IsOptional=false
  //                     a.IsOff=true
  //                 }
  //                 return a;
  //             }
  //             return item
  //         })
  //     }))
  // }
  onOptionClick(id, value) {
    this.props.dispatch({ type: "onAppFormChange", payload: { id, value } });
  }
  _renderFormList(props, index) {
    const {
      jobCreate: { RecruitmentJobApplicationFormDto },
    } = this.props;
    return (
      <li key={props.id} className="application-form__item">
        <div className="application-form__title">
          <h3>{props.SectionName}</h3>
        </div>
        <div className="application-form__options">
          {
            // props.isMendatory?
            // (<span className="application-form__option active">Mandatory</span>)
            // :
            props.ComponentId === 1 || props.ComponentId === 2 ? (
              <span className="application-form__option active">Mandatory</span>
            ) : (
              <>
                <span
                  className={classnames({
                    "application-form__option": true,
                    active: props.IsMandatory,
                  })}
                  onClick={() => this.onOptionClick(props.ComponentId, 1)}
                >
                  MANDATORY
                </span>
                <span
                  className={classnames({
                    "application-form__option": true,
                    active: props.IsOptional,
                  })}
                  onClick={() => this.onOptionClick(props.ComponentId, 2)}
                >
                  OPTIONAL
                </span>
                <span
                  className={classnames({
                    "application-form__option": true,
                    active: props.IsOff,
                  })}
                  onClick={() => this.onOptionClick(props.ComponentId, 3)}
                >
                  OFF
                </span>
              </>
            )
          }
        </div>
      </li>
    );
  }

  render() {
    const { ApplicationForm } = this.props;
    return (
      <div className="application-container">
        <h3 className="tertiary-title">Application Form</h3>
        <div className="application-form">
          <ul className="application-form__list">
            {!isEmpty(ApplicationForm) &&
              ApplicationForm.map(this._renderFormList)}
          </ul>
        </div>
      </div>
    );
  }
}

Applications.propTypes = {
  createJobReducer: PropTypes.object.isRequired,
  getJobApplicationForm: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => ({
  jobApplicationForm: state.createJobReducer.jobApplicationForm,
});

const App = () => (
  <Consumer>
    {(value) => {
      const { jobCreate, jobApplicationForm, dispatch } = value;
      // const {jobCreate:{RecruitmentPositionDetailDto},dispatch}= value;
      return (
        <Applications
          ApplicationForm={jobApplicationForm}
          dispatch={dispatch}
          jobCreate={jobCreate}
        />
      );
    }}
  </Consumer>
);

export default connect(mapStateToProps, { getJobApplicationForm })(App);
