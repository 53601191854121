import isEmpty from 'isEmpty';
import moment from 'moment';

export const getDisciplinaryCaseData = (data) => {
  let fd = new FormData();

  data.FileList.map((x, i) => {
    fd.append(`DisciplinaryCaseDocumentsDto,${i + 1}`, x);
  });
  data.FileList.map((x, i) => {
    fd.append(`Files`, x);
  });

  let ReportedBy = data.ReportedBy.map((x) => x.value).join(",");
  let Notify = data.Notify.map((x) => x.value).join(",");
  let AttachmentDto = [];
  
  data.FileList.map((x) => {
    if (!isEmpty(x.AttachmentId)) {
      AttachmentDto.push({ AttachmentId: x.AttachmentId });
    }
  });
  //fd.append("Attachment", JSON.stringify(attachmentDto));

  // let dto = {
  //   DisciplinaryCaseId: data.DisciplinaryCaseId,
  //   EmployeeRef_Id: data.Employee.value,
  //   CaseTitle: data.CaseTitle,
  //   CaseStatement: data.CaseStatement,
  //   ReportedDate: data.CaseDate,
  //   ReportedDateEng: data.CaseDate,
  //   ReportedBy: reportedBy,
  //   NotifyTo: notify,
  //   Attachment:attachmentDto
  // };
  // fd.append("DisciplinaryCaseDto", JSON.stringify(dto));

  if(data.DisciplinaryCaseId !== 0 && data.DisciplinaryCaseId > 0)  fd.append("DisciplinaryCaseId", data.DisciplinaryCaseId);
  fd.append("EmployeeRefId", data.Employee.value);
  fd.append("CaseTitle", data.CaseTitle);
  fd.append("CaseStatement", data.CaseStatement);
  fd.append("ReportedDate", data.CaseDate);
  fd.append("ReportedDateEng", moment(data.CaseDate).format('LL'));
  fd.append("ReportedBy", ReportedBy);
  fd.append("NotifyTo", Notify);
  fd.append("Attachment", []);

  return fd;
};
