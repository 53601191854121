import React, { useState, useEffect } from 'react'
import Button from 'components/button'
import Icons from 'components/icons'
import Modal from 'components/modal'
import FormGroup from 'components/form'
import DocumentViewer from 'components/common/commonDocumentViewer'
import isEmpty from 'isEmpty'
import {addEmployeeAdditionalDocuments,
    editEmployeeAdditionalDocuments,
    deleteEmployeeAdditionalDocuments,
    getEmployeeAdditionalDocuments,
} from '../action';
import { useDispatch } from 'react-redux';
// import DeleteTimer from 'components/common/deleteTimer'
import Confirm from 'components/common/commonConfimation'
import { TiUser } from 'react-icons/ti'

function Additional(props) {
    const { AdditionalDocs,EmployeeId } = props;
    const [open, setOpen] = useState(false);
    const onModalClose = () => setOpen(false);
    return (
        <div className="employee-docs__additional">
            <div className="tertiary-title">
                <h3>Additional Documents</h3>
            </div>
            <div className="employee-block">
                <ul className="employee-docs__additional-list">
                    {
                        !isEmpty(AdditionalDocs) ? AdditionalDocs.map(item=><Docs key={item.Id} doc={item} EmployeeId={EmployeeId} />)
                            :
                            <span className="nodata">No Additional Documents Found</span>
                    }

                </ul>
                <Button
                    bg="primary-light"
                    title="Add"
                    buttonType="icon-button"
                    leftIcon={<Icons name="Add" />}
                    onClick={() => setOpen(true)}
                />
                <Modal
                    open={open}
                    onModalClose={onModalClose}
                    title="Additional Document"
                >
                    <AddDocument onModalClose={onModalClose} EmployeeId={EmployeeId} />
                </Modal>
            </div>
        </div>
    )
}

const Docs = (props) => {
    const {doc, EmployeeId} = props;
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const onModalClose = () => setOpen(false);
    const onDelete = () =>dispatch(deleteEmployeeAdditionalDocuments(doc.Id));
    return (
        <li className="employee-docs__additional-item">
            <div className="employee-docs__additional-name">
                <div className="employee-docs__additional-title">
                    {doc.Name}
                </div>
                <div className="employee-docs__additional-desc">
                    {doc.Description}
                </div>
            </div>
            <div className="employee-docs__additional-file">
                <DocumentViewer document={doc.DocumentFile} >
                    <a>
                        <Icons name="File" justSVG />
                    </a>
                </DocumentViewer>
            </div>
            <div className="employee-docs__additional-actions">
                <a onClick={()=>setOpen(true)}>
                    <Icons name="Edit" justSVG />
                </a>
                <Confirm
                    action={()=>onDelete()}    
                >
                    <a>
                        <Icons name="Delete" justSVG />
                    </a>
                </Confirm>

            </div>
            <Modal
                    open={open}
                    onModalClose={onModalClose}
                    title="Update Addittional Documents"
                >
                    <AddDocument onModalClose={onModalClose} doc={doc} EmployeeId={EmployeeId} />
            </Modal>
        </li>
    )
}


const InitialState = {
    Name:'',
    Description:'',
    File: '',
    EmployeeId:0,
    Id:0,
    noedit: true
}

const AddDocument = (props) => {
    let { onModalClose,EmployeeId,doc } = props;
    let [docs,setDocs] = useState<any>({});
    let [errors,setErrors] = useState<any>({});
    let [isEdit,setIsEdit] = useState(false);
    const dispatch = useDispatch();
    useEffect(()=>{
        if(!isEmpty(EmployeeId)){
            setDocs({...docs,EmployeeId});
        }
    },[EmployeeId]);
    useEffect(()=>{
        if(!isEmpty(doc)){
            
            let docNew = {
                ...doc,
                File: {
                    name: doc.OriginalFile,
                    noedit: true,
                }
            }
            // doc.File = {
            // };
            setDocs({...docs, ...docNew });
            setIsEdit(true)
        }else{
            setIsEdit(false)
        }
    },[doc])
    const onSubmit = async () =>{
        const {errs,isValid} = onValidate();
        setErrors(errs);
        if(!isValid){
            return false;
        }
        const data = new FormData();
        Object.keys(docs).map(item=> {
            if (item !== 'File')
                data.append(item,docs[item])
        });

        if (docs.noedit == true) {
            
            data.append('File', null);
        }
        else {
            
            data.append('File', docs['File']);
        }


        
        if(isEdit){
            await dispatch(editEmployeeAdditionalDocuments(data));
            // return false;
        }else {
            await dispatch(addEmployeeAdditionalDocuments(data));
        }
        onModalClose()
        dispatch(getEmployeeAdditionalDocuments(EmployeeId));
    }
    const onValidate = () =>{
        const errs:any = {};
        if(isEmpty(docs.Name)){
            errs.Name = 'Document Name is required'
        }
        if(isEmpty(docs.Description)){
            errs.Description = 'Document Description is required'
        }
        if(docs.File == null && !docs.File?.name){
            
            errs.File = 'Document File is required'
        }
        
        return {errs,isValid:isEmpty(errs)}
    }
    const onChange = (name,value) =>{
        if (name == 'File') {
            setDocs({...docs,[name]:value, noedit: false, });
            setErrors({})
            
        }
        else {
            
            setDocs({...docs,[name]:value});
        }
    }
    return (
        <div className="additional-documents">
                <div className="additional-documents__body">

                    <div className="additional-documents__form">
                        <FormGroup
                            label="Document Name"
                            alignment="horizontal"
                            value={docs.Name}
                            name="Name"
                            validators={['required']}
                            onChange={onChange}
                            error={errors.Name}
                        />
                    </div>
                    <div className="additional-documents__form">
                        <FormGroup
                            label="Document Description"
                            alignment="horizontal"
                            value={docs.Description}
                            name="Description"
                            onChange={onChange}
                            error={errors.Description}
                        />
                    </div>
                    <div className="additional-documents__form additional-documents__form-documents">
                        <FormGroup
                            formName="singledocumentinput"
                            btnText="Add Documents"
                            value={docs.File}
                            name="File"
                            error={errors.File}
                            onChange={onChange}
                        />
                    </div>
                </div>
                <div className="additional-documents__footer">
                    <Button
                        title={isEdit ? "Update" :"Add"}
                        bg="primary"
                        onClick={()=>onSubmit()}
                    />
                    <Button
                        title="Cancel"
                        bg="secondary-dark"
                        onClick={() => onModalClose()}
                    />
                </div>
        </div>
    )
}

export default Additional
