import React, {useState, useEffect} from "react";
import Icons from "components/icons";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {getSbiLoanSettingList} from "../helpers/action";
import Table from "components/customTable";

const actionCell = (rowProps) => {
  return (
    <div className="flex">
      <Link
        to={`/loan/sbi_loan/staffPersonalLoan/${rowProps.value}`}
        style={{cursor: "pointer"}}
      >
        <Icons name="RoundEdit" />
      </Link>
    </div>
  );
};

const beforecolumns = [
  {accessor: "LoanTypeName", Header: "Loan Type", width: 180},
  {
    accessor: row => row.InterestRate ? `${row.InterestRate} %` : '', // Access Field
    // accessor: "InterestRate",
    Header: "Interest Rate", width: 145
  },
  {
    accessor: row => row.MinServicePeriod ? `${row.MinServicePeriod} ${row.MinServicePeriod == 1 ? 'year' : 'years'}` : '', // Access Field
    // accessor: "MinServicePeriod",
    Header: "Minimum work Period",
    width: 180
  },
  {
    accessor: row => row.MaxAllowablePaymentTerms ? `${row.MaxAllowablePaymentTerms} ${row.MaxAllowablePaymentTerms == 1 ? 'month' : 'months'}` : '', // Access Field
    // accessor: "MaxAllowablePaymentTerms",
    Header: "Maximum Tenure",
    width: 180
  },
  {accessor: "EditedBy", Header: "Last Edited by", width: 180},
  {
    accessor: "LoanTypeId",
    Header: "Action",
    Cell: actionCell,
    width: 145,
  },
];

const LoanSettingtable = () => {
  const dispatch = useDispatch();
  const [rowDatas, setRowDatas] = useState([]);

  const {sbiLoanSettingList} = useSelector((state: any) => state.sbiLoanSettingReducer);

  useEffect(() => {
    (async () => {
      const apis = [dispatch(getSbiLoanSettingList())];
      await Promise.all(apis);
    })();
  }, []);

  useEffect(() => {
    if (sbiLoanSettingList) {
      let test = sbiLoanSettingList.map((values) => {
        return {...values, EditedBy: values.Title + " " + values.LastEditedBy};
      });
      setRowDatas(test);
    }
  }, [sbiLoanSettingList]);

  return (
    <div>
      <Table isFixedWidth columns={beforecolumns} data={rowDatas} />
    </div>
  );
};

export default LoanSettingtable;
