import React, { Component } from "react";
import { connect } from "react-redux";
import { RoleId } from "constants/userDetails";
import { ADMIN_ROLE_ID } from "constants/constants";
import isEmpty from "isEmpty";
import CommonFilters from "../../../employee/commonFilters";
import { getTravelHistory, clearEditTravelRequest } from "../actions";
import CommonHeaderSection from "../../../admin/commonHeaderSection";
//import Table from "./Travel_Request_History_Table";
import TravelHistoryBlock from "./travelHistoryBlock";
import Loader from "components/loaders";
import moment from "moment";

class Travel_Request_History_Screen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settlementId: null,
      rowsPerPage: 10,
      pageIndex: 0,
      isModalShow: false,
      statusId: 0,
      searchEmployeeText: -1,
      branchId: -1,
      searchFlowSteps: "",
      fromDate: null,
      toDate: null,
      flag: "",
    };
    this.handleRowChange = this.handleRowChange.bind(this);
    this.handleIndexChange = this.handleIndexChange.bind(this);
  }
  async componentDidMount() {
    //if (!this.props.initialLoad) {
    var date = new Date();
    let startDate = date.setDate(date.getDate() - 7);
    let endDate = new Date();
    // let range = {
    //   startDate: date,
    //   endDate: new Date(),
    // };
    // let startDate = moment(range.startDate, "YYYYMMDDHHmmss")
    //   .startOf("day")
    //   .format("YYYY/MM/DD HH:mm:ss");
    // let endDate = moment(range.endDate, "YYYYMMDDHHmmss")
    //   .endOf("day")
    //   .format("YYYY/MM/DD HH:mm:ss");
    this.setState({ fromDate: startDate, toDate: endDate }, () =>
      this.initialize()
    );
    // //this.props.getTravelCategoryList();
    // let { getTravelCategoryList } = this.props;
    // let ajaxApi = [getTravelCategoryList()];
    // Promise.all(
    //   ajaxApi.map(async item => {
    //     return await item;
    //   })
    // );
    // }
  }
  componentDidUpdate = (prevProps, prevState) => {
    const { rowsPerPage, pageIndex } = this.state;
    if (
      rowsPerPage !== prevState.rowsPerPage ||
      pageIndex !== prevState.pageIndex
    ) {
      this.initialize();
    }
  };
  handleRowChange(num) {
    this.setState({ rowsPerPage: num });
  }
  handleIndexChange(num) {
    this.setState({ pageIndex: num });
  }

  initialize = () => {
    const {
      pageIndex,
      rowsPerPage,
      searchEmployeeText,
      branchId,
      searchFlowSteps,
      fromDate,
      toDate,
      flag,
    } = this.state;
    const data = {
      PageIndex: pageIndex,
      PageSize: rowsPerPage,
      SearchEmployeeText: searchEmployeeText,
      BranchId: branchId,
      StepName: searchFlowSteps,
      FromDate: fromDate,
      ToDate: toDate,
      Flag: flag,
    };
    this.props.getTravelHistory(data);
  };

  handleSearchTextChange = (text) => {
    this.setState({ searchEmployeeText: text }, () => {
      this.initialize();
    });
  };

  handleBranchChange = (branchId) => {
    this.setState({ branchId: branchId }, () => {
      this.initialize();
    });
  };

  handleFilterChange = (name, selected) => {
    this.setState({ [name]: selected }, () => {
      this.initialize();
    });
  };

  handleDateRangeChange = (range) => {
    this.setState({ fromDate: range.fromDate, toDate: range.toDate }, () => {
      this.initialize();
    });
  };
  renderBody() {
    const { travelHistory } = this.props;
    return (
      <div className="travel-history">
        {travelHistory.loading ? (
          <Loader
            type="skeleton"
            name="card"
            cardType="travel"
            noRows={10}
            loading={travelHistory.loading}
          />
        ) : (
          <TravelHistoryBlock
            rows={travelHistory.value}
            clearEditTravelRequest={this.props.clearEditTravelRequest}
            history={this.props.history}
            pageIndex={this.state.pageIndex}
            rowsPerPage={this.state.rowsPerPage}
            handleRowChange={this.handleRowChange}
            handleIndexChange={this.handleIndexChange}
          />
        )}
        {/* <Table
          pageIndex={this.state.pageIndex}
          rowsPerPage={this.state.rowsPerPage}
          handleRowChange={this.handleRowChange}
          handleIndexChange={this.handleIndexChange}
        /> */}
      </div>
    );
  }
  render() {
    const selectedTabId = !isEmpty(this.props.location.state)
      ? this.props.location.state.selectedTabId
      : null;
    const roleId = RoleId();
    return (
      <>
        {roleId == ADMIN_ROLE_ID ? (
          <CommonHeaderSection
            renderBody={this.renderBody()}
            selectedTabId={selectedTabId}
            pathName="Travel History"
            showFilterSection={true}
            handleSearchTextChange={(text) => this.handleSearchTextChange(text)}
            handleBranchChange={(branchId) => this.handleBranchChange(branchId)}
            handleFilterChange={this.handleFilterChange}
            handleDateRangeChange={this.handleDateRangeChange}
          />
        ) : (
          <div className="travel__filter-body">
            {/* <CommonFilters
              handleSearchTextChange={(text) =>
                this.handleSearchTextChange(text)
              }
              handleBranchChange={(branchId) =>
                this.handleBranchChange(branchId)
              }
              handleFilterChange={this.handleFilterChange}
              handleDateRangeChange={this.handleDateRangeChange}
              selectedTabId={selectedTabId}
            /> */}
            <div className="travel-requestMainBody">{this.renderBody()}</div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  initialLoad: state.travelInformationReducer.travelHistoryInitialLoad,
  travelHistory: state.travelInformationReducer.travelHistory,
});

export default connect(mapStateToProps, {
  getTravelHistory,
  clearEditTravelRequest,
})(Travel_Request_History_Screen);
