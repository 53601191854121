import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  FaHandPaper,
  FaRegAddressCard,
  FaRegCheckCircle,
  FaRegHandPointLeft,
} from "react-icons/fa";
import { IoIosGrid } from "react-icons/io";
import { MdMailOutline } from "react-icons/md";
import queryString from "query-string";

import isEmpty from "isEmpty";
import { ArraysKey, ConvertToDataSource } from "helpers/getTextLabel";
import FormGroup from "components/form";
import Loader from "components/loaders";
import Modal from "components/modal";
import Breadcrumb from "scenes/common/recruitBreadcrumb";
// import Breadcrumb from "scenes/common/breadcrumb";
import { JobStages } from "../../enums";
import JobDetails from "../jobDetails";
import CandidateList from "./old_candidateList";
import CandidateDetail from "./profileView/candidateDetail";
import TableView from "./tableView";
import ProfileView from "./profileView";
import TableViewActivities from "./tableView/activities";

import {
  addRemoveTags,
  ChangeCandidateStage,
  GetAppliedCandidateListByJobId,
  GetCandidateDetail,
  GetCandidateDetailsByJobId,
  GetCandidateListByJobAndStageId,
  GetDashboardDetail,
  getHiringStagesByJobId,
  GetHiringStagAndSubstageByJobId,
  GetJobStages,
  GetMilestoneDetail,
  // makeCandidateFavorite,
  updateMultipleCandidateDetails,
  // moveCandidateToNextStage,
  GetRecruitmentDashboardDetail,
  GetSelectedCandidateWithDetail,
  UndoCandidateReject,
  SetAsDelete,
} from "store/actions/recruitment/dashboard";
import { SaveCandidateReject } from "store/actions/recruitment/reject";

import RejectCandidate from "../rejectCandidate";
import { useQuery } from "react-query";
import ComposeEmail from "scenes/common/email/compose";
import confirmation from "./tableView/table/cells/Confirmation";

function JobInformation(props) {
  //  let params = queryString.parse(props.location.search) || {};

  const [state, setState] = useState({
    selectedJobId: 0,
    seletedJobStage: -3, //Applied
    selectedCandidate: null,
    viewId: 1, //table view
    modalOpen: false,
    modalContent: "",
    modalTitle: "Error",
    isJobStageChanged: false,
    filteredCandidateListByStage: null,
    pageIndex: 0,
    rowsPerPage: 25,
    showRejectCandidateModal: false,
    rejectDataModel: {},
    searchByName: '',
    showDeleteConfirmModel: false,
    deleteParam: {},
    history: []
  });

  const [showComposeModal, setComposeModal] = useState(false);
  const [composeModalData, setComposeModalData] = useState([]);
  const [jobDetailsLoading, setJobDetailsLoading] = useState(true);
  const [candidates, setCandidates] = useState([]);
  const [rejectModal, setRejectModal] = useState(false);
  const [disqualifyModal, setDisqualifyModal] = useState(false);
  const [candiateId, setCandiateId] = useState(0);
  const [jobId, setJobId] = useState(0);
  const [candidateList, setCandidateList] = useState([]);

  useEffect(() => {
    setJobDetailsLoading(true);
    loadSelectedCandidatesFromQuerryString();

    let params = queryString.parse(props.location.search);
    props.GetRecruitmentDashboardDetail(params.jobId);
  }, []);

  useEffect(() => {
    setCandidateList(props.candidateTableViewDetails || [])
  }, [props.candidateTableViewDetails])
  useEffect(() => {
    if (state.selectedJobId > 0) loadData();
  }, [state.selectedJobId]);

  useEffect(() => {
    initializeCandidateDetails();
  }, [
    state.rowsPerPage,
    state.selectedJobId,
    state.pageIndex,
    state.searchByName,
    props.currentCandidateDetail,
    props.updateCandiateRejection,
    props.saveCandidateRejectLoading,
  ]);

  const loadSelectedCandidatesFromQuerryString = async () => {
    let params = queryString.parse(props.location.search);
    if (isEmpty(params)) return;
    if (!params.jobId) return;
    const { rowsPerPage } = state;

    let newState = { ...state };
    newState.selectedJobId = params.jobId;
    newState.seletedJobStage = params.jobStage || JobStages.Applied;

    const data = {
      jobId: params.jobId,
      pageSize: rowsPerPage || 20,
      stageId: -1,
      candidateId: params.candidateId,
    };

    if (params.candidateId) {
      var res = await props.GetSelectedCandidateWithDetail(data);
      newState.pageIndex = res.PageIndex;
      newState.selectedCandidate = res.SelectedCandidate;
      newState.viewId = 2;
      // await props.GetRecruitmentDashboardDetail(params.jobId);
      await handleCandidateClick(res.SelectedCandidate);
    }
    setState(newState);
  };
  const onStageClick = async (jobId, jobStageId) => {
    var newState = {
      ...state,
      pageIndex: 0,
      selectedJobId: jobId,
      seletedJobStage: jobStageId,
      isJobStageChanged: false,
      selectedCandidate: null,
      candidateDetail: null,
      isLoading: true,
    };

    // const { viewId } = state;
    // await loadAppliedAndShortlistedCandidates(viewId, jobStageId);
    await initializeCandidateDetails(newState);
  };

  const loadAppliedAndShortlistedCandidates = async (viewId, jobStageId) => {
    const { selectedJobId } = state;
    if (jobStageId == JobStages.Applied) {
      await props.GetAppliedCandidateListByJobId(selectedJobId);
    } else {
      await props.GetCandidateListByJobAndStageId(selectedJobId, jobStageId);
    }
  };

  const initializeCandidateDetails = async (newState = null, isExport = false) => {
    let { seletedJobStage, pageIndex, rowsPerPage, selectedJobId, searchByName } = newState || state;

    if (newState) {
      setState(newState);
    }
    if (!seletedJobStage) return;
    if (!selectedJobId) return;

    if (props.currentCandidateDetail) {
      let jobId = props.currentCandidateDetail.Jobid;
      const data = {
        jobId,
        pageIndex: isExport ? 0 : pageIndex,//pageIndex,
        pageSize: isExport ? 999999999 : (rowsPerPage || 20),/// rowsPerPage || 20,
        stageId: seletedJobStage === JobStages.Applied ? -1 : seletedJobStage,
        candidateName: searchByName
      };
      await props.GetCandidateDetailsByJobId(data, isExport);
      setJobDetailsLoading(false);
    }
    // else {
    //   //load detail
    //   props.GetRecruitmentDashboardDetail(selectedJobId);
    // }
  };

  const handleTableViewSearch = async (param) => {
    let { seletedJobStage, pageIndex, rowsPerPage } = state;
    let jobId = props.currentCandidateDetail.Jobid;
    const data = {
      jobId,
      pageIndex: pageIndex,
      pageSize: rowsPerPage,
      stageId: seletedJobStage === JobStages.Applied ? -1 : seletedJobStage,
      candidateName: param.candidateName,
    };
    await props.GetCandidateDetailsByJobId(data);
  };

  const loadData = async () => {
    let jobId = state.selectedJobId;
    if (isEmpty(jobId)) return;
    props.getHiringStagesByJobId(jobId);
    props.GetHiringStagAndSubstageByJobId(jobId);
    props.GetAppliedCandidateListByJobId(jobId);
    props.GetJobStages();
  };
  const handleExcelExport = async () => {
    await initializeCandidateDetails(null, true);
  }

  const handleRowChange = (num) => {
    setState({ ...state, rowsPerPage: num });
  };

  const handleIndexChange = (num) => {
    setState({ ...state, pageIndex: num });
  };

  const handleRejectClick = (jobId, candidates) => {
    var rejectDataModel = {
      jobId: jobId,
      candidates: candidates,
    };
    setState({
      ...state,
      showRejectCandidateModal: true,
      rejectDataModel: rejectDataModel,
    });
  };
  const handleCandidateClick = async (data) => {
    const candidateId = data.CandidateId;
    const jobId = data.JobId;
    await props.GetCandidateDetail(candidateId);
    await props.GetMilestoneDetail({ jobId, candidateId });
    setState({
      ...state,
      selectedCandidate: data,
      viewId: 2,
      isLoading: false,
    });
  };

  const onChangeJobStage = async (name, value, candidatePara = null, jobPara = null) => {
    setState({ ...state, isLoading: true });
    let {
      filteredCandidateListByStage,
      isJobStageChanged,
      selectedCandidate,
      seletedJobStage,
    } = state;
    const { candidateListByStage } = props;

    const stageId = value.StageId;
    const subStageId = value.HiringSubStageId;

    let candidateId = !isEmpty(candidatePara)
      ? candidatePara
      : selectedCandidate.CandidateId;
    let jobId = !isEmpty(jobPara) ? jobPara : selectedCandidate.JobId;

    await props.ChangeCandidateStage({
      stageId,
      candidateId,
      subStageId,
      jobId,
    });

    let isAppliedStage = false;
    if (seletedJobStage == JobStages.Applied) {
      await props.GetAppliedCandidateListByJobId(jobId);
      isAppliedStage = true;
    }

    if (isJobStageChanged && filteredCandidateListByStage && selectedCandidate) {
      filteredCandidateListByStage = filteredCandidateListByStage.filter(
        (f) => f.CandidateId != selectedCandidate.CandidateId
      );
    } else if (!isJobStageChanged && candidateListByStage && selectedCandidate) {
      filteredCandidateListByStage = candidateListByStage.filter(
        (f) => f.CandidateId != selectedCandidate.CandidateId
      );
    }
    //debugger
    //deselct the candidates
    //setCandidates([]);
    //return
    setState({
      ...state,
      selectedCandidate: { ...selectedCandidate, CandidateStatusId: stageId },
      isJobStageChanged: isAppliedStage ? false : true,
      filteredCandidateListByStage: filteredCandidateListByStage,
      isLoading: false,
    });
  };

  const handleEmailClicked = (props) => {
    setComposeModalData(props.candidates);
    setComposeModal(true);
  };

  const handleViewChange = async (viewId) => {
    const { seletedJobStage } = state;
    // await loadAppliedAndShortlistedCandidates(viewId, seletedJobStage);
    setState({ ...state, viewId: viewId });
  };

  const onMultipleCandidateSelect = (candidates) => {
    setCandidates(candidates);
  };

  const onModalClose = () => {
    setState({ ...state, modalOpen: false, modalTitle: "", modalContent: "" });
  };

  const handleChangeSearch = (e) => {
    const { name, value } = e.target;
    let newState = { ...state }
    newState[name] = value
    setState(newState);
  }
  const handleCandidateDelete = () => {
    const { deleteParam, history } = state
    props.SetAsDelete(deleteParam, history)
  }
  const render = () => {
    const {
      currentCandidateDetail,
      appliedCandidateList,
      candidateDetail,
      candidateListByStage,
      jobStageList,
    } = props;

    const {
      selectedJobId,
      selectedCandidate,
      seletedJobStage,
      isLoading,
      viewId,
      isJobStageChanged,
      filteredCandidateListByStage,
      showRejectCandidateModal,
      rejectDataModel,
      showDeleteConfirmModel
    } = state;

    if (isEmpty(currentCandidateDetail) || jobDetailsLoading) {
      return (
        <Loader
          type="normal"
          className="loader-icon abs-position-center"
          loading={true}
          name="card"
          cardType="dashboard"
        />
      );
    }

    let newCandidateListByStage = !isJobStageChanged
      ? candidateListByStage
      : filteredCandidateListByStage;

    return (
      <div
        key={currentCandidateDetail.Jobid}
        className="common-container recruitment-container"
      >
        {/* <div className="breadCrumb"> */}
        <Breadcrumb name="Job Information" />

        {/* </div> */}
        <Modal
          open={state.modalOpen}
          title={state.modalTitle}
          type="dangerContent"
          content={state.modalContent}
          onModalClose={onModalClose}
        />

        <JobDetails
          data={currentCandidateDetail}
          seletedJobStage={seletedJobStage}
          onStageClick={onStageClick}
        />

        <div className="details-collapseable">
          <div className=" jobsfilterbar">
            <div className="jobsfilter jobsfilter-searchgrid">
              <input
                className="genericForm-group__input"
                name="searchByName"
                placeholder="Filter by name or #"
                type="search"
                value={state.searchByName}
                onChange={handleChangeSearch}
              ></input>
            </div>
            <div className="jobsfilter jobsfilter-recent">
              {/* gitlab similar searchbar dom */}
            </div>
            <div className="jobsfilter jobsfilter-tabs">
              <div
                className={viewId == 1 ? "display-views active" : "display-views"}
                onClick={() => handleViewChange(1)}
              >
                <div className="display-views-icon">
                  <IoIosGrid />
                </div>
                TABLE VIEW
              </div>
              <div
                className={viewId == 2 ? "display-views active" : "display-views"}
                onClick={() => handleViewChange(2)}
              >
                <div className="display-views-icon ">
                  <FaRegAddressCard />
                </div>
                PROFILE VIEW
              </div>
            </div>
          </div>
          {/* <div className="jobsaction-buttons">
            {viewId === 1 ? (
              <TableViewActivities
                currentCandidateDetail={currentCandidateDetail}
                seletedJobStage={seletedJobStage}
                history={props.history}
                candidates={candidates}
                hiringStageAndSubStagesJobId={props.hiringStageAndSubStagesJobId}
                updateCandidates={() => (candidates = [])}
                setState={(values) => {
                  const { modalOpen, modalTitle, modalContent } = values;
                  let newState = {
                    ...state,
                    modalOpen,
                    modalTitle,
                    modalContent,
                  };
                  setState(newState);
                }}
                updateMultipleCandidateDetails={(data, history) => {
                  props.updateMultipleCandidateDetails(data, history);
                }}
                handleRejectClick={handleRejectClick}
                handleEmailClicked={handleEmailClicked}
              />
            ) : null}
          </div> */}
          <div>
            {viewId == 1 && props.candidateTableViewDetails ? (
              <div>
                <TableView
                  currentCandidateDetail={currentCandidateDetail}
                  seletedJobStage={seletedJobStage}
                  handleExcelExport={handleExcelExport}
                  candidateTableViewDetails={candidateList}
                  exportCandidateTableViewDetails={props.exportCandidateTableViewDetails}
                  onMultipleCandidateSelect={onMultipleCandidateSelect}
                  handleCandidateClick={handleCandidateClick}
                  handleRejectClick={handleRejectClick}
                  handleEmailClicked={handleEmailClicked}
                  SetAsDelete={(data, history) => {
                    setState({ ...state, deleteParam: data, history: history, showDeleteConfirmModel: true })
                    // props.SetAsDelete(data, history);
                  }}
                  setActivitiesState={(values) => {
                    const { modalOpen, modalTitle, modalContent } = values;
                    let newState = {
                      ...state,
                      modalOpen,
                      modalTitle,
                      modalContent,
                    };
                    setState(newState);
                  }}
                  // onChangeJobStage={onChangeJobStage}
                  selectedJobId={state.selectedJobId}
                  selectedCandidates={candidates}
                  seletedJobStage={state.seletedJobStage}
                  hiringStagesJobId={
                    props.hiringStagesJobId && props.hiringStagesJobId.value
                  }
                  // makeCandidateFavorite={props.makeCandidateFavorite}
                  history={props.history}
                  handleRowChange={handleRowChange}
                  handleIndexChange={handleIndexChange}
                  pageIndex={state.pageIndex}
                  rowsPerPage={state.rowsPerPage}
                  updateMultipleCandidateDetails={(data, history) => {
                    props.updateMultipleCandidateDetails(data, history);
                  }}
                  onSearch={handleTableViewSearch}
                  handleRejectClick={handleRejectClick}
                  setRejectModal={setRejectModal}
                  setCandiateId={setCandiateId}
                  setDisqualifyModal={setDisqualifyModal}
                  setJobId={setJobId}
                />
              </div>
            ) : viewId == 2 ? (
              <ProfileView
                currentCandidateDetail={currentCandidateDetail}
                candidateTableViewDetails={candidateList}
                appliedCandidateList={appliedCandidateList}
                selectedCandidate={selectedCandidate}
                seletedJobStage={state.seletedJobStage}
                handleCandidateClick={handleCandidateClick}
                jobStageList={props.hiringStageAndSubStagesJobId.value}
                onChangeJobStage={onChangeJobStage}
                addRemoveTags={props.addRemoveTags}
                handleRejectClick={handleRejectClick}
                searchByName={state.searchByName}
                {...props}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        {rejectDataModel && (
          <RejectCandidate
            src="recruitment"
            open={showRejectCandidateModal}
            handleModal={(value) => {
              setState({ ...state, showRejectCandidateModal: value });
            }}
            data={rejectDataModel}
          />
        )}

        <ComposeEmail
          src="recruitment"
          open={showComposeModal}
          handleModal={(value) => setComposeModal(value)}
          data={composeModalData || {}}
        />

        {confirmation(
          setRejectModal,
          rejectModal,
          candiateId,
          setDisqualifyModal,
          disqualifyModal,
          jobId,
          { history: props.history }
        )}
        {showDeleteConfirmModel &&
          <Modal
            title=""
            open={showDeleteConfirmModel}
            onModalClose={() => setState({ ...state, showDeleteConfirmModel: false })}
            type="confirm"
            func={() => handleCandidateDelete()}
          ></Modal>
        }
      </div >
    );
  };
  return render();
}

const mapStateToProps = (state) => ({
  currentCandidateDetail: state.recruitment_dashboardReducer.currentCandidateDetail,
  appliedCandidateList: state.recruitment_dashboardReducer.appliedCandidateList,
  candidateDetail: state.recruitment_dashboardReducer.candidateDetail,
  candidateListByStage: state.recruitment_dashboardReducer.candidateListByStage,
  jobStageList: state.recruitment_dashboardReducer.jobStageList,
  candidateTableViewDetails: state.recruitment_dashboardReducer.candidateTableViewDetails,
  exportCandidateTableViewDetails: state.recruitment_dashboardReducer.exportCandidateTableViewDetails,
  milestoneDetail: state.recruitment_dashboardReducer.milestoneDetail,
  hiringStagesJobId: state.recruitment_dashboardReducer.hiringStagesJobId,
  hiringStageAndSubStagesJobId:
    state.recruitment_dashboardReducer.hiringStageAndSubStagesJobId,
  updateCandiateRejection: state.recruitment_dashboardReducer.updateCandiateRejection,
  saveCandidateRejectLoading: state.recruitment_rejectReducer.saveCandidateRejectLoading,
});

const mapDispatchToProps = {
  GetDashboardDetail,
  GetAppliedCandidateListByJobId,
  GetCandidateListByJobAndStageId,
  GetCandidateDetail,
  GetJobStages,
  ChangeCandidateStage,
  GetCandidateDetailsByJobId,
  // makeCandidateFavorite,
  updateMultipleCandidateDetails,
  // moveCandidateToNextStage,
  GetMilestoneDetail,
  addRemoveTags,
  getHiringStagesByJobId,
  GetHiringStagAndSubstageByJobId,
  GetRecruitmentDashboardDetail,
  GetSelectedCandidateWithDetail,
  UndoCandidateReject,
  SaveCandidateReject,
  SetAsDelete
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JobInformation));
