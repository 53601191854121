import React, {useState, useEffect} from "react";
import {getEmployeeListing} from "../../helpers/action";
import useDebounce from "helpers/customHooks/useDebounce";
import {useDispatch, useSelector} from "react-redux";
import types from "constants/types";
import Select from "react-select";
import Button from "components/button";

const EmployeeSelection = ({setOpen, multipleData, rowData, setRowData}) => {
  const [employeeList, setEmployeeList]: any = useState([]);
  const [typedValue, setTypedValue] = useState("");
  const [allValues, setAllValues] = useState([]);

  const dispatch = useDispatch();

  const {employeeListForSbiLoan} = useSelector((state: any) => state.commonReducer);
  // const {employeeListForSbiLoanTest} = useSelector(
  //   (state: any) => state.sbiLoanSettingReducer
  // );

  const getAllEmployee = () => {
    if (typedValue.length > 0) {
      dispatch(getEmployeeListing(typedValue));
    }
    // else {
    //   dispatch({type: types.GET_SBI_ALL_EMPLOYEES_SPECIFIC, payload: []});
    // }
  };

  useDebounce(() => getAllEmployee(), 1000, [typedValue]);

  const handleMultiSelect = (list, selected) => {
    setAllValues(list);
  };

  const inputChanged = (value, selected) => {
    setTypedValue(value);
  };

  useEffect(() => {
    if (multipleData.MultipleSpecificPersonName) {
      let allVal = multipleData?.MultipleSpecificPersonName?.split(",")?.map((place) => ({
        label: place,
        value: parseInt(place.split("-").pop().split(" ").join("")),
      }));

      setAllValues(allVal);
    }
  }, [multipleData]);
  useEffect(() => {
    let test = employeeListForSbiLoan.map((values) => ({
      value: values.EmployeeId,
      label: values.FullName + "-" + values.EmployeeId,
    }));
    setEmployeeList(test);
  }, [employeeListForSbiLoan]);

  return (
    <div>
      <div style={{width: "400px"}}>
        <Select
          value={allValues}
          closeMenuOnSelect={false}
          isMulti
          tabSelectsValue={false}
          className="isMulti"
          options={employeeList}
          onInputChange={(value, selected) => inputChanged(value, selected)}
          onChange={(list, selected) => handleMultiSelect(list, selected)}
        />
      </div>
      <Button
        title={"Save"}
        bg={"primary"}
        onClick={() => {
          let anyVal = allValues
            ?.map((val) => {
              return val.label;
            })
            .join(",");

          let anyVals = allValues
            ?.map((val) => {
              return val.value;
            })
            .join(",");

          let test = rowData?.map((values) => {
            if (values.StepId === multipleData.StepId) {
              values.MultipleSpecificPersonName = anyVal;
              values.MultipleSpecificPerson = anyVals;
            }
            return values;
          });

          setRowData(test);
          setOpen(false);
        }}
        style={{position: "fixed", bottom: 20, right: 20}}
      />
    </div>
  );
};

export default EmployeeSelection;
