import React, { useRef, useEffect } from 'react'
import {
    getUser,
    getBaseURL,
    getPublicRecruitmentBaseURL,
    loginUser,
    refreshToken,
} from "actions/authAction";
import {
    GetCompanyHolidaysWhole,
    GetCompanyInformation,
    GetEmpMenus
} from "actions/commonAction";
import { useDispatch, useSelector } from "react-redux";
import {
    getFeatureFlag,
    getActiveFeatures,
    getActiveFeatureFlag,
    activeFlags,
  } from "actions/permissionsAction";
import { roles } from "constants/constants";

function useCommonAPIs() {
    const dispatch = useDispatch();
    const { isAuthenticated, user, baseUrl } = useSelector((state: any) => state.auth);
    const { refresh_loading } = useSelector((state: any) => state.loadingReducer);
    useEffect(() => {
        (async () => {
            await dispatch(getBaseURL());
            await dispatch(getPublicRecruitmentBaseURL());
            // await dispatch(getActiveFeatureFlag());
            await dispatch(getFeatureFlag());
            await dispatch(getActiveFeatures());
            await dispatch(GetEmpMenus());
            // await dispatch(GetCompanyHolidaysWhole());
        })();
    }, []);
    let a: any = roles();
    useEffect(() => {
        if (isAuthenticated) {
            (async () => {
                const promiseArray = [
                    dispatch(getUser()),
                    dispatch(GetCompanyHolidaysWhole()),
                    dispatch(GetCompanyInformation(a.includes(user.RoleId), baseUrl?.NetFrameworkAppBaseUrl)),
                ];
                await Promise.all(promiseArray);
            })();
        }
    }, [isAuthenticated]);
}


export default useCommonAPIs