import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {
  getRosterLocationList,
  getRosterShitsList,
  getRosterShitsDropdownList,
  getRosterPatternList,
  getRosterScheduleList,
  getRosterScheduleEmployeeList,
  getRosterTeamList,
  getRosterTeamRoleList,
} from "./helpers/action";
import "./styles.scss";

function Rosters({children}) {
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      const promiseArray = [
        // dispatch(getRosterLocationList()),
        // dispatch(getRosterShitsList()),
        dispatch(getRosterTeamList()),
        // dispatch(getRosterPatternList()),
        // dispatch(getRosterTeamRoleList()),
      ];
      await Promise.all(promiseArray);
    })();
  }, []);
  return <div>{children}</div>;
}

export default Rosters;
