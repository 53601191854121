import React from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'isEmpty';

const stop = [
    {num:3,name:'Stop payment',label:'StopPaymentCount'},
    {num:'-',name:'Adjustments',label:'AdjustmentCount'},
    {num:'-',name:'Arrears',label:'ArrearsCount'},
    {num:'-',name:'No pay for LoA',label:'StopPaymentCount'},
    {num:'23 days of 2 emp',name:'LWP deduction days',label:'LWPdeductionDays'},
    // {num:'Late deductions',name:'24 hrs of 2 emp',label:'LWPdeductionEmpCount'},
    {num:'Late deductions',name:'LWP deduction emp count',label:'LWPdeductionEmpCount'},
    {num:'Late deductions',name:'Late deduction days',label:'LateDeductionDays'},
    {num:'Late deductions',name:'Late deduction emp count',label:'LateDeductionEmpCount'},
]

function SomeComponent(props) {
    const payrollDashboard = useSelector(state => state.payrollDashboardReducer.payrollDashboard );
    const {StopPaymentBlock}  = payrollDashboard;
    return (
        <div className="pending stopPayment">
            {
                payrollDashboard && !isEmpty(StopPaymentBlock)?(
                    <ul className="pending-list">
                        {
                            stop.map((pen,index)=>{
                                const num = StopPaymentBlock[pen.label] || StopPaymentBlock[pen.label] === 0 ? StopPaymentBlock[pen.label] : '-';
                                return(
                                <li key={index + Math.random()} className="pending-list__item">
                                    <span className="pending-name">{pen.name}</span>
                                    <span className="pending-value bold-500">{num}</span>
                                </li>
                            )}
                            )
                        }
                        
                    </ul>
                ):
                <span className="no-found">No Stop Payment Data Found</span>
            }
            
        </div>
    )
}

export default SomeComponent
