import React, { Component,useState,useEffect,useRef } from 'react'
import { ColumnDirective, ColumnsDirective, GridComponent } from '@syncfusion/ej2-react-grids';
import {FaCheckDouble,FaRegTimesCircle} from 'react-icons/fa'
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom'
import {updateSalaryAdjust,deleteSalaryAdjustment} from './action';
import { connect } from 'react-redux';
import isEmpty from 'isEmpty'
import classnames from 'classnames';
import Modal from '../../../components/common/modal'
import { Prompt } from 'react-router'
import {Tooltip} from '@material-ui/core';
import {MdDateRange} from 'react-icons/md'
import Loader from '../../../components/loaders'
import getFormattedNumber from '../../../helpers/getFormattedNumber'
import Moment from 'react-moment'
import NavigationPrompt from "react-router-navigation-prompt";
import PromptPopup from '../../../components/common/promptPopup'
import UpdateInput from '../../../components/common/updateInput'
import CommonPagination from '../../../components/common/pagination'

export class Table extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            editable:[],
            note:'',
            shouldChangePage:false
        }
        this.tablerow=[]
        this.template = this.gridTemplate;
        this.onTotalChange=this.onTotalChange.bind(this);
        this.onSave=this.onSave.bind(this);
        this.onDelete=this.onDelete.bind(this);
        this.handleChangePage=this.handleChangePage.bind(this)
        this.handleChangeRowsPerPage=this.handleChangeRowsPerPage.bind(this);
        this.shouldBlockNavigation=this.shouldBlockNavigation.bind(this);
        this.checkIfSaved=this.checkIfSaved.bind(this);
        this.onUpdate=this.onUpdate.bind(this);
    }
    onTotalChange(e,args){
        // const {name,value}=e.target;
        // const toSendData = { 
        //     PayrollPeriodId:args.PayrollPeriodId,
        //     IncomeDeductionId:args.IncomeDeductionId,
        //     EmployeeId:args.EmployeeId,
        //     Type:args['TYPE'],
        //     ExistingAmount:isEmpty(args.ExistingAmount)?0:parseInt(args.ExistingAmount),
        //     AdjustmentAmount:isEmpty(args.AdjustmentAmount)?0:parseInt(args.AdjustmentAmount),
        //     PaidAmount:args.PaidAmount,
        //     Note:args.Note,
        // }
        // const stateData =this.state.editable;
        // const finds = stateData.find(item=>item.EmployeeId===args.EmployeeId);
        // if(isEmpty(finds)){
        //     if(name==='Note'){
        //         toSendData.Note=value;
        //     }else if(name==='AdjustmentAmount'){
        //         toSendData.AdjustmentAmount=value;
        //     }
        //     this.setState({editable:[...this.state.editable,toSendData]});
        // }else{
        //     if(name==='Note'){
        //         toSendData.Note=value;
        //         toSendData.AdjustmentAmount=finds.AdjustmentAmount;
        //     }else if(name==='AdjustmentAmount'){
        //         toSendData.AdjustmentAmount=value;
        //         toSendData.Note=finds.Note;
        //     }
        //     const prevState  = stateData.map(item=>item.EmployeeId===args.EmployeeId?toSendData:item);
        //     this.setState({editable:prevState})
        // }
    }
    onSave(){
        const filtered=this.tablerow.filter(item=>item.state.isEdit===true);
        const toSend=[];

        let a = filtered.map(item=>{
            const {args}=item.props;
                const toSendData = { 
                    PayrollPeriodId:args.PayrollPeriodId,
                    IncomeDeductionId:args.IncomeDeductionId,
                    EmployeeId:args.EmployeeId,
                    Type:args['TYPE'],
                    ExistingAmount:args.ExistingAmount,
                    AdjustmentAmount:isEmpty(item.state.AdjustmentAmount)?args.AdjustmentAmount:parseInt(item.state.AdjustmentAmount),
                    PaidAmount:args.PaidAmount,
                    Note:isEmpty(item.state.Note)?args.Note:item.state.Note,
                }
                toSend.push(toSendData);
        });
        this.props.updateSalaryAdjust(toSend);
    }
    onDelete(){
        const toSend=[];
        let a = this.tablerow.map(item=>{
            const {args}=item.props;
            const toSendData = { 
                PayrollPeriodId:args.PayrollPeriodId,
                IncomeDeductionId:args.IncomeDeductionId,
                EmployeeId:args.EmployeeId,
                Type:args['TYPE'],
                ExistingAmount:args.ExistingAmount,
                AdjustmentAmount:args.AdjustmentAmount,
                PaidAmount:args.PaidAmount,
                Note:args.Note,
            }
            toSend.push(toSendData);
        });
        this.props.deleteSalaryAdjustment(toSend);
    }
    onUpdate(index){
     
        const tableLength = this.tablerow.length;
        const ind = parseInt(index);
        if(ind <tableLength-1){
            const tab = this.tablerow[ind+1];
            tab.onNoteEdit();
            tab.onOpen();
            
        }
        
    }
    gridTemplate(props) {
        return (
            <TableRow args={props} 
                    ref={(tablerow) => {this.tablerow=[...this.tablerow, tablerow]}}
                    editable={this.state.editable}
                    onRowUpdateChange={this.onUpdate}
                    deleteSalaryAdjustment={this.props.deleteSalaryAdjustment}
                    updateSalaryAdjust={this.props.updateSalaryAdjust} 
                    onTotalChange={this.onTotalChange} />
        );
    }
    // componentDidUpdate = (prevProps, prevState) => {
    //   const currentAdjust=this.props.salaryAdjustReducer.searchSalaryAdjustment;
    //   const prevAdjust =prevProps.salaryAdjustReducer.searchSalaryAdjustment;
    //   const arraysAreEqual=(ary1,ary2)=>{
    //     return (ary1.join('') == ary2.join(''));
    //   }
    //   if(arraysAreEqual(currentAdjust,prevAdjust)){
    //       currentAdjust.map(adjust=>{
    //           const newdata={PayrollPeriodId:adjust.PayrollPeriodId,
    //                         IncomeDeductionId:adjust.IncomeDeductionId,
    //                         EmployeeId:adjust.EmployeeId,
    //                         Type:adjust.TYPE,
    //                         ExistingAmount:adjust.ExistingAmount,
    //                         AdjustmentAmount:adjust.AdjustmentAmount,
    //                         PaidAmount:adjust.PaidAmount,
    //                         Note:adjust.Note,
    //                         }
    //           this.setState({editable:[...this.state.editable,newdata]})
    //       })
    //   }
    // };
    checkIfSaved(){
        let a = this.shouldBlockNavigation();
        if(a){
            let message=(
                JSON.stringify({
                        "confirmText": "", 
                        "title":"Are You Sure ?",
                        "messageText": `It looks like you might have some unsaved  
                                    hanges! Are you sure you want to continue?`,
                        "cancelText": "Do not Continue"
                    }  
                )
            );
            return message;
        }else{
            return true
        }
        
    }
    shouldBlockNavigation(){
       
        if(!isEmpty(this.tablerow)){
                let isEdit = false;
                this.tablerow.map(table=>{
                    const {AdjustmentAmount,Note}=table.state;
                    const args=table.props.args;
                    if(!args.IsSaved){
                        if(Note!==args.Note || AdjustmentAmount !==args.AdjustmentAmount){
                            isEdit=true;
                        }
                    }
                    
                });
                if(isEdit){
                    return true
                }else{
                    return false;
                }
            }else{
                return false;
            }
    }
    handleChangePage = (event, newPage) => {
        this.props.handleIndexChange(newPage)
    };
    handleChangeRowsPerPage = (event) => {
        this.props.handleIndexChange(0)
        this.props.handleRowChange(event.target.value)
    };
    render() {
        const {searchSalaryAdjustment,searchedPage,loading}= this.props.salaryAdjustReducer;
        
        return(
            <div>
                {/* <Prompt
                    message={this.checkIfSaved.bind(this)}
                /> */}
                <NavigationPrompt when={() =>this.shouldBlockNavigation()
                            }>
                    {({ onConfirm, onCancel }) => {
                        const message = this.checkIfSaved();
                        PromptPopup(message,onConfirm, onCancel)
                        }
                        
                    }
                </NavigationPrompt>
                {/* <PromptPopup
                            when={true}
                            message={JSON.stringify({
                                "confirmText": "", 
                                "title":"Are You Sure ?",
                                "messageText": `It looks like you might have some unsaved  
                                            hanges! Are you sure you want to continue?`,
                                "cancelText": "Do not Continue"
                            }  
                        )}
                            onCancel={onCancel}
                            onConfirm={onConfirm}
                        /> */}
            <div className="panel blue space-between payroll-panel">
                <div className="flex">
                    <h4>Salary Adjustment</h4>
                </div>
                {/* <div>
                    <a onClick={()=>this.toolbarClick('grid_excelexport')} className="btn-text btn-text__white">Export</a>
                </div> */}
            </div>
            <div className="arrear-table">
            {
                loading?<Loader open={loading} />:
                <div className="payroll-grid">
                    <GridComponent dataSource={searchSalaryAdjustment} 
                        toolbar={this.toolbar}
                        //  height={!isEmpty(searchedLeaveAbsence)?"500":'80'}
                        id='grid'
                        allowExcelExport={true} 
                        ref={g=> this.grid = g}
                        rowTemplate={this.template.bind(this)}>
                        <ColumnsDirective>
                            <ColumnDirective field='EmployeeId'  width='60'/>
                            <ColumnDirective field='Name' width='100'/>
                            <ColumnDirective field='Old Amount' width='100' textAlign="right" />
                            <ColumnDirective field='Change By' width='80'  textAlign="right" />
                            <ColumnDirective field='New Amount' width='90' textAlign="right" />
                            <ColumnDirective field='Note' width='120'/>
                            <ColumnDirective field='' width='150'/>
                        </ColumnsDirective>
                    </GridComponent>
                    {
                        isEmpty(searchSalaryAdjustment)?null:
                            <CommonPagination 
                                    handleRowChange={this.props.handleRowChange}
                                    handleIndexChange={this.props.handleIndexChange}
                                    pageIndex={this.props.pageIndex}
                                    searchedPage={searchedPage}
                                    rowsPerPage={this.props.rowsPerPage}
                                />
                        }
                </div>
                    
            }
            
        </div>
        
        <div className="button-group button-group__right">
            <button className="btn btn-blue" onClick={()=>this.onSave()}>Save Imported Figures</button>
            <button className="btn btn-del" onClick={()=>this.onDelete()}>Delete</button>
        </div>
        </div>
        )
    }
}


export class TableRow extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            Note:'',
            noteEdit:false,
            changeEdit:false,
            AdjustmentAmount:null,
            modalOpen:false,
            isEdit:false,
            isNote:false,
        }
        this.change='';
        this.note='';
        this.onSave=this.onSave.bind(this);
        this.onModalClose=this.onModalClose.bind(this);
        this.onChange=this.onChange.bind(this);
        this.handleEscKey=this.handleEscKey.bind(this);
        this.handleClick=this.handleClick.bind(this);
        this.onEdit=this.onEdit.bind(this);
        this.onEnter=this.onEnter.bind(this);
        this.onCancel=this.onCancel.bind(this);
        this.onUpdate=this.onUpdate.bind(this);
        this.onOpen=this.onOpen.bind(this);
        this.onNoteEdit=this.onNoteEdit.bind(this);
    }
    componentDidMount() {
        if(!isEmpty(this.props.args)){
            this.setState({AdjustedAmount:parseInt(this.props.args.ExistingAmount)+parseInt(this.props.args.AdjustmentAmount)});
        }
        document.addEventListener('keyup', this.handleEscKey);
        document.addEventListener('mousedown', this.handleClick);
    }
    componentWillUnmount() {
        document.removeEventListener('keyup', this.handleEscKey);
        document.removeEventListener('mousedown', this.handleClick);
    }
    handleEscKey(e){
        if(e.keyCode===27 && e.key==="Escape" ){
            if (this.change && !this.change.contains(e.target)) {
                // this.setState({noteEdit:false});  
                this.onCancel();
            }
            if(this.note && !this.note.contains(e.target)){
                this.setState({changeEdit:false});  
            }
        }
    }
    handleClick(e){
        const ele = document.getElementById("uploadInput");
        if (this.change && !this.change.contains(e.target) && ele &&  !ele.contains(e.target)) {  
            // this.setState({changeEdit:false});
            this.onCancel();  
        }
    }
    onOpen(){
        this.setState({changeEdit:true});
      
    }
    onEdit(e,name){
        if(e.target.tagName.toUpperCase() !== 'INPUT'){
            this.setState({changeEdit:!this.state.changeEdit})
            this.onNoteEdit(name)
        }
        
    }
    onEnter(e,name){
        const {onEnterEdit,args} = this.props;
        if(e.keyCode===13){
            this.setState({changeEdit:!this.state.changeEdit});
            this.onNoteEdit(name);
        }
        if(e.keyCode===27 && e.key==="Escape"){
            this.onCancel();
        }
        
    }
    onCancel=()=>{
        this.setState({changeEdit:false})
    }
    onSave=()=>{
        const {args}=this.props;
            const toSendData = { 
                    PayrollPeriodId:args.PayrollPeriodId,
                    IncomeDeductionId:args.IncomeDeductionId,
                    EmployeeId:args.EmployeeId,
                    Type:args['TYPE'],
                    ExistingAmount:isEmpty(args.ExistingAmount)?0:parseInt(args.ExistingAmount),
                    AdjustmentAmount:isEmpty(this.state.AdjustmentAmount)?0:parseInt(this.state.AdjustmentAmount),
                    PaidAmount:args.PaidAmount,
                    Note:isEmpty(this.state.Note)?args.Note:this.state.Note,
            }
            this.props.updateSalaryAdjust([toSendData])
        }
    onModalClose=()=>{
        this.setState({modalOpen:false});
    }
    onChange=(value)=>{
        // console.log(value)
        const {note,amount}=value;
        const newAmount = isEmpty(amount)?null:parseInt(amount)
        this.setState({
            Note:note,
            AdjustmentAmount:newAmount,
            isEdit:true
        },()=>{
            this.onUpdate()
        });
    }
    onNoteEdit(name){
        if(name==='note'){
            this.setState({isNote:true})
        }else{
            this.setState({isNote:false})
        }
    }
    onUpdate = ()=>{
        const {onRowUpdateChange,args} =this.props;
        const {AdjustmentAmount,Note} = this.state;
        onRowUpdateChange(args.index);
        this.onCancel();
        // if(!isEmpty(AdjustmentAmount) || !isEmpty((Note))){
        //     this.onSave();  
        // }
        
    }
    render() {
        const{
                args,
                onTotalChange,
                editable,
                updateSalaryAdjust,
                deleteSalaryAdjustment
            }=this.props;
        const currentData = editable.find(item =>item.EmployeeId===args.EmployeeId);
        const delData={ 
            PayrollPeriodId:args.PayrollPeriodId,
            IncomeDeductionId:args.IncomeDeductionId,
            EmployeeId:args.EmployeeId,
            Type:args['TYPE'],
            ExistingAmount:args.ExistingAmount,
            AdjustmentAmount:args.AdjustmentAmount,
            PaidAmount:args.PaidAmount,
            Note:args.Note,
    }
    const {AdjustmentAmount,Note,changeEdit}=this.state;
    
    const rowClass = classnames({
        "leave-row":true,
        "tax-row":true,
        "bordered":true,
        "active-row":changeEdit,
        "active-blue":args.IsSaved,
        "active":!isEmpty(AdjustmentAmount) || !isEmpty(Note)
        // "active":AdjustmentAmount !==args.AdjustmentAmount || Note !==args.Note
    });
    const tdClass = classnames({
        "align-right":true,
        "editable-td":true,
        "editedCol":changeEdit
    }) 
        return (
        <tr className={rowClass}>
        <td className="">
            <a href="#">
                <span>
                    {args.EmployeeId}
                </span>
            </a>
            
        </td>
        <td className="">
            <a href="#">
                <span>
                    {args.Name}
                </span>
            </a>
        </td>
        <td className="align-right">
            <span>
                {getFormattedNumber(args.ExistingAmount)}
            </span>
        </td>
        <td className={tdClass}
            id={args.EmployeeId}
            ref={change=>this.change=change}
            onKeyUp={(e)=>this.onEnter(e,'change')} 
            onClick={(e)=>this.onEdit(e)}
            // onDoubleClick={(e)=>this.onEdit(e)}
            >
            {
                // this.state.changeEdit && !args.IsSaved ?
                this.state.changeEdit?
                <>
                    <span className="editedText">
                        {
                            isEmpty(args.AdjustmentAmount)?0:
                            getFormattedNumber(args.AdjustmentAmount)
                        }
                    </span>
                        <UpdateInput
                            ele={this.change}
                            onChange={this.onChange}
                            amountName="AdjustmentAmount"
                            noteName="Note"
                            onUpdate={this.onUpdate}
                            onCancel ={this.onCancel}
                            amountValue={AdjustmentAmount}
                            noteValue={Note}
                            isNote={this.state.isNote}
                        />
                </>
                    
                :
                <span  className="text-blue">
                    {isEmpty(AdjustmentAmount)?
                        isEmpty(args.AdjustmentAmount)?"Adjust":getFormattedNumber(args.AdjustmentAmount):
                        AdjustmentAmount >=0 ?`+ ${getFormattedNumber(AdjustmentAmount)}`:`${getFormattedNumber(AdjustmentAmount)}`
                    }
                </span>
            }
        </td>
        <td className="align-right">
            <span className="text-green">
                {
                    args.IsSaved?
                    getFormattedNumber(parseInt(args.ExistingAmount) + parseInt(args.AdjustmentAmount))
                    :
                    isEmpty(AdjustmentAmount)?getFormattedNumber(parseInt(args.ExistingAmount)):getFormattedNumber(parseInt(args.ExistingAmount) + parseInt(AdjustmentAmount))
                }
            </span>
        </td>
        <td className="align-left editable-td" ref={note=>this.note=note}
            onKeyUp={(e)=>this.onEnter(e,'note')} 
            onClick={(e)=>this.onEdit(e,'note')}
            // onDoubleClick={(e)=>this.onEdit(e,'note')}
            >
            <span className="text-blue">
                {args.IsSaved?args.Note:isEmpty(Note)?
                        isEmpty(args.Note)?"Adjust":args.Note:Note
                }
            </span>
        </td>
        <td className="align-right" >{
                !args.IsSaved?
                // <button className="btn btn-blue grid-btn" onClick={()=>this.onSave()}>Save</button>
                <button className="btn btn-blue grid-btn" style={{visibility:'hidden',opacity:0}}>Save</button>
                :
                <div className="flex" style={{flexDirection:'row-reverse'}}>
                    <button style={{marginTop:0}} 
                        className="btn btn-blue grid-btn" 
                        onClick={()=>this.setState({modalOpen:true})}>Reset</button>
                        <div className="tool">
                            <Tooltip 
                                    title={
                                        
                                        <div className="tooltip">
                                            
                                            <span className="name" style={{marginBottom:'5px'}}>{args.AdjustedBy && args.AdjustedBy}</span>
                                            <span className="date">
                                            <Moment format="YYYY/MM/DD">
                                                {args.AdjustedOn && args.AdjustedOn}
                                            </Moment>
                                                
                                            </span>
                                        </div>
                                    } 
                                    placement="bottom">
                                <span className="tool-icon">
                                    <MdDateRange />
                                    {/* <FaUserEdit /> */}
                                </span>
                            </Tooltip>
                                
                                
                            </div>
                </div>
                // <button className="btn btn-grey" onClick={()=>deleteSalaryAdjustment([editData])}>Delete</button>
            }
                <Modal type="confirm" 
                    open={this.state.modalOpen}
                    onModalClose={this.onModalClose} 
                    func={()=>deleteSalaryAdjustment([delData])}
                    // id={[delData]} 
                /> 
        </td>
    </tr>
        )
    }
}

Table.propTypes = {
    salaryAdjustReducer: PropTypes.object.isRequired,
    updateSalaryAdjust:PropTypes.func,
    deleteSalaryAdjustment:PropTypes.func
};
    
const mapStateToProps = state => ({
    salaryAdjustReducer: state.salaryAdjustReducer,
});
    
export default withRouter(
    connect(
        mapStateToProps,
        {
            updateSalaryAdjust,
            deleteSalaryAdjustment
        }
    )    
    (Table)
);

