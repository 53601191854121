import * as React from "react";

interface IAppProps {
  timeAttendanceRequest: any[];
}

const App: React.FunctionComponent<IAppProps> = (props) => {
  const { timeAttendanceRequest } = props;
  return (
    <div className="time-table flex-column">
      <div className="time-table__header">
        <span>Date</span>
        <span>Device in</span>
        <span>Device out</span>
        <span>Requested in</span>
        <span>In Note</span>
        <span>Requested out</span>
        <span>Out Note</span>
      </div>
      <div className="time-table__body flex-column">
        {timeAttendanceRequest?.map((x) => {
          return (
            <div className="time-table__body-row">
              <span>{x.RequestDate || "-"}</span>
              <span>{x.InTimeDv || "-"}</span>
              <span>{x.OutTimeDv || "-"}</span>
              <span className="requested-time">{x.InTime || "-"}</span>
              <span>{x.InNote || "-"}</span>
              <span className="requested-time">{x.OutTime || "-"}</span>
              <span>{x.OutNote || "-"}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default App;
