import React from "react";
import classnames from "classnames";
import isEmpty from "isEmpty";

export const Sidetab = (props) => {
  const { currentStep, onStepChange, isAt } = props;
  const tabContent = [
    {
      num: 1,
      title: "Property Details",
    },
    {
      num: 2,
      title: "Area",
    },
    {
      num: 3,
      title: "Loan Requirement",
    },
    {
      num: 4,
      title: "Documents",
    },
    {
      num: 5,
      title: "Approvers",
    },
  ];
  return (
    <div className="homeloan-sidebar-header">
      <div className="flex">
        {tabContent.map((item) => (
          <Tab
            tab={item}
            onStepChange={onStepChange}
            currentStep={currentStep}
            isAt={isAt}
          />
        ))}
      </div>
    </div>
  );
};

export const Tab = ({ tab, currentStep, onStepChange, isAt }) => {
  const classes = classnames({
    "homeloan-sidebar-header-item": true,
    disabled: tab.num > isAt,
    selected: tab.num === currentStep,
  });
  return (
    <a
      key={tab.num}
      className={classes}
      disabled={true}
      onClick={() => onStepChange(tab.num, true)}
    >
      <div className="homeloan-sidebar-title">{tab.title}</div>
      {/* <div className="recruit-header-body">
        <p>{tab.details}</p>
      </div> */}
    </a>
  );
};

export default Sidetab;
