import React from "react";
import ReactPDF, {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet
} from "@react-pdf/renderer";
import {getUploadPath} from 'constants/userDetails'
// import moment from "moment";
import { getBaseAxios } from "helpers/constants";
import isEmpty from "isEmpty";
import { numberToNepaliCurrency } from "helpers/regex";

export default function travelPdfTemplate(props) {
  const {
    CompanyDetail,
    TravelDetails,
    TravelDestination,
    TravelExpense,
    TravelRequestApprover
  } = props;

  let advanceAndExpense_Total = 0;
  return (
    <Document title="Travel Request.pdf">
      <Page style={styles.body} size="A4" orientation="portrait">
        {/*portrait */}
        <Text style={styles.header} fixed>
          Travel Request
        </Text>
        {CompanyDetail && (
          <>
            {CompanyDetail.CompanyLogo && (
              <Image
                style={styles.image}
                src={getUploadPath(CompanyDetail.CompanyLogo)}
                // src={"https://bhetincha.com/files/thumb/15322/1500/450/fit.png"}
              />
            )}
            <Text style={styles.company}>{CompanyDetail.CompanyName}</Text>
            {/* <Text style={styles.branch}>{"---"}</Text> */}
            <Text style={styles.address}>{CompanyDetail.Address}</Text>
          </>
        )}
        <View style={{ marginTop: 20 }}></View>
        <Text style={styles.title}>Travel Report</Text>
        <View>
          <View style={styles.row}>
            <Text style={styles.leftColumn}>Travel Order #</Text>
            <Text style={styles.rightColumn}>
              {!isEmpty(TravelDetails.RequestNumber)
                ? TravelDetails.RequestNumber
                : "--"}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftColumn}>Order Date</Text>
            <Text style={styles.rightColumn}>
              {TravelDetails.TravelOrderDate
                ? TravelDetails.TravelOrderDate
                : "--"}
            </Text>
          </View>
        </View>
        <View style={{ marginTop: 15 }}></View>
        {TravelDetails.RequestedBy && (
          <View>
            <Text style={styles.text}>Authorized to</Text>
            <Text style={styles.name}>
              {TravelDetails && TravelDetails.RequestedBy}
            </Text>
            <Text style={styles.text}>
              {TravelDetails.Designation && TravelDetails.Designation + ", "}
              {TravelDetails.Department && TravelDetails.Department + ", "}
              {TravelDetails.Branch}
            </Text>
          </View>
        )}
        <View style={styles.tableBlock}>
          <Text
            style={[
              styles.subtitle,
              {
                borderBottomWidth: 0.5,
                marginBottom:3
              }
            ]}
          >
            Travel Information
          </Text>
          {TravelDestination &&
            TravelDestination.map((dest, i) => {
              return (
                <View style={styles.travelInformation} key={i}>
                  <View style={styles.row}>
                    <Text style={styles.leftColumn}>Place of Travel</Text>
                    <Text style={styles.rightColumn}>{dest.PlaceName}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.leftColumn}>Mode</Text>
                    <Text style={styles.rightColumn}>{dest.TransportMode}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.leftColumn}>Dates</Text>
                    <Text style={styles.rightColumn}>
                      {dest.StartDate + " - "}
                      {dest.EndDate}
                      {!isEmpty(dest.TravelDuration) &&
                        " (" + dest.TravelDuration + ")"}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.leftColumn}>Purpose</Text>
                    <Text style={styles.rightColumn}>
                      {TravelDetails.Purpose}
                    </Text>
                  </View>
                </View>
              );
            })}
        </View>
        <View style={styles.tableBlock}>
          <Text style={[styles.subtitle, { marginTop:3 }]}>
            Allowances and Advance
          </Text>
          {TravelExpense && TravelExpense.length > 0 ? (
            <View style={styles.allowanceAndAdvance}>
              <View style={styles.tableTitle}>
                <Text style={styles.c1}>Particulars</Text>
                <Text style={styles.c2}>Units</Text>
                <Text style={styles.c3}>Rate</Text>
                <Text style={styles.c4}>Amount</Text>
              </View>
              {TravelExpense.map((te, i) => {
                advanceAndExpense_Total =
                  advanceAndExpense_Total + parseFloat(te.Amount);
                return (
                  <View style={styles.row} key={i}>
                    <Text style={styles.c1}>{te.AllowanceName}</Text>
                    <Text style={styles.c2}>{te.Units}</Text>
                    <Text style={styles.c3}>
                      {numberToNepaliCurrency(te.Rate)}
                    </Text>
                    <Text style={styles.c4}>
                      {numberToNepaliCurrency(te.Amount)}
                    </Text>
                  </View>
                );
              })}
              <View style={styles.total}>
                <Text style={styles.c1}>Total</Text>
                <Text style={styles.c2}></Text>
                <Text style={styles.c3}></Text>
                <Text style={styles.c4}>
                  {"NPR " + numberToNepaliCurrency(advanceAndExpense_Total)}
                </Text>
              </View>
              <View style={styles.advance}>
                <Text style={styles.c1}>Advance</Text>
                <Text style={styles.c2}></Text>
                <Text style={styles.c3}></Text>
                <Text style={styles.c4}>
                  {"NPR " +
                    numberToNepaliCurrency(TravelDetails.AdvanceTakenAmount)}
                </Text>
              </View>
            </View>
          ) : (
            <Text style={styles.message}>No Allowance and advance found.</Text>
          )}
        </View>

        <View style={styles.approvedBy}>
          <Text style={styles.approvedByTitle}>
            Travel Authorization Approved by
          </Text>
          <View style={styles.authorizers}>
            {TravelRequestApprover && TravelRequestApprover.length > 0 ? (
              TravelRequestApprover.map((tra, i) => {
                return (
                  <View style={styles.authorizer}>
                    <Text style={styles.text}>{tra.ApproverName}</Text>
                    <Text style={styles.text}>{tra.Designation}</Text>
                    <Text style={styles.text}>{tra.ApprovedOn}</Text>
                  </View>
                );
              })
            ) : (
              <Text style={styles.message}>Not approved yet.</Text>
            )}
          </View>
        </View>
        <View style={styles.printedDate}>
          <Text style={styles.text}>
            {"Printed On: " + TravelDetails.PrintedOn}
          </Text>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  company: {
    fontSize: 17,
    marginBottom:2,
    fontWeight: "bold",
    textAlign: "left",
    fontFamily: "Times-Roman"
  },
  branch: {
    fontSize: 10,
    textAlign: "left",
    marginTop: 5
  },
  address: {
    fontSize: 12,
    textAlign: "left"
  },
  title: {
    fontSize: 16,
    paddingVertical: 5,
    fontFamily: "Times-Roman"
  },
  subtitle: {
    fontSize: 12,
    textAlign: "left",
    paddingVertical: 3,
    marginTop: 5,
  },

  row: {
    flex: 1,
    flexDirection: "row",
    marginVertical: 2.5
  },
  leftColumn: {
    width: "20%",
    fontSize: 10,
    fontFamily: "Times-Roman"
  },
  rightColumn: {
    width: "80%",
    fontSize: 10,
    fontFamily: "Times-Roman"
  },

  name: {
    fontSize: 10,
    fontWeight: 500,
    marginBottom: 4,
    textAlign: "left"
  },

  travelInformation: {
    marginTop: 3
  },

  allowanceAndAdvanceTitle: {
    marginTop: 8
  },
  allowanceAndAdvanceText: {
    fontSize: 12,
    textAlign: "left"
  },
  allowanceAndAdvance: {
    marginTop: 3
  },

  tableTitle: {
    flex: 1,
    flexDirection: "row",
    paddingVertical: 3,
    borderBottomWidth: 0.5,
    borderBottomColor: "grey",
    backgroundColor: "#f2f2f2",
    marginBottom: 3
  },
  c1: {
    width: "55%",
    fontSize: 10,
    fontFamily: "Times-Roman",
    paddingLeft: 2
  },
  c2: {
    width: "10%",
    textAlign: "justify",
    fontSize: 10,
    fontFamily: "Times-Roman"
  },
  c3: {
    width: "15%",
    textAlign: "justify",
    fontSize: 10,
    fontFamily: "Times-Roman"
  },
  c4: {
    width: "20%",
    textAlign: "right",
    fontSize: 10,
    fontFamily: "Times-Roman",
    paddingRight: 4
  },

  total: {
    flex: 1,
    flexDirection: "row",
    paddingVertical: 3,
    borderTopWidth: 0.5,
    borderTopColor: "grey",
    marginTop: 3
  },

  advance: {
    flex: 1,
    flexDirection: "row",
    paddingVertical: 3,
    borderBottomWidth: 0.5,
    borderBottomColor: "grey"
  },

  approvedBy: {
    marginVertical: 15
  },
  approvedByTitle: {
    fontSize: 10,
    textDecoration: "underline"
    // borderBottomWidth:0.5,
    // borderBottomColor:'#000',
    // paddingBottom:1,
    // marginTop: 5,
    // width:"28%"
  },
  tableBlock:{
    marginVertical:5
  },
  authorizers: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap"
  },
  authorizer: {
    maxWidth: "33.33%",
    flexDirection: "column",
    marginVertical: 5,
    paddingRight: 20
  },
  printedDate: {
    marginVertical: 5
  },
  text: {
    fontSize: 10,
    marginBottom: 4,
    textAlign: "left",
    fontFamily: "Times-Roman"
  },
  message: {
    fontSize: 8,
    margin: 4,
    textAlign: "left",
    fontFamily: "Times-Roman",
    color: "grey"
  },
  image: {
    maxWidth: "50%",
    maxHeight: 30,
    objectFit: "contain",
    marginRight: "auto",
    marginBottom: 4
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "#a1b7d6"
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey"
  }
});
