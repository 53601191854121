import React, { useState, useEffect } from "react";
import Avatar from "react-avatar";
import { useSelector } from "react-redux";
import { formatedFullDayMMYearTime } from "helpers/dateFormat";
import isEmpty from "helpers/isEmpty";

const CommentSection = (props) => {
  const [commentData, setCommentData] = useState({} as any);
  const { baseUrl } = useSelector((state: any) => state.auth);
  const base = baseUrl ? baseUrl.NetFrameworkAppBaseUrl : "";
  // const base = props.auth.baseUrl
  //   ? props.auth.baseUrl.NetFrameworkAppBaseUrl
  //   : "";

  useEffect(() => {
    setCommentData(props.data);
  }, [props.data]);

  return (
    !isEmpty(commentData) && !isEmpty(commentData.ActionRemarks) && (
      <div className="taskmodal__activities-comment" key={commentData.ActionBy}>
          <Avatar
            className="avatar"
            src={`${base}${commentData.ImagePath}`}
            name={commentData.ActionBy}
            size={"30"}
            round={true}
          />
            <div className="comment">
              <div className="comment-title">
                {commentData.ActionBy}{" "}
                <span>
                {formatedFullDayMMYearTime(commentData.ActionOnEng)}
                </span>
              </div>

              <div className="comment-body">
              {commentData.ActionRemarks && <div
                  dangerouslySetInnerHTML={{ __html: commentData.ActionRemarks }}
              ></div>
              }
              </div>
              
            </div>
        </div>
    )
  );
};

export default CommentSection;
