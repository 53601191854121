import React, { useState } from "react";
import Sidebar from "./../components/sidebar";
import classnames from 'classnames';
function Boards(props) {
  const [showSideBar, setShowSideBar] = useState(true);
  return (
    <div className={classnames("tasks",{"tasks-close":!showSideBar})} style={{ position: "relative" }}>
      <Sidebar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <div className="tasks-main">{props.children}</div>
    </div>
  );
}

export default Boards;
