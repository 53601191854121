export const ADD_BOARD = "ADD_BOARD";
export const DELETE_BOARD = "DELETE_BOARD";
export const UPDATE_BOARD = "UPDATE_BOARD";
export const GET_BOARDS = "GET_BOARDS";
export const GET_BOARD = "GET_BOARD";
export const GET_BOARD_LABEL = "GET_BOARD_LABEL";
export const Save_BOARD_LABEL = "Save_BOARD_LABEL";
export const DELETE_BOARD_LABEL = "DELETE_BOARD_LABEL";
export const GET_TABLE_VIEW = "GET_TABLE_VIEW";
export const RESET_TABLE_VIEW = "RESET_TABLE_VIEW";
export const UPDATE_TABLE_VIEW = "UPDATE_TABLE_VIEW";
export const GET_BOARD_VIEW = "GET_BOARD_VIEW";
export const ADD_TASK = "ADD_TASK";
export const GET_TASK_DETAILS = "GET_TASK_DETAILS";
export const ADD_TASK_DETAILS = "ADD_TASK_DETAILS";
export const ADD_TASK_DETAILS_LOADING = "ADD_TASK_DETAILS_LOADING";
export const DELETE_TASKS = "DELETE_TASKS";
export const UPDATE_TASK_DETAILS = "UPDATE_TASK_DETAILS";
export const MOVE_TASK = "MOVE_TASK";
export const DUPLICATE_TASK = "DUPLICATE_TASK";
export const GET_CARD_VIEW = "GET_CARD_VIEW";
export const ADD_TASK_LABEL = "ADD_TASK_LABEL";
export const GET_BOARD_MEMBERS = "GET_BOARD_MEMBERS";
export const SAVE_BOARD_MEMBERS = "SAVE_BOARD_MEMBERS";
export const GET_COMMENTS_BY_TASK_ID = "GET_COMMENTS_BY_TASK_ID";
export const GET_COMMENTS_BY_TASK_ID_LOADING =
  "GET_COMMENTS_BY_TASK_ID_LOADING";
export const COMMENTS_SCROLL = "COMMENTS_SCROLL";
export const GET_BOARD_ACTIVITY = "GET_BOARD_ACTIVITY";
export const SET_BOARD_ACTIVITY_AS_VIEWED = "SET_BOARD_ACTIVITY_AS_VIEWED";
export const GET_BOARD_DETAILS = "GET_BOARD_DETAILS";
