import {
  GET_INTERVIEW_MEDIUM,
  GET_INTERVIEW_MEDIUM_LOADING,
  SEND_INTERVIEW_INVITATION_LOADING,
  GET_SCHEDULED_INTERVIEW,
  GET_SCHEDULED_INTERVIEW_LOADING,
} from "store/types";

const initialState = {
  interviewMediums: [],
  interviewMediumLoading: [],
  sendInterviewInvitationLoading: false,
  scheduledInterview: {},
  scheduledInterviewLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_INTERVIEW_MEDIUM:
      return {
        ...state,
        interviewMediums: action.payload,
        interviewMediumLoading: false,
      };
    case GET_INTERVIEW_MEDIUM_LOADING:
      return {
        ...state,
        interviewMediumLoading: action.payload,
      };
    case SEND_INTERVIEW_INVITATION_LOADING: {
      return { ...state, sendInterviewInvitationLoading: action.payload };
    }
    case GET_SCHEDULED_INTERVIEW_LOADING:
      return {
        ...state,
        scheduledInterviewLoading: action.payload,
      };
    case GET_SCHEDULED_INTERVIEW:
      return {
        ...state,
        scheduledInterview: action.payload,
        scheduledInterviewLoading: false,
      };
    default:
      return state;
  }
}
