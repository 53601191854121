import React, { Component } from "react";
import "../../../scss";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import { MdAdd } from "react-icons/md";
import { FaRegFilePdf } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";
import ClientProjectTabs from "./projectTabs";
import AnimatedForm from "components/common/animatedForm";
import { InputValidator } from "helpers/inputValidator";
import { dateFieldValidator } from "./dateFieldValidator";
import isEmpty from "isEmpty";
import { isDateTodayOrAfter, Date_Format } from "helpers/dateFormat";
import { ValidationComponent } from "helpers/getComponents";
import Form from "components/form";
import Modal from "components/modal";
import { objectToFormData } from "object-to-formdata";
import {
  // GetDepartments,
  DisplayClientSideMessage,
  GetEmployeeList,
} from "actions/commonAction";
import {
  GetProjectMatterId,
  AddNewProject,
  GetProjectDetails,
  UpdateProject,
  DeleteProjectContract,
  GetBillingDesignations,
  GetActiveClientsList,
  GetLegalClientList,
  GetLegalClientTypes,
  SaveProjectContract,
  GetSecretarialChargesTypes,
  GetClientByClientId,
  GetMemberByName,
  GetDepartmentToHandleCase,
} from "../../../action";
import { dateFieldValidation } from "./dateFieldValidator";
import { PostFilter } from "actions/employeesAction";
import { Enum_EventType } from "../../../enums";

const fileClasses = (value) => {
  return classnames({
    badges: value,
    "badges-rounded": value,
    "left-icon": value,
    inputWidth: "calc(100% - 225px)",
    "badges-default": value,
    "badges-file": value,
  });
};

export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTabId: 1,
      selectedClient: "",
      ProjectName: "",
      secretarialCharges: { label: "Lumpsum", value: "1" },
      amount: null,
      HandlingDepartmentId: null,
      selectedDepartment: null,
      selectedClientTypeId: null,
      secretarialChargesNote: "",
      additionalRate: "",
      CurrencyCode: null,

      contractHours: null,
      contractAmount: null,
      contractCurrency: null,

      meetingContractHours: null,
      meetingContractAmount: null,
      meetingContractCurrency: null,
      renewDocuments: [],
      contributingMembers: [
        {
          key: 1,
          id: "",
          label: "",
          value: null,
          estHours: 0,
          hourlyRate: 0,
          image: "",
          inputKey: `estHours${1}`,
        },
      ],
      counterParties: [{ key: 1, id: "", value: "", label: "", selected: "1" }],
      CourtHearingDateEng: null,
      CourtHearing2DateEng: null,
      CourtHearing3DateEng: null,
      ContractDate: null,
      ContractEndDate: null,
      InternalDeadlineEng: null,
      ExternalDeadlineEng: null,
      ExternalDeadlineEng2: null,
      ExternalDeadlineEng3: null,
      editMode: false,
      ContractList: [],
      billingDesignations: [],
      isTabReady: false,
      dateFieldsError: {},
    };
  }

  async componentDidMount() {
    this.props.childRef.current = this;
    let { projectId, clientId, projectDataToEdit } = this.props.parent;

    this.setState({ projectDataToEdit: projectDataToEdit });
    //#region  These functions are called on listing page too so no need to async await
    this.props.GetMemberByName();
    this.props.GetDepartmentToHandleCase();
    this.props.GetBillingDesignations();
    this.props.GetSecretarialChargesTypes();
    this.props.GetLegalClientTypes();
    this.props.GetActiveClientsList();
    this.props.GetEmployeeList();
    //#endregion

    if (projectId) {
      await this.getDataByProjectId(projectId);
    } else {
      await this.getLegalClients(clientId, projectId);
    }

    this.setState({ isTabReady: true });
  }

  getLegalClients = async (clientId, ProjectId) => {
    await this.props.GetClientByClientId(clientId);

    if (clientId) {
      const { legalClientById, billingDesignations } = this.props;
      let selectedClient = {
        label: legalClientById.ClientName,
        value: legalClientById.ClientId,
      };

      this.setState(
        {
          selectedClient,
          CurrencyCode: legalClientById.CurrencyCode,
          selectedClientTypeId: legalClientById.ClientTypeRefId,
          billingDesignations,
        },
        () => {
          this.getMatterId();
        }
      );
    }
  };

  getDataByProjectId = async (projectId) => {
    await this.props.GetProjectDetails(projectId);
    const { projectDetails, departments } = this.props;

    if (projectDetails) {
      let selectedClient = {
        label: projectDetails.ClientName,
        value: projectDetails.ClientRefId,
      };

      this.setState(
        {
          selectedClient,
          CurrencyCode: projectDetails.CurrencyCode,
          selectedClientTypeId: projectDetails.ClientTypeRefId,
          billingDesignations: this.props.billingDesignations,
        },
        () => {
          this.getMatterId();
        }
      );

      // department to handle
      let selectedDepartment;
      await departments.forEach((department) => {
        if (parseInt(department.value) == projectDetails.HandlingDepartmentId) {
          selectedDepartment = {
            label: department.label,
            value: department.value,
          };
        }
      });

      // counter parties
      let counterParties = [];
      await projectDetails.CounterPartyList.forEach((party, index) => {
        counterParties.push({
          key: index + 1,
          id: "",
          value: party.CounterPartyName,
          label: party.CounterPartyName,
          selected: "" + party.PositionId + "",
        });
      });
      if (counterParties.length == 0) {
        counterParties = [
          { key: 1, id: "", value: "", label: "", selected: "1" },
        ];
      }

      // team members
      let contributingMembers = [];
      await projectDetails.TeamList.forEach((teamMember, index) => {
        teamMember.MemberDetail && 
        contributingMembers.push({
          key: index + 1,
          id: teamMember.MemberDetail.MemberId,
          label: teamMember.MemberDetail.MemberName,
          value: teamMember.MemberDetail,
          estHours: teamMember.EstimatedHours,
          hourlyRate: teamMember.HourlyRate ? teamMember.HourlyRate : 0,
          image: teamMember.MemberDetail.ImgPath,
          projectRefId: teamMember.ProjectRefId,
          inputKey: `estHours${index + 1}`,
        });
      });
      if (contributingMembers.length == 0) {
        contributingMembers = [
          {
            key: 1,
            id: "",
            label: "",
            value: null,
            estHours: 0,
            image: "",
            hourlyRate: 0,
            inputKey: `estHours${1}`,
          },
        ];
      }

      // hourlyList;
      let billingDstns = [...this.state.billingDesignations];
      let billingDesignations = [];

      billingDstns &&
        billingDstns.map((billing) => {
          projectDetails.HourlyRateList.forEach((hour) => {
            if (parseInt(hour.DesignationId) == parseInt(billing.Value)) {
              billingDesignations.push({
                ...billing,
                HourlyRate: hour.HourlyRate,
                DesignationId: hour.DesignationId,
              });
            }
          });
        });

      let projectData = {
        editMode: projectId && projectDetails ? true : false,
        ProjectName: projectDetails.ProjectName,

        ApproverRefId: projectDetails.Approval.Approver
          ? {
              label: projectDetails.Approval.Approver.FullName,
              value: projectDetails.Approval.ApproverRefId,
            }
          : null,
        Approver2RefId: projectDetails.Approval.Approver2
          ? {
              label: projectDetails.Approval.Approver2.FullName,
              value: projectDetails.Approval.Approver2RefId,
            }
          : null,
        ReviewerRefId: projectDetails.Approval.Reviewer
          ? {
              label: projectDetails.Approval.Reviewer.FullName,
              value: projectDetails.Approval.ReviewerRefId,
            }
          : null,
        Reviewer2RefId: projectDetails.Approval.Reviewer2
          ? {
              label: projectDetails.Approval.Reviewer2.FullName,
              value: projectDetails.Approval.Reviewer2RefId,
            }
          : null,

        contributingMembers,
        note: projectDetails.EventNote,
        projectMatterId: projectDetails.MatterId,

        Attachment: projectDetails.Contract.Attachment,
        ContractDate:
          projectDetails.Contract.EventStartDateEng &&
          Date_Format(projectDetails.Contract.EventStartDateEng), // || new Date(),
        ContractEndDate:
          projectDetails.Contract.EventEndDateEng &&
          Date_Format(projectDetails.Contract.EventEndDateEng), // || new Date(),
        InternalDeadlineEng:
          projectDetails.InternalDeadlineEng &&
          Date_Format(projectDetails.InternalDeadlineEng), // || new Date(),
        ExternalDeadlineEng:
          projectDetails.ExternalDeadlineEng &&
          Date_Format(projectDetails.ExternalDeadlineEng), // || new Date(),
        ExternalDeadlineEng2:
          projectDetails.ExternalDeadlineEng2 &&
          Date_Format(projectDetails.ExternalDeadlineEng2), // || new Date(),
        ExternalDeadlineEng3:
          projectDetails.ExternalDeadlineEng3 &&
          Date_Format(projectDetails.ExternalDeadlineEng3), // || new Date(),
        CourtHearingDateEng:
          projectDetails.CourtHearingDateEng &&
          Date_Format(projectDetails.CourtHearingDateEng), // || new Date(),
        CourtHearing2DateEng:
          projectDetails.CourtHearing2DateEng &&
          Date_Format(projectDetails.CourtHearing2DateEng), // || new Date(),
        CourtHearing3DateEng:
          projectDetails.CourtHearing3DateEng &&
          Date_Format(projectDetails.CourtHearing3DateEng), // || new Date(),

        selectedClientTypeId: projectDetails.Fee.ClientTypeRefId,
        contractHours: projectDetails.Fee.ContractHours,
        contractAmount: projectDetails.Fee.ContractAmount,
        contractCurrency: projectDetails.Fee.ContractCurrency,
        meetingContractHours: projectDetails.Fee.MeetingContractHours,
        meetingContractAmount: projectDetails.Fee.MeetingContractAmount,
        meetingContractCurrency: projectDetails.Fee.MeetingContractCurrency,
        secretarialChargesNote: projectDetails.Fee.Note,
        additionalRate: projectDetails.Fee.AdditionalRate,
        amount:
          projectDetails.Fee.SecretarialAmount ||
          projectDetails.Fee.SecretarialPercent ||
          0,
        selectedDepartment,
        counterParties,
        HandlingDepartmentId: projectDetails.HandlingDepartmentId,
        // ContractList: projectDetails.ContractList,
        ContractList: projectDetails.ContractHistory,
        secretarialCharges: {
          label:
            projectDetails.Fee.SecretarialChargeCalcType == 1
              ? "Lumpsum"
              : "PercentageOfBillAmount",
          value: "" + projectDetails.Fee.SecretarialChargeCalcType,
        },
        billingDesignations,
      };
      this.setState({ ...projectData });
    }
  };

  changeFeeType = (feeType) => {
    this.setState({ feeType });
  };

  handleSelectedFile = (event) => {
    const newFile = event.target.files[0];
    let { renewDocuments } = this.state;
    renewDocuments.push({
      AttachmentId: renewDocuments.length + 1,
      file: newFile,
    });
    this.setState({ renewDocuments });
  };

  handleRemoveFile = (document) => {
    let { renewDocuments } = this.state;
    renewDocuments = renewDocuments.filter(
      (f) => f.AttachmentId !== document.AttachmentId
    );
    this.setState({ renewDocuments });
  };

  addTeamMember = (option, key) => {
    if (option == "add") {
      let contributingMembers = [...this.state.contributingMembers];
      const newKey =
        contributingMembers[contributingMembers.length - 1].key + 1;
      const newWork = {
        key: newKey,
        id: "",
        inputKey: `estHours${newKey}`,
        value: null,
        image: "",
        hourlyRate: 0,
      };

      contributingMembers.push(newWork);
      this.setState({ contributingMembers });
    } else {
      let oldMembers = [...this.state.contributingMembers];
      if (oldMembers.length > 1) {
        let newMembers = oldMembers.filter(function (member) {
          return member.key != key;
        });
        this.setState({
          contributingMembers: newMembers,
        });
      }
    }
  };

  handleAutoSelect = (name, selected) => {
    this.setState({ [name]: selected });
  };

  handleInput = async (name, value) => {
    if (name.includes("billing")) {
      let billingId = name.replace("billing", "");
      let billingDstns = [...this.state.billingDesignations];
      let billingDesignations = billingDstns.map(function (billing) {
        if (parseInt(billing.Value) == parseInt(billingId)) {
          return {
            ...billing,
            DesignationId: parseInt(billing.Value),
            HourlyRate: parseInt(value),
          };
        } else {
          return billing;
        }
      });
      this.setState({ billingDesignations });
    } else
      this.setState({
        [name]: value,
        submitted: false,
        errors: {
          ...this.state.errors,
          [name]: null,
        },
      });
  };

  handleClientType = (name, value) => {
    const selectedClientTypeId = value.value ? parseInt(value.value) : null;
    this.setState({ [name]: selectedClientTypeId });
  };

  onDateChange = (name, date) => {
    this.setState(
      {
        [name]: date,
      },
      () => {
        this.updateErrors();
        if (name == "ContractDate") this.getMatterId();
      }
    );
  };

  updateErrors = async () => {
    const {
      CourtHearingDateEng,
      CourtHearing2DateEng,
      CourtHearing3DateEng,
      ContractDate,
      ContractEndDate,
      InternalDeadlineEng,
      ExternalDeadlineEng,
      ExternalDeadlineEng2,
      ExternalDeadlineEng3,
    } = this.state;

    const validatingFields = {
      CourtHearingDateEng,
      CourtHearing2DateEng,
      CourtHearing3DateEng,
      ContractDate,
      ContractEndDate,
      InternalDeadlineEng,
      ExternalDeadlineEng,
      ExternalDeadlineEng2,
      ExternalDeadlineEng3,
    };

    let dateFieldsError = await dateFieldValidator(document, validatingFields);
    this.setState({ dateFieldsError });
  };

  onTextChange = (name, value) => {
    this.setState({ [name]: value });
  };

  // handle department selection
  onSelectChange = (name, value) => {
    this.setState(
      {
        [name]: value,
        HandlingDepartmentId: value ? value.value : null,
        errors: {
          ...this.state.errors,
          [name]: null,
        },
      },
      () => {
        this.getMatterId();
      }
    );
  };

  // fetch matter ID
  getMatterId = () => {
    const { HandlingDepartmentId, selectedClient, ContractDate } = this.state;
    let matterObj = {
      clientId:
        selectedClient && selectedClient.value.ClientId
          ? selectedClient.value.ClientId
          : selectedClient.value,
      departmentId: HandlingDepartmentId,
      contractDate: ContractDate,
    };
    this.props.GetProjectMatterId(matterObj);
  };

  setSelectedFiles = (e) => {
    const file = e.target.files[0];
    if (file) {
      let { renewDocuments } = this.state;
      renewDocuments.push({
        AttachmentId: renewDocuments.length + 1,
        file: file,
      });
      this.setState({ renewDocuments });
    }
  };

  // counter paties section
  addCounterParties = (option, key) => {
    if (option == "add") {
      let newParties = [...this.state.counterParties];
      const newKey = newParties[newParties.length - 1].key + 1;
      const newWork = {
        key: newKey,
        id: "",
        selected: "1",
        value: "",
        label: "",
      };

      newParties.push(newWork);
      this.setState({ counterParties: newParties });
    } else if (option == "remove") {
      let oldParties = [...this.state.counterParties];
      if (oldParties.length > 1) {
        let newParties = oldParties.filter(function (party) {
          return party.key != key;
        });
        this.setState({
          counterParties: newParties,
        });
      }
    }
  };

  changeCounterParties = (name, value, option) => {
    let counterParties = [...this.state.counterParties];
    counterParties.map((party) => {
      if (party.key == name) {
        option == "input" ? (party.value = value) : (party.selected = value);
      }
    });
    this.setState({ counterParties });
  };

  // team member select
  handleEmployeeSelect = (event, value) => {
    let siblingField =
      event == "ReviewerRefId"
        ? "Reviewer2RefId"
        : event == "Reviewer2RefId"
        ? "ReviewerRefId"
        : event == "ApproverRefId"
        ? "Approver2RefId"
        : event == "Approver2RefId"
        ? "ApproverRefId"
        : event;
    if (value || event !== "selectedClient")
      this.setState(
        {
          [event]: value,
          errors: {
            ...this.state.errors,
            [event]: null,
            [siblingField]: null,
          },
        },
        () => {
          // if (event == "selectedClient") this.getMatterId();
        }
      );
  };

  handleTeamMember = (name, value) => {
    const { billingDesignations } = this.state;
    let contributingMembers = [...this.state.contributingMembers];

    let selectedBillingDesignation = billingDesignations.find(
      (f) => f.DesignationId == value.billingDesignationId
    );

    let hourlyRate = 0;
    if (selectedBillingDesignation && selectedBillingDesignation.HourlyRate) {
      hourlyRate = selectedBillingDesignation.HourlyRate;
    }

    contributingMembers.map((member) => {
      if (`team${member.key}` == name) {
        member.value = value;
        member.id = value ? value.value : null;
        member.label = value ? value.label : null;
        member.image = value ? value.Imgpath : null;
        member.hourlyRate = hourlyRate;
      }
    });
    this.setState({ contributingMembers });
  };

  changeMemberEstHours = (name, value) => {
    let contributingMembers = [...this.state.contributingMembers];
    contributingMembers.map((member) => {
      if (member.inputKey == name) {
        member.estHours = value;
      }
    });
    this.setState({ contributingMembers });
  };

  changeMemberHourlyRate = (name, value) => {
    let contributingMembers = [...this.state.contributingMembers];
    contributingMembers.map((member) => {
      if (member.inputKey == name) {
        member.hourlyRate = value;
      }
    });
    this.setState({ contributingMembers });
  };

  deleteContractHisory = async (eventId, deletable) => {
    if (!deletable) return;
    this.setState({ modalOpen: true, eventId });
  };

  onModalClose = () => this.setState({ modalOpen: false });

  confirmDeleteContractHistory = async (eventId) => {
    let prevContractList = [...this.state.ContractList];
    let ContractList = prevContractList.map((contract) => {
      if (contract.ContractId != eventId) return contract;
    });
    await this.props.DeleteProjectContract(eventId);
    if (this.props.contractDeleted) this.setState({ ContractList });
  };

  //edit contract list events
  editContractHistoryEvent = async (data, renewDocuments, onModalClose) => {
    await this.props.SaveProjectContract(
      data,
      renewDocuments,
      onModalClose,
      null
    );

    if (this.props.contractSaved) {
      let documents = renewDocuments.map((doc) => {
        return {
          AttachmentId: doc.AttachmentId,
          UserFileName: doc.file.name,
        };
      });
      let contractList = [...this.state.ContractList];
      let updatedContractList = contractList.map((contract) => {
        if (contract.ContractId == data.ContractId) {
          return {
            ...contract,
            ...data,
            ContractDocuments: [...documents],
          };
        } else return contract;
      });
      this.setState({ ContractList: updatedContractList });
    } else onModalClose();
  };

  // submit the project
  handleSave = async () => {
    let validationFields = { ...this.state };
    delete validationFields["errors"];
    let data = this.state;

    let errors = {};
    // if (!validationFields.ApproverRefId && !validationFields.Approver2RefId)
    //   errors.ApproverRefId = "At least One Member Required";
    // if (!validationFields.ReviewerRefId && !validationFields.Reviewer2RefId)
    //   errors.ReviewerRefId = "At least One Member Required";
    if (!validationFields.HandlingDepartmentId)
      errors.selectedDepartment = "Department Required";

    errors = {
      ...errors,
      ...(await InputValidator(document, validationFields)),
    };

    if (!isEmpty(errors)) {
      this.setState({ errors, submitted: true });
      this.props.DisplayClientSideMessage("Please fill all required fields.");
      return;
    } else if (!isEmpty(this.state.dateFieldsError)) {
      this.props.DisplayClientSideMessage("Please fill valid dates.");
      return;
    } else {
      // post the data
      // if (
      //   !isDateTodayOrAfter(data.InternalDeadlineEng, data.contractDate) &&
      //   !isDateTodayOrAfter(data.ExternalDeadlineEng, data.contractDate)
      // ) {
      //   this.props.DisplayClientSideMessage("Valid contract date is required.");
      //   return;
      // }

      let counterParties = [];
      let teamMembers = [];
      let HourlyRateList = [];

      let dataToCompute = [
        data.counterParties.map((party) => {
          if (party.value != "")
            counterParties.push({
              CounterPartyName: party.value,
              PositionId: parseInt(party.selected),
            });
        }),
        data.contributingMembers.map((member) => {
          if (member.value)
            teamMembers.push({
              ProjectRefId: member.projectRefId ? member.projectRefId : 0,
              MemberId: member.id ? parseInt(member.id) : null,
              MemberTypeRefId: member.value.memberTypeId
                ? member.value.memberTypeId
                : member.value.MemberType,
              EstimatedHours:
                member.id && parseInt(member.estHours)
                  ? parseInt(member.estHours)
                  : 0,
              HourlyRate: member.hourlyRate,
            });
        }),
      ];
      if ([1, 2, 3, 4].includes(this.state.selectedClientTypeId)) {
        dataToCompute.push(
          data.billingDesignations.map((billings) => {
            HourlyRateList.push({
              DesignationId: parseInt(billings.Value),
              HourlyRate: billings.HourlyRate
                ? parseInt(billings.HourlyRate)
                : 0,
            });
          })
        );
      }

      await Promise.all(dataToCompute);

      // let fd = new FormData();
      // data.renewDocuments.map((doc, i) => {
      //   fd.append(`Files`, doc.file);
      // });
      // fd.append("EventStartDateEng", data.contractDate);
      // fd.append("EventEndDateEng", data.ExternalDeadlineEng);
      // fd.append("EventNote", data.note ? data.note : "");
      

      if (data.selectedClientTypeId != 1) {
        data.meetingContractAmount = 0;
        data.meetingContractCurrency = null;
        data.meetingContractHours = 0;
        data.additionalRate = "";
      }

      let projectData = {
        ClientRefId: data.selectedClient.value,
        ProjectName: data.ProjectName,
        MatterId: data.editMode
          ? data.projectMatterId
          : this.props.projectMatterId,
        HandlingDepartmentId: parseInt(data.HandlingDepartmentId),
        CourtHearingDateEng: data.CourtHearingDateEng,
        CourtHearing2DateEng: data.CourtHearing2DateEng,
        CourtHearing3DateEng: data.CourtHearing3DateEng,
        InternalDeadlineEng: data.InternalDeadlineEng,
        ExternalDeadlineEng: data.ExternalDeadlineEng,
        ExternalDeadlineEng2: data.ExternalDeadlineEng2,
        ExternalDeadlineEng3: data.ExternalDeadlineEng3,
        // ReviewerRefId: data.ReviewerRefId ? data.ReviewerRefId.value : null,
        // Reviewer2RefId: data.Reviewer2RefId ? data.Reviewer2RefId.value : null,
        // ApproverRefId: data.ApproverRefId ? data.ApproverRefId.value : null,
        // Approver2RefId: data.Approver2RefId ? data.Approver2RefId.value : null,
        // ContractStartDateEng: data.contractDate,
        // EventEndDateEng: data.ContractEndDate,

        Approval: {
          ReviewerRefId: data.ReviewerRefId ? data.ReviewerRefId.value : null,
          Reviewer2RefId: data.Reviewer2RefId
            ? data.Reviewer2RefId.value
            : null,
          ApproverRefId: data.ApproverRefId ? data.ApproverRefId.value : null,
          Approver2RefId: data.Approver2RefId
            ? data.Approver2RefId.value
            : null,
        },

        Fee: {
          ClientTypeRefId: data.selectedClientTypeId,
          ContractHours: data.contractHours,
          ContractAmount: data.contractAmount ? data.contractAmount : 0,
          // ContractCurrency: data.contractCurrency,
          MeetingContractHours: data.meetingContractHours
            ? data.meetingContractHours
            : 0,
          MeetingContractAmount: data.meetingContractAmount
            ? data.meetingContractAmount
            : 0,
          // MeetingContractCurrency: data.meetingContractCurrency,
          SecretarialChargeCalcType: data.secretarialCharges.value,
          SecretarialAmount:
            data.amount && data.secretarialCharges.value == 1 ? data.amount : 0,
          SecretarialPercent:
            data.secretarialCharges.value == 2 ? data.amount : 0,
          AdditionalRate: data.additionalRate,
          Note: data.secretarialChargesNote,
        },
        Contract: {
          EventStartDateEng: data.ContractDate,
          EventEndDateEng: data.ContractEndDate,
          EventNote: data.note,
          Attachment: data.Attachment,
          // files: data.renewDocuments,
        },
        HourlyRateList,
      };
      if (counterParties) projectData.CounterPartyList = counterParties;
      if (teamMembers) projectData.TeamList = teamMembers;
      if (data.editMode) {
        let { projectId } = this.props.parent;
        projectData.ProjectId = projectId;
        projectData.ContractId = this.state.projectDataToEdit.ContractId;
      }

      const options = {
        /**
         * include array indices in FormData keys
         * defaults to false
         */
        indices: true,

        /**
         * treat null values like undefined values and ignore them
         * defaults to false
         */
        nullsAsUndefineds: false,

        /**
         * convert true or false to 1 or 0 respectively
         * defaults to false
         */
        booleansAsIntegers: false,
      };
      const formData = objectToFormData(projectData, options);

      data.renewDocuments.map((doc, i) => {
        formData.append(`Files`, doc.file);
      });

      if (data.editMode) {
        await this.props.UpdateProject(
          formData,
          this.props.parent.handleFormDisplay
        );
      } else {
        await this.props.AddNewProject(
          formData,
          this.props.parent.handleFormDisplay
        );
      }
    }
  };

  // handleSaveResponse = async () => {
  //   if (this.props.savedClientProject) {
  //     await this.addEventsToProject(
  //       this.props.savedClientProject.Fee.ProjectRefId,
  //       true
  //     );
  //   } else {
  //     this.props.parent.handleFormDisplay &&
  //       this.props.parent.handleFormDisplay(false);
  //   }
  // };

  // handleUpdateRespone = async (projectId) => {
  //   const { renewDocuments } = this.state;
  //   if (!isEmpty(renewDocuments)) {
  //     await this.addEventsToProject(projectId, false);
  //   } else {
  //     this.props.parent.handleFormDisplay &&
  //       this.props.parent.handleFormDisplay(false);
  //   }
  // };

  // addEventsToProject = async (ProjectRefId, isNewProject) => {
  //   let {
  //     contractDate,
  //     ExternalDeadlineEng,
  //     note,
  //     renewDocuments,
  //   } = this.state;

  //   let data = {
  //     ProjectRefId,
  //     EventTypeId: Enum_EventType.NewContract,
  //     EventStartDateEng: contractDate,
  //     EventEndDateEng: ExternalDeadlineEng,
  //     EventNote: note,
  //   };
  //   await this.props.SaveProjectContract(
  //     data,
  //     renewDocuments,
  //     false,
  //     this.props.parent.handleFormDisplay
  //   );
  // };

  // hoursToMin=(hrs)=>{
  //   let minutes=0;
  //   if (hrs) {
  //     let splitedHours=value.split('.');
  //     value=parseInt(splitedHours[0])
  //   }
  //   return minutes
  // }

  handleTabChange = (tabId) => {
    this.setState({ activeTabId: tabId });
  };

  render() {
    const values = this.state;
    // const { dateValidation } = this.state;
    let { departments, projectMatterId, activeClientsList } = this.props;
    let finalClientsList = [];
    activeClientsList &&
      activeClientsList.forEach((client) => {
        finalClientsList.push({
          label: client.ClientName,
          value: client,
        });
      });
    if (values.editMode) projectMatterId = values.projectMatterId;

    const { errors, dateFieldsError } = this.state;

    return (
      <>
        <div className="new_project">
          <div className="new_project-column">
            <ValidationComponent
              rootClassName="form-group select autoselect"
              error={errors && errors.selectedClient}
              errorClassName="error__message"
            >
              <Form
                formName="reactselect"
                name="selectedClient"
                value={values.selectedClient}
                options={finalClientsList}
                onChange={this.handleEmployeeSelect}
                disabled={values.editMode}
                label="Select Client"
                width="500px"
              />
            </ValidationComponent>

            <ValidationComponent
              rootClassName="form-group select"
              error={errors && errors.ProjectName}
              errorClassName="error__message"
            >
              <Form
                formName="textinput"
                name="ProjectName"
                label="Case or Project Name"
                value={values.ProjectName}
                onChange={this.handleInput}
                validators={["required"]}
                width="500px"
              />
            </ValidationComponent>

            <ValidationComponent
              rootClassName="form-group select"
              error={errors && errors.selectedDepartment}
              errorClassName="error__message"
            >
              <Form
                formName="reactselect"
                onChange={this.onSelectChange}
                name="selectedDepartment"
                label="Department to Handle Case"
                value={values.selectedDepartment}
                options={departments}
                width={"40%"}
                disabled={values.editMode}
              />
            </ValidationComponent>

            <div className="new_project-column-section">
              <div className="form-group">
                <Form
                  formName="customdateinput"
                  name="CourtHearingDateEng"
                  value={values.CourtHearingDateEng}
                  onChange={this.onDateChange}
                  width={"150px"}
                  label="Court Hearning Date 1"
                  error={dateFieldsError && dateFieldsError.CourtHearingDateEng}
                />
              </div>
              <div className="form-group">
                <Form
                  formName="customdateinput"
                  name="CourtHearing2DateEng"
                  value={values.CourtHearing2DateEng}
                  onChange={this.onDateChange}
                  width={"150px"}
                  label="Court Hearning Date 2"
                  validators={[
                    "required:CourtHearingDateEng,greaterThan:CourtHearingDateEng",
                  ]}
                  error={
                    dateFieldsError && dateFieldsError.CourtHearing2DateEng
                  }
                />
              </div>
              <div className="form-group">
                <Form
                  formName="customdateinput"
                  name="CourtHearing3DateEng"
                  value={values.CourtHearing3DateEng}
                  onChange={this.onDateChange}
                  width={"150px"}
                  label="Court Hearning Date 3"
                  validators={[
                    "required:CourtHearingDateEng,required:CourtHearing2DateEng,greaterThan:CourtHearing2DateEng",
                  ]}
                  error={
                    dateFieldsError && dateFieldsError.CourtHearing3DateEng
                  }
                />
              </div>
            </div>
            <div className="new_project-column-section">
              <div className="form-group">
                <Form
                  formName="customdateinput"
                  name="ContractDate"
                  value={values.ContractDate}
                  onChange={this.onDateChange}
                  width={"150px"}
                  disabled={values.editMode}
                  label="Contract Date"
                  error={dateFieldsError && dateFieldsError.ContractDate}
                />
              </div>
              <div className="form-group">
                <Form
                  formName="customdateinput"
                  name="ContractEndDate"
                  value={values.ContractEndDate}
                  minDate={
                    values.ContractDate ? values.ContractDate : new Date()
                  }
                  // minDateMessage="Contract EndDate should be greater than StartDate"
                  onChange={this.onDateChange}
                  width={"150px"}
                  label="Contract End Date"
                  validators={["greaterThan:ContractDate"]}
                  error={dateFieldsError && dateFieldsError.ContractEndDate}
                />
              </div>
              <div className="form-group">
                <Form
                  formName="customdateinput"
                  name="InternalDeadlineEng"
                  value={values.InternalDeadlineEng}
                  minDate={
                    values.ContractDate ? values.ContractDate : new Date()
                  }
                  // minDateMessage="Deadline should be greater than StartDate"
                  onChange={this.onDateChange}
                  width={"150px"}
                  label="Internal Deadline"
                  validators={["greaterThan:ContractEndDate"]}
                  error={dateFieldsError && dateFieldsError.InternalDeadlineEng}
                />
              </div>
            </div>

            <div className="new_project-column-section">
              <div className="form-group">
                <Form
                  formName="customdateinput"
                  name="ExternalDeadlineEng"
                  value={values.ExternalDeadlineEng}
                  minDate={
                    values.ContractDate ? values.ContractDate : new Date()
                  }
                  // minDateMessage="Deadline should be greater than StartDate"
                  onChange={this.onDateChange}
                  width={"150px"}
                  label="External Deadline 1"
                  error={dateFieldsError && dateFieldsError.ExternalDeadlineEng}
                />
              </div>
              <div className="form-group">
                <Form
                  formName="customdateinput"
                  name="ExternalDeadlineEng2"
                  value={values.ExternalDeadlineEng2}
                  minDate={
                    values.ContractDate ? values.ContractDate : new Date()
                  }
                  // minDateMessage="Deadline should be greater than StartDate"
                  onChange={this.onDateChange}
                  width={"150px"}
                  label="External Deadline 2"
                  validators={["greaterThan:ExternalDeadlineEng"]}
                  error={
                    dateFieldsError && dateFieldsError.ExternalDeadlineEng2
                  }
                />
              </div>
              <div className="form-group">
                <Form
                  formName="customdateinput"
                  name="ExternalDeadlineEng3"
                  value={values.ExternalDeadlineEng3}
                  minDate={
                    values.ContractDate ? values.ContractDate : new Date()
                  }
                  // minDateMessage="Deadline should be greater than StartDate"
                  onChange={this.onDateChange}
                  width={"150px"}
                  label="External Deadline 3"
                  validators={["greaterThan:ExternalDeadlineEng2"]}
                  error={
                    dateFieldsError && dateFieldsError.ExternalDeadlineEng3
                  }
                />
              </div>
            </div>
          </div>
          <div className="new_project-column">
            <ValidationComponent
              rootClassName="form-group select"
              errorClassName="error__message"
            >
              <Form
                type="text"
                name="matterId"
                value={projectMatterId && projectMatterId}
                width={"300px"}
                label="Matter ID"
                disabled
              />
            </ValidationComponent>

            <div style={{ height: "80px" }}></div>

            <div className="new_project-column-section">
              <div className="upload-btn-wrapper">
                <label htmlFor="client" className="form-group__label"></label>
                {values.renewDocuments &&
                  values.renewDocuments.map((document) => {
                    return (
                      <div
                        className={fileClasses(true)}
                        style={{
                          margin: "10px",
                        }}
                      >
                        <span className="document-icon">
                          <FaRegFilePdf />
                        </span>
                        <label className="document-name">
                          {document.file.name}
                        </label>
                        <span
                          className="document-icon cancel-icon"
                          onClick={() => this.handleRemoveFile(document)}
                        >
                          <AiFillCloseCircle />
                        </span>
                      </div>
                    );
                  })}
                <button className="button with-icon button-white">
                  <MdAdd />
                  <span>Attach Document</span>
                  <input type="file" onChange={this.setSelectedFiles} />
                </button>
              </div>
              {values.editMode && (
                <span
                  className="saved-document__count"
                  onClick={() => this.handleTabChange(5)}
                >
                  {"Total saved documents: "}
                  {(values.projectDataToEdit &&
                    values.projectDataToEdit.DocumentCount) ||
                    0}
                </span>
              )}
            </div>

            <ValidationComponent
              rootClassName="form-group"
              errorClassName="error__message"
            >
              <Form
                formName="textarea"
                name="note"
                value={values.note}
                label="Note"
                onChange={this.handleInput}
              />
            </ValidationComponent>
          </div>
        </div>

        {this.state.isTabReady && (
          <ClientProjectTabs
            handleInput={this.handleInput}
            handleClientType={this.handleClientType}
            addCounterParties={this.addCounterParties}
            changeCounterParties={this.changeCounterParties}
            changeFeeType={this.changeFeeType}
            changePartyRadio={this.changePartyRadio}
            data={values}
            handleEmployeeSelect={this.handleEmployeeSelect}
            handleTeamMember={this.handleTeamMember}
            changeMemberEstHours={this.changeMemberEstHours}
            changeMemberHourlyRate={this.changeMemberHourlyRate}
            addTeamMember={this.addTeamMember}
            handleAutoSelect={this.handleAutoSelect}
            deleteContractHisory={this.deleteContractHisory}
            editContractHistoryEvent={this.editContractHistoryEvent}
            onDateChange={this.onDateChange}
            onTextChange={this.onTextChange}
            handleSelectedFile={this.handleSelectedFile}
            handleRemoveFile={this.handleRemoveFile}
            handleTabChange={this.handleTabChange}
            selectedProjectForRenew={this.props.parent.selectedProjectForRenew}
            activeTabId={this.state.activeTabId}
            {...this.props}
          />
        )}

        <Modal
          type="confirm"
          open={this.state.modalOpen}
          onModalClose={this.onModalClose}
          func={() => this.confirmDeleteContractHistory(this.state.eventId)}
          // id={[delData]}
        />
      </>
    );
  }
}

index.propTypes = {
  GetDepartmentToHandleCase: PropTypes.func.isRequired,
  GetProjectMatterId: PropTypes.func.isRequired,
  GetBillingDesignations: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  departments: state.taskTrackerReducer.departments,
  projectMatterId: state.taskTrackerReducer.projectMatterId,
  projectDetails: state.taskTrackerReducer.projectDetails,
  savedClientProject: state.taskTrackerReducer.savedClientProject,
  contractDeleted: state.taskTrackerReducer.contractDeleted,
  billingDesignations: state.taskTrackerReducer.billingDesignations,
  activeClientsList: state.taskTrackerReducer.activeClientsList,
  legalClientById: state.taskTrackerReducer.legalClientById,
  contractSaved: state.taskTrackerReducer.contractSaved,
  membersByNameDll: state.taskTrackerReducer.membersByNameDll,
});

const mapDispatchToProps = {
  GetDepartmentToHandleCase,
  GetProjectMatterId,
  GetBillingDesignations,
  PostFilter,
  GetActiveClientsList,
  GetLegalClientList,
  GetLegalClientTypes,
  AddNewProject,
  UpdateProject,
  SaveProjectContract,
  GetSecretarialChargesTypes,
  GetProjectDetails,
  GetClientByClientId,
  DeleteProjectContract,
  GetMemberByName,
  GetEmployeeList,
  DisplayClientSideMessage,
};

export default AnimatedForm()(
  connect(mapStateToProps, mapDispatchToProps)(index)
);
