import React from "react";
import {FaRegCheckCircle, FaTimesCircle} from "react-icons/fa";

import {AiOutlineMail} from "react-icons/ai";
import isEmpty from "helpers/isEmpty";
import classnames from "classnames";
import Button from "components/button";
import Interview from "assets/images/interviewing.svg";
import {FaCalendarAlt} from "react-icons/fa";
function InterviewCard(props: any) {
  const {data, handleEmailClicked, setScheduleModal} = props;

  const renderInterViewers = (obj) => {
    let interViewers = [];
    if (!isEmpty(obj.Interviewer)) {
      interViewers = obj.Interviewer.split(",");
    }
    if (!isEmpty(obj.ExternalInterviewer)) {
      interViewers.push(obj.ExternalInterviewer);
    }
    let el = interViewers.map((x) => (
      <li>
        <div className="interviewer-personnel">{x}</div>
        {/* <div className="interviewer-process primary">Evaluation Pending</div> */}
      </li>
    ));
    return el;
  };
  const renderCards = () => {
    if (!data) {
      return (
        <button
          className="button-outline button-outline-primary mb-16"
          onClick={(e) => setScheduleModal(true)}
        >
          <FaCalendarAlt />
          <span className="ml-xsm">Schedule Interview</span>
        </button>
      );
    }
    return (
      <div>
        {data.map((item) => (
          <div>
            <div className="information-wholeInfo-milestone-interview-panel no-bg">
              <div className="interview-stage mb-md">
                <div className="interview-header">
                  <div className=" flex">
                    <img src={Interview} />
                    <span className="body-accordion_title">
                      {item.InterviewMedium} Interview
                    </span>
                  </div>
                  <div className="interviewdate">
                    {`Scheduled By ${item.ScheduledBy} ${item.InterviewDate}`}
                  </div>
                </div>
                <div className="interview-body">
                  <ul className="interviewers">
                    {renderInterViewers(item)}

                    {/* <li>
                      <div className="interviewer-personnel">Jelina</div>
                      <div className="interviewer-process success">Evaluation Pending</div>
                    </li> */}
                  </ul>
                </div>

                {item.SubStageName && (
                  <div className="panel-container-subtitle">{item.SubStageName}</div>
                )}
                {/* <div className="panel-container-sub-item">
                <div>{item.InterviewMedium}</div>
                <div>{item.InterviewDate}</div>
                

                <div className="panel-container-sub-item-email">
                  <Button
                    bg={item.IsEmailed ? "primary" : "secondary"}
                    title="Send Email"
                    icon={<AiOutlineMail />}
                    onClick={() => {
                      handleEmailClicked && handleEmailClicked(item);
                    }}
                  />
                </div>
              </div> */}
              </div>

              <div className="flex">
                <Button
                  bg={item.IsEmailed ? "primary" : "secondary"}
                  title="Send Email"
                  icon={"AiOutlineMail"}
                  onClick={() => {
                    handleEmailClicked && handleEmailClicked(item);
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  return renderCards();
}

export default InterviewCard;
