import React, { useEffect, useState } from 'react'
import './styles.scss';
import classnames from 'classnames';
import {FaUserFriends,FaYoutube,FaLock} from 'react-icons/fa'
import {BsClock} from 'react-icons/bs'
import {MdCheckCircle,MdPlayCircleFilled} from 'react-icons/md';
import { getCourse, getCoursePages, getEmployeeCourse, getCourseCompleted } from '../helpers/action';
import { useDispatch, useSelector } from 'react-redux';
import RouteEnum from 'constants/routeEnum';
import dayjs from 'dayjs';
var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)


function Courses(props) {
    console.log({props})
    const {id} = props.match.params;
    const dispatch = useDispatch();
    const {coursePages,course,courseCompleted} = useSelector((state:any)=>state.lmsReducer);
    const {user} = useSelector((state:any)=>state.auth);

    useEffect(()=>{
        if(id){
            dispatch(getEmployeeCourse(id,user?.EmployeeId))
            dispatch(getCoursePages(id))
            dispatch(getCourseCompleted(user?.EmployeeId,id))
        }
    },[id]);
    const [active, setActive] = useState(4)
    const onResume = () =>{
        if(courseCompleted && courseCompleted.length>0){
            const page = courseCompleted[courseCompleted.length - 1]
            props.history.push(RouteEnum.lmsCourse + '/' + id +'/pages/' + page.Id)
            return;
        }
        if(coursePages){
            const page = coursePages[0]
            props.history.push(RouteEnum.lmsCourse + '/' + id +'/pages/' + page.Id)
        }
    }
    return (
        <div className="course">
            <div className="course-container">
                <div className="course-header">
                    <div className="course-header__image">
                        {course?.ServerFileName && <img src={course?.ServerFileName} alt={course?.Title} />}
                    </div>
                    <div className="course-header__body">
                        <div className="course-header__title">
                            <span>{course?.Title}</span>
                        </div>
                        <div className="course-header__by">
                            <span>by</span>
                            <a >{course?.Author}</a>
                        </div>
                        <div className="course__tags">
                            <div className="course__tag">{course?.CategoryName}</div>
                        </div>
                        <div className="course-header__num">
                            <FaUserFriends />
                            <span className="bold-500">4</span>
                            <span>colleagues taking this course</span>
                        </div>
                        <div className="course-header__statuses">
                            <div className="course-header__status">
                                <BsClock />
                                <span className="bold-500">{!course.Duration ? 0 :course?.Duration} min</span>
                            </div>
                            <div className="course-header__status">
                                <FaYoutube />
                                <span className="bold-500">15 min</span>
                            </div>
                            <div className="course-header__status">
                                Due  <span className="ml-sm bold-500"> {dayjs(course.EndDate).format('ll')}</span>
                            </div>
                        </div>
                        <div className="course-header__action">
                            <button 
                                onClick={()=>onResume()}
                                className="button button-large  button-primary">Resume</button>
                            <div className="course-header__complete">{parseFloat(course.PercentComplete).toFixed(2)} %</div>
                        </div>
                    </div>
                </div>
                <div className="course-body">
                    <div className="course-body__container">
                        {/* <div className="course-status">
                            <div className="course-status__rankings">
                                <div className="course-status__rankings-title">YOUR RANKINGS</div>
                                <div className="course-status__rankings-place">
                                    <div className="course-status__rankings-rank">
                                        <span>#1</span>
                                        <span>Branch</span>
                                    </div>
                                    <div className="course-status__rankings-rank">
                                        <span>#3</span>
                                        <span>Organization</span>
                                    </div>
                                </div>
                            </div>
                            <div className="course-status__score">
                                <div className="course-status__score-text">
                                    <span>Your score 100/500 points (20%)</span>
                                </div>
                                <div className="course-status__score-position">
                                    <div className="course-status__score-point active"></div>
                                    <div className="course-status__score-point"></div>
                                    <div className="course-status__score-point"></div>
                                </div>
                            </div>
                        </div> */}
                        <div className="course__contain">
                            <div className="course__title">Description</div>
                            <div 
                                dangerouslySetInnerHTML={{__html:course?.Description}}
                                className="course__text mt-sm">
                                
                            </div>
                        </div>
                        {/* <div className="course-seperator"></div>
                        <div className="course__contain">
                            <div className="course__title">Course Leaderboard</div>
                            <div className="course__text">
                                Learn and provide correct answer to improve your rank!
                            </div>
                            <div className="course-leadership">
                                <div className="course-leadership__row">
                                    <div className="course-leadership__title">TOP Learners in your branch</div>
                                    <div className="course-leadership__person">
                                        <div className="course-leadership__image"></div>
                                        <div className="course-leadership__status">
                                            <span>Sumnima Shahi</span>
                                            <span>RANK: 1</span>
                                        </div>
                                    </div>
                                    <div className="course-leadership__person">
                                        <div className="course-leadership__image"></div>
                                        <div className="course-leadership__status">
                                            <span>Susmita Sen</span>
                                            <span>RANK: 1</span>
                                        </div>
                                    </div>
                                    <div className="course-leadership__person">
                                        <div className="course-leadership__image"></div>
                                        <div className="course-leadership__status">
                                            <span>Ghanashyam Giri</span>
                                            <span>RANK: 1</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="course-leadership__row">
                                    <div className="course-leadership__title">TOP Learners in your organization</div>
                                    <div className="course-leadership__person">
                                        <div className="course-leadership__image"></div>
                                        <div className="course-leadership__status">
                                            <span>Sumnima Shahi</span>
                                            <span>RANK: 1</span>
                                        </div>
                                    </div>
                                    <div className="course-leadership__person">
                                        <div className="course-leadership__image"></div>
                                        <div className="course-leadership__status">
                                            <span>Susmita Sen</span>
                                            <span>RANK: 1</span>
                                        </div>
                                    </div>
                                    <div className="course-leadership__person">
                                        <div className="course-leadership__image"></div>
                                        <div className="course-leadership__status">
                                            <span>Ghanashyam Giri</span>
                                            <span>RANK: 1</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="course-body__sidebar">
                        <div className="course-body__sidebar-title">Outline</div>
                            <ul className="course-body__sidebar-list">
                                <li className="course-body__sidebar-item">
                                    {/* <MdCheckCircle /> */}
                                    <MdPlayCircleFilled />
                                    <span>Introduction</span>
                                </li>
                                {
                                    coursePages && coursePages.map(item=>{
                                        
                                        return(
                                            <li 
                                                onClick={()=>props.history.push(RouteEnum.lmsCourse + '/' + item.CourseId +'/pages/' + item.Id)}
                                                className="course-body__sidebar-item">
                                                <div className="course-body__sidebar-circle"></div>
                                                <span>{item.PageName}</span>
                                            </li>
                                        )
                                    })
                                }
                                {/* <li className="course-body__sidebar-item">
                                    <MdPlayCircleFilled />
                                    <span>What is User Experience</span>
                                </li>
                                <li className="course-body__sidebar-item">
                                    <div className="course-body__sidebar-circle"></div>
                                    <span>What is User Experience</span>
                                </li>
                                <li className="course-body__sidebar-item">
                                    <FaLock />
                                    <span>UI vs UX</span>
                                </li>
                                <li className="course-body__sidebar-item">
                                    <FaLock />
                                    <span>Final Quiz</span>
                                </li>
                                <li className="course-body__sidebar-item">
                                    <FaLock />
                                    <span>Certificate</span>
                                </li> */}
                            </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Courses
