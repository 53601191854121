import React, { Component } from "react";
import "./icons.styles.scss";

export const trash = () => {
  return (
    <svg
      width="442"
      height="512"
      viewBox="0 0 442 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="garbage 1">
        <g id="Group 1">
          <g id="bottom">
            <g id="box">
              <g id="shades">
                <path
                  id="Vector"
                  d="M354.917 219.69H86.6425C80.1599 219.69 75.0165 225.282 75.4644 231.768L96.0313 481.322C97.3727 498.546 111.905 511.966 129.342 511.966H312.217C329.654 511.966 344.187 498.546 345.528 481.322L366.095 231.768C366.543 225.282 361.399 219.69 354.917 219.69Z"
                  fill="#C8C6CD"
                />
                <path
                  id="Vector_2"
                  d="M366.095 231.768L345.528 481.322C344.187 498.546 329.654 511.966 312.217 511.966H220.78V219.69H354.917C361.399 219.69 366.543 225.282 366.095 231.768Z"
                  fill="#AEADB3"
                />
              </g>
              <g id="lines">
                <path
                  id="Vector_3"
                  d="M231.958 275.61V433.678C231.958 441.06 226.369 444.862 220.78 444.862C215.191 444.862 209.602 441.06 209.602 433.678V275.61C209.602 268.228 215.191 264.426 220.78 264.426C226.369 264.426 231.958 268.228 231.958 275.61Z"
                  fill="#AEADB3"
                />
                <path
                  id="Vector_4"
                  d="M276.67 433.678V275.61C276.67 260.868 299.026 260.807 299.026 275.61V433.678C299.026 448.421 276.67 448.481 276.67 433.678Z"
                  fill="#8B8892"
                />
                <path
                  id="Vector_5"
                  d="M142.533 433.678V275.61C142.533 260.868 164.889 260.807 164.889 275.61V433.678C164.889 448.421 142.533 448.481 142.533 433.678Z"
                  fill="#AEADB3"
                />
                <path
                  id="Vector_6"
                  d="M232.824 275.77V433.837C232.824 441.22 227.234 445.021 221.645 445.021V264.586C227.234 264.586 232.824 268.388 232.824 275.77Z"
                  fill="#8B8892"
                />
              </g>
            </g>
          </g>
          <g id="cap">
            <g id="handle">
              <path
                id="Vector_7"
                d="M253.832 128.163L189.858 128.105C172.158 128.088 157.858 142.369 157.842 160.079L157.822 181.415C157.817 187.388 162.503 192.087 168.475 192.093L275.098 192.191C281.069 192.196 285.764 187.506 285.77 181.533L285.789 160.197C285.806 142.487 271.532 128.18 253.832 128.163ZM264.455 170.845L179.156 170.767L179.166 160.099C179.172 154.124 183.867 149.435 189.838 149.44L253.812 149.499C259.784 149.505 264.47 154.202 264.465 160.177L264.455 170.845Z"
                fill="#AEADB3"
              />
              <path
                id="Vector_8"
                d="M285.788 160.194L285.769 181.529C285.764 187.503 281.069 192.193 275.097 192.188L221.786 192.14L221.806 170.804L264.454 170.842L264.464 160.175C264.469 154.2 259.783 149.502 253.811 149.497L221.825 149.468L221.844 128.133L253.83 128.162C271.53 128.177 285.804 142.484 285.788 160.194Z"
                fill="#8B8892"
              />
            </g>
            <g id="capped">
              <path
                id="Vector_9"
                d="M348.949 180.796L93.0537 180.561C75.3535 180.544 61.0536 194.826 61.0373 212.535L61.0177 233.871C61.0122 239.846 65.6986 244.543 71.6702 244.549L370.215 244.823C376.187 244.829 380.882 240.14 380.887 234.165L380.907 212.829C380.923 195.119 366.649 180.812 348.949 180.796Z"
                fill="#E5E5E5"
              />
              <path
                id="Vector_10"
                d="M380.907 212.824L380.888 234.16C380.883 240.134 376.188 244.823 370.216 244.818L220.944 244.684L221.001 180.676L348.949 180.791C366.649 180.807 380.923 195.114 380.907 212.824Z"
                fill="#D9D9D9"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
