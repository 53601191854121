import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { JobStages } from "../../../../../enums";
import useEventListener from "helpers/customHooks/useEventListener";
// import usePortal from "helpers/customHooks/usePortal";
// import { createPortal } from "react-dom";
import classnames from "classnames";
import { connect, useSelector } from "react-redux";
import SvgIcons from "components/icons/svgIcons";
import {
  FaHandPaper,
  FaTrashAlt,
  FaBan,
  FaArrowAltCircleRight,
  FaAngleRight,
  FaAngleLeft,
} from "react-icons/fa";
import JobStageIcon from "scenes/recruit/common/jobStageIcon";
import CustomPopup from "components/common/customPopup";

const initialState = {
  top: null,
  left: null,
};
const Action = function (props: any) {
  const dropRef = useRef(null);
  const dropDownRef = useRef(null);
  const [state, setState] = useState(initialState);
  const [drop, setDrop] = useState(false);
  const {
    data,
    pageIndex,
    rowsPerPage,
    seletedJobStage,
    // hiringStagesJobId,
    hiringStageAndSubStagesJobId,
    rowIndex,
  } = props || {};

  useEffect(() => {
    if (dropRef.current) {
      const dropComp = dropRef.current;
      const { top, left, width, height, ...rest } =
        dropComp && dropComp.getBoundingClientRect();

      // setState((prev) => ({ ...prev, top, left }))
    }
  }, [dropRef.current]);

  const [stages, setStages] = useState([]);
  useEffect(() => {
    if (
      props.hiringStageAndSubStagesJobId &&
      props.hiringStageAndSubStagesJobId.value
    ) {
      setStages(props.hiringStageAndSubStagesJobId.value);
    }
  }, [props.hiringStageAndSubStagesJobId]);

  const hiringStagesJobId = useSelector(
    (state: any) => state.recruitment_dashboardReducer.hiringStagesJobId
  );

  const style: CSSProperties = {
    top: state.top,
    left: state.left,
    position: "fixed",
    zIndex: 111,
  };
  const closeDropDowns = () => {
    var elems = document.querySelectorAll(".drop-active");
    [].forEach.call(elems, function (el) {
      el.classList.remove("drop-active");
    });
  };
  // const Portal = ({ id, children }) => {
  //   const target = usePortal(id);
  //   return createPortal(children, target);
  // };

  const handleRejectedMessage = (data) => {
    props.setRejectModal(true);
    props.setCandiateId(data.CandidateId);
  };

  const handleDisqualifiedData = (data) => {
    props.setDisqualifyModal(true);
    props.setCandiateId(data.CandidateId);
    props.setJobId(data.JobId);
  };

  const handleClickOutside = (event) => {
    let target = (event && event.target && event.target.className) || "";
    let isDropDown =
      (target && target.includes && target.includes("clickable")) || false;

    if (!isDropDown) {
      setDrop(false);
    }
  };
  useEventListener("mousedown", handleClickOutside);

  const onChangeJobStage = async (stage = 0, obj, HiringSubStageId = null) => {
    let data = {
      jobId: obj.JobId,
      pageIndex: pageIndex,
      pageSize: rowsPerPage,
      candidateId: obj.CandidateId,
      currentStageId: seletedJobStage,
      stageId: stage,
      subStageId: HiringSubStageId,
    };

    closeDropDowns();
    await props.updateMultipleCandidateDetails(data, props.history);
    // onChangeJobStage(null, list[currentIndex + 1], CandidateId, JobId);
  };
  const onMoveToNextStage = async (stage = 0, obj) => {
    let data = {
      jobId: obj.JobId,
      pageIndex: pageIndex,
      pageSize: rowsPerPage,
      candidateId: obj.CandidateId,
      currentStageId: seletedJobStage,
      stageId: stage,
    };
    await props.moveCandidateToNextStage(data, props.history);
  };
  const onDelete = async (stage = 0, obj) => {
    let data = {
      jobId: obj.JobId,
      pageIndex: pageIndex,
      pageSize: rowsPerPage,
      candidateId: obj.CandidateId,
      currentStageId: seletedJobStage,
      stageId: stage,
    };
    await props.SetAsDelete(data, props.history);
  };

  //   const handleScroll = (e) => {
  //     if (dropRef.current) {
  //       const dropComp = dropRef.current;
  //       const { top, left, width, height } =
  //         dropComp && dropComp.getBoundingClientRect();
  //       setState((prev) => ({ ...prev, top, left }));
  //     }
  //   };
  //   const horizontalScroll: any = document.querySelector(
  //     ".ag-center-cols-viewport"
  //   );

  const getIconByCurrentStage = (stage) => {
    let iconName: any = "angleRight";
    if (data.CandidateStatusId === stage) {
      iconName = "arrowCircleRight";
    } else if (data.CandidateStatusId > stage) {
      iconName = "angleLeft";
    }
    return (
      <span>
        <SvgIcons name={iconName} color="#3a3d43" size="17px" />
      </span>
    );
  };

  if (data.IsRejected) {
    return (
      <button
        onClick={() => handleRejectedMessage(data)}
      // className="btn-rounded"
      >
        <span>Undo Rejected</span>
      </button>
    );
  }

  const isDisqualified = () => {
    return (
      <button onClick={() => handleDisqualifiedData(data)}>
        <span>Undo Disqualified</span>
      </button>
    );
  };

  return (
    <div>
      {data.CandidateStatusId === JobStages.Disqualified ? (
        isDisqualified()
      ) : (
        <LinkDropDown
          data={data}
          onMoveToNextStage={onMoveToNextStage}
          onChangeJobStage={onChangeJobStage}
          onDelete={onDelete}
          stages={stages}
          {...props}
        />
      )}
    </div>
  );
};

const LinkDropDown = (props) => {
  const onChangeJobStage = async (stage = 0, obj, HiringSubStageId = null) => {
    let data = {
      jobId: obj.JobId,
      pageIndex: 0,
      pageSize: 10,
      candidateId: obj.CandidateId,
      currentStageId: 0,
      stageId: stage,
      subStageId: HiringSubStageId,
    };

    await props.updateMultipleCandidateDetails(data, props.history);
    // onChangeJobStage(null, list[currentIndex + 1], CandidateId, JobId);
  };

  const { data, onMoveToNextStage, stages } = props;
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  return (
    <div className="link-dropdown">
      {JobStages.Hired === data.CandidateStatusId ? (
        <div className="link-dropdown-link">
          <span>Start onboarding</span>
        </div>
      ) : (
        <div
          className="link-dropdown-link"
          onClick={() => onMoveToNextStage(0, data)}
          ref={ref}
        >
          <span>Move to next stage</span>
        </div>
      )}
      <div className="link-dropdown-dropdown">
        <div
          className="drop-icon"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(!open);
          }}
        >
          <FiChevronDown />
        </div>
        {ref && ref.current && open && (
          <CustomPopup
            parentRef={ref}
            open={open}
            handleOutsideClick={() => setOpen(false)}
          >
            <div className="drop-content">
              <ul className="drop-list">
                <li
                  className="drop-list__item with-flex  drop-list-hover clickable"
                  onClick={() => onChangeJobStage(JobStages.Revert, data)}
                >
                  <JobStageIcon stageId={JobStages.Applied} />
                  Applied
                </li>
                {
                  // dropStages.filter(stage=>seletedJobStage !==JobStages.Applied ?stage.value > seletedJobStage:stage.value >0)

                  stages.map((stage) => (
                    <li
                      // onClick={()=>onChangeJobStage(null,{value:stage.Key},CandidateId,JobId)}

                      className={classnames({
                        "drop-list__item": true,
                        "drop-list-hover": stage.SubStages.length === 0,
                      })}
                    // className="drop-list__item drop-list-hover"
                    >
                      {stage.SubStages.length === 0 ? (
                        <div
                          className="with-flex clickable"
                          onClick={() => onChangeJobStage(stage.StageId, data)}
                        >
                          <JobStageIcon stageId={stage.StageId} />

                          {stage.Name}
                        </div>
                      ) : (
                        <div>
                          <div>
                            <JobStageIcon stageId={stage.StageId} />

                            {stage.Name}
                          </div>
                          <ul>
                            {stage.SubStages.map((subStage) => (
                              <li
                                className="drop-list__item-sub"
                                onClick={() =>
                                  onChangeJobStage(
                                    subStage.StageId,
                                    data,
                                    subStage.HiringSubStageId
                                  )
                                }
                              >
                                <div className="flex clickable">
                                  <JobStageIcon stageId={stage.StageId} />

                                  {subStage.Name}
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </li>
                  ))
                }
                {/* {
            dropStages.filter(stage=>seletedJobStage !==JobStages.Applied ?stage.value < seletedJobStage:stage.value <0)
                      .map(stage=><li   
                        onClick={()=>onChangeJobStage(null,stage,CandidateId,JobId)}  
                        className="drop-list__item">{stage.label}</li>)
          } */}
                <li className="drop-list__item-hr"></li>

                {data.CandidateStatusId === JobStages.Revert ||
                  data.CandidateStatusId === JobStages.Applied ? (
                  <li
                    className="drop-list__item with-flex clickable"
                    onClick={() =>
                      onChangeJobStage(JobStages.Disqualified, data)
                    }
                  >
                    <span>
                      <FaBan />
                    </span>
                    Disqualified
                  </li>
                ) : (
                  <li
                    className="drop-list__item with-flex drop-list-hover clickable"
                    onClick={() => {
                      props.handleRejectClick(data.JobId, [data]);
                    }}
                  >
                    <span>
                      <FaHandPaper />
                    </span>
                    Reject
                  </li>
                )}
                <li className="drop-list__item with-flex drop-list-hover clickable"
                  onClick={() => props.onDelete(0, data)}>
                  <span>
                    <FaTrashAlt />
                  </span>
                  Delete
                </li>
              </ul>
            </div>
          </CustomPopup>
        )}
      </div>
    </div>
  );
  // </Portal>
};

const mapStateToProps = (state) => ({
  hiringStageAndSubStagesJobId:
    state.recruitment_dashboardReducer.hiringStageAndSubStagesJobId,
});

export default connect(mapStateToProps, {})(Action);
