import React, { Component } from "react";
import { FaCalendarAlt, FaPlus } from "react-icons/fa";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Loader from "components/loaders";
import isEmpty from "isEmpty";
import {
  CreateNewJobRequisitionPath,
  MyRequistionRequestPath,
  RequistionRequestPath,
} from "routes/commonLinks";
import "scenes/travel/travelRequest.scss";
import {
  BS2AD,
  AD2BS,
  englishMonthNames,
  nepaliMonthNames,
} from "helpers/dateConvert";
import Breadcrumb from "scenes/common/breadcrumb";
import { Nepali_Date_Format } from "helpers/dateFormat";
import { FetchJobRequisitionData } from "store/actions/recruitment/common";
class CommonHeaderSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeName: "",
      branches: [{ label: "one", value: 1 }],
      tabs: [
        { id: 1, name: "Requistion Requests", link: RequistionRequestPath },
        {
          id: 2,
          name: "My Requistion Requests",
          link: MyRequistionRequestPath,
        },
        { id: 3, name: "Hiring Process", link: "" },
        { id: 4, name: "History", link: "" },
      ],
      selectedBranch: null,
      selectedTabId: !isEmpty(props.selectedTabId) ? props.selectedTabId : 0,
      commingMonths: [],
    };
  }
  componentDidMount() {
    this.props.FetchJobRequisitionData();
    this.getCommingMonths();
  }

  getCommingMonths = () => {
    let isNepali = !this.props.companyInformation.IsEnglishDate;
    let d = new Date();
    let month = null;
    let commingMonths = [];

    try {
      if (isNepali) {
        const nepaliFormatedDate = Nepali_Date_Format(d);
        let nepaliDate = AD2BS(nepaliFormatedDate).en;
        month = nepaliDate.month;
        commingMonths.push({
          label: nepaliMonthNames[month],
          value: month,
        });
        commingMonths.push({
          label: nepaliMonthNames[month + 1],
          value: month + 1,
        });
      } else {
        let month = d.getMonth();
        commingMonths.push({
          label: englishMonthNames[month + 1],
          value: month + 1,
        });
        commingMonths.push({
          label: englishMonthNames[month + 2],
          value: month + 2,
        });
      }
      this.setState({ commingMonths });
    } catch (ex) {}
  };

  handleText = (e) => {
    const { name, value } = e && e.target;
    this.setState({ [name]: value });
    this.props.handleSearchTextChange &&
      this.props.handleSearchTextChange(value);
  };

  handleDropdown = (name, selected) => {
    this.setState({ [name]: selected });
    let branchId = -1;
    if (!isEmpty(selected)) {
      branchId = selected.value;
    }
    this.props.handleBranchChange && this.props.handleBranchChange(branchId);
  };

  // handleTab = e => {
  //   const { id } = e.target;
  //   this.setState({ selectedTabId: parseInt(id) });
  // };

  render() {
    const { tabs, commingMonths, selectedTabId } = this.state;
    const { spinnerLoading } = this.props;
    const { requisitionListCount } = this.props.jobRequisitionReducer;
    return (
      <div className="common-container">
        <Breadcrumb name="Job Requisition" />
        <div className="travel">
          <div className="travel-requestMainHeading">
            <div className="flex top-bar">
              <NavLink
                to={{
                  pathname: CreateNewJobRequisitionPath,
                  state: { selectedTabId: -1 },
                }}
                // className={"btn btn-grey-2 btnTravelRequest"}
                activeClassName="active-btn"
                // className={selectedTabId == -1 ? " selectedBorderBottom" : ""}
              >
                <button className={"btn btn-grey-2 btnTravelRequest"}>
                  <FaPlus /> <span>New Job Requistion</span>
                </button>
              </NavLink>
              <div className="travel-month">
                <FaCalendarAlt />
              </div>
              {commingMonths &&
                commingMonths.map((month, index) => {
                  if (!month.label) {
                    return null;
                  }
                  return (
                    <div className="travel-month" key={index}>
                      {month.label}
                    </div>
                  );
                })}
              {/* <div className="travel-month current">Mangsir</div> */}
              <div className="travel-tabs">
                <ul className="travel-tabs__list">
                  {tabs &&
                    tabs.map((data, index) => {
                      return (
                        <NavLink
                          to={{
                            pathname: data.link,
                            state: { selectedTabId: data.id },
                          }}
                          className={
                            "travel-tabs__item" +
                            (selectedTabId == data.id
                              ? " selectedBorderBottom"
                              : "")
                          }
                          key={index + Math.random()}
                        >
                          {data.name}
                          <span className="badge badge-primary">
                            {data.id == 1
                              ? requisitionListCount.TotalRows
                              : data.id == 2
                              ? requisitionListCount.MyTotalRows
                              : data == 3
                              ? 0
                              : 0}
                          </span>
                        </NavLink>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>

          <div className="travel-requestMainBody">{this.props.renderBody}</div>
        </div>
        <Loader open={spinnerLoading} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  spinnerLoading: state.jobRequisitionReducer.spinnerLoading,
  jobRequisitionReducer: state.jobRequisitionReducer,
  companyInformation: state.commonReducer.companyInformation,
});

export default connect(mapStateToProps, { FetchJobRequisitionData })(
  CommonHeaderSection
);
