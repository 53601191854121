import React from 'react'
import PieChart from 'components/charts/pieChart';
import { useSelector } from 'react-redux';
import {pieChartValue} from 'helpers/getTextLabel'
import isEmpty from 'isEmpty'

function LeavePieChart({innerRadius,type}) {
    const leaveTimeDashboard = useSelector(state => state.leaveTimeReducer.leaveTimeDashboard );
    // const {[type]:type}  = leaveTimeDashboard;
    const data = leaveTimeDashboard[type];
    let extra = null;
    if(type==="UnusedLeave"){
        let carry = 0 ;
        let lapse = 0;
        data.map(item=>{
            carry += item.CarryForward;
            lapse += item.Lapse
        });
        extra = (
            <div className="unused-data">
                <div className="unused-carry">
                    <span>
                        <span className="text-blue">{carry}</span>
                        CFWD
                    </span>
                </div>
                <div className="unused-lapse">
                    <span>
                        <span className="text-pink">{lapse}</span>
                        LAPSE
                    </span>
                </div>
            </div>

        )
    }
    if(isEmpty(data)){
        return <span className="no-found">No Data Found</span>
    }
    return (
        <div className="leave-chart">
            <PieChart extraComponent={extra} innerRadius={innerRadius} data={pieChartValue(data)} />
            {extra}
        </div>
    )
}

export default LeavePieChart
