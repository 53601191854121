export default function baseEnv(baseApi) {
  const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href") as string;
  return {
    route: {
      baseRoute: baseUrl, // Fixes issue with Github Pages
    },

    api: {
      //Common Private
      getBranchDetails: "/v1/common/branch-detail",
      getDepartmentDetails: "/v1/common/department-detail",
      commonUpload: "/v1/common/shared/UploadFiles",

      //LMS
      getCourseEnrollment: "/v1/lms/course-enrollments",
      getCourseNavigationPolicies: "/v1/lms/course-navigation-policies",
      getShowCoursesTo: "/v1/lms/course-show-courses-to",
      courses: "/v1/lms/courses",
      courseDetails: "/v1/lms/course-detail",
      coursePages: "/v1/lms/course-pages",
      courseMultiplePages: "/v1/lms/multiple-course-pages",
      courseList: "/v1/lms/course-list",
      updateCourseState: "/v1/lms/courses/change-status",
      courseView: "/v1/lms/course-views",
      courseCategories: "/v1/lms/course-categories",
      courseOptions: "/v1/lms/course-options",
      courseOutlines: "/v1/lms/course-outlines",
      courseContent: "/v1/lms/course-contents",
      courseQuestions: "/v1/lms/course-questions",
      getQuestionDetails: "/v1/lms/course-questions/details",
      getQuestionAnswers: `/v1/lms/course-questions-answers`,
      addAnswers: `/v1/lms/employee-answers`,
      courseCompleted: `/v1/lms/employee-completed-page`,
      assignCourses: "/v1/lms/assign-courses",
      assignCoursesDashboard: "/v1/lms/assign-courses/dashboard",
      coursesDashboard: "/v1/lms/courses/dashboard",

      // travel request
      advanceTravelRequest: "/v1/travel/settlements/advancereport",
      advanceTravelCategoryList: "/v1/travel/travel-categories/list",

      // sbi loans
      getConsumerLoanPurposeList: "/v1/sbiloan/purposes",
      // getConsumerLoanPurposeList: "/v1​/sbiloan​/settings​/",
      getLoanSettingsByType: "/v1/sbiloan/settings",
      saveConsumerLoanRequest: "/v1/sbiloan/consumerloan",
      draftConsumerLoanRequest: "/v1/sbiloan/consumerloan/draft",
      getAppliedLoanList: "/v1/sbiloan/requests-list/",
      getEmployeeInfo: "/v1/sbiloan/employee-info/",
      calculatePaymentSchedule: "/v1/sbiloan/payment-schedule",
      generateIncomeDeductionList: "/v1/sbiloan/incomededuction-list/",
      deleteConsumerLoan: "/v1/sbiloan/consumer/",
      loanApproverList: "/v1/sbiloan/settings/approvers",
      getConsumerLoanDetails: "/v1/sbiloan/consumerloan/",
      getOutstandingLoans: "/v1/sbiloan/outstanding-loans",
      getSbiLoanTypes: "/v1/sbiloan/types",
      getLoanCommentsHistory: "/v1/sbiloan/loan-status-histories-detail/list",
      // saveOutstandingLoan: "/v1/sbiloan/outstanding-loans/",
      // deleteOutstandingLoan: "/v1/sbiloan/outstanding-loans/",
      saveSocialLoanRequest: "/v1/sbiloan/social-loan",
      draftSocialLoanRequest: "/v1/sbiloan/social-loan/draft",
      deleteSocialLoan: "/v1/sbiloan/social/",
      getSocialLoanDetails: "/v1/sbiloan/social-loan/",

      // sbi loan setting
      sbiLoanSettingList: "/v1/sbiloan/settings-list",
      sbiLoanTypeList: "/v1/sbiloan/settings-details/",
      sbiGeneralLoanSave: "/v1/sbiloan/settings",
      sbiSettingAmountLimit: "/v1/sbiloan/settings-amountlimit",
      sbiSettingApprovalFlow: "/v1/sbiloan/settings-approvalflow",
      employeeListForSbiLoan: "/v1/common/employees",
      sbiLoanSettingsApprovers: "/v1/sbiloan/settings/approvers",

      // sbi loan status history
      sbiLoanStatusHistory: "/v1/sbiloan/loan-status-histories/list",
      sbiLoanTypes: "/v1/sbiloan/types",

      //Roster Schedule
      roosterShift: "v1/leave-time/shift",
      roosterShiftList: "v1/leave-time/shift/list",
      roosterShiftArchive: "v1/leave-time/shift/archive",
      roosterShiftListDown: "v1/leave-time/shift/dropdown-list/team",

      roosterShiftPattern: "/v1/leave-time/shift-pattern",
      roosterShiftPatternList: "/v1/leave-time/shift-pattern/list",
      roosterShiftPatternDetail: "/v1/leave-time/shift-pattern/detail",
      roosterShiftPatternDetailMaster: "v1/leave-time/shift-pattern/details-by-master",

      roosterLocation: "/v1/leave-time/location",
      roosterLocationList: "/v1/leave-time/location/list",

      roosterSchedule: "/v1/leave-time/schedule",
      roosterScheduleCopy: "/v1/leave-time/schedule/copy",
      roosterScheduleList: "/v1/leave-time/schedule/list",
      roosterScheduleAllEmployeeList: "/v1/leave-time/schedule-all-employee/list",
      roosterScheduleEmployeeList: "/v1/leave-time/schedule-employee/list",
      roosterScheduleEmployeeListWithWorkingHourDetail: "/v1/leave-time/schedule-employee/list-with-working-hours-detail",
      roosterScheduleEmployee: "/v1/leave-time/schedule-employee",
      roosterScheduleRemoveEmployee: "/v1/leave-time/schedule-employee/remove-employee/",

      roosterTeam: "/v1/leave-time/team",
      roosterTeamList: "/v1/leave-time/team/list",
      roosterTeamEmployee: "/v1/leave-time/team-employee",
      roosterTeamRole: "/v1/leave-time/team-role",
      roosterTeamRoleList: "/v1/leave-time/team-role/list",

      forwardSbiLoan: "/v1/sbiloan/forward",
      rejectSbiLoan: "v1/sbiloan/reject",

      // task tracer project listing
      taskTrackerPorjectDetailsList: "v1/tt/projects/details-list",
      taskTrackerProjectTypes: "v1/tt/client-types",
    },
    isProduction: true,
    isDevelopment: false,
    isStaging: false,
    isTesting: false,
  };
}
