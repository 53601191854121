import React, {useEffect} from 'react'
import FormGroup from "components/form";
import Button from "components/button";
import Icons from "components/icons";
import { GetBranches, GetDepartments } from "actions/commonAction";
import isEmpty from 'isEmpty';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import DatePickerAsPerSetting from "components/customdaterange/datepickerAsPerSetting";
import {
    GetOverTimeTypes,
  } from "actions/commonAction";

function Filters(props) {
    let {branchList,departmentList,overTimeTypes} = useSelector((state:any)=>state.commonReducer);
    
    const dispatch = useDispatch();

    useEffect(()=>{
        if(isEmpty(branchList)){
            dispatch(GetBranches())
        }
        if(isEmpty(departmentList)){
            dispatch(GetDepartments())
        }
        if(isEmpty(overTimeTypes)){
            dispatch(GetOverTimeTypes())
        }
    },[]);
    return (
        <div className="filters mt-sm mb-sm">
            <div className="filters-grid">
                <FormGroup
                    name="employeeName"
                    className="search"
                    placeholder="Search Employee"
                    type="search"
                    value={props.employeeName}
                    onChange={props.handleAutoSelect}
                />
            </div>
            <div className="filters-grid">
                <FormGroup
                    formName="reactselect"
                    onChange={props.handleAutoSelect}
                    name="overtimeType"
                    value={props.overtimeType}
                    options={overTimeTypes}
                    label="Overtime type"
                />
            </div>
            <div className="filters-grid">
                <FormGroup
                    formName="reactselect"
                    onChange={props.handleAutoSelect}
                    value={props.branches}
                    name="branch"
                    options={branchList}
                    label="Branch"
                    // width="200px"
                />
            </div>
            <div className="filters-grid">
                <FormGroup
                    formName="reactselect"
                    onChange={props.handleAutoSelect}
                    value={props.departments}
                    options={departmentList}
                    name="department"
                    label="Department"
                    // width="200px"
                />
            </div>
            <div className="filters-grid">
            <DatePickerAsPerSetting
                onChange={(name, range) => props.handleAutoSelect(name, range)}
                value={{
                    startDate: !isEmpty(props.startDate) ? props.startDate : "",
                    endDate: !isEmpty(props.endDate) ? props.endDate : "",
                }}
                placeholder="Date Range"
                width="250px"
                name="dateRange"
                isDateRange
            />
            </div>
        </div>
    )
}

export default Filters
