import React, { useEffect, useState } from "react";
import AgGridTable from "components/table/agGridTable";
import FavoriteCell from "./cells/favorite";
import CurrentStateCell from "./cells/currentState";
import CandidateNameCell from "./cells/candidateName";
import ActionCell from "./cells/action";
import CommonPagination from "components/table/pagination";
import ExperieceCell from "./cells/experiece";

const columnDefs = [
  // { checkboxSelection: true, width: 40 },
  {
    rowMultiSelectWithClick: true,
    checkboxSelection: true,
    filter: false,
    width: 30,
  },
  {
    field: "Favorite",
    headerName: "",
    width: 50,
    cellRenderer: "favoriteCell",
  },
  {
    field: "CandidateName",
    headerName: "Name",
    width: 150,
    cellRenderer: "candidateNameCell",
  },
  { field: "HighestDegree", headerName: "Education", width: 130 },
  { field: "Experience", width: 100, cellRenderer: "ExperieceCell" },
  { field: "Location", headerName: "Location", width: 120 },
  { field: "Age", headerName: "Age", width: 60 },
  { field: "Salary", width: 80 },
  { field: "Tags", width: 70 },
  {
    field: "CurrentState",
    width: 150,
    cellRenderer: "CurrentStateCell",
  },
  {
    width: 200,
    cellRenderer: "actionCell",
  },
];
function Table(props: any) {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    const selectedCandidates = props.selectedCandidates || [];
    if (gridApi) {
      if (selectedCandidates.length > 0) {
        gridApi.forEachNode(function (node) {
          console.log("selectedCandidates", selectedCandidates.length);

          selectedCandidates.forEach((el) => {
            var index = rowData.findIndex(
              (x) => x.CandidateId == el.CandidateId
            );
            if (node.rowIndex === index) {
              node.setSelected(true);
            }
          });
        });
      }
    }
    console.log("table", props.selectedCandidates);
  }, [rowData]);

  const {
    data,
    handleRowChange,
    handleIndexChange,
    hiringStagesJobId,
    onRowSelected,
    pageIndex,
    rowsPerPage,
  } = props;

  const frameworkComponents = {
    CurrentStateCell,
    ExperieceCell,
    favoriteCell: (rowProps: any) => <FavoriteCell {...rowProps} {...props} />,
    candidateNameCell: (rowProps: any) => (
      <CandidateNameCell {...rowProps} {...props} />
    ),
    actionCell: (rowProps: any) => (
      <ActionCell
        {...rowProps}
        name="Shakir"
        {...props}
        pageIndex={pageIndex}
      />
    ),
  };
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  useEffect(() => {
    if (props.candidateTableViewDetails) {
      setRowData(props.candidateTableViewDetails);
    }
  }, [props.candidateTableViewDetails]);
  return (
    <>
      <AgGridTable
        columnDefs={columnDefs}
        rowData={rowData}
        frameworkComponents={frameworkComponents}
        onGridReady={onGridReady}
        // defaultColDef={{
        //   flex: 1,
        //   wrapText: true,
        //   autoHeight: true,
        //   sortable: true,
        //   resizable: true,
        // }}
        rowSelection={"multiple"}
        rowMultiSelectWithClick
        rowHeight={75}
        suppressRowClickSelection={true}
        onSelectionChanged={onRowSelected}
      />

      {rowData && rowData.length > 0 && (
        <CommonPagination
          handleRowChange={handleRowChange}
          handleIndexChange={handleIndexChange}
          pageIndex={pageIndex}
          rowsPerPage={rowsPerPage}
          total={rowData[0].TotalRows}
        />
      )}
    </>
  );
}
export default Table;
