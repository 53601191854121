import React, { useState, useEffect } from "react";
import "react-circular-progressbar/dist/styles.css";
import isEmpty from "isEmpty";
import classnames from "classnames";
import useOutsideClick from "helpers/outsideClickHook";
import TaskSelectPopup from "./taskSelectPopup";
import { Props } from "./interface";
import Header from "./component/header";
import RowBody from "./component/rowBody";

import TeamCell from "../table/component/customCell/teamCell";
import PriorityCell from "../table/component/customCell/priorityCell";

import LabelCell from "./component/customCell/labelCell";
import CustomFields from "scenes/tasks/components/customFields";
import DueDateCell from "./component/customCell/dueDateCell";
import CustomFieldCell from "./component/customCell/customFieldCell";
import {
  getTaskDetails,
  addTaskDetails,
  updateTaskDetails,
} from "../../../../helpers/action";
import { connect } from "react-redux";
function Task(props: Props) {
  const { title, tasks, theme, onTaskClicked, stage, board } = props;
  const [cols, setCols] = useState([]);
  const [openCustomFiled, setOpenCustomFiled] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedTask, setSelectedTask] = React.useState([]);
  const [expand, setExpand] = React.useState(true);
  const [clear, setClear] = React.useState(false);
  const ref = React.useRef(null);

  useEffect(() => {
    if (props.board) {
      let columns: any = [
        {
          headerName: "Team",
          field: "team",
          className: "td td-team",
          iconName: "team",
          cellRenderer: (props) => <TeamCell {...props} />,
        },
        {
          headerName: "Label",
          field: "",
          className: "td td-label",
          iconName: "status",
          cellRenderer: (props) => <LabelCell {...props} />,
          hide: false,
        },

        {
          headerName: "Priority",
          field: "Priority",
          className: "td td-priority",
          iconName: "priority",
          cellRenderer: (props) => <PriorityCell {...props} />,
        },
        {
          headerName: "Due Date",
          field: "DueDate",
          className: "td",
          iconName: "dueDate",
          hide: true,
          optional: true,
          cellRenderer: (props) => <DueDateCell {...props} />,
        },
      ];
      if (!isEmpty(board.FieldLabel1))
        columns.push({
          headerName: board.FieldLabel1,
          field: "CustomField1",
          iconName: "brackets",
          className: "td",
          hide: true,
          optional: true,
          cellRenderer: (props) => <CustomFieldCell {...props} />,
        });

      if (!isEmpty(board.FieldLabel2))
        columns.push({
          headerName: board.FieldLabel2,
          field: "CustomField2",
          className: "td",
          iconName: "brackets",
          hide: true,
          optional: true,
          cellRenderer: (props) => <CustomFieldCell {...props} />,
        });
      if (!isEmpty(board.FieldLabel3))
        columns.push({
          headerName: board.FieldLabel3,
          field: "CustomField3",
          className: "td",
          iconName: "brackets",
          hide: true,
          optional: true,
          cellRenderer: (props) => <CustomFieldCell {...props} />,
        });
      setCols(columns);
    }
  }, [board]);

  useOutsideClick(ref, () => setOpen(false));
  const taskBlockClass = classnames({
    taskBlock: true,
    ["taskBlock-" + theme]: true,
  });
  const onTaskSelect = (task) => {
    if (isEmpty(selectedTask)) {
      setSelectedTask([task]);
    } else {
      setSelectedTask([task, ...selectedTask]);
    }
  };
  const onTaskRemove = (task) => {
    setSelectedTask((item) => {
      const a = item.filter((tk) => tk.TaskId !== task.TaskId);
      return a;
    });
  };
  const handleUpdateTaskDetail = (task) => {};

  return (
    <div className="taskBlock-container">
      <div className={taskBlockClass}>
        <table className="taskBlock-table">
          <Header
            stage={stage}
            title={title}
            theme={theme}
            cols={cols}
            setCols={setCols}
            onColumnChange={(cols) => {
              //console.log("table cols", cols);
              setCols(cols);
            }}
            expand={expand}
            setExpand={setExpand}
            setOpenCustomFiled={setOpenCustomFiled}
          />

          <RowBody
            {...props}
            expand={expand}
            tasks={tasks}
            clear={clear}
            setClear={setClear}
            onTaskRemove={onTaskRemove}
            onTaskSelect={onTaskSelect}
            selectedTask={selectedTask}
            columns={cols}
            onUpdateTaskDetail={handleUpdateTaskDetail}
          />
        </table>
        {!isEmpty(selectedTask) ? (
          <TaskSelectPopup
            selectedTask={selectedTask}
            setClear={setClear}
            BoardId={props.board.BoardId}
            setSelectedTask={setSelectedTask}
          />
        ) : null}

        {openCustomFiled ? (
          <CustomFields
            data={board}
            onModalClose={() => setOpenCustomFiled(false)}
            open={openCustomFiled}
          />
        ) : null}
      </div>
    </div>
  );
}
Task.defaultProps = {
  enableAddTask: false,
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = { updateTaskDetails };
export default connect(mapStateToProps, mapDispatchToProps)(Task);
