import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Grid,
  Inject,
} from "@syncfusion/ej2-react-grids";
import Pagination from "components/common/pagination";
import Icons from "components/icons";
import * as React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import RouteEnum from 'constants/routeEnum'
import FormGroup from "components/form";
import isEmpty from "isEmpty";
import { connect } from "react-redux";
import { SetSelectedRecords } from "actions/commonAction";
import { getSelectedRecords } from "../getProfileData";

export interface IAppProps {
  data: any;
  handleRowChange: Function;
  handleIndexChange: Function;
  pageIndex: BigInteger;
  pageSize: BigInteger;
  history: any;
  handleConfirmDelRecord: Function;
  handleMultiDeleteOption: Function;
  selectedRecords: any;
  SetSelectedRecords: Function;
}

interface State {
  checkedProfiles: any;
}
class App extends React.Component<IAppProps, State> {
  private grid: Grid | null;
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      checkedProfiles: [],
    };
    this.gridTemplate = this.gridTemplate;
  }

  gridTemplate(props) {
    return (
      <Router>
        <div className="flex library">
          <Link
            to={{
              pathname: RouteEnum.CreateJobProfilePath,
              search: `?profileId=${props.ProfileId}`,
            }}
            target="_blank"
          >
            <button className="button with-icon button-white">
              <Icons name="Copy" className="flex" />
              <span>Duplicate</span>
            </button>
          </Link>
          <Link to={RouteEnum.UpdateJobProfilePath + props.ProfileId} target="_blank">
            <button
              className="button with-icon button-white"
              //onClick={(e) => this.handleEditRecord(props.ProfileId)}
            >
              <Icons name="Edit" className="flex" />
              <span>Edit</span>
            </button>
          </Link>
          <button
            className="button with-icon button-white"
            onClick={(e) => this.handleDelRecord(props.ProfileId)}
          >
            <Icons name="Delete" className="flex" />
            <span>Delete</span>
          </button>
        </div>
      </Router>
    );
  }

  handleCheckboxChange = (event, profileId) => {
    let selectedRecords = getSelectedRecords(event, this.props);

    this.props.SetSelectedRecords(selectedRecords);
    this.props.handleMultiDeleteOption(
      !isEmpty(selectedRecords) ? true : false
    );
  };
  handleEditRecord = (profileId) => {
    //this.props.history.push(UpdateJobProfilePath + profileId);
    window.open(RouteEnum.UpdateJobProfilePath + "/" + profileId);
  };

  handleDelRecord = (profileId) => {
    this.props.handleConfirmDelRecord(profileId);
  };
  public render() {
    const {
      data,
      handleRowChange,
      handleIndexChange,
      pageIndex,
      pageSize,
    } = this.props;
    return (
      <div className="table">
        <div className="arrear-table">
          {/* <Loader open={travelSettlement.loading} /> */}

          <div className="job-profile-grid">
            <GridComponent
              dataSource={data}
              className="custom-grid"
              filterSettings={{ type: "Menu" }}
              allowFiltering={true}
              //  height={!isEmpty(searchedLeaveAbsence)?"500":'80'}
              id="grid"
              allowExcelExport={true}
              allowSorting={true}
              ref={(g) => (this.grid = g)}
              rowSelecting={this.handleCheckboxChange}
              rowDeselecting={this.handleCheckboxChange}
            >
              <ColumnsDirective>
                <ColumnDirective type="checkbox" width="20" />
                <ColumnDirective
                  field="ProfileName"
                  headerText="Job Profile Name"
                  width={100}
                />
                <ColumnDirective
                  field="LevelName"
                  headerText="Job Family"
                  width={100}
                />
                <ColumnDirective
                  field="LevelGroupName"
                  headerText="Job Role"
                  width={100}
                />
                <ColumnDirective
                  field="LastUpdatedDate"
                  headerText="Last Updated"
                  width={100}
                />
                <ColumnDirective
                  field=""
                  width="170"
                  template={this.gridTemplate.bind(this)}
                />
              </ColumnsDirective>
              <Inject services={[Filter]} />
            </GridComponent>

            {data && data.length > 0 && (
              <Pagination
                handleRowChange={handleRowChange}
                handleIndexChange={handleIndexChange}
                pageIndex={pageIndex}
                rowsPerPage={pageSize}
                total={data[0].TotalRows}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedRecords: state.commonReducer.selectedRecords,
});

const mapDispatchToProps = {
  SetSelectedRecords,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
