import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "actions/authAction";
import RouteEnum from 'constants/routeEnum'

const Signout = (props) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(logoutUser());
  }, []);
  const isAuth = useSelector((state: any): any => state.auth.isAuthenticated);
  React.useEffect(() => {
    if (!isAuth) {
      props.history.push(RouteEnum.LoginPath);
    }
  }, [isAuth]);
  return null;
};

export default Signout;
