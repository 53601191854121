import React from "react";
import Form from "components/form";
import Button from "components/button";
import moment from "moment";
import isEmpty from "isEmpty";

export default function NewLoanRecord({
  data,
  onChange,
  onModalClose,
  handleSubmit,
  payrollLoanHeadings,
  ...props
}) {
  return (
    <div className="homeloan-newloan">
      <div className="homeloan-newloan-body">
        <div className="homeloan-newloan-row">
          <Form
            name="LoanName"
            value={data.LoanName}
            // value={salaryTemplate}
            options={payrollLoanHeadings}
            onChange={(name, value) => onChange(name, value)}
            // type="number"
            formName="reactselect"
            error={data.errors && data.errors.LoanName}
            label={"Loan Name"}
            validators={["required"]}
            // width={"80%"}
          />
        </div>
        <div className="homeloan-newloan-row flex">
          <div className="homeloan-newloan-row-content">
            <Form
              name="Outstanding"
              value={data.Outstanding}
              onChange={(name, value) => onChange(name, value)}
              validators={["required", "numbers"]}
              error={data.errors && data.errors.Outstanding}
              type="number"
              label={"Outstanding Amount"}
              // width={"80%"}
            />
          </div>
          <div className="homeloan-newloan-row-content-date">
            <Form
              formName="customdateinput"
              label="As on Date"
              value={!isEmpty(data.AsOnDate) ? moment(data.AsOnDate).format("YYYY/MM/DD") : null}
              validators={["required"]}
              name="AsOnDate"
              error={data.errors && data.errors.AsOnDate}
              onChange={(name, value) => onChange(name, value)}
              placeholder={"YYYY/MM/DD"}
              // isNepali={!companyInformation.IsEnglishDate}
              // isTextInNepali={!companyInformation.IsEnglishDate}
            />
          </div>
        </div>
        <div className="homeloan-newloan-row flex">
          <div className="homeloan-newloan-row-content">
            <Form
              name="MonthlyDeduction"
              value={data.MonthlyDeduction}
              onChange={(name, value) => onChange(name, value)}
              validators={["required", "numbers"]}
              error={data.errors && data.errors.MonthlyDeduction}
              type="number"
              label={"Monthly Deduction"}
              // width={"80%"}
            />
          </div>
          <div className="homeloan-newloan-row-content-date">
            <Form
              formName="customdateinput"
              label="Completes on"
              // value={
              //   companyInformation.IsEnglishDate ? completesOn : date_expired_nep
              // }
              value={!isEmpty(data.CompletesOn) ? moment(data.CompletesOn).format("YYYY/MM/DD") : null}
              validators={["required"]}
              name="CompletesOn"
              error={data.errors && data.errors.CompletesOn}
              onChange={(name, value) => onChange(name, value)}
              placeholder={"YYYY/MM/DD"}
              // isNepali={!companyInformation.IsEnglishDate}
              // isTextInNepali={!companyInformation.IsEnglishDate}
            />
          </div>
        </div>
      </div>
      <div className="homeloan-newloan-footer">
        <Button
          title="Cancel"
          bg="subtle"
          onClick={() => onModalClose()}
        />
        <Button title="Save" bg="primary" onClick={() => handleSubmit()} />
      </div>
    </div>
  );
}
