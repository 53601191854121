import React, { Component } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Filter,
  Inject,
} from "@syncfusion/ej2-react-grids";
import { AgGridReact } from "ag-grid-react";
import Pagination from "components/common/pagination";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AssignApprover from "./AssignApprover";
import moment from "moment";
import { DisplayClientSideMessage } from "actions/commonAction";
import RouteEnum from "constants/routeEnum";
import Avatar from "react-avatar";
import { getUploadPath, UserByStore } from "constants/userDetails";
import Modal from "components/modal";
import Button from "components/button";
import {
  getQualifiedApprovers,
  forwardLoanToFinance,
  forwardLoanToCAD,
  forwardLoanToHR,
  approveOrRejectLoan,
  getPrimeLoanSavedSalaryHead,
  saveMultiplePrimeLoanSalaryHead,
  getPrimeLoanSalaryHead,
} from "./helpers/action";
import { currencyCommaSeperator } from "./helpers/currencyFormatter";
import isEmpty from "helpers/isEmpty";

const ButtonGroup = (props) => {
  return (
    <div className="flex flex-end align-right view-button-pd">
      <Button
        bg="secondary"
        size="large"
        onClick={() => props.api.viewHomeDetails(props.data)}
        title="View"
      />
      {props.data.Status == 3 && !props.data.IsSelfForward ? (
        <Button
          bg="success"
          size="large"
          onClick={() => props.api.submitLoanApproved(props.data, true)}
          isDisabled={UserByStore()?.EmployeeId == props.data.EmployeeId}
          title="Approve"
        />
      ) : (props.data.Status == 1 || props.data.Status == 2 || props.data.Status == 8) &&
        !props.data.IsSelfForward ? (
        <Button
          bg="secondary"
          size="large"
          onClick={() => props.api.setOpen(props.data)}
          isDisabled={UserByStore()?.EmployeeId == props.data.EmployeeId}
          title="Foward"
        />
      ) : null}
    </div>
  );
};

const EmployeePic = (props) => {
  return (
    <Avatar
      name={props.data.EmployeeName}
      size={45}
      round={true}
      src={props.data.UrlPhoto && getUploadPath(props.data.UrlPhoto)}
    />
  );
};

const formatNumber = (params) => {
  return (
    Math.floor(params?.value)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + ".00"
  );
};

const formatDate = (date) => {
  return moment(date?.value).format("YYYY/MM/DD");
};

const EmployeeProfile = (props) => {
  return (
    <div className="profile-div">
      <div className="profile-div__info">
        <div className="profile-div__avatar">
          <Avatar
            name={props.data?.EmployeeName}
            size={"45"}
            round={true}
            src={props.data?.UrlPhoto && getUploadPath(props.data?.UrlPhoto)}
          />
        </div>
        <div
          style={{ maxWidth: 180, whiteSpace: "initial", lineHeight: 1.2 }}
          onClick={() =>
            props.api.history.push(
              RouteEnum.EmployeeProfile + `/${props.data?.EmployeeId}`
            )
          }
          className="name-link"
        >
          <span>{props.data?.EmployeeName}</span>
        </div>
      </div>
    </div>
  );
};

// const saleFilterParams = {
//   allowedCharPattern: '\\d\\-\\,\\$',
//   numberParser: function (text) {
//     return text == null
//       ? null
//       : parseFloat(text.replace(',', '.').replace('$', ''));
//   },
// };
export class LoanTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ForwardTo: "",
      open: false,
      loanToForward: null,
      approverTitle: "Credit Administration Department/Finance Department",

      columnDefs: [
        {
          field: "",
          headerName: "Employee",
          // pinned: "left",
          cellRenderer: "EmployeeProfile",
          width: 220,
        },
        // {
        //   field: "EmployeeName",
        //   headerName: "Employee Name",
        //   width: 180,
        // },
        {
          field: "LoanPurpose",
          headerName: "Loan Purpose",
          width: 280,
          autoHeight: true,
          // cellStyle: {'white-space': 'normal'},
        },
        { field: "LoanTypeName", width: 150, headerName: "Loan Type" },
        { field: "AssignedByName", width: 180, headerName: "Assigned By" },
        { field: "ForwardedByName", width: 180, headerName: "Forwarded By" },
        { field: "ForwardedToName", width: 180, headerName: "Forwarded To" },
        {
          field: "RequestAmount",
          width: 140,
          headerName: "Amount (NPR)",
          wrapText: true,
          type: "rightAligned",
          cellStyle: { dispaly: "table" },
          // filterParams: {
          //   // allowedCharPattern: "\\d\\-\\,", // note: ensure you escape as if you were creating a RegExp from a string
          //   numberParser: (text) => {
          //     return text == null ? null : formatNumber(text);
          //   },
          // },
          // filter: 'RequestAmount',
          valueFormatter: formatNumber,
        },
        {
          field: "RequestedOn",
          width: 140,
          headerName: "Requested on",
          valueFormatter: formatDate,
        },
        { field: "StatusName", width: 210, headerName: "Status" },
        { field: "", cellRenderer: "ButtonGroup", headerName: "", width: 250 },
      ],
      domLayout: "autoHeight",
      defaultColDef: {
        wrapText: true,
        // width: 145,
        // resizable: true,
      },
      // loadingCellRenderer: 'customLoadingCellRenderer',
      // loadingCellRendererParams: { loadingMessage: 'One moment please...' },
      frameworkComponents: {
        // profileImage: ProfileImage,
        ButtonGroup,
        EmployeePic,
        EmployeeProfile,
      },

      getRowHeight: function (params) {
        return 58;
      },
    };
  }

  async componentDidMount() {
    const { loanApproverList } = this.props;
    await this.setState({ loanApproverList });
    
    // this.props.getQualifiedApprovers();
  }
  viewHomeDetails = async (loan) => {
    this.props.history.push({
      pathname: `${RouteEnum.ViewHomeLoanPath}/${loan.LoanId}`,
      state: {
        data: loan.LoanType,
        LoanId: loan.LoanId,
        EmployeeId: loan.EmployeeId,
        Status: loan.Status,
        DisbursementDate: loan.DisbursementDate,
        NoOfLoanTaken: loan.NoOfLoanTaken,
        FourWheelerNoOfLoanTaken: loan?.FourWheelerNoOfLoanTaken,
        FourWheelerDisbursementDate: loan?.FourWheelerDisbursementDate,
        AssignedBy: loan?.AssignedBy,
        viewMode: loan.IsSelfForward || loan.Status == 5 || loan.Status == 4,
      },
    });
  };

  handleInput = (name, value) => {
    this.setState({ [name]: value });
  };

  handleSubmit = async () => {
    const { loanToForward, ForwardTo } = this.state;
    if (isEmpty(ForwardTo)) {
      this.props.DisplayClientSideMessage("At least one approver is required");
      return;
    }
    const {
      forwardLoanToFinance,
      forwardLoanToHR,
      forwardLoanToCAD,
      handleStageChange,
      employeePrimeLoanSalary,
    } = this.props;
    let data = {
      loanId: loanToForward.LoanId,
      loanType: loanToForward.LoanType,
      employeeId: ForwardTo,
      Status: loanToForward.Status,
      // loanType: loanToForward.type
    };
    debugger
    if (!isEmpty(employeePrimeLoanSalary)) {
      const { employeePrimeLoanSalary, Recommendation } = this.props;
      let data = {
        PrimeLoanSalaryHead: [...employeePrimeLoanSalary],
        Recommendation: "",
      };
      await this.props.saveMultiplePrimeLoanSalaryHead(data);
      // const { multiplePrimeLoanSalaryHeadSaved } = this.props;
    }
    debugger
    if (loanToForward.Status == 1) await forwardLoanToCAD(data);
    else if (loanToForward.Status == 2) await forwardLoanToFinance(data);
    else if (loanToForward.Status == 8) await forwardLoanToHR(data);
    debugger
    handleStageChange();
    this.onClose();
  };

  onClose = () =>
    this.setState({ open: false, ForwardTo: "", loanToForward: null });

  setOpen = async (loanToForward) => {
    const { loanApproverList } = this.props;
    let approverTitle = "Credit Administration Department";
    // let loanData = {
    //   EmployeeId: loanToForward.EmployeeId,
    //   LoanType: loanToForward.LoanType,
    //   LoanId: loanToForward.LoanId,
    // };
    await this.props.getPrimeLoanSalaryHead(loanToForward.EmployeeId);
    if (!isEmpty(loanApproverList)) {
      let newList = [];
      if (loanToForward.Status == 2) {
        // newList = loanApproverList.filter((loan) => loan.IsHR == true);
        newList = loanApproverList.filter((loan) => loan.Role == 3);
        approverTitle = "Finance Department";
      } else if (loanToForward.Status == 1 || loanToForward.Status == 3) {
        approverTitle = "Credit Administration Department";
        // newList = loanApproverList.filter((loan) => loan.IsHR == false);
        newList = loanApproverList.filter((loan) => loan.Role == 2);
      } else if (loanToForward.Status == 8) {
        approverTitle = "HR Department";
        // newList = loanApproverList.filter((loan) => loan.IsHR == false);
        newList = loanApproverList.filter((loan) => loan.Role == 1);
      }
        this.setState({
          loanApproverList: newList,
          open: true,
          loanToForward,
          approverTitle,
        });
    }
  };

  submitLoanApproved = async (data, isApprove) => {
    let submitData = {
      isApprove,
      loanId: data.LoanId,
      loanType: data.LoanType,
    };
    await this.props.approveOrRejectLoan(submitData);
    const { loanApprovedStatus, handleStageChange } = this.props;
    if (loanApprovedStatus) {
      handleStageChange();
    }
  };
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.setAutoHeight();
    const updateData = (data) => {
      this.setState({ rowData: data });
    };

    if (this.props.data?.Data) {
      updateData(this.props.data.Data);
    }
    this.gridApi.viewHomeDetails = this.viewHomeDetails;
    this.gridApi.submitLoanApproved = this.submitLoanApproved;
    this.gridApi.activeStageId = this.props.activeStageId;
    this.gridApi.setOpen = this.setOpen;
    this.gridApi.history = this.props.history;
  };
  setAutoHeight = () => {
    this.gridApi.setDomLayout("autoHeight");
    const grid = document.querySelector("#myGrid");
    if (grid) grid.style.height = "";
  };
  render() {
    const {
      forwardedLoanListToReview,
      handleRowChange,
      handleIndexChange,
      pageIndex,
      rowsPerPage,
      user: { EmployeeId },
      // data,
      // loanApproverList,
    } = this.props;
    let { ForwardTo, open, loanApproverList, approverTitle } = this.state;
    loanApproverList = loanApproverList?.filter(
      (appr) => appr.Id != EmployeeId
    );

    return (
      <div className="table">
        <div className="arrear-table">
          <div className="pending-settlement-grid">
            <Modal
              onModalClose={this.onClose}
              title={"Forward To"}
              className="current-modal"
              open={open}
            >
              <AssignApprover
                closeModal={this.onClose}
                // {...props}
                // mode={mode}
                hrEmpDetails={loanApproverList}
                ForwardTo={ForwardTo}
                approverTitle={approverTitle}
                handleSubmit={this.handleSubmit}
                handleInput={this.handleInput}
                setOpen={
                  (loanToForward) => this.setOpen(loanToForward)
                  // this.setState({ open: true, loanToForward })
                }
              />
            </Modal>
            <div
              id="myGrid"
              style={{
                height: "100%",
                width: "100%",
              }}
              className="ag-theme-alpine"
            >
              <AgGridReact
                columnDefs={this.state.columnDefs}
                defaultColDef={this.state.defaultColDef}
                frameworkComponents={this.state.frameworkComponents}
                onGridReady={this.onGridReady}
                getRowHeight={this.state.getRowHeight}
                // loadingCellRenderer={this.state.loadingCellRenderer}
                // loadingCellRendererParams={this.state.loadingCellRendererParams}
                rowData={
                  forwardedLoanListToReview && forwardedLoanListToReview.Data
                }
                alwaysShowHorizontalScroll={true}
              />
            </div>
            {forwardedLoanListToReview &&
              forwardedLoanListToReview?.RecordsTotal > 0 && (
                <Pagination
                  handleRowChange={handleRowChange}
                  handleIndexChange={handleIndexChange}
                  pageIndex={pageIndex}
                  rowsPerPage={rowsPerPage}
                  total={
                    forwardedLoanListToReview &&
                    forwardedLoanListToReview.RecordsTotal
                  }
                />
              )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  forwardedLoanListToReview: state.loanReducer.forwardedLoanListToReview,
  loanApproverList: state.loanReducer.loanApproverList,
  loanApprovedStatus: state.loanReducer.loanApprovedStatus,
  user: state.auth.user,
});

export default withRouter(
  connect(mapStateToProps, {
    getQualifiedApprovers,
    forwardLoanToFinance,
    forwardLoanToCAD,
    forwardLoanToHR,
    DisplayClientSideMessage,
    approveOrRejectLoan,
    getPrimeLoanSavedSalaryHead,
    saveMultiplePrimeLoanSalaryHead,
    getPrimeLoanSalaryHead,
  })(LoanTable)
);
