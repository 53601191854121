import React, { Component } from 'react'
import Avatar from "react-avatar";
import RouteEnum from 'constants/routeEnum'
import { withRouter } from 'react-router-dom';
import { createPortal } from "react-dom";
import Button from 'components/button'
import { MdAdd, MdKeyboardArrowDown } from "react-icons/md";
import {connect} from 'react-redux';
import {getUploadPath} from 'constants/userDetails'
export class ProfileImage extends Component<any, any>{
    constructor(props) {
        super(props);

        this.state = {
            show: false,
        };

        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.onCardClick = this.onCardClick.bind(this);
        this.clearState = this.clearState.bind(this);
    }
    public dropdown: any = "";
    public timerId: any = null;
    public dropDownRef: any = null;
    public teamCard: any = "";
    public avatar: any = "";
    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        // window.addEventListener('scroll', this.handleScroll.bind(this));
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
        // window.removeEventListener('scroll', this.handleScroll.bind(this));
    }

    handleClickOutside(event) {
        if (this.teamCard && !this.teamCard.contains(event.target)) {
            this.setState({ show: false })
            this.clearState()
        }
        if (this.dropDownRef && !this.dropDownRef.contains(event.target)) {
            this.setState({ drop: false })
        }
    }
    handleScroll = (event) => {
        if(this.avatar){
            const measurements = this.avatar.getBoundingClientRect();
            const ele: any = this.teamCard;
            ele.style.left = measurements.left - 100 + "px";
            ele.style.top = measurements.top + 50 + "px";
        }
        // this.clearState()

    }
    clearState = () => {
        //   this.setState({ currentCard: null, activeCard: "" });
        if (this.teamCard) {
            this.teamCard.classList.remove("active-card");
        }
    };
    onCardClick(e) {
        const currentTarget = e.currentTarget;
        this.setState({ show: !this.state.show }, () => {
            if (this.state.show) {
                const measurements = this.avatar.getBoundingClientRect();
                const ele: any = this.teamCard;
                ele.style.position = "fixed";
                ele.style.left = measurements.left - 100 + "px";
                ele.style.top = measurements.top + 50 + "px";
                ele.classList.add("active-card");
            } else {
                this.clearState()
            }
        })
    }
    onDrop(id, event) {
        this.setState({ drop: !this.state.drop },()=>{
            if (this.state.drop) {
                const measurements:any =this.dropdown.getBoundingClientRect();
                const ele: any =  this.dropDownRef;
                console.log(ele)
                if(ele){
                    ele.style.position = "fixed";
                    ele.style.left = measurements.left - 140 + "px";
                    ele.style.top = measurements.top + 30 + "px";
                    ele.classList.add("active-dropdown");
                }

            } else {
                this.clearState()
            }
        });
    }
    renderCardView = props => {
        const {baseUrl} = this.props;
        return (
            createPortal(
                <div ref={ref => (this.teamCard = ref)} className="team-card">
                    {props ? (
                        <>
                            <div className="team-card-image">
                                {/* <img src={currentCard.image} alt={currentCard.name} /> */}
                                <Avatar
                                    name={props.Fullname}
                                    size={'75'}
                                    round={true}
                                    src={getUploadPath(props.UrlPhoto)}
                                />
                            </div>
                            <div className="team-card-content">
                                <div className="team-card-title">
                                    <h3>{props.Fullname}</h3>
                                </div>
                                <p className="mb-sm">{props.Branch + ", " + props.Department}</p>
                                {/* <span className="team-card-text">{props.Position}</span> */}
                                {/* <span className="team-card-text mb-sm">{props.Branch}</span> */}
                                <span className="team-card-text">{props.Email}</span>
                                <span className="team-card-text">{props.MobileNo}</span>
                                <span className="team-card-text">{props.PhoneNo}</span>

                                {/* <a href="#" className="team-card-link">
                                    <span>Send Message</span>
                                </a> */}
                            </div>
                        </>
                    ) : null}
                </div>
                ,
                document.body)
        );
    };
    renderDrop = (props) =>{
        return(
            <div>
                <div
                    className="profile-down-caret"
                    ref={dropdown => (this.dropdown = dropdown)}
                    onClick={this.onDrop.bind(this, props.EmployeeId)}
                >
                    <MdKeyboardArrowDown />
                </div>
                {
                    this.state.drop ? createPortal(
                        <div
                            id={props.EmployeeId + 'a'}
                            ref={ref=>this.dropDownRef = ref}
                            // style={style}
                            className="profile-drop__content"
                        >
                            <ul className="profile-drop-list">
                                <li className="profile-drop-list__item">
                                    <span className="profile-drop-name">
                                        Pay and Compensation
                                    </span>
                                </li>
                                <li className="profile-drop-list__item with-hr">
                                    <span className="profile-drop-name">Leave and Time</span>
                                </li>
                                <li className="profile-drop-list__item with-hr">
                                    <span className="profile-drop-name">Delete</span>
                                </li>
                            </ul>
                        </div>
                        ,document.body)
                    :null
                }

            </div>
        )
    }
    render() {
        const { data, baseUrl} = this.props;

        return (
            <div className="profile-div">
                <div className="profile-div__info">
                    <div className="profile-div__avatar">
                        <div
                            ref={ref => this.avatar = ref}
                            onClick={e => this.onCardClick(e)}
                        // onMouseOut={e => this.onOutMouse(e)}
                        // onMouseOver={e => this.onOverMouse(e)}
                        >
                            <Avatar
                                name={data.Fullname}
                                size={'45'}
                                round={true}
                                src={getUploadPath(data.UrlPhoto)}
                            />
                        </div>
                        {this.renderCardView(data)}
                    </div>
                    <div
                        style={{maxWidth:180,whiteSpace:'initial',lineHeight:1.2}}
                        onClick={() => this.props.history.push(RouteEnum.EmployeeProfile + `/${data.EmployeeId}`)}
                        className="name-link">
                        <span>{data.Fullname}</span>
                    </div>
                </div>
                <div
                    className="profile-td"
                    role="gridcell"
                >
                    {this.renderDrop(data)}
                    
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    baseUrl: state.auth.baseUrl ? state.auth.baseUrl.NetFrameworkAppBaseUrl : '',
});
  

export default connect(mapStateToProps,{})(withRouter(ProfileImage))
