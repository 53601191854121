import React from "react";
import classnames from "classnames";
import { connect } from 'react-redux';

function HealthRecord(props) {

        const travelClasses = () => {
            return classnames({
            badges: true,
            "badges-rounded": true,
            "badges-secondary": true
            });
        };

        let healthRecords = props.healthInfo;
        return (
            <div className="profile-row">
            <div className="profile-column">
                <div className="profile-block">
                    <div className="profile-block__title">
                        <h2>HEALTH AND FAMILYS</h2>
                    </div>
                    { healthRecords && healthRecords.length > 0 ? <div className="profile-address__block">
                        <div className="profile-health">
                            <div className="profile-health__title">
                                <span className="document-serial-bold">
                                        {healthRecords[0].FamilyMembers.split(" ")[0]}
                                </span>
                                <span className="profile-label-health">
                                    Family Members
                                </span>
                            </div>
                            <div className="profile-block__div profile-family">
                                <div className="flex">

                                    <div className={travelClasses()}>
                                        Father
                                    </div>
                                    <div className={travelClasses()}>
                                        Mother
                                    </div>
                                    <div className={travelClasses()}>
                                        2 siblings
                                    </div>
                                    <div className={travelClasses()}>
                                        Spouse
                                    </div>
                                    <div className={travelClasses()}>
                                        1 Child
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="profile-block__div">
                            {
                                healthRecords[0].BloodGroupName?
                                    <div className="health-attention">
                                    {healthRecords[0].BloodGroupName}
                                    </div>
                                :null
                            }
                            
                            <div className="profile-label-health attention">
                                    Health Condition Requiring Attention: <span> none</span>
                            </div>
                        </div>
                    </div> :
                    <div className="profile-block__title nodata">
                        <h2>No Health Record Found</h2>
                    </div> }
                </div>
            </div>
            </div>
        );
};

const mapStateToProps = state => ({
    healthInfo: state.employeeProfile.employeeProfile && state.employeeProfile.employeeProfile.HealthAndRelation,
    // familyDetail: state.employeeDashboard.employeeDetail && state.employeeDashboard.employeeDetail.Family
});

export default connect(mapStateToProps, null)(HealthRecord);