import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteRosterShift,
    getRosterShitsList,
    updateRosterShiftArchive,
} from '../helpers/action'
import Button from 'components/button'
import Modal from 'components/modal';
import AddShift from './addShift';
import { MdAdd } from 'react-icons/md';
import Table from 'components/customTable';
import Pagination from "components/common/pagination";
import Confirm from 'components/common/commonConfimation'
import './styles.scss';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import classNames from 'classnames';
import Dropdown from 'components/dropdown';
import Icons from 'components/icons/svgIcons'

const ButtonCell = (props) => {
    const {getShiftList} = props;
    const [open, setOpen] = useState(false);
    const onModalOpen = () => setOpen(true)
    const onModalClose = () => setOpen(false);
    const dispatch = useDispatch();
    const onEdit = () => {
        console.log("Edit")
    }
    const onDelete = async() =>{
        const res:any = await dispatch(deleteRosterShift(props?.value))
        if(res && res?.Status){
            getShiftList()
        }
    }
    const onArchieve = async () => {
        const res:any = await dispatch(updateRosterShiftArchive(props?.value))
        if(res && res?.Status){
            getShiftList()
        }
    }
    return (
        <div className="shifts-table__col">
            <div className="shifts-table__links">
                <Button bg="primary" onClick={() => onModalOpen()}>Edit</Button>
                <Dropdown 
                >
                <span 
                    onClick={()=>onArchieve()}
                >Archive</span> 
                <Confirm action={onDelete} >
                    <span>Delete</span>
                </Confirm>
                </Dropdown>
                
            </div>
            <Modal
                open={open}
                onModalClose={onModalClose}
                title="Add Shift"
            >
                <AddShift isEdit getShiftList={getShiftList} editData={props?.row?.original} onModalClose={onModalClose} />
            </Modal>
        </div>
    )
}


const ArchivedCell = ({value}) =>{
    return <div className={classNames("shifts-table__archive",{"shifts-table__archive-err":value})}>
        {/* {!value ? <IoCheckmarkCircleOutline /> : <IoIosCloseCircleOutline /> } */}
        {!value ? <Icons name="archive" size={14}  /> : <Icons name="check" size={14} />}
    </div>
}

const pageState = {
    pageIndex:0,
    pageSize:10
}

function Shifts() {
    const [ state, setState ] = useState(pageState);
    const [open, setOpen] = useState(false);
    const onModalOpen = () => setOpen(true)
    const onModalClose = () => setOpen(false);
    const dispatch = useDispatch();
    const {
        shiftList
    } = useSelector((state:any)=>state.roosterReducer)
    useEffect(() => {
        getShiftList();
    }, [])

    
    useEffect(()=>{
        getShiftList()
    },[state.pageIndex,state.pageSize])
    const getShiftList = () =>{
        const data ={
            page:state.pageIndex,
            size:state.pageSize
        }
        dispatch(getRosterShitsList(data))
    }
    const cols = [
        {
            Header: 'Shift Name',
            accessor: 'Name',
            width: 160,
        },
        {
            Header: 'Begin Time',
            accessor: 'BeginsAt',
            width: 160,
        },
        {
            Header: 'End Time',
            accessor: 'EndsAt',
            width: 160,
        },
        {
            Header: 'Hours',
            accessor: 'Duration',
            width: 160,
        },
        {
            Header: 'State',
            accessor: 'IsArchived',
            Cell: ArchivedCell,
            width: 120,
        },
        {
            Header: 'Actions',
            accessor: 'Id',
            Cell:(val) => <ButtonCell {...val}  getShiftList={getShiftList} />,
            width: 160,
        },
    ];
    const handleRowChange = (num) => {
        setState((filter:any)=>{
            return {
                ...filter,
                pageSize:num,
                pageIndex:0
            }
        })
    };
    
    const handleIndexChange = (num) => {
        setState((filter:any)=>{
            return {
                ...filter,
                pageIndex:num
            }
        })
    };
    return (
        <div className="shifts" >
            <div className="shifts-container">
                <div className="shifts-title">
                    Shifts
                </div>
                <div className="shifts-adds">
                    <Button title="Add Shift" bg="primary" buttonType="icon-button" icon={<MdAdd />} onClick={() => onModalOpen()} />
                </div>
                <Table
                    isFixedWidth
                    data={shiftList}
                    columns={cols}
                />
                {shiftList && shiftList.length > 0 && (
                <Pagination
                    handleRowChange={handleRowChange}
                    handleIndexChange={handleIndexChange}
                    pageIndex={state.pageIndex}
                    rowsPerPage={state.pageSize}
                    // total={10}
                    total={shiftList.length > 1 ?  shiftList[1]?.TotalRows : shiftList.length}
                />
                )}
            </div>
            <Modal
                open={open}
                onModalClose={onModalClose}
                title="Add Shift"
            >
                <AddShift onModalClose={onModalClose} getShiftList={getShiftList} />
            </Modal>
        </div>
    )
}

export default Shifts
