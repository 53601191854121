import axios from "axios";
import { Apis } from "src/config/api";
import { getErrorResponse } from "helpers/getApiResponse";
import { loadingHeaders } from "helpers/getHeaders";
//#region employee requests
const getEmployeeRequestCount = async () => {
  try {
    let result = await axios.get(Apis.GetEmployeeRequestsCount, loadingHeaders);
    return result.data;
  } catch (err) {
    return getErrorResponse(err?.message);
  }
};
const getEmployeeReviewRequest = async (searchData) => {
  try {
    const {
      pageNumber,
      pageSize,
      employeeName,
      requestType,
      requestStartDate,
      requestEndDate,
      eventStartDate,
      eventEndDate,
    } = searchData;
    let result = await axios.get(
      Apis.GetEmployeeReviewRequest +
        `?RequestType=${requestType}&EmployeeName=${employeeName}&RequestStartDate=${requestStartDate}&RequestEndDate=${requestEndDate}&EventStartDate=${eventStartDate}&EventEndDate=${eventEndDate}&PageIndex=${pageNumber}&PageSize=${pageSize}`,
      loadingHeaders
    );
    return result.data;
  } catch (err) {
    return getErrorResponse(err?.message);
  }
};
const getMyRequests = async (searchData) => {
  try {
    const {
      pageNumber,
      pageSize,
      employeeName,
      requestType,
      requestStartDate,
      requestEndDate,
      eventStartDate,
      eventEndDate,
    } = searchData;
    let result = await axios.get(
      Apis.GetMyRequests +
        `?RequestType=${requestType}&EmployeeName=${employeeName}&RequestStartDate=${requestStartDate}&RequestEndDate=${requestEndDate}&EventStartDate=${eventStartDate}&EventEndDate=${eventEndDate}&PageIndex=${pageNumber}&PageSize=${pageSize}`,
      loadingHeaders
    );
    return result.data;
  } catch (err) {
    return getErrorResponse(err?.message);
  }
};
const getTeamRequestHistory = async (searchData) => {
  try {
    const {
      pageNumber,
      pageSize,
      employeeName,
      requestType,
      requestStartDate,
      requestEndDate,
      eventStartDate,
      eventEndDate,
    } = searchData;
    let result = await axios.get(
      Apis.GetTeamRequestHistory +
        `?RequestType=${requestType}&EmployeeName=${employeeName}&RequestStartDate=${requestStartDate}&RequestEndDate=${requestEndDate}&EventStartDate=${eventStartDate}&EventEndDate=${eventEndDate}&PageIndex=${pageNumber}&PageSize=${pageSize}`,
      loadingHeaders
    );
    return result.data;
  } catch (err) {
    return getErrorResponse(err?.message);
  }
};
const getMyRequestHistory = async (searchData) => {
  try {
    const {
      pageNumber,
      pageSize,
      employeeName,
      requestType,
      requestStartDate,
      requestEndDate,
      eventStartDate,
      eventEndDate,
    } = searchData;
    let result = await axios.get(
      Apis.GetMyRequestHistory +
        `?RequestType=${requestType}&EmployeeName=${employeeName}&RequestStartDate=${requestStartDate}&RequestEndDate=${requestEndDate}&EventStartDate=${eventStartDate}&EventEndDate=${eventEndDate}&PageIndex=${pageNumber}&PageSize=${pageSize}`,
      loadingHeaders
    );
    return result.data;
  } catch (err) {
    return getErrorResponse(err?.message);
  }
};

const cancelMyRequests = async (requestData) => {
  try {
    const { requestId, requestType } = requestData;
    let result = await axios.put(
      Apis.CancelMyRequests +
        `?RequestId=${requestId}&RequestType=${requestType}`
    );
    return result.data;
  } catch (err) {
    return getErrorResponse(err?.message);
  }
};
const rejectReviewRequests = async (requestData) => {
  try {
    const { requestId, requestType, reason } = requestData;
    // let result = await axios.put(
    //   Apis.RejectReviewRequests +
    //     `?RequestId=${requestId}&Reason=${reason}&RequestType=${requestType}`
    // );
    let result = await axios.put(Apis.RejectReviewRequests, {
      RequestId: requestId,
      Reason: reason,
      RequestType: requestType,
    });
    return result.data;
  } catch (err) {
    return getErrorResponse(err?.message);
  }
};
const approveReviewRequests = async (requestData) => {
  try {
    const { requestId, requestType, reason } = requestData;
    // let result = await axios.put(
    //   Apis.RejectReviewRequests +
    //     `?RequestId=${requestId}&Reason=${reason}&RequestType=${requestType}`
    // );
    let result = await axios.put(Apis.ApproveReviewRequests, {
      RequestId: requestId,
      Reason: reason,
      RequestType: requestType,
    });
    return result.data;
  } catch (err) {
    return getErrorResponse(err?.message);
  }
};
const saveReviewRequestComment = async (requestData) => {
  try {
    const { requestId, requestType, comment } = requestData;
    // let result = await axios.put(
    //   Apis.RejectReviewRequests +
    //     `?RequestId=${requestId}&Reason=${reason}&RequestType=${requestType}`
    // );
    let result = await axios.post(Apis.SaveReviewRequestComment, {
      RequestRefId: requestId,
      Comments: comment,
      RequestType: requestType,
    });
    return result.data;
  } catch (err) {
    return getErrorResponse(err?.message);
  }
};

const getAuditLogOfRequests = async (searchData) => {
  try {
    const { requestId, moduleId } = searchData;
    let result = await axios.get(
      Apis.GetAuditLogOfRequests +
        `?requestId=${requestId}&moduleId=${moduleId}`,
      loadingHeaders
    );
    return result.data;
  } catch (err) {
    return getErrorResponse(err?.message);
  }
};

const getEmployeeListToAssignRequest = async () => {
  try {
    let result = await axios.get(Apis.GetEmployeeListToAssignRequest);
    let formatedResponse: any = [];
    if (result.data.Status) {
      result.data.Data.map((d: any) => {
        formatedResponse.push({ ...d, label: d.Key, value: d.Value });
      });
    }
    return formatedResponse;
  } catch (err) {
    return getErrorResponse(err?.message);
  }
};
//#endregion
export {
  getEmployeeReviewRequest,
  getMyRequests,
  getMyRequestHistory,
  getTeamRequestHistory,
  cancelMyRequests,
  rejectReviewRequests,
  approveReviewRequests,
  saveReviewRequestComment,
  getEmployeeRequestCount,
  getAuditLogOfRequests,
  getEmployeeListToAssignRequest,
};
