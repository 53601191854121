import axios from "axios";
import { GET_EMPLOYEE_LIST_FOR_ADMIN } from "actions/types";
import isEmpty from "isEmpty";
import { toastMessage } from "actions/validateAction";

export const GetEmployeeListForAdmin = (data) => async (dispatch) => {
  const {
    pageIndex,
    rowsPerPage,
    searchValue,
    activeBranch,
    activeDepartment,
    activePosition,
    activeStatus,
    fromDate,
    endDate,
    retirementStartDate,
    retirementEndDate,
    gender,
    maritalStatus,
    state
  } = data;
  const branchId = !isEmpty(activeBranch) ? activeBranch.value : -1;
  const departmentId = !isEmpty(activeDepartment) ? activeDepartment.value : -1;
  const positionId = !isEmpty(activePosition) ? activePosition.value : -1;
  const statusId = !isEmpty(activeStatus) ? activeStatus.value : -1;
  const isRetiredOrResignedAlso = !isEmpty(state) ? state.value : null;
  const MaritalStatus = !isEmpty(maritalStatus) ? maritalStatus.value : '';
  const RetirementStartDate = !isEmpty(retirementStartDate) ? retirementStartDate : null;
  const RetirementEndDate = !isEmpty(retirementEndDate) ? retirementEndDate : null;
  const FromDate = !isEmpty(fromDate) ? fromDate : null;
  const EndDate = !isEmpty(endDate) ? endDate : null;
  const Gender = !isEmpty(gender) ? gender.value : -1;

  const queryData = {
    searchText:searchValue,
    branchId,
    departmentId,
    positionId,
    statusId,
    MaritalStatus,
    isRetiredOrResignedAlso,
    RetirementStartDate,
    RetirementEndDate,
    FromDate,
    EndDate,
    Gender,
    pageIndex,
    pageSize: rowsPerPage
  }
  await axios
    .get(
      `/v1/employee-record/employees?SearchText=${searchValue}&BranchId=${branchId}&DepartmentId=${departmentId}&PositionId=${positionId}&StatusId=${statusId}&isRetiredOrResignedAlso=${isRetiredOrResignedAlso}&MaritalStatus=${MaritalStatus}&RetirementStartDate=${RetirementStartDate}&RetirementEndDate=${RetirementEndDate}&FromDate=${FromDate}&EndDate=${EndDate}&Gender=${Gender}&PageIndex=${pageIndex}&PageSize=${rowsPerPage}`
    )
    .then((response) => {
      if (response.data.Status) {
        dispatch({
          type: GET_EMPLOYEE_LIST_FOR_ADMIN,
          payload: response.data.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to get employee list.",
        },
      };
      toastMessage(dispatch, response);
    });
};
