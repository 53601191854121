import React, { Component } from "react";
import Form from "components/form";

export class SearchAndFilter extends Component {
  render() {
    const { searchEmployee, fromDate, toDate } = this.props;
    return (
      <div className="filters">
        <div className="filters-grid">
          <Form
            formName="textinput"
            name="searchEmployee"
            value={searchEmployee}
            onChange={this.props.handleInput}
            placeholder="Search Employee"
          />
        </div>

        <div className="filters-grid">
          <Form
            formName="reactdaterange"
            startDate={fromDate}
            endDate={toDate}
            onChange={this.props.handleDateRangePicker}
            placeholder="Date filter"
          />
        </div>
      </div>
    );
  }
}

export default SearchAndFilter;
