import React from 'react'
import Button from 'components/button'
import FormGroup from 'components/form'
import Form from "components/form";
import {
  GetBranches,
  GetDepartments,
  GetEmployeeList,
} from "actions/commonAction";
import {
  UpdateOnboardingEmployee,
  GetOnboardingEmployeeDetail,
} from "../action";
import moment from "moment";
import { connect } from 'react-redux';
import { InputValidator } from "helpers/inputValidator";
import isEmpty from "isEmpty";
import { objectToFormData } from "object-to-formdata";
import { BS2AD, AD2BS } from "helpers/dateConvert";
import { convertTime12to24 } from "helpers/timeConvert";
import { Date_Format_Reverse } from "constants/dateFormat";
import {
  DisplayClientSideMessage,
} from "actions/commonAction";
class DateAndPlaceToReport extends React.Component {
  state = {
    instruction: '',
    joinDate: null,
    officeInTime: null,
    place: null,
    contactToId: null,
    managerId: null,
    buddyId: null,
    instruction: null,
    isLoading: true
  }

  componentDidMount = async () => {

    let { employeeListSelect, companyInformation } = this.props;
    // if (!employeeListSelect && employeeListSelect.length == 0 ) {
      await this.props.GetEmployeeList();
      employeeListSelect = this.props.employeeListSelect;
    // }
    

    // if (!onboardingEmployeeDetail)
    // if (!onboardingEmployeeDetail) {
      await this.props.GetOnboardingEmployeeDetail(this.props.match.params.id);
      let { onboardingEmployeeDetail } = this.props
      // onboardingEmployeeDetail = this.props.onboardingEmployeeDetail;
    // }
    // onboardingEmployeeDetail =  this.props.onboardingEmployeeDetail;
    if (onboardingEmployeeDetail) {
      const { EmployeeOnBoardingDto: {
        Instruction, BuddyId, ManagerId, ContactToId, Place, OfficeInTime,
        JoinDate, WelcomeMessage
      } } = onboardingEmployeeDetail;
      let contactId = employeeListSelect.find(e => e.value == ContactToId);
      let managerId = employeeListSelect.find(e => e.value == ManagerId);
      let buddyId = employeeListSelect.find(e => e.value == BuddyId);
  
      let convertedAppointmentDate = JoinDate ? moment(new Date(JoinDate)).format("YYYY/MM/DD")
        : moment().format('YYYY/MM/DD')
      if (!isEmpty(companyInformation?.IsEnglishDate)) {
        let conversionDate = AD2BS(convertedAppointmentDate).en;
        convertedAppointmentDate = `${conversionDate.year}/${conversionDate.month}/${conversionDate.day}`;
      }
      let officeInTime = OfficeInTime && OfficeInTime.slice(0, OfficeInTime.lastIndexOf(':'));

      let initialData = {
        joinDate: convertedAppointmentDate,
        officeInTime,
        place: Place,
        contactToId: contactId,
        managerId,
        buddyId,
        instruction: Instruction,
        welcomeMessage: WelcomeMessage,
        isLoading: false
      }
      this.setState({ ...initialData });
      this.props.changeActiveTab('0');
    }
    // let empData = this.props.location.state ? this.props.location.state.SubData : {};
    // this.setState({ empData })
    // await this.props.GetBranches()
    // await this.props.GetDepartments()
    // await this.props.GetDesignations();

  }

  // handleFieldChange = (name, value) => {

  // }
  onClose = () => {
    this.props.history.push('/admin/employee_onboarding/');
  }
  
  handleFieldChange = (name, value) => {
    this.setState({ [name]: value })
  }
  handleFormSubmit = async () => {
    const {
      instruction, joinDate, officeInTime,
      place, contactToId, managerId, buddyId, welcomeMessage,
    } = this.state;
    
    if (isEmpty(officeInTime)) {
      this.props.DisplayClientSideMessage('Reach by time is required');
      return
    }
    let errors = await InputValidator(document, this.state);
    if (!isEmpty(errors)) {
      this.setState({ errors });
      return;
    }
    const { companyInformation, employeeId } = this.props;
    let convertedSelectedDate = null;
    if (!companyInformation.IsEnglishDate)
      convertedSelectedDate = joinDate;
    else convertedSelectedDate = Date_Format_Reverse(joinDate);

    if (!companyInformation.IsEnglishDate) {
      convertedSelectedDate = `${BS2AD(convertedSelectedDate).year}/${BS2AD(convertedSelectedDate).month
        }/${BS2AD(convertedSelectedDate).day}`;
    }
    
    let data = {
      // ...empData,
      // addressDto: {
      //     ...empData.addressDto,
      //     EmployeeId: employeeId
      // },
      // employeeDto: {
      //     ...empData.employeeDto,
      //     EmployeeId: employeeId
      // },
      // employeeOnBoardingDto: {
      //     ...empData.employeeOnBoardingDto,
      //     EmployeeId: employeeId
      // },
      // employeeOnBoardingDto: {
      //     ...empData.employeeOnBoardingDto,
      Stage: 2,
      JoinDate: new Date(convertedSelectedDate),
      OfficeInTime: (officeInTime.includes('am') || officeInTime.includes('pm')) ? convertTime12to24(officeInTime) : officeInTime, /* .split(' ')[0], */
      Place: place,
      ContactToId: contactToId.value,
      ManagerId: managerId.value,
      BuddyId: buddyId.value,
      Instruction: instruction,
      EmployeeId: employeeId,
      WelcomeMessage: welcomeMessage
    }
    // delete data.employeeId

    // const options = {
    //     indices: true,
    //     nullsAsUndefineds: false,
    //     booleansAsIntegers: false,
    // };
    // let formData = objectToFormData(dat/a, options);
    // formData = objectToFormData(data.employeeOnBoardingDto, options);
    // formData.append(`Files`, files);
    this.props.handleSubmit(data, '0', 2);
    // this.props.UpdateOnboardingEmployee(formData);
  }

  render() {
    const { 
      instruction, joinDate, officeInTime,
      place, contactToId, managerId, buddyId,
      errors, isLoading
    } = this.state;
    const { employeeListSelect, companyInformation } = this.props;
    if (isLoading) return null;
    return (
      <div className="date-and-place">
        <div className="date-and-place__container">
          <div className="date-and-place__body">
            <div className="date-and-place__header">
              DATE AND PLACE
            </div>
            <div className="date-and-place__firstday">
              <Form
                formName="customdateinput"
                name="joinDate"
                onChange={(name, value) =>
                  this.handleFieldChange(name, value)
                }
                label="First day of office"
                value={joinDate}
                placeholder="Select Date"
                width="200px"
                isNepali={!companyInformation?.IsEnglishDate}
                isTextInNepali={false}
                validators={["required"]}
                errors={errors && errors.joinDate}
              // isTextInNepali={!companyInformation?.IsEnglishDate}
              />
              <Form
                formName="timepicker"
                name="officeInTime"
                value={officeInTime}
                onChange={(name, value) =>
                  this.handleFieldChange(name, value)
                }
                placeholder="Time.."
                defaultMeridiem={"am"}
                label="Reach office by"
                validators={["required"]}
                errors={errors && errors.officeInTime}
              />
              <Form
                // formName="timepicker"
                name="place"
                value={place}
                onChange={(name, value) =>
                  this.handleFieldChange(name, value)
                }
                label="Place"
                error={errors?.place}
                width="200px"
                validators={["required"]}

              />
            </div>
            <div className="date-and-place__firstday">
              <FormGroup
                formName="reactselect"
                onChange={(name, value) => this.handleFieldChange(name, value)}
                name="contactToId"
                value={contactToId}
                options={employeeListSelect}
                // loading={jobProfileList.loading}
                loadingType="skeleton"
                placeholder="Select"
                // disabled={isViewMode}
                validators={["required"]}
                error={errors?.contactToId}
                width="200px"
                label="Whom to Contact"
              />
              <FormGroup
                formName="reactselect"
                onChange={(name, value) => this.handleFieldChange(name, value)}
                name="managerId"
                value={managerId}
                options={employeeListSelect}
                // loading={jobProfileList.loading}
                loadingType="skeleton"
                placeholder="Select"
                // disabled={isViewMode}
                validators={["required"]}
                error={errors?.managerId}
                width="200px"
                label="Your Manager"
              />
              <FormGroup
                formName="reactselect"
                onChange={(name, value) => this.handleFieldChange(name, value)}
                name="buddyId"
                value={buddyId}
                options={employeeListSelect}
                // loading={jobProfileList.loading}
                loadingType="skeleton"
                placeholder="Select"
                // disabled={isViewMode}
                validators={["required"]}
                error={errors?.buddyId}
                width="200px"
                label="Your Buddy"
              />
            </div>
            <div className="date-and-place__instruction">
              <Form
                formName="textarea"
                name="instruction"
                onChange={(name, value) =>
                  this.handleFieldChange(name, value)
                }
                label="Instruction"
                value={instruction}
                placeholder="Instruction to be provided to the employee"
                width="745px"
                validators={['required']}
                error={errors?.instruction}
                // isNepali={!companyInformation?.IsEnglishDate}
              // isTextInNepali={!companyInformation?.IsEnglishDate}
              />
            </div>
            <div className="date-and-place__footer">
              <Button bg="primary" title="Save and Next" onClick={() => this.handleFormSubmit()} />
              <Button bg="subtle" onClick={() => this.onClose()} title="Cancel" />
            </div>
          </div>

        </div>
      </div>
    )
  }
}

DateAndPlaceToReport = connect((state) => ({
  employeeListSelect: state.commonReducer.employeeListSelect,
  companyInformation: state.commonReducer.companyInformation,
  onboardingEmployeeDetail: state.employeeOnboarding.onboardingEmployeeDetail,
  companyInformation: state.commonReducer.companyInformation,

}),
  {
    GetEmployeeList,
    UpdateOnboardingEmployee,
    GetOnboardingEmployeeDetail,
    DisplayClientSideMessage
  }
)(DateAndPlaceToReport);

export default DateAndPlaceToReport
