import React, { useState } from 'react'
import AddressDisplay from './addressDisplay';
import AddressForm from './addressForm' 
import Button from 'components/button'
import Icons from 'components/icons'
import './styles.scss';

function Address(props) {
    let { EmployeeAddress, EmployeeId } = props;
    let [ openAddressForm, setOpenAddress] = useState(false);
    let [ editMode, setEditMode] = useState(false);
    // let [ editMode, setEditMode] = useState(false);
    // EmployeeAddress = null;
    // if(EmployeeAddress)
    const toggleEditMode = (flag) => {
        setOpenAddress(flag);
        setEditMode(flag);
        // setEditMode(flag);
    }
    return (
        <div className="employee-address">
            <AddressDisplay
              EmployeeAddress={EmployeeAddress}
              openAddressForm={openAddressForm}
              toggleEditMode={(flag) => toggleEditMode(flag)}
            />
            {openAddressForm &&
              <AddressForm
                EmployeeId={EmployeeId}
                EmployeeAddress={EmployeeAddress}
                editMode={editMode}
                toggleEditMode={(flag) => toggleEditMode(flag)}
              />}
        </div>
    )
    // else {
    //     return (
    //         <div className="employee-block">
    //             <ul className="employee-docs__additional-list">
    //                 <span className="nodata">No Additional Documents Found</span>
    //             </ul>
    //             <Button
    //                 bg="primary-light"
    //                 title="Add"
    //                 buttonType="icon-button"
    //                 leftIcon={<Icons name="Add" />}
    //                 onClick={() => setOpenAddress(true)}
    //             />
    //         </div>
    //     )
    // }
}

export default Address
