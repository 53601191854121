import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import Clock from "components/clock";
import Icons from "components/icons";
import SvgIcons from "components/svgIcons";
import Button from "components/button";
import FormGroup from "components/form";
import { Date_Format, Time_Format } from "helpers/dateFormat";
import isEmpty from "helpers/isEmpty";
import {
  CheckClockInOutAuthority,
  GetAttendanceByDate,
  SaveAttendanceTime,
} from "./action";

function AttendanceShortcutButton(props: any) {
  const { attendanceByDate, attendanceAuthority } = props;
  const [isInTimeExist, setIsInTimeExist] = useState(false);
  const [isCheck, setCheck] = useState(true);

  useEffect(() => {
    //#region "to implement asycn in tsx"
    async function initialize() {
      await props.CheckClockInOutAuthority();
      let date = new Date();
      await props.GetAttendanceByDate(date, date);
    }
    initialize();

    //#endregion
  }, []);

  useEffect(() => {
    if (attendanceByDate && isCheck) {
      setCheck(false);
      checkInTimeExist();
    }
  }, [attendanceByDate, isCheck]);

  const checkInTimeExist = async () => {
    let todayAttendance = null;
    if (attendanceByDate && attendanceByDate.length > 0) {
      let ta = attendanceByDate.find(
        (f) => Date_Format(f.DateEng) == Date_Format(new Date())
      );
      todayAttendance = Object.assign({}, ta);
    }

    let isInTimeExist = false;
    if (
      todayAttendance &&
      (!isEmpty(todayAttendance.InTimeDv) || !isEmpty(todayAttendance.InTime))
    ) {
      isInTimeExist = true;
    }
    setIsInTimeExist(isInTimeExist);
  };

  const onClockInOut = async () => {
    const isCheckIn = isInTimeExist ? false : true;
    const currentTime = Time_Format(new Date());
    const param = { ManualVisibleTime: currentTime };

    await props.SaveAttendanceTime(isCheckIn, param);
    let date = new Date();
    await props.GetAttendanceByDate(date, date);
    setCheck(true);
  };

  return (
    <>
      {!isEmpty(attendanceAuthority) && attendanceAuthority.IsManualAttendance && (
        <div className="attendance-shortcut">
          <Button
            buttonClass={isInTimeExist ? "color-out" : ""}
            onClick={onClockInOut}
            buttonType="icon-button"
            type="button-outline"
            bg="primary"
            // leftIcon={<Icons name="StopWatch" justSVG />}
            // rightIcon={<Icons name="ArrowUpRight" justSVG />}
            icon={<SvgIcons name="Time" />}
          >
            <span className="mr-sm">
              {isInTimeExist ? "Clock Out" : "Clock In"}{" "}
            </span>
            <Clock />
          </Button>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state: any) => ({
  attendanceAuthority: state.attendanceReducer.attendanceAuthority,
  attendanceByDate: state.attendanceReducer.attendanceByDate,
});

const mapDispatchToProps = {
  CheckClockInOutAuthority,
  GetAttendanceByDate,
  SaveAttendanceTime,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttendanceShortcutButton);
