import React from "react";
import FormInput from "components/form";
import ExportExcel from "components/excel/exportExcel";
import Button from "components/button";
import isEmpty from "isEmpty";
import Form from "components/form";

let export_name = "Employee Loan Requests";

const export_columns = [
  {value: "EmployeeName", label: "Employee"},
  {value: "EmployeeId", label: "EID"},
  {value: "Designation", label: "Designation"},
  {value: "Department", label: "Department"},
  {value: "Branch", label: "Branch"},
  {value: "LoanTypeName", label: "Loan Type"},
  {value: "LoanAmount", label: "Loan Amount"},
  {value: "ActionOn", label: "Request Date"},
  {value: "StatusName", label: "Status"},
  {value: "Purpose", label: "Purpose"},
];

const commonFilter = (props) => {
  const {
    handleAutoText,
    initialData,
    handleDateRangePicker,
    exportSbiLoanStatusHistory,
    handleAutoSelect,
    data,
    handleExcelExport,
    onSearch,
    sbiLoanTypes,
  } = props;

  return (
    <div className="filters-input filters">
      <div className="filters-input__item">
        <FormInput
          formName="searchInput"
          onChange={handleAutoText}
          name="employeeName"
          value={initialData.employeeName}
          placeholder="Employee Name"
          width="200px"
        />
      </div>
      <div className="filters-input__item">
        <Form
          formName="reactdaterange"
          startDate={initialData.startDate}
          endDate={initialData.endDate}
          onChange={handleDateRangePicker}
          placeholder="Start Date - End Date"
          width="240px"
        />
      </div>
      <div className="filters-input__item">
        <FormInput
          formName="reactselect"
          onChange={handleAutoSelect}
          name="loanType"
          value={initialData.loanType}
          options={sbiLoanTypes}
          placeholder="Loan Type"
          width="200px"
        />
      </div>

      <Button onClick={() => onSearch()} title="Update" bg="primary" />

      {data.length > 0 && (
        <div className="filters-input__item">
          <ExportExcel
            onClick={handleExcelExport}
            // fileName={export_name}
            fileName={initialData.activeTab.label}
            columns={export_columns}
            displayText="Download"
            sheets={[{ sheetName: export_name, data: exportSbiLoanStatusHistory }]}
            className="button button-white"
          />
        </div>
      )}
    </div>
  );
};

export default commonFilter;
