// export const TravelInformationPath="/travel/travel_information";
export const MyTravelRequestPath = "/travel/my_travel_request";
export const TeamTravelRequestPath = "/travel/travel_request";
export const CreateTravelRequestPath = "/travel/create_travel_request";
export const UpdateTravelRequestPath = "/travel/update_travel_request";
export const SettlementRequestPath = "/travel/settlement_request";
export const PendingSettlementPath = "/travel/pending_settlement";
export const TravelHistoryPath = "/travel/travel_history";
export const TravelAllowanceExpensePath = "/travel/allowance_expense";
export const TravelCategoryPath = "/travel/travel_category";

//Paroll Links
export const PayrollComponent = "/payroll/components";
export const AttendanceRegisterPath = "/payroll/components/attendance_register";
export const LeaveAbsencePath = "/payroll/components/leave_absence";
export const SalaryAdjustmentPath = "/payroll/components/salary_adjustment";
export const ArrearCalculationPath = "/payroll/components/arrear_calculation";
export const StopPaymentPath = "/payroll/components/stop_payment";
export const RFAdjustmentPath = "/payroll/components/rf_adjustment";
export const IncomeCalculationPath = "/payroll/components/income_calculation";
export const TaxCalculationPath = "/payroll/components/tax_calculation";
export const TaxAdjustmentPath = "/payroll/components/tax_adjustment";
export const ReviewAndFinalizePath = "/payroll/components/review_and_finalize";

//Recruit Links
export const JobCreatePath = "/recruit/job_create";
export const JobListing = "/recruit/job_listing";
export const PositionDetailsPath = "/recruit/job_create/position_details";
export const ApplicationsPath = "/recruit/job_create/applications";
export const HiringStagePath = "/recruit/job_create/hiring_stage";
export const ScorecardPath = "/recruit/job_create/scorecard";
export const HiringTeamPath = "/recruit/job_create/hiring_team";
export const RecruitDashboardPath = "/recruit/dashboard";
export const RecruitDashboardInformationPath = "/recruit/dashboard/information";
export const RecruitDashboard = "/recruit/dashboard";
export const RequistionRequestPath = "/recruit/requisition_request";
export const MyRequistionRequestPath = "/recruit/my_requisition_request";
export const CreateNewJobRequisitionPath = "/recruit/create_job_requisition";
export const UpdateJobRequisitionPath = "/recruit/update_job_requisition";
export const JobApplyPath = "/recruit/job_apply";
export const JobListingPath = "/recruit/job_listing";
export const JobDetailsPath = "/recruit/job_details/";

//Employee Links
export const PersonalInfo = "/employee/personal-information";
export const JobInfo = "/employee/job-information";
export const EmployeePayroll = "/employee/payroll";
export const EmployeeDashBoard = "/employee";
export const EmployeeProfile = "/employee/profile";
export const EmployeeRequest = "/employee/request";
export const EmployeeRequestReviewPath = "/employee/request/review";
export const MyRequestPath = "/employee/request/my_request";
export const LeaveRequestDetailsPath = "/employee/leave_details";
export const OverTimeRequestDetailsPath = "/employee/overtime_details";
export const MyRequestHistoryPath = "/employee/request/my_history";
export const EmployeeRequestHistoryPath = "/employee/request/team_history";
export const EmployeeReportsPath = "/employee/reports";
// export const JobDescriptionPath = "/employee/profile/job-description";

//Admin Dashboard Links
export const EmployeeListPath = "/admin/employee_list";
export const AttendanceTrackerPath = "/admin/attendance_tracker";
export const DisciplinaryActionPath = "/admin/disciplinary_action";
export const DisciplinaryActionStagesPath = "/admin/disciplinary_stages";
export const ViewDisciplinaryActionPath = "/admin/view_disciplinary_action/";

//#region Task Tacker
export const LegalClientPath = "/task_tracker/legal_client";
export const ActivityDashboardPath = "/task_tracker/dashboard";
export const TaskTrackerCommonTaskPath = "/task_tracker/common_tasks";
export const TaskTrackerReportPath = "/task_tracker/reports";
export const NewActivityPath = "/task_tracker/new_activity";
export const ActivityAndBillingRolesPath = "/task_tracker/roles";
export const ActivityReportPath = "/task_tracker/activity_report";
export const HoursWorkedByClientPath = "/task_tracker/hours_by_client";
export const HoursWorkedByPeoplePath = "/task_tracker/hours_by_people";
export const HoursCommittedPath = "/task_tracker/hours_committed";
export const PeopleInProjectPath = "/task_tracker/people_in_project";
export const RawReportPath = "/task_tracker/raw_report";
//#endregion Task Tracker

//Tasks Links
export const TaskBoardSingleLink = "/tasks/boards/board/";
export const TaskBoardTodo = "/tasks/boards/to-do";
export const TaskBoards = "/tasks/boards/";
export const TaskBoardListLinks = "/tasks/boards/list";
//End-of Tasks Links

export const HRDashboardPath = "/hr/dashboard";
export const HRDashboardCommonTaskPath = "/hr/common_tasks";
export const HRDashboardReportsPath = "/hr/reports";
export const HRDashboardSettingsPath = "/hr/settings";
export const PayrollDashboardPath = "/payroll/dashboard";
export const PayrollDashboardCommonTaskPath = "/payroll/common_tasks";
export const PayrollDashboardReportsPath = "/payroll/reports";
export const PayrollDashboardSettingsPath = "/payroll/settings";
export const LeaveTimeDashboardPath = "/leavetime/dashboard";
export const LeaveTimeDashboardCommonTaskPath = "/leavetime/common_tasks";
export const LeaveTimeDashboardReportsPath = "/leavetime/reports";
export const LeaveTimeDashboardSettingsPath = "/leavetime/settings";

//Roles & Permissions
export const AuthorizedUser = "/roles_permission/authorized_user";
export const Roles = "/roles_permission/roles";
export const ManagersLinks = "/roles_permission/managers";
export const ClustersLink = "/roles_permission/clusters";

//Job Profile
export const JobProfilePath = "/admin/job_profile";
export const KSALibrariesPath = "/admin/ksa_libraries";
export const CreateJobProfilePath = "/admin/create_job_profile";
export const UpdateJobProfilePath = "/admin/update_job_profile/";
export const CreateJobDescriptionPath = "/admin/create_job_description";
export const UpdateJobDescriptionPath = "/admin/update_job_description/";

export const ExportPdfPath = "/export_pdf";

//Common
export const LoginPath = "/login";
export const Logout = "/sign-out";
export const Settings = "/settings";
export const NotificationsPath = "/notifications";

//#region Leaves and time
export const EmployeeLeavesPath = "/employee/leaves";
export const EmployeeTimePath = "/employee/time/attendance";
export const EmployeeTimeRequestPath = "/employee/time-request";
//#endregion
