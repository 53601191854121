import React, { Component } from 'react'
import { GridComponent, ColumnsDirective, ColumnDirective, Edit, Toolbar, Page, Inject } from '@syncfusion/ej2-react-grids';


export class GrossTable extends Component {
    constructor() {
        super(...arguments);
        this.template = this.gridTemplate;
    }
    gridTemplate(props) {
        return (
            <tr className="leave-row bordered">
            <td className="">
                <a href="#">
                    <span>
                        {props.Name}
                    </span>
                </a>
                
            </td>
            <td className="">
                <span className="text-bold">
                    {props['Gross Salary']}
                </span>
            </td>
            <td className="">
                <span className="text-red">
                    {props['PF Deduction']}
                </span>
            </td>
            <td className="">
                <span className="text-red">
                    {props['Tax']}
                </span>
            </td>
            <td className="">
                <span className="text-green">
                    {props["Net Pay"]}
                </span>
            </td>
        </tr>
        );
    }
    render() {
        const data = [
            {'Name':'Kamala Gharti','Gross Salary':'10,000.00','Tax':'5,000.00',
            'PF Deduction':'1,000.00','Net Pay':'13,800.00'},
        ]
        return (
            <div className="arrear-table">
                              <GridComponent dataSource={data} 
                              rowTemplate={this.template.bind(this)}
                              editSettings={this.editSettings}>
                <ColumnsDirective>
                    <ColumnDirective field='Name'  validationRules={this.validationRule} width='100'/>
                    <ColumnDirective field='Gross Salary'  editType='numericedit' validationRules={this.orderidRules}  width='80'/>
                    <ColumnDirective field='Tax' width='80'/>
                    <ColumnDirective field='PF Deduction' width='80'/>
                    <ColumnDirective field='Net Pay' width='70'/>
                </ColumnsDirective>
              </GridComponent>
            </div>
        )
    }
}

export default GrossTable
