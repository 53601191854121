import React, { Component } from "react";
import "../../scss";
import { connect } from "react-redux";
import SearchAndFilter from "./searchAndFilter";
import Table from "./table";
import Breadcrumb from "../../../common/breadcrumb";
import Loaders from "components/loaders";
import { GetRawReport } from "../../action";
import RouteBaseClass from "components/routeBaseClass";
import ExportExcel from "components/excel/exportExcel";

let export_name = "Raw Report";
let export_columns = [
  { label: "DATE", value: "ActivityDateEng" },
  { label: "EMPLOYEE NAME", value: "MemberName" },
  { label: "MATTER ID", value: "MatterId" },
  { label: "CLIENT", value: "ClientName" },
  { label: "PROJECT", value: "ProjectName" },
  { label: "NATURE OF WORK", value: "ActivityTypes" },
  { label: "WORKED HOURS", value: "WorkedHoursText" },
  { label: "BILLABLE HOURS", value: "BillableHoursText" },
  { label: "NON-BILLABLE HOURS", value: "NonBillableHoursText" },
  { label: "HOURLY RATE", value: "HourlyRate" },
  { label: "EXPENSES", value: "PocketExpenses" },
  { label: "ESTIMATED FEE", value: "EstimatedFee" },
  { label: "DESCRIPTION", value: "Description" },
];

export class index extends RouteBaseClass {
  constructor(props) {
    super(props, ["Administrator"]);
    this.state = {
      isLoading: true,
      pageIndex: 0,
      rowsPerPage: 10,
      searchEmployee: "",
      searchClient: "",
      searchProject: "",
      searchMatterId: "",
      fromDate: null,
      toDate: null,
    };
  }

  async componentDidMount() {
    await this.initialize();
    this.setState({ isLoading: false });
  }

  initialize = async (isExport = false) => {
    const {
      searchEmployee,
      searchClient,
      searchProject,
      searchMatterId,
      fromDate,
      toDate,
      pageIndex,
      rowsPerPage,
    } = this.state;

    const data = {
      MemberName: searchEmployee,
      ClientName: searchClient,
      ProjectName: searchProject,
      MatterId: searchMatterId,
      ActivityDateFrom: fromDate,
      ActivityDateTo: toDate,
      PageIndex: pageIndex,
      PageSize: isExport ? "99999" : rowsPerPage,
    };
    await this.props.GetRawReport(data, isExport);
  };

  handleExcelExport = async () => {
    await this.initialize(true);
  };

  handleRowChange = (num) => {
    this.setState({ rowsPerPage: num, pageIndex: 0 }, async () => {
      await this.initialize();
    });
  };

  handleIndexChange = (num) => {
    this.setState({ pageIndex: num }, async () => {
      await this.initialize();
    });
  };

  handleInput = (name, value) => {
    this.setState({ [name]: value, pageIndex: 0 }, async () => {
      await this.initialize();
    });
  };

  handleDateRangePicker = (name, date) => {
    const { startDate, endDate } = date;
    this.setState(
      { fromDate: startDate, toDate: endDate, pageIndex: 0 },
      async () => {
        await this.initialize();
      }
    );
  };

  render() {
    const { isLoading } = this.state;
    const { rawReport, rawReportToExport } = this.props;
    return (
      <div className="common-container">
        <div className="tasktracker-title-section">
          <Breadcrumb name="Raw Report" />
        </div>
        <div>
          <div className="flex">
            <SearchAndFilter
              handleDateRangePicker={this.handleDateRangePicker}
              handleInput={this.handleInput}
              {...this.state}
            />
            <ExportExcel
              onClick={this.handleExcelExport}
              fileName={export_name}
              columns={export_columns}
              sheets={[{ sheetName: export_name, data: rawReportToExport }]}
              className="button button-primary"
            />
          </div>
          {isLoading ? (
            <Loaders loading={isLoading} type="skeleton" name="custom-table" />
          ) : (
            <Table
              data={rawReport}
              {...this.state}
              handleRowChange={this.handleRowChange}
              handleIndexChange={this.handleIndexChange}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rawReport: state.taskTrackerReducer.rawReport,
  rawReportToExport: state.taskTrackerReducer.rawReportToExport,
});

const mapDispatchToProps = {
  GetRawReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
