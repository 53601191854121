import React, { Component } from "react";
import {Tabs,Tab} from 'components/tabs/index.d';
import './styles/style.scss';
import Company from './company';
import Travel from './travel';
import Loan from './loan';
import SalaryTemplate from './salaryTemplate';
import {isFeatureEnabledValue} from 'helpers/checkPermissions';
import { Features } from "helpers/getPermissions";

export class Settings extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          activeTab:''
        };
        this.activeTab=this.activeTab.bind(this)
      }
      activeTab(name){
        this.setState({activeTab:name})
      }
    render() {
        const ref = React.createRef();
        const features = Features();
        return (
            <div className="settings">
                <Tabs>
                    <Tab label="Company Settings" name="Company Settings" >
                        <Company />
                    </Tab>
                    {/* {
                        !isFeatureEnabledValue(features.TravelManagement) ? null :
                        <Tab 
                            label="Travel Settings" name="Travel Settings">
                            <Travel />
                        </Tab>
                    } */}

                    <Tab 
                        label="Salary Settings" name="Salary Settings">
                        <SalaryTemplate />
                    </Tab>
                    <Tab 
                        label="Loan Settings" name="Loan Settings">
                        <Loan />
                    </Tab>
                </Tabs>
            </div>
        );
    }
}

export default Settings;
