import React from "react";
import isEmpty from "isEmpty";
function TravelInformationHistory(props) {
  const history = props.settlementAuditLog;
  return (
    <div className="travel-information-history">
      <div className="travel-information-history__header">
        <span>Change History</span>
      </div>
      <div className="travel-information-history__body history-grid">
        {history.map((x) => {
          let category = x.AuditLogs.find((x) => x.Type === "Travel Category");
          let destinationType = x.AuditLogs.find(
            (x) => x.Type === "Destination"
          );
          let place = x.AuditLogs.find((x) => x.Type === "Place");
          let district = x.AuditLogs.find((x) => x.Type === "District");
          let mode = x.AuditLogs.find((x) => x.Type === "Transport Mode");
          let eligibleAmount = x.AuditLogs.find(
            (x) => x.Type === "Total Eligible Amount"
          );
          return (
            <>
              <div className="history-grid__row">
                <span className="date">{x.ModifiedOn}</span>
                <span className="editor">{x.ChangedBy}</span>
              </div>
              {isEmpty(category) ? null : (
                <div className="history-grid__row">
                  <span className="row-label">Travel Category</span>
                  <div>
                    <span className="old-value">{category.BeforeValue}</span>
                    <span className="new-value">{category.AfterValue}</span>
                  </div>
                </div>
              )}
              {isEmpty(destinationType) ? null : (
                <div className="history-grid__row">
                  <span className="row-label">Destination Type</span>
                  <div>
                    <span className="old-value">
                      {destinationType.BeforeValue}
                    </span>
                    <span className="new-value">
                      {destinationType.AfterValue}
                    </span>
                  </div>
                </div>
              )}
              {isEmpty(place) ? null : (
                <div className="history-grid__row">
                  <span className="row-label">Destination</span>
                  <div>
                    <span className="old-value">{place.BeforeValue}</span>
                    <span className="new-value">{place.AfterValue}</span>
                  </div>
                </div>
              )}
              {isEmpty(district) ? null : (
                <div className="history-grid__row">
                  <span className="row-label">District</span>
                  <div>
                    <span className="old-value">{district.BeforeValue}</span>
                    <span className="new-value">{district.AfterValue}</span>
                  </div>
                </div>
              )}
              {isEmpty(mode) ? null : (
                <div className="history-grid__row">
                  <span className="row-label">Mode</span>
                  <div>
                    <span className="old-value">{mode.BeforeValue}</span>
                    <span className="new-value">{mode.AfterValue}</span>
                  </div>
                </div>
              )}
              {isEmpty(eligibleAmount) ? null : (
                <div className="history-grid__row">
                  <span className="row-label">Total Eligible Amount</span>
                  <div>
                    <span className="old-value">
                      {eligibleAmount.BeforeValue}
                    </span>
                    <span className="new-value">
                      {eligibleAmount.AfterValue}
                    </span>
                  </div>
                </div>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
}
export default TravelInformationHistory;
