import FormGroup from "components/form";
import { ValidationComponent } from "helpers/getComponents";
import React from "react";
import { connect } from "react-redux";

function QualificationForm({
  data,
  onChange,
  educationLevelList,
  experienceDurationList,
  errors,
}) {
  console.log("ONDATAT===>>", data, experienceDurationList);
  const onDataChange = (name, value) => {
    // const { name, value } = e.target;
    onChange(name, value);
  };
  const onSelectChange = (name, value) => {
    onChange(name, value.value);
  };
  const getKeyValue = (data, val) => {
    return data && data.find((item) => item.value == val);
  };
  const inputWidth = "calc(100% - 225px)";
  return (
    <div className="form-block">
      <div className="form-block__title">
        <h3>Desired Qualification</h3>
      </div>
      <div className="form-block__form">
        <ValidationComponent
          rootClassName="inline-form-group select"
          error={errors.QualificationLevelId}
          errorClassName="error__message"
        >
          <FormGroup
            formName="reactselect"
            onChange={onSelectChange}
            name="QualificationLevelId"
            value={getKeyValue(educationLevelList, data.QualificationLevelId)}
            options={educationLevelList}
            width="500px"
            //error={errors.QualificationLevelId}
            label="Education Level"
            className="inline-form-group"
          />
        </ValidationComponent>
        <div className="inline-form-group select">
          <label htmlFor="level"></label>
          <div style={{ width: inputWidth }}>
            <FormGroup
              formName="checkgroup"
              name="IsExperience"
              value={!data.IsExperience}
              label="Experience not necessary"
              className="IsExperience-check"
              checked={!data.IsExperience}
              onChange={(e) =>
                onChange("IsExperience", e.target.checked ? false : true)
              }
              labelPosition={"right"}
            />
          </div>
        </div>
        {data.IsExperience ? (
          <ValidationComponent
            rootClassName="inline-form-group select experience"
            error={errors.ExperienceDurationType}
            errorClassName="error__message"
          >
            <label htmlFor="level">Experience</label>
            <FormGroup
              name="Experience"
              value={data.Experience}
              label=""
              onChange={onDataChange}
              className="inline-form-group"
              type="number"
              minValue="1"
              width={errors.Experience ? "120px" : "60px"}
              error={errors.Experience}
            />

            <FormGroup
              formName="reactselect"
              onChange={onSelectChange}
              name="ExperienceDurationType"
              value={getKeyValue(
                experienceDurationList,
                data.ExperienceDurationType
              )}
              options={experienceDurationList}
              width={"150px"}
            />

            {/* <span style={{ fontSize: 14, color: "#565656", marginLeft: 10 }}>
              Years
            </span> */}
          </ValidationComponent>
        ) : null}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  educationLevelList: state.commonReducer.educationLevelList,
  experienceDurationList: state.commonReducer.experienceDurationList,
});
// connect
export default connect(mapStateToProps, {})(QualificationForm);
