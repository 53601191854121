import { TextareaAutosize, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import FormGroup from "components/form";
import React from "react";
import { FaRegCalendarAlt } from "react-icons/fa";
import { MdError } from "react-icons/md";
import Moment from "react-moment";
import { connect } from "react-redux";
import Select from "react-select";
import isEmpty from "isEmpty";
import DatePickerAsPerSetting from "components/customdaterange/datepickerAsPerSetting";

export class TravelInformation extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let {
      selectedCountry,
      selectedCurrency,
      travelTypeId,
      destinationTypeId,
      reason,
      selectedTravelDestList,
    } = this.props.travelInformationState;

    let {
      modeOfTransportList,
      travelHostList,
      travelHotelList,
      countryList,
      currencyList,
      districtList,
    } = this.props;
    return (
      <>
        <div className="form-row">
          <div className="info-changed">
            <MdError className="changed-icon" />
            <span>
              The information has been changed during settlement request
            </span>
          </div>
        </div>
        <div className="form-row pt-xsm pb-xsm">
          <span className="dest-header">
            {travelTypeId === 1 ? "Domestic Travel" : "International Travel"}
          </span>
        </div>
        <div className="form-row pt-xsm">
          <span className="dest-header">
            {destinationTypeId === 1
              ? "Single Destination"
              : "Multiple Destination"}
          </span>
        </div>
        <div className="form-row">
          <div className="destinations">
            {selectedTravelDestList.map((x, i) => {
              let destination = x.travelDestination;
              return (
                <div className="destination" key={i}>
                  <div className="dest-count">
                    <span>{i + 1}</span>
                  </div>
                  <div className="form-row" style={{ "padding-top": 0 }}>
                    <div>
                      <div className="form-label">
                        <span>District</span>
                      </div>
                      <FormGroup
                        formName="reactselect"
                        name="selectedDistrict"
                        placeholder="Select district"
                        value={destination.selectedDistrict}
                        options={districtList}
                        disabled={true}
                        width={250}
                      />
                      {/* <AutoSelect
                        name="selectedDistrict"
                        placeholder="Select district"
                        value={destination.selectedDistrict}
                        options={districtList}
                        disabled={true}
                        width={250}
                      /> */}
                    </div>
                  </div>
                  <div className="form-row stay">
                    <div>
                      <div className="form-label">
                        <span>Place</span>
                      </div>
                      <FormGroup
                        name={"placeName"}
                        placeholder="type destination"
                        //label="Place"
                        type="text"
                        className="no-margin"
                        value={
                          !isEmpty(destination.placeName)
                            ? destination.placeName
                            : ""
                        }
                        disabled
                      />
                      {/* <InputGroup
                        name={"placeName"}
                        placeholder="type destination"
                        label="Place"
                        type="text"
                        className="no-margin"
                        value={
                          !isEmpty(destination.placeName)
                            ? destination.placeName
                            : ""
                        }
                        disabled
                        // onChange={e => this.handleTextChangeForMultiple(e, key)}
                      /> */}
                    </div>
                    <div className="pl-md">
                      <div className="form-label">
                        <span>Mode of transpotation</span>
                      </div>
                      <Select
                        value={destination.selectedModeOfTransport}
                        closeMenuOnSelect={false}
                        isMulti
                        options={modeOfTransportList}
                        // onChange={(list, selected) =>
                        //   this.handleMultiSelect(list, selected, key)
                        // }
                        isDisabled
                      />
                    </div>
                    <div className="pl-md">
                      <div className="form-label">
                        <span>Duration</span>
                      </div>
                      <DatePickerAsPerSetting
                        // onChange={(name, range) =>
                        //   this.handleDateSelectForMultiple(range, key)
                        // }
                        value={{
                          startDate: destination.startDateEng,
                          endDate: destination.endDateEng,
                        }}
                        // placeholder="Select date"
                        //label="Dates"
                        // isNepali
                        width="250px"
                        disabled={true}
                        isDateRange
                      />

                      {/* <div className="daterangepicker" tabIndex="0">
                        <span className="daterangepicker__date">
                          {!isEmpty(
                            destination.startDateEng && destination.endDateEng
                          ) ? (
                            <>
                              {" "}
                              <span>
                                <Moment format="D MMM YYYY" withTitle>
                                  {destination.startDateEng}
                                </Moment>
                              </span>
                              -
                              <span>
                                <Moment format="D MMM YYYY" withTitle>
                                  {destination.endDateEng}
                                </Moment>
                              </span>
                            </>
                          ) : null}
                        </span>
                        <span className="daterangepicker__icon">
                          <FaRegCalendarAlt />
                        </span>
                      </div> */}
                    </div>
                    <div className="day-night pl-md">
                      <div className="form-label">
                        <span>Days</span>
                      </div>
                      <FormGroup
                        name={"days"}
                        className="small-input"
                        type="number"
                        value={
                          !isEmpty(destination.days) ? destination.days : 0
                        }
                        disabled
                        width="60px"
                      />
                      {/* <InputGroup
                        name={"days"}
                        className="small-input"
                        type="number"
                        value={
                          !isEmpty(destination.days) ? destination.days : 0
                        }
                        disabled
                        //onChange={e => this.handleTextChangeForMultiple(e, key)}
                      /> */}
                    </div>
                    <div className="day-night">
                      <div className="form-label">
                        <span>Nights</span>
                      </div>
                      <FormGroup
                        name={"nights"}
                        className="small-input"
                        type="number"
                        value={
                          !isEmpty(destination.nights) ? destination.nights : 0
                        }
                        disabled
                        width="60px"
                      />
                      {/* <InputGroup
                        name={"nights"}
                        className="small-input"
                        type="number"
                        value={
                          !isEmpty(destination.nights) ? destination.nights : 0
                        }
                        disabled
                        //onChange={e => this.handleTextChangeForMultiple(e, key)}
                      /> */}
                    </div>
                  </div>
                  <div className="form-row stay">
                    <div>
                      <div className="form-label">
                        <span>Host</span>
                      </div>
                      <Autocomplete
                        freeSolo
                        style={{ width: 250 }}
                        options={travelHostList}
                        includeInputInList
                        autoComplete
                        value={destination.hostName}
                        disableClearable
                        disabled
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name={"hostName"}
                            placeholder={"Type or select"}
                            variant="outlined"
                            fullWidth
                            // onChange={e =>
                            //   this.handleTextChangeForMultiple(e, key)
                            // }
                            disabled
                          />
                        )}
                        renderOption={(option) => {
                          const { label, value } = option;
                          return (
                            <div className="input-row">
                              <span className="input-value">{label}</span>
                            </div>
                          );
                        }}
                      />
                    </div>
                    <div className="pl-md">
                      <div className="form-label">
                        <span>Hotel</span>
                      </div>
                      <Autocomplete
                        freeSolo
                        style={{ width: 250 }}
                        options={travelHotelList}
                        // loading={false}
                        includeInputInList
                        autoComplete
                        value={destination.hotelName}
                        disableClearable
                        disabled
                        // onChange={(event, value) =>
                        //   this.handleHostAndHotelDropdown(
                        //     event,
                        //     value,
                        //     key,
                        //     (name = "selectedHotel")
                        //   )
                        // }
                        // onChange={(event, value) => onChange(name,value,event)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name={"hotelName"}
                            placeholder={"Type or select"}
                            variant="outlined"
                            fullWidth
                            // onChange={e =>
                            //   this.handleTextChangeForMultiple(e, key)
                            // }
                            disabled
                          />
                        )}
                        renderOption={(option) => {
                          const { label, value } = option;
                          return (
                            <div className="input-row">
                              <span className="input-value">{label}</span>
                            </div>
                          );
                        }}
                      />
                    </div>
                    <div className="pl-md">
                      <div className="form-label">
                        <span>Host or Hotel Phone Number</span>
                      </div>
                      <FormGroup
                        name={"phoneNumber"}
                        placeholder="host or hotel phone number"
                        type="text"
                        //label="Host or Hotel Phone Number"
                        value={
                          !isEmpty(destination.phoneNumber)
                            ? destination.phoneNumber
                            : ""
                        }
                        disabled
                      />
                      {/* <InputGroup
                        name={"phoneNumber"}
                        placeholder="host or hotel phone number"
                        type="text"
                        label="Host or Hotel Phone Number"
                        value={
                          !isEmpty(destination.phoneNumber)
                            ? destination.phoneNumber
                            : ""
                        }
                        disabled
                        //onChange={e => this.handleTextChangeForMultiple(e, key)}
                      /> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="form-row">
          {/* <div>
            <div className="form-label">
              <span>Reason</span>
            </div>
            <TextareaAutosize
              rows={6}
              cols={136}
              name="Comment"
              className="comment"
              placeholder="If you have any comment type here"
              value={reason}
              disabled
            />
          </div> */}
          <FormGroup
            formName="textarea"
            value={reason}
            placeholder="type why you are travelling.."
            name="reason"
            label="Reason"
            onChange={this.handleTextChange}
            disabled
          />
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {};

TravelInformation = connect(
  (state) => ({
    countryList: state.commonReducer.countryList,
    currencyList: state.commonReducer.currencyList,
    districtList: state.commonReducer.districtList,
    modeOfTransportList: state.travelInformationReducer.modeOfTransportList,
    travelHostList: state.travelInformationReducer.travelHostList,
    travelHotelList: state.travelInformationReducer.travelHotelList,
    travelHostAndHotelPhoneList:
      state.travelInformationReducer.travelHostAndHotelPhoneList,
  }),
  mapDispatchToProps
)(TravelInformation);

export default TravelInformation;
