import React, {useState, useEffect} from 'react'
import man from 'assets/images/man.png'
import Avatar from "react-avatar";
import classnames from 'classnames';
import {getLeaveBydate} from '../../helpers/action';
import {useDispatch,useSelector} from 'react-redux';
import isEmpty from 'isEmpty';
import {getUploadPath} from 'constants/userDetails'

function EmployeeList(props) {
    const {employees} = props;
    const [tab, setTab] = useState('Yesterday');
    const dispatch = useDispatch();
    const {leaveByTime,loading} = useSelector(state=>state.leaveTimeReducer)

    useEffect(()=>{
        dispatch(getLeaveBydate(tab))
    },[])

    const onTabClick = (item) =>{
        setTab(item);
        dispatch(getLeaveBydate(item))
    }
    const classes = (item) =>{
        return classnames({
             "employees-tab":true,
             "active-tab":item === tab,
        })
    }
    return (
        <>
            <div className="employees-tabs">
                <span 
                    onClick={()=>onTabClick('Yesterday')}
                    className={classes('Yesterday')}>
                        Yesterday
                </span>
                <span 
                    onClick={()=>onTabClick('Today')}
                    className={classes('Today')}>
                        Today
                </span>
                <span 
                    onClick={()=>onTabClick('Tomorrow')}
                    className={classes('Tomorrow')}>
                        Tomorrow
                </span>
                <span 
                    onClick={()=>onTabClick('This Week')}
                    className={classes('This Week')}>
                        This Week
                </span>
            </div>
            {
                loading ? <div className="employees-loading">
                        <div className="loader-icon" style={{width:35,height:35}}></div>
                    </div>:
                (
                    <div className="employees-list">
                        {
                            !isEmpty(leaveByTime) ? leaveByTime.map(renderEmployee) : <div className="no-found">No Employee Found</div>
                        }
                    </div>
                )
            }

        </>
        
    )
}

export const renderEmployee = (props)=>{
    return(
        <div className="employees-list__item employee">
            <div className="employee-avatar avatar-50">
                <Avatar
                    className="employee-logo"
                    name={props.name}
                    size={50}
                    round={true}
                    src={props.UrlPhoto ? getUploadPath(props.UrlPhoto) : man}
                />
            </div>
            <div className="employee-value">
                <h5 className="employee-name">{props.Name}</h5>
                <div className="flex">
                    <span className="position">{props.Department}</span>
                </div>
                <div className="employee-time">
                    <span className="time bold-500">{props.FromDay} days ago</span>
                    <span className="date">{props.FromDate} to {props.ToDate}</span>
                </div>
            </div>
        </div>
    )
}

export default EmployeeList
