import React,{useEffect,useState} from 'react'
import Button from 'components/button'
import Modal from 'components/modal'
import FormGroup from 'components/formGroup'
import FormItem from 'components/formGroup/formItem';
import Avatar from 'react-avatar';
import { useDispatch, useSelector } from 'react-redux';
import { addRosterScheduleEmployee, removeRosterScheduleEmployee, updateRosterScheduleEmployee } from 'scenes/roster/helpers/action';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs'

const weekList = [
    {label:'Sun',value:0},
    {label:'Mon',value:1},
    {label:'Tue',value:2},
    {label:'Wed',value:3},
    {label:'Thu',value:4},
    {label:'Fri',value:5},
    {label:'Sat',value:6},
]

function AssignModal(props) {
    const {
        shiftOpen,
        onShiftModalClose,
        startDate,
        onRemoveAssign,
        endDate,
        duration,
        timeData,
        editData,
    } = props;
    const dispatch = useDispatch();
    const [isEdit,setIsEdit] = useState(false)
    const shiftId = timeData?.ShiftScheduleId;
    const method = useForm();
    const {setValue,control,watch} = method;
    const {scheduleAllEmployeeList,scheduleList,teamRoleList} = useSelector((state:any)=>state.roosterReducer);
    const emps = scheduleAllEmployeeList && scheduleAllEmployeeList.map(item=>({label:item?.EmployeeName,value:item.EmployeeId,...item}))
    const schedules = scheduleList && scheduleList.map(item=>({label:item.ShiftName,value:item.ShiftScheduleId,...item}))
    const roles = teamRoleList && teamRoleList.map(item=>({label:item.RoleName,value:item.Id,...item}))
    const employee = watch('EmployeeId')
    useEffect(()=>{
        if(editData  && roles && schedules && emps){
            setIsEdit(true)
            // const shift = schedules && schedules.find(item=>item.ShiftScheduleId === editData?.ShiftScheduleId)
            const empss = emps && emps.find(item=>item.EmployeeId === editData?.EmployeeId)
            const role = roles && roles.find(item=>item.RoleName === editData?.TeamRole);
            // if(shift) setValue('ShiftScheduleId',shift);
            if(empss) setValue('EmployeeId',empss);
            if(role) setValue('TeamRoleId',role);
            setValue('ShiftRotationStartDay',editData?.ShiftRotationStartDay+'');
        }
    },[editData])
// },[editData,roles,schedules,emps])
    // useEffect(()=>{
    //     if(shiftId && schedules){
    //         const shift = schedules && schedules.find(item=>item.ShiftScheduleId === shiftId)
    //         if(shift){
    //             setValue('shiftScheduleId',shift)
    //         }
    //     }
    // },[shiftId,schedules])
    // useEffect(()=>setValue('shiftRotationStartDay',"0"),[])
    const onSubmit = async (data) => {
        // const shift = schedules && schedules.find(item=>item.ShiftScheduleId === shiftId)
        const finalData = {
            ...data,
            EmployeeId:data?.EmployeeId?.value,
            TeamRoleId:data?.TeamRoleId?.value,
            ShiftScheduleId:shiftId,
            // shiftScheduleId:data?.shiftScheduleId?.value,
        }
        let res:any;
        const stDate = startDate ? dayjs(startDate).format("YYYY-MM-DD") : "";
        const edDate = endDate ? dayjs(endDate).format("YYYY-MM-DD") : "";
        if(isEdit){
            res = await dispatch(updateRosterScheduleEmployee({...finalData,id:editData?.ShiftScheduleEmployeeId},stDate,edDate))
        }else{
            res = await dispatch(addRosterScheduleEmployee(finalData,stDate,edDate))
        }
        if(res?.Status){
            onShiftModalClose()
        }
    }
    const onPersonRemove = async () => {
        const res:any = await dispatch(removeRosterScheduleEmployee(editData.ShiftScheduleId,[editData?.EmployeeId]))
        if(res?.Status){
            onShiftModalClose()
        }
    }
    return (
        <Modal
            open={shiftOpen}
            onModalClose={onShiftModalClose}
            className="assign-modal"
            width="575px"
        >
            <div className="assign-modal__container">
                <div className="assign-modal__col assign-modal__col-left">
                    <div className="assign-modal__emp">
                        <Avatar src={employee?.EmployeeImgPath} name={employee?.EmployeeName} size={"40px"} round="100%" />
                        <div className="assign-modal__emp-name">
                            {employee?.EmployeeName}
                        </div>
                        <div className="assign-modal__emp-designation">
                            {employee?.TeamName}
                        </div>
                    </div>
                    <div className="assign-modal__work">
                        <div className="assign-modal__work-col">
                            <div>40:00</div>
                            <div>Weekly Hours</div>
                        </div>
                        <div className="assign-modal__work-col">
                            <div>0:00</div>
                            <div>OT Hours</div>
                        </div>
                        <div className="assign-modal__work-col">
                            <div>0.00</div>
                            <div>OT Cost</div>
                        </div>
                    </div>
                </div>
                <div className="assign-modal__col assign-modal__col-right">
                    <div className="assign-modal__head">
                        {timeData?.dateWithDay}
                    </div>
                    <div className="assign-modal__sub">
                        <div className="assign-modal__sub-head">{timeData?.ShiftName}</div>
                        <div className="assign-modal__sub-time">{duration}</div>
                    </div>
                    <FormGroup method={method} onSubmit={onSubmit}>
                        <div className="assign-modal__form">
                            <div className="assign-modal__form-item">
                                <FormItem
                                    name="EmployeeId"
                                    formName="reactselect"
                                    options={emps}
                                    disabled={isEdit}
                                    control={control}
                                    width="200px"
                                    label="Who is working ?  *"
                                    rules={{ required: "Employee is required!" }}
                                />
                            </div>
                            <div className="assign-modal__form-item">
                                {/* <FormItem
                                    name="ShiftScheduleId"
                                    formName="reactselect"
                                    options={schedules}
                                    width="200px"
                                    label="Shift Pattern"
                                /> */}
                                <div className="assign-modal__form-pattern">
                                    <div className="assign-modal__form-label">Shift Pattern</div>
                                    <div className="assign-modal__form-value">{timeData?.ShiftName}</div>
                                </div>
                            </div>
                            <div className="assign-modal__form-item">
                                <FormItem
                                    name="TeamRoleId"
                                    formName="reactselect"
                                    options={roles}
                                    control={control}
                                    width="200px"
                                    label="Role  *"
                                    rules={{ required: "Role is required!" }}
                                />
                            </div>
                            <div className="assign-modal__form-item">
                            <FormItem
                                formName="radioList"
                                name={"ShiftRotationStartDay"}
                                control={control}
                                label={"Sift Pattern Rotation Start Day  *"}
                                options={weekList}
                                rules={{ required: "Shift Rotation Start Day is required!" }}
                            />
                            </div>
                            <div className="assign-modal__form-btns space-between">
                                <div className="">
                                    <Button title="Cancel" bg="subtle" onClick={() => onShiftModalClose()} />
                                    <Button title="Save" bg="primary" htmlButtonType="submit" />
                                </div>
                                { editData ? <Button title="Remove Person" bg="danger" onClick={() => onPersonRemove()} /> : <Button title="Delete Spot" bg="danger" onClick={() => onRemoveAssign()} /> }
                            </div>
                        </div>
                    </FormGroup>
                </div>
            </div>
        </Modal>
    )
}

export default AssignModal
