import React, { Component } from "react";
import { PDFViewer, StyleSheet } from "@react-pdf/renderer"; //https://react-pdf.org/components
import { connect } from "react-redux";
import { MdCloudDownload } from "react-icons/md";
import isEmpty from "isEmpty";
import { DisplayClientSideMessage } from "actions/commonAction";
import moment from "moment";
import { FaCheckCircle } from "react-icons/fa";
import "../../style.scss";
import Modal from "components/modal";
import Button from "components/button";
import { Tabs, Tab } from "components/tabs/index.d";
import RouteEnum from "constants/routeEnum";
import Form from "components/form";
import RequestedForm from "./requestedForm";
import SalaryHR from "./salaryHR";
import OutstandingLoan from "./outstandingLoan";
import RejectNote from "./rejectNote";
import Breadcrumb from "../../../../common/breadcrumb";
import SvgIcons from "components/svgIcons";
import { currencyCommaSeperator } from "../../../helpers/currencyFormatter";
import AssignApprover from "../../../AssignApprover";
import Recommendation from "./recommendation";
import {
  getHomeLoanDetails,
  getEmployeeOutstandingLoan,
  saveMultipleOutstandingLoan,
  getEmployeeDetailForLoan,
  setEmployeeLoanPulledStatus,
  changeRecommendation,
  saveMultiplePrimeLoanSalaryHead,
  getQualifiedApprovers,
  assignForwaredLoan,
  approveOrRejectLoan,
  forwardLoanToFinance,
  forwardLoanToHR,
  forwardLoanToCAD,
  getVehicleLoanDetails,
  getStaffODLoanDetails,
  getStaffPersonalLoanDetails,
  addPrimeLoanComment,
  deletePrimeLoanComments,
  getPrimeLoanComments,
  getLoanPDFData,
} from "../../../helpers/action";

import LoanPdfTemplate from "./loanPdfTemplate";
import StaffOverDraftPDF from "./staffOverDraftPDF";

const tabName = ["Request Form", "Salary HR", "Outstanding Loans"];
export const getLoanTypeText = (num) => {
  switch (num) {
    case 1:
      return "PrimeHomeLoan";
    case 2:
      return "PrimeStaffODLoan";
    case 3:
      return "PrimeStaffPersonalLoan";
    case 4:
      return "PrimeVehicleLoan";
    case 5:
      return "PrimeStaffAdvanceSalary";
    default:
      return "PrimeHomeLoan";
  }
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "stretch",
    height: "100%",
    width: "100%",
  },
});

export class HomeLoanForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: tabName[0],
      constructionStage: "1",
      FileList: [],
      open: false,
      AssignTo: "",
      ForwardTo: "",
      modalTitle: "Assign To",
      forwardTitle: false,
      isSelfApplied: false,
      comments: [],
      isLoading: true,
      NoOfLoanTaken: null,
      DisbursementDate: null,
      FourWheelerNoOfLoanTaken: null,
      FourWheelerDisbursementDate: null,
      RejectMessage: "",
      ShowRejectModal: false,
      noOutstandingLoans: false,
      openLoanPdf: false,
      tabs: [
        {
          name: tabName[0],
          label: tabName[0],
          key: "0",
          component: <RequestedForm loanDetails={props.location.state} />,
        },
        {
          name: "Salary HR",
          label: "Salary (HR)",
          key: "1",
          component: <SalaryHR {...props} loanDetails={props.location.state} />,
        },
        {
          name: "Outstanding Loans",
          label: "Outstanding Loans",
          key: "2",
          component: (
            <OutstandingLoan
              match={props.match}
              {...props}
              loanDetails={props.location.state}
              onChecknoOutstandingLoans={this.onChecknoOutstandingLoans}
            />
          ),
        },
      ],
    };
  }

  onChecknoOutstandingLoans = (checked, employeeOutstandingLoan) => {
    this.setState({ noOutstandingLoans: checked, employeeOutstandingLoan });
  };

  getCommonLoanData = (loanType, props) => {
    const {
      homeLoanDetail,
      staffPersonalLoanDetail,
      staffODLoanDetail,
      vehicleLoanDetail,
    } = props;
    switch (loanType) {
      case getLoanTypeText(1):
        return {
          LoanApplier: homeLoanDetail?.EmployeeId,
          LoanId: homeLoanDetail?.Id,
          Status: homeLoanDetail?.Status,
          LoanTitle: "Staff Home Loan",
          LoanType: loanType,
          LoanEmployeeId: homeLoanDetail?.EmployeeId,
          NoOfLoanTaken: homeLoanDetail?.NoOfLoanTaken,
          DisbursementDate: homeLoanDetail?.DisbursementDate
            ? moment(homeLoanDetail?.DisbursementDate).format("YYYY/MM/DD")
            : null,
          ForwardTo: homeLoanDetail?.ForwardTo,
          DisbursementEmployeeId: homeLoanDetail?.DisbursementEmployeeId,
          // FourWheelerNoOfLoanTaken: homeLoanDetail?.FourWheelerNoOfLoanTaken,
          // FourWheelerDisbursementDate: homeLoanDetail?.FourWheelerDisbursementDate,
        };
      case getLoanTypeText(4):
        return {
          LoanApplier: vehicleLoanDetail?.EmployeeId,
          LoanId: vehicleLoanDetail?.Id,
          Status: vehicleLoanDetail?.Status,
          LoanTitle: "Staff Vehicle Loan",
          LoanType: loanType,
          LoanEmployeeId: vehicleLoanDetail?.EmployeeId,
          NoOfLoanTaken: vehicleLoanDetail?.NoOfLoanTaken,
          DisbursementDate: vehicleLoanDetail?.DisbursementDate
            ? moment(vehicleLoanDetail?.DisbursementDate).format("YYYY/MM/DD")
            : null,
          ForwardTo: vehicleLoanDetail?.ForwardTo,
          DisbursementEmployeeId: vehicleLoanDetail?.DisbursementEmployeeId,
          FourWheelerNoOfLoanTaken: vehicleLoanDetail?.FourWheelerNoOfLoanTaken,
          FourWheelerDisbursementDate:
            vehicleLoanDetail?.FourWheelerDisbursementDate
              ? moment(vehicleLoanDetail?.FourWheelerDisbursementDate).format(
                  "YYYY/MM/DD"
                )
              : null,
        };
      case getLoanTypeText(2):
        return {
          LoanApplier: staffODLoanDetail?.EmployeeId,
          LoanId: staffODLoanDetail?.Id,
          Status: staffODLoanDetail?.Status,
          LoanTitle: "Staff Overdraft(OD) Loan",
          LoanType: loanType,
          LoanEmployeeId: staffODLoanDetail?.EmployeeId,
          NoOfLoanTaken: staffODLoanDetail?.NoOfLoanTaken,
          DisbursementDate: staffODLoanDetail?.DisbursementDate
            ? moment(staffODLoanDetail?.DisbursementDate).format("YYYY/MM/DD")
            : null,
          ForwardTo: staffODLoanDetail?.ForwardTo,
          DisbursementEmployeeId: staffODLoanDetail?.DisbursementEmployeeId,
          // FourWheelerNoOfLoanTaken: vehicleLoanDetail?.FourWheelerNoOfLoanTaken,
          // FourWheelerDisbursementDate: vehicleLoanDetail?.FourWheelerDisbursementDate,
        };
      case getLoanTypeText(3):
        return {
          LoanApplier: staffPersonalLoanDetail?.EmployeeId,
          LoanId: staffPersonalLoanDetail?.Id,
          Status: staffPersonalLoanDetail?.Status,
          LoanTitle: "Staff Personal Loan",
          LoanType: loanType,
          LoanEmployeeId: staffPersonalLoanDetail?.EmployeeId,
          NoOfLoanTaken: staffPersonalLoanDetail?.NoOfLoanTaken,
          DisbursementDate: staffPersonalLoanDetail?.DisbursementDate
            ? moment(staffPersonalLoanDetail?.DisbursementDate).format(
                "YYYY/MM/DD"
              )
            : null,
          ForwardTo: staffPersonalLoanDetail?.ForwardTo,
          DisbursementEmployeeId:
            staffPersonalLoanDetail?.DisbursementEmployeeId,
          // FourWheelerNoOfLoanTaken: vehicleLoanDetail?.FourWheelerNoOfLoanTaken,
          // FourWheelerDisbursementDate: vehicleLoanDetail?.FourWheelerDisbursementDate,
        };
      case getLoanTypeText(5):
        return {
          // LoanApplier: staffAdvanceSalaryDetail?.EmployeeId,
          LoanApplier: staffPersonalLoanDetail?.EmployeeId,
          LoanId: staffPersonalLoanDetail?.Id,
          Status: staffPersonalLoanDetail?.Status,
          LoanTitle: "Staff Advance Salary",
          LoanType: loanType,
          LoanEmployeeId: staffPersonalLoanDetail?.EmployeeId,
          NoOfLoanTaken: staffPersonalLoanDetail?.NoOfLoanTaken,
          DisbursementDate: staffPersonalLoanDetail?.DisbursementDate
            ? moment(staffPersonalLoanDetail?.DisbursementDate).format(
                "YYYY/MM/DD"
              )
            : null,
          ForwardTo: staffPersonalLoanDetail?.ForwardTo,
          DisbursementEmployeeId:
            staffPersonalLoanDetail?.DisbursementEmployeeId,
          // FourWheelerNoOfLoanTaken: vehicleLoanDetail?.FourWheelerNoOfLoanTaken,
          // FourWheelerDisbursementDate: vehicleLoanDetail?.FourWheelerDisbursementDate,
        };
    }
  };

  async componentDidMount() {
    const {
      match,
      getHomeLoanDetails,
      getVehicleLoanDetails,
      getStaffODLoanDetails,
      getStaffPersonalLoanDetails,
      getEmployeeDetailForLoan,
      location: { state },
      getPrimeLoanComments,
    } = this.props;

    const { id } = match.params;
    if (id && state) {
      if (state.data == getLoanTypeText(1)) await getHomeLoanDetails(id);
      else if (state.data == getLoanTypeText(2))
        await getStaffODLoanDetails(id);
      else if (state.data == getLoanTypeText(3))
        await getStaffPersonalLoanDetails(id, "1");
      else if (state.data == getLoanTypeText(4))
        await getVehicleLoanDetails(id);
      else if (state.data == getLoanTypeText(5))
        await getStaffPersonalLoanDetails(id, "2");
      let {
        loanApproverList,
        user: { EmployeeId },
      } = this.props;
      if (isEmpty(loanApproverList)) {
        await this.props.getQualifiedApprovers(EmployeeId);
      }

      const {
        LoanApplier,
        LoanId,
        Status,
        LoanTitle,
        LoanEmployeeId,
        LoanType,
        ForwardTo,
        NoOfLoanTaken,
        DisbursementDate,
        FourWheelerNoOfLoanTaken,
        FourWheelerDisbursementDate,
        DisbursementEmployeeId,
      } = await this.getCommonLoanData(state.data, this.props);
      let isSelfApplied = EmployeeId == LoanEmployeeId;

      loanApproverList = this.props.loanApproverList;
      loanApproverList =
        loanApproverList &&
        loanApproverList.filter(
          (loan) => loan.Id != EmployeeId && loan.IsHR == true
        );
      const { comments, totalLoanComments } =
        await this.formatSavedLoanComments(
          LoanType,
          LoanId,
          isSelfApplied,
          loanApproverList
        );
      if (!isEmpty(LoanApplier)) await getEmployeeDetailForLoan(LoanApplier);
      if (!isEmpty(Status) && !isEmpty(LoanId)) {
        this.setState({
          loanApproverList,
          // AssignTo: loanApproverList[0].Id,
          LoanId,
          Status,
          LoanTitle,
          isSelfApplied,
          LoanType,
          comments,
          totalLoanComments,
          isLoading: false,
          commentDisable: false,
          NoOfLoanTaken,
          DisbursementDate,
          FourWheelerNoOfLoanTaken,
          FourWheelerDisbursementDate,
          ForwardTo,
          DisbursementEmployeeId,
          LoanEmployeeId,
          viewMode: state.viewMode,
        });
      } else this.props.history.goBack();
    }
  }

  formatSavedLoanComments = async (
    LoanType,
    LoanId,
    isSelfApplied,
    loanApproverList
  ) => {
    await this.props.getPrimeLoanComments({ LoanType, LoanId });
    const {
      loanComments,
      user: { EmployeeId },
    } = this.props;

    let totalLoanComments = [];
    let comments = [];
    loanComments &&
      loanComments.forEach((comment) => {
        let commentData = {
          commentType: comment.CommentType,
          commentId: comment.Id,
          commenter: comment.EmployeeName,
          comment: comment.Comment,
          commenterId: comment.EmployeeId,
          commentDate: moment(
            new Date(comment.CreatedOn),
            "YYYYMMDDHHmmss"
          ).format("MMM DD, YYYY hh:mm A"),
        };
        if (isSelfApplied) {
          if (comment.CommentType == 2) {
            let apprv = loanApproverList.filter(
              (appr) => appr.Id == comment.EmployeeId
            );
            if (!isEmpty(apprv)) comments.push(commentData);
          }
        } else if (comment.CommentType == 1) comments.push(commentData);
        totalLoanComments.push(commentData);
      });
    return { comments, totalLoanComments };
  };

  componentWillUnmount() {
    this.props.setEmployeeLoanPulledStatus(false);
  }

  handleInput = async (name, value, e) => {
    if (name == "DisbursementDate" || name == "FourWheelerDisbursementDate") {
      this.setState({ [name]: value }, () => this.handleBlur(name, value));
    } else this.setState({ [name]: value });
  };
  handleBlur = async (name, value) => {
    let {
      NoOfLoanTaken,
      DisbursementDate,
      FourWheelerNoOfLoanTaken,
      FourWheelerDisbursementDate,
      LoanId,
      LoanType,
    } = this.state;
    // let data  = {
    //   NoOfLoanTaken,
    //   DisbursementDate,
    //   FourWheelerNoOfLoanTaken,
    //   FourWheelerDisbursementDate
    // }
    if (
      name == "NoOfLoanTaken" ||
      name == "DisbursementDate" ||
      name == "FourWheelerNoOfLoanTaken" ||
      name == "FourWheelerDisbursementDate"
    ) {
      let data = {
        // PrimeOutstandingLoanDto: loanArray,
        // Recommendation: null,
        FourWheelerNoOfLoanTaken,
        FourWheelerDisbursementDate,
        DisbursementDate,
        NoOfLoanTaken,
        LoanId,
        // : parseInt(this.props.match.params.id),
        LoanType,
      };
      await this.props.saveMultipleOutstandingLoan(data);
    }
    // this.setState({ [name]: value});
  };

  onChangeRadio = (name, value) => {
    this.setState({ [name]: value });
  };

  onTabChange = (activeTab) => {
    this.setState({ activeTab, commentDisable: true }, async () => {
      const { comments, totalLoanComments } =
        await this.formatSavedLoanComments(
          this.state.LoanType,
          this.state.LoanId
        );
      this.formatCommentsbyTabId(activeTab, totalLoanComments);
    });
  };

  formatCommentsbyTabId = (activeTab, totalLoanComments) => {
    let comments = [];
    if (activeTab == tabName[0])
      totalLoanComments.forEach((comment) => {
        if (comment.commentType == 1) comments.push(comment);
      });
    else if (activeTab == tabName[1])
      totalLoanComments.forEach((comment) => {
        if (comment.commentType == 2) comments.push(comment);
      });
    else
      totalLoanComments.forEach((comment) => {
        if (comment.commentType == 3) comments.push(comment);
      });
    this.setState({ comments, totalLoanComments, commentDisable: false });
  };

  saveComment = async (Comment) => {
    const {
      user: { EmployeeId },
    } = this.props;
    const { Status, LoanType, LoanId } = this.state;
    let recommendData = {
      Id: 0,
      LoanId,
      LoanType,
      LoanStatus: Status,
      Comment,
      CommentType: this.getActiveTab(),
      EmployeeId,
    };
    await this.props.addPrimeLoanComment(recommendData);
    const { isCommentAdded } = this.props;
    if (isCommentAdded) return isCommentAdded;
  };

  deleteComment = async (Comment) => {
    const { activeTab, LoanType, LoanId } = this.state;
    await this.props.deletePrimeLoanComments(Comment.commentId);
    const { isCommentDeleted } = this.props;
    if (isCommentDeleted) {
      const { comments, totalLoanComments } =
        await this.formatSavedLoanComments(LoanType, LoanId);
      this.setState({ commentDisable: true }, () => {
        this.formatCommentsbyTabId(activeTab, totalLoanComments);
      });
    }
  };

  getActiveTab = () => {
    const { activeTab } = this.state;
    switch (activeTab) {
      case tabName[0]:
        return 1;
      case tabName[1]:
        return 2;
      case tabName[2]:
        return 3;
    }
  };

  handleFormSubmit = async (isApprove) => {
    let { loanApproverList } = this.props;
    const {
      LoanId,
      Status,
      LoanType,
      RejectMessage,
      noOutstandingLoans,
      employeeOutstandingLoan,
      NoOfLoanTaken,
      FourWheelerNoOfLoanTaken,
    } = this.state;
    let submitData = {
      isApprove,
      loanId: LoanId,
      loanType: LoanType,
      RejectMessage,
    };
    const {
      user: {EmployeeId},
    } = this.props;
    if (!isApprove) {
      if (RejectMessage == "")
        this.props.DisplayClientSideMessage(
          "Please state a reason for rejection"
        );
      else {
        await this.props.approveOrRejectLoan(submitData);
        this.setState({ ShowRejectModal: isApprove });
        const { loanApprovedStatus, history } = this.props;
        if (loanApprovedStatus) history.goBack();
      }
    } else if (Status == 1) {
      // loanApproverList = loanApproverList.filter((loan) => loan.IsHR == false);
      loanApproverList = loanApproverList.filter((loan) => loan.Id != EmployeeId && loan.Role == 2);
      this.setState({
        open: true,
        loanApproverList,
        modalTitle: "Forward To",
        approverTitle: "Credit Administration Department",
        forwardTitle: false,
      });
    } else if (Status == 2 || Status == 8) {
      let isTotalRowOnlyPresent =
        employeeOutstandingLoan &&
        employeeOutstandingLoan.filter((loan) => loan.LoanName != "Total");

      if (!noOutstandingLoans && isEmpty(isTotalRowOnlyPresent)) {
        this.props.DisplayClientSideMessage(
          "Please add the outstanding loans (if any) or tick on 'No Outstanding Loan'"
        );
        return;
      }
      if (
        (isEmpty(FourWheelerNoOfLoanTaken) &&
          LoanType === getLoanTypeText(4)) ||
        (isEmpty(NoOfLoanTaken) &&
          (LoanType === getLoanTypeText(4) || LoanType == getLoanTypeText(3)))
      ) {
        this.props.DisplayClientSideMessage(
          "Please fill out the number of times loan taken"
        );
        return;
      }
      
      loanApproverList = loanApproverList.filter(
        (loan) => loan.Id != EmployeeId && (Status == 2 ? loan.Role == 3 : loan.Role == 1)
      );

      this.setState({
        open: true,
        loanApproverList,
        modalTitle: "Forward To",
        approverTitle: Status == 2 ? "Finance Department" : "Human Resource Department",
        forwardTitle: false,
      });
    } else if (Status == 3) {
      await this.props.approveOrRejectLoan(submitData);
      const { loanApprovedStatus, history } = this.props;
      if (loanApprovedStatus) {
        history.push("/loan/employee_loan_requests");
      }
    }
  };

  saveMultipleSalaryHead = async () => {
    let { employeePrimeLoanSalary, Recommendation } = this.props;
    const { LoanType, LoanId } = this.state;
    let PrimeLoanSalaryHead = [];
    employeePrimeLoanSalary &&
      employeePrimeLoanSalary.forEach((sal) => {
        PrimeLoanSalaryHead.push({
          ...sal,
          LoanType,
          LoanId,
        });
      });
    let data = {
      PrimeLoanSalaryHead,
      Recommendation: "",
    };
    await this.props.saveMultiplePrimeLoanSalaryHead(data);
    // const { multiplePrimeLoanSalaryHeadSaved } = this.props;
    // if (multiplePrimeLoanSalaryHeadSaved) this.props.history.goBack();
  };

  saveMultipleOutstandingLoan = async () => {
    const {
      saveMultipleOutstandingLoan,
      employeePayrollOutstandingLoan,
      match,
      Recommendation,
    } = this.props;
    const {
      LoanType,
      DisbursementDate,
      NoOfLoanTaken,
      FourWheelerNoOfLoanTaken,
      FourWheelerDisbursementDate,
    } = this.state;

    let loanArray = [];
    if (match.params.id && !isEmpty(employeePayrollOutstandingLoan))
      employeePayrollOutstandingLoan &&
        employeePayrollOutstandingLoan.forEach((loan) => {
          loanArray.push({
            ...loan,
            Id: 0,
            LoanId: parseInt(match.params.id),
            LoanType,
          });
        });
    let data = {
      PrimeOutstandingLoanDto: loanArray,
      Recommendation,
      DisbursementDate: DisbursementDate,
      NoOfLoanTaken: NoOfLoanTaken,
      LoanId: parseInt(match.params.id),
      LoanType,
      FourWheelerNoOfLoanTaken,
      FourWheelerDisbursementDate,
    };
    await saveMultipleOutstandingLoan(data);
    const { multipleOutstandingLoanSaved } = this.props;
    if (multipleOutstandingLoanSaved) {
      this.props.history.goBack();
    }
  };

  editHomeDetails = async (LoanId, LoanType) => {
    let updateURI = "";
    if (LoanType === getLoanTypeText(1))
      updateURI = RouteEnum.UpdateHomeLoanPath;
    if (LoanType === getLoanTypeText(2))
      updateURI = RouteEnum.UpdateStaffODLoanPath;
    if (LoanType === getLoanTypeText(3))
      updateURI = RouteEnum.UpdateStaffPersonalLoanPath;
    if (LoanType === getLoanTypeText(4))
      updateURI = RouteEnum.UpdateVehicleLoanPath;
    updateURI = updateURI + `/${LoanId}`;
    this.props.history.push(updateURI);
  };

  onClose = () =>
    this.setState({ open: false, ForwardTo: "", loanToForward: null });

  handleAssignerSubmit = async () => {
    const {
      LoanId,
      AssignTo,
      modalTitle,
      ForwardTo,
      Status,
      LoanType,
      LoanEmployeeId,
    } = this.state;
    if (isEmpty(AssignTo) && modalTitle == "Assign To") {
      this.props.DisplayClientSideMessage(
        "Please Select an Assignee to Procced"
      );
      return;
    } else if (isEmpty(ForwardTo) && modalTitle == "Forward To") {
      this.props.DisplayClientSideMessage(
        "Please Select an Assignee to Procced"
      );
      return;
    }
    let data = {
      LoanId,
      EmployeeId: AssignTo,
      LoanType,
      Status,
      RequesterId: LoanEmployeeId,
    };
    if (modalTitle == "Forward To") {
      let forwardData = {
        LoanId,
        LoanType,
        EmployeeId: ForwardTo,
        Status,
      };
      let forwardAssigned = false;
      if (Status == 1) {
        // await this.props.forwardLoanToFinance(forwardData);
        await this.props.forwardLoanToCAD(forwardData);
        const { forwaredToCAD } = this.props;
        if (forwaredToCAD) {
          // await this.saveMultipleSalaryHead();
          forwardAssigned = true;
        }
      } else if (Status == 2) {
        await this.props.forwardLoanToFinance(forwardData);
        const { forwaredToFinance } = this.props;
        if (forwaredToFinance) {
          // await this.saveMultipleSalaryHead();
          forwardAssigned = true;
        }
      } else if (Status == 8) {
        await this.props.forwardLoanToHR(forwardData);
        const { forwaredToHR } = this.props;
        if (forwaredToHR) {
          forwardAssigned = true;
          await this.saveMultipleOutstandingLoan();
        }
      }
      if (forwardAssigned)
        this.setState({ open: false }, () => {
          // if (Status == 1) 
          this.props.history.goBack();
        });
    } else if (modalTitle == "Assign To") {
      await this.props.assignForwaredLoan(data);
      const { isLoanAssigned } = this.props;
      if (isLoanAssigned) {
        this.props.history.goBack();
      }
    }
  };

  handleAssignInput = (name, value) => {
    const { modalTitle } = this.state;
    if (modalTitle == "Forward To") {
      this.setState({ ForwardTo: value });
    } else if (modalTitle == "Assign To") this.setState({ AssignTo: value });
  };

  openAssignModal = () => {
    const {
      Status,
      noOutstandingLoans,
      employeeOutstandingLoan,
      NoOfLoanTaken,
      FourWheelerNoOfLoanTaken,
      LoanType,
      isSelfApplied,
    } = this.state;
    const {
      user: { EmployeeId },
    } = this.props;
    let approverTitle = "Human Resource Department";
    let loanApproverList = this.props.loanApproverList;
    // if (
    //   !noOutstandingLoans &&
    //   isEmpty(employeeOutstandingLoan) &&
    //   Status == 2
    // ) {
    //   this.props.DisplayClientSideMessage(
    //     "Please add the outstanding loans (if any) or tick on 'No Outstanding Loan'"
    //   );
    //   return;
    // }
    // if (
    //   isEmpty(FourWheelerDisbursementDate) &&
    //   isEmpty(NoOfLoanTaken) &&
    //   Status == 2
    // ) {
    //   this.props.DisplayClientSideMessage(
    //     "Please fill out the number of times taken"
    //   );
    //   return;
    // }
    if (Status == 2 || Status == 8) {
      approverTitle = Status == 2 ? "Credit Administration Department" : "Finance Department";
      loanApproverList =
        loanApproverList &&
        // loanApproverList.filter((loan) => loan.Id != EmployeeId && !loan.IsHR);
        loanApproverList.filter(
          (loan) => 
          // {
          //   console.log(
          //     "Status == 2 ? loan.Role == 2 : loan.Role == 3",
          //     Status,
          //     loan.Role,
          //     loan,
          //     Status == 2 ? loan.Role == 2 : loan.Role == 3,
          //     loan.Id != EmployeeId && (Status == 2 ? loan.Role == 2 : loan.Role == 3)
          //   );
            loan.Id != EmployeeId && (Status == 2 ? loan.Role == 2 : loan.Role == 3)
          // }
        );
      let isTotalRowOnlyPresent =
        employeeOutstandingLoan &&
        employeeOutstandingLoan.filter((loan) => loan.LoanName != "Total");
    // const { isSelfApplied, Status } = this.state;

      if (!noOutstandingLoans && isEmpty(isTotalRowOnlyPresent) && !isSelfApplied) {
        this.props.DisplayClientSideMessage(
          "Please add the outstanding loans (if any) or tick on 'No Outstanding Loan'"
        );
        return;
      }
      if (
        (isEmpty(FourWheelerNoOfLoanTaken) &&
          LoanType === getLoanTypeText(4)) ||
        // isEmpty(FourWheelerNoOfLoanTaken) &&
        (isEmpty(NoOfLoanTaken) &&
          (LoanType === getLoanTypeText(4) || LoanType == getLoanTypeText(3)))
          && !isSelfApplied
      ) {
        this.props.DisplayClientSideMessage(
          "Please fill out the number of times loan taken"
        );
        return;
      }
    }
    // else if (Status == 8) {
    //   loanApproverList =
    //     loanApproverList &&
    //     // loanApproverList.filter((loan) => loan.Id != EmployeeId && loan.IsHR);
    //     loanApproverList.filter((loan) => loan.Id != EmployeeId && loan.Role == 3);
    // }
    else if (Status == 1 || Status == 3)
      loanApproverList =
        loanApproverList &&
        // loanApproverList.filter((loan) => loan.Id != EmployeeId && loan.IsHR);
        loanApproverList.filter((loan) => loan.Id != EmployeeId && loan.Role == 1);
    // debugger
    this.setState({
      open: true,
      forwardTitle: true,
      approverTitle,
      modalTitle: "Assign To",
      loanApproverList,
    });
  };

  renderFooter = () => {
    const { isSelfApplied, Status, viewMode } = this.state;
    const { isHR } = this.props;
    console.log("render footer ", this.props, this.state)
    if (Status == 2 && isHR) {
      return null;
    } else if (Status > 2 && Status !== 8 && !isHR) {
      return null;
    } else if (!viewMode && !isSelfApplied && (Status < 4 || Status == 8) )
      return (
        <div className="flex">
          {Status == 3 || Status == 1 ? (
            <div className="homeloan-section-footer-item">
              <Button
                buttonType="icon-button"
                bg="danger"
                title={"Reject"}
                leftIcon={<FaCheckCircle className="text-xbg" />}
                onClick={() =>
                  this.setState({ errors: {}, ShowRejectModal: true })
                }
              />
            </div>
          ) : (
            <div className="homeloan-section-footer-item wd-30"></div>
          )}
          <div className="homeloan-section-footer-item">
            <Button
              buttonType="icon-button"
              bg="success"
              title={Status == 3 ? "Approve" : "Forward"}
              leftIcon={<FaCheckCircle className="text-xbg" />}
              onClick={() => this.handleFormSubmit(true)}
            />
          </div>
        </div>
      );
    else return <div className="homeloan-section-footer-item"></div>;
  };

  loanApplicationForm = async () => {
    const { LoanType, LoanId } = this.state;
    let loanPdfData = {};
    await this.props.getLoanPDFData({ LoanId, LoanType });
    const { primeLoanPrintDetail } = this.props;
    if (!isEmpty(primeLoanPrintDetail)) {
      const {
        TotalGrossSalary,
        OutstandingLoan,
        EmployeeDetail,
        // HomeLoanDetail, VehicleLoanDetail, StaffODLoanDetail, StaffPersonalLoanDetail,
        // StaffAdvanceSalaryDetail,
        LoanFilledInformation,
      } = primeLoanPrintDetail;
      let LoanDetails = this.getLoanDetail(LoanType, primeLoanPrintDetail);
      loanPdfData = {
        OutstandingLoan:
          OutstandingLoan &&
          OutstandingLoan.map((loan) => {
            return {
              LoanName: loan?.LoanName || "-",
              Outstanding: loan?.Outstanding || "-",
              AsOn: loan.AsOnDate
                ? moment(loan.AsOnDate).format("YYYY/MM/DD")
                : "-",
              MonthlyDeduction: loan?.MonthlyDeduction || "-",
              CompletesOn: loan.CompletesOn
                ? moment(loan.CompletesOn).format("YYYY/MM/DD")
                : "-",
            };
          }),
        LoanFilledInformation,
        RequestedBy: EmployeeDetail?.Name || "-",
        Position: EmployeeDetail?.Position || "-",
        EmployeeDetail: [
          {
            title: "Staff ID",
            description: LoanDetails?.StaffId || "-",
          },
          {
            title: "Name",
            description: EmployeeDetail?.Name || "-",
          },
          {
            title: "Position",
            description: EmployeeDetail?.Position || "-",
          },
          {
            title: "Branch",
            description: EmployeeDetail?.Branch || "-",
          },
          {
            title: "Department",
            description: EmployeeDetail?.Department || "-",
          },
          {
            title: "Join Date",
            description: EmployeeDetail?.JoinDateNep || "-",
          },
          {
            title: "Permanent Date",
            description: EmployeeDetail?.PermanentDate || "-",
          },
          {
            title: "Retirement Date",
            description: EmployeeDetail?.DateOfRetirement || "-",
          },
          {
            title: "SS Account Number",
            description: EmployeeDetail?.BankACNo || "-",
          },
          {
            title: "Grosss Salary",
            description: !isEmpty(TotalGrossSalary)
              ? `NPR ${currencyCommaSeperator(TotalGrossSalary)}`
              : "-",
          },
        ],
        LoanDetails,
        LoanType,
      };
    }

    this.setState({ loanPdfData }, () => {
      this.setState({ openLoanPdf: true });
    });
  };
  getLoanDetail = (LoanType, primeLoanPrintDetail) => {
    if (LoanType == getLoanTypeText(1)) {
      const { HomeLoanDetail } = primeLoanPrintDetail;
      let IsBuilding = !isEmpty(HomeLoanDetail?.Location);
      let totalLoanRequirmentCosts =
        parseInt(HomeLoanDetail?.LoanPurchaseCost) +
        parseInt(HomeLoanDetail?.RegistrationFee) +
        parseInt(HomeLoanDetail?.TotalConstructionCost) +
        parseInt(HomeLoanDetail?.DrawingApprovalCost) +
        parseInt(HomeLoanDetail?.ContengencyCost) +
        parseInt(HomeLoanDetail?.OtherCost);
      let loanDetail = {
        IsBuilding,
        PropertyDetail: [
          {
            title: "Property Location",
            description: IsBuilding
              ? HomeLoanDetail?.Location
              : HomeLoanDetail?.ApartmentLocation,
          },
          {
            title: "Owner of property",
            description: IsBuilding ? HomeLoanDetail?.PropertyOwner : "-",
          },
          {
            title: "Blue Print Approved",
            description: HomeLoanDetail?.BluePrintApproved ? "Yes" : "No",
          },
          {
            title: "Builder",
            description: IsBuilding
              ? HomeLoanDetail?.LandBuilderName
              : HomeLoanDetail?.ApartmentBuilderName,
          },
        ],
        AreaDetail: [
          {
            title: "Land Area",
            description: HomeLoanDetail?.LandArea,
          },
          {
            title: "Kitta Number",
            description: HomeLoanDetail?.KittaNumber,
          },
          {
            title: "Building Sq. feet ",
            description: HomeLoanDetail?.BuildingArea,
          },
          {
            title: "Storeys",
            description: HomeLoanDetail?.NumberofStoreys,
          },
          {
            title: "Estimated Value",
            description: HomeLoanDetail?.EstimatedValue
              ? `NPR ${HomeLoanDetail?.EstimatedValue}`
              : "-",
          },
          {
            title: "Construction Stage",
            description: HomeLoanDetail?.ConstructionStageValue,
          },
        ],
        // Locations: IsBuilding ? HomeLoanDetail?.Location : HomeLoanDetail?.ApartmentLocation,
        // PropertyOwner: IsBuilding ? HomeLoanDetail?.PropertyOwner : HomeLoanDetail?.PropertyOwner,
        // BluePrintApproved: HomeLoanDetail?.BluePrintApproved,
        // Builder: IsBuilding ? HomeLoanDetail?.LandBuilderName : HomeLoanDetail?.ApartmentBuilderName,
        // LandArea: HomeLoanDetail?.LandArea,
        // KittaNumber: HomeLoanDetail?.KittaNumber,
        // BuildingArea: HomeLoanDetail?.BuildingArea,
        // NumberofStoreys: HomeLoanDetail?.NumberofStoreys,
        // EstimatedValue: HomeLoanDetail?.EstimatedValue,
        // ConstructionStageValue: HomeLoanDetail?.ConstructionStageValue,
        LoanRequirements: [
          {
            value: !isEmpty(HomeLoanDetail?.LoanPurchaseCost)
              ? `NPR ${HomeLoanDetail?.LoanPurchaseCost}`
              : "-",
            label: "Land Purchase Cost",
          },
          {
            value: !isEmpty(HomeLoanDetail?.RegistrationFee)
              ? `NPR ${HomeLoanDetail?.RegistrationFee}`
              : "-",
            label: "Registration Fees",
          },
          {
            value: !isEmpty(HomeLoanDetail?.TotalConstructionCost)
              ? `NPR ${HomeLoanDetail?.TotalConstructionCost}`
              : "-",
            label: "Total Construction Cost",
          },
          {
            value: !isEmpty(HomeLoanDetail?.DrawingApprovalCost)
              ? `NPR ${HomeLoanDetail?.DrawingApprovalCost}`
              : "-",
            label: "Drawing, Approval etc",
          },
          {
            value: !isEmpty(HomeLoanDetail?.ContengencyCost)
              ? `NPR ${HomeLoanDetail?.ContengencyCost}`
              : "-",
            label: "Contengency Cost",
          },
          {
            value: !isEmpty(HomeLoanDetail?.OtherCost)
              ? `NPR ${HomeLoanDetail?.OtherCost}`
              : "-",
            label: "Other Cost",
          },
          {
            value: !isEmpty(totalLoanRequirmentCosts)
              ? `NPR ${totalLoanRequirmentCosts}`
              : "0",
            label: "Total",
          },
        ],
        StaffId: HomeLoanDetail?.EmployeeId || "-",
        LoanTitle: "STAFF HOME LOAN",
        RequestedOn: !isEmpty(HomeLoanDetail?.RequestedOn)
          ? moment(HomeLoanDetail?.RequestedOn).format("YYYY/MM/DD")
          : null,
      };
      return loanDetail;
    } else if (LoanType == getLoanTypeText(2)) {
      const { StaffODLoanDetail } = primeLoanPrintDetail;
      let loanDetail = {
        LoanPurpose: isEmpty(StaffODLoanDetail?.OtherPurpose)
          ? StaffODLoanDetail?.LoanPurpose
          : StaffODLoanDetail?.OtherPurpose,
        RequestAmount: StaffODLoanDetail?.RequestAmount,
        StaffId: StaffODLoanDetail?.EmployeeId || "-",
        LoanTitle: "Staff Overdraft (OD) Loan",
        RequestedOn: !isEmpty(StaffODLoanDetail?.RequestedOn)
          ? moment(StaffODLoanDetail?.RequestedOn).format("YYYY/MM/DD")
          : null,
      };
      return loanDetail;
    } else if (LoanType == getLoanTypeText(3)) {
      const { StaffPersonalLoanDetail } = primeLoanPrintDetail;
      let loanDetail = {
        LoanPurpose: isEmpty(StaffPersonalLoanDetail?.OtherPurpose)
          ? StaffPersonalLoanDetail?.LoanPurpose
          : StaffPersonalLoanDetail?.OtherPurpose,
        RequestAmount: StaffPersonalLoanDetail?.RequestAmount,
        NoOfLoanTaken: StaffPersonalLoanDetail?.NoOfLoanTaken,
        DisbursementDate: StaffPersonalLoanDetail?.DisbursementDate
          ? moment(StaffPersonalLoanDetail?.DisbursementDate).format(
              "YYYY/MM/DD"
            )
          : "-",
        RepayIn: StaffPersonalLoanDetail?.RepayIn,
        StaffId: StaffPersonalLoanDetail?.EmployeeId || "-",
        LoanTitle: "Staff Personal Loan",
        RequestedOn: !isEmpty(StaffPersonalLoanDetail?.RequestedOn)
          ? moment(StaffPersonalLoanDetail?.RequestedOn).format("YYYY/MM/DD")
          : null,
      };
      return loanDetail;
    } else if (LoanType == getLoanTypeText(4)) {
      const { VehicleLoanDetail } = primeLoanPrintDetail;
      let loanDetail = {
        LoanPurpose: isEmpty(VehicleLoanDetail?.OtherPurpose)
          ? VehicleLoanDetail?.LoanPurpose
          : VehicleLoanDetail?.OtherPurpose,
        VehicleName: VehicleLoanDetail?.VehicleName,
        ModelName: VehicleLoanDetail?.ModelName,
        EngineCapacity: VehicleLoanDetail?.EngineCapacity,
        CompanyName: VehicleLoanDetail?.CompanyName,
        QuotationPrice: VehicleLoanDetail?.QuotationPrice,
        RegistrationDate: VehicleLoanDetail?.RegistrationDate,
        RegistrationDate: VehicleLoanDetail?.RegistrationDate,
        RequestAmount: VehicleLoanDetail?.RequestAmount,
        NoOfLoanTaken: VehicleLoanDetail?.NoOfLoanTaken,
        DisbursementDate: VehicleLoanDetail?.DisbursementDate
          ? moment(VehicleLoanDetail?.DisbursementDate).format("YYYY/MM/DD")
          : "-",
        FourWheelerNoOfLoanTaken: VehicleLoanDetail?.FourWheelerNoOfLoanTaken,
        FourWheelerDisbursementDate:
          VehicleLoanDetail?.FourWheelerDisbursementDate
            ? moment(VehicleLoanDetail?.FourWheelerDisbursementDate).format(
                "YYYY/MM/DD"
              )
            : "-",
        StaffId: VehicleLoanDetail?.EmployeeId || "-",
        LoanTitle: "Staff Vehicle Loan",
        RequestedOn: !isEmpty(VehicleLoanDetail?.RequestedOn)
          ? moment(VehicleLoanDetail?.RequestedOn).format("YYYY/MM/DD")
          : null,
      };
      return loanDetail;
    } else if (LoanType == getLoanTypeText(5)) {
      const { StaffAdvanceSalaryDetail } = primeLoanPrintDetail;
      let loanDetail = {
        LoanPurpose: isEmpty(StaffAdvanceSalaryDetail?.OtherPurpose)
          ? StaffAdvanceSalaryDetail?.LoanPurpose
          : StaffAdvanceSalaryDetail?.OtherPurpose,
        RequestAmount: StaffAdvanceSalaryDetail?.RequestAmount,
        // NoOfLoanTaken: StaffAdvanceSalaryDetail?.NoOfLoanTaken,
        // DisbursementDate: StaffAdvanceSalaryDetail?.DisbursementDate,
        StaffId: StaffAdvanceSalaryDetail?.EmployeeId || "-",
        LoanTitle: "Staff Advance Salary",
        RequestedOn: !isEmpty(StaffAdvanceSalaryDetail?.RequestedOn)
          ? moment(StaffAdvanceSalaryDetail?.RequestedOn).format("YYYY/MM/DD")
          : null,
      };
      return loanDetail;
    }
  };

  render() {
    let {
      activeTab,
      tabs,
      comments,
      open,
      AssignTo,
      loanApproverList,
      modalTitle,
      forwardTitle,
      approverTitle,
      ForwardTo,
      LoanTitle,
      isSelfApplied,
      Status,
      LoanId,
      LoanType,
      isLoading,
      commentDisable,
      NoOfLoanTaken,
      DisbursementDate,
      ShowRejectModal,
      FourWheelerNoOfLoanTaken,
      FourWheelerDisbursementDate,
      DisbursementEmployeeId,
      openLoanPdf,
      loanPdfData,
      viewMode,
    } = this.state;
    const {
      isHR,
      user: { EmployeeId },
    } = this.props;
    if (isLoading) return null;
    loanApproverList = loanApproverList?.filter(
      (appr) => appr.Id != EmployeeId
    );

    // const loanPdfData = {
    //   Requester: 'Kolam Dahal',
    //   LoanType: 'Vehicle Loan',
    //   Approve: "Approved",
    //   Salary: [
    //     {
    //       title: 'PF',
    //       amount: "3400",
    //     },
    //     {
    //       title: "Basic",
    //       amount: "6000"
    //     }
    //   ]
    // }
    let distribShown =
      LoanType == getLoanTypeText(3) || LoanType == getLoanTypeText(4);
    console.log("LoanType distirbshown", LoanType, distribShown);
    return (
      <div className="common-container dashboard">
        <div className="tasktracker-title-section">
          <Breadcrumb name="Loan View" />
        </div>
        <div className="homeloan homeloan-view mb-bg">
          <div className="homeloan-body">
            <div className="homeloan-header mb-bg flex justify-between">
              <h3 /* onClick={() => this.loanApplicationForm()} */>
                {LoanTitle}
              </h3>
              <div className="flex">
                {!isSelfApplied && Status == 1 ? (
                  <Button
                    bg="secondary"
                    size="large"
                    onClick={() => this.editHomeDetails(LoanId, LoanType)}
                    title="Edit"
                  />
                ) : null}
                {!isSelfApplied && Status > 2 && Status !== 8 && (
                  // <Button
                  //   bg="secondary"
                  //   size="large"
                  //   onClick={() => this.editHomeDetails(LoanId, LoanType)}
                  //   title="Print"
                  // />
                  <Button
                    // title="Download"
                    // bg="secondary"
                    buttonType="icon-button"
                    leftIcon={<MdCloudDownload />}
                    title="Print"
                    bg="subtle"
                    onClick={() => this.loanApplicationForm()}
                  />
                )}
                {!viewMode && ((Status == 2 || Status == 8) ||
                  (Status == 1 || Status == 3)) && (
                  <Button
                    bg="primary"
                    buttonType="icon-button"
                    leftIcon={
                      <SvgIcons name="PeopleArrows" color="#fff" size={28} />
                    }
                    onClick={() => this.openAssignModal()}
                    title="Assign"
                  />
                )}
              </div>
            </div>
            <Modal
              open={openLoanPdf}
              className="pdf-modal"
              onModalClose={() => this.setState({ openLoanPdf: false })}
            >
              <PDFViewer style={styles.container}>
                {LoanType == getLoanTypeText(1)
                  ? LoanPdfTemplate(loanPdfData)
                  : StaffOverDraftPDF(loanPdfData)}
              </PDFViewer>
            </Modal>
            <Modal
              onModalClose={this.onClose}
              title={modalTitle}
              className="current-modal"
              open={open}
            >
              <AssignApprover
                closeModal={this.onClose}
                hrEmpDetails={loanApproverList}
                ForwardTo={modalTitle == "Assign To" ? AssignTo : ForwardTo}
                approverTitle={approverTitle}
                handleSubmit={this.handleAssignerSubmit}
                handleInput={this.handleAssignInput}
                multiple={false}
                forwardTitle={forwardTitle}
              />
            </Modal>
            <Modal
              open={ShowRejectModal}
              onModalClose={() =>
                this.setState({ errors: {}, ShowRejectModal: false })
              }
              title="Reason of Rejection"
              className="homeloan-outstandingloan-addnewrecord"
            >
              <RejectNote
                data={this.state}
                onChange={this.handleInput}
                handleSubmit={() => this.handleFormSubmit(false)}
                title="Reason of Rejecttion"
                onModalClose={() =>
                  this.setState({ errors: {}, ShowRejectModal: false })
                }
              />
            </Modal>
            <div className="homeloan-section-content mt-bg">
              <Tabs noBackground activeTab={activeTab}>
                {tabs.map((tab) => {
                  if (isSelfApplied && tab.key != "0") return null;
                  else if (isHR && tab.key == "2" && Status < 3) return null;
                  else if (!isHR && tab.key == "1") return null;
                  else {
                    return (
                      <Tab
                        label={tab.label}
                        name={tab.name}
                        id={tab.name}
                        // isDisabled={isSelfApplied}
                        onTabClick={() => this.onTabChange(tab.name)}
                      >
                        {tab.component ? (
                          tab.component
                        ) : (
                          <span>{tab.label}</span>
                        )}
                      </Tab>
                    );
                  }
                })}
              </Tabs>
            </div>
          </div>
          {activeTab == tabName[2] && Status == 2 && distribShown && (
            // (LoanType == getLoanTypeText(3) ||
            //   LoanType == getLoanTypeText(4)) &&
            <div className="flex">
              <div className="homeloan-view-timesTaken">
                {LoanType == getLoanTypeText(4) && (
                  <div className="vehicle_loantaken_header">
                    Two Wheelers (if any)
                  </div>
                )}
                <div className="homeloan-section-content flex">
                  <span className="homeloan__disbursement text-md">
                    Number of times loan taken:
                  </span>
                  {isHR ? (
                    <span className="text-md">
                      {NoOfLoanTaken ? NoOfLoanTaken : "-"}
                    </span>
                  ) : (
                    <Form
                      name="NoOfLoanTaken"
                      value={NoOfLoanTaken}
                      type="number"
                      onChange={this.handleInput}
                      onBlur={this.handleBlur}
                      // validators={["required", "numbers"]}
                      width={"40%"}
                      disabled={
                        DisbursementEmployeeId != EmployeeId &&
                        !isEmpty(DisbursementEmployeeId)
                      }
                    />
                  )}
                </div>
                <div className="homeloan-section-content flex">
                  <span className="homeloan__disbursement text-md">
                    Latest Disbursement date:
                  </span>
                  {isHR ? (
                    <span className="text-md">
                      {DisbursementDate ? DisbursementDate : "-"}
                    </span>
                  ) : (
                    <Form
                      formName="customdateinput"
                      name="DisbursementDate"
                      value={DisbursementDate}
                      // validators={["required"]}
                      onChange={this.handleInput}
                      // onBlur={this.handleBlur}
                      placeholder={"YYYY/MM/DD"}
                      maxDate={new Date()}
                      disabled={
                        DisbursementEmployeeId != EmployeeId &&
                        !isEmpty(DisbursementEmployeeId)
                      }
                      width={"40%"}
                    />
                  )}
                </div>
              </div>
              {LoanType == getLoanTypeText(4) && (
                <div className="homeloan-view-timesTaken">
                  <div className="vehicle_loantaken_header">
                    Four Wheelers (if any)
                  </div>
                  <div className="homeloan-section-content flex">
                    <span className="homeloan__disbursement text-md">
                      Number of times loan taken:
                    </span>
                    {isHR ? (
                      <span className="text-md">
                        {FourWheelerNoOfLoanTaken
                          ? FourWheelerNoOfLoanTaken
                          : "-"}
                      </span>
                    ) : (
                      <Form
                        name="FourWheelerNoOfLoanTaken"
                        value={FourWheelerNoOfLoanTaken}
                        type="number"
                        onChange={this.handleInput}
                        onBlur={this.handleBlur}
                        // validators={["required", "numbers"]}
                        disabled={
                          DisbursementEmployeeId != EmployeeId &&
                          !isEmpty(DisbursementEmployeeId)
                        }
                        width={"40%"}
                      />
                    )}
                  </div>
                  <div className="homeloan-section-content flex">
                    <span className="homeloan__disbursement text-md">
                      Latest Disbursement date:
                    </span>
                    {isHR ? (
                      <span>
                        {FourWheelerDisbursementDate
                          ? FourWheelerDisbursementDate
                          : "-"}
                      </span>
                    ) : (
                      <Form
                        formName="customdateinput"
                        name="FourWheelerDisbursementDate"
                        value={FourWheelerDisbursementDate}
                        // validators={["required"]}
                        onChange={this.handleInput}
                        // onBlur={this.handleBlur}
                        placeholder={"YYYY/MM/DD"}
                        maxDate={new Date()}
                        disabled={
                          DisbursementEmployeeId != EmployeeId &&
                          !isEmpty(DisbursementEmployeeId)
                        }
                        width={"40%"}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          {((activeTab == tabName[0] && !commentDisable && !isSelfApplied) ||
            (isSelfApplied && Status > 3 && !isEmpty(comments))) && (
            <div className="homeloan-view-comments">
              <Recommendation
                submitComment={this.saveComment}
                comments={comments}
                isViewMode={
                  Status > 3 || isSelfApplied || (!isHR && Status > 2)
                }
                showDeleteButton={true}
                deleteComment={this.deleteComment}
                // commentTitle={ isSelfApplied ? "Recommendation" : "Comment"}
                commentTitle={"Recommendation"}
              />
            </div>
          )}
          {(((activeTab == "Salary HR" || activeTab == "Outstanding Loans") &&
            !commentDisable &&
            !isSelfApplied) ||
            (isSelfApplied && Status > 3 && !isEmpty(comments))) &&
            !isSelfApplied && (
              <div className="homeloan-view-comments">
                <Recommendation
                  submitComment={this.saveComment}
                  comments={comments}
                  isViewMode={
                    Status > 3 ||
                    isSelfApplied ||
                    (isHR && activeTab == "Outstanding Loans")
                  }
                  showDeleteButton={true}
                  deleteComment={this.deleteComment}
                  commentTitle={"Recommendation"}
                />
              </div>
            )}
          <div className="homeloan-view-footer">
            <div className="homeloan-section-footer-item">
              <Button
                bg="subtle"
                onClick={() => this.props.history.goBack()}
                title="Close"
              />
            </div>
            <div className="homeloan-section-footer-item wd-90"></div>
            {this.renderFooter()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  homeLoanDetail: state.loanReducer.homeLoanDetail,
  vehicleLoanDetail: state.loanReducer.vehicleLoanDetail,
  staffODLoanDetail: state.loanReducer.staffODLoanDetail,
  staffPersonalLoanDetail: state.loanReducer.staffPersonalLoanDetail,
  employeeOutstandingLoan: state.loanReducer.employeeOutstandingLoan,
  employeePayrollOutstandingLoan:
    state.loanReducer.employeePayrollOutstandingLoan,
  Recommendation: state.loanReducer.Recommendation,
  multipleOutstandingLoanSaved: state.loanReducer.multipleOutstandingLoanSaved,
  employeePrimeLoanSalary: state.loanReducer.employeePrimeLoanSalary,
  loanApproverList: state.loanReducer.loanApproverList,
  loanApprovedStatus: state.loanReducer.loanApprovedStatus,
  isLoanAssigned: state.loanReducer.isLoanAssigned,
  isCommentAdded: state.loanReducer.isCommentAdded,
  loanComments: state.loanReducer.loanComments,
  forwaredToHR: state.loanReducer.forwaredToHR,
  forwaredToFinance: state.loanReducer.forwaredToFinance,
  forwaredToCAD: state.loanReducer.forwaredToCAD,
  isCommentDeleted: state.loanReducer.isCommentDeleted,
  primeLoanPrintDetail: state.loanReducer.primeLoanPrintDetail,
  isHR: state.loanReducer.isHR,
  multiplePrimeLoanSalaryHeadSaved:
    state.loanReducer.multiplePrimeLoanSalaryHeadSaved,
  user: state.auth.user,
});

const mapDispatchToProps = {
  getHomeLoanDetails,
  getEmployeeOutstandingLoan,
  saveMultipleOutstandingLoan,
  getEmployeeDetailForLoan,
  setEmployeeLoanPulledStatus,
  changeRecommendation,
  saveMultiplePrimeLoanSalaryHead,
  getQualifiedApprovers,
  DisplayClientSideMessage,
  assignForwaredLoan,
  approveOrRejectLoan,
  forwardLoanToFinance,
  forwardLoanToHR,
  getVehicleLoanDetails,
  getStaffPersonalLoanDetails,
  getStaffODLoanDetails,
  addPrimeLoanComment,
  getPrimeLoanComments,
  deletePrimeLoanComments,
  getLoanPDFData,
  forwardLoanToCAD,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeLoanForm);
