import React from "react";
import isEmpty from "isEmpty";
import { useSelector, useDispatch } from "react-redux";
import {
  addTask,
  deleteTasks,
  duplicateTask,
  moveTask,
} from "../../../../../../../helpers/action";

const RowFooter = (props) => {
  const { columns, stage } = props;
  const [task, setTask] = React.useState("");
  const [team, setTeam] = React.useState("");
  const dispatch = useDispatch();
  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "task") {
      setTask(value);
    }
    if (name === "team") {
      setTeam(value);
    }
  };
  const onEnter = (e) => {
    if (e.key === "Enter") {
      if (isEmpty(task)) {
        return false;
      }
      debugger;
      const data = {
        taskId: 0,
        boardRefId: props.board.BoardId,
        taskName: task,
        isRecurring: props.board.IsRecurring,
        stageId: stage,
      };
      dispatch(addTask(data, "table"));
      setTask("");
    }
  };

  return (
    <tr>
      <td>
        <div className="taskBlock-item light">
          <div className="taskBlock-border"></div>
          <div className="taskBlock-task taskBlock-task__input">
            <div className="taskBlock-task__item">
              <input
                name="task"
                onChange={onChange}
                value={task}
                placeholder="+ Add"
                onKeyUp={(e) => onEnter(e)}
              />
            </div>
          </div>
        </div>
      </td>

      {columns.map((column, index) =>
        column.hide === true ? null : <td className="td-smd" key={index}></td>
      )}

      <td>
        <span></span>
      </td>
    </tr>
  );
};

export default RowFooter;
