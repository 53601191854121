import isEmpty from "isEmpty";

// export const PositionsValidate = data => {
//   let errors = {};
//   if (!data.InternalJobTitle) {
//     errors.InternalJobTitle = "Required";
//   }
//   return errors;
// };
export const validateJobCreate = (step, data) => {
  let errors = {};
  const positionDetailsValidateField = [
    "InternalJobTitle",
    "ExternalJobTitle",
    "LevelId",
    "DivisionId",
    "BranchId",
    "StartDateEng",
    "EndDateEng",
    "EmployementTypeId",
    "CategoryId",
    "NumberOfOpenings",
    "QualificationLevelId",
    "ExperienceDurationType",
    "Experience",
  ];
  switch (step) {
    case 1:
      positionDetailsValidateField.map(x => {
        if (x === "Experience" || x === "ExperienceDurationType") {
          if (data.RecruitmentPositionDetailDto.IsExperience) {
            if (!data.RecruitmentPositionDetailDto[x]) {
              errors[x] = "Required";
            }
          }
        } else if (!data.RecruitmentPositionDetailDto[x]) {
          errors[x] = "Required";
        }
      });
    case 2:

    case 3:

    case 4:

    case 5:
  }
  return errors;
};
