import React from "react";
import { Tabs, Tab } from "components/tabs/index.d";
import JobDescription from "./jobDescription";
import JobProfile from "./jobProfile";
import JobFamily from "./jobFamily";
import JobRole from "./jobRole";

function jobTabs(props) {
  const { handleJobProfileFormShow } = props;
  const tabs = [
    {
      id: 1,
      label: "JOB DESCRIPTIONS",
      component: JobDescription,
    },
    {
      id: 2,
      label: "JOB PROFILES",
      component: JobProfile,
    },
    // {
    //   id: 3,
    //   label: "JOB FAMILIES",
    //   component: JobFamily,
    // },
    // {
    //   id: 4,
    //   label: "JOB ROLES",
    //   component: JobRole,
    // },
  ];
  return (
    <div>
      {tabs && (
        <Tabs
          // type="block"
          noBackground
        >
          {tabs.map((tab, index) => {
            return (
              <Tab
                label={tab.label}
                name={tab.id}
                id={tab.id}
                key={index}
                // isActive = {activeTabId===tab.tabId}
                // onClick={() => this.handleTabChange(tab.tabId)}
              >
                <tab.component
                  handleJobProfileFormShow={handleJobProfileFormShow}
                />
              </Tab>
            );
          })}
        </Tabs>
      )}
    </div>
  );
}

export default jobTabs;
