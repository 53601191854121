import FormGroup from "components/form";
import isEmpty from "isEmpty";
import React, { useState } from "react";

function AddComponentForm({
  handleComponentFormShow,
  components,
  handleAddComponent,
  sectionId,
  title,
  defaultComponents,
}) {
  let componentBlockToShow = [];
  defaultComponents.map((x) => {
    if (isEmpty(components.find((bloc) => bloc.type === x.Type)))
      componentBlockToShow.push(x);
  });
  let initialCheckedlist = defaultComponents.map((x) => ({
    Id: x.Id,
    Name: x.Name,
    Checked: false,
    Type: x.Type,
  }));
  let [checkedList, setCheckedList] = useState(initialCheckedlist);
  let [customSectionText, setcustomSectionText] = useState("");
  const handleCheckBoxChange = (id) => (event) => {
    let stateCopy = checkedList.map((x) => {
      const o = {
        ...x,
      };
      if (x.Id === id) {
        o["Checked"] = event.target.checked;
      }
      return o;
    });

    setCheckedList(stateCopy);
  };

  return (
    <div className="add-section-form">
      <div className="add-section-form__body">
        <div className="form-row">
          <div style={{ display: "grid", "grid-row-gap": ".5rem" }}>
            <span className="body-header">Add component to</span>
            <span className="comp-header">{title}</span>
          </div>
        </div>
        <div className="form-row">
          <span className="recom-comp-header">Recommended Components</span>
        </div>
        {componentBlockToShow.map((x) => (
          <div className="form-row">
            <FormGroup
              formName="checkgroup"
              name="archive"
              value={x.Id}
              label={x.Name}
              checked={checkedList.find((item) => item.Id === x.Id).Checked}
              onChange={handleCheckBoxChange(x.Id)}
              labelPosition={"right"}
            />
          </div>
        ))}
        <div className="form-row">
          <FormGroup
            name="customSectionText"
            value={customSectionText}
            onChange={(name, value) => setcustomSectionText(value)}
            label="Additional Paragraph"
            // error={errors && errors.positionText}
            validators={["required"]}
            width="250px"
            autoFocus
          />
        </div>
      </div>
      <div className="add-section-form__footer">
        <button
          className="button button-white"
          onClick={() => handleComponentFormShow(false)}
        >
          Cancel
        </button>
        <button
          className="button button-primary"
          onClick={() =>
            handleAddComponent(checkedList, customSectionText, sectionId)
          }
        >
          Add
        </button>
      </div>
    </div>
  );
}

export default AddComponentForm;
