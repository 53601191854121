import React, { Component } from 'react'
import Modal from '../../../components/common/modal'
import DataAutocomplete from '../../../components/dataselect/dataAutocomplete'
import DataSelect from '../../../components/dataselect'
import CommonEmployeeSearch from '../../../components/common/employeeSearch'
import validateStopPayment from '../../../validations/validateStopPayment'
// import InputGroup from '../../../components/form/inputGroup'
import userFormValidation from'../../../validations/useFormValidation'
import AutoSelect from '../../../components/common/autoSelect'
import {connect} from 'react-redux';
import Form from "components/form";
import PropTypes from 'prop-types';
import {addStopPayment,updateStopPayment} from './action'
import classnames from 'classnames'
import isEmpty from 'isEmpty'

export class StopPaymentForm extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            modalOpen:false,
        }
        this.onModalClose=this.onModalClose.bind(this);
    }
    onModalClose=()=>{
        this.setState({modalOpen:false});
    }
    render() {
        const {
            isEdit,toEdit, addStopPayment, onFilter,
            updateStopPayment, companyInformation
        } = this.props;
        let editData = isEdit ? toEdit :null;
        return (
            <>
            
                <button 
                    onClick={()=>this.setState({modalOpen:true})}
                    className={isEdit?"btn btn-primary":"btn btn-grey payment-btn"}
                >
                    {isEdit ? "Edit" :"Add New"}
                </button>
                
                <FormModal
                    modalOpen={this.state.modalOpen}
                    onModalClose={this.onModalClose}
                    addStopPayment={addStopPayment}
                    updateStopPayment={updateStopPayment}
                    toEdit={editData}
                    isEdit={isEdit}
                    companyInformation={companyInformation}
                    onFilter={onFilter}
                />
            </>
        )
    }
}


export const FormModal = ({
    modalOpen,
    onModalClose,
    addStopPayment,
    updateStopPayment,
    toEdit,
    isEdit,
    companyInformation,
    onFilter,
})=>{
    let INITIAL_STATE;
    if(toEdit !== null){
        INITIAL_STATE = {
            StopPaymentId:!isEmpty(toEdit.StopPaymentId)?toEdit.StopPaymentId:0,
            employee:!isEmpty(toEdit.EmployeeId)?{label:toEdit.EmployeeName,value:toEdit.EmployeeId}:{label:'',value:0},
            fromDate:!isEmpty(toEdit.FromDate)?toEdit.FromDate:'',
            toDate: !isEmpty(toEdit.ToDate)?toEdit.ToDate:'',
            ExcludePeriodInWorkDaysCount:!isEmpty(toEdit.ExcludePeriodInWorkDaysCount)?{label:toEdit.ExcludePeriodInWorkDaysCount?'Yes':'No',value:toEdit.ExcludePeriodInWorkDaysCount}:{label:'Yes',value:true},
            notes:!isEmpty(toEdit.Notes)?toEdit.Notes:''
        };
    }else{
        INITIAL_STATE = {
            StopPaymentId:0,
            employee:{label:'',value:0},
            fromDate: "",
            toDate: "",
            ExcludePeriodInWorkDaysCount:{label:'Yes',value:true},
            notes:''
        };
    }
    
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        clearValues,
        isSubmitting
        } = userFormValidation(INITIAL_STATE, validateStopPayment);
        if(isSubmitting && isEmpty(errors)){
            const data = {
                StopPaymentId:values.StopPaymentId,
                EmployeeId:values.employee.value,
                EmployeeName:values.employee.label,
                fromDateNep:values.fromDate,
                toDateNep:values.toDate,
                ExcludePeriodInWorkDaysCount:values.ExcludePeriodInWorkDaysCount.value,
                Notes:values.notes
            }
            let clear = true;
            console.log(data,errors);
            if(isEdit){
                updateStopPayment(data);
            } else{
                addStopPayment(data);
            }
                onFilter()
            if(clear){
                clearValues();
                clear=false;
            }
            
            onModalClose();
        }
        const onSelectChange= (name,value)=>{
            handleChange(name,value)
        }
        const onChange = (e)=>{
            const {name,value}=e.target;
            handleChange(name,value)
        }
        const getToDate = (name, date)=>{
            handleChange('toDate', date)
        }
        const getFromDate = (name, date)=>{
            handleChange('fromDate', date)
        }
        return(
            <Modal
                open={modalOpen}
                onModalClose={onModalClose}
                className="flexify"
                title="Stop Payment Form"
            >
                <form onSubmit={handleSubmit}>
                    <div className="stopPayment-form">
                        <div className="stopPayment-group">
                            {/* <DataAutocomplete
                                    onChange={onSelectChange}
                                    value={values.employee}
                                    label="Employee"
                                    name="employee"
                                    actionType="employee"
                                    width="250px"
                                /> */}
                                {
                                    !isEdit ? 
                                // <DataSelect
                                //     onChange={onSelectChange}
                                //     value={values.employee}
                                //     label="Employee"
                                //     name="employee"
                                //     actionType="employee"
                                //     width="250px"
                                //     isDisabled={true}
                                // />
                                <CommonEmployeeSearch
                                        onChange={onSelectChange}
                                        value={values.employee}
                                        label="Employee"
                                        name="employee"
                                        width="220px"
                                    />
                                :
                                <div className="form-group" style={{width:250}}>
                                    <label htmlFor="employee" className="form-group__label">Employee</label>
                                    <input type="text" value={values.employee.label} className="form-group__input disabled" disabled/>
                                </div>
                                }
                            
                            {errors.employee && <p className="error-text">{errors.employee}</p>}
                        </div>
                        <div className="flex">
                        <div className="stopPayment-group" style={{width:250,marginRiight:20}}>
                            {/* <InputGroup
                                name="fromDate"
                                onChange={onChange}
                                type="date"
                                value={values.fromDate}
                                label="From Date Nepali"
                                error={errors.fromDate}
                                handleBlur={handleBlur}
                                width="250px"
                            /> */}
                            <div className={
                                    classnames(
                                        {
                                            "form-group":true,
                                            "error": !isEmpty(errors.fromDate)
                                        }
                                    )
                                }>
                                <label className="form-group__label">From Date</label>
                                {/* <DatePicker 
                                    value={values.fromDate}
                                    isInNepali 
                                    getDetails 
                                    onDateChange={getFromDate} 
                                    // isEnglish
                                    noFormated
                                /> */}
                                <Form
                                    formName="customdateinput"
                                    name="fromDate"
                                    onChange={getFromDate}
                                    // label="First day of office"
                                    value={values.fromDate}
                                    // value={"2077/9/2"}
                                    placeholder="Select Date"
                                    width="200px"
                                    isNepali={!companyInformation?.IsEnglishDate}
                                    isTextInNepali={false}
                                    validators={["required"]}
                                    errors={errors && errors.fromDate}
                                    isTextInNepali={!companyInformation?.IsEnglishDate}
                                />
                                {/* {errors.fromDate && <div className="form-group__message">{errors.fromDate}</div>} */}
                            </div>
                        </div>
                        <div className="stopPayment-group" style={{width:250}}>
                            {/* <InputGroup
                                name="fromDate"
                                onChange={onChange}
                                type="date"
                                value={values.fromDate}
                                label="From Date Nepali"
                                error={errors.fromDate}
                                handleBlur={handleBlur}
                                width="250px"
                            /> */}
                            <div className={
                                classnames(
                                    {
                                        "form-group":true,
                                        "error": !isEmpty(errors.toDate)
                                    }
                                )
                            }>
                                <label className="form-group__label">To Date</label>
                                <Form
                                    formName="customdateinput"
                                    name="toDate"
                                    onChange={getToDate}
                                    // label="First day of office"
                                    value={values.toDate}
                                    placeholder="Select Date"
                                    width="200px"
                                    isNepali={!companyInformation?.IsEnglishDate}
                                    isTextInNepali={false}
                                    validators={["required"]}
                                    errors={errors && errors.toDate}
                                    isTextInNepali={!companyInformation?.IsEnglishDate}
                                />
                                {/* <DatePicker 
                                    isInNepali 
                                    getDetails 
                                    onDateChange={getToDate} 
                                    // isEnglish
                                    noFormated
                                /> */}
                                {/* {errors.toDate && <div className="form-group__message">{errors.toDate}</div>} */}
                            </div>
                        </div>

                        </div>
                        
                        {/* <div className="stopPayment-group">
                            <InputGroup
                                name="toDate"
                                onChange={onChange}
                                type="date"
                                value={values.toDate}
                                label="To Date Nepali"
                                error={errors.toDate}
                                handleBlur={handleBlur}
                                width="250px"
                            />
                        </div> */}
                        <div className="stopPayment-group">
                            <AutoSelect
                                onChange={onSelectChange}
                                value={values.ExcludePeriodInWorkDaysCount}
                                options={[{label:"Yes",value:true},{label:'No',value:false}]}
                                label="Exclude Working Days"
                                name="ExcludePeriodInWorkDaysCount"
                                width="250px"
                            />
                        </div>
                        <div className="stopPayment-group">
                            <div className="form-group" style={{width:350}}>
                                <label htmlFor="notes" className="form-group__label">Notes</label>
                                <textarea 
                                    name="notes" 
                                    defaultValue ={values.notes}   
                                    onChange={onChange} 
                                    id="notes" 
                                    className="form-group__textarea"
                                    cols="30" rows="5">
                                </textarea>
                            </div>
                        </div>
                        <div className="button-group">
                            <button type="submit" className="btn btn-blue">Save</button>
                        </div>
                    </div>

                </form>
                
            </Modal> 
        )
}

StopPaymentForm.propTypes = {
    addStopPayment: PropTypes.func.isRequired,
    updateStopPayment: PropTypes.func.isRequired,
    // salaryAdjustReducer: PropTypes.object.isRequired,
    // selectReducer: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    companyInformation: state.commonReducer.companyInformation,

    // salaryAdjustReducer: state.salaryAdjustReducer,
    // selectReducer: state.selectReducer
});

export default connect(mapStateToProps, 
    {
        addStopPayment,
        updateStopPayment
    }
    )
    (StopPaymentForm);
