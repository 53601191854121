import React, { useRef } from "react";
import { useState } from "react";
import Avatar from "react-avatar";
import { connect } from "react-redux";

const Activity = (props) => {
  const { boardActivityLog } = props;
  const ref = useRef<any>(null);
  const onScroll = (e) => {
    const scrollHeight = ref.current.scrollHeight - ref.current.clientHeight;
    if (ref.current.scrollTop + 10 >= scrollHeight) {
      props.getActivities && props.getActivities(boardActivityLog.HasMore);
    }
  };
  const onMouseover = (e) => {
    const scrollHeight = ref.current.scrollHeight - ref.current.clientHeight;
    if (scrollHeight == 0) {
      props.getActivities && props.getActivities(boardActivityLog.HasMore);
    }
  };
  const base = props.auth.baseUrl
    ? props.auth.baseUrl.NetFrameworkAppBaseUrl
    : "";
  const keys = Object.keys(boardActivityLog.Records);
  return (
    <ul
      className="activity__list"
      ref={ref}
      onScroll={onScroll}
      onMouseEnter={onMouseover}
    >
      {keys.length === 0 ? (
        <div className="activity__list-item">No new activities</div>
      ) : (
        keys.map((key, index) => (
          <li className="activity__list-item" key={index}>
            <div className="activity__list-item-title">{key}</div>

            {boardActivityLog.Records[key].map((item, j) => (
              <div
                key={j}
                className="activity__list-item-body"
                onClick={() => props.onClick && props.onClick(item)}
              >
                <div className="activity__list-item-body-image">
                  <Avatar
                    src={`${base}${item.ImagePath}`}
                    className="member-image"
                    name={item.CreatedBy}
                    size={"30"}
                    round={true}
                    //   src={`${hostedUrl}/${item.Imagepath}`}
                  />
                </div>
                <div className="activity__list-item-body-desc">
                  <div>
                    <span className="desc desc-name">{item.CreatedBy}</span>{" "}
                    <span className="desc desc-text">{item.Text}</span>
                  </div>
                  <div className="desc desc-sub-text">{`on ${item.TaskName}`}</div>
                </div>
                <div className="activity__list-item-body-time">{item.Time}</div>
              </div>
            ))}
          </li>
        ))
      )}
    </ul>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  board: state.taskReducer.board,
  boardActivityLog: state.taskReducer.boardActivityLog,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Activity);
