import React from 'react';
import Incomes from './incomes'
import Deduction from './deductions'
import './styles.scss'


function Payroll() {
    return (
        <div className="emp-payroll">
            <div className="emp-payroll__container">
                <Incomes />
                <Deduction />
            </div>
        </div>
    )
}

export default Payroll
