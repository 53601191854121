import React from 'react'
import PieChart from 'components/charts/pieChart';
import { useSelector } from 'react-redux';
import {pieChartValue} from 'helpers/getTextLabel'

function HRPieChart() {
    const hrDashboard = useSelector(state => state.hrReducer.hrDashboard );
    const {EmployeeStatusDistribution}  = hrDashboard;
    return (
        <PieChart data={pieChartValue(EmployeeStatusDistribution)} />
    )
}

export default HRPieChart
