import React,{useState,useCallback,useEffect} from 'react';
import Modal from '../modal'
import {useDropzone} from 'react-dropzone';
import RootRef from '@material-ui/core/RootRef';
import { Paper } from "@material-ui/core";
import Button from '../button'
import {changeProfileImage} from 'actions/authAction';
import { useDispatch,useSelector } from 'react-redux';
import './profile.styles.scss';
import isEmpty from 'isEmpty'
import {EmployeeID} from 'constants/userDetails';
import AvatarEdit from "react-avatar-edit";
import dataURLtoFile from 'helpers/dataURLtoFile'
import Icons from 'components/icons';

function ProfilePictureChange() {
    const [open,setOpen] = useState(false);
    const user = useSelector((state:any)=>state.auth.user);
    const EmpID = user.RoleId ===2 ? user.EmployeeId :  user.IsEmployeeType ? user.EmployeeId : user.MappedEmployeeId
    return (
        <>
            <button 
                onClick={()=>setOpen(true)}
                className="header-change-profile__btn">
                <span>Change Profile Picture</span>
            </button>
            <Modal className="profile-change__modal" open={open} onModalClose={()=>setOpen(false)} >
                <UploadPofile  
                    EmployeeId={EmpID}
                    onModalClose={()=>setOpen(false)} />
            </Modal>
        </>

    )
}

const validateImage = (elem) =>{
    const validImageExtension = ["png", "jpg", "jpeg"];
    const file = elem[0];
    const validExtensions = validImageExtension;
    const extension = file.name.split(".")[1];
    let errs:any = {}
    const isValidExtension = validExtensions.find(f => f == extension)
    ? true
    : false;
    if (file.size > 556000) {
    //556000 Byte==556 Kilobyte
    errs.images = "Maximum valid file size is 556 Kb.";
    } else if (!isValidExtension) {
    errs.images = "Invalid Extension! Valid extension are " + validExtensions;
    } 
    return {errs,isValid:isEmpty(errs)}
}

export const UploadPofile = (props) =>{
    const {onModalClose,isNotModal,EmployeeId,previewImage, uploadOnly, uploadProfileImageToParent} =props;
    const [images,setImages] = useState([])
    const [cropImages,setCropImages] = useState('')
    const [errors,setErrors]:any = useState({})
    const [open,setOpen] = useState(false);
    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        const {errs,isValid} = validateImage(acceptedFiles)
        setErrors(errs);
        if(!isValid){
            return false;
        }
        setImages(acceptedFiles)
      }, [])
    const {getRootProps, getInputProps} = useDropzone({onDrop});
    const {ref, ...rootProps} = getRootProps();
    const dispatch = useDispatch();
    const user = useSelector((state:any)=>state.auth.user)
    const getFileImage = async () =>{
        const cropedPhotoName = "croped_" + images[0].name;
        const base64_photo = cropImages;
        const file_photo = await dataURLtoFile(base64_photo, cropedPhotoName);
        return file_photo
    }
    const onValidate = ()=>{
        let errs:any = {};
        if(isEmpty(images)){
            errs.images = 'Browse an image before uploading'
        }
        if(isEmpty(cropImages)){
            errs.cropped = 'Image needed to be cropped before uploading'
        }
        return {errs,isValid:isEmpty(errs)}
    }
    const onSave = async () =>{
        const {errs,isValid} = onValidate();
        setErrors(errs)
        if(!isValid){
            return false;
        }
        const formData = new FormData();
        
        formData.append('EmployeeId',`${EmployeeId}`)
        formData.append('OriginalFile',images[0]);
        const cropFile = await getFileImage();
        formData.append('CroppedFile',cropFile)
        
        if (uploadOnly) {
            uploadProfileImageToParent(formData);
        } else {
            dispatch(changeProfileImage(formData))
        }
        setCropImages(null)
        setImages([])
        !isNotModal && onModalClose()
    }
    return (
        <div className="profile-change">
            <div className="profile-change__container">
                <div className="profile-change__input">
                    <RootRef rootRef={ref}>
                        <Paper {...rootProps}>
                        <input {...getInputProps()} />
                        {
                            !isEmpty(previewImage) && isEmpty(images) ? 
                                (
                                    <div className="profile-change__file">
                                    <div 
                                        style={{display:'block'}}
                                        className="profile-change__images">
                                        <div className="profile-change__images-block">
                                            <div className="profile-change__image">
                                                <img src={previewImage} alt="Profile Image"/>
                                                {/* <div 
                                                    onClick={() => setImages([])}
                                                    className="profile-change__image-remove">
                                                    <Icons name="Close" justSVG />
                                                </div> */}

                                            </div>
                                            <h5>Profile Image</h5>
                                        </div>
                                    </div>
                                    <p>
                                        <div className="text-blue">Replace photo</div>
                                        <div className="">or</div>
                                        <span>Drag and drop here</span>
                                        {
                                            !isEmpty(errors.images) ?
                                            <div className="profile-change__error">
                                                {errors.images}
                                            </div>
                                            :null
                                        }
                                    </p>
                                     
                                </div>
                                ) 
                            :
                            !isEmpty(images) ? 
                            (
                                <div className="profile-change__file">
                                <div className="profile-change__images">
                                    <div className="profile-change__images-block">
                                        <div className="profile-change__image">
                                            <img src={URL.createObjectURL(images[0])} alt=""/>
                                            <div 
                                                onClick={() => setImages([])}
                                                className="profile-change__image-remove">
                                                <Icons name="Close" justSVG />
                                            </div>
                                        </div>
                                        <h5>Original Image</h5>
                                    </div>
                                    <div className="profile-change__images-block">
                                        <div className="profile-change__image cropped">
                                            <img src={cropImages} alt=""/>
                                            <div 
                                                onClick={() => setCropImages(null)}
                                                className="profile-change__image-remove">
                                                <Icons name="Close" justSVG />
                                            </div>
                                            {
                                                errors.cropped ? (
                                                    <div className="profile-change__image-error">
                                                        <span>
                                                            {errors.cropped}
                                                        </span>
                                                    </div>
                                                ) :null
                                            }

                                        </div>
                                        <h5>Cropped Image</h5>
                                    </div>

                                </div>
                                <p>
                                    <div className="text-blue">Replace photo</div>
                                    <div className="">or</div>
                                    <span>Drag and drop here</span>
                                </p>
                                 
                            </div>
                            )
                            :
                            
                            (
                                <p>
                                <div className="text-blue">Browse a file</div>
                                <div className="">or</div>
                                <span>Drag and drop here</span>
                                {
                                    !isEmpty(errors.images) ?
                                    <div className="profile-change__error">
                                        {errors.images}
                                    </div>
                                    :null
                                }
                                
                            </p>
                                
                            )

                        }
                        </Paper>
                    </RootRef>
                </div>
            </div>
            <CropModal 
                cropImages={cropImages}
                setCropImage={setCropImages}
                setImages={setImages} 
                open={open} 
                setOpen={setOpen} 
                images={images} />
            <div className="profile-change__footer">

                {!isEmpty(images) ? 
                <>
                    <Button 
                        onClick={()=>onSave()}
                        bg="primary" 
                        title="Upload Image" />
                    <Button 
                        onClick={()=>setOpen(true)}
                        bg="green" 
                        title="Crop Image" />
                </>

                    :null}
                {
                    isNotModal ? null : (
                        <Button 
                            onClick={()=>onModalClose()}
                            bg="secondary-dark" 
                            title="Cancel" />
                    )
                }

            </div>
        </div>
    )
}

const CropModal = ({images,open,setOpen,setImages,cropImages,setCropImage}) =>{
    
    const [small,setSmall] = useState(null)
    const [error,setError] = useState('')
    useEffect(()=>{
        if(!isEmpty(images)){
            setOpen(true)
        }
    },[images]);
    useEffect(()=>{
        if(!isEmpty(cropImages)){
            setSmall(cropImages)
        }
    },[cropImages]);
    const onCrop = preview => {
        setSmall(preview);
    };
    const onClose = () => {
        setSmall(null);
    };
    const onCropSave = () =>{
        setCropImage(small)
        setOpen(false)
    }
    
    const onBeforeFileLoad = elem => {
        const validImageExtension = ["png", "jpg", "jpeg"];
        const file = elem.target.files[0];
        const validExtensions = validImageExtension;
        const extension = file.name.split(".")[1];
        const isValidExtension = validExtensions.find(f => f == extension)
        ? true
        : false;
        if (file.size > 556000) {
        //556000 Byte==556 Kilobyte
        setError("Maximum valid file size is 556 Kb.");
        elem.target.value = "";
        } else if (!isValidExtension) {
        setError("Valid extension are " + validExtensions);
        elem.target.value = "";
        } else {
            setImages(file);
        }
    };
    if(!isEmpty(images)){
        return(
            <Modal
                className="crop__modal" 
                open={open} 
                title="Crop Profile Photo"
                onModalClose={()=>setOpen(false)}
            >
                <div className="crop-profile">
                    <div className="crop-profile__container">
                        <div className="crop-profile__crop">
                            <AvatarEdit
                                width={550}
                                height={300}
                                onCrop={onCrop}
                                onClose={onClose}
                                onBeforeFileLoad={onBeforeFileLoad}
                                src={URL.createObjectURL(images[0])}
                                label={"Upload photo"}
                                borderStyle={{
                                border: "2px solid #979797",
                                borderStyle: "dashed",
                                borderRadius: "8px",
                                textAlign: "center"
                                }}
                            />
                        </div>
                        <div className="crop-profile__preview">
                            {
                                !isEmpty(small) ? <img src={small} alt=""/>
                                :null
                            }
                        </div>
                    </div>
                    <div className="crop-profile__footer">
                        <Button 
                            onClick={()=>onCropSave()}
                            bg="primary">Crop Image</Button>
                        <Button 
                            onClick={()=>setOpen(false)}
                            bg="secondary-dark">Cancel</Button>
                    </div>
                </div>
            </Modal>
        )
    }
    return null

}

export default ProfilePictureChange
