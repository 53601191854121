import React, { useState, useEffect } from "react";
import { FaRegUserCircle } from "react-icons/fa";
import { FiLock } from "react-icons/fi";
import FormGroup from "components/form";
import isEmpty from "helpers/isEmpty";
import SvgIcon from "components/icons/svgIcons";

const TaskPopList = (props) => {
  const { cols, board, setOpenCustomFiled } = props;
  const [columns, setColumns] = useState([]);
  const [state, setState] = useState({});
  const [dueDate, setDueDate] = useState(true);

  useEffect(() => {
    if (cols) {
      setColumns(cols);
    }
  }, [props.cols]);

  const handleChange = (name, value) => {
    props.onChange && props.onChange(name, value);
  };

  const getColorStyle = (item) => {
    if (!item.hide && item.optional) {
      return { color: "#0068D4" };
    }
    return {};
  };
  const getColor = (item) => {
    if (!item.hide && item.optional) {
      return "#0068D4";
    }
    return "#405471";
  };
  const renderListItem = (isOptional) => {
    return (
      columns &&
      columns
        .filter(
          (x) =>
            (isEmpty(x.optional) && !isOptional) || x.optional === isOptional
        )
        .map((x, index) => (
          <li className="task-popup__item" key={index}>
            <div
              className="task-popup__row"
              onClick={(e) => x.optional && handleChange(x.field, !x.hide)}
            >
              {x.optional ? (
                <div className="task-popup__column task-popup__check">
                  <FormGroup
                    formName="checkgroup"
                    name={x.field}
                    checked={isEmpty(x.hide) ? true : !x.hide}
                    onChange={(e) => {
                      handleChange(x.field, !x.hide);
                    }}
                    value={!x.hide}
                  />
                </div>
              ) : (
                <div className="task-popup__column task-popup__lock">
                  <FiLock />
                </div>
              )}

              <div
                className="task-popup__column task-popup__icon"
                style={getColorStyle(x)}
              >
                {!isEmpty(x.iconName) && (
                  <SvgIcon name={x.iconName} size="18px" color={getColor(x)} />
                )}
              </div>
              <div
                className="task-popup__column task-popup__title"
                style={getColorStyle(x)}
              >
                <span>{x.headerName}</span>
              </div>
            </div>
          </li>
        ))
    );
  };
  return (
    <ul className="task-popup__list">
      {renderListItem(false)}
      <div className="hr"></div>
      {renderListItem(true)}
      <li>
        <div
          className="task-popup__custom-field"
          onClick={(e) => setOpenCustomFiled(true)}
        >
          CUSTOM FIELDS
        </div>
      </li>
    </ul>
  );
};

export default TaskPopList;
