import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import MomentUtils from "@date-io/moment";
import {TextareaAutosize} from "@material-ui/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {
  DisplayClientSideMessage,
  GetCountryList,
  GetCurrencyList,
  GetDistrictList,
  GetEmployeeList,
} from "actions/commonAction";
import classnames from "classnames";
import Modal from "components/modal";
import Upload from "components/common/upload";
import FormGroup from "components/form";
import {getCommaFormattedNumber} from "helpers/getFormattedNumber";
import isEmpty from "isEmpty";
import React from "react";
import {FiPlus} from "react-icons/fi";
import {GoCloudDownload} from "react-icons/go";
import {IoIosCheckmarkCircle, IoMdCloseCircle} from "react-icons/io";
import {MdClose} from "react-icons/md";
import {connect} from "react-redux";
import {reduxForm} from "redux-form";
import CommonCommentsHistory from "../../../commonLayout/commentsHistory";
import {
  fetchTravelInformationData,
  getAllowanceAndExpensesList,
  getApprovalAuthorities,
  getApprovalHistory,
  handleSettlementActive,
  removeOtherExpense,
  saveSettlementDetails,
} from "../../actions";
import TravelInformation from "../../common/travelInformation";
import "../../common/travelSettlement.scss";
import {validateForm} from "../../common/validateTravelInformationForm";
import TravelInformationTab from "../../../commonLayout/travelInformationTab";
import {
  EditTravelRequests,
  GetTravelHostAndHotel,
  GetTravelModeOfTransport,
} from "../../../travelInformation/actions";
import AddOtherExpenses from "./addOtherExpenses";
import AttachBill from "./attachBill";
import {getSettlementSaveData} from "./getSettlementSaveData";
import {getTravelInformationData} from "./getTravelInformationData";
import Form from "components/form";
import {BS2AD, AD2BS} from "helpers/dateConvert";
import {Date_Format, Nepali_Date_Format} from "helpers/dateFormat";
import DatePickerAsPerSetting from "components/customdaterange/datepickerAsPerSetting";

function createTravelDestination() {
  return {
    selectedDistrict: [],
    placeName: null,
    selectedModeOfTransport: [],
    startDate: null,
    endDate: null,
    startDateEng: null,
    endDateEng: null,
    days: 0,
    nights: 0,
    hostName: null,
    selectedHost: [],
    hotelName: null,
    selectedHotel: [],
    phoneNumber: "",
  };
}
const editorConfiguration = {
  //plugins: [ Essentials, Bold, Italic, Paragraph ],
  toolbar: ["bold", "italic"],
};
export class Travel_Settlement extends React.Component {
  constructor(props) {
    super(props);
    let isNepaliDate = !props.companyInformation.IsEnglishDate;
    let selectedJoinedDate = props.travelRequestDetails.value.ReturnDate || null;
    if (selectedJoinedDate) {
      selectedJoinedDate = Nepali_Date_Format(selectedJoinedDate);
      if (isNepaliDate) {
        let conversionDate = AD2BS(selectedJoinedDate).en;
        selectedJoinedDate = `${conversionDate.year}/${conversionDate.month}/${conversionDate.day}`;
      }
    }
    this.state = {
      requestId: this.props.RequestId,
      isAsPlanned: !isEmpty(this.props.travelRequestDetails.value.SettlementId)
        ? this.props.travelRequestDetails.value.IsTravelAsPlan
        : this.props.IsAsPlanned,
      documentsState: null,
      selectedTravelCategory: null,
      isModalShow: false,
      formData: [],
      isAttachBill: true,
      selectedJoinedDate: selectedJoinedDate,
      travelInformationState: {
        travelRequestId: 0,
        travelTypeId: 1,
        destinationTypeId: 1,
        selectedTravelDestList: [{key: 0, travelDestination: createTravelDestination()}],
        selectedCountry: null,
        selectedCurrency: null,
        reason: "",
      },
      isTab1EditDataFetched: false,
      travelSummaryFileList: isEmpty(
        this.props.travelRequestDetails.value.TravelSettlementJobDocDto
      )
        ? []
        : this.props.travelRequestDetails.value.TravelSettlementJobDocDto,
      selectedSummaryFileList: [],
      settlementId: !isEmpty(this.props.travelRequestDetails.value.SettlementId)
        ? this.props.travelRequestDetails.value.SettlementId
        : 0,
      categoryId: this.props.travelRequestDetails.value.CategoryId,
      isEditDataFetched: false,
      isAllRequiredFieldValid: true,
      totalClaimAmount: 0,
      travelSummary: this.props.travelRequestDetails.value.Summary,
      submitStatusId: 0,
      errors: {},
      Note: !isEmpty(this.props.travelRequestDetails.value.Note)
        ? this.props.travelRequestDetails.value.Note
        : "",
    };
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.commonComments = "";
  }
  componentDidMount() {
    const {requestId, EmployeeId} = this.props;
    debugger
    this.props.fetchTravelInformationData(this.state.requestId);
    this.props.getApprovalHistory(requestId);
    this.getApproverDetails(EmployeeId);
  }

  componentDidUpdate() {
    let {isEditDataFetched} = this.state;
    let {isLoading} = this.props;

    if (!isLoading && !isEditDataFetched) {
      let {travelInformationState, newSelectedTravelDestList} = getTravelInformationData(
        this.state,
        this.props
      );
      this.getOverAllDates(newSelectedTravelDestList);
      this.setState({
        isEditDataFetched: true,
        travelInformationState,
      });
    }
    // let { otherExpenses, billsAndClaimsList } = this.props;
    // const totalBillAmount = billsAndClaimsList.reduce(
    //   (a, b) => a + (parseInt(b["Amount"]) || 0),
    //   0
    // );
    // const totalOtherExpenseAmount = otherExpenses.reduce(
    //   (a, b) => a + (parseInt(b["Amount"]) || 0),
    //   0
    // );

    // const totalClaimAmount =
    //   parseInt(totalBillAmount) + parseInt(totalOtherExpenseAmount);
    // this.setClaimAmount(totalClaimAmount);
  }
  getApproverDetails = (EmployeeId) => {
    this.props.getApprovalAuthorities(this.state.submitStatusId, EmployeeId);
  };
  handleInformationTypeChange = (type) => {
    this.setState({isAsPlanned: type});
  };
  handleDocumentsTabState = (data) => {
    this.setState({documentsState: data});
  };
  closeModal = () => {
    this.setState({isModalShow: false});
  };
  handleModalShow = (type, data) => {
    if (type === 2 && isEmpty(data))
      data["Id"] =
        this.props.otherExpenses.length === 0
          ? 1
          : Math.max.apply(
              Math,
              this.props.otherExpenses.map((x) => {
                return x.Id;
              })
            ) + 1;
    this.setState({
      isAttachBill: type === 1 ? true : false,
      formData: data,
      isModalShow: true,
    });
  };
  onFormSubmit = (data) => {
    this.setState({isModalShow: false});
  };
  handleDateChange = (value) => {
    this.setState({selectedJoinedDate: value});
  };
  setSelectedDate = (value) => {
    let date = new Date();
    if (value === 2) {
      date = new Date(
        new Date().setDate(new Date().getDate() + (6 - new Date().getDay() - 1) - 7)
      );
    } else if (value == 1) {
      date = new Date(new Date().setDate(new Date().getDate() - 1));
    }

    let isNepaliDate = !this.props.companyInformation.IsEnglishDate;
    if (date) {
      if (isNepaliDate) {
        date = Nepali_Date_Format(date);
        let conversionDate = AD2BS(date).en;
        date = `${conversionDate.year}/${conversionDate.month}/${conversionDate.day}`;
      } else {
        date = Date_Format(date);
      }
    }
    this.setState({selectedJoinedDate: date});
  };

  getOverAllDates = (selectedTravelDestList) => {
    let overAllDestinationFromDate = null;
    let overAllDestinationToDate = null;
    if (!isEmpty(selectedTravelDestList)) {
      let selectedTravelDestLength = selectedTravelDestList.length;

      overAllDestinationFromDate = !isEmpty(
        selectedTravelDestList[0].travelDestination.startDateEng
      )
        ? selectedTravelDestList[0].travelDestination.startDateEng
        : null;
      overAllDestinationToDate = !isEmpty(
        selectedTravelDestList[selectedTravelDestLength - 1].travelDestination.endDateEng
      )
        ? selectedTravelDestList[selectedTravelDestLength - 1].travelDestination
            .endDateEng
        : null;
    }

    this.setState({
      overAllDestinationFromDate,
      overAllDestinationToDate,
    });
  };
  handleTravelInfoTabState = (name, value, isDestinationDateChanged) => {
    let {travelInformationState} = this.state;
    travelInformationState[name] = value;
    this.setState({travelInformationState});

    if (isDestinationDateChanged) {
      this.getOverAllDates(value);
    }
  };

  handleCategoryChange = (name, selected) => {
    this.setState({categoryId: !isEmpty(selected) ? selected.value : 0});
    const categoryId = !isEmpty(selected) ? parseInt(selected.value) : -1;
    let {selectedTravelDestList} = this.state.travelInformationState;
    const totalDays = selectedTravelDestList.reduce(
      (a, b) => a + (parseInt(b["travelDestination"]["days"]) || 0),
      0
    );
    const totalNights = selectedTravelDestList.reduce(
      (a, b) => a + (parseInt(b["travelDestination"]["nights"]) || 0),
      0
    );
    this.props.getAllowanceAndExpensesList({
      categoryId,
      fromDate: this.state.overAllDestinationFromDate,
      toDate: this.state.overAllDestinationToDate,
      totalDays,
      totalNights,
    });
  };

  handleSaveSettlement = async () => {
    let {travelInformationState, isAsPlanned} = this.state;
    let isNepaliDate = !this.props.companyInformation.IsEnglishDate;
    const isAllRequiredFieldValid = await validateForm({
      travelInformationState,
      isAsPlanned,
    });
    this.setState({isAllRequiredFieldValid: isAllRequiredFieldValid});
    let errors = {};
    let approverData = this.commonComments.onApproveReturn(1);
    if (!approverData || approverData.ApprovalEmployeeId === 0) {
      errors.employee = "Required";
      this.setState({errors});
    }
    const {billsAndClaimsList} = this.props;

    if (
      billsAndClaimsList.find(
        (x) => x.IsBillRequired && (x.BillAmount === 0 || isEmpty(x.BillAmount))
      )
    ) {
      let message = {
        MessageType: "danger",
        Message: "You cannot save without attaching bills.",
      };
      this.props.DisplayClientSideMessage(message);
      return false;
    }

    if (isAllRequiredFieldValid && isEmpty(errors)) {
      let data = getSettlementSaveData(
        this.props,
        this.state,
        approverData,
        isNepaliDate
      );
      data.TravelSettlementApprovalFlowHistoryDto = approverData;
      debugger
      this.props.saveSettlementDetails(
        data,
        this.state.selectedSummaryFileList,
        this.props.billsAndClaimsList,
        this.props.otherExpenses
      );
    } else {
      let message = {
        MessageType: "danger",
        Message: "You cannot save without filling all required fields.",
      };
      this.props.DisplayClientSideMessage(message);
    }
  };

  setSelectedFiles = (fileList) => {
    this.setState({selectedSummaryFileList: fileList});
  };
  // validateForm = async () => {
  //   let isAllRequiredFieldValid = true;
  //   let { travelInformationState, isAsPlanned } = this.state;
  //   if (!isAsPlanned && !isEmpty(travelInformationState)) {
  //     const { selectedTravelDestList, travelTypeId } = travelInformationState;

  //     let isEmptyField =
  //       (await selectedTravelDestList) &&
  //       selectedTravelDestList.some(data => {
  //         let destination = data.travelDestination;

  //         const districtId =
  //           travelTypeId == 1 && !isEmpty(destination.selectedDistrict)
  //             ? destination.selectedDistrict.value
  //             : null;

  //         let transferModes = "";
  //         destination.selectedModeOfTransport.map((mode, index) => {
  //           if (index > 0) {
  //             transferModes = transferModes + "," + mode.value;
  //           } else {
  //             transferModes = mode.value;
  //           }
  //         });

  //         return !(
  //           (travelTypeId == 2 || districtId > 0) &&
  //           !isEmpty(destination.placeName) &&
  //           !isEmpty(transferModes) &&
  //           !isEmpty(destination.startDateEng) &&
  //           !isEmpty(destination.endDateEng) &&
  //           (!isEmpty(destination.hostName) ||
  //             !isEmpty(destination.hotelName)) &&
  //           !isEmpty(destination.phoneNumber)
  //         );
  //       });
  //     isAllRequiredFieldValid = !isEmptyField;
  //   }
  //   this.setState({
  //     isAllRequiredFieldValid: isAllRequiredFieldValid
  //   });
  // };
  setClaimAmount = (totalClaimAmount) => {
    this.setState({totalClaimAmount});
  };

  // handleTravelSummaryChange = (name,value) => {
  //   this.setState({ [name]: value });
  // };

  handleChange = (name, value) => {
    this.setState({[name]: value});
  };
  render() {
    let {
      isAsPlanned,
      delegations,
      documentsState,
      selectedTravelCategory,
      formData,
      isAttachBill,
      travelSummaryFileList,
      travelSummary,
      categoryId,
      selectedJoinedDate,
    } = this.state;
    let {
      travelCategoryList,
      otherExpenses,
      billsAndClaimsList,
      handleSettlementActive,
      isViewMode,
      companyInformation,
    } = this.props;

    let travelRequestDetails = this.props.travelRequestDetails.value;
    const travelClasses = (type) => {
      return classnames({
        badges: true,
        "badges-rounded": true,
        "left-icon": isAsPlanned === type,
        "badges-primary": isAsPlanned === type,
        disabled: isViewMode,
      });
    };
    const totalBillAmount = billsAndClaimsList.reduce(
      (a, b) => a + (parseInt(b["Amount"]) || 0),
      0
    );
    const totalOtherExpenseAmount = otherExpenses.reduce(
      (a, b) => a + (parseInt(b["Amount"]) || 0),
      0
    );

    const totalClaimAmount =
      parseInt(totalBillAmount) + parseInt(totalOtherExpenseAmount);
    //() => this.setClaimAmount(totalClaimAmount);
    const advanceTaken = travelRequestDetails.AdvanceTakenAmount;

    const categoryValue = {
      label:
        travelCategoryList.find((x) => x.value == categoryId) &&
        travelCategoryList.find((x) => x.value == categoryId).label,
      value: categoryId,
    };

    return (
      <div>
        <div className="settlement-form">
          <div className="main-header">
            <div className="form-header">
              <span className="header-title">Travel Settlement</span>
              <span className="header-date">
                {travelRequestDetails.PlaceName}: {travelRequestDetails.TravelDuration}
              </span>
            </div>
            <div className="form-close">
              <MdClose onClick={() => handleSettlementActive(false)} />
            </div>
          </div>
          <div className="first-section section">
            <div className="section__header">
              <span>1. When did you return from travel and joined office?</span>
            </div>
            <div className="section__body">
              <div className="form-row">
                <div className="grid">
                  <span>Planned join date was</span>
                  <span className="date">{travelRequestDetails.PlannedJoinedDate}</span>
                </div>

                <div className="grid">
                  <span>Actual date</span>
                  <DatePickerAsPerSetting
                    onChange={(name, value) => this.handleDateChange(value)}
                    value={selectedJoinedDate}
                    disabled={isViewMode}
                  />
                  {/* <MuiPickersUtilsProvider utils={MomentUtils}>
                    <div className="pickers">
                      <KeyboardDatePicker
                        value={selectedJoinedDate}
                        onChange={this.handleDateChange}
                        // disabled={isViewMode}
                      />
                    </div>
                  </MuiPickersUtilsProvider> */}
                </div>
                {isViewMode ? null : (
                  <div className="flex" style={{marginLeft: "-150px", marginTop: "30px"}}>
                    <span onClick={() => this.setSelectedDate(0)}>Today</span>
                    <span onClick={() => this.setSelectedDate(1)}>Yesterday</span>
                    <span onClick={() => this.setSelectedDate(2)}>Last Friday</span>
                  </div>
                )}
              </div>
              <div className="form-row">
                <div className="deligation-grid grid">
                  <div className="grid__header">
                    <span className="grid__header__name">Delegation revoke</span>
                    <span className="grid__header__tag">
                      Upon completion of this form the following delegation will be
                      revoked
                    </span>
                  </div>
                  <div className="grid__body">
                    {!isEmpty(travelRequestDetails) &&
                      travelRequestDetails.TravelDelegatesApprovalResult.map((x) => {
                        return (
                          <div className="grid__body__row">
                            <div>
                              <span>{x.DelegateType}</span>
                            </div>
                            <div>
                              <span className="employee-name">{x.Name}</span>
                            </div>
                            <div>
                              <span className="actions">{x.Actions}</span>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="second-section section">
            <div className="section__header">
              <span>2. Was the Travel as planned?</span>
            </div>
            <div className="section__body">
              <div className="form-row">
                <div className="badges-group">
                  <div
                    className={travelClasses(true)}
                    onClick={() =>
                      isViewMode ? null : this.handleInformationTypeChange(true)
                    }
                  >
                    {isAsPlanned ? <IoIosCheckmarkCircle /> : null}
                    Yes, it was as planned
                  </div>
                  <div
                    className={travelClasses(false)}
                    onClick={() =>
                      isViewMode ? null : this.handleInformationTypeChange(false)
                    }
                  >
                    {!isAsPlanned ? <IoIosCheckmarkCircle /> : null}
                    No, I would like to change information
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!isAsPlanned ? (
            isViewMode ? (
              !this.props.isLoading ? (
                <div className="section">
                  <div className="section__header">Travel Information</div>
                  <div className="section__body">
                    {!this.props.isLoading ? (
                      <TravelInformation
                        travelInformationState={
                          getTravelInformationData(this.state, this.props)
                            .travelInformationState
                        }
                      />
                    ) : null}
                  </div>
                </div>
              ) : null
            ) : (
              <div className="information-section section">
                <TravelInformationTab
                  isAllRequiredFieldValid={this.state.isAllRequiredFieldValid}
                  travelInformationState={this.state.travelInformationState}
                  createTravelDestination={createTravelDestination()}
                  handleTravelInfoTabState={(name, value, isDestinationDateChanged) =>
                    this.handleTravelInfoTabState(name, value, isDestinationDateChanged)
                  }
                  handleDateChange={() => this.handleCategoryChange("", categoryValue)}
                />
              </div>
            )
          ) : null}
          <div className="third-section section">
            <div className="section__header">
              <span>3. Travel summary</span>
            </div>
            <div className="section__body">
              <div className="form-row">
                <span>Would you like to write your travel synopsis?</span>
              </div>

              <div className="form-row">
                <Form
                  formName="texteditor"
                  name="travelSummary"
                  disabled={isViewMode}
                  value={travelSummary}
                  toolbarId="travelSummaryPar"
                  placeholder={"Write something"}
                  onChange={this.handleChange}
                  width="100%"
                />
                {/* <CKEditor
                  editor={ClassicEditor}
                  config={editorConfiguration}
                  data={travelSummary}
                  onInit={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    //console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    //console.log({ event, editor, data });
                    this.handleTravelSummaryChange(data);
                  }}
                  onBlur={(event, editor) => {
                    //console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    //console.log("Focus.", editor);
                  }}
                  disabled={isViewMode}
                /> */}
              </div>
              <div className="form-row">
                <Upload
                  fileList={travelSummaryFileList}
                  setSelectedFiles={this.setSelectedFiles}
                  uploadBtnText="Add document"
                  noSelectedText="No document selected"
                  isViewMode={isViewMode}
                  isDifferentSource={true}
                />
              </div>
            </div>
          </div>
          <div className="fourth-section section">
            <div className="section__header">
              <span>{isAsPlanned ? "3" : "4"}. Bills and claims</span>
            </div>
            <div className="section__body">
              <div className="form-row">
                <div className="autoselect">
                  <label>Travel category</label>
                  <FormGroup
                    formName="reactselect"
                    name="selectedTravelCategory"
                    placeholder="select category"
                    value={categoryValue}
                    options={travelCategoryList}
                    onChange={this.handleCategoryChange}
                    disabled={isAsPlanned || isViewMode}
                    width={250}
                  />
                </div>
              </div>
              {/* <div className="form-row md-wd">
                <div className="flex">
                  <IoIosCheckmarkCircle className="icon-check" />
                  <span>Travel allowance</span>
                </div>
                <div className="flex">
                  <IoIosCheckmarkCircle className="icon-check" />
                  <span>Daily allowance</span>
                </div>
                <div className="flex">
                  <IoIosCheckmarkCircle className="icon-check" />
                  <span>Expense paid</span>
                </div>
              </div> */}
              <div className="form-row">
                <div className="grid bill-grid">
                  <div className="grid__header">
                    <span>Allowance</span>
                    <span>Units</span>
                    <span>Rate</span>
                    <span>Amount</span>
                    <span>Bill Amount</span>
                  </div>
                  <div className="grid__body">
                    {billsAndClaimsList.map((x) => {
                      return (
                        <div className="grid__body__row">
                          <div>
                            <span>{x.Name}</span>
                          </div>
                          <div>
                            <span>{x.Units}</span>
                          </div>
                          <div className="amount">
                            <span>{getCommaFormattedNumber(x.Rate)}</span>
                          </div>
                          <div className="amount">
                            <span>{getCommaFormattedNumber(x.Amount)}</span>
                          </div>
                          <div className="amount bill-amount">
                            <div className="bill-amount__flex">
                              {parseInt(x.BillCount) !== 0 && !isEmpty(x.BillCount) && (
                                <span className="bill-count">{x.BillCount}</span>
                              )}
                            </div>
                            {isViewMode || !x.IsBillRequired ? null : (
                              <div className="bill-amount__flex">
                                <button
                                  className="btn btn-grey-2"
                                  onClick={() => this.handleModalShow(1, x)}
                                >
                                  <FiPlus />
                                  Attach bill
                                </button>
                              </div>
                            )}

                            <div className="bill-amount__flex">
                              {parseInt(x.BillAmount) !== 0 ? (
                                <span>{getCommaFormattedNumber(x.BillAmount)}</span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="grid__footer">
                    <div className="grid__footer__row">
                      <div>
                        <span>Total assessed eligibility </span>
                      </div>
                      <span></span>
                      <span></span>
                      <div className="amount">
                        <span>{getCommaFormattedNumber(totalBillAmount)}</span>
                      </div>
                      <span></span>
                    </div>
                    {otherExpenses.map((x) => {
                      return (
                        <div className="grid__footer__row">
                          <div>
                            <span
                              className={classnames({
                                "other-expense-name": true,
                                disabled: isViewMode,
                              })}
                              onClick={() =>
                                isViewMode ? null : this.handleModalShow(2, x)
                              }
                            >
                              {x.ExpenseName}
                            </span>
                          </div>
                          <span></span>
                          <span></span>
                          <div className="amount">
                            <span className="other-expense-amount">
                              {getCommaFormattedNumber(x.Amount)}
                            </span>
                          </div>
                          <div className="flex pl-md">
                            {!isEmpty(x.FileUrl) && (
                              <a href={x.FileUrl} target="_blank">
                                <GoCloudDownload className="text-bg" />
                              </a>
                            )}

                            {!isViewMode && (
                              <IoMdCloseCircle
                                className="otherexpense-close-icon text-bg ml-bg"
                                onClick={() => this.props.removeOtherExpense(x.Id)}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                    <div className="grid__footer__row summary">
                      <div>
                        {isViewMode ? null : (
                          <button
                            className="btn btn-primary"
                            onClick={() => this.handleModalShow(2, {})}
                          >
                            <FiPlus className="icon-plus" />
                            Add other expenses
                          </button>
                        )}
                      </div>

                      <div className="summary__label">
                        <span className="claim-label">Total Claim Amount</span>
                        <span className="advance-label">
                          {totalClaimAmount > advanceTaken
                            ? "Less"
                            : totalClaimAmount === advanceTaken
                            ? "Equal"
                            : "More"}
                          : Advance taken
                        </span>
                        <span className="settle-label">Amount Receivable</span>
                      </div>
                      <div className="amount summary__amount">
                        <span className="claim-amount">
                          {getCommaFormattedNumber(totalClaimAmount)}
                        </span>
                        <span className="advance-amount">
                          {getCommaFormattedNumber(advanceTaken)}
                        </span>
                        <span className="settle-amount">
                          {getCommaFormattedNumber(totalClaimAmount - advanceTaken)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <Form
                  formName="textarea"
                  name="Note"
                  rows={6}
                  cols={100}
                  label="Note"
                  placeholder="Justification why this expense was incurred"
                  disabled={isViewMode}
                  value={this.state.Note}
                  onChange={this.handleChange}
                />
                {/* <div className="form-label">
                    <span>Note</span>
                  </div>
                  <TextareaAutosize
                    rows={6}
                    cols={100}
                    className="note"
                    name="Note"
                    placeholder="Justification why this expense was incurred"
                    disabled={isViewMode}
                    value={this.state.Note}
                    onChange={(event) => this.handleChange(event, "Note")}
                  /> */}
              </div>
            </div>
          </div>
          {isViewMode ? null : (
            <div className="fifth-section section">
              <div className="form-row">
                <CommonCommentsHistory
                  childRef={(ref) => (this.commonComments = ref)}
                  RequestId={this.state.requestId}
                  StatusId={this.state.submitStatusId}
                  approvalAuthorities={this.props.approvalAuthorities}
                  approvalHistory={this.props.approvalHistory}
                  errors={this.state.errors}
                  isViewMode={isViewMode}
                  isModal={!isViewMode}
                  disableApprover={isViewMode}
                />
              </div>
              {!isViewMode && (
                <div className="form-row btn-row">
                  <button
                    className="btn btn-del"
                    onClick={() => handleSettlementActive(false)}
                  >
                    Cancel
                  </button>
                  <button className="btn btn-primary" onClick={this.handleSaveSettlement}>
                    Send for Approval
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
        <Modal
          title={"Attach bill"}
          open={this.state.isModalShow}
          onModalClose={this.closeModal}
          type="normal"
          className="modal-settlement"
        >
          {isAttachBill ? (
            <AttachBill
              initialValues={formData}
              onSubmit={this.onFormSubmit}
              closeModal={this.closeModal}
            />
          ) : (
            <AddOtherExpenses
              initialValues={formData}
              onSubmit={this.onFormSubmit}
              closeModal={this.closeModal}
            />
          )}
        </Modal>
      </div>
    );
  }
}
Travel_Settlement = reduxForm({
  form: "travelSettlementForm", // a unique identifier for this form
  keepDirtyOnInitialize: true,
  keepDirtyOnReinitialize: true,
  enableReinitialize: true,
  updateUnregisteredFields: true,
})(Travel_Settlement);

const mapDispatchToProps = {
  EditTravelRequests,
  getAllowanceAndExpensesList,
  saveSettlementDetails,
  GetCountryList,
  GetCurrencyList,
  GetDistrictList,
  GetTravelModeOfTransport,
  GetTravelHostAndHotel,
  GetEmployeeList,
  fetchTravelInformationData,
  DisplayClientSideMessage,
  handleSettlementActive,
  getApprovalAuthorities,
  getApprovalHistory,
  removeOtherExpense,
};

Travel_Settlement = connect(
  (state) => ({
    travelCategoryList: state.travelInformationReducer.travelCategoryList,
    travelRequestDetails: state.travelSettlementReducer.travelRequestDetails,
    otherExpenses: state.travelSettlementReducer.otherExpenses,
    billsAndClaimsList: state.travelSettlementReducer.billsAndClaims,
    pendingSettlement: state.travelSettlementReducer.pendingSettlement,

    countryList: state.commonReducer.countryList,
    currencyList: state.commonReducer.currencyList,
    districtList: state.commonReducer.districtList,
    modeOfTransportList: state.travelInformationReducer.modeOfTransportList,
    travelHostList: state.travelInformationReducer.travelHostList,
    travelHotelList: state.travelInformationReducer.travelHotelList,

    travelInformationToEdit: state.travelInformationReducer.travelInformationToEdit,
    isLoading: state.travelInformationReducer.isLoading,
    approvalAuthorities: state.travelSettlementReducer.approvalAuthorities,
    approvalHistory: state.travelSettlementReducer.approvalHistory,
    companyInformation: state.commonReducer.companyInformation,
  }),
  mapDispatchToProps
)(Travel_Settlement);
export default Travel_Settlement;
