import { formatedDate, formatedMonthYear } from "./constants";

export function FinalDataToSave_Template({
  recruitmentCandidateDto,
  recruitmentCandidateEducationInfoDto = [],
  recruitmentCandidateTrainingInfoDto = [],
  recruitmentCandidateWorkExperienceInfoDto = [],
  recruitmentCandidateReferenceInfoDto = [],
}) {
  return {
    RecruitmentCandidateDto: recruitmentCandidateDto,
    RecruitmentCandidateEducationInfoDto: recruitmentCandidateEducationInfoDto,
    RecruitmentCandidateTrainingInfoDto: recruitmentCandidateTrainingInfoDto,
    RecruitmentCandidateWorkExperienceInfoDto: recruitmentCandidateWorkExperienceInfoDto,
    RecruitmentCandidateReferenceInfoDto: recruitmentCandidateReferenceInfoDto,
  };
}

export function RecruitmentCandidateDto({
  jobId,
  honorificType,
  firstName,
  lastName,
  mobileNumber,
  email,
  nationalityId,
  dob,
  imageOriginalName,
  imageName,
  cropedImageOriginalName,
  cropedImageName,
  permanentProvienceId,
  permanentDistrictId,
  permanentLocalBodyId,
  permanentWard,
  permanentLocality,
  correspondingProvienceId,
  correspondingDistrictId,
  correspondingLocalBodyId,
  correspondingWard,
  correspondingLocality,
  documentTypeId,
  identificationDocumentName,
  identificationDocumentOriginalName,
  havePassport,
  drivingLicenseTypeId,
  fatherName,
  motherName,
  grandFatherName,
  maritalStatusId,
  coverLetterDocumentOriginalName,
  coverLetterDocumentName,
  cvDocumentOriginalName,
  cvDocumentName,
}) {
  return {
    CandidateId: 0, //no needed
    JobRefId: jobId, //job id
    Salutation: honorificType,
    FirstName: firstName,
    LastName: lastName,
    MobileNo: mobileNumber,
    Email: email,
    Nationality: nationalityId, //id
    BirthDate: dob && formatedDate(dob),
    BirthDateEng: dob,
    ImageOriginalName: imageOriginalName,
    ImageName: imageName,
    CropedImageOriginalName: cropedImageOriginalName,
    CropedImageName: cropedImageName,
    PermanentProvinceId: permanentProvienceId,
    PermanentDistrictId: permanentDistrictId,
    PermanentLocalBodyId: permanentLocalBodyId,
    PermanentWardNo: permanentWard,
    PermanentLocality: permanentLocality,
    CorrespondingProvinceId: correspondingProvienceId,
    CorrespondingDistrictId: correspondingDistrictId,
    CorrespondingLocalBodyId: correspondingLocalBodyId,
    CorrespondingWardNo: correspondingWard,
    CorrespondingLocality: correspondingLocality,
    DocumentType: documentTypeId, //identification document type
    DocumentName: identificationDocumentName,
    OriginalName: identificationDocumentOriginalName, //selected identification document
    HasPassport: havePassport === 1,
    DrivingLicense: drivingLicenseTypeId,
    FatherName: fatherName,
    MotherName: motherName,
    GrandFatherName: grandFatherName,
    MaritalStatus: maritalStatusId, //1=single , 2=married
    CoverLetterOriginalName: coverLetterDocumentOriginalName,
    CoverLetterFileName: coverLetterDocumentName,
    CVOriginalName: cvDocumentOriginalName,
    CVFileName: cvDocumentName,
    SubmitOn: null, //not needed
    SubmitOnEng: null, //not needed
    IsSourced: false, //not needed
    CandidateStatus: 0, //not needed
  };
}

export function RecruitmentCandidateEducationInfoDto({
  degreeName,
  qualificationLevelId,
  institutionName,
  countryId,
  universityName,
  passedYear,
  isPercentage,
  isGPA,
  gradeValue,
  majorSubjects,
  educationDocumentName,
  educationDocumentOriginalName,
}) {
  return {
    Id: 0, //not needed
    CandidateRef_Id: 0, //not needed
    DegreeName: degreeName,
    QualificationLevelId: qualificationLevelId,
    InstitutationName: institutionName,
    CountryId: countryId,
    UniversityName: universityName,
    PassedYear: formatedMonthYear(passedYear),
    IsPercentage: isPercentage,
    IsGPA: isGPA,
    GradeValue: gradeValue,
    MajorSubjects: majorSubjects,
    DocumentName: educationDocumentName,
    OriginalName: educationDocumentOriginalName,
  };
}

export function RecruitmentCandidateTrainingInfoDto({
  trainingName,
  institutionName,
  countryId,
  year,
  trainingDocumentOriginalName,
  trainingDocumentName,
}) {
  return {
    Id: 0, //not needed
    CandidateRef_Id: 0, //not needed
    TrainingName: trainingName,
    InstitutionName: institutionName,
    CountryId: countryId,
    Year: formatedMonthYear(year),
    DocumentOriginalName: trainingDocumentOriginalName,
    DocumentName: trainingDocumentName,
  };
}

export function RecruitmentCandidateWorkExperienceInfoDto({
  organizationName,
  countryId,
  placeName,
  fromYear,
  toYear,
  position,
  keyResponsibilities,
}) {
  return {
    Id: 0, //not needed
    CandidateRef_Id: 0, //not needed
    OrganisationName: organizationName,
    CountryId: countryId,
    PlaceName: placeName,
    FromDate: formatedDate(fromYear), //string
    FromDateEng: fromYear, //Datetime
    ToDate: formatedDate(toYear), //string
    ToDateEng: toYear, //Datetime
    Position: position,
    KeyResponsibilities: keyResponsibilities,
  };
}

export function RecruitmentCandidateReferenceInfoDto({
  referenceName,
  referenceOrganizationName,
  referencePosition,
  referencePhoneNo,
  referenceEmail,
}) {
  return {
    Id: 0, //not needed
    CandidateRef_Id: 0, //not needed
    Name: referenceName,
    Organization: referenceOrganizationName,
    Position: referencePosition,
    PhoneNo: referencePhoneNo,
    Email: referenceEmail,
  };
}
