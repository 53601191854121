import React, { Component } from "react";
// import Tabs from '../../../Components/Tabs/Tabs'
// import InputGroup from '../../../Components/Form/InputGroup'
import Table from "./table";
import { Prompt } from "react-router";
import {
  GetBranches,
  GetDepartments,
  GetDesignations,
  GetJobStatus,
} from "actions/commonAction";
import { GetEmployeeListForAdmin } from "../../employee/employeeList/action";
import { searchTaxAdjustment } from "./action";
import { connect } from "react-redux";
import Filters from "./filters";
import Sidetab from "./adjusmentTabs";
import "./taxAdjustment.scss";
import moment from "moment";
import isEmpty from "isEmpty";

const getDate = (name) => {
  var date = new Date();
  var firstDay = null;
  var lastDay = null;
  const label = !isEmpty(name) ? name.label : "";
  if (label === "This Month") {
    firstDay = moment(date).startOf("month").format("YYYY/MM/DD");
    lastDay = moment(date).endOf("month").format("YYYY/MM/DD");
  }
  if (label === "Last Month") {
    firstDay = moment(date)
      .subtract(1, "months")
      .startOf("month")
      .format("YYYY/MM/DD");
    lastDay = moment(date)
      .subtract(1, "months")
      .endOf("month")
      .format("YYYY/MM/DD");
  }
  if (label === "This Year") {
    firstDay = moment().startOf("year").format("YYYY/MM/DD");
    lastDay = moment().endOf("year").format("YYYY/MM/DD");
  }
  return { firstDay, lastDay };
};

export class Tax_Adjustment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      leftValue: "",
      searchValue: "",
      activeBranch: "",
      activeDepartment: "",
      activePosition: "",
      activeStatus: "",
      income_deduct: "",
      position: "",
      region: "",
      approval_state: "",
      leave_type: "",
      rowsPerPage: 10,
      pageIndex: 0,
      currentStep: 1,
    };
    this.onChange = this.onChange.bind(this);
    this.handleRowChange = this.handleRowChange.bind(this);
    this.handleIndexChange = this.handleIndexChange.bind(this);
  }
  componentDidMount() {
    this.onFilter()
    // this.props.GetBranches();
    // this.props.GetDepartments();
    // this.props.GetDesignations();
    // this.props.GetJobStatus();
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleInput = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, pageIndex: 0 }, () => {
    });
  };

  handleAutoSelect = (name, selected) => {
    this.setState({ [name]: selected, pageIndex: 0 }, () => {
    });
  };

  handleRowChange = (num) => {
    this.setState({ rowsPerPage: num }, () => {
      this.onFilter();
    });
  };

  handleIndexChange = (num) => {
    this.setState({ pageIndex: num }, () => {
      this.onFilter();
    });
  };

  onFilter() {
    const {
      searchValue, activeBranch, activeDepartment,
      pageIndex, activePosition, activeStatus, rowsPerPage
    } = this.state;
    const { selectedPayPeriod } = this.props.selectReducer;
    const searchedData = {
      EmployeeId: !isEmpty(searchValue) ? parseInt(searchValue.value) : 0,
      BranchId: !isEmpty(activeBranch) ? parseInt(activeBranch.value) : 0,
      DepartmentId: !isEmpty(activeDepartment) ? parseInt(activeDepartment.value) : 0,
      PayrollPeriodId: parseInt(selectedPayPeriod.Value),
      // LeaveRequestStatusId: parseInt(status.value),
      // PositionId: parseInt(position.value),
      // LeaveTypeId: 0,
      PageIndex: pageIndex,
      PageSize: rowsPerPage,
    };
  
    this.props.searchTaxAdjustment(searchedData);
  }
  changeSideTab = (currentStep) => {
    this.setState({ currentStep });
  };

  componentDidUpdate = (prevProps, prevState) => {
        
    const prevPayroll = prevProps.selectReducer.selectedPayPeriod.Value;
    const nowPayroll = this.props.selectReducer.selectedPayPeriod.Value;
    // const {archive} = this.state;
    if(prevPayroll !== nowPayroll){
      this.onFilter();
        // const searchData ={
        //     ShowArchive: archive,
        //     PayrollPeriodId: parseInt(nowPayroll),
        //     PageIndex: this.state.pageIndex,
        //     PageSize: this.state.rowsPerPage
        // }
        // this.props.searchStopPayment(searchData)
    }
};

  render() {
    const {
      employeeListForAdmin,
      branches,
      searchValue,
      departments,
      designations,
      statusList,
      history,
      taxAdjustmentReducer: {
        loading,
        searchTaxAdjustment
      }
    } = this.props;
    const {
      actTab,
      pageIndex,
      attendancePageIndex,
      rowsPerPage,
      attendanceRowsPerPage,
      currentStep,
    } = this.state;
    if (loading || searchTaxAdjustment === null) return null;
    return (
      <div className="payroll-body payroll-tax">
        <Filters
          // pageIndex={this.state.pageIndex}
          // handleRowChange={this.handleRowChange}
          // handleIndexChange={this.handleIndexChange}
          // rowsPerPage={this.state.rowsPerPage}
          parentState={this.state}
          branches={branches}
          searchValue={searchValue}
          departments={departments}
          designations={designations}
          statusList={statusList}
          handleAutoSelect={this.handleAutoSelect}
          handleInput={this.handleInput}
          onFilter={this.onFilter}
        />
        {/* <div className="button-group button-group__right">
          <button className="btn btn-grey-2">Export</button>
          <button className="btn btn-blue">Save all changes</button>
        </div> */}
        <div className="tax-adj-tabs mt-sm">
          <Sidetab
            currentStep={currentStep}
            onStepChange={(step) => this.changeSideTab(step)}
          />
          <Table
            pageIndex={pageIndex} 
            rowsPerPage={rowsPerPage} 
            handleRowChange={this.handleRowChange}
            handleIndexChange={this.handleIndexChange}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeListForAdmin: state.admin_EmployeeListReducer.employeeListForAdmin,
  branches: state.commonReducer.branchList,
  departments: state.commonReducer.departmentList,
  designations: state.commonReducer.designationList,
  statusList: state.commonReducer.jobStatusList,
  selectReducer: state.selectReducer,
  taxAdjustmentReducer: state.taxAdjustmentReducer,
 
});

const mapDispatchToProps = {
  GetEmployeeListForAdmin,
  GetBranches,
  GetDepartments,
  GetDesignations,
  GetJobStatus,
  searchTaxAdjustment,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tax_Adjustment);
