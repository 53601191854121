import React, { Component } from "react";
import isEmpty from "isEmpty";
import { numberToNepaliCurrency } from "helpers/regex";
import SvgIcons from "components/svgIcons";
import Modal from "components/modal";
import PayslipTitleBar from "./payslipTitleBar";

function SalarySlip(props) {
  const { title, showTableModal, payslip } = props;
  if (!payslip) {
    return <div className="no-data">No Data Found for this month</div>;
  }

  const isAllEmpty =
    isEmpty(payslip.Income) &&
    isEmpty(payslip.Deduction) &&
    isEmpty(payslip.IncomeCalculation);
  return (
    <Modal
      // title={"Payslip for " + activeMonth.Name}
      title={""}
      open={showTableModal}
      onModalClose={props.handleModalClose}
      type=""
      // className="yearly-payslip-modal"
    >
      <>
        <PayslipTitleBar {...props} slipName="Salary Slip" />
        <div className="table-payslip">
          {isAllEmpty ? (
            <div className="no-data">No Data Found for this month</div>
          ) : (
            <>
              <div className="table-row-payslip row-heading">
                <span className="align_left bold">Plan Name</span>
                <span className="align_right bold">Amount</span>
                <span className="align_left bold">Note</span>
              </div>
              {payslip.Income &&
                payslip.Income.map((item, key) => {
                  return (
                    <div key={key} className="table-row-payslip">
                      <span className="align_left">{item.Header}</span>
                      <span className="align_right">
                        {numberToNepaliCurrency(item.Amount)}
                      </span>
                      <span className="align_left">{item.Note}</span>
                    </div>
                  );
                })}
              <div className="table-row-payslip row-heading">
                <span className="align_left bold">Gross Income</span>
                <span className="align-right bold">
                  {payslip.IncomeCalculation &&
                    numberToNepaliCurrency(
                      payslip.IncomeCalculation.GrossIncome
                    )}
                </span>
                <span className="align_left"></span>
              </div>

              {payslip.Deduction &&
                payslip.Deduction.map((item, key) => {
                  return (
                    <div key={key} className="table-row-payslip">
                      <span className="align_left">{item.Header}</span>
                      <span className="align_right">
                        {numberToNepaliCurrency(item.Amount)}
                      </span>
                      <span className="align_left">{item.Note}</span>
                    </div>
                  );
                })}
              <div className="table-row-payslip row-heading">
                <span className="align_left bold">Total Deduction</span>
                <span className="align-right bold">
                  {payslip.IncomeCalculation &&
                    numberToNepaliCurrency(
                      payslip.IncomeCalculation.TotalDeduction
                    )}
                </span>
                <span className="align_left"></span>
              </div>
              <div className="table-row-payslip row-heading">
                <span className="align_left bold">Net Paid</span>
                <span className="align-right bold">
                  {payslip.IncomeCalculation &&
                    numberToNepaliCurrency(payslip.IncomeCalculation.NetIncome)}
                </span>
                <span className="align_left"></span>
              </div>
            </>
          )}
        </div>
      </>
    </Modal>
  );
}

export default SalarySlip;
