import Button from "components/button";
import ApproveButton from "components/button/approveButton";
import Pagination from "components/common/pagination";
import Modal from "components/modal";
import isEmpty from "isEmpty";
import React, { useState } from "react";
import ApprovalFlow from "scenes/common/approvalFlow";
import GetDetailsPage from "../common/getDetailsPage";
import RequestBlock from "../common/requestBlock";
import RequestBlockWrapper from "../common/requestBlockWrapper";
import SvgIcons from 'components/icons/svgIcons';

export interface IAppProps {
  rows: any;
  handleRowChange: Function;
  handleIndexChange: Function;
  pageIndex: number;
  rowsPerPage: number;
  refreshRequestData: Function;
  handleApproveRequest?: Function;
}

function App(props: IAppProps) {
  let [modalOpen, setModalOpen] = useState("");
  let {
    handleRowChange,
    handleIndexChange,
    pageIndex,
    rowsPerPage,
    refreshRequestData,
    handleApproveRequest,
  } = props;
  const onModalShow = (id) => {
    setModalOpen(id);
  };
  let [isDetailsPageShow, setDetailsPageShow] = useState(false);
  let [selectedRow, setSelectedRow] = useState({});
  
  return (
    <>
      {props?.rows?.map((row, rowIndex) => {
        const approvalStatus = [
          {
            RequestRefId: row.RequestId,
            UserName: row.Requester,
            ActionBy: row.Reccommender,
            ActionOn: "",
            StepName: "",
            ApprovalStatus: row.RecommendedStatus,
            ApproverImage: row.RecommendedImage,
          },
          {
            RequestRefId: row.RequestId,
            UserName: row.Requester,
            ActionBy: row.Approval,
            ActionOn: "",
            StepName: "",
            ApprovalStatus: row.ApprovalStatus,
            ApproverImage: row.ApprovalImage,
          },
        ];

        return (
          <div className="request-details" key={rowIndex}>
            <RequestBlock
              row={row}
              isViewMode={false}
              isReview={true}
              refreshRequestData={refreshRequestData}
              requestData={props.rows}
              page="requestReview"
            />
            <div className="request-details__footer">
              <div className="details-btn flex">
                {/* <NavLink
                  to={{
                    pathname: LeaveRequestDetailsPath + row.RequestId,
                    state: {},
                  }}
                  // onClick={() => handleExportToPdf(row.RequestId)}
                > */}
                <Button
                  title="Details"
                  bg="subtle"
                  buttonType="icon-button"
                  leftIcon={<SvgIcons name="list" color="#3a3d43" size="17px" />}
                  // type="button-outline"

                  onClick={() => {
                    setSelectedRow(row);
                    setDetailsPageShow(true);
                  }}
                />
                {/* </NavLink> */}
              </div>

              <ApprovalFlow
                approvalStatus={approvalStatus}
                rowIndex={rowIndex}
              />

              <ApproveButton
                handleApproveRequest={() => handleApproveRequest(row)}
              />
              <Modal
                open={modalOpen === row.RequestId}
                onModalClose={() => onModalShow("")}
                title="Comments History"
              ></Modal>
            </div>
          </div>
        );
      })}
      {isEmpty(props.rows) ? null : (
        <Pagination
          handleRowChange={handleRowChange}
          handleIndexChange={handleIndexChange}
          pageIndex={pageIndex}
          searchedPage={{
            index: pageIndex,
            total: props.rows ? props.rows[0].TotalRow : 0,
          }}
          rowsPerPage={rowsPerPage}
        />
      )}
      {isDetailsPageShow && (
        <GetDetailsPage
          selectedRow={selectedRow}
          closeDetailsModal={() => setDetailsPageShow(false)}
          isViewMode={false}
          isReview={true}
          refreshRequestData={refreshRequestData}
          requestData={props.rows}
          page="requestReview"
        />
      )}
    </>
  );
}
export default RequestBlockWrapper()(App);
