import React, { Component } from "react";
import Icons from "components/icons";
import { formatedShortMonthDayYear } from "helpers/dateFormat";
import Button from "components/button";

export class TableSubRows extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   isRenewContract: false,
    //   selectedProjectForRenew: null,
    // };
  }
  onArchiveProject = (e, projectId) => {
    e.stopPropagation();
    this.props.handleArchiveProject(projectId);
  };

  onRestoreProject = (e, projectId) => {
    e.stopPropagation();
    this.props.handleRestoreProject(projectId);
  };
  onEditProject = (e, data) => {
    e.stopPropagation();
    this.props.handleEditProject(data);
  };
  onDeleteProject = (e, data) => {
    e.stopPropagation();
    this.props.handleDeleteProject(data);
  };

  reloadList = () => {
    this.props.getLegalClientList && this.props.getLegalClientList();
  };

  onModalClose = (name) => {
    this.setState({ [name]: false });
  };

  onRenewContract = (isRenew, selectedProjectForRenew) => {
    // handleFormDisplay
    this.props.handleRenewContract &&
      this.props.handleRenewContract(isRenew, selectedProjectForRenew);
  };

  render() {
    const { widths, list } = this.props;
    // const { isRenewContract, selectedProjectForRenew } = this.state;

    let estimatedHoursToDisplay = null;
    let estimatedHrs = 0;
    let estimatedMins = 0;
    if (list.EstimatedHours) {
      estimatedHrs = parseInt(list.EstimatedHours / 60);
      estimatedMins = list.EstimatedHours % 60;
    }

    if (estimatedHrs > 0 && estimatedMins > 0) {
      estimatedHoursToDisplay = estimatedHrs + " hr " + estimatedMins + " min";
    } else if (estimatedHrs > 0) {
      estimatedHoursToDisplay = estimatedHrs + " hr";
    } else {
      estimatedHoursToDisplay = estimatedMins + " min";
    }
    return (
      <>
        <td></td>
        <td>
          <span className="detail">
            <span className="project">
              <span className="project-icon">P</span>
              <span>{list.ProjectName}</span>
            </span>
          </span>
        </td>

        <td>
          <span className="detail">{list.TypeName}</span>
        </td>

        <td>
          <span className="detail">
            <p>
              {list.EventStartDateEng &&
                formatedShortMonthDayYear(list.EventStartDateEng)}
            </p>
            <p>{" - "}</p>
            <p>
              {list.EventEndDateEng &&
                formatedShortMonthDayYear(list.EventEndDateEng)}
            </p>
          </span>
        </td>

        <td>
          <span className="detail">
            <span className="hoursAndMore">
              <span>{estimatedHoursToDisplay}</span>

              <span className="flex">
                {list.IsExpired && (
                  <>
                    <span className="color-red">Expired</span>

                    <Button
                      onClick={() => this.onRenewContract(true, list)}
                      buttonClass="button-renew"
                    >
                      <span className="color-white">Renew</span>
                    </Button>
                  </>
                )}
              </span>
            </span>
          </span>
        </td>

        <td classNam="e-custom-row">
          <span className="detail flex">
            {/* <Button title={list.DocumentCount + " Documents"} bg="white" /> */}
            <div className="client-document-count">
              <Icons name="Pdf" />
              <span className="client-document-count-number">
                {list.DocumentCount}
              </span>
            </div>
            {!list.IsArchived ? (
              <Button
                buttonType="drop-button"
                bg="secondary"
                title="Edit"
                onClick={(e) => this.onEditProject(e, list)}
                dropClass={"common-drop"}
                dropComponent={
                  <ul
                    id={"Project" + list.ProjectId}
                    className="common-drop-list"
                  >
                    <li
                      className={"common-drop-list__item"}
                      onClick={(e) => this.onArchiveProject(e, list.ProjectId)}
                    >
                      <span className="common-drop-name">Archive</span>
                    </li>

                    <li
                      className={"common-drop-list__item"}
                      onClick={(e) => this.onDeleteProject(e, list)}
                    >
                      <span className="common-drop-name">Delete </span>
                    </li>
                  </ul>
                }
              />
            ) : (
              <Button
                buttonType="drop-button"
                bg="secondary"
                title="Restore"
                onClick={(e) => this.onRestoreProject(e, list.ProjectId)}
                dropClass={"common-drop"}
                dropComponent={
                  <ul className="common-drop-list">
                    <li
                      className={"common-drop-list__item"}
                      onClick={(e) => this.onDeleteProject(e, list)}
                    >
                      <span className="common-drop-name">Delete</span>
                    </li>
                  </ul>
                }
              />
            )}
          </span>
        </td>
      </>
    );
  }
}

export default TableSubRows;
