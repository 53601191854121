import * as React from "react";

interface IAppProps {
  overTimeRequest: any[];
}

const App: React.FunctionComponent<IAppProps> = (props) => {
  const { overTimeRequest } = props;
  return (
    <div className="overtime-table flex-column">
      <div className="overtime-table__header">
        <span>Date</span>
        <span>Device in</span>
        <span>Device out</span>
        <span>Start time</span>
        <span>End time</span>
        <span>Minutes</span>
      </div>
      <div className="overtime-table__body flex-column">
        {overTimeRequest?.map((x) => {
          return (
            <div className="overtime-table__body-row">
              <span>{x.RequestDate || "-"}</span>
              <span>{x.InTimeDv || "-"}</span>
              <span>{x.OutTimeDv || "-"}</span>
              <span>{x.StartTime || "-"}</span>
              <span>{x.EndTime || "-"}</span>
              <span className="minutes">{x.RequestedMin || "-"}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default App;
