import React from "react";
import { ADMIN_ROLE_ID } from "constants/constants";
import { TRAVEL_PATHS } from "constants/paths";
import { RoleId } from "constants/userDetails";
import isEmpty from "isEmpty";
import CommonHeader from "./employee/commonHeader";
import CommonRoutes from "./employee/commonRoutes";
import CommonTabs from "./employee/commonTabs";
import RoutesEnum from 'constants/routeEnum'

const MyContext = React.createContext();
export class Travel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employee: { label: "", value: 0 },
    };
  }

  render() {
    const { match, location } = this.props;
    const travelPaths = TRAVEL_PATHS.find(
      (x) => x.PathName === location.pathname
    );

    const roleId = RoleId();
    const {pathname} = this.props.location;
    const isNew =  pathname === RoutesEnum.CreateTravelRequestPath || pathname === RoutesEnum.UpdateTravelRequestPath

    return (
      <div className="travel">
        {roleId !== ADMIN_ROLE_ID && !isEmpty(travelPaths) ? (
          <div className="travel-header">
            <CommonHeader props={this.props} />
            <CommonRoutes />
            {isNew ? null : <CommonTabs match={match} />}
            {/* {hideCommonTab ? null : <CommonTabs match={match} />} */}
          </div>
        ) : null}
        <MyContext.Provider>{this.props.children}</MyContext.Provider>
      </div>
    );
  }
}
export default Travel;
