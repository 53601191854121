import React from 'react'
import CommonModuleLinks from 'components/common/commonModuleLinks'

export const modules = (features) => {
    return [
    {
        groupTitle:'Monthly Pay Reports',
        feature: features?.CoreHR,
        links: [
            { title:'Pay Summary', link:'CP/Report/PaySummary.aspx'},
            { title:'Pay Summary Tax Only', link:'CP/Report/NIBLGrossSalaryReport.aspx'},
            { title:'Salary Extract', link:'CP/Report/SalaryExtract.aspx'},
            { title:'Bank Payment', link:'CP/Report/PayBank.aspx'},
            { title:'Cash Payment', link:'CP/Report/PayCash.aspx'},
            { title:'Accounting Voucher', link:'CP/VoucherList.aspx'},
            { title:'Tax Details', link:'CP/Report/TaxDetails.aspx'},
            { title:'Calculation Diagnostics', link:'CP/Report/PayDiagnosticsReport.aspx'},
            { title:'Pay Summary Grouping', link:'CP/Report/PaySummaryGroupingHeaders.aspx'},
            { title:'Pay Summary Incomes', link:'CP/Report/PaySummaryGroupingHeaders.aspx?Type=2'},
        ]
    },
    {
        groupTitle:'Statutory Reports',
        feature: features?.CoreHR,
        links: [
            { title:'Social Security Tax (SST)', link:'CP/Report/PaySST.aspx'},
            { title:'Tax Deducted at Source (TDS)', link:'CP/Report/PayTDS.aspx'},
            { title:'SST/TDS Details', link:'CP/Report/SSTTDSDetails.aspx'},
            { title:'Consoldated SST/TDS', link:'CP/Report/SSTTDSConsolidatedReport.aspx'},
            { title:'Provident Fund Statement (PF)', link:'CP/Report/PayPFStatement.aspx'},
            { title:'Social Security Statement (SSF)', link:'CP/Report/PaySSFStatement.aspx'},
            { title:'Citizen Investment Trust (CIT)', link:'CP/Report/PayCIT.aspx'},
            { title:'CIT MOnthly Details', link:'CP/Report/CitDetails.aspx'},
            { title:'Gratuty Deposit', link:'CP/Report/PayGratuityForDeposit.aspx'},
            { title:'Other Retirement Fund', link:'CP/Report/PayOtherFund.aspx'},
            { title:'Nabil / Other PF', link:'CP/Report/PayNIBLPF.aspx'},
        ]
    },
    {
        groupTitle:'Audit & Reconciliation Reports',
        feature: features?.CoreHR,
        links: [
            { title:'Tax Calculation Summary', link:'CP/TaxDetailsSummary.aspx'},
            { title:'Annual Tax Details(Beta)', link:'CP/AnnualTaxReportNew.aspx'},
            { title:'Employee Variance', link:'CP/Report/PayVariance.aspx'},
            { title:'Salary Comparision', link:'CP/Report/NewHrReport/PayVariance.aspx'},
            { title:'Last Month LWP Deduction', link:'CP/Report/PrevMonthAttendanceDeductionReport.aspx'},
            { title:'Income Adjustment', link:'CP/Report/ReportAdjustment.aspx'},
            { title:'Income Adjutment Summary', link:'CP/Report/PaySummaryWithAdjustmentAndArrear.aspx'},
            { title:'Salary Changes', link:'CP/Report/SalaryChangeList.aspx'},
            { title:'Branchwise Report', link:'CP/Report/BranchwiseReconciliationReport.aspx'},
            { title:'Income Report', link:'CP/Report/IncomewiseReconciliationReport.aspx'},
        ]
    },
    {
        groupTitle:'Other Reports',
        feature: features?.CoreHR,
        links: [
            { title:'Loan Recovery', link:'CP/Report/PayEmpLoan.aspx'},
            { title:'Loan Repayment', link:'NewHR/LoanRepaymentreport.aspx'},
            { title:'Advance Recovery', link:'CP/Report/PayEmpAdvance.aspx'},
            { title:'Take Home Salary', link:'newhr/EmployeehomeSalary.aspx'},
            { title:'Pay Component', link:'CP/Report/PayComponentDetails.aspx'},
            { title:'detailed Payslip', link:'CP/Report/PayDetail.aspx'},
        ]
    },
    {
        groupTitle:'Project Reports',
        feature: features?.CoreHR,
        links: [
            { title:'Project Report', link:'CP/Report/ProjectReport.aspx'},
            { title:'Consolidated Project', link:'CP/Report/ProjectConsolidatedReport.aspx'},
            { title:'Cost Allocation', link:'CP/Report/ProjectContributionPaySummary.aspx'},
        ]
    },
    {
        groupTitle:'Provision',
        feature: features?.CoreHR,
        links: [
            { title:'Leave Encashment', link:'CP/Report/PayLeaveProvision.aspx'},
            { title:'Gratuity/Severance', link:'CP/Report/PayGratuity.aspx'},
            { title:'Estimated leave Pay', link:'CP/Report/PayLeaveEncashmet.aspx'},
        ]
    },
    {
        groupTitle:'Additional Pay Reports',
        feature: features?.CoreHR,
        links: [
            { title:'Pay - Cost Code - Designations', link:'CP/Report/PaySummaryCostCodeDesignationWise.aspx'},
            { title:'Pay Calculation - Designations', link:'CP/Report/PaySummaryDesignationWise.aspx'},
            { title:'Salary Extract - Departments', link:'CP/Report/SalaryExtractDepartmentWise.aspx'},
            { title:'Stop / Hold Pay', link:'newhr/Report/StopHoldPayReport.aspx'},
            { title:'Branch / Dep Wise salary', link:'cp/report/newhrreport/ReportPayBranchDepartmentWise.aspx'},
        ]
    },
    {
        groupTitle:'Employee Service',
        feature: features?.CoreHR,
        links: [
            { title:'Pay Slip', link:'CP/Report/PaySlipDetail.aspx'},
            { title:'Retired Payslip', link:'CP/Report/RetiredPaySlipDetail.aspx'},
            { title:'Mail Payslip', link:'cp/PayReportSendIntoMail.aspx'},
        ]
    },
    {
        groupTitle:'Yearly Reports',
        feature: features?.CoreHR,
        links: [
            { title:'Consolidated Summary', link:'CP/Report/PaySummaryMultiple.aspx'},
            { title:'Year End Summary Report', link:'cp/report/newhrreport/YearEndSummaryReport.aspx'},
            { title:'Multi Add or Report', link:'cp/report/addonreport.aspx'},
            { title:'Income and Tax Calculation', link:'CP/Report/PayVariance.aspx?withtax=true'},
            { title:'Tax Payment Details', link:'CP/Report/PayVariance.aspx?taxonly=true'},
            { title:'Tax Certificate', link:'CP/Report/TaxCertificate.aspx'},
            { title:'Taxable Income Deposited', link:'CP/TaxableIncomeAndIRDComparisionReport.aspx'},
        ]
    },
]};

function Reports(props) {
    return (
        <div>
            <CommonModuleLinks 
                baseUrl={props.baseUrl} 
                title="Payroll Reports" 
                localStorageTitle="PayrollReportsFav" 
                modules={modules} 
                
            />
        </div>
    )
}

export default Reports
