import React from "react";
import LeaveRequestDetails from "scenes/employee/leaves/leaveDetails";
import TimeRequestDetail from "scenes/employee/time/timeRequestDetail";
import AllowanceRequestDetail from "scenes/employee/allowance/allowanceDetails";
import OverTimeRequestDetail from "scenes/employee/overtimeRequest/overtimeDetails";

export interface IGetDetailsPageProps {
  selectedRow: any;
  closeDetailsModal: Function;
  isViewMode?: boolean;
  isReview?: boolean;
  refreshRequestData?: Function;
  requestData?: any[];
  page?: string;
  isAdmin?:boolean
}

export default function GetDetailsPage(props: IGetDetailsPageProps) {
  const {
    selectedRow,
    closeDetailsModal,
    isViewMode,
    isReview,
    refreshRequestData,
    requestData,
    isAdmin,
    page,
  } = props;
  console.log({props})
  switch (selectedRow.RequestType) {
    case "Leave":
      return (
        <LeaveRequestDetails
          leaveRequestDetails={selectedRow}
          closeDetailsModal={closeDetailsModal}
          isViewMode={isViewMode}
          refreshRequestData={refreshRequestData}
          requestData={requestData}
          page={page}
        />
      );
      break;
    case "Time":
      return (
        <TimeRequestDetail
          timeRequestDetails={selectedRow}
          closeDetailsModal={closeDetailsModal}
          isViewMode={isViewMode}
          isReview={isReview}
          refreshRequestData={refreshRequestData}
          page={page}
        />
      );
    case "Allowance":
      return (
        <AllowanceRequestDetail
          allowanceRequestDetails={selectedRow}
          closeDetailsModal={closeDetailsModal}
          isViewMode={isViewMode}
          refreshRequestData={refreshRequestData}
          requestData={requestData}
          isAdmin={isAdmin}
        />
      );
    case "Overtime":
      return (
        <OverTimeRequestDetail
          overTimeRequestDetails={selectedRow}
          closeDetailsModal={closeDetailsModal}
          isViewMode={isViewMode}
          refreshRequestData={refreshRequestData}
          requestData={requestData}
          isAdmin={isAdmin}
        />
      );
    default:
      return null;
      break;
  }
}
