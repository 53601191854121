import React from "react";
import { FaRegFilePdf, FaEye } from "react-icons/fa";
import { connect } from 'react-redux';

function DocumentsDetail(props) {
        const documents = props.documentInfo || [];
        return (
            <div className="profile-row">
                <div className="profile-column">
                    <div className="profile-block">
                        <div className="profile-block__title">
                            <h2>IDENTIFICATION AND DOCUMENTS</h2>
                        </div>
                        <div className="profile-address__block">
                            { documents.length > 0 ? documents.map( (doc, i) => <div key={i + Math.random()} className="profile-block__div">
                                <div className="profile-contact__label document">
                                    {doc.Document}
                                </div>
                                <div className="value">
                                    ************
                                </div>
                                <div className="value">
                                    <FaEye />
                                </div>
                                <div className="document-address">
                                    {doc.Document == 'Driving Liscence' ? doc.LiscenceTypeName + ", " : ''}   {doc.Place +", "} on {doc.IssueDate}
                                </div>
                                <span className="document-icon">
                                    <FaRegFilePdf />
                                </span>
                                {doc.ExpireIn && <span className="document-expires">
                                    expires in {doc.ExpireIn}
                                </span>}
                            </div>) : <div className="profile-block__title nodata">
                                        <h2>No Document Available</h2>
                                    </div>}
                            {/* <div className="profile-block__div">
                                <div className="profile-contact__label document">
                                    Passport
                                </div>
                                <div className="value">
                                    ************
                                </div>
                                <div className="value">
                                    <FaEye />
                                </div>
                                <div className="document-address">
                                    DDC Sindupalchowk on Baisakh 1, 2067
                                </div>
                                <span className="document-icon">
                                    <FaRegFilePdf />
                                </span>
                                <span className="document-expires">
                                    expires in 23 months
                                </span>
                                
                            </div>
                            <div className="profile-block__div">
                                <div className="profile-contact__label document">
                                    Driving License
                                </div>
                                <div className="value">
                                    ************
                                </div>
                                <div className="value">
                                    <FaEye />
                                </div>
                                Two Wheelers, Banke on Baisakh 1, 2070
                                <span className="document-icon">
                                    <FaRegFilePdf />
                                </span>
                                <span className="document-expires">
                                    expires in 23 months
                                </span>
                            </div>
                         */}
                        </div>
                    </div>
                </div>
            </div>
        );
};

const mapStateToProps = state => ({
    documentInfo: state.employeeProfile.employeeProfile && state.employeeProfile.employeeProfile.Identification || [],
});

export default connect(mapStateToProps, null)(DocumentsDetail);