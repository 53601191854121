import * as React from "react";
interface IAppProps {
  employeeAllowanceHistory: any;
}

const App: React.FunctionComponent<IAppProps> = (props) => {
  const { employeeAllowanceHistory } = props;
  return (
    <div className="emp-history ">
      <div className="emp-history__header flex justify-between">
        <span className="header-title">
          Allowance History
        </span>
      </div>
      <div className="emp-history__body flex-column">
          <div className="flex-column mt-md">
            <span className="ml-sm mb-10 wd-60">
              This month
            </span>
            {employeeAllowanceHistory?.ThisMonth.map(a => 
            <div className="flex">
              <span className="wd-50 ml-sm mr-sm bold-500">
                {a.AllowanceTypeName}
              </span>
              <span className="ml-xsm bold-500">
              {a.TotalDays}
              </span>
            </div>
            )}
          </div>
          <div className="mt-md">
            <span className="ml-sm mb-10 wd-60">
              Previous month
            </span>
            {employeeAllowanceHistory?.PreviousMonth.map(a => 
            <div className="flex">
              <span className="wd-50 ml-sm mr-sm bold-500">
              {a.AllowanceTypeName}
              </span>
              <span className="ml-xsm bold-500">
              {a.TotalDays}
              </span>
            </div>)}
          </div>
      </div>
    </div>
  );
};

export default App;
