export const monthsValue = [
    {label:'JAN',value: 1},
    {label:'FEB',value: 2},
    {label:'MAR',value: 3},
    {label:'APR',value: 4},
    {label:'MAY',value: 5},
    {label:'JUN',value: 6},
    {label:'JUL',value: 7},
    {label:'AUG',value: 8},
    {label:'SEP',value: 9},
    {label:'OCT',value: 10},
    {label:'NOV',value: 11},
    {label:'DEC',value: 12},
];

export const getYears = () =>{
    let min = 1950;
    let years = 100;
    const a = [...Array(years).keys()].map(index =>{
        return {label:min+index,value:min+index}
    })
    return a 
}

export const getDays = () =>{
    let max = 31;
    const a = [...Array(max).keys()].map(index =>{
        return {label:1+index,value:1+index}
    })
    return a 
}