import React, { Component } from "react";
import JobTabs from "./jobTabs";
import "./jobProfile.scss";
import RouteEnum from 'constants/routeEnum'

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showJobProfileForm: false,
    };
  }
  handleJobProfileFormShow = (show) => {
    this.setState({
      showJobProfileForm: show,
    });
    this.props.history.push(RouteEnum.CreateJobProfilePath);
  };

  render() {
    const { showJobProfileForm } = this.state;
    const { history } = this.props;
    return (
      <div className="job-pages">
        <JobTabs handleJobProfileFormShow={this.handleJobProfileFormShow} />
        {/* {showJobProfileForm ? (
          <JobProfileForm
            history={history}
            title="New Job Profile"
            handleFormDisplay={this.handleJobProfileFormShow}
            isEditPage={false}
            showForm={showJobProfileForm}
            formExists={true}
            isFormLoading={false}
          />
        ) : null} */}
      </div>
    );
  }
}

export default index;
