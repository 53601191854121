import React from 'react'
import './styles.scss';
import Form from 'components/form'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getCoursesOptions,getCourse,addCoursesOptions,updateCoursesOptions } from '../helpers/action';
import FormGroup from 'components/formGroup';
import FormItem from 'components/formGroup/formItem';
import Button from 'components/button';
import { useForm } from 'react-hook-form';
import isEmpty from 'helpers/isEmpty';
import dayjs from 'dayjs'

function CourseSettings(props) {
    const dispatch = useDispatch();
    const methods = useForm();
    const {setValue,getValues,watch,control} = methods;
    const {
        courseOption,
        course,
        courseCategories,
        courseNavigationPolicies,
        courseEnrollements,
        courseShowTo
    }= useSelector((state:any)=>state.lmsReducer);
    const {courseId} = props.match.params;
    useEffect(()=>{
        dispatch(getCourse(courseId))
        dispatch(getCoursesOptions(courseId))
    },[]);
    useEffect(()=>{
        if(courseOption && !isEmpty(courseOption)){
            const cat = courseOption.CategoryId ? courseCategories.find(item=>item.value === courseOption.CategoryId) : null
            const waiting = courseOption.WaitingList ? courseOption.WaitingList.split(",") : []
            const start = courseOption?.StartDate ? dayjs(courseOption?.StartDate).format("YYYY-MM-DD") : ""
            const end = courseOption?.EndDate ? dayjs(courseOption?.EndDate).format("YYYY-MM-DD") : "";
            setValue("categoryId",cat)
            setValue("credits",courseOption?.Credits)
            setValue("passMarks",courseOption?.PassMarks)
            setValue("navigationPolicy",courseOption?.NavigationPolicy)
            setValue("maxNoOfAttempts",courseOption?.MaxNoOfAttempts)
            setValue("courseRating",courseOption?.CourseRating)
            setValue("showCourseTo",courseOption?.ShowCourseTo)
            setValue("maximumQuota",courseOption?.MaximumQuota)
            setValue("enrollment",courseOption.Enrollment)
            setValue("waitingList_enable",waiting?.includes("1"))
            setValue("waitingList_auto",waiting?.includes("2"))
            setValue("startDate",start)
            setValue("endDate",end)
            setValue("accessValidity",courseOption?.AccessValidity)
        }
    },[courseOption])
    const onSubmit = (data) =>{
        let waitingList = "";
        if(data.waitingList_enable && data.waitingList_auto){
            waitingList = "1,2"
        }else if(data.waitingList_enable){
            waitingList = "1"
        }else if(data.waitingList_auto){
            waitingList = "2"
        }
        if(data.categoryId){
            data['categoryId'] = data.categoryId.value
        }
        data['waitingList'] = waitingList;
        data['courseId'] = courseId;
        if(courseOption && courseOption.Id > 0){
            data['id'] = courseOption.Id 
            dispatch(updateCoursesOptions(data))
            return;
        }
        dispatch(addCoursesOptions(data))
    }
    return (
        <div className="course-settings">
            <div className="course-settings__container">
                <div className="course-settings__header">
                    <div className="course-settings__header-image">
                        <img src={course?.ServerFileName} alt="" />
                    </div>
                    <div className="course-settings__header-title">
                        {course?.Title}
                    </div>
                </div>
                <FormGroup method={methods} onSubmit={onSubmit}>
                    <div className="course-settings__body">
                        <CoursePanel title="Course Options" >
                            <CourseRow label="Course Category">
                                <FormItem 
                                    formName="reactselect"
                                    options={courseCategories}
                                    name="categoryId"
                                    width="250px"
                                    rules={{ required: 'Category is required!' }}
                                />
                            </CourseRow>
                        </CoursePanel>
                        <CoursePanel title="Credits and Navigation" >
                            <CourseRow label="Course Credits">
                                <FormItem 
                                    type="number"
                                    name="credits"
                                    width="150px"
                                />
                            </CourseRow>
                            <CourseRow label="Pass Marks">
                                <FormItem 
                                    type="number"
                                    name="passMarks"
                                    width="150px"
                                />
                            </CourseRow>
                            <CourseRow label="Navigation Policy">
                                {
                                    courseNavigationPolicies && courseNavigationPolicies.map(item=>{
                                        return <FormItem 
                                                    formName="radiogroup"
                                                    value={item.value}
                                                    // checked={false}
                                                    labelPosition="right"
                                                    label={item.label}
                                                    checked={item.value == watch("navigationPolicy",control)}
                                                    name="navigationPolicy"
                                                    width="350px"
                                                />
                                    })
                                }
                            </CourseRow>
                            <CourseRow label="Max No of Attempts">
                                <FormItem 
                                    type="number"
                                    name="maxNoOfAttempts"
                                    width="150px"
                                />
                            </CourseRow>
                            <CourseRow label="Course Rating">
                                <FormItem 
                                    formName="checkgroup"
                                    name="courseRating"
                                    label="Allow"
                                    value="Allow"
                                    width="150px"
                                    checked={ watch("courseRating",control)}
                                    labelPosition="right"
                                />
                            </CourseRow>
                        </CoursePanel>
                        <CoursePanel title="Assignment and Enrollment" >
                            <CourseRow label="Show Course to">
                                {
                                    courseShowTo && courseShowTo.map(item=>{
                                        return <FormItem 
                                                    formName="radiogroup"
                                                    value={item.value}
                                                    // checked={false}
                                                    labelPosition="right"
                                                    checked={item.value == watch("showCourseTo",control)}
                                                    label={item.label}
                                                    name="showCourseTo"
                                                    width="350px"
                                                />
                                    })
                                }
                            </CourseRow>
                            <CourseRow label="Maximum Quota">
                                <FormItem 
                                    type="number"
                                    name="maximumQuota"
                                    width="150px"
                                />
                            </CourseRow>
                            <CourseRow label="Enrollment">
                                {
                                    courseEnrollements && courseEnrollements.map(item=>{
                                        return <FormItem 
                                                    formName="radiogroup"
                                                    value={item.value}
                                                    // checked={false}
                                                    labelPosition="right"
                                                    label={item.label}
                                                    checked={item.value == watch("enrollment",control)}
                                                    name="enrollment"
                                                    width="350px"
                                                />
                                    })
                                }
                            </CourseRow>
                            <CourseRow label="Waiting List">
                                <FormItem 
                                    formName="checkgroup"
                                    name="waitingList_enable"
                                    label="Enable Waiting List"
                                    value="Enable Waiting List"
                                    width="320px"
                                    labelPosition="right"
                                    checked={watch("waitingList_enable",control)}
                                />
                                <FormItem 
                                    formName="checkgroup"
                                    checked={ watch("waitingList_auto",control)}
                                    name="waitingList_auto"
                                    label="Automatically Enroll the Next Learner in the List"
                                    value="Automatically Enroll the Next Learner in the List"
                                    width="350px"
                                    labelPosition="right"
                                />
                            </CourseRow>
                        </CoursePanel>
                        <CoursePanel title="Assignment and Enrollment" >
                            <CourseRow label="Start Date">
                                <FormItem 
                                        formName="dateinput"
                                        name="startDate"
                                        width="200px"
                                    />
                            </CourseRow>
                            <CourseRow label="End Date">
                                <FormItem 
                                        formName="dateinput"
                                        name="endDate"
                                        width="200px"
                                    />
                            </CourseRow>
                            <CourseRow label="Access Validity">
                                <div className="flex">
                                    <FormItem 
                                        type="number"
                                        name="accessValidity"
                                        width="150px"
                                    />
                                    <span>Days</span>
                                </div>

                            </CourseRow>
                        </CoursePanel>
                        <Button title="Submit" htmlButtonType="submit" bg="primary" />
                    </div>
                </FormGroup>
                
            </div>
        </div>
    )
}

const CourseRow = ({label,children}) => {
    return(
        <div className="course-settings__row">
            <div className="course-settings__label">
                {label}
            </div>
            <div className="course-settings__form">
                {children}
            </div>
        </div>
    )
}

const CoursePanel = ({title,children}) => {
    return(
        <div className="course-settings__panel">
            <div className="course-settings__panel-header">
                {title}
            </div>
            <div className="course-settings__panel-body">
                {children}
            </div>
        </div>
    )
}

export default CourseSettings
