import axios from "axios";
import {
  GetEmployeeList,
  GetEducationLevel,
  GetExperienceDuration,
  GetJobStatus,
  GetSalaryPayDuration,
} from "actions/commonAction";
import {
  GET_BRANCHES,
  GET_DEPARTMENTS,
  GET_EDUCATION_LEVELS,
  GET_EXPERIENCE_DURATION,
  GET_JOB_STATUS,
  GET_LOCATION,
  GET_REQUISITION_LIST_COUNT,
  IS_REQUISITION_DETAILS_LOADING,
  SET_SPINNER_LOADING,
} from "actions/types";
import { toastMessage } from "actions/validateAction";
import {
  getBranches,
  getDepartments,
  getLevels,
} from "components/dataselect/action";
import { Arrays } from "helpers/getTextLabel";

export const FetchJobRequisitionData = (travelRequestId) => (dispatch) => {
  dispatch({
    type: IS_REQUISITION_DETAILS_LOADING,
    payload: true,
  });
  axios
    .all([
      // axios.put(
      //   `/v1/travel/travel-information-edit?requestid=${travelRequestId}`
      // ),
      axios.get(`/v1/common/branches`),
      axios.get(`/v1/common/departments`),
      axios.get(`/v1/common/locations`),
      axios.get(`/v1/common/shared/status`),
      axios.get(`/v1/jp/educations`),
      axios.get(`/v1/common/experience-duration`),
      axios.get(`/v1/recruitment/requisitions/count`),
    ])
    .then(
      axios.spread(
        (
          firstResponse,
          secondResponse,
          thirdResponse,
          fourthResponse,
          fifthResponse,
          sixthResponse,
          seventhRespone
        ) => {
          dispatch({
            type: GET_BRANCHES,
            payload: Arrays(firstResponse.data),
          });
          dispatch({
            type: GET_DEPARTMENTS,
            payload: Arrays(secondResponse.data),
          });
          dispatch({
            type: GET_LOCATION,
            payload: Arrays(thirdResponse.data),
          });

          dispatch({
            type: GET_JOB_STATUS,
            payload: fourthResponse?.data?.Data.map((x) => {
              return { value: x.StatusId, label: x.Name };
            }),
          });
          dispatch({
            type: GET_EDUCATION_LEVELS,
            payload: fifthResponse?.data?.Data.map((x) => {
              return { value: x.EducationId, label: x.Name };
            }),
          });

          dispatch({
            type: GET_EXPERIENCE_DURATION,
            payload: Arrays(sixthResponse.data),
          });
          dispatch({
            type: GET_REQUISITION_LIST_COUNT,
            payload: seventhRespone.data.Data[0],
          });
          //   dispatch({
          //     type: GET_EMPLOYEE_LIST,
          //     payload: sixthResponse.data
          //   });
          //   dispatch({
          //     type: IS_LOADING,
          //     isLoading: false
          //   });
          dispatch({
            type: IS_REQUISITION_DETAILS_LOADING,
            payload: false,
          });
        }
      )
    )
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to get job requisition data.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const FetchJobCreateData = () => (dispatch) => {
  dispatch(getBranches());
  dispatch(getDepartments());
  dispatch(getLevels());
  dispatch(GetEducationLevel());
  dispatch(GetJobStatus());
  dispatch(GetExperienceDuration());
  dispatch(GetSalaryPayDuration());
  dispatch(GetEmployeeList());
  //dispatch(GetEmployeeList());
};

// Set loading state
export const setSpinnerLoading = (data) => {
  return {
    type: SET_SPINNER_LOADING,
    payload: data,
  };
};
