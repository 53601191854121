import React, { Component } from 'react'

export default class GlobalErrorBoundry extends Component<any,any> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
      }
    
      componentDidCatch(error, info) {
          console.log({error,info})
        // Display fallback UI
        this.setState({ hasError: true });
      }
    
      render() {
        if (this.state.hasError) {
          return (
            <div className="error-message">
                <span>
                    Something went wrong with your app
                </span>
            </div>
          );
        }
    
        return this.props.children;
      }
}
