import React, { useState } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { ValidationComponent } from "helpers/getComponents";
import Form from "components/form";

function ClientProjectFee(props) {
  const errors = props && props.data ? props.data.errors : {};
  const values = props && props.data ? props.data : {};
  let selectedClientTypeId = values.selectedClientTypeId;

  // const [selectedClientTypeId, setSelectedClientTypeId] = useState(
  //   values.selectedClientTypeId
  // );
  // values.selectedClient && values.selectedClient.value.ClientTypeRefId;

  const chipClasses = (typeId) => {
    return classnames({
      badges: true,
      "badges-rounded": true,
      "left-icon": selectedClientTypeId == typeId,
      "badges-primary": selectedClientTypeId == typeId,
      "badges-default": selectedClientTypeId != typeId,
    });
  };

  // const inputclass = (error) => {
  //   return classnames({
  //     "fees-row": true,
  //     error: error,
  //   });
  // };

  const onChange = (name, value) => {
    
    props.handleInput(name, value);
  };

  const legalClientTypes = props.legalClientTypes;
  const isHourlyRate = [1, 2, 3, 4].includes(selectedClientTypeId);
  // let clientCurrency = values.CurrencyCode;

  const handleAutoSelect = (name, selected) => {
    props.handleAutoSelect(name, selected);
  };

  const { secretarialChargesTypes } = props;

  let selectedClientTypeName = "";
  if (legalClientTypes) {
    const activeClientType = legalClientTypes.find(
      (f) => f.value == selectedClientTypeId
    );
    selectedClientTypeName = activeClientType && activeClientType.label;
  }
  return (
    <div className="fees">
      <div className="fees-row">
        <Form
          formName="chipsinput"
          name={"selectedClientTypeId"}
          chipsArray={legalClientTypes}
          value={selectedClientTypeName}
          onChange={props.handleClientType}
          label="Type"
        />
      </div>

      <div className="fees-row flex">
        {selectedClientTypeId == 1 && (
          <span className="genericForm-group__label">{"Legal Advisory"}</span>
        )}
        <ValidationComponent
          error={errors && errors.contractHours}
          errorClassName="error__message"
        >
          <Form
            formName="hourMinute"
            // width="200px"
            name="contractHours"
            id="contractHours"
            value={values.contractHours}
            label="Contract Hours"
            onChange={onChange}
            validators={["numberAndDecimals"]}
          />
        </ValidationComponent>
        {selectedClientTypeId != "3" && (
          <div rootClassName="">
            <Form
              formName="textinput"
              width="120px"
              name="contractAmount"
              id="contractAmount"
              value={values.contractAmount}
              label="Contract Amount"
              validators={["numberAndDecimals"]}
              onChange={onChange}
            />
          </div>
        )}
        <div rootClassName="">
          <Form
            formName="textinput"
            width="120px"
            name="contractCurrency"
            // value={values.contractCurrency}
            value={values.CurrencyCode}
            label="Contract Currency"
            disabled
          />
        </div>
      </div>

      {selectedClientTypeId == 1 && (
        <>
          <div className="fees-row flex">
            <span className="genericForm-group__label">{"Meeting"}</span>
            <ValidationComponent
              error={errors && errors.meetingContractHours}
              errorClassName="error__message"
            >
              <Form
                formName="hourMinute"
                // width="200px"
                name="meetingContractHours"
                id="meetingContractHours"
                value={values.meetingContractHours}
                label="Contract Hours"
                onChange={onChange}
                validators={["numberAndDecimals"]}
              />
            </ValidationComponent>
            {selectedClientTypeId != "3" && (
              <div rootClassName="form-group">
                <Form
                  formName="textinput"
                  width="120px"
                  name="meetingContractAmount"
                  id="meetingContractAmount"
                  value={values.meetingContractAmount}
                  label="Contract Amount"
                  validators={["numberAndDecimals"]}
                  onChange={onChange}
                />
              </div>
            )}
            <div rootClassName="form-group">
              <Form
                formName="textinput"
                width="120px"
                name="meetingContractCurrency"
                // value={values.meetingContractCurrency}
                value={values.CurrencyCode}
                label="Contract Currency"
                disabled
              />
            </div>
          </div>

          <div className="fees-row flex">
            <span className="genericForm-group__label">
              {"Additional Rate"}
            </span>

            <Form
              formName="textinput"
              name={"additionalRate"}
              value={values.additionalRate}
              onChange={onChange}
              width="300px"
            />
          </div>
        </>
      )}

      {isHourlyRate && (
        <>
          <div className="bold_title">Hourly Rates</div>
          {values.billingDesignations &&
            values.billingDesignations.map((billing, index) => (
              <div className="mt-sm" key={billing.Value}>
                <ValidationComponent
                  rootClassName="form-group"
                  error={errors && errors.mPartner}
                  errorClassName="error__message"
                >
                  <Form
                    formName="textinput"
                    alignment="horizontal"
                    type="number"
                    name={`billing${billing.Value}`}
                    value={billing["HourlyRate"]}
                    validators={["numberAndDecimals"]}
                    onChange={onChange}
                    width="40%"
                    label={billing.Text}
                  />
                </ValidationComponent>
              </div>
            ))}
        </>
      )}

      <div className="bold_title">Pocket Expenses</div>
      <div className="fees-row">
        <ValidationComponent
          rootClassName="form-group"
          errorClassName="error__message"
        >
          <Form
            formName="reactselect"
            label="Calculation"
            onChange={handleAutoSelect}
            name="secretarialCharges"
            value={values.secretarialCharges}
            options={secretarialChargesTypes}
          />
        </ValidationComponent>
        {values.secretarialCharges.value == "1" ? (
          <ValidationComponent
            rootClassName="form-group"
            error={errors && errors.amount}
            errorClassName="error__message"
          >
            <Form
              formName="textinput"
              name="amount"
              value={values.amount}
              validators={["numberAndDecimals"]}
              label="Amount"
              onChange={onChange}
              width="150px"
            />
          </ValidationComponent>
        ) : (
          <ValidationComponent
            rootClassName="form-group"
            error={errors && errors.amount}
            errorClassName="error__message"
          >
            <Form
              formName="textinput"
              name="amount"
              value={values.amount}
              validators={["numberAndDecimals"]}
              onChange={onChange}
              label="Percentage"
              width="150px"
            />
          </ValidationComponent>
        )}
      </div>
      <div className="fees-row">
        <Form
          formName="textarea"
          name="secretarialChargesNote"
          value={values.secretarialChargesNote}
          label="Note"
          onChange={onChange}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  billingDesignations: state.taskTrackerReducer.billingDesignations,
  // currencyList: state.commonReducer.currencyList,
  legalClientTypes: state.taskTrackerReducer.legalClientTypes,
  secretarialChargesTypes: state.taskTrackerReducer.secretarialChargesTypes,
});

export default connect(mapStateToProps, null)(ClientProjectFee);
