import React from "react";
import { AiOutlineDoubleRight } from "react-icons/ai";
import isEmpty from "isEmpty";
import { withRouter } from "react-router-dom";
import { TRAVEL_PATHS } from "constants/paths";
import Title from "../../common/title";

function CommonHeader(props) {
  let pathName = !isEmpty(
    TRAVEL_PATHS.find((x) => x.PathName === props.location.pathname)
  )
    ? TRAVEL_PATHS.find((x) => x.PathName === props.location.pathname).Title
    : "Travel Requests";

  return (
    <Title name={pathName} parentProps={props} hideAssignAndApprove={true} />
    // <div className="travel-route-title">
    //   <span
    //     className="module"
    //     onClick={() =>
    //       props.history && props.history.push(TeamTravelRequestPath)
    //     }
    //   >
    //     Home Page
    //   </span>
    //   <AiOutlineDoubleRight className="right-arrow" />
    //   <span className="path-name">{pathName}</span>
    // </div>
  );
}

export default withRouter(CommonHeader);
