import React, { Component } from 'react'
import Toast from '../toast'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'helpers/isEmpty'
import classnames  from 'classnames';

interface Validated{
    validateReducer:any
}

export class Validate extends Component<Validated> {
    render() {
        const {validateReducer} = this.props;
        const {messages, messages:{message,type,position,timeout},arrayMessages}=validateReducer;
        
        let newMessage;
        if(validateReducer && !isEmpty(messages)){
            newMessage = type==="Success" && message ===null ? "Success" :message===null?[]:message
        }
        return (
            <>
                {
                    !isEmpty(validateReducer && messages)?
                    <Toast 
                        type={type} 
                        timeout={timeout}
                        position={position?position:"bottom-right"}
                        >
                        {
                            newMessage
                        }
                    </Toast>
                    :null
                }
                {
                    !isEmpty(validateReducer && arrayMessages)?
                    <div className={
                        classnames({
                            "toast-container":true,
                            'bottom-left':arrayMessages[0].position==='bottom-left',
                            'bottom-right':arrayMessages[0].position==='bottom-right',
                            'bottom-center':arrayMessages[0].position==='bottom-center',
                            'top-left':arrayMessages[0].position==='top-left',
                            'top-right':arrayMessages[0].position==='top-right',
                            'top-center':arrayMessages[0].position==='top-center',
                        })
                        } 
                    >
                        {
                            arrayMessages.map(msg=>{
                                return(
                                    <Toast type={msg.type} id={msg.id} remove position={msg.position?position:"bottom-right"}>
                                    {
                                        msg.message
                                    }
                                    </Toast>
                                )
                            })
                        }
                    </div>
                    
                    :null
                }
            </>
            
            
        )
    }
}
const mapStateToProps = state => ({
    validateReducer: state.validateReducer,
});
export default connect(mapStateToProps)(Validate);