import React, { Component } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Filter,
  Inject,
  Sort,
  SortSettingsModel,
} from "@syncfusion/ej2-react-grids";
import TableRow from "./tableRow";
import classNames from "classnames";

export class Table extends Component {
  constructor(props) {
    super(props);
    this.gridTemplate = this.gridTemplate;
    this.row = [];
  }

  gridTemplate(props, history) {
    return (
      <TableRow
        {...props}
        {...history}
        handleDelete={this.props.handleDelete}
        handleEdit={this.props.handleEdit}
        ref={(row) => {
          this.row = [...this.row, row];
        }}
      />
    );
  }

  render() {
    const { tableStructure, tableData, ksaView } = this.props;
    return (
      <div
        className={classNames({
          table: true,
          "wd-60": ksaView,
        })}
      >
        <div className="arrear-table">
          <div className="">
            <GridComponent
              dataSource={tableData}
              className="custom-grid"
              // toolbar={this.toolbar}
              // filterSettings={{ type: "Menu" }}
              // allowFiltering={true}
              //  height={height}
              id="grid"
              // allowExcelExport={true}
              ref={(g) => (this.grid = g)}
              rowTemplate={this.gridTemplate.bind(this)}
              allowSorting={true}
              sortSettings={this.sortingOptions}
            >
              <ColumnsDirective>
                {tableStructure &&
                  tableStructure.map((ts, i) => {
                    return (
                      <ColumnDirective
                        field={ts.field}
                        headerText={ts.headerText}
                        width={ts.width}
                      />
                    );
                  })}
              </ColumnsDirective>
              <Inject services={[Sort]} />
            </GridComponent>
          </div>
        </div>
      </div>
    );
  }
}

export default Table;
