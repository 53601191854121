import React from "react";
import {
  AiOutlineRight
} from "react-icons/ai";
import RouteEnum from "constants/routeEnum";
import { useHistory } from "react-router-dom";

function Breadcrumb(props) {
  const history = useHistory();
  const { name } = props && props;
  return (
    <div className="common-route-title">
      <span
        className="module"
        onClick={() => history.push(RouteEnum.SbiLoanSetting)}
        style={{ paddingLeft: 0 }}
      >
        Dashboard
      </span>
      {
        props.from &&
          <span onClick={() => history.goBack()}>
            <AiOutlineRight className="right-arrow" />
            <span className="module">{props.from}</span>
          </span>
      }
      <AiOutlineRight />
      <span className="path-name">{name}</span>
    </div>
  );
}

export default Breadcrumb;
