import isEmpty from "isEmpty";

export function yearDifference(startDate, endDate) {
  let result = null;
  if (!isEmpty(startDate) && !isEmpty(endDate)) {
    let diff = (startDate.getTime() - endDate.getTime()) / 1000;
    diff /= 60 * 60 * 24;
    result = Math.abs(Math.round(diff / 365.25));
  }
  return result;
}

export const handleMergedData = (data1, data2) => {
  let result = "";
  if (data1 && data2) {
    result = data1 + ", " + data2;
  } else if (data1 && !data2) {
    result = data1;
  } else if (data2 && !data1) {
    result = data2;
  }
  return result;
};
