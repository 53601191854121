import React, { Component } from "react";
import "./style.scss";
import LeaveTable from "./leaveTable";
import LeaveTab from "./leaveTab";

export const Leave = (props) => {
  return (
    <div className="leave-container">
      <div className="leave-block">
        {/* <div className="leave-heading">
          <h3>Leaves</h3>
        </div> */}
        <LeaveTable {...props} />
      </div>
      <div className="leave-block">
        <div className="leave-heading">
          <h3>Leaves</h3>
        </div>
        <LeaveTab />
      </div>
    </div>
  );
};
export default Leave;
