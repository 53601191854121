import axios from "axios";
import {
    SEARCHED_STOP_PAYMENT,
    CLEAR_ERRORS,
    EDIT_STOP_PAYMENT,
    ADD_STOP_PAYMENT,
    GET_STOP_PAYMENT,
    GET_ERRORS,
    DELETE_STOP_PAYMENT,
    STOP_PAYMENT_LOADING,
    ADD_MESSAGE
} from "../../../actions/types";
import {toastMessage} from '../../../actions/validateAction'


// Get Filtered Leave
export const searchStopPayment = data => dispatch => {
    dispatch(setStopLoading());
    axios
        .post(`/v1/payroll/stop-payments/search`, data)
        .then(res => {
            dispatch({
                type: SEARCHED_STOP_PAYMENT,
                payload: res.data.Data,
                page: {
                    index: res.data.PageIndex,
                    total: res.data.RecordsTotal
                }
            });
        })
        .catch(err => {
            dispatch({
                type: SEARCHED_STOP_PAYMENT,
                payload: null
            })
            // toastMessage(dispatch, err);
        });
};

// Post Stop Payment
export const addStopPayment = data => dispatch => {
    // dispatch(setStopLoading());
    axios
        .post(`/v1/payroll/stop-payments`, data)
        .then(res => {
            console.log(res)
            
            toastMessage(dispatch,res);
            if(res.data.Data !== null && res.data.Status){
                dispatch({
                    type: ADD_STOP_PAYMENT,
                    payload: res.data.Data,
                    // id:data.EmployeeId
                })
            }
            
        })
        .catch(err =>
            dispatch({
                type: ADD_STOP_PAYMENT,
                payload: null
            })
        );
};

export const updateStopPayment = data => dispatch => {
    // dispatch(setStopLoading());
    axios
        .put(`/v1/payroll/stop-payments`, data)
        .then(res => {
            console.log(res)
            toastMessage(dispatch,res);
            if(res.data.Data !== null && res.data.Status){
                dispatch({
                    type: EDIT_STOP_PAYMENT,
                    payload: res.data.Data,
                    id:res.data.Data.StopPaymentId
                })
            }
        })
        .catch(err =>
            dispatch({
                type: EDIT_STOP_PAYMENT,
                payload: null
            })
        );
};

//   Delete Salary Adjustment
export const deleteStopPayment = id => dispatch => {
    axios
        .delete(`/v1/payroll/stop-payments/${id}`)
        .then(res => {
            toastMessage(dispatch,res);
            if(res.data.Status){
                dispatch({
                    type: DELETE_STOP_PAYMENT,
                    payload: res.data.Data,
                    id:id
                });
            }
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

// Set loading state
export const setStopLoading = () => {
    return {
        type: STOP_PAYMENT_LOADING
    };
};

// Clear errors
export const clearErrors = () => {
    return {
        type: CLEAR_ERRORS
    };
};
