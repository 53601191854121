import React, { useReducer, useEffect } from "react";
import CommonHeader from "./common/commonHeader";
import CommonTabs from "scenes/common/tab/tabs";
import "./requests.scss";
import { connect } from "react-redux";
import { GetEmployeeRequestCount } from "./actions";

interface IAppProps {
  match: any;
  listCount: any;
  GetEmployeeRequestCount: Function;
}

const App: React.FunctionComponent<IAppProps> = (props) => {
  const { match, listCount } = props;
  useEffect(() => {
    props.GetEmployeeRequestCount();
  }, []);
  const {
    reviewRequestCount,
    myRequestCount,
    teamHistoryCount,
    myHistoryCount,
  } = listCount;
  const tabContent = [
    {
      id: 1,
      num: "1",
      title: "Requests to review",
      type: "success",
      link: "/review",
      count: reviewRequestCount,
    },
    {
      id: 2,
      num: "2",
      title: "My Requests",
      type: "primary",
      link: "/my_request",
      count: myRequestCount,
    },
    {
      id: 3,
      num: "3",
      title: "Team History",
      type: "primary",
      link: "/team_history",
      count: teamHistoryCount,
    },
    {
      id: 4,
      num: "4",
      title: "My History",
      type: "primary",
      link: "/my_history",
      count: myHistoryCount,
    },
  ];
  return (
    <div className="common-container">
      <div className="employee-request">
        <div className="employee-request__header common-header">
          <div
            // style={{ alignItems: "flex-end" }}
            className="common-header-title-no-pd space-between mb-sm"
          >
            <CommonHeader props={props} />
          </div>
          <CommonTabs tabContent={tabContent} match={props.match} />
        </div>

        {props.children}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  listCount: state.employeeRequestReducer.listCount,
});

const mapDispatchToProps = { GetEmployeeRequestCount };

export default connect(mapStateToProps, mapDispatchToProps)(App);
