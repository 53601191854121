import React, { Component } from 'react'
import FormGroup from '../form'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import Skeleton from '@material-ui/lab/Skeleton';
import {
    getSubDepartments,
    getLevels,
    getEmployeeStates,
    getLocation,
    getDesignations,
    getGenders,
    getMaritalStatus
} from './action'
import {ArraysKey} from 'helpers/getTextLabel';

export class AdvancedFilterSelect extends Component {
    componentDidMount() {
        this.props.getSubDepartments();
        this.props.getLevels();
        this.props.getEmployeeStates();
        this.props.getLocation();
        this.props.getDesignations();
        this.props.getGenders();
        this.props.getMaritalStatus();
    }
    // async componentDidMount() {
    //     Promise.all(p).then(()=> {
    //         // redirection here
    //         this.props.getSubDepartments();
    //         this.props.getLevels();
    //         this.props.getEmployeeStates();
    //         this.props.getLocation();
    //     });
        
    // }
    render() {
        const {value,onChange,selectReducer,isGender,isMaritial}=this.props;
        const {subDepartment,location,levels,employeeStates,designations,genders,maritialStatus}=selectReducer;
        const state = [
            {Text:'Active',Value:1,Selected:false},
            {Text:'Retired',Value:2,Selected:false},
            
        ]
        return (
            <>
                <div className="filters-input__item">
                    <FormGroup 
                        formName="reactselect"
                        onChange={onChange}
                        value={value.level}
                        options={levels.value}
                        label="Level"
                        name="level"
                        actionType="level"
                        width="200px"
                        isArray
                        loading={levels.loading}
                        loadingType="skeleton"
                    />
                </div>
                <div className="filters-input__item">
                    <FormGroup 
                        formName="reactselect"
                        onChange={onChange}
                        value={value.position}
                        label="Position"
                        options={designations.value}
                        name="position"
                        actionType="position"
                        width="200px"
                        isArray
                        loading={designations.loading}
                        loadingType="skeleton"
                    />
                </div>
                <div className="filters-input__item">
                    <FormGroup 
                        formName="reactselect"
                        onChange={onChange}
                        value={value.empState}
                        label="State"
                        options={employeeStates.value}
                        name="empState"
                        actionType="state"
                        width="200px"
                        loading={employeeStates.loading}
                        loadingType="skeleton"
                        isArray
                    />
                </div>
                <div className="filters-input__item">
                        <FormGroup 
                            formName="reactselect"
                            onChange={onChange}
                            value={value.subDepartment}
                            label="Sub Department"
                            options={subDepartment.value}
                            name="subDepartment"
                            actionType="subDepartment"
                            width="200px"
                            loading={subDepartment.loading}
                            loadingType="skeleton"
                            isArray
                        />
                </div>
                <div className="filters-input__item">
                    <FormGroup 
                        formName="reactselect"
                        onChange={onChange}
                        value={value.location}
                        options={location.value}
                        label="Location"
                        name="location"
                        actionType="location"
                        width="200px"
                        loading={location.loading}
                        loadingType="skeleton"
                        isArray
                    />
                </div>
                {
                    isGender ? 
                        <div className="filters-input__item">
                            <FormGroup 
                                formName="reactselect"
                                onChange={onChange}
                                value={genders.value}
                                options={genders.value}
                                label="Genders"
                                name="genders"
                                actionType="genders"
                                width="200px"
                                loading={genders.loading}
                                loadingType="skeleton"
                                isArray
                                isArrayKeys
                            />
                        </div>
                    :null
                }
                {
                    isMaritial?
                        <div className="filters-input__item">
                            <FormGroup 
                                formName="reactselect"
                                onChange={onChange}
                                value={maritialStatus.value}
                                options={maritialStatus.value}
                                label="Maritial Status"
                                name="maritialStatus"
                                actionType="maritialStatus"
                                width="200px"
                                loading={maritialStatus.loading}
                                loadingType="skeleton"
                                isArray
                                isArrayKeys
                            />
                        </div>
                    :null
                }
                
            </>
            
        )
    }
}

AdvancedFilterSelect.propTypes = {
    getSubDepartments:PropTypes.func.isRequired,
    getLevels:PropTypes.func.isRequired,
    getEmployeeStates:PropTypes.func.isRequired,
    getLocation:PropTypes.func.isRequired,
    onChange:PropTypes.func.isRequired,
    value:PropTypes.any.isRequired,
    getDesignations:PropTypes.func.isRequired,
    selectReducer: PropTypes.object.isRequired,
    getMaritalStatus:PropTypes.func.isRequired,
    getGenders:PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    selectReducer: state.selectReducer,
});

export default connect(mapStateToProps,{
        getSubDepartments,
        getLevels,
        getEmployeeStates,
        getLocation,
        getDesignations,
        getMaritalStatus,
        getGenders
    })(AdvancedFilterSelect)
