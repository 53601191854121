import React from "react";
import "../style.scss";
import { connect } from "react-redux";
import { currencyCommaSeperator } from "../../../helpers/currencyFormatter";

function StaffODDetails({ staffODLoanDetail }) {
  return (
    <div className="homeloan-view-employeedetails homeloan-view-vehicledetails healthCard__health mt-md">
      <div className="healthCard-heading">
        <h4>Staff Overdraft</h4>
      </div>
      <div className="healthCard__health__details">
        <div className="placement-details">
          <div className="placement-details__block">
            <div className="placement-details__table">
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Loan Purpose
                </div>
                <div className="placement-details__col placement-val">
                  {staffODLoanDetail?.LoanPurpose == "Other"
                    ? staffODLoanDetail?.OtherPurpose
                    : staffODLoanDetail?.LoanPurpose}
                </div>
              </div>

              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Note
                </div>
                <div className="placement-details__col placement-val">
                  {staffODLoanDetail?.Note}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Requested Amount
                </div>
                <div className="placement-details__col placement-val">
                  NPR {currencyCommaSeperator(staffODLoanDetail?.RequestAmount)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  staffODLoanDetail: state.loanReducer.staffODLoanDetail,
});

export default connect(mapStateToProps, {})(StaffODDetails);
