import React, { Component } from 'react'
import DragBlock from './components/dashboard/dragBlock';
import './payroll.styles.scss';
import {getPayrollDashboard} from './helpers/action';
import {connect} from 'react-redux';
import isEmpty from 'isEmpty'
import Button from 'components/button'
import {Link} from 'react-router-dom'
import RouteEnum from 'constants/routeEnum'
import SvgIcons from 'components/icons/svgIcons';
import FavIconModal from 'components/common/favIconModal';
import {modules as common} from '../commonTasks/index'
import {modules as report} from '../reports/index'
import {addDashboardBlocks} from '../../hr/helpers/action'
import { Features } from "helpers/getPermissions";

export class PayrollDashboard extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             
        }
        this._renderCount = this._renderCount.bind(this);
        this._renderFavButton = this._renderFavButton.bind(this);
    }
    componentDidMount(){
        this.props.getPayrollDashboard()
    }
    _renderCount(){
        const {baseUrl} = this.props;
        let list = [
            {name:'Employees',label:'TotalEmployees',isReact:true,link:RouteEnum.EmployeeListPath},
            {name:'New Joins',label:'NewJoinEmployees'},
            {name:'Retiring',label:'RetiringEmployees'},
            {name:'Contract-Expiring',label:'ContracExpiring'},
            {name:'Branches',label:'Branches',link:baseUrl + 'CP/ManageBranch.aspx'},
            {name:'Departments',label:'Departments',link:baseUrl + 'CP/ManageDep.aspx'},
        ]
        const {EmployeeDetailsCount} = this.props.payrollDashboard;
        return(
            <div className="flex">
                <div className="count-current">
                    <p>Current payroll</p>
                    <h3>December 2019</h3>
                    <button onClick={()=> this.props.history.push('/payroll')}
                        className="btn btn-primary">Continue</button>
                    {/* <button 
                        onClick={()=>window.open(baseUrl + 'CP/Calculation.aspx')}
                        className="btn btn-primary">Continue</button> */}
                </div>
                <div className="count-block">
                    <ul className="count-block__list">
                        {list.map((lis,index)=>{
                            const num = EmployeeDetailsCount[lis.label] ? EmployeeDetailsCount[lis.label] : '-';
                            return(
                                <li key={index + Math.random()} className="count-block__item">
                                    {
                                            lis.link? 
                                            lis.isReact ?
                                            <Link to={lis.link}>
                                                <div className="count-number">
                                                    <span>{num}</span>
                                                </div>
                                                <div className="count-name">
                                                    <span>{lis.name}</span>
                                                </div>
                                            </Link>
                                            :
                                            <a target="_blank" href={lis.link}>
                                                <div className="count-number">
                                                    <span>{num}</span>
                                                </div>
                                                <div className="count-name">
                                                    <span>{lis.name}</span>
                                                </div>
                                            </a>
                                            :
                                            <a>
                                                <div className="count-number">
                                                    <span>{num}</span>
                                                </div>
                                                <div className="count-name">
                                                    <span>{lis.name}</span>
                                                </div>
                                            </a>
                                    }
                                    
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        )
    }
    _renderFavButton(){
        const features = Features()
        return(
            <div className="fav-button-block">
                <div className="fav-button-group">
                    <Button 
                        buttonType="icon-button"
                        icon={<SvgIcons name="tasks" color="#3a3d43" size="17px" />}
                        title="Common Tasks"
                        bg="white"
                        onClick={()=>this.props.history.push(RouteEnum.PayrollDashboardCommonTaskPath)}
                    />
                    <Button 
                        buttonType="icon-button"
                        icon={<SvgIcons name="fileInvoice" color="#3a3d43" size="14px" />}
                        title="Reports"
                        bg="white"
                        onClick={()=>this.props.history.push(RouteEnum.PayrollDashboardReportsPath)}
                    />
                    <FavIconModal dashboardName="Payroll" common={common(features)} reports={report(features)} /> 
                </div>
            </div>
        )
    }
    render() {
        const {payrollDashboard} = this.props;
        console.log(this.props)
        return (
            <div className="admin">
                <div className="admin-container">
                    <div className="admin-title">
                        <h1>Payroll Dashboard</h1>
                    </div>
                    {
                        isEmpty(payrollDashboard)?
                        (
                            <span className="no-found">No Data Loaded</span>
                        )
                        :
                        (
                            <div className="admin-body">
                                { this._renderCount() }
                                { this._renderFavButton() }
                                <div className="payroll-drag">
                                    <DragBlock addDashboardBlocks={this.props.addDashboardBlocks} blocks={this.props.dashboardBlock} baseUrl={this.props.baseUrl} />
                                </div>
                            </div>
                        )
                    }
                    
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = {
    getPayrollDashboard,
    addDashboardBlocks
}
const mapStateToProps = state =>({
    payrollDashboard:state.payrollDashboardReducer.payrollDashboard,
    dashboardBlock:state.hrReducer.dashboardBlock
})

export default connect(mapStateToProps,mapDispatchToProps)(PayrollDashboard)
