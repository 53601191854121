import axios from 'axios';

const setAuthToken = token =>{
    axios.defaults.headers.loading=true;
    axios.defaults.headers['Content-Type'] = 'application/json'
    if(token){
        //Apply to every request 
        axios.defaults.headers.common['Authorization'] =token;
    }else{
        //Delete Auth Header 
        delete axios.defaults.headers.common['Authorization'];
    }
}

export default setAuthToken