import React, { Component } from "react";
import "./ksalibrary.style.scss";
import { MdAdd } from "react-icons/md";
import { connect } from "react-redux";
import Title from "scenes/common/title";
import { Tabs, Tab } from "components/tabs/index.d";
import Modal from "components/modal";
import FormGroup from "components/form";
import Education from "./education";
import Certifications from "./certifications";
import KsaView from "./ksaView";
import { Enum_KSA_SkillType } from "./enums";
import { InputValidator } from "helpers/inputValidator";
import isEmpty from "isEmpty";
import {
  GetEducationList,
  GetEducationById,
  GetEducationLevelForDDL,
  GetEducationTypeForDDL,
  CreateEducation,
  UpdateEducation,
  GetCertificationById,
  GetCertificationList,
  GetCertificationTypeForDDL,
  CreateCertifications,
  UpdateCertifications,
  GetKSA_Types,
  GetKSA_ListByType,
  GetKSA_ById,
  CreateKSA,
  UpdateKSA,
  DeleteEducation,
  DeleteCertification,
  DeleteKSA,
} from "./actions";

const tabs = [
  { tabId: 1, label: "Education" },
  { tabId: 2, label: "Certifications" },
  { tabId: 3, label: "Knowledge" },
  { tabId: 4, label: "Skills" },
  { tabId: 5, label: "Abilities" },
];
export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      educationId: 0,
      educationQualification: "",
      educationType: null,
      educationLevel: null,

      certificationId: 0,
      cerName: "",
      cerType: "",
      cerRenewalFrequency: "",

      ksaId: 0,
      knowledgeStatement: "",
      skillStatement: "",
      abilityStatement: "",
      activeTab: tabs[0],

      showAddNewModal: false,
      isEdit: false,

      ksaType: null,
      isSubmitted: false,
    };
  }

  async componentDidMount() {
    // await this.props.GetKSA_Types();
    await this.initialize();
  }

  initialize = async () => {
    const { activeTab } = this.state;
    const currentTabId = activeTab ? activeTab.tabId : null;

    let type = null;
    if (currentTabId === 1) {
      this.props.GetEducationLevelForDDL();
      this.props.GetEducationTypeForDDL();
      await this.props.GetEducationList();
    } else if (currentTabId === 2) {
      this.props.GetCertificationTypeForDDL();
      await this.props.GetCertificationList();
    } else if (currentTabId === 3) {
      type = Enum_KSA_SkillType.Knowledge;
      await this.props.GetKSA_ListByType(type);
    } else if (currentTabId === 4) {
      type = Enum_KSA_SkillType.Skills;
      await this.props.GetKSA_ListByType(type);
    } else if (currentTabId === 5) {
      type = Enum_KSA_SkillType.Abilities;
      await this.props.GetKSA_ListByType(type);
    }
    this.setState({ ksaType: type });
  };

  refreshScreen = () => {
    this.initialize();
    this.handleModal(false);
  };

  handleSave = async () => {
    const {
      activeTab,
      educationId,
      educationQualification,
      educationType,
      educationLevel,
      certificationId,
      cerName,
      cerType,
      cerRenewalFrequency,
      ksaId,
      knowledgeStatement,
      skillStatement,
      abilityStatement,
      isEdit,
    } = this.state;
    const currentTabId = activeTab ? activeTab.tabId : null;
    let finalData = null;
    this.setState({ isSubmitted: true });
    let errors = await this.updateErrors();

    if (!isEmpty(errors)) {
      return;
    }

    if (currentTabId === 1) {
      finalData = {
        EducationId: educationId,
        Name: educationQualification,
        EducationLevelRefId: educationLevel ? educationLevel.value : 0,
        Type: educationType ? educationType.value : 0,
      };
      if (isEdit) {
        this.props.UpdateEducation(finalData, this);
      } else {
        this.props.CreateEducation(finalData, this);
      }
    } else if (currentTabId === 2) {
      finalData = {
        CertificationId: certificationId,
        Name: cerName,
        Type: cerType ? cerType.value : 0,
        RenewalFrequency: cerRenewalFrequency ? cerRenewalFrequency : 0,
      };
      if (isEdit) {
        this.props.UpdateCertifications(finalData, this);
      } else {
        this.props.CreateCertifications(finalData, this);
      }
    } else if (currentTabId === 3 || currentTabId === 4 || currentTabId === 5) {
      if (currentTabId === 3) {
        finalData = {
          KSAId: ksaId,
          Type: Enum_KSA_SkillType.Knowledge,
          Name: knowledgeStatement,
        };
      } else if (currentTabId === 4) {
        finalData = {
          KSAId: ksaId,
          Type: Enum_KSA_SkillType.Skills,
          Name: skillStatement,
        };
      } else if (currentTabId === 5) {
        finalData = {
          KSAId: ksaId,
          Type: Enum_KSA_SkillType.Abilities,
          Name: abilityStatement,
        };
      }
      if (isEdit) {
        this.props.UpdateKSA(finalData, this);
      } else {
        this.props.CreateKSA(finalData, this);
      }
    }
  };

  handleDeleteEducation = (id) => {
    this.props.DeleteEducation(id, this);
  };
  handleEditEducation = async (id) => {
    await this.props.GetEducationById(id);
    const { educationTypes, educationLevels, educationById } = this.props;
    if (educationById) {
      let educationType =
        educationTypes &&
        educationTypes.find((f) => f.value === educationById.Type);
      let educationLevel =
        educationLevels &&
        educationLevels.find(
          (f) => f.value === educationById.EducationLevelRefId
        );

      this.setState({
        educationId: educationById.EducationId,
        educationQualification: educationById.Name,
        educationType,
        educationLevel,
      });
      this.handleModal(true, true);
    }
  };

  handleDeleteCertification = (id) => {
    this.props.DeleteCertification(id, this);
  };
  handleEditCertification = async (id) => {
    await this.props.GetCertificationById(id);
    const { certificationTypes, certificationById } = this.props;

    if (certificationById) {
      let cerType =
        certificationTypes &&
        certificationTypes.find((f) => f.value === certificationById.Type);

      this.setState({
        certificationId: certificationById.CertificationId,
        cerName: certificationById.Name,
        cerRenewalFrequency: certificationById.RenewalFrequency,
        cerType,
      });
      this.handleModal(true, true);
    }
  };

  handleDeleteKSA = (id) => {
    this.props.DeleteKSA(this.state.ksaType, id, this);
  };
  handleEditKSA = async (id) => {
    const { ksaType } = this.state;
    await this.props.GetKSA_ById(this.state.ksaType, id);
    const { ksaDataById } = this.props;
    if (ksaDataById) {
      if (ksaType === Enum_KSA_SkillType.Knowledge) {
        this.setState({
          ksaId: ksaDataById.KSAId,
          knowledgeStatement: ksaDataById.Name,
        });
      } else if (ksaType === Enum_KSA_SkillType.Skills) {
        this.setState({
          ksaId: ksaDataById.KSAId,
          skillStatement: ksaDataById.Name,
        });
      } else if (ksaType === Enum_KSA_SkillType.Abilities) {
        this.setState({
          ksaId: ksaDataById.KSAId,
          abilityStatement: ksaDataById.Name,
        });
      }

      this.handleModal(true, true);
    }
  };

  handleTabChange = (tab) => {
    this.setState(
      {
        activeTab: tab,
      },
      async () => {
        this.initialize();
        await this.updateErrors();
      }
    );
  };
  handleInput = (name, value) => {
    //const { name, value } = e.target;
    const { isSubmitted } = this.state;
    this.setState({ [name]: value }, async () => {
      isSubmitted && (await this.updateErrors());
    });
  };

  handleAutoSelect = (name, selected) => {
    const { isSubmitted } = this.state;
    this.setState({ [name]: selected }, async () => {
      isSubmitted && (await this.updateErrors());
    });
  };

  handleKey=(event)=>{
    if (event.keyCode === 13) {
      this.handleSave();
    }
  }
  updateErrors = async () => {
    const {
      activeTab,
      educationQualification,
      educationType,
      educationLevel,
      cerName,
      cerType,
      cerRenewalFrequency,
      knowledgeStatement,
      skillStatement,
      abilityStatement,
      ksaId,
    } = this.state;

    const currentTabId = activeTab ? activeTab.tabId : null;
    let fieldsToValidate = "";

    if (currentTabId === 1) {
      fieldsToValidate = {
        educationQualification,
        educationType,
        educationLevel,
      };
    } else if (currentTabId === 2) {
      fieldsToValidate = { cerName, cerType, cerRenewalFrequency };
    } else if (currentTabId === 3) {
      fieldsToValidate = { knowledgeStatement };
    } else if (currentTabId === 4) {
      fieldsToValidate = { skillStatement };
    } else if (currentTabId === 5) {
      fieldsToValidate = { abilityStatement };
    }
    let errors = await InputValidator(document, fieldsToValidate);
    if (isEmpty(errors)) {
      let { ksaListByType } = this.props;
      if (currentTabId === 3) {
        if (
          ksaListByType &&
          ksaListByType.find(
            (x) => x.KSAId !== ksaId && x.Name === knowledgeStatement
          )
        ) {
          errors.knowledgeStatement =
            "Knowlege name already taken. Please choose another name!";
        }
      } else if (currentTabId === 4) {
        if (
          ksaListByType &&
          ksaListByType.find(
            (x) => x.KSAId !== ksaId && x.Name === skillStatement
          )
        ) {
          errors.skillStatement =
            "Skill name already taken. Please choose another name!";
        }
      } else if (currentTabId === 5) {
        if (
          ksaListByType &&
          ksaListByType.find(
            (x) => x.KSAId !== ksaId && x.Name === abilityStatement
          )
        ) {
          errors.abilityStatement =
            "Abilitiy name already taken. Please choose another name!";
        }
      }
    }

    this.setState({ errors });
    return errors;
  };

  handleModal = (isShow, isEdit = false) => {
    if (!isShow) {
      //Reset state
      this.setState({
        educationId: 0,
        educationQualification: "",
        educationType: null,
        educationLevel: null,

        certificationId: 0,
        cerName: "",
        cerType: "",
        cerRenewalFrequency: "",

        ksaId: 0,
        knowledgeStatement: "",
        skillStatement: "",
        abilityStatement: "",
        isSubmitted: false,
      });
    }
    this.setState({ showAddNewModal: isShow, isEdit: isEdit, errors: {} });
  };

  educationModal = () => {
    const {
      educationQualification,
      educationType,
      educationLevel,
      errors,
    } = this.state;
    const { educationLevels, educationTypes } = this.props;
    return (
      <>
        <div className="add-modal-section">
          <div className="add-modal-section-content">
            <FormGroup
              autoFocus
              name="educationQualification"
              value={educationQualification}
              onChange={this.handleInput}
              label="Education Qualification Name"
              validators={["required"]}
              error={errors && errors.educationQualification}
              width="600px"
            />
          </div>
        </div>
        <div className="add-modal-section">
          <div className="add-modal-section-content">
            <FormGroup
              formName="reactselect"
              onChange={this.handleAutoSelect}
              name="educationType"
              value={educationType}
              options={educationTypes}
              label="Education Type"
              validators={["required"]}
              error={errors && errors.educationType}
              width="300px"
            />
          </div>
          <div className="add-modal-section-content">
            <FormGroup
              formName="reactselect"
              onChange={this.handleAutoSelect}
              name="educationLevel"
              value={educationLevel}
              options={educationLevels}
              label="Education Level"
              validators={["required"]}
              error={errors && errors.educationLevel}
              width="300px"
            />
          </div>
        </div>
      </>
    );
  };

  certificationsModal = () => {
    const { cerName, cerType, cerRenewalFrequency, errors } = this.state;
    const { certificationTypes } = this.props;
    return (
      <>
        <div className="add-modal-section">
          <div className="add-modal-section-content">
            <FormGroup
              autoFocus
              name="cerName"
              value={cerName}
              onChange={this.handleInput}
              label="Certification Name"
              validators={["required"]}
              error={errors && errors.cerName}
            />
          </div>
        </div>
        <div className="add-modal-section">
          <div className="add-modal-section-content">
            <FormGroup
              formName="reactselect"
              onChange={this.handleAutoSelect}
              name="cerType"
              value={cerType}
              options={certificationTypes}
              label="Type"
              width="300px"
              validators={["required"]}
              error={errors && errors.cerType}
            />
          </div>
          {(!cerType || (cerType && cerType.label != "Lifetime")) && (
            <div className="add-modal-section-content">
              <FormGroup
                type="number"
                name="cerRenewalFrequency"
                value={cerRenewalFrequency}
                onChange={this.handleInput}
                label="Renewal Frequency in Year"
                validators={["required"]}
                error={errors && errors.cerRenewalFrequency}
              />
            </div>
          )}
        </div>
      </>
    );
  };

  knowledgeModal = () => {
    const { knowledgeStatement, errors } = this.state;
    return (
      <div className="add-modal-full_content">
        <FormGroup
          autoFocus
          name="knowledgeStatement"
          value={knowledgeStatement}
          onChange={this.handleInput}
          label="Knowledge Statement"
          validators={["required"]}
          error={errors && errors.knowledgeStatement}
          onKeyUp={this.handleKey}
        />
      </div>
    );
  };

  skillModal = () => {
    const { skillStatement, errors } = this.state;
    return (
      <div className="add-modal-full_content">
        <FormGroup
          autoFocus
          name="skillStatement"
          value={skillStatement}
          onChange={this.handleInput}
          label="Skill Statement"
          validators={["required"]}
          error={errors && errors.skillStatement}
          onKeyUp={this.handleKey}
        />
      </div>
    );
  };

  abilityModal = () => {
    const { abilityStatement, errors } = this.state;
    return (
      <div className="add-modal-full_content">
        <FormGroup
          autoFocus
          name="abilityStatement"
          value={abilityStatement}
          onChange={this.handleInput}
          label="Ability Statement"
          validators={["required"]}
          error={errors && errors.abilityStatement}
          onKeyUp={this.handleKey}
        />
      </div>
    );
  };

  modalContent = () => {
    const { activeTab } = this.state;
    return (
      <div className="add-modal">
        <div className="add-modal__content">
          {activeTab.tabId === 1
            ? this.educationModal()
            : activeTab.tabId === 2
            ? this.certificationsModal()
            : activeTab.tabId === 3
            ? this.knowledgeModal()
            : activeTab.tabId === 4
            ? this.skillModal()
            : activeTab.tabId === 5
            ? this.abilityModal()
            : null}
        </div>
        <div className="add-modal-footer">
          <button
            className="button button-white"
            onClick={() => this.handleModal(false)}
          >
            Cancel
          </button>
          <button className="button button-primary" onClick={this.handleSave}>
            Save
          </button>
        </div>
      </div>
    );
  };

  render() {
    const { activeTab, showAddNewModal } = this.state;
    return (
      <div className="common-container">
        <Title name="KSA Libraries" />
        {tabs && (
          <Tabs activeTab={activeTab.tabId} type="tabs-block" noBackground>
            {tabs.map((tab, index) => {
              return (
                <Tab
                  label={tab.label}
                  name={tab.tabId}
                  id={tab.tabId}
                  key={index}
                  isActive={activeTab.tabId === tab.tabId}
                  onClick={() => this.handleTabChange(tab)}
                >
                  <>
                    <button
                      className="btn-outline add-content"
                      onClick={() => this.handleModal(true)}
                    >
                      <span className="add-content-icon">
                        <MdAdd />
                      </span>
                      {"Add "}
                      {activeTab.tabId === 1
                        ? "Education"
                        : activeTab.tabId === 2
                        ? "Certifications"
                        : activeTab.tabId === 3
                        ? "Knowledge"
                        : activeTab.tabId === 4
                        ? "Skill"
                        : activeTab.tabId === 5
                        ? "Ability"
                        : null}
                    </button>

                    {activeTab.tabId === 1 ? (
                      <Education
                        {...this.props}
                        handleDelete={this.handleDeleteEducation}
                        handleEdit={this.handleEditEducation}
                      />
                    ) : activeTab.tabId === 2 ? (
                      <Certifications
                        {...this.props}
                        handleDelete={this.handleDeleteCertification}
                        handleEdit={this.handleEditCertification}
                      />
                    ) : activeTab.tabId === 3 ||
                      activeTab.tabId === 4 ||
                      activeTab.tabId === 5 ? (
                      <KsaView
                        {...this.props}
                        handleDelete={this.handleDeleteKSA}
                        handleEdit={this.handleEditKSA}
                      />
                    ) : null}
                  </>
                </Tab>
              );
            })}
          </Tabs>
        )}

        {showAddNewModal ? (
          <Modal
            open={showAddNewModal}
            title={
              activeTab.tabId === 1
                ? "Education Qualification"
                : activeTab.tabId === 2
                ? "Certifications"
                : activeTab.tabId === 3
                ? "Knowledge Statement"
                : activeTab.tabId === 4
                ? "Skill Statement"
                : activeTab.tabId === 5
                ? "Ability Statement"
                : null
            }
            className="ksa-modal"
            content={this.modalContent()}
            onModalClose={() => this.handleModal(false)}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  educationList: state.jobProfileKSAReducer.educationList,
  educationById: state.jobProfileKSAReducer.educationById,
  educationLevels: state.jobProfileKSAReducer.educationLevels,
  educationTypes: state.jobProfileKSAReducer.educationTypes,
  certificationList: state.jobProfileKSAReducer.certificationList,
  certificationById: state.jobProfileKSAReducer.certificationById,
  certificationTypes: state.jobProfileKSAReducer.certificationTypes,
  ksaSkillType: state.jobProfileKSAReducer.ksaSkillType,
  ksaListByType: state.jobProfileKSAReducer.ksaListByType,
  ksaDataById: state.jobProfileKSAReducer.ksaDataById,
  ksaType: state.jobProfileKSAReducer.ksaType,
});

const mapDispatchToProps = {
  GetEducationList,
  GetEducationById,
  GetEducationLevelForDDL,
  GetEducationTypeForDDL,
  CreateEducation,
  UpdateEducation,
  GetCertificationList,
  GetCertificationById,
  GetCertificationTypeForDDL,
  CreateCertifications,
  UpdateCertifications,
  GetKSA_Types,
  GetKSA_ListByType,
  GetKSA_ById,
  CreateKSA,
  UpdateKSA,
  DeleteEducation,
  DeleteCertification,
  DeleteKSA,
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
