import Button from "components/button";
import Pagination from "components/common/pagination";
import Modal from "components/modal";
import SvgIcons from 'components/icons/svgIcons';
import isEmpty from "isEmpty";
import React, { useState } from "react";
import ApprovalFlow from "scenes/common/approvalFlow";
import GetDetailsPage from "../common/getDetailsPage";
import RequestBlock from "../common/requestBlock";
import RequestBlockWrapper from "../common/requestBlockWrapper";

export interface IAppProps {
  rows: any;
  handleRowChange: Function;
  handleIndexChange: Function;
  pageIndex: number;
  rowsPerPage: number;
  refreshRequestData: Function;
}

function App(props: IAppProps) {
  let [modalOpen, setModalOpen] = useState("");
  let {
    handleRowChange,
    handleIndexChange,
    pageIndex,
    rowsPerPage,
    refreshRequestData,
  } = props;
  const onModalShow = (id) => {
    setModalOpen(id);
  };
  let [isDetailsPageShow, setDetailsPageShow] = useState(false);
  let [selectedRow, setSelectedRow] = useState({});
  return (
    <>
      {props.rows.map((row, rowIndex) => {
        const approvalStatus = [
          {
            RequestRefId: row.RequestId,
            UserName: row.Requester,
            ActionBy: row.Reccommender,
            ActionOn: "",
            StepName: "",
            ApprovalStatus: row.RecommendedStatus,
            ApproverImage: row.RecommendedImage,
          },
          {
            RequestRefId: row.RequestId,
            UserName: row.Requester,
            ActionBy: row.Approval,
            ActionOn: "",
            StepName: "",
            ApprovalStatus: row.ApprovalStatus,
            ApproverImage: row.ApprovalImage,
          },
        ];
        return (
          <div className="request-details" key={rowIndex}>
            <RequestBlock
              row={row}
              isViewMode={true}
              refreshRequestData={refreshRequestData}
              requestData={props.rows}
              page="teamHistory"
            />
            <div className="request-details__footer">
              <div className="details-btn flex">
                <Button
                  title="Details"
                  bg="subtle"
                  buttonType="icon-button"
                  leftIcon={<SvgIcons name="list" color="#3a3d43" size="17px" />}
                  onClick={() => {
                    setSelectedRow(row);
                    setDetailsPageShow(true);
                  }}
                />
              </div>

              <ApprovalFlow
                approvalStatus={approvalStatus}
                rowIndex={rowIndex}
              />

              {/* <button
                type="button"
                className="button button__primary"
                onClick={() => {
                  setSelectedRow(row);
                  setDetailsPageShow(true);
                }}
              >
                Details
              </button> */}
              <Modal
                open={modalOpen === row.RequestId}
                onModalClose={() => onModalShow("")}
                title="Comments History"
              ></Modal>
            </div>
          </div>
        );
      })}
      {isEmpty(props.rows) ? null : (
        <Pagination
          handleRowChange={handleRowChange}
          handleIndexChange={handleIndexChange}
          pageIndex={pageIndex}
          searchedPage={{
            index: pageIndex,
            total: props.rows ? props.rows[0].TotalRow : 0,
          }}
          rowsPerPage={rowsPerPage}
        />
      )}
      {isDetailsPageShow && (
        <GetDetailsPage
          selectedRow={selectedRow}
          closeDetailsModal={() => setDetailsPageShow(false)}
          isViewMode={true}
          refreshRequestData={refreshRequestData}
          requestData={props.rows}
          page="teamHistory"
        />
      )}
    </>
  );
}
export default RequestBlockWrapper()(App);
