import React from "react";
import { FaEdit } from 'react-icons/fa'
import { connect } from 'react-redux';
import Button from 'components/button';

export class HealthRecord extends React.Component {
    render() {
        const { healthInfo: {
            BloodGroup,
            ContitionTypeName,
            HospitalClinicName,
            HospitalAddress,
            DoctorName,
            PhoneNumber,
            }
        } = this.props;
        return (
            <div className="personal-row">
            <div className="personal-column">
                <div className="personal-block">
                    <div className="personal-block__title">
                        <h2>Health</h2>
                        <Button title="Change" bg="subtle" buttonClass="personal-block__btn" />
                    </div>
                    <div className="personal-block__body">
                        {/* <div className="personal-block__icon">
                            <FaEdit />
                        </div> */}
                        <div className="personal-block__div">
                            <div className="personal-label-health">
                                Blood Group
                            </div>
                            <div className="value1">
                                {BloodGroup ? BloodGroup : '-'}
                            </div>
                        </div>
                        <div className="personal-block__div">
                            <div className="personal-label-health">
                                Diagnosed Medical Condition
                            </div>
                            <div className="value1">
                                {ContitionTypeName ? ContitionTypeName : '-'}
                            </div>
                        </div>
                        <div className="personal-block__div">
                            <div className="personal-label-health">
                                Consulting Doctor
                            </div>
                            <div className="value1">
                                {DoctorName ? DoctorName : '-'}
                            </div>
                        </div>
                        <div className="personal-block__div">
                            <div className="personal-label-health">
                                Emergengy Contact
                            </div>
                            <div className="value1">
                                {HospitalClinicName ? HospitalClinicName : ''}
                                {PhoneNumber ? (", " + PhoneNumber) : "" }
                                {!HospitalClinicName && !PhoneNumber && "-"}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        );
    }
};

const mapStateToProps = state => ({
    healthInfo: state.employeeDashboard.employeeDetail && state.employeeDashboard.employeeDetail.Health || {}
});

export default connect(mapStateToProps, null)(HealthRecord);