import React from "react";
import Avatar from "react-avatar";

export default function CandidateName(props: any) {
  const {data} = props || {};
  const base = props.auth.baseUrl ? props.auth.baseUrl.NetFrameworkAppBaseUrl : "";
  const imageUrl = base + data.ImageName;

  return (
    <div className="person">
      <div className="person-image">
        <Avatar
          className="user-avatar"
          name={data.CandidateName}
          size={"40"}
          round={true}
          src={imageUrl}
        />
      </div>
      <div className="person-details" onClick={() => props.handleCandidateClick(data)}>
        <h4>{data.CandidateName}</h4>
        <p>{data.CurrentPosition}</p>
        <p>{data.CurrentCompany}</p>
      </div>
    </div>
  );
}
