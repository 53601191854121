import React from 'react'
import {
    Date_Format
} from '../../../../../constants/dateFormat';
import { connect  } from 'react-redux';

function History(props) {
    const { employeeRolesHistory } = props;
    let dmData = [];
    let mxData = [];
    let cmData = [];
    let ksaAdded = [];
    let dataToFilter = [
        employeeRolesHistory && employeeRolesHistory.AssignedJobDiscription 
        && employeeRolesHistory.AssignedJobDiscription.forEach(dm => {
                dmData.push({
                    date: Date_Format(dm.AssignedDate),
                    position: dm.AssignedBy,
                    type: dm.JdName
                })
        }),
        employeeRolesHistory && employeeRolesHistory.AssignedJobDiscription 
        && employeeRolesHistory.AssignedJobDiscription.forEach(dm => {
                mxData.push({
                    date: Date_Format(dm.AssignedDate),
                    position: dm.AssignedBy,
                    type: dm.JdName
                })
        }),
        employeeRolesHistory && employeeRolesHistory.JobDiscriptionKsaRemoved 
        && employeeRolesHistory.JobDiscriptionKsaRemoved.forEach(dm => {
                cmData.push({
                    date: Date_Format(dm.RemovedDate),
                    position: dm.RemovedBy,
                    type: dm.KsaType
                })
        }),
        employeeRolesHistory && employeeRolesHistory.JobDiscriptionKsaAdded 
        && employeeRolesHistory.JobDiscriptionKsaAdded.forEach(dm => {
                ksaAdded.push({
                    date: Date_Format(dm.RemovedDate),
                    position: dm.RemovedBy,
                    type: dm.KsaType
                })
        }),

    ];
    Promise.all(dataToFilter);
    return (
        <div className="reporting-history">
            <HistoryBlock title="Assigned" data={dmData} />
            <HistoryBlock title="Description Changed" data={mxData} />
            <HistoryBlock title="KSA Added" data={ksaAdded} />
            <HistoryBlock title="KSA Removed" data={cmData} />
        </div>
    )
}

const HistoryBlock = ({ title, data }) =>{
    return(
        <div className="reporting-history__block">
            <div className="reporting-history__title">
                <h4>{title}</h4>
            </div>
            {
                data && data.length > 0 ? data.map(item=>{
                    return <HistoryRow data={item} key={item.position} />
                }) 
                :
                <span className="no-data">No data</span>
            }
        </div>
    )
}

const HistoryRow = ({ data }) =>{
    return(
        <div className="reporting-history__row">
            <div className="reporting-history__col">
                {data.date}
            </div>
            <div className="reporting-history__col">
                {data.type}
            </div>
            <div className="reporting-history__col">
                {data.position}
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    employeeRolesHistory: state.employeeProfile.employeeRolesHistory || [],
});

export default connect(mapStateToProps, null)(History);