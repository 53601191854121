import React, { Component } from 'react'

export class RF_Adjustment extends Component {
    render() {
        return (
            <div className="payroll-body">
                RF_Adjustment
            </div>
        )
    }
}

export default RF_Adjustment
