import React from "react";
import isEmpty from "isEmpty";
import Avatar from "react-avatar";
import { useSelector } from 'react-redux';
import { getUploadPath } from 'constants/userDetails'
import { formatNumberShort } from '../../helpers/constants';
function EmployeeInfo(props) {
  const { consumerLoanDetails, loanRequesterInformation } = useSelector((state: any) => state.sbiLoanReducer);
  // const consumerLoanDetails = useSelector(
    //   (state: any) => state.sbiLoanReducer.consumerLoanDetails
    // );
  console.log("consumerLoan Dtails", consumerLoanDetails);
  if (isEmpty(consumerLoanDetails) || isEmpty(loanRequesterInformation)) return null;
  const { EmployeeName, Designation, Branch, Department, EmployeeStatus, ServicePeriod, UrlPhoto } = loanRequesterInformation || {};
  const {
    LoanDetail: {
      LoanAmount,
      PurposeOfLoan,
      RepaymentTenure,
    },
  } = consumerLoanDetails || {
    LoanDetail: {
      LoanAmount: "",
      PurposeOfLoan: "",
      RepaymentTenure: "",
    }
  };
  console.log("getPhotos of user details page", getUploadPath(UrlPhoto))
  return (
    <div className="loan-employee-info mb-md">
      <div className="loan-employee-image">
        <Avatar
          alt={"x.FullName"}
          src={getUploadPath(UrlPhoto)}
          className="emp-image"
          size={"136"}
        />
      </div>
      <div className="loan-employee-details flex-column justify-between">
        <div className="loan-employee-details__name">
          {EmployeeName}
        </div>
        <div>
          <div className="loan-employee-details__position text-md">
            {Designation}
          </div>
          <div className="loan-employee-details__department text-md">
            {Branch}, {Department}
          </div>
        </div>
        <span className="jobstatus-hsk">Loan Amount: {formatNumberShort(LoanAmount)}</span>
      </div>
    </div>
  )
}
export default EmployeeInfo;
