import React, { Component } from "react";
import { connect } from "react-redux";
import "./employee_jobInformation.scss";
import Title from "../../common/title";
import OfficeAndPosition from "./officeAndPosition";
import JobProfile from "./jobProfile";
import MyManager from "./myManager";
import MyTeam from "./myTeam";
import JobHistory from "./jobHistory";
import { GetJobInformation } from "../action";

export class JobInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  async componentDidMount() {
    await this.props.GetJobInformation();
  }

  render() {
    const { jobInformationData } = this.props;
    if (!jobInformationData) {
      return null;
    }
    return (
      <div className="common-container">
        <Title name="Job Information" />

        <OfficeAndPosition data={jobInformationData.CompanyDetails} />

        <div className="section">
          {jobInformationData.JobProfile && (
            <JobProfile data={jobInformationData.JobProfile} />
          )}
          {jobInformationData.MyManager && (
            <MyManager data={jobInformationData.MyManager} />
          )}
        </div>
        <MyTeam data={jobInformationData.MyTeam} />

        <JobHistory data={jobInformationData.JobHistory} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  jobInformationData: state.employeeReducer.jobInformationData,
});

const mapDispatchToProps = {
  GetJobInformation,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobInformation);
