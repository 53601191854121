import React from "react";
import Table from "./table";
import Modal from "components/modal";
import Loader from "components/loaders";
import AddExpenseAllowance from "./allowanceExpenseForm";
import "./allowanceExpense.scss";
import {
  addAllowanceExpense,
  getAllowanceExpense,
  editAllowanceExpense,
  deleteAllowanceExpense,
} from "./actions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { SubmissionError } from "redux-form";

class Allowance_Expense extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedId: null,
      rowsPerPage: 10,
      pageIndex: 0,
      modalState: { show: false, type: "normal" },
    };
    this.closeModal = this.closeModal.bind(this);
    this.handleConfirmDelRecord = this.handleConfirmDelRecord.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }
  componentDidMount = () => {
    this.props.getAllowanceExpense();
  };

  closeModal = () => {
    this.props.handleModalShow({ show: false, type: "normal" });
  };
  submitForm = (formData) => {
    if (
      this.props.allowanceExpense &&
      this.props.allowanceExpense.find(
        (x) =>
          x.AllowanceId !== formData.AllowanceId && x.Name === formData.Name
      )
    ) {
      throw new SubmissionError({
        Name:
          "Allowance expense name already taken. Please choose another name!",
      });
    } else {
      const { isEdit } = this.props.allowanceExpenseReducer;
      isEdit
        ? this.props.editAllowanceExpense(formData)
        : this.props.addAllowanceExpense(formData);
    }
  };
  handleConfirmDelRecord = (id) => {
    this.setState({
      selectedId: id,
    });
    this.handleModalShow(true, "confirm");
  };

  handleDelRecord = (args) => {
    this.props.deleteAllowanceExpense(this.state.selectedId);
  };
  handleModalShow = (show, type) => {
    this.setState({ modalState: { show, type } });
  };
  render() {
    const { spinnerLoading } = this.props.allowanceExpenseReducer;
    const { modalState } = this.state;
    return (
      <div className="expense-allowance">
        <Table
          pageIndex={this.state.pageIndex}
          rowsPerPage={this.state.rowsPerPage}
          handleRowChange={null}
          handleIndexChange={null}
          handleConfirmDelRecord={this.handleConfirmDelRecord}
          handleModalShow={this.handleModalShow}
        />
        {modalState.show && (
          <Modal
            title={"Allowance or Expense"}
            open={modalState.show}
            onModalClose={() => this.handleModalShow(false, "normal")}
            type={modalState.type}
            func={() => this.handleDelRecord()}
          >
            <AddExpenseAllowance
              onSubmit={this.submitForm}
              handleModalShow={this.handleModalShow}
            />
          </Modal>
        )}
        <Loader open={spinnerLoading} />
      </div>
    );
  }
}

Allowance_Expense.propTypes = {
  addAllowanceExpense: PropTypes.func.isRequired,
  getAllowanceExpense: PropTypes.func.isRequired,
  editAllowanceExpense: PropTypes.func.isRequired,
  allowanceExpenseReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  allowanceExpenseReducer: state.allowanceExpenseReducer,
  allowanceExpense: state.allowanceExpenseReducer.allowanceExpense,
});

export default connect(mapStateToProps, {
  addAllowanceExpense,
  getAllowanceExpense,
  editAllowanceExpense,
  deleteAllowanceExpense,
})(Allowance_Expense);
