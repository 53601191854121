import React from "react";
import classnames from "classnames";
import isEmpty from "isEmpty";

export const Sidetab = (props) => {
  const { currentStep, onStepChange } = props;
  const tabContent = [
    {
      num: 1,
      title: "Total",
      //   details: "Define the Job Position you are hiring",
    },
    {
      num: 2,
      title: "Regular",
      //   details: "Select the components in Application Form",
    },
    {
      num: 3,
      title: "Adjustment",
      //   details: "Define or Select the stages the application will go through",
    },
    {
      num: 4,
      title: "Arrears",
      //   details: "Define how th candidate will be scored for the job",
    },
    // {
    //     num: 5,
    //     title: "Marking Scheme",
    //     details: "How the candidate will be Scored based on hiring stages",
    // },
    // {
    //   num: 5,
    //   title: "Hiring Team",
    //   details: "Define who will be involved in this position's hiring process",
    // },
  ];
  return (
    <div className="recruit-header">
      <div className="flex">
        {tabContent.map((item) => (
          <Tab
            tab={item}
            onStepChange={onStepChange}
            currentStep={currentStep}
            //   isAt={isAt}
          />
        ))}
      </div>
      <div className="button-group button-group__right flex">
        <button className="btn btn-grey-2">Export</button>
      </div>
    </div>
  );
};

export const Tab = ({ tab, currentStep, onStepChange, isAt }) => {
  const classes = classnames({
    "recruit-header-item": true,
    // disabled: tab.num > isAt,
    selected: tab.num === currentStep,
  });
  return (
    <a
      key={tab.num}
      className={classes}
      disabled={true}
      onClick={() => onStepChange(tab.num)}
    >
      <div className="recruit-header-title">{tab.title}</div>
      {/* <div className="recruit-header-body">
        <p>{tab.details}</p>
      </div> */}
    </a>
  );
};

export default Sidetab;
