import React,{useEffect,useState} from 'react'
import { useDispatch,useSelector } from 'react-redux';
import isEmpty from 'isEmpty';
import CreatableSelect from 'react-select/creatable';
import {
    GetEmployeeList,
    GetProvienceList,
    GetJobStatus,
    GetBranches,
    GetDepartments,
    GetLevels,
} from 'actions/commonAction';
import {Arrays} from 'helpers/getTextLabel'
import {getCluster} from '../../clusters/helpers/action'

function SelectValues({values,name,onChange,colorType,styles,actionValue}) {
    const [options,setOptions] = useState([]);
    const selectReducer = useSelector(state=>state.selectReducer);
    const commonReducer = useSelector(state=>state.commonReducer);
    const clusterReducer = useSelector(state=>state.clusterReducer);
    const dispatch = useDispatch();
    const getResponse = async () =>{
        const {label} = actionValue;
        if(label === 'Region') dispatch(GetProvienceList())
        if(label === 'Branch') dispatch(GetBranches())
        if(label === 'Cluster') dispatch(getCluster())
        if(label === 'Department') dispatch(GetDepartments())
        if(label === 'Level') dispatch(GetLevels())
        if(label === 'Person') dispatch(GetEmployeeList())
        if(label === 'Status') dispatch(GetJobStatus())
    }
    const getOptions = () =>{
        const {branch,jobStatus,levels,department,} = selectReducer;
        const {provinceList,employeeList} = commonReducer;
        const {clusters} = clusterReducer;
        const {label} = actionValue;
        if(label === 'Branch'){
            if(!isEmpty(branch.value)) setOptions(branch.value) 
            else setOptions([])
        }
        else if(label === 'Region') {
            if(!isEmpty(provinceList)) setOptions(provinceList)
            else setOptions([])
        }
        else if(label === 'Cluster'){
            console.log(clusters)
            if(!isEmpty(clusters)) setOptions(Arrays(clusters))
            else setOptions([])
        }
        else if(label === 'Department'){
            if(!isEmpty(department.value)) setOptions(department.value)
            else setOptions([])
        }
        else if(label === 'Level'){
            if(!isEmpty(levels.value)) setOptions(levels.value)
            else setOptions([])
        }
        else if(label === 'Person'){
            if(!isEmpty(employeeList)) setOptions(employeeList)
            else setOptions([])
        }
        else if(label === 'Status'){
            if(!isEmpty(jobStatus.value)) setOptions(jobStatus.value)
            else setOptions([])
        }
        else{
            setOptions([])
        }
    }
    useEffect(()=>{
        if(!isEmpty(actionValue)){
            getResponse();
        }
    },[actionValue])
    useEffect(()=>{
        if(!isEmpty(actionValue)){
            getOptions()
            console.log(selectReducer)
        }
    },[actionValue,selectReducer,commonReducer,clusterReducer])
    return (
        <CreatableSelect
            defaultValue={values}
            isMulti
            name={name}
            onChange={onChange}
            colorType={colorType}
            styles={styles}
            classNamePrefix="select"
            className="tags-multi-select"
            placeholder=""
            options={options}
        />
    )
}

export default SelectValues
