import * as employeeRequestService from "services/employeeRequests";
import isEmpty from "isEmpty";
import { toastMessage } from "actions/validateAction";
import {
  GET_EMPLOYEE_REVIEW_REQUEST,
  GET_MY_REQUESTS,
  GET_TEAM_REQUEST_HISTORY,
  GET_MY_REQUEST_HISTORY,
  GET_EMPLOYEE_REQUEST_COUNT,
  GET_EMPLOYEE_REQUEST_AUDIT_LOG,
  SET_EMPLOYEE_REQUEST_AUDIT_LOG_LOADING,
  SET_EMPLOYEE_REVIEW_REQUEST_LOADING,
  SET_MY_REQUESTS_LOADING,
  SET_TEAM_REQUEST_HISTORY_LOADING,
  SET_MY_REQUEST_HISTORY_LOADING,
} from "actions/types";
import moment from "moment";
import axios from "axios";

//#region leave request
export const GetEmployeeRequestCount = () => async (dispatch) => {
  try {
    let result = await employeeRequestService.getEmployeeRequestCount();
    if (result.Status) {
      dispatch({
        type: GET_EMPLOYEE_REQUEST_COUNT,
        payload: result.Data,
      });
    } else {
      toastMessage(dispatch, result);
    }
  } catch (err) {
    toastMessage(dispatch, err.message);
  }
};

export const GetEmployeeReviewRequest = (searchData) => async (dispatch) => {
  try {
    dispatch({ type: SET_EMPLOYEE_REVIEW_REQUEST_LOADING, payload: true });
    let result = await employeeRequestService.getEmployeeReviewRequest(
      searchData
    );
    if (result.Status) {
      dispatch({
        type: GET_EMPLOYEE_REVIEW_REQUEST,
        payload: result.Data,
      });
    } else {
      toastMessage(dispatch, result);
    }
  } catch (err) {
    toastMessage(dispatch, err.message);
  }
};
export const GetMyRequests = (searchData) => async (dispatch) => {
  try {
    dispatch({ type: SET_MY_REQUEST_HISTORY_LOADING, payload: true });
    let result = await employeeRequestService.getMyRequests(searchData);
    if (result.Status) {
      dispatch({
        type: GET_MY_REQUESTS,
        payload: result.Data,
      });
    } else {
      toastMessage(dispatch, result);
    }
  } catch (err) {
    toastMessage(dispatch, err.message);
  }
};
export const GetTeamRequestHistory = (searchData) => async (dispatch) => {
  try {
    dispatch({ type: SET_TEAM_REQUEST_HISTORY_LOADING, payload: true });
    let result = await employeeRequestService.getTeamRequestHistory(searchData);
    if (result.Status) {
      dispatch({
        type: GET_TEAM_REQUEST_HISTORY,
        payload: result.Data,
      });
    } else {
      toastMessage(dispatch, result);
    }
  } catch (err) {
    toastMessage(dispatch, err.message);
  }
};
export const GetMyRequestHistory = (searchData) => async (dispatch) => {
  try {
    dispatch({ type: SET_MY_REQUEST_HISTORY_LOADING, payload: true });
    let result = await employeeRequestService.getMyRequestHistory(searchData);
    if (result.Status) {
      dispatch({
        type: GET_MY_REQUEST_HISTORY,
        payload: result.Data,
      });
    } else {
      toastMessage(dispatch, result);
    }
  } catch (err) {
    toastMessage(dispatch, err.message);
  }
};
export const GetAuditLogOfRequests = (searchData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_EMPLOYEE_REQUEST_AUDIT_LOG_LOADING,
      payload: true,
    });
    let result = await employeeRequestService.getAuditLogOfRequests(searchData);
    if (result.Status) {
      dispatch({
        type: GET_EMPLOYEE_REQUEST_AUDIT_LOG,
        payload: result.Data,
      });
    } else {
      toastMessage(dispatch, result);
    }
  } catch (err) {
    toastMessage(dispatch, err.message);
  }
};
//#endregion
