import React, { Component } from "react";
import { TableSubRows } from "./tableSubRows";
import Button from "components/button";
import classnames from "classnames";
import { expandIcon } from "scenes/taskTracker/common/expandIcon";

export class TableRows extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // dropClient: false,
    };
    this.toggleDetail = this.toggleDetail.bind(this);
    this.dropdownClient = "";
  }

  onAddNewProject = (e, data) => {
    e.stopPropagation();
    this.props.handleEditProject(data);
    // this.props.handleFormDisplay("showNewProjectForm", true, data);
  };

  onEditClient = (e, data) => {
    e.stopPropagation();
    this.props.handleEditClient(data);
  };
  onDeleteClient = (e, data) => {
    e.stopPropagation();
    this.props.handleDeleteClient(data);
  };
  onArchiveClient = (e, clientId) => {
    e.stopPropagation();
    this.props.handleArchiveClient(clientId);
  };
  onRestoreClient = (e, clientId) => {
    e.stopPropagation();
    this.props.handleRestoreClient(clientId);
  };

  toggleDetail = (id, drop) => {
    this.setState({ drop: !drop });
    var toggleIcon = document.getElementById(`toggleIcon${id}`);
    toggleIcon.classList.toggle("expand");
    var detailsRow = document.querySelectorAll(`.details-${id}`);

    if (detailsRow) {
      for (var i = 0; i < detailsRow.length; i++) {
        detailsRow[i].classList.toggle("row-expand");
      }
    }
  };

  render() {
    const { props } = this;
    const { drop } = this.state;
    let trClass = "table-row";
    if (drop) {
      trClass = trClass + " expanded__tr";
    }
    return (
      <>
        <tr
          className={trClass}
          role="row"
          key={"ClientId" + props.ClientId}
          id={`toggleIcon${props.ClientId}`}
          onClick={() => this.toggleDetail(props.ClientId, drop)}
        >
          <td className="e-rowcell" role="gridcell">
            {props.ClientProjectList &&
              props.ClientProjectList.length > 0 &&
              expandIcon(drop)}
          </td>
          <td className="e-rowcell" role="gridcell">
            <span>{props.ClientName}</span>
          </td>
          <td className="e-rowcell " role="gridcell">
            <span>{props.TypeName}</span>
          </td>
          <td className="e-rowcell" role="gridcell">
            <span>{props.ActiveProjectCount}</span>
          </td>
          <td className="e-rowcell " role="gridcell">
            <span></span>
          </td>
          <td className="e-custom-row" style={{ overflow: "visible" }}>
            <div className="flex">
              {!props.isArchivedTab && (
                <button
                  className="button with-icon button-secondary"
                  onClick={(e) =>
                    this.onAddNewProject(e, { ClientId: props.ClientId })
                  }
                >
                  <span>New Project</span>
                </button>
              )}
              <div className="actions">
                {!props.isArchivedTab ? (
                  <Button
                    buttonType="drop-button"
                    bg="secondary"
                    title="Edit"
                    onClick={(e) => this.onEditClient(e, props)}
                    dropClass={"common-drop"}
                    dropComponent={
                      <ul className="common-drop-list">
                        <li
                          className={"common-drop-list__item"}
                          onClick={(e) =>
                            this.onArchiveClient(e, props.ClientId)
                          }
                        >
                          <span className="common-drop-name">Archive</span>
                        </li>

                        <li
                          className={"common-drop-list__item"}
                          onClick={(e) => this.onDeleteClient(e, props)}
                        >
                          <span className="common-drop-name">Delete</span>
                        </li>
                      </ul>
                    }
                  />
                ) : (
                  <Button
                    buttonType="drop-button"
                    bg="secondary"
                    title="Restore"
                    onClick={(e) => this.onRestoreClient(e, props.ClientId)}
                    dropClass={"common-drop"}
                    dropComponent={
                      <ul className="common-drop-list">
                        <li
                          className={"common-drop-list__item"}
                          onClick={(e) => this.onDeleteClient(e, props)}
                        >
                          <span className="common-drop-name">Delete</span>
                        </li>
                      </ul>
                    }
                  />
                )}
              </div>
            </div>
          </td>
        </tr>

        {props.ClientProjectList &&
          props.ClientProjectList.map((list, i) => {
            const rowClasses = classnames({
              "table-details": true,
              "custom-row": true,
              "expanded__tr-details": true,
              [`details-${props.ClientId}`]: true,
            });
            return (
              <tr className={rowClasses} key={i}>
                <TableSubRows
                  widths={props.widths}
                  list={list}
                  getLegalClientList={this.props.getLegalClientList}
                  handleEditProject={this.props.handleEditProject}
                  handleDeleteProject={this.props.handleDeleteProject}
                  handleArchiveProject={this.props.handleArchiveProject}
                  handleRestoreProject={this.props.handleRestoreProject}
                  SaveProjectContract={this.props.SaveProjectContract}
                  handleRenewContract={this.props.handleRenewContract}
                />
              </tr>
            );
          })}
      </>
    );
  }
}

export default TableRows;
