import React, { useEffect } from 'react'
import { useState } from 'react';
import classnames from 'classnames';
import Form from 'components/form';
import shortid from 'shortid';

function Question(props) {
    const {
        question,
        answers,
        prevAnswers,
        setAnswers,
        coursePageId,
        courseId,
        employeeId
    } = props;
    const [answer,setAnswer] = useState(null);
    // const [answerOptions,setAnswerOptions] = useState([]);
    const ids = question.Id;
    useEffect(()=>{
        if(coursePageId){
            (async ()=>{
                await setAnswers(prev=>{
                    const ans = prev.find(item=>item.ids === ids);
                    if(ans){
                        return prev   
                    }
                    const answer = {
                        ids,
                        Id:0,
                        EmployeeId:employeeId,
                        CourseRefId: parseInt(courseId),
                        CoursePageRefId: parseInt(coursePageId),
                        QuestionRefId: question.Id,
                        Answer: "",
                        QuestionType:question.QuestionType,
                        AnswerRefId: null,
                        MultipleAnswerRefId:[],
                        errors:null
                    }
                    if(prev){
                        return [...prev,answer]
                    }
                    return [answer]
                })
            })()
            
        }
    },[coursePageId])
    useEffect(()=>{
        if(prevAnswers){
            console.log("Here")
            const anss = prevAnswers.filter(item=>item.QuestionRefId === question.Id);
            if(anss && anss.length>0){
                setAnswers(prev=>{
                    const ans = prev.find(item=>item.ids === ids);
                    // console.log({
                    //     ...ans,
                    //     ...anss[0]
                    // })
                    return prev &&  prev.map(item=>{
                        if(item.ids === ids){
                            return {
                                ...ans,
                                // Id:anss[0].Id,
                                MultipleAnswerRefId:anss[0].MultipleAnswerRefId,
                                AnswerRefId:anss[0].AnswerRefId,
                                Answer:anss[0].Answer
                            }
                        }
                        return item
                    });

                })
            }
        }
    },[prevAnswers])
    // useEffect(()=>{
    //     if(question && question.AnswerOptions){
    //         console.log("Here")
    //         const answerOptions = question.IsRandomised ?  shuffle(question.AnswerOptions) : question.AnswerOptions;
    //         setAnswerOptions(answerOptions);
    //     }
    // },[question])
    const answerOptions = React.useMemo(()=>{
        if(question && question.AnswerOptions){
            return question.IsRandomised ?  shuffle(question.AnswerOptions) : question.AnswerOptions;
        }
    },[])
    const ans = answers && answers.find(item=>item.ids === ids);
    // console.log({ans})
    const onChange = (value,isId = false,isMulti=false) =>{
        
        setAnswers(prev=>{
            return prev && prev.map(item=>{
                if(item.ids == ids){
                    return {
                        ...item,
                        Answer: !isId ? value : "",
                        AnswerRefId: isId && !isMulti ? value : 0,
                        MultipleAnswerRefId:isMulti ? value : []
                    }
                }
                return item
            })
        })
    };
    return (
        <div className="course-page__quiz-body">
            <div className="course-page__quiz-label">Q. {question?.Question}:</div>
            {
                question?.QuestionType === 1 && (
                <div className="course-page__quiz-form">
                    <Form
                        name="Answer"
                        value={ans && ans.Answer}
                        onChange={(name,value)=>onChange(value)}
                    />
                </div>)
            }
            {
                question?.QuestionType === 2 && (
                <div className="course-page__quiz-form">
                    <Form
                        name="Answer"
                        formName="textarea"
                        value={ans && ans.Answer}
                        onChange={(name,value)=>onChange(value)}
                    />
                </div>)
            }
            {
                question?.QuestionType === 3 && <CourseRadio answerOptions={question.AnswerOptions} answer={ans} onChange={onChange} question={question} />
            }
            {
                question?.QuestionType === 4 && <CourseCheck answerOptions={ question.AnswerOptions} answer={ans} onChange={onChange} question={question} />
            }
        </div>
    )
}


function shuffle(arrays) {
    const array = [...arrays];
    var currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

const CourseRadio = ({question,answer,onChange, answerOptions}) => {
    // const [chose, setChose] = useState(null);
    const onClick = (id) =>{
        // setChose(id);   
        onChange(id,true)
    }
    
    return(
        <ul className="course-page__quiz-list">
            {
                answerOptions && answerOptions.map(item=>{
                    console.log({question,item})
                    return(
                        <li 
                            key={item.Id}
                            onClick={()=>onClick(item.Id)}
                            className={classnames("course-page__quiz-item",{"course-page__quiz-item-active": answer && item.Id === answer.AnswerRefId})}
                            >
                                <div className="course-page__quiz-radio"></div>
                                <div className="course-page__quiz-name">{item.AnswerOption}</div>
                            </li>
                    )
                })
            }
        </ul>
    )
}


const CourseCheck = ({question,answer,onChange,answerOptions}) => {
    const [chose, setChose] = useState([]);
    const onClick = (id) =>{
        if(chose.includes(id)){
          setChose(prev=>{
            return prev.filter(item=>item!==id)
        }) 
        
        }else{
            setChose(prev=>{
                return [...prev,id]
            })  
        }
    }
    useEffect(()=>{
        if(chose){
            onChange(chose,true,true) 
        }
    },[chose])
    return(
        <>
        <ul className="course-page__quiz-list">
            {
                answerOptions && answerOptions.map(item=>{
                    const isChecked = answer && answer.MultipleAnswerRefId && answer.MultipleAnswerRefId.includes(item.Id)
                    const isQuestionLimit = answer && answer.MultipleAnswerRefId && question.AnswerLimitTo === answer.MultipleAnswerRefId.length;
                    const isDisabled = !isChecked && isQuestionLimit
                    // console.log({isChecked,a:answer})
                    return(
                        <li 
                            key={item.Id}
                            onClick={()=>!isDisabled && onClick(item.Id)}
                            className={classnames(
                                "course-page__quiz-item",{
                                "course-page__quiz-item-active": isChecked,
                                "course-page__quiz-item-disabled": isDisabled,
                            }
                                )}
                            >
                                <div className="course-page__quiz-check"></div>
                                <div className="course-page__quiz-name">{item.AnswerOption}</div>
                            </li>
                    )
                })
            }
        </ul>
        {answer && answer.errors && <span className="error__message">{answer.errors}</span>}

        </>
    )
}

export default Question
