import React from 'react';
import NewRole from './component/newRole'
import Table from './component/table'
import Search from './component/search';
import './roles.styles.scss'

function Roles() {
    return (
        <div className="admin roles">
            <div className="admin-container">
                <NewRole />
                <div className="admin-body">
                    <Search />
                    <Table />
                </div>
            </div>
        </div>
    )
}

export default Roles
