import React from 'react';
import { connect } from 'react-redux';
import moment from "moment";
import './style.scss';
import { Tabs, Tab } from 'components/tabs/index.d';
import DateAndPlaceToReport from './datePlaceToReport';
import WelcomeMessage from './welcomeMessage';
import OnBoardingEmpProfile from './onBoardEmpProfile';
import { GetBranches, GetDepartments, GetDesignations,
  GetHiringMethods } from "actions/commonAction";
import isEmpty from "isEmpty";
import {
  InitiateOnboardingEmployee,
  GetOnboardingEmployeeDetail
} from "../action";

// let tabs =
class EmployeeOnBoardingPackage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "Date and Place",
      activeTabIndex: 0,
      dataToSubmit: null,
      tabs: [
        {
          name: 'Date and Place', label: 'Date and Place', key: "0", component:
            <DateAndPlaceToReport
              handleSubmit={this.handleFormSubmit}
              changeActiveTab={this.changeActiveTab}
              employeeId={props.match.params.id/* .location.state.employeeId */}
              // isDisabled={true}
              {...props}
            />
        },
        {
          name: 'Welcome Message', label: 'Welcome Message', key: "1", component:
            <WelcomeMessage
              changeActiveTab={this.changeActiveTab}
              handleSubmit={this.handleFormSubmit}
              employeeId={props.match.params.id}
              {...props}
              // isDisabled={true}
            />
        },
        { name: 'Access and IDs', label: 'Access and IDs', key: "2", /* component: <DateAndPlaceToReport /> */ },
        { name: 'Checklist', label: 'Checklist', key: "3", /* component: <DateAndPlaceToReport /> */ },
      ]
    }
  }
  async componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      let { departmentList: departmentList, branchList: branchList, designationList: designationList, hiringMethodLists: hiringMethodLists } = this.props;
      if (isEmpty(branchList)) {
        await this.props.GetBranches();
        branchList = this.props.branchList;
      }
      if (isEmpty(hiringMethodLists)) {
        await this.props.GetHiringMethods();
        hiringMethodLists = this.props.hiringMethodLists;
      }
      if (isEmpty(designationList)) {
        await this.props.GetDesignations();
        designationList = this.props.designationList;
      }
      if (isEmpty(departmentList)) {
        await this.props.GetDepartments();
        departmentList = this.props.departmentList;
      }
      // if (!onboardingEmployeeDetail) {
      await this.props.GetOnboardingEmployeeDetail(id);
      // onboardingEmployeeDetail = this.props.onboardingEmployeeDetail;
      // }
      // let { onboardingEmployeeDetail } = this.props;
      if (isEmpty(this.props.onboardingEmployeeDetail))
        await this.props.GetOnboardingEmployeeDetail(id);
      if (!isEmpty(this.props.onboardingEmployeeDetail)) {
        const { EmployeeDto, EmployeePhotoDto, EmployeeOnBoardingDto } = this.props.onboardingEmployeeDetail;
        let HiringMethod = hiringMethodLists ? hiringMethodLists.find(d => d.value == EmployeeOnBoardingDto.HiringSourceId) : null;
        let empDepartment = departmentList?.find(d => d.value == EmployeeDto?.DepartmentId);
        let empBranch = branchList?.find(d => d.value == EmployeeDto?.BranchId);
        let empDesignation = designationList?.find(d => d.value == EmployeeDto?.DesignationId);
  
        let employeeInfo = {
          Photo: `${EmployeePhotoDto?.Photo}`,
          Name: EmployeeDto.FirstName + " " + (EmployeeDto?.MiddleName ? (EmployeeDto.MiddleName + " ") : '' )+ EmployeeDto?.LastName,
          Department: empDepartment,
          Designation: empDesignation,
          Branch: empBranch,
          AppointmentDate: moment(new Date(EmployeeOnBoardingDto?.AppointmentDate)).format("DD MMM, YYYY"),
          HiringMethod: HiringMethod ? HiringMethod.label : null
        };
        this.setState({ employeeInfo });
      }
    }
    // const { departmentList, branchList, designationList, location } = this.props;
    // const { employeeOnBoardingDto, employeeDto } = location.state ? location.state.SubData : { employeeOnBoardingDto: null, employeeDto: null};
    // if (employeeOnBoardingDto && employeeDto) {
    //     if (isEmpty(branchList)) {
    //         await this.props.GetBranches();
    //         branchList = this.props.branchList;
    //     }
    //     if (isEmpty(designationList)) {
    //         await this.props.GetDesignations();
    //         designationList = this.props.designationList;
    //     }
    //     if (isEmpty(departmentList)) {
    //         await this.props.GetDepartments();
    //         departmentList = this.props.departmentList;
    //     }
    //     let empDepartment = departmentList.find(d => d.value == employeeDto.DepartmentId);
    //     let empBranch = branchList.find(d => d.value == employeeDto.BranchId);
    //     let empDesignation = designationList.find(d => d.value == employeeDto.DesignationId);
    //     let employeeInfo = {
    //         Name: employeeDto.Name,
    //         Department: empDepartment,
    //         Designation: empDesignation,
    //         Branch: empBranch,
    //         AppointmentDate: moment(new Date(employeeOnBoardingDto.AppointmentDate)).format("DD MMM, YYYY"),
    //         HiringMethod: employeeOnBoardingDto.HiringSourceId
    //     };
    //     this.setState({ employeeInfo });
    // }
  }

  handleFormSubmit = async (data, activeTabIndex, Stage) => {
    let { activeTab, dataToSubmit } = this.state;
    if (activeTabIndex == "0") {
      let formData = {
        employeeOnBoardingDto: {
          Stage,
          ...data,
          // WelcomeMessage: data,
        }
      }
      await this.props.InitiateOnboardingEmployee(formData);
      this.setState({ activeTab: 'Welcome Message', activeTabIndex: "1", dataToSubmit: data })
    } else if (activeTabIndex == "1") {
      let formData = {
        employeeOnBoardingDto: {
          ...data,
          Stage,
          // WelcomeMessage: data,
        }
      }
      await this.props.InitiateOnboardingEmployee(formData);
      // this.setState({ activeTab: 'Date and Place', activeTabIndex: "1", dataToSubmit: formData })

      const { saveOnboardingEmployee } = this.props;
      if (saveOnboardingEmployee) this.props.history.push('/admin/employee_onboarding/');
    }
  }

  changeActiveTab = (tab) => {
    if (tab == "0") this.setState({ activeTab: 'Date and Place' });
    else if (tab == '1') this.setState({ activeTab: 'Welcome Message' });
  }

  onClose = () => {
    this.props.history.push('/admin/employee_onboarding/');
  }

  render() {
    const { tabs, employeeInfo, activeTab, activeTabIndex } = this.state
    // let isDisabled = activeTab 
    return (
      <>
        { employeeInfo && <OnBoardingEmpProfile employeeInfo={employeeInfo} />}
        <div className="profile-tab subscription-tab-disabled">
          <Tabs noBackground activeTab={activeTab}>
            {
              tabs.map(tab => {
                return (
                  <Tab isDisabled={activeTabIndex < tab.key } label={tab.label} name={tab.name} id={tab.name} /* onTabClick={() => this.fetchRequiredDataFromTab(tab.key)}  */>
                    {
                      tab.component ? tab.component :
                        <span>
                          {tab.label}
                        </span>
                    }
                  </Tab>
                )
              })
            }
          </Tabs>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  branchList: state.commonReducer.branchList,
  departmentList: state.commonReducer.departmentList,
  designationList: state.commonReducer.designationList,
  onboardingEmployeeDetail: state.employeeOnboarding.onboardingEmployeeDetail,
  saveOnboardingEmployee: state.employeeOnboarding.saveOnboardingEmployee,
  hiringMethodLists: state.commonReducer.hiringMethodLists,
})

const mapDispatchToProps = {
  GetBranches,
  GetDesignations,
  GetDepartments,
  InitiateOnboardingEmployee,
  GetOnboardingEmployeeDetail,
  GetHiringMethods,

}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeOnBoardingPackage);