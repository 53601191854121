import Http from 'src/utils/http/http';
import Action from 'src/utils/http/actionUtility';
import environment from 'environment';
import types from 'constants/types';
import RouteEnum from 'constants/routeEnum';
import queryUrl from 'helpers/getQueryUrl';

export const getRosterLocationList = () =>async dispatch =>{
    const res = Http.get(environment.api.roosterLocationList)
    const actionConfig = {
		dispatch,
		actionType:types.GET_ROOSTER_LOCATION_LIST,
		effect:res,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}

export const getRosterLocations = (id) =>async dispatch =>{
    const res = Http.get(environment.api.roosterLocation +'/' + id)
    const actionConfig = {
		dispatch,
		actionType:types.GET_ROOSTER_LOCATION,
		effect:res,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}

export const addRosterLocations = (data) =>async dispatch =>{
    const res = Http.post(environment.api.roosterLocation,data)
    const actionConfig = {
		dispatch,
		actionType:types.ADD_ROOSTER_LOCATION,
		effect:res,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}

export const updateRosterLocations = (data) =>async dispatch =>{
    const res = Http.put(environment.api.roosterLocation,data)
    const actionConfig = {
		dispatch,
		actionType:types.UPDATE_ROOSTER_LOCATION,
		effect:res,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}

export const deleteRosterLocations = (id) =>async dispatch =>{
    const res = Http.delete(environment.api.roosterLocation +'/' + id)
    const actionConfig = {
		dispatch,
		actionType:types.DELETE_ROOSTER_LOCATION,
		effect:res,
        type:'delete',
        id,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}

//End Roster Location


//Roster Shifts
export const getRosterShitsList = (params) =>async dispatch =>{
    const final = queryUrl(environment.api.roosterShiftList,params)
    const res = Http.get(final)
    const actionConfig = {
		dispatch,
		actionType:types.GET_ROOSTER_SHIFTS_LIST,
		effect:res,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}

export const getRosterShift = (id) =>async dispatch =>{
    const res = Http.get(environment.api.roosterShift +'/' + id)
    const actionConfig = {
		dispatch,
		actionType:types.GET_ROOSTER_SHIFT,
		effect:res,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}

export const addRosterShift = (data) =>async dispatch =>{
    const res = Http.post(environment.api.roosterShift,data)
    const actionConfig = {
		dispatch,
		actionType:types.ADD_ROOSTER_SHIFT,
		effect:res,
        isLoading:true,
        message:'Roster Shift Added Successfully'
	}
    return await Action.createThunkEffect(actionConfig);
}

export const updateRosterShift = (data) =>async dispatch =>{
    const res = Http.put(environment.api.roosterShift,data)
    const actionConfig = {
		dispatch,
		actionType:types.UPDATE_ROOSTER_SHIFT,
		effect:res,
        message:'Roster Shift Updated Successfully',
        isLoading:true
	}
    return await Action.createThunkEffect(actionConfig);
}

export const updateRosterShiftArchive = (id) =>async dispatch =>{
    const res = Http.put(environment.api.roosterShiftArchive +'/' +id)
    const actionConfig = {
		dispatch,
		actionType:types.UPDATE_ROOSTER_SHIFT_ARCHIVE,
		effect:res,
        message:'Roster Shift Archive Updated Successfully',
        isLoading:true
	}
    return await Action.createThunkEffect(actionConfig);
}

export const deleteRosterShift = (id) =>async dispatch =>{
    const res = Http.delete(environment.api.roosterShift +'/' + id)
    const actionConfig = {
		dispatch,
		actionType:types.DELETE_ROOSTER_SHIFT,
		effect:res,
        type:'delete',
        message:'Roster Shift Deleted Successfully',
        id,
        isLoading:true
	}
    const resp = await Action.createThunkEffect(actionConfig);
    return resp;
}

export const getRosterShitsDropdownList = (teamId) =>async dispatch =>{
    const res = Http.get(environment.api.roosterShiftListDown + '/' + teamId ) 
    const actionConfig = {
		dispatch,
		actionType:types.GET_ROOSTER_SHIFTS_DROPDOWN_LIST,
		effect:res,
        isSelect:true,
        // isValueKey:true,
        // isJustData:true,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}

//End Roster Shifts


//Start Roster Shift Pattern
export const getRosterPatternList = () =>async dispatch =>{
    const final = queryUrl(environment.api.roosterShiftPatternList,{})
    const res = Http.get(final)
    const actionConfig = {
		dispatch,
		actionType:types.GET_ROOSTER_PATTERN_LIST,
		effect:res,
	}
    await Action.createThunkEffect(actionConfig);
}

export const getRosterShiftPattern = (id) =>async dispatch =>{
    const res = Http.get(environment.api.roosterShiftPattern +'/' + id)
    const actionConfig = {
		dispatch,
		actionType:types.GET_ROOSTER_SHIFT_PATTERN,
		effect:res,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}

export const addRosterShiftPattern = (data) =>async dispatch =>{
    const res = Http.post(environment.api.roosterShiftPattern,data)
    const actionConfig = {
		dispatch,
		actionType:types.ADD_ROOSTER_SHIFT_PATTERN,
		effect:res,
        message:'Shift Pattern has been created successfully.',
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}

export const updateRosterShiftPattern = (data) =>async dispatch =>{
    const res = Http.put(environment.api.roosterShiftPattern,data)
    const actionConfig = {
		dispatch,
		actionType:types.UPDATE_ROOSTER_SHIFT_PATTERN,
		effect:res,
        message:'Shift Pattern has been updated successfully.',
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}

export const deleteRosterShiftPattern = (id) =>async dispatch =>{
    const res = Http.delete(environment.api.roosterShiftPattern +'/' + id)
    const actionConfig = {
		dispatch,
		actionType:types.DELETE_ROOSTER_SHIFT_PATTERN,
		effect:res,
        type:'delete',
        id,
        isLoading:true,
        message:'Shift Pattern has been deleted successfully.'
	}
    await Action.createThunkEffect(actionConfig);
}

export const getRosterShiftPatternDetailsByMaster = (shiftPatternMasterId) =>async dispatch =>{
    const res = Http.get(environment.api.roosterShiftPatternDetailMaster +'/' + shiftPatternMasterId)
    const actionConfig = {
		dispatch,
		actionType:types.GET_ROOSTER_SHIFT_PATTERN_DETAIL_BY_MASTER,
		effect:res,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}

export const addRosterShiftPatternDetail = (shiftPatternMasterId : number, data:any ) =>async dispatch =>{
    const res = Http.post(environment.api.roosterShiftPatternDetail+'/'+shiftPatternMasterId ,data)
    const actionConfig = {
		dispatch,
		actionType:types.ADD_ROOSTER_SHIFT_PATTERN_DETAIL,
		effect:res,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}

//End Roster Shift Pattern List


//Start Roster Schedule
export const getRosterScheduleList = (params) =>async dispatch =>{
    const final = queryUrl(environment.api.roosterScheduleList,params)
    const res = Http.get(final)
    const actionConfig = {
		dispatch,
		actionType:types.GET_ROOSTER_SCHEDULE_LIST,
		effect:res,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}

export const addRosterSchedule = (data) =>async dispatch =>{
    const res = Http.post(environment.api.roosterSchedule,data)
    const actionConfig = {
		dispatch,
		actionType:types.ADD_ROOSTER_SCHEDULE,
		effect:res,
        message:'Rooster Schedule added successfully',
        messageType:'success',
        isLoading:true
	}
    return await Action.createThunkEffect(actionConfig);
}

export const copyRosterSchedule = (data) =>async dispatch =>{
    const res = Http.post(environment.api.roosterScheduleCopy, data)
    const actionConfig = {
		dispatch,
		actionType:types.ADD_ROOSTER_SCHEDULE,
		effect:res,
        message:'Rooster Schedule copied successfully',
        messageType:'success',
        isLoading:true
	}
    return await Action.createThunkEffect(actionConfig);
}

export const updateRosterSchedule = (data) =>async dispatch =>{
    const res = Http.put(environment.api.roosterSchedule,data)
    const actionConfig = {
		dispatch,
		actionType:types.UPDATE_ROOSTER_SCHEDULE,
		effect:res,
        isLoading:true
	}
    return await Action.createThunkEffect(actionConfig);
}

export const deleteRosterSchedule = (id) =>async dispatch =>{
    const res = Http.delete(environment.api.roosterSchedule +'/' + id)
    const actionConfig = {
		dispatch,
		actionType:types.DELETE_ROOSTER_SCHEDULE,
		effect:res,
        type:'delete',
        id,
        isLoading:true
	}
    return await Action.createThunkEffect(actionConfig);
}

export const getRosterScheduleEmployeeList = (params) =>async dispatch =>{
    const final = queryUrl(environment.api.roosterScheduleEmployeeList,params)
    const res = Http.get(final)
    const actionConfig = {
		dispatch,
		actionType:types.GET_ROOSTER_SCHEDULE_EMPLOYEE_LIST,
		effect:res,
	}
    await Action.createThunkEffect(actionConfig);
}

export const getRosterScheduleAllEmployeeList = (params) =>async dispatch =>{
    const final = queryUrl(environment.api.roosterScheduleAllEmployeeList,params)
    const res = Http.get(final)
    const actionConfig = {
		dispatch,
		actionType:types.GET_ROOSTER_SCHEDULE_ALL_EMPLOYEE_LIST,
		effect:res,
	}
    await Action.createThunkEffect(actionConfig);
}

export const getRosterScheduleEmployeeListWithWorkingHour = (params) =>async dispatch =>{
    const final = queryUrl(environment.api.roosterScheduleEmployeeListWithWorkingHourDetail,params)
    const res = Http.get(final)
    const actionConfig = {
		dispatch,
		actionType:types.GET_ROOSTER_SCHEDULE_EMPLOYEE_WITH_WORKING_HOURS,
		effect:res,
	}
    await Action.createThunkEffect(actionConfig);
}


export const addRosterScheduleEmployee = (data,startDate,endDate) =>async dispatch =>{
    const res = Http.post(environment.api.roosterScheduleEmployee + `/${startDate}/${endDate}`,data)
    const actionConfig = {
		dispatch,
		actionType:types.ADD_ROOSTER_SCHEDULE_EMPLOYEE,
		effect:res,
        isLoading:true
	}
    return await Action.createThunkEffect(actionConfig);
}

export const addRosterScheduleEmployeeByShift = (data,startDate,endDate,shiftId) =>async dispatch =>{
    const res = Http.post(environment.api.roosterScheduleEmployee + `/${startDate}/${endDate}/${shiftId}`,data)
    const actionConfig = {
		dispatch,
		actionType:types.ADD_ROOSTER_SCHEDULE_EMPLOYEE_ALL,
		effect:res,
        isLoading:true
	}
    return await Action.createThunkEffect(actionConfig);
}

export const updateRosterScheduleEmployee = (data,startDate,endDate) =>async dispatch =>{
    const res = Http.put(environment.api.roosterScheduleEmployee + `/${startDate}/${endDate}`,data)
    const actionConfig = {
		dispatch,
		actionType:types.UPDATE_ROOSTER_SCHEDULE_EMPLOYEE,
		effect:res,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}

export const removeRosterScheduleEmployee = (id,data) =>async dispatch =>{
    const res = Http.post(environment.api.roosterScheduleRemoveEmployee + `${id}`,data)
    const actionConfig = {
		dispatch,
		actionType:types.REMOVE_ROOSTER_SCHEDULE_EMPLOYEE,
		effect:res,
        message:"Successfully unassigned employee",
        isLoading:true
	}
    return await Action.createThunkEffect(actionConfig);
}

// End Roster Schedule


// Start Roster Team
export const getRosterTeamList = () =>async dispatch =>{
    const res = Http.get(environment.api.roosterTeamList)
    const actionConfig = {
		dispatch,
		actionType:types.GET_ROOSTER_TEAM_LIST,
		effect:res,
	}
    await Action.createThunkEffect(actionConfig);
}

export const getRosterTeam = (id) =>async dispatch =>{
    const res = Http.get(environment.api.roosterTeam +'/' + id)
    const actionConfig = {
		dispatch,
		actionType:types.GET_ROOSTER_TEAM,
		effect:res,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}

export const addRosterTeam = (data) =>async dispatch =>{
    const res = Http.post(environment.api.roosterTeam,data)
    const actionConfig = {
		dispatch,
		actionType:types.ADD_ROOSTER_TEAM,
		effect:res,
        isLoading:true
	}
    return await Action.createThunkEffect(actionConfig);
}

export const updateRosterTeam = (data) =>async dispatch =>{
    const res = Http.put(environment.api.roosterTeam,data)
    const actionConfig = {
		dispatch,
		actionType:types.UPDATE_ROOSTER_TEAM,
		effect:res,
        isLoading:true
	}
    return await Action.createThunkEffect(actionConfig);
}

export const deleteRosterTeam = (id) =>async dispatch =>{
    const res = Http.delete(environment.api.roosterTeam +'/' + id)
    const actionConfig = {
		dispatch,
		actionType:types.DELETE_ROOSTER_TEAM,
		effect:res,
        type:'delete',
        message:'Team Deleted Successfully',
        id,
        isLoading:true
	}
    return await Action.createThunkEffect(actionConfig);
}


export const getRosterTeamEmployee = (params) =>async dispatch =>{
    const final = queryUrl(environment.api.roosterTeamEmployee,params)
    const res = Http.get(final)
    const actionConfig = {
		dispatch,
		actionType:types.GET_ROOSTER_TEAM_EMPLOYEE,
		effect:res,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}

export const addRosterTeamEmployee = (data) =>async dispatch =>{
    const res = Http.post(environment.api.roosterTeamEmployee,data)
    const actionConfig = {
		dispatch,
		actionType:types.ADD_ROOSTER_TEAM_EMPLOYEE,
		effect:res,
        isLoading:true
	}
    return await Action.createThunkEffect(actionConfig);
}

export const updateRosterTeamEmployee = (data) =>async dispatch =>{
    const res = Http.put(environment.api.roosterTeamEmployee,data)
    const actionConfig = {
		dispatch,
		actionType:types.UPDATE_ROOSTER_TEAM_EMPLOYEE,
		effect:res,
        isLoading:true
	}
    return await Action.createThunkEffect(actionConfig);
}

export const deleteRosterTeamEmployee = (id) =>async dispatch =>{
    const res = Http.delete(environment.api.roosterTeamEmployee +'/' + id)
    const actionConfig = {
		dispatch,
		actionType:types.DELETE_ROOSTER_TEAM_EMPLOYEE,
		effect:res,
        type:'delete',
        id,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}

// End Roster Team

// Start Roster Team Role
export const getRosterTeamRoleList = (teamId) =>async dispatch =>{
    const final = queryUrl(environment.api.roosterTeamRoleList,{teamId})
    const res = Http.get(final)
    const actionConfig = {
		dispatch,
		actionType:types.GET_ROOSTER_TEAM_ROLE_LIST,
		effect:res,
	}
    return await Action.createThunkEffect(actionConfig);
}


export const getRosterTeamRole = (id) =>async dispatch =>{
    const res = Http.get(environment.api.roosterTeamRole +'/' + id)
    const actionConfig = {
		dispatch,
		actionType:types.GET_ROOSTER_TEAM_ROLE,
		effect:res,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}

export const addRosterTeamRole = (data) =>async dispatch =>{
    const res = Http.post(environment.api.roosterTeamRole,data)
    const actionConfig = {
		dispatch,
		actionType:types.ADD_ROOSTER_TEAM_ROLE,
		effect:res,
        isLoading:true
	}
    return await Action.createThunkEffect(actionConfig);
}

export const updateRosterTeamRole = (data) =>async dispatch =>{
    const res = Http.put(environment.api.roosterTeamRole,data)
    const actionConfig = {
		dispatch,
		actionType:types.UPDATE_ROOSTER_TEAM_ROLE,
		effect:res,
        isLoading:true
	}
    return await Action.createThunkEffect(actionConfig);
}

export const deleteRosterTeamRole = (id) =>async dispatch =>{
    const res = Http.delete(environment.api.roosterTeamRole +'/' + id)
    const actionConfig = {
		dispatch,
		actionType:types.DELETE_ROOSTER_TEAM_ROLE,
		effect:res,
        type:'delete',
        message:'Role Deleted Successfully',
        id,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}

// END Roster Team Role