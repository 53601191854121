import React from 'react'
import { contentTypes } from 'constants/enums';
import { contents, questionContent } from 'scenes/lms/helpers/constants';
import { BsTrash } from 'react-icons/bs';
import { RiArrowDownLine, RiArrowUpLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCourseContent, getCourseContent, getCoursePage, updateCourseContent } from 'scenes/lms/helpers/action';
import Docs from 'assets/images/document.svg'
import PDF from 'assets/images/pdf.svg'


function ShowItem(props) {
    const dispatch = useDispatch()
    const { coursePages, course, coursePage, courseContent } = useSelector((state: any) => state.lmsReducer)
    const getComponents = () => {
        switch (parseInt(props.Type)) {
            case contentTypes.text.id:
                return <Text {...props} />;
            case contentTypes.image.id:
                return <Image {...props} />;
            case contentTypes.video.id:
                return <Video {...props} />;
            case contentTypes.video_link.id:
                return <VideoLink {...props} />
            case contentTypes.attachment.id:
                return <Attachments {...props} />
            case contentTypes.pdf.id:
                return <PDFS {...props} />
            default:
                return null
        }
    }
    const onCourseContentDelete = async () => {
        await dispatch(deleteCourseContent(props.Id))
        await dispatch(getCoursePage(props.CoursePageId))
        await dispatch(getCourseContent(props.CoursePageId))
    }
    const onUpdate = (corseItems) => {
        const data = new FormData();
        corseItems.map((item: any, key) => {
            data.append(`[${key}].CoursePageId`, item.CoursePageId);
            data.append(`[${key}].Id`, item.Id);
            data.append(`[${key}].Type`, item.Type);
            data.append(`[${key}].VideoLink`, item.VideoLink);
            item.Attachments && item.Attachments.map((att, k) => {
                Object.keys(att).map(name => {
                    data.append(`[${key}].Attachments[${k}].${name}`, att[name]);
                })
                data.append(`[${key}].AttachmentIds`, att.AttachmentID);
            })
            data.append(`[${key}].Description`, item.Description);
            data.append(`[${key}].VideoDuration`, item.VideoDuration);
            data.append(`[${key}].ContentOrder`, item.ContentOrder);
            data.append(`[${key}].Files`, null);
        })
        debugger
        dispatch(updateCourseContent(data))
    }
    const onGoDown = () => {
        let newContents = courseContent.map((item: any, key) => {
            let newItem = Object.assign({},item);
            if (props.Id === item.Id) {
                newItem.ContentOrder = item.ContentOrder + 1;
            }
            if (item.ContentOrder === props.ContentOrder + 1) {
                newItem.ContentOrder = item.ContentOrder - 1;
            }
            return newItem
        })
        onUpdate(newContents)
    }
    const onGoUp = () => {
        let newContents = courseContent.map((item: any, key) => {
            let newItem = Object.assign({},item);
            if (props.Id === item.Id) {
                newItem.ContentOrder = item.ContentOrder - 1;
            }
            if (item.ContentOrder === props.ContentOrder - 1) {
                newItem.ContentOrder = item.ContentOrder + 1;
            }
            return newItem
        })
        debugger
        onUpdate(newContents)
    }
    const isFirst = props.ContentOrder === 1;
    const isLast = props.ContentOrder === courseContent.length;
    return (
        <div className="course-content">
            <div className="course-content__header">
                <div className="course-content__type">
                    {
                        contents.map((item: any) => {
                            if (item.id === parseInt(props.Type)) {
                                return (
                                    <div className="course-chip">
                                        <span className="course-chip__icon">
                                            {item.icon}
                                        </span>
                                        <span className="course-chip__title" >{item.title}</span>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
                <div className="course-content__icons">
                    {
                        !isLast && (
                            <div
                                onClick={() => onGoDown()}
                                className="course-content__icon">
                                <RiArrowDownLine />
                            </div>
                        )
                    }
                    {
                        !isFirst && (
                            <div
                                onClick={() => onGoUp()}
                                className="course-content__icon">
                                <RiArrowUpLine />
                            </div>
                        )
                    }

                    <div
                        onClick={() => onCourseContentDelete()}
                        className="course-content__icon">
                        <BsTrash />
                    </div>
                </div>
            </div>
            <div className="course-content__body">
                {getComponents()}
            </div>
        </div>
    )
}

export const Text = (props) => {
    return (
        <div className="course-content__text-container fr-view">
            <div  
                dangerouslySetInnerHTML={{__html:props?.Description}}
                className="course-content__text">
                {/* {
                    props?.description
                } */}
            </div>
        </div>
    )
}

export const Image = (props) => {
    return (
        <div className="course-content__images">
            {
                props?.Attachments && props?.Attachments.map(item => {
                    return (
                        <div className="course-content__image">
                            <img src={item.FileLocation + item.ServerFileName} alt="" />
                        </div>
                    )
                })
            }
        </div>
    )
}

export const Video = (props) => {
    return (
        <div className="course-content__images">
            {
                props?.Attachments && props?.Attachments.map(item => {
                    return (
                        <div className="course-content__image">
                            {/* <img src={item.FileLocation + item.ServerFileName} alt="" /> */}
                            <video width="320" height="240" autoPlay muted>
                                <source src={item.FileLocation + item.ServerFileName} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    )
                })
            }
        </div>
    )
}

export const VideoLink = (props) => {
    return (
        <div className="course-content__images">
            <div className="course-content__image">
                {/* <img src={item.FileLocation + item.ServerFileName} alt="" /> */}
                <video width="320" height="240" autoPlay muted>
                    <source src={props?.VideoLink} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    )
}

export const PDFS = (props) => {
    return (
        <div className="course-content__images">
            {
                props?.Attachments && props?.Attachments.map(item => {
                    return (
                        <div className="course-content__doc">
                            <div className="course-content__doc-icon">
                                <img src={PDF} />
                            </div>
                            <div className="course-content__doc-text">
                                {item.UserFileName}
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export const Attachments = (props) => {
    return (
        <div className="course-content__images">
            {
                props?.Attachments && props?.Attachments.map(item => {
                    return (
                        <div className="course-content__doc">
                            <div className="course-content__doc-icon">
                                <img src={Docs} />
                            </div>
                            <div className="course-content__doc-text">
                                {item.UserFileName}
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}


export default ShowItem
