import React,{useEffect} from 'react'
import Search from './components/search';
import Regions from './components/regions'
import './clusters.styles.scss';
import {getRegionCluster} from './helpers/action';
import { useDispatch } from 'react-redux';

function Cluster() {
    
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(getRegionCluster())
    },[]);

    return (
        <div className="admin roles">
            <div className="admin-container">
                <div className="admin-title">
                    <h1>Region & Clusters</h1>
                </div>
                <div className="admin-body clusters">
                    <Search />
                    <Regions />
                </div>
            </div>
        </div>
    )
}

export default Cluster
