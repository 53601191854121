import React,{useEffect} from 'react'
import Sidebar from '../components/sidebar';
import TaskBody from './taskBody'
import './tasks.styles.scss'

function Dashboard(props) {
    return (
        <TaskBody {...props} />
    )
}

export default Dashboard
