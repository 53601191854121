import React from "react";
import {
  FaHandPaper,
  FaRegAddressCard,
  FaRegCheckCircle,
  FaRegHandPointLeft,
} from "react-icons/fa";
import CandidateList from "./candidateList";
import CandidateDetail from "./candidateDetail";

function ProfileView(props: any) {
  const {
    baseUrl,
    isLoading,
    seletedJobStage,
    selectedCandidate,
    currentCandidateDetail,
    appliedCandidateList,
    candidateDetail,
    candidateListByStage,
    candidateTableViewDetails,
    jobStageList,
    handleCandidateClick,
    hiringStageAndSubStagesJobId,
    searchByName
  } = props;
  return (
    <div className="information information-block">
      <CandidateList
        seletedJobStage={seletedJobStage}
        currentCandidateDetail={currentCandidateDetail}
        candidateTableViewDetails={candidateTableViewDetails}
        appliedCandidateList={appliedCandidateList}
        candidateListByStage={[]}
        selectedCandidate={selectedCandidate}
        candidateDetail={candidateDetail}
        handleCandidateClick={handleCandidateClick}
        searchByName={searchByName}
      />
      <div className="information-wholeInfo">
        {selectedCandidate ? (
          <CandidateDetail {...props} />
        ) : (
          <div className="no-candidates">
            <span className="no-candidates-icon">
              <FaRegHandPointLeft />
            </span>
            <span className="no-candidates-title">No candidate chosen..</span>
            <span className="no-candidates-title">
              Please choose a candidate seletedJobStage
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
export default ProfileView;
