import React from "react";
import Upload from "components/common/upload";

const propertyDocuments = [
  "Copy of Citizenship certificate of staff and current owner of property",
  "Copy of Land ownership Certificate / Manjurinama, blue print of the land",
  "Copy of construction approval / completion certificate from Municipality / VDC",
  "Copy of four boundary disclosure (Char killa) from ward office of the land / building to be placed as collateral",
  "Copy of latest land revenue receipt (Malpot receipt) and tax clearance receipt of the building to be placed collateral",
];
const vehicleDocuments = [
  "Copy of Citizenship Certificate",
  "Quotation for new vehicle / Valuation Report for used vehicle",
  "Copy of Driving License Copy of Bill Book (applicable for used vehicle)",
];

function DocumentsDetail({
  data,
  onChange,
  showDocumentModal,
  loanType,
  DisplayClientSideMessage,
}) {
  return (
    <div className="homeloan-documents">
      <h5 className="mb-md">Documents to be attached (max 5MB, pdf only)</h5>
      <div className="mb-md">
        <div>
          {loanType == "home"
            ? propertyDocuments.map((docName) => {
                return (
                  <>
                    <span>{docName}</span>
                    <br />
                  </>
                );
              })
            : loanType == "vehicle"
            ? vehicleDocuments.map((vehName) => {
                return (
                  <>
                    <span>{vehName}</span>
                    <br />
                  </>
                );
              })
            : "Attached Documents"}
        </div>
      </div>
      <div>
        <Upload
          fileList={data.Files}
          setSelectedFiles={(fileList) => onChange("Files", fileList)}
          uploadBtnText="Upload document"
          noSelectedText="No document selected"
          showModal={true}
          showDocumentModal={(file, open) => showDocumentModal(file, open)}
          allowedFileTypes={["pdf"]}
          maxSize={5000000}
          DisplayClientSideMessage={DisplayClientSideMessage}
        />
      </div>
    </div>
  );
}

export default DocumentsDetail;
