import React,{useState,useEffect} from 'react'
import {FaPlusCircle,FaMinusCircle} from 'react-icons/fa';
import {IoMdAdd} from 'react-icons/io'
import {MdModeEdit} from 'react-icons/md'
import {AiOutlineDrag} from 'react-icons/ai'
import { CSSTransition } from 'react-transition-group';
import Modal from 'components/modal'
import AddCluster from './addCluster'
import MoveCluster from './moveCluster';
import { useDispatch,useSelector } from 'react-redux';

function Regions() {
    const regionCluster = useSelector(state => state.clusterReducer.regionCluster);
    return (
        <div className="clusters-container">
            <div className="clusters-block">
                <ul className="clusters-list">
                    {
                        regionCluster && regionCluster.map(item=><ClusterItem data={item} />)
                    }
                </ul>
            </div>
        </div>
    )
}

const ClusterItem = ({data}) =>{
    const [open,setOpen] = useState(false);
    const [modalOpen,setModalOpen] = useState(false);
    const onToggle = () => setOpen(!open);

    const onModalClose = () =>{
        setModalOpen(false)
    }
    const clusterNum = data.clusters ? data.clusters.length : 0;
    const branchNum = (cluster) =>{
        if(cluster){
            let num = 0;
            cluster.map(item=>{
                if(item.branch){
                    num += item.branch.length;
                }
            })
            return num;
        }
        return 0;
    }
    return(
        <li className="clusters-list__item">
            <div className="cluster">
                <div className="cluster-icon" onClick={()=>onToggle()} >
                    {!open ? <FaPlusCircle /> : <FaMinusCircle /> }
                </div>
                <div className="cluster-region">
                    <span className="cluster-letter">{data.Alias}</span>
                    <span className="cluster-name">{data.Name}</span>
                </div>
                <div className="cluster-counts">
                    <span className="cluster-num">
                        {data.ClustersCount} Clusters
                    </span>
                    <span className="branch-num">
                        {data.BranchesCount} Branches
                    </span>
                </div>
                <div className="cluster-type">
                    <span>{data.Desc}</span>
                </div>
                <div className="cluster-btn">
                    <button 
                        onClick = {()=>setModalOpen(true)}
                        className="button button-white with-icon">
                        <IoMdAdd />
                        <span>Cluster</span>
                    </button>
                    <Modal
                        open={modalOpen}
                        onModalClose={onModalClose}
                        title="Add Cluster"
                        isPrimary
                        className="clusters-modal"
                        width="450px"
                    >
                        <AddCluster  close={onModalClose} ProvinceId={data.ProvinceId} />
                    </Modal>
                </div>
            </div>
            {
                data.Clusters && (
                    <div className="cluster-sub">
                        <ul className="cluster-sub__list">
                            {
                                data.Clusters.map(item=><ClusterSubItem ProvinceId={data.ProvinceId} data={item} isOpen={open} />)
                            }
                        </ul>
                    </div>
                )
            }
        </li>
    )
}

export const ClusterSubItem = ({isOpen,data,ProvinceId}) =>{
    const [open,setOpen] = useState(false);
    const onToggle = () => setOpen(!open);
    useEffect(()=>{
        if(!isOpen){
            setOpen(false)
        }
    },[isOpen]);
    const [modalOpen,setModalOpen] = useState(false);
    const onModalClose = () =>{
        setModalOpen(false)
    }
    return(
        <CSSTransition
                in={isOpen}
                // key={item.module_name} 
                timeout={300}
                classNames="cluster"
                unmountOnExit 
            >
            <li className="cluster-sub__item">
                <div className="cluster">
                    <div className="cluster-icon" onClick={()=>onToggle()}>
                        {!open ? <FaPlusCircle /> : <FaMinusCircle /> }
                    </div>
                    <div className="cluster-region">
                        <span className={`cluster-letter ${data.Alias === 'U' ? 'pink' : 'orange'}`}>{data.Alias}</span>
                        <span className="cluster-name">{data.ClusterName}</span>
                    </div>
                    <div className="cluster-counts">
                        <span className="branch-num">
                            {data.BranchesCount} Branches
                        </span>
                    </div>
                    <div className="cluster-type">
                        <span>{data.Desc}</span>
                    </div>
                    {
                        data.ClusterId !== -1 ? 
                        (
                            <div className="cluster-btn">
                                <button 
                                    onClick = {()=>setModalOpen(true)}
                                    className="button button-white with-icon">
                                    <MdModeEdit />
                                    <span>Edit</span>
                                </button>
                                <Modal
                                    open={modalOpen}    
                                    onModalClose={onModalClose}
                                    title="Edit Cluster"
                                    isPrimary
        
                                    className="clusters-modal"
                                    width="450px"
                                >
                                    <AddCluster isEdit cluster={data} close={onModalClose} ProvinceId={ProvinceId} />
                                </Modal>
                            </div>
                        )
                        : <div className="cluster-btn"></div>
                    }

                </div>   
                {
                    data.Branches && (
                        <div className="cluster-sub">
                            <ul className="cluster-sub__list">
                                {
                                    data.Branches.map(item=><ClusterLastItem cluster={data} data={item} isOpen={open} />)
                                }
                                
                            </ul>
                        </div>
                    )
                }
            </li>
        </CSSTransition> 
    )
}

export const ClusterLastItem = ({isOpen,data,cluster}) =>{
    const [modalOpen,setModalOpen] = useState(false);

    const onModalClose = () =>{
        setModalOpen(false)
    }
    return(
        <CSSTransition
                in={isOpen}
                // key={item.module_name} 
                timeout={300}
                classNames="clusterChild"
                unmountOnExit 
            >
            <li className="cluster-sub__item">
                
                <div className="cluster">
                    <div className="cluster-icon">
                        <AiOutlineDrag />
                    </div>
                    <div className="cluster-region">
                        <span className="cluster-name">{data.BranchName}</span>
                    </div>
                    <div className="cluster-btn">
                        <button 
                            onClick={()=>setModalOpen(true)}
                            className="button button-primary-light with-icon">
                            <MdModeEdit />
                            <span>Move</span>
                        </button>
                        <Modal
                            open={modalOpen}
                            onModalClose={onModalClose}
                            title="Move Branch to another cluster"
                            className="roles-modal"
                            width="700px"
                        >
                            <MoveCluster branch={data} cluster={cluster} close={onModalClose} />
                        </Modal>
                    </div>
                </div> 
            </li>
        </CSSTransition>
    )
}

export default Regions
