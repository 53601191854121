import React, { Component } from 'react'
import GrossTable from './grossTable'
import ActivityTable from './activityTable'
import {  motion } from "framer-motion";
import Sidetab from "./reviewTabs";
import './style.scss';

const pageVariants = {
    initial: {
      opacity: 0,
      x: "-100vw",
      scale: 0.8
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1
    },
    out: {
      opacity: 0,
      x: "100vw",
      scale: 1.2
    }
  };
  
  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.5
  };
  
  const pageStyle = {
    position: "absolute",
    width:"100%"
  };

export class Review_Finalize extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 1,
    }
  }
  changeSideTab = (currentStep) => {
    this.setState({ currentStep });
  };
    render() {
      const {
        currentStep,
      } = this.state;
        return (
            <div className="payroll-body payroll-tax">
                {/* <motion.div
                    style={pageStyle}
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                > */}
                <div>
                    <div>
                      <h3 className="mb-md blue">Final Revision</h3>
                    </div>
                    <div className="tax-adj-tabs mt-sm">
                      <Sidetab
                        currentStep={currentStep}
                        onStepChange={(step) => this.changeSideTab(step)}
                      />
                    </div>
                    <div className="mb-md">
                        <GrossTable />
                    </div>
                    <div>
                        <ActivityTable />
                    </div>
                    <div className="mt-bg">
                      <div className="button-group button-group__right">
                        <div className="card-sm green flex align-center">
                          <h3 className="card-sm__title align-center">Verify and Generate Tax</h3>
                        </div>
                      </div>
                    </div>
                    
                </div>
                {/* </motion.div>   */}
            </div>
        )
    }
}

export default Review_Finalize
