import React from "react";
import { JobStages } from "../enums";
import SvgIcons from "components/icons/svgIcons";

export default function JobStageIcon(props: any) {
  const { stageId } = props;
  let name: any = null;
  switch (stageId) {
    case JobStages.Applied:
      name = "applied";
      break;
    case JobStages.Shortlisted:
      name = "shortlisted";
      break;
    case JobStages.Interview:
      name = "interview";
      break;
    case JobStages.Written_Exam:
      name = "written";
      break;
    case JobStages.Job_Offer:
      name = "jobOffer";
      break;
    case JobStages.Hired:
      name = "hired";
      break;
    default:
      return null;
  }
  return name ? (
    <span>
      <SvgIcons name={name} color="#3a3d43" size="15px" />
    </span>
  ) : null;
}
