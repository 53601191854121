import {
  DisplayClientSideMessage,
  // GetAllEmployeeListWithPhoto,
  GetEmployeeList,
} from "actions/commonAction";
import classnames from "classnames";
import Loader from "components/loaders";
import Modal from "components/modal";
import { DISCIPLINARY_ACTION_DETAILS } from "constants/constants";
import isEmpty from "isEmpty";
import React, { Component } from "react";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { FaArrowRight, FaCheck, FaQuestion } from "react-icons/fa";
import { connect } from "react-redux";
import ComposeEmail from "scenes/common/email/compose";
import EmployeeDetails from "../../../common/employeeDetails";
import {
  getDisciplinaryCaseDetails,
  moveDisciplinaryActionToNextStage,
} from "../action";
import ActionForm from "./actionForm";
import AddCommentsForm from "./addCommentsForm";
import "./disciplinaryActions.scss";
import DisciplinaryStageDetails from "./disciplinaryStageDetails";
import RouteEnum from "constants/routeEnum";

class DisciplinaryAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disciplinaryCaseDetails: {},
      actionsStages: [],
      stageDetails: [],
      isModalShow: false,
      formData: DISCIPLINARY_ACTION_DETAILS,
      currentStageFormData: {},
      isSendEmailShow: false,
      isCaseClosed: false,
      isCommentModalShow: false,
      selectedActionId: 0,
    };
  }
  async componentDidMount() {
    const {
      match: { params },
    } = this.props;
    const {
      GetEmployeeList,
      // GetAllEmployeeListWithPhoto,
      getDisciplinaryCaseDetails,
    } = this.props;
    let ajaxApi = [
      GetEmployeeList(),
      // GetAllEmployeeListWithPhoto(),
      getDisciplinaryCaseDetails(params.disciplinaryCaseId),
    ];
    Promise.all(
      ajaxApi.map(async (item) => {
        return await item;
      })
    );
    console.log(this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    const { disciplinaryCaseDetails } = this.props;
    console.log(disciplinaryCaseDetails);
    if (
      !disciplinaryCaseDetails.loading &&
      !isEmpty(disciplinaryCaseDetails.value) &&
      prevProps.disciplinaryCaseDetails.value !== disciplinaryCaseDetails.value
    ) {
      let dspCaseDetails = disciplinaryCaseDetails.value;
      let disciplinaryCase = {
        DisciplinaryCaseId: dspCaseDetails.DisciplinaryCaseId,
        CaseTitle: dspCaseDetails.CaseTitle,
        CaseStatement: dspCaseDetails.CaseStatement,
        CurrentActionStageId: dspCaseDetails.DisciplinaryActionStageRefId,
        CurrentActionSubStageId: dspCaseDetails.DisciplinaryActionSubStageRefId,
        CurrentActionStageText: dspCaseDetails.CurrentActionStageText,
        CurrentActionSubStageText: dspCaseDetails.CurrentActionSubStageText,
        PastDisciplinaryCasesCount: dspCaseDetails.PastDisciplinaryCasesCount,
        DisciplinaryEmployee: dspCaseDetails.DisciplinaryEmployee,
      };

      let stageDetails = !dspCaseDetails.DisciplinaryActions
        ? []
        : dspCaseDetails.DisciplinaryActions.map((stageDetail) => {
            const selectedDisciplinaryActionStage = dspCaseDetails.DisciplinaryActionStage.find(
              (x) =>
                x.ActionStageId === stageDetail.DisciplinaryActionStageRefId
            );
            return {
              ActionDateText: stageDetail.ActionDateText,
              ActionTitle: stageDetail.ActionRemarksDisplayText,
              ActionByDisplayText: stageDetail.ActionByDisplayText,
              ResponseMediumText: stageDetail.ResponseMediumText,
              CreatedByText: stageDetail.CreatedByText,
              CreatedOnText: stageDetail.CreatedOnText,
              ActionByEmployees: stageDetail.ActionByEmployees,
              Comments: stageDetail.Comments,
              Attachments: stageDetail.Attachments,
              ActionStageId: stageDetail.DisciplinaryActionStageRefId,
              ActionSubStageId: stageDetail.DisciplinaryActionSubStageRefId,
              ActionRemarks: stageDetail.ActionRemarks,
              ResponseMedium: stageDetail.ResponseMedium,
            };
          });
      let actionsStages = !dspCaseDetails.DisciplinaryActionStage
        ? []
        : dspCaseDetails.DisciplinaryActionStage.map((actionStage) => {
            return {
              ...actionStage,
              Checked:
                actionStage.ActionStageId ===
                  dspCaseDetails.DisciplinaryActionStageRefId ||
                !isEmpty(
                  stageDetails.find(
                    (x) => x.ActionStageId === actionStage.ActionStageId
                  )
                )
                  ? true
                  : false,
              SubStages: actionStage.SubStages.map((actionSubStage) => {
                return {
                  ...actionSubStage,
                  ActionStageId: actionSubStage.DisciplinaryActionStageRefId,
                };
              }),
            };
          });
      let isCaseClosed = !dspCaseDetails.Status;
      this.setState({
        actionsStages,
        stageDetails,
        disciplinaryCaseDetails: disciplinaryCase,
        isCaseClosed,
      });
    }
  }

  handleModalShow = (stageId, subStageId) => {
    const { actionsStages } = this.state;
    let currentStageFormData = this.state.formData.find(
      (item) => item.ActionId === stageId
    );
    if (subStageId !== 0) {
      let currentStage = actionsStages?.find(
        (x) => x.ActionStageId === stageId
      );
      let currentSubStage = currentStage?.SubStages?.find(
        (x) => x.ActionSubStageId === subStageId
      );
      currentStageFormData.ActionTitle =
        currentSubStage?.SubStageName + " Note";
    }
    currentStageFormData.SubStageId = subStageId;
    this.setState({ isModalShow: true, currentStageFormData });
  };
  closeModal = () => {
    this.setState({ isModalShow: false });
  };
  handleSendEmailShow = () => {
    this.setState({ isSendEmailShow: true });
  };
  closeEmailModal = () => {
    this.setState({ isSendEmailShow: false });
  };

  closeCommentModal = () => {
    this.setState({ isCommentModalShow: false });
  };
  handleCommentModalShow = (selectedActionId) => {
    this.setState({ isCommentModalShow: true, selectedActionId });
  };
  handleMoveToNextStage = async (data) => {
    const { ActionStageId } = data;
    const { disciplinaryCaseDetails, actionsStages, stageDetails } = this.state;
    const {
      CurrentActionStageId,
      CurrentActionStageText,
    } = disciplinaryCaseDetails;
    let currentStage = actionsStages?.find(
      (x) => x.ActionStageId === CurrentActionStageId
    );
    let isAllStageNoteAdded = true;
    var i = 0;
    for (i = 0; i < currentStage?.SubStages.length; i++) {
      if (
        isEmpty(
          stageDetails.find(
            (x) =>
              x.ActionSubStageId ===
              currentStage?.SubStages[i]?.ActionSubStageId
          )
        )
      ) {
        isAllStageNoteAdded = false;
        break;
      }
    }
    if (
      !isAllStageNoteAdded ||
      isEmpty(
        stageDetails.filter(
          (item) => item.ActionStageId === CurrentActionStageId
        )
      )
    ) {
      let message = {
        MessageType: "danger",
        Message:
          "You cannot move without adding " +
          CurrentActionStageText.toLowerCase() +
          " note",
      };
      this.props.DisplayClientSideMessage(message);
      return false;
    }
    let requestData = {
      DisciplinaryCaseId: disciplinaryCaseDetails.DisciplinaryCaseId,
      ActionStageRefId: ActionStageId,
      ActionSubStageRefId: null,
    };

    let result = await moveDisciplinaryActionToNextStage(requestData);
    if (result.Status) {
      this.setState((prevState) => ({
        actionsStages: prevState.actionsStages.map((el) =>
          el.ActionStageId === ActionStageId ? { ...el, Checked: true } : el
        ),
      }));
    } else {
      this.props.DisplayClientSideMessage(result);
    }
  };
  render() {
    const { employeeList, employeeListWithPhoto, spinnerLoading } = this.props;

    const {
      actionsStages,
      stageDetails,
      isModalShow,
      formData,
      currentStageFormData,
      isSendEmailShow,
      disciplinaryCaseDetails,
      isCaseClosed,
      isCommentModalShow,
      selectedActionId,
    } = this.state;
    const {
      CaseTitle,
      CaseStatement,
      CurrentActionStageId,
      CurrentActionSubStageId,
      CurrentActionStageText,
      CurrentActionSubStageText,
      PastDisciplinaryCasesCount,
      DisciplinaryEmployee,
    } = disciplinaryCaseDetails;

    return (
      <>
        <div className="disp-actions">
          {/* <div className="disp-actions__title">
            <span>Disciplinary Action</span>
          </div> */}
          <div className="disp-actions__title">
            <div className="common-route-title">
              <span
                className="module"
                onClick={() =>
                  this.props.history.push(RouteEnum.DisciplinaryActionPath)
                }
              >
                Disciplinary Action
              </span>
              <AiOutlineDoubleRight className="right-arrow ml-xsm" />
              <span className="path-name ml-xsm">
                {DisciplinaryEmployee?.FullName}
              </span>
            </div>
          </div>
          <div className="disp-actions__header">
            {!isEmpty(DisciplinaryEmployee) ? (
              <EmployeeDetails empDetails={DisciplinaryEmployee} size={80} />
            ) : null}
            <div className="past-actions">
              <span className="past-actions__text">
                Past Disciplinary Actions
              </span>
              <span className="past-actions__count">
                {PastDisciplinaryCasesCount}
              </span>
            </div>
            <div className="current-stage">
              <span className="current-stage__name">
                {isEmpty(CurrentActionSubStageText)
                  ? CurrentActionStageText
                  : CurrentActionSubStageText}
              </span>
            </div>
          </div>
          <div className="disp-actions__body">
            <div className="case-title">
              <span className="case-title__text">{CaseTitle}</span>
              {isCaseClosed ? null : (
                <button
                  className="btn close-case-btn"
                  type="button"
                  onClick={() => this.handleModalShow(0, 0)}
                >
                  Close the Case
                </button>
              )}
            </div>
            {actionsStages?.map((x) => {
              let currentStageDetails = !isEmpty(stageDetails)
                ? stageDetails.filter(
                    (item) => item.ActionStageId === x.ActionStageId
                  )
                : [];
              return (
                <>
                  <div className="action-stage" key={x.ActionStageId}>
                    <span
                      className={classnames({
                        "action-stage__icon flex": true,
                        "current-stage":
                          !x.Checked &&
                          x.ActionStageId === CurrentActionStageId + 1,
                        "checked-stage": x.Checked,
                      })}
                    >
                      {x.Checked ? (
                        <FaCheck />
                      ) : x.ActionStageId === CurrentActionStageId + 1 ? (
                        <FaArrowRight />
                      ) : (
                        <FaQuestion />
                      )}
                    </span>
                    <div className="action-stage__text-btn">
                      <span className="action-stage__name">{x.StageName}</span>
                      <div className="flex">
                        {x.ActionStageId >= CurrentActionStageId &&
                          (!x.Checked ? (
                            <>
                              <button
                                className="btn action-stage__btn"
                                type="button"
                                onClick={() =>
                                  x.ActionStageId === 1
                                    ? this.handleModalShow(x.ActionStageId, 0)
                                    : this.handleMoveToNextStage(x)
                                }
                              >
                                {x.ActionStageId === 1
                                  ? "Add councelling note"
                                  : "Move to this stage"}
                              </button>
                            </>
                          ) : (
                            <>
                              {isEmpty(x.SubStages) &&
                              isEmpty(
                                stageDetails.find(
                                  (stage) =>
                                    stage.ActionStageId === x.ActionStageId
                                )
                              ) ? (
                                <button
                                  className="btn action-stage__btn"
                                  type="button"
                                  onClick={() =>
                                    this.handleModalShow(x.ActionStageId, 0)
                                  }
                                >
                                  Add {x.StageName.toLowerCase()} Note
                                </button>
                              ) : (
                                x.ActionStageId !== 4 &&
                                x.SubStages.map((subStage) => {
                                  return isEmpty(
                                    stageDetails.find(
                                      (stage) =>
                                        stage.ActionSubStageId ===
                                        subStage.ActionSubStageId
                                    )
                                  ) ? (
                                    <button
                                      className="btn action-stage__btn"
                                      type="button"
                                      onClick={() =>
                                        this.handleModalShow(
                                          x.ActionStageId,
                                          subStage.ActionSubStageId
                                        )
                                      }
                                    >
                                      {subStage.SubStageName}
                                    </button>
                                  ) : null;
                                })
                              )}
                              {x.ActionStageId === 3 ||
                              x.ActionStageId === 4 ? (
                                <button
                                  className="btn action-stage__btn"
                                  type="button"
                                  onClick={() =>
                                    this.handleSendEmailShow(x.ActionStageId)
                                  }
                                >
                                  Send Email
                                </button>
                              ) : null}
                            </>
                          ))}
                      </div>
                    </div>
                  </div>
                  {x.Checked &&
                    !isEmpty(currentStageDetails) &&
                    currentStageDetails?.map((stageDetail) => {
                      return (
                        <DisciplinaryStageDetails
                          isCaseClosed={isCaseClosed}
                          stageDetails={stageDetail}
                          handleCommentModalShow={this.handleCommentModalShow}
                        />
                      );
                    })}
                </>
              );
            })}
          </div>
        </div>
        {isModalShow ? (
          <ActionForm
            formData={currentStageFormData}
            isModalShow={isModalShow}
            closeModal={this.closeModal}
            employeeList={employeeList}
            disciplinaryCaseDetails={disciplinaryCaseDetails}
            actionsStages={actionsStages}
          />
        ) : null}
        {/* {isSendEmailShow ? (
          <Modal
            title="Write an email to Chetana Sharma"
            open={isSendEmailShow}
            onModalClose={this.closeEmailModal}
            type=""
            className="modal-recruit-email"
          >
            <ComposeEmail />
          </Modal>
        ) : null} */}

        <ComposeEmail
          title="Write an email to Chetana Sharma"
          open={isSendEmailShow}
          handleModal={this.closeEmailModal}
        />

        <Loader open={spinnerLoading} />
        {isCommentModalShow ? (
          <AddCommentsForm
            isCommentModalShow={isCommentModalShow}
            closeCommentModal={this.closeCommentModal}
            ActionId={selectedActionId}
            CaseId={disciplinaryCaseDetails.DisciplinaryCaseId}
          />
        ) : null}
      </>
    );
  }
}

DisciplinaryAction = connect(
  (state) => ({
    employeeList: state.commonReducer.employeeListSelect,
    // employeeListWithPhoto: state.commonReducer.employeeWithPhotos,
    disciplinaryCaseDetails:
      state.disciplinaryActionReducer.disciplinaryCaseDetails,
    spinnerLoading: state.disciplinaryActionReducer.spinnerLoading,
  }),
  {
    GetEmployeeList,
    // GetAllEmployeeListWithPhoto,
    getDisciplinaryCaseDetails,
    DisplayClientSideMessage,
  }
)(DisciplinaryAction);
export default DisciplinaryAction;
