import React from "react";
import PropTypes from "prop-types";
import {otherInputInterface} from 'ts/interfaces/formInterface'

const SelectInput = (props:otherInputInterface): JSX.Element => {
  const {
    name,
    type,
    value,
    autoFocus,
    disabled,
    placeholder,
    options,
    innerLabel
  } = props;

  const handleChange = (event) => {
    const { name, value } = event.target;
    props.onChange && props.onChange(name, value);
  };

  const handleKeyUp = (event) => {
    const { name, value } = event.target;
    props.onKeyUp && props.onKeyUp(event);
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    props.onBlur && props.onBlur(name, value);
  };
  const handleFocus = (event) =>{
    if (type === 'number'){
      event.target.select();
    }
  }
  return (
    <div className="genericForm-group__select-container">
      {
        innerLabel && <div className="genericForm-group__inner-label">
          {innerLabel}
        </div>
      }
        <select 
            onChange={handleChange}
            value={value}
            name={name} 
            className={'genericForm-group__select'}
            onBlur={handleBlur}
            disabled={disabled}
            id={name}>
            <option value="">Choose an option</option>
            {
                options.map(item=>{
                return <option value={item.value}>{item.label}</option>
                })
            }
        </select>
        <div className="custom-arrow"></div>
    </div>
  );
};

SelectInput.defaultProps = {
  name: "",
  innerLabel:null
};

export default SelectInput;
