export const agSettings = {
  domLayout: "autoHeight",
  defaultColDef: {
    width: 145,
    resizable: true,
  },
};

export const initialFilters = {
  pageIndex: 0,
  pageSize: 10,
  name: "",
  destination: "",
  type: -1,
  category: -1,
};

export const initialHeader = [
  // { field: "ID", cellRenderer: "CustomCheck", width: 80 },
  {
    field: "Name",
    headerName: "Name",
    pinned: "left",
    // rowDrag: true,
  },
  {
    field: "TravelOrder",
    headerName: "Travel Order",
    pinned: "left",
    cellRenderer: "RowLink",
  },
  {field: "OrderDate", headerName: "Order Date", cellRenderer: "OrderDate"},
  {field: "RequestApprovedBy", headerName: "Approved by"},
  {
    field: "TravelType",
    headerName: "Type",
  },
  {
    field: "Destination",
    headerName: "Destinations",
  },
  {field: "TravelCategory", headerName: "Travel Category"},
  {field: "ModeOfTravel", headerName: "Mode of Travel"},
  {
    field: "TravelDate",
    headerName: "Dates",
    width: 180,
    wrapText: true,
  },
  {field: "Totaldays", headerName: "Days", width: 80},
  {field: "PurposeOfVisit", headerName: "Puropse"},
  {
    field: "Advance",
    headerName: "Advance",
    width: 120,
    cellRenderer: "PaymentInRupees",
  },
];

export const finalHeaders = [
  {
    field: "Total",
    headerName: "Total",
    width: 120,
    type: "rightAligned",
    cellRenderer: "PaymentInRupees",
  },
  {field: "SettlementApprovedBy", headerName: "Approved By"},
  {
    field: "NetPayable",
    headerName: "Net Payable",
    width: 120,
    cellRenderer: "PaymentInRupees",
  },
];

export const export_name = "Advance Travel Request Report";
