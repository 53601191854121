import React from "react";
import '../style.scss';
import { connect } from 'react-redux';
import { currencyCommaSeperator } from '../../../helpers/currencyFormatter';

function AreaDetails({ homeLoanDetail }) {
  return (
    <div className="homeloan-view-employeedetails healthCard__health mt-md">
      <div className="healthCard-heading">
        <h4>Area</h4>
      </div>
      <div className="healthCard__health__details">
        <div className="placement-details">
          <div className="placement-details__block">
            <div className="placement-details__table">
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Land Area
                </div>
                <div className="placement-details__col placement-val">
                  {homeLoanDetail?.LandArea}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Kitta Number
                </div>
                <div className="placement-details__col placement-val">
                  {homeLoanDetail?.KittaNumber}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Building Sq. feet
                </div>
                <div className="placement-details__col placement-val">
                  {homeLoanDetail?.BuildingArea}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Storeys
                </div>
                <div className="placement-details__col placement-val">
                  {homeLoanDetail?.NumberofStoreys}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Estimated Value
                </div>
                <div className="placement-details__col placement-val">
                  {currencyCommaSeperator(homeLoanDetail?.EstimatedValue)}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Construction Stage
                </div>
                <div className="placement-details__col placement-val">
                  {homeLoanDetail?.ConstructionStageValue}
                </div>
              </div>
            </div>
          </div>
          </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  homeLoanDetail: state.loanReducer.homeLoanDetail,
});

export default connect(mapStateToProps, {})(AreaDetails);