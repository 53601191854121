import React, { Component } from "react";
// import JobRequisitionForm from "./jobRequisitionForm";
import JobRequisitionForm from "./requisitionForm";
import { connect } from "react-redux";
import { getRequisitionNumber, getJobRequisitionDetails } from "./action";
import CommonHeaderSection from "./commonHeaderSection";
import isEmpty from "isEmpty";

export class JobRequisition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabId: 0,
      isEditPage: false,
      isViewPage: false,
      requisitionId: 0,
      title: "New Job Requisition",
    };
  }
  componentDidMount() {
    if (!isEmpty(this.props.location.state)) {
      const selectedTabId = this.props.location.state.selectedTabId;
      const isEditPage = !isEmpty(this.props.location.state.isEditPage)
        ? this.props.location.state.isEditPage
        : false;
      const isViewPage = !isEmpty(this.props.location.state.isViewPage)
        ? this.props.location.state.isViewPage
        : false;
      const requisitionId = !isEmpty(this.props.location.state.requisitionId)
        ? this.props.location.state.requisitionId
        : 0;
      const title = !isEmpty(this.props.location.state.title)
        ? this.props.location.state.title
        : "New Job Requisition";
      this.setState({
        selectedTabId,
        isEditPage,
        isViewPage,
        requisitionId,
        title,
      });

      if (isEditPage || isViewPage) {
        //this.props.getJobRequisitionDetails(requisitionId);
      }
      // if (!isEditPage && !isViewPage) {
      //  // this.props.getRequisitionNumber();
      // }
    }
  }

  renderBody() {
    let {
      loading,
    } = this.props.jobRequisitionReducer;


    return (
      <div>
        {!loading ? (<JobRequisitionForm
          isEditPage={this.state.isEditPage}
          isViewMode={this.state.isViewPage}
          title={this.state.title}

        />) : null}


      </div>
    );
  }

  render() {
    const selectedTabId = !isEmpty(this.props.location.state)
      ? this.props.location.state.selectedTabId
      : null;
    return (
      <CommonHeaderSection
        renderBody={this.renderBody()}
        selectedTabId={selectedTabId}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  jobRequisitionReducer: state.jobRequisitionReducer,
});

export default connect(mapStateToProps, {
  getRequisitionNumber,
  getJobRequisitionDetails,
})(JobRequisition);
