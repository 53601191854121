import {DisplayClientSideMessage} from "actions/commonAction";
import {RoleId} from "constants/userDetails";
import {getBaseAxios} from "helpers/constants";
import isEmpty from "isEmpty";
import React from "react";
import Avatar from "react-avatar";
import {FaRegFilePdf} from "react-icons/fa";
import {IoIosCheckmarkCircle} from "react-icons/io";
import {MdClose, MdError} from "react-icons/md";
import {connect} from "react-redux";
import CommonCommentsHistory from "../../../commonLayout/commentsHistory";
import TravelInformationTab from "../../../commonLayout/travelInformationTab";
import {
  fetchTravelInformationData,
  getAllowanceAndExpensesList,
  handleSettlementReportActive,
  saveSettlementReportDetails,
} from "../../actions";
import "../../common/travelSettlement.scss";
import {validateForm} from "../../common/validateTravelInformationForm";
import {getTravelInformationData} from "../../pendingSettlement/form/getTravelInformationData";
import BillsAndClaims from "./billsAndClaims";
import {getSettlementReportSaveData} from "./getSettlementReportSaveData";
import TravelInformationHistory from "./travelInformationHistory";
import "./travelSettlementReport.scss";
import ViewTravelInformation from "./viewTravelInformation";
import {getUploadPath} from "constants/userDetails";

function createTravelDestination() {
  return {
    selectedDistrict: [],
    placeName: null,
    selectedModeOfTransport: [],
    startDate: null,
    endDate: null,
    startDateEng: null,
    endDateEng: null,
    days: 0,
    nights: 0,
    hostName: null,
    selectedHost: [],
    hotelName: null,
    selectedHotel: [],
    phoneNumber: "",
  };
}
export class TravelSettlementReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      travelInformationState: {
        travelRequestId: 0,
        travelTypeId: 1,
        destinationTypeId: 1,
        selectedTravelDestList: [{key: 0, travelDestination: createTravelDestination()}],
        selectedCountry: null,
        selectedCurrency: null,
        reason: "",
      },
      isEditDataFetched: false,
      isAllRequiredFieldValid: true,
      isViewMode: true,
      submitStatusId: this.props.statusId,
      settlementId: this.props.settlementId,
      isEditTravelinformation: false,
      categoryId: this.props.settlementReport.value.CategoryId,
    };
    this.commonComments = "";
  }
  componentDidMount() {
    this.props.fetchTravelInformationData(2);
  }
  componentDidUpdate() {
    let {isEditDataFetched} = this.state;
    let {isLoading, statusId} = this.props;
    console.log("status id", statusId);

    if (!isLoading && !isEditDataFetched) {
      let {travelInformationState, newSelectedTravelDestList} = getTravelInformationData(
        this.state,
        this.props
      );
      this.getOverAllDates(newSelectedTravelDestList);
      this.setState({
        isEditDataFetched: true,
        travelInformationState,
        statusId,
      });
    }
  }
  getOverAllDates = (selectedTravelDestList) => {
    let overAllDestinationFromDate = null;
    let overAllDestinationToDate = null;
    if (!isEmpty(selectedTravelDestList)) {
      let selectedTravelDestLength = selectedTravelDestList.length;

      overAllDestinationFromDate = !isEmpty(
        selectedTravelDestList[0].travelDestination.startDateEng
      )
        ? selectedTravelDestList[0].travelDestination.startDateEng
        : null;
      overAllDestinationToDate = !isEmpty(
        selectedTravelDestList[selectedTravelDestLength - 1].travelDestination.endDateEng
      )
        ? selectedTravelDestList[selectedTravelDestLength - 1].travelDestination
            .endDateEng
        : null;
    }

    this.setState({
      overAllDestinationFromDate,
      overAllDestinationToDate,
    });
  };
  handleTravelInfoTabState = (name, value, isDestinationDateChanged) => {
    let {travelInformationState} = this.state;
    travelInformationState[name] = value;
    this.setState({travelInformationState});

    if (isDestinationDateChanged) {
      this.getOverAllDates(value);
    }
  };
  onInformationEdit = () => {
    this.setState({
      isEditTravelinformation: true,
      isViewMode: false,
    });
  };
  handleCategoryChange = (name, selected) => {
    this.setState({categoryId: selected.value});
    const categoryId = !isEmpty(selected) ? parseInt(selected.value) : -1;
    let {selectedTravelDestList} = this.state.travelInformationState;
    const totalDays = selectedTravelDestList.reduce(
      (a, b) => a + (parseInt(b["travelDestination"]["days"]) || 0),
      0
    );
    const totalNights = selectedTravelDestList.reduce(
      (a, b) => a + (parseInt(b["travelDestination"]["nights"]) || 0),
      0
    );
    this.props.getAllowanceAndExpensesList({
      categoryId,
      fromDate: this.state.overAllDestinationFromDate,
      toDate: this.state.overAllDestinationToDate,
      totalDays: totalDays,
      totalNights: totalNights,
    });
  };

  handleSaveSettlement = async () => {
    const roleId = RoleId();
    let {travelInformationState, isEditTravelinformation, statusId} = this.state;
    const isAllRequiredFieldValid = await validateForm({
      travelInformationState,
      isAsPlanned: !isEditTravelinformation,
    });

    this.setState({isAllRequiredFieldValid: isAllRequiredFieldValid});
    let errors = {};
    let approverData = this.commonComments.onApproveReturn(statusId);
    debugger;
    if (
      (!approverData || (approverData.ApprovalEmployeeId === 0 && roleId !== 1)) &&
      this.state.submitStatusId != 15 //15= final state
    ) {
      errors.employee = "Required";
      this.setState({errors});
    }
    if (isAllRequiredFieldValid && isEmpty(errors)) {
      let data = getSettlementReportSaveData(this.props, this.state, approverData);
      data.TravelSettlementApprovalFlowHistoryDto = approverData;
      data.getSettlementRequest = this.props.getSettlementRequest;
      console.log("saveSettlementReportDetails", data);
      debugger
      await this.props.saveSettlementReportDetails(data);
      // if (this.props.isSettlementSaveSuccess) this.props.getSettlementRequest();
    } else {
      let message = {
        MessageType: "danger",
        Message: "You cannot save without filling all required fields.",
      };
      this.props.DisplayClientSideMessage(message);
    }
  };
  render() {
    let {travelType, destination, approver, isEditTravelinformation} = this.state;
    let {handleSettlementReportActive, settlementAuditLog, settlementReport} = this.props;

    return (
      <div>
        <div className="settlement-form">
          <div className="main-header">
            <div className="form-header">
              <span className="header-title">Travel Settlement Report</span>
              <div className="sub-header">
                <Avatar
                  className="user-image"
                  size={70}
                  round={true}
                  src={
                    // getBaseAxios() +
                    settlementReport.value &&
                    getUploadPath(settlementReport.value.EmployeeImagePath)
                  }
                  alt={settlementReport.value.RequestedBy}
                  name={settlementReport.value.RequestedBy}
                />
                {/* <img
                  src={
                    getBaseAxios() + settlementReport.value.EmployeeImagePath
                  }
                  className="user-image"
                /> */}
                <div className="pl-md">
                  <span className="header-name">
                    {settlementReport.value.RequestedBy}
                  </span>
                  <span className="header-date">
                    {!isEmpty(settlementReport.value.LastPlace) &&
                      settlementReport.value.LastPlace + " : "}
                    {settlementReport.value.TravelStartDate}
                    {!isEmpty(settlementReport.value.TravelEndDate) &&
                      " - " + settlementReport.value.TravelEndDate}
                  </span>
                </div>
              </div>
            </div>
            <div className="form-close">
              <MdClose onClick={() => handleSettlementReportActive(false)} />
            </div>
          </div>

          {isEditTravelinformation ? (
            <div className="section">
              <div className="information-section section edit-information">
                <div className="form-close">
                  <MdClose
                    onClick={() =>
                      this.setState({
                        isEditTravelinformation: false,
                      })
                    }
                  />
                </div>
                <TravelInformationTab
                  isAllRequiredFieldValid={this.state.isAllRequiredFieldValid}
                  travelInformationState={this.state.travelInformationState}
                  createTravelDestination={createTravelDestination()}
                  handleTravelInfoTabState={(name, value, isDestinationDateChanged) =>
                    this.handleTravelInfoTabState(name, value, isDestinationDateChanged)
                  }
                />
              </div>
            </div>
          ) : (
            <div className="first-section section">
              <div className="section__header">
                <span>Travel information</span>
              </div>
              <div className="section__body">
                <div className="form-row" style={{width: "100%"}}>
                  {settlementReport.IsTravelAsPlan ? (
                    <div className="no-change">
                      <IoIosCheckmarkCircle className="checked" />
                      <span>
                        The information is as approved, no changes have been made post
                        approval in travel information
                      </span>
                    </div>
                  ) : (
                    <div className="info-changed">
                      <MdError className="changed-icon" />
                      <span>
                        The information has been changed during settlement request
                      </span>
                    </div>
                  )}

                  <div>
                    <button
                      type="button"
                      className="btn btn-grey"
                      onClick={() => this.onInformationEdit()}
                    >
                      Edit
                    </button>
                  </div>
                </div>
                <div className="dest-approver-history">
                  <div className="form-row">
                    {settlementReport.loading ||
                    isEmpty(settlementReport.value) ? null : (
                      <ViewTravelInformation settlementReport={settlementReport.value} />
                    )}
                  </div>

                  {settlementAuditLog.loading ||
                  isEmpty(settlementAuditLog.value) ? null : (
                    <TravelInformationHistory
                      settlementAuditLog={settlementAuditLog.value}
                    />
                  )}
                </div>
                <div className="form-row">
                  <div className="return-details">
                    <span className="return-details__header">
                      Returned and joined office on
                    </span>
                    <span className="return-date">
                      {settlementReport.value.ReturnDate}
                    </span>
                    <span className="return-status">
                      was {settlementReport.value.ReturnDateStatus}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="second-section section">
            <div className="section__header">
              <span>Travel summary</span>
            </div>
            <div className="section__body">
              <div
                className="form-row summary-text"
                dangerouslySetInnerHTML={{
                  __html: settlementReport.value.TravelSummary,
                }}
              ></div>
              <div className="form-row">
                {!isEmpty(settlementReport.value) &&
                  settlementReport.value.SettlementJobDocuments.map((x) => {
                    return (
                      <div className="summary-doc flex">
                        <span className="document-icon">
                          <FaRegFilePdf />
                        </span>
                        <label className="document-name">{x.OriginalName}</label>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="third-section section">
            <div className="section__header">
              <span>Bills and Cliams</span>
            </div>
            <div className="section__body">
              <BillsAndClaims
                isViewMode={this.state.isViewMode}
                handleCategoryChange={this.handleCategoryChange}
                settlementReport={settlementReport.value}
              />
            </div>
          </div>
          <div className="third-section section">
            <div className="section__header">
              <span>Your comment</span>
            </div>
            <div className="section__body">
              {/* <div className="form-row">
                <TextareaAutosize
                  rows={6}
                  cols={200}
                  name="Comment"
                  className="comment"
                  placeholder="If you have any comment type here"
                />
              </div> */}
              <CommonCommentsHistory
                childRef={(ref) => (this.commonComments = ref)}
                RequestId={this.state.settlementId}
                StatusId={this.state.submitStatusId}
                approvalAuthorities={this.props.approvalAuthorities}
                approvalHistory={this.props.approvalHistory}
                isEditPage={true}
                isViewMode={false}
                pendingSettlement={true}
              />
            </div>
          </div>
          <div className="fourth-section  footer-section">
            <div className="form-row">
              <button
                className="btn btn-del"
                onClick={() => handleSettlementReportActive(false)}
              >
                Cancel
              </button>
              {!isEmpty(this.commonComments) ? (
                this.commonComments.state.isApprove ? (
                  <button className="btn btn-green" onClick={this.handleSaveSettlement}>
                    Approve
                  </button>
                ) : (
                  <button className="btn btn-primary" onClick={this.handleSaveSettlement}>
                    Forward
                  </button>
                )
              ) : (
                <button className="btn btn-primary" onClick={this.handleSaveSettlement}>
                  Forward
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  fetchTravelInformationData,
  handleSettlementReportActive,
  getAllowanceAndExpensesList,
  DisplayClientSideMessage,
  saveSettlementReportDetails,
};

TravelSettlementReport = connect(
  (state) => ({
    travelCategoryList: state.travelInformationReducer.travelCategoryList,
    travelRequestDetails: state.travelSettlementReducer.settlementReport,
    countryList: state.commonReducer.countryList,
    currencyList: state.commonReducer.currencyList,
    districtList: state.commonReducer.districtList,
    modeOfTransportList: state.travelInformationReducer.modeOfTransportList,
    travelHostList: state.travelInformationReducer.travelHostList,
    travelHotelList: state.travelInformationReducer.travelHotelList,
    isLoading: state.travelInformationReducer.isLoading,
    approvalAuthorities: state.travelSettlementReducer.approvalAuthorities,
    approvalHistory: state.travelSettlementReducer.approvalHistory,
    settlementAuditLog: state.travelSettlementReducer.settlementAuditLog,
    settlementReport: state.travelSettlementReducer.settlementReport,
    otherExpenses: state.travelSettlementReducer.otherExpenses,
    billsAndClaimsList: state.travelSettlementReducer.billsAndClaims,
    isSettlementSaveSuccess: state.travelSettlementReducer.isSettlementSaveSuccess,
  }),
  mapDispatchToProps
)(TravelSettlementReport);

export default TravelSettlementReport;
