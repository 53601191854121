import FormGroup from "components/form";
import {ADMIN_ROLE_ID} from "constants/constants";
import {RoleId} from "constants/userDetails";
import isEmpty from "helpers/isEmpty";
import React, {Component} from "react";
import {connect} from "react-redux";
import DelegationTable from "./delegationTable";
import EmployeeSearch from "components/common/employeeSearch";

export class Create_DelegationTab extends Component {
  handleDropdown = (name, selected) => {
    const {delegationList} = this.props.delegationState;
    let newDelegationList = [];

    delegationList.map((item, index) => {
      newDelegationList.push({
        ApprovalTypeId: item.ApprovalTypeId,
        ApprovalType: item.ApprovalType,
        SubstituteId: selected && selected.value ? parseInt(selected.value) : null,
        SubstituteName: selected.label,
        IsDelegate: item.IsDelegate,
        IsChangeClicked: item.IsChangeClicked,
      });
    });

    this.props.handleDelegationTabState &&
      this.props.handleDelegationTabState(name, selected);
    this.props.handleDelegationTabState &&
      this.props.handleDelegationTabState("delegationList", newDelegationList);
    this.props.handleDelegationTabState &&
      this.props.handleDelegationTabState("isSubstituteSelected", true);
  };

  handleChange = (id, isChangeClicked) => {
    this.handleDelegationChange("handleChange", id, null, true, isChangeClicked);
  };

  handleDelegate = (id, isDelegate) => {
    this.handleDelegationChange("handleDelegate", id, null, isDelegate);
  };

  handleDelegationTableDropdown = (id, selected) => {
    this.handleDelegationChange("handleDelegationTableDropdown", id, selected);
  };

  handleDelegationChange = (
    source,
    id,
    selected = null,
    isDelegate = true,
    isChangeClicked = false
  ) => {
    const {delegationList} = this.props.delegationState;
    let modifiedDelegationList = [];

    delegationList.map((m) => {
      if (m.ApprovalTypeId === id) {
        modifiedDelegationList.push({
          ApprovalTypeId: m.ApprovalTypeId,
          ApprovalType: m.ApprovalType,
          SubstituteId:
            source === "handleDelegationTableDropdown"
              ? parseInt(selected.value)
              : m.SubstituteId,
          SubstituteName:
            source === "handleDelegationTableDropdown"
              ? selected.label
              : m.SubstituteName,
          IsDelegate: source === "handleDelegate" ? isDelegate : m.IsDelegate,
          IsChangeClicked:
            source === "handleDelegationTableDropdown"
              ? false
              : source === "handleChange"
              ? isChangeClicked
              : m.IsChangeClicked,
        });
      } else {
        modifiedDelegationList.push(m);
      }
    });

    this.props.handleDelegationTabState &&
      this.props.handleDelegationTabState("delegationList", modifiedDelegationList);
  };

  render() {
    const {delegationList, selectedSubstitutePerson, isSubstituteSelected} =
      this.props.delegationState;
    const roleId = RoleId();
    const {
      employeeList,
      isViewMode,
      myDetail,
      selectedEmployee,
      isEditPage,
      requesterId,
    } = this.props;

    let filterEmployeeList = [];
    if (employeeList && employeeList.length > 0) {
      if (isEditPage && !isEmpty(requesterId)) {
        filterEmployeeList = employeeList.filter((x) => x.value !== requesterId);
      } else if (
        roleId === ADMIN_ROLE_ID &&
        selectedEmployee &&
        !isEmpty(selectedEmployee.value)
      ) {
        filterEmployeeList = employeeList.filter(
          (x) => x.value !== selectedEmployee.value
        );
      } else if (myDetail && !isEmpty(myDetail.value)) {
        filterEmployeeList = employeeList.filter((x) => x.value !== myDetail.value);
      }
    }
    return (
      <div className="travel-delegation-create">
        <div className="travel-create__title">
          <h3>Delegation</h3>
        </div>
        <div className="travel-create__body">
          <div className="travel-field" style={{margin: "0"}}>
            {/* <label>Select your substitute</label> */}
            {/* <FormGroup
              formName="reactselect"
              name="selectedSubstitutePerson"
              placeholder="select a person"
              value={selectedSubstitutePerson}
              options={filterEmployeeList.map((x) => {
                return {label: x.FullName, value: x.EmployeeId};
              })}
              onChange={this.handleDropdown}
              disabled={isViewMode}
              width="250px"
            /> */}
            <EmployeeSearch
              onChange={(name, selected) => this.handleDropdown(name, selected)}
              // onChange={(name, selected) =>
              //   handleDelegationTableDropdown(
              //     props,
              //     row.ApprovalTypeId,
              //     name,
              //     selected
              //   )
              // }
              name="selectedSubstitutePerson"
              value={selectedSubstitutePerson}
              placeholder="Search (3 letters..) and Select"
              disabled={isViewMode}
              label="Select your substitute"
              width={250}
            />
          </div>
          <div className="delegation-table">
            <DelegationTable
              data={delegationList}
              hasActions={isSubstituteSelected}
              handleChange={(id, isChangeClicked) =>
                this.handleChange(id, isChangeClicked)
              }
              handleDelegate={(id, isDelegate) => this.handleDelegate(id, isDelegate)}
              handleDelegationTableDropdown={(id, selected) =>
                this.handleDelegationTableDropdown(id, selected)
              }
              employeeList={employeeList}
              isViewMode={isViewMode}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeList: state.commonReducer.employeeList,
  myDetail: state.commonReducer.myDetail,
});

export default connect(mapStateToProps, null)(Create_DelegationTab);
