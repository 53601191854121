import {
  GET_OVERTIME_REQUEST
} from 'actions/types';

const initialState = {
    overtimeRequest:null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_OVERTIME_REQUEST:
      return {
          ...state,
          overtimeRequest: action.payload
      };
    default:
      return state;
  }
}