import React from "react";
import ContentLoader from "react-content-loader";

interface CardSkeleton {
  foregroundColor: string;
  backgroundColor: string;
  width?: string;
  height?: string;
}

const CardSkeleton = (props: CardSkeleton) => {
  const tabRowData = [0, 1, 2, 3, 4];
  const { backgroundColor, foregroundColor, width, height } = props;
  // console.log(props)
  return (
    <ContentLoader
      speed={2}
      width={width ? width : "100%"}
      height={height ? height : "100%"}
      viewBox="0 0 800 550"
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
      {...props}
    >
      <rect x="0" y="29" rx="3" ry="3" width="800" height="2" />
      <rect x={36} y={58} rx="3" ry="3" width="400" height="15" />
      <rect x={640} y={58} rx="3" ry="3" width="50" height="20" />
      <rect x={700} y={58} rx="3" ry="3" width="60" height="20" />
      <rect x="0" y="106" rx="3" ry="3" width="800" height="2" />
      {/* <rect x="52" y="80" rx="3" ry="3" width="906" height="17" /> */}
      {/* <rect x="53" y="57" rx="3" ry="3" width="68" height="33" />
        <rect x="222" y="54" rx="3" ry="3" width="149" height="33" />
        <rect x="544" y="55" rx="3" ry="3" width="137" height="33" />
        <rect x="782" y="56" rx="3" ry="3" width="72" height="33" />
        <rect x="933" y="54" rx="3" ry="3" width="24" height="33" /> */}

      <rect x="0" y="29" rx="3" ry="3" width="2" height="480" />
      <rect x="798" y="29" rx="3" ry="3" width="2" height="480" />
      <rect x="0" y="509" rx="3" ry="3" width="800" height="2" />
      {tabRowData.map(TabRow)}
      {/* <TabRow x={0} y={115} cx={879} cy="123" />
        <TabRow x={1} y={176} cx={880} cy="184" />
        <TabRow x={2} y={234} cx={881} cy="242" />
        <TabRow x={3} y={295} cx={882} cy="303" />
        <TabRow x={4} y={355} cx={881} cy="363" />
        <TabRow x={5} y={416} cx={882} cy="424" />
        <TabRow x={6} y={476} cx={882} cy="484" /> */}
    </ContentLoader>
  );
};

const TabRow = (num, index) => {
  let xx = [130, 295, 621];
  let yy = 140;
  let yyy = [
    yy + num * 80 - 10,
    yy + num * 80 + 5,
    yy + num * 80 + 5,
    yy + num * 80 + 50,
  ];
  let cyy = yy + 80 * num + 10;
  let cxx = 740;
  let cx1 = 64;
  return (
    <>
      <circle cx={`${cx1}`} cy={cyy} r="30" />

      <rect
        x={`${xx[0] + num}`}
        y={`${yyy[0]}`}
        rx="3"
        ry="3"
        width="100"
        height="15"
      />
      <rect
        x={`${xx[0] + num}`}
        y={`${yyy[0] + 20}`}
        rx="3"
        ry="3"
        width="141"
        height="15"
      />
      <rect
        x={`${xx[1] + num}`}
        y={`${yyy[0]}`}
        rx="3"
        ry="3"
        width="200"
        height="15"
      />
      <rect
        x={`${xx[1] + num}`}
        y={`${yyy[0] + 20}`}
        rx="3"
        ry="3"
        width="299"
        height="15"
      />
      <rect
        x={`${xx[2] + num}`}
        y={`${yyy[0]}`}
        rx="3"
        ry="3"
        width="80"
        height="15"
      />
      <rect
        x={`${xx[2] + num}`}
        y={`${yyy[0] + 20}`}
        rx="3"
        ry="3"
        width="101"
        height="15"
      />

      {/* <rect
        x={`${xx[5]}`}
        y={`${yyy[3]}`}
        rx="3"
        ry="3"
        width="800"
        height="2"
      /> */}
    </>
  );
};

CardSkeleton.defaultProps = {
  backgroundColor: "#e4e5e6",
  foregroundColor: "#fff",
};

export default CardSkeleton;
