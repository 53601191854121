import React from "react";
import classnames from "classnames";
import Icons from "components/icons";

export const expandIcon = (drop) => {
  const iconClass = classnames({
    "table-row__icon": true,
    "table-row__icon-uncollapsed": drop,
  });
  return (
    <div className={iconClass}>
      <Icons name="AngleRight" />
    </div>
  );
};
