import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { IoIosCheckmarkCircle } from "react-icons/io";

interface Props {
  name: string;
  chipsArray: [];
  value: any;
  onChange: Function;
  multiple?: boolean;
  badgeColor?:string
}

function ChipsInput(props: Props) {
  const { chipsArray, name, value, multiple, badgeColor } = props;

  const handleChange = (newValue: any) => {
    var val = newValue;
    if(multiple){
      const isThere = Boolean(value.find((x) => x.label === newValue.label));
      val = value.length > 0 ? isThere ? value.filter(item=>item?.label !== newValue?.label) : [newValue,...value]: [newValue]
    }
    props.onChange && props.onChange(name, val);
  };
  const chipsClasses = (type: any) => {
    return classnames({
      badges: true,
      "mr-md": true,
      "left-icon": multiple
        ? value.length === 0 ? false : value.find((x) => x.label === type)
        : value === type,
      ["badges-"+badgeColor]: multiple
        ? value.length === 0 ? false : value.find((x) => x.label === type)
        : value === type,
    });
  };
  return (
    <div className="inline-form-group">
      {chipsArray &&
        chipsArray.map((chip: any, index) => {
          return (
            <div
              key={index}
              className={chipsClasses(chip.label)}
              onClick={() => handleChange(chip)}
            >
              {multiple ? (
                value && value.find((x) => x.value === chip.value) ? (
                  <IoIosCheckmarkCircle />
                ) : null
              ) : chip.label == value ? (
                <IoIosCheckmarkCircle />
              ) : null}
              {chip.label}
            </div>
          );
        })}
    </div>
  );
}

ChipsInput.defaultProps = {
  name: "",
  chipsArray: [],
  value: null,
  badgeColor:'primary'
};

export default ChipsInput;
