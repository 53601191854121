const LoanType = {
  1: "Consumer Loan",
  2: "Social Loan",
};

const currencyCommaSeperator = (num) => {
  if (isNaN(num)) return "0";
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

const formatNumber = (params) => {
    return (
      Math.floor(params)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + ".00"
    );
  };
const formatNumberShort = (params) => {
    return (
      Math.floor(params)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  };

export {LoanType, currencyCommaSeperator, formatNumber, formatNumberShort};