import axios from "axios";
import reject from "store/reducers/recruitment/reject";
import { baseURL } from "../constants/urls";
import { dispatchMessage } from "../helpers/dispatchMessage";

const setHeaders = () => {
  // Token
  const token = localStorage.getItem("AuthToken");
  return {
    headers: {
      Authorization: token,
    },
  };
};

const actionBase = axios.create({ baseURL: baseURL });

export const get = (
  url,
  dispatch,
  param = null,
  showResponseMessage = false
) => {
  return new Promise((resolve, reject) => {
    const fullUrl = getFullStringUrl(url, param);
    actionBase
      .get(fullUrl, setHeaders())
      .then((res) =>
        onSuccess(res, dispatch, resolve, reject, showResponseMessage)
      )
      .catch((err) => onFailure(err, dispatch, reject));
  });
};

export const get_by_id = (url, dispatch, id, showResponseMessage = false) => {
  return new Promise((resolve, reject) => {
    const fullUrl = `${url}/${id}`;
    actionBase
      .get(fullUrl, setHeaders())
      .then((res) =>
        onSuccess(res, dispatch, resolve, reject, showResponseMessage)
      )
      .catch((err) => onFailure(err, dispatch, reject));
  });
};

export const post = (
  url,
  dispatch,
  param,
  showResponseMessage = true,
  isMultipart = false
) => {
  let headers = setHeaders();
  if (isMultipart) {
    headers["content-type"] = "multipart/form-data";
  }

  return new Promise((resolve, reject) => {
    actionBase
      .post(url, param, headers)
      .then((res) =>
        onSuccess(res, dispatch, resolve, reject, showResponseMessage)
      )
      .catch((err) => onFailure(err, dispatch, reject));
  });
};

export const put = (
  url,
  dispatch,
  param,
  showResponseMessage = true,
  isMultipart = false
) => {
  return new Promise((resolve, reject) => {
    let headers = setHeaders();
    if (isMultipart) {
      headers["content-type"] = "multipart/form-data";
    } else {
      headers["content-type"] = "application/json";
    }
    actionBase
      .put(url, param, headers)
      .then((res) =>
        onSuccess(res, dispatch, resolve, reject, showResponseMessage)
      )
      .catch((err) => onFailure(err, dispatch, reject));
  });
};

export const put_inline_param = (
  url,
  dispatch,
  param,
  showResponseMessage = true
) => {
  return new Promise((resolve, reject) => {
    const fullUrl = getFullStringUrl(url, param);

    actionBase
      .put(fullUrl, null, setHeaders())
      .then((res) =>
        onSuccess(res, dispatch, resolve, reject, showResponseMessage)
      )
      .catch((err) => onFailure(err, dispatch, reject));
  });
};

export const deletion = (url, dispatch, id, showResponseMessage = true) => {
  return new Promise((resolve, reject) => {
    const fullUrl = `${url}/${id}`;
    actionBase
      .delete(fullUrl, setHeaders())
      .then((res) =>
        onSuccess(res, dispatch, resolve, reject, showResponseMessage)
      )
      .catch((err) => onFailure(err, dispatch, reject));
  });
};

// export const deletion_with_param = (
//   url,
//   dispatch,
//   param,
//   showResponseMessage = true
// ) => {
//   return new Promise((resolve, reject) => {
//     actionBase
//       .delete(url, param)
//       .then((res) => onSuccess(res, dispatch, resolve, showResponseMessage))
//       .catch((err) => onFailure(err, dispatch, reject));
//   });
// };

// export const deletion_inline_param = (url, dispatch, param) => {
//   return new Promise((resolve, reject) => {
//     const fullUrl = getFullStringUrl(url, param);
//     actionBase
//       .delete()
//       .then((res) => onSuccess(res, dispatch, resolve))
//       .catch((err) => onFailure(err, dispatch, reject));
//   });
// };

const getFullStringUrl = (url, param) => {
  const entries = param ? Object.entries(param) : null;
  let fullUrl = url;
  entries &&
    entries.map((entry, ind) => {
      if (ind == 0) {
        fullUrl = `${fullUrl}?${`${entry[0]}=${entry[1]}`}`;
      } else {
        fullUrl = `${fullUrl}&${`${entry[0]}=${entry[1]}`}`;
      }
    });
  return fullUrl;
};

const onSuccess = (res, dispatch, resolve, reject, showResponseMessage) => {
  const response = res.data;
  if (response.Status == true) {
    if (showResponseMessage) {
      dispatchMessage(dispatch, response);
    }
    resolve(response);
  } else if (response.Status == undefined) {
    if (res.status == 200) {
      resolve(response);
    } else {
      dispatchMessage(dispatch, "Response status is not 200");
      reject(response);
    }
  } else {
    dispatchMessage(dispatch, response);
    reject(response);
  }
};

const onFailure = (err, dispatch, reject) => {
  dispatchMessage(dispatch, err.message);
  reject(err);
};
