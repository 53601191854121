import classnames from "classnames";
import FormGroup from "components/form";
import isEmpty from "isEmpty";
import React, { useState } from "react";
import { FaRegFilePdf, FaTrash } from "react-icons/fa";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import AddDocument from "./addDocument";
import { formatedMonthYear, inputWidth } from "./constants";
import { OneDatePicker } from "./pickers";
import { validateEducation } from "./validation";

function Education({
  propsData,
  data,
  isMandatory,
  onChange,
  onFileSelectionList,
  onChangeList,
  onNewList,
  onConfirmList,
  onRemoveList,
  onEditList,
  onDeleteList,
  blockError,
}) {
  const listName = "educations";
  const title = "education";

  const [errors, setErrors] = useState(null);

  const { countryList, educationLevelList } = propsData;

  let selectedEducation = data.find((f) => f.isSelected);
  let unselectedEducation = data.filter((f) => !f.isSelected);

  const inputclass = (error) => {
    return classnames({
      "form-group": true,
      "inline-form-group": true,
      inputWidth: inputWidth,
      error: error,
    });
  };

  const handleGradeTypeChange = (typeId) => {
    onChangeList(listName, selectedEducation.key, title, "gradeTypeId", typeId);
  };

  const handleFileSelection = async (file) => {
    await onFileSelectionList(listName, selectedEducation.key, title, file);
    if (!isEmpty(errors)) {
      checkValidation();
    }
  };

  const onTextChange = async (name,value) => {
    //const { name, value } = e.target;
    onChangeList(listName, selectedEducation.key, title, name, value);
    if (!isEmpty(errors)) {
      checkValidation();
    }
  };

  const onSelectChange = (name, value) => {
    onChangeList(listName, selectedEducation.key, title, name, value);
    if (!isEmpty(errors)) {
      checkValidation();
    }
  };

  const handleDateChange = (name, value) => {
    onChangeList(listName, selectedEducation.key, title, name, value);
    if (!isEmpty(errors)) {
      checkValidation();
    }
  };

  const handleNew = () => {
    const newKey = data.length + 1;
    onNewList(listName, title, newKey);
    setErrors(null);
  };

  const handleConfirmList = async () => {
    const isValidate = await checkValidation();
    if (!isMandatory || isValidate) {
      onConfirmList(listName, selectedEducation.key);
    }
  };

  const checkValidation = async () => {
    if (isMandatory) {
      let returnedData = await validateEducation(selectedEducation.education);
      setErrors(returnedData);
      if (isEmpty(returnedData)) {
        return true;
      } else {
        return false;
      }
    }
  };

  const renderCreateForm = () => {
    if (!isEmpty(selectedEducation)) {
      let {
        degreeName,
        qualificationLevel,
        institution,
        country,
        university,
        year,
        gradeTypeId,
        grade,
        majorSubject,
        file,
      } = selectedEducation.education;

      const gradeClasses = (typeId) => {
        return classnames({
          badges: true,
          "badges-rounded": true,
          "left-icon": gradeTypeId == typeId,
          "badges-primary": gradeTypeId == typeId,
          "badges-default": gradeTypeId != typeId,
        });
      };

      return (
        <>
          <div className="form-partition">
            <div className="form-block__form">
              <FormGroup
                name="degreeName"
                value={degreeName}
                label="Name of the Degree"
                onChange={onTextChange}
                className="inline-form-group"
                error={errors && errors.degreeName}
              />
              <FormGroup
                formName="reactselect"
                onChange={onSelectChange}
                name="qualificationLevel"
                value={qualificationLevel}
                options={educationLevelList}
                //width="300px"
                label="Qualification Level"
                error={errors?.qualificationLevel}
                className="inline-form-group"
              />

              <FormGroup
                name="institution"
                value={institution}
                label="Institution"
                onChange={onTextChange}
                className="inline-form-group"
                error={errors && errors.institution}
              />
              <FormGroup
                formName="reactselect"
                onChange={onSelectChange}
                name="country"
                value={country}
                options={countryList}
               // width="300px"
                label="Country"
                error={errors?.country}
                className="inline-form-group"
              />

              <FormGroup
                name="university"
                value={university}
                label="University"
                onChange={onTextChange}
                className="inline-form-group"
                error={errors && errors.university}
              />

              <OneDatePicker
                label="Year"
                format="yyyy/MM"
                views={["year", "month"]}
                value={year}
                onChange={(date) => handleDateChange("year", date)}
                error={errors && errors.year}
                errorClassName="error__message"
              />

              <div className={inputclass(errors && errors.grade)}>
                <label className="form-group__label">Grade</label>
                <div className="education-grade flex">
                  <div className="education-grade__chips flex">
                    <div
                      className={gradeClasses(1)}
                      onClick={() => handleGradeTypeChange(1)}
                    >
                      {gradeTypeId == 1 ? <IoIosCheckmarkCircle /> : null}
                      {"%"}
                    </div>

                    <div
                      className={gradeClasses(2)}
                      onClick={() => handleGradeTypeChange(2)}
                    >
                      {gradeTypeId == 2 ? <IoIosCheckmarkCircle /> : null}
                      {"GPA"}
                    </div>
                  </div>

                  <input
                    className="form-group__input education-grade__input"
                    value={grade}
                    name={"grade"}
                    onChange={(e) => onTextChange('grade', e.target.value)}
                  />
                </div>

                <div className="error__message">{errors && errors.grade}</div>
              </div>
              <FormGroup
                name="majorSubject"
                value={majorSubject}
                label="Major Subjects"
                onChange={onTextChange}
                className="inline-form-group"
                error={errors && errors.majorSubject}
              />
            </div>

            <div className="form-partition align-center">
              {!isEmpty(data) && (
                <AddDocument
                  text={"Add document"}
                  selectedFile={file}
                  handleFileSelection={handleFileSelection}
                  error={errors && errors.file}
                />
              )}
            </div>
          </div>
          <div className="detail-buttons">
            <button
              className="btn btn"
              onClick={() => onRemoveList(listName, selectedEducation.key)}
            >
              Cancel
            </button>
            <button
              className="btn btn-blue"
              onClick={() => handleConfirmList()}
            >
              Confirm
            </button>
          </div>
        </>
      );
    }
  };

  let blockRequiredText = blockError;
  if (data && data.length > 0) {
    blockRequiredText = "";
  }

  return (
    <div className="form-block">
      <div className="form-block__title title__add">
        <h3>Education</h3>

        {isEmpty(selectedEducation) ? (
          <div className="title__add-button__position">
            <button
              className="btn-outline btn-outline__black"
              onClick={() => handleNew()}
            >
              Add
            </button>
          </div>
        ) : null}

        <div className={inputclass(blockError)}>
          <div className="form-group__message">{blockRequiredText}</div>
        </div>
      </div>

      {renderCreateForm()}

      {unselectedEducation &&
        unselectedEducation.map((data, index) => {
          const education = data.education;

          return (
            <div className="form-block detail-block" key={index}>
              <div className="detail-left">
                <p className="detail-dark__text">{education.degreeName}</p>
                <p className="detail-light__text">{education.institution}</p>
                <p className="detail-light__text">{education.university}</p>

                {education.file && (
                  <div className="flex" style={{ marginTop: "10px" }}>
                    <span className="document-icon">
                      <FaRegFilePdf />
                    </span>
                    <label className="document-name">
                      {education.file.name}
                    </label>
                  </div>
                )}
              </div>
              <div className="detail-mid">
                <p className="detail-dark__text">
                  {education.qualificationLevel &&
                    education.qualificationLevel.label + " Degree"}
                </p>

                <span className="flex">
                  <span className="detail-light__text">
                    {education.year ? formatedMonthYear(education.year) : ""}
                  </span>

                  <span className="detail-light__text">
                    {education.grade ? ", " + education.grade : ""}
                  </span>

                  <span className="detail-light__text">
                    {education.grade &&
                    education.gradeTypeId &&
                    education.gradeTypeId == 1
                      ? " %"
                      : education.grade &&
                        education.gradeTypeId &&
                        education.gradeTypeId == 2
                      ? " GPA"
                      : " "}
                  </span>
                </span>

                <p className="detail-light__text">{education.majorSubject}</p>
              </div>
              <div className="detail-right">
                <span
                  onClick={() => onEditList(listName, data.key)}
                  className="change-btn btn-edit"
                >
                  <MdEdit />
                </span>
                <span
                  onClick={() => onDeleteList(listName, data.key)}
                  className="change-btn btn-delete"
                >
                  <FaTrash />
                </span>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default Education;
