import React from "react";
import TableSkeleton from './tableSkeleton';
import TableSkeletonMaterial from './tableSkeletonMaterial'
import CardSkeleton from './cardSkeleton';

interface Loading{
    loading?:boolean,
    name?:string
}

const Skeleton = (props:Loading) => {
    const { loading } = props;
    if(!loading){
        return null
    }
    const switchComponent = () => {
        const { name } = props;
        switch(name){
        case "table":
            return TableSkeleton
        case "custom-table":
            return TableSkeletonMaterial
        case "card":
            return CardSkeleton
        default :
            return null
        }
    }
    const Component = switchComponent();
    return(
        <div className="skeleton-loading">
            <Component {...props} />
        </div>
    ) 
};

export default Skeleton