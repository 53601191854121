import React, {useState,useEffect} from 'react';
import thumbsDown from 'assets/images/icons/thumbsDownColor.svg'
import thumbsUp from 'assets/images/icons/thumbsUpColor.svg'
import comment from 'assets/images/icons/commentBlue.svg'
import man from 'assets/images/man.png';
import Avatar from 'react-avatar';
import Icons from 'components/icons';
import FormGroup from 'components/form';
import Button from 'components/button';
import Modal from 'components/modal';
import classnames from 'classnames';
import './style.scss';


function Journal() {
    const [date,setDate] = useState('');
    const [open,setOpen] = useState(false);
    const onOpen = () => setOpen(true);
    const onClose = () => setOpen(false);
    return (
        <div className="journal">
            <div className="tertiary-title">
                <span>Employee Journal</span>
            </div>
            <div className="journal-container">
                <div className="journal-header">
                    <div className="journal-header__left">
                        <div className="journal-header__daterange">
                            <FormGroup 
                                formName="customdateinput"
                                isDateRange
                                value={date}
                                name="period"
                                onChange={(name,value)=>setDate(value)}
                                label="Period"
                            />
                        </div>
                        <div className="journal-header__link">
                            <span>Current Review Period</span>
                        </div>
                        <div className="journal-header__link">
                            <span>This Year</span>
                        </div>
                        <div className="journal-header__link">
                            <span>Last Year</span>
                        </div>
                    </div>
                    <div className="journal-header__right">
                        <Button 
                            title="Add Note"
                            bg="primary"
                            onClick={()=>onOpen()}
                        />
                        <Modal
                            open={open}
                            onModalClose={onClose}
                            title="Add Employee Journal Note"
                            className="journal-modal"

                        >
                            <JournalAdd onClose={onClose} />
                        </Modal>
                    </div>
                </div>
                <div className="journal-body">
                    <ul className="journal-list">
                        <JournalItem />
                        <JournalItem />
                        <JournalItem />
                    </ul>
                </div>
            </div>
        </div>
    )
}

const JournalItem = () =>{
    return(
        <li className="journal-item">
            <div className="journal-item__header">
                <div className="journal-item__left">
                    <div className="journal-item__avatar">
                        <Avatar src={man} size="35"  />
                    </div>
                    <div className="journal-item__name">
                        <span>Sumnima Shahi</span>
                    </div>
                    <div className="journal-item__date">
                        <span>Jan 3 2020</span>
                        <span>5:30 pm</span>
                    </div>
                </div>
                <div className="journal-item__right">
                    <div className="journal-item__action journal-item__edit">
                        <span className="icon">
                            <Icons name="Edit" justSVG />
                        </span>
                        <span className="name">
                            Edit
                        </span>
                    </div>
                    <div className="journal-item__action journal-item__delete">
                        <span className="icon">
                            <Icons name="Delete" justSVG />
                        </span>
                        <span className="name">
                            Delete
                        </span>
                    </div>
                </div>
            </div>
            <div className="journal-item__comment">
                <div className="journal-item__icon">
                    <img src={thumbsDown} />
                </div>
                <div className="journal-item__content">
                    <span>
                        Negative comments received from clinets about lack of following procedure for training, implement and support.
                    </span>
                </div>
            </div>
        </li>
    )
}

const iconName = {
    comments:'comments',
    up:'up',
    down:'down',
    active:'active'
}
const JournalAdd = (props) =>{
    const [date,setDate] = useState('');
    const [note,setNote] = useState('');
    const [active,setActive] = useState(iconName.comments);
    const iconClass = (name) => classnames('journal-add__icon',{
        'journal-add__icon-comment':iconName.comments ===name,
        'journal-add__icon-down':iconName.down ===name,
        'journal-add__icon-up':iconName.up ===name,
        'active':name===active
    })
    return(
        <div className="journal-add">
            <div className="journal-add__body">
                <div className="journal-add__upper">
                    <div className="journal-add__icons">
                        <div onClick={()=>setActive(iconName.comments)} className={iconClass(iconName.comments)}>
                            <img src={comment} alt="Comment Icon"/>
                        </div>
                        <div onClick={()=>setActive(iconName.up)} className={iconClass(iconName.up)}>
                            <img src={thumbsUp} alt="Comment Icon"/>
                        </div>
                        <div onClick={()=>setActive(iconName.down)} className={iconClass(iconName.down)}>
                            <img src={thumbsDown} alt="Comment Icon"/>
                        </div>
                    </div>
                    <div className="journal-add__date">
                        <FormGroup 
                            formName="customdateinput"
                            value={date}
                            alignment="horizontal"
                            name="Alert on"
                            onChange={(name,value)=>setDate(value)}
                            label="Period"
                        />
                    </div>
                </div>
                <div className="journal-add__lower">
                    <FormGroup 
                        formName="textarea"
                        value={note}
                        name="note"
                        onChange={(name,value)=>setNote(value)}
                        label="Note"
                    />
                </div>
            </div>
            <div className="journal-add__footer">
                <Button title="Save" bg="primary" onClick={()=>console.log('Saved')} /> 
                <Button title="Cancle" bg="subtle" onClick={()=>props.onClose()} /> 
            </div>
        </div>
    )
}

export default Journal
