import React, { Component } from 'react';
import './disciplinary.styles.scss';
import classnames from "classnames";
import shortid from "shortid";
import {MdCreate,MdAdd,MdDelete,MdClose,MdCheck} from 'react-icons/md';
import {    
    getDisciplinaryStages,
    updateDisciplinaryStages,
    deleteDisciplinarySubStages,
    updateDisciplinarySubStages,
    addDisciplinarySubStages
} from './action';
import {connect} from 'react-redux'
import isEmpty from 'isEmpty'

export class DisciplinaryStages extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             disciplinaryStages:[],
             editStage:'',
             editSub:'',
             addStage:"",
             subInput:'',
             stageInput:'',
             subStageInput:''
        }
        this._renderStage=this._renderStage.bind(this);
        this._renderSubStage=this._renderSubStage.bind(this);
        this.onEditStgae=this.onEditStgae.bind(this);
        this.onChange=this.onChange.bind(this);
        this.onKeyUp=this.onKeyUp.bind(this);
        this.onSubKeyUp=this.onSubKeyUp.bind(this);
        this.onSubChange=this.onSubChange.bind(this);
        this.onSubRemove=this.onSubRemove.bind(this);
        this.onSubEdit=this.onSubEdit.bind(this);
        this.OnSubStageAdd = this.OnSubStageAdd.bind(this)
        this.SubStageAdd = this.SubStageAdd.bind(this)
        this.onSubStageKeyUp=this.onSubStageKeyUp.bind(this)
    }
    async componentDidMount(){
        await this.props.getDisciplinaryStages();
        const {disciplinaryStages} = this.props;
        if(disciplinaryStages){
            this.setState({disciplinaryStages})
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const {disciplinaryStages} = this.props;
        if(!isEmpty(disciplinaryStages) && prevProps.disciplinaryStages !== disciplinaryStages){
            this.setState({disciplinaryStages})
        }
    }
    
    onEditStgae(id,name=''){
        this.setState({editStage:id,stageInput:name})
    }
    onSubStageKeyUp(e){
        const {value,name,id} = e.target;
        if(e.key === 'Enter'){
            const { updateDisciplinarySubStages } = this.props;
            const data ={
                ActionSubStageId: parseInt(name),
                SubStageName: value,
                DisciplinaryActionStageRefId:parseInt(id),
            }
            // console.log(data)
            updateDisciplinarySubStages(data)
            this.onSubEdit('','');
        }
        if( e.key === 'Escape'){
            this.onSubEdit('','')
        }
    }
    _renderSubStage=stage=>{
        const {subStageInput,onSubEdit} =this.state;
        return(
            <li className="stage-subStages__item subStage">
                {
                    onSubEdit === stage.ActionSubStageId?
                    <>
                        <span className="subStage-input">
                            <input type="text"
                                onChange={(e)=>this.setState({subStageInput:e.target.value})}
                                value={subStageInput} 
                                autoFocus
                                onKeyUp={this.onSubStageKeyUp}
                                id={stage.DisciplinaryActionStageRefId}
                                name={stage.ActionSubStageId} />
                        </span>
                        <span 
                            onClick={()=>this.onSubEdit('','')}
                            className="subStage-icon">
                            <MdClose />
                        </span>
                    </>
                    :
                    <>
                        <span className="subStage-name">
                            {stage.SubStageName}
                        </span>
                        <span className="subStage-icons">
                            <span 
                                onClick={()=>this.onSubEdit(stage.ActionSubStageId,stage.SubStageName)}
                                className="subStage-icon">
                                <MdCreate />
                            </span>
                            <span 
                                onClick={()=>this.onSubRemove(stage.ActionSubStageId)}
                                className="subStage-icon">
                                <MdDelete />
                            </span>
                        </span>
                    </>
                }
            </li>
        )
    }
    onSubEdit(id,name){
        this.setState({onSubEdit:id,subStageInput:name})
    }
    onChange(e){
        const {name,value} = e.target;
        this.setState({stageInput:value})
    }
    onSubChange(e){
        const {name,value} = e.target;
        this.setState({subInput:value})
    }
    onKeyUp(e){
        const {value,name} = e.target;
        if(e.key === 'Enter'){
            const {updateDisciplinaryStages} = this.props;
            const data ={
                ActionStageId: name,
                StageName: value
              }
            updateDisciplinaryStages(data)

            this.onEditStgae('','');
        }
        if( e.key === 'Escape'){
            this.onEditStgae('','')
        }
    }
    onSubKeyUp(e){
        const {value,name} = e.target;
        if(e.key === 'Enter'){
            this.SubStageAdd(value,name)
        }
        if( e.key === 'Escape'){
            this.setState({addStage:'',subInput:''})
        }
    }
    OnSubStageAdd(){
        const input = document.getElementById("sub-stage");
        const {name,value} = input;
        this.SubStageAdd(value,name)
    }
    SubStageAdd(value,name){
        const { addDisciplinarySubStages } = this.props;
        const data ={
            ActionSubStageId: 0,
            SubStageName: value,
            DisciplinaryActionStageRefId:parseInt(name),
        }
        addDisciplinarySubStages(data)
        this.setState({subInput:''})
    }
    onSubRemove(id){
        const {deleteDisciplinarySubStages} = this.props;
        deleteDisciplinarySubStages(id)
    }
    _renderStage = (stage)=>{
        const {editStage,stageInput,addStage} = this.state;
        return(
            <li className="disciplinary-stages-item stage">
                <div className="stage-block">
                    {
                        editStage !== stage.ActionStageId?
                            <>
                                <div className="stage-name">
                                    <span>{stage.StageName}</span>
                                </div>
                                <div className="stage-edit">
                                    <div className="edit-icon" onClick={()=>this.onEditStgae(stage.ActionStageId,stage.StageName)}>
                                        <MdCreate />
                                    </div>
                                </div>
                            </>
                        :
                            <>
                                <div className="stage-input">
                                    <input type="text"
                                        onChange={this.onChange}
                                        value={stageInput} 
                                        autoFocus
                                        onKeyUp={this.onKeyUp}
                                        name={stage.ActionStageId} />
                                </div>
                                <div className="stage-edit">
                                    <div className="remove-icon" onClick={()=>this.onEditStgae('','')}>
                                        <MdClose />
                                    </div>
                                </div>
                            </>
                    }
                </div>
                {
                    stage.SubStages && (
                        <ul className="stage-substages">
                            {
                                stage.SubStages.map(this._renderSubStage)
                            }
                        </ul>
                    )
                }
                {
                    addStage===stage.ActionStageId?
                        <div className="stage-input-block">
                            <input 
                                name={stage.ActionStageId}
                                value={this.state.subInput}
                                onChange={this.onSubChange}
                                autoFocus
                                id="sub-stage"
                                onKeyUp={this.onSubKeyUp}
                                type="text"
                            />
                            <div 
                                onClick={()=>this.setState({addStage:'',subInput:''})}
                                className="stage-input-icon stage-input-icon-remove">
                                <MdClose />
                            </div>
                            <div 
                                onClick={()=>this.OnSubStageAdd()}
                                className="stage-input-icon stage-input-icon-add">
                                <MdCheck />
                            </div>
                            
                        </div>
                    :null
                }
                {
                    stage.ActionStageId === 3 || stage.ActionStageId === 4 ?
                    <div className="stage-button">
                        <button 
                            onClick={()=>this.setState({addStage:stage.ActionStageId})}
                            className="btn btn-white with-icon">
                            <MdAdd />
                            <span>
                                Add {stage.ActionStageId === 3 ? 'Next Level':stage.ActionStageId === 4?'Action':''}
                            </span>
                        </button>
                    </div>
                    :null
                }
            </li>
        )
    }
    render() {
        const { disciplinaryStages } = this.state;
        return (
            <div className="disciplinary-stages">
                <h2 className="tertiary-title">Disciplinary Action Stages</h2>
                <div className="disciplinary-stages-container">
                    <ul className="disciplinary-stages-list">
                        {
                            disciplinaryStages && disciplinaryStages.map(this._renderStage)
                        }
                    </ul>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = {
    getDisciplinaryStages,
    addDisciplinarySubStages,
    updateDisciplinaryStages,
    deleteDisciplinarySubStages,
    updateDisciplinarySubStages
}

const mapStateToProps = state =>({
    disciplinaryStages:state.disciplinaryStagesReducer.disciplinaryStages,
    loading:state.disciplinaryStagesReducer.loading,
})

export default connect(mapStateToProps,mapDispatchToProps)(DisciplinaryStages)
