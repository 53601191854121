import React from 'react'
import Button from 'components/button'
import Icons from 'components/icons'
import AddressGeoLocation from '../../personal/addressGeoLocation';

function AddressDisplay(props) {
    const {EmployeeAddress} = props;
    console.log("empoeeasddre", EmployeeAddress)
    return (
        <div className="address-display">
            <div className="tertiary-title">
                <h3>Address</h3>
            </div>
            {EmployeeAddress ? <div className="employee-block">
                <div className="address-display__row">
                    <div className="address-display__col">
                        <h4 className="address-display__heading">
                            Permanent Address
                        </h4>
                        <div className="address-display__addresses">
                            <p>{EmployeeAddress?.PELocality}{`${EmployeeAddress?.PEWardNo ?  ` - ${EmployeeAddress?.PEWardNo}`:'' }`}{`${EmployeeAddress?.PELocalBodyName ?`, ${EmployeeAddress?.PELocalBodyName}` : '' }`}</p>
                            <p>{EmployeeAddress?.PEDistrictName}{`${EmployeeAddress?.PEProvinceName ?  `, ${EmployeeAddress?.PEProvinceName}` : '' }`}</p>
                            <p>{EmployeeAddress?.PECountryName}</p>
                        </div>
                    </div>
                    <div className="address-display__col">
                        <h4 className="address-display__heading">
                            Temporary Address
                        </h4>
                        <div className="address-display__addresses">
                            <p>{EmployeeAddress?.PSLocality}{`${EmployeeAddress?.PSWardNo ?  ` - ${EmployeeAddress?.PSWardNo}`:'' }`}{`${EmployeeAddress?.PSLocalBodyName?`, ${EmployeeAddress?.PSLocalBodyName}` : '' }`}</p>
                            <p>{EmployeeAddress?.PSDistrictName}{`${EmployeeAddress?.PSProvinceName ?  `, ${EmployeeAddress?.PSProvinceName}`:'' }`}</p>
                            <p>{EmployeeAddress?.PSCountryName}</p>
                        </div>
                    </div>
                    {
                        EmployeeAddress && (
                            <div className="address-location">
                                <AddressGeoLocation GeoLocation={EmployeeAddress?.GeoLocation} />
                            </div>
                        )
                    }
                    {/* <div className="address-display__col align-center">
                        <div className="address-display__map"></div>
                        <Button bg="white" title="View Map" />
                    </div> */}
                    {!props.openAddressForm && <div className="address-display__actions">
                    <a onClick={()=> props.toggleEditMode(true)}>
                        <Icons name="Edit" justSVG />
                    </a>
                    {/* <Confirm
                        action={()=>onDelete()}    
                    >
                        <a>
                            <Icons name="Delete" justSVG />
                        </a>
                    </Confirm> */}
                    </div>}
                </div>
            </div> :
            <div className="employee-block">
            <ul className="employee-docs__additional-list">
                <span className="nodata">No Additional Documents Found</span>
            </ul>
            <Button
                bg="primary-light"
                title="Add"
                buttonType="icon-button"
                leftIcon={<Icons name="Add" />}
                onClick={() => props.toggleEditMode(true)}
            />
        </div>}

        </div>
    )
}

export default AddressDisplay
