import React, { PureComponent } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

export default class CustomAreaChart extends PureComponent {
  render() {
    const { data } = this.props;
    return (
      <AreaChart
        width={600}
        height={135}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis dataKey="checkin" />
        {/* <YAxis dataKey="checkout" /> */}
        {/* <YAxis /> */}
        <Tooltip />
        <Area
          type="monotone"
          dataKey="checkedin"
          stroke="#fff"
          fill="#3888fd"
        />
        {/* <Area type="monotone" dataKey="checkout" stroke="#fff" fill="#3888fd" /> */}
      </AreaChart>
    );
  }
}
