import React, {useEffect, useState} from "react";
import Table from "components/customTable";
import dayjs from "dayjs";
import CommonPagination from "components/common/pagination";
import Modal from "components/modal";
import ForwardLoan from "./forwardLoan";
import {useHistory} from "react-router-dom";
import RouteEnum from "constants/routeEnum";
import {getSbiLoanApprovers} from "../helpers/action";
import {useDispatch, useSelector} from "react-redux";
import Icons from "components/icons";
import isEmpty from 'isEmpty';

const Tables = (props) => {
  const { name, rowData, handleIndexChange, handleRowChange, state, userId, getSbiLoanStatusHistories} = props;
  const history = useHistory();
  const [openModel, setOpenModel] = useState(false);
  const [approversList, setApproversList] = useState([]);
  const [forwardSelectedData, setForwardSelectedData] = useState({
    ProcessFlowStepId: null,
  });

  const dispatch = useDispatch();
  const {sbiLoanApproversList} = useSelector((state: any) => state.sbiLoanSettingReducer);

  const handleDataModal = async (data) => {
    console.log(`setOpenModel(true)`, data);
    await setForwardSelectedData(data);
    if (data.ProcessFlowStepId !== 10)
      await dispatch(
        getSbiLoanApprovers({
          EmployeeId: data.EmployeeId ? data.EmployeeId : userId !== 0 ? userId : 0,
          LoanType: data.LoanType ? data.LoanType : 1,
          FlowType: data.FlowType,
          CurrentStep: data.ProcessFlowStepId,
        })
      );
    setOpenModel(true);
  };

  const actionCell = (rowProps) => {
    console.log("rowprops", rowProps);
    const {
      LoanType,
      Status,
      CurrentStep,
      FlowType,
      ProcessFlowStepId,
      IsSelfForward,
      LoanId,
      EmployeeId,
    } = rowProps.value;
    return (
      <div className="sbi-employee-loan__button">
        <span
          onClick={() => {
            history.push({
              pathname: `${RouteEnum.ViewSBILoanDetail}/${LoanId}`,
              state: {
                // data: rowProps.value.LoanType,
                // LoanId: rowProps.value.LoanId,
                // LoanType: rowProps.value.LoanType,
                // EmployeeId: rowProps.value.EmployeeId,
                // // EmployeeId: 10,
                // Status: rowProps.value.Status,
                // CurrentStep: rowProps.value.CurrentStep,
                // FlowType: rowProps.value.FlowType,
                // ProcessFlowStepId: rowProps.value.ProcessFlowStepId,
                // IsSelfForward: rowProps.value.IsSelfForward
                data: LoanType,
                LoanId: LoanId,
                LoanType: LoanType,
                EmployeeId: EmployeeId,
                // EmployeeId: 10,
                Status: Status,
                CurrentStep: CurrentStep,
                FlowType: FlowType,
                ProcessFlowStepId: ProcessFlowStepId,
                IsSelfForward: IsSelfForward,
              },
            });
          }}
        >
          <Icons name="EyeView" />
        </span>
        { ((Status !== 10 && Status !== -1) && !IsSelfForward) && <span onClick={() => handleDataModal(rowProps.value)}>
          <Icons name="ForwardShare" />
        </span>}
      </div>
    );
  };

  const DayFormatter = ({value}) => {
    if (isEmpty(value)) return '-'
    return <div className="">{dayjs(value).format("YYYY-MM-DD")}</div>;
  };

  const beforeColumns = [
    {accessor: "EmployeeName", Header: "Employee", width: 200},
    {accessor: "EmployeeId", Header: "EID", width: 70},
    {accessor: "Designation", Header: "Designation", width: 200},
    {accessor: "Department", Header: "Department", width: 145},
    {accessor: "Branch", Header: "Branch", width: 145},
    {accessor: "LoanTypeName", Header: "Loan Type", width: 145},
    {accessor: "LoanAmount", Header: "Loan Amount", width: 145},
    {accessor: "ActionOn", Header: "Request Date", width: 140, Cell: DayFormatter},
    {accessor: "StatusName", Header: "Status", width: 145},
    {accessor: "Purpose", Header: "Purpose", width: 145},
    {
      fixed: "right",
      accessor: (d) => d,
      Header: "Action",
      Cell: actionCell,
      width: 145,
    },
  ];

  useEffect(() => {
    console.log(`sbiLoanApproversList`, sbiLoanApproversList);
    sbiLoanApproversList && setApproversList(sbiLoanApproversList);
  }, [sbiLoanApproversList]);

  return (
    <div>
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <p> {name} </p>
        {rowData && rowData.length > 0 && (
          <CommonPagination
            handleRowChange={handleRowChange}
            handleIndexChange={handleIndexChange}
            pageIndex={state.pageIndex}
            rowsPerPage={state.pageSize}
            total={rowData[0].TotalRows}
          />
        )}
      </div>
      <Table columns={beforeColumns} data={rowData} />
      {openModel && (
        <Modal
          title={forwardSelectedData.ProcessFlowStepId == 10
            ? "Approve"
            : forwardSelectedData?.ProcessFlowStepId == 2 ? "Recommend and Forward" : "Verify and Forward"}
          open={openModel}
          onModalClose={() => setOpenModel(false)}
          type=""
          className="modal-allowance-request allowance-request-form"
        >
          <ForwardLoan
            approversList={approversList}
            setOpenModel={setOpenModel}
            forwardSelectedData={forwardSelectedData}
            history={props.history}
            rejectButton={false}
            isApprove={forwardSelectedData.ProcessFlowStepId == 10}
            isTable={true}
            getSbiLoanStatusHistories={getSbiLoanStatusHistories}
          />
        </Modal>
      )}
    </div>
  );
};

export default Tables;
