import React, { Component } from "react";
import { Date_Format } from "helpers/dateFormat";
import { Date_Format_Reverse } from "constants/dateFormat";
import { minutesToHrMin } from "scenes/taskTracker/common/minutesToHrMin";
import NumberToCash from "components/numberToCash";
import isEmpty from "helpers/isEmpty";

export class TableRow extends Component {
  render() {
    const { props } = this;

    return (
      <tr className="table-row" role="row" key={props.index}>
        <td className="e-rowcell " role="gridcell">
          <span>{props.ActivityDateEng}</span>
        </td>

        <td className="e-rowcell " role="gridcell">
          <span>{props.MemberName}</span>
        </td>
        <td className="e-rowcell " role="gridcell">
          <span>{props.MatterId}</span>
        </td>
        <td className="e-rowcell" role="gridcell">
          <span>{props.ClientName}</span>
        </td>
        <td className="e-rowcell" role="gridcell">
          <span>{props.ProjectName}</span>
        </td>
        <td className="e-rowcell" role="gridcell">
          <span>{props.ActivityTypes}</span>
        </td>
        <td className="e-rowcell" role="gridcell">
          <span>{props.WorkedHoursText}</span>
        </td>
        <td className="e-rowcell" role="gridcell">
          <span>{props.BillableHoursText}</span>
        </td>
        <td className="e-rowcell" role="gridcell">
          <span>{props.NonBillableHoursText}</span>
        </td>
        <td className="e-rowcell table-cash" role="gridcell">
          <NumberToCash value={props.HourlyRate} />
        </td>
        <td className="e-rowcell table-cash" role="gridcell">
          {/* <span className="detail "> */}
          <NumberToCash value={props.PocketExpenses} />
          {/* </span> */}
        </td>
        <td className="e-rowcell table-cash" role="gridcell">
          <NumberToCash value={props.EstimatedFee} />
        </td>
        <td className="e-rowcell" role="gridcell">
          <span>{props.Description}</span>
        </td>
      </tr>
    );
  }
}

export default TableRow;
