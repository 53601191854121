import * as jobProfileService from "services/jobProfile";
import isEmpty from "isEmpty";
import { toastMessage } from "actions/validateAction";
import {
  GET_JOB_PROFILE,
  GET_JOB_PROFILE_BY_ID,
  GET_DEFAULT_JOB_PROFILE,
  GET_JOBPROFILE_KSA_EDUCATION_LIST,
  GET_JOBPROFILE_KSA_CERTIFICATION_LIST,
  GET_JOBPROFILE_KNOWLEDGES,
  GET_JOBPROFILE_SKILLS,
  GET_JOBPROFILE_ABILITIES,
  GET_JOB_DESCRIPTION,
  GET_JOB_DESCRIPTION_BY_ID,
  SET_JOB_PROFILE_BY_ID_LOADING
} from "actions/types";
import moment from "moment";
import axios from "axios";
import { loadingHeaders } from "helpers/getHeaders";

const urlPrefix_ksa = "v1/jp/";
//#region job profile
export const GetJobProfile = (searchData) => async (dispatch) => {
  try {
    let result = await jobProfileService.getJobProfile(searchData);
    if (result.Status) {
      let data = result.Data.map((x) => {
        x.LastUpdatedDate = moment(
          new Date(x.LastUpdatedDate),
          "YYYYMMDDHHmmss"
        ).format("MMMM D, YYYY");
        return x;
      });
      dispatch({
        type: GET_JOB_PROFILE,
        payload: data,
      });
    } else {
      toastMessage(dispatch, result);
    }
  } catch (err) {
    toastMessage(dispatch, err.message);
  }
};

export const GetDefaultJobProfile = () => async (dispatch) => {
  try {
    let result = await jobProfileService.getDefaultJobProfile();
    if (result.Status) {
      dispatch({
        type: GET_DEFAULT_JOB_PROFILE,
        payload: result.Data,
      });
    } else {
      toastMessage(dispatch, result);
    }
  } catch (err) {
    toastMessage(dispatch, err.message);
  }
};

export const GetJobProfileById = (profileId) => async (dispatch) => {
  try {
    dispatch({
      type: SET_JOB_PROFILE_BY_ID_LOADING,
      payload: true,
    });
    let result = await jobProfileService.getJobProfileById(profileId);
    if (result.Status) {
      dispatch({
        type: GET_JOB_PROFILE_BY_ID,
        payload: result.Data,
      });
    } else {
      toastMessage(dispatch, result);
    }
  } catch (err) {
    toastMessage(dispatch, err.message);
  }
};

export const GetEducationList = () => async (dispatch) => {
  await axios
    .get(`${urlPrefix_ksa}educations`, loadingHeaders)
    .then((resp) => {
      if (resp.data.Status) {
        let data = resp.data.Data.map((x) => {
          return {
            Id: x.EducationId,
            Name: x.Name,
            Selected: false,
          };
        });
        dispatch({
          type: GET_JOBPROFILE_KSA_EDUCATION_LIST,
          payload: data,
        });
      } else {
        toastMessage(dispatch, resp.data);
      }
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const GetCertificationList = () => async (dispatch) => {
  await axios
    .get(`${urlPrefix_ksa}certifications`, loadingHeaders)
    .then((resp) => {
      if (resp.data.Status) {
        let data = resp.data.Data.map((x) => {
          return {
            Id: x.CertificationId,
            Name: x.Name,
            Selected: false,
          };
        });
        dispatch({
          type: GET_JOBPROFILE_KSA_CERTIFICATION_LIST,
          payload: data,
        });
      } else {
        toastMessage(dispatch, resp.data);
      }
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const GetKnowledgeList = () => async (dispatch) => {
  await axios
    .get(`${urlPrefix_ksa}knowledges`, loadingHeaders)
    .then((resp) => {
      if (resp.data.Status) {
        let data = resp.data.Data.map((x) => {
          return {
            Id: x.KSAId,
            Name: x.Name,
            Selected: false,
            Level: 3,
          };
        });
        dispatch({
          type: GET_JOBPROFILE_KNOWLEDGES,
          payload: data,
        });
      } else {
        toastMessage(dispatch, resp.data);
      }
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const GetSkillsList = () => async (dispatch) => {
  await axios
    .get(`${urlPrefix_ksa}skills`, loadingHeaders)
    .then((resp) => {
      if (resp.data.Status) {
        let data = resp.data.Data.map((x) => {
          return {
            Id: x.KSAId,
            Name: x.Name,
            Selected: false,
            Level: 3,
          };
        });
        dispatch({
          type: GET_JOBPROFILE_SKILLS,
          payload: data,
        });
      } else {
        toastMessage(dispatch, resp.data);
      }
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const GetAbilityList = () => async (dispatch) => {
  await axios
    .get(`${urlPrefix_ksa}abilities`, loadingHeaders)
    .then((resp) => {
      if (resp.data.Status) {
        let data = resp.data.Data.map((x) => {
          return {
            Id: x.KSAId,
            Name: x.Name,
            Selected: false,
            Level: 3,
          };
        });
        dispatch({
          type: GET_JOBPROFILE_ABILITIES,
          payload: data,
        });
      } else {
        toastMessage(dispatch, resp.data);
      }
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};
//#endregion

//#region job description
export const GetJobDescription = (searchData) => async (dispatch) => {
  try {
    let result = await jobProfileService.getJobDescription(searchData);
    if (result.Status) {
      let data = result.Data.map((x) => {
        x.LastUpdatedDate = moment(
          new Date(x.LastUpdatedDate),
          "YYYYMMDDHHmmss"
        ).format("MMMM D, YYYY");
        return x;
      });
      dispatch({
        type: GET_JOB_DESCRIPTION,
        payload: data,
      });
    } else {
      toastMessage(dispatch, result);
    }
  } catch (err) {
    toastMessage(dispatch, err.message);
  }
};

export const GetJobDescriptionById = (descriptionId) => async (dispatch) => {
  try {
    let result = await jobProfileService.getJobDescriptionById(descriptionId);
    if (result.Status) {
      dispatch({
        type: GET_JOB_DESCRIPTION_BY_ID,
        payload: result.Data,
      });
    } else {
      toastMessage(dispatch, result);
    }
  } catch (err) {
    toastMessage(dispatch, err.message);
  }
};
//#endregion
