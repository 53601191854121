import React, { Component } from "react";

export class CompensationPlan extends Component {
  render() {
    return (
      <div className="segment">
        <div className="segment-scheme">
          <div className="segment-title">Compensation Scheme</div>

          <div className="table-payslip segment-full_width">
            <div className="table-row-scheme row-heading">
              <span></span>
              <span>Gross Compensation</span>
              <span>Non Cash Benefits</span>
              <span>Festival/Event Allowance</span>
              <span>Bonus</span>
              <span className="align_right">Total</span>
            </div>
            <div className="table-row-scheme">
              <span className="align_left bold">Monthly</span>
              <span>18,000.00</span>
              <span>3,000.00</span>
              <span></span>
              <span></span>
              <span className="align_right bold">21,000.00</span>
            </div>
            <div className="table-row-scheme">
              <span className="align_left bold">Annual</span>
              <span>2,00,000.00</span>
              <span>30,000.00</span>
              <span>10,000.00</span>
              <span>-</span>
              <span className="align_right bold">2,40,000.00</span>
            </div>
          </div>

          <div className="table-payslip segment-semi_width">
            <div className="table-row-column_4">
              <span className="align_left">Plan Name</span>
              <span>Amount</span>
              <span>Effective From</span>
              <span>Frequency</span>
            </div>
            <div className="table-row-column_4">
              <span className="align_left">Basic Salary</span>
              <span>18,000.00</span>
              <span>Baisakh 1, 2076</span>
              <span>Monthly</span>
            </div>
            <div className="table-row-column_4">
              <span className="align_left">Pf</span>
              <span>18,000.00</span>
              <span>Baisakh 1, 2076</span>
              <span>Monthly</span>
            </div>
            <div className="table-row-column_4 table-row-line_top table-row-line_bottom">
              <span className="align_left">General Allowance</span>
              <span>18,000.00</span>
              <span>Baisakh 1, 2076</span>
              <span>Monthly</span>
            </div>
            <div className="table-row-column_4">
              <span className="align_left">Retirement fund</span>
              <span>18,000.00</span>
              <span>Baisakh 1, 2076</span>
              <span>Monthly</span>
            </div>
            <div className="table-row-column_4">
              <span className="align_left bold">Total Cash</span>
              <span className="bold">18,000.00</span>
              <span></span>
              <span></span>
            </div>
          </div>

          <span className="segment-scheme-note">
            This is the base compensation scheme. The amounts may change based
            on leaves and attendance
          </span>
        </div>

        <div className="segment-benefits">
          <div className="segment-title">Non Cash Benefits</div>

          <div className="table-payslip segment-semi_width">
            <div className="table-row-column_4">
              <span className="align_left">Housing Allowance</span>
              <span>18,000.00</span>
              <span>Baisakh 1, 2076</span>
              <span>Monthly, Non-Cash</span>
            </div>
            <div className="table-row-column_4">
              <span className="align_left">Car Allowance</span>
              <span>18,000.00</span>
              <span>Baisakh 1, 2076</span>
              <span>Monthly, Non-Cash</span>
            </div>
            <div className="table-row-column_4 table-row-line_top">
              <span className="align_left bold">Total Benefits</span>
              <span className="bold">3,000.00</span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CompensationPlan;
