import {
  GET_EMPLOYEE_REVIEW_REQUEST,
  GET_MY_REQUESTS,
  GET_TEAM_REQUEST_HISTORY,
  GET_MY_REQUEST_HISTORY,
  GET_EMPLOYEE_REQUEST_COUNT,
  GET_EMPLOYEE_REQUEST_AUDIT_LOG,
  SET_EMPLOYEE_REQUEST_AUDIT_LOG_LOADING,
  SET_EMPLOYEE_REVIEW_REQUEST_LOADING,
  SET_MY_REQUESTS_LOADING,
  SET_TEAM_REQUEST_HISTORY_LOADING,
  SET_MY_REQUEST_HISTORY_LOADING,
} from "actions/types";
import isEmpty from "isEmpty";

const initialState = {
  listCount: {
    reviewRequestCount: 0,
    myRequestCount: 0,
    teamHistoryCount: 0,
    myHistoryCount: 0,
  },
  employeeReviewRequests: { loading: true, value: [] },
  myRequests: { loading: true, value: [] },
  teamRequestHistory: { loading: true, value: [] },
  myRequestHistory: { loading: true, value: [] },
  auditLogData: { loading: true, value: [] },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEE_REQUEST_COUNT:
      const {
        RequestToReview,
        MyRequest,
        MyHistory,
        TeamHistory,
      } = action.payload;
      return {
        ...state,
        listCount: {
          reviewRequestCount: RequestToReview,
          myRequestCount: MyRequest,
          teamHistoryCount: TeamHistory,
          myHistoryCount: MyHistory,
        },
      };
    case GET_EMPLOYEE_REVIEW_REQUEST:
      return {
        ...state,
        employeeReviewRequests: { loading: false, value: action.payload },
        listCount: {
          ...state.listCount,
          reviewRequestCount: isEmpty(action.payload)
            ? 0
            : action.payload[0]?.TotalRow,
        },
      };
    case GET_MY_REQUESTS:
      return {
        ...state,
        myRequests: { loading: false, value: action.payload },
        listCount: {
          ...state.listCount,
          myRequestCount: isEmpty(action.payload)
            ? 0
            : action.payload[0]?.TotalRow,
        },
      };
    case GET_TEAM_REQUEST_HISTORY:
      return {
        ...state,
        teamRequestHistory: { loading: false, value: action.payload },
        listCount: {
          ...state.listCount,
          teamHistoryCount: isEmpty(action.payload)
            ? 0
            : action.payload[0]?.TotalRow,
        },
      };
    case GET_MY_REQUEST_HISTORY:
      return {
        ...state,
        myRequestHistory: { loading: false, value: action.payload },
        listCount: {
          ...state.listCount,
          myHistoryCount: isEmpty(action.payload)
            ? 0
            : action.payload[0]?.TotalRow,
        },
      };
    case SET_EMPLOYEE_REQUEST_AUDIT_LOG_LOADING:
      return {
        ...state,
        auditLogData: { loading: action.payload, value: [] },
      };
    case GET_EMPLOYEE_REQUEST_AUDIT_LOG:
      return {
        ...state,
        auditLogData: { loading: false, value: action.payload },
      };
    case SET_EMPLOYEE_REVIEW_REQUEST_LOADING:
      return {
        ...state,
        employeeReviewRequests: { loading: action.payload, value: [] },
      };
    case SET_MY_REQUESTS_LOADING:
      return {
        ...state,
        myRequests: { loading: action.payload, value: [] },
      };
    case SET_TEAM_REQUEST_HISTORY_LOADING:
      return {
        ...state,
        teamRequestHistory: { loading: action.payload, value: [] },
      };
    case SET_MY_REQUEST_HISTORY_LOADING:
      return {
        ...state,
        myRequestHistory: { loading: action.payload, value: [] },
      };
    default:
      return state;
  }
}
