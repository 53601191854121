import {
  EmailRegexValidaion,
  PhoneNumberRegexValidation,
  numberAndDecimals,
  numbersOnly,
  positiveNumber,
} from "./regex";
import isEmpty from "isEmpty";

export const InputValidator = async (document, validationFields) => {
  const errors = {};
  let fields = Object.keys(validationFields).map(function (key) {
    return { id: key, value: validationFields[key] };
  });

  if (fields) {
    Promise.all(
      fields.map(async (field) => {
        const getElementById = document.getElementById(field.id);

        let validators =
          getElementById && getElementById.getAttribute("validators");
        const conditions = validators ? validators.split(",") : null;

        if (conditions) {
          Promise.all(
            conditions.map((condition) => {
              const errMsg = validationConditions(condition, field.value);
              if (errMsg) {
                errors[field.id] = errMsg;
              }
            })
          );
        }
      })
    );
  }
  return errors;
};

const validationConditions = (condition, value) => {
  switch (condition) {
    case "required": {
      return !value || isEmpty(value) ? "Required" : "";
    }

    case "email": {
      const isValid = !isEmpty(value) ? EmailRegexValidaion(value) : true;
      return !isValid ? "Invalid Email" : "";
    }

    case "numberAndDecimals": {
      const isValid = !isEmpty(value) ? numberAndDecimals(value) : true;
      return isEmpty(value) ? "Required" : !isValid ? "Numbers Only" : "";
    }

    case "numbers": {
      const isValid = !isEmpty(value) ? numbersOnly(value) : true;
      return !value ? "Required" : !isValid ? "Numbers Only" : "";
    }

    case "posNumbers": {
      const isValid = !isEmpty(value) ? positiveNumber(value) : true;
      return !isValid ? "Should be Greater than 0" : "";
    }

    case "yearNum": {
      const isValid = !isEmpty(value)
        ? numbersOnly(value) && value.toString().length == 4
        : true;
      return !value ? "Required" : !isValid ? "Invalid Year" : "";
    }

    case "phone": {
      const isValid = !isEmpty(value)
        ? PhoneNumberRegexValidation(value)
        : true;
      return !value ? "Required" : !isValid ? "Invalid Phone no." : "";
    }

    case "upToCurrentYear": {
      const isValid = !isEmpty(value)
        ? numbersOnly(value) && value.toString().length == 4
        : true;
      return !value
        ? "Required"
        : !isValid
        ? "Invalid Year"
        : new Date().getFullYear() < value
        ? "Can't exceed current date"
        : "";
    }

    default:
      return "";
  }
};
