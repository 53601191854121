import React,{useState,useEffect} from 'react'
import {MdEdit,MdClose} from 'react-icons/md';
import classnames from 'classnames';
import { connect } from 'react-redux';
import {
    updateEmployeeKsaRating,
    saveEmployeeKsaRating,
    getEmployeeKsaRating,
} from '../action'

function ScoreCard({ 
    item,
    index, 
    updateEmployeeKsaRating,
    saveEmployeeKsaRating, 
    getEmployeeKsaRating,
    employeeId
}) {

    const [open,setOpen] = useState(false);
    const onClose = () => setOpen(false);
    const onOpen = () => setOpen(true);
    const updateKsaScore = async (score, onClose) => {
        let data = {
            id: item.Id,
            employeeRefId: item.EmployeeRef_Id,
            ksaRefId: item.KSARef_Id,
            ratingValue: score,
        }
        if (data.id === 0)
            await saveEmployeeKsaRating(data, onClose);
        else 
            await updateEmployeeKsaRating(data, onClose);
        getEmployeeKsaRating(employeeId);;
    }

    return(
        <li key={index + Math.random()} className="ksa-block__item ksa-item">
            <div className="ksa-item__num">
                <span className="badge">{item.num}</span>
            </div>
            <div className="ksa-item__name">
                <span>
                    {
                        item.name
                    }
                </span>
            </div>
            <div className="ksa-item__score">
                <Score score={item.score} required={item.min} />
            </div>
            <div className="ksa-item__edit">
                <span className="edit-icon" onClick={()=>onOpen()}>
                    <MdEdit />
                </span>
                <EditScorePopup open={open} onClose={onClose}  ksa={item} updateKsaScore={updateKsaScore} />
            </div>
        </li>
    )
}

const EditScorePopup = ({ ksa, open, onClose, updateKsaScore }) =>{

    const [ksaScore, setKsaScore] = useState(ksa.score);

    const scoreType = [
        {score:0,name:'None'},
        {score:1,name:'Very Poor'},
        {score:2,name:'Poor'},
        {score:3,name:'Average'},
        {score:4,name:'Good'},
        {score:5,name:'Very Good'},
        {score:6,name:'Exceptional'},
    ]
    if(!open){
        return null
    }
    const changeKsaScore = (score) => {
        setKsaScore(score);
    }
    return(
        <div className="ksa-popup">
            <div className="ksa-popup__body">
                <div className="ksa-popup__header">
                    <div className="ksa-item">
                        <div className="ksa-item__num">
                            {/* <span className="badge">{ksa.num}</span> */}
                            <span className="badge">{ksa.score}</span>
                        </div>
                        <div className="ksa-item__name">
                            <span>{ksa.name}</span>
                        </div>
                        <div className="ksa-item__score">
                            <Score score={ksaScore} required={ksa.min} />
                        </div>
                        <div className="ksa-item__edit">
                            <span className="edit-icon" onClick={()=>onClose()}>
                                <MdClose />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="ksa-popup__content">
                    <div className="ksa-popup__container">
                        <div className="ksa-scoreType">
                            <ul className="ksa-scoreType__list">
                                {
                                    scoreType.map(item=>{
                                        const itemClass = classnames({
                                            "ksa-scoreType__item":true,
                                            "active": item.score === ksaScore,
                                        })
                                        return(
                                            <li key={item.name} onClick={() => changeKsaScore(item.score)} className={itemClass}>
                                                <div className="ksa-scoreType__content">
                                                    <div className="ksa-scoreType__score">
                                                        <span className="badge">{item.score}</span>
                                                    </div>
                                                    <div className="ksa-scoreType__name">
                                                        <span>{item.name}</span>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="ksa-popup__footer">
                    <div className="button-group button-group__right">
                        <button onClick={()=>onClose()} className="button button-secondary-dark">
                            Cancel
                        </button>
                        <button  onClick={() => updateKsaScore(ksaScore, onClose)} className="button button-primary">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Score = ({score,required,max=6}) =>{
    const per = (score * 100) / max;
    const req = (required * 100) / max;

    const scoreClass = classnames({
        "score-back-score":true,
        "score-orange":score===0,
        "score-dark":score===1,
        "score-blue-light":score===2,
        "score-blue":score===3,
        "score-blue-dark":score===4,
        "score-green-light":score===5,
        "score-green":score===6,
    })
    return(
        <div className="score">
            <div className="score-back">
                <div className={scoreClass} style={{width:`${per}%`}}>
                    <span>
                        {score}
                    </span>
                </div>
                <div className="score-back-required" style={{left:`${req}%`}}>

                </div>
            </div>
        </div>
    )

}

const mapStateToProps = state => ({
    employeeKsaRating: state.employeeProfile.employeeKsaRating,
});

const mapDispatchToProps = {
    updateEmployeeKsaRating,
    getEmployeeKsaRating,
    saveEmployeeKsaRating
};

export default connect(mapStateToProps, mapDispatchToProps)(ScoreCard);