export default [
    {
        id:1,
        img:'',
        name:'Romi Khadka',
        position:'Finance Manager',
        direct:10,
        total:10,
        children:[
            {
                id:12,
                img:'',
                name:'Mina Tandan',
                position:'Finance Officer',
                direct:10,
                total:10,
                children:[
                   {id:1212,img:'',name:'Dhiraj Rai'}, 
                   {id:212,img:'',name:'Dhiraj Rai'}, 
                   {id:313,img:'',name:'Dhiraj Rai'}, 
                   {id:414,img:'',name:'Dhiraj Rai'}, 
                   {id:141,img:'',name:'Dhiraj Rai'}, 
                   {id:511,img:'',name:'Dhiraj Rai'}, 
                ]
            },
            {
                id:122,
                img:'',
                name:'Mina Tandan',
                position:'Finance Officer',
                direct:10,
                total:10,
                children:[
                    {id:322,img:'',name:'Dhiraj Rai'}, 
                    {id:431,img:'',name:'Dhiraj Rai'}, 
                    {id:423,img:'',name:'Dhiraj Rai'}, 
                    {id:2341,img:'',name:'Dhiraj Rai'}, 
                ]
            }
        ]
    }
]