import React, {useEffect,useState} from 'react'
import Title from "../../common/title";
import Button from 'components/button';
import { Tabs, Tab } from "components/tabs/index.d";
import Filters from './filters'
import Tables from './tables'
import './style.scss';
import {getOvertimeRequest} from './helpers/action'
import { useDispatch,useSelector } from 'react-redux';
import {BS2AD} from 'helpers/dateConvert';
import {postToSalary} from './helpers/action';
import OvertimeRequestForm from "scenes/employee/overtimeRequest";
import Modal from 'components/modal';

const tabs = [
    { id: '-1', label: "All" },
    { id: '0', label: "Pending" },
    { id: '1', label: "Recommended" },
    { id: '2', label: "Approved" },
    { id: '3', label: "Rejected" },
    { id: '4', label: "Posted to pay" },
];

function Overtime() {
    const [activeTab,setActiveTab] = useState('-1');
    const [branches,setBranches] = useState('');
    const [open,setOpen] = useState(false);
    const [pageIndex,setPageIndex] = useState(0);
    const [rowsPerPage,setRowsPerPage] = useState(10);
    const [departments,setDepartments] = useState('');
    const [overtimeType,setOvertimeType] = useState('');
    const [startDate,setStartDate] = useState('');
    const [endDate,setEndDate] = useState('');
    const [employeeName,setEmployeeName] = useState('')
    const dispatch = useDispatch();
    const {overtimeRequest} = useSelector((state:any)=>state.overtimeReducer);

    useEffect(()=>{
        initialize()
    },[]);

    const initialize = async () => {
        let start = '';
        let end = '';
        if(startDate){
            start =BS2AD(startDate).formatedDate;
        }
        if(endDate){
            end =BS2AD(endDate).formatedDate;
        }
        await dispatch(getOvertimeRequest(
            pageIndex,
            rowsPerPage,
            activeTab,
            branches,
            departments,
            overtimeType,
            employeeName,
            start,
            end
        ))
    }

    const handleRowChange = async num => {
        setRowsPerPage(num);
    };
    
    const handleIndexChange = async num => {
        await setPageIndex(num);
    };
    useEffect(()=>{
        initialize()
    },[branches,departments,pageIndex,rowsPerPage,activeTab,overtimeType,employeeName,startDate,endDate]);

    const handleAutoSelect = (name, selected) => {
        if(name === 'branch') setBranches(selected);
        if(name === 'department') setDepartments(selected);
        if(name === 'overtimeType') setOvertimeType(selected);
        if(name === 'employeeName') setEmployeeName(selected);
        if(name === 'dateRange') {
            const { startDate, endDate } = selected;
            setStartDate(startDate);
            setEndDate(endDate)
        }
    };

    const onTabChange = (e,a) =>{
        setActiveTab(e);
    }
    const postToPay = (ids) =>{
        dispatch(postToSalary(ids,initialize));
        
    }
    return (
        <div  style={{width: 1425,maxWidth:'cacl(100vw - 70px)'}} className="admin common-container">
            <Title name="Overtime Management" />
            <div className="space-between">
                <div className="tertiary-title">
                    Overtime Management
                </div>
                <Button 
                    onClick={()=>setOpen(true)}
                    title="Assign Overtime" bg="primary" />
                        <Modal
                        title={"Assign Overtime"}
                        open={open}
                        onModalClose={() => setOpen(false)}
                        type=""
                        className="time-request-modal over-time"
                        >
                        <OvertimeRequestForm
                            isAssign={true}
                            isAdmin
                            onModalClose={() => setOpen(false)}
                        />
                        </Modal>
                    
            </div>
            <div className="mt-md overtime-tabs">
                <Tabs noBackground>
                    {
                        tabs.map(item=>{
                            return <Tab 
                                        isActive={activeTab===item.id}
                                        onClick={onTabChange} 
                                        label={item.label} name={item.id} >
                                <div className="">
                                    <Filters 
                                        branches={branches}
                                        departments={departments}
                                        startDate={startDate}
                                        endDate={endDate}
                                        overtimeType={overtimeType}
                                        employeeName={employeeName}
                                        handleAutoSelect={handleAutoSelect}
                                    />
                                    <Tables 
                                        pageIndex={pageIndex}
                                        rowsPerPage={rowsPerPage}
                                        handleRowChange={handleRowChange}
                                        postToPay={postToPay}
                                        activeTab={activeTab}
                                        initialize={initialize}
                                        handleIndexChange={handleIndexChange}
                                        data={overtimeRequest} />
                                </div>
                            </Tab>
                        })  
                    }
                </Tabs>
            </div>
        </div>
    )
}

export default Overtime
