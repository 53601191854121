import React, { Component } from "react";
import "../../scss";
import { connect } from "react-redux";
import SearchAndFilter from "./searchAndFilter";
import Table from "./table";
import Breadcrumb from "../../../common/breadcrumb";
import Loaders from "components/loaders";
import { GetHoursCommitted } from "../../action";
import RouteBaseClass from "components/routeBaseClass";

export class index extends RouteBaseClass {
  constructor(props) {
    super(props, ["Administrator"]);
    this.state = {
      isLoading: true,
      pageIndex: 0,
      rowsPerPage: 10,
      searchClient: "",
      searchProject: "",
      fromDate: null,
      toDate: null,
    };
  }

  async componentDidMount() {
    await this.initialize();
    this.setState({ isLoading: false });
  }

  initialize = async () => {
    const { searchClient, searchProject, pageIndex, rowsPerPage } = this.state;

    const data = {
      ClientName: searchClient,
      ProjectName: searchProject,
      PageIndex: pageIndex,
      PageSize: rowsPerPage,
    };

    await this.props.GetHoursCommitted(data);
  };

  handleRowChange = (num) => {
    this.setState({ rowsPerPage: num, pageIndex: 0 }, async () => {
      await this.initialize();
    });
  };

  handleIndexChange = (num) => {
    this.setState({ pageIndex: num }, async () => {
      await this.initialize();
    });
  };

  handleInput = (name, value) => {
    this.setState({ [name]: value, pageIndex: 0 }, async () => {
      await this.initialize();
    });
  };

  render() {
    const { isLoading } = this.state;
    const { hoursCommitted } = this.props;
    return (
      <div className="common-container">
        <div className="tasktracker-title-section">
          <Breadcrumb name="Hours committed" />
        </div>

        <div>
          <SearchAndFilter handleInput={this.handleInput} {...this.state} />

          {isLoading ? (
            <Loaders loading={isLoading} type="skeleton" name="custom-table" />
          ) : (
            <Table
              {...hoursCommitted}
              {...this.state}
              handleRowChange={this.handleRowChange}
              handleIndexChange={this.handleIndexChange}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  hoursCommitted: state.taskTrackerReducer.hoursCommitted,
});

const mapDispatchToProps = {
  GetHoursCommitted,
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
