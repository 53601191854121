import React,{MouseEvent} from "react";
import PropTypes from 'prop-types';
import classnames from 'classnames';
import isEmpty from 'isEmpty';
import {ButtonProps} from 'ts/interfaces/componentInterface';
import ButtonDrop from './buttonDrop';
import ButtonIcon from './buttonIcon';
import {Link} from 'react-router-dom'

class Button extends React.PureComponent<ButtonProps,any> {
  public displayName = 'Button';
  public static defaultProps ={
      size: "regular",
      bg:'',
      type:'button',
      buttonType:'normal',
      justDrop:false,
      htmlButtonType:'button',
      isDisabled:false,
      // roundValue:3,
      buttonClass:'',
      withShadow:false,
      withIcon:false,
      withDrop:false,
  }
  public dropRef:any;
  constructor(props:ButtonProps) {
    super(props)
  
    this.state = {}
  }
  public _renderChildren(){
      const { children,
              title,
              buttonType
            } 
          = this.props;
      switch(buttonType){
        case 'normal':
          return !isEmpty(title) ? title : children
        case 'drop-button':
          return <ButtonDrop {...this.props} />
        case 'icon-button':
          return <ButtonIcon {...this.props} />
        default:
          return !isEmpty(title) ? title : children
      }
  }
  render() {
    const { size,to, 
            children,
            buttonClass, 
            htmlButtonType,
            justDrop, 
            bg,
            isDisabled, 
            roundValue, 
            withDrop,
            style,
            onClick, buttonType, type, icon, withShadow, ...rest  } = this.props;
    let buttonClasses = classnames({
        [type]:true,
        [type + '-primary']:bg==='primary',
        [type + '-primary-light']:bg==='primary-light',
        [type + '-primary-dark']:bg==='primary-dark',
        [type + '-danger']:bg==='danger',
        [type + '-danger-dark']:bg==='danger-dark',
        [type + '-danger-light']:bg==='danger-light',
        [type + '-green']:bg==='green',
        [type + '-green-dark']:bg==='green-dark',
        [type + '-warning']:bg==='warning',
        [type + '-secondary']:bg==='secondary',
        [type + '-secondary-light']:bg==='secondary-light',
        [type + '-secondary-dark']:bg==='secondary-dark',
        [type + '-highlight']:bg==='highlight',
        [type + '-white']:bg==='white',
        [type + '-black']:bg==='black',
        [type + '-success']:bg==='success',
        [type + '-subtle']:bg==='subtle',
        [type + '-subtle-error']:bg==='subtle-error',
        [buttonClass]:!isEmpty(buttonClass),
        "button-large":size==='large',
        "button-small":size==='small',
        "button-shadow":withShadow,
        "with-icon": buttonType === 'icon-button',
        "with-drop": buttonType === 'drop-button',
        "with-just-drop":justDrop
    })

    if(type === 'button-text'){
        return(
            <a
                // {...rest}
                className={buttonClasses}
                >
                {children}
            </a>
        )
    }
    if(buttonType === 'link-button'){
        return(
            <Link
                to={to}
                style={roundValue?{borderRadius:roundValue}:{}}
                className={buttonClasses}
            >
                {this._renderChildren()}
            </Link>
        )
    }
    return (
      <button
            // {...rest}
            disabled={isDisabled}
            onClick={onClick}
            type={htmlButtonType}
            style={style?{...style}:roundValue?{borderRadius:roundValue}:{}}
            className={buttonClasses}
        >
          {this._renderChildren()}
      </button>
    );
  }
}



export default Button;