import React,{useEffect,useState} from 'react';
import FormGroup from 'components/form';
import { useDispatch,useSelector } from 'react-redux';
import {getRegionCluster} from '../helpers/action';

function Search() {
    const [search,setSearch] = useState('');
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(getRegionCluster(search));
    },[search])
    return (
        <div className="roles-search">
            <FormGroup
                formName="searchInput" 
                value={search}
                type="search"
                name="search"
                className="search"
                placeholder="Search Branch"
                onChange={(e)=>setSearch(e.target.value)}
            />
        </div>
    )
}

export default Search
