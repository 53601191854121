import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { DisplayClientSideMessage } from "actions/commonAction";
import classnames from "classnames";
import FormGroup from "components/form";
import isEmpty from "isEmpty";
import moment from "moment";
import React, { Component } from "react";
import { DateRange } from "react-date-range";
import FlipMove from "react-flip-move";
import { AiFillPlusCircle } from "react-icons/ai";
import { FaRegCalendarAlt, FaRegTimesCircle } from "react-icons/fa";
import { IoIosCheckmarkCircle } from "react-icons/io";
import Moment from "react-moment";
import { connect } from "react-redux";
import Select from "react-select";
import { GetTravelCategoryList } from "../travelInformation/actions";
import CustomDateRange from "components/customdaterange";
import DatePickerAsPerSetting from "components/customdaterange/datepickerAsPerSetting";
import SelectEmployee from "scenes/employee/selectEmployee";
import { BS2AD, AD2BS } from "helpers/dateConvert";
import { Nepali_Date_Format } from "helpers/dateFormat";

export class Create_TravelInformationTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      enterLeaveAnimation: "accordianVertical",
      selectedDateRangeDay: 1,
      selectedDateRangeNight: 0,
      dateSelected: false,
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.setDateRangeRef = this.setDateRangeRef.bind(this);
    //this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  // componentDidMount() {
  //   document.addEventListener("mousedown", this.handleClickOutside);
  // }
  setWrapperRef(node) {
    if (!isEmpty(node)) {
      let allNodes = !isEmpty(this.wrapperRef) ? this.wrapperRef : [];
      allNodes.push({ Id: "ref" + node.tabIndex, Node: node });
      this.wrapperRef = allNodes;
    }
  }
  setDateRangeRef(node) {
    if (!isEmpty(node)) {
      let allNodes = !isEmpty(this.dateRangeRef) ? this.dateRangeRef : [];
      allNodes.push({ Id: "ref" + node.tabIndex, Node: node });
      this.dateRangeRef = allNodes;
    }
  }
  handleDropdown = (name, selected) => {
    this.props.handleTravelInfoTabState &&
      this.props.handleTravelInfoTabState(name, selected);
  };

  handleDropdownForMultiple = (name, selected, key) => {
    let { selectedTravelDestList } = this.props.travelInformationState;
    let selectedTravelDest = selectedTravelDestList.find((f) => f.key === key);

    if (!isEmpty(selectedTravelDest)) {
      selectedTravelDest.travelDestination[name] = selected;
      selectedTravelDestList[key].travelDestination =
        selectedTravelDest.travelDestination;

      this.props.handleTravelInfoTabState &&
        this.props.handleTravelInfoTabState(
          "selectedTravelDestList",
          selectedTravelDestList
        );
    }
  };

  handleMultiSelect = (list, selected, key) => {
    if (isEmpty(list)) {
      list = [];
    }
    let { selectedTravelDestList } = this.props.travelInformationState;
    let selectedTravelDest = selectedTravelDestList.find((f) => f.key === key);

    if (!isEmpty(selectedTravelDest)) {
      selectedTravelDest.travelDestination.selectedModeOfTransport = list;
      selectedTravelDestList[key].travelDestination =
        selectedTravelDest.travelDestination;

      this.props.handleTravelInfoTabState &&
        this.props.handleTravelInfoTabState(
          "selectedTravelDestList",
          selectedTravelDestList
        );
    }
  };

  handleHostAndHotelDropdown = (event, selected, key, name) => {
    let { selectedTravelDestList } = this.props.travelInformationState;
    let selectedTravelDest = selectedTravelDestList.find((f) => f.key === key);
    let label = !isEmpty(selected) ? selected.label : null;
    let value = !isEmpty(selected) ? selected.value : null;

    if (!isEmpty(selected)) {
      if (!isEmpty(selectedTravelDest)) {
        const selectedPhoneDetail = this.props.travelHostAndHotelPhoneList.find(
          (f) => f.value === value
        );
        const selectedPhoneNumber = !isEmpty(selectedPhoneDetail)
          ? selectedPhoneDetail.label
          : "";

        selectedTravelDest.travelDestination[name] = isEmpty(selected)
          ? []
          : selected;
        if (name == "selectedHost") {
          selectedTravelDest.travelDestination.hostName = label;
        } else if (name == "selectedHotel") {
          selectedTravelDest.travelDestination.hotelName = label;
        }

        selectedTravelDest.travelDestination.phoneNumber = selectedPhoneNumber;

        // if (!isEmpty(selectedTravelDest.travelDestination.phoneNumber)) {
        //   selectedTravelDest.travelDestination.phoneNumber =
        //     selectedTravelDest.travelDestination.phoneNumber +
        //     "," +
        //     selectedPhoneNumber;
        // } else {
        //   selectedTravelDest.travelDestination.phoneNumber = selectedPhoneNumber;
        // }
      }
    } else {
      if (!isEmpty(selectedTravelDest)) {
        selectedTravelDest.travelDestination[name] = [];
        if (name == "selectedHost") {
          selectedTravelDest.travelDestination.hostName = "";
        } else if (name == "selectedHotel") {
          selectedTravelDest.travelDestination.hotelName = "";
        }
      }
    }
    selectedTravelDestList[key].travelDestination =
      selectedTravelDest.travelDestination;

    this.props.handleTravelInfoTabState &&
      this.props.handleTravelInfoTabState(
        "selectedTravelDestList",
        selectedTravelDestList
      );
  };

  handleTextChange = (name, value) => {
    //const { name, value } = e && e.target;
    this.props.handleTravelInfoTabState &&
      this.props.handleTravelInfoTabState(name, value);
  };

  handleTextChangeForMultiple = (name, value, key) => {
    //const { name, value } = e && e.target;

    let { selectedTravelDestList } = this.props.travelInformationState;
    let selectedTravelDest = selectedTravelDestList.find((f) => f.key === key);

    if (!isEmpty(selectedTravelDest)) {
      selectedTravelDest.travelDestination[name] = value;
      selectedTravelDestList[key].travelDestination =
        selectedTravelDest.travelDestination;

      this.props.handleTravelInfoTabState &&
        this.props.handleTravelInfoTabState(
          "selectedTravelDestList",
          selectedTravelDestList
        );
      this.props.handleDateChange && this.props.handleDateChange();
    }
  };

  handleDateSelectForMultiple = (range, key) => {
    let { startDate, endDate } = range;
    if (isEmpty(endDate)) {
      endDate = startDate;
    }
    //if (isEmpty(range.endDate)) range.endDate = range.startDate;
    // startDate = moment(new Date(startDate), "YYYYMMDDHHmmss")
    //   .startOf("day")
    //   .format("YYYY/MM/DD HH:mm:ss");
    // endDate = moment(new Date(endDate), "YYYYMMDDHHmmss")
    //   .endOf("day")
    //   .format("YYYY/MM/DD HH:mm:ss");

    let { selectedTravelDestList } = this.props.travelInformationState;
    let selectedTravelDest = selectedTravelDestList.find((f) => f.key === key);

    if (!isEmpty(selectedTravelDest)) {
      selectedTravelDest.travelDestination.startDateEng = startDate;
      selectedTravelDest.travelDestination.endDateEng = endDate;

      //Calculate day and night starts
      const date1 = new Date(startDate);
      const date2 = new Date(endDate);
      const Difference_In_Time = date2.getTime() - date1.getTime();
      const Difference_In_Days = Math.ceil(
        Difference_In_Time / (1000 * 3600 * 24)
      );

      //Selected Travel Destination days and night
      selectedTravelDest.travelDestination.days = Difference_In_Days + 1;
      selectedTravelDest.travelDestination.nights =
        Difference_In_Days > 0 ? Difference_In_Days : 0;

      //Calculate day and night ends

      selectedTravelDestList[key].travelDestination =
        selectedTravelDest.travelDestination;

      this.props.handleTravelInfoTabState &&
        this.props.handleTravelInfoTabState(
          "selectedTravelDestList",
          selectedTravelDestList,
          true
        );
      this.props.handleDateChange && this.props.handleDateChange();
      this.setState({
        selectedDateRangeDay: Difference_In_Days,
        selectedDateRangeNight: Difference_In_Days - 1,
        dateSelected: true,
      });
    }
  };

  handleAddNewPlaceInformation = () => {
    const { companyInformation } = this.props;
    let isNepaliDate = !companyInformation.IsEnglishDate;

    let { selectedTravelDestList } = this.props.travelInformationState;
    let newKey = selectedTravelDestList.length;
    let newTravelDest = {
      key: newKey,
      travelDestination: this.props.createTravelDestination,
    };

    let prevDestEndDate =
      selectedTravelDestList[selectedTravelDestList.length - 1]
        .travelDestination.endDateEng;

    let nextDayOfPrevDestEndDate = "";
    if (isNepaliDate) {
      let splitedDate = prevDestEndDate.split("/");
      nextDayOfPrevDestEndDate =
        splitedDate[0] +
        "/" +
        splitedDate[1] +
        "/" +
        (parseInt(splitedDate[2]) + 1);
      // let nepaliDateToEnglish = BS2AD(prevDestEndDate);
      // nepaliDateToEnglish = nepaliDateToEnglish.date;

      // nepaliDateToEnglish.setDate(nepaliDateToEnglish.getDate() + 1);

      // let nepaliFormatedEndDate = Nepali_Date_Format(nepaliDateToEnglish);

      // nextDayOfPrevDestEndDate = AD2BS(nepaliFormatedEndDate);
    } else {
      nextDayOfPrevDestEndDate = prevDestEndDate.setDate(
        prevDestEndDate.getDate() + 1
      );
    }

    newTravelDest.travelDestination.startDateEng = nextDayOfPrevDestEndDate;
    newTravelDest.travelDestination.endDateEng = nextDayOfPrevDestEndDate;
    newTravelDest.travelDestination.days = 1;

    selectedTravelDestList.push(newTravelDest);

    this.props.handleTravelInfoTabState &&
      this.props.handleTravelInfoTabState(
        "selectedTravelDestList",
        selectedTravelDestList
      );
  };

  handleRemovePlaceInformation = (key) => {
    let { selectedTravelDestList } = this.props.travelInformationState;
    selectedTravelDestList = selectedTravelDestList.filter((f) => f.key != key);
    selectedTravelDestList = selectedTravelDestList.map((f, index) => {
      f.key = index;
      return f;
    });
    this.props.handleTravelInfoTabState &&
      this.props.handleTravelInfoTabState(
        "selectedTravelDestList",
        selectedTravelDestList,
        true
      );
  };

  handleTravelTypeChange = (typeId) => {
    this.props.handleTravelInfoTabState &&
      this.props.handleTravelInfoTabState("travelTypeId", typeId);
    this.props.GetTravelCategoryList(typeId);
  };

  handleDestinationTypeChange = (typeId) => {
    const { selectedTravelDestList } = this.props.travelInformationState;
    if (typeId == 1 && selectedTravelDestList.length > 1) {
      let message = {
        MessageType: "danger",
        Message: "Please remove multiple destinations first.",
      };
      this.props.DisplayClientSideMessage(message);
    } else {
      this.props.handleTravelInfoTabState &&
        this.props.handleTravelInfoTabState("destinationTypeId", typeId);
    }
  };

  handleDateClick = (event, key) => {
    if (
      this.wrapperRef
        .find((x) => x.Id === "ref" + key)
        .Node.contains(event.target) &&
      !this.dateRangeRef
        .find((x) => x.Id === "ref" + key)
        .Node.contains(event.target)
    )
      this.wrapperRef
        .find((x) => x.Id === "ref" + key)
        .Node.classList.toggle("active");
  };
  // handleClickOutside(event) {
  //   this.wrapperRef.map((x) => {
  //     if (
  //       x.Node &&
  //       x.Node.classList.contains("active") &&
  //       !x.Node.contains(event.target)
  //     ) {
  //       x.Node.classList.remove("active");
  //     }
  //   });
  // }
  render() {
    let {
      selectedCountry,
      selectedCurrency,
      travelTypeId,
      destinationTypeId,
      reason,
      selectedTravelDestList,
      employeeToAssign,
      // errors
    } = this.props.travelInformationState;
    const {
      modeOfTransportList,
      travelHostList,
      travelHotelList,
      countryList,
      currencyList,
      districtList,
      isViewMode,
    } = this.props;
    const { isAllRequiredFieldValid } = this.props;
    const {
      selectedDateRangeDay,
      selectedDateRangeNight,
      dateSelected,
    } = this.state;

    const travelClasses = (typeId) => {
      return classnames({
        badges: true,
        "badges-rounded": true,
        "left-icon": travelTypeId == typeId,
        "badges-primary": travelTypeId == typeId,
      });
    };

    const destinationClasses = (typeId) => {
      return classnames({
        badges: true,
        "badges-rounded": true,
        "left-icon": destinationTypeId == typeId,
        "badges-primary": destinationTypeId == typeId,
      });
    };

    const addNewPlaceBlock = (key, destination, isViewMode) => {
      // let x=[{label:'Air',value:"1"},{label:"Bus",value:"2"}]
      return (
        <div className="travel-detail-information" key={key}>
          <div className="flex row-num">
            {destinationTypeId == 2 && (
              <div className="">
                <span className="badge lg badge-dark-light">{key + 1}</span>
              </div>
            )}
            {destinationTypeId == 2 && selectedTravelDestList.length > 1 && (
              <div className="">
                <div
                  className={"icon-btn"}
                  onClick={() => this.handleRemovePlaceInformation(key)}
                >
                  <FaRegTimesCircle />
                </div>
              </div>
            )}
          </div>
          {travelTypeId == 1 ? (
            <div className="travel-detail-row">
              <div
                className={classnames({
                  "travel-field": true,
                  "auto-error":
                    !isAllRequiredFieldValid &&
                    isEmpty(destination.selectedDistrict),
                })}
              >
                <label>District</label>
                <FormGroup
                  formName="reactselect"
                  placeholder="Select district"
                  name="selectedDistrict"
                  onChange={(name, selected) =>
                    this.handleDropdownForMultiple(name, selected, key)
                  }
                  value={destination.selectedDistrict}
                  disabled={isViewMode}
                  options={districtList}
                  width="250px"
                />

                {!isAllRequiredFieldValid &&
                isEmpty(destination.selectedDistrict) ? (
                  <span className="auto-error__message">required</span>
                ) : null}
              </div>
            </div>
          ) : null}

          <div className="travel-detail-row">
            <div className="travel-field">
              <label>Place</label>
              <FormGroup
                name={"placeName"}
                placeholder="type destination"
                type="text"
                className="no-margin"
                label=""
                error={
                  !isAllRequiredFieldValid && isEmpty(destination.placeName)
                    ? "required"
                    : null
                }
                value={
                  !isEmpty(destination.placeName) ? destination.placeName : ""
                }
                onChange={(name, value) =>
                  this.handleTextChangeForMultiple(name, value, key)
                }
                disabled={isViewMode}
                width="250px"
              />
            </div>
            <div
              className={classnames({
                "travel-field": true,
                "auto-error":
                  !isAllRequiredFieldValid &&
                  destination.selectedModeOfTransport.length <= 0,
              })}
            >
              <label>Mode of transportation</label>
              <Select
                value={destination.selectedModeOfTransport}
                closeMenuOnSelect={false}
                isMulti
                tabSelectsValue={false}
                className="isMulti"
                options={modeOfTransportList}
                onChange={(list, selected) =>
                  this.handleMultiSelect(list, selected, key)
                }
                isDisabled={isViewMode}
              />
              {/* <FormGroup 
              formName="reactselect"
              multiple
               value={destination.selectedModeOfTransport}
               options={modeOfTransportList}
               disabled={isViewMode}
               onChange={(list, selected) =>this.handleMultiSelect(selected, selected, key)}
               width="250px"
              /> */}
              {!isAllRequiredFieldValid &&
              destination.selectedModeOfTransport.length <= 0 ? (
                <span className="auto-error__message">required</span>
              ) : null}
            </div>

            <div
              className={classnames({
                "travel-field": true,
                "auto-error":
                  !isAllRequiredFieldValid && isEmpty(destination.startDateEng),
              })}
            >
              <label>Duration</label>
              <DatePickerAsPerSetting
                onChange={(name, range) =>
                  this.handleDateSelectForMultiple(range, key)
                }
                // value={{
                //   startDate: !isEmpty(destination.startDateEng)
                //     ? moment(destination.startDateEng).format("YYYY/MM/DD")
                //     : "",
                //   endDate: !isEmpty(destination.endDateEng)
                //     ? moment(destination.endDateEng).format("YYYY/MM/DD")
                //     : "",
                // }}
                value={{
                  startDate: destination.startDateEng,
                  endDate: destination.endDateEng,
                }}
                placeholder="Select date"
                //label="Dates"
                // isNepali
                width="250px"
                validators={["required"]}
                error={
                  !isAllRequiredFieldValid &&
                  (isEmpty(destination.startDateEng) ||
                    isEmpty(destination.endDateEng))
                }
                name="startDate"
                disabled={isViewMode}
                isDateRange
              />
              {/* <FormGroup
                formName="reactdaterange"
                startDate={
                  !isEmpty(destination.startDateEng)
                    ? destination.startDateEng
                    : new Date()
                }
                endDate={
                  !isEmpty(destination.endDateEng)
                    ? destination.endDateEng
                    : new Date()
                }
                onChange={(name, range) =>
                  this.handleDateSelectForMultiple(range, key)
                }
                placeholder="Select date"
                disabled={isViewMode}
              /> */}
              {/* <div
                className="daterangepicker"
                tabIndex={key}
                ref={this.setWrapperRef}
                onClick={(event) => this.handleDateClick(event, key)}
              >
                <span className="daterangepicker__date">
                  {!isEmpty(
                    destination.startDateEng && destination.endDateEng
                  ) ? (
                    <>
                      <span>
                        <Moment format="D MMM YYYY" withTitle>
                          {destination.startDateEng}
                        </Moment>
                      </span>
                      -
                      <span>
                        <Moment format="D MMM YYYY" withTitle>
                          {destination.endDateEng}
                        </Moment>
                      </span>
                    </>
                  ) : null}
                </span>
                {isViewMode ? null : (
                  <div
                    className="daterangepicker__range"
                    ref={this.setDateRangeRef}
                    tabIndex={key}
                  >
                    <DateRange
                      startDate={
                        !isEmpty(destination.startDateEng)
                          ? moment(destination.startDateEng)
                          : moment(Date())
                      }
                      endDate={
                        !isEmpty(destination.endDateEng)
                          ? moment(destination.endDateEng)
                          : moment(Date())
                      }
                      // onInit={(range) =>
                      //   this.handleDateSelectForMultiple(range, key)
                      // }
                      onChange={(range) =>
                        this.handleDateSelectForMultiple(range, key)
                      }
                    />
                  </div>
                )}
                <span className="daterangepicker__icon">
                  <FaRegCalendarAlt />
                </span>
              </div> */}
            </div>

            {/* <div
              className={classnames({
                "travel-field small-input": true,
                "auto-error": parseFloat(destination.days) <= 0
              })}
            > */}
            <div className="travel-field small-input">
              <label>Days</label>
              <FormGroup
                name={"days"}
                className="small-input"
                type="number"
                value={!isEmpty(destination.days) ? destination.days : 0}
                onChange={(name, value) =>
                  this.handleTextChangeForMultiple(name, value, key)
                }
                disabled={isViewMode}
                minValue={1}
                maxValue={dateSelected ? selectedDateRangeDay : ""}
              />
            </div>
            <div className="travel-field small-input">
              {/* <div
              className={classnames({
                "travel-field small-input": true,
                "auto-error": parseFloat(destination.nights) <= 0
              })}
            > */}
              <label>Nights</label>
              <FormGroup
                name={"nights"}
                className="small-input"
                type="number"
                value={!isEmpty(destination.nights) ? destination.nights : 0}
                onChange={(name, value) =>
                  this.handleTextChangeForMultiple(name, value, key)
                }
                disabled={isViewMode}
                maxValue={dateSelected ? selectedDateRangeNight : ""}
                minValue={0}
              />
            </div>
          </div>

          <div className="travel-detail-row">
            <div
              className={classnames({
                "travel-field autocomplete": true,
                // "autocomplete-error":
                //   !isAllRequiredFieldValid &&
                //   isEmpty(destination.hostName) &&
                //   isEmpty(destination.hotelName)
              })}
            >
              {/* <div className="travel-field autocomplete"> */}
              <label>Host</label>

              <Autocomplete
                freeSolo
                style={{ width: 250 }}
                // getOptionSelected={(option, value) => {
                //   option.value === value.value;
                // }}
                options={travelHostList.map((option) => option.label)}
                //options={travelHostList}
                // loading={false}
                includeInputInList
                autoComplete
                // disableOpenOnFocus
                value={destination.hostName}
                // value={"jjjjjjjjjj"}
                onChange={(event, value) => {
                  let selected = travelHostList.find((x) => x.label === value);
                  this.handleHostAndHotelDropdown(
                    event,
                    selected,
                    key,
                    "selectedHost"
                  );
                }}
                disableClearable={isViewMode}
                // onChange={(event, value) => onChange(name,value,event)}
                // onInputChange={(event, newInputValue) => {
                //   this.handleTextChangeForMultiple( "selectedHost", newInputValue, key)
                // }}
                disabled={isViewMode}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={"hostName"}
                    // value={destination.hostName}
                    // placeholder={
                    //   !isEmpty(destination.hostName)
                    //     ? destination.hostName
                    //     : "Type or select"
                    // }
                    placeholder={"Type or select"}
                    variant="outlined"
                    fullWidth
                    onChange={(event) => {
                      let { name, value } = event.target;
                      this.handleTextChangeForMultiple(name, value, key);
                    }}
                    disabled={isViewMode}
                  />
                )}
                renderOption={(option) => {
                  //const { label, value } = option;
                  return (
                    <div className="input-row">
                      <span className="input-value">{option}</span>
                    </div>
                  );
                }}
              />
              {/* {!isAllRequiredFieldValid &&
              isEmpty(destination.hostName) &&
              isEmpty(destination.hotelName) ? (
                <span className="auto-error__message">required</span>
              ) : null} */}
            </div>

            <div
              className={classnames({
                "travel-field autocomplete": true,
                // "autocomplete-error":
                //   !isAllRequiredFieldValid &&
                //   isEmpty(destination.hotelName) &&
                //   isEmpty(destination.hostName)
              })}
            >
              {/* <div className="travel-field autocomplete"> */}
              <label>Hotel</label>
              <Autocomplete
                freeSolo
                style={{ width: 250 }}
                options={travelHotelList.map((option) => option.label)}
                //options={travelHotelList}
                // loading={false}
                includeInputInList
                autoComplete
                value={destination.hotelName}
                onChange={(event, value) => {
                  let selected = travelHotelList.find((x) => x.label === value);
                  this.handleHostAndHotelDropdown(
                    event,
                    selected,
                    key,
                    "selectedHotel"
                  );
                }}
                disabled={isViewMode}
                // onChange={(event, value) => onChange(name,value,event)}
                disableClearable={isViewMode}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={"hotelName"}
                    placeholder={"Type or select"}
                    variant="outlined"
                    fullWidth
                    onChange={(event) => {
                      let { name, value } = event.target;
                      this.handleTextChangeForMultiple(name, value, key);
                    }}
                    disabled={isViewMode}
                  />
                )}
                renderOption={(option) => {
                  //const { label, value } = option;
                  return (
                    <div className="input-row">
                      <span className="input-value">{option}</span>
                    </div>
                  );
                }}
              />
              {/* {!isAllRequiredFieldValid &&
              isEmpty(destination.hostName) &&
              isEmpty(destination.hotelName) ? (
                <span className="auto-error__message">required</span>
              ) : null} */}
            </div>

            <div className="travel-field">
              <label>Host or Hotel Phone Number</label>
              <FormGroup
                name={"phoneNumber"}
                placeholder="host or hotel phone number"
                type="text"
                label=""
                // error={
                //   !isAllRequiredFieldValid && isEmpty(destination.phoneNumber)
                //     ? "required"
                //     : null
                // }
                value={
                  !isEmpty(destination.phoneNumber)
                    ? destination.phoneNumber
                    : ""
                }
                onChange={(name, value) =>
                  this.handleTextChangeForMultiple(name, value, key)
                }
                disabled={isViewMode}
                width="250px"
              />
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className="travel-info-create">
        <div className="travel-create__title">
          <h3>Travel Information</h3>
        </div>
        <div className="travel-create__body">
          {/* {this.props.isAssign && (
            <div className="travel-detail-row mb-md">
              <SelectEmployee
                employeeToAssign={employeeToAssign}
                error={
                  !isAllRequiredFieldValid && isEmpty(employeeToAssign)
                    ? "required"
                    : null
                }
                onChange={this.handleDropdown}
              />
            </div>
          )} */}
          <div className="badges-group">
            <div
              className={travelClasses(1)}
              onClick={() =>
                isViewMode ? null : this.handleTravelTypeChange(1)
              }
            >
              {travelTypeId == 1 ? <IoIosCheckmarkCircle /> : null}
              Domestic travel
            </div>
            <div
              className={travelClasses(2)}
              onClick={() =>
                isViewMode ? null : this.handleTravelTypeChange(2)
              }
            >
              {travelTypeId == 2 ? <IoIosCheckmarkCircle /> : null}
              International travel
            </div>
          </div>

          {travelTypeId == 2 ? (
            <div className="travel-detail-row" style={{ alignItems: "unset" }}>
              <div className="travel-field">
                <label>Country</label>
                <FormGroup
                  formName="reactselect"
                  placeholder="Select country"
                  name="selectedCountry"
                  onChange={this.handleDropdown}
                  value={selectedCountry}
                  disabled={isViewMode}
                  options={countryList}
                  width="250px"
                />
              </div>

              <div className="travel-field">
                <label>Currency</label>
                <FormGroup
                  formName="reactselect"
                  name="selectedCurrency"
                  placeholder="Select currency"
                  value={selectedCurrency}
                  options={currencyList}
                  onChange={this.handleDropdown}
                  disabled={isViewMode}
                  width="250px"
                />
              </div>
            </div>
          ) : (
            <div className="travel-field" />
          )}

          <div className="badges-group">
            <div
              className={destinationClasses(1)}
              onClick={() =>
                isViewMode ? null : this.handleDestinationTypeChange(1)
              }
            >
              {destinationTypeId == 1 ? <IoIosCheckmarkCircle /> : null}
              Single destination
            </div>
            <div
              className={destinationClasses(2)}
              onClick={() =>
                isViewMode ? null : this.handleDestinationTypeChange(2)
              }
            >
              {destinationTypeId == 2 ? <IoIosCheckmarkCircle /> : null}
              Multiple destination
            </div>
          </div>

          {/* {Array.apply(null, { length: selectedTravelDestNo }).map((e, i) =>
            addNewPlaceBlock(i)
          )} */}
          <FlipMove>
            {selectedTravelDestList.map((data) => {
              if (!isEmpty(data.travelDestination)) {
                return addNewPlaceBlock(
                  data.key,
                  data.travelDestination,
                  isViewMode
                );
              }
            })}
          </FlipMove>
          {destinationTypeId == 2 && (
            <div style={{ display: "block", padding: "20px 0 10px" }}>
              <button
                className="btn-rounded blue left-icon"
                onClick={this.handleAddNewPlaceInformation}
              >
                <AiFillPlusCircle />
                Add place
              </button>
            </div>
          )}

          <div className="travel-field">
            {/* <label>Reason</label> */}
            <FormGroup
              formName="textarea"
              value={reason}
              placeholder="type why you are travelling.."
              name="reason"
              label="Reason"
              onChange={this.handleTextChange}
              disabled={isViewMode}
              error={
                !isAllRequiredFieldValid && isEmpty(reason) ? "required" : null
              }
            />
            {/* <textarea
              value={reason}
              name="reason"
              // className="search"
              placeholder="type why you are travelling.."
              onChange={this.handleTextChange}
              disabled={isViewMode}
            /> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  countryList: state.commonReducer.countryList,
  currencyList: state.commonReducer.currencyList,
  districtList: state.commonReducer.districtList,
  modeOfTransportList: state.travelInformationReducer.modeOfTransportList,
  travelHostList: state.travelInformationReducer.travelHostList,
  travelHotelList: state.travelInformationReducer.travelHotelList,
  travelHostAndHotelPhoneList:
    state.travelInformationReducer.travelHostAndHotelPhoneList,
  companyInformation: state.commonReducer.companyInformation,
});
const mapDispatchToProps = {
  DisplayClientSideMessage,
  GetTravelCategoryList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Create_TravelInformationTab);
