import React from 'react'
import Button from 'components/button'
import FormGroup from 'components/form'

function EditIncome({onClose}) {
    return (
        <div className="payroll-edit">
            <div className="payroll-edit__container">
                <div className="payroll-edit__header">
                    <div className="payroll-edit__header-title">
                        Basic Salary
                    </div>
                    <div className="payroll-edit__header-subtitle">
                        Fixed Income
                    </div>
                </div>
                <div className="payroll-edit__body">
                    <div className="payroll-edit__row">
                        <FormGroup 
                            name="current_amount"
                            value="1000"
                            label="Current Amount"
                            disabled
                            onChange={(name,value)=>console.log(value)}
                            alignment="horizontal"
                        />
                        <FormGroup 
                            name="new_amount"
                            value="3000"
                            label="New Amount"
                            onChange={(name,value)=>console.log(value)}
                            alignment="horizontal"
                        />
                        <FormGroup 
                            name="Effective From"
                            formName="customdateinput"
                            value=""
                            label="Effective from"
                            onChange={(name,value)=>console.log(value)}
                            alignment="horizontal"
                        />
                    </div>
                    <div className="payroll-edit__row">
                        <div className="">
                            <div className="payroll-edit__lab">
                                <span>This will also change</span>
                            </div>
                            <div className="payroll-edit__val">
                                <span>Provident Fund</span>
                            </div>
                        </div>
                    </div>
                    <div className="payroll-edit__row payroll-edit__row-vert">
                        <FormGroup 
                            name="reason"
                            formName="reactselect"
                            value=""
                            label="Reason For Change"
                            onChange={(name,value)=>console.log(value)}
                            options={[{label:'Promotions',value:'Promotions'}]}
                        />
                    </div>
                </div>
            </div>
            <div className="payroll-edit__footer">
                <Button bg="primary" title="Save" />
                <Button bg="subtle" onClick={()=>onClose()} title="Cancel" />
            </div>
        </div>
    )
}

export default EditIncome
