import React, { Component } from "react";
// import InputGroup from "../../../Components/Form/InputGroup";
import Form from "components/form";

export class SearchAndFilter extends Component {
  render() {
    const { searchEmployee, handleChange } = this.props;
    return (
      <div className="filters">
        <div className="filters-grid">
          <Form
            formName="textinput"
            type="text"
            name="searchEmployee"
            value={searchEmployee}
            onChange={handleChange}
            placeholder="Search Employee"
          />
        </div>
      </div>
    );
  }
}

export default SearchAndFilter;
