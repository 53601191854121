import React from 'react';
import {contentTypes} from 'constants/enums';
import { FaRegFileImage, FaRegFilePdf, FaRegFileVideo, FaYoutube } from 'react-icons/fa'
import { FiFileText } from 'react-icons/fi';
import { ImAttachment } from 'react-icons/im';
import { BsFillQuestionSquareFill } from 'react-icons/bs'

interface contentInter {
    title: string,
    icon: any,
    id: number | string,
    type: string
}

export const contents: Array<contentInter> = [
    {
        title: 'Text',
        icon: <FiFileText />,
        id: contentTypes.text.id,
        type: contentTypes.text.title
    },
    {
        title: 'Image',
        icon: <FaRegFileImage />,
        id: contentTypes.image.id,
        type: contentTypes.image.title
    },
    {
        title: 'Video',
        icon: <FaRegFileVideo />,
        id: contentTypes.video.id,
        type: contentTypes.video.title
    },
    {
        title: 'Video Link',
        icon: <FaYoutube />,
        id: contentTypes.video_link.id,
        type: contentTypes.video_link.title
    },
    {
        title: 'PDF',
        icon: <FaRegFilePdf />,
        id: contentTypes.pdf.id,
        type: contentTypes.pdf.title
    },
    {
        title: 'Attachment',
        icon: <ImAttachment />,
        id: contentTypes.attachment.id,
        type: contentTypes.attachment.title
    }
]

export const questionContent = {
    title: 'Question',
    icon: <BsFillQuestionSquareFill />,
    id: 6,
    type: contentTypes.questions
}