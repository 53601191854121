import Button from 'components/button'
import React, { useEffect, useState } from 'react'
import Form from 'components/form';
import { AgGridReact } from 'ag-grid-react';
import {CustomCheck} from '../common/tableComponents'
import { useDispatch, useSelector } from 'react-redux';
import { assignCourse, getBranchTable } from '../helpers/action';
import CommonPagination from "components/common/pagination";
import DataSelect from 'components/dataselect';


const columnDefs = [
    {field:'Id',cell:CustomCheck},
    {field: 'Province'},
    {field: 'Branch'},
    {field: 'Users'},
];

// const data = [
//     {Province:'Bagmati',Branch:'Kathmandu',Users:'1'},
//     {Province:'Bagmati',Branch:'Bhaktapur',Users:'2'},
// ]

const agSettings ={
    domLayout: 'autoHeight',
    defaultColDef: {
      width: 145,
      resizable: false,
    },
    getRowHeight: function(params) {
        return 58;
    },
}

const initialState = {
    provinceId:null,
}

const initialPage = {
    "pageIndex": 0,
    "rowsPerPage": 10,
}

function Branches(props) {
    const [gridApi,setGridApi] = useState(null);
    const [state,setState] = useState(initialPage);
    const [filter,setFilter] = useState(initialState)
    const [data,setData] = useState([]);
    const [selectedRow,setSelectedRow] = useState([]);
    const {branchesTable,assignCourses} = useSelector((state:any)=>state.lmsReducer)
    const dispatch = useDispatch();
    const {courseId} = props.match.params;
    // console.log(selectedRow)
    const onGridReady = params => {
        setGridApi(params.api);
    };
    const onSelectionChanged = () => {
        var selectedRows = gridApi.getSelectedRows();
        setSelectedRow(selectedRows);
        // document.querySelector('#selectedRows').innerHTML =
        //   selectedRows.length === 1 ? selectedRows[0].athlete : '';
    };
    const onSelect = (item,checked) =>{
        if(checked){
            setSelectedRow(prev=> [item,...prev])
        }else{
            setSelectedRow(prev=>(prev.filter(d=>d.Id !== item.Id)))
        }
    }
    const onChange = (name,value) =>{
        setFilter(prev=>{
            return{
                ...prev,
                [name]:value
            }
        })
    }
    useEffect(()=>{
        getBranches()
    },[state,filter]);
    useEffect(()=>{
        if(branchesTable){
            setData(branchesTable)
        }
        if(assignCourses){
            setSelectedRow(assignCourses)
        }
        if(assignCourses && branchesTable){
            const assignedIds = assignCourses.map(item=>item.BranchId);
            const assingnedEmployees = branchesTable.map(item=>{
                if(assignedIds.includes(item.BranchId)){
                    console.log("Here Braanch")
                    return {...item,isChecked:true}
                }
                return {...item,isChecked:false}
            })
            setData(assingnedEmployees)
            // EmployeeId branchesTable
        }
    },[assignCourses,branchesTable])
    const getBranches = () =>{
        dispatch(getBranchTable({...state,provinceId:filter.provinceId ? filter.provinceId.value :-1}));
    }
    const handleRowChange = (num) =>{
        setState(prev=>({
            ...prev,
            ['rowsPerPage']:num
        }))
    }
    const handleIndexChange = (num) =>{
        setState(prev=>({
            ...prev,
            ['pageIndex']:num
        }))
    }
    const onAssign = () =>{
        let data = [];
        selectedRow && selectedRow.filter(item=>item.BranchId !== null).map(item=>{
            data = [
                ...data,
                {
                    "id": 0,
                    "courseId": courseId,
                    "employeeId": null,
                    "branchId": item.BranchId,
                    "departmentId": null
                }
            ]
        })
        if(data){
            dispatch(assignCourse(data))
            setSelectedRow([])
        }
    }
    return (
        <div className="course-users course-assign__common">
            <div className="course-assign__actions">
                <div className="course-assign__filters">
                    <DataSelect
                        onChange={onChange}
                        value={filter.provinceId}
                        label="Province"
                        name="provinceId"
                        type=''
                        actionType="provinceList"
                        width="200px"
                    />
                </div>
                <Button onClick={()=>onAssign()}  bg="primary" title='Assign to selected Users' />
            </div>
            <div className="course-assign__table">
                <div
                    id="myGrid"
                    style={{
                    height: '100%',
                    width: '100%',
                    }}
                    className="ag-theme-alpine"
                >
                    <AgGridReact
                        frameworkComponents={{ CustomCheck: (props) =><CustomCheck {...props} onSelect={onSelect} setSelectedRow={setSelectedRow} selectedRow={selectedRow} /> }}
                        columnDefs={[
                            {field: 'ID',cellRenderer: 'CustomCheck',},
                            {field: 'ProvinceName',headerName:'Province'},
                            {field: 'BranchName',headerName:'Branch'},
                            {field: 'TotalEmployee',headerName:'Users'},
                        ]}
                        {...agSettings}
                        onGridReady={onGridReady}
                        rowData={data}
                        rowSelection={'multiple'}
                        suppressRowClickSelection={true}
                    />
                </div>
                {branchesTable && branchesTable.length > 0 && (
                    <CommonPagination
                        handleRowChange={handleRowChange}
                        handleIndexChange={handleIndexChange}
                        pageIndex={state.pageIndex}
                        rowsPerPage={state.rowsPerPage}
                        total={branchesTable[0].TotalRows}
                    />
                )}
            </div>
        </div>
    )
}

export default Branches
