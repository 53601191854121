import React from 'react';
import shortid from "shortid";
import { useSelector } from 'react-redux';
import {KNOWLEDGE_SKILL_ABILITY_TYPE} from 'constants/constants';



            
function JobScore() {
    let ksaList=[];
    const jobDescriptionDetails = useSelector(state => state.employeeProfile.employeeAssignedJobDescription);
    let sections=jobDescriptionDetails?.Sections?.find(c=>c.SectionTypeName===KNOWLEDGE_SKILL_ABILITY_TYPE);

   
    let test=sections?.Components?.map(x=>{
                    x.KSARatings.map(ksa=>{
                        ksaList.push({id:ksa.KSARefId,name:ksa.KSAStatement,score:ksa.RatingValue});
                    });
                return null;
                });
             
    return (
        <div className="jobdesc-blocks">
            <div className="jobdesc-block">
                <div className="jobdesc-title">
                    <h3>Expected KSA Scale</h3>
                </div>
                <div className="jobdesc-block__body">
                    <span>
                        This paragraph is not visible to employee. It will only visible to managers and administrators for evaluation purpose only
                    </span>
                    <div className="jobdesc-block__score">
                        <ul className="ksa-block__list">
                            {
                                ksaList.map(ScoreCard)
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <div className="jobdesc-block">
                <div className="jobdesc-title">
                    <h3>Scope and impact</h3>
                </div>
                <div className="jobdesc-block__body">
                    <div>
                        Descision for purchanse of upto 10,000
                    </div>
                    <div>
                        join check signature along the chief of operation
                    </div>
                    
                </div>
            </div>

        </div>

    )
}


function ScoreCard(item,index) {
    return(
        <li key={index + Math.random()} className="ksa-block__item ksa-item">
            <div className="ksa-item__num">
                <span className="badge">{index + 1}</span>
            </div>
            <div className="ksa-item__name">
                <span>
                    {item.name}
                </span>
            </div>
            <div className="ksa-item__score">
                <Score score={item.score} required={item.min} />
            </div>
        </li>
    )
}

const Score = ({score,required,max=6}) =>{
    const per = (score * 100) / max;
    return(
        <div className="score">
            <div className="score-back">
                <div className={"score-back-score"} style={{width:`${per}%`,backgroundColor:'rgb(142, 151, 169)'}}>
                    <span>
                        {/* {score} */}
                    </span>
                </div>
            </div>
        </div>
    )

}


export default JobScore
