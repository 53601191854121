import React, { Component } from "react";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    Grid,
} from "@syncfusion/ej2-react-grids";
import store from '../../../store/configureStore';
import { Provider } from 'react-redux';
import Form from './form'
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEmpty from "isEmpty";
import Loader from '../../../components/loaders'
import Moment from 'react-moment';
import {deleteStopPayment} from './action'
import CommonPagination from '../../../components/common/pagination'
import Modal from '../../../components/common/modal'


export class Table extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen:false,
            delid:null
        };
        this.onModalClose=this.onModalClose.bind(this);
        this.template = this.gridTemplate;
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.toolbarClick = this.toolbarClick.bind(this);
    }
    componentDidMount() {
        this.grid = Grid || null;
        this.toolbar = ["ExcelExport"];
    }
    toolbarClick = id => {
        if (this.grid && id === "grid_excelexport") {
            this.grid.excelExport();
        }
    };
    onModalClose=()=>{
        console.log('jere')
        this.setState({modalOpen:false});
    }
    gridTemplate(props) {
        return (
            <tr className="leave-row">
                <td className="">
                    <a href="#">
                        <span>{props.EmployeeId}</span>
                    </a>
                </td>
                <td className="">
                    <a
                        href={
                            "/newhr/EmployeeDetails.aspx?Id=" + props.EmployeeId
                        }
                        target="_blank"
                    >
                        <span>{props.EmployeeName}</span>
                    </a>
                </td>
                <td className="">
                    <span>
                    <Moment format="YYYY/MM/DD">
                        {props.FromDate}
                    </Moment>
                    </span>
                </td>
                <td className="">
                    <span>
                    <Moment format="YYYY/MM/DD">
                        {props.EngFromDate}
                    </Moment>
                    </span>
                </td>
                <td className="">
                    <span>
                        <Moment format="YYYY/MM/DD">
                            {props.ToDate}
                        </Moment>
                    </span>
                </td>
                <td className="">
                    <span>
                        <Moment format="YYYY/MM/DD">
                            {props.EngToDate}
                        </Moment>
                    </span>
                </td>
                {/* <td className="">
                    <span>{props.ExcludePeriodInWorkDaysCount?'True':"False"}</span>
                </td> */}
                <td
                    className={
                        props.ExcludePeriodInWorkDaysCount
                            ? "leave-status success"
                            : "leave-status danger"
                    }
                >
                    <span className="leave-icon">
                        {props.ExcludePeriodInWorkDaysCount ? (
                            <FaCheckDouble />
                        ) : (
                            <FaRegTimesCircle />
                        )}
                    </span>
                    <span>{props.Status}</span>
                </td>
                <td>
                    <span>
                        {props.Notes}
                    </span>
                </td>
                <td>
                    <div className="button-group" style={{margin:0,padding:0}}>
                        {/* <button 
                            onClick={()=>this.onEdit()}
                            className="btn btn-primary">
                                Edit
                        </button> */}
                        <Provider store={store}>
                            <Form isEdit toEdit={props} onFilter={this.props.onFilter}/>
                        </Provider>
                        
                        <button 
                            onClick={()=>this.setState({modalOpen:true,delid:props.StopPaymentId})}
                            className="btn btn-del">
                            Delete
                        </button>
                        
                    </div>
                </td>
            </tr>
        );
    }
    onDelete(){
        this.props.deleteStopPayment(this.state.delid);
        this.setState({delid:null})
    }
    handleChangePage = (event, newPage) => {
        this.props.handleIndexChange(newPage);
    };

    handleChangeRowsPerPage = event => {
        this.props.handleIndexChange(0);
        this.props.handleRowChange(event.target.value);
    };
    render() {
        const {
            searchStopPayment,
            searchedPage,
            loading
        } = this.props.stopPaymentReducer;
        
        return (
            <div>
                <div className="arrear-table">
                    {loading || searchStopPayment===null ?(
                        <Loader open={loading} />
                    ) : (
                        <div className="payroll-grid">
                            <GridComponent
                                dataSource={searchStopPayment}
                                //  height={!isEmpty(searchedLeaveAbsence)?"500":'80'}
                                id="grid"
                                className="custom-grid"
                                allowExcelExport={true}
                                ref={g => (this.grid = g)}
                                rowTemplate={this.template.bind(this)}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective
                                        field="EIN"
                                        width="55"
                                    />
                                    <ColumnDirective 
                                        field="EmployeeName" 
                                        headerText="Name" 
                                        width="120" 
                                    />
                                    <ColumnDirective
                                        field="FromDateNep"
                                        width="100"
                                        textAlign="Center"
                                        headerText="From Date"
                                    />
                                    <ColumnDirective
                                        field="EngFromDate"
                                        width="100"
                                        headerText="From Date Eng"
                                    />
                                    <ColumnDirective
                                        field="ToDate"
                                        width="100"
                                        headerText="To Date"
                                    />
                                    <ColumnDirective
                                        field="EngToDate"
                                        width="100"
                                        headerText="To Date Eng"
                                    />
                                    <ColumnDirective
                                        field="ExcludePeriodInWorkDaysCount"
                                        headerText="Workingdays Ded"
                                        width="80"
                                    />
                                    <ColumnDirective
                                        field="Notes"
                                        headerText="Notes"
                                        width="160"
                                    />
                                    <ColumnDirective
                                        field=""
                                        headerText=""
                                        width="220"
                                    />
                                </ColumnsDirective>
                            </GridComponent>
                            {isEmpty(searchStopPayment) ? null : (
                                <CommonPagination 
                                    handleRowChange={this.props.handleRowChange}
                                    handleIndexChange={this.props.handleIndexChange}
                                    pageIndex={this.props.pageIndex}
                                    searchedPage={searchedPage}
                                    rowsPerPage={this.props.rowsPerPage}
                                />
                                // <div className="paginate">
                                //     <TablePagination
                                //         rowsPerPageOptions={[10, 25, 100, 200]}
                                //         component="div"
                                //         count={searchedPage.total}
                                //         rowsPerPage={this.props.rowsPerPage}
                                //         page={this.props.pageIndex}
                                //         onChangePage={this.handleChangePage}
                                //         onChangeRowsPerPage={
                                //             this.handleChangeRowsPerPage
                                //         }
                                //     />
                                //     <MuiThemeProvider theme={theme}>
                                //         <CssBaseline />
                                //         <Pagination
                                //             limit={this.props.rowsPerPage}
                                //             offset={
                                //                 this.props.rowsPerPage *
                                //                 this.props.pageIndex
                                //             }
                                //             total={searchedPage.total}
                                //             textPrimary="#2680eb"
                                //             otherPageColor={"#646464"}
                                //             onClick={(de, offset) =>
                                //                 this.props.handleIndexChange(
                                //                     offset /
                                //                         this.props.rowsPerPage
                                //                 )
                                //             }
                                //         />
                                //     </MuiThemeProvider>
                                // </div>
                            )}
                        </div>
                    )}
                </div>
                <Modal type="confirm" 
                    open={this.state.modalOpen}
                    onModalClose={this.onModalClose} 
                    func={()=>this.onDelete()}
                />
            </div>
        );
    }
}

Table.propTypes = {
    stopPaymentReducer: PropTypes.object.isRequired,
    deleteStopPayment:PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    stopPaymentReducer: state.stopPaymentReducer
});

export default connect(mapStateToProps,{deleteStopPayment})(Table);
