import React from "react";
import Form from "components/form";

function Approvers({ data, onChange }) {
  return (
    <div className="homeloan-propertydetail">
      <div className="">
        <div className="">
          <div className="form-row ml-sm mr-sm items-start">
            <Form
              formName="checkBoxGroup"
              name="ForwardTo"
              label="Human Resource Department"
              checkGroupArray={data.loanApproverList}
              checkedGroupValue={data.ForwardTo}
              onChange={(name, value) => onChange(name, value)}
              multiple={false}
              validators={["required"]}
              error={data?.errors && data?.errors.ForwardTo}
              disabled={data.EditView && !data.IsDraft}
              // disabled={data?.EditView }
              width="100%"
            />
          </div>
          <div className="homeloan-propertydetail-form mb-md flex homeloan-termsCond">
            <label class="checkbox-container">
              I hereby declare that all the particulars given in the application
              form are correct, complete and up to date in all respect and I
              have not withheld any information. I agree to provide sufficient
              mortgage to the bank. In case it is found that mortgage property
              to the bank has claim with any other authority for recovering any
              liability, the bank may ask to adjust the loan in full. I hereby
              agree that at any given time of inspection if it is found that the
              loan already disbursed to me has not been satisfactory utilized,
              the bank may withhold the disbursement of the subsequent
              installments. I hereby agree to accept the valuation done by the
              Bank's approved valuator.
              <input
                type="checkbox"
                checked={data.TermsCond}
                onChange={() =>
                  onChange("TermsCond", !data.TermsCond || data.EditView)
                }
              />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Approvers;
