import React from 'react'
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';

function AddressGeoLocation(props) {
    const geoSplit = props.GeoLocation ? props.GeoLocation.split(",") : [];
    console.log(geoSplit)
    if(geoSplit.length < 1){
        return (<div className="nodata"><h2>Invalid GeoLocation</h2></div>)
    }
    const lat = parseFloat(geoSplit[0]);
    const long = parseFloat(geoSplit[1]);
    return (
        <Map google={props.google}
            initialCenter={{
            lat: lat,
            lng: long
          }}
        
        >
      </Map>
    )
}

const LoadingContainer = (props) => (
    <div>Fancy loading container!</div>
)
console.log(process.env.REACT_APP_GOOGLE_MAP_API)
export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API,
    LoadingContainer: LoadingContainer
})(AddressGeoLocation)
