import React from "react";
import FormGroup from "components/form";
import ExportExcel from "components/excel/exportExcel";
import isEmpty from "helpers/isEmpty";
import { JobStageList, JobStages } from "../../enums";

export default function SearchAndFilter(props: any) {
  const {
    internalJobId,
    candidateName,
    externalJobTitle,
    level,
    stage,
    mobile,
    email,
    handleInputChange,
    handleExcelExport,
    exportName,
    exportColumns,
    exportData,
    levels,
  } = props;

  const getValue = (val: any) => {
    if (!isEmpty(val)) {
      return val;
    }
    return [];
  };

  const getStages = () => {
    return [
      ...JobStageList,
      { label: "Disqualified", value: JobStages.Disqualified },
      { label: "Reject", value: JobStages.Reject },
    ];
  };
  const getDropDownValues = (data) => {
    let a = [];
    let d = data?.value?.forEach((item) =>
      a.push({ label: item.Text, value: item.Value })
    );
    return a;
  };

  return (
    <div className="filters">
      <div className="filters-grid">
        <FormGroup
          type="text"
          onChange={handleInputChange}
          name="internalJobId"
          value={internalJobId}
          placeholder="Internal Job Id"
          //width="200px"
        />
      </div>
      <div className="filters-grid">
        <FormGroup
          type="text"
          onChange={handleInputChange}
          name="candidateName"
          value={candidateName}
          placeholder="Candidate Name"
          width="250px"
        />
      </div>
      <div className="filters-grid">
        <FormGroup
          onChange={handleInputChange}
          name="externalJobTitle"
          value={externalJobTitle}
          type="text"
          placeholder="External Job Title"
          // width="200px"
        />
      </div>
      <div className="filters-grid">
        <FormGroup
          formName="reactselect"
          onChange={handleInputChange}
          value={getValue(level)}
          options={getDropDownValues(levels)}
          placeholder="Level"
          name="level"
          width="250px"
          loading={levels.loading}
          loadingType="skeleton"
        />
      </div>
      <div className="filters-grid">
        <FormGroup
          type="text"
          onChange={handleInputChange}
          name="mobile"
          value={mobile}
          placeholder="Mobile"
          //width="200px"
        />
      </div>
      <div className="filters-grid">
        <FormGroup
          type="text"
          onChange={handleInputChange}
          name="email"
          value={email}
          placeholder="Email"
          //width="200px"
        />
      </div>
      <div className="filters-grid">
        <FormGroup
          formName="reactselect"
          onChange={handleInputChange}
          value={getValue(stage)}
          options={getStages()}
          placeholder="Stage"
          name="stage"
          // width="250px"
        />
      </div>

      <div className="library">
        <ExportExcel
          onClick={handleExcelExport}
          fileName={exportName}
          columns={exportColumns}
          sheets={[{ sheetName: exportName, data: exportData }]}
          className="button button-subtle"
        />
      </div>
    </div>
  );
}
