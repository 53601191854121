import React, { Component } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Filter,
  Inject,
  Sort,
  // SortSettingsModel,
} from "@syncfusion/ej2-react-grids";
import Pagination from "components/common/pagination";
import TableRows from "./tableRow";

export class ActivityReportTable extends Component {
  constructor(props) {
    super(props);
    this.widths = {
      employeeName: 200,
      department: 140,
      activityNotSubmitted: 120,
      workDays: 100,
    };

    this.gridTemplate = this.gridTemplate;
    this.row = [];
  }

  gridTemplate(props, history) {
    return (
      <TableRows
        {...props}
        {...history}
        activeTab={this.props.activeTab}
        ref={(row) => {
          this.row = [...this.row, row];
        }}
      />
    );
  }

  render() {
    const {
      data,
      handleRowChange,
      handleIndexChange,
      pageIndex,
      rowsPerPage,
    } = this.props;

    return (
      <div className="default-table">
        <div className="pending-settlement-grid">
          <GridComponent
            dataSource={data}
            className="custom-grid"
            // toolbar={this.toolbar}
            // filterSettings={{ type: "Menu" }}
            // allowFiltering={true}
            //  height={!isEmpty(searchedLeaveAbsence)?"500":'80'}
            id="grid"
            // allowExcelExport={true}
            ref={(g) => (this.grid = g)}
            rowTemplate={this.gridTemplate.bind(this)}
            allowSorting={true}
            // sortSettings={this.sortingOptions}
          >
            <ColumnsDirective>
              <ColumnDirective width={30} />
              <ColumnDirective
                field="EmployeeName"
                headerText="Employee Name (EIN)"
                width={this.widths.employeeName}
              />
              <ColumnDirective
                field="DepartmentName"
                headerText="Department"
                width={this.widths.department}
              />
              <ColumnDirective
                field="ActivityNotSubmittedDays"
                headerText="Activity Not Submitted"
                width={this.widths.activityNotSubmitted}
              />
              <ColumnDirective
                field="WorkDays"
                headerText="Work Days"
                width={this.widths.workDays}
              />

              <ColumnDirective width={100} />
            </ColumnsDirective>
            <Inject services={[Sort]} />
          </GridComponent>

          {data && data.length > 0 && (
            <Pagination
              handleRowChange={handleRowChange}
              handleIndexChange={handleIndexChange}
              pageIndex={pageIndex}
              rowsPerPage={rowsPerPage}
              total={data[0].TotalRows}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ActivityReportTable;
