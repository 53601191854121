import React, { Component } from "react";
import { Tabs, Tab } from "components/tabs/index.d";
import { Enum_HolidaysCategory } from "./enums";
import { formatedToMonthDay, formatToNepaliMonthDay } from "helpers/dateFormat";

const tabs = [
  { tabId: 1, label: "This Month", key: "ThisMonth" },
  { tabId: 2, label: "Next Month", key: "NextMonth" },
  { tabId: 3, label: "This Year", key: "Year" },
];
export class Holidays extends Component {
  state = {
    activeTab: tabs[0],
    pageIndex: 0,
    pageSize: 10000,
  };

  componentDidMount() {
    this.getCompanyHolidays();
  }

  getCompanyHolidays = async () => {
    const { activeTab, pageIndex, pageSize } = this.state;
    const holidayCategory = Enum_HolidaysCategory[activeTab.key];

    const data = {
      holidayCategory,
      pageIndex,
      pageSize,
    };
    await this.props.GetCompanyHolidays(data);
  };

  handleTabChange = (tab) => {
    this.setState({ activeTab: tab }, () => {
      this.getCompanyHolidays();
    });
  };

  render() {
    const { activeTab } = this.state;
    const { companyHolidays, companyInformation } = this.props;
    
    return (
      <div className="holidays leave-block">
        <div className="bloch__title">Holidays</div>

        <Tabs>
          {tabs.map((tab, index) => {
            return (
              <Tab
                label={tab.label}
                name={tab.tabId}
                id={tab.tabId}
                key={index}
                isActive={activeTab.tabId === tab.tabId}
                onClick={() => this.handleTabChange(tab)}
              >
                <div className="holidays-body">
                  {companyHolidays &&
                    companyHolidays.map((holiday, index) => {
                      return (
                        <div className="holidays-body-row">
                          <div className="holidays-body-row-date">
                            {companyInformation &&
                            !companyInformation.IsEnglishDate
                              ? formatToNepaliMonthDay(holiday.Date)
                              : formatedToMonthDay(holiday.EngDate)}
                          </div>
                          <div className="holidays-body-row-reason">
                            {holiday.Description}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </Tab>
            );
          })}
        </Tabs>
      </div>
    );
  }
}

export default Holidays;
