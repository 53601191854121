import React,{useState,} from 'react'
import './style.scss';
import FormGroup from 'components/form'
import Button from 'components/button'
import Icons from 'components/icons'
import { connect } from "react-redux";
import {
    saveEmployeeFamilyInfo,
    updateEmployeeFamilyInfo,
    GetEmployeeFamilies
} from '../action';
import { InputValidator } from "helpers/inputValidator";
import { DisplayClientSideMessage } from "actions/commonAction";
import isEmpty from "helpers/isEmpty";
import { monthsValue } from 'constants/ydmValue';
import { Nepali_Date_Format } from "helpers/dateFormat";
import { AD2BS } from "helpers/dateConvert";
import { isDateTodayOrAfter } from "helpers/dateFormat";

const maritalStatusOptions = [
    {label:'Single',value:'Single'},
    {label:'Married',value:'Married'},
    
]
const childrenOptions = [
    {label:'Yes',value:'Yes'},
    {label:'No',value:'No'},
    
]

interface validatingField {
    father: any,
    mother: any,
    grandFather,
    maritalStatus: any,
    spouse: any,
    children,
    childrenList?: any,
}
const childOptions = [
    {label:'Daughter',value:'Daughter'},
    {label:'Son',value:'Son'},
    
]

const bloodOptions = [
    {label:'A+',value:'A+'},
    {label:'A-',value:'A-'},
    {label:'B+',value:'B+'},
    {label:'B-',value:'B-'},
    {label:'O+',value:'O+'},
    {label:'O-',value:'O-'},
    {label:'AB+',value:'AB+'},
    {label:'AB-',value:'AB-'},
]

const getChildTemplete = (id, type) => {
    return { 
        id: id,
        new: true,
        type: type,
        name: null,
        dob: {
        },
    }
}

interface IAppProps {
    saveEmployeeFamilyInfo?: Function,
    employeeFamilyInfoSaved?: any,
    DisplayClientSideMessage?: Function,
    EmployeeId?: any,
    GetEmployeeFamilies?: any,
    toggleEditView?: any,
    editView?: any,
    updateEmployeeFamilyInfo: Function,
    editViewData: any,
}
interface State {
    father: any,
    mother: any,
    grandFather: any,
    maritalStatus: any,
    spouse: any,
    children: any,
    childrenList: any,
    errors: any,
    editView: any,

}
class App extends React.Component<IAppProps, State> {
    state = {
        father: {
            Name: null,
            FamilyId: 0,
        },
        mother: {
            Name: null,
            FamilyId: 0,
        },
        grandFather: {
            Name: null,
            FamilyId: 0,
        },
        maritalStatus: null,
        spouse: {
            Name: null,
            FamilyId: 0,
        },
        children: null,
        childrenList: [],
        errors: null,
        editView: false,
    }

    async componentDidMount () {
        const { editView, editViewData } = this.props;
        if (editView && editViewData) {
            let data = {
                editView: true,
                father: {
                    Name: null,
                    FamilyId: 0,
                },
                mother: {  
                    Name: null,
                    FamilyId: 0,
                },
                grandFather: {
                    Name: null,
                    FamilyId: 0,
                },
                maritalStatus: "Single",
                spouse: {
                    Name: null,
                    FamilyId: 0,
                },
                children: null,
                childrenList: [],
            }
            editViewData.forEach(f => {
                if (f.Relation === 'Father'){
                    data.father.Name = f.Name
                    data.father.FamilyId = f.FamilyId
                } else if (f.Relation == 'Mother') {
                    data.mother.Name = f.Name
                    data.mother.FamilyId = f.FamilyId
                    
                } else if (f.Relation == 'GrandFather') {
                    data.grandFather.Name = f.Name
                    data.grandFather.FamilyId = f.FamilyId
                    
                } else if (f.Relation == 'Spouse') {
                    data.maritalStatus = maritalStatusOptions[1].label;
                    data.spouse.Name = f.Name
                    data.spouse.FamilyId = f.FamilyId
                    
                } else {
                    let dob = new Date(f.DateOfBirthEng);
                    let dobDay = dob.getDate();
                    let dobMonth = dob.getMonth();
                    let dobYear = dob.getFullYear();

                    data.children = childrenOptions[0].label;
                    data.childrenList.push({
                        id: f.FamilyId,
                        type: f.Relation == 'Son' ? childOptions[1].label : childOptions[0].label,
                        name: f.Name,
                        new: false,
                        dob: {
                            days: { label: dobDay, value: dobDay },
                            months: monthsValue[dobMonth],
                            years: { label: dobYear, value: dobYear },
                        }
                    })
                }
            });
            this.setState(data);
        }
    }

    handleFieldChange = (name: string, value: any) => {
        if (name  == 'children') {
            let { childrenList } = this.state;
            if (childrenList.length == 0 && value == childrenOptions[0].value) {
                this.setState({ childrenList: [getChildTemplete(0, 'Daughter')], [name]: value })
            } else {
                this.setState({ childrenList: [], [name]: value })
            }
        } else if(name == 'maritalStatus') {
            this.setState({ [name]: value })
        } else {
            this.setState({
                [name]: {
                    ...this.state[name],
                    Name: value,
                },
                errors: {
                ...this.state.errors,
                [name]: null,
                },
            } as Pick<State, keyof State>);
        }
    };

    addChildrenField = (name, value) => {
        let { childrenList } = this.state;
        const id = childrenList[childrenList.length - 1].id + 1;
        childrenList.push(getChildTemplete(id, value));
        this.setState({ childrenList });
    }

    removeChildrenField = (id) => {
        let { childrenList } = this.state;
        if (childrenList.length > 1) {
            childrenList = childrenList.filter((f) => f.id != id);
            this.setState({ childrenList });
        } else {
            this.props.DisplayClientSideMessage(
            "At least one child is required"
            );
        }
    }

    handleChildFieldChange = (name, value, id) => {
        let { childrenList } = this.state;
        let selectedChild = childrenList.find((f) => f.id == id);
        selectedChild[name] = value;
        this.setState({ childrenList });
    }

    handleSave = async () => {
        const {
            father, mother, grandFather,
            maritalStatus, spouse, children,
            childrenList, editView,
        } = this.state;
        let fieldsToValidate : validatingField = {
            father, mother, grandFather,
            maritalStatus, spouse, children,
            childrenList
        }
        
        if (children == childrenOptions[0].value) {
            fieldsToValidate = {
                ...fieldsToValidate,
                childrenList
            }
        }
        let errors = await InputValidator(document, fieldsToValidate);
        let isMonthValid = false;
        let nameValid = false;
        childrenList.forEach((child) => {
            if (isEmpty(child.dob)) {
                isMonthValid = true
            } else {
                if (isEmpty(child.dob.months)) isMonthValid = true;
                if (isEmpty(child.dob.years)) isMonthValid = true;
                if (isEmpty(child.dob.days)) isMonthValid = true;
            }
            if (isEmpty(child.name))
                nameValid = true;
        });
        if (isMonthValid) {
            this.props.DisplayClientSideMessage(
                "One of the children Date of Birth is missing"
            );
            return;
        }
        let errField = {
            father: null,
            mother: null,
            grandFather: null,
            spouse: null,
            error: false,
        };
        if (isEmpty(father.Name)) { errField.father = 'Required'; errField.error = true }
        if (isEmpty(mother.Name)) { errField.mother = 'Required'; errField.error = true }
        if (isEmpty(grandFather.Name)) { errField.grandFather = 'Required'; errField.error = true }
        if (maritalStatus == maritalStatusOptions[1].label &&
            isEmpty(spouse.Name)) { errField.spouse = 'Required'; errField.error = true }
        if(errField.error) {
            this.setState({ errors: errField })
            return;
        };

        if (nameValid) {
            this.props.DisplayClientSideMessage(
                "One of the children Name is missing"
            );
            return;
        }
        if (isEmpty(errors)) {
            let validDOB = false;
            let familyData = [];
            let familyKey = [ 'father', 'mother', 'grandFather' ];
            let relationshipArray = ['Father', 'Mother', 'GrandFather', 'Spouse']
            if (maritalStatus == maritalStatusOptions[1].label) familyKey.push('spouse');
            familyKey.forEach((f, i) => {
                familyData.push({
                    "familyId": editView ? fieldsToValidate[f].FamilyId : 0,
                    "employeeId": this.props.EmployeeId,
                    "name": fieldsToValidate[f].Name,
                    "relation": relationshipArray[i],
                    "relationID": 0,
                })
            })
            if (children == childrenOptions[0].label) {
                childrenList.forEach(child => {
                    let dobMonth = child.dob.months.value - 1;
                    let dobYear = child.dob.years.value;
                    let dobDate = child.dob.days.value;

                    let fullDOB = new Date().setFullYear(dobYear);
                        fullDOB = new Date(fullDOB).setMonth(dobMonth);
                        fullDOB = new Date(fullDOB).setDate(dobDate);
                    let convertedFullDOB = Nepali_Date_Format(fullDOB);
                    let conversionDate = AD2BS(convertedFullDOB).en;
                    let futureDate = new Date(new Date().setDate(new Date().getDate() + 1));
                    if (isDateTodayOrAfter(convertedFullDOB, futureDate)) validDOB = true;
                    let nepaliDate = `${conversionDate.year}/${conversionDate.month}/${conversionDate.day}`
                    
                    familyData.push({
                        "familyId": child.new ? 0 : child.id,
                        "employeeId": this.props.EmployeeId,
                        "name": child.name,
                        "relation": child.type,
                        "relationID": 0,
                        "dateOfBirth": nepaliDate,
                        "dateOfBirthEng": convertedFullDOB,
                    })
                })
            }
            if (validDOB) {
                this.props.DisplayClientSideMessage(
                    "Children Date of Birth can't be future date"
                );
                return;
            }
            const { updateEmployeeFamilyInfo, saveEmployeeFamilyInfo,
                GetEmployeeFamilies, toggleEditView, EmployeeId } = this.props;
            if (editView) await updateEmployeeFamilyInfo(familyData);
            else await saveEmployeeFamilyInfo(familyData);

            if (this.props.employeeFamilyInfoSaved) {
                GetEmployeeFamilies(EmployeeId);
                toggleEditView(false);
            }

        } else {
            this.setState({ errors });
            let message = {
                MessageType: "Danger",
                Message: ["Please fill all the required fields."],
                Status: false,
            };
            this.props.DisplayClientSideMessage(message);
        }
    }

    render() {
        const { father, mother, grandFather,
            maritalStatus, spouse, children,
            childrenList, errors, } = this.state;
        return(
            <div className="health-item">
                <div className="health-block">
                    <div className="health-block__row">
                        <div className="health-block__col">
                            <FormGroup 
                                label="Father's Name"
                                alignment="horizontal"
                                value={father?.Name}
                                name="father"
                                onChange={(name,value)=>this.handleFieldChange(name, value)}
                                validators={["required"]}
                                error={errors && errors.father}
                            />
                        </div>
                    </div>
                    <div className="health-block__row">
                        <div className="health-block__col">
                            <FormGroup 
                                label="Mother's Name"
                                alignment="horizontal"
                                value={mother?.Name}
                                name="mother"
                                onChange={(name,value)=> this.handleFieldChange(name, value)}
                                validators={["required"]}
                              error={errors && errors.mother}
                            />
                        </div>
                    </div>
                    <div className="health-block__row">
                        <div className="health-block__col">
                            <FormGroup 
                                label="GrandFather's Name"
                                alignment="horizontal"
                                value={grandFather?.Name}
                                name="grandFather"
                                onChange={(name,value)=> this.handleFieldChange(name, value)}
                                validators={["required"]}
                              error={errors && errors.grandFather}
                            />
                        </div>
                    </div>
                    <div className="health-block__row">
                        <div className="health-block__col">
                            <FormGroup
                                formName="chipsinput"
                                name={"maritalStatus"}
                                alignment="horizontal"
                                chipsArray={maritalStatusOptions}
                                value={maritalStatus}
                                onChange={(name,value)=> this.handleFieldChange(name, value.value)}
                                label="Marital Status"
                                badgeColor="green"
                                validators={["required"]}
                                error={errors && errors.maritalStatus}
                            />
                        </div>
                    </div>
                    {
                        maritalStatus && maritalStatus === maritalStatusOptions[1].value ?(
                            <div className="health-block__row">
                                <div className="health-block__col">
                                    <FormGroup 
                                        label="Spouse Name"
                                        alignment="horizontal"
                                        value={spouse?.Name}
                                        name="spouse"
                                        onChange={(name,value)=> this.handleFieldChange(name, value)}
                                        validators={["required"]}
                                        error={errors && errors.spouse}
                                    />
                                </div>
                            </div>
                        ):null
                    }
                    <div className="health-block__row">
                        <div className="health-block__col">
                            <FormGroup
                                formName="chipsinput"
                                name={"children"}
                                alignment="horizontal"
                                chipsArray={childrenOptions}
                                value={children}
                                onChange={(name,value)=> this.handleFieldChange(name, value.value)}
                                label="Children"
                                badgeColor="green"
                                validators={["required"]}
                                error={errors && errors.children}
                            />
                        </div>
                    </div>
                    {
                        (childrenList && children && children === childrenOptions[0].value) ? childrenList.map(child => (
                            <>
                                <div className="health-block__row2">
                                    <div className="health-block__col">
                                        <FormGroup
                                            formName="chipsinput"
                                            name={"type"}
                                            alignment="horizontal"
                                            chipsArray={childOptions}
                                            value={child.type}
                                            onChange={(name,value)=> this.handleChildFieldChange(name, value.value, child.id)}
                                            badgeColor="green"
                                            validators={["required"]}
                                            // error={errors && errors.selectedType}
                                        />
                                    </div>
                                    <div className="health-block__col">
                                        <FormGroup 
                                            label="Name"
                                            alignment="horizontal"
                                            value={child.name}
                                            name="name"
                                            onChange={(name,value) => this.handleChildFieldChange(name, value, child.id)}
                                        />
                                    </div>
                                    <div className="health-block__col">
                                        <FormGroup
                                            formName="ydmInput"
                                            name="dob"
                                            onChange={(name,value)=> this.handleChildFieldChange(name, value, child.id)}
                                            value={child.dob}
                                            alignment="horizontal"
                                            label="Date of birth"
                                            inputShow={['months','years','days']}
                                        />
                                    </div>
                                    <div className="health-block__col">
                                    <span
                                    className="delete-time-attendance"
                                    onClick={() => this.removeChildrenField(child.id)}
                                    >
                                        <Icons name="Delete" className="flex" />
                                    </span>
                                    </div>
                                </div> 
        
                            </>

                        )):null
                    }
                    {(childrenList && children && children === childrenOptions[0].value) &&
                    <div className="" style={{marginLeft:190}} >
                        <Button 
                            bg="primary"
                            type="button-outline"
                            title="Add"
                            buttonType="icon-button"
                            leftIcon={<Icons name="Add" justSVG />}
                            onClick={() => this.addChildrenField(null, 'Daughter')}
                        />
                    </div>}
                    <div className="flex saveButton">
                    <Button 
                      bg="primary"
                      title="Save"
                      onClick={() => this.handleSave()}
                    />
                    <Button  
                      onClick={() => this.props.toggleEditView(false)}
                      bg="white"
                      title="Cancel"
                    />
                  </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    employeeFamilyInfoSaved: state.employeeProfile.employeeFamilyInfoSaved,
});

const mapDispatchToProps = {
    saveEmployeeFamilyInfo,
    DisplayClientSideMessage,
    updateEmployeeFamilyInfo,
    GetEmployeeFamilies,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);