export const taskDetailsInitialState = {
  TmTaskDetailsDto: {
    TaskDetailsId: 0,
    TaskRefId: 0,
    TeamEmployeeIds: "",
    TaskDescription: "",
    Tags: "",
    StartDate: "",
    DueDate: "",
    EstimatedHour: "",
    FollowedBy: "",
    CustomField1: "",
    CustomField2: "",
    CustomField3: "",
    RepeatNos: 0,
    RepeatPeriod: "",
    RepeatPeriodValue: "",
    RepeatTime: 0,
    RepeatDueDate: "",
    ShowBeforeRepeatDueDate: null,
    Comments: "",
    IsRecurring: false,
    Status: 0,
    Priority: 0,
    CreatedBy: "",


  },
  TmTaskCheckListDto: [],
  TmTaskMemberListDto: [],
  Attachment: null,
  Files: [],
};

export const taskDetailsState = {
  TaskDetailsId: 0,
  TaskRefId: 10,
  TeamEmployeeIds: "",
  TaskDescription: "",
  Tags: "",
  StartDate: "",
  DueDate: "",
  EtimatedHour: "",
  FollowedBy: "",
  CustomField1: "",
  CustomField2: "",
  CustomField3: "",
  RepeatNos: 0,
  RepeatPeriod: "",
  RepeatPeriodVale: "",
  RepeatTime: 0,
  RepeatDueDate: "",
  Comments: "",
  Status: 0,
  Priority: 0,
};

export const taskCheckListState = {
  CheckListId: 0,
  TaskDetailsRefId: 0,
  CheckListName: "",
  Status: false,
};
