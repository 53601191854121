import {
  GET_EMPLOYEE_LIST_FOR_ADMIN,
  GET_EMPLOYEE_DASHBOARD,
  GET_EMPLOYEE_TRAVEL_DASHBOARD,
  GET_EMPLOYEE_DASHBOARD_LOADING,
  GET_EMPLOYEE_DASHBOARD_MESSAGES,
  GET_EMPLOYEE_DASHBOARD_NEWS,
  GET_PAYROLL_SLIP,
  GET_EMPLOYEE_DASHBOARD_JOBINFORMATION,
} from "actions/types";

const initialState = {
  employeeListForAdmin: null,
  employeeDashboard: [],
  employeeMessages: [],
  employeeNotices: [],
  jobInformationData: null,
  paySlip:null,
  dashboardLoding:false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEE_DASHBOARD_LOADING:
      return{
        ...state,
        dashboardLoding:true
      }
    case GET_EMPLOYEE_LIST_FOR_ADMIN:
      return {
        ...state,
        employeeListForAdmin: action.payload,
      };
    case GET_EMPLOYEE_DASHBOARD:
      return {
        ...state,
        dashboardLoding:false,
        employeeDashboard: action.payload,
      };
    case GET_EMPLOYEE_TRAVEL_DASHBOARD:
      return {
        ...state,
        dashboardLoding:false,
        employeeTravelDashboard: action.payload,
      };
    case GET_EMPLOYEE_DASHBOARD_JOBINFORMATION:
      return {
        ...state,
        jobInformationData: action.payload,
      };
    case GET_EMPLOYEE_DASHBOARD_MESSAGES:
      return{
        ...state,
        employeeMessages:action.payload
      };
    case GET_EMPLOYEE_DASHBOARD_NEWS:
      return{
        ...state,
        employeeNotices:action.payload
      }
    case GET_PAYROLL_SLIP:
      return {
        ...state,
        paySlip:action.payload
      }
    default:
      return state;
  }
}
