import * as React from "react";

interface IAppProps {
  leaveBalance: any[];
}

const App: React.FunctionComponent<IAppProps> = (props) => {
  const { leaveBalance } = props;
  return (
    <div className="my-leave-balance">
      <div className="my-leave-balance__header">
        <span className="header-title">Balances</span>
      </div>
      <div className="my-leave-balance__body">
        {leaveBalance?.map((item) => {
          if (item.leaveRemaining === "-" && item.totalLeave === "-")
            return null;
          return (
            <div className="flex  mt-sm">
              <span>{item.leaveType}</span>
              <span className="ml-md">
                <span className="bold">{item.leaveRemaining || "0"}</span>
                <span style={{ color: "#6f6f6f" }}>{"  of  "}</span>
                <span style={{ color: "#6f6f6f" }}>
                  {item.totalLeave || "0"}
                </span>
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default App;
