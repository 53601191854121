import React, { Component } from "react";
import "./employee_payroll.scss";
import Title from "../../common/title";
import PayrollTabs from "./payrollTabs";

export class index extends Component {
  render() {
    return (
      <div className="common-container">
        <Title name="Payroll" />
        <PayrollTabs />
      </div>
    );
  }
}

export default index;
