import React, { Component } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Filter,
  Inject,
} from "@syncfusion/ej2-react-grids";
import Pagination from "components/common/pagination";
import OnBoardingTableRow from "./onBoardingTableRow";
import { formatedMonthDayYear } from "constants/dateFormat";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Avatar from "react-avatar";
import Button from "components/button";
import { getUploadPath } from "constants/userDetails";
export class DisciplinaryTable extends Component {
  constructor(props) {
    super(props);
    this.widths = {
      image: 55,
      name: 240,
      idCard: 70,
      jobRolePosition: 220,
      date: 150,
      // date: 200,
      stage: 150,
    };

    this.gridTemplate = this.gridTemplate;
    this.row = [];
  }

  gridTemplate(props) {
    const { history } = this.props;
    return (
      <OnBoardingTableRow
        {...props}
        // handleEdit={this.props.handleEdit}
        // GetOnboardingEmployeeDetail={this.props.GetOnboardingEmployeeDetail}
        // deleteOnBoardingEmployee={this.props.deleteOnBoardingEmployee}
        // stageId={this.props.stageId}
        {...history}
        ref={(row) => {
          this.row = [...this.row, row];
        }}
      />
    );
  }
  onPackageInitiate = async (EmployeeId) => {
    await this.props.GetOnboardingEmployeeDetail(EmployeeId);
    this.props.history.push(`/admin/employee_onboarding/packages/${EmployeeId}`);
  }

  dropdownTemplate = (props) => {
    return (
      <Button
        buttonType="drop-button"
        bg="secondary"
        title="Review"
        onClick={(e) => null}
        dropClass={"common-drop onboarding-dropdown"}
        dropComponent={
          <ul id={"Project" + 1} className="common-drop-list">
            {props.CurrentStage == "Draft" ? (
              <li
                className={"common-drop-list__item"}
                onClick={() => this.onPackageInitiate(props.EmployeeId)}
              >
                <span className="common-drop-name">Initial Onboarding</span>
              </li>
            ) : null}

            {props.CurrentStage == "Draft" ||
            props.CurrentStage == "Onboarding Process" ? (
              <li
                className={"common-drop-list__item"}
                onClick={(e) => this.props.handleEdit(props)}
              >
                <span className="common-drop-name">Edit</span>
              </li>
            ) : null}
            {props.CurrentStage == "Draft" ||
            props.CurrentStage == "Onboarding Process" ? (
              <li
                className={"common-drop-list__item"}
                onClick={(e) =>
                  this.props.deleteOnBoardingEmployee(props.EmployeeId)
                }
              >
                <span className="common-drop-name">Delete</span>
              </li>
            ) : null}
          </ul>
        }
      />
    );
  };

  EmployeeProfile = (props) => {
    return (
      <>
        <Avatar
          name={props.EmployeeName}
          size={45}
          round={true}
          // color="#e1e1e1"
          // fgColor="#787878"
          src={props.EmployeeImage && getUploadPath(props.EmployeeImage)}
        />
        <span className="clickable ml-sm" onClick={(e) => this.props.handleEdit(props)}>
          {props.EmployeeName}
        </span>
      </>
    );
  };

  Destination = (props) => {
    return (
      <div className="flex-column">
            <span>{props.Designation}</span>
            <span className="color-light">{props.Level}</span>
      </div>
    );
  };
  Branch = (props) => {
    return (
      <div className="flex-column">
            <span>{props.Branch}</span>
            <span className="color-light">{props.Department}</span>
          </div>
    );
  };

  render() {
    const {
      data,
      handleRowChange,
      handleIndexChange,
      pageIndex,
      rowsPerPage,
      stageId,
    } = this.props;
    return (
      <div className="table empTable">
        <div className="arrear-table">
          {/* <Loader open={travelSettlement.loading} /> */}

          <div className="pending-settlement-grid">
            <GridComponent
              dataSource={data}
              className="custom-grid"
              toolbar={this.toolbar}
              filterSettings={false}
              id="grid"
              ref={(g) => (this.grid = g)}
            >
              <ColumnsDirective>
                <ColumnDirective
                  headerText="Employee Name"
                  width={this.widths.name}
                  filterSettings={false}
                  template={this.EmployeeProfile}
                />
                <ColumnDirective
                  headerText="Job Role and Position"
                  template={this.Destination}
                  width={this.widths.jobRolePosition}
                />
                <ColumnDirective
                  headerText="Designation"
                  width={this.widths.jobRolePosition}
                  template={this.Destination}
                />
                <ColumnDirective
                  headerText="Branch & Department"
                  width={this.widths.date}
                  template={this.Branch}
                />
                <ColumnDirective
                  field="Added"
                  headerText="Added"
                  width={this.widths.date}
                  type="datetime"
                  format='MM/dd/yyyy'
                />
                <ColumnDirective
                  field="JoinDate"
                  headerText="Join Date"
                  width={this.widths.stage}
                  type="datetime"
                  format='MM/dd/yyyy'
                  />
                <ColumnDirective
                  field="CurrentStage"
                  headerText="Current Stage"
                  width={this.widths.stage}
                />
                {stageId != 0 && stageId != 3 ? (
                  <ColumnDirective
                    width={120}
                    template={this.dropdownTemplate}
                  />
                ) : null}
              </ColumnsDirective>
              <Inject services={[Filter]} />
            </GridComponent>

            {data && data.length > 0 && (
              <Pagination
                handleRowChange={handleRowChange}
                handleIndexChange={handleIndexChange}
                pageIndex={pageIndex}
                rowsPerPage={rowsPerPage}
                total={data[0].TotalRow}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default withRouter(connect(mapStateToProps, {})(DisciplinaryTable));
