import React, { useState, useEffect } from 'react'
import FormInput from 'components/form'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux'
import {
    addRosterTeamRole,
} from '../../helpers/action';
import Button from 'components/button';
import Modal from 'components/modal';



function TeamFilters(props) {
    const { teamList, teamRoleList } = useSelector((state: any) => state.roosterReducer);

    const { filter, onChange } = props;
    const dispatch = useDispatch()

    useEffect(() => {
        if (filter.teamID) {
            dispatch(addRosterTeamRole(filter.teamID?.value))
        }
    }, [filter.teamId])
    return (
        <div className="space-between">
            <div className="tasksReport-filters">
                <FormInput
                    formName="searchInput"
                    name="employee"
                    placeholder="Search employee"
                    value={filter.employee}
                    onChange={onChange}
                    width="250px"
                />
                <FormInput
                    formName="reactselect"
                    onChange={onChange}
                    name="teamId"
                    placeholder="Filter Team"
                    value={filter.teamId}
                    width="200px"
                    options={teamList}
                />

                <FormInput
                    formName="reactselect"
                    onChange={onChange}
                    name="roleId"
                    placeholder="Filter Role"
                    value={filter.roleId}
                    width="200px"
                    options={teamRoleList}
                />
            </div>
        </div>

    )
}

export default TeamFilters
