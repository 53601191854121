import React, { Component } from "react";
import {Tabs,Tab} from "components/tabs/index.d";
import Payslip from "./payslip";
import CompensationPlan from "./compensationPlan";
import CompensationHistory from "./compensationHistory";
import {GetPayrollSlip,GetPayrollPeriod} from '../action';
import {connect} from 'react-redux'
import { employeeReducer } from 'scenes/employee/reducer';
import Const from 'assets/images/const_vect.png'

const ComingSoon = () =>{
  return(
    <div className="coming-soon">
      <div className="coming-soon__img">
        <img src={Const} alt=""/>
      </div>
    </div>
  )
}

export class PayrollTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        { id: 1, label: "Payslip" },
        { id: 2, label: "Compensation Plan" },
        { id: 3, label: "Compensation History" }
      ],
      activeTabId: 3
    };
  }
  async componentDidMount(){
      // await this.props.GetPayrollPeriod()
      // const {value} =this.props.payrollPeriod
      // if(value){
      //   await this.props.GetPayrollSlip(value.Year,5)
      //   // await this.props.GetPayrollSlip()
      // }
  }

  render() {
    const { tabs, activeTabId } = this.state;
    return (
      <div>
        <Tabs type="tabs-block">
          {tabs.map((tab, index) => {
            return (
              <Tab
                label={tab.label}
                name={tab.id}
                key={index}
                isDisabled = {tab.id !== 1}
                disabledMessage= {tab.id !== 1 ?'We are working on it, it will be available in our next release':''}
              >
                {tab.id == 1 ? (
                  <Payslip />
                ) : tab.id == 2 ? (
                  // <CompensationPlan />
                 <ComingSoon />
                ) : tab.id == 3 ? (
                  // <CompensationHistory />
                  <ComingSoon />
                ) : null}
              </Tab>
            );
          })}
        </Tabs>
      </div>
    );
  }
}

const mapDispatchToProps = {
  GetPayrollSlip,
  GetPayrollPeriod
}

const mapStateToProps = state =>({
  payslip : state.employeeReducer.paySlip,
  payrollPeriod:state.selectReducer.payrollPeriod
})

export default connect(mapStateToProps,mapDispatchToProps)(PayrollTabs);
