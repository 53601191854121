import Button from "components/button";
import ApproveButton from "components/button/approveButton";
import Pagination from "components/common/pagination";
import getFormattedNumber from "helpers/getFormattedNumber";
import isEmpty from "isEmpty";
import React, { useState } from "react";
import { GoComment } from "react-icons/go";
import { MdCloudDownload } from "react-icons/md";
// import { RiGroupLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import EmployeeDetails from "scenes/common/employeeDetails";
import RouteEnum from "constants/routeEnum";
import ApprovalFlow from "../../../../common/approvalFlow";
import { getSettlementAuditLog, getSettlementReport } from "../actions";
import SvgIcons from "components/svgIcons";

function SettlementRequestBlock(props) {
  let { cancelledList } = props;
  let [modalOpen, setModalOpen] = useState("");
  const dispatch = useDispatch();
  const onModalClose = (id) => {
    setModalOpen(id);
  };
  const handleReviewSettlement = async (data) => {
    let ajaxApi = [
      getSettlementReport(data.SettlementId),
      getSettlementAuditLog(data.SettlementId),
    ];
    Promise.all(
      ajaxApi.map(async (item) => {
        return await dispatch(item);
      })
    );
    //this.props.getTravelRequestDetails(2);
    props.handleReviewSettlement(data);
  };
  const handleExportPdf = (data) => {
    const settlementId = data.SettlementId;
    props.history.push({
      pathname: RouteEnum.ExportPdfPath,
      state: { id: settlementId, source: "settlementReport" },
    });
  };
  let {
    handleRowChange,
    handleIndexChange,
    pageIndex,
    rowsPerPage,
    searchedPage,
  } = props;
  return (
    <>
      {props.rows.map((row, rowIndex) => {
        let isCancelled =
          !isEmpty(cancelledList) &&
          cancelledList.find((x) => x === row.RequestId);
        const employeeDetail = {
          Id: row["EmployeeId"],
          FullName: row["Name"],
          Imagepath: row["UrlPhoto"],
          Designation: row["Designation"],
          Branch: row["Branch"],
          Department: row["Department"],
        };
        return (
          <div className="request-details" key={rowIndex}>
            <div className="request-details__header">
              <EmployeeDetails empDetails={employeeDetail} size={50} />
              {/* <div className="emp-details flex">
                <Avatar
                  alt={row.Name}
                  src={row.UrlPhoto && BASE_URL + row.UrlPhoto}
                  className="emp-image"
                  round
                  size={50}
                />
                <div className="emp-name-desg">
                  <span className="emp-name">{row.Name}</span>
                  <span className="emp-desg">{row.Designation}</span>
                </div>
              </div> */}
              <div>
                <span>{row.PlaceName}</span>
              </div>
              <div>
                <span>{row.TotalDays} </span>
                <span>{row.TotalNights}</span>
              </div>
              <div>
                <span>{row.TravelStartEndDate}</span>
              </div>
              {row.IsCommented ? (
                <div className="comment">
                  <GoComment className="comment__icon"></GoComment>
                  {/* <span className="first-line"></span>
                <span className="second-line"></span> */}
                </div>
              ) : null}
              <div className="requested-on">
                <span>Requested on: </span>
                <span>{row.RequestedOn}</span>
              </div>
            </div>
            <div className="request-details__body">
              <div className="body-row">
                <div className="body-col">
                  <div className="reason">
                    <span>{row.Reason}</span>
                  </div>
                  {row.TravelSettlementDestination.map((dest, destIndex) => {
                    return (
                      <div className="destination dest" key={destIndex}>
                        <div className="dest__row">
                          <span className="district">
                            {dest.District}
                            {!isEmpty(dest.District) ? "," : null}
                          </span>
                          <span>{dest.PlaceName}</span>
                          {destIndex === 0 && !isEmpty(row.GroupEmployee) ? (
                            <div className="travel-people">
                              {/* <RiGroupLine /> */}
                              <span className="travel-people__name">
                                {row.GroupEmployee.map((emp, i) => {
                                  return i + 1 === row.GroupEmployee.length
                                    ? emp
                                    : emp + ", ";
                                })}
                              </span>
                            </div>
                          ) : null}
                        </div>
                        <div className="dest__row">
                          <span className="travel-date">
                            {dest.DestinationStart}
                          </span>
                          <span className="divider"> - </span>
                          <span className="travel-date">
                            {dest.DestinationEnd}
                          </span>
                        </div>
                        <div className="dest__row">
                          <span>{dest.TravelDays}</span>
                          <span className="divider"></span>
                          <span> {dest.TravelNights}</span>
                          <span className="hotel-name">{dest.HotelName}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="body-col">
                  <div className="amount-row">
                    <span>Eligible Amount</span>
                    <span>
                      {getFormattedNumber(row.AssessedEligibilityAmt)}
                    </span>
                  </div>
                  <div className="amount-row">
                    <span>Advance</span>
                    <span>{getFormattedNumber(row.AdvanceTakenAmount)}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="request-details__footer">
              <div className="details-btn flex">
                <Button
                  title="Review"
                  bg="subtle"
                  // type="button-outline"
                  onClick={() => handleReviewSettlement(row)}
                  buttonType="icon-button"
                  leftIcon={<SvgIcons name={"DetailsList"} size={16} />}
                />
                <NavLink
                  target="_blank"
                  to={{
                    pathname: RouteEnum.ExportPdfPath,
                    state: {
                      id: row.RequestId,
                      source: "settlementReport",
                    },
                    search: "settlementReport/" + row.SettlementId,
                  }}
                  // onClick={() => handleExportToPdf(row.RequestId)}
                >
                  {/* <button
                    className="button button-white with-icon"
                    //onClick={() => handleExportPdf(row)}
                  >
                    <IoMdCloudDownload />
                    <span>Download</span>
                  </button> */}
                  <Button
                    title="Download"
                    buttonType="icon-button"
                    // type="button-outline"
                    leftIcon={<MdCloudDownload />}
                    bg="subtle"
                  />
                </NavLink>
              </div>
              <ApprovalFlow
                approvalStatus={row.ApprovalStatus}
                rowIndex={rowIndex}
              />
              {row.StatusName !== "Approved" && (
                <div className="btn-col btn-grp flex">
                  <ApproveButton
                    handleApproveRequest={() => props.handleApproveClick(row)}
                  />
                  {/* <button
                    className="button button-primary with-icon"
                    onClick={() => props.handleApproveClick(row)}
                  >
                    <MdRateReview />
                    <span>Approve</span>
                  </button> */}
                  {/* <button
                    className="button button-primary with-icon"
                    onClick={() => handleReviewSettlement(row)}
                  >
                    <MdRateReview />
                    <span>Review</span>
                  </button> */}
                </div>
              )}
            </div>
          </div>
        );
      })}
      {!isEmpty(props.rows) && (
        <Pagination
          handleRowChange={handleRowChange}
          handleIndexChange={handleIndexChange}
          pageIndex={pageIndex}
          searchedPage={searchedPage}
          rowsPerPage={rowsPerPage}
        />
      )}
    </>
  );
}

export default SettlementRequestBlock;
