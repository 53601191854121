import React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames'

function AttendanceStatistics() {
    const leaveTimeDashboard = useSelector(state => state.leaveTimeReducer.leaveTimeDashboard );
    const {AttendanceStatics}  = leaveTimeDashboard;
    const classes = (type,title)=>{
        const split = title.split(" ")
        return classnames({
            "attendance-label":type==='label',
            "value":type==='value',
            "text-red":split.includes("Very") && split.includes("Late"),
            "text-green":title === "On Time"
        })
    }
    return (
        <div className="attendance-statistics">
            {
               leaveTimeDashboard && AttendanceStatics ?(
                    <div className="grid grid-half">
                            {
                                AttendanceStatics.map(item=>{
                                    return(
                                        <div className="attendance-list__item">
                                            <span className={classes('label',item.Title)}>{item.Title}</span>
                                            <span className={classes('value',item.Title)}><span className="bold-500">{item.Total}</span> 10%</span>
                                        </div>
                                    )
                                })
                            }
                    </div>  
               ):
               <span className="no-found">No Data Found</span>
            }
            
        </div>
    )
}

export default AttendanceStatistics
