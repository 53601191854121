import React, { Component } from "react";
import RouteBaseClass from "components/routeBaseClass";
import "../../scss";
import { connect } from "react-redux";
import Icons from "components/icons";
import { Tabs, Tab } from "components/tabs/index.d";
import Table from "./table";
import Breadcrumb from "../../../common/breadcrumb";
import SearchAndFilter from "./searchAndFilter";
import Loaders from "components/loaders";
import Modal from "components/modal";
import Button from "components/button";
import { MdAdd } from "react-icons/md";
// import InputGroup from "~/Components/Form/InputGroup";
import Form from "components/form";
import {
  GetBillingDesignations,
  GetActivityAndBillingRoles,
  ArchiveOrDeleteActivityOrBillings,
  CreateActivityOrBillings,
  SaveMemberBillingDesignation,
  UpdateMemberBillingDesignation,
} from "../../action";
import { DisplayClientSideMessage } from "actions/commonAction";

const tabs = [
  { tabId: 1, label: "Active" },
  { tabId: 2, label: "Archived" },
];
export class index extends RouteBaseClass {
  constructor(props) {
    super(props, ["Administrator"]);
    this.state = {
      isLoading: true,
      showAddModal: false,
      pageIndex: 0,
      rowsPerPage: 10,
      searchEmployee: "",
      activeTab: tabs[0],
      consultantName: "",
    };
  }

  async componentDidMount() {
    this.props.GetBillingDesignations();
    await this.getActivityAndBillingRoles();
    this.setState({ isLoading: false });
  }

  getActivityAndBillingRoles = async () => {
    const { activeTab, searchEmployee, pageIndex, rowsPerPage } = this.state;
    const data = {
      SearchEmployee: searchEmployee,
      Status: activeTab.tabId == 1 ? true : activeTab.tabId == 2 ? false : null,
      PageIndex: pageIndex,
      PageSize: rowsPerPage,
    };
    await this.props.GetActivityAndBillingRoles(data);
  };

  handleRowChange = (num) => {
    this.setState({ rowsPerPage: num, pageIndex: 0 }, async () => {
      await this.getActivityAndBillingRoles();
    });
  };

  handleIndexChange = (num) => {
    this.setState({ pageIndex: num }, async () => {
      await this.getActivityAndBillingRoles();
    });
  };

  handleSearchInput = (name, value) => {
    this.setState({ [name]: value, pageIndex: 0 }, async () => {
      await this.getActivityAndBillingRoles();
    });
  };

  handleInput = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleTabChange = (tab) => {
    this.setState({ activeTab: tab, pageIndex: 0 }, async () => {
      await this.getActivityAndBillingRoles();
    });
  };

  handleShow = (value) => {
    this.setState({ showAddModal: value });
  };

  handleCreateNew = async () => {
    const { consultantName } = this.state;
    const data = {
      MemberId: 0,
      MemberName: consultantName,
    };
    await this.props.CreateActivityOrBillings(data, this.handleSuccessResponse);
  };

  handleSuccessResponse = () => {
    this.getActivityAndBillingRoles();
    this.setState({ showAddModal: false });
  };

  handleDeleteOrArchive = (memberId) => {
    this.props.ArchiveOrDeleteActivityOrBillings(
      memberId,
      this.getActivityAndBillingRoles
    );
  };

  handleSaveOrUpdateDillingDesignation = (data, isChange) => {
    if (isChange) {
      this.props.UpdateMemberBillingDesignation(
        data,
        this.getActivityAndBillingRoles
      );
    } else {
      this.props.SaveMemberBillingDesignation(
        data,
        this.getActivityAndBillingRoles
      );
    }
  };

  displayClientSideMessage = (message) => {
    this.props.DisplayClientSideMessage(message);
  };

  render() {
    const { isLoading, showAddModal, activeTab, consultantName } = this.state;
    const { activityAndBillingRoles } = this.props;

    return (
      <div className="common-container">
        <div className="tasktracker-title-section">
          <Breadcrumb name="Activity and Billing Roles" />

          <div className="activity_billing_role_add">
            <Button
              bg="primary"
              icon={<MdAdd />}
              buttonType="icon-button"
              title="Add External Consultant"
              onClick={() => this.handleShow(true)}
            />
          </div>
          {/* <div className="activity_billing_role_add">
            <button
              className="button button-primary with-icon"
              onClick={() => this.handleShow(true)}
            >
              <Icons name="Add" />
              <span> Add External Consultant </span>
            </button>
          </div> */}
        </div>

        {tabs && (
          <Tabs noBackground>
            {tabs.map((tab, index) => {
              return (
                <Tab
                  key={index}
                  label={tab.label}
                  name={tab.tabId}
                  id={tab.tabId}
                  isActive={activeTab.tabId === tab.tabId}
                  onClick={() => this.handleTabChange(tab)}
                >
                  <>
                    <SearchAndFilter
                      handleChange={this.handleSearchInput}
                      {...this.state}
                    />
                    {isLoading ? (
                      <Loaders
                        loading={isLoading}
                        type="skeleton"
                        name="custom-table"
                      />
                    ) : (
                      <Table
                        data={activityAndBillingRoles}
                        {...this.props}
                        {...this.state}
                        handleRowChange={this.handleRowChange}
                        handleIndexChange={this.handleIndexChange}
                        handleDeleteOrArchive={this.handleDeleteOrArchive}
                        handleSaveOrUpdateDillingDesignation={
                          this.handleSaveOrUpdateDillingDesignation
                        }
                        displayClientSideMessage={this.displayClientSideMessage}
                      />
                    )}
                  </>
                </Tab>
              );
            })}
          </Tabs>
        )}
        {showAddModal && (
          <Modal
            open={showAddModal}
            onModalClose={() => this.handleShow(false)}
            title="Add External Consultant"
            className="roles-modal"
          >
            <div className="activity_billing_role_add-modal-body">
              {/* <InputGroup
                name={"consultantName"}
                label={"Consultant Name"}
                value={consultantName}
                // width="100%"
                onChange={this.handleInput}
              /> */}
              <Form
                formName="textinput"
                type="text"
                alignment="vertical"
                name="consultantName"
                value={consultantName}
                onChange={this.handleChange}
                label="Consultant Name"
              />
              <div className="activity_billing_role_add-modal-footer">
                <button
                  className="button button-secondary"
                  onClick={() => this.handleShow(false)}
                >
                  Cancel
                </button>
                <button
                  className="button button-primary"
                  onClick={this.handleCreateNew}
                >
                  Save
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activityAndBillingRoles: state.taskTrackerReducer.activityAndBillingRoles,
  billingDesignationsDdl: state.taskTrackerReducer.billingDesignationsDdl,
});

const mapDispatchToProps = {
  GetBillingDesignations,
  GetActivityAndBillingRoles,
  ArchiveOrDeleteActivityOrBillings,
  CreateActivityOrBillings,
  SaveMemberBillingDesignation,
  UpdateMemberBillingDesignation,
  DisplayClientSideMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
