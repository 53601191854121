import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DataSelect from '../../../components/dataselect'
import AutoSelect from '../../../components/common/autoSelect'
// import {getIncomeOrDeduction,searchSalaryAdjustment} from './action'
import isEmpty from 'isEmpty'
import {FaRedo} from 'react-icons/fa'
import {Skeleton} from '@material-ui/lab';

export class Filters extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            employee:{label:'',value:0},
            branch:{label:'',value:0},
            department:{label:'',value:0},
            income_deduct:{label:'',value:0},
            position:{label:'',value:0},
        }
        this.onChange=this.onChange.bind(this);
        this.onSelectChange=this.onSelectChange.bind(this);
        this.onFilter=this.onFilter.bind(this);
        this.onClear=this.onClear.bind(this);
    }
    render() {
        return (
            <div className="filters-input filters">
            <div className="filters-input__item">
                <DataSelect
                    onChange={this.onSelectChange} 
                    value={this.state.employee}
                    label="Employee"
                    name="employee"
                    actionType="employee"
                    width="220px"
                />
            </div>
            <div className="filters-input__item">
                <DataSelect
                    onChange={this.onSelectChange} 
                    value={this.state.branch}
                    label="Branch"
                    name="branch"
                    // multiple
                    actionType="branch"
                    width="200px"
                />
            </div>
             <div className="filters-input__item">
                <DataSelect
                    onChange={this.onSelectChange} 
                    value={this.state.department}
                    label="Department"
                    name="department"
                    actionType="department"
                    width="200px"
                />
            </div>
            {/* {
                incomeDeductionLoading || income_or_deductions===null?
                <div className="filters-input__item">
                    <div className="form-group">
                        <Skeleton variant="text" width={75} height={15}  />
                        <Skeleton variant="rect" width={200} height={40}  />
                    </div>
                </div>
                :
                <div className="filters-input__item">
                <AutoSelect 
                    onChange={this.onSelectChange} 
                    value={this.state.income_deduct} 
                    options={Arrays(income_or_deductions)}
                    label="Income or Deduction"
                    name="income_deduct"
                    width="200px"
                />
            </div>
            } */}
            
            <div className="filters-input__item">
                <button onClick={()=>this.onFilter()} className="btn btn-blue">Apply</button>
                <button onClick={()=>this.onClear()} className="btn btn-del"><FaRedo /></button>
            </div>
        </div>
        )
    }
}

export default Filters
