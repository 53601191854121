import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import isEmpty from "../../helpers/isEmpty";

function RadioGroup(props) {
  const {
    key,
    name,
    value,
    error,
    label,
    radioLabel,
    id,
    checked,
    className,
    labelPosition,
    disabled,
    onChange,
  } = props;
  var inputclass = classnames({
    "custom-check": true,
    error: !isEmpty(error) && error,
    [className]: className,
    // [size]:size,
    "label-right": labelPosition === "right",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    onChange && onChange(name, value);
  };
  return (
    <div className="form__radio-group">
      <input
        key={key}
        value={value}
        disabled={disabled}
        checked={checked}
        type="radio"
        className="form__radio-input"
        id={id ? id : label}
        onChange={handleChange}
        name={name}
      />
      <label htmlFor={id ? id : label} className="form__radio-label">
        <span className="form__radio-button"></span>
        {label || radioLabel}
      </label>
    </div>
  );
}

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  error: PropTypes.string,
  labelPosition: PropTypes.string,
  label: PropTypes.string,
  radioLabel:PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

RadioGroup.defaultProps = {
  labelPosition: "left",
  disabled: false,
};

export default RadioGroup;
