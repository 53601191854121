import React, { useState, useRef, useEffect } from "react";
import Icons from "components/icons";
import CommonCommentSection from "components/common/commonCommentSection";
import EmployeeDetails from "scenes/common/employeeDetails";
import { MdComment } from "react-icons/md";
import { FaThumbsUp, FaMinusCircle } from "react-icons/fa";
import LeaveRequestForm from "scenes/employee/leaves/leaveRequest/form";
import ApproveButton from "components/button/approveButton";
import Button from "components/button";
import moment from "moment";
import { FaCheckCircle } from "react-icons/fa";
import Avatar from "react-avatar";
import GetFileTypeIcon from "components/icons/getFileTypeIcon";
import isEmpty from "isEmpty";
import { checkIsFileImageType } from "helpers/checkIsFileImageType";
import {getUploadPath} from 'constants/userDetails'

interface IAppProps {
  leaveRequestId: number;
  empRequest: any;
  comments: any[];
  empDetails:any,
  errors: any;
  saveComment: Function;
  handleApproveRequest: Function;
  handleRejectRequest: Function;
  isViewMode?: boolean;
  refreshRequestData?: Function;
  attachments: any;
}

const App: React.FunctionComponent<IAppProps> = (props) => {
  const {
    empRequest,
    comments,
    errors,
    saveComment,
    leaveRequestId,
    handleApproveRequest,
    isViewMode,
    attachments,
    empDetails
  } = props;
  const [isLeaveRequestFormShown, showLeaveRequestForm] = useState(false);
  return (
    <div className="my-request">
      <div className="my-request__header">
        <span className="request-type">{empRequest.requestType}</span>
        <div className="flex mt-sm">
          <span>{empRequest.requestPeriod}</span>
          <span className="ml-md">{empRequest.requestDays} {empRequest.LeaveDayType}</span>
        </div>
        <div className="flex mt-md">
          <span>Requested On</span>
          <span className="ml-md">
          {empRequest.requestedOn}
          </span>
        </div>

        <span className="mt-md display-block">{empRequest.reason}</span>
        {!isEmpty(attachments) && (
          <div className="flex mt-md items-start attachments">
            <span>Attachments</span>
            <div className="ml-md flex items-start flex-wrap attachments__files">
              {attachments?.map((x) => {
                let isImageFile = checkIsFileImageType(x.UserFileName);
                return (
                  <>
                    <a
                      href={getUploadPath(x.FileLocation + x.ServerFileName)}
                      target={isImageFile ? "_blank" : ""}
                      className="flex"
                      download={isImageFile ? false : true}
                    >
                      <div className="flex attachment-file">
                        <GetFileTypeIcon fileName={x.UserFileName} />
                        <span className="attachment-file__text">
                          {x.UserFileName}
                        </span>
                      </div>
                    </a>
                  </>
                );
              })}
            </div>
          </div>
        )}
      </div>

      <div className="my-request__body flex-column mt-sm">
        {empRequest?.substitute && !isEmpty(empRequest?.substitute) && (
          <div className="substitute flex">
            <span>Substitute</span>
            <div className="susbtitute__name flex">
              <EmployeeDetails empDetails={empRequest.substitute} size={30} />
            </div>
          </div>
        )}
        {!isEmpty(empRequest?.approvers) && (
          <div className="approvers flex mt-sm">
            <span>Approvers</span>
            <div className="approver__name flex">
              {empRequest?.approvers?.map((item) => {
                return (
                  <>
                    <EmployeeDetails
                      empDetails={item}
                      size={30}
                      showApprovalStatus
                    />
                  </>
                );
              })}
            </div>
          </div>
        )}

        <div className="mt-md flex">
          <CommonCommentSection
            submitComment={saveComment}
            comments={comments}
            submitErrors={errors}
            isViewMode={isViewMode}
          />
        </div>

        {!isViewMode && (
          <div className="flex mt-md justify-between">
            <div className="flex library">
              <Button
                title="Deny"
                bg="primary"
                type="button-outline"
                onClick={() => props.handleRejectRequest()}
                buttonType={"icon-button"}
                leftIcon={<FaMinusCircle />}
              />

              <Button
                title="Edit"
                bg="primary"
                type="button-outline"
                onClick={() => {
                  showLeaveRequestForm(true);
                }}
                buttonType={"icon-button"}
                leftIcon={<Icons name="Edit" className="flex" />}
              />

              {/* <button
              className="button with-icon button-white"
              onClick={(e) => {}}
            >
              <MdComment />
              <span>Comment</span>
            </button> */}
            </div>
            <ApproveButton handleApproveRequest={handleApproveRequest} />
            {/* <button
            className="button with-icon button-approve"
            onClick={() => handleApproveRequest()}
          >
            <Icons name="Check" className="flex" />
            <span>Approve</span>
          </button> */}
          </div>
        )}
      </div>
      {isLeaveRequestFormShown && (
        <LeaveRequestForm
          closeForm={showLeaveRequestForm}
          leaveRequestId={leaveRequestId}
          employeeId={empDetails.Id}
          refreshRequestData={props.refreshRequestData}
          isApprover={true}
        />
      )}
    </div>
  );
};

export default App;
