import React from "react";
import { FaExclamationCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import {
  UndoCandidateReject,
  updateMultipleCandidateDetails,
} from "store/actions/recruitment/dashboard";

const Confirmation = (
  setRejectModal: any,
  rejectModal: boolean,
  candidateId: number,
  setDisqualifyModal: any,
  disqualifyModal: boolean,
  jobId: number,
  history: any
) => {
  const dispatch = useDispatch();

  const undoRejection = (candiateId) => {
    dispatch(
      UndoCandidateReject({
        candidateId: candiateId,
      })
    );
  };

  const undoDisqualification = (candiateId, jobId) => {
    let data = {
      jobId: jobId,
      pageIndex: 0,
      pageSize: 10,
      candidateId: candiateId,
      currentStageId: 10,
      stageId: 0,
      subStageId: null,
    };

    dispatch(updateMultipleCandidateDetails(data, history));
  };

  return (
    (rejectModal || disqualifyModal) && (
      <div className="modal__confrimations confirm modal-travel-request">
        <div className="confirm-icon">
          <FaExclamationCircle />
        </div>
        <h2 className="confirm-title">Are you Sure?</h2>
        <div className="confirm-buttons">
          <button
            className="confirm-buttons__confirm mr-md"
            onClick={() => {
              setRejectModal(false);
              setDisqualifyModal(false);
              rejectModal && undoRejection(candidateId);
              disqualifyModal && undoDisqualification(candidateId, jobId);
            }}
          >
            <span>Yes</span>
          </button>
          <button
            className="confirm-buttons__cancel"
            onClick={() => {
              setRejectModal(false);
              setDisqualifyModal(false);
            }}
          >
            <span>No</span>
          </button>
        </div>
      </div>
    )
  );
};

export default Confirmation;
