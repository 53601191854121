import React from "react";
import "../style.scss";
import { useSelector } from "react-redux";
import { currencyCommaSeperator } from "../../../helpers/currencyFormatter";

export default function StaffPersonalLoanDetail() {
  const { staffPersonalLoanDetail } = useSelector((state) => state.loanReducer);
  return (
    <div className="homeloan-view-employeedetails homeloan-view-vehicledetails healthCard__health mt-md">
      <div className="healthCard-heading">
        <h4>Loan Detail</h4>
      </div>
      <div className="healthCard__health__details">
        <div className="placement-details">
          <div className="placement-details__block">
            <div className="placement-details__table">
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Loan Purpose
                </div>
                <div className="placement-details__col placement-val">
                  {staffPersonalLoanDetail?.LoanPurpose == "Other"
                    ? staffPersonalLoanDetail?.OtherPurpose
                    : staffPersonalLoanDetail?.LoanPurpose}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Notes
                </div>
                <div className="placement-details__col placement-val">
                  {staffPersonalLoanDetail?.Note}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Pays In
                </div>
                <div className="placement-details__col placement-val">
                  {staffPersonalLoanDetail?.RepayIn} Months
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Requested Amount
                </div>
                <div className="placement-details__col placement-val">
                  NPR{" "}
                  {currencyCommaSeperator(
                    staffPersonalLoanDetail?.RequestAmount
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
