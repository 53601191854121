import Slider from "@material-ui/core/Slider";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React from "react";
import PropTypes from "prop-types";

const useStyles = (style) => {
  const classFunc = makeStyles((theme) => ({
    root: {
      // width: 300 + theme.spacing(3) * 2,
      width: style.width,
    },
    margin: {
      height: theme.spacing(5),
    },
  }));

  return classFunc();
};

function valuetext(value) {
  return `${value}`;
}

const PrettoSlider = withStyles({
  root: {
    color: "#0065ff",
    height: 18,
    marginBottom: 0,
    padding: 0,
  },
  thumb: {
    height: 30,
    width: 30,
    backgroundColor: "currentColor",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -16,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 16px)",
  },

  track: {
    height: 18,
    borderRadius: 4,
    //marginRight:30
    backgroundColor: "#2f73ea",
  },
  rail: {
    height: 18,
    borderRadius: 4,
    //marginLeft:30,
    backgroundColor: "#d9d9d9",
  },
  mark: {
    backgroundColor: "#bfbfbf",
    height: 18,
    width: 1,
    marginTop: 0,
    display: "none",
  },
  markActive: {
    opacity: 1,
    backgroundColor: "currentColor",
    color: "#fff",
  },
  markLabel: {
    marginTop: -26,
    color: "#000",
    fontWeight: "bold",
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
    //margin:"0 15px"
  },
  markLabelActive: {
    color: "#fff",
    background: "#2f73ea",
    borderRadius: "3px",
    width: "20px",
    textAlign: "center",
    lineHeight: 1.3,
  },
})(Slider);

function ThumbComponent(props) {
  return (
    <span {...props}>
      <span style={{ color: "#fff", fontWeight: "bold" }}>
        {props["aria-valuenow"]}
      </span>
    </span>
  );
}

export default function CustomizedSlider(props) {
  const {
    step,
    marks,
    min,
    max,
    defaultValue,
    valueLabelDisplay,
    name,
    onChange,
    value,
    rootElStyle,
  } = props;
  const classes = useStyles(rootElStyle);

  return (
    <div className={classes.root}>
      <PrettoSlider
        name={name}
        value={value}
        defaultValue={defaultValue}
        getAriaValueText={valuetext}
        aria-labelledby=""
        step={step}
        min={min}
        max={max}
        marks={marks}
        valueLabelDisplay={valueLabelDisplay}
        valueLabelFormat={"212"}
        ThumbComponent={ThumbComponent}
        onChange={(event, value) => {
          onChange(name, value);
        }}
      />
    </div>
  );
}

/*
 * PropType validation
 */
CustomizedSlider.propTypes = {
  step: PropTypes.number.isRequired,
  marks: PropTypes.array.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  defaultValue: PropTypes.number.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  rootElStyle: PropTypes.object.isRequired,
};
