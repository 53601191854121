import React, {useState } from "react";
import { FaTrash, FaEdit, FaFileInvoice } from "react-icons/fa";
import Modal from "components/modal";
import { monthsValue } from 'constants/ydmValue';
import isEmpty from "helpers/isEmpty";
import Tried from 'routes/navigate/try';
import DocumentViewer from 'components/common/commonDocumentViewer'
import Button from "components/button";
import {getUploadPath} from 'constants/userDetails'

function DetailCard(props) {
    let [ modalOpen, setModalOpen ] = useState(false)
    let title1;
    let subTitle1;
    let subTitle11;
    
    let title2;
    let subTitle2;
    let subTitle22;
    
    let Files;
    let ServerFiles;
    let title = props.title;
    let hideActionButton;

    if (title == 'education') {
            const qindex = props.educationQualificationList && 
                props.educationQualificationList.findIndex(e => e.value == props.detail.LevelTypeID);
            title1 =  props.detail  && props.detail.Course;
            subTitle1 = `${props.detail.College}${props.detail.Country !== null ? ', '+ props.detail.Country : ''}`;
            subTitle11 = props.detail && props.detail.University;

            title2 = props.educationQualificationList !=null && 
                props.educationQualificationList[qindex] && props.educationQualificationList[qindex].label;

            subTitle2 = `${props.detail.PassedYear ? props.detail.PassedYear : ''}${props.detail.GradeValue !== null ? ', '+ props.detail.GradeValue : ''}`;
            subTitle22 = props.detail  && props.detail.MajorSubjects;
            
            Files = props.detail  && props.detail.UserFileName;
            ServerFiles = props.detail  && props.detail.ServerFileName;
    }
    if (title == 'training') {
            title1 =  props.detail  && props.detail.TrainingName;
            title2 = null;
            Files = props.detail  && props.detail.UserFileName;
            ServerFiles = props.detail  && props.detail.ServerFileName;

            subTitle1 = `${props.detail.InstitutionName ? props.detail.InstitutionName : ''}${props.detail.Country !== null ? ', '+ props.detail.Country : ''}`;
            subTitle11 = '';

            subTitle2 = `${props.detail.Year ? props.detail.Year : ''}`;
            subTitle22 = '';
    }
    if (title == 'Reference') {
            title1 =  props.detail  && props.detail.PersonName;
            title2 = props.detail  && props.detail.Phone;

            Files = props.detail  && props.detail.UserFileName;
            ServerFiles = props.detail  && props.detail.ServerFileName;

            subTitle1 = `${props.detail.Position ? props.detail.Position : ''}`;
            subTitle11 = props.detail  && props.detail.Organization;

            subTitle2 = `${props.detail.Email ? props.detail.Email : ''}`;
            subTitle22 = '';


    }
    if (title == 'workExperience') {
            title1 =  props.detail  && props.detail.Organization;
            title2 = props.detail  && props.detail.Place;

            Files = props.detail  && props.detail.UserFileName;
            ServerFiles = props.detail  && props.detail.ServerFileName;
            let fromMonths = monthsValue[monthsValue.findIndex(m => m.value == (props.detail.FromMonth))].label;
            let toMonths = monthsValue[monthsValue.findIndex(m => m.value == (props.detail.ToMonth))].label;

            subTitle1 = `${props.detail.Position ? props.detail.Position : ''}`;
            subTitle11 = props.detail  && props.detail ? 
            `${fromMonths} ${props.detail.FromYear} - ${toMonths} ${props.detail.ToYear}`: '';

            subTitle2 = `${props.detail.ServicePeriod ? props.detail.ServicePeriod : ''}`;
            subTitle22 = '';
    }
    const deleteEmployeeItem = (flag) => {
        setModalOpen(flag);
    }
    
    const confirmDeleteEmployeeItem = async () => {
        let id;
        if (title === 'education') id = props.detail.EductionId;
        else if (title == 'training') id = props.detail.TrainingId;
        else if (title == 'workExperience') id = props.detail.PrevEmploymentId;
        else if (title == 'Reference') id = props.detail.ReferenceId;
        await props.deleteEmployeeItem(id);
        deleteEmployeeItem(false)
    }
    const changeBgColor = () => {
        if (title == 'education'){
            if (props.editViewId === props.detail.EductionId &&
                props.currentlySelectedEduSection == 'education')
                return 'education-detail education-detail-withBg';
            else
                return 'education-detail';
        } else if (title == 'training') {
            if (props.editViewId === props.detail.TrainingId &&
                props.currentlySelectedEduSection == 'training')
                return 'education-detail education-detail-withBg';
            else
                return 'education-detail';
        } else if (title == 'Reference') {
            if (props.editViewId === props.detail.ReferenceId &&
                props.currentlySelectedEduSection == 'Reference')
                return 'education-detail education-detail-withBg'
            else
                return 'education-detail';
        } else if (title == 'workExperience') {
            if (props.editViewId === props.detail.PrevEmploymentId &&
                props.currentlySelectedEduSection == 'workExperience')
                return 'education-detail education-detail-withBg';
            else
                return 'education-detail';
        }
    }
    const showHideActionButtons = () => {
        if (title == 'education'){
            if (props.editViewId === props.detail.EductionId &&
                props.currentlySelectedEduSection == 'education')
                return true;
            else
                return false;
        } else if (title == 'training') {
            if (props.editViewId === props.detail.TrainingId &&
                props.currentlySelectedEduSection == 'training')
                return true;
            else
                return false;
        } else if (title == 'Reference') {
            if (props.editViewId === props.detail.ReferenceId &&
                props.currentlySelectedEduSection == 'Reference')
                return true;
            else
                return false;
        } else if (title == 'workExperience') {
            if (props.editViewId === props.detail.PrevEmploymentId &&
                props.currentlySelectedEduSection == 'workExperience')
                return true;
            else
                return false;
        };
    }

    if (Files != null) {
        // ServerFiles = `/Uploads/${ServerFiles}`;
        ServerFiles = getUploadPath(ServerFiles);
    }
        
    if (isEmpty(props.detail)) return null;

    const closeAddSection = () => {
        if (props.editView) props.toggleEditView();
        else props.closeAddSection();
    }
    return (
    <div className={changeBgColor()}>
        <div className="education-detail__body">
            <div className="education-detail__primary">
                {props.detail ? <><span className="education-detail__title">{title1}</span>
                <span>{subTitle1}</span>
                <span>{subTitle11}</span></>
                : null}
            </div>
            <div className="education-detail__secondary">
                <div className="education-detail__primary">
                    {props.detail ? <><span className="education-detail__title">{title2}</span>
                    <span>{subTitle2}</span>
                    <span>{subTitle22}</span></>
                    : null}
                </div>

                {Files && 
                    <DocumentViewer document={ServerFiles}>
                        <a className="education-detail__files" >
                            <FaFileInvoice />
                        </a>
                    </DocumentViewer>
                }
            </div>
            <Modal
                type="confirm"
                open={modalOpen}
                onModalClose={() => deleteEmployeeItem(false)}
                func={() => confirmDeleteEmployeeItem()}
            />
            {showHideActionButtons() ?
            <Button onClick={() => closeAddSection()} bg="secondary" className="wd-70">
                Cancel
            </Button> :
            <div className="education-detail__secondary education-detail__toolbars">
                <span
                className="education-detail__trash"
                onClick={() => deleteEmployeeItem(true)}
                >
                    <FaTrash />
                </span>
                <span onClick={() => props.toggleEditView(props.detail, title)} className="education-detail__edit">
                    <FaEdit />
                </span>
            </div>}
        </div>
    </div>
    );
}

export default DetailCard;