import React, { useState } from 'react'
import Button from 'components/button';
import QuestionContent from './contents/quetionContent'
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { BsFillQuestionSquareFill, BsTrash } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';
import { deleteCourseQuestion } from '../helpers/action';

const convertQuestions = (questions) =>{
    const final = {};
    if(questions){

    }
    return final;
}


function AddQuestions(props) {
    const [open,setOpen] = useState(false);
    const {questionDetails} = useSelector((state:any)=>state.lmsReducer);
    const dispatch = useDispatch()
    const onClear = () => {
        setOpen(false)
    }
    const onQuestionDelete = (id) =>{
        dispatch(deleteCourseQuestion(id))
    }
    return (
        <div className="lms-questions">
            {
                    questionDetails && questionDetails.length > 0 && (
            <div className="lms-questions__container">
                
                        <div className="lms-questions__title">
                            <span>Questions</span>
                        </div>
                    
                <ul className="lms-questions__list">
                    {
                        questionDetails && questionDetails.map(item=>{
                            return(
                                <>
                                    <li className="lms-questions__item">
                                        <div className="lms-questions__item-action">
                                            <div 
                                                onClick={()=>onQuestionDelete(item.Id)}
                                                className="lms-questions__item-action-item">
                                                <MdClose />
                                                <span>Delete</span>
                                            </div>
                                        </div>
                                        <div className="lms-questions__item-container">
                                            <div className="lms-questions__q">
                                                {item.Question}
                                            </div>
                                            {
                                                (item.QuestionType === 1 || item.QuestionType === 1) && (
                                                    <div className="lms-questions__box"></div>
                                                )
                                            }
                                            {
                                                (item.QuestionType === 3 || item.QuestionType === 4) && (
                                                    <div className="lms-questions__answers">
                                                        {
                                                            item.AnswerOptions && item.AnswerOptions.map(ans=>{
                                                                return(
                                                                    <div className="lms-questions__answer">
                                                                        <div className={classnames("lms-questions__answer-circle",{"active":ans?.IsCorrect})}></div>
                                                                        <div className="lms-questions__answer-text">
                                                                            {ans.AnswerOption}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                )
                                            }

                                        </div>
                                    </li>
                                </>
                            )
                        })
                    }
                </ul>
            </div>
                    )}
             <Button 
                onClick={()=>setOpen(prev=>!prev)}
                title="Add Questions"
                bg="primary"  
                buttonType="icon-button"
                icon={<BsFillQuestionSquareFill />}
                style={{backgroundColor:'#2693FF',borderRadius:4}}
            />
            {
                open && (<QuestionContent onClear={onClear} {...props} />)
            }
        </div>
    )
}

export default AddQuestions
