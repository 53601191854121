import React, { useState, useEffect } from "react";
import Icons from "components/icons";
import FormGroup from "components/form";
import isEmpty from "isEmpty";
import classNames from "classnames";
import { GoPin } from "react-icons/go";
import moment from "moment";
import { useSelector } from "react-redux";

interface IAppProps {
  comments?: any;
  submitComment: Function;
  submitErrors?: any;
  isViewMode?: boolean;
  deleteComment?: Function;
  showDeleteButton?: boolean;
  commentTitle: string;
}

const App: React.FunctionComponent<IAppProps> = (props) => {
  const user = useSelector((state) => state["auth"].user);
  const { comments, commentTitle, submitComment, submitErrors, isViewMode, deleteComment, showDeleteButton  } = props;
  const [comment, setComment] = React.useState("");
  const [allComments, setComments] = React.useState([]);
  const [myComment, setMyComment] = React.useState({});
  const [errors, setErrors] = React.useState({ comment: "" });
  useEffect(() => {
    setErrors(submitErrors);
    setComments(comments);
    setMyComment(comments?.find((x) => x.commenterId === user.EmployeeId));
  }, [submitErrors, comments]);
  const onCommentChange = (name, value) => {
    setComment(value);
    setErrors({ comment: "" });
  };
  const saveComment = async () => {
    if (isEmpty(comment)) setErrors({ comment: "Required" });
    else {
      let status = await submitComment(comment);
      if (status) {
        let myComment = {
          commenter: "My Recommendation",
          commentDate: moment(new Date(), "YYYYMMDDHHmmss").format(
            "MMM DD, YYYY hh:mm A"
          ),
          comment: comment,
          commenterId: user.EmployeeId,
        };
        setMyComment(myComment);
        let newComments = allComments.map((x) => {
          return { ...x };
        });
        newComments.push(myComment);
        setComments(newComments);
      }
    }
  };
  const removeComment = () => {
    setMyComment("");
    setComment("");
    submitComment("");
  };

  if (isViewMode && isEmpty(allComments)) return null;
  return (
    <div className="comment">
      <div className="comment__header">
        <span>{commentTitle}</span>
      </div>
      <div className="comment__body mt-sm">
        {allComments?.map((item, index) => {
          return (
            <div key={item.commentId} className="flex items-start comment-li">
              {item.commenterId === user.EmployeeId ? (
                <GoPin
                  name="Comment"
                  className={classNames({
                    "text-xbg": true,
                  })}
                />
              ) : (
                <Icons name="Comment" />
              )}
              <div
                className={classNames({
                  "flex-column ml-sm items-start  comment-details": true,
                  "last-child": index + 1 === allComments.length,
                })}
              >
                <div className="flex">
                  <span className="commenter">{item.commenterId === user.EmployeeId ? ("My " + commentTitle) : item.commenter}</span>
                  <span className="comment-date ml-sm">{item.commentDate}</span>
                  {showDeleteButton && item.commenterId === user.EmployeeId && !isViewMode &&<span onClick={() => deleteComment(item)}><Icons name="Delete"/> </span>}
                </div>
                <span className="comment-text">{item.comment}</span>
              </div>
            </div>
          );
        })}
        {!isEmpty(myComment)
          ? null
          : !isViewMode && (
              <div
                className={classNames({
                  "comment-input mt-sm flex": true,
                  "items-start": !isEmpty(errors?.comment),
                  "no-comment": isEmpty(allComments),
                })}
              >
                <FormGroup
                  name="comment"
                  value={comment}
                  onChange={onCommentChange}
                  label=""
                  error={errors && errors?.comment}
                  width="400px"
                  validators={["required"]}
                />
                <div
                  className="flex cursor-pointer check-button"
                  onClick={saveComment}
                >
                  <Icons name="Check" />
                </div>
              </div>
            )}
      </div>
    </div>
  );
};

export default App;
