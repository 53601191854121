import React from "react";
import FormGroup from "components/form";

export default function JobDescription({ data, onChange }) {
  return (
    <div className="form-block">
      <div className="form-block__title">
        <h3>Job Description</h3>
      </div>
      <div className="form-block__form">
        <FormGroup
          formName="texteditor"
          value={data || ""}
          defaultValue={data}
          toolbarId={"jobDescriptionEditor"}
          placeholder={"Write something"}
          name="JobDescription"
          onChange={(name, value) => {
            onChange("JobDescription", value);
          }}
          width="100%"
        />
      </div>
    </div>
  );
}
