import React, { Component } from 'react'
import PropTypes from 'prop-types';
import {FaCaretDown} from 'react-icons/fa'
import {MdClose} from 'react-icons/md'
import isEmpty from 'isEmpty'
import classnames from 'classnames'

export class Dropdown extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            active:false
        }
        this.onClick=this.onClick.bind(this)
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.renderLeftIcon=this.renderLeftIcon.bind(this);
        this.renderHeader=this.renderHeader.bind(this);
        this.renderNoData=this.renderNoData.bind(this);
        this.renderTitle=this.renderTitle.bind(this);
        this.renderContent=this.renderContent.bind(this);
        this.renderChildren=this.renderChildren.bind(this);
        this.renderList=this.renderList.bind(this);
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
      }
    
      componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
      }
      setWrapperRef(node) {
        this.wrapperRef = node;
      }
      handleClickOutside(e) {
        
        if (this.wrapperRef && !this.wrapperRef.contains(e.target) && !this.setDropDown.contains(e.target)) {
            this.setState({active:false})
        }
      }
    onClick(e){
        e.stopPropagation();
        this.setState({active:!this.state.active})
    }

    renderLeftIcon(){
        const {leftIcon,leftValue,dynamicLeft} =this.props;
        return !isEmpty(leftIcon)?
        <div className="dropdown-left">
            {
                dynamicLeft?
                    <span>{isEmpty(leftValue)?leftIcon:leftValue}</span>
                    :
                    <span>{leftIcon}</span>
            }
            
        </div>
        :null
    }
    renderTitle(){
        const {title,active}=this.props;
        return (
            <div className="dropdown-title">
                {
                    isEmpty(active)?<h3>{title}</h3>
                    :<h3>{ typeof active ==='object'?active.Text:active}</h3>    
                }
            </div>
        )
    }
    renderHeader(){
        return (
            <div className="dropdown-container" onClick={this.onClick}>
                {this.renderLeftIcon()}
                {this.renderTitle()}
                <div className="dropdown-right">
                    <FaCaretDown />
                </div>
            </div>
        )
    }
    renderOnCancel(){
        const {onCancel,active,name}=this.props;
        return(
            !isEmpty(onCancel) && !isEmpty(active)?
                <li className="dropdown-list__item cancel" onClick={(e)=>onCancel(e,name)}>
                    <MdClose />
                </li>
            :null
        )
    }
    renderContent(){
        const {lists,children}=this.props;
        const contentClass= classnames({
            "dropdown-content":true,
            "dropdown-open":this.state.active
        });
        let contentView;
        contentView = !isEmpty(lists)?this.renderList():
                        isEmpty(lists) && !isEmpty(children)?this.renderChildren():
                        this.renderNoData()
        return(
            <div ref={this.setWrapperRef} className={contentClass} >
                {contentView}
            </div> 
        )
    }
    renderList=()=>{
        const {lists,name,onClick,active}=this.props;
        return(
            <ul className="dropdown-list">
                {this.renderOnCancel()}
                {
                    lists.map((list,key)=>{
                                    
                        let liClass = classnames({
                            'dropdown-list__item':true,
                            'active-item':list===active
                        })
                        return <li key={key} className={liClass} onClick={(e)=>{onClick(e,name,list);this.setState({active:false})}}>
                            <span>{list}</span>
                        </li>
                    })
                }
            </ul>
        )
    }
    renderChildren(){
        const {children,name,onClick,active,hr,dynamicLeft}=this.props;
        let isSingle= !Array.isArray(children) && typeof children ==='object';
        let content;
        if(isSingle){
            content = (
                React.cloneElement(children, {
                    key: 0,
                    className:classnames({
                        'dropdown-list__item':true,
                        [children.props.className]:!isEmpty(children.props.className),
                        'hr':hr
                    })
                })
            )
        }else{
            content=children.map((slide, index) => {
                if(!isEmpty(slide)){
                    let left =false;
                    let leftValue="";
                    
                    if(typeof slide.props.children==='object' && !Array.isArray(slide.props.children)){
                        // if(!isEmpty(slide.props.children.props.dynamic) && slide.props.children.props.dynamic==='true'){
                        //     left=true;
                        //     leftValue=slide.props.children.props.children
                        // }
                        if(dynamicLeft){
                            left=true;
                            leftValue=slide.props.children.props.children
                        }
                    }else{
                        if(dynamicLeft){
                            left=true;
                            leftValue=slide.props.children[0].props.children
                        }
                        // if(!isEmpty(slide.props.children[0].props.dynamic) &&slide.props.children[0].props.dynamic==='true'){
                        //     left=true;
                        //     leftValue=slide.props.children[0].props.children
                        // }
                    }
                    let liClass = classnames({
                        'dropdown-list__item':true,
                        [slide.props.className]:!isEmpty(slide.props.className),
                        'active-item':slide.props.value.Value===active.Value,
                        'hr':hr
                    })
                    return React.cloneElement(slide, {
                        key: index,
                        onClick: (e)=>{onClick(e,{name,value:slide.props.value,left,leftValue});this.setState({active:false});},
                        className:liClass
                    });
                }
                
            })
        }
        return(
            <ul className="dropdown-list">
                {this.renderOnCancel()}
                {content}
            </ul>
        )
    }
    renderNoData = () => {
        const { lists,children}=this.props;
        return (
                isEmpty(lists) && isEmpty(children)?
                <ul className="dropdown-list">
                    <li className="dropdown-list__item">
                        <span>No Data Found</span>
                    </li>
                </ul>
                :null
        );
    }

    render() {
        return (
            <div className="dropdown" ref={node => this.setDropDown=node}>
                {
                    this.renderHeader()
                }
                {
                    this.renderContent()
                }
            </div>
        )
    }
}

Dropdown.propTypes={
    title:PropTypes.string.isRequired,
    lists:PropTypes.array,
    onClick:PropTypes.func,
    onCancel:PropTypes.func,
    active:PropTypes.any,
    name:PropTypes.string,
}

export default Dropdown
