import React from 'react'
import Button from 'components/button'
import FormGroup from 'components/form';
import Modal from 'components/modal'
import { InputValidator } from "helpers/inputValidator";
import isEmpty from "isEmpty";
import { objectToFormData } from "object-to-formdata";
import { connect } from "react-redux";
import {
  getEmployeeCitizenshipDetails,
  getEmployeeDrivingDetails,
  getEmployeePassportDetails,
  saveEmployeeDocumentDetails,
  updateEmployeeDocumentDetails,
} from "../action";
import {
  DisplayClientSideMessage,
  GetCompanyInformation
} from "actions/commonAction";
import { GetDistrictList } from "actions/commonAction";
import { title } from 'process';
import { convertDateYYMMDD }from "components/form/customDateInput";
import { Date_Format_Reverse, isDateTodayOrAfter } from "constants/dateFormat";
import { Date_Format } from "helpers/dateFormat";
import moment from "moment";
import { BS2AD, AD2BS } from "helpers/dateConvert";
interface IAppProps {
    EmployeeId: any;
    // EmployeeAddress: any;
    // GetProvienceList: Function,
    // GetDistrictByProvince: Function,
    // GetLocalBodyByDistrict: Function,
    // provinceList: any,
    districtList: any,
    // localBodyList: any,
    // editView?: any,
    getEmployeeCitizenshipDetails?: Function,
    getEmployeeDrivingDetails?: Function,
    getEmployeePassportDetails?: Function,
    saveEmployeeDocumentDetails?: Function,
    updateEmployeeDocumentDetails?: Function,
    DisplayClientSideMessage?: Function,
    title?: string,
    onModalClose?: Function,
    editData?: any;
    GetDistrictList: any,
    type: string,
    companyInformation: any,
  }
  
  interface State {
    doc_number: any,
    issue_district: any,
    date_issued_nep?: any,
    date_issued: any,
    date_expired: any,
    date_expired_nep: any,
    errors: any,
    Files: any,
    editMode: boolean,
    doc_type: any,
    CitizenshipId?: Number,
    DrivingLicenceId?: any,
    PassportId?: any,
    LiscenceTypeName?: any,
    IsFileEdit?: boolean,
  }
  
  class IdentificationForm extends React.Component<IAppProps, State> {
// function IdentificationForm(props) {
    state = {
      doc_number: null,
      issue_district: null,
      date_issued: null,
      date_issued_nep: null,
      date_expired: null,
      date_expired_nep: null,
      errors: null,
      Files: null,
      editMode: false,
      CitizenshipId: 0,
      DrivingLicenceId: 0,
      PassportId: 0,
      doc_type: null,
      LiscenceTypeName: null,
      IsFileEdit: true,
    }

    handleChange = (label, value) => {
      
      if (label == 'date_issued') {
        let selectedEngDate = value; 
        let selectedNepDate = value; 
        if (this.props.companyInformation.IsEnglishDate) {
          selectedEngDate = Date_Format_Reverse(new Date(value));
          selectedNepDate = AD2BS(selectedEngDate).en;
          selectedNepDate = `${selectedNepDate.year}/${selectedNepDate.month}/${selectedNepDate.day}`;
        } else {
          // selectedEngDate = value;
          selectedEngDate = `${BS2AD(value).year}/${BS2AD(value).month}/${BS2AD(value).day}`
        }
        this.setState({ [label]: selectedEngDate, date_issued_nep: selectedNepDate } as Pick<State, keyof State>);
      } else if (label == 'date_expired') {
        // let englishDate = Date_Format_Reverse(new Date(value));
        // const nepDate = AD2BS(englishDate).en;
        // const nepFullDate = `${nepDate.year}/${nepDate.month}/${nepDate.day}`;
        let selectedEngDate = value; 
        let selectedNepDate = value; 
        // let selectedFullDate = value; 
        if (this.props.companyInformation.IsEnglishDate) {
          selectedEngDate = Date_Format_Reverse(new Date(value));
          selectedNepDate = AD2BS(selectedEngDate).en;
          selectedNepDate = `${selectedNepDate.year}/${selectedNepDate.month}/${selectedNepDate.day}`;
        } else {
          // selectedEngDate = value;
          selectedEngDate = `${BS2AD(value).year}/${BS2AD(value).month}/${BS2AD(value).day}`
        }
        this.setState({ [label]: selectedEngDate, date_expired_nep: selectedNepDate } as Pick<State, keyof State>);
      } else
        this.setState({ [label]: value } as Pick<State, keyof State>);
    }

    async componentDidMount() {
      await this.props.GetDistrictList();
      // await GetCompanyInformation();
      if (this.props.editData) {
        let data = {
          doc_number: null,
          issue_district: null,
          date_issued: null,
          editMode: true,
          CitizenshipId: 0,
          DrivingLicenceId: 0,
          doc_type: null,
          Files: null,
          PassportId: null,
          date_expired: null,
          IsFileEdit: false,
          date_issued_nep: null,
          date_expired_nep: null,
        }
        const { districtList, type, editData: {
          CitizenshipNo, PassportNo, DrivingLicenceNo, IssuingDateEng, Place,
          CitizenshipId, DrivingLicenceId, LiscenceTypeName, UserFileName, PassportId,
          ValidUpto, ValidUptoEng
        }, companyInformation } = this.props;

        let convertedIssuedDate = moment(IssuingDateEng).format('YYYY/MM/DD').toString();
        let convertedIssuedNepDate = moment(IssuingDateEng).format('YYYY/MM/DD').toString();
        let convertedValidUptoDate = moment(ValidUptoEng).format('YYYY/MM/DD').toString();
        let convertedValidUptoNepDate = moment(ValidUptoEng).format('YYYY/MM/DD').toString();

        // if (!companyInformation.IsEnglishDate) {
          let conversionDate = AD2BS(convertedIssuedDate).en;
          convertedIssuedNepDate = `${conversionDate.year}/${conversionDate.month}/${conversionDate.day}`
          if (ValidUptoEng) {
            let conversionValidUptoDate = AD2BS(convertedValidUptoDate).en;
            convertedValidUptoNepDate = `${conversionValidUptoDate.year}/${conversionValidUptoDate.month}/${conversionValidUptoDate.day}`
          }
        // }
        let district = this.props.districtList.filter(d => d.value == this.props.editData.DistrictId)
        if (type == '1') {
          data.doc_number = CitizenshipNo
          data.CitizenshipId = CitizenshipId
          data.issue_district = district[0];
          data.Files = {
            // noedit: true,
            name: UserFileName
          }
        }
        if (type == '2') {
          data.doc_number = DrivingLicenceNo;
          data.DrivingLicenceId = DrivingLicenceId;
          data.doc_type = LiscenceTypeName;
          data.date_expired = convertedValidUptoDate;
          data.date_expired_nep = convertedValidUptoNepDate;
          data.Files = {
            // noedit: true,
            name: UserFileName
          };
        }
        if (type == '3') {
          data.doc_number = PassportNo;
          // data.doc_number = PassportNo;
          data.PassportId = PassportId;
          data.issue_district = district[0];
          data.date_expired = convertedValidUptoDate;
          data.date_expired_nep = convertedValidUptoNepDate;
          data.Files = {
            // noedit: true,
            name: UserFileName
          };
        }
        // data.issue_district = district[0];
        data.date_issued = convertedIssuedDate;
        data.date_issued_nep = convertedIssuedNepDate;
        // data.date_issued = convertedIssuedDate;
        this.setState(data);
      }
    }

    handleSubmit = async () => {
      // let

      // let errors = await InputValidator(document, this.state);

      // if (!isEmpty(errors)) {
      //     this.setState({ errors });
      //     return;
      // }
      let {
        doc_number,
        issue_district,
        date_issued,
        date_issued_nep,
        date_expired,
        date_expired_nep,
        Files,
        editMode,
        doc_type,
        CitizenshipId,
        DrivingLicenceId,
        PassportId,
        IsFileEdit
      } = this.state;
      let fieldsToValidate = {
        doc_number,
        issue_district,
        date_issued,
        date_expired,
        doc_type,
      };
      const { type, EmployeeId, onModalClose, getEmployeeCitizenshipDetails,
        getEmployeeDrivingDetails, getEmployeePassportDetails
      } = this.props;
      if (type == '1') delete fieldsToValidate.date_expired;
      if (type == '1' || type == '3') delete fieldsToValidate.doc_type;
      // const { errs, isValid } = this.onValidate();
      let errors = await InputValidator(document, fieldsToValidate);
      // errors = { ...errors, ...errs }
      // if (errs.Name) {
      //   this.props.DisplayClientSideMessage('Document is Required');
      // }
      if (!isEmpty(errors)) {
          this.setState({ errors });
          return;
      }
      let data = {
        EmployeeId: parseInt(EmployeeId),
        CitizenshipNo: null,
        IssuingDate: null,
        Place: null,
        DistrictId: null,
        // Files: null,
        type: null,
        CitizenshipId: null,
        DrivingLicenceNo: null,
        LiscenceTypeName: null,
        DrivingLicenceIssueDate: null,
        DrivingLicenceId: null,
        PassportId: null,
        PassportNo: null,
        ValidUpto: null,
        IssuingDateEng: null,
        ValidUptoEng: null,
        IsFileEdit,
      }
      let nextDay =  new Date().setDate(new Date().getDate() + 1);
      if (isDateTodayOrAfter(date_issued, nextDay)) {
        this.props.DisplayClientSideMessage("Issue date cannot be the future date")
        return;
      }
      if (isDateTodayOrAfter(date_issued, date_expired ) && type !== '1' ) {
        this.props.DisplayClientSideMessage("Expired date should be greater issued date");
        return;
      }


      if (type == '1') {
        // data.IssuingDate = date_issued;
        data.type = '1';
        // data.IssuingDate = convertDateYYMMDD(date_issued, false);
        data.IssuingDate = date_issued_nep;
        data.IssuingDateEng = date_issued;
        data.CitizenshipNo = doc_number;
        data.CitizenshipId = editMode ? CitizenshipId : 0;
        data.Place = issue_district.label;
        data.DistrictId = issue_district.value;
      }
      if (type == '2') {
        data.type = '2';
        // data.IssuingDate = convertDateYYMMDD(date_issued, false);;
        data.IssuingDate = date_issued_nep;
        data.IssuingDateEng = date_issued;
        data.DrivingLicenceNo = doc_number;
        data.DrivingLicenceId = editMode ? DrivingLicenceId : 0;
        data.LiscenceTypeName = doc_type;
        data.ValidUpto = date_expired_nep;
        data.ValidUptoEng = date_expired;
      }
      if (type == '3') {
        data.type = '3';
        // data.IssuingDate = convertDateYYMMDD(date_issued, false);;
        data.IssuingDate = date_issued_nep;
        data.IssuingDateEng = date_issued;
        data.PassportNo = doc_number;
        data.PassportId = editMode ? PassportId : 0;
        data.ValidUpto = date_expired_nep;
        data.ValidUptoEng = date_expired;
        data.Place = issue_district.label;
        data.DistrictId = issue_district.value;
      }

      const options = {
          indices: true,
          nullsAsUndefineds: false,
          booleansAsIntegers: false,
      };
      
      const formData = objectToFormData(data, options);
      // if (Files.noedit == true)
      //   formData.append(`File`, null);
      // else 
        formData.append(`File`, Files);
      if (data.type == '1' && !editMode)
        await this.props.saveEmployeeDocumentDetails(formData, onModalClose, '1', getEmployeeCitizenshipDetails, EmployeeId);
      else if (data.type == '1' && editMode)
        await this.props.updateEmployeeDocumentDetails(formData, onModalClose, '1', getEmployeeCitizenshipDetails, EmployeeId);

      if (data.type == '2' && !editMode)
        await this.props.saveEmployeeDocumentDetails(formData, onModalClose, '2', getEmployeeDrivingDetails, EmployeeId);
      if (data.type == '2' && editMode)
        await this.props.updateEmployeeDocumentDetails(formData, onModalClose, '2', getEmployeeDrivingDetails, EmployeeId);

      if (data.type == '3' && !editMode)
        await this.props.saveEmployeeDocumentDetails(formData, onModalClose, '3', getEmployeePassportDetails, EmployeeId);
      if (data.type == '3' && editMode)
        await this.props.updateEmployeeDocumentDetails(formData, onModalClose, '3', getEmployeePassportDetails, EmployeeId);

      // if (this.props.editView)
      //     await this.props.updateEmployeeEducationDetails(formData);
      // else
          // await this.props.saveEmployeeAddressDetails(data);
      // if (this.props.employeeAddressSaved) {
      //   this.props.toggleEditMode(false);
    }

    onFileUpload = (name,value) =>{
      const { Files } = this.state;
      this.setState({ Files : value, IsFileEdit: true });
    }

    onValidate = () =>{
      const errs:any = {};
      const { Files } = this.state;
      if(isEmpty(Files && Files.name)){
          errs.Name = 'Document Name field is required'
      }
      // if(isEmpty(Files.Description)){
      //     errs.Description = 'Document Name field is required'
      // }
      // if(Files.File === '' || Files.File === null){
      //     errs.File = 'Document File field is required'
      // }
      return { errs, isValid:isEmpty(errs) }
  }

    render() {

      const { type, onModalClose, districtList, companyInformation } = this.props;
      const {
        doc_number, issue_district,
        date_issued, date_expired, Files,
        errors, doc_type, date_issued_nep,
        date_expired_nep, editMode
      } = this.state;

      return (
          <div className="employee-block">
              {/* <div className="employee-block__heading">
                  {title}
              </div> */}
              <div className="employee-docs__form">
                  <FormGroup 
                      label="Number"
                      alignment="horizontal"
                      value={doc_number}
                      validators={["required"]}
                      name="doc_number"
                      error={ errors && errors.doc_number}
                      onChange={this.handleChange}
                  />
              </div>
              {type == '2' ? <div className="employee-docs__form">
                  <FormGroup 
                      label="Type"
                      alignment="horizontal"
                      value={doc_type}
                      validators={["required"]}
                      name="doc_type"
                      error={ errors && errors.doc_type}
                      onChange={this.handleChange}
                  />
              </div> : <div className="employee-docs__form">
                  <FormGroup 
                      formName="reactselect"
                      label="Issue District"
                      alignment="horizontal"
                      value={issue_district}
                      name="issue_district"
                      validators={["required"]}
                      options={districtList}
                      // width="100px"
                      error={ errors && errors.issue_district}
                      onChange={this.handleChange}
                  />
              </div>}
              <div className="employee-docs__form">
                  <FormGroup
                      formName="customdateinput" 
                      label="Date Issued"
                      alignment="horizontal"
                      value={companyInformation.IsEnglishDate ? date_issued : date_issued_nep}
                      validators={["required"]}
                      name="date_issued"
                      // isFullDetails={true}
                      error={ errors && errors.date_issued}
                      onChange={this.handleChange}
                      isNepali={!companyInformation.IsEnglishDate}
                      isTextInNepali={!companyInformation.IsEnglishDate}
                  />
              </div>
              {type !=="1" && <div className="employee-docs__form">
                  <FormGroup
                      formName="customdateinput" 
                      label="Expiry Date"
                      alignment="horizontal"
                      value={companyInformation.IsEnglishDate ? date_expired : date_expired_nep}
                      validators={["required"]}
                      name="date_expired"
                      error={ errors && errors.date_expired}
                      onChange={this.handleChange}
                      isNepali={!companyInformation.IsEnglishDate}
                      isTextInNepali={!companyInformation.IsEnglishDate}
                  />
              </div>}
              <div className="employee-docs__form employee-docs__form-upload">
                  <FormGroup 
                      formName="singledocumentinput"
                      btnText="Add Documents"
                      validators={["required"]}
                      value={Files}
                      error={ errors && errors.File}
                      onChange={this.onFileUpload}
                  />
              </div>
              <div className="additional-documents__footer">
                  <Button
                      title={!editMode ? "Add": 'Update'}
                      bg="primary"
                      onClick={()=> this.handleSubmit()}
                  />
                  <Button
                      title="Cancel"
                      bg="secondary-dark"
                      onClick={() => onModalClose()}
                  />
              </div>
          </div>
      )

    }
}
const mapStateToProps = state => ({
  companyInformation: state.commonReducer.companyInformation,
  districtList: state.commonReducer.districtList,
});

const mapDispatchToProps = {
  getEmployeeCitizenshipDetails,
  getEmployeeDrivingDetails,
  getEmployeePassportDetails,
  saveEmployeeDocumentDetails,
  GetDistrictList,
  updateEmployeeDocumentDetails,
  DisplayClientSideMessage,
  GetCompanyInformation,
};

export default connect(mapStateToProps, mapDispatchToProps)(IdentificationForm);

// export default IdentificationForm
