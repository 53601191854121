import * as React from "react";
import Loader from "components/loaders";

const requestBlockWrapper = () => (WrappedComponent) => {
  interface IAppProps {
    request: any;
    handleRowChange: Function;
    handleIndexChange: Function;
    pageIndex: number;
    rowsPerPage: number;
    refreshRequestData: Function;
  
    handleConfirmCancelRequest?: Function;
    handleApproveRequest?: Function;
  }

  const App: React.FunctionComponent<IAppProps> = (props) => {
    console.log(props.request.loading);
    return (
      <>
        {props.request.loading ? (
          <Loader
            type="skeleton"
            name="card"
            cardType="travel"
            noRows={10}
            loading={props.request.loading}
          />
        ) : (
          <WrappedComponent {...props} rows={props.request.value} />
        )}
      </>
    );
  };
  return App;
};
export default requestBlockWrapper;
