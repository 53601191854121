import React from "react";
import '../style.scss';
import { connect } from 'react-redux';

function PropertyDetails({ homeLoanDetail }) {
  return (
    <div className="homeloan-view-employeedetails healthCard__health mt-md">
      <div className="healthCard-heading">
        <h4>Property Details</h4>
      </div>
      <div className="healthCard__health__details">
        <div className="placement-details">
          <div className="placement-details__block">
            <div className="placement-details__table">
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Property Location
                </div>
                <div className="placement-details__col placement-val">
                  {homeLoanDetail?.Location || '-'}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Owner
                </div>
                <div className="placement-details__col placement-val">
                  {homeLoanDetail?.PropertyOwner || '-'}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                 BluePrint Approved
                </div>
                <div className="placement-details__col placement-val">
                  {homeLoanDetail?.BluePrintApproved ? "YES": "NO"}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Builder
                </div>
                <div className="placement-details__col placement-val">
                  {homeLoanDetail?.LandBuilderName || '-'}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Apartment Location
                </div>
                <div className="placement-details__col placement-val">
                  {homeLoanDetail?.ApartmentLocation || '-'}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Apartment Name
                </div>
                <div className="placement-details__col placement-val">
                  {homeLoanDetail?.ApartmentName || '-'}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Apartment Number
                </div>
                <div className="placement-details__col placement-val">
                  {homeLoanDetail?.ApartmentNumber || '-'}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Builder
                </div>
                <div className="placement-details__col placement-val">
                  {homeLoanDetail?.ApartmentBuilderName || '-'}
                </div>
              </div>
            </div>
          </div>
          </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  homeLoanDetail: state.loanReducer.homeLoanDetail,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyDetails);
