import React, { useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { FaRegComment, FaRegUserCircle, FaRegCopy } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";
import { GrClose } from "react-icons/gr";
import {
  addTask,
  deleteTasks,
  duplicateTask,
  moveTask,
} from "../../../../../helpers/action";
import { FiLock, FiArrowRightCircle } from "react-icons/fi";
import Popup from "components/popup";
import classnames from "classnames";
import { TMStage } from "scenes/tasks/helpers/enum";
import Stages from "scenes/tasks/board/taskBody/tableView/table/component/customCell/labelCell/stages";
import CustomPopup from "components/common/customPopup";
const TaskSelectPopup = (props: any) => {
  const { selectedTask, setSelectedTask, setClear, BoardId } = props;
  const dispatch = useDispatch;
  const [popup, setPopup] = React.useState(false);
  const [activeStage, setActiveStage] = React.useState(0);
  const ids = selectedTask.map((item) => item.TaskId);
  const moveRef = useRef(null);
  
  const onTasksDelete = async () => {
    props.deleteTasks(ids, BoardId);
    clearSelected();
  };
  const onTaskDuplicate = () => {
    props.duplicateTask(ids, BoardId);
    clearSelected();
  };
  const onTaskMove = (stage) => {
    props.moveTask(ids, stage, BoardId);
    clearSelected();
    setPopup(false);
  };
  const clearSelected = () => {
    setSelectedTask([]);
    setClear(true);
  };

  return (
    <div className="task-select">
      <div className="task-select__container">
        <div className="task-select__side side-primary">
          <div className="task-select__count">{selectedTask.length}</div>
        </div>
        <div className="task-select__center">
          <div className="task-select__center-body">
            <div className="task-select__title">
              <span>Task Selected</span>
            </div>
            <div className="task-select__actions">
              <ul className="task-select__actions-list">
                <li
                  onClick={() => onTaskDuplicate()}
                  className="task-select__actions-item"
                >
                  <div className="task-select__action">
                    <FaRegCopy />
                    <span>Duplicate</span>
                  </div>
                </li>
                <li
                  onClick={() => onTasksDelete()}
                  className="task-select__actions-item"
                >
                  <div className="task-select__action">
                    <BsTrash />
                    <span>Delete</span>
                  </div>
                </li>
                <li
                  ref={moveRef}
                  onClick={() => setPopup(!popup)}
                  style={{ position: "relative" }}
                  className="task-select__actions-item"
                >
                  <div className="task-select__action">
                    <FiArrowRightCircle />
                    <span>Move To</span>
                  </div>
                  {popup && (
                    <Popup
                      onClose={() => setPopup(false)}
                      open={popup}
                      parentPop={moveRef}
                      // disableClickOutside
                      hideFooter
                      // onDone={(close) => onTaskMove(close)}
                    >
                      <Stages
                        onClickStage={(stage) => {
                          onTaskMove(stage);
                        }}
                      />
                    </Popup>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="task-select__side side-cancel">
          <div onClick={() => clearSelected()} className="task-select__close">
            <GrClose />
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  deleteTasks,
  duplicateTask,
  moveTask,
};
export default connect(mapStateToProps, mapDispatchToProps)(TaskSelectPopup);
