import React, { useEffect, useState } from 'react'
import './styles.scss';
import sample from 'assets/images/sample.jpg'
import { IoMdArrowBack } from 'react-icons/io';
import { FaTimesCircle, FaPlusCircle } from 'react-icons/fa';
import { GrDrag } from 'react-icons/gr';
import MainPageForm from './mainPageForm';
import shortid from 'shortid';
import PageForm from './pageForm';
import { useDispatch, useSelector } from 'react-redux';
import { getCourse, getCourseContent, getCoursePage, getCoursePages, getQuestionDetails, updateMultipleCoursePage } from '../helpers/action';
import RouteEnum from 'constants/routeEnum';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const pages: any = [
    {
        id: shortid.generate(),
        data: null,
        isMain: true
    }
];
const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // change background colour if dragging
    background: isDragging ? "#2693FF" : "",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
});

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
};

function AddCourse(props) {
    const [state, setState] = useState(pages);
    const [data,setData] = useState([]);
    const dispatch = useDispatch();
    const { courseId, pageId } = props.match.params;
    const [active, setActive] = useState(-1);
    const { coursePages, course } = useSelector((state: any) => state.lmsReducer)
    useEffect(() => {
        if (pageId) {
            dispatch(getCoursePage(pageId))
            dispatch(getCourseContent(pageId))
            dispatch(getQuestionDetails(pageId))
            setActive(2)
        }
    }, [pageId])
    useEffect(()=>{
        if(coursePages){
            setData(coursePages)
        }
    },[coursePages])
    const addPage = () => {
        setState(prev => ([
            ...prev,
            {
                id: shortid.generate(),
                data: null,
            }
        ]))
    }
    const onActive = (id) => {
        props.history.push(RouteEnum.lmsCourseAdd + '/' + courseId);
        setActive(id);
    }

    useEffect(() => {
        if (courseId) {
            dispatch(getCourse(courseId))
            dispatch(getCoursePages(courseId))
        }
    }, [courseId]);
    
    const onDragEnd = async (result) => {
        if (!result.destination) {
            return;
        }
        const items = reorder(
            data,
            result.source.index,
            result.destination.index
        );
        const newItems = items.map((item:any,index)=>({...item,PageOrder:index}))
        await dispatch(updateMultipleCoursePage(newItems));
        setData(newItems)
    }

    return (
        <div className="courseForm">
            <div className="courseForm__header">
                <div
                    onClick={() => props.history.push(RouteEnum.lmsCourseManagement)}
                    className="courseForm__header-back">
                    <IoMdArrowBack />
                </div>
                <div className="courseForm__header-cover">
                    <img src={course?.ServerFileName} alt={course?.Title} />
                </div>
                <div className="courseForm__header-title">
                    <span>{course?.Title}</span>
                </div>
            </div>
            <div className="courseForm__body">
                <div className="courseForm__body-container">
                    {active === -1 && <MainPageForm {...props} />}
                    {active === 1 && <PageForm courseId={courseId} isJustForm {...props} />}
                    {active === 2 && <PageForm pageId={pageId} courseId={courseId} {...props} />}

                    {/* <PageForm /> */}
                </div>

                {
                    course && (
                        <DragDropContext onDragEnd={onDragEnd}>

                            <div className="courseForm__body-sidebar">
                                <div className="courseForm__body-sidebar-title">Outline</div>
                                <Droppable droppableId="course-pages" type="collection" >
                                    {(provided, snapshot) => (
                                        <ul
                                        className="courseForm__body-sidebar-list"
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        nf={provided}
                                            >
                                            <li
                                                onClick={() => onActive(-1)}
                                                className="courseForm__body-sidebar-item first">
                                                <div className="courseForm__body-sidebar-cover">
                                                    <img src={course?.ServerFileName} alt={course?.Title} />
                                                </div>
                                                <div className="courseForm__body-sidebar-content">{course?.Title}</div>
                                            </li>
                                            {
                                                data && data.map((item, index) => {
                                                    return (
                                                        <Draggable 
                                                            key={"Page-" + item.Id} 
                                                            draggableId={"Page-" + item.Id} 
                                                            index={index}
                                                            disableInteractiveElementBlocking="true"
                                                        >
                                                            {(provided, snapshot) => (
                                                                <li
                                                                {...provided.draggableProps}
                                                                // {...provided.dragHandleProps}
                                                                ref={provided.innerRef}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}


                                                                    className="courseForm__body-sidebar-item">
                                                                    <div
                                                                        {...provided.dragHandleProps}
                                                                        className="courseForm__body-sidebar-icon"><GrDrag /></div>
                                                                    <div
                                                                        onClick={() => {
                                                                            props.history.push(RouteEnum.lmsCourseAdd + '/' + item.CourseId + '/' + item.Id)
                                                                            setActive(2)
                                                                        }}
                                                                        className="courseForm__body-sidebar-content">{item.PageName}</div>
                                                                </li>
                                                            )}
                                                        </Draggable>

                                                    )
                                                })
                                            }
                                            {provided.placeholder}
                                        </ul>
                                    )}

                                </Droppable>
                                <div
                                    onClick={() => onActive(1)}
                                    className="courseForm__body-sidebar-add">
                                    <FaPlusCircle /> Add page
                                </div>
                            </div>


                        </DragDropContext>

                    )
                }
            </div>
        </div>
    )
}


export default AddCourse
