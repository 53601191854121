import React, { Component } from "react";
import { Time_Format } from "helpers/dateFormat";

class index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: new Date(),
    };
  }
  componentDidMount() {
    this.intervalID = setInterval(() => this.tick(), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  tick() {
    const date = new Date();
    this.setState({
      time: date,
    });
    this.props.getTimeText && this.props.getTimeText(Time_Format(date));
  }
  render() {
    return <p>{Time_Format(this.state.time)}</p>;
  }
}

export default index;
