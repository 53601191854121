import FormGroup from "components/form";
import Modal from "components/modal";
import {
  KNOWLEDGE_SKILL_ABILITY_TYPE,
  JOB_PROFILE_PARAGRAPH_TYPE,
} from "constants/constants";
import isEmpty from "isEmpty";
import React, { Component } from "react";
import { GoPlus } from "react-icons/go";
import { GrCheckmark, GrClose, GrFormClose } from "react-icons/gr";
import {
  MdArrowDownward,
  MdArrowUpward,
  MdDelete,
  MdEdit,
} from "react-icons/md";
import AddComponentForm from "./addComponentForm";
import InsertKSAForm from "../common/insertKSAForm";

const profileBlockType = KNOWLEDGE_SKILL_ABILITY_TYPE;
class KnowledgeSkillAbilities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNameEdit: false,
      isComponentFormShow: false,
      subtitleToEdits: [],
      defaultComponents: [],
      isInsertKSAFormShow: false,
      selectedType: "",
      intialBlockData: {},
      errors:{}
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount() {
    const { defaultJobProfiles } = this.props;
    var stateCopy = this.props.state.profileBlocks.map((x) => {
      return { ...x };
    });
    const currentSection = stateCopy.find((x) => x.type === profileBlockType);
    let currentDefaultProfile = defaultJobProfiles.Sections.find(
      (x) => x.SectionTypeName === profileBlockType
    );
    let defaultComponents =
      currentDefaultProfile &&
      currentDefaultProfile.Components.map((x) => {
        return {
          Id: x.ComponentTypeRefId,
          Name: x.ComponentName,
          Type: x.ComponentTypeName,
        };
      });
    this.setState({ intialBlockData: currentSection, defaultComponents });
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  handleSectionNameEdit = (edit) => {
    this.setState({ isNameEdit: edit });
  };
  handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      this.handleSectionNameEdit(false);
    }
  };

  handleComponentFormShow = (show) => {
    this.setState({ isComponentFormShow: show });
  };

  handleAddComponent = (components, customSectionText, sectionId) => {
    this.props.handleAddComponent(components, customSectionText, sectionId);
    this.handleComponentFormShow(false);
  };
  showcloseAddEduQualForm = (type) => {
    this.setState({ selectedType: type, isInsertKSAFormShow: true });
  };
  closeKSAForm = () => {
    this.setState({ isInsertKSAFormShow: false });
  };
  handleRemoveSubSection = (type, id) => {
    this.props.handleRemoveSubSection(this.props.id, type, id);
  };
  handleSubtitleEditClick = (id) => {
    let { subtitleToEdits } = this.state;
    subtitleToEdits.push(id);
    this.setState({ subtitleToEdits });
  };
  handleInputChange = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value });
  };
  handleSaveTitle = (value) => {
    if (isEmpty(value)) this.setState({ errors: { title: "Required" } });
    else {
      this.setState((prevState) => ({
        ...prevState,
        intialBlockData: {
          ...prevState.intialBlockData,
          title: value,
        },
        errors: { ...prevState.errros, title: "" },
      }));

      this.handleSectionNameEdit(false);
    }
  };
  handleSaveSubtitle = (value, id) => {
    let newIntialBlockData = this.state.intialBlockData.components.map((x) => {
      let o = { ...x };
      if (x.id === id) {
        o.subtitle = value;
      }
      return o;
    });
    this.setState((prevState) => ({
      ...prevState,
      intialBlockData: {
        ...prevState.intialBlockData,
        components: newIntialBlockData,
      },
      subtitleToEdits: [],
    }));
  };
  handleRemoveChangedTitle = () => {
    const currentSection = this.state.intialBlockData;
    this.props.handleProfileBlockTitle(profileBlockType, currentSection.title);
    this.handleSectionNameEdit(false);
  };
  handleRemoveChangedSubtitleTitle = () => {
    const id = this.state.subtitleToEdits[0];
    if (!isEmpty(id)) {
      const currentComponent = this.state.intialBlockData.components.find(
        (x) => x.id === id
      );
      this.props.handleComponentFieldChange(
        profileBlockType,
        id,
        "subtitle",
        currentComponent.subtitle
      );
      const { subtitleToEdits } = this.state;
      const index = subtitleToEdits.indexOf(id);
      if (index > -1) {
        subtitleToEdits.splice(index, 1);
      }
      this.setState({ subtitleToEdits });
    }
  };

  handleClickOutside(event) {
    let containsTarget = false;
    let targetTitleEl = document.getElementsByClassName("title-action");
    let targetEditSubTitleEl = document.getElementsByClassName(
      "subtitle-action"
    );
    for (let el of targetTitleEl) {
      if (el.contains(event.target)) {
        containsTarget = true;
        break;
      }
    }
    if (!containsTarget) {
      for (let el of targetEditSubTitleEl) {
        if (el.contains(event.target)) {
          containsTarget = true;
          break;
        }
      }
    }
    if (
      (!isEmpty(targetTitleEl) || !isEmpty(targetEditSubTitleEl)) &&
      !containsTarget
    ) {
      this.handleRemoveChangedTitle();
      this.handleRemoveChangedSubtitleTitle();
    }
  }
  render() {
    const {
      isNameEdit,
      subtitleToEdits,
      defaultComponents,
      isComponentFormShow,
      isInsertKSAFormShow,
      selectedType,
      errors,
    } = this.state;
    const {
      handleInput,
      handleBlockMove,
      index,
      subComponent,
      isViewMode,
      handleRemoveSection,
    } = this.props;
    const { profileBlocks, initialBlocks } = this.props.state;
    const currentSection = this.props.state.profileBlocks.find(
      (x) => x.type === profileBlockType
    );
    return (
      <div className="know-skill-ability profile-block">
        <div className="profile-block__header">
          <div>
            {!isNameEdit ? (
              <span className="profile-text">{currentSection.title}</span>
            ) : (
              <div className="flex">
                <FormGroup
                  name="title"
                  value={currentSection.title}
                  onChange={(name, value) =>
                    this.props.handleProfileBlockTitle(profileBlockType, value)
                  }
                  label=""
                  error={errors?.title}
                  onKeyUp={this.handleKeyUp}
                  validators={["required"]}
                  width="250px"
                  autoFocus
                  className="title-action"
                />
                <div
                  className="edit-box-title title-action"
                  onClick={() => this.handleSaveTitle(currentSection.title)}
                >
                  <GrCheckmark />
                </div>
                <div
                  className="edit-box-title ml-xsm title-action"
                  onClick={() => this.handleRemoveChangedTitle()}
                >
                  <GrClose />
                </div>
              </div>
            )}
            <span className="profile-type">{currentSection.type}</span>
          </div>
          <div className="flex justify-end action-bar">
            {!isNameEdit && (
              <button
                className="button with-icon"
                onClick={() => this.handleSectionNameEdit(true)}
              >
                <MdEdit />
                <span> Edit Section Name</span>
              </button>
            )}
            <button
              className="button with-icon "
              onClick={() => handleRemoveSection(currentSection.id)}
            >
              <MdDelete />
              <span> Remove Section</span>
            </button>
            <button
              className="button with-icon "
              onClick={() => this.handleComponentFormShow(true)}
            >
              <GoPlus />
              <span> Add Component</span>
            </button>
          </div>
          <div className="arrow-grp">
            {index < initialBlocks.length - 1 ? (
              <div
                className="arrow-grp__down"
                onClick={() => handleBlockMove(index, index + 1)}
              >
                <MdArrowDownward />
                <span className="icon-line"></span>
              </div>
            ) : null}
            {index > 0 ? (
              <div
                className="arrow-grp__up"
                onClick={() => handleBlockMove(index, index - 1)}
              >
                <span className="icon-line"></span>
                <MdArrowUpward />
              </div>
            ) : null}
          </div>
        </div>
        <div className="profile-block__body">
          {currentSection.components.map((x, i) => {
            return (
              <>
                <div className="form-row component-header">
                  {subtitleToEdits.includes(x.id) ? (
                    <div className="flex">
                      <FormGroup
                        name="subtitle"
                        value={x.subtitle}
                        onChange={(name, value) =>
                          this.props.handleComponentFieldChange(
                            profileBlockType,
                            x.id,
                            name,
                            value
                          )
                        }
                        label=""
                        error={errors && errors.dutiesAndResponsibilites}
                        onKeyUp={this.handleKeyUp}
                        validators={["required"]}
                        width="250px"
                        autoFocus
                        className="subtitle-action"
                      />
                      <div
                        className="edit-box-subtitle subtitle-action"
                        onClick={() =>
                          this.handleSaveSubtitle(x.subtitle, x.id)
                        }
                        active-id={x.id}
                      >
                        <GrCheckmark />
                      </div>
                      <div
                        className=" ml-xsm remove-box-subtitle subtitle-action"
                        onClick={() =>
                          this.handleRemoveChangedSubtitleTitle(x.id)
                        }
                        active-id={x.id}
                      >
                        <GrClose />
                      </div>
                    </div>
                  ) : (
                    <>
                      <span className="subtitle">{x.subtitle}</span>
                      <span
                        className="edit-link flex"
                        onClick={() => this.handleSubtitleEditClick(x.id)}
                      >
                        <MdEdit />
                        Edit
                      </span>
                    </>
                  )}

                  <span
                    className="del-link flex"
                    onClick={() =>
                      this.props.handleRemoveComponent(profileBlockType, x.id)
                    }
                  >
                    <MdDelete />
                    Remove
                  </span>
                </div>
                <div className="form-row pt-xsm">
                  <span className="subtitle-type text-md"> {x.type}</span>
                </div>
                {x.type !== JOB_PROFILE_PARAGRAPH_TYPE && (
                  <>
                    <div className="form-row">
                      <button
                        className="button insert-btn"
                        onClick={() => this.showcloseAddEduQualForm(x.type)}
                      >
                        <span> Insert {x.type}</span>
                      </button>
                    </div>
                    {!isEmpty(x.type) && x[x.type.toLowerCase()].length > 0 && (
                      <div className="form-row flex-column items-start">
                        {x[x.type.toLowerCase()].map((p, i) => {
                          return (
                            <div className="flex ksa-li">
                              <span className="ksa-count flex justify-center text-md">
                                {i + 1}
                              </span>
                              <span className="pl-sm text-md ksa-text">
                                {p.name}({p.level})
                              </span>
                              {/* <div className="pl-sm text-md ksa-text">
                                <span>Level: </span>
                                <span>{p.level}</span>{" "}
                              </div> */}
                              <GrFormClose
                                className="close-icon cursor-pointer ml-sm text-md"
                                onClick={() =>
                                  this.handleRemoveSubSection(x.type, p.id)
                                }
                              />
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </>
                )}
                <div className="form-row">
                  <FormGroup
                    formName="texteditor"
                    value={x.paragraph}
                    toolbarId={"knowledgeSkillAbilities" + i + 1}
                    placeholder={"Write something"}
                    name="paragraph"
                    onChange={(name, value) => {
                      this.props.handleComponentFieldChange(
                        profileBlockType,
                        x.id,
                        name,
                        value
                      );
                    }}
                    width="100%"
                  />
                </div>
              </>
            );
          })}
        </div>
        {isComponentFormShow && (
          <Modal
            title="Add Component"
            open={isComponentFormShow}
            onModalClose={() => this.handleComponentFormShow(false)}
            type=""
            className="modal-add-section"
          >
            <AddComponentForm
              handleComponentFormShow={this.handleComponentFormShow}
              components={currentSection.components}
              handleAddComponent={this.handleAddComponent}
              sectionId={this.props.id}
              title="Knowledge, Skills and Abilities (KSA"
              defaultComponents={defaultComponents}
            />
          </Modal>
        )}
        {isInsertKSAFormShow && (
          <Modal
            title="Add KSA"
            open={isInsertKSAFormShow}
            onModalClose={() => this.closeKSAForm()}
            type=""
            className="modal-insert-ksa"
          >
            <InsertKSAForm
              insertType={selectedType}
              closeKSAForm={this.closeKSAForm}
              handleAddSubSections={this.props.handleAddSubSections}
              components={currentSection.components}
              sectionId={this.props.id}
            />
          </Modal>
        )}
      </div>
    );
  }
}

export default KnowledgeSkillAbilities;
