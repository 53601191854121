import classnames from "classnames";
import FormGroup from "components/form";
import Loader from "components/loaders";
import Modal from "components/modal";
import isEmpty from "isEmpty";
import PropTypes from "prop-types";
import React, { Component, useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { MdAdd, MdEdit } from "react-icons/md";
import { connect } from "react-redux";
import shortid from "shortid";
import { getJobHiringStages } from "../helpers/actions";
import { Consumer } from "../helpers/context";

const initialState = [
  {
    id: shortid.generate(),
    name: "Application Review",
    type: "Shortlist",
    order: 1,
    subTypes: [],
  },
  {
    id: shortid.generate(),
    name: "Written Exxam",
    type: "Written Exam",
    order: 3,
    subTypes: [],
  },
  {
    id: shortid.generate(),
    name: "Interview",
    type: "Interview",
    order: 4,
    subTypes: [],
  },
  {
    id: shortid.generate(),
    name: "Job Offer",
    type: "Offer",
    order: 5,
    subTypes: [],
  },
  {
    id: shortid.generate(),
    name: "Confirm",
    type: "Hired",
    order: 6,
    subTypes: [],
  },
];
const isChangable = ["Written Exam", "Interview", "Offer", "Screening Call"];
const isAddable = ["Written Exam", "Interview"];

export class Hiring_Stage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hiringStages: [],
      modalOpen: false,
      modalType: "",
      subTypes: [],
      toEdit: null,
      select: [],
    };
    this.onModalClose = this.onModalClose.bind(this);
    this._renderHiringStages = this._renderHiringStages.bind(this);
    this._renderSubTypes = this._renderSubTypes.bind(this);
    this.onStageRemove = this.onStageRemove.bind(this);
    this.onStageAdd = this.onStageAdd.bind(this);
    this.onSubtypeAdd = this.onSubtypeAdd.bind(this);
    this.onSubtypeDelete = this.onSubtypeDelete.bind(this);
    this.onStageEdit = this.onStageEdit.bind(this);
    this.onSubtypeEdit = this.onSubtypeEdit.bind(this);
  }
  onModalClose = () => {
    this.setState({ modalOpen: false, modalType: "", toEdit: null });
  };

  componentDidUpdate(prevProps, prevState) {
    const { HiringStages, jobHiringStages } = this.props;

    if (!isEmpty(HiringStages) && prevProps.HiringStages !== HiringStages) {
      let arrayItem = [];
      jobHiringStages &&
        jobHiringStages.value &&
        jobHiringStages.value.forEach((x) => {
          if (x.DefaultDisplay) {
            var obj =
              HiringStages && HiringStages.find((y) => y.StageId == x.StageId);
            if (!obj) {
              arrayItem.push({ label: x.StageName, value: x.StageId });
            }
          }
        });
      this.setState({ select: arrayItem });

      /*
      let arrayItem = [];
      HiringStages.filter((item) => item.StageId > 1 && item.StageId < 6).map(
        (item, key) => {
          arrayItem.push({ label: item.StageName, value: item.StageId });
        }
      );

      console.log("arrayItem", arrayItem);
      this.setState({ select: arrayItem });
      */
    }
    //   this.setState({ select: [] });
  }

  onStageRemove(id) {
    const { dispatch } = this.props;
    dispatch({ type: "removeHiringStage", payload: { id } });
    // this.setState(prevState => ({
    //     ...prevState,
    //     hiringStages: prevState.hiringStages.filter(
    //         stage => stage.StageId !== id
    //     )
    // }));
  }
  onStageAdd(item) {
    const { dispatch } = this.props;
    dispatch({ type: "addHiringStage", payload: item });
    // this.setState(prevState => ({
    //     ...prevState,
    //     hiringStages: [item, ...prevState.hiringStages]
    // }));
  }
  onStageEdit(item) {
    const { dispatch } = this.props;

    dispatch({ type: "editHiringStage", payload: item });
    // this.setState(prevState => ({
    //     ...prevState,
    //     hiringStages: prevState.hiringStages.map(stage =>{
    //         if(stage.StageId === item.StageId ){
    //             let a = stage;
    //             a.StageType= item.StageType;
    //             console.log(a)
    //             return a
    //         }
    //         return stage
    //     }
    //     )
    // }));
  }
  onSubtypeEdit(sub) {
    const { dispatch } = this.props;
    dispatch({ type: "editHiringSubStage", payload: sub });
    // this.setState(prevState => ({
    //     ...prevState,
    //     subTypes: prevState.subTypes.map(subItem =>{
    //         if(subItem.Id === sub.id){
    //             let a = subItem;
    //             a.SubStageName = sub.name;
    //             return a
    //         }
    //         return subItem
    //     }
    //     )
    // }));
  }
  onSubtypeAdd(stage) {
    const { dispatch } = this.props;
    dispatch({
      type: "addHiringSubStage",
      payload: {
        SubStageName: stage.name,
        JobStageRef_Id: stage.StageId,
        Id: stage.Id,
      },
    });
    // this.setState(prevState => ({
    //     ...prevState,
    //     subTypes: [
    //         { SubStageName: stage.name, JobStageRef_Id: stage.id,Id:stage.Id },
    //         ...prevState.subTypes
    //     ]
    // }),()=>console.log(this.state));
  }
  onSubtypeDelete(subId) {
    const { dispatch } = this.props;
    dispatch({ type: "removeHiringSubStage", payload: { id: subId } });
    // this.setState(prevState => ({
    //     ...prevState,
    //     subTypes: prevState.subTypes.filter(sub => sub.Id !== subId)
    //     // subTypes: prevState.subTypes.filter(sub => sub.JobStageRef_Id !== subId)
    // }));
  }
  _renderSubTypes(subType, props) {
    return (
      <li
        key={subType.JobStageRef_Id}
        className="hiring-stage__subItem subItem"
      >
        <div className="subItem__body space-between">
          <div className="subItem__title">
            <h4>{subType.SubStageName}</h4>
          </div>
          <div className="subItem__change flex">
            <span
              onClick={() =>
                this.setState({
                  modalType: props,
                  modalOpen: true,
                  toEdit: subType,
                })
              }
              className="change-btn btn-edit"
            >
              <MdEdit />
            </span>
            <span
              onClick={() => this.onSubtypeDelete(subType.Id)}
              className="change-btn btn-delete"
            >
              <FaTrash />
            </span>
          </div>
        </div>
      </li>
    );
  }
  _renderHiringStages(props) {
    const { jobHiringSubStages } = this.props;
    return (
      <li key={props.StageId + Math.random()} className="hiring-stages__item">
        <div className="hiring-stage">
          <div className="hiring-stage__header space-between">
            <div className="hiring-stage__name flex">
              <h3>{props.StageType}</h3>
              <p>{props.StageName}</p>
            </div>
            {isChangable.includes(props.StageName) ? (
              <div className="hiring-stage__change flex">
                <span
                  onClick={() =>
                    this.setState({
                      modalOpen: true,
                      toEdit: props,
                    })
                  }
                  className="change-btn btn-edit"
                >
                  <MdEdit />
                </span>
                <span
                  onClick={() => this.onStageRemove(props.StageId)}
                  className="change-btn btn-delete"
                >
                  <FaTrash />
                </span>
              </div>
            ) : null}
          </div>
          {!isEmpty(jobHiringSubStages) ? (
            <div className="hiring-stage__body">
              <ul className="hiring-stage__subList">
                {jobHiringSubStages.map((item) => {
                  if (item.JobStageRef_Id === props.StageId) {
                    return this._renderSubTypes(item, props);
                  }
                })}
              </ul>
            </div>
          ) : null}
        </div>
        {isAddable.includes(props.StageName) ? (
          <button
            className="btn add-btn with-icon"
            onClick={() => this.setState({ modalType: props, modalOpen: true })}
          >
            <MdAdd />
            Add {props.StageType}
          </button>
        ) : null}
      </li>
    );
  }
  render() {
    const { jobHiringStages } = this.props;
    const { HiringStages } = this.props;
    return (
      <div className="hiring-container">
        <h3 className="tertiary-title">Hiring Stages</h3>
        {jobHiringStages.loading ? <Loader open={true} /> : null}
        <div className="hiring-stages">
          <ul className="hiring-stages__list">
            {HiringStages.sort((a, b) => a.StageId - b.StageId)
              .filter((item) => item.DefaultDisplay)
              .map(this._renderHiringStages)}
          </ul>

          {this.state.select && this.state.select.length > 0 ? (
            <div className="hiring-stages__btn">
              <button onClick={() => this.setState({ modalOpen: true })}>
                Add Stage
              </button>
            </div>
          ) : null}
        </div>
        <Modal
          open={this.state.modalOpen}
          onModalClose={this.onModalClose}
          className="hiring-stages-modal"
          title={
            isEmpty(this.state.modalType)
              ? "Add a Stage"
              : `Add ${this.state.modalType.StageType} `
          }
        >
          {isEmpty(this.state.modalType) ? (
            <StageForm
              toEdit={this.state.toEdit}
              select={this.state.select}
              onStageEdit={this.onStageEdit}
              onStageAdd={this.onStageAdd}
              onModalClose={this.onModalClose}
            />
          ) : (
            <SubTypeForm
              toEdit={this.state.toEdit}
              onSubtypeEdit={this.onSubtypeEdit}
              onSubtypeAdd={this.onSubtypeAdd}
              onModalClose={this.onModalClose}
              stage={this.state.modalType}
            />
          )}
        </Modal>
      </div>
    );
  }
}

const StageForm = ({
  onModalClose,
  onStageAdd,
  toEdit,
  onStageEdit,
  select,
}) => {
  const [stageType, setStageType] = useState([]);
  const [stageName, setStageName] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!isEmpty(toEdit)) {
      setStageName(toEdit.StageType);
      setStageType({ label: toEdit.type, value: toEdit.order });
    }
  }, []);
  const tyeOption = [
    // { label: "Shortlist", value: 1 },
    { label: "Screening Call", value: 2 },
    { label: "Written Exam", value: 3 },
    { label: "Interview", value: 4 },
    { label: "Offer", value: 5 },
    // { label: "Hired", value: 6 }
  ];
  const onDataChange = (name, value) => {
    //const { value } = e.target;
    setStageName(value);
  };
  const onSelectChange = (name, value) => {
    setStageType(value);
  };
  const onAdd = () => {
    const data = {
      StageId: stageType.value,
      StageType: stageName,
      StageName: stageType.label,
      DefaultDisplay: true,
    };
    const { isAuth, errors } = onValidate(data);
    if (!isAuth) {
      setErrors(errors);
      return false;
    }
    onStageAdd(data);
    onModalClose();
  };
  const onEdit = () => {
    const data = {
      StageId: toEdit.StageId,
      StageType: stageName,
      DefaultDisplay: true,
      isEdit: true,
      StageName: stageName,
    };
    const { isAuth, errors } = onValidate(data);
    if (!isAuth) {
      setErrors(errors);
      return false;
    }
    onStageEdit(data);
    onModalClose();
  };
  const onValidate = (data) => {
    const errors = {};
    if (isEmpty(data.StageType)) {
      errors.stageName = "Stage Name is Required";
    }
    if (isEmpty(data.StageType) && isEmpty(data.isEdit)) {
      errors.stageType = "Stage Type is Required";
    }
    return { isAuth: isEmpty(errors), errors };
  };
  return (
    <div className="hiring-stages__form">
      <div className="hiring-stages__form-body">
        <div className="hiring-stages__form-input">
          <FormGroup
            name="stageName"
            value={stageName}
            label="Stage Name"
            onChange={onDataChange}
            error={errors.stageName}
          />
        </div>
        {isEmpty(toEdit) ? (
          <div
            className={classnames({
              "hiring-stages__form-input": true,
              "auto-error": !isEmpty(errors.stageType),
            })}
          >
            <label htmlFor="stageType">Stage Type</label>
            <FormGroup
              formName="reactselect"
              onChange={onSelectChange}
              name="stageType"
              value={stageType}
              options={select}
              width={"100%"}
            />

            {errors.stageType && (
              <span className="auto-error__message">{errors.stageType}</span>
            )}
          </div>
        ) : null}
      </div>
      <div className="hiring-stages__form-footer">
        {!isEmpty(toEdit) ? (
          <button className="btn btn-blue" onClick={() => onEdit()}>
            Edit
          </button>
        ) : (
          <button className="btn btn-blue" onClick={() => onAdd()}>
            Add
          </button>
        )}
        <button className="btn btn-grey-2" onClick={() => onModalClose()}>
          Cancel
        </button>
      </div>
    </div>
  );
};

const SubTypeForm = ({
  onModalClose,
  onSubtypeAdd,
  stage,
  onSubtypeEdit,
  toEdit,
}) => {
  const [subTypeName, setSubTypeName] = useState("");
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (!isEmpty(toEdit)) {
      setSubTypeName(toEdit.SubStageName);
    }
  }, []);
  const onDataChange = (name, value) => {
    //const { value } = e.target;
    setSubTypeName(value);
  };
  const onValidate = (data) => {
    const errors = {};
    if (isEmpty(data.name)) {
      errors.stageName = "Subtype Name is Required";
    }
    return { isAuth: isEmpty(errors), errors };
  };
  const onAdd = () => {
    const data = {
      name: subTypeName,
      StageId: stage.StageId,
      Id: shortid.generate(),
    };
    const { isAuth, errors } = onValidate(data);
    if (!isAuth) {
      setErrors(errors);
      return false;
    }
    onSubtypeAdd(data);
    onModalClose();
  };
  const onEdit = () => {
    const data = {
      name: subTypeName,
      id: toEdit.Id,
    };
    const { isAuth, errors } = onValidate(data);
    if (!isAuth) {
      setErrors(errors);
      return false;
    }
    onSubtypeEdit(data);
    onModalClose();
  };
  return (
    <div className="hiring-stages__form">
      <div className="hiring-stages__form-body">
        <div className="hiring-stages__form-input">
          <FormGroup
            name="subTypeName"
            value={subTypeName}
            label="Subtype Name"
            onChange={onDataChange}
            error={errors.subTypeName}
          />
        </div>
      </div>
      <div className="hiring-stages__form-footer">
        {!isEmpty(toEdit) ? (
          <button className="btn btn-blue" onClick={() => onEdit()}>
            Edit
          </button>
        ) : (
          <button className="btn btn-blue" onClick={() => onAdd()}>
            Add
          </button>
        )}
        <button className="btn btn-grey-2" onClick={() => onModalClose()}>
          Cancel
        </button>
      </div>
    </div>
  );
};

Hiring_Stage.propTypes = {
  jobHiringStages: PropTypes.object.isRequired,
  getJobHiringStages: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => ({
  jobHiringStages: state.createJobReducer.jobHiringStages,
});

const Hire = (props) => (
  <Consumer>
    {(value) => {
      const { jobCreate, dispatch, jobHiringStages, jobHiringSubStages } =
        value;

      // const {jobCreate:{RecruitmentPositionDetailDto},dispatch}= value;
      return (
        <Hiring_Stage
          {...props}
          dispatch={dispatch}
          jobHiringSubStages={jobHiringSubStages}
          HiringStages={jobHiringStages}
          jobCreate={jobCreate}
        />
      );
    }}
  </Consumer>
);

export default connect(mapStateToProps, { getJobHiringStages })(Hire);
