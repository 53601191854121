import React, { Component } from "react";
import classnames from "classnames";
import { MdArrowForward } from "react-icons/md";
import { expandIcon } from "../../common/expandIcon";
import SvgIcons from "components/svgIcons";
import { formatedShortMonthDayYear } from "helpers/dateFormat";
import { Enum_ProjectStatus } from "scenes/taskTracker/enums";

export class TableRows extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drop: false,
    };
    this.toggleDetail = this.toggleDetail.bind(this);
    this.dropdown = "";
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.dropdown && !this.dropdown.contains(event.target)) {
      this.setState({ drop: false });
    }
  }

  onDrop() {
    this.setState({ drop: !this.state.drop });
  }

  // toggleDetail = (id) => {
  //   var toggleIcon = document.getElementById(`toggleIcon${id}`);
  //   toggleIcon.classList.toggle("expand");
  //   var detailsRow = document.getElementById(`details${id}`);

  //   if (detailsRow.offsetHeight === 0) {
  //     detailsRow.style.height = detailsRow.scrollHeight + "px";
  //   } else {
  //     detailsRow.style.height = "0";
  //   }
  // };
  toggleDetail = (id, drop) => {
    this.setState({ drop: !drop });
    var toggleIcon = document.getElementById(`toggleIcon${id}`);
    toggleIcon.classList.toggle("expand");
    var detailsRow = document.querySelectorAll(`.details-${id}`);

    if (detailsRow) {
      for (var i = 0; i < detailsRow.length; i++) {
        detailsRow[i].classList.toggle("row-expand");
      }
    }
  };

  onModalClose = (name) => {
    this.setState({ [name]: false });
  };

  onApprove = (e, data) => {
    e.stopPropagation();
    this.props.handleApprove(data);
  };

  onView = (e, data) => {
    e.stopPropagation();
    this.props.handleView(data);
  };

  onEditProject = (e, data) => {
    e.stopPropagation();
    this.props.handleEditProject(data);
  };

  render() {
    const { props } = this;
    const { drop } = this.state;
    let trClass = "table-row";
    if (drop) {
      trClass = trClass + " expanded__tr";
    }

    let statusIcon = "";
    let statusColor = "";
    if (props.Status == Enum_ProjectStatus.Saved) {
      statusIcon = "CalendarPlus";
      statusColor = "#f38b00";
    } else if (props.Status == Enum_ProjectStatus.Forwarded) {
      statusIcon = "Forwarded";
      statusColor = "#f38b00";
    } else if (props.Status == Enum_ProjectStatus.Reviewed) {
      statusIcon = "Like";
      statusColor = "#007eda";
    } else if (props.Status == Enum_ProjectStatus.Approved) {
      statusIcon = "Doublecheck";
      statusColor = "#619a17";
    } else if (props.Status == Enum_ProjectStatus.Denied) {
      statusIcon = "Cross";
      statusColor = "#e23a3a";
    }

    return (
      <>
        <tr
          className={trClass}
          role="row"
          key={props.index}
          id={`toggleIcon${props.index}`}
          onClick={() => this.toggleDetail(props.index, drop)}
        >
          <td className="e-rowcell" role="gridcell">
            {props.ActivityDetail &&
              props.ActivityDetail.length > 0 &&
              expandIcon(drop)}
          </td>
          <td className="e-rowcell " role="gridcell">
            <span>{props.SubmittedByDisplayText}</span>
          </td>
          <td className="e-rowcell " role="gridcell">
            <span>
              {formatedShortMonthDayYear(props.ActivityDateDisplayText)}
            </span>
          </td>
          <td className="e-rowcell " role="gridcell">
            <span>{props.ClientName}</span>
          </td>
          <td className="e-rowcell " role="gridcell">
            <span>{props.WorkedHoursDisplayText}</span>
          </td>
          <td className="e-rowcell " role="gridcell">
            <span>{props.BillableHoursDisplayText}</span>
          </td>
          <td className="e-rowcell " role="gridcell">
            <span>{props.NonBillableHoursDisplayText}</span>
          </td>
          <td className="e-rowcell " role="gridcell">
            <span>{props.Expenses}</span>
          </td>
          <td className="e-rowcell " role="gridcell">
            <span className="status">
              <SvgIcons name={statusIcon} size={12} color={statusColor} />
              <span style={{ color: statusColor }}>
                {props.StatusDisplayText}
              </span>
            </span>
          </td>

          <td className="e-rowcell " role="gridcell">
            <div className="flex">
              {props.activeTab.key == "ActivityToReview" && (
                <button
                  className="btn btn-green"
                  onClick={(e) => this.onApprove(e, props)}
                >
                  <span className="color-white">Approve</span>
                </button>
              )}
              {/* <button
                className="button button-white"
                onClick={(e) => this.onView(e, props)}
              >
                <span>View</span>
              </button> */}
            </div>
          </td>
        </tr>

        {props.ActivityDetail &&
          props.ActivityDetail.map((activity, i) => {
            let splitedNatureOfWork = activity.NatureOfWorks
              ? activity.NatureOfWorks.split(",")
              : [];
            const rowClasses = classnames({
              "table-details": true,
              "custom-row": true,
              "expanded__tr-details": true,
              [`details-${props.index}`]: true,
            });
            return (
              <tr className={rowClasses} key={i}>
                <td>
                  <span className="detail"></span>
                </td>
                <td>
                  <span className="detail"></span>
                </td>
                <td>
                  <span className="detail"></span>
                </td>
                <td>
                  <span className="detail detail-col">
                    <div className="project">
                      <span className="project-icon">P</span>
                      <span>{activity.ProjectName}</span>
                    </div>
                    {splitedNatureOfWork.map((natureOfWork, i) => {
                      return (
                        <div className="activities" key={i}>
                          <span className="activities-icon">
                            <MdArrowForward />
                          </span>
                          <span>{natureOfWork}</span>
                        </div>
                      );
                    })}
                  </span>
                </td>

                <td>
                  <span className="detail">
                    {activity.WorkedHoursDisplayText}
                  </span>
                </td>

                <td>
                  <span className="detail">
                    {activity.BillableHoursDisplayText}
                  </span>
                </td>
                <td>
                  <span className="detail"></span>
                </td>
                <td>
                  <span className="detail"></span>
                </td>
                <td>
                  <span className="detail"></span>
                </td>
                <td>
                  <span className="detail">
                    {(props.activeTab.key == "MyActivities" || props.activeTab.key == "ActivityToReview") &&
                      (activity.Status == 0 || activity.Status == 1) && (
                        <button
                          className="button button-primary"
                          onClick={(e) => this.onEditProject(e, activity)}
                        >
                          <span>Edit</span>
                        </button>
                      )}
                  </span>
                </td>
              </tr>
            );
          })}
      </>
    );
  }
}

export default TableRows;
