import React, { useEffect } from "react";
import home from "assets/images/icons/home-lg-alt.svg";
import users from "assets/images/icons/users-class.svg";
import shortid from "shortid";
import classnames from "classnames";
import "./boardslist.styles.scss";
import Icon from "components/icons";
import Board from "./board";
import { getCardView } from "../helpers/action";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "isEmpty";

function BoardList() {
  const dispatch = useDispatch();
  const cardView = useSelector((state: any) => state.taskReducer.cardView);
  useEffect(() => {
    dispatch(getCardView());
  }, []);

  if (!isEmpty(cardView)) {
    return (
      <div className="boardslist">
        <div className="boardslist-board">
          <div className="boardslist-title">
            <div className="tasks-component__primary-icon">
              <img src={home} alt="Icon" />
            </div>
            <div className="boardslist-title-title">My Boards</div>
            <div className="tasks-component__primary-icon">
              <Icon justSVG name="Add" />
            </div>
          </div>
          <ul className="boardslist-list">
            <BoardsToDo
              MyToDoTasks={cardView.MyToDoTasks}
              TaskStatus={cardView.TaskStatus}
            />
          </ul>
          <ul className="boardslist-list">
            {cardView.PersonalBoards &&
              cardView.PersonalBoards.map((data) => {
                return <Board key={data.id} data={data} />;
              })}
          </ul>
        </div>
        <div className="boardslist-board">
          <div className="boardslist-title">
            <div className="tasks-component__primary-icon">
              <img src={users} alt="Icon" />
            </div>
            <div className="boardslist-title-title">Team Boards</div>
            <div className="tasks-component__primary-icon">
              <Icon justSVG name="Add" />
            </div>
          </div>
          <div className="boardslist-list">
            {cardView.TeamBoards &&
              cardView.TeamBoards.map((data) => {
                return <Board key={data.id} data={data} />;
              })}
          </div>
        </div>
      </div>
    );
  }
  return null;
}

const BoardsToDo = (props) => {
  const { MyToDoTasks, TaskStatus } = props;
  return (
    <li className="boardslist-item to-do">
      <div className="boardslist-card">
        <h4 className="boardslist-card__title">My To Do</h4>
        <div className="boardslist-card__things">
          {MyToDoTasks &&
            MyToDoTasks.map((item: any, key) => {
              return (
                <h5 key={key}>
                  {item.StageName} <span>{item.Total}</span>
                </h5>
              );
            })}
        </div>
        <div className="boardslist-card__done">
          <h5>
            <p>On Track</p>
            <span className="text-blue">
              {TaskStatus && TaskStatus.OnTrack}
            </span>
          </h5>
          <h5>
            <p>Behind</p>
            <span className="text-orange">
              {TaskStatus && TaskStatus.Behind}
            </span>
          </h5>
          <h5>
            <p>Overdue</p>
            <span className="text-red">{TaskStatus && TaskStatus.OverDue}</span>
          </h5>
          <h5>
            <p>Others</p>
            <span className="text-red">{TaskStatus && TaskStatus.Others}</span>
          </h5>
        </div>
      </div>
    </li>
  );
};

export default BoardList;
