import {
  SET_SPINNER_LOADING,
  GET_DISCIPLINARY_CASE_DETAILS,
  DISCIPLINARY_CASE_DETAILS_LOADING,
  GET_DISCIPLINARY_ACTION_LIST,
  GET_DISCIPLINARY_STAGES,
  MOVE_DISCIPLINARY_ACTION_TO_NS,
  SAVE_ONBOARDING_EMPLOYEE,
  GET_ONBOARDING_EMPLOYEE_DETAIL,
  GET_ONBOARDING_EMPLOYEE_LIST,
  SAVE_EMPLOYEE_SALARY_TEMPLATE,
  DELETE_ONBOARDING_EMPLOYEE,
  GET_ONBOAREDING_EMPLOYEE_EMAIL_PREVIEW,
  GET_EMPLOYEE_SALARY_TEMPLATE_DETAILS
} from "actions/types";
import axios from "axios";
import isEmpty from "isEmpty";
import { toastMessage } from "actions/validateAction";
import RouteEnum from 'constants/routeEnum'
import { getErrorResponse } from "helpers/getApiResponse";


export const SaveOnboardingEmployee = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_ONBOARDING_EMPLOYEE,
    payload: false,
  });
  await axios
    .post(`/v1/onBoarding/employee`, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.data.Status) {
        dispatch({
          type: SAVE_ONBOARDING_EMPLOYEE,
          payload: response.data.Data,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to Save Onboarding Employee Details",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const UpdateOnboardingEmployee = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_ONBOARDING_EMPLOYEE,
    payload: false,
  });
  await axios
    .put(`/v1/onBoarding/employee`, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: SAVE_ONBOARDING_EMPLOYEE,
          payload: response.data.Data,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to Save Onboarding Employee Details",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const InitiateOnboardingEmployee = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_ONBOARDING_EMPLOYEE,
    payload: false,
  });
  await axios
    .put(`/v1/onBoarding/package`, data)
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: SAVE_ONBOARDING_EMPLOYEE,
          payload: response.data,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to Save Onboarding Employee Details",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const GetOnboardingEmployeeDetail = (employeeId) => async (dispatch) => {
  dispatch({
    type: GET_ONBOARDING_EMPLOYEE_DETAIL,
    payload: null,
  });
  await axios
    .get(`/v1/onBoarding/employees/${employeeId}`)
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: GET_ONBOARDING_EMPLOYEE_DETAIL,
          payload: response.data.Data,
        });
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to get Onboarding Employee Details",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const GetOnBoardingEmployeeList = (data) => async (dispatch) => {
  await axios
    .get(data.requestUrl)
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: GET_ONBOARDING_EMPLOYEE_LIST,
          payload: response.data.Data,
        });
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to get Onboarding employee list",
        },
      };
      toastMessage(dispatch, response);
    });
};


export const SaveEmployeeSalaryTemplate = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_EMPLOYEE_SALARY_TEMPLATE,
    payload: false,
  });
  await axios
    .post(`/v1/onBoarding/employeeSalaryTemplate`, data)
    .then((res) => {
      if (res.data.Status) {
        let data = res.data.Data;
        dispatch({
          type: SAVE_EMPLOYEE_SALARY_TEMPLATE,
          payload: true,
        });
        toastMessage(dispatch, res);
      }
    })
    .catch((err) => {
      const res = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to save salary Template.",
        },
      };
      toastMessage(dispatch, res);
    });
};

export const DeleteOnBoardingEmployee = (employeeId) => async (dispatch) => {
  dispatch({
    type: DELETE_ONBOARDING_EMPLOYEE,
    payload: false,
  });
  await axios
    .delete(`/v1/onBoarding/employees/${employeeId}`)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: DELETE_ONBOARDING_EMPLOYEE,
          payload: true,
        });
        toastMessage(dispatch, res);
      }
    })
    .catch((err) => {
      const res = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to save salary Template.",
        },
      };
      toastMessage(dispatch, res);
    });
};


export const GetEmployeeEmailPreview = (data) => async (dispatch) => {
  // dispatch({
  //   type: DELETE_ONBOARDING_EMPLOYEE,
  //   payload: false,
  // });
  await axios
    .post(`/v1/onBoarding/welcomeMessage?isPackage=true`, data)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: GET_ONBOAREDING_EMPLOYEE_EMAIL_PREVIEW,
          payload: res.data,
        });
        // toastMessage(dispatch, res);
      }
    })
    .catch((err) => {
      const res = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to get employee email preview",
        },
      };
      toastMessage(dispatch, res);
    });
};

export const GetEmployeeSalaryTemplateDetails = (employeeId) => async (dispatch) => {
  // dispatch({
  //   type: DELETE_ONBOARDING_EMPLOYEE,
  //   payload: false,
  // });
  await axios
    .get(`v1/onBoarding/employeeSalaryTemplate/${employeeId}`)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: GET_EMPLOYEE_SALARY_TEMPLATE_DETAILS,
          payload: res.data.Data,
        });
        // toastMessage(dispatch, res);
      }
    })
    .catch((err) => {
      const res = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to get employee salary template details",
        },
      };
      toastMessage(dispatch, res);
    });
};