import React, { Component } from "react";
import {Tabs,Tab} from "components/tabs/index.d";
import OnBoardingTable from "./onBoardingTable";
import { connect } from "react-redux";

export class OnboardingTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        { tabId: 1, stageId: 0, label: "All onboarding" },
        { tabId: 2, stageId: 1, label: "Draft Stage" },
        { tabId: 3, stageId: 2, label: "Onboarding Process" },
        { tabId: 4, stageId: 3, label: "Onboarding Completed" },
      ],
      activeTabId: 2,
    };
  }

  async componentDidMount() {
  }

  handleTabChange = (tabId) => {
    const { tabs } = this.state;
    const activeStageId = tabs && tabs.find((f) => f.tabId == tabId).stageId;
    this.setState({ activeTabId: tabId });

    this.props.handleStageChange && this.props.handleStageChange(activeStageId);
  };

  render() {
    const {
      onboardingEmployeeList,
      GetEmployeeOnboardingList,
      GetOnboardingEmployeeDetail,
      deleteOnBoardingEmployee
    } = this.props;
    const { tabs, activeTabId } = this.state;
    const { pageIndex, rowsPerPage } = this.props.parentState;
    const activeStageId =
      tabs && tabs.find((f) => f.tabId == activeTabId).stageId;
      
    return (
      <div>
        {tabs && (
          <Tabs 
            // type="block" 
            noBackground>
            {tabs.map((tab, index) => {
              return (
                <Tab
                  label={tab.label}
                  name={tab.tabId}
                  id={tab.tabId}
                  key={index}
                  isActive = {activeTabId===tab.tabId}
                  onClick={() => this.handleTabChange(tab.tabId)}
                >
                  <OnBoardingTable
                    stageId={activeStageId}
                    data={onboardingEmployeeList}
                    pageIndex={pageIndex}
                    rowsPerPage={rowsPerPage}
                    handleRowChange={this.props.handleRowChange}
                    handleIndexChange={this.props.handleIndexChange}
                    handleEdit={this.props.handleEdit}
                    GetEmployeeOnboardingList={GetEmployeeOnboardingList}
                    GetOnboardingEmployeeDetail={GetOnboardingEmployeeDetail}
                    deleteOnBoardingEmployee={deleteOnBoardingEmployee}
                  />
                </Tab>
              );
            })}
          </Tabs>
        )}
      </div>
    );
  }
}

export default connect(null, null)(OnboardingTabs);
