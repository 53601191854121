import React,{ useEffect } from 'react'
import ProfilePicture from './profilePicture';
import Identification from './identification';
import Additional from './additional';
import {
    getEmployeeAdditionalDocuments,
    getEmployeeAddressDetails,
    getEmployeeCitizenshipDetails,
    getEmployeeDrivingDetails,
    getEmployeePassportDetails
} from '../action';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import Address from '../address'

function Documents(props) {
    const dispatch = useDispatch();
    const {
        employeeAdditionalDocs,
        employeeAddressDetails,
        employeeCitizenshipDetails,
        employeeDrivingDetails,
        employeePassportDetails
    } = useSelector((state:any)=>state.employeeProfile);
    // const {employeeAddressDetails} = useSelector((state:any)=>state.employeeProfile);
    useEffect(()=>{
        (async () =>{
            const promiseArray = [
                dispatch(getEmployeeAdditionalDocuments(props.employeeId)),
                dispatch(getEmployeeAddressDetails(props.employeeId)),
                dispatch(getEmployeeCitizenshipDetails(props.employeeId)),
                dispatch(getEmployeeDrivingDetails(props.employeeId)),
                dispatch(getEmployeePassportDetails(props.employeeId)),
            ]
            await Promise.all(promiseArray)
        })();
    },[]);
    return (
        <div className="employee-docs">
            <ProfilePicture EmployeeId={props.employeeId} />
            <Address EmployeeId={props.employeeId} EmployeeAddress={employeeAddressDetails} />
            <Identification EmployeeId={props.employeeId}
            citizenShipDetails={employeeCitizenshipDetails}
            />
            <Additional EmployeeId={props.employeeId} AdditionalDocs={employeeAdditionalDocs} />
        </div>
    )
}

export default Documents
