import React, { useState } from "react";
import "../style.scss";
import { connect, useSelector } from "react-redux";
import Modal from "components/modal";
import ViewDocument from "../../../helpers/documentViewer";
import { getUploadPath } from "constants/userDetails";
import isEmpty from "helpers/isEmpty";
import getFullStringUrl from "helpers/getFullStringUrl";
import {hostedUrl} from "constants/urls";
import {Apis} from "src/config/api";

function DocumentsAttached({ loanDetails }) {
  const {
    homeLoanDetail,
    vehicleLoanDetail,
    staffODLoanDetail,
    staffPersonalLoanDetail,
  } = useSelector((state) => state.loanReducer);
  // const { baseUrl } = useSelector((state) => state.auth); 
  const showDocumentModal = (file, flag) => {
    if (file) {
      const param = {
        serverFileName: file.ServerFileName.replace("Uploads/", ""),
        userFileName: file.UserFileName,
        fileType: file.FileType,
      };
      window.open(getFullStringUrl(`${hostedUrl}${Apis.common.downloadFiles}`, param));
    }

  // const base = baseUrl ? baseUrl.NetFrameworkAppBaseUrl : "";

  //   let fileURI = `${base}${file.ServerFileName}` 
    // let fileURI = getUploadPath(`/${file.ServerFileName}`); 
    // console.log("geturl path on home view => 22", fileURI)
    // window.open(fileURI, '_blank');
    // e.stopPropagation();
  };
  const getLoanAttachments = () => {
    let attachments = [];
    let RejectedMessage = "";
    if (loanDetails == "PrimeHomeLoan") {
      RejectedMessage = homeLoanDetail.RejectedMessage;
      attachments = homeLoanDetail?.attachments;
    }
    if (loanDetails == "PrimeVehicleLoan") {
      RejectedMessage = vehicleLoanDetail.RejectedMessage;
      attachments = vehicleLoanDetail?.attachments;
    }
    if (loanDetails == "PrimeStaffODLoan") {
      RejectedMessage = staffODLoanDetail.RejectedMessage;
      attachments = staffODLoanDetail?.attachments;
    }
    if (loanDetails == "PrimeStaffPersonalLoan") {
      RejectedMessage = staffPersonalLoanDetail.RejectedMessage;
      attachments = staffPersonalLoanDetail?.attachments;
    }
    attachments = attachments?.map((file) => {
      return {
        ...file,
        OriginalName: file.UserFileName,
        DocumentPath: file.FileLocation,
        DocumentName: file.UserFileName,
        name: file.UserFileName,
        path: getUploadPath(file.ServerFileName),
        docName: file.UserFileName,
        isFetched: true,
        file: true
      };
    });
    return { attachments, RejectedMessage };
  };
  let { attachments, RejectedMessage } = getLoanAttachments();
  return (
    <>
      {!isEmpty(attachments) && (
        <div className="homeloan-view-employeedetails healthCard__health mt-md">
          <div className="healthCard-heading">
            <h4>Documents</h4>
          </div>
          <div className="healthCard__health__details">
            {attachments?.map((doc) => {
              return (
                <div
                  className="documents-name mb-sm"
                  onClick={() => showDocumentModal(doc, true)}
                >
                  <a>
                    <span className="clickable">{doc.UserFileName}</span>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {!isEmpty(RejectedMessage) && (
        <div className="homeloan-view-employeedetails healthCard__health mt-md">
          <div className="healthCard-heading">
            <h4>Notes</h4>
          </div>
          <div className="healthCard__health__details text-red">{RejectedMessage}</div>
        </div>
      )}
    </>
  );
}
export default DocumentsAttached;
