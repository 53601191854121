import React, { Component } from 'react'
import './style.scss';
import FamilyForm from './familyForm';
import DetailCard from './familyDetailCard';
import { connect } from "react-redux";
import {
  GetEmployeeFamilies
} from '../action';
import './style.scss';
import isEmpty from "isEmpty";

interface IAppProps {
  employeeId: any,
  GetEmployeeFamilies: Function,
  employeeFamiliesDetail: any,
}

interface State {
  isEditView: boolean,
}

class index extends React.Component<IAppProps, State> {

  constructor(props) {
    super(props);
    this.state = {
      isEditView: false,
    }
  }

  async componentDidMount() {
    const {
      employeeId,
      GetEmployeeFamilies
    } = this.props;
    if (employeeId) {
      await GetEmployeeFamilies(employeeId);
    }
    const { employeeFamiliesDetail } = this.props;
    if (!isEmpty(employeeFamiliesDetail)) {
      this.setState({ isEditView: false });
    }
  }

  toggleEditView = (flag) => {
    this.setState({ isEditView: flag });
  }


  render () {
    const { employeeFamiliesDetail, employeeId } = this.props;
    const { isEditView } = this.state;
    return (
    <div className="healthCard__health">
        <div className="healthCard-heading">
            <h4>Family Information</h4>
        </div>
        <div className="healthCard__health__details">
          <>
          {!isEmpty(employeeFamiliesDetail) &&
          <DetailCard
            key="1"
            detail={employeeFamiliesDetail}
            isEditView={isEditView}
            toggleEditView={(flag) => this.toggleEditView(flag)}
            />}
          {(isEditView || isEmpty(employeeFamiliesDetail)) &&
          <FamilyForm
            editView={isEditView}
            editViewData={employeeFamiliesDetail}
            toggleEditView={(flag) => this.toggleEditView(flag)}
            EmployeeId={employeeId}
          />
          }
        </>
        </div>
    </div>
    );
}
}

const mapStateToProps = state => ({
  employeeFamiliesDetail: state.employeeProfile.employeeFamiliesDetail
});

const mapDispatchToProps = {
  GetEmployeeFamilies
};

export default connect(mapStateToProps, mapDispatchToProps)(index);