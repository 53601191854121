import React from 'react'
import {FaFemale,FaMale} from 'react-icons/fa';
import { useSelector } from 'react-redux';

function GenderRatio() {
    const hrDashboard = useSelector(state => state.hrReducer.hrDashboard );
    const {EmployeeDetailsCount}  = hrDashboard;
    return (
        <div className="gender-ratio">
            <div className="gender-ratio-genders">
                <div className="gender gender-male">
                    <div className="gender-icon">
                        <FaMale />                    
                    </div>
                    <div className="gender-value">
                        <div className="gender-value__num"><span>{EmployeeDetailsCount.MaleEmployees}</span></div>
                        <div className="gender-value__name"><span>Male</span></div>
                    </div>
                </div>
                <div className="gender gender-female">
                    <div className="gender-icon">
                        <FaFemale />
                    </div>
                    <div className="gender-value">
                        <div className="gender-value__num"><span>{EmployeeDetailsCount.FemaleEmployees}</span></div>
                        <div className="gender-value__name"><span>FeMale</span></div>
                    </div>
                </div>
            </div>
            <div className="gender-ratio-marital">
                <div className="marital-status text-blue2">
                    <div className="marital-status__num">
                        <span>{EmployeeDetailsCount.Single}</span>
                    </div>
                    <div className="marital-status__name">
                        <span>Single</span>
                    </div>
                </div>
                <div className="marital-status text-red">
                    <div className="marital-status__num">
                        <span>{EmployeeDetailsCount.Married}</span>
                    </div>
                    <div className="marital-status__name">
                        <span>Married</span>
                    </div>
                </div>
            </div>  
        </div>
    )
}

export default GenderRatio
