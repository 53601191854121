import React from 'react'
import RadioInput from './radioInput'
import classnames from 'classnames'

interface radioGroupTypes {
    name:string,
    value:any,
    error?:string,
    labelPosition?:string,
    id:any,
    disabled?:boolean,
    className?:string,
    checked?:string,
    radioPosition:'horizontal' | 'vertical',
    onChange:Function,
    theme?:string,
    options:labelsInter[]
}
interface labelsInter{
    label:string,
    value:any
}
function RadioGroup(props:radioGroupTypes) {
    const {
        options,
        radioPosition,
        checked,
        value,
        theme,
        ...rest
    } = props;
    const radioGroupClasses = classnames({
        "form__radio-container":true,
        ["form__radio-container-" + theme]:theme,
        "form__radio-horizontal":radioPosition==='horizontal',
        "form__radio-vertical":radioPosition === 'vertical'
    })
    return (
        <div className={radioGroupClasses}>
            {
                options.map((item:labelsInter)=>{
                    let check = item.label==checked || item.value +''  == value +'';
                    return <RadioInput key={item.value} value={item.value} checked={check}  radioLabel={item.label} {...rest} />
                })
            }
        </div>
    )
}

RadioGroup.defaultProps = {
    radioPosition: "horizontal",
};

export default RadioGroup
