import React from "react";
import "../style.scss";
import { connect } from "react-redux";
import { currencyCommaSeperator } from "../../../helpers/currencyFormatter";

function VehicleLoanDetails({ vehicleLoanDetail }) {
  return (
    <div className="homeloan-view-employeedetails homeloan-view-vehicledetails healthCard__health mt-md">
      <div className="healthCard-heading">
        <h4>Vehicle Detail</h4>
      </div>
      <div className="healthCard__health__details">
        <div className="placement-details">
          <div className="placement-details__block">
            <div className="placement-details__table">
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Loan Purpose
                </div>
                <div className="placement-details__col placement-val">
                  {vehicleLoanDetail?.LoanPurpose}
                </div>
              </div>

              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Vehicle Name
                </div>
                <div className="placement-details__col placement-val">
                  {vehicleLoanDetail?.VehicleName}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Model Number
                </div>
                <div className="placement-details__col placement-val">
                  {vehicleLoanDetail?.ModelName}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Engine Capacity
                </div>
                <div className="placement-details__col placement-val">
                  {vehicleLoanDetail?.EngineCapacity}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Company Name
                </div>
                <div className="placement-details__col placement-val">
                  {vehicleLoanDetail?.CompanyName}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Quotation Price
                </div>
                <div className="placement-details__col placement-val">
                  {currencyCommaSeperator(vehicleLoanDetail?.QuotationPrice)}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Requested Amount
                </div>
                <div className="placement-details__col placement-val">
                  NPR {currencyCommaSeperator(vehicleLoanDetail?.RequestAmount)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  vehicleLoanDetail: state.loanReducer.vehicleLoanDetail,
});

export default connect(mapStateToProps, {})(VehicleLoanDetails);
