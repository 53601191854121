import React, { useState } from "react";
import FileBlock from "./fileBlock";

function Index({ documents }) {
  return (
    <div className="dashboard-profile-document">
      <div className="document-title">
        <span>DOCUMENTS</span>
      </div>
      {documents.map((document, index) => {
        if (document.Attachments.length == 0) return null;
        return (
          <>
            {document.Attachments.map((file) => (
              <FileBlock file={file} />
            ))}
          </>
        );
      })}

      {/* {documents.map((document, index) => {
        if (document.Attachments.length == 0) return null;
        return (
          <div className="document-container" key={index}>
            <div className="document-container-title">
              <span>{document.Title}</span>
            </div>
            <div className="document-container-body">
              {document.Attachments.map((file) => (
                <FileBlock file={file} />
              ))}
            </div>
          </div>
        );
      })} */}
    </div>
  );
}
export default Index;
