import React from "react";
import styled from "styled-components";
import Icon from "@material-ui/core/Icon";
import {Card} from "@material-ui/core";

const Container = styled.div`
  width: 284px;
  margin-bottom: 8px;
`;

const StyledCard = styled(Card)`
  min-height: 55px;
  padding: 6px 8px;
  box-shadow:unset !important;
  border: 1px solid #cfcfcf;
`;

const StyledTextArea = styled.input`
  resize: none;
  width: 100%;
  height: 45px;
  padding: 5px 15px;
  overflow: hidden;
  outline: none;
  border: none;
`;


const ButtonContainer = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

const StyledIcon = styled(Icon)`
  margin-left: 8px;
  cursor: pointer;
`;

const TrelloForm = React.memo(
  ({ list, text = "", onChange,onEnter,onBlur }) => {
    const placeholder = list
      ? "Enter list title..."
      : "Enter a title for a card...";

    return (
      <Container>
        <StyledCard>
          <StyledTextArea
            placeholder={placeholder}
            autoFocus
            value={text}
            onChange={e => onChange(e)}
            onBlur={e=>onBlur && onBlur(e)}
            // onBlur={closeForm}
            onKeyUp={e=>onEnter(e)} 
          />
        </StyledCard>
        {/* <ButtonContainer>
          {children}
          <StyledIcon onMouseDown={closeForm}>close</StyledIcon>
        </ButtonContainer> */}
      </Container>
    );
  }
);

export default TrelloForm;
