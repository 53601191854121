import React from "react";
import { TMStage, TmViewType } from "scenes/tasks/helpers/enum";

const Stages = (props) => {
  const { onClickStage } = props;
  const stages = [
    { label: "Things to Do", value: TMStage.ThingsToDo, name: "things-to-do" },
    { label: "In Progress", value: TMStage.InProgress, name: "in-progress" },
    { label: "Completed", value: TMStage.Completed, name: "completed" },
    { label: "On Hold", value: TMStage.OnHold, name: "on-hold" },
    { label: "Cancelled", value: TMStage.Cancelled, name: "cancelled" },
  ];
  return (
    <div className="label-popup__section">
      <ul>
        <li>
          <span className="stage-item-label">Move To</span>
        </li>
        {stages.map((stage, index) => (
          <li
            className="li-stage"
            key={index}
            onClick={() => onClickStage(stage.value)}
          >
            <div className="stage-item">
              <div className={`stage-item-stage stage-item-${stage.name}`} />
              <span className="stage-item-stage-label">{stage.label}</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Stages;
