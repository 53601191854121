import React, { Component } from "react";
import Form from "components/form";

export class SearchAndFilter extends Component {
  render() {
    const { searchClient, activeType, legalClientTypes } = this.props;
    return (
      <div className="filters">
        <div className="filters-grid">
          <Form
            formName="textinput"
            type="text"
            name="searchClient"
            value={searchClient}
            onChange={this.props.handleChange}
            placeholder="Search Client"
          />
        </div>
        <div className="filters-grid">
          <Form
            formName="reactselect"
            name="activeType"
            value={activeType}
            options={legalClientTypes}
            onChange={this.props.handleChange}
            placeholder="Select Type.."
          />
        </div>
      </div>
    );
  }
}

export default SearchAndFilter;
