import React from 'react';
import data from './data'
import Treenode from './treenode'

function index() {
    return (
        <div className="reported-by">
            <div className="tertiary-title">
                <h3>Reported By</h3>
            </div>
            <div className="reported-block">
                <Treenode data={data} />
            </div>
        </div>
    )
}

export default index
