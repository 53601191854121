import React from "react";
import CommonModuleLinks from "components/common/commonModuleLinks";
import RouteEnum from "constants/routeEnum";

const modules = (features) => [
  {
    groupTitle: "Reports",
    feature:features.TaskTracker,
    links: [
      { title: "Activity report", link: RouteEnum.ActivityReportPath },
      {
        title: "Activity not submitted",
        link: RouteEnum.ActivityNotSubmittedReportPath,
      },
      { title: "Hours committed", link: RouteEnum.HoursCommittedPath },
      {
        title: "Hours worked for clients",
        link: RouteEnum.HoursWorkedByClientPath,
      },
      {
        title: "Hours worked by staffs",
        link: RouteEnum.HoursWorkedByPeoplePath,
      },
      { title: "People in project", link: RouteEnum.PeopleInProjectPath },
      { title: "Raw Report", link: RouteEnum.RawReportPath },
    ],
  },
];

function index(props) {
  return (
    <div className="admin-contain">
      <CommonModuleLinks
        title="Task Tracker Reports"
        localStorageTitle="TaskTrackerReports"
        modules={modules}
      />
    </div>
  );
}

export default index;
