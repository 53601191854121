import React, { useEffect } from "react";
import Icons from "components/icons";

import { CSSTransition } from "react-transition-group";

import TodoItem from "./toDoItem";

const Todo = ({ title, todos, }) => {
  const [open, setOpen] = React.useState(true);
  return (
    <div className="task-todo-assignments__block">
      <div
        onClick={() => setOpen(!open)}
        className="task-todo-assignments__block-header"
      >
        <div className="task-todo-assignments__block-icon">
          {open ? (
            <Icons name="ArrowCollapse" justSVG />
          ) : (
            <Icons name="ArrowExpand" justSVG />
          )}
        </div>
        <div className="task-todo-assignments__block-title">
          {title} ({todos.length})
        </div>
      </div>
      <div className="task-todo-assignments__body">
        <CSSTransition
          unmountOnExit
          in={open}
          timeout={400}
          classNames="taskpop"
        >
          <ul className="task-todo-assignments__list">
            {todos.map((x, index) => (
              <TodoItem key={index} todo={x}/>
            ))}
          </ul>
        </CSSTransition>
      </div>
    </div>
  );
};

export default Todo;
