import React, { Component, useEffect, useRef } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { FaRegCalendarAlt } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
  startDatetime,
  endDatetime,
  Date_Format,
} from "../../helpers/dateFormat";
import isEmpty from "../../helpers/isEmpty";
import useOutsideClick from "helpers/customHooks/outsideClickHook";

interface Props {
  name: string;
  type: string;
  date: any;
  minDate: any;
  maxDate: any;
  autoFocus: boolean;
  placeholder: string;
  disabled: boolean;
  onChange: Function;
}

function ReactCalendar(props: Props) {
  const {
    name,
    type,
    date,
    minDate,
    maxDate,
    autoFocus,
    disabled,
    placeholder,
  } = props;

  let wrapperRef: any = useRef();
  let dateRangeRef: any = useRef();

  useOutsideClick(dateRangeRef, () =>
    wrapperRef.current.classList.remove("active")
  );

  const handleDateClick = (event: any) => {
    if (
      wrapperRef.current.contains(event.target) &&
      !dateRangeRef.current.contains(event.target)
    )
      wrapperRef.current.classList.toggle("active");
  };

  const handleCalendar = (date: any) => {
    const formatedDate = date ? Date_Format(date) : null;
    props.onChange && props.onChange(name, formatedDate);
  };

  const removeSelectedDate = () => {
    props.onChange && props.onChange(name, null);
  };

  return (
    <div
      className="daterangepicker"
      ref={wrapperRef}
      onClick={(e) => handleDateClick(e)}
    >
      <span className="daterangepicker__date">
        {!isEmpty(date) ? (
          <span>{Date_Format(date)}</span>
        ) : (
          <span className="placeholder">{placeholder}</span>
        )}
      </span>
      <div className="daterangepicker__range" ref={dateRangeRef}>
        <Calendar
          date={date && new Date(date)}
          minDate={minDate && new Date(minDate)}
          maxDate={maxDate && new Date(maxDate)}
          onChange={handleCalendar}
        />
      </div>
      {isEmpty(date) ? (
        <span className="daterangepicker__icon">
          <FaRegCalendarAlt />
        </span>
      ) : (
        <span className="daterangepicker__icon" onClick={removeSelectedDate}>
          <MdClose />
        </span>
      )}
    </div>
  );
}

ReactCalendar.defaultProps = {
  date: new Date(),
  minDate: new Date(),
  maxDate: null,
  placeholder: "Date range..",
};

export default ReactCalendar;
