import axios from "axios";
import { 
    GET_GROUP_MEMBER_USER_POOL_OPTIONS,
 } from "actions/types";
import isEmpty from "isEmpty";
import { toastMessage } from "actions/validateAction";

export const getUserMemberPoolOptions = () => async dispatch =>{
    await axios
    .get(`/v1/security/user-group-member-pool-options`)
    .then(response => {
        if (response.data.Status) {
          dispatch({
            type: GET_GROUP_MEMBER_USER_POOL_OPTIONS,
            payload: response.data.Data
          });
        } else {
          toastMessage(dispatch, response);
        }
      })
      .catch(err => {
        dispatch({
          type: GET_GROUP_MEMBER_USER_POOL_OPTIONS,
          payload: []
        });
        const response = {
          data: {
            MessageType: "Danger",
            Message: err.message?err.message:"Failed to get user group member list."
          }
        };
        toastMessage(dispatch, response);
    });
} 