import React, { useState, useEffect, useRef } from "react";
// import { MdCheck, MdClose } from "react-icons/md";
import Icons from "components/icons";
import useEventListener from "helpers/customHooks/useEventListener";
import isEmpty from "isEmpty";

function Popup(props) {
  const {
    open,
    onClose,
    onDone,
    children,
    parentPop,
    disableClickOutside = false,
    hideFooter = false,
  } = props;
  const pop = useRef(null);
  const setClose = () => {
    const { current } = pop;
    current.style.display = "";
    current.classList.add("popup-close");
    setTimeout(() => {
      current.classList.remove("popup-close");
      current.classList.remove("popup-open");
      onClose();
    }, 270);
  };

  // const setDone = () => {
  //   const { current } = pop;
  //   current.style.display = "";
  //   current.classList.add("popup-close");
  //   setTimeout(() => {
  //     current.classList.remove("popup-close");
  //     current.classList.remove("popup-open");

  //   }, 270);
  // };
  // if(!disableClickOutside) useOutsideAlerter(pop, setClose, parentPop);
  useEventListener(
    "click",
    (event) => {
      if (parentPop) {
        if (pop.current && !pop.current.contains(event.target)) {
          if (!parentPop.current.contains(event.target)) {
            setClose();
          }
        }
      } else {
        if (pop.current && !pop.current.contains(event.target)) {
          setClose();
        }
      }
    },
    { enabled: !disableClickOutside }
  );
  useEffect(() => {
    if (open) {
      const { current } = pop;
      current.style.display = "flex";
      // current.classList.add("popup-animate");
      current.classList.add("popup-open");
      // setTimeout(() => {
      //   current.classList.remove("popup-animate");
      //   current.classList.add("popup-open");
      // }, 270);
    }
  }, [open]);
  return (
    <div ref={pop} className="popup">
      <div className="popup-body">{children}</div>
      {!hideFooter && (
        <div className="popup-footer">
          <div onClick={setClose} className="popup-btn popup-remove">
            {/* <MdClose /> */}
            <Icons name="Close" />
          </div>
          <div className="popup-btn popup-add" onClick={() => onDone(setClose)}>
            {/* <MdCheck /> */}
            <Icons name="Check" />
          </div>
        </div>
      )}
    </div>
  );
}

export default Popup;
