import React, { Component } from 'react'
import classnames from 'classnames'
import isEmpty from 'isEmpty'
import {Link,NavLink} from 'react-router-dom'
import {FaChevronLeft} from 'react-icons/fa'
import RouteEnum from "constants/routeEnum";

// import {
//     PayrollComponent,
//     PayrollReviewFinalizePath,
//     PayrollTaxAdjustmentPath,
//     PayrollTaxCalculationPath,
//     // PayrollIncomeCalculationPath,
//     PayrollRFAdjustmentPath,
//     PayrollStopPaymentPath,
//     // ArrearCalculationPath,
//     PayrollSalaryAdjustmentPath,
//     PayrollLeaveAbsencePath,
// } from RouteEnum;
export class SideTab extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            isExpand:true,
        }
    }
    onHover(){
        this.sideHover && this.sideHover.classList.add('after');
        if(this.sideTab.classList.contains('sidetab-collapse')){
            this.sideTab && this.sideTab.classList.add('absolute');
        }
        
    }
    onHoverOut(){
        this.sideHover.classList.remove('after');
        setInterval(() => {
            this.sideTab && this.sideTab.classList.remove('absolute');
        }, 200);
    }
    render() {
        const tabContent = [
            {num:'1',title:'Attendance Register',type:"success",},
            {num:'2',title:'Leave & Absence',type:"success",},
            {num:'3',title:'Stop Payment',type:"primary",},
            {num:'4',title:'Arrear Calculation',type:"primary",},
            {num:'5',title:'Income Calculation',type:"disabled",errorNum:'2',},
            {num:'6',title:'Tax Adjustment',type:"disabled",},
            {num:'7',title:'Review and Finalize',type:"disabled",},
            // {num:'6',title:'Salary Adjustment',type:"primary",},
            // {num:'8',title:'RF Adjustment',type:"primary",},
            // {num:'9',title:'Tax Calculation',type:"disabled",},
        ];

        // const tabContent = [
        //     {num:'1',title:'Leave & Absence',type:"success",},
        //     {num:'2',title:'Stop Payment',type:"primary",},
        //     // {num:'3',title:'Arrear Calculation',type:"primary",},
        //     {num:'3',title:'Income Calculation',type:"disabled",errorNum:'2',},
        //     {num:'4',title:'Tax Adjustment',type:"disabled",},
        //     {num:'5',title:'Review and Finalize',type:"disabled",},
        // ];
        // const tabContent = [
        //     {num:'1',title:'Leave & Absence',type:"success",link: RouteEnum.PayrollLeaveAbsencePath},
        //     {num:'2',title:'Stop Payment',type:"primary",link: RouteEnum.PayrollStopPaymentPath},
        //     // {num:'3',title:'Arrear Calculation',type:"primary",link: RouteEnum.ArrearCalculationPath},
        //     {num:'4',title:'Income Calculation',type:"disabled",errorNum:'2',link: RouteEnum.PayrollIncomeCalculationPath},
        //     {num:'5',title:'Tax Adjustment',type:"disabled",link: RouteEnum.PayrollTaxAdjustmentPath},
        //     {num:'6',title:'Review and Finalize',type:"disabled",link: RouteEnum.PayrollTaxCalculationPath},
        // ];
        const {isExpand}=this.state;
        const sideClass=classnames({
            "payroll-sidetab":true,
            "sidetab-expand":isExpand===true,
            "sidetab-collapse":isExpand===false,
        });
        return (
            <div className={classnames({"sideHover":true,"sideHover-collapsed":!isExpand})}  ref={sideHover=> this.sideHover =sideHover}>
                <div  className={sideClass} ref={sideTab=> this.sideTab =sideTab}>
                    <div className="sidetab" 
                        onMouseEnter={()=>this.onHover()}
                        onMouseLeave={()=>this.onHoverOut()}
                        // onClick={() => this.props.onTabChanged}
                        >
                        {
                            tabContent.map((tab,key)=>{
                                return(
                                    <Tab key={key} data={tab} match={this.props.match} month={this.props.month} onTabClicked={(tabNo) => this.props.onTabChanged(tabNo)} />
                                )
                            })
                        }
                        {/* <div
                                    className={isExpand?"sidetab-expand__btn":"sidetab-expand__btn collapsed"}  
                                    onClick={()=>this.setState({isExpand:!isExpand})}    >
                                    <FaChevronLeft />
                        </div> */}
                        <a 
                            onClick={()=>this.setState({isExpand:!isExpand})}  
                            className={classnames({"sidetab-item":true,"sidetab-expand":true,"collapsed":!isExpand})} >
                                
                            <div className="sidetab-contain">
                                <div className="sidetab-countdown">
                                        <div className="sidetab-countdown__number">
                                            <FaChevronLeft />
                                        </div>
                                    </div>
                                <div className="sidetab-content">
                                    <h2 className="sidetab-title">Minimize this menu</h2>
                                </div>
                            </div>
                        </a>
                        
                    </div>
                </div>
            </div>
        )
    }
}

const Tab = ({
    data,
    month,
    match,
    onTabClicked
})=>{
    const tabClass = classnames({
        "sidetab-item":true,
        [data.type]:data.type
    })
    return(
        
        <a  /* to={{ pathname: data.link, state: { month} }} */ 
                className={tabClass} activeClassName="selected"
                onClick={() => {
                    console.log("youtube==>>", data, )
                    onTabClicked(data.num)
                    }
                }
                >
                <div className="sidetab-contain">
                    <div className="sidetab-countdown">
                        <div className="sidetab-countdown__number">
                            <span>{data.num}</span>
                        </div>
                    </div>
                    <div className="sidetab-content">
                        <h2 className="sidetab-title">
                            {data.title}
                        </h2>
                        {
                            isEmpty(data.errorNum)?null:
                            <span className="badge badge-danger-fade md">{data.errorNum}</span>
                        }
                    </div>
                </div>
        </a>
        
    )
}

export default SideTab
