import { validUploadDocExtension } from "constants/validExtensions";
import isEmpty from "isEmpty";

export const getSelectedUploadFile = (
  selectedFiles,
  prevSelectedFiles,
  allowedFileTypes
) => {
  const files = [];
  const fileList = selectedFiles;
  let inValidFileExists = false;
  for (var i = 0; i < fileList?.length; i++) {
    const file = fileList.item(i);

    Object.defineProperty(file, "myProp", {
      value: true,
    });
    let splittedFileName = file.name.split(".");
    let isAllowedToAdd = false;

    if (!isEmpty(allowedFileTypes)) {
      isAllowedToAdd = allowedFileTypes.includes(
        splittedFileName[splittedFileName.length - 1]
      );
    } else if (
      !validUploadDocExtension.includes(
        splittedFileName[splittedFileName.length - 1]
      )
    ) {
      inValidFileExists = true;
      break;
    } else isAllowedToAdd = true;
    if (isAllowedToAdd) files.push(file);
    else inValidFileExists = true;
  }

  const nonRepeatingFiles = prevSelectedFiles?.filter(
    ({ name: id1 }) => !files?.some(({ name: id2 }) => id2 === id1)
  );
  var mergedFiles = files.concat(nonRepeatingFiles);
  return { inValidFileExists, mergedFiles };
};
