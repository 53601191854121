import React, { Component, useState, useEffect, useRef } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
} from "@syncfusion/ej2-react-grids";
import isEmpty from "isEmpty";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  loadTravelCategory,
  deleteTravelCategory,
  getAllowanceExpense,
  getLevels,
} from "./actions";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Button from "components/button";
import Icons from "components/icons";

const theme = createMuiTheme();

export class Table extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editable: [],
      note: "",
      isExpanded: false,
    };
    this.tablerow = [];
    this.CommandTemplate = this.gridCommandTemplate;
    this.desTemplate = this.desTemplate;
    this.toggleTravelDetails = this.toggleTravelDetails.bind(this);
  }

  desTemplate = (props) => {
    //console.log(props);
    const listItems = props.DescriptionList.map((x, i) => {
      return (
        <div className="travel-details__li" key={i}>
          <span>{x.AllowanceExpenseName}</span>
          <span>{x.CalculationType}</span>
          <span>{x.Rate}</span>
        </div>
      );
    });
    return (
      <div className="description" id={`description${props.CategoryId}`}>
        <div className="description__text">
          <span>{props.Description}</span>
        </div>
        <div className="travel-details" id={`travelDetail${props.CategoryId}`}>
          {listItems}
        </div>
      </div>
    );
  };
  gridCommandTemplate = (props) => {
    //let className = "";
    return (
      <div className="btn-col">
        <Button
          title="Edit"
          bg="primary"
          type="button-outline"
          onClick={() => this.handleEditRecord(props)}
          buttonType={"icon-button"}
          leftIcon={<Icons name="Edit" />}
        />
        <Button
          title="Delete"
          bg="primary"
          type="button-outline"
          onClick={() => this.handleDelRecord(props)}
          buttonType={"icon-button"}
          leftIcon={<Icons name="Delete" />}
        />
        {/* <button
          className="btn-col__edit btn"
          onClick={() => this.handleEditRecord(props)}
        >
          Edit
        </button>
        <button
          className="btn-col__del btn"
          onClick={() => this.handleDelRecord(props)}
        >
          Delete
        </button> */}
        <IoIosArrowDown
          className="btn-arrow"
          onClick={(event) => this.toggleTravelDetails(props.CategoryId, event)}
        />
      </div>
    );
  };

  gridColumnTemplate = (name, props) => {
    return (
      <div className="flex" style={{ padding: "10px" }}>
        {props[name]}
      </div>
    );
  };
  toggleTravelDetails = (id, event) => {
    event.target.closest(".btn-arrow").classList.toggle("expand");
    this.setState({ isExpanded: !this.state.isExpanded });
    var x = document.getElementById(`description${id}`);
    if (x.offsetHeight === 20) {
      x.style.height = x.scrollHeight + "px";
      x.classList.toggle("expand-col");
    } else {
      x.style.height = "20px";
      x.classList.toggle("expand-col");
    }
  };
  handleAddNew = () => {
    const data = {
      CategoryId: 0,
      CategoryName: "",
      Description: "",
      TravelCategoryType: "1",
      TenantId: "",
    };
    this.handleModalOpen(data);
  };
  handleEditRecord = ({
    CategoryId,
    CategoryName,
    Description,
    Type,
    TenantId,
  }) => {
    const data = {
      CategoryId,
      CategoryName,
      Description,
      TravelCategoryType: Type,
      TenantId,
    };
    //console.log(data);
    this.handleModalOpen(data);
    //this.props.setEditMode();
  };

  handleDelRecord = (args) => {
    this.props.handleConfirmDelRecord(args.CategoryId);
  };

  handleModalOpen = (data) => {
    this.props.loadTravelCategory(data);
    // this.props.getAllowanceExpense();
    // this.props.getLevels();
    this.props.handleModalShow({ show: true, type: "normal" });
  };

  render() {
    const { travelCategory, loading } = this.props.travelCategoryReducer;
    return (
      <div>
        <div className="panel white space-between">
          <div className="flex">
            <h4 className="page-title">Travel Categories</h4>
            <Button
              title="Add New"
              bg="primary"
              type="button-outline"
              onClick={this.handleAddNew}
              buttonType={"icon-button"}
              leftIcon={<Icons name="Add" />}
            />
          </div>
          {/* <div>
                <a onClick={()=>this.toolbarClick('grid_excelexport')} className="btn-text btn-text__white">Export</a>
            </div> */}
        </div>
        <div className="arrear-table">
          {loading ? (
            <div className="skeleton-table">
              <Skeleton variant="rect" width={"100%"} height={55} />
              <Skeleton variant="rect" width={"100%"} height={55} />
              <Skeleton variant="rect" width={"100%"} height={55} />
              <Skeleton variant="rect" width={"100%"} height={55} />
              <Skeleton variant="rect" width={"100%"} height={55} />
              <Skeleton variant="rect" width={"100%"} height={55} />
              <Skeleton variant="rect" width={"100%"} height={55} />
            </div>
          ) : (
            <div className="travel-category-grid">
              <GridComponent
                dataSource={isEmpty(travelCategory) ? null : travelCategory}
                className="custom-grid"
                toolbar={this.toolbar}
                //  height={!isEmpty(searchedLeaveAbsence)?"500":'80'}
                id="grid"
                allowExcelExport={true}
                ref={(g) => (this.grid = g)}
              >
                <ColumnsDirective>
                  <ColumnDirective field="CategoryId" visible={false} />
                  <ColumnDirective
                    field="CategoryName"
                    headerText="CATEGORY NAME"
                    width="100"
                    template={this.gridColumnTemplate.bind(
                      this,
                      "CategoryName"
                    )}
                  />
                  <ColumnDirective field="Type" visible={false} />
                  <ColumnDirective
                    field="TypeName"
                    headerText="TYPE"
                    width="80"
                    template={this.gridColumnTemplate.bind(this, "TypeName")}
                  />
                  <ColumnDirective
                    field="Description"
                    headerText="DESCRIPTION"
                    width="250"
                    template={this.desTemplate.bind(this)}
                  />
                  <ColumnDirective
                    field=""
                    width="120"
                    template={this.CommandTemplate.bind(this)}
                  />
                </ColumnsDirective>
              </GridComponent>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Table.propTypes = {
  loadTravelCategory: PropTypes.func.isRequired,
  travelCategoryReducer: PropTypes.object.isRequired,
  deleteTravelCategory: PropTypes.func.isRequired,
  getAllowanceExpense: PropTypes.func.isRequired,
  getLevels: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  travelCategoryReducer: state.travelCategoryReducer,
});

export default connect(mapStateToProps, {
  loadTravelCategory,
  deleteTravelCategory,
  getAllowanceExpense,
  getLevels,
})(Table);
