import React,{useEffect} from 'react'
import Modal from 'components/modal';
import Button from 'components/button';
import { useDispatch, useSelector } from 'react-redux';
import FormGroup from 'components/formGroup';
import FormItem from 'components/formGroup/formItem';
import { addRosterTeamRole, updateRosterTeamRole } from 'scenes/roster/helpers/action';
import { useForm } from 'react-hook-form';

function AddRole(props) {
    const {
        open,
        onModalClose,
        isEdit,
        editData
    } = props;
    const dispatch = useDispatch();
    
    const {teamList,teamRoleList,scheduleList} = useSelector((state:any)=>state.roosterReducer);
    const method = useForm();
    const {setValue,watch,register,control} = method;
    useEffect(() => {
        if(editData ){
            console.log({editData})
            const team = teamList && teamList.find(item=>item.value === editData?.TeamId);
            console.log(editData?.RoleName,team)
            setValue('TeamId',team);
            setValue('RoleName',editData?.RoleName);
        }
    }, [editData])
    const onSubmit = async (beforeData) => {
        const data = {
            ...beforeData,
            TeamId:beforeData?.TeamId?.value
        }
        let res:any;
        if(isEdit){
            res = await dispatch(updateRosterTeamRole({Id:editData.Id,...data}))
        }else{
            res = await dispatch(addRosterTeamRole(data))
        }
        if(res && res?.Status){
            onModalClose();
        }
    }
    return (
        <Modal 
            onModalClose={onModalClose}
            open={open}
            title={isEdit?"Update Role":"Add Role"}                
            width="400px"
        >
            <div className="role-form">
                <FormGroup
                    onSubmit={onSubmit}
                    method={method}
                >
                    <div className="role-formitems">
                        <FormItem
                            width="250px"
                            name="TeamId"
                            formName="reactselect"
                            placeholder='Select Team'
                            // disabled={isEdit}
                            options={teamList}
                            register={register}
                            control={control}
                            rules={{ required: "Team is required!" }}
                        />
                        <FormItem
                            width="250px"
                            name="RoleName"
                            placeholder='Role Name'
                            register={register}
                            control={control}
                            rules={{ required: "Role Name is required!" }}
                        />
                    </div>
                    <div className="role-btns flex">
                        <Button 
                            title={isEdit?"Update Role":"Add Role"}
                            htmlButtonType="submit"
                            bg="primary"
                        />
                        <Button 
                            title="Cancel"
                            bg="secondary"
                            onClick={()=>onModalClose()}
                        />
                    </div>
                </FormGroup>
            </div>
        </Modal>
    )
}

export default AddRole
