import React, { Component } from "react";
import "./time.style.scss";
import { connect } from "react-redux";
import CommonCommentSection from "components/common/commonCommentSection";
import Icons from "components/icons";
import Modal from "components/modal";
import EmployeeDetails from "scenes/common/employeeDetails";
import ApproveButton from "components/button/approveButton";
import TimeRequestForm from "scenes/employee/time/timeRequest";
import isEmpty from "isEmpty";
import * as employeeRequestService from "services/employeeRequests";
import { DisplayClientSideMessage } from "actions/commonAction";
import { GetAuditLogOfRequests } from "scenes/employee/requests/actions";
import { Time_Format } from "helpers/dateFormat";
import { EMPLOYEE_TIME_REQUEST_MODULE_VALUE } from "constants/constants";
import AuditHistory from "components/common/auditHistory";

class TimeRequestDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeDetail: {},
      comments: [],
      comment: "",
      errors: {},
      myRequest: {},
      showTimeRequestForm: false,
    };
  }

  async componentDidMount() {
    const { timeRequestDetails } = this.props;
    if (!isEmpty(timeRequestDetails)) {
      this.setState({
        timeRequestId: timeRequestDetails["RequestId"],
      });
      this.initialize();
      //await this.props.getEmployeeLeaveList(10005);
    }
  }
  initialize = async () => {
    let { timeRequestDetails } = this.props;
    let { employeeDetail, comments, myRequest } = this.state;
    employeeDetail = {
      Id: 0,
      FullName: timeRequestDetails["Requester"],
      Imagepath: timeRequestDetails["RequesterImage"],
      Designation: timeRequestDetails["Designation"],
      Branch: timeRequestDetails["Branch"],
      Department: timeRequestDetails["Department"],
    };

    comments = timeRequestDetails["IsCommented"]
      ? timeRequestDetails["Comments"]?.map((x) => {
          return {
            commenter: x.CommentedBy,
            commentDate: x.CommentedDate,
            comment: x.Comments,
            commenterId: x.CommenterId,
          };
        })
      : [];

    let approverRecommender = [];
    approverRecommender.push({
      ApprovalStatus: timeRequestDetails.ApprovalStatus,
      FullName: timeRequestDetails.Approval,
      Imagepath: timeRequestDetails.ApprovalImage,
    });
    if (timeRequestDetails.Approval != timeRequestDetails.Reccommender) {
      approverRecommender.push({
        ApprovalStatus: timeRequestDetails.RecommendedStatus,
        FullName: timeRequestDetails.Reccommender,
        Imagepath: timeRequestDetails.RecommendedImage,
      });
    }
    this.setState({ employeeDetail, comments, approverRecommender });

    await this.props.GetAuditLogOfRequests({
      requestId: timeRequestDetails.RequestId,
      moduleId: EMPLOYEE_TIME_REQUEST_MODULE_VALUE,
    });
  };

  saveComment = async (comment) => {
    const { timeRequestId } = this.state;
    const { timeRequestDetails } = this.props;
    let requestData = {
      requestId: timeRequestId,
      requestType: timeRequestDetails["RequestType"],
      comment,
    };

    let result = await employeeRequestService.saveReviewRequestComment(
      requestData
    );
    if (result.Status) {
      await this.props.refreshRequestData();
    }
    this.props.DisplayClientSideMessage(result);
    this.setState({ comment });
    return result.Status;
  };

  handleRejectRequest = async () => {
    const { timeRequestId } = this.state;
    const { timeRequestDetails } = this.props;
    let x = this;

    let requestData = {
      requestId: timeRequestId,
      requestType: timeRequestDetails["RequestType"],
      reason: "",
    };

    let result = await employeeRequestService.rejectReviewRequests(requestData);
    if (result.Status) {
      await this.props.refreshRequestData();
      this.props.closeDetailsModal();
    }

    this.props.DisplayClientSideMessage(result);
  };

  handleApproveRequest = async () => {
    const { timeRequestId } = this.state;
    const { timeRequestDetails } = this.props;

    let requestData = {
      requestId: timeRequestId,
      requestType: timeRequestDetails["RequestType"],
      reason: "",
    };
    let result = await employeeRequestService.approveReviewRequests(
      requestData
    );
    if (result.Status) {
      await this.props.refreshRequestData();
      this.props.closeDetailsModal();
    }

    this.props.DisplayClientSideMessage(result);
  };

  handleEditFormDisplay = (value) => {
    this.setState({ showTimeRequestForm: value });
    if (!value) {
      this.props.closeDetailsModal();
    }
  };

  render() {
    const {
      timeRequestDetails,
      closeDetailsModal,
      isViewMode,
      isReview,
      auditLogData,
      page,
    } = this.props;

    const {
      employeeDetail,
      comments,
      errors,
      showTimeRequestForm,
      approverRecommender,
    } = this.state;

    return (
      <>
        <Modal
          title=""
          open={true}
          onModalClose={() => closeDetailsModal()}
          type=""
          className="modal-time-details"
        >
          <div className="time-attendance">
            <div className="time-attendance__header flex ml-md mr-md">
              <EmployeeDetails empDetails={employeeDetail} size={60} />
              {/* <IoIosCloseCircleOutline className="close-icon" /> */}
            </div>
            {/* <div className="time-attendance-title">Time Attendance</div> */}
            <div className="flex items-start wd-100">
              <div className="time-attendance-body mt-md ml-md mr-md mb-md">
                <div className="time-attendance-body__title">
                  <span className="request-type">Time Attendance</span>
                </div>
                {timeRequestDetails.TimeRequests.map((tr, i) => {
                  return (
                    <>
                      <div className="time-attendance-punched mt-sm">
                        <span className="time-attendance-punched_date">
                          {tr.RequestDate}
                        </span>
                        <span className="time-attendance-punched_time flex">
                          <span className="mr-sm bold">IN</span>
                          <span className="mr-sm">{tr.InTimeDv || "none"}</span>
                          <span className="mr-sm">
                            <Icons name="ArrowForward" color="grey" />
                          </span>
                          <span className="mr-sm">{tr.InTime || "none"}</span>
                        </span>
                        <span className="time-attendance-punched_reason">
                          {tr.InNote}
                        </span>
                      </div>
                      <div className="time-attendance-punched">
                        <span className="time-attendance-punched_date">
                          {/* Jan 21,2020 */}
                        </span>
                        <span className="time-attendance-punched_time flex">
                          <span className="mr-sm bold">OUT</span>
                          <span className="mr-sm">
                            {tr.OutTimeDv || "none"}
                          </span>
                          <span className="mr-sm">
                            <Icons name="ArrowForward" color="grey" />
                          </span>
                          <span className="mr-sm">{tr.OutTime || "none"}</span>
                        </span>
                        <span className="time-attendance-punched_reason">
                          {tr.OutNote}
                        </span>
                      </div>
                    </>
                  );
                })}

                <div className="time-attendance-requested">
                  <span className="time-attendance-requested_date">
                    <span className="mr-bg">Requested On</span>
                    <span className="mr-sm white-space-nowrap">
                      <span>{timeRequestDetails.RequestedOn + "   "}</span>
                      <span>
                        {timeRequestDetails.RequestedOnEng &&
                          Time_Format(timeRequestDetails.RequestedOnEng)}
                      </span>
                    </span>
                  </span>

                  <div className="time-attendance-requested_employee">
                    {approverRecommender?.map((item) => {
                      return (
                        <>
                          <EmployeeDetails
                            empDetails={item}
                            size={30}
                            showApprovalStatus
                          />
                        </>
                      );
                    })}
                  </div>

                  {/* <span className="time-attendance-requested_employee">
                  <span className="emp-name">
                    {timeRequestDetails.RecommendedStatus === "Approved" && (
                      <Icons name="Check" />
                    )}
                    <span>{timeRequestDetails.Reccommender}</span>
                  </span>
                  <span className="emp-name ml-md">
                    {timeRequestDetails.ApprovalStatus === "Approved" && (
                      <Icons name="Check" />
                    )}
                    <span>{timeRequestDetails.Approval}</span>
                  </span>
                </span> */}
                </div>

                {/* {page !== "myHistory" && (
                <div className="flex-column wd-50 overflow-hidden right-col mb-md">
                  {auditLogData.loading
                    ? null
                    : !isEmpty(auditLogData.value) && (
                        <AuditHistory auditLogData={auditLogData.value} />
                      )}
                </div>
              )} */}

                <CommonCommentSection
                  submitComment={this.saveComment}
                  comments={comments}
                  submitErrors={errors}
                  isViewMode={isViewMode}
                />

                {!isViewMode && (
                  <div className="time-attendance-bottom">
                    <div className="time-attendance-bottom-left">
                      <div className="deny">
                        <button
                          className="button with-icon button-primary-light"
                          onClick={this.handleRejectRequest}
                        >
                          <Icons name="CircleMinus" className="flex" />
                          <span>Deny</span>
                        </button>
                      </div>
                      <div className="deny">
                        <button
                          className="button with-icon button-primary-light"
                          onClick={(e) => this.handleEditFormDisplay(true)}
                        >
                          <Icons name="Edit" className="flex" />
                          <span>Edit</span>
                        </button>
                      </div>
                    </div>
                    <div className="time-attendance-bottom-right">
                      <ApproveButton
                        handleApproveRequest={this.handleApproveRequest}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="flex overflow-hidden right-col mt-md">
                <AuditHistory
                  auditLogData={auditLogData.value}
                  loadingType="skeleton"
                  loading={auditLogData.loading}
                />
              </div>
            </div>
          </div>
        </Modal>

        {showTimeRequestForm && (
          <TimeRequestForm
            showModal={showTimeRequestForm}
            onModalClose={this.handleEditFormDisplay}
            dataToEdit={timeRequestDetails}
            isReview={isReview}
            refreshRequestData={this.props.refreshRequestData}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  //   companyHolidays: state.commonReducer.companyHolidays,
  auditLogData: state.employeeRequestReducer.auditLogData,
});

const mapDispatchToProps = {
  DisplayClientSideMessage,
  GetAuditLogOfRequests,
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeRequestDetail);
