import React, { useState, useEffect } from "react";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "@material-ui/core";
import {
  FaCalendarAlt,
  FaCircle,
  FaRegCheckCircle,
  FaRegCircle,
  FaTimesCircle,
} from "react-icons/fa";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ConvertToDataSource } from "helpers/getTextLabel";
import isEmpty from "helpers/isEmpty";
import Form from "components/form";
import Button from "components/button";
import { AiOutlineMail } from "react-icons/ai";
import InterviewCard from "./components/interviewCard";
import { JobStages } from "scenes/recruit/enums";
import IconByCurrentStage from "scenes/recruit/common/iconByCurrentStage";
import { connect } from "react-redux";
import Loader from "components/loaders";
import Select, { components } from "react-select";
import JobStageSelect from "src/scenes/recruit/common/jobStageSelect";
import Align from "assets/images/align-slash.svg";
import Right from "assets/images/arrow-right.svg";
import Double from "assets/images/check-double.svg";

function Milestone(props: any) {
  const {
    selectedCandidate,
    milestoneDetail,
    onChangeJobStage,
    jobStageList,
    handleRejectClick,
    hanleEmailCicked,
    hiringStagesJobId,
    setScheduleModal
  } = props;

  const firstMilestone = milestoneDetail && milestoneDetail[0].StageId;
  const [expanded, setExpanded] = useState(firstMilestone);

  const [stages, setStages] = useState([]);

  const handlePanelChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleChangeJobStage = (name, value) => {
    onChangeJobStage(name, value);
  };
  const onRejectClick = () => {

    handleRejectClick && handleRejectClick(selectedCandidate.JobId, [selectedCandidate]);
  };
  const onMoveToJobOffer = (e) => {
    let hiringStage = props.hiringStageAndSubStagesJobId.value.find(x => x.StageId === JobStages.Hired)
    if (hiringStage) {
      let val = { ...hiringStage, label: hiringStage.Name, value: hiringStage.Id }
      onChangeJobStage("selectedJobStage", val);
    }
  }

  const renderCommonDiv = ({ StageId, index }) => {
    if (
      selectedCandidate &&
      (selectedCandidate.IsRejected || selectedCandidate.IsDisqualified)
    ) {
      return null;
    }

    return (
      <JobStageSelect
        index={index}
        StageId={StageId}
        onChange={handleChangeJobStage}
        selectedCandidate={selectedCandidate}
        onRejectClick={onRejectClick}
        hiringStageAndSubStagesJobId={props.hiringStageAndSubStagesJobId}
      />

    );
  };
  const renderActionBy = (md) => {
    let el = !isEmpty(md.SubStageName) ? (
      md.SubStageName.map((x) =>
        !isEmpty(x.ActionBy) ? (
          <div className="information-wholeInfo-milestone-shortlist no-bg">
            {x.SubStageName && <span>{x.SubStageName}</span>}
            <span>By {x.ActionBy}</span>
            <span>{x.ActionOn}</span>

          </div>
        ) : null
      )
    ) : !isEmpty(md.ActionBy) ? (
      <div className="information-wholeInfo-milestone-shortlist no-bg">
        <span>By {md.ActionBy}</span>
        <span>{md.ActionOn}</span>
      </div>
    ) : null
    return el

  }
  return (
    <div className="information-wholeInfo-milestone">
      <h5>Activities</h5>
      <div className="flex mb-md">
        {selectedCandidate.selectedCandidate !== JobStages.Job_Offer && (
          <button className="btn btn-primary" onClick={onMoveToJobOffer}>Move to Job Offer</button>)}
        <button className="button button-white" onClick={onRejectClick}>Reject Candidate</button>
      </div>
      <Loader
        type="normal"
        className="loader-icon abs-position-center"
        loading={props.milestoneDetailLoading}
        name="card"
        cardType="dashboard"
      />
      {/* <div className="verticle_line" /> */}

      {milestoneDetail &&
        milestoneDetail.map((md, i) => {
          let DynamicIcon = null;
          // if (currentStage) {
          //   iconName = "FaRegDotCircle";
          // }
          if (md.IsComplete && md.IsSkiped) {
            DynamicIcon = <FaCircle color={"#d9d9d9"} />;
          } else if (md.IsComplete) {
            DynamicIcon = <FaRegCheckCircle />;
          } else if (!md.IsComplete) {
            DynamicIcon = <FaRegCircle color={"#646464"} />;
          }
          return (
            <ExpansionPanel
              expanded={expanded === md.StageId}
              onChange={handlePanelChange(md.StageId)}
              key={i}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <div className="information-wholeInfo-milestone-panel">
                  <div className="information-wholeInfo-milestone-panel-icon_title">
                    {/* <span className="information-wholeInfo-milestone-panel-icon">
                      {DynamicIcon && DynamicIcon}
                    </span> */}
                    {md.StageName}
                  </div>

                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>

                {renderActionBy(md)}

                {md.StageId === JobStages.Interview && (
                  <InterviewCard
                    data={md.InterviewScheduleDetails}
                    handleEmailClicked={hanleEmailCicked}
                    setScheduleModal={setScheduleModal}
                  />
                )}

                {renderCommonDiv({ StageId: md.StageId, index: i })}

              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })}

      {/* <ExpansionPanel
        expanded={expanded === "hiring"}
        onChange={handlePanelChange("hiring")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          // aria-controls="panel2a-content"
          // id="panel2a-header"
        >
          <div className="information-wholeInfo-milestone-icon_title">
            <span className="information-wholeInfo-milestone-icon">
              <FaCircle color={"#d9d9d9"} />
            </span>
            Hiring Manager Review
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>{renderCommonDiv()}</ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        expanded={expanded === "written"}
        onChange={handlePanelChange("written")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          // aria-controls="panel2a-content"
          // id="panel2a-header"
        >
          <div className="information-wholeInfo-milestone-icon_title">
            <span className="information-wholeInfo-milestone-icon">
              <FaRegCheckCircle />
            </span>
            Written Exam
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>{renderCommonDiv()}</ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        expanded={expanded === "telephone"}
        onChange={handlePanelChange("telephone")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          // aria-controls="panel2a-content"
          // id="panel2a-header"
        >
          <div className="information-wholeInfo-milestone-icon_title">
            <span className="information-wholeInfo-milestone-icon">
              <FaRegDotCircle />
            </span>
            Telephone Interview
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>{renderCommonDiv()}</ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        expanded={expanded === "joboffer"}
        onChange={handlePanelChange("joboffer")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          // aria-controls="panel2a-content"
          // id="panel2a-header"
        >
          <div className="information-wholeInfo-milestone-icon_title">
            <span className="information-wholeInfo-milestone-icon">
              <FaRegCircle color={"#646464"} />
            </span>
            Job Offer
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>{renderCommonDiv()}</ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        expanded={expanded === "hired"}
        onChange={handlePanelChange("hired")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          // aria-controls="panel2a-content"
          // id="panel2a-header"
        >
          <div className="information-wholeInfo-milestone-icon_title">
            <span className="information-wholeInfo-milestone-icon">
              <FaRegCircle color={"#646464"} />
            </span>
            Hired
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>{renderCommonDiv()}</ExpansionPanelDetails>
      </ExpansionPanel> */}
    </div>
  );
}

// class LinkDropdown extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {};
//     this.moveNextStage = this.moveNextStage.bind(this);
//   }

//   moveNextStage = () => {
//     let list = JobStageList;
//     console.log(list);
//     // let currentIndex = list.map((item,index)=>item.value === this.props.seletedJobStage?index:null)
//     //                   .filter(item=>item!==null)[0];
//     // const {JobId,CandidateId,onChangeJobStage} = this.props;
//     // onChangeJobStage(null,list[currentIndex+1],CandidateId,JobId);
//   };
//   render() {
//     let dropStages = JobStageList.filter((stage) => stage !== seletedJobStage);
//     return (
//       <div className="link-dropdown">
//         <div
//           className="link-dropdown-link"
//           onClick={() => this.moveNextStage()}
//         >
//           <span>Move to next stage</span>
//         </div>
//         <div className="link-dropdown-dropdown" ref={this.setDropDown}>
//           <div
//             className="drop-icon"
//             onClick={() => this.setState({ openDrop: !this.state.openDrop })}
//           >
//             <FiChevronDown />
//           </div>
//           <div className={`drop-content ${openDrop ? "drop-active" : ""}`}>
//             <ul className="drop-list">
//               {dropStages
//                 .filter((stage) =>
//                   seletedJobStage !== JobStages.Applied
//                     ? stage.value > seletedJobStage
//                     : stage.value > 0
//                 )
//                 .map((stage) => (
//                   <li
//                     onClick={() =>
//                       onChangeJobStage(null, stage, CandidateId, JobId)
//                     }
//                     className="drop-list__item"
//                   >
//                     {stage.label}
//                   </li>
//                 ))}
//               {dropStages
//                 .filter((stage) =>
//                   seletedJobStage !== JobStages.Applied
//                     ? stage.value < seletedJobStage
//                     : stage.value < 0
//                 )
//                 .map((stage) => (
//                   <li
//                     onClick={() =>
//                       onChangeJobStage(null, stage, CandidateId, JobId)
//                     }
//                     className="drop-list__item"
//                   >
//                     {stage.label}
//                   </li>
//                 ))}
//               <li className="drop-list__item-hr"></li>
//               <li
//                 className="drop-list__item"
//                 onClick={() =>
//                   onChangeJobStage(null, { value: 10 }, CandidateId, JobId)
//                 }
//               >
//                 Disqualified
//               </li>
//               <li className="drop-list__item">Delete</li>
//             </ul>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }
const mapStateToProps = (state) => ({
  milestoneDetail: state.recruitment_dashboardReducer.milestoneDetail,
  milestoneDetailLoading: state.recruitment_dashboardReducer.milestoneDetailLoading,
  hiringStagesJobId: state.recruitment_dashboardReducer.hiringStagesJobId,
  hiringStageAndSubStagesJobId:
    state.recruitment_dashboardReducer.hiringStageAndSubStagesJobId,
});
export default connect(mapStateToProps, {})(Milestone);
