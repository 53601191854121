import React, { Component } from "react";
import "./style.scss";
import { connect } from "react-redux";
import { MdAdd } from "react-icons/md";
import Title from "../../common/title";
import DisciplinaryTabs from "./disciplinaryTabs";
import SearchAndFilter from "./searchAndFilter";
// import { GetDesignations, GetLevels } from "actions/commonAction";
import { GetDisciplinaryStages } from "./action";
import {
  GetDisciplinaryActionList,
  MoveDisciplinaryActionToNextStage,
  DeleteDisciplinaryCase,
  getDisciplinaryCaseDetails,
} from "./action";
import DisciplinaryCaseForm from "./form/disciplinaryCaseForm";
import isEmpty from "isEmpty";
import Button from "components/button";
import Icons from "components/icons";

export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      pageIndex: 0,
      rowsPerPage: 10,
      searchEmployee: "",
      searchActionStatement: "",
      fromDate: null,
      toDate: null,
      activeJobRole: null,
      activePosition: null,
      activeStageId: 5,
      showCreateDispCaseForm: false,
      isEditPage: false,
      disciplinaryStages: [],
    };
  }

  async componentDidMount() {
    await this.props.GetDisciplinaryStages();
    // this.props.GetDesignations();
    // this.props.GetLevels();
    await this.getDisciplinaryActionList();
  }
  componentDidUpdate(prevProps, prevState) {
    const { disciplinaryStages } = this.props;

    if (prevProps.disciplinaryStages !== disciplinaryStages) {
      this.setState({ disciplinaryStages });
    }
  }

  getDisciplinaryActionList = async () => {
    this.setState({ isLoading: true });
    const {
      pageIndex,
      rowsPerPage,
      activeStageId,
      searchEmployee,
      searchActionStatement,
      fromDate,
      toDate,
      activeJobRole,
      activePosition,
    } = this.state;

    const data = {
      SearchEmployee: searchEmployee,
      CaseTitle: searchActionStatement,
      LevelId: !isEmpty(activeJobRole) ? activeJobRole.value : -1,
      PositionId: !isEmpty(activePosition) ? activePosition.value : -1,
      ReportedDateFrom: fromDate,
      ReportedDateTo: toDate,
      StageId: activeStageId,
      PageIndex: pageIndex,
      PageSize: rowsPerPage,
    };
    await this.props.GetDisciplinaryActionList(data);
    this.setState({ isLoading: false });
  };

  handleMoveToNextStage = async ({
    disciplinaryCaseId = 0,
    stageId = null,
    subStageId = null,
  }) => {
    const { activeStageId } = this.state;
    const data = {
      DisciplinaryCaseId: disciplinaryCaseId,
      ActionSubStageRefId: !isEmpty(stageId) ? stageId : activeStageId,
      ActionSubStageRef_Id: subStageId,
    };

    await this.props.MoveDisciplinaryActionToNextStage(data, this.props);
  };

  handleEdit = async (disciplinaryCaseId) => {
    await this.props.getDisciplinaryCaseDetails(disciplinaryCaseId);
    this.setState({ showCreateDispCaseForm: true, isEditPage: true });
  };

  handleDelete = async (DisciplinaryCaseId) => {
    await this.props.DeleteDisciplinaryCase(
      DisciplinaryCaseId,
      this.getDisciplinaryActionList
    );
    //await this.getDisciplinaryActionList();
  };

  handleInput = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, pageIndex: 0 }, async () => {
      await this.getDisciplinaryActionList();
    });
  };

  handleDateRangePicker = (startDate, endDate) => {
    this.setState(
      { fromDate: startDate, toDate: endDate, pageIndex: 0 },
      async () => {
        await this.getDisciplinaryActionList();
      }
    );
  };

  handleStageChange = (stageId) => {
    this.setState({ activeStageId: stageId, pageIndex: 0 }, async () => {
      await this.getDisciplinaryActionList();
    });
  };

  handleAutoSelect = (name, selected) => {
    this.setState({ [name]: selected, pageIndex: 0 }, async () => {
      await this.getDisciplinaryActionList();
    });
  };

  handleRowChange = (num) => {
    this.setState({ rowsPerPage: num }, async () => {
      await this.getDisciplinaryActionList();
    });
  };

  handleIndexChange = (num) => {
    this.setState({ pageIndex: num }, async () => {
      await this.getDisciplinaryActionList();
    });
  };
  handleFormDisplay = (value) => {
    this.setState({ showCreateDispCaseForm: value });
    document.body.style.overflow = value ? "hidden" : "visible";
  };
  render() {
    const {
      employeeListForAdmin,
      disciplinaryList,
      designationList,
      levelList,
      history,
      disciplinaryCaseDetails,
    } = this.props;
    //console.log(disciplinaryStages);
    let height = this.state.showCreateDispCaseForm ? 0 : "auto";
    const {
      showCreateDispCaseForm,
      isEditPage,
      disciplinaryStages,
    } = this.state;
    return (
      <div className="common-container" style={{ height: height }}>
        <Title name="Disciplinary Action" parentProps={this.props} />

        <div className="add" style={{width:250}}>
          {/* <button
            onClick={() =>
              this.setState({ isEditPage: false, showCreateDispCaseForm: true })
            }
            className="btn btn-outline add-content"
          >
            <span className="add-content-icon">
              <MdAdd />
            </span>
            Add Disciplinary Action
          </button> */}
          <Button
            onClick={() => {
              this.setState({ isEditPage: false });
              this.handleFormDisplay(true);
            }}
            buttonType="icon-button"
            type="button"
            bg="white"
            icon={<Icons name="Add" />}
          >
            Add Disciplinary Action
          </Button>
        </div>

        <SearchAndFilter
          handleAutoSelect={this.handleAutoSelect}
          handleInput={this.handleInput}
          handleDateRangePicker={this.handleDateRangePicker}
          parentState={this.state}
          designationList={designationList}
          levelList={levelList}
        />
        {!isEmpty(disciplinaryStages) ? (
          <DisciplinaryTabs
            handleIndexChange={this.handleIndexChange}
            handleRowChange={this.handleRowChange}
            handleStageChange={this.handleStageChange}
            handleMoveToNextStage={this.handleMoveToNextStage}
            handleEdit={this.handleEdit}
            handleDelete={this.handleDelete}
            parentState={this.state}
            disciplinaryList={disciplinaryList}
            disciplinaryStages={disciplinaryStages}
          />
        ) : null}

        {showCreateDispCaseForm ? (
          <DisciplinaryCaseForm
            history={history}
            title="New Disciplinary Case"
            handleFormDisplay={this.handleFormDisplay}
            isEditPage={isEditPage}
            showForm={showCreateDispCaseForm}
            formExists={true}
            isFormLoading={disciplinaryCaseDetails.loading}
            getDisciplinaryActionList={this.getDisciplinaryActionList}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // designationList: state.commonReducer.designationList,
  designationList: [],
  levelList: [],
  // levelList: state.commonReducer.levelList,
  disciplinaryStages: state.disciplinaryActionReducer.disciplinaryStages,
  disciplinaryList: state.disciplinaryActionReducer.disciplinaryList,
  disciplinaryCaseDetails:
    state.disciplinaryActionReducer.disciplinaryCaseDetails,
});

const mapDispatchToProps = {
  // GetDesignations,
  // GetLevels,
  GetDisciplinaryStages,
  GetDisciplinaryActionList,
  MoveDisciplinaryActionToNextStage,
  DeleteDisciplinaryCase,
  getDisciplinaryCaseDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
