import React, {useEffect} from 'react';
import Reporting from './reporting/index'
import PlacementBlock from './placementBlock/index'
import ReportedBy from './reportedBy';
import Responsibility from './responsibility';
import { useDispatch,useSelector } from 'react-redux';
import isEmpty from 'isEmpty'
import './placement.styles.scss';

function Placement(props) {
    const dispatch = useDispatch()
    const {employeeAssignedJobDescription} = useSelector(state=>state.employeeProfile)
    useEffect(()=>{
        if(isEmpty(employeeAssignedJobDescription)){
            props.getPlacementData()
        }
    },[])
    return (
        <div  className="placement">
            <PlacementBlock data={employeeAssignedJobDescription} {...props} />    
            <Responsibility {...props} />
            <Reporting {...props} />    
            <ReportedBy />    
        </div>
    )
}

export default Placement
