import {
  GET_EMPLOYEE_PROFILE_DETAIL,
  IS_EMPLOYEE_PROFILE_LOADING,
  GET_JOB_DESC_BY_LEVEL,
  ASSIGN_JOB_DESCRIPTION,
  CHANGE_ASSIGNED_JOB_DESCRIPTION,
  GET_EMPLOYEE_MANAGERS_FOR_JOB,
  GET_EMPLOYEE_MANAGER_HISTORY,
  SET_MANAGER_FOR_EMPLOYEE,
  GET_EMPLOYEE_ASSIGNED_JOB_DESCRIPTION,
  EMPLOYEE_KSA_RATING,
  GET_EMPLOYEE_ROLES_HISTORY,
  EMPLOYEE_EDUCATION_LIST,
  SAVE_EMPLOYEE_EDUCATION_DETAILS,
  DELETE_EMPLOYEE_EDUCATION_DETAIL,
  CURRENTLY_SELECTED_EDU_SECTION,
  GET_EMPLOYEE_TRAINING_LIST,
  ASSIGN_JOB_DESCRIPTION_HISTORY,
  SAVE_EMPLOYEE_TRAINING_DETAILS,
  DELETE_EMPLOYEE_TRAINING_DETAIL,
  GET_EMPLOYEE_EXPERIENCE_LIST,
  SAVE_EMPLOYEE_EXPERIENCE_DETAILS,
  DELETE_EMPLOYEE_EXPERIENCE_DETAIL,
  GET_EMPLOYEE_REFERENCE_LIST,
  SAVE_EMPLOYEE_REFERENCE_DETAILS,
  DELETE_EMPLOYEE_REFERENCE_DETAIL,
  GET_EMPLOYEE_LEAVE_LIST,
  GET_EMPLOYEE_ADDITIONAL_DOCUMENTS,
  ADD_EMPLOYEE_ADDITIONAL_DOCUMENTS,
  EDIT_EMPLOYEE_ADDITIONAL_DOCUMENTS,
  DELETE_EMPLOYEE_ADDITIONAL_DOCUMENTS,
  GET_EDUCTION_QUALIFICATION_LIST,
  GET_EMPLOYEE_ADDRESS_DETAILS,
  SAVE_EMPLOYEE_ADDRESS_DETAILS,
  GET_EMPLOYEE_CITIZENSHIP_DETAILS,
  GET_EMPLOYEE_DRIVING_DETAILS,
  GET_EMPLOYEE_PASSPORT_DETAILS,
  SAVE_EMPLOYEE_FAMILY_INFO,
  SAVE_EMPLOYEE_HEALTH_INFO,
  EMPLOYEE_FAMILIES_DETAIL,
  EMPLOYEE_HEALTH_DETAIL,
  
} from "actions/types";

const initialState = {
  jobDescriptionByLevel: [],
  employeeAdditionalDocs: [],
  assignJobDescriptionHistoryData: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case IS_EMPLOYEE_PROFILE_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_EMPLOYEE_ADDITIONAL_DOCUMENTS: {
      return {
        ...state,
        employeeAdditionalDocs: action.payload,
      };
    }
    case GET_EMPLOYEE_PROFILE_DETAIL: {
      return {
        ...state,
        employeeProfile: action.payload,
      };
    }
    case ADD_EMPLOYEE_ADDITIONAL_DOCUMENTS: {
      return {
        ...state,
        employeeAdditionalDocs: [
          action.payload,
          ...state.employeeAdditionalDocs,
        ],
      };
    }
    case EDIT_EMPLOYEE_ADDITIONAL_DOCUMENTS: {
      return {
        ...state,
        employeeAdditionalDocs: state.employeeAdditionalDocs.map((item) => {
          if (item.Id == action.payload.Id) {
            return action.payload;
          }
          return item;
        }),
      };
    }
    case DELETE_EMPLOYEE_ADDITIONAL_DOCUMENTS: {
      return {
        ...state,
        employeeAdditionalDocs: state.employeeAdditionalDocs.filter(
          (item) => item.Id !== action.payload
        ),
      };
    }
    case GET_JOB_DESC_BY_LEVEL: {
      return {
        ...state,
        jobDescriptionByLevel: action.payload,
      };
    }

    case ASSIGN_JOB_DESCRIPTION: {
      return {
        ...state,
        assignJobDescriptionData: action.payload,
      };
    }
    case ASSIGN_JOB_DESCRIPTION_HISTORY: {
      return {
        ...state,
        assignJobDescriptionHistoryData: action.payload,
      };
    }
    case CHANGE_ASSIGNED_JOB_DESCRIPTION: {
      return {
        ...state,
        changedJobDescriptionData: action.payload,
      };
    }

    case GET_EMPLOYEE_MANAGERS_FOR_JOB: {
      return {
        ...state,
        getEmployeeManagersForJob: action.payload,
      };
    }

    case GET_EMPLOYEE_MANAGER_HISTORY: {
      return {
        ...state,
        employeeManagersHistory: action.payload,
      };
    }

    case GET_EMPLOYEE_ROLES_HISTORY: {
      return {
        ...state,
        employeeRolesHistory: action.payload,
      };
    }

    case SET_MANAGER_FOR_EMPLOYEE: {
      return {
        ...state,
        savedReportingManagerForEmployee: action.payload,
      };
    }

    case GET_EMPLOYEE_ASSIGNED_JOB_DESCRIPTION: {
      return {
        ...state,
        employeeAssignedJobDescription: action.payload,
      };
    }

    case EMPLOYEE_KSA_RATING: {
      return {
        ...state,
        employeeKsaRating: action.payload,
      };
    }

    case EMPLOYEE_EDUCATION_LIST: {
      return {
        ...state,
        employeeEducationList: action.payload,
      };
    }

    case SAVE_EMPLOYEE_EDUCATION_DETAILS: {
      return {
        ...state,
        employeeEducationSaved: action.payload,
      };
    }

    case DELETE_EMPLOYEE_EDUCATION_DETAIL: {
      return {
        ...state,
        employeeEducatonDeleted: action.payload,
      };
    }

    case CURRENTLY_SELECTED_EDU_SECTION: {
      return {
        ...state,
        currentlySelectedEduSection: action.payload,
      };
    }

    case GET_EMPLOYEE_TRAINING_LIST: {
      return {
        ...state,
        employeeTrainingList: action.payload,
      };
    }

    case SAVE_EMPLOYEE_TRAINING_DETAILS: {
      return {
        ...state,
        employeeTrainingSaved: action.payload,
      };
    }

    case DELETE_EMPLOYEE_TRAINING_DETAIL: {
      return {
        ...state,
        employeeTrainingDeleted: action.payload,
      };
    }

    case GET_EMPLOYEE_EXPERIENCE_LIST: {
      return {
        ...state,
        employeeExperienceList: action.payload,
      };
    }

    case SAVE_EMPLOYEE_EXPERIENCE_DETAILS: {
      return {
        ...state,
        employeeExperienceSaved: action.payload,
      };
    }

    case DELETE_EMPLOYEE_EXPERIENCE_DETAIL: {
      return {
        ...state,
        employeeExperienceDeleted: action.payload,
      };
    }

    case GET_EMPLOYEE_REFERENCE_LIST: {
      return {
        ...state,
        employeeReferenceList: action.payload,
      };
    }

    case SAVE_EMPLOYEE_REFERENCE_DETAILS: {
      return {
        ...state,
        employeeReferenceSaved: action.payload,
      };
    }

    case DELETE_EMPLOYEE_REFERENCE_DETAIL: {
      return {
        ...state,
        employeeReferenceDeleted: action.payload,
      };
    }

    case GET_EMPLOYEE_LEAVE_LIST: {
      return {
        ...state,
        employeeLeaveList: action.response,
        employeeLeaveList_Formated: action.formatedResponse,
      };
    }

    case GET_EDUCTION_QUALIFICATION_LIST: {
      return {
        ...state,
        educationQualificationList: action.payload,
      };
    }
    case SAVE_EMPLOYEE_FAMILY_INFO: {
        return {
            ...state,
            employeeFamilyInfoSaved: action.payload
        };
    }
    case SAVE_EMPLOYEE_HEALTH_INFO: {
        return {
            ...state,
            employeeHealthInfoSaved: action.payload
        };
    }

    case GET_EMPLOYEE_ADDRESS_DETAILS: {
      return {
        ...state,
        employeeAddressDetails: action.payload,
      };
    }

    case SAVE_EMPLOYEE_ADDRESS_DETAILS: {
      return {
        ...state,
        employeeAddressSaved: action.payload,
      };
    }

    case GET_EMPLOYEE_CITIZENSHIP_DETAILS: {
      return {
        ...state,
        employeeCitizenshipDetails: action.payload,
      };
    }

    case GET_EMPLOYEE_DRIVING_DETAILS: {
      return {
        ...state,
        employeeDrivingDetails: action.payload,
      };
    }

    case GET_EMPLOYEE_PASSPORT_DETAILS: {
      return {
        ...state,
        employeePassportDetails: action.payload,
      };
    }
    case EMPLOYEE_FAMILIES_DETAIL: {
      return {
        ...state,
        employeeFamiliesDetail: action.payload,
      };
    }

    case EMPLOYEE_HEALTH_DETAIL: {
      return {
        ...state,
        employeeHealthDetail: action.payload,
      };
    }

    default:
      return state;
  }
}
