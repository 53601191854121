import { DisplayClientSideMessage } from "actions/commonAction";
import FormGroup from "components/form";
import isEmpty from "isEmpty";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  GetAdvanceAndExpensesList,
  GetSavedAdvanceAndExpensesList,
} from "../actions";
import AdvanceAndExpensesTable from "./advanceAndExpensesTable";

export class Create_AdvanceAndExpenses extends Component {
  componentDidMount() {
    const { selectedTravelCategory } = this.props.advanceAndExpensesState;
    this.handleDropdown("selectedTravelCategory", selectedTravelCategory, true);
  }
  handleDropdown = (name, selected, initial) => {
    this.props.handleAdvanceAndExpensesTabState &&
      this.props.handleAdvanceAndExpensesTabState(name, selected);

    const categoryId = !isEmpty(selected) ? parseInt(selected.value) : -1;
    const {
      overAllDestinationFromDate,
      overAllDestinationToDate,
      isEditPage,
      isViewMode,
      GetSavedAdvanceAndExpensesList,
      RequestId,
    } = this.props;

    let { selectedTravelDestList } = this.props.travelInformationState;
    const totalDays = selectedTravelDestList.reduce(
      (a, b) => a + (parseInt(b["travelDestination"]["days"]) || 0),
      0
    );
    const totalNights = selectedTravelDestList.reduce(
      (a, b) => a + (parseInt(b["travelDestination"]["nights"]) || 0),
      0
    );
    if (
      !isEmpty(overAllDestinationFromDate) &&
      !isEmpty(overAllDestinationToDate)
    ) {
      (isViewMode || isEditPage) && initial
        ? GetSavedAdvanceAndExpensesList({
            requestId: RequestId,
            totalDays,
            totalNights,
          })
        : this.props.GetAdvanceAndExpensesList({
            categoryId,
            overAllDestinationFromDate,
            overAllDestinationToDate,
            totalDays,
            totalNights,
          });
    } else {
      let message = {
        MessageType: "danger",
        Message: "Please fill your destination first.",
      };
      this.props.DisplayClientSideMessage(message);
    }
  };

  handleTextChange = (name, value) => {
    //const { name, value } = e && e.target;
    this.props.handleAdvanceAndExpensesTabState &&
      this.props.handleAdvanceAndExpensesTabState(name, value);
  };

  render() {
    const {
      selectedTravelCategory,
      advanceRequestAmount,
      note,
    } = this.props.advanceAndExpensesState;
    const {
      travelCategoryList,
      selectedAdvanceAndExpensesList,
      isViewMode,
    } = this.props;
    return (
      <div className="travel-advance-expense-create">
        <div className="travel-create__title">
          <h3>Advance and expenses</h3>
        </div>
        <div className="travel-create__body">
          <div className="travel-field">
            <label>Travel category</label>
            <FormGroup
              formName="reactselect"
              name="selectedTravelCategory"
              placeholder="select category"
              value={selectedTravelCategory}
              options={travelCategoryList}
              onChange={this.handleDropdown}
              disabled={isViewMode}
              width="300px"
            />
          </div>
          <div className="">
            {/* <GeneralTable
              columnDirectiveInfo={advanceAndExpensesColumnDirectiveInfo}
              data={advanceAndExpensesList}
              hasActions={false}
            /> */}
            {
              selectedAdvanceAndExpensesList &&
              selectedAdvanceAndExpensesList.length > 0 ? (
                <AdvanceAndExpensesTable
                  data={selectedAdvanceAndExpensesList}
                />
              ) : null
              // <div>Please select travel category</div>
            }
          </div>
          <div className="travel-detail-row">
            <div className="travel-field">
              <label>Advance Request Amount</label>
              <FormGroup
                name="advanceRequestAmount"
                placeholder="5000"
                type="number"
                value={advanceRequestAmount}
                onChange={this.handleTextChange}
                disabled={isViewMode}
                width="300px"
              />
            </div>
          </div>
          <div className="travel-field">
            {/* <label>Note</label> */}
            <FormGroup
              formName="textarea"
              name="note"
              style={{ width: "100%" }}
              label="Note"
              value={note}
              onChange={this.handleTextChange}
              disabled={isViewMode}
            />
            {/* <textarea
              value={note}
              name="note"
              style={{ width: "60%" }}
              placeholder="Note on allowance and expenses"
              onChange={this.handleTextChange}
              disabled={isViewMode}
            /> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  travelCategoryList: state.travelInformationReducer.travelCategoryList,
  selectedAdvanceAndExpensesList:
    state.travelInformationReducer.selectedAdvanceAndExpensesList,
});
const mapDispatchToProps = {
  GetAdvanceAndExpensesList,
  DisplayClientSideMessage,
  GetSavedAdvanceAndExpensesList,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Create_AdvanceAndExpenses);
