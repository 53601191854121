import React from 'react'
import notPage from 'assets/images/404.svg'
import {Link} from 'react-router-dom';
import Button from 'components/button'

function NotFound() {
    return (
        <div className="page-not">
            <div className="page-not-col page-not-left">
                <div className="page-not-title">
                    <span>
                        404
                    </span>
                </div>
                <div className="page-not-content">
                    <span>
                        The page you’re looking for can’t be found
                    </span>
                </div>
                <div className="page-not-link">
                    <Link to="/">
                        <Button  title="Go back to dahboard" bg="highlight" />
                    </Link>
                </div>
            </div>
            <div className="page-not-col page-not-right">
                <img src={notPage} alt="404 || Not Found Page"/>
            </div>

        </div>
    )
}

export default NotFound
