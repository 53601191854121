import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Title from "scenes/common/title";
import SearchAndFilter from "./searchAndFilter";
import Table from "./table";
import { FetchCandidates } from "store/actions/recruitment/report";
import { getLevels } from "components/dataselect/action";
import { withRouter } from "react-router";

const exportColumns = [
  { value: "InternalId", label: "Internal Id" },
  { value: "CandidateName", label: "Candidate Name" },
  { value: "JobTitle", label: "Job Title" },
  { value: "Level", label: "Level" },
  { value: "EducationLevel", label: "Education Level" },
  { value: "MobileNo", label: "Mobile" },
  { value: "Email", label: "Email" },
  { value: "Nationality", label: "Nationality" },
  { value: "Age", label: "Age" },
  { value: "Gender", label: "Gender" },
  { value: "FatherName", label: "Father Name" },
  { value: "MotherName", label: "Mother Name" },
  { value: "PermanentProvince", label: "Permanent Province" },
  { value: "PermanentDistrict", label: "Permanent District" },
  { value: "PermanentLocalBody", label: "Permanent LocalBody" },
  { value: "PermanentLocality", label: "Permanent Locality" },
  { value: "PermanentWardNo", label: "Permanent Ward  No" },

  { value: "CorrespondingProvince", label: "Corresponding Province" },
  { value: "CorrespondingDistrict", label: "Corresponding District" },
  { value: "CorrespondingLocalBody", label: "Corresponding LocalBody" },
  { value: "CorrespondingLocality", label: "Corresponding Locality" },
  { value: "CorrespondingWardNo", label: "Corresponding Ward No" },

  { value: "ExperienceInYears", label: "Experience in Years" },
  { value: "ExperienceInDays", label: "Experience in Days" },
  { value: "ExperienceInMonths", label: "Experience in Months" },
  { value: "Trainings", label: "Trainings" },
  { value: "MaritalStatus", label: "Marital Status" },
];

function Index(props: any) {
  const { applicants, exportApplicants, applicantsLoading, levels } = props;

  const [exportData, setExportDaa] = useState([]);
  const [state, setState] = useState({
    internalJobId: "",
    candidateName: "",
    externalJobTitle: "",
    level: "",
    mobile: "",
    email: "",
    stage: 0,
    exportName: "",
    exportColumns: [],
    rowsPerPage: 20,
    pageIndex: 0,
  });

  //on load
  useEffect(() => {
    props.getLevels();
  }, []);
  //on exportApplicants change

  useEffect(() => {
    fetchApplicants(false);
  }, [state]);

  const fetchApplicants = async (isExport = false) => {
    const {
      rowsPerPage,
      pageIndex,
      internalJobId,
      candidateName,
      externalJobTitle,
      level,
      mobile,
      email,
      stage,
    } = state;

    let levelText = "";
    let stageVal = (stage && (stage as any).value) || 0;
    if (level) levelText = (level as any).label;

    await props.FetchCandidates(
      {
        PageIndex: isExport ? 0 : pageIndex,
        PageSize: isExport ? 999999999 : rowsPerPage,
        internalJobId,
        candidateName,
        externalJobTitle,
        level: levelText,
        mobile,
        email,
        stage: stageVal,
      },
      isExport
    );
  };

  const handleInputChange = (name, value) => {
    console.log(name, value);
    let nextState = { ...state };
    nextState[name] = value;
    setState(nextState);
  };

  const handleRowChange = (num) => {
    let nextState = { ...state };
    nextState["pageIndex"] = 0;
    nextState["rowsPerPage"] = num;
    setState(nextState);
  };

  const handleIndexChange = (num) => {
    let nextState = { ...state };
    nextState["pageIndex"] = num;
    setState(nextState);
  };

  const handleExcelExport = async () => {
    await fetchApplicants(true);
  };

  return (
    <div>
      <div className="common-container">
        <Title name="Applicants" />
        <SearchAndFilter
          {...state}
          handleInputChange={handleInputChange}
          handleExcelExport={handleExcelExport}
          exportName="applicants"
          exportData={props.exportApplicants}
          exportColumns={exportColumns}
          levels={levels}
        />
        <Table
          data={applicants}
          {...props}
          pageIndex={state.pageIndex}
          rowsPerPage={state.rowsPerPage}
          handleRowChange={handleRowChange}
          handleIndexChange={handleIndexChange}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  applicants: state.recruitment_reportReducer.applicants,
  exportApplicants: state.recruitment_reportReducer.exportApplicants,
  applicantsLoading: state.recruitment_reportReducer.applicantsLoading,
  levels: state.selectReducer.levels,
});

const mapDispatchToProps = { FetchCandidates, getLevels };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
