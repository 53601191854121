import React from "react";
import Avatar from "react-avatar";
import Icon from "components/icons";
import { MdSchedule } from "react-icons/md";
import CommonLinearProgress from "components/common/commonLinearProgress";
import classnames from "classnames";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import RouteEnum from "constants/routeEnum";

function Board(props) {
  const { data } = props;
  const {
    BoardId,
    BoardName,
    BoardMembers,
    BoardDescription,
    TotalTask,
    CompletedTask,
    CancelledTask,
    DueDays,
    DueDaysText,
    Status,
    BoardTime,
    TimeElapsed,
    TimeElapsedPercentage,
    PercentageComplete,
    OverdueTask,
  } = data;
  const base = props.auth.baseUrl
    ? props.auth.baseUrl.NetFrameworkAppBaseUrl
    : "";

  let progress = 0;
  if (TotalTask && CompletedTask) {
    progress = Math.ceil((CompletedTask / (TotalTask - CancelledTask)) * 100);
  }

  const dueClass = classnames({
    "boardslist-card__due": true,
    success: Status === "On Track",
    danger: Status === "Overdue",
    warning: Status === "Behind",
  });

  return (
    <div className="boardslist-item to-do">
      <div className="boardslist-card">
        <NavLink
          to={`${RouteEnum.TaskBoardSingleLink}${BoardId}`}
          className="tasks-component__secondary-item"
          activeClassName="active"
        >
          <div className="">
            <h4 className="boardslist-card__title">{BoardName}</h4>
            <p className="boardslist-card__description">{BoardDescription}</p>
          </div>
        </NavLink>
        {/* <div className="">
          
        </div> */}
        <div className="boardslist-card__teams">
          {BoardMembers &&
            BoardMembers.map((member, index) => (
              <Avatar
                key={index}
                size="25"
                round={true}
                name={member.EmployeeName}
                src={`${base}${member.ImageUrl}`}
              />
            ))}
        </div>
        <div className="boardslist-card__progress">
          <div className="boardslist-card__progress-pp">{progress}%</div>
          <CommonLinearProgress
            type={progress >= 50 ? "success" : "danger"}
            progress={progress}
          />
        </div>

        <div className="boardslist-card__time">
          <div className={dueClass}>
            <MdSchedule />
            <span>
              {DueDaysText ? DueDaysText : "--"} | {Status}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(Board);
