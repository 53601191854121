import {
  GET_EMAIL_TEMPLATE,
  GET_CANDIDATE_EMAILS,
  GET_CANDIDATE_COMMENTS,
  GET_MILESTONE_DETAIL,
} from "store/types";
import { api, get, post, deletion } from "networkService";

export const GetEmailTemplates = () => async (dispatch) => {
  const res: any = await get(api.recruitment.email.template, dispatch);

  let formatedTemplates = [];
  res &&
    res.Data &&
    res.Data.map((d) => {
      formatedTemplates.push({ ...d, label: d.TemplateName, value: d.Id });
    });

  dispatch({
    type: GET_EMAIL_TEMPLATE,
    payload: formatedTemplates,
  });
};

export const SaveEmailTemplates = (param, onSuccess) => async (dispatch) => {
  const res: any = await post(
    api.recruitment.email.saveTemplate,
    dispatch,
    param
  );
  onSuccess && onSuccess();
};

export const DeleteEmailTemplate = (id, onSuccess) => async (dispatch) => {
  const res: any = await deletion(
    api.recruitment.email.deleteTemplate,
    dispatch,
    id
  );
  onSuccess && onSuccess();
};

export const GetCandidateEmails = (param) => async (dispatch) => {
  const res: any = await get(
    api.recruitment.candidateEmail.email,
    dispatch,
    param
  );

  dispatch({
    type: GET_CANDIDATE_EMAILS,
    payload: res.Data,
  });
};

export const SaveCandidateEmail = (param, onSuccess) => async (dispatch) => {
  const res: any = await post(
    api.recruitment.candidateEmail.send,
    dispatch,
    param
  );
  onSuccess && onSuccess();
};

export const SaveScheduledInterviewEmail = (param, onSuccess) => async (
  dispatch
) => {
  const res: any = await post(
    api.recruitment.candidateEmail.sendForInterviewSchedule,
    dispatch,
    param
  );
  dispatch({
    type: GET_MILESTONE_DETAIL,
    payload: res.Data,
  });
  onSuccess && onSuccess();
};
export const GetCandidateComments = (param) => async (dispatch) => {
  const res: any = await get(
    api.recruitment.candidateComment.comment,
    dispatch,
    param
  );

  dispatch({
    type: GET_CANDIDATE_COMMENTS,
    payload: res.Data,
  });
};

export const SaveCandidateComment = (param, onSuccess) => async (dispatch) => {
  const res: any = await post(
    api.recruitment.candidateComment.comment,
    dispatch,
    param
  );
  onSuccess && onSuccess();
};
