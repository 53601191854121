import React from 'react'
import {loginUser} from "actions/authAction";
import { useDispatch,useSelector } from 'react-redux';
import RouteEnum from 'constants/routeEnum'
import isEmpty from 'isEmpty'
import queryString from 'query-string';


function SwitchUser(props) {
    const queryData = !isEmpty(props.location.search)? queryString.parse(props.location.search) :{}
    const dispatch = useDispatch();
    const {user} = useSelector((state:any)=>state.auth)
    const isAuth = useSelector((state:any):any=>state.auth.isAuthenticated);
    const userDetails = localStorage.getItem("UserDetail");
    const usered = !isEmpty(user) ? user :!isEmpty(userDetails) ? JSON.parse(userDetails) : [];
    const onSwitchUser = () =>{
        if(isAuth){
            const data = {
                rigoId:!isEmpty(queryData) ? queryData.username ?queryData.username :usered.MapToUserName :usered.MapToUserName,
                password:'',
                strategy:'switchuser'
            } 
            dispatch(loginUser(data,changeHistory,true,props.history))
            
        }
    }
    const changeHistory = () =>{
        props.history.push('/')
    }
    React.useEffect(()=>{
        onSwitchUser()
    },[]);
    
    React.useEffect(()=>{
          if(!isAuth && !localStorage.RefreshToken){
              props.history.push(RouteEnum.LoginPath)
          }
    },[isAuth]);
    return (null)
}

export default SwitchUser
