import React, {useState, useEffect} from 'react';
import {FaPlusCircle,FaMinusCircle} from 'react-icons/fa';
import man from 'assets/images/man.png';
import Avatar from 'react-avatar'
import { CSSTransition } from 'react-transition-group';

function Treenode({data}) {
    return (
        <div className="tree">
            <SingleNode />
        </div>
    )
}

export const SingleNode  = ({data}) =>{
    const [open,setOpen] = useState(false);
    const onOpen = () => setOpen(true);
    const onClose = () => setOpen(false);
    const onToggle = () => setOpen(!open);
    return(
        <div className="tree-row root">
            <div className="tree-column">
                <div className="tree-icon" onClick={()=>onToggle()}>
                    {
                        open ? <FaMinusCircle /> : <FaPlusCircle />
                    }
                </div>
                <div className="tree-block">
                    <div className="tree-block__avatar">
                        <Avatar 
                            src={man}
                            size={50}
                            className="tree-img"
                            name={"Image"}
                            round={true}
                        />
                    </div>
                    <div className="tree-block__details">
                        <div className="tree-block__name">
                            <h5>Romi Khadka</h5>
                        </div>
                        <div className="tree-block__report">
                            <span className="tree-block__position">Finance Manager</span>
                            <span className="tree-block__number">Direct Reports: 2</span>
                            <span className="tree-block__number">Total Subordinates: 2</span>
                        </div>
                    </div>
                </div>
            </div>
            
                <div className="tree-child tree-child-first">
                    <FirstChild isOpen={open} />
                    <FirstChild isOpen={open} />
                </div>
            
        </div>
    )
}

export const FirstChild  = ({data,isOpen}) =>{
    const [open,setOpen] = useState(false);
    const onToggle = () => setOpen(!open);

    useEffect(()=>{
        if(!isOpen){
            setOpen(false)
        }
    },[isOpen])
    return(
        <div className={`tree-row ${open?'tree-row__open':''}`}>
            <CSSTransition
                in={isOpen}
                // key={item.module_name} 
                timeout={300}
                classNames="tree"
                unmountOnExit 
            >
                <div className="tree-column">
                    <div className="tree-icon" onClick={()=>onToggle()}>
                        {
                            open ? <FaMinusCircle /> : <FaPlusCircle />
                        }
                    </div>
                    <div className="tree-block">
                        <div className="tree-block__avatar">
                            <Avatar 
                                src={man}
                                size={50}
                                className="tree-img"
                                name={"Image"}
                                round={true}
                            />
                        </div>
                        <div className="tree-block__details">
                            <div className="tree-block__name">
                                <h5>Romi Khadka</h5>
                            </div>
                            <div className="tree-block__report">
                                <span className="tree-block__position">Finance Manager</span>
                                <span className="tree-block__number">Direct Reports: 2</span>
                                <span className="tree-block__number">Total Subordinates: 2</span>
                            </div>
                        </div>
                    </div>
                </div>
            </CSSTransition>
            <div className="tree-child tree-child-last">
                <LastChild isOpen={open} />
            </div>
        </div>
    )
}

export const LastChild = ({isOpen}) =>{
    return(
        <div className="tree-row">
            <CSSTransition
                in={isOpen}
                // key={item.module_name} 
                timeout={300}
                classNames="treeChild"
                unmountOnExit 
            >
                <div className="tree-column">
                    <div className="tree-block tree-block__grid">
                        <Name />
                        <Name />
                        <Name />
                        <Name />
                        <Name />
                    </div>
                </div>
            </CSSTransition>
        </div>
    )
}

export const Name = ({}) =>{
    return(
        <div className="tree-block__person flex">
            <Avatar 
                src={man}
                size={50}
                className="tree-img"
                name={"Image"}
                round={true}
            />
            <div className="tree-block__name">
                <h5>Romi Khadka</h5>
            </div>
        </div>
    )
}

export default Treenode
