// import isEmpty from "~/helpers/isEmpty";
import {
  ADD_MESSAGE,
  CLEAR_MESSAGE,
  REMOVE_MESSAGE,
  ADD_MESSAGES,
  ADD_OTHER_MESSAGES,
} from "./types";
import shortid from "shortid";
import { validateAction } from "ts/interfaces/reduxInterfaces";

interface dispatchAction {
  type: string;
  payload: object | string | [];
}

export const addMessage = (data: validateAction) => (
  dispatch: (action: dispatchAction) => dispatchAction
) => {
  dispatch({
    type: ADD_MESSAGE,
    payload: {
      type: data.type,
      message: data.message,
      position: data.position,
    },
  });
};

export const addMessages = (data: validateAction) => (
  dispatch: (action: dispatchAction) => dispatchAction
) => {
  dispatch({
    type: ADD_MESSAGES,
    payload: {
      type: data.type,
      message: data.message,
      position: data.position,
      id: shortid.generate(),
    },
  });
};

export const clearOtherMessage = () => (
  dispatch: (action: dispatchAction) => dispatchAction
) => {
  dispatch({
    type: ADD_OTHER_MESSAGES,
    payload: {},
  });
};

export const toastMessage = (dispatch: any, res: any) => {
  let MessageType = null;
  let Message = [];

  if (res) {
    if (res.MessageType) {
      MessageType = res.MessageType;
    } else if (res.data && res.data.MessageType) {
      MessageType = res.data.MessageType;
    }

    if (res.Message) {
      Message = res.Message;
    } else if (res.data && res.data.Message) {
      Message = res.data.Message;
    }
  }

  if (MessageType && Message) {
    dispatch({
      type: ADD_MESSAGE,
      payload: { type: MessageType, message: Message },
    });
  }

  //   if (res && res.data && !isEmpty(res.data)) {
  //     if (
  //       (res.data.Message && !isEmpty(res.data.Message)) ||
  //       (res.data.MessageType && !isEmpty(res.data.MessageType))
  //     ) {
  //       dispatch({
  //         type: ADD_MESSAGE,
  //         payload: { type: res.data.MessageType, message: res.data.Message }
  //       });
  //     }
  //   }
};

export const removeMessage = (id: string) => (
  dispatch: (action: dispatchAction) => dispatchAction
) => {
  dispatch({
    type: REMOVE_MESSAGE,
    payload: id,
  });
};

export const clearMessage = () => (
  dispatch: (action: dispatchAction) => dispatchAction
) => {
  dispatch({
    type: CLEAR_MESSAGE,
    payload: {},
  });
};
