import React from "react";
import { connect } from "react-redux";
import FormGroup from "components/form";
import isEmpty from "isEmpty";

export class Filter extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let {
      onSubmit,
      onChange,
      branch,
      department,
      status,
      requisitionNumber,
      jobTitle,
    } = this.props;
    let {
      branches,
      departments,
      employeeStatus,
    } = this.props.recruitment_commonReducer;

    return (
      <form className="requisition-filter" onSubmit={onSubmit}>
        <div className="input-grp">
          <FormGroup
            name="requisitionNumber"
            size="sm"
            label=""
            type="text"
            width="13vw"
            placeholder="Search REQ ID"
            value={requisitionNumber}
            onChange={onChange}
          />
        </div>
        <div className="input-grp">
          {/* <InputGroup
          name="jobTitle"
          size="sm"
          label=""
          type="text"
          width="13vw"
          placeholder="search Job Title"
          onChange={onChange}
        /> */}
          <FormGroup
            name="jobTitle"
            size="sm"
            label=""
            type="text"
            width="13vw"
            placeholder="Search Job Title"
            value={jobTitle}
            onChange={onChange}
          />
        </div>
        <div className="input-grp">
          <FormGroup
            formName="reactselect"
            name="department"
            placeholder="Filter Division"
            options={departments}
            width={200}
            value={department}
            onChange={onChange}
          />
        </div>
        <div className="input-grp">
          <FormGroup
            formName="reactselect"
            name="branch"
            placeholder="Filter branches "
            options={branches}
            width={200}
            value={branch}
            onChange={onChange}
          />
        </div>
        <div className="input-grp">
          <FormGroup
            formName="reactselect"
            name="status"
            placeholder="Filter Status"
            options={employeeStatus}
            width={200}
            value={status}
            onChange={onChange}
          />
        </div>
        <div className="btn-grp">
          <button type="submit" className="btn btn-primary">
            Apply
          </button>
        </div>
      </form>
    );
  }
}

Filter = connect((state) => ({
  recruitment_commonReducer: state.recruitment_commonReducer,
}))(Filter);
export default Filter;
