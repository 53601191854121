import React,{ useEffect } from 'react'
import FormItem from 'components/formGroup/formItem';
import FormGroup from 'components/formGroup'
import Button from 'components/button';
import { useDispatch, useSelector } from 'react-redux';
import { addRosterScheduleEmployeeByShift } from 'scenes/roster/helpers/action';
import { useForm } from 'react-hook-form';


function ShiftAssignment(props) {
    const {onModalClose,timeData, teamId,startDate,endDate} = props;
    const {scheduleAllEmployeeList} = useSelector((state:any)=>state.roosterReducer);
    const emps = scheduleAllEmployeeList && scheduleAllEmployeeList.map(item=>({FullName:item?.EmployeeName,Id:item.EmployeeId,...item}))
    const dispatch = useDispatch();
    const method = useForm();
    const {setValue} = method;
    const onSubmit = async (data) => {
        const newData = data.assignable && data.assignable.map(item=>({
            shiftScheduleId:timeData?.ShiftScheduleId,
            employeeId:item,
            teamRoleId:teamId?.value,
            shiftRotationStartDay:0,
        }));
        if(newData){
            const res:any = await dispatch(addRosterScheduleEmployeeByShift(newData,startDate,endDate,timeData?.ShiftScheduleId))
            if(res && res?.Status){
                onModalClose()
            }
        }
    }
    useEffect(()=>{
        if(timeData && timeData?.allEmployees){
            const emps = timeData?.allEmployees.map(item=>item.EmployeeId);
            setValue("assignable",emps)
            // allEmployees
        }
    },[timeData])
    return (
        <FormGroup method={method} onSubmit={onSubmit} >
            <div className="shift-basic">
                <div className="shift-basic__row shift-basic__time">
                    <span>{timeData?.dateWithDay}</span>
                </div>
                <div className="shift-basic__row shift-basic__row-2">
                    <div className="shift-basic__col">
                        <FormItem
                            formName="checkBoxGroup"
                            name={"assignable"}
                            label="Assignable"
                            checkGroupArray={emps}
                            checkedGroupValue={[]}
                            multiple={true}
                        />
                    </div>
                    <div className="shift-basic__col">
                        <FormItem
                            formName="checkBoxGroup"
                            name={"unassignable"}
                            label="Unassignable"
                            checkGroupArray={[]}
                            // checkGroupArray={[{Id:1,FullName:'Bryan Green',Designation:'Some'}]}
                            checkedGroupValue={[1]}
                            multiple={false}
                        />
                    </div>
                </div>
                
                <div className="shift-basic__row flex">
                    <Button onClick={()=>onModalClose()} size="large" bg="subtle" title="Cancel" />
                    <Button bg="primary" size="large" htmlButtonType="submit" title="Save" />
                </div>
            </div>
        </FormGroup>
    )
}

export default ShiftAssignment
