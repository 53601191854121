import React, { useEffect } from 'react'
import FormItem from 'components/formGroup/formItem';
import FormGroup from 'components/formGroup'
import Button from 'components/button';
import { useDispatch, useSelector } from 'react-redux';
import { addRosterSchedule, deleteRosterSchedule, deleteRosterShift, getRosterScheduleList, updateRosterSchedule } from 'scenes/roster/helpers/action';
import { useForm } from 'react-hook-form';
import Confirm from 'components/common/commonConfimation';
import Icons from "components/icons";

const BasicShift = (props) => {
    const {
        onModalClose,
        date,
        teamId,
        startDate,
        endDate,
        timeData,
        data,
        isEdit
    } = props;

    const { shiftDropdownList } = useSelector((state: any) => state.roosterReducer);
    const method = useForm();
    const { setValue, watch, getValues } = method;
    const dispatch = useDispatch();
    const ab = watch("shiftId");
    useEffect(() => {
        if (timeData && isEdit) {
            const shift = shiftDropdownList && shiftDropdownList.find(item => item.value === timeData?.ShiftId + '')
            setValue("shiftId", shift);
            setValue("positions", timeData?.Positions);
            setValue("enableAllowance", timeData?.EnableAllowance);
            setValue("enableSwapping", timeData?.EnableSwapping);
        }
    }, [isEdit, timeData])
    const onSubmit = async (data) => {
        const finalData = {
            ...data,
            shiftId: data.shiftId?.value,
            scheduleDate: date,
            teamId: teamId?.value
        }
        let res: any;
        if (isEdit) {
            res = await dispatch(updateRosterSchedule({ id: timeData?.ShiftScheduleId, ...finalData }));
        } else {
            res = await dispatch(addRosterSchedule(finalData));
        }
        if (res && res?.Status === true) {
            onModalClose()
            const param = {
                startDate: startDate,
                endDate: endDate,
                teamId: teamId ? teamId.value : null
            }
            dispatch(getRosterScheduleList(param))
        }
    }
    const onRemoveShift = async () => {
        const res: any = await dispatch(deleteRosterSchedule(timeData?.ShiftScheduleId));
        if (res && res?.Status) {
            onModalClose();
        }
    }
    const { shiftId } = getValues();
    const rightIcon = <Icons name="ArrowRight"  />
    return (
        <>

            <FormGroup method={method} onSubmit={onSubmit} >
                <div className="shift-basic">
                    <div className="shift-basic__row shift-basic__time">
                        <span>
                            {data?.dateFull}
                        </span>
                        {/* <span>{date}</span> */}
                    </div>
                    {/* <div className="shift-basic__row shift-basic__duration">
                    <FormItem
                        formName="chipsinput"
                        name={"duration"}
                        alignment="horizontal"
                        chipsArray={[{ label: 'Shift', value: 'Shift' }, { label: 'Time', value: 'Time' }]}
                        label="Duration"
                    />
                </div> */}
                    <div className="shift-basic__row">
                        <FormItem
                            name="shiftId"
                            width="275px"
                            formName="reactselect"
                            options={shiftDropdownList}
                            label="Select Shift  *"
                            rules={{ required: "Shift is required!" }}
                        />
                        {shiftId &&
                            <div className="shift-basic__shift-time">
                                <span>{shiftId.BeginsAt}</span>
                                <span>{rightIcon}</span>
                                <span>{shiftId.EndsAt}</span>
                            </div>
                        }

                    </div>
                    {/* <div className="shift-basic__row">
                    <FormItem
                        name="role"
                        formName="reactselect"
                        width="275px"
                        options={[{ label: 'Chat', value: 'Chat' }, { label: 'INT', value: 'INT' }, { label: 'Team Member', value: 'Team Member' }]}
                        label="Role"
                    />
                </div> */}
                    <div className="shift-basic__row">
                        <FormItem
                            name="positions"
                            type="number"
                            width="100px"
                            label="Positions  *"
                            rules={{ required: "Positions is required!", min: { value: 1, message: 'Positions should be greater than 0' } }}
                        />
                    </div>
                    <div className="shift-basic__row">
                        <FormItem
                            formName="switch"
                            isStatus
                            name={"enableSwapping"}
                            alignment="horizontal"
                            leftLabel={"Shift Swapping"}
                        // rightLabel={"Disabled"}
                        // label="Shift Swapping"
                        />
                    </div>
                    <div className="shift-basic__row">
                        <FormItem
                            formName="switch"
                            isStatus
                            name={"enableAllowance"}
                            alignment="horizontal"
                            leftLabel={"Shift Allowance"}
                        // rightLabel={"Disabled"}
                        // label="Shift Swapping"
                        />
                    </div>
                    <div className="shift-basic__row space-between">
                        <div className="flex">
                            <Button onClick={() => onModalClose()} size="large" bg="subtle" title="Cancel" />
                            <Button bg="primary" size="large" htmlButtonType="submit" title="Save" />
                        </div>
                        {
                            isEdit && (
                                <Confirm
                                    action={() => onRemoveShift()}
                                    title="Remove Shift"
                                />
                            )
                        }

                        {/* <Button 
                            bg="danger"
                            size="large"
                            title="Remove Shift" />
                    </Confirm> */}

                    </div>
                </div>
            </FormGroup>
        </>

    )
}


export default BasicShift
