import isEmpty from "isEmpty";
import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "components/modal";
import { cancelJobRequisition, getMyJobRequisition } from "./action";
import CommonHeaderSection from "./commonHeaderSection";
//import {GetMyTravelRequests,DeleteTravelRequests} from './action';
import MyRequisitionRequest from "./table/requisitionRequest";

class MyTravelRequestScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsPerPage: 10,
      pageIndex: 1,
      isModalShow: false,
      selectedRequisitionId: 0,
    };
    this.handleRowChange = this.handleRowChange.bind(this);
    this.handleIndexChange = this.handleIndexChange.bind(this);
  }
  componentDidMount() {
    this.props.getMyJobRequisition({
      EmployeeId: this.props.user.EmployeeId,
      PageIndex: 1,
      PageSize: 10,
    });
  }
  componentDidUpdate = (prevProps, prevState) => {
    const { rowsPerPage, pageIndex } = this.state;
    if (
      rowsPerPage !== prevState.rowsPerPage ||
      pageIndex !== prevState.pageIndex
    ) {
      const searchData = {
        EmployeeId: this.props.user.EmployeeId,
        PageIndex: this.state.pageIndex,
        PageSize: this.state.rowsPerPage,
      };
      this.props.getMyJobRequisition(searchData);
    }
  };

  handleRowChange(num) {
    this.setState({ rowsPerPage: num });
  }
  handleIndexChange(num) {
    this.setState({ pageIndex: num + 1 });
  }
  handleConfirmCancelRequest = (id) => {
    this.setState({
      selectedRequisitionId: id,
      isModalShow: true,
    });
  };
  handleCancelRequest = (args) => {
    this.props.cancelJobRequisition(this.state.selectedRequisitionId);
  };
  closeModal = () => {
    this.setState({ isModalShow: false });
  };
  renderBody = () => {
    const { spinnerLoading, loading } = this.props.jobRequisitionReducer;
    return (
      <div className="">
        {!loading ? (
          <MyRequisitionRequest
            pageIndex={this.state.pageIndex}
            rowsPerPage={this.state.rowsPerPage}
            handleRowChange={this.handleRowChange}
            handleIndexChange={this.handleIndexChange}
            type={2}
            handleConfirmCancelRequest={this.handleConfirmCancelRequest}
          />
        ) : null}
        <Modal
          title=""
          open={this.state.isModalShow}
          onModalClose={this.closeModal}
          type="confirm"
          func={() => this.handleCancelRequest()}
          className=""
        />
      </div>
    );
  };

  render() {
    const selectedTabId = !isEmpty(this.props.location.state)
      ? this.props.location.state.selectedTabId
      : null;
    return (
      <CommonHeaderSection
        renderBody={this.renderBody()}
        selectedTabId={selectedTabId}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  jobRequisitionReducer: state.jobRequisitionReducer,
  user: state.auth.user,
});

const mapDispatchToProps = { getMyJobRequisition, cancelJobRequisition };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyTravelRequestScreen);
