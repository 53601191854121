import {
  GET_REJECT_REASONS,
  GET_REJECT_REASONS_LOADING,
  SAVE_CANDIDATE_REJECT_LOADING,
} from "store/types";

const initialState = {
  rejectReasons: [],
  rejectReasonsLoading: false,
  saveCandidateRejectLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_REJECT_REASONS_LOADING: {
      return { ...state, rejectReasonsLoading: action.payload };
    }
    case GET_REJECT_REASONS:
      return {
        ...state,
        rejectReasons: action.payload,
        rejectReasonsLoading: false,
      };
    case SAVE_CANDIDATE_REJECT_LOADING: {
      return { ...state, saveCandidateRejectLoading: action.payload };
    }

    default:
      return state;
  }
}
