import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Title from "scenes/common/title";
import Table from "./table";
import AddForm from "./addForm";
import Button from "components/button";
import {
  GetEmailTemplates,
  DeleteEmailTemplate,
} from "store/actions/recruitment/setting";
import { GetJobStages } from "store/actions/recruitment/dashboard";

function EmailTemplate(props: any) {
  const [isModalShow, setIsModalShow] = useState(false);
  const [dataToEdit, setDataToEdit] = useState(null);
  const [state, setState] = useState({
    pageIndex: 0,
    rowsPerPage: 20,
  });

  useEffect(() => {
    props.GetJobStages();
    getEmailTemplates();
  }, []);

  const getEmailTemplates = () => {
    props.GetEmailTemplates();
  };

  const onEdit = (data) => {
    setDataToEdit(data);
    setIsModalShow(true);
  };

  const onDelete = (data) => {
    props.DeleteEmailTemplate(data.Id, getEmailTemplates);
  };

  // const handleRowChange = (num) => {};
  // const handleIndexChange = (num) => {};

  return (
    <div className="common-container">
      <Title name="ATS E-Mail Template" />
      <div className="mb-sm mt-md">
        <Button
          title="Add New Template"
          bg="secondary"
          onClick={() => {
            setIsModalShow(true);
          }}
        />
      </div>

      <Table
        data={
          props.jobStageList && props.jobStageList.length > 0
            ? props.emailTemplates
            : null
        }
        jobStageList={props.jobStageList}
        pageIndex={state.pageIndex}
        rowsPerPage={state.rowsPerPage}
        // handleRowChange={handleRowChange}
        // handleIndexChange={handleIndexChange}
        onEdit={onEdit}
        onDelete={onDelete}
      />

      <AddForm
        show={isModalShow}
        handleModal={(value) => setIsModalShow(value)}
        dataToEdit={dataToEdit}
        jobStageList={props.jobStageList}
        getEmailTemplates={getEmailTemplates}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  jobStageList: state.recruitment_dashboardReducer.jobStageList,
  emailTemplates: state.recruitment_settingReducer.emailTemplates,
});

const mapDispatchToProps = {
  GetEmailTemplates,
  GetJobStages,
  DeleteEmailTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplate);
