import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { MdAdd } from "react-icons/md";
import { FaRegFilePdf } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";
import Modal from "components/modal";
// import ReactDatePicker from "react-date-picker";
import Form from "components/form";
import { formatedShortMonthDayYear } from "helpers/dateFormat";
import { Enum_EventType } from "../../../enums";

export class DateAndDocument extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   renewalDate: null,
    //   renewedUpto: null,
    //   renewNote: "",
    //   renewDocuments: [],
    // };
  }

  // componentDidMount() {
  
  //   const {
  //     ClientId,
  //     ProjectId,
  //     ProjectName,
  //     EventEndDateEng,
  //     EventStartDateEng,
  //     EventNote,
  //     EventTypeId,
  //     ContractId,
  //     EventType,
  //   } = this.props.selectedProjectForRenew;

  //   this.setState({
  //     clientId: ClientId,
  //     projectId: ProjectId,
  //     projectName: ProjectName,
  //     expiryDate: EventEndDateEng,
  //     renewNote: EventNote,
  //     renewalDate: new Date(EventStartDateEng),
  //     renewedUpto: new Date(EventEndDateEng),
  //     eventTypeId: EventTypeId,
  //     ContractId,
  //     EventType,
  //   });
  // }

  // onSave = () => {
  //   const {
  //     projectId,
  //     renewalDate,
  //     renewedUpto,
  //     renewNote,
  //     renewDocuments,
  //     clientId,
  //     ContractId,
  //     eventTypeId,
  //   } = this.state;

  //   const data = {
  //     ProjectRefId: projectId,
  //     EventTypeId: eventTypeId ? eventTypeId : Enum_EventType.Renewal,
  //     EventTypeId: Enum_EventType.Renewal,
  //     EventStartDateEng: renewalDate,
  //     EventEndDateEng: renewedUpto,
  //     EventNote: renewNote,
  //   };
  //   if (ContractId) data.ContractId = ContractId;

  //   this.props.SaveProjectContract(
  //     data,
  //     renewDocuments,
  //     this.onModalClose,
  //     this.props.reloadList
  //   );
  // };

  // onModalClose = () => {
  //   this.props.onModalClose("showRenewModal");
  // };

  // onChangeDate = (name, date) => {
  //   this.setState({ [name]: date });
  // };

  // onTextChange = (name, value) => {
  //   this.setState({ [name]: value });
  // };

  // handleSelectedFile = (event) => {
  //   const newFile = event.target.files[0];
  //   let { renewDocuments } = this.state;
  //   renewDocuments.push({
  //     AttachmentId: renewDocuments.length + 1,
  //     file: newFile,
  //   });
  //   this.setState({ renewDocuments });
  // };

  // handleRemoveFile = (document) => {
  //   let { renewDocuments } = this.state;
  //   renewDocuments = renewDocuments.filter(
  //     (f) => f.AttachmentId !== document.AttachmentId
  //   );
  //   this.setState({ renewDocuments: renewDocuments });
  // };

  render() {
    const fileClasses = (value) => {
      return classnames({
        badges: value,
        "badges-rounded": value,
        "left-icon": value,
        inputWidth: "calc(100% - 225px)",
        "badges-default": value,
        "badges-file": value,
      });
    };

    const {
      ProjectName,
      expiryDate,
      // renewalDate,
      // renewedUpto,
      contractDate,
      ContractEndDate,
      renewNote,
      renewDocuments,
    } = this.props.data;

    return (
      <div className="renew-body">
        <div className="renew-body-section-projectName">{ProjectName}</div>
        <div className="renew-body-section">
          <span>This contract expires on</span>
          <div className="renew-body-section-expiryDate">
            {ContractEndDate
              ? formatedShortMonthDayYear(ContractEndDate)
              : "----"}
          </div>
        </div>

        <div className="renew-body-section grid">
          <Form
            formName="reactcalendar"
            name="contractDate"
            date={contractDate}
            maxDate={new Date()}
            onChange={this.props.onDateChange}
            label={"Renewal Date"}
            width={"220px"}
          />
          <Form
            formName="reactcalendar"
            name="ContractEndDate"
            date={ContractEndDate}
            onChange={this.props.onDateChange}
            label={"Renewal Upto"}
            width={"220px"}
          />
        </div>

        <div className="renew-body-section">
          <Form
            formName="textarea"
            type="text"
            name="renewNote"
            value={renewNote}
            onChange={this.props.onTextChange}
            label="Note"
          />
        </div>

        <div className="renew-body-section upload-btn-wrapper">
          {renewDocuments &&
            renewDocuments.map((document) => {
              return (
                <div className={fileClasses(true)} style={{ margin: "7px" }}>
                  <span className="document-icon">
                    <FaRegFilePdf />
                  </span>
                  <label className="document-name">{document.file.name}</label>
                  <span
                    className="document-icon cancel-icon"
                    onClick={() => this.props.handleRemoveFile(document)}
                  >
                    <AiFillCloseCircle />
                  </span>
                </div>
              );
            })}
          <button className="button with-icon button-white">
            <MdAdd />
            <span>Attach Document</span>
            <input type="file" onChange={this.props.handleSelectedFile} />
          </button>
        </div>
      </div>
    );
  }
}

export default DateAndDocument;
