import React, { useEffect, useState } from 'react'
import Sidebar from '../../common/sidebar';
import { BiChevronRight, BiLeftArrowAlt } from 'react-icons/bi'
import { FaUserFriends, FaYoutube, FaLock } from 'react-icons/fa'
import { BsClock } from 'react-icons/bs'
import './style.scss'
import { useDispatch, useSelector } from 'react-redux';
import { getCoursePages, postAnswers, getAnswers, getCourseContent, getCoursePage, getEmployeeCourse, getQuestionDetails, addCourseCompleted, getCourseCompleted } from 'scenes/lms/helpers/action';
import {
    Text,
    Image,
    Video,
    VideoLink,
    PDFS,
    Attachments
} from '../../addCourse/contents/showItem'
import { contentTypes } from 'constants/enums';
import classnames from 'classnames'
import RouteEnum from 'constants/routeEnum';
import Question from './question';
import Formgroup from 'components/formGroup';

function CoursePage(props) {
    const {id,pageId} = props.match.params;
    const {coursePages, answers:prevAnswers, coursePage,course, courseContent, questionDetails, courseCompleted} = useSelector((state:any)=>state.lmsReducer)
    const dispatch = useDispatch();
    const {user} = useSelector((state:any)=>state.auth);
    const [answers,setAnswers] = useState([]);
    const questionSettings = {
        answers,
        prevAnswers,
        setAnswers,
        coursePageId:pageId,
        courseId:id,
        employeeId:user?.EmployeeId
    }
    useEffect(()=>{
        if(id){
            (async ()=>{
                await dispatch(getEmployeeCourse(id,user?.EmployeeId))
                await dispatch(getCoursePages(id))
                await dispatch(getCourseCompleted(user?.EmployeeId,id))
            })();
        }
    },[id]);
    useEffect(()=>{
        if(pageId){
            setAnswers([])
            dispatch(getCoursePage(pageId))
            dispatch(getCourseContent(pageId))
            dispatch(getQuestionDetails(pageId))
            dispatch(getAnswers(user?.EmployeeId,pageId))
        }   
    },[pageId]);

    useEffect(()=>{
        if(courseCompleted !== null){
            const comletedCourse = courseCompleted ? courseCompleted.find(c=>c.CoursePageRefId == pageId) : null;
            const isCourseCompleted = Boolean(comletedCourse)
            const data = {
                "id": 0,
                "employeeId": user?.EmployeeId,
                "courseRefId": id,
                "coursePageRefId": pageId
            }
            if(!isCourseCompleted){
                dispatch(addCourseCompleted(data))
            }
        }

    },[pageId,courseCompleted])
    const getComponents = (item) => {
        switch (parseInt(item.Type)) {
            case contentTypes.text.id:
                return <Text {...item} />;
            case contentTypes.image.id:
                return <Image {...item} />;
            case contentTypes.video.id:
                return <Video {...item} />;
            case contentTypes.video_link.id:
                return <VideoLink {...item} />
            case contentTypes.attachment.id:
                return <Attachments {...item} />
            case contentTypes.pdf.id:
                return <PDFS {...item} />
            default:
                return null
        }
    }
    const contents = courseContent && courseContent.slice().sort((a,b)=>a?.ContentOrder - b?.ContentOrder);
    const onSubmit = async () =>{
        // QuestionType === 4
        let isAllowed = true;
        answers && answers.map(item=>{
            const question = questionDetails.find(q=>q.Id === item.QuestionRefId);
            if(question && item.QuestionType === 4){
                if(item.MultipleAnswerRefId.length < question.AnswerLimitFrom){
                    isAllowed = false;
                    setAnswers(prev=>{
                        let a = prev.map(ans=>{
                            if(ans.ids === item.ids){
                                return {
                                    ...ans,
                                    errors:`Please choose in between ${question.AnswerLimitFrom} - ${question.AnswerLimitTo} choices `
                                }
                            }
                            return ans;
                        })
                        return a;
                    })
                }
            }
        })
        if(isAllowed){
            answers && answers.map(async item=>{
                await dispatch(postAnswers(item))
            })
        }
    }
    const currentPageIndex = coursePages && coursePages.findIndex(x => x.Id ===coursePage?.Id);
    const nextPage = coursePages[currentPageIndex + 1];
    // console.log({contents})
    return (
        <div className="course-page">
            <div className="course-page__body">
                <div className="course-page__bookmark">
                    <div 
                        onClick={()=>props.history.push(RouteEnum.lmsRoutes)}
                        className="course-page__bookmark-item"><BiLeftArrowAlt /> Back To Courses</div>
                    <div className="course-page__bookmark-seperator"><BiChevronRight /></div>
                    <div className="course-page__bookmark-item">{course?.Title}</div>
                </div>
                <div className="course-page__title">
                    <h2>{coursePage?.PageName}</h2>
                    <div className="course-page__title-length">
                        <div className="course-page__title-status">
                            <BsClock />
                            <span className="bold">{course?.Duration} min</span>
                        </div>
                        <div className="course-page__title-status">
                            <FaYoutube />
                            <span className="bold">15 min</span>
                        </div>
                    </div>
                </div>
                <div className="course-page__content">
                    {
                       contents && contents.map(item=>{
                            return getComponents(item)
                        })
                    }
                </div>
                {
                        questionDetails && (
                            <div className="course-page__quiz">
                                <div className="course-page__quiz-title">Quiz</div>
                                {
                                    questionDetails.map(item=><Question {...questionSettings} question={item} />)
                                }
                                <div className="course-page__quiz-footer">
                                    <button 
                                        onClick={()=>onSubmit()}
                                        type="submit" >Submit</button>
                                </div>
                            </div>
                        )
                }
                {
                    nextPage && (
                        <div className="course-page__footer">
                            <button
                                onClick={()=> props.history.push(RouteEnum.lmsCourse + '/' + id +'/pages/' + nextPage.Id)}
                                >Next</button>
                            <span>{nextPage?.PageName}</span>
                        </div>
                    )
                }
                
            </div>
            <Sidebar coursePages={coursePages} coursePage={coursePage} />
        </div>
    )
}

export default CoursePage
