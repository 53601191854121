import React from "react";
import { FaEdit } from "react-icons/fa";
import Button from "components/button";

function HealthDetailCard(props) {
    let bloodGroup = null;
    let eContactNumber = null;
    let eContactName = null;
    let eContactRelation = null;
    let onGoingTreatment = null;
    let hospitalName = null;
    let doctorsName = null;
    let hospitalPhone = null;
    let doctorPhone = null;
    let specialiHealthCond = null;
    
    if (props.detail) {
        const { BloodGroup,
            BloodGroupName,
            DoctorName,
            DoctorNumber,
            EmergencyContactName,
            EmergencyContactNumber,
            EmergencyContactRelation,
            HospitalClinicName,
            HospitalNumber,
            OngoingTreatment,
            SpecialHealthCondition,
        } = props.detail;
        bloodGroup = BloodGroupName;
        eContactNumber = EmergencyContactNumber;
        eContactName = EmergencyContactName;
        eContactRelation = EmergencyContactRelation;
        onGoingTreatment = OngoingTreatment ? "Yes" : "No";
        hospitalName = HospitalClinicName;
        doctorsName = DoctorName;
        hospitalPhone = HospitalNumber;
        doctorPhone = DoctorNumber;
        specialiHealthCond = SpecialHealthCondition;

    }
    const changeBgColor = () => {
            if (props.isEditView)
                return 'health-detailcard health-detailcard-withBg';
            else
                return 'health-detailcard';
    }
    const showHideActionButtons = () => {
        
    }


    return (
    <div className={changeBgColor()}>
        <div className="profile-block__body">
            <div className="placement-details">
            <div className="placement-details__block">
                <div className="placement-details__table">
                <div className="placement-details__row">
                    <div className="placement-details__col placement-title">
                        Blood Group
                    </div>
                    <div className="placement-details__col placement-val">
                    {bloodGroup || '-'}
                    </div>
                </div>
                <div className="placement-details__row">
                    <div className="placement-details__col placement-title">
                    Emergency Contact Number
                    </div>
                    <div className="placement-details__col placement-val">
                    {eContactNumber || '-'}
                    </div>
                </div>
                <div className="placement-details__row">
                    <div className="placement-details__col placement-title">
                    Emergency Contact Name
                    </div>
                    <div className="placement-details__col placement-val">
                    {eContactName || 'Single'}
                    </div>
                </div>
                <div className="placement-details__row">
                    <div className="placement-details__col placement-title">
                    Emergency Contact Relation
                    </div>
                    <div className="placement-details__col placement-val">
                    {eContactRelation || 'Single'}
                    </div>
                </div>
                <div className="placement-details__row">
                    <div className="placement-details__col placement-title">
                    Special Health Condition
                    </div>
                    <div className="placement-details__col placement-val">
                        {specialiHealthCond || 'Single'}
                    </div>
                </div>
                </div>
            </div>

            <div className="placement-details__block">
                <div className="placement-details__table">
                <div className="placement-details__row">
                    <div className="placement-details__col placement-title">
                    Ongoing Treatment
                    </div>
                    <div className="placement-details__col placement-val">
                    {onGoingTreatment || '-'}
                    </div>
                </div>
                <div className="placement-details__row">
                    <div className="placement-details__col placement-title">
                    Hospital Name
                    </div>
                    <div className="placement-details__col placement-val">
                    {hospitalName || '-'}
                    </div>
                </div>
                <div className="placement-details__row">
                    <div className="placement-details__col placement-title">
                    Doctor's Name
                    </div>
                    <div className="placement-details__col placement-val">
                    {doctorsName || '-' }
                    </div>
                </div>
                <div className="placement-details__row">
                    <div className="placement-details__col placement-title">
                    Hospital Phone
                    </div>
                    <div className="placement-details__col placement-val">
                    {hospitalPhone || '-'}
                    </div>
                </div>
                <div className="placement-details__row">
                    <div className="placement-details__col placement-title">
                    Doctor's Phone
                    </div>
                    <div className="placement-details__col placement-val">
                    {doctorPhone || '-'}
                    </div>
                </div>
                </div>
            </div>
                <div className="healthCard-detail__secondary healthCard-detail__toolbars">
                    {props.isEditView ?
                    <Button
                    onClick={() => props.toggleEditView(false)}
                    bg="white"
                    >
                        Cancel
                    </Button> :
                        <span onClick={() => props.toggleEditView(true)} className="healthCard-detail__edit">
                            <FaEdit />
                        </span>
                    }
                </div>
            </div>
        
        </div>
    </div>
    );
}

export default HealthDetailCard;