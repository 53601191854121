import React from "react";
import FormGroup from "components/form";
import Button from 'components/button'

function IncomeHeadOptions({ title, onModalClose, incomeHeads,
  onIncomeHeadChecked, addIncomeHeads }) {
  console.log("income head", incomeHeads)
  return (
    <div>
      <div className="salaryTemplate-incomehead">
        {incomeHeads && incomeHeads.map((a, i) => <FormGroup
          formName="checkgroup"
          name={a.IncomeId}
          label={a.Title}
          checked={a.Abbreviation == 'BS' ? true : a.checked}
          onChange={(e) => onIncomeHeadChecked(e, a)}
          value={a.IncomeId}
          // type={""}
        />)}
      </div>
      <div className="additional-documents__footer">
        <Button
          title={'Add'}
          bg="primary"
          onClick={() => addIncomeHeads()}
        />
        <Button
          title="Cancel"
          bg="secondary-dark"
          onClick={() => onModalClose()}
        />
      </div>
    </div>
  );
}
export default IncomeHeadOptions;
