import React, { Component } from "react";
import { Tabs, Tab } from "components/tabs/index.d";
import FormGroup from "components/form";
import CommonEmployeeSearch from "components/common/employeeSearch";

export class ListingTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        { id: 1, label: "Common Parameters" },
        { id: 2, label: "Advanced Parameters" },
        { id: 3, label: "Selection by numbers" },
      ],
      searchText: "",
      activeTabId: 1,
    };
  }

  handleTabChange = (tabId) => {
    this.setState({ activeTabId: tabId });
  };

  render() {
    const { tabs, activeTabId } = this.state;
    return (
      <div className="employee-tabs">
        <Tabs
          activeTab={activeTabId}
          type="block"
          isCollapse={true}
          collapseText="FILTRATION"
        >
          {tabs.map((tab, index) => {
            return (
              <Tab
                label={tab.label}
                name={tab.id}
                id={tab.id}
                key={index}
                onTabClick={() => this.handleTabChange(tab.id)}
              >
                {tab.id === 1 ? <CommonFilter {...this.props} /> : tab.id === 2 ? <AdvanceFilter {...this.props} /> : <NumberSelectionFilter {...this.props}/>}
              </Tab>
            );
          })}
        </Tabs>
      </div>
    );
  }
}

const genders = [
  { label: "Male", value: 1 },
  { label: "Female", value: 0 },
  { label: "Others", value: 2 },
];
const marital_statuses = [
  { label: "Married", value: 'Married' },
  { label: "Single", value: 'Single' },
];
const new_joins_opt = [
  { label: "This Month", value: 1 },
  { label: "Last Month", value: 2 },
  { label: "This Year", value: 3 },
];
const retired_opt = [
  { label: "This Month", value: 1 },
  { label: "Last Month", value: 2 },
  { label: "This Year", value: 3 },
];

const state_opt = [
  { label: "Working Only", value: false },
  { label: "Retired Only", value: true },
  { label: "All", value: null },
];

const changedByOptions = [
  { label: "Changed By", value: true },
];
const changedByAmountOptions = [
  { label: "<100", value: '<100' },
  { label: ">100", value: '>100' },
];

const NumberSelectionFilter = (props) => {
  const {
    changedBy,
    changedByAmount
  } = props.parentState;
  return (
    <div className="filters-input filters">
      <div className="filters-input__item">
        <FormGroup
          formName="reactselect"
          onChange={props.handleAutoSelect}
          name="changedBy"
          // label="Changed"
          value={changedBy}
          options={changedByOptions}
          placeholder="Changed By"
          width="200px"
        />
      </div>
      <div className="filters-input__item">
        <FormGroup
          formName="reactselect"
          onChange={props.handleAutoSelect}
          name="changedByAmount"
          // label="changedByAmount"
          value={changedByAmount}
          options={changedByAmountOptions}
          placeholder="changedByAmount"
          width="200px"
        />
      </div>
    </div>
  );
};
const AdvanceFilter = (props) => {
  const {
    region
  } = props.parentState;
  return (
    <div className="filters-input filters">
      <div className="filters-input__item">
        <FormGroup
        //   formName="reactselect"
          onChange={props.handleInput}
          name="region"
          label="Region"
          value={region}
        //   options={designations}
          placeholder="Region"
          width="200px"
        />
      </div>
    </div>
  );
};

const CommonFilter = (props) => {
  const {
    searchValue,
    activeBranch,
    activeDepartment,
    activePosition,
    activeStatus,
    incomeDeduction,
  } = props.parentState;
  const { branches, departments, designations, statusList } = props;
  return (
    <div className="filters-input filters">
      <div className="filters-input__item search">
        <FormGroup
          onChange={props.handleAutoSelect}
          name="searchValue"
          label={"Employee Name"}
          value={props.searchValue}
          placeholder="Employee name"
          width="200px"
        />
      </div>
      <div className="filters-input__item">
        <FormGroup
        //   formName="reactselect"
          onChange={props.handleInput}
          name="incomeDeduction"
          label="Income or Deduction"
          value={incomeDeduction}
        //   options={designations}
          placeholder="Income or Deduction"
          width="200px"
        />
      </div>
      <div className="filters-input__item">
        <FormGroup
          formName="reactselect"
          onChange={props.handleAutoSelect}
          name="activeBranch"
          label={"Branch"}
          value={activeBranch}
          options={branches}
          placeholder="Select Branch"
          width="200px"
        />
      </div>
      <div className="filters-input__item">
        <FormGroup
          formName="reactselect"
          onChange={props.handleAutoSelect}
          name="activeDepartment"
          label="Department"
          value={activeDepartment}
          options={departments}
          placeholder="Select Department"
          width="200px"
        />
      </div>

      {/* <div className="filters-input__item">
        <FormGroup
          formName="reactselect"
          onChange={props.handleAutoSelect}
          name="activePosition"
          label="Position"
          value={activePosition}
          options={designations}
          placeholder="Select Position"
          width="200px"
        />
      </div>

      <div className="filters-input__item">
        <FormGroup
          formName="reactselect"
          onChange={props.handleAutoSelect}
          name="activeStatus"
          label="Status"
          value={activeStatus}
          options={statusList}
          placeholder="Select Status"
          width="200px"
        />
      </div> */}
    </div>
  );
};

export default ListingTabs;
