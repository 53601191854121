import React, { useEffect } from 'react'
import Button from 'components/button';
import { useState } from 'react';
import environment from 'environment';
import { useDispatch, useSelector } from 'react-redux';
import types from 'constants/types';
import { addCourseContent, updateCourseContent } from '../helpers/action';
import { FiFileText } from 'react-icons/fi';

declare let FroalaEditor:any;

function AddContent(props) {
    const [open,setOpen] = useState(false);
    const {courseContent} = useSelector((state:any) => state.lmsReducer)
    useEffect(()=>{
        if(props.pageId){
            setOpen(false)
        }
    },[props.pageId])
    return (
        <div>
            <Button 
                onClick={()=>setOpen(prev=>!prev)}
                title={courseContent ? "Update Content" : "Add Content"}
                buttonType="icon-button"
                style={{backgroundColor:'#2693FF',borderRadius:4,marginBottom:12}}
                bg="primary"  
                icon={<FiFileText />}
            />
            {
                (open) && (<Content {...props} />)
            }
        </div>
    )
}

const Content = (props) =>{
    const [editor, setEditor] = useState(null);
    const dispatch = useDispatch();
    const {courseContent} = useSelector((state:any) => state.lmsReducer)
    const {token} = useSelector((state:any) => state.auth)
    const isContent = courseContent && courseContent.length > 0;
    let imageFormData:any = null;
    useEffect(()=>{
        (function () {
            let a = new FroalaEditor("#myEditor",{

                requestHeaders: {
                    authorization: token
                },

                videoUploadParam: 'Files',
                videoUploadURL: environment.api.commonUpload,
                videoUploadMethod: 'POST',
                // Set max video size to 50MB.
                videoMaxSize: 50 * 1024 * 1024,
                // Allow to upload MP4, WEBM and OGG
                videoAllowedTypes: ['webm', 'jpg', 'ogg', 'mp4'],

                imageUploadParam: 'Files',
                // imageUploadParams: imageFormData,
                imageUploadURL: environment.api.commonUpload,
                imageUploadMethod: 'POST',

                // Set the file upload parameter.
                fileUploadParam: 'Files',
                fileUploadURL: environment.api.commonUpload,
                fileUploadMethod:  'POST',
                // Set max file size to 20MB.
                fileMaxSize: 20 * 1024 * 1024,
                fileAllowedTypes: ['*'],

                events: {
                    'image.beforeUpload': function (images) {
                      // Return false if you want to stop the image upload.
                      console.log({images})
                    },
                    'image.uploaded': function (response) {
                      // Image was uploaded to the server.
                      const res = JSON.parse(response);
                      const imageData = res.Data? res.Data[0]:res;
                    //   if(res.Data){
                    //       dispatch({type:types.ADD_COURSE_CONTENT,payload:res.Data})
                    //   }
                      const image = imageData ? imageData?.FileLocation + imageData?.ServerFileName : '';
                      var img_url = image;
                      this.image.insert(img_url, false, null, this.image.get(), response);
                      
                      return false;
                    },
                    'image.inserted': function ($img, response) {
                      // Image was inserted in the editor.
                      console.log({$img,response})
                    },
                    'image.replaced': function ($img, response) {
                      // Image was replaced in the editor.
                    },
                    'image.error': function (error, response) {
                      // Bad link.
                      console.log({error,response})
                    },
                    'video.error': function (error, response) {
                        // Bad link.
                        console.log({error,response})
                    },
                    'video.inserted': function ($img, response) {
                        // Image was inserted in the editor.
                        console.log({$img,response})
                    },
                    'video.uploaded': function (response) {
                        const res = JSON.parse(response);
                        const imageData = res.Data? res.Data[0]:res;
                        // if(res.Data){
                        //     dispatch({type:isContent ? types.ADD_COURSE_CONTENT : types.UPDATE_COURSE_CONTENT,payload:res.Data})
                        // }
                        const image = imageData ? imageData?.FileLocation + imageData?.ServerFileName : '';
                        var img_url = image;
                        
                        this.video.setSize('300px', '300px');
                        const a = this.video.insert(`<iframe width="560" height="315" src="${img_url}" frameborder="0" />`);
                        // const a = this.video.insertByURL(img_url);
                        
                        debugger
                        return false;
                      },
                      'file.error': function (error, response) {
                        // Bad link.
                        console.log({error,response})
                        },
                        'file.inserted': function ($img, response) {
                            // Image was inserted in the editor.
                            console.log({$img,response})
                        },
                        'file.uploaded': function (response) {
                            const res = JSON.parse(response);
                            const imageData = res.Data? res.Data[0]:res;
                          //   if(res.Data){
                          //       dispatch({type:types.ADD_COURSE_CONTENT,payload:res.Data})
                          //   }
                            const image = imageData ? imageData?.FileLocation + imageData?.ServerFileName : '';
                            const file = imageData ? imageData?.Attachments[0]?.UserFileName : 'File';
                            var img_url = image;
                            this.file.insert(img_url, file, response);
                            
                            return false;
                        },
                    }
                    
            })
            setEditor(a)
        })()
    },[])

    const onSave = () => {
        if(courseContent && courseContent.length > 0){
            const fd = new FormData();
            fd.append('[0].CoursePageId', courseContent[0].CoursePageId);
            fd.append('[0].Type', courseContent[0].Type);
            fd.append('[0].Id', courseContent[0].Id);
            fd.append('[0].ContentOrder', courseContent[0].ContentOrder);
            fd.append('[0].VideoDuration', courseContent[0].VideoDuration);
            fd.append('[0].VideoLink', courseContent[0].VideoLink);
            fd.append('[0].Description', editor.html.get());
            fd.append('[0].Description', editor.html.get());
            dispatch(updateCourseContent(fd))
        }else{
            const fd = new FormData();
            fd.append('[0].CoursePageId', props.pageId);
            fd.append('[0].Type', '1');
            fd.append('[0].ContentOrder', '1');
            fd.append('[0].VideoDuration', "");
            fd.append('[0].VideoLink', '');
            fd.append('[0].Attachments', null);
            dispatch(addCourseContent(fd))
        }
    }
    return(
        <div>
        <div className="mb-md">
            <textarea name="editor_content" id="myEditor">{courseContent && courseContent[0]?.Description}</textarea>
        </div>
        <Button 
            onClick={()=>onSave()}
            bg="primary"
            title="Save Content"
        />
    </div>
    )
}

export default AddContent
