import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import shortid from "shortid";
import isEmpty from "isEmpty";
import { textEditorInterface } from "ts/interfaces/formInterface";

const TextEditor = (props: textEditorInterface): JSX.Element => {
  const {
    toolbarId,
    onChange,
    placeholder,
    value,
    width,
    name,
    disabled,
  } = props;

  return (
    <div className="text-editor" style={{ width: width }}>
      <CustomToolbar toolbarId={toolbarId} />
      <ReactQuill
        defaultValue={value}
        value={value}
        onChange={(value) => {
          onChange(name, value);
        }}
        placeholder={placeholder}
        modules={TextEditor.modules(toolbarId)}
        formats={TextEditor.formats}
        theme={"snow"} // pass false to use minimal theme
        readOnly={disabled}
      />
    </div>
  );
};

/*
 * Custom "star" icon for the toolbar using an Octicon
 * https://octicons.github.io
 */
// const CustomButton = () => <span className="octicon octicon-star" />;

/*
 * Event handler to be attached using Quill toolbar module (see line 73)
 * https://quilljs.com/docs/modules/toolbar/
 */
// function insertStar() {
//   const cursorPosition = this.quill.getSelection().index;
//   this.quill.insertText(cursorPosition, "★");
//   this.quill.setSelection(cursorPosition + 1);
// }

/*
 * Custom toolbar component including insertStar button and dropdowns
 */
const CustomToolbar = ({ toolbarId }) => (
  <div id={toolbarId}>
    <select className="ql-size">
      <option value="small"></option>
      <option selected></option>
      <option value="large"></option>
      <option value="huge"></option>
    </select>
    <button className="ql-bold" />
    <button className="ql-italic" />
    <select className="ql-color">
      <option value="red" />
      <option value="green" />
      <option value="blue" />
      <option value="orange" />
      <option value="violet" />
      <option value="#d0d1d2" />
      <option selected />
    </select>
    {/* <button className="ql-insertStar">
        <CustomButton />
      </button> */}
  </div>
);

/*
 * Editor component with custom toolbar and content containers
 */

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
TextEditor.modules = (toolbarId) => ({
  toolbar: {
    container: "#" + toolbarId,
    // handlers: {
    //   insertStar: insertStar
    // }
  },
  clipboard: {
    matchVisual: false,
  },
});

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
TextEditor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
];
TextEditor.defaultProps = {
  disabled: false,
};

export default TextEditor;
