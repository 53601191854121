import React, { Component } from "react";
// import "../../../scss";
import { connect } from "react-redux";
// import { GetUnsubmittedActivities } from "../action";
import SearchAndFilter from "./searchAndFilter";
import MyLoanListingTable from "./table";
import Breadcrumb from "../../common/breadcrumb";
import Loaders from "components/loaders";
import RouteBaseClass from "components/routeBaseClass";
import { getFirstAndLastNepaliDateOfCurrentMonthTillToday } from "scenes/taskTracker/common/getDates";
import Title from "../../common/title";
import ExportExcel from "components/common/exportExcel";
import { getEmployeeAppliedLoanList } from '../helpers/action'
import '../style.scss';
export class index extends RouteBaseClass {
  constructor(props) {
    super(props, ["Employee", "Administrator"]);
    this.state = {
      isLoading: true,
      PageIndex: 0,
      PageSize: 10,
      searchEmployee: "",
      fromDate: null,
      toDate: null,
    };
  }

  // async componentDidMount() {
  //   await this.getUnsubmittedActivities();
  // }

  componentDidMount() {
    this.getEmployeeAppliedLoanList();
  }

  getEmployeeAppliedLoanList = async () => {
    const { PageIndex, PageSize } = this.state;
    const {
      user: { EmployeeId },
    } = this.props;
    await this.props.getEmployeeAppliedLoanList(EmployeeId);
    this.setState({ isLoading: false });

}

  // getUnsubmittedActivities = async (isExport = false) => {
  //   this.setState({ isLoading: true });
  //   const {
  //     searchEmployee,
  //     fromDate,
  //     toDate,
  //     PageIndex,
  //     PageSize,
  //   } = this.state;

  //   const data = {
  //     startDate: fromDate,
  //     endDate: toDate,
  //     empName: searchEmployee,
  //     PageIndex: isExport ? 0 : PageIndex,
  //     pageSize: isExport ? 999999999 : PageSize,
  //   };

  //   await this.props.GetUnsubmittedActivities(data, isExport);
  //   this.setState({ isLoading: false });
  // };

  // handleExcelExport = async () => {
  //   await this.getUnsubmittedActivities(true);
  // };

  handleRowChange = (num) => {
    this.setState({ PageSize: num }, async () => {
      await this.getEmployeeAppliedLoanList();
    });
  };

  handleIndexChange = (num) => {
    this.setState({ PageIndex: num }, async () => {
      await this.getEmployeeAppliedLoanList();
    });
  };

  handleTabChange = (tab) => {
    this.setState({ PageIndex: 0 }, async () => {
      await this.getEmployeeAppliedLoanList();
    });
  };

  handleInput = (name, value) => {
    this.setState({ [name]: value, PageIndex: 0 }, async () => {
      await this.getEmployeeAppliedLoanList();
    });
  };

  handleDateRangePicker = (name, date) => {
    const { startDate, endDate } = date;
    this.setState(
      { fromDate: startDate, toDate: endDate, PageIndex: 0 },
      async () => {
        await this.getEmployeeAppliedLoanList();
      }
    );
  };

  render() {
    const { isLoading, PageIndex, PageSize } = this.state;
    const {
      unSubmittedActivities,
      unSubmittedActivitiesExportExcel,
      employeeAppliedLoanList
    } = this.props;
    return (
      <div className="common-container homeloan-appliedlist">
        {/* <Title name="My Loan Requests" parentProps={this.props} /> */}
        <div className="tasktracker-title-section">
          {/* <Breadcrumb name="Loan Management" /> */}
        </div>

        {isLoading ? (
          <Loaders loading={isLoading} type="skeleton" name="custom-table" />
        ) : (
          <MyLoanListingTable
            data={employeeAppliedLoanList}
            pageIndex={PageIndex}
            rowsPerPage={PageSize}
            handleRowChange={this.handleRowChange}
            handleIndexChange={this.handleIndexChange}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  unSubmittedActivities: state.taskTrackerReducer.unSubmittedActivities,
  unSubmittedActivitiesExportExcel:
    state.taskTrackerReducer.unSubmittedActivitiesExportExcel,
  employeeAppliedLoanList: state.loanReducer.employeeAppliedLoanList,
  user: state.auth.user,

});

const mapDispatchToProps = {
  // GetUnsubmittedActivities,
  getEmployeeAppliedLoanList
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
