import React, {useEffect, useState} from "react";
import Breadcrumb from "scenes/common/sbiLoanBreadcrumb";
import "./index.scss";
import FormGroup from "components/formGroup";
import FormItem from "components/formGroup/formItem";
import {useForm} from "react-hook-form";
import ApproveFlowSettingTable from "./table";
import LoanLimit from "./loanLimit";
import Button from "components/button";
import {getSbiLoanTypeList, updateSbiLoanSetting} from "../helpers/action";
import {useDispatch, useSelector} from "react-redux";

const Index = ({match}) => {
  const method = useForm();
  const {setValue, reset} = method;
  const dispatch = useDispatch();

  const loanType = match.params.id;

  const [loanLimitAmount, setLoanLimitAmount] = useState([]);
  const [flowType, setFlowType] = useState(0);
  const {sbiLoanTypeList} = useSelector((state: any) => state.sbiLoanSettingReducer);

  const onSubmit = (data) => {
    const param = {
      interestRate: data.InterestRate,
      maxAllowablePaymentTerms: data.MaxAllowablePaymentTerms,
      minServicePeriod: data.MinServicePeriod,
      loanType: loanType,
    };
    dispatch(updateSbiLoanSetting(param));
  };

  useEffect(() => {
    (async () => {
      const apis = [dispatch(getSbiLoanTypeList(loanType))];
      await Promise.all(apis);
    })();
  }, [loanType]);

  useEffect(() => {
    if (sbiLoanTypeList.GeneralSetting) {
      setValue(`InterestRate`, sbiLoanTypeList.GeneralSetting.InterestRate ?? "");
      setValue(
        `MaxAllowablePaymentTerms`,
        sbiLoanTypeList.GeneralSetting.MaxAllowablePaymentTerms ?? ""
      );
      setValue(`MinServicePeriod`, sbiLoanTypeList.GeneralSetting.MinServicePeriod ?? "");
    } else {
      reset();
    }
    sbiLoanTypeList?.LoanAmountLimit &&
      setLoanLimitAmount(sbiLoanTypeList.LoanAmountLimit);

    sbiLoanTypeList?.FlowType?.FlowType && setFlowType(sbiLoanTypeList.FlowType.FlowType);
  }, [sbiLoanTypeList]);

  return (
    <div className="common-container dashboard">
      <Breadcrumb
        name={
          sbiLoanTypeList?.GeneralSetting?.LoanTypeName &&
          sbiLoanTypeList.GeneralSetting.LoanTypeName
        }
        from="Loan Setting"
      />
      <header className="dashboard__header">
        <div className="space-between panel-section">
          <h3 className="tertiary-title">
            {sbiLoanTypeList?.GeneralSetting?.LoanTypeName
              ? sbiLoanTypeList.GeneralSetting.LoanTypeName
              : "Staff Personal Loan"}
          </h3>
        </div>
      </header>
      <div className="personal-loan-block">
        <h2 className="personal-loan-block-heading">General Settings</h2>
        <FormGroup method={method} onSubmit={onSubmit}>
          <FormItem
            formName="textinput"
            width="150px"
            name="InterestRate"
            placeholder="Rate %"
            label="Interest Rate"
            type="number"
            rules={{required: "Interest Rate is required!"}}
          />
          <div className="general-setting-form">
            <FormItem
              formName="textinput"
              width="200px"
              name="MinServicePeriod"
              placeholder="Years"
              label="Minimum Service Period"
              rightSideText="Years"
              type="number"
              rules={{required: "Minimum Service Period is required!"}}
            />
            <FormItem
              formName="textinput"
              width="261px"
              name="MaxAllowablePaymentTerms"
              placeholder="Months"
              label="Maximum Allowable Payment Terms"
              rightSideText="Months"
              type="number"
              rules={{required: "Maximum Allowable Payment Terms is required!"}}
            />
          </div>
          <Button title="Save" bg="primary" htmlButtonType="submit" />
        </FormGroup>

        {flowType > 0 && (
          <ApproveFlowSettingTable loanType={loanType} flowType={flowType} />
        )}

        <hr className="seperate" />
        <h2 className="personal-loan-block-heading">Loan Amount Limit </h2>

        <div style={{display: "flex", marginBottom: "12px"}}>
          <p
            className="personal-loan-block-subHeading"
            style={{width: "300px", marginRight: "17px"}}
          >
            Level
          </p>
          <p className="personal-loan-block-subHeading" style={{width: "300px"}}>
            Amount
          </p>
        </div>

        <LoanLimit loanLimitAmount={loanLimitAmount} loanType={loanType} />
      </div>
    </div>
  );
};

export default Index;
