import Button from 'components/button';
import Modal from 'components/modal';
import React, { useState, useEffect } from 'react'
import { MdAdd } from 'react-icons/md';
import UserCard from './components/userCard';
import AddShift from './addShift';
import AssignModal from './components/assignModal'
import { useDispatch, useSelector } from 'react-redux';
import { getDatesInBetween } from '../../helpers/roosterDates'
import isEmpty from 'helpers/isEmpty';
import classnames from 'classnames';
import { getRosterScheduleList, updateRosterSchedule, updateRosterScheduleEmployee } from 'scenes/roster/helpers/action';
import { Droppable, Draggable } from "react-beautiful-dnd";
import ScheduleCopyModal from './components/scheduleCopyModal';
import { IoCheckmarkCircleOutline, IoEllipsisVertical } from 'react-icons/io5';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // change background colour if dragging
    background: isDragging ? "#2693FF" : "",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#e6f0fb' : 'transparent',
});




function ScheduleList(props) {
    const { startDate, endDate, teamId, isPrev } = props;
    const [schedules, setSchedules] = useState([])
    const { scheduleList, scheduleEmployeeList } = useSelector((state: any) => state.roosterReducer);

    useEffect(() => {
        if (scheduleList && scheduleEmployeeList) {
            if (startDate && endDate) {
                const dates = getDatesInBetween(startDate, endDate, scheduleList, scheduleEmployeeList)
                setSchedules(dates)
            }
        }
    }, [scheduleList, scheduleEmployeeList])

    return (
        <>
            
                    <div className="schedule-list" >
                        <div className="schedule-list__container">
                            {
                                !schedules || schedules.length === 0 && (
                                    <div className="schedule-no">
                                        No Schedule
                                    </div>
                                )
                            }
                            <div className={classnames("schedule-list__blocks", {
                                "schedule-list__blocks-7": schedules.length === 7,
                                "schedule-list__blocks-28": schedules.length === 28,
                                "schedule-list__blocks-29": schedules.length === 29,
                                "schedule-list__blocks-30": schedules.length === 30,
                                "schedule-list__blocks-31": schedules.length === 31
                            })}>
                                {
                                    scheduleList && schedules && schedules.map((item, key) => {
                                        return <SchduleBlock
                                            key={item.date}
                                            teamId={teamId}
                                            startDate={startDate}
                                            endDate={endDate}
                                            isPrev={isPrev}
                                            item={item}
                                        />
                                    })
                                }

                            </div>
                            
                        </div>
                    </div>
                
        </>

    )
}


const SchduleBlock = ({ item, teamId, endDate, startDate, isPrev = false }) => {
    const [open, setOpen] = useState(false);
    const onModalClose = () => setOpen(false);
    if (!teamId) {
        return null
    }
    return (
        <div className="schedule-list__blocks-item">
            <div
                
                className="schedule-list__blocks-header">
                <span>
                    {item?.dateWeek}
                </span>
                <div onClick={() => setOpen(true)} className="schedule-list__blocks-header-icon">
                    <IoEllipsisVertical />
                </div>
            </div>
            <ScheduleCopyModal
                open={open}
                onModalClose={onModalClose}
                isSingle
                teamId={teamId}
                currentDate={item?.date}
                startDate={startDate}
                endDate={endDate}
            />
            <div className="schedule-list__blocks-body">
                <div className="schedule-list__times">
                    {
                        !isEmpty(item.data2) &&
                        Object.keys(item.data2).map((duration => {
                            return <TimeBlock
                                duration={duration}
                                startDate={startDate}
                                endDate={endDate}
                                isPrev={isPrev}
                                teamId={teamId}
                                data={item} timeData={item.data2[duration] && item.data2[duration][0]} />
                        }))
                    }
                    {
                        !isPrev && <AddShiftButton
                            data={item}
                            startDate={startDate}
                            endDate={endDate}
                            teamId={teamId}
                        />
                    }

                    {/* <TimeBlock isShift={isShift} /> */}
                </div>
            </div>
        </div>
    )
}


const TimeBlock = (props) => {
    const { timeData, duration, teamId, data, startDate, endDate, isPrev = false } = props;
    const [open, setOpen] = useState(false);
    const [editData, setEditData] = useState(null);
    const [shiftOpen, setShiftOpen] = useState(false);
    const onModalClose = () => setOpen(false);
    const onModalOpen = () => setOpen(true);
    const onShiftModalClose = () => setShiftOpen(false);
    const onShiftModalOpen = () => setShiftOpen(true);

    const onEmployeeEdit = (emp) => {
        setEditData(emp)
        onShiftModalOpen()
    }
    const dispatch = useDispatch();
    const onAddAsign = async () => {
        const finalData = {
            ...data,
            shiftId: timeData?.ShiftId,
            scheduleDate: timeData?.ScheduleDate,
            teamId: teamId?.value,
            enableAllowance: timeData?.EnableAllowance,
            enableSwapping: timeData?.EnableSwapping,
            positions: timeData?.Positions ? timeData?.Positions + 1 : 1,
            id: timeData?.ShiftScheduleId
        }
        const res: any = await dispatch(updateRosterSchedule(finalData));
        if (res && res?.Status === true) {
            const param = {
                startDate: startDate,
                endDate: endDate,
                teamId: teamId ? teamId.value : null
            }
            dispatch(getRosterScheduleList(param))
        }
    }
    const onRemoveAssign = async () => {
        const finalData = {
            ...data,
            shiftId: timeData?.ShiftId,
            scheduleDate: timeData?.ScheduleDate,
            teamId: teamId?.value,
            enableAllowance: timeData?.EnableAllowance,
            enableSwapping: timeData?.EnableSwapping,
            positions: timeData?.Positions ? timeData?.Positions - 1 : 0,
            id: timeData?.ShiftScheduleId
        }
        const res: any = await dispatch(updateRosterSchedule(finalData));
        if (res && res?.Status === true) {
            setShiftOpen(false);
            const param = {
                startDate: startDate,
                endDate: endDate,
                teamId: teamId ? teamId.value : null
            }
            dispatch(getRosterScheduleList(param))
        }
    }
    const isEmpError = timeData?.positionsError && timeData?.positionsError > 0  || timeData?.remainingPosition > 0
    return (
        <Droppable droppableId={timeData?.ShiftScheduleId + "," + timeData.remainingPosition} direction="vertical" type="sections">
            {(provided, snapshot) => (
                <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    nf={provided}
                    className="schedule-list__time"
                >
                    <div
                        // onClick={() => onModalOpen()}
                        onClick={() => { !isPrev && onModalOpen() }}
                        className="schedule-list__time-header">
                        <span>{duration}</span>
                    </div>
                    {
                        timeData.totalPositions > 0 ? (
                            <div className={classnames("schedule-list__time-status",{"schedule-list__time-status-err":isEmpError})}>
                                <div className="schedule-list__time-status-total">
                                    {
                                        isEmpError ? <AiOutlineExclamationCircle /> :<IoCheckmarkCircleOutline />
                                    }
                                    {timeData.totalPositions + ' '} 
                                     total
                                </div>
                                {timeData?.remainingPosition > 0 && (
                                    <div className="schedule-list__time-status-open">
                                        <span>{timeData?.remainingPosition } </span> open
                                    </div>
                                )}
                                 {timeData?.positionsError > 0 && (
                                    <div className="schedule-list__time-status-error">
                                        <span>{timeData?.positionsError } </span> error
                                    </div>
                                )}
                            </div>
                        ) : null
                    }
                    <div className="schedule-list__time-body">
                        {
                            timeData.employees && Object.keys(timeData.employees) && Object.keys(timeData.employees).map((role) => {
                                return (

                                    <div className="schedule-list__time-block">
                                        <div className="schedule-list__time-block-heading">
                                            <span>{role}</span>
                                        </div>
                                        <div className="schedule-list__time-block-cards">
                                            {
                                                timeData.employees[role] && timeData.employees[role].map((emp, index) => {
                                                    return (
                                                        <Draggable
                                                            key={"Employee-" + emp.EmployeeId}

                                                            draggableId={
                                                                emp?.ShiftScheduleEmployeeId + "," +
                                                                emp.EmployeeId + "," +
                                                                emp?.ShiftRotationStartDay + "," +
                                                                emp?.TeamRoleId + "," +
                                                                emp?.ShiftScheduleId + "," +
                                                                timeData?.remainingPosition
                                                            }
                                                            index={index}
                                                            disableInteractiveElementBlocking="true"
                                                        >
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    ref={provided.innerRef}
                                                                // style={getItemStyle(
                                                                //     snapshot.isDragging,
                                                                //     provided.draggableProps.style
                                                                // )}
                                                                >
                                                                    <UserCard
                                                                        onClick={() => !isPrev && onEmployeeEdit(emp)}
                                                                        name={emp.EmployeeName}
                                                                        image={emp?.EmployeeImgPath}
                                                                        isDanger={!emp?.IsWorkDay}
                                                                    />

                                                                </div>

                                                            )}
                                                        </Draggable>

                                                    )
                                                })
                                            }

                                        </div>
                                        {/* <div className="schedule-list__time-block">
                                        <div className="schedule-list__time-block-heading">
                                            <span>INT</span>
                                        </div>
                                        <div className="schedule-list__time-block-cards">
                                            <UserCard name="Shankar Aryal" />
                                            <UserCard name="Shankar Aryal" />
                                            <UserCard name="Shankar Aryal" />
                                            <UserCard onClick={()=>onShiftModalOpen()} isOpen name="Assign" />
                                            <UserCard isJustIcon />
                                        </div>
                                    </div> */}
                                    </div>
                                )
                            })
                        }
                        {
                            timeData && timeData?.remainingPosition && timeData?.remainingPosition > 0 ? [...Array(timeData?.remainingPosition)].map((i, e) => {
                                return (
                                    <div className="schedule-list__time-block">
                                        <div className="schedule-list__time-block-cards">
                                            <UserCard onClick={() => !isPrev && onShiftModalOpen()} isOpen name="Assign" />
                                        </div>
                                    </div>
                                )
                            }) : null
                        }
                        {
                            !isPrev && (
                                <div className="schedule-list__btn">
                                    <Button
                                        icon={<MdAdd />}
                                        buttonType="icon-button"
                                        onClick={() => onAddAsign()}
                                        bg="secondary-dark"
                                    />
                                </div>
                            )
                        }

                    </div>

                    <AssignModal
                        shiftOpen={shiftOpen}
                        duration={duration}
                        onShiftModalClose={onShiftModalClose}
                        timeData={timeData}
                        startDate={startDate}
                        endDate={endDate}
                        onRemoveAssign={onRemoveAssign}
                        editData={editData}
                    />
                    <AddShift
                        open={open}
                        onModalClose={onModalClose}
                        teamId={teamId}
                        date={data.date}
                        endDate={endDate}
                        startDate={startDate}
                        timeData={timeData}
                        isEdit={true}
                    />
                </div>
            )}
        </Droppable>
    )
}


const AddShiftButton = (props) => {
    const { timeData, teamId, data, startDate, endDate } = props;
    const [open, setOpen] = useState(false);
    const onModalClose = () => setOpen(false);
    const onModalOpen = () => setOpen(true);
    return (
        <>
            <div className="schedule-list__assign flex" style={{justifyContent:'center'}}>
                <Button
                    onClick={() => onModalOpen()}
                    bg="primary"
                    buttonType="icon-button"
                    icon={<MdAdd />}
                    title="Add Shift"
                />
            </div>
            <AddShift
                open={open}
                onModalClose={onModalClose}
                teamId={teamId}
                date={data.date}
                data={data}
                endDate={endDate}
                startDate={startDate}
                timeData={timeData}
                isEdit={false}
            />
        </>
    )
}


export default ScheduleList
