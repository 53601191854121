import React, { Component } from "react";
import Tabs from "../../../components/tabs/tabs";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Table from "./table";
import Filters from "./filters";
import { getPayTypeVisibility, searchCalcList } from "./action";
import "./style.scss";
import moment from "moment";
import Sidetab from "./sideTabs";
import isEmpty from "isEmpty";

const getDate = (name) => {
  var date = new Date();
  var firstDay = null;
  var lastDay = null;
  const label = !isEmpty(name) ? name.label : "";
  if (label === "This Month") {
    firstDay = moment(date).startOf("month").format("YYYY/MM/DD");
    lastDay = moment(date).endOf("month").format("YYYY/MM/DD");
  }
  if (label === "Last Month") {
    firstDay = moment(date)
      .subtract(1, "months")
      .startOf("month")
      .format("YYYY/MM/DD");
    lastDay = moment(date)
      .subtract(1, "months")
      .endOf("month")
      .format("YYYY/MM/DD");
  }
  if (label === "This Year") {
    firstDay = moment().startOf("year").format("YYYY/MM/DD");
    lastDay = moment().endOf("year").format("YYYY/MM/DD");
  }
  return { firstDay, lastDay };
};

export class Income_Calculation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leftValue: "",
      searchValue: "",
      activeBranch: "",
      activeDepartment: "",
      activePosition: "",
      activeStatus: "",
      income_deduct: "",
      position: "",
      region: "",
      approval_state: "",
      leave_type: "",
      rowsPerPage: 10,
      pageIndex: 0,
      actTab: "",
      currentStep: 1,
    };
    this._renderTable = this._renderTable.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleRowChange = this.handleRowChange.bind(this);
    this.handleIndexChange = this.handleIndexChange.bind(this);
  }
  componentDidMount() {
    const { selectedPayPeriod } = this.props.selectReducer;
 
    if (!isEmpty(selectedPayPeriod)) {
  
      this.props.getPayTypeVisibility(selectedPayPeriod.Value);
    }
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  onFilter = async () => {
    this.setState({ isLoading: true });

    const {
      pageIndex,
      rowsPerPage,
      searchValue,
      activeBranch,
      activeDepartment,
      activePosition,
      activeStatus,
      gender,
      maritalStatus,
      newJoinees,
      state,
      retired,
    } = this.state;
    const { firstDay: fromDate, lastDay: endDate } = getDate(newJoinees);
    const {
      firstDay: retirementStartDate,
      lastDay: retirementEndDate,
    } = getDate(retired);


    await this.props.searchCalcList({
      pageIndex,
      rowsPerPage,
      searchValue,
      gender,
      maritalStatus,
      activeBranch,
      state,
      fromDate,
      endDate,
      retirementStartDate,
      retirementEndDate,
      activeDepartment,
      activePosition,
      activeStatus,
    });

    this.setState({ isLoading: false });
  };

  handleInput = (e) => {
    const { name, value } = e.target;
    
    this.setState({ [name]: value, pageIndex: 0 }, () => {
      this.onFilter();
    });
  };

  handleAutoSelect = (name, selected) => {
    this.setState({ [name]: selected, pageIndex: 0 }, () => {
      this.onFilter();
    });
  };

  handleRowChange = (num) => {
    this.setState({ rowsPerPage: num }, () => {
      this.onFilter();
    });
  };

  handleIndexChange = (num) => {
    this.setState({ pageIndex: num }, () => {
      this.onFilter();
    });
  };
  // handleRowChange(num) {
  //     this.setState({ rowsPerPage: num });
  // }
  // handleIndexChange(num) {
  //     this.setState({ pageIndex: num });
  // }
  componentDidUpdate = (prevProps, prevState) => {
    const { selectedPayPeriod } = this.props.selectReducer;
    if (!isEmpty(selectedPayPeriod)) {
      if (selectedPayPeriod !== prevProps.selectReducer.selectedPayPeriod) {
        this.props.getPayTypeVisibility(selectedPayPeriod.Value);
      }
      const { payTypeVisibility } = this.props.incomeReducer;
      if (
        payTypeVisibility.value !==
        prevProps.incomeReducer.payTypeVisibility.value
      ) {
        if (!isEmpty(payTypeVisibility.value)) {
          const {
            IsCombined,
            IsRegular,
            IsAdjustment,
            IsRetrospect,
          } = payTypeVisibility.value;
          if (!IsCombined && !IsRegular && !IsAdjustment && !IsRetrospect) {
            this.setState({ actTab: "" });
          }
        }
      }
    }
  };
  changeSideTab = (currentStep) => {
    this.setState({ currentStep });
  };

  _renderTable() {
    const { payTypeVisibility } = this.props.incomeReducer;
    const {
      IsCombined,
      IsRegular,
      IsAdjustment,
      IsRetrospect,
    } = payTypeVisibility.value;
    // console.log(this.props)
   
    const { currentStep } = this.state
    return (
      <div className="payroll-tax">
        <div className="tax-adj-tabs mt-sm">
          <Sidetab
            currentStep={currentStep}
            onStepChange={(step) => this.changeSideTab(step)}
            payTypeVisibility={payTypeVisibility}
          />
        </div>
        <Table
          pageIndex={this.state.pageIndex}
          rowsPerPage={this.state.rowsPerPage}
          handleRowChange={this.handleRowChange}
          handleIndexChange={this.handleIndexChange}
        />
      </div>
    );
  }
  // _renderTable() {
  //     const { payTypeVisibility } = this.props.incomeReducer;
  //     const {
  //         IsCombined,
  //         IsRegular,
  //         IsAdjustment,
  //         IsRetrospect
  //     } = payTypeVisibility.value;
  //     // console.log(this.props)
  //    
  //     if (!IsCombined && !IsRegular && !IsAdjustment && !IsRetrospect) {
  //         return (

  //             <Table
  //                 pageIndex={this.state.pageIndex}
  //                 rowsPerPage={this.state.rowsPerPage}
  //                 handleRowChange={this.handleRowChange}
  //                 handleIndexChange={this.handleIndexChange}
  //             />
  //         );
  //     } else {
  //         return (
  //             <Tabs>
  //                 {IsCombined ? (
  //                     <div
  //                         onClick={() => this.setState({ actTab: "total" })}
  //                         label="Total"
  //                         name="Total"
  //                     >
  //                         <Table
  //                             pageIndex={this.state.pageIndex}
  //                             rowsPerPage={this.state.rowsPerPage}
  //                             handleRowChange={this.handleRowChange}
  //                             handleIndexChange={this.handleIndexChange}
  //                         />
  //                     </div>
  //                 ) : null}
  //                 {IsRegular ? (
  //                     <div
  //                         onClick={() => this.setState({ actTab: "regular" })}
  //                         label="Regular"
  //                         name="Regular"
  //                     >
  //                         <Table
  //                             pageIndex={this.state.pageIndex}
  //                             rowsPerPage={this.state.rowsPerPage}
  //                             handleRowChange={this.handleRowChange}
  //                             handleIndexChange={this.handleIndexChange}
  //                         />
  //                     </div>
  //                 ) : null}
  //                 {IsAdjustment ? (
  //                     <div
  //                         onClick={() =>
  //                             this.setState({ actTab: "adjustment" })
  //                         }
  //                         label="Adjustment"
  //                         name="Adjustment"
  //                     >
  //                         <Table
  //                             pageIndex={this.state.pageIndex}
  //                             rowsPerPage={this.state.rowsPerPage}
  //                             handleRowChange={this.handleRowChange}
  //                             handleIndexChange={this.handleIndexChange}
  //                         />
  //                     </div>
  //                 ) : null}
  //                 {IsRetrospect ? (
  //                     <div
  //                         onClick={() => this.setState({ actTab: "arrears" })}
  //                         name="Arrears"
  //                         label="Arrears"
  //                     >
  //                         <Table
  //                             pageIndex={this.state.pageIndex}
  //                             rowsPerPage={this.state.rowsPerPage}
  //                             handleRowChange={this.handleRowChange}
  //                             handleIndexChange={this.handleIndexChange}
  //                         />
  //                     </div>
  //                 ) : null}
  //             </Tabs>
  //         );
  //     }
  // }
  render() {
    const {
      employeeListForAdmin,
      branches,
      searchValue,
      departments,
      designations,
      statusList,
      history,
    } = this.props;

    return (
      <div className="payroll-body income">
        <Filters
          // activeTab={this.state.actTab}
          // pageIndex={this.state.pageIndex}
          // handleRowChange={this.handleRowChange}
          // handleIndexChange={this.handleIndexChange}
          // rowsPerPage={this.state.rowsPerPage}
          parentState={this.state}
          branches={branches}
          searchValue={searchValue}
          departments={departments}
          designations={designations}
          statusList={statusList}
          handleAutoSelect={this.handleAutoSelect}
          handleInput={this.handleInput}
          onFilter={this.onFilter}
        />
        <div className="income-tabs">{this._renderTable()}</div>
      </div>
    );
  }
}

Income_Calculation.propTypes = {
  // getCalcHeader: PropTypes.func.isRequired,
  getPayTypeVisibility: PropTypes.func.isRequired,
  incomeReducer: PropTypes.object.isRequired,
  selectReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  incomeReducer: state.incomeReducer,
  selectReducer: state.selectReducer,
  employeeListForAdmin: state.admin_EmployeeListReducer.employeeListForAdmin,
  branches: state.commonReducer.branchList,
  departments: state.commonReducer.departmentList,
  designations: state.commonReducer.designationList,
  statusList: state.commonReducer.jobStatusList,
});

export default connect(mapStateToProps, { getPayTypeVisibility, searchCalcList })(
  Income_Calculation
);
