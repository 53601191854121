import React from "react";
import classnames from "classnames";

export const Sidetab = (props) => {
  const { currentStep, onStepChange, payTypeVisibility } = props;
  const {
    IsCombined,
    IsRegular,
    IsAdjustment,
    IsRetrospect,
  } = payTypeVisibility && payTypeVisibility.value;
  let tabContent = [
    // {
    //   num: 1,
    //   title: "Total",
    // },
    // {
    //   num: 2,
    //   title: "Regular",
    // },
    // {
    //   num: 3,
    //   title: "Adjustment",
    // },
    // {
    //   num: 4,
    //   title: "Arrear",
    // },
  ];
  if (IsCombined) tabContent.push({ num: 1, title: "Total" });
  if (IsRegular) tabContent.push({ num: 2, title: "Regular" });
  if (IsAdjustment) tabContent.push({ num: 3, title: "Adjustment" });
  if (IsRetrospect) tabContent.push({ num: 4, title: "Arrear" });
  return (
    <div className="recruit-header">
      <div className="flex">
        {tabContent.map((item) => (
          <Tab
            tab={item}
            onStepChange={onStepChange}
            currentStep={currentStep}
            //   isAt={isAt}
          />
        ))}
      </div>
      <div className="button-group button-group__right flex">
        <button className="btn btn-grey-2">Export</button>
      </div>
    </div>
  );
};

export const Tab = ({ tab, currentStep, onStepChange, isAt }) => {
  const classes = classnames({
    "recruit-header-item": true,
    // disabled: tab.num > isAt,
    selected: tab.num === currentStep,
  });
  return (
    <a
      key={tab.num}
      className={classes}
      disabled={true}
      onClick={() => onStepChange(tab.num)}
    >
      <div className="recruit-header-title">{tab.title}</div>
      {/* <div className="recruit-header-body">
        <p>{tab.details}</p>
      </div> */}
    </a>
  );
};

export default Sidetab;