import React, { useState } from "react";
import Button from "components/button";
import Modal from "components/modal";
import { NavLink, Link } from "react-router-dom";
import { BsFillCaretDownFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import SvgIcons from "components/svgIcons";
import SvgIcon from "components//icons/svgIcons";
import { Features } from "helpers/getPermissions";
import { isFeatureEnabledValue } from "helpers/checkPermissions";
import OvertimeRequestForm from "scenes/employee/overtimeRequest";
import AllowanceRequestForm from "scenes/employee/allowance/allowanceRequest";
import TimeRequestForm from "scenes/employee/time/timeRequest";
import LeaveRequestForm from "scenes/employee/leaves/leaveRequest/form";
import { useSelector } from "react-redux";
import CreateNewTravelRequest from "scenes/travel/common/travelInformation/createTravelRequest/createNewTravelRequest";
import RouteEnum from "constants/routeEnum";

function CommonRequestButton(props) {
  const { isHRorFinance } = useSelector((state: any) => state.auth);

  const features = Features();

  return (
    <>
      <Button
        buttonType="drop-button"
        title={!props.isAssign ? props.buttonTitle : ""}
        customDropIcon={<BsFillCaretDownFill style={{ fontSize: 15 }} />}
        justDrop
        bg={props.isAssign ? "primary" : "success"}
        dropComponent={
          <div className="request-drop">
            <ul className="request-drop__list">
              {(!props.isAssign ||
                (props.isAssign && props.isLeaveTimeReviewerOrApprover)) && (
                  <>
                    {isFeatureEnabledValue(features.CoreHR) ? (
                      <NavLink
                        // to={() =>
                        //   REACT_APP_IS_LEAVE_OLD_URL === "true"
                        //     ? window.open(getLink("leave"))
                        //     : showLeaveRequestForm(true)
                        // }
                        to={(RouteEnum.CreateHomeLoanPath)}
                        className="request-drop__item"
                      >
                        <div className="request-drop__item-name">
                          <span>
                            Staff Home Loan
                          </span>
                        </div>
                      </NavLink>
                    ) : null}
                    {isFeatureEnabledValue(features.CoreHR) ? (
                      <NavLink
                        // to={() => {
                        //   REACT_APP_IS_TIME_OLD_URL === "true"
                        //     ? window.open(getLink("time"))
                        //     : setShowTimeRequestForm(true);
                        // }}
                        to={(RouteEnum.CreateVehicleLoanPath)}
                        className="request-drop__item"
                      >
                        <div className="request-drop__item-name">
                          <span>
                            Staff Vehicle Loan
                          </span>
                        </div>
                      </NavLink>
                    ) : null}
                  </>
                )}
              <>
                {isFeatureEnabledValue(features.CoreHR) ? (
                  <NavLink
                    className="request-drop__item"
                    to={(RouteEnum.CreateStaffODLoanPath)}
                  // to={() => {
                  //   REACT_APP_IS_ALLOWANCE_OLD_URL === "true"
                  //     ? window.open(getLink("allowance"))
                  //     : showAllowanceRequestForm(true);
                  // }}
                  >
                    <div className="request-drop__item-name">
                      <span>
                        Staff OverDraft/Renewal
                      </span>
                    </div>
                  </NavLink>
                ) : null}
                {isFeatureEnabledValue(features.CoreHR) ? (
                  <NavLink
                    className="request-drop__item"
                    to={{
                      pathname: RouteEnum.CreateStaffPersonalLoanPath,
                      state: {
                        data: '1'
                      }
                    }}
                    // to={(RouteEnum.CreateStaffPersonalLoanPath)}
                  // to={() => {
                  //   REACT_APP_IS_OVERTIME_OLD_URL === "true"
                  //     ? window.open(getLink("overtime"))
                  //     : setShowOvertimeRequestForm(true);
                  // }}
                  >
                    <div className="request-drop__item-name">
                      <span>
                        Staff Personal Loan
                      </span>
                    </div>
                  </NavLink>
                ) : null}
                {isFeatureEnabledValue(features.CoreHR) ? (
                  <NavLink
                    className="request-drop__item"
                    to={{
                      pathname: RouteEnum.CreateStaffAdvanceLoanPath,
                      state: {
                        data: '2'
                      }
                    }}
                  // to={(RouteEnum.CreateStaffPersonalLoanPath)}
                  // to={() => {
                  //   REACT_APP_IS_OVERTIME_OLD_URL === "true"
                  //     ? window.open(getLink("overtime"))
                  //     : setShowOvertimeRequestForm(true);
                  // }}
                  >
                    <div className="request-drop__item-name">
                      <span>
                        Staff Advance Salary
                      </span>
                    </div>
                  </NavLink>
                ) : null}
              </>
              <NavLink
                // to={(RouteEnum.AdminLoanManagement)}
                to={(RouteEnum.EmployeeLoanList)}
                className="request-drop__item"
              >
                <div className="request-drop__item-name">
                  <span>My Loan Requests</span>
                </div>
              </NavLink>
              {isHRorFinance && <NavLink
                to={(RouteEnum.LoanManagement)}
                // to={() => window.open(getLink("employee"))}
                className="request-drop__item"
              >
                <div className="request-drop__item-name">
                  <span>Loan Management</span>
                </div>
              </NavLink>}
            </ul>
          </div>
        }
      >
      </Button>
    </>
  );
}

export default CommonRequestButton;
