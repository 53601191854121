import React, { useState, useRef } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { otherTimePickerInterface } from "ts/interfaces/formInterface";

function TimePicker(props: otherTimePickerInterface) {
  const hrsInputRef = useRef(null);
  const minInputRef = useRef(null);

  const { name, value, defaultMeridiem, disabled } = props;

  let initialHrs = null;
  let initialMin = null;
  let initialMeridiem = defaultMeridiem;
  // let initialTime = null;

  if (value) {
    let [time, modifier] = value.split(" ");
    let [hours, minutes] = time.split(":");
    if (hours > 12) {
      hours = hours % 12;
      modifier = "pm";
    } else if (hours == 12) {
      // hours = 0;
      modifier = "pm";
    } else modifier = "am";
    initialHrs = hours;
    initialMin = minutes;
    initialMeridiem = modifier;
    // initialTime = `${hours}:${minutes} ${modifier.toLowerCase()}`;
  }

  const [hrs, setHrs] = useState(initialHrs);
  const [min, setMin] = useState(initialMin);
  const [meridiem, setMeridiem] = useState(initialMeridiem);
  const [finalTime, setFinalTime] = useState(null);

  const handleHoursChange = (e) => {
    const { name, value } = e.target;
    if (value.length > 2 || (value && parseInt(value) > 12)) {
      return false;
    }
    setHrs(value);

    handleTimeChange(value, min, meridiem);
  };

  const handleMinuteChange = (e) => {
    const { name, value } = e.target;
    if (value.length > 2 || (value && parseInt(value) > 59)) {
      return false;
    }
    setMin(value);

    handleTimeChange(hrs, value, meridiem);
  };

  const handleMeridiemChange = (value) => {
    if ((!hrs && !min) || !hrs) {
      hrsInputRef.current.focus();
      return null;
    } else if (!min) {
      minInputRef.current.focus();
      return null;
    } else {
      setMeridiem(value);
      handleTimeChange(hrs, min, value);
    }
  };

  const handleTimeChange = (finalHrs, finalMin, meridiem) => {
    let ft = null;
    if (finalHrs && finalMin && meridiem) {
      ft = finalHrs + ":" + finalMin + " " + meridiem.toLowerCase();
    }
    setFinalTime(ft);
    props.onChange && props.onChange(name, ft);
  };

  return (
    <div className="genericForm-group__custom-time">
      <input
        ref={hrsInputRef}
        className="genericForm-group__custom-time-box"
        type="number"
        placeholder={"-  -"}
        value={hrs}
        name={"hrs"}
        disabled={disabled}
        onChange={handleHoursChange}
      />
      <span className="genericForm-group__custom-time-colon">:</span>
      <input
        ref={minInputRef}
        className="genericForm-group__custom-time-box"
        type="number"
        placeholder={"-  -"}
        value={min}
        name={"min"}
        disabled={disabled}
        onChange={handleMinuteChange}
      />
      <span className="genericForm-group__custom-time-meridiem">
        <button
          className={`genericForm-group__custom-time-meridiem-name " + ${
            meridiem == "am" ? "active" : ""
          }`}
          onClick={() => handleMeridiemChange("am")}
        >
          AM
        </button>
        <button
          className={`genericForm-group__custom-time-meridiem-name " + ${
            meridiem == "pm" ? "active" : ""
          }`}
          onClick={() => handleMeridiemChange("pm")}
        >
          PM
        </button>
      </span>
    </div>
  );
}

TimePicker.defaultProps = {
  time: null,
  disabled: false,
  defaultMeridiem: null,
};

export default TimePicker;
