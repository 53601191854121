import React, { useState, useRef, useEffect } from "react";
import ReactQuill, { Quill } from "react-quill";
import * as Emoji from "quill-emoji";
import "quill-mention";
import useOutsideClick from "helpers/customHooks/outsideClickHook";
import CustomToolbar from "./customToolbar";
import isEmpty from "isEmpty";
import classNames from "classnames";

Quill.register("modules/emoji", Emoji);

let selectedMentions: any = [];
let isOpenMention = false;
const setSelectedMentions = (mentions) => {
  selectedMentions = mentions;
};
let mentions = [];
const setMentions = (items) => {
  mentions = items;
};

const mentionModuleConfig = {
  allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
  mentionDenotationChars: ["@"],
  source: (searchTerm, renderList, mentionChar) => {
    let values;

    if (mentionChar === "@") {
      let ids = selectedMentions.map((x) => parseInt(x.id));
      values = mentions.filter((x) => !ids.includes(x.id));
    }

    if (searchTerm.length === 0) {
      renderList(values, searchTerm);
    } else {
      const matches = [];
      for (let i = 0; i < values.length; i++)
        if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
          matches.push(values[i]);
      renderList(matches, searchTerm);
    }
  },
  // offsetTop: 10,
  // defaultMenuOrientation: "bottom",
  onSelect: (item, insertItem) => {
    if (selectedMentions.filter((x) => x.id === item.id).length === 0) {
      let newItems = [...selectedMentions];
      newItems = [...selectedMentions, item];
      setSelectedMentions(newItems);
      insertItem(item);
    }
    // props.onSelectedMentions && props.onSelectedMentions(newItems);
  },
  onOpen: () => {
    isOpenMention = true;
  },
  onClose: () => {
    isOpenMention = false;
  },
};

var bindings = {
  // This will overwrite the default binding also named 'tab'
  tab: {
    key: 13,
    handler: function () {
      // Handle tab
    },
  },
};
const modules = {
  toolbar: {
    container: "#toolbar",
  },
  mention: mentionModuleConfig,
  keyboard: {
    bindings: bindings,
  },
};

const CommentEditor = (props) => {
  const { alwaysShow, enableCancelButton } = props;
  const [showTools, setShowTools] = useState(false);
  const ref = useRef<any>(null);
  const quilRef = useRef<any>(null);
  // const [selctedMentions, setSelectedMentions] = useState([]);
  let lockEnter = true;
  useEffect(() => {
    setMentions(props.mentions);
  }, [props.mentions]);

  useEffect(() => {
    if (quilRef && quilRef.current && !isEmpty(props.initialValue)) {
      let editor = quilRef.current.getEditor();
      editor.root.innerHTML = props.initialValue;
    }
  }, [props.initialValue]);

  useOutsideClick(ref, () => {
    if (isEmpty(props.value)) handleShowTools(false);
  });

  const handleShowTools = (val) => {
    if (!alwaysShow) {
      setShowTools(val);
      props.setShowTools && props.setShowTools(val);
    }
  };
  const openMention = function () {
    let editor = quilRef.current.getEditor();
    let mentionModule = editor.getModule("mention");
    mentionModule.openMenu("@");
  };

  const insertEmoji = function (item) {
    let editor = quilRef.current.getEditor();
    const value = `<span>${item.code_decimal}</span>`;
    let pars = editor.root.getElementsByTagName("p");
    pars[pars.length - 1].innerHTML += value;
  };

  const handleChange = (content, delta, source, editor) => {
    let quillEditor = quilRef.current.getEditor();
    let mentionModule = quillEditor.getModule("mention");

    console.log("CHANGE--->", isOpenMention, "mentionModule", mentionModule);
    // if (lockEnter) return;

    let text = "";
    if (content !== "<p><br></p>") text = content;
    props.onChange && props.onChange("name", text);
    let contents = editor.getContents();
    let mentions = [];

    contents.forEach((x) => {
      const mention = x.insert.mention;
      if (mention) {
        mentions.push(mention);
      }
    });

    setSelectedMentions(mentions);
    props.onSelectedMentions && props.onSelectedMentions(mentions);
  };
  const handleKeyUp = (e) => {
    // if (mentionModule.isOpen) setLockEnter(true);
    // if(lockEnter && mentionModule.isOpen )
    console.log(
      "key up--->",
      isOpenMention,
      "lockEnter",
      lockEnter,
      "key",
      e.key
    );
    if (e.key === "Enter") {
      !lockEnter && props.onEnterPressed && props.onEnterPressed(props.value);
      lockEnter = false;
    }
  };
  const handleKeyDown = (e) => {
    let quillEditor = quilRef.current.getEditor();
    let mentionModule = quillEditor.getModule("mention");

    if (e.key === "Enter") {
    }
    // let quillEditor = quilRef.current.getEditor();
    // let mentionModule = quillEditor.getModule("mention");
    // lockEnter = mentionModule.isOpen;
    // console.log("key down--->", "lockEnter", lockEnter, "key", e.key);
  };
  return (
    <div
      className={classNames({ "flex-editor": !enableCancelButton })}
      ref={ref}
    >
      <ReactQuill
        value={props.value}
        ref={quilRef}
        // value={value}
        onChange={handleChange}
        modules={modules}
        placeholder={props.placeHolder}
        onFocus={(e) => {
          handleShowTools(true);
        }}
        onKeyUp={handleKeyUp}
        onKeyDown={handleKeyDown}
      />

      <CustomToolbar
        value={props.value}
        showTools={true}
        enableCancelButton={enableCancelButton}
        onCancelClicked={() => {
          props.onCancelClicked && props.onCancelClicked();
        }}
        setShowTools={handleShowTools}
        onMentionClicked={openMention}
        onEmojiClicked={insertEmoji}
        onSubmit={(val) => props.handleSubmit && props.handleSubmit(val)}
      />
    </div>
  );
};
CommentEditor.defaultProps = {
  enableCancelButton: false,
};
export default CommentEditor;
