import isEmpty from "isEmpty";
export default function validateDisciplinaryActionForm(values) {
  let errors = {};

  const disciplinaryActionValidateField = [
    "ActionId",
    "ActionDate",
    "ActionBy",
    "ActionNote",
    "ActionType"
  ];
  disciplinaryActionValidateField.map(x => {
    if (x === "ActionDate") {
      if (values[x] === null) {
        errors[x] = "Required";
      }
    } else if (isEmpty(values[x])) {
      errors[x] = "Required";
    }
  });
  return errors;
}
