import man from "assets/images/man.png";
import classnames from "classnames";
import FormGroup from "components/form";
import { getBaseAxios } from "helpers/constants";
import isEmpty from "isEmpty";
import React, { Component } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { connect } from "react-redux";
import Avatar from "react-avatar";
import {getUploadPath} from 'constants/userDetails'

const BaseUrl = getBaseAxios() + "/";

export class Create_GroupTab extends Component {
  handleDropdown = (name, selected) => {
    let { selectedTravellerList } = this.props.groupState;

    let getExistingTraveller = selectedTravellerList.find(
      (f) => f.value == selected?.value
    );

    if (isEmpty(getExistingTraveller)&& !isEmpty(selected)) {
      selectedTravellerList.push(selected);

      this.props.handleGroupTabState &&
        this.props.handleGroupTabState(name, selectedTravellerList);
    }
  };

  handleRemove = (value) => {
    let { selectedTravellerList } = this.props.groupState;
    selectedTravellerList = selectedTravellerList.filter(
      (f) => f.value != value
    );
    this.props.handleGroupTabState &&
      this.props.handleGroupTabState(
        "selectedTravellerList",
        selectedTravellerList
      );
  };

  handleGroupChange = (groupTypeId) => {
    this.props.handleGroupTabState &&
      this.props.handleGroupTabState("groupTypeId", groupTypeId);
  };

  render() {
    const {
      selectedTravellerList,
      groupTypeId,
      requesterInformation,
    } = this.props.groupState;
    const {
      employeeListWithPhoto,
      myDetail,
      isViewMode,
      isEditPage,
    } = this.props;
    let doShow = isViewMode || isEditPage;
    const groupClasses = (type) => {
      return classnames({
        badges: true,
        "badges-rounded": true,
        "left-icon": groupTypeId == type,
        "badges-primary": groupTypeId == type,
      });
    };
    return (
      <div className="travel-group-create">
        <div className="travel-create__title">
          <h3>Group</h3>
        </div>
        <div className="travel-create__body">
          <div className="badges-group">
            <div
              className={groupClasses(1)}
              onClick={() => (isViewMode ? null : this.handleGroupChange(1))}
            >
              {groupTypeId == 1 ? <IoIosCheckmarkCircle /> : null}
              Only me
            </div>

            <div
              className={groupClasses(2)}
              onClick={() => (isViewMode ? null : this.handleGroupChange(2))}
            >
              {groupTypeId == 2 ? <IoIosCheckmarkCircle /> : null}
              Group
            </div>
          </div>
          <div className="travel-field">
            {groupTypeId == 2 && (
              <FormGroup
                formName="reactselect"
                name="selectedTravellerList"
                placeholder="Select travelers"
                value={{}}
                options={employeeListWithPhoto}
                onChange={this.handleDropdown}
                width="250px"
              />
            )}
            <br />
            {groupTypeId == 2 &&
              selectedTravellerList &&
              selectedTravellerList.map((traveller, index) => {
                return (
                  <div className="selected-user" key={index}>
                    <Avatar
                      alt="Avatar"
                      src={
                        !isEmpty(traveller?.Imagepath)
                          ? getUploadPath(traveller?.Imagepath)
                          : man
                      }
                      className="emp-image"
                      round
                      size={40}
                    />
                    {/* <img
                      src={
                        !isEmpty(traveller.Imagepath)
                          ? BaseUrl + traveller.Imagepath
                          : man
                      }
                      className="avatar avatar-sm"
                      alt="Avatar"
                    /> */}
                    <h5 className="selected-user__name">{traveller?.label}</h5>
                    {isViewMode ? null : (
                      <span onClick={() => this.handleRemove(traveller?.value)}>
                        <AiFillCloseCircle />
                      </span>
                    )}
                  </div>
                );
              })}

            {groupTypeId == 1 &&
              (!isEmpty(requesterInformation) || !isEmpty(myDetail)) &&
              requesterDetail(
                !isEmpty(requesterInformation) ? requesterInformation : myDetail
              )}
          </div>
        </div>
      </div>
    );
  }
}

const requesterDetail = (data) => {
  return (
    <div className="selected-user">
      <Avatar
        alt="Avatar"
        src={!isEmpty(data.Imagepath) ?getUploadPath(data.Imagepath) : man}
        className="emp-image"
        round
        size={40}
      />
      {/* <img
        src={!isEmpty(data.Imagepath) ? BaseUrl + data.Imagepath : man}
        className="avatar avatar-sm"
        alt="Avatar"
      /> */}
      <h5 className="selected-user__name">{data.label}</h5>
    </div>
  );
};
const mapStateToProps = (state) => ({
  employeeList: state.commonReducer.employeeList,
  employeeListWithPhoto: state.commonReducer.employeeListWithPhoto,
  myDetail: state.commonReducer.myDetail,
});

export default connect(mapStateToProps, null)(Create_GroupTab);
