import React, { Component } from "react";
import { connect } from "react-redux";
import "./leaves.style.scss";
import Title from "../../common/title";
import MyLeaves from "./myLeaves";
import MyRequests from "./myRequests";
import HolidayCalendar from "./holidayCalendar";
import Holidays from "./holidays";
import { GetEmployeeLeaveList } from "./action";
import { GetCompanyHolidays } from "actions/commonAction";

export class index extends Component {
  componentDidMount() {
    this.props.GetEmployeeLeaveList();
  }
  render() {
    return (
      <div className="common-container">
        <Title name="Leaves" />
        <MyLeaves {...this.props} />
        {/* <MyRequests /> */}
        <div className="holiday">
          <HolidayCalendar />
          <Holidays {...this.props} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeLeaveList_Formated:
    state.employeeLeaveReducer.employeeLeaveList_Formated,
  companyHolidays: state.commonReducer.companyHolidays,
  companyInformation: state.commonReducer.companyInformation,
});

const mapDispatchToProps = {
  GetEmployeeLeaveList,
  GetCompanyHolidays,
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
