import isEmpty from "isEmpty";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getJobApplicationForm,
  getJobHiringStages,
  getJobNumber,
  getJobScorecard,
} from "./actions";
const Context = React.createContext();

const reducer = (state, action) => {
  const { payload, type } = action;
  switch (type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case "onMarkingSchemeChange": {
      return {
        ...state,
        jobCreate: {
          ...state.jobCreate,
          RecruitmentJobMarkingSchemeDto: payload,
        },
      };
    }
    case "onPositionChange": {
      console.log("jobCreate-context-position change", {
        ...state.jobCreate,
        RecruitmentPositionDetailDto: {
          ...state.jobCreate.RecruitmentPositionDetailDto,
          [payload.name]: payload.value,
        },
      });

      return {
        ...state,
        jobCreate: {
          ...state.jobCreate,
          RecruitmentPositionDetailDto: {
            ...state.jobCreate.RecruitmentPositionDetailDto,
            [payload.name]: payload.value,
          },
        },
      };
    }
    case "onAppFormChange": {
      return {
        ...state,
        jobCreate: {
          ...state.jobCreate,
          RecruitmentJobApplicationFormDto:
            state.jobCreate.RecruitmentJobApplicationFormDto.map((item) => {
              if (item.ComponentRef_Id === payload.id) {
                let a = { ...item };
                a.RequiredType = payload.value;
                return a;
              }
              return item;
            }),
        },
        jobApplicationForm: state.jobApplicationForm.map((item) => {
          if (item.ComponentId === payload.id) {
            let a = { ...item };
            if (payload.value === 1) {
              a.IsMandatory = true;
              a.IsOptional = false;
              a.IsOff = false;
            }
            if (payload.value === 2) {
              a.IsMandatory = false;
              a.IsOptional = true;
              a.IsOff = false;
            }
            if (payload.value === 3) {
              a.IsMandatory = false;
              a.IsOptional = false;
              a.IsOff = true;
            }
            return a;
          }
          return item;
        }),
      };
    }
    case "addHiringStage":
      // {
      //     Id: 0,
      //     JobRef_Id: 0,
      //     JobStageRef_Id: 0,
      //     SubStageName: ""
      // }
      return {
        ...state,
        jobCreate: {
          ...state.jobCreate,
          RecruitmentJobHiringStageDto: [
            ...state.jobCreate.RecruitmentJobHiringStageDto,
            { StageRef_Id: payload.StageId, StageName: payload.StageType },
          ],
        },
        jobHiringStages: [payload, ...state.jobHiringStages],
      };
    case "editHiringStage":
      return {
        ...state,
        jobCreate: {
          ...state.jobCreate,
          RecruitmentJobHiringStageDto:
            state.jobCreate.RecruitmentJobHiringStageDto.map((stage) => {
              if (stage.StageRef_Id === payload.StageId) {
                let a = { ...stage };
                a.StageType = payload.StageType;
                a.StageName = payload.StageName;
                return a;
              }
              return stage;
            }),
        },
        jobHiringStages: state.jobHiringStages.map((stage) => {
          if (stage.StageId === payload.StageId) {
            let a = { ...stage };
            a.StageType = payload.StageType;
            return a;
          }
          return stage;
        }),
      };
    case "removeHiringStage":
      return {
        ...state,
        jobCreate: {
          ...state.jobCreate,
          RecruitmentJobHiringStageDto:
            state.jobCreate.RecruitmentJobHiringStageDto.filter(
              (stage) => stage.StageId !== payload.id
            ),
        },
        jobHiringStages: state.jobHiringStages.filter(
          (stage) => stage.StageId !== payload.id
        ),
      };
    case "addHiringSubStage":
      return {
        ...state,
        jobCreate: {
          ...state.jobCreate,
          RecruitmentJobHiringSubStageDto: [
            payload,
            ...state.jobCreate.RecruitmentJobHiringSubStageDto,
          ],
        },
        jobHiringSubStages: [payload, ...state.jobHiringSubStages],
      };
    case "editHiringSubStage":
      return {
        ...state,
        jobCreate: {
          ...state.jobCreate,
          RecruitmentJobHiringSubStageDto:
            state.jobCreate.RecruitmentJobHiringSubStageDto.map((subItem) => {
              if (subItem.Id === payload.id) {
                let a = { ...subItem };
                a.SubStageName = payload.name;
                return a;
              }
              return subItem;
            }),
        },
        jobHiringSubStages: state.jobHiringSubStages.map((subItem) => {
          if (subItem.Id === payload.id) {
            let a = { ...subItem };
            a.SubStageName = payload.name;
            return a;
          }
          return subItem;
        }),
      };
    case "removeHiringSubStage":
      return {
        ...state,
        jobCreate: {
          ...state.jobCreate,
          RecruitmentJobHiringSubStageDto:
            state.jobCreate.RecruitmentJobHiringSubStageDto.filter(
              (stage) => stage.Id !== payload.id
            ),
        },
        jobHiringSubStages: state.jobHiringSubStages.filter(
          (stage) => stage.Id !== payload.id
        ),
      };
    case "addHiringTeam":
      return {
        ...state,
        jobHiringTeam: [...state.jobHiringTeam, payload],
        jobCreate: {
          ...state.jobCreate,
          RecruitmentJobHiringTeamDto: [
            ...state.jobCreate.RecruitmentJobHiringTeamDto,
            action.payload,
          ],
        },
      };
    case "removeHiringTeam":
      return {
        ...state,
        jobHiringTeam: state.jobHiringTeam.filter((item) => {
          // if (!isEmpty(item.isCurrent) && item.isCurrent) {
          return item.UserId !== payload;
          //}
        }),
        jobCreate: {
          ...state.jobCreate,
          RecruitmentJobHiringTeamDto:
            state.jobCreate.RecruitmentJobHiringTeamDto.filter((item) => {
              //if (!isEmpty(item.isCurrent) && item.isCurrent) {
              return item.UserId !== payload;
              //}
            }),
        },
      };
  }
};

class Provider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dispatch: (action) => this.setState((state) => reducer(state, action)),
      loading: false,
      jobScoreCard: [],
      jobApplicationForm: [],
      jobHiringStages: [],
      jobHiringSubStages: [],
      jobHiringTeam: [],
      jobCreate: {
        RecruitmentPositionDetailDto: {
          JobId: 0,
          InternalId: "",
          InternalJobTitle: "",
          ExternalJobTitle: "",
          LevelId: 0,
          DivisionId: 0,
          BranchId: 0,
          IsAnywhere: true,
          EmployementTypeId: 0,
          CategoryId: 0,
          NumberOfOpenings: 0,
          StartDate: "",
          EndDate: "",
          StartDateEng: null,
          EndDateEng: null,
          IsPayShow: true,
          SalaryRangeFrom: "",
          SalaryRangeTo: "",
          SalaryDurationType: 0,
          QualificationLevelId: 0,
          IsExperience: false,
          Experience: "",
          ExperienceDurationType: 0,
          JobDescription: null,
          Tags: "",
          SaveDraftStage: 0,
          IsPublished: false,
          PublishedBy: 0,
          WizardStage: 0,
        },
        RecruitmentJobApplicationFormDto: [],
        RecruitmentJobHiringStageDto: [],
        RecruitmentJobHiringSubStageDto: [],
        RecruitmentJobScorecardDto: [],
        RecruitmentJobScorecardDetailDto: [],
        RecruitmentJobMarkingSchemeDto: {},
        RecruitmentJobHiringTeamDto: [],
      },
    };
    this.handleAppForm = this.handleAppForm.bind(this);
    this.handleHiringStage = this.handleHiringStage.bind(this);
    this.handleScoreCard = this.handleScoreCard.bind(this);
    this.onUpdateDataLoad = this.onUpdateDataLoad.bind(this);
    this.jobHirintTeamHandle = this.jobHirintTeamHandle.bind(this);
  }
  async componentDidMount() {
    const {
      getJobScorecard,
      getJobApplicationForm,
      getJobHiringStages,
      getJobNumber,
    } = this.props;
    let ajaxApi = [
      getJobHiringStages,
      getJobApplicationForm,
      getJobScorecard,
      getJobNumber,
    ];
    Promise.all(
      ajaxApi.map(async (item) => {
        return await item();
      })
    );
    const data = await this.props.jobScoreCard;
    const userDetail =
      localStorage.UserDetail && JSON.parse(localStorage.UserDetail);
    //const user = userFullDetail;
    const userData = {
      Id: 0,
      JobRef_Id: 0,
      Name: userDetail.UserName,
      Email: userDetail.Email,
      UserId: userDetail.EmployeeId ? userDetail.EmployeeId : 0,
      Designation: userDetail.Designation,
      HasAllAccess: true,
      HasStandardAccess: true,
      isCurrent: true,
      ImagePath: "",
    };
    this.setState((prevState) => ({
      ...prevState,
      jobHiringTeam: [userData, ...prevState.jobHiringTeam],
      jobCreate: {
        ...prevState.jobCreate,
        RecruitmentJobHiringTeamDto: [
          userData,
          ...prevState.jobCreate.RecruitmentJobHiringTeamDto,
        ],
      },
    }));
  }
  componentDidUpdate(prevProps, prevState) {
    const {
      jobScoreCard,
      jobApplicationForm,
      jobHiringStages,
      trelloReducer,
      employeeList,
      employeeWithPhotos,
      createJobReducer,
    } = this.props;
    let { jobNumber, jobCreate } = createJobReducer;

    if (
      !isEmpty(jobCreate.value) &&
      prevProps.createJobReducer.jobCreate.value !== jobCreate.value
    ) {
      this.onUpdateDataLoad(jobCreate.value);
    } else {
      if (
        !isEmpty(jobScoreCard.value) &&
        prevProps.jobScoreCard.value !== jobScoreCard.value
      ) {
        this.setState({ jobScoreCard: jobScoreCard.value });
        this.handleScoreCard(jobScoreCard.value);
      }
      if (
        !isEmpty(jobApplicationForm.value) &&
        prevProps.jobApplicationForm.value !== jobApplicationForm.value
      ) {
        this.setState({ jobApplicationForm: jobApplicationForm.value });
        this.handleAppForm(jobApplicationForm.value);
      }
      if (
        !isEmpty(jobHiringStages.value) &&
        prevProps.jobHiringStages.value !== jobHiringStages.value
      ) {
        this.setState({ jobHiringStages: jobHiringStages.value });
        this.handleHiringStage(jobHiringStages.value);
      }
      if (
        !isEmpty(trelloReducer.cards) &&
        prevProps.trelloReducer.cards !== trelloReducer.cards
      ) {
        let ele = trelloReducer.cards.map((item, index) => {
          return {
            Id: !item.id.toString().match(/^[\d]+$/) ? 0 : item.id,
            ScorecardRef_Id: item.ScorecardRef_Id,
            TagName: item.TagName,
            OrderId: index,
          };
        });
        this.setState((prevState) => ({
          ...prevState,
          jobCreate: {
            ...prevState.jobCreate,
            RecruitmentJobScorecardDetailDto: ele,
          },
        }));
      }
      if (
        (isEmpty(
          this.state.jobCreate.RecruitmentPositionDetailDto.InternalId
        ) &&
          !isEmpty(jobNumber.value)) ||
        (!isEmpty(jobNumber.value) &&
          prevProps.createJobReducer.jobNumber.value !== jobNumber.value)
      ) {
        // let recruitmentPositionDetailDto = this.state.jobCreate
        //   .RecruitmentPositionDetailDto;
        // recruitmentPositionDetailDto.InternalId = jobNumber.value;
        this.setState((prevState) => ({
          ...prevState,
          jobCreate: {
            ...prevState.jobCreate,
            RecruitmentPositionDetailDto: {
              ...prevState.jobCreate.RecruitmentPositionDetailDto,
              InternalId: jobNumber.value,
            },
          },
        }));
      }
    }
  }
  onUpdateDataLoad(data) {
    this.setState((prevState) => ({
      ...prevState,
      jobCreate: data,
      jobApplicationForm: this.jobAppUpdateHandle(
        prevState.jobApplicationForm,
        data.RecruitmentJobApplicationFormDto
      ),
      jobHiringStages: this.jobHiringStageUpdateHandle(
        prevState.jobHiringStages,
        data.RecruitmentJobHiringStageDto
      ),
      // jobHiringSubStages:this.jobHiringSubStageUpdateHandle(prevState.jobHiringStages,data.RecruitmentJobHiringSubStageDto),
      jobHiringSubStages: data.RecruitmentJobHiringSubStageDto,
      jobHiringTeam: this.jobHirintTeamHandle(data.RecruitmentJobHiringTeamDto),
    }));
  }
  jobHirintTeamHandle(data) {
    let emp = this.props.employeeList;

    let res = data.map((item) => {
      let a = emp && emp.find((empItem) => empItem.EmployeeId === item.UserId);
      if (!isEmpty(a)) {
        let b = { ...a };
        b.Name = a.FullName;
        b.ImagePath = a.Imagepath;
        return { ...item, ...b };
      }
      return item;
    });

    return res;
  }
  jobHiringStageUpdateHandle(data, value) {
    let ret = data.map((item, index) => {
      let a = value.map((val) => {
        if (val.StageRef_Id === item.StageId) {
          return item;
        }
      });
      let b = a.find((item) => typeof item !== "undefined");
      return b;
    });
    let c = ret.filter((item) => typeof item !== "undefined");

    return c;
  }
  // jobHiringSubStageUpdateHandle(data,value){
  // }
  jobAppUpdateHandle(data, value) {
    return data.map((item, index) => {
      if (!isEmpty(value[index]) && value[index].RequiredType == 1) {
        let a = { ...item };
        a.IsMandatory = true;
        a.IsOptional = false;
        a.IsOff = false;
        return a;
      }
      if (!isEmpty(value[index]) && value[index].RequiredType == 2) {
        let b = { ...item };
        b.IsMandatory = false;
        b.IsOptional = true;
        b.IsOff = false;
        return b;
      }
      if (!isEmpty(value[index]) && value[index].RequiredType == 3) {
        let c = { ...item };
        c.IsMandatory = false;
        c.IsOptional = false;
        c.IsOff = true;
        return c;
      }
      return item;
    });
  }
  handleScoreCard(data) {
    let ele = data.map((item) => {
      //if (item.DefaultDisplay) {
      return {
        ScorecardRef_Id: item.ScorecardId,
        ScorecardName: item.ScorecardName,
      };
      // }
    });
    this.setState((prevState) => ({
      ...prevState,
      jobCreate: {
        ...prevState.jobCreate,
        RecruitmentJobScorecardDto: ele,
      },
    }));
  }
  handleHiringStage(data) {
    let ele = [];
    data.map((item) => {
      if (item.DefaultDisplay) {
        ele.push({ StageRef_Id: item.StageId, StageName: item.StageType });
      }
    });
    this.setState((prevState) => ({
      ...prevState,
      jobCreate: {
        ...prevState.jobCreate,
        RecruitmentJobHiringStageDto: ele,
      },
    }));
  }
  handleAppForm(data) {
    const ele = data.map((item) => {
      const { IsMandatory, IsOptional, IsOff, ComponentId } = item;
      if (IsMandatory && !IsOptional && !IsOff) {
        return { ComponentRef_Id: ComponentId, RequiredType: 1 };
      }
      if (!IsMandatory && IsOptional && !IsOff) {
        return { ComponentRef_Id: ComponentId, RequiredType: 2 };
      }
      if (!IsMandatory && !IsOptional && IsOff) {
        return { ComponentRef_Id: ComponentId, RequiredType: 3 };
      }
    });
    this.setState((prevState) => ({
      ...prevState,
      jobCreate: {
        ...prevState.jobCreate,
        RecruitmentJobApplicationFormDto: ele,
      },
    }));
  }

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

Provider.propTypes = {
  jobScoreCard: PropTypes.object.isRequired,
  jobApplicationForm: PropTypes.object.isRequired,
  jobHiringStages: PropTypes.object.isRequired,
  getJobScorecard: PropTypes.func.isRequired,
  getJobApplicationForm: PropTypes.func.isRequired,
  getJobHiringStages: PropTypes.func.isRequired,
  getJobNumber: PropTypes.func.isRequired,
  trelloReducer: PropTypes.object.isRequired,
  employeeList: PropTypes.object.isRequired,
  createJobReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  jobScoreCard: state.createJobReducer.jobScoreCard,
  jobApplicationForm: state.createJobReducer.jobApplicationForm,
  jobHiringStages: state.createJobReducer.jobHiringStages,
  trelloReducer: state.trelloReducer,
  employeeWithPhotos: state.commonReducer.employeeWithPhotos,
  employeeList: state.commonReducer.employeeList,
  createJobReducer: state.createJobReducer,
});

export default connect(mapStateToProps, {
  getJobHiringStages,
  getJobApplicationForm,
  getJobScorecard,
  getJobNumber,
})(Provider);

export const Consumer = Context.Consumer;
