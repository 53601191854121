import Button from 'components/button'
import Modal from 'components/modal'
import React, { useState } from 'react'
import Table from 'components/customTable';
import TeamForm from './teamForm';
import { useDispatch, useSelector } from 'react-redux';
import Confirm from 'components/common/commonConfimation';
import { deleteRosterTeam } from 'scenes/roster/helpers/action';

const ButtonCell = (props) => {
    const [open, setOpen] = useState(false);
    const onModalOpen = () => setOpen(true)
    const onModalClose = () => setOpen(false)
    const dispatch = useDispatch();
    console.log({props})
    const onDelete = () => {
        dispatch(deleteRosterTeam(props.value));
    }
    return (
        <div className="shifts-table__col flex">
            <div className="shifts-table__links">
                <span onClick={() => onModalOpen()} >Edit</span>
            </div>
            <div className="shifts-table__links">
                <Confirm action={onDelete} >
                    <span>Delete</span>
                </Confirm>
            </div>
            <Modal
                open={open}
                onModalClose={onModalClose}
                title="Edit Team"
                width="450px"
            >
                <TeamForm editData={props?.row?.original} isEdit={true} onModalClose={onModalClose} />
            </Modal>
        </div>
    )
}


function Teams() {
    const [open, setOpen] = useState(false);
    const onModalClose = () => setOpen(false);
    const { teamList } = useSelector((state: any) => state.roosterReducer);
    const cols = [
        {
            Header: 'Id',
            accessor: 'value',
            width: 160,
        },
        {
            Header: 'Team Name',
            accessor: 'Name',
            width: 160,
        },
        {
            Header: 'Description',
            accessor: 'Description',
            width: 220,
        },
        {
            Header: 'Shift Pattern',
            accessor: 'ShiftPattern',
            width: 160,
        },
        {
            Header: 'Actions',
            accessor: 'Id',
            Cell: ButtonCell,
            width: 160,
        },
    ];
    return (
        <div className="teams">
            <div className="flex mb-sm mt-sm">
                <Button
                    title="Add Team"
                    bg="primary"
                    onClick={() => setOpen(true)}
                />
                <Modal
                    title="Add Team"
                    onModalClose={onModalClose}
                    open={open}
                    width="400px"
                >
                    <TeamForm onModalClose={onModalClose} />
                </Modal>
            </div>
            <div className="teams-table">
                <Table
                    isFixedWidth
                    data={teamList ?? []}
                    columns={cols}
                />
            </div>
        </div>
    )
}

export default Teams
