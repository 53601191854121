import React from "react";
import ContentLoader from "react-content-loader";

interface CardSkeleton {
  foregroundColor: string;
  backgroundColor: string;
  width?: string;
  height?: string;
}

const CardSkeleton = (props: CardSkeleton) => {
  const tabRowData = [0, 1, 2, 3, 4];
  const { backgroundColor, foregroundColor, width, height } = props;
  // console.log(props)
  return (
    <ContentLoader
      speed={2}
      width={width ? width : "100%"}
      height="100%"
      viewBox={"0 0 800 " + height}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      //header
      <rect x="0" y="0" rx="3" ry="3" width="800" height="1" />
      <rect x={15} y={10} rx="3" ry="3" width="200" height="9" />
      <rect x={15} y={22} rx="3" ry="3" width="180" height="8" />
      <rect x={400} y={11} rx="3" ry="3" width="100" height="18" />
      <rect x={520} y={11} rx="3" ry="3" width="100" height="18" />
      <rect x={640} y={11} rx="3" ry="3" width="100" height="18" />
      <rect x={760} y={7} rx="3" ry="3" width="30" height="26" />
      <rect x="0" y="40" rx="3" ry="3" width="800" height="1" />
      // //border
      <rect x="0" y="0" rx="3" ry="3" width="1" height="200" />
      <rect x="798" y="0" rx="3" ry="3" width="1" height="200" />
      <rect x="0" y="200" rx="3" ry="3" width="800" height="1" />
      // //body //first row
      <rect x={20} y={50} rx="3" ry="3" width="80" height="10" />
      <rect x={110} y={51} rx="3" ry="3" width="40" height="8" />
      <rect x={160} y={51} rx="3" ry="3" width="50" height="8" />
      // //second row
      <rect x={20} y={70} rx="3" ry="3" width="70" height="10" />
      // //Third row
      <rect x={20} y={90} rx="3" ry="3" width="100" height="20" />
      // //Fourth row - text-editor //border
      <rect x="20" y="120" rx="3" ry="3" width="760" height="1" />
      <rect x="20" y="120" rx="3" ry="3" width="1" height="60" />
      <rect x="780" y="120" rx="3" ry="3" width="1" height="60" />
      <rect x="20" y="140" rx="3" ry="3" width="760" height="1" />
      <rect x="20" y="180" rx="3" ry="3" width="760" height="1" />
      // //header
      <rect x="30" y="125" rx="3" ry="3" width="25" height="10" />
      <rect x="60" y="124" rx="3" ry="3" width="12" height="12" />
      <rect x="75" y="124" rx="3" ry="3" width="12" height="12" />
      <rect x="90" y="124" rx="3" ry="3" width="12" height="12" />
      // //body
      <rect x="30" y="165" rx="3" ry="3" width="100" height="10" />
      // //
    </ContentLoader>
  );
};

const TabRow = (num, index) => {
  let xx = [130, 295, 621];
  let yy = 140;
  let yyy = [
    yy + num * 80 - 10,
    yy + num * 80 + 5,
    yy + num * 80 + 5,
    yy + num * 80 + 50,
  ];
  let cyy = yy + 80 * num + 10;
  let cxx = 740;
  let cx1 = 64;
  return (
    <>
      <circle cx={`${cx1}`} cy={cyy} r="30" />

      <rect
        x={`${xx[0] + num}`}
        y={`${yyy[0]}`}
        rx="3"
        ry="3"
        width="100"
        height="15"
      />
      <rect
        x={`${xx[0] + num}`}
        y={`${yyy[0] + 20}`}
        rx="3"
        ry="3"
        width="141"
        height="15"
      />
      <rect
        x={`${xx[1] + num}`}
        y={`${yyy[0]}`}
        rx="3"
        ry="3"
        width="200"
        height="15"
      />
      <rect
        x={`${xx[1] + num}`}
        y={`${yyy[0] + 20}`}
        rx="3"
        ry="3"
        width="299"
        height="15"
      />
      <rect
        x={`${xx[2] + num}`}
        y={`${yyy[0]}`}
        rx="3"
        ry="3"
        width="80"
        height="15"
      />
      <rect
        x={`${xx[2] + num}`}
        y={`${yyy[0] + 20}`}
        rx="3"
        ry="3"
        width="101"
        height="15"
      />

      {/* <rect
        x={`${xx[5]}`}
        y={`${yyy[3]}`}
        rx="3"
        ry="3"
        width="800"
        height="2"
      /> */}
    </>
  );
};

CardSkeleton.defaultProps = {
  backgroundColor: "#e4e5e6",
  foregroundColor: "#fff",
};

export default CardSkeleton;
