import React from 'react'
import {
    Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis,Legend
  } from 'recharts';

const renderLegend = (props) => {
    const { payload } = props;

    return (
        <ul className="ksa-legend">
        {
            payload.map((entry, index) => (
            <li className="ksa-legend__item" key={`item-${index}`}>
                <span style={{background:entry.payload.fill}} className="ksa-legend__circle"></span>
                <span className="ksa-legend__text">
                    {entry.value}
                </span>    
            </li>
            ))
        }
        </ul>
    );
}

function CustomRadarChart({data}) {
    return (
        <div className="ksa-radar">
            <RadarChart cx={300} cy={250} outerRadius={150} width={500} height={500} data={data}>
                <PolarGrid/>
                <PolarAngleAxis dataKey="name" />
                <PolarRadiusAxis angle={30}  domain={[0, 6]} />
                <Radar name="Expected" dataKey="min" stroke="#8884d8" fill="#888" fillOpacity={0.6} />
                <Radar name="Observed" dataKey="score" stroke="#8884d8" fill="#bcef73" fillOpacity={0.6} />
                <Legend content={renderLegend} />
            </RadarChart>
        </div>
    )
}

export default CustomRadarChart;