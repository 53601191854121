import React from 'react'
import {
    PieChart, Pie, Sector, Cell,Legend,
  } from 'recharts';
import { useSelector } from 'react-redux';
import {pieChartValue} from 'helpers/getTextLabel'
import isEmpty from 'isEmpty'

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

function LeaveAltChart({type}) {

    const leaveTimeDashboard = useSelector(state => state.leaveTimeReducer.leaveTimeDashboard );
    const {RejectedLeave}  = leaveTimeDashboard;
    const data = leaveTimeDashboard[type]

    if(isEmpty(data)){
        return <span className="no-found">No Data Found</span>
    }
    const customLabel = ()=>{
        if(type === 'RejectedLeave'){
            const req = data.find(a=>a.Title==='Requested')
            const rej = data.find(a=>a.Title==='Rejected')
            return(<div className="cutom-label">
                    <div className="">
                        <span className="text-pink">{rej.Total}</span>
                        Leaves rejected
                    </div>
                    <div className="">
                    {(rej.Total/req.Total) * 100}% of {req.Total} requests
                    </div>
                </div>
            )
        }
        if(type === 'ApprovedLeave'){
            const req = data.find(a=>a.Title==='Requested')
            const rej = data.find(a=>a.Title==='Approved')
            return(<div className="cutom-label">
                    <div className="">
                        <span className="text-pink">{rej.Total}</span>
                        Leaves approved
                    </div>
                    <div className="">
                    {(rej.Total/req.Total) * 100}% of {req.Total} requests
                    </div>
                </div>
            )
        }
        if(type === 'DeviceStaticts'){
            const act = data.find(a=>a.Title==='Active')
            const fail = data.find(a=>a.Title==='Failed')
            return(<div className="cutom-label">
                    <div className="">
                        <span className="text-pink">{fail.Total}</span>
                        Failed devices
                    </div>
                    <div className="">
                        <span className="text-green">{act.Total}</span>
                        Active devices
                    </div>
                    <div className="">
                     of {fail.Total + act.Total} Reegistered
                    </div>
                </div>
            )
        }
        
    }
        
    return (
        <Pies data={data} customLabel={customLabel} />
    )
}



const Pies = ({data,customLabel}) => {
    return(
        <div className="alt-chart">
            <PieChart width={350} height={200}>
                <Pie
                    data={pieChartValue(data)}
                    cx={110}
                    cy={95}
                    labelLine={false}
                    innerRadius={40}
                    outerRadius={70}
                    // label={renderCustomizedLabel}
                    fill="#8884d8"
                    dataKey="value"
                >
                {
                    data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                }
                    
                </Pie>
            </PieChart>
            <div className="pie-chart-labels">
                {customLabel()}
            </div>
        </div>
    )
}

export default LeaveAltChart
