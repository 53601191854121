import React, { useState,useEffect } from 'react'
import Formgroup from 'components/form';
import shortid from "shortid";
import {TiDelete} from 'react-icons/ti';
import isEmpty from 'isEmpty';
import { useDispatch,useSelector } from 'react-redux';
import SelectValues from './selectValues'

function AuthorizedModal({close}) {
    const [group,setGroup] = useState('');
    return (
        <div className="permission">
            <div className="permission-body">
                <div className="permission-block">
                    <Formgroup 
                        value={group}
                        type="text"
                        name="group"
                        width={400}
                        // placeholder="Search"
                        label="Group name"
                        onChange={(e)=>setGroup(e.target.value)}
                    />
                    <div className="permission-members-container">
                        <div className="permission-members-block">
                            <div className="permission-members__number">
                                <h4>14</h4>
                                <span>members</span>
                            </div>
                            <div className="permission-members__btn">
                                <button className="button button-grey">
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="permission-block">
                    <h2 className="permission-title">Choose Group Members</h2>
                    <PeoplePool type="include" />
                </div>
                <hr className="seperate"/>
                <div className="permission-block">
                    <h2 className="permission-title">Exclude these people from the permission group</h2>
                    <PeoplePool type="exclude" />
                </div>
            </div>
            <div className="permission-footer">
                <div className="button-group button-group__right">
                    <button className="button button-primary">Save</button>
                    <button 
                        onClick={()=>close()}
                        className="button">Cancel</button>
                </div>
            </div>
        </div>
    )
}

export const PeoplePool = (props) =>{
    const [pool,setPool] = useState([])
    const poolAdd = () =>{
        const newPool = {
            id: shortid.generate(),
        }
        if(pool){
            setPool([...pool,newPool]);
            return;
        }
        setPool([newPool]);
    }
    useEffect(()=>{
        if(props.type==='include' && isEmpty(pool)){
            const newPool = {
                id: shortid.generate(),
            }
            setPool([newPool]);
        }
    },[])
    return(
        <div className="people-pool">
            {
                pool.map(pool=><PeoplePoolItem {...props} pool={pool} key={pool.id} />)
            }
            <div className="people-pool__add">
                <button 
                    onClick={()=>poolAdd()}
                    className="button button-primary">
                    Add people pool
                </button>
            </div>
        </div>
    )
}

const PeoplePoolItem = ({pool,type}) =>{
    const [poolItem,setPoolItem] = useState([])
    const poolAdd = () =>{
        const newPool = {
            id: shortid.generate(),
        }
        if(poolItem){
            setPoolItem([...poolItem,newPool]);
            return;
        }
        setPoolItem([newPool]);
    }
    useEffect(()=>{
        if(type==='include' && isEmpty(poolItem)){
            const newPool = {
                id: shortid.generate(),
            }
            setPoolItem([newPool]);
        }
    },[])
    const poolRemove = (id) =>{
        let filteredPool = poolItem.filter(item=>item.id !==id);
        setPoolItem(filteredPool)
    }
    return(
        <div className="people-pool__item">
            <h5 className="people-pool__title">People Pool</h5>
            {
                poolItem.map(poolItem=><Pool type={type} pool={poolItem} poolRemove={poolRemove} key={poolItem.id} />)
            }
            <button 
                onClick={()=>poolAdd()}
                className="button button-grey">
                    {
                        poolItem.length === 0 ? 'Add a category' : 'Add another category'
                    }
            </button>
        </div>
    )
}

export const Pool = ({pool,type,poolRemove})=>{
    const [poolVal,setPoolVal] = useState([])
    const [userGroup,setUserGroup] = useState([])
    const userGroupMember = useSelector(state=>state.authorizedReducer.userGroupMember)
    useEffect(()=>{
        if(!isEmpty(userGroupMember)){
            setUserGroup(userGroupMember)
        }
    },[userGroupMember])
    const [tags,setTags] = useState([])
    const onSelectChange=(name,value)=>{
        setPoolVal(value)
    }
    // const onSelectChange = (name, value) => {
    //     // onChange(name, value);
    //     var a;
    //     let val = value.map(item=>item.label)
    //     onChange(name, val.join(","));
    // };
    // const tagsData = data && data.split(",").map(tag=>{
    //     return {label:tag,value:tag}
    // })

    const onTagChange = (value) => setTags(value)
    return(
        <div className="pool">
            <div className="pool-select">
                <Formgroup
                    formName="reactselect" 
                    width="200px"
                    options={userGroup}
                    onChange={onSelectChange}
                    value={poolVal}
                    name="poolVal"
                    isArray
                />
            </div>
            <div className="pool-tag">
                {/* <DataSelect 
                    defaultValue={tags}
                    type="tags"
                    onChange={(value)=>setTags(value)}
                    colorType={type}
                    actionType={poolVal?poolVal.value:''}
                    styles={colourStyles}
                /> */}
                <SelectValues
                    values={tags}
                    actionValue={poolVal}
                    name={"tags"}
                    onChange={onTagChange}
                    colorType={type}
                    styles={colourStyles}
                />
            </div>
            <div 
                onClick={()=>poolRemove(pool.id)}
                className="pool-remove">
                <TiDelete />
            </div>
        </div>
    )
}

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: "#F4F5F7",minHeight:35,height:35 }),
    valueContainer:styles => ({ ...styles, height:35 }),
    input:styles => ({ ...styles, height:35 }),
    multiValue: (styles, {data,selectProps}) => {
        const {colorType} = selectProps
        return {
            ...styles,
            borderRadius:'3px',
            backgroundColor: colorType === 'exclude' ? '#FE2E23' : '#55BA00'
        };
    },
    placeholder:styles=>({...styles,top: "65%"}),
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: '#fff',
        padding: "4px 6px",
        paddingLeft: "10px"
    }),
    multiValueRemove: (styles, { data,selectProps }) => {
        const {colorType} = selectProps
        return ({
            ...styles,
            borderRadius:'100%',
            width: 15,
            height: 15,
            color: colorType === 'exclude' ? '#FE2E23' : '#55BA00',
            background: colorType === 'exclude' ? '#f19691' : "#96cd69",
            padding:0,
            justifyContent:'center',
            margin:"auto 6px",
            ":hover": {
                backgroundColor: '#f1f1f1',
                color: colorType === 'exclude' ? '#FE2E23' : '#55BA00'
            }
        })
    }
};

export default AuthorizedModal
