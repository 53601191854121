import React, { Component } from "react";
import "../../scss";
import { connect } from "react-redux";
import SearchAndFilter from "./searchAndFilter";
import Breadcrumb from "../../../common/breadcrumb";
import Table from "./table";
import Loaders from "components/loaders";
import { GetHoursWorkedByClient } from "../../action";
import RouteBaseClass from "components/routeBaseClass";

export class index extends RouteBaseClass {
  constructor(props) {
    super(props, ["Administrator"]);
    this.state = {
      isLoading: true,
      pageIndex: 0,
      rowsPerPage: 10,
      searchClient: "",
      searchProject: "",
      fromDate: null,
      toDate: null,
    };
  }

  async componentDidMount() {
    await this.initialize();
    this.setState({ isLoading: false });
  }

  initialize = async () => {
    const {
      searchClient,
      searchProject,
      fromDate,
      toDate,
      pageIndex,
      rowsPerPage,
    } = this.state;

    const data = {
      ClientName: searchClient,
      ProjectName: searchProject,
      ActivityDateFrom: fromDate,
      ActivityDateTo: toDate,
      PageIndex: pageIndex,
      PageSize: rowsPerPage,
    };

    await this.props.GetHoursWorkedByClient(data);
  };

  handleRowChange = (num) => {
    this.setState({ rowsPerPage: num, pageIndex: 0 }, async () => {
      await this.initialize();
    });
  };

  handleIndexChange = (num) => {
    this.setState({ pageIndex: num }, async () => {
      await this.initialize();
    });
  };

  handleInput = (name, value) => {
    this.setState({ [name]: value, pageIndex: 0 }, async () => {
      await this.initialize();
    });
  };

  handleDateRangePicker = (name, date) => {
    const { startDate, endDate } = date;
    this.setState(
      { fromDate: startDate, toDate: endDate, pageIndex: 0 },
      async () => {
        await this.initialize();
      }
    );
  };

  render() {
    const { isLoading } = this.state;
    const { hoursWorkedByClient } = this.props;

    return (
      <div className="common-container">
        <div className="tasktracker-title-section">
          <Breadcrumb name="Hours worked by Client" />
        </div>

        <SearchAndFilter
          handleDateRangePicker={this.handleDateRangePicker}
          handleInput={this.handleInput}
          {...this.state}
        />

        {isLoading ? (
          <Loaders loading={isLoading} type="skeleton" name="custom-table" />
        ) : (
          <Table
            {...hoursWorkedByClient}
            {...this.state}
            handleRowChange={this.handleRowChange}
            handleIndexChange={this.handleIndexChange}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  hoursWorkedByClient: state.taskTrackerReducer.hoursWorkedByClient,
});

const mapDispatchToProps = {
  GetHoursWorkedByClient,
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
