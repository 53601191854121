import React, { Component } from "react";
import { connect } from "react-redux";
import './style.scss';
import Form from "components/form";
import Button from "components/button";
import { InputValidator } from "helpers/inputValidator";
import isEmpty from "isEmpty";
import { objectToFormData } from "object-to-formdata";
import {
    saveEmployeeTrainingDetails,
    updateEmployeeTrainingDetails,
    getEmployeeTrainingList,
} from '../action';
import {
    DisplayClientSideMessage,
} from "actions/commonAction";
class EducationForm extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            showAddSection: false,
            editView: false,
            errors: {},
            TrainingName: '',
            InstitutionName: '',
            Country: null,
            Year: null,
            CountryList: [],
            IsFileEdit: true,
        }
    }

    async componentDidMount () {
        const { editView, editViewData, countryList } = this.props;
        if (editView) {
            const index = countryList && countryList.findIndex(e => e.label == editViewData.Country);
            this.setState({
                ...editViewData,
                Files: {
                    name: editViewData.UserFileName,
                },
                IsFileEdit: false,
                Country: countryList[index],
            })
        }
    }

    handleInput = (name, value) => {
        console.log()
        this.setState(
            { [name]: value, },
            () => {
                this.updateErrors();
            }
        );
    }

    updateErrors = async () => {
        const { errors } = this.state;
        if (!isEmpty(errors)) {
        let errors = await InputValidator(document, this.state);
        this.setState({ errors });
        }
    };

    setSelectedFiles = (name, file) => {
        this.setState({ Files: file, IsFileEdit: true });
    };

    handleAutoSelect = (label, value) => {
        this.setState({ [label]: value });
    }

    // onValidate = () =>{
    //     let errs = {};
    //     const { Files } = this.state;
    //     if(isEmpty(Files?.name)){
    //         errs.Name = 'Document field is required'
    //     }
    //     if(Files === null){
    //         errs.File = 'Document File field is required'
    //     }
    //     return {errs, isValid:isEmpty(errs)}
    // }

    handleSave = async () => {
        let errors = await InputValidator(document, this.state);
        // const { errs, isValid } = this.onValidate();
        // errors = { ...errors, ...errs }
        // if (errs.Name) {
        //     this.props.DisplayClientSideMessage('Document is Required');
        // }
        if (!isEmpty(errors)) {
            this.setState({ errors });
            return;
        }
        let {
            TrainingName,
            InstitutionName,
            Country,
            Year,
            Files,
            IsFileEdit
        } = this.state;

        let data = {
            EmployeeId: parseInt(this.props.employeeId),
            TrainingId: this.props.editView ? this.props.editViewData.TrainingId : 0,
            TrainingName,
            InstitutionName,
            Year,
            Country: Country.label,
            Files,
            IsFileEdit
        }
        const options = {
            indices: true,
            nullsAsUndefineds: false,
            booleansAsIntegers: false,
        };
        const formData = objectToFormData(data, options);
        // if (Files.noedit == true) formData.append('File', Files);
        // else
        formData.append(`File`, Files);

        if (this.props.editView)
            await this.props.updateEmployeeTrainingDetails(formData);
        else
            await this.props.saveEmployeeTrainingDetails(formData);
        if (this.props.employeeTrainingSaved) {
            this.props.getEmployeeTrainingList(this.props.employeeId);
            this.closeAddSection(false)
        }
    }

    closeAddSection = () => {
        const { editView, toggleEditView, closeAddSection } = this.props;
        if (editView) toggleEditView();
        else closeAddSection();
    }

    onDateChange = (name, date) => this.setState({[name]: date});
    
    render() {
        const {
            TrainingName,
            InstitutionName,
            errors,
            Files,
            Country,
            Year,
        } = this.state;

        const { editView, countryList } = this.props;

        return (
            <div className="education__education__body trainingSection">
                <div className="education__education__div">
                    <div className="education__education__div__label trainingSection__body">
                        <div className="education__education__labels">
                            <Form
                                name="TrainingName"
                                value={TrainingName}
                                onChange={this.handleInput}
                                alignment="horizontal"
                                label="Training Name"
                                validators={["required"]}
                                error={errors && errors.TrainingName}
                            />
                        </div>
                        <div className="education__education__labels">
                            <Form
                                name="InstitutionName"
                                value={InstitutionName}
                                onChange={this.handleInput}
                                alignment="horizontal"
                                label="Institution"
                                validators={["required"]}
                                error={errors && errors.InstitutionName}
                            />
                        </div>
                        <div className="education__education__labels">
                            <Form
                                formName="reactselect"
                                name="Country"
                                value={Country}
                                options={countryList}
                                onChange={this.handleAutoSelect}
                                alignment="horizontal"
                                label="Country"
                                placeholder="Select.."
                                validators={["required"]}
                                error={errors && errors.Country}
                            />
                        </div>
                        <div className="education__education__labels education-smallSection">
                            <Form
                                name="Year"
                                value={Year}
                                onChange={this.handleInput}
                                alignment="horizontal"
                                label="Year"
                                validators={["upToCurrentYear"]}
                                error={errors && errors.Year}
                            />
                        </div>
                        <div className="education__education__attachments_training">
                            <Form
                                formName="singledocumentinput"
                                value={Files}
                                onChange={this.setSelectedFiles}
                                btnText="Add Documents"
                            />
                        </div>
                        <div className="button-group button-group__right education__savebutton">
                            <Button bg="primary" onClick={this.handleSave}>{!editView ? 'Add' : 'Update' }</Button>
                            <Button onClick={() => this.closeAddSection(false)} bg="secondary">
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    employeeTrainingSaved: state.employeeProfile.employeeTrainingSaved,
    countryList: state.commonReducer.countryList || [],
});

const mapDispatchToProps = {
    saveEmployeeTrainingDetails,
    updateEmployeeTrainingDetails,
    getEmployeeTrainingList,
    DisplayClientSideMessage,

};

export default connect(mapStateToProps, mapDispatchToProps)(EducationForm);