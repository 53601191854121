export const getDaywiseDate = (day) => {
  let result = null;
  if (day == "today") {
    result = new Date();
  } else if (day == "yesterday") {
    let today = new Date();
    let yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    result = yesterday;
  } else {
    let d = new Date();
    let value = null;
    if (day == "last_sunday") {
      value = 0;
    }
    if (day == "last_saturday") {
      value = 1;
    } else if (day == "last_friday") {
      value = 2;
    } else if (day == "last_thursday") {
      value = 3;
    } else if (day == "last_wednesday") {
      value = 4;
    } else if (day == "last_tuesday") {
      value = 5;
    } else if (day == "last_monday") {
      value = 6;
    }
    value && d.setDate(d.getDate() - ((d.getDay() + value) % 7));
    result = d;
  }
  return result;
};
