import axios from "axios";
import { toastMessage } from "actions/validateAction";
import { IS_EMPLOYEE_DETAIL_LOADING, GET_EMPLOYEE_DASHBOARD_DETAIL } from "actions/types";

export const getPersonalDetail = (props) => async dispatch => {
    dispatch({
        type: IS_EMPLOYEE_DETAIL_LOADING,
        isLoading: true
    });
    await axios
        .get(`/v1/employee-record/employees/information`)
        .then(resp => {
        const response = resp.data;
        if (response.Status) {
            dispatch({
                type: GET_EMPLOYEE_DASHBOARD_DETAIL,
                payload: response.Data
            });
            dispatch({
                type: IS_EMPLOYEE_DETAIL_LOADING,
                isLoading: false
            });
        } else {
            toastMessage(dispatch, response);
        }
        })
        .catch(err => {
        const response = {
            data: {
            MessageType: "Danger",
            Message: "Failed to Fetch Detail."
            }
        };
        toastMessage(dispatch, response);
        });
};