import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "helpers/isEmpty";
import { addMessage } from "actions/validateAction";
import RouteEnum from "constants/routeEnum";
import { roles } from "constants/constants";
import OnlyDev from "components/common/onlyDev";
import FlagRoutes from "./flagRoutes";
import { Features } from "helpers/getPermissions";

const PrivateRoute = ({
  component: Component,
  children,
  title,
  auth,
  render,
  isNotPrivate,
  isAdmin,
  isEmployee,
  isDev,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        // return auth.isAuthenticated === true || isNotPrivate (
        //     <Component {...props} ref={ref=>!isEmpty(rest.childRef) ? rest.childRef.current = ref:null} children={children} />
        // ): (
        //     <Redirect to={{pathname:"/login", state: { auth: false }}} />
        //      window.open(getBaseAxios()),<Redirect to={{pathname:"/login", state: { auth: false }}} />
        // )
        return (
          <ShowRoutes
            {...props}
            {...rest}
            auth={auth}
            isNotPrivate={isNotPrivate}
            children={children}
            Component={Component}
            isDev={isDev}
            isAdmin={isAdmin}
            isEmployee={isEmployee}
            title={title}
            // ref={(ref) =>
            //   !isEmpty(rest.childRef) ? (rest.childRef.current = ref) : null
            // }
          />
        );
      }}
    />
  );
};

export const ShowRoutes = ({
  Component,
  children,
  title,
  auth,
  // ref,
  isNotPrivate,
  isAdmin,
  isDev,
  isEmployee,
  ...rest
}) => {
  const [role, setRole] = useState(0);
  const { isAuthenticated, user: uerRed } = auth;
  const userDetails = localStorage.getItem("UserDetail");
  const user = !isEmpty(uerRed)
    ? uerRed
    : !isEmpty(userDetails)
    ? JSON.parse(userDetails)
    : [];
  const dispatch = useDispatch();
  const error = { type: "Danger", message: "Unauthorized" };
  const baseUrl = useSelector((state) => state.auth.baseUrl);
  const base = baseUrl ? baseUrl.NetFrameworkAppBaseUrl : "";
  useEffect(() => {
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const {
      location: { pathname },
    } = rest;
    if (!isEmpty(title)) {
      document.title = title;
    }
    // else {
    //   let title = pathname.split("/");
    //   if (!isEmpty(title) && title.length > 0 && Array.isArray(title)) {
    //     let pagePath = title[title.length - 1].split("_");
    //     let a = pagePath.map(cap=>capitalizeFirstLetter(cap))
    //     title = a.join(" ");
    //   } else {
    //     title = "HRMS";
    //   }
    //   document.title = title;
    // }
  }, []);
  const features = Features();

  // useEffect(() => {
  //   if (!isEmpty(user)) {
  //     setRole(user.RoleId);
  //   } else {
  //     setRole(0);
  //   }
  // }, [user]);
  // if((isAuthenticated ===  true && user.RoleId > 0 ) || isNotPrivate ){
  const { REACT_APP_ADMIN_ROLES } = process.env;
  // roles().includes(user.RoleId)
  if (isAuthenticated === true || isNotPrivate) {
    if (isAdmin && user && user.RoleId === 2) {
      // dispatch(addMessage(error));
      return <Redirect to={{ pathname: "/employee" }} />;
    }
    if (isEmployee && user && user.RoleId !== 2) {
      // dispatch(addMessage(error));
      return <Redirect to={{ pathname: RouteEnum.HRDashboardPath }} />;
    }
    if (isDev) {
      return (
        <OnlyDev>
          <Component
            baseUrl={base}
            {...rest}
            // ref={(ref) => ref}
            children={children}
          />
        </OnlyDev>
      );
    }
    return (
      <FlagRoutes feature={rest.feature}>
        <Component
          baseUrl={base}
          {...rest}
          // ref={(ref) => ref}
          children={children}
        />
      </FlagRoutes>
    );
  }
  return <Redirect to={{ pathname: "/login", state: { auth: false } }} />;
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(PrivateRoute);
