import React from "react";
import { connect } from "react-redux";
import RequestButton from "components/common/commonRequestButton";
import LoanButton from "components/common/commonLoanModuleButton";
import SBILoanButton from "components/common/commonSBILoanButton";
import { Features } from "helpers/getPermissions";
import { isFeatureEnabledValue, isFeatureEnabled } from "helpers/checkPermissions";

function RequestAndAssign(props: any) {
  const { user, isRecommenderOrApprover } = props;
  const features = Features();
  return (
    <>
      {user.RoleName === "Employee" ? (
        <div className="common-route-buttons">
          {isRecommenderOrApprover &&
            (isRecommenderOrApprover.IsLeaveTimeReviewerOrApprover ||
              isRecommenderOrApprover.IsAllowanceOvertimeReviewerOrApprover || 
            isRecommenderOrApprover.IsTravelReviewerOrApprover) && (
              <RequestButton
                buttonTitle="Assign Request"
                isAssign={true}
                isLeaveTimeReviewerOrApprover={
                  isRecommenderOrApprover.IsLeaveTimeReviewerOrApprover
                }
                isAllowanceOvertimeReviewerOrApprover={
                  isRecommenderOrApprover.IsAllowanceOvertimeReviewerOrApprover
                }
                isTravelReviewerOrApprover={
                  isRecommenderOrApprover.IsTravelReviewerOrApprover
                }
              />
            )}
          <RequestButton buttonTitle="New Request" isAssign={false} isTravelReviewerOrApprover={true}/>
          {isFeatureEnabledValue(features.Loan) &&
            <LoanButton buttonTitle="Loan Request" isAssign={false} />}
          {isFeatureEnabledValue(features.Loan) &&
            <SBILoanButton buttonTitle="SBI Loan Request" isAssign={false} />}
        </div>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => ({
  isRecommenderOrApprover: state.auth.isRecommenderOrApprover,
  user: state.auth.user,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(RequestAndAssign);
