import React, { useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import RouteEnum from "constants/routeEnum";

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const TodoItem = ({ todo, auth }) => {
  const { BoardName, TaskName, StageName, WeekTeamMembers, TaskDays,BoardId } = todo;
  const classes = useStyles();
  const base = auth.baseUrl ? auth.baseUrl.NetFrameworkAppBaseUrl : "";
  return (
    <li className="task-todo-assignments__item" onClick={() => {}}>
      <NavLink to={`${RouteEnum.TaskBoardSingleLink}${BoardId}`}>
        <div className="task-todo-assignment">
          <div className="task-todo-assignment-main">
            <div className="task-todo-assignment-titles">
              <div className="task-todo-assignment-title">
                <h4>{BoardName}</h4>
              </div>
              <div className="task-todo-assignment-status">
                <span>{TaskName} </span>
                {" > "}
                <span className="text-blue"> {StageName} </span>
              </div>
            </div>
            <div className="task-todo-assignment-avatar">
              <AvatarGroup max={1}>
                {WeekTeamMembers &&
                  WeekTeamMembers.map((x, index) => (
                    <Avatar
                      key={index}
                      className={classes.small}
                      alt={x.EmployeeName}
                      src={`${base}${x.ImageUrl}`}
                    />
                  ))}
              </AvatarGroup>
            </div>
          </div>
          <div className="task-todo-assignment-date">
            <span>{TaskDays}</span>
          </div>
        </div>
      </NavLink>
    </li>
  );
};
const mapStateToProps = (state) => ({ auth: state.auth });

export default connect(mapStateToProps, {})(TodoItem);
