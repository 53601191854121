import React, { Component } from "react";
import "../../scss";
import { connect } from "react-redux";
import TableByProject from "./tableByProject";
import TableByPeople from "./tableByPeople";
import { Tabs, Tab } from "components/tabs/index.d";
import Breadcrumb from "../../../common/breadcrumb";
import Loaders from "components/loaders";
import {
  GetPeopleInProject_ViewByProject,
  GetPeopleInProject_ViewByPeople,
} from "../../action";
import RouteBaseClass from "components/routeBaseClass";

const tabs = [
  { tabId: 1, label: "View by Project" },
  { tabId: 2, label: "View by People" },
];
export class index extends RouteBaseClass {
  constructor(props) {
    super(props, ["Administrator"]);
    this.state = {
      isLoading: true,
      pageIndex: 0,
      rowsPerPage: 10,
      activeTab: tabs[0],
    };
  }

  async componentDidMount() {
    await this.initialize();
    this.setState({ isLoading: false });
  }

  initialize = async () => {
    const { activeTab, pageIndex, rowsPerPage } = this.state;

    const data = {
      PageIndex: pageIndex,
      PageSize: rowsPerPage,
    };
    if (activeTab.tabId == 1) {
      await this.props.GetPeopleInProject_ViewByProject(data);
    } else if (activeTab.tabId == 2) {
      await this.props.GetPeopleInProject_ViewByPeople(data);
    }
  };

  handleRowChange = (num) => {
    this.setState({ rowsPerPage: num, pageIndex: 0 }, async () => {
      await this.initialize();
    });
  };

  handleIndexChange = (num) => {
    this.setState({ pageIndex: num }, async () => {
      await this.initialize();
    });
  };

  handleTabChange = (tab) => {
    this.setState({ activeTab: tab, pageIndex: 0 }, async () => {
      await this.initialize();
    });
  };

  render() {
    const { isLoading, activeTab } = this.state;
    const {
      peopleInProject_ViewByPeople,
      peopleInProject_ViewByProject,
    } = this.props;
    return (
      <div className="common-container">
        <div className="tasktracker-title-section">
          <Breadcrumb name="People in Project" />
        </div>
        <Tabs noBackground>
          {tabs.map((tab, index) => {
            return (
              <Tab
                key={index}
                label={tab.label}
                name={tab.tabId}
                id={tab.tabId}
                isActive={activeTab.tabId === tab.tabId}
                onClick={() => this.handleTabChange(tab)}
              >
                <>
                  {isLoading ? (
                    <Loaders
                      loading={isLoading}
                      type="skeleton"
                      name="custom-table"
                    />
                  ) : activeTab.tabId == 1 ? (
                    <TableByProject
                      {...peopleInProject_ViewByProject}
                      {...this.state}
                      handleRowChange={this.handleRowChange}
                      handleIndexChange={this.handleIndexChange}
                    />
                  ) : activeTab.tabId == 2 ? (
                    <TableByPeople
                      {...peopleInProject_ViewByPeople}
                      {...this.state}
                      handleRowChange={this.handleRowChange}
                      handleIndexChange={this.handleIndexChange}
                    />
                  ) : null}
                </>
              </Tab>
            );
          })}
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  peopleInProject_ViewByProject:
    state.taskTrackerReducer.peopleInProject_ViewByProject,
  peopleInProject_ViewByPeople:
    state.taskTrackerReducer.peopleInProject_ViewByPeople,
});

const mapDispatchToProps = {
  GetPeopleInProject_ViewByProject,
  GetPeopleInProject_ViewByPeople,
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
