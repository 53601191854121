import { loginUser, logoutUser } from "actions/authAction";
// import { getBaseAxios } from "../../../helpers/Constants";
import { GetCompanyInformation } from "actions/commonAction";
import React, { Component } from "react";
import { connect } from "react-redux";
import HeaderEmployee from "./headerEmployee";
import HeaderTop from "./headerTop";
import Navigation from "./navigation";
import "./styles.scss";

export class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyInfo: null,
      totalRequestCount: 0,
    };
    this.updateCompanyInfo = this.updateCompanyInfo.bind(this);
  }
  async componentDidMount() {
    // await this.props.GetCompanyInformation();
    this.updateCompanyInfo();
  }
  componentDidUpdate(prevProps, prevState) {
    const { companyInformation, employeeDashboard } = this.props;
    if (prevProps?.employeeDashboard !== employeeDashboard) {
      const { RequestCount } = employeeDashboard;
      const totalRequestCount = RequestCount?.reduce(
        (a, b) => a + (parseInt(b["Total"]) || 0),
        0
      );
      this.setState({ totalRequestCount });
    }
    if (prevProps?.companyInformation !== companyInformation) {
      if (companyInformation) {
        this.updateCompanyInfo();
      }
    }
  }
  updateCompanyInfo() {
    const { companyInformation, employeeDashboard } = this.props;
    let companyInfo = null;
    if (companyInformation) {
      const logoExtension =
        companyInformation.CompanyLogo &&
        companyInformation.CompanyLogo.split(".")[1];
      let companyLogo = null;
      if (logoExtension) {
        companyLogo = companyInformation.CompanyLogo;
      } else {
        companyLogo = companyInformation.CompanyLogo + ".png";
      }

      companyInfo = {
        companyGuIdId: companyInformation.ID,
        companyName: companyInformation.Name,
        address: companyInformation.Address,
        countryId: companyInformation.CountryId,
        currencyCode: companyInformation.CurrencyCode,
        email: companyInformation.Email,
        logo: companyLogo,
      };
      this.setState({ companyInfo: companyInfo });
    }
  }

  render() {
    const { companyInfo, isModalShown, totalRequestCount } = this.state;
    const { user, auth, activeFeatureFlags, employeeDashboard, informationalVersion } = this.props;

    let userDetail =
      localStorage.UserDetail && JSON.parse(localStorage.UserDetail);
    let userRole = userDetail ? userDetail.RoleName : null;
    return (
      <>
        <header className="headers">

          <HeaderTop
            companyInfo={companyInfo}
            auth={this.props.auth}
            adminEmployee={this.props.adminEmployee}
            // onLogoutCheck={this.props.onLogoutCheck}
            loginUser={this.props.loginUser}
            logoutUser={this.props.logoutUser}
            totalRequestCount={totalRequestCount}
            informationalVersion={informationalVersion}
          />
          <Navigation />

          {/* {activeFeatureFlags &&
          activeFeatureFlags.length > 0 &&
          activeFeatureFlags.find((f) => f.Feature == "TaskTracker").Flag ==
            true &&
          userRole == "Employee" && <TimeFunction />} */}

          {/* {user && <HeaderEmployee user={user} baseUrl={this.props.baseUrl} />} */}
        </header>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  companyInformation: state.commonReducer.companyInformation,
  user: state.auth.user,
  auth: state.auth,
  baseUrl: state.commonReducer.baseUrl,
  activeFeatureFlags: state.permissionReducer.activeFeatureFlags,
  employeeDashboard: state.employeeReducer.employeeDashboard,
  informationalVersion: state.commonReducer.informationalVersion,
});

const mapDispatchToProps = {
  GetCompanyInformation,
  logoutUser,
  loginUser,
  // adminEmployee,
  // onLogoutCheck
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
