import React, { useState, useEffect, useRef } from "react";
import Icons from "components/icons";
import { menuProps, menuNavProps } from "../../../types";
import classnames from "classnames";
import isEmpty from "isEmpty";
import RouteEnum from "constants/routeEnum";

import { useSelector, useDispatch, connect } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { MenuNavBar, NavItem } from "../index";
import BoardAdd from "scenes/tasks/components/boardAdd";
import Modal from "components/modal";
import useOutsideClick from "helpers/customHooks/outsideClickHook";
import {
  deleteBoard,
  archiveBoard,
  duplicateBoard,
  saveBoardMembers,
  getBoardMembers,
  getBoardActivity,
  setboardActivityAsViewed,
} from "../../../../helpers/action";
import { useHistory } from "react-router-dom";
import MenuPopup from "./menuPopup";
import BoardMember from ".././member/boardMember";
import Activity from "./activity";
import moment from "moment";

const TaskHeader = (props) => {
  let history = useHistory();
  const [openMenu, setOpenMenu] = useState(false);
  const [openAddBoard, setOpenAddBoard] = useState(false);
  const [confirmation, setConfirmation] = useState({
    open: false,
    type: "",
    confirmText: null,
    members: null,
  });
  const [confirmMessage, setConfirmMessage] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);
  const { board, boardActivityLog } = props;

  useEffect(() => {
    if (board && board.BoardId) {
      getActivities(true);
      getTeamMembers();
    }
  }, [board]);

  useEffect(() => {
    if (props.boardMembers) {
      setSelectedMembers(props.boardMembers || []);
    }
  }, [props.boardMembers]);

  const getActivities = async (load) => {
    const data = {
      page: boardActivityLog.Page, //refresh ? 0 : pageIndex + 1,
      size: 5,
      boardId: board.BoardId,
    };
    load && (await props.getBoardActivity(data));
  };

  const getTeamMembers = async () => {
    await props.getBoardMembers(board.BoardId);
  };
  const onEditClick = () => {
    setOpenAddBoard(true);
  };

  const onConfirmAction = async () => {
    if (confirmation.type === "delete") {
      await props.deleteBoard(board.BoardId);
      history.push({
        pathname: RouteEnum.TaskBoardListLinks,
      });
    } else if (confirmation.type === "archive") {
      await props.archiveBoard({ BoardId: board.BoardId, IsArchived: true });
      history.push({
        pathname: RouteEnum.TaskBoardListLinks,
      });
    } else if (confirmation.type === "member") {
      props.saveBoardMembers(board.BoardId, confirmation.members);
    } else if (confirmation.type === "duplicate") {
      await props.duplicateBoard(board.BoardId);
    }
  };
  const openConfirm = (type, confirmText = null, members = null) => {
    setConfirmation({
      open: true,
      type: type,
      confirmText: confirmText,
      members: members,
    });
  };
  const renderMenu = () => {
    return (
      <MenuPopup className="" open={openMenu} onChangeOpen={setOpenMenu}>
        <div>
          <ul>
            <li onClick={onEditClick}>
              <span>Edit</span>
            </li>
            <li
              onClick={() =>
                openConfirm("duplicate", "Wanna copy this board ?")
              }
            >
              <span>Duplicate</span>
            </li>
            <li onClick={() => openConfirm("delete")}>
              <span>Delete</span>
            </li>
            <li onClick={() => openConfirm("archive")}>
              <span>Archive</span>
            </li>
          </ul>
        </div>
      </MenuPopup>
    );
  };

  const handleMemberRemoved = async (members) => {
    openConfirm(
      "member",
      "The member will be delted from all Task. You won't be able to revert your action.",
      members
    );
  };
  const handleSelectedMemberChanged = async (members) => {
    props.saveBoardMembers(board.BoardId, members);
  };
  if (isEmpty(board)) return null;
  
  var dueDate = board.DueDate && moment(new Date(board.DueDate)).format("MMM DD");
  return (
    <div className="task-header">

      <div className="task-titles">
        <div className="task-titles-section">
          <h2>{board.BoardName}</h2>
          {board.DueDate && !board.IsRecurring && <span className="task-duedate">{dueDate}</span>}
        </div>

        <p>{board.BoardDescription}</p>
      </div>

      <div className="task-menus">
        <div className="task-menu">
          <MenuNavBar>
            <NavItem title="Team" icon="Users" count={selectedMembers.length}>
              <div className="nav-item">
                <BoardMember
                  selectedMembers={selectedMembers}
                  onMemberRemoved={handleMemberRemoved}
                  onSelectionChange={handleSelectedMemberChanged}
                />
              </div>
            </NavItem>
            <NavItem
              title="Activities"
              icon="UserEdit"
              menuPopupClassName="task-activity-popup"
              onClick={(e) => {
                props.setboardActivityAsViewed(board.BoardId);
              }}
              count={boardActivityLog.TotalNewActivity || 0}
            >
              <div className="nav-item">
                <Activity
                  getActivities={getActivities}
                  onClick={(item) =>
                    props.onOpenTaskModal &&
                    props.onOpenTaskModal({ TaskId: item.TaskId })
                  }
                />
              </div>
            </NavItem>
          </MenuNavBar>
        </div>

        <div className="task-ellipse">
          <div
            onClick={() => setOpenMenu(!openMenu)}
            className="task-ellipse-button"
          >
            <Icons justSVG name={"Ellipse"} />
          </div>
          {renderMenu()}
        </div>
      </div>

      {openAddBoard ? (
        <BoardAdd
          data={board}
          boardType={1}
          onModalClose={() => setOpenAddBoard(false)}
          open={openAddBoard}
        />
      ) : null}

      <Modal
        type="confirm"
        func={() => onConfirmAction && onConfirmAction()}
        open={confirmation.open}
        modalMessage={confirmation.confirmText}
        onModalClose={() =>
          setConfirmation({
            open: false,
            type: "",
            confirmText: null,
            members: null,
          })
        }
      />

      {/* <Modal
        type="confirm"
        func={() => onSelectedItemClicked && onSelectedItemClicked(selIndex)}
        open={open}
        modalMessage={`The member will be delted from all Task. You won't be able to revert your action. ${selIndex}`}
        onModalClose={() => setOpen(false)}
      /> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  board: state.taskReducer.board,
  boardMembers: state.taskReducer.boardMembers,
  boardActivityLog: state.taskReducer.boardActivityLog,
});
const mapDispatchToProps = {
  deleteBoard,
  duplicateBoard,
  archiveBoard,
  saveBoardMembers,
  getBoardMembers,
  getBoardActivity,
  setboardActivityAsViewed,
};
export default connect(mapStateToProps, mapDispatchToProps)(TaskHeader);
