import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton';
import A from './tableSkeleton'

function TableSkeletonMaterial(props) {
    const {noRows} = props
    return (
        <>
        <div className="skeleton-table">
            <div className="skeleton-table__header">
                <Skeleton variant="rect" width={"100%"} height={50} />
            </div>
            <div className="skeleton-table__body">
                {
                    [...Array(noRows)].map((e, i) =>(
                        <div className="skeleton-table__row">
                            <div className="skeleton-table__col">
                                <div className="skeleton-table__avatar">
                                    <Skeleton animation="wave" variant="circle" width={40} height={40} />
                                    <Skeleton animation="wave" height={25} width="110px" />
                                </div>
                            </div>
                            <div className="skeleton-table__col">
                                <Skeleton animation="wave" height={25} width="220px" />
                            </div>
                            <div className="skeleton-table__col">
                                <Skeleton animation="wave" height={25} width="120px" />
                            </div>
                            <div className="skeleton-table__col">
                                <Skeleton animation="wave" variant="circle" width={24} height={24} />
                            </div>
                            <div className="skeleton-table__col">
                                <Skeleton animation="wave" height={40} width="100px" style={{borderRadius:'10rem'}} />
                            </div>
                            <div className="skeleton-table__col">
                                <div className="flex">
                                    <Skeleton animation="wave" height={50} width="100px" />
                                    <Skeleton animation="wave" height={50} width="100px" />
                                </div>
                            </div>
                        </div>
                    ))
                }
               
            </div>
            <div className="skeleton-table__footer">
                <Skeleton variant="rect" width={"100%"} height={50} />
            </div>
        </div>
        </>

    )
}

TableSkeletonMaterial.defaultProps = {
    noRows:6
}

export default TableSkeletonMaterial
