import React, { useState } from "react";
import { FaFileContract } from "react-icons/fa";
import { connect } from "react-redux";
import { Date_Format } from "helpers/dateFormat";
import EditContractHistory from "../editContractHistory";
import { SaveProjectContract } from "../../../action";

function ContractHistory(props) {
  const [editContractModal, setEditContractModal] = useState(false);
  const [contractDetails, setContractDetails] = useState({});

  let values = props.data;

  const editContractHisory = (contract, editable) => {
    if (!editable) return;
    setEditContractModal(true);

    let contractData = {
      ProjectId: contract.ProjectRefId,
      EventEndDateEng: contract.EventEndDateEng,
      EventStartDateEng: contract.EventStartDateEng,
      EventNote: contract.EventNote,
      EventTypeId: contract.EventTypeId,
      ContractId: contract.ContractId,
      EventType: contract.EventType,
    };
    setContractDetails(contractData);
  };

  const onModalClose = () => {
    setEditContractModal(false);
  };

  return (
    <div className="form-row_body">
      {values.ContractList && values.ContractList.length > 0 ? (
        <>
          <div className="contract-block contract-block-title ">
            <span>Event </span>
            <span>Event Start Date</span>
            <span>Event End Date</span>
            <span>Note</span>
          </div>
          <div className="contract-block_row">
            {values.ContractList &&
              values.ContractList.map((contract, index) => (
                <div
                  key={index + Math.random()}
                  className="contract-block contract-block-body"
                >
                  <span>
                    {contract.EventType}
                    {/* {contract.ContractDocuments &&
                  contract.ContractDocuments.length > 0 && ( */}
                    {contract.Attachment && contract.Attachment.length > 0 && (
                      <ul className="contract_files">
                        <FaFileContract />
                        <ul className="tooltiptext nobull">
                          {contract.Attachment.map((d, index) => (
                            <li key={d.AttachmentId}>
                              {index + 1 + ". " + d.UserFileName}
                            </li>
                          ))}
                        </ul>
                      </ul>
                    )}
                  </span>
                  <span>
                    {contract &&
                      contract.EventStartDateEng &&
                      Date_Format(contract.EventStartDateEng)}
                  </span>
                  <span>
                    {contract &&
                      contract.EventEndDateEng &&
                      Date_Format(contract.EventEndDateEng)}
                  </span>
                  <span>{contract && contract.EventNote}</span>
                  {contract && (
                    <div className="edit_contract">
                      <button
                        disabled={!contract.IsEditable}
                        onClick={() =>
                          editContractHisory(contract, contract.IsEditable)
                        }
                        className="btn btn-outline "
                      >
                        Edit
                      </button>
                      <button
                        disabled={
                          !contract.IsEditable || contract.EventTypeId == 1
                        }
                        onClick={() =>
                          props.deleteContractHisory(
                            contract.ContractId,
                            contract.IsEditable
                          )
                        }
                        className="btn btn-outline"
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              ))}
          </div>
          {editContractModal && (
            <EditContractHistory
              onModalClose={onModalClose}
              reloadList={null}
              SaveProjectContract={(data, renewDocuments, onModalClose) =>
                props.editContractHistoryEvent(
                  data,
                  renewDocuments,
                  onModalClose
                )
              }
              selectedProjectForRenew={contractDetails}
            />
          )}
        </>
      ) : (
        <span>{"No history found"}</span>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  contractSaved: state.taskTrackerReducer.contractSaved,
});

export default connect(mapStateToProps, { SaveProjectContract })(
  ContractHistory
);
