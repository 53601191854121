import FormGroup from "components/form";
import { PRIMARY_DUITIES_RESPONSIBILTY_TYPE } from "constants/constants";
import isEmpty from "isEmpty";
import React, { Component } from "react";
import { GoPlus } from "react-icons/go";
import { GrCheckmark, GrClose } from "react-icons/gr";
import {
  MdArrowDownward,
  MdArrowUpward,
  MdDelete,
  MdEdit,
} from "react-icons/md";

const profileBlockType = PRIMARY_DUITIES_RESPONSIBILTY_TYPE;
class PDutiesAndResp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNameEdit: false,
      subtitleToEdits: [],
      intialBlockData: {},
    };
  }
  componentDidMount() {
    var stateCopy = this.props.state.profileBlocks.map((x) => {
      return { ...x };
    });
    const currentSection = stateCopy.find((x) => x.type === profileBlockType);
    this.setState({ intialBlockData: currentSection });
  }

  render() {
    const { isNameEdit, subtitleToEdits, intialBlockData } = this.state;
    const {
      handleInput,
      handleBlockMove,
      index,
      isViewMode,
      handleRemoveSection,
      handleAddDefaultComponent,
    } = this.props;
    const { profileBlocks, errors, initialBlocks } = this.props.state;
    const currentSection = this.props.state.profileBlocks.find(
      (x) => x.type === profileBlockType
    );

    return (
      <div className="duties-resp profile-block">
        <div className="profile-block__header justify-start">
          <span className="profile-text">{currentSection.title}</span>
          <span className="profile-type">{currentSection.type}</span>
        </div>
        <div className="profile-block__body">
          {currentSection.components.map((x, i) => {
            return (
              <>
                <div className="form-row component-header">
                  <span className="subtitle">{x.subtitle}</span>
                  <span className="ml-md subtitle-type"> {x.type}</span>
                </div>
                <div className="form-row">
                  <FormGroup
                    formName="texteditor"
                    value={x.paragraph}
                    toolbarId={"primaryDutiesAndResp" + i + 1}
                    placeholder={"Write something"}
                    name="paragraph"
                    onChange={(name, value) => {
                      this.props.handleComponentFieldChange(
                        profileBlockType,
                        x.id,
                        name,
                        value
                      );
                    }}
                    width="100%"
                  />
                </div>
              </>
            );
          })}
        </div>
      </div>
    );
  }
}

export default PDutiesAndResp;
