import React, { useState } from "react";
import { connect } from "react-redux";
import Table from "./table";
import SearchFilter from "./searchFilter";
import { FaTrash } from "react-icons/fa";
import { AiOutlineMail, AiOutlineDelete, AiOutlineCloseCircle } from "react-icons/ai";
import { TiMessage } from "react-icons/ti";
import { BiExport } from "react-icons/bi";
import { IoArrowUpCircleOutline } from "react-icons/io5";

import { BsExclamationCircle } from "react-icons/bs";
import { FiCheckCircle } from "react-icons/fi";
import TableViewActivities from "./activities";
import {
  makeCandidateFavorite,
  moveCandidateToNextStage,
} from "store/actions/recruitment/dashboard";

function TableView(props) {
  const [state, setState] = useState({
    searchByCandidateName: "",
  });
 
  const handleSearch = (name, value) => {
    let nextState = { ...state };
    nextState[name] = value;
    setState(nextState);
    props.onSearch && props.onSearch({ candidateName: value });
  };
 

  const onRowSelected = (rows) => {
    props.onMultipleCandidateSelect(rows);
  };

  return (
    <div className="name">
      <TableViewActivities
        {...props}
        // updateCandidates={() => (candidates = [])}
      
        // updateMultipleCandidateDetails={(data, history) => {
        //   props.updateMultipleCandidateDetails(data, history);
        // }}
     
      />
      <div className="recruit-tableview">
        <Table {...props} onRowSelected={onRowSelected} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  makeCandidateFavorite,
  moveCandidateToNextStage,
};
export default connect(mapStateToProps, mapDispatchToProps)(TableView);
