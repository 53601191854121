import React from "react";
import { FaEdit } from 'react-icons/fa'
import { connect } from 'react-redux';
import isEmpty from 'isEmpty';
import AddressGeoLocation from './addressGeoLocation';
import Button from 'components/button';
import Modal from 'components/modal'
import Map from 'assets/images/map_1.png'

export class AddressDetail extends React.Component {
    constructor(props) {
        super(props)
    
        this.state = {
             open:false
        }
    }
    render() {
        const {
            addressInfo: {
                PECountyName,
                PELocality,
                PEDistrict,
                PEZone,
                PEHouseNo,
                PEStreet,
                PEState,
                PEZipCode,
                PEWardNo,
                PELocalBodyName,
                PEVDCMuncipality,
                PSLocality,
                PSDistrict,
                PSZone,
                PSCountryName,
                PSVDCMuncipality,
                PSWardNo,
                PEProvinceId,
                PSProvinceId,
                PSHouseNo,
                PSLocalBodyName,
                GeoLocation,
                PSProvince,
                PEProvince,
                PSStreet,
                PSState,
            }
        } = this.props || { addressInfo : {} }
        console.log(GeoLocation)
        return (
            <div className="personal-row">
            <div className="personal-column personal-column__address">
                    <div className="personal-block">
                        <div className="personal-block__title">
                            <h2>Permanent Address</h2>
                            <Button title="Change" bg="subtle" buttonClass="personal-block__btn" />
                        </div>
                        {
                            (isEmpty(this.props.addressInfo)) || (!PEStreet && !PEDistrict && !PEZone && !PECountyName) ? (
                                <div className="profile-block__title nodata">
                                    <h2>No Address Detail Available</h2>
                                </div>
                            ):
                            (
                                <div className="personal-block__body">
                                    {/* <div className="personal-block__icon">
                                        <FaEdit />
                                    </div> */}
                                    <div className="personal-block__div">
                                        <div className="value">
                                            {PEHouseNo && PEHouseNo}  {PELocality && PEHouseNo ? ' - ' :''} {PEStreet && PEStreet} {PEStreet && PELocality ?', ' :''} {PELocality && PELocality}
                                        </div>
                                    </div>
                                    <div className="personal-block__div">
                                        <div className="value">
                                            {PELocalBodyName && PELocalBodyName}
                                        </div>
                                    </div>
                                    <div className="personal-block__div">
                                        <div className="value">
                                            {PEVDCMuncipality && PEVDCMuncipality} {PEVDCMuncipality && PEWardNo ?' - ' : '-' } {PEWardNo && PEWardNo}
                                        </div>
                                        
                                    </div>
                                    <div className="personal-block__div">
                                        <div className="value">
                                        {PEDistrict && PEDistrict} {PEDistrict && PEProvince ? ', ' :'-'} {PEProvince && PEProvince} {PEProvinceId && PEProvince ? '- ' :''} {PEProvinceId && PEProvinceId}
                                        </div>
                                        
                                    </div>
                                    <div className="personal-block__div">
                                        <div className="value">
                                            {PECountyName ?PECountyName : '-'}
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                            )
                        }
                        
                    </div>
                </div>
            <div className="personal-column personal-column__address">
                    <div className="personal-block">
                        <div className="personal-block__title">
                            <h2>Current Address</h2>
                            <Button title="Change" bg="subtle" buttonClass="personal-block__btn" />
                        </div>
                        {
                            isEmpty(this.props.addressInfo) || (!PSStreet && !PSDistrict && !PSZone && !PSCountryName) ? (
                                <div className="profile-block__title nodata">
                                    <h2>No Address Detail Available</h2>
                                </div>
                            ):
                            (
                                <div className="personal-block__body">
                                    {/* <div className="personal-block__icon">
                                        <FaEdit />
                                    </div> */}
                                    <div className="personal-block__div">
                                        <div className="value">
                                           {PSHouseNo && PSHouseNo} {PSStreet && PSHouseNo ? ' - ' :''} {PSStreet && PSStreet } {PSStreet && PSLocality ? ', ' : ''} {PSLocality && PSLocality}
                                        </div>
                                    </div>
                                    <div className="personal-block__div">
                                        <div className="value">
                                            {PSLocalBodyName && PSLocalBodyName}
                                        </div>
                                    </div>
                                    <div className="personal-block__div">
                                        <div className="value">
                                            {PSVDCMuncipality && PSVDCMuncipality} {PSVDCMuncipality && PSWardNo ?' - ' :'-' }  {PSWardNo && PSWardNo}
                                        </div>
                                        
                                    </div>
                                    <div className="personal-block__div">
                                        <div className="value">
                                        {PSDistrict && PSDistrict} {PSDistrict && PSProvince ? ', ' :''} {PSProvince && PSProvince } {PSProvinceId && PSProvince ? '- ' :''} {PSProvinceId && PSProvinceId}
                                        </div>
                                        
                                    </div>
                                    <div className="personal-block__div">
                                        <div className="value">
                                            {PSCountryName ? PSCountryName : '-'}
                                        </div>
                                        
                                    </div>
                                    {
                                        GeoLocation && (
                                            <div className="personal-block__address-location">
                                                <div className="personal-block__address-map">
                                                    <div className="personal-block__address-map-image">
                                                        <img src={Map} alt="Map Image"/>
                                                    </div>
                                                    <div className="personal-block__address-map-btn">
                                                        <Button 
                                                            bg="highlight"
                                                            title="View Map"
                                                            // onClick={()=>this.setState({open:true})}
                                                            onClick={()=>{
                                                                const geoSplit = GeoLocation ? GeoLocation.split(",") : [];
                                                                if(geoSplit.length > 0){
                                                                    const lat = parseFloat(geoSplit[0]);
                                                                    const long = parseFloat(geoSplit[1]);
                                                                    window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${long}`)
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                {/* <Modal
                                                    open={this.state.open}
                                                    onModalClose={()=>this.setState({open:false})}
                                                    title="GeoLocation"
                                                    width="60%"
                                                >
                                                    <div className="personal-block__address-map-geo">
                                                        <AddressGeoLocation GeoLocation={GeoLocation} />
                                                    </div>
                                                </Modal> */}
                                            </div>
                                        )
                                    }

                                </div>
                            
                            )
                        }

                    </div>
                </div>
            
            </div>
            
        );
    }
};

const mapStateToProps = state => ({
    addressInfo: state.employeeDashboard.employeeDetail && state.employeeDashboard.employeeDetail.EmployeeAddress || {}
});

export default connect(mapStateToProps, null)(AddressDetail);
