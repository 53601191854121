import React from "react";
import { FaStar } from "react-icons/fa";

export default function Favorite(props: any) {
  const { data } = props || {};

  const onFavorite = (fav, obj) => {
    const { JobId, CandidateId } = obj;
    const data = {
      jobId: JobId,
      candidateId: CandidateId,
      isFavourite: fav,
    };
    props.makeCandidateFavorite(data);
  };

  return (
    <div className="favorite">
      <div
        onClick={() => onFavorite(!data.IsFavorite, data)}
        className={`favorite-div ${data.IsFavorite ? "active" : ""}`}
      >
        <FaStar />
      </div>
    </div>
  );
}
