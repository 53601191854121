import React, { useState, useEffect } from "react";
import Form from "components/form";
import { jobsTypeNewList } from "../enums";
import RecruitmentPieChart from "./common/RecruitmentPieChart";
import RecruitmentFunnelChart from "./common/RecruitmentDashboardInfo/RecruitmentFunnelChart";
import { GetDashboardInfo } from "store/actions/recruitment/dashboard";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/button";
import isEmpty from "isEmpty";
import Empty from "assets/images/emtpy-jobspanel.svg";
import RecruitmentDashboardInfo from "./common/RecruitmentDashboardInfo";
import "./recruitment.scss";
import { RenderModal } from "./createJob";
import classNames from "classnames";
import { getDateISO } from "components/calendar/helpers/engCalendar";
import moment from "moment";
import JobList from "./common/RecruitmentDashboardInfo/jobList"

const RecruitmentDashboard = (props: any) => {
  interface jobDetailsInterface {
    Applicants?: number;
    Disqualified?: number;
    Exam?: number;
    Hired?: number;
    Interview?: number;
    JobOffer?: number;
    Openings?: number;
    Rejected?: number;
    ShortListed?: number;
    Sourced?: number;
  }
  const [selectedJob, setSelectedJob] = useState(null)

  const [searchJobParam, setSearchJobParam] = useState({ jobTitle: "", jobStartDate: "", type: "" });

  const [selectedJobType, setSelectedJobType] = useState("active");
  const [jobDetails, setJobDetails] = useState([]);
  const [jobStageDetail, setJobStageDetail] = useState<jobDetailsInterface>({});
  const [candidates, setCandidates] = useState([]);
  const [publicRecruitmentBaseUrl, setPublicRecruitmentBaseUrl] = useState("");
  const [baseUrls, setBaseUrls] = useState("");
  const [createJobModal, setCreateJobModal] = useState(false);

  const dispatch = useDispatch();
  const dashboardInfo = useSelector(
    (state: any) => state.recruitment_dashboardReducer.dashboardInfo
  );
  const auth = useSelector((state: any) => state.auth);

  console.clear();
  console.log("auth", auth);
  const { Applicants, Hired, Openings, ShortListed } = jobStageDetail;

  const handleChange = (type: any): void => {
    setSelectedJobType(type);
    dispatch(GetDashboardInfo(type));
  };

  useEffect(() => {
    const base = auth.baseUrl ? auth.baseUrl.NetFrameworkAppBaseUrl : "";
    const publicRecruitmentBaseUrl = auth.publicRecruitmentBaseUrl ? auth.publicRecruitmentBaseUrl.PublicRecruitmentBaseUrl : "";
    setBaseUrls(base);
    setPublicRecruitmentBaseUrl(publicRecruitmentBaseUrl);
    // dispatch(GetDashboardInfo(selectedJobType));
  }, []);

  useEffect(() => {
    if (!isEmpty(dashboardInfo)) {
      const { Data } = dashboardInfo;
      //  setSearchJobParam({ jobTitle: "", jobStartDate: "" });
      setJobDetails(Data?.JobDetails);
      setJobStageDetail(Data?.JobStageDetail);
      setCandidates(Data?.Candidates);
    }
  }, [dashboardInfo]);


  const handleJobSearchParamChange = (name, value) => {
    let param = { ...searchJobParam };
    param[name] = value;
    setSearchJobParam(param);
    filterJob(param);
  };

  const filterJob = (obj) => {
    const { Data } = dashboardInfo;
    let jobDetails = Data.JobDetails || [];
    const { jobTitle, jobStartDate } = obj;
    if (!isEmpty(jobTitle)) {
      jobDetails = jobDetails.filter(
        (x) =>
          x.ExternalJobTitle.trim()
            .toLowerCase()
            .includes(jobTitle.trim().toLowerCase()) || x.JobId == parseInt(jobTitle)
      );
    }
    if (!isEmpty(jobStartDate)) {
      jobDetails = jobDetails.filter((x) => moment(x.StartDate) <= moment(jobStartDate));
    }
    console.log(jobDetails);
    setJobDetails(jobDetails);
  };

  const convertDateISO = (date) => {
    return date ? getDateISO(new Date(date)) : "";
  };

  return (
    <div className="common-container dashboard">
      <header className="dashboard__header">
        <div className="space-between panel-section">
          <h3 className="tertiary-title recruitment_title">Recruitment Dashboard</h3>
        </div>
      </header>
      <main className="recruitment_top_section">
        <div className="recruitment-btns">
          <Button
            type="button"
            bg="white"
            title="Create New Job"
            onClick={() => setCreateJobModal(true)}
          />
          {/* <Button
            type="button"
            bg="white"
            title="Open Jobs"
            onClick={() => props.history.push(`/recruit/dashboard/opening`)}
          /> */}
          {!isEmpty(publicRecruitmentBaseUrl) &&
            <Button type="button" bg="white" title="Career Page"
              onClick={() => window.open(publicRecruitmentBaseUrl, '_blank')} />
          }
        </div>
      </main>
      {
        <RenderModal
          onModalClose={() => {
            setCreateJobModal(false);
          }}
          history={props.history}
          modalOpen={createJobModal}
        />
      }

      <main className="dashboard__body">
        <div className="recruitment">
          <div className="recruitment-navigation">
            <div className="recruitment-tabs">
              <ul>
                <li
                  className={classNames({ active: selectedJobType === "active" })}
                  onClick={() => handleChange("active")}
                >
                  <span>Active</span>
                </li>
                <li
                  className={classNames({ active: selectedJobType === "closed" })}
                  onClick={() => handleChange("closed")}
                >
                  <span>Closed</span>
                </li>
              </ul>
            </div>
            <div className="recruitmentinfo-blocks joblists">
              <JobList jobType={selectedJobType} setSelectedJob={setSelectedJob} />
            </div>
          </div>

          <RecruitmentDashboardInfo selectedJob={selectedJob} />
        </div>
      </main>
    </div>
  );
};

export default RecruitmentDashboard;
