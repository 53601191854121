import Loader from "components/loaders";
import Modal from "components/modal";
import {ADMIN_ROLE_ID} from "constants/constants";
import {RoleId} from "constants/userDetails";
import {AnimateShowHide} from "helpers/animation";
import isEmpty from "isEmpty";
import {EmployeeID} from "constants/userDetails";
import React from "react";
import {connect} from "react-redux";
import CommonHeaderSection from "../../../admin/commonHeaderSection";
import {
  cancelTravelRequest,
  getBillsAndClaimsList,
  getOtherExpenseList,
  getPendingSettlement,
  getTravelCategoryList,
} from "../actions";
import TravelSettlement from "./form/travelSettlement";
import PendingSettlementBlock from "./pendingSettlementBlock";

class PendingSettlement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRequestId: null,
      rowsPerPage: 10,
      pageIndex: 0,
      isAsPlanned: true,
      isModalShow: false,
      isViewMode: false,
    };
    this.handleRowChange = this.handleRowChange.bind(this);
    this.handleIndexChange = this.handleIndexChange.bind(this);
    this.handleSettlementClick = this.handleSettlementClick.bind(this);
    this.handleViewSettlement = this.handleViewSettlement.bind(this);
  }
  componentDidMount() {
    //if (!this.props.initialLoad) {
    this.props.getPendingSettlement({
      PageIndex: 0,
      PageSize: 10,
      companyInformation: this.props.companyInformation,
    });

    this.props.getTravelCategoryList();
    //this.props.getOtherExpenseList();
    //this.props.getBillsAndClaimsList();
    // }
  }
  componentDidUpdate = (prevProps, prevState) => {
    const {rowsPerPage, pageIndex} = this.state;
    if (rowsPerPage !== prevState.rowsPerPage || pageIndex !== prevState.pageIndex) {
      const searchData = {
        PageIndex: this.state.pageIndex,
        PageSize: this.state.rowsPerPage,
        companyInformation: this.props.companyInformation,
      };
      this.props.getPendingSettlement(searchData);
    }
  };
  handleRowChange(num) {
    this.setState({rowsPerPage: num});
  }
  handleIndexChange(num) {
    this.setState({pageIndex: num});
  }
  handleSettlementClick(requestId, isAsPlanned, EmployeeId) {

    let empId = !isEmpty(EmployeeId) && EmployeeId > 0 ? EmployeeId : EmployeeID()
    this.setState(
      {
        EmployeeId: empId,
      },
      () => {
        this.setState({
          selectedRequestId: requestId,
          isAsPlanned,
          isViewMode: false,
        });
      }
    );
  }
  handleViewSettlement(requestId) {
    this.setState({
      selectedRequestId: requestId,
      isViewMode: true,
    });
  }
  handleConfirmCancelRequest = (id) => {
    debugger;
    this.setState({
      selectedRequestId: id,
      isModalShow: true,
    });
  };

  handleCancelRequest = (args) => {
    this.props.cancelTravelRequest(this.state.selectedRequestId);
  };
  closeModal = () => {
    this.setState({isModalShow: false});
  };
  renderBody() {
    const {
      spinnerLoading,
      loading,
      isSettlementActive,
      travelRequestDetails,
      pendingSettlement,
      cancelledList,
      pendingSettlementSearchedPage,
    } = this.props.travelSettlementReducer;
    let {isLoading} = this.props.travelInformationReducer;
    console.log("props==>", travelRequestDetails, isSettlementActive);
    return (
      <div className="travel-settlement">
        {pendingSettlement.loading && (
          <Loader
            type="skeleton"
            name="card"
            cardType="travel"
            noRows={10}
            loading={pendingSettlement.loading}
          />
        )}
        {!pendingSettlement.loading
          ? !isSettlementActive && (
              <PendingSettlementBlock
                rows={pendingSettlement.value}
                pageIndex={this.state.pageIndex}
                rowsPerPage={this.state.rowsPerPage}
                handleRowChange={this.handleRowChange}
                handleIndexChange={this.handleIndexChange}
                handleSettlementClick={this.handleSettlementClick}
                handleConfirmCancelRequest={this.handleConfirmCancelRequest}
                handleViewSettlementClick={this.handleViewSettlement}
                cancelledList={cancelledList}
                searchedPage={pendingSettlementSearchedPage}
              />
            )
          : null}
        {/* <Table
          pageIndex={this.state.pageIndex}
          rowsPerPage={this.state.rowsPerPage}
          handleRowChange={this.handleRowChange}
          handleIndexChange={this.handleIndexChange}
          handleSettlementClick={this.handleSettlementClick}
          handleConfirmCancelRequest={this.handleConfirmCancelRequest}
          handleViewSettlementClick={this.handleViewSettlement}
        /> */}
        <AnimateShowHide
          show={isSettlementActive}
          config={{duration: 700}}
          fromStyle={{
            opacity: 0,
            transform: "translate3d(0,-500%,0)",
          }}
          toStyle={{
            opacity: 1,
            transform: isSettlementActive
              ? "translate3d(0,0,0)"
              : "translate3d(0,-500%,0)",
          }}
        >
          {!travelRequestDetails.loading && !isEmpty(travelRequestDetails.value) ? (
            <TravelSettlement
              RequestId={this.state.selectedRequestId}
              EmployeeId={this.state.EmployeeId}
              IsAsPlanned={this.state.isAsPlanned}
              isViewMode={this.state.isViewMode}
            />
          ) : null}
        </AnimateShowHide>
        <Modal
          title=""
          open={this.state.isModalShow}
          onModalClose={this.closeModal}
          type="confirm"
          func={() => this.handleCancelRequest()}
          className="modal-travel-category"
        ></Modal>
      </div>
    );
  }
  render() {
    const selectedTabId = !isEmpty(this.props.location.state)
      ? this.props.location.state.selectedTabId
      : null;
    const roleId = RoleId();
    return (
      <>
        {roleId == ADMIN_ROLE_ID ? (
          <CommonHeaderSection
            renderBody={this.renderBody()}
            selectedTabId={selectedTabId}
            pathName="Pending Settlement"
          />
        ) : (
          <div className="travel__filter-body">
            <div className="travel-requestMainBody">{this.renderBody()}</div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  travelSettlementReducer: state.travelSettlementReducer,
  travelInformationReducer: state.travelInformationReducer,
  pendingSettlement: state.travelSettlementReducer.pendingSettlement,
  travelCategoryList: state.travelInformationReducer.travelCategoryList,
  initialLoad: state.travelSettlementReducer.pendingSettlementInitialLoad,
  companyInformation: state.commonReducer.companyInformation,
});

export default connect(mapStateToProps, {
  getPendingSettlement,
  getTravelCategoryList,
  getOtherExpenseList,
  getBillsAndClaimsList,
  cancelTravelRequest,
})(PendingSettlement);
