import Http from "src/utils/http/http";
import Action from "src/utils/http/actionUtility";
import environment from "environment";
import types from "constants/types";

export const getSbiLoanSettingList = () => async (dispatch) => {
  const res = Http.get(environment.api.sbiLoanSettingList);
  const actionConfig = {
    dispatch,
    actionType: types.GET_SBI_LOAN_SETTING_LIST,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const getSbiLoanTypeList = (param) => async (dispatch) => {
  const res = Http.get(environment.api.sbiLoanTypeList + `${param}`);

  const actionConfig = {
    dispatch,
    actionType: types.GET_SBI_LOAN_TYPE_LIST,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const updateSbiLoanSetting = (data) => async (dispatch) => {
  const res: any = Http.post(environment.api.sbiGeneralLoanSave, data);
  console.log(`res`, res);
  const actionConfig = {
    dispatch,
    actionType: types.UPDATE_SBI_LOAN_SETTING,
    effect: res,
    message: "Loan Settings has been updated successfully.",
    isLoading: true,
  };
  const response = await Action.createThunkEffect(actionConfig);
};

export const updateSbiSettingAmountLimit = (loanType, data) => async (dispatch) => {
  const res = Http.post(
    environment.api.sbiSettingAmountLimit + "/?loanType=" + loanType,
    data
  );
  const actionConfig = {
    dispatch,
    actionType: types.UPDATE_SBI_SETTING_AMOUNT_LIMIT,
    effect: res,
    isLoading: true,
    message: "Loan Amount Limit has been created successfully.",
  };
  const response = await Action.createThunkEffect(actionConfig);
};

export const getSbiSettingApprovalFlow = (loanType, flowType) => async (dispatch) => {
  const res = Http.get(
    environment.api.sbiSettingApprovalFlow + `?loanType=${loanType}&flowType=${flowType}`
  );

  const actionConfig = {
    dispatch,
    actionType: types.GET_SBI_SETTINGS_APPROVAL_FLOW,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const postSbiSettingApprovalFlow = (loanType, data) => async (dispatch) => {
  const res = Http.post(
    environment.api.sbiSettingApprovalFlow + "/?loanType=" + loanType,
    data
  );
  const actionConfig = {
    dispatch,
    actionType: types.UPDATE_SBI_SETTING_AMOUNT_LIMIT,
    effect: res,
    isLoading: true,
    message: "Approval flow setting has been created successfully.",
  };
  const response = await Action.createThunkEffect(actionConfig);
};

export const getEmployeeListing = (search) => async (dispatch) => {
  const res = Http.get(environment.api.employeeListForSbiLoan + `?searchTerm=${search}`);

  const actionConfig = {
    dispatch,
    actionType: types.GET_SBI_ALL_EMPLOYEES_SPECIFIC,
    effect: res,
    isJustData: true,
    isLoading: false,
  };
  await Action.createThunkEffect(actionConfig);
};

export const getSbiLoanStatusHistory =
  (search, data, isExport) =>
  async (dispatch) => {
    // if (isExport)
      // await dispatch({ type: types.EXPORT_SBI_LOAN_STATUS_HISTORY, payload: []});
    let alldatas = {
      ...search,
      loanType: data.value ?? 0,
    };
    console.log("alldatas", alldatas);
    const res: any = await Http.post(environment.api.sbiLoanStatusHistory, alldatas);

    const actionConfig = {
      dispatch,
      actionType: isExport
        ? types.EXPORT_SBI_LOAN_STATUS_HISTORY
        : types.GET_SBI_LOAN_STATUS_HISTORY,
      effect: res,
      isLoading: true,
    };
    await Action.createThunkEffect(actionConfig);
    debugger
    if (res.Status && isExport) return res.data.Data;
  };

export const getSbiLoanApprovers =
  ({EmployeeId, LoanType, FlowType, CurrentStep}) =>
  async (dispatch) => {
    const res: any = await Http.get(
      environment.api.sbiLoanSettingsApprovers +
        `?employeeId=${EmployeeId}&loanType=${LoanType}&flowType=${FlowType}&currentStep=${CurrentStep}`
    );

    const actionConfig = {
      dispatch,
      actionType: types.GET_SBI_LOAN_APPROVERS_LIST,
      effect: res,
      message: "",
      isLoading: true,
      isJustData: true,
    };
    await Action.createThunkEffect(actionConfig);
  };

export const forwardSbiLoans = (data) => async (dispatch) => {
  const res: any = await Http.post(environment.api.forwardSbiLoan, data);

  const actionConfig = {
    dispatch,
    actionType: types.FORWARD_SBI_LOAN,
    effect: res,
    message: "Loan has been Forwarded successfully.",
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
  return res.data.MessageType === "Success"

};

export const rejectSbiLoan = (data) => async (dispatch) => {
  const res: any = await Http.post(environment.api.rejectSbiLoan, data);

  const actionConfig = {
    dispatch,
    actionType: types.REJECT_SBI_LOAN,
    effect: res,
    message: "Loan has been Rejected successfully.",
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
  debugger
  return res.data.MessageType === "Success"
  // if (res.data.MessageType == "Success")return true
};

export const getSbiLoanType = () => async (dispatch) => {
  const res: any = Http.get(environment.api.sbiLoanTypes);
  const actionConfig = {
    dispatch,
    actionType: types.GET_SBI_LOAN_TYPES,
    effect: res,
    isLoading: true,
    isJustData: true,
  };
  await Action.createThunkEffect(actionConfig);
};
