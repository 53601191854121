import { createMuiTheme } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
} from "@syncfusion/ej2-react-grids";
import isEmpty from "isEmpty";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  deleteAllowanceExpense,
  loadAllowanceExpense,
  setEditMode,
} from "./actions";
import Button from "components/button";
import Icons from "components/icons";

const theme = createMuiTheme();

export class Table extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editable: [],
      note: "",
    };
    this.tablerow = [];
    this.template = this.gridTemplate;
  }

  gridTemplate(props) {
    return (
      <div className="btn-col">
        <Button
          title="Edit"
          bg="primary"
          type="button-outline"
          onClick={() => this.handleEditRecord(props)}
          buttonType={"icon-button"}
          leftIcon={<Icons name="Edit" />}
        />
        <Button
          title="Delete"
          bg="primary"
          type="button-outline"
          onClick={() => this.handleDelRecord(props)}
          buttonType={"icon-button"}
          leftIcon={<Icons name="Delete" />}
        />
        {/* <button
          className="btn-col__edit btn"
          onClick={() => this.handleEditRecord(props)}
        >
          Edit
        </button>
        <button
          className="btn-col__del btn"
          onClick={() => this.handleDelRecord(props)}
        >
          Delete
        </button> */}
      </div>
    );
  }

  handleChangePage = (event, newPage) => {
    this.props.handleIndexChange(newPage);
  };

  handleChangeRowsPerPage = (event) => {
    this.props.handleIndexChange(0);
    this.props.handleRowChange(event.target.value);
  };
  handleAddNew = () => {
    const data = {
      AllowanceId: 0,
      IsAllowance: "true",
      Name: "",
      CalcMethod: null,
      IsBillRequired: false,
    };
    this.handleModalOpen(data);
  };
  handleEditRecord = ({
    AllowanceId,
    IsAllowance,
    Name,
    CalcMethod,
    IsBillRequired,
  }) => {
    const data = {
      AllowanceId,
      IsAllowance,
      Name,
      CalcMethod,
      IsBillRequired,
    };
    this.handleModalOpen(data);
    this.props.setEditMode();
  };

  handleDelRecord = (args) => {
    this.props.handleConfirmDelRecord(args.AllowanceId);
  };

  handleModalOpen = (data) => {
    this.props.loadAllowanceExpense(data);
    this.props.handleModalShow(true, "normal");
  };

  render() {
    const {
      allowanceExpense,
      searchedPage,
      loading,
    } = this.props.allowanceExpenseReducer;
    return (
      <div>
        <div className="panel white space-between">
          <div className="flex">
            <h4 className="page-title">Allowance or Expense</h4>
            {/* <button className="btn-add" onClick={this.handleAddNew}>
              Add New
            </button> */}
            <Button
              title=" Add New"
              bg="primary"
              type="button-outline"
              onClick={this.handleAddNew}
              buttonType={"icon-button"}
              leftIcon={<Icons name="Add" />}
            />
          </div>
          {/* <div>
                <a onClick={()=>this.toolbarClick('grid_excelexport')} className="btn-text btn-text__white">Export</a>
            </div> */}
        </div>
        <div className="arrear-table">
          {loading ? (
            <div className="skeleton-table">
              <Skeleton variant="rect" width={"100%"} height={55} />
              <Skeleton variant="rect" width={"100%"} height={55} />
              <Skeleton variant="rect" width={"100%"} height={55} />
              <Skeleton variant="rect" width={"100%"} height={55} />
              <Skeleton variant="rect" width={"100%"} height={55} />
              <Skeleton variant="rect" width={"100%"} height={55} />
              <Skeleton variant="rect" width={"100%"} height={55} />
            </div>
          ) : (
            <div className="expense_allowance-grid">
              <GridComponent
                dataSource={isEmpty(allowanceExpense) ? null : allowanceExpense}
                className="custom-grid"
                toolbar={this.toolbar}
                //  height={!isEmpty(searchedLeaveAbsence)?"500":'80'}
                id="grid"
                allowExcelExport={true}
                ref={(g) => (this.grid = g)}
              >
                <ColumnsDirective>
                  <ColumnDirective field="AllowanceId" visible={false} />
                  <ColumnDirective field="Name" headerText="NAME" width="100" />
                  <ColumnDirective field="Type" headerText="TYPE" width="100" />
                  <ColumnDirective field="IsAllowance" visible={false} />
                  <ColumnDirective
                    field="Calculation"
                    headerText="CALCULATION"
                    width="80"
                  />
                  <ColumnDirective field="CalcMethod" visible={false} />
                  <ColumnDirective
                    field="BillRequired"
                    headerText="BILL REQUIRED"
                    width="80"
                  />
                  <ColumnDirective field="IsBillRequired" visible={false} />
                  <ColumnDirective
                    field=""
                    width="80"
                    template={this.template.bind(this)}
                  />
                </ColumnsDirective>
              </GridComponent>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Table.propTypes = {
  loadAllowanceExpense: PropTypes.func.isRequired,
  setEditMode: PropTypes.func.isRequired,
  allowanceExpenseReducer: PropTypes.object.isRequired,
  deleteAllowanceExpense: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  allowanceExpenseReducer: state.allowanceExpenseReducer,
});

export default connect(mapStateToProps, {
  loadAllowanceExpense,
  setEditMode,
  deleteAllowanceExpense,
})(Table);
