import React from "react";
import {Link} from "react-router-dom";
import {IoMdTime} from "react-icons/io";
import Icons from "components/icons";
import SvgIcons from "components/svgIcons";
import isEmpty from "isEmpty";
import classnames from "classnames";
import RouteEnum from "constants/routeEnum";
import {FaRegSmile} from "react-icons/fa";
import {ReactComponent as LeaveIcon} from "assets/images/icons/leave.svg";
import {ReactComponent as TimeIcon} from "assets/images/icons/time.svg";
import {ReactComponent as OvertimeIcon} from "assets/images/icons/overtime.svg";
import {ReactComponent as AllowanceIcon} from "assets/images/icons/allowance.svg";
import {ReactComponent as TravelIcon} from "assets/images/icons/travel.svg";
import {ReactComponent as CheckIcon} from "assets/images/icons/check.svg";
import {ReactComponent as DoubleCheckIcon} from "assets/images/icons/check-double.svg";
import Loaders from "components/loaders";
import {IoIosAddCircle} from "react-icons/io";
import {AiFillMinusCircle} from "react-icons/ai";
import {getmyUpDates} from "./getMyUpdates";

function MyPage({RequestCount, MyUpdate, dashboardLoding}) {
  // const reducer = (accumulator, currentValue) =>
  //   accumulator && !isEmpty(MyUpdate[currentValue]);
  // const isUpdateValue =
  //   !isEmpty(MyUpdate) && Object.keys(MyUpdate).reduce(reducer, true);
  var isAllRequestCountZero;
  var isAllUpdateFieldEmpty;
  if (RequestCount) {
    isAllRequestCountZero = RequestCount.every(function (key) {
      return key["Total"] === 0;
    });
  }
  if (MyUpdate) {
    isAllUpdateFieldEmpty = Object.values(MyUpdate).every((x) => isEmpty(x));
  }
  let myUpdateList = MyUpdate && !isAllUpdateFieldEmpty ? getmyUpDates(MyUpdate) : [];

  return (
    <div className="mypage">
      <div className="mypage-section">
        <div className="bloch__title">To Do</div>
        <div className="mypage-section-grid">
          {dashboardLoding ? (
            <Loaders
              name="card"
              type="skeleton"
              noRows={4}
              cardType="dashboard"
              loading={true}
            />
          ) : !isAllRequestCountZero ? (
            RequestCount.map((req, index) => {
              if (req.Total === 0) return null;
              return (
                <Link
                  to={{
                    pathname:
                      req.RequestType === "Travel"
                        ? RouteEnum.TeamTravelRequestPath
                        : req.RequestType === "Settlement"
                        ? RouteEnum.SettlementRequestPath
                        : RouteEnum.EmployeeRequestReviewPath,
                    state: {RequestType: req.RequestType},
                  }}
                  key={index}
                >
                  <div className="mypage-section-block">
                    <div className="mypage-section-block-name">{req.Title}</div>
                    <div className="mypage-section-block-desc">
                      <div className="mypage-section-block-desc-icon">
                        {/* <Icons name={req.RequestType} size={40} /> */}
                        <SvgIcons
                          name={req.RequestType}
                          size={30}
                          className="request-icon" /*  styles={{"height": "30px"}}  */
                        />
                      </div>
                      <div className="mypage-section-block-desc-detail">
                        <div className="mypage-section-block-desc-detail-int">
                          {req.Total}
                        </div>
                        <div className="mypage-section-block-desc-detail-text">
                          requests
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })
          ) : (
            <div className="no-found-container" style={{width: "190px"}}>
              <div className="flex-column content">
                <span className="">All clear</span>
                <span className="mt-xsm flex">
                  No pending work <FaRegSmile className="ml-sm text-xbg " />
                </span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mypage-section">
        <div className="bloch__title">My Update</div>
        {/* <div className="mypage-section-grid"> */}
        {dashboardLoding ? (
          <Loaders
            name="card"
            type="skeleton"
            noRows={3}
            cardType="dashboard2"
            loading={true}
          />
        ) : !isAllUpdateFieldEmpty ? (
          <UpdateItem myUpdateList={myUpdateList} />
        ) : (
          // <>
          //   <UpdateItem
          //     title="UnApproved Leave"
          //     data={MyUpdate?.UnApprovedLeave}
          //     link={MyRequestPath}
          //     requestType="Leave"
          //     Icon={LeaveIcon}
          //   />

          //   <UpdateItem
          //     title="UnApproved Time"
          //     data={MyUpdate?.UnApprovedTime}
          //     link={MyRequestPath}
          //     requestType="Time"
          //     Icon={TimeIcon}
          //   />

          //   <UpdateItem
          //     title="UnApproved Overtime"
          //     data={MyUpdate?.UnApprovedOverTime}
          //     link={MyRequestPath}
          //     Icon={AllowanceIcon}
          //     requestType="Overtime"
          //   />

          //   <UpdateItem
          //     title="UnApproved Allowance"
          //     data={MyUpdate?.UnApprovedAllowance}
          //     link={MyRequestPath}
          //     Icon={OvertimeIcon}
          //     requestType="Allowance"
          //   />

          //   <UpdateItem
          //     title="Approved Leave"
          //     data={MyUpdate?.ApprovedLeave}
          //     link={MyRequestHistoryPath}
          //     requestType="Leave"
          //   />
          //   <UpdateItem
          //     title="Approved Time"
          //     data={MyUpdate?.ApprovedTime}
          //     link={MyRequestHistoryPath}
          //     requestType="Time"
          //   />
          //   <UpdateItem
          //     title="Approved OverTime"
          //     data={MyUpdate?.ApprovedOverTime}
          //     link={MyRequestHistoryPath}
          //     requestType="Overtime"
          //   />
          //   <UpdateItem
          //     title="Approved Allowance"
          //     data={MyUpdate?.ApprovedAllowance}
          //     link={MyRequestHistoryPath}
          //     requestType="Allowance"
          //     Icon={AllowanceIcon}
          //   />
          //   <UpdateItem
          //     title="Settlement Request"
          //     data={MyUpdate?.SettlementRequest}
          //     link={PendingSettlementPath}
          //     requestType="Settlement"
          //   />
          //   <UpdateItem
          //     title="Travel Request"
          //     data={MyUpdate?.TravelRequest}
          //     link={MyTravelRequestPath}
          //     requestType={"Travel"}
          //   />
          // </>
          <div className="no-found-container" style={{width: "190px"}}>
            <span className="no-found">No Update Found</span>
          </div>
        )}
        {/* </div> */}
      </div>
    </div>
  );
}

export const UpdateItem = ({myUpdateList}) => {
  if (isEmpty(myUpdateList)) {
    return null;
  }
  const statusClasses = (text) =>
    classnames({
      "mypage-section-block-name": true,
      "text-pink": text === "Pending" || text === "Approval Pending",
      "text-green": text === "Approved",
      "text-blue": text === "Reviewed",
      "text-orange": text === "Recommended",
    });

  return (
    <div className="mypage-section__container">
      {/* <h3 className="tertiary-title">{title}</h3> */}
      <div className="mypage-section-grid">
        {myUpdateList &&
          myUpdateList?.map((item, index) => (
            <Link
              to={{
                pathname: item.link,
                state: {RequestType: item.requestType},
              }}
              key={index}
            >
              <div className="mypage-section-block">
                <div className={statusClasses(item.data.Status)}>
                  {item.data.Status}
                  <span className="mypage-section-block-name-icon">
                    {item.data.Status === "Approved" ? (
                      <SvgIcons name={"Doublecheck"} className="status-icon text-green" />
                    ) : item.data.Status === "Recommended" ? (
                      <SvgIcons name={"Check"} className="status-icon text-orange" />
                    ) : (
                      <Icons name="Time" />
                    )}
                  </span>
                </div>
                <div className="mypage-section-block-desc">
                  <div className="mypage-section-block-desc-detail items-start wd-100">
                    <div className="mypage-section-block-desc-detail-text text-title flex wd-100 justify-between">
                      {item.data.Title}
                      {item.data.Title === "Compensatory Leave" &&
                        item.data.CompensatorType &&
                        (item.data.CompensatorType === "Add" ? (
                          <IoIosAddCircle className="comp-leave-plus-icon" />
                        ) : (
                          <AiFillMinusCircle className="comp-leave-minus-icon" />
                        ))}
                    </div>

                    <div className="flex justify-between wd-100">
                      <div className="flex-column">
                        <div className="mypage-section-block-desc-detail-text text-time">
                          <span>{item.data.FromDateEng}</span>
                          <span>{" - "}</span>
                          <span> {item.data.ToDateEng} </span>
                        </div>
                        {item.data.Place && (
                          <div className="mypage-section-block-desc-detail-text text-place">
                            <span>{item.data.Place}</span>
                          </div>
                        )}
                      </div>
                      <div className="mypage-section-block-desc-icon">
                        <SvgIcons name={item.requestType} className="request-icon" />
                        {/* <Icon className="request-icon" /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default MyPage;
