import React, { Component } from "react";
import "../../../scss";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import { MdAdd } from "react-icons/md";
import { FaRegFilePdf } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";
import ClientProjectTabs from "./projectTabs";
import AnimatedForm from "components/common/animatedForm";
import { InputValidator } from "helpers/inputValidator";
import isEmpty from "isEmpty";
import { isDateTodayOrAfter, Date_Format } from "helpers/dateFormat";
import { ValidationComponent } from "helpers/getComponents";
import Form from "components/form";
import Modal from "components/modal";
import { objectToFormData } from "object-to-formdata";
import {
  GetDepartments,
  DisplayClientSideMessage,
  GetEmployeeList,
} from "actions/commonAction";
import {
  // GetProjectMatterId,
  // AddNewProject,
  // UpdateProject,
  DeleteProjectContract,
  GetBillingDesignations,
  GetActiveClientsList,
  GetLegalClientList,
  GetLegalClientTypes,
  SaveProjectContract,
  GetSecretarialChargesTypes,
  GetClientByClientId,
  GetMemberByName,
  GetContractDetails,
  CreateRenewContract,
  UpdateRenewContract,
} from "../../../action";
import { PostFilter } from "actions/employeesAction";
import { Enum_EventType } from "../../../enums";

const fileClasses = (value) => {
  return classnames({
    badges: value,
    "badges-rounded": value,
    "left-icon": value,
    inputWidth: "calc(100% - 225px)",
    "badges-default": value,
    "badges-file": value,
  });
};

export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // selectedClient: "",
      // ProjectName: "",
      secretarialCharges: { label: "Lumpsum", value: "1" },
      amount: null,
      HandlingDepartmentId: null,
      selectedDepartment: null,
      selectedClientTypeId: null,
      contractHours: null,
      CurrencyCode: null,
      renewDocuments: [],
      // renewalDate: null,
      // renewedUpto: null,
      // renewNote: "",
      contributingMembers: [
        {
          key: 1,
          id: "",
          label: "",
          value: null,
          estHours: 0,
          hourlyRate: 0,
          image: "",
          inputKey: `estHours${1}`,
        },
      ],
      counterParties: [{ key: 1, id: "", value: "", label: "", selected: "1" }],
      contractDate: Date_Format(new Date()),
      ContractEndDate: Date_Format(
        new Date(new Date().setDate(new Date().getDate() + 1))
      ),
      // InternalDeadlineEng: Date_Format(
      //   new Date(new Date().setDate(new Date().getDate() + 1))
      // ),
      // ExternalDeadlineEng: Date_Format(
      //   new Date(new Date().setDate(new Date().getDate() + 1))
      // ),
      // CourtHearingDateEng: Date_Format(new Date()),
      editMode: false,
      ContractList: [],
      billingDesignations: [],
    };
  }

  async componentDidMount() {
    this.props.childRef.current = this;
    let { ContractId, ClientId, ProjectId, ProjectName } =
      this.props.parent.selectedProjectForRenew &&
      this.props.parent.selectedProjectForRenew;

    this.setState({ ContractId, ClientId, ProjectId, ProjectName });
    //#region  These functions are called on listing page too so no need to async await
    this.props.GetMemberByName();
    this.props.GetDepartments();
    this.props.GetBillingDesignations();
    this.props.GetSecretarialChargesTypes();
    this.props.GetLegalClientTypes();
    this.props.GetActiveClientsList();
    this.props.GetEmployeeList();
    //#endregion

    await this.getLegalClients(ClientId);

    await this.getDataByContractId(ContractId);
  }

  getLegalClients = async (clientId) => {
    await this.props.GetClientByClientId(clientId);
    if (clientId) {
      const { legalClientById, billingDesignations } = this.props;
      let selectedClient = {
        label: legalClientById.ClientName,
        value: legalClientById.ClientId,
      };
      this.setState({
        selectedClient,
        CurrencyCode: legalClientById.CurrencyCode,
        selectedClientTypeId: legalClientById.ClientTypeRefId,
        billingDesignations,
      });
      //   () => {
      //     this.getMatterId();
      //   }
      // );
    }
  };

  getDataByContractId = async (projectId) => {
    await this.props.GetContractDetails(projectId);
    const { contractDetails } = this.props;

    if (contractDetails) {
      // counter parties
      let counterParties = [];
      await contractDetails.CounterPartyList.forEach((party, index) => {
        counterParties.push({
          key: index + 1,
          id: "",
          value: party.CounterPartyName,
          label: party.CounterPartyName,
          selected: "" + party.PositionId + "",
        });
      });
      if (counterParties.length == 0) {
        counterParties = [
          { key: 1, id: "", value: "", label: "", selected: "1" },
        ];
      }

      // team members
      let contributingMembers = [];
      await contractDetails.TeamList.forEach((teamMember, index) => {
        contributingMembers.push({
          key: index + 1,
          id: teamMember.MemberDetail.MemberId,
          label: teamMember.MemberDetail.MemberName,
          value: teamMember.MemberDetail,
          estHours: teamMember.EstimatedHours,
          hourlyRate: teamMember.HourlyRate ? teamMember.HourlyRate : 0,
          image: teamMember.MemberDetail.ImgPath,
          projectRefId: teamMember.ProjectRefId,
          inputKey: `estHours${index + 1}`,
        });
      });
      if (contributingMembers.length == 0) {
        contributingMembers = [
          {
            key: 1,
            id: "",
            label: "",
            value: null,
            estHours: 0,
            image: "",
            hourlyRate: 0,
            inputKey: `estHours${1}`,
          },
        ];
      }

      // hourlyList;
      let billingDstns = [...this.state.billingDesignations];
      let billingDesignations = [];

      billingDstns &&
        billingDstns.map((billing) => {
          contractDetails.HourlyRateList.forEach((hour) => {
            if (parseInt(hour.DesignationId) == parseInt(billing.Value)) {
              billingDesignations.push({
                ...billing,
                HourlyRate: hour.HourlyRate,
                DesignationId: hour.DesignationId,
              });
            }
          });
        });

      let projectData = {
        // editMode: projectId && contractDetails ? true : false,
        ProjectName: contractDetails.ProjectName,

        ApproverRefId: contractDetails.Approval.Approver
          ? {
              label: contractDetails.Approval.Approver.FullName,
              value: contractDetails.Approval.ApproverRefId,
            }
          : null,
        Approver2RefId: contractDetails.Approval.Approver2
          ? {
              label: contractDetails.Approval.Approver2.FullName,
              value: contractDetails.Approval.Approver2RefId,
            }
          : null,
        ReviewerRefId: contractDetails.Approval.Reviewer
          ? {
              label: contractDetails.Approval.Reviewer.FullName,
              value: contractDetails.Approval.ReviewerRefId,
            }
          : null,
        Reviewer2RefId: contractDetails.Approval.Reviewer2
          ? {
              label: contractDetails.Approval.Reviewer2.FullName,
              value: contractDetails.Approval.Reviewer2RefId,
            }
          : null,

        contributingMembers,
        note:contractDetails.Contract.EventNote,
        contractDate:
          contractDetails.Contract.EventStartDateEng &&
          Date_Format(contractDetails.Contract.EventStartDateEng), // || new Date(),
        ContractEndDate:
          contractDetails.Contract.EventEndDateEng &&
          Date_Format(contractDetails.Contract.EventEndDateEng), // || new Date(),
        // InternalDeadlineEng:
        //   contractDetails.InternalDeadlineEng &&
        //   Date_Format(contractDetails.InternalDeadlineEng), // || new Date(),
        // ExternalDeadlineEng:
        //   contractDetails.ExternalDeadlineEng &&
        //   Date_Format(contractDetails.ExternalDeadlineEng), // || new Date(),
        // CourtHearingDateEng:
        //   contractDetails.CourtHearingDateEng &&
        //   Date_Format(contractDetails.CourtHearingDateEng), // || new Date(),
        contractHours: contractDetails.Fee.ContractHours || 0,
        contractAmount: contractDetails.Fee.ContractAmount || 0,
        amount:
          contractDetails.Fee.SecretarialAmount ||
          contractDetails.Fee.SecretarialPercent ||
          0,
        // selectedDepartment,
        counterParties,
        // HandlingDepartmentId: contractDetails.HandlingDepartmentId,
        // ContractList: contractDetails.ContractList,
        ContractList: contractDetails.ContractHistory,
        secretarialCharges: {
          label:
            contractDetails.Fee.SecretarialChargeCalcType == 1
              ? "Lumpsum"
              : "PercentageOfBillAmount",
          value: "" + contractDetails.Fee.SecretarialChargeCalcType,
        },
        billingDesignations,
      };
      this.setState({ ...projectData });
    }
  };

  changeFeeType = (feeType) => {
    this.setState({ feeType });
  };

  handleSelectedFile = (event) => {
    const newFile = event.target.files[0];
    let { renewDocuments } = this.state;
    renewDocuments.push({
      AttachmentId: renewDocuments.length + 1,
      file: newFile,
    });
    this.setState({ renewDocuments });
  };

  handleRemoveFile = (document) => {
    let { renewDocuments } = this.state;
    renewDocuments = renewDocuments.filter(
      (f) => f.AttachmentId !== document.AttachmentId
    );
    this.setState({ renewDocuments });
  };

  addTeamMember = (option, key) => {
    if (option == "add") {
      let contributingMembers = [...this.state.contributingMembers];
      const newKey =
        contributingMembers[contributingMembers.length - 1].key + 1;
      const newWork = {
        key: newKey,
        id: "",
        inputKey: `estHours${newKey}`,
        value: null,
        image: "",
        hourlyRate: 0,
      };

      contributingMembers.push(newWork);
      this.setState({ contributingMembers });
    } else {
      let oldMembers = [...this.state.contributingMembers];
      if (oldMembers.length > 1) {
        let newMembers = oldMembers.filter(function (member) {
          return member.key != key;
        });
        this.setState({
          contributingMembers: newMembers,
        });
      }
    }
  };

  handleAutoSelect = (name, selected) => {
    this.setState({ [name]: selected });
  };

  handleInput = async (name, value) => {
    if (name.includes("billing")) {
      let billingId = name.replace("billing", "");
      let billingDstns = [...this.state.billingDesignations];
      let billingDesignations = billingDstns.map(function (billing) {
        if (parseInt(billing.Value) == parseInt(billingId)) {
          return {
            ...billing,
            DesignationId: parseInt(billing.Value),
            HourlyRate: parseInt(value),
          };
        } else {
          return billing;
        }
      });
      this.setState({ billingDesignations });
    } else
      this.setState({
        [name]: value,
        submitted: false,
        errors: {
          ...this.state.errors,
          [name]: null,
        },
      });
  };

  onDateChange = (name, date) => {
    this.setState({
      [name]: date,
    });
    //   () => {
    //     if (name == "contractDate") this.getMatterId();
    //   }
    // );
  };

  onTextChange = (name, value) => {
    this.setState({ [name]: value });
  };

  // handle department selection
  onSelectChange = (name, value) => {
    this.setState({
      [name]: value,
      HandlingDepartmentId: value.value,
      errors: {
        ...this.state.errors,
        [name]: null,
      },
    });
    //   () => {
    //     this.getMatterId();
    //   }
    // );
  };

  // // fetch matter ID
  // getMatterId = () => {
  //   const { HandlingDepartmentId, selectedClient, contractDate } = this.state;
  //   let matterObj = {
  //     clientId:
  //       selectedClient && selectedClient.value.ClientId
  //         ? selectedClient.value.ClientId
  //         : selectedClient.value,
  //     departmentId: HandlingDepartmentId,
  //     contractDate,
  //   };
  //   this.props.GetProjectMatterId(matterObj);
  // };

  setSelectedFiles = (e) => {
    const file = e.target.files[0];
    if (file) {
      let { renewDocuments } = this.state;
      renewDocuments.push({
        AttachmentId: renewDocuments.length + 1,
        file: file,
      });
      this.setState({ renewDocuments });
    }
  };

  // counter paties section
  addCounterParties = (option, key) => {
    if (option == "add") {
      let newParties = [...this.state.counterParties];
      const newKey = newParties[newParties.length - 1].key + 1;
      const newWork = {
        key: newKey,
        id: "",
        selected: "1",
        value: "",
        label: "",
      };

      newParties.push(newWork);
      this.setState({ counterParties: newParties });
    } else if (option == "remove") {
      let oldParties = [...this.state.counterParties];
      if (oldParties.length > 1) {
        let newParties = oldParties.filter(function (party) {
          return party.key != key;
        });
        this.setState({
          counterParties: newParties,
        });
      }
    }
  };

  changeCounterParties = (name, value, option) => {
    let counterParties = [...this.state.counterParties];
    counterParties.map((party) => {
      if (party.key == name) {
        option == "input" ? (party.value = value) : (party.selected = value);
      }
    });
    this.setState({ counterParties });
  };

  // team member select
  handleEmployeeSelect = (event, value) => {
    let siblingField =
      event == "ReviewerRefId"
        ? "Reviewer2RefId"
        : event == "Reviewer2RefId"
        ? "ReviewerRefId"
        : event == "ApproverRefId"
        ? "Approver2RefId"
        : event == "Approver2RefId"
        ? "ApproverRefId"
        : event;
    if (value || event !== "selectedClient")
      this.setState({
        [event]: value,
        errors: {
          ...this.state.errors,
          [event]: null,
          [siblingField]: null,
        },
      });
    //   () => {
    //     if (event == "selectedClient") this.getMatterId();
    //   }
    // );
  };

  handleTeamMember = (name, value) => {
    const { billingDesignations } = this.state;
    let contributingMembers = [...this.state.contributingMembers];

    let selectedBillingDesignation = billingDesignations.find(
      (f) => f.DesignationId == value.billingDesignationId
    );

    let hourlyRate = 0;
    if (selectedBillingDesignation && selectedBillingDesignation.HourlyRate) {
      hourlyRate = selectedBillingDesignation.HourlyRate;
    }

    contributingMembers.map((member) => {
      if (`team${member.key}` == name) {
        member.value = value;
        member.id = value ? value.value : null;
        member.label = value ? value.label : null;
        member.image = value ? value.Imgpath : null;
        member.hourlyRate = hourlyRate;
      }
    });
    this.setState({ contributingMembers });
  };

  changeMemberEstHours = (name, value) => {
    let contributingMembers = [...this.state.contributingMembers];
    contributingMembers.map((member) => {
      if (member.inputKey == name) {
        member.estHours = value;
      }
    });
    this.setState({ contributingMembers });
  };

  changeMemberHourlyRate = (name, value) => {
    let contributingMembers = [...this.state.contributingMembers];
    contributingMembers.map((member) => {
      if (member.inputKey == name) {
        member.hourlyRate = value;
      }
    });
    this.setState({ contributingMembers });
  };

  deleteContractHisory = async (eventId, deletable) => {
    if (!deletable) return;
    this.setState({ modalOpen: true, eventId });
  };

  onModalClose = () => this.setState({ modalOpen: false });

  confirmDeleteContractHistory = async (eventId) => {
    let prevContractList = [...this.state.ContractList];
    let ContractList = prevContractList.map((contract) => {
      if (contract.ContractId != eventId) return contract;
    });
    await this.props.DeleteProjectContract(eventId);
    if (this.props.contractDeleted) this.setState({ ContractList });
  };

  //edit contract list events
  editContractHistoryEvent = async (data, renewDocuments, onModalClose) => {
    await this.props.SaveProjectContract(
      data,
      renewDocuments,
      onModalClose,
      null
    );

    if (this.props.contractSaved) {
      let documents = renewDocuments.map((doc) => {
        return {
          AttachmentId: doc.AttachmentId,
          UserFileName: doc.file.name,
        };
      });
      let contractList = [...this.state.ContractList];
      let updatedContractList = contractList.map((contract) => {
        if (contract.ContractId == data.ContractId) {
          return {
            ...contract,
            ...data,
            ContractDocuments: [...documents],
          };
        } else return contract;
      });
      this.setState({ ContractList: updatedContractList });
    } else onModalClose();
  };

  // submit the project
  handleSave = async () => {
    let validationFields = { ...this.state };
    delete validationFields["errors"];
    let data = this.state;

    let errors = {};

    errors = {
      ...errors,
      ...(await InputValidator(document, validationFields)),
    };

    if (!isEmpty(errors)) {
      this.setState({ errors, submitted: true });
      this.props.DisplayClientSideMessage("Please fill all required fields.");
      return;
    } else {
      // post the data
      // if (
      //   !isDateTodayOrAfter(data.InternalDeadlineEng, data.contractDate) &&
      //   !isDateTodayOrAfter(data.ExternalDeadlineEng, data.contractDate)
      // ) {
      //   this.props.DisplayClientSideMessage("Valid contract date is required.");
      //   return;
      // }

      let counterParties = [];
      let teamMembers = [];
      let HourlyRateList = [];

      let dataToCompute = [
        data.counterParties.map((party) => {
          if (party.value != "")
            counterParties.push({
              CounterPartyName: party.value,
              PositionId: parseInt(party.selected),
            });
        }),
        data.contributingMembers.map((member) => {
          if (member.value)
            teamMembers.push({
              ProjectRefId: member.projectRefId ? member.projectRefId : 0,
              MemberId: member.id ? parseInt(member.id) : null,
              MemberTypeRefId: member.value.memberTypeId
                ? member.value.memberTypeId
                : member.value.MemberType,
              EstimatedHours:
                member.id && parseInt(member.estHours)
                  ? parseInt(member.estHours)
                  : 0,
              HourlyRate: member.hourlyRate,
            });
        }),
      ];

      dataToCompute.push(
        data.billingDesignations.map((billings) => {
          HourlyRateList.push({
            DesignationId: parseInt(billings.Value),
            HourlyRate: billings.HourlyRate ? parseInt(billings.HourlyRate) : 0,
          });
        })
      );

      await Promise.all(dataToCompute);

      // let fd = new FormData();
      // data.renewDocuments.map((doc, i) => {
      //   fd.append(`Attachment`, doc.file);
      // });
      // fd.append("ContractId", data.ContractId);
      // fd.append("EventStartDateEng", data.contractDate);
      // fd.append("EventEndDateEng", data.ExternalDeadlineEng);
      // fd.append("EventNote", data.note ? data.note : "");

      let projectData = {
        // ClientRefId: data.selectedClient.value,
        // ProjectName: data.ProjectName,
        // MatterId: data.editMode
        //   ? data.projectMatterId
        //   : this.props.projectMatterId,
        // HandlingDepartmentId: parseInt(data.HandlingDepartmentId),
        // CourtHearingDateEng: data.CourtHearingDateEng,
        // InternalDeadlineEng: data.InternalDeadlineEng,
        // ExternalDeadlineEng: data.ExternalDeadlineEng,

        // ContractStartDateEng: data.contractDate,

        approval: {
          ReviewerRefId: data.ReviewerRefId ? data.ReviewerRefId.value : null,
          Reviewer2RefId: data.Reviewer2RefId
            ? data.Reviewer2RefId.value
            : null,
          ApproverRefId: data.ApproverRefId ? data.ApproverRefId.value : null,
          Approver2RefId: data.Approver2RefId
            ? data.Approver2RefId.value
            : null,
        },

        fee: {
          ContractHours: parseInt(data.contractHours),
          ContractAmount: data.contractAmount
            ? parseInt(data.contractAmount)
            : 0,
          SecretarialChargeCalcType: parseInt(data.secretarialCharges.value),
          SecretarialAmount:
            data.amount && parseInt(data.secretarialCharges.value) == 1
              ? parseInt(data.amount)
              : 0,
          SecretarialPercent:
            parseInt(data.secretarialCharges.value) == 2
              ? parseInt(data.amount)
              : 0,
        },
        contract: 
         {
           ContractId:data.ContractId,
          EventStartDateEng: data.contractDate,
          EventEndDateEng: data.ContractEndDate,
          EventNote: data.note,
          // Attachment: [],
          // Files: data.renewDocuments,
        },
        HourlyRateList,
      };
      if (counterParties) projectData.CounterPartyList = counterParties;
      if (teamMembers) projectData.TeamList = teamMembers;

      const formData = objectToFormData(
        projectData
      );

      data.renewDocuments.map((doc, i) => {
        formData.append(`Files`, doc.file);
      });

      await this.props.CreateRenewContract(
        formData,
        this.props.parent.handleFormDisplay
      );
      // if (data.editMode) {
      //   let { projectId } = this.props.parent;
      //   projectData.ProjectId = projectId;

      //   await this.props.UpdateRenewContract(
      //     projectData,
      //     this.props.parent.handleFormDisplay
      //   );
      // } else {
      //   await this.props.CreateRenewContract(
      //     projectData,
      //     this.props.parent.handleFormDisplay
      //   );
      // }
    }
  };

  render() {
    const values = this.state;
    let { departments, projectMatterId, activeClientsList } = this.props;
    let finalClientsList = [];
    activeClientsList &&
      activeClientsList.forEach((client) => {
        finalClientsList.push({
          label: client.ClientName,
          value: client,
        });
      });
    // if (values.editMode) projectMatterId = values.projectMatterId;

    const { errors } = this.state;

    return (
      <>
        <ClientProjectTabs
          handleInput={this.handleInput}
          addCounterParties={this.addCounterParties}
          changeCounterParties={this.changeCounterParties}
          changeFeeType={this.changeFeeType}
          changePartyRadio={this.changePartyRadio}
          data={values}
          handleEmployeeSelect={this.handleEmployeeSelect}
          handleTeamMember={this.handleTeamMember}
          changeMemberEstHours={this.changeMemberEstHours}
          changeMemberHourlyRate={this.changeMemberHourlyRate}
          addTeamMember={this.addTeamMember}
          handleAutoSelect={this.handleAutoSelect}
          handleClientType={this.handleClientType}
          deleteContractHisory={this.deleteContractHisory}
          editContractHistoryEvent={this.editContractHistoryEvent}
          onDateChange={this.onDateChange}
          onTextChange={this.onTextChange}
          handleSelectedFile={this.handleSelectedFile}
          handleRemoveFile={this.handleRemoveFile}
          selectedProjectForRenew={this.props.parent.selectedProjectForRenew}
          {...this.props}
        />
        <Modal
          type="confirm"
          open={this.state.modalOpen}
          onModalClose={this.onModalClose}
          func={() => this.confirmDeleteContractHistory(this.state.eventId)}
          // id={[delData]}
        />
      </>
    );
  }
}

index.propTypes = {
  GetDepartments: PropTypes.func.isRequired,
  // GetProjectMatterId: PropTypes.func.isRequired,
  GetBillingDesignations: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  departments: state.commonReducer.departmentList,
  // projectMatterId: state.taskTrackerReducer.projectMatterId,
  // projectDetails: state.taskTrackerReducer.projectDetails,
  contractDetails: state.taskTrackerReducer.contractDetails,
  savedClientProject: state.taskTrackerReducer.savedClientProject,
  contractDeleted: state.taskTrackerReducer.contractDeleted,
  billingDesignations: state.taskTrackerReducer.billingDesignations,
  activeClientsList: state.taskTrackerReducer.activeClientsList,
  legalClientById: state.taskTrackerReducer.legalClientById,
  contractSaved: state.taskTrackerReducer.contractSaved,
  membersByNameDll: state.taskTrackerReducer.membersByNameDll,
});

const mapDispatchToProps = {
  GetDepartments,
  // GetProjectMatterId,
  GetBillingDesignations,
  PostFilter,
  GetActiveClientsList,
  GetLegalClientList,
  GetLegalClientTypes,
  // AddNewProject,
  // UpdateProject,
  SaveProjectContract,
  GetSecretarialChargesTypes,
  GetClientByClientId,
  DeleteProjectContract,
  GetMemberByName,
  GetEmployeeList,
  DisplayClientSideMessage,

  GetContractDetails,
  CreateRenewContract,
  UpdateRenewContract,
};

export default AnimatedForm()(
  connect(mapStateToProps, mapDispatchToProps)(index)
);
