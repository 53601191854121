import React, { Component } from "react";
import { Tabs, Tab } from "components/tabs/index.d";
import FormGroup from "components/form";
import CommonEmployeeSearch from "components/common/employeeSearch";

export class ListingTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        { id: 1, label: "Common Parameters" },
        { id: 2, label: "Advanced Parameters" },
      ],
      searchText: "",
      activeTabId: 1,
    };
  }

  handleTabChange = (tabId) => {
    this.setState({ activeTabId: tabId });
  };

  render() {
    const { tabs, activeTabId } = this.state;
    return (
      <div className="employee-tabs">
        <Tabs
          activeTab={activeTabId}
          type="block"
          isCollapse={true}
          collapseText="FILTRATION"
        >
          {tabs.map((tab, index) => {
            return (
              <Tab
                label={tab.label}
                name={tab.id}
                id={tab.id}
                key={index}
                onTabClick={() => this.handleTabChange(tab.id)}
              >
                {tab.id === 1 ? <CommonFilter {...this.props} />:<AdvanceFilter {...this.props} />}
              </Tab>
            );
          })}
        </Tabs>
      </div>
    );
  }
}

const genders = [
  { label: "Male", value: 1 },
  { label: "Female", value: 0 },
  { label: "Others", value: 2 },
];
const marital_statuses = [
  { label: "Married", value: 'Married' },
  { label: "Single", value: 'Single' },
];
const new_joins_opt = [
  { label: "This Month", value: 1 },
  { label: "Last Month", value: 2 },
  { label: "This Year", value: 3 },
];
const retired_opt = [
  { label: "This Month", value: 1 },
  { label: "Last Month", value: 2 },
  { label: "This Year", value: 3 },
];
const state_opt = [
  { label: "Working Only", value: false },
  { label: "Retired Only", value: true },
  { label: "All", value: null },
];
const AdvanceFilter = (props) => {
  const {
    gender,
    maritalStatus,
    newJoinees,
    state,
    retired,
  } = props.parentState;
  return (
    <div className="filters-input filters">
      <div className="filters-input__item">
        <FormGroup
          formName="reactselect"
          onChange={props.handleAutoSelect}
          name="gender"
          label={"Gender"}
          value={gender}
          options={genders}
          placeholder="Select Gender"
          width="200px"
        />
      </div>
      <div className="filters-input__item">
        <FormGroup
          formName="reactselect"
          onChange={props.handleAutoSelect}
          name="maritalStatus"
          label={"Marital Status"}
          value={maritalStatus}
          options={marital_statuses}
          placeholder="Select Marital Statuses"
          width="200px"
        />
      </div>
      <div className="filters-input__item">
        <FormGroup
          formName="reactselect"
          onChange={props.handleAutoSelect}
          name="newJoinees"
          label={"New Joinees"}
          value={newJoinees}
          options={new_joins_opt}
          placeholder="Select New Joinees"
          width="200px"
        />
      </div>
      <div className="filters-input__item">
        <FormGroup
          formName="reactselect"
          onChange={props.handleAutoSelect}
          name="state"
          label={"State"}
          value={state}
          options={state_opt}
          placeholder="Select State"
          width="200px"
        />
      </div>
      <div className="filters-input__item">
        <FormGroup
          formName="reactselect"
          onChange={props.handleAutoSelect}
          name="retired"
          label={"Retired"}
          value={retired}
          options={retired_opt}
          placeholder="Select Retired"
          width="200px"
        />
      </div>
    </div>
  );
};

const CommonFilter = (props) => {
  const {
    searchValue,
    activeBranch,
    activeDepartment,
    activePosition,
    activeStatus,
  } = props.parentState;
  const { branches, departments, designations, statusList } = props;
  return (
    <div className="filters-input filters">
      <div className="filters-input__item search">
        <FormGroup
          onChange={props.handleAutoSelect}
          name="searchValue"
          label={"Employee Name"}
          value={props.searchValue}
          placeholder="Employee name"
          width="200px"
        />
      </div>
      <div className="filters-input__item">
        <FormGroup
          formName="reactselect"
          onChange={props.handleAutoSelect}
          name="activeBranch"
          label={"Branch"}
          value={activeBranch}
          options={branches}
          placeholder="Select Branch"
          width="200px"
        />
      </div>
      <div className="filters-input__item">
        <FormGroup
          formName="reactselect"
          onChange={props.handleAutoSelect}
          name="activeDepartment"
          label="Department"
          value={activeDepartment}
          options={departments}
          placeholder="Select Department"
          width="200px"
        />
      </div>

      <div className="filters-input__item">
        <FormGroup
          formName="reactselect"
          onChange={props.handleAutoSelect}
          name="activePosition"
          label="Position"
          value={activePosition}
          options={designations}
          placeholder="Select Position"
          width="200px"
        />
      </div>

      <div className="filters-input__item">
        <FormGroup
          formName="reactselect"
          onChange={props.handleAutoSelect}
          name="activeStatus"
          label="Status"
          value={activeStatus}
          options={statusList}
          placeholder="Select Status"
          width="200px"
        />
      </div>
    </div>
  );
};

export default ListingTabs;
