import React, {Component, forwardRef} from "react";
import Sidetab from "../common/sidetab";

import Position_Details from "./positionDetails";
import Applications from "./applications";
import Hiring_Stage from "./hiringStage";
import Scorecard from "./scorecard";
import Hiring_Team from "./hiringTeam";
import Provider, {Consumer} from "./helpers/context";
import {connect} from "react-redux";
import {
  addJob,
  updateJob,
  updateJobCloseStatus,
  unpublishJobCloseStatus,
  getJobById,
  getJobRequisitionById,
  getJobStepsSetting,
} from "./helpers/actions";

import {validateJobCreate} from "./helpers/validations";
import isEmpty from "isEmpty";
import {withRouter} from "react-router-dom";
import Loader from "components/loaders";
import MarkingScheme from "./markingScheme";
import Breadcrumb from "scenes/common/recruitBreadcrumb";
import {FetchJobCreateData} from "store/actions/recruitment/common";
import {stringify} from "query-string";

export class Job_Create extends Component {
  constructor(props) {
    super(props);

    this.state = {
      jobId: 0,
      step: 1,
      lastStep: 3,
      isAt: 1,
      errors: {},
    };
    this.onDraft = this.onDraft.bind(this);
    this.onStepChange = this.onStepChange.bind(this);
  }
  componentDidMount() {
    this.props.FetchJobCreateData();
    const {data} = this.props.location;
    this.props.getJobStepsSetting();

    if (!isEmpty(data)) {
      this.setState({jobId: data.jobID});
      if (data.isReq) {
        this.props.getJobRequisitionById(data.jobID);
      } else {
        this.setState({step: -1});
        this.props.getJobById(data.jobID, data.isJobId);
      }
    }
  }
  onDraft(dispatch, jobCreate) {
    // jobCreate.RecruitmentPositionDetailDto.JobId = jobId;
    //jobCreate.RecruitmentPositionDetailDto.WizardStage = this.state.step;
    const {step} = this.state;
    dispatch({
      type: "onPositionChange",
      payload: {name: "WizardStage", value: step},
    });
    let job = this.onHiringSubIdChange(jobCreate);

    const jobId = (job && job.RecruitmentPositionDetailDto.JobId) || 0;

    if (jobId == 0) this.props.addJob(job, this.props.history, true);
    else this.props.updateJob(job, this.props.history, true);
  }

  updateStep() {
    const {step} = this.state;
    const {jobStepsSetting} = this.props;
    const lastSteps = (jobStepsSetting && jobStepsSetting.lastStep) || 6;
    if (step < lastSteps) {
      this.setState({step: step + 1}, () =>
        step > lastSteps ? this.setState({step: lastSteps}) : null
      );
    }
  }
  async onSave(dispatch, jobCreate) {
    const {lastStep} = this.props.jobStepsSetting || 6;

    const {step} = this.state;
    const errors = validateJobCreate(step, jobCreate);
    let isPublished = step == lastStep;
    this.setState({errors: errors});

    if (isEmpty(errors)) {
      // jobCreate.RecruitmentPositionDetailDto.JobId = jobId;
      if (isPublished) {
        // jobCreate.RecruitmentPositionDetailDto.IsPublished = true;
        dispatch({
          type: "onPositionChange",
          payload: {name: "IsPublished", value: true},
        });
      }
      let newStep = step + 1;

      await dispatch({
        type: "onPositionChange",
        payload: {name: "WizardStage", value: newStep},
      });
      //jobCreate.RecruitmentPositionDetailDto.WizardStage = step + 1;

      let job = this.onHiringSubIdChange(jobCreate);
      let newJob = {
        ...job,
        RecruitmentPositionDetailDto: {
          ...job.RecruitmentPositionDetailDto,
          WizardStage: newStep,
          IsPublished: isPublished,
        },
      };

      //job.RecruitmentPositionDetailDto.WizardStage = newStep;

      if (newJob.RecruitmentPositionDetailDto.JobId == 0)
        this.props.addJob(newJob, this.props.history, isPublished, () => {
          this.updateStep();
        });
      else
        this.props.updateJob(newJob, this.props.history, isPublished, () => {
          this.updateStep();
        });
    }
  }

  onCloseJob = (dispatch, jobCreate) => {
    const data = {
      ...jobCreate,
      RecruitmentPositionDetailDto: {
        ...jobCreate.RecruitmentPositionDetailDto,
        IsClosed: !jobCreate.RecruitmentPositionDetailDto.IsClosed,
        IsPublished: false,
      },
    };
    this.props.updateJob(data, this.props.history, false);
  };

  onUnpublishJob = (dispatch, jobCreate) => {
    const data = {
      ...jobCreate,
      RecruitmentPositionDetailDto: {
        ...jobCreate.RecruitmentPositionDetailDto,
        IsPublished: false,
      },
    };
    this.props.updateJob(data, this.props.history, false);
  };

  onHiringSubIdChange(data) {
    return {
      ...data,
      RecruitmentJobHiringSubStageDto: data.RecruitmentJobHiringSubStageDto.map(
        (item) => {
          return {
            ...item,
            Id: !item.Id.toString().match(/^[\d]+$/) ? 0 : item.Id,
          };
        }
      ),
    };
  }
  componentDidUpdate(prevProps, prevState) {
    const {jobCreate, jobStepsSetting} = this.props;
    let {step, isAt} = this.state;

    if (!isEmpty(jobCreate.value) && jobCreate.value !== prevProps.jobCreate.value) {
      const {RecruitmentPositionDetailDto: rp} = jobCreate.value;

      if (!isEmpty(rp.WizardStage)) {
        if (rp.WizardStage === null || rp.WizardStage === 0) {
          this.setState({step: 1, isAt: 1});
        } else {
          let wStep = rp.WizardStage;

          if (jobStepsSetting && wStep > jobStepsSetting.lastStep) {
            wStep = jobStepsSetting.lastStep;
          }

          if (step == -1) {
            step = wStep;
          }
          this.setState({step: step, isAt: wStep});
        }
      }
    }
  }

  onStepChange(step) {
    this.setState({step});
  }
  getTitle(jobCreate) {
    return (
      (jobCreate &&
        jobCreate.RecruitmentPositionDetailDto &&
        jobCreate.RecruitmentPositionDetailDto.InternalJobTitle) ||
      "New Position"
    );
  }
  renderSwitch(step) {
    switch (step) {
      case 1:
        return <Position_Details errors={this.state.errors} />;
      case 2:
        return <Applications errors={this.state.errors} />;
      case 3:
        return <Hiring_Stage errors={this.state.errors} />;
      case 4:
        return <Scorecard errors={this.state.errors} />;
      case 5:
        return <MarkingScheme errors={this.state.errors} />;
      case 6:
        return <Hiring_Team errors={this.state.errors} />;
    }
  }
  render() {
    const {step, lastStep} = this.state;

    const {jobCreate: jc, jobStepsSetting} = this.props;

    return (
      <Provider>
        <Consumer>
          {(value) => {
            const {dispatch, jobCreate} = value;
            const {RecruitmentPositionDetailDto} = jobCreate || {};
            const {IsPublished, IsClosed} = RecruitmentPositionDetailDto;

            return (
              <div className="jobCreate">
                <Loader open={jc.loading} />
                <div className="jobCreate__breadCrumb">
                  <Breadcrumb name="Job" />
                </div>
                <div className="jobcreate-wizard">
                  <div className="jobCreate__head">
                    <div className="jobCreate__head-top">
                      <div className="jobCreate__head-title tertiary-title">
                        <h3>{this.getTitle(jobCreate)}</h3>

                        {RecruitmentPositionDetailDto.JobId > 0 && (
                          <div>
                            <button
                              onClick={() =>
                                this.onCloseJob(
                                  dispatch,
                                  jobCreate,
                                  RecruitmentPositionDetailDto.JobId
                                )
                              }
                              className="btn-outline btn-outline__black"
                            >
                              {RecruitmentPositionDetailDto.IsClosed
                                ? "Reopen job"
                                : "Close Job"}
                            </button>
                            {RecruitmentPositionDetailDto.IsPublished ? (
                              <button
                                onClick={() =>
                                  this.onUnpublishJob(
                                    dispatch,
                                    jobCreate,
                                    RecruitmentPositionDetailDto.JobId
                                  )
                                }
                                className="btn-outline btn-outline__black"
                              >
                                Unpublish Job
                              </button>
                            ) : null}
                          </div>
                        )}
                      </div>

                      <div className="jobCreate__head-buttons">
                        {jobStepsSetting &&
                          !IsPublished &&
                          !IsClosed &&
                          step === jobStepsSetting.lastStep && (
                            <button
                              onClick={() => this.onDraft(dispatch, jobCreate)}
                              className="btn-outline btn-outline__black"
                            >
                              Save draft
                            </button>
                          )}
                        {jobStepsSetting && (
                          <button
                            onClick={() => {
                              this.onSave(dispatch, jobCreate);
                            }}
                            className="btn btn-blue"
                          >
                            {step === jobStepsSetting.lastStep
                              ? IsClosed
                                ? "Save & Continue"
                                : "Publish"
                              : "Save & Continue"}
                          </button>
                        )}
                      </div>
                    </div>

                    <Sidetab
                      onStepChange={this.onStepChange}
                      currentStep={this.state.step}
                      stepsSetting={jobStepsSetting}
                      isAt={this.state.isAt}
                    />
                  </div>
                  {/*  */}
                  {/* <div className="jobCreate__body">{this.props.children}</div> */}
                  <div className="jobCreate__body">{this.renderSwitch(step)}</div>
                </div>
              </div>
            );
          }}
        </Consumer>
      </Provider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    jobCreate: state.createJobReducer.jobCreate,
    jobStepsSetting: state.createJobReducer.jobStepsSetting,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    addJob,
    updateJob,
    updateJobCloseStatus,
    unpublishJobCloseStatus,
    getJobById,
    getJobRequisitionById,
    FetchJobCreateData,
    getJobStepsSetting,
  })(Job_Create)
);
