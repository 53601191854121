import Upload from "components/common/upload";
import Form from "components/form";
import { getCommaFormattedNumber } from "helpers/getFormattedNumber";
import isEmpty from "isEmpty";
import React from "react";
import { connect } from "react-redux";
import { dispatchBillsAndClaimsList } from "../../actions";

export class AttachBill extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documentsState: null,
      formData: {},
      fileList: isEmpty(this.props.initialValues.BillList)
        ? []
        : this.props.initialValues.BillList,
      errors: {},
    };
  }
  componentDidMount() {
    this.setState({ formData: this.props.initialValues });
  }
  handleDocumentsTabState = (data) => {
    this.setState({ documentsState: data });
  };

  fileSelectedHandler = (event) => {
    const { fileList } = this.state;
    const file = event.target.files[0];
    fileList.push(file);
    this.setState({ fileList: fileList });
  };

  removeFile(name) {
    const { fileList } = this.state;
    let newFiles = fileList.filter((item) => item.name !== name);

    this.setState({ fileList: newFiles });
  }
  setSelectedFiles = (fileList) => {
    this.setState({ fileList });
  };
  onFormSubmit = (event) => {
    event.preventDefault();
    let { formData } = this.state;
    let errors = {};
    if (!formData.BillAmount) {
      errors.BillAmount = "Required";
    }
    this.setState({ errors });
    if (isEmpty(errors)) {
      if (
        this.props.billsAndClaimsList.filter((x) => x.Id === formData.Id)
          .length > 0
      ) {
        let billAndClaimList = this.props.billsAndClaimsList.map((x) => {
          let o = { ...x };
          if (x.Id === formData.Id) {
            o.BillCount = this.state.fileList.length;
            o.BillAmount = formData.BillAmount;
            // x.FileList = this.state.fileList.map(x => {
            //   return { name: x.name };
            // });
            o.BillList = this.state.fileList;
          }
          return o;
        });
        this.props.dispatchBillsAndClaimsList(billAndClaimList);
      }
      this.props.onSubmit();
    }
  };

  handleInputChange = (name, value) => {
    this.setState((prevState) => ({
      formData: { ...prevState.formData, [name]: value },
    }));
  };
  render() {
    const { fileList, formData, errors } = this.state;
    const { initialValues } = this.props;
    //console.log(initialValues);
    return (
      <form className="attach-bill" onSubmit={this.onFormSubmit}>
        <div className="form-row">
          <div className="grid">
            <div className="grid__header">
              <span>Allowance</span>
              <span>Units</span>
              <span>Rate</span>
              <span>Amount</span>
            </div>
            <div className="grid__body">
              <span>{initialValues.Name}</span>
              <span>{initialValues.Units}</span>
              <span>{getCommaFormattedNumber(initialValues.Rate)}</span>
              <span>{getCommaFormattedNumber(initialValues.Amount)}</span>
            </div>
          </div>
        </div>
        <div className="form-row">
          <Upload
            fileList={fileList}
            setSelectedFiles={this.setSelectedFiles}
            uploadBtnText="Attach Bill"
            noSelectedText="No bill selected"
          />
          <div className="bill-amount justify-end">
            {/* <span>Bill Amount</span> */}
            <Form
              name="BillAmount"
              type="number"
              error={errors.BillAmount}
              label="Bill Amount"
              value={formData.BillAmount}
              onChange={this.handleInputChange}
              width="150px"
            />
            {/* <Field
              name="BillAmount"
              component={renderTextField}
              size="sm"
              label=""
              validate={[required]}
              type="number"
              value={this.state.billAmount}
              width="150px"
            /> */}
            {/* <button className="btn btn-grey-2s">Update</button> */}
          </div>
        </div>
        <div className="form-row form-footer">
          <button
            className="btn btn-grey-2"
            type="button"
            onClick={() => this.props.closeModal()}
          >
            Cancel
          </button>
          <button className="btn btn-primary" type="submit">
            Save
          </button>
        </div>
      </form>
    );
  }
}

AttachBill = connect(
  (state) => ({
    billsAndClaimsList: state.travelSettlementReducer.billsAndClaims,
  }),
  { dispatchBillsAndClaimsList }
)(AttachBill);

export default AttachBill;
