import React, { Component } from "react";
import Tabs from "../../../components/tabs/tabs";
import InputGroup from "../../../components/common/inputGroup";
import {GetBranches,GetDepartments,GetDesignations,GetJobStatus} from "actions/commonAction";
import { GetEmployeeListForAdmin } from "../../employee/employeeList/action";
import { connect } from "react-redux";
import Table from "./table";
import moment from "moment";
import Filters from "./filters";
import isEmpty from "isEmpty";
import { motion } from "framer-motion";


const getDate = (name) => {
  var date = new Date();
  var firstDay = null;
  var lastDay = null;
  const label = !isEmpty(name) ? name.label : "";
  if (label === "This Month") {
    firstDay = moment(date).startOf("month").format("YYYY/MM/DD");
    lastDay = moment(date).endOf("month").format("YYYY/MM/DD");
  }
  if (label === "Last Month") {
    firstDay = moment(date)
      .subtract(1, "months")
      .startOf("month")
      .format("YYYY/MM/DD");
    lastDay = moment(date)
      .subtract(1, "months")
      .endOf("month")
      .format("YYYY/MM/DD");
  }
  if (label === "This Year") {
    firstDay = moment().startOf("year").format("YYYY/MM/DD");
    lastDay = moment().endOf("year").format("YYYY/MM/DD");
  }
  return { firstDay, lastDay };
};

const pageVariants = {
  initial: {
    opacity: 0,
    x: "-100vw",
    scale: 0.8,
  },
  in: {
    opacity: 1,
    x: 0,
    scale: 1,
  },
  out: {
    opacity: 0,
    x: "100vw",
    scale: 1.2,
  },
};

const pageTransition = {
  type: "tween",
  ease: "anticipate",
  duration: 0.5,
};

const pageStyle = {
  position: "absolute",
};

export class Tax_Calculation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      leftValue: "",
      searchValue: "",
      activeBranch: "",
      activeDepartment: "",
      activePosition: "",
      activeStatus: "",
      income_deduct: "",
      position: "",
      region: "",
      approval_state: "",
      leave_type: "",
    };
    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  initialize = async () => {
    this.setState({ isLoading: true });

    const {
      pageIndex,
      rowsPerPage,
      searchValue,
      activeBranch,
      activeDepartment,
      activePosition,
      activeStatus,
      gender,
      maritalStatus,
      newJoinees,
      state,
      retired,
    } = this.state;
    const { firstDay: fromDate, lastDay: endDate } = getDate(newJoinees);
    const {
      firstDay: retirementStartDate,
      lastDay: retirementEndDate,
    } = getDate(retired);

    await this.props.GetEmployeeListForAdmin({
      pageIndex,
      rowsPerPage,
      searchValue,
      gender,
      maritalStatus,
      activeBranch,
      state,
      fromDate,
      endDate,
      retirementStartDate,
      retirementEndDate,
      activeDepartment,
      activePosition,
      activeStatus,
    });

    this.setState({ isLoading: false });
  };

  handleInput = (e) => {
    const { name, value } = e.target;
    
    this.setState({ [name]: value, pageIndex: 0 }, () => {
      this.initialize();
    });
  };

  handleAutoSelect = (name, selected) => {
    this.setState({ [name]: selected, pageIndex: 0 }, () => {
      this.initialize();
    });
  };

  handleRowChange = (num) => {
    this.setState({ rowsPerPage: num }, () => {
      this.initialize();
    });
  };

  handleIndexChange = (num) => {
    this.setState({ pageIndex: num }, () => {
      this.initialize();
    });
  };
  render() {

    const { employeeListForAdmin,branches, searchValue, departments,designations,statusList, history } = this.props;
    const {actTab,pageIndex,attendancePageIndex,rowsPerPage,attendanceRowsPerPage}=this.state;
    return (
      <motion.div
        style={pageStyle}
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}
      >
        <div className="payroll-body tax">
          <Filters
            // pageIndex={this.state.pageIndex}
            // handleRowChange={this.handleRowChange}
            // handleIndexChange={this.handleIndexChange}
            // rowsPerPage={this.state.rowsPerPage}
            parentState={this.state}
            branches={branches}
            searchValue={searchValue}
            departments={departments}
            designations={designations}
            statusList={statusList}
            handleAutoSelect={this.handleAutoSelect}
            handleInput={this.handleInput}
          />
          <div className="button-group button-group__right">
            <button className="btn btn-grey-2">Export</button>
          </div>
          <div className="tax-table">
            <Table />
          </div>
        </div>
      </motion.div>
    );
  }
}

const mapStateToProps = state => ({
  employeeListForAdmin: state.admin_EmployeeListReducer.employeeListForAdmin,
  branches:state.commonReducer.branchList,
  departments:state.commonReducer.departmentList,
  designations:state.commonReducer.designationList,
  statusList:state.commonReducer.jobStatusList
});

const mapDispatchToProps = {
  GetEmployeeListForAdmin,
  GetBranches,
  GetDepartments,
  GetDesignations,
  GetJobStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(Tax_Calculation);