import React, { useState, useEffect } from 'react'
import UserCard from './components/userCard';
import { useSelector } from 'react-redux';
import { Droppable, Draggable } from "react-beautiful-dnd";
import FormItem from 'components/form'

function ScheduleSidebar({ teamId, isPrev }) {
    const [employees, setEmployees] = useState([])
    const [search, setSearch] = useState("")
    const { teamEmployeeList, scheduleAllEmployeeList, scheduleAllEmployeeListwithWorkingHours } = useSelector((state: any) => state.roosterReducer);
    useEffect(() => {
        if (scheduleAllEmployeeListwithWorkingHours) {
            setEmployees(scheduleAllEmployeeListwithWorkingHours)
        }
    }, [scheduleAllEmployeeListwithWorkingHours])
    useEffect(()=>{
        setEmployees(prev=>{
            return scheduleAllEmployeeListwithWorkingHours && scheduleAllEmployeeListwithWorkingHours.filter(item=>item.EmployeeName.toLocaleLowerCase().includes(search))
        })
    },[search]);
    return (
        <Droppable droppableId={-1} direction="vertical" type="sections">
            {(provided, snapshot) => (
                <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="schedule-sidebar">
                    <div className="schedule-sidebar__search">

                    </div>
                    <div className="schedule-sidebar__users">
                        {/* <Draggable
                    key={"isOpen"} 
                    draggableId={"isOpen"} 
                    index={0}
                    disableInteractiveElementBlocking="true"
                >
                {(provided, snapshot) => (
                    <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    // style={getItemStyle(
                    //     snapshot.isDragging,
                    //     provided.draggableProps.style
                    // )}
                    >
                        <UserCard isOpen isLarge />
                    </div>
                )}
                    
                </Draggable> */}
                        {
                            scheduleAllEmployeeListwithWorkingHours && scheduleAllEmployeeListwithWorkingHours.length > 0 && (
                                <FormItem 
                                    name="search"
                                    formName="searchInput"
                                    onChange={(name,value)=>{
                                        setSearch(value);
                                    }}
                                />
                            )
                        }
                        {
                            employees && employees.map((item, index) => {
                                return (
                                    // <Draggable 
                                    //     key={"Employee-" + item.EmployeeId} 
                                    //     draggableId={
                                    //         null + "," + 
                                    //         item.EmployeeId + "," + 
                                    //         0 + "," +
                                    //         teamId + "," + 
                                    //         null + "," + 
                                    //         0
                                    //     } 
                                    //     index={index}
                                    //     disableInteractiveElementBlocking="true"
                                    // >
                                    //     {(provided, snapshot) => (
                                    <div
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                    >

                                        <UserCard
                                            isLarge
                                            name={item?.EmployeeName}
                                            image={item?.EmployeeImgPath}
                                            designation={item?.TeamRole}
                                            ot_hr={item.OT > 0 ? `${item.OT}h` : "0h"}
                                            working_hr={item.TotalHours > 0 ? `${item.TotalHours}h` : "0h"}
                                        />
                                    </div>
                                    // )}
                                    // </Draggable>

                                )
                            })
                        }
                        {/* <UserCard isLarge name="Shankar Aryal" designation="Team Member" ot_hr="2h" working_hr="20h" /> */}
                    </div>
                </div>
            )}
        </Droppable>

    )
}

export default ScheduleSidebar
