import React from "react";
import "./jobListing.scss";
import CareerAdditionalDetails from "./careerAdditionalDetails";
import { GetCompanyInformation } from "actions/commonAction";
import { getJobDetails } from "./action";
import { connect } from "react-redux";
import Loader from "components/loaders";
import { withRouter } from "react-router-dom";

export class JobDetails extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    this.props.GetCompanyInformation();
    this.props.getJobDetails(params.jobId);
  }

  render() {
    const { spinnerLoading, loading } = this.props.jobListingReducer;
    return (
      <div className="career">
        <div className="career__header">
          <span>Latest</span>
          <span> Careers</span>
        </div>
        {!spinnerLoading ? <CareerAdditionalDetails /> : null}
        <Loader open={spinnerLoading} />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  jobListingReducer: state.jobListingReducer
});

export default withRouter(
  connect(mapStateToProps, {
    getJobDetails,
    GetCompanyInformation
  })(JobDetails)
);
