import {
    GET_FISCAL_YEAR,
    FISCAL_LOADING,
    GET_PAYROLL_PERIOD,
    SELECTED_PAYROLL,
    PAYROLL_PERIOD_LOADING,
    ADD_MESSAGE,
  } from '../../actions/types';
import axios from 'axios'
import {toastMessage} from '../../actions/validateAction'


export const getFiscalYear = () => dispatch => {
  // dispatch({type: FISCAL_LOADING})
  axios
    // .get(`/v1/branches`,)
    .get(`/v1/payroll/fiscal-years`,)
    .then(res =>{
      dispatch({
        type: GET_FISCAL_YEAR,
        payload: res.data
      })
      toastMessage(dispatch,res);

    }
      
    )
    .catch(err =>{
      dispatch({
        type: GET_FISCAL_YEAR,
        payload: null
      })

    }
      
    );
};

export const getPayrollPeriods = (payrollPeriod, changeFiscalPeriod) => dispatch => {
    dispatch({type: PAYROLL_PERIOD_LOADING})
    axios
      .get(`/v1/payroll/payroll-periods?fiscalYearId=${payrollPeriod.Value}`)
      // .get(`/v1/payroll/shared/payrollperiod-addons?fiscalYearId=${id}`)
      .then(async res =>{
        toastMessage(dispatch,res);
        // await dispatch({
        //   type: SELECTED_PAYROLL,
        //   payload: payrollPeriod
        // })
        await res.data.map((item, i) => {
          if (item.Selected) {
            dispatch({
              type: SELECTED_PAYROLL,
              payload: item
            });
            changeFiscalPeriod && changeFiscalPeriod();
          }
        });
        dispatch({
          type: GET_PAYROLL_PERIOD,
          payload: res.data
        });
      })
      .catch(err =>{
        dispatch({
          type: GET_PAYROLL_PERIOD,
          payload: null
        })
      });
  };


  export const selectedPayrollPeriods = (data) => dispatch => {
    dispatch({
      type: SELECTED_PAYROLL,
      payload:data
    });
  };