import React, {useState, useEffect} from "react";
import {useForm} from "react-hook-form";
import ExportExcel from "components/excel/exportExcel";
import Button from "components/button";
import FormGroup from "components/formGroup";
import FormItem from "components/formGroup/formItem";
import {export_name} from "./constant";

const ReportTable = (props) => {
  const method = useForm();

  const {advanceCategory, onSearch, handleExcelExport, exportData, exportColumn} = props;

  return (
    <div className="advance-travel-management__filters">
      <FormGroup method={method} onSubmit={onSearch}>
        <div className="advance-travel-management__filters">
          <FormItem
            formName="searchInput"
            width="200px"
            name="name"
            placeholder="Employee Name"
            label="Search employee"
          />
          <FormItem
            formName="searchInput"
            width="200px"
            name="destination"
            placeholder="Destination"
            label="Destination"
          />
          <FormItem
            formName="reactselect"
            options={[
              {label: "Domestic", value: 1},
              {label: "International", value: 2},
            ]}
            name="type"
            width="200px"
            label="Type"
          />
          <FormItem
            formName="reactselect"
            options={advanceCategory}
            name="category"
            width="200px"
            label="Travel Category"
          />

          <Button htmlButtonType="submit" title="search" bg="white" />
        </div>
      </FormGroup>
      <ExportExcel
        onClick={handleExcelExport}
        fileName={export_name}
        columns={exportColumn}
        sheets={[{sheetName: export_name, data: exportData}]}
        className="button button-primary"
      />
    </div>
  );
};

export default ReportTable;
