import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { FaRegFilePdf } from "react-icons/fa";
import { inputWidth } from "./constants";

function AddDocument(props) {
  const {
    text,
    extraClassName,
    selectedFile,
    handleFileSelection,
    error,
  } = props;

  const fileClasses = (value) => {
    return classnames({
      badges: value,
      "badges-rounded": value,
      "left-icon": value,
      inputWidth: inputWidth,
      "badges-default": value,
      "badges-file": value,
    });
  };
  const file = selectedFile;
  // const [file, setFile] = useState(selectedFile);

  const fileSelectedHandler = (event) => {
    const newFile = event.target.files[0];
    // setFile(newFile);
    handleFileSelection(newFile);
  };

  const fileRemoveHandler = () => {
    // setFile(null);
    handleFileSelection(null);
  };
  console.log(selectedFile);
  return (
    <div className="upload-btn-wrapper" style={{ marginRight: "20px" }}>
      {file && file.name ? (
        <div
          className={fileClasses(true)}
          // onClick={()=>this.onModalOpen(file,true)}
        >
          <span className="document-icon">
            <FaRegFilePdf />
          </span>
          <label className="document-name">
            {file.name}
            {/* {file.name.split(".")[0] + "." + file.name.split(".")[1] } */}
          </label>
          <span
            className="document-icon cancel-icon"
            onClick={fileRemoveHandler}
          >
            <AiFillCloseCircle />
          </span>
        </div>
      ) : (
        <button className={"upload-btn " + extraClassName}>
          {text}
          <input type="file" name="file" onChange={fileSelectedHandler} />
        </button>
      )}

      {error && (
        <div className="error__message mt-sm align-center">{error}</div>
      )}
    </div>
  );
}

AddDocument.propTypes = {
  text: PropTypes.string,
  extraClassName: PropTypes.string,
};
AddDocument.defaultProps = {
  text: "Add document",
  extraClassName: "addDocument",
};

export default AddDocument;
