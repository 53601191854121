import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Table from 'components/customTable';
import Pagination from "components/common/pagination";
import EmployeeEditForm from './employeeEditForm';
import Modal from 'components/modal';

const ButtonCell = (props) => {
    const [open, setOpen] = useState(false);
    const onModalOpen = () => setOpen(true)
    const onModalClose = () => setOpen(false)
    const dispatch = useDispatch();
    const onEdit = () => {
        console.log("Edit")
    }
    return (
        <div className="shifts-table__col">
            <div className="shifts-table__links">
                <span onClick={() => onModalOpen()} >Edit</span>
            </div>
            <Modal
                open={open}
                onModalClose={onModalClose}
                title="Edit Employee Team"
                width="450px"
            >
                <EmployeeEditForm editData={props?.row?.original} onModalClose={onModalClose} />
            </Modal>
        </div>
    )
}

function TeamTable(props) {
    const {
        pageIndex,
        pageSize,
        handleRowChange,
        handleIndexChange
    } = props;
    const {teamEmployeeList} = useSelector((state:any)=>state.roosterReducer)
    const cols = [
        {
            Header: 'SN',
            accessor: 'RowNumber',
            width: 60,
        },
        {
            Header: 'Employee Name',
            accessor: 'EmployeeName',
            width: 160,
        },
        {
            Header: 'Team',
            accessor: 'TeamName',
            width: 160,
        },
        {
            Header: 'Role',
            accessor: 'RoleName',
            width: 160,
        },
        {
            Header: 'Actions',
            accessor: 'Id',
            Cell: ButtonCell,
            width: 160,
        },
    ];
    return (
        <div>
            <Table
                isFixedWidth
                data={teamEmployeeList ?? []}
                columns={cols}
            />
            {teamEmployeeList && teamEmployeeList.length > 0 && (
                <Pagination
                    handleRowChange={handleRowChange}
                    handleIndexChange={handleIndexChange}
                    pageIndex={pageIndex}
                    rowsPerPage={pageSize}
                    total={teamEmployeeList[0].TotalRows}
                />
                )}
        </div>
    )
}

export default TeamTable
