import { Skeleton } from "@material-ui/lab";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
} from "@syncfusion/ej2-react-grids";
import React, { Component } from "react";
import { FaCheckDouble, FaFileMedical } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CommonPagination from "components/common/pagination";
import isEmpty from "isEmpty";
import { UpdateJobRequisitionPath } from "routes/commonLinks";
import { getJobRequisitionDetails } from "../action";

export class Table extends Component {
  constructor(props) {
    super(props);
    this.tablerow = [];
    this.gridTemplate = this.gridTemplate;
    this.toggleTravelDetails = this.toggleTravelDetails.bind(this);
  }

  gridTemplate(props) {
    let { cancelledList } = this.props.jobRequisitionReducer;
    return (
      <>
        <tr
          className="table-row e-row"
          role="row"
          onClick={() => this.toggleTravelDetails(props.RequisitionId)}
        >
          <td className="e-rowcell e-templatecell" role="gridcell">
            <div className="">
              <span>{props.RequisitionNumber}</span>
              <span className="light-color">{props.RequestedOn}</span>
            </div>
          </td>
          <td className="e-rowcell e-templatecell" role="gridcell">
            <div className="">
              <span>{props.ExternalJobTitle}</span>
              <span className="light-color">{props.InternalJobTitle}</span>
            </div>
          </td>
          <td className="e-rowcell e-templatecell" role="gridcell">
            <div className="">
              <span>{props.Branch}</span>
            </div>
          </td>
          <td className="e-rowcell e-templatecell" role="gridcell">
            <div className="">
              <span>{props.EmployeeMentType}</span>
            </div>
          </td>
          <td className="e-rowcell e-templatecell" role="gridcell">
            <div className="">
              <span>{props.NumberOfOpenings}</span>
            </div>
          </td>
          <td className="e-rowcell e-templatecell" role="gridcell">
            <div className="approver">
              {props.RequisitonStatusId === 0 ? (
                <FaFileMedical style={{ color: "unset" }} />
              ) : (
                <FaCheckDouble />
              )}
              <span className="approver__status">{props.Status}</span>
              {props.RequisitonStatusId === 0 ? (
                !isEmpty(props.CreatedBy) ? (
                  <span className="light-color">by {props.CreatedBy}</span>
                ) : null
              ) : !isEmpty(props.ApprovedBy) ? (
                <span className="light-color">by {props.ApprovedBy}</span>
              ) : null}
            </div>
          </td>

          <td className="e-rowcell e-templatecell" role="gridcell">
            <div className="">
              <div className="btn-col btn-grp">
                {props.IsCancelled ||
                (!isEmpty(cancelledList) &&
                  cancelledList.find((x) => x === props.RequisitionId)) ? (
                  <div className="cancel">
                    <TiCancel />
                    <span>Cancelled</span>
                  </div>
                ) : props.RequisitonStatusId === 0 ? (
                  <div>
                    {this.props.type === 1 ? (
                      <button
                        className="btn-col__green btn"
                        onClick={() =>
                          this.props.approveJobRequisition(props.RequisitionId)
                        }
                      >
                        Approve
                      </button>
                    ) : null}

                    <button
                      className="btn-col__edit btn"
                      onClick={() => {
                        this.props.getJobRequisitionDetails(
                          props.RequisitionId
                        );
                        this.props.history.push({
                          pathname: UpdateJobRequisitionPath,
                          state: {
                            selectedTabId: -2,
                            requisitionId: props.RequisitionId,
                            isEditPage: true,
                            isViewPage: false,
                            title: "Update Job Requisition",
                          },
                        });
                      }}
                    >
                      Edit
                    </button>
                    {this.props.type === 2 ? (
                      <button
                        className="btn-col__del btn"
                        onClick={() =>
                          this.handleCancelRequisition(props.RequisitionId)
                        }
                      >
                        Cancel
                      </button>
                    ) : null}
                  </div>
                ) : (
                  <button
                    className="btn-col__edit btn"
                    onClick={() => {
                      this.props.getJobRequisitionDetails(props.RequisitionId);
                      this.props.history.push({
                        pathname: UpdateJobRequisitionPath,
                        state: {
                          selectedTabId: -2,
                          requisitionId: props.RequisitionId,
                          isEditPage: true,
                          isViewPage: true,
                          title: "View Job Requisition",
                        },
                      });
                    }}
                  >
                    View
                  </button>
                )}
              </div>
            </div>
          </td>
          <td className="e-rowcell e-templatecell" role="gridcell">
            <div className="icon-expand-collapse btn-col">
              <IoIosArrowDown
                className="btn-arrow"
                //onClick={() => this.toggleTravelDetails(props.RequestId)}
                id={`toggleIcon${props.RequisitionId}`}
              />
            </div>
          </td>
        </tr>
        <div
          className="table-details e-row"
          id={`details${props.RequisitionId}`}
        >
          <div className="details e-rowcell e-templatecell">
            {/* <div>{props.Reason}</div>
            <div className="details__amount">
              <span className="light-color"> {props.AllownceExpnese}</span>
            </div>
            <div className="details__otherExpenses">
              {props.ReqeustDestinationDetail.map(x => {
                return (
                  <div className="">
                    <span>{x.PlaceName}</span>
                    <span>
                      {x.Destinationstart} - {x.Destinationed}
                    </span>
                    <span>
                      {x.Totaldays} {x.Totalnight}
                    </span>
                    <span>{x.HotelName}</span>
                  </div>
                );
              })}
            </div> */}
          </div>
        </div>
      </>
    );
  }
  toggleTravelDetails = (id) => {
    //event.target.closest(".btn-arrow").classList.toggle("expand");
    var toggleIcon = document.getElementById(`toggleIcon${id}`);
    toggleIcon.classList.toggle("expand");
    var detailsRow = document.getElementById(`details${id}`);
    
    //x.classList.toggle("expand");

    if (detailsRow.offsetHeight === 0) {
      detailsRow.style.height = detailsRow.scrollHeight + "px";
    } else {
      detailsRow.style.height = "0";
    }
  };
  handleChangeSettlement = (requestId) => {
    this.props.getTravelRequestDetails(requestId);
    this.props.handleSettlementClick(requestId, false);
  };
  handleEditSettlement = (requestId) => {
    this.props.getTravelRequestDetails(requestId);
    this.props.handleSettlementClick(requestId, true);
  };

  handleCancelRequisition = (requestId) => {
    this.props.handleConfirmCancelRequest(requestId);
  };

  render() {
    
    let {
      handleRowChange,
      handleIndexChange,
      pageIndex,
      rowsPerPage,
      type,
    } = this.props;
    //let { loading } = this.props.travelSettlementReducer;
    let searchedPage =
      type === 1
        ? this.props.jobRequisitionReducer.approverRequisitionSearchedPage
        : this.props.jobRequisitionReducer.myRequisitionSearchedPage;
    let {
      loading,
      myRequisition,
      approverRequisition,
    } = this.props.jobRequisitionReducer;
    let gridData = type === 1 ? approverRequisition : myRequisition;
    return (
      <div>
        <div className="arrear-table">
          {loading ? (
            <div className="skeleton-table">
              <Skeleton variant="rect" width={"100%"} height={55} />
              <Skeleton variant="rect" width={"100%"} height={55} />
              <Skeleton variant="rect" width={"100%"} height={55} />
              <Skeleton variant="rect" width={"100%"} height={55} />
              <Skeleton variant="rect" width={"100%"} height={55} />
              <Skeleton variant="rect" width={"100%"} height={55} />
              <Skeleton variant="rect" width={"100%"} height={55} />
            </div>
          ) : (
            <div className="requisition-request-grid">
              <GridComponent
                dataSource={isEmpty(gridData) ? null : gridData}
                className="custom-grid"
                toolbar={this.toolbar}
                //  height={!isEmpty(searchedLeaveAbsence)?"500":'80'}
                id="grid"
                allowExcelExport={true}
                ref={(g) => (this.grid = g)}
                rowTemplate={this.gridTemplate.bind(this)}
              >
                <ColumnsDirective>
                  <ColumnDirective field="SettlementId" visible={false} />
                  <ColumnDirective
                    field="ReqID"
                    headerText="REQ ID"
                    width="102"
                  />
                  <ColumnDirective
                    field="JobTitle"
                    headerText="JOB TITLE"
                    width="112"
                  />
                  <ColumnDirective
                    field="Branch"
                    headerText="BRANCH"
                    width="142"
                    // template={this.desTemplate.bind(this)}
                  />
                  <ColumnDirective
                    field="Status"
                    headerText="STATUS"
                    width="114"
                    // template={this.desTemplate.bind(this)}
                  />
                  <ColumnDirective
                    field="Openings"
                    headerText="OPENINGS"
                    width="113"
                    // template={this.desTemplate.bind(this)}
                  />
                  <ColumnDirective
                    field="Approver"
                    headerText=""
                    width="120"
                    // template={this.desTemplate.bind(this)}
                  />
                  <ColumnDirective
                    field=""
                    width="150"
                    // template={this.CommandTemplate.bind(this)}
                  />
                  <ColumnDirective field="" width="40" />
                </ColumnsDirective>
              </GridComponent>
              {isEmpty(gridData) ? null : (
                <CommonPagination
                  handleRowChange={handleRowChange}
                  handleIndexChange={handleIndexChange}
                  pageIndex={pageIndex - 1}
                  searchedPage={searchedPage}
                  rowsPerPage={rowsPerPage}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

Table.propTypes = {
  //   loadTravelCategory: PropTypes.func.isRequired,
  //   setEditMode: PropTypes.func.isRequired,
  //   travelCategoryReducer: PropTypes.object.isRequired,
  //   deleteTravelCategory: PropTypes.func.isRequired,
  //   getAllowanceExpense: PropTypes.func.isRequired,
  //   getLevels: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  jobRequisitionReducer: state.jobRequisitionReducer,
});

//export default connect(mapStateToProps, {})(Table);
export default withRouter(
  connect(mapStateToProps, { getJobRequisitionDetails })(Table)
);
