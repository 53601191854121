import React, { Component } from "react";
import RouteBaseClass from "components/routeBaseClass";
import "../../scss";
import { connect } from "react-redux";

import { Tabs, Tab } from "components/tabs/index.d";

import { MdAdd } from "react-icons/md";
import Breadcrumb from "../../../common/breadcrumb";
import Button from "components/button";
import Loaders from "components/loaders";
import LegalClientTable from "./legalClientTable";
import SearchAndFilter from "./searchAndFilter";
import NewClientForm from "./newClientForm";
import NewProjectForm from "./newProject";
import RenewContractForm from "./renewContract";
import { GetCurrencyList, GetDepartments } from "actions/commonAction";
import {
  GetLegalClientTypes,
  GetLegalClientList,
  SaveProjectContract,
  DeleteClient,
  DeleteProject,
  ChangeArchiveStatus,
  GetBillingDesignations,
  GetSecretarialChargesTypes,
  GetActiveClientsList,
  GetMemberByName,
} from "../../action";
import { Enum_WhatToArchive } from "../../enums";

const tabs = [
  { tabId: 1, label: "Active Clients & Projects" },
  { tabId: 2, label: "Archived Clients & Projects" },
];
export class index extends RouteBaseClass {
  constructor(props) {
    super(props, ["Administrator"]);
    this.state = {
      isLoading: true,
      loading:false,
      pageIndex: 0,
      rowsPerPage: 10,
      searchClient: "",
      activeType: null,
      typingTimeout: 0,
      typing: false,

      isArchivedTab: false, //if activeTab==tab[0]?true:false
      activeTab: tabs[0],
      showNewClientForm: false,
      showNewProjectForm: false,
      clientIdToEdit: 0,
      projectIdToEdit: null,
      projectDataToEdit: null,

      isRenewContract: false,
      selectedProjectForRenew: null,
    };
  }

  async componentDidMount() {
    this.props.GetCurrencyList();
    this.props.GetLegalClientTypes();
    this.props.GetMemberByName();
    this.props.GetDepartments();
    this.props.GetBillingDesignations();
    this.props.GetSecretarialChargesTypes();
    this.props.GetActiveClientsList();

    await this.getLegalClientList();
    this.setState({ isLoading: false });
  }

  getLegalClientList = async () => {
    const {
      searchClient,
      activeType,
      isArchivedTab,
      pageIndex,
      rowsPerPage,
    } = this.state;

    const data = {
      searchText: searchClient,
      clientTypeId: activeType ? activeType.value : 0,
      isArchived: isArchivedTab,
      pageIndex: pageIndex,
      rowsPerPage: rowsPerPage,
    };

    await this.props.GetLegalClientList(data);
  };

  handleArchiveClient = (clientId) => {
    let whatToArchive = Enum_WhatToArchive.Client;
    const postData = {
      IsArchived: true,
      Id: clientId,
      WhatToArchive: whatToArchive,
    };
    this.props.ChangeArchiveStatus(postData, this);
  };
  handleArchiveProject = (projectId) => {
    let whatToArchive = Enum_WhatToArchive.Project;
    const postData = {
      IsArchived: true,
      Id: projectId,
      WhatToArchive: whatToArchive,
    };
    this.props.ChangeArchiveStatus(postData, this);
  };

  handleRestoreClient = (clientId) => {
    let whatToArchive = Enum_WhatToArchive.Client;
    const postData = {
      IsArchived: false,
      Id: clientId,
      WhatToArchive: whatToArchive,
    };
    this.props.ChangeArchiveStatus(postData, this);
  };

  handleRestoreProject = (projectId) => {
    let whatToArchive = Enum_WhatToArchive.Project;
    const postData = {
      IsArchived: false,
      Id: projectId,
      WhatToArchive: whatToArchive,
    };
    this.props.ChangeArchiveStatus(postData, this);
  };

  handleNewClientFormCallback = () => {
    this.setState({ showNewClientForm: false });
    this.getLegalClientList();
    this.props.GetActiveClientsList();
  };

  handleEditClient = (data) => {
    this.setState({
      showNewClientForm: true,
      clientIdToEdit: data.ClientId,
      projectIdToEdit: null,
    });
  };

  handleDeleteClient = (data) => {
    const clientId = data.ClientId;
    this.props.DeleteClient(clientId, this);
  };

  handleEditProject = (data) => {
    let clientIdToEdit = data ? data.ClientId : 0;
    let projectIdToEdit = data ? data.ProjectId : null;
    this.setState({
      showNewProjectForm: true,
      clientIdToEdit,
      projectIdToEdit,
      projectDataToEdit: data,
    });
  };

  handleDeleteProject = (data) => {
    const projectId = data.ProjectId;
    this.props.DeleteProject(projectId, this);
  };

  handleRowChange = (num) => {
    this.setState({ rowsPerPage: num }, async () => {
      await this.getLegalClientList();
    });
  };

  handleIndexChange = (num) => {
    this.setState({ pageIndex: num }, async () => {
      await this.getLegalClientList();
    });
  };

  // handleInput = (name, value) => {
  //   this.setState({ [name]: value, pageIndex: 0 }, async () => {
  //     await this.getLegalClientList();
  //   });
  // };

  // handleAutoSelect = (name, selected) => {
  //   this.setState({ [name]: selected, pageIndex: 0 }, async () => {
  //     await this.getLegalClientList();
  //   });
  // };
  handleChange = async (name, value) => {
    const self = this;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
   }
    this.setState({ [name]: value, pageIndex: 0 }, async () => {
      if(name === 'activeType'){
        await self.getLegalClientList();
      }
      if(name === 'searchClient'){
        await self.setState({loading:true})
        await self.setState({
          typing: false,
          typingTimeout: setTimeout(async function () {
             
              await self.getLegalClientList();
              await self.setState({loading:false}) 
          }, 2500)
        })
      }
    });
  };

  handleTabChange = (tab) => {
    let isArchivedTab = "";
    if (tab.tabId == 1) {
      isArchivedTab = false;
    } else if (tab.tabId == 2) {
      isArchivedTab = true;
    }
    this.setState(
      { activeTab: tab, isArchivedTab: isArchivedTab, pageIndex: 0 },
      async () => {
        await this.getLegalClientList();
      }
    );
  };

  handleFormDisplay = (name, value) => {
    if (!value) {
      this.setState({
        showNewClientForm: false,
        showNewProjectForm: false,
        clientIdToEdit: 0,
        projectIdToEdit: null,
      });
      this.getLegalClientList();
    }
    this.setState({ [name]: value });
  };

  handleRenewContract = (isRenew, selectedProjectForRenew) => {
    this.setState({ isRenewContract: isRenew, selectedProjectForRenew });
  };

  render() {
    const {
      isLoading,
      activeTab,
      pageIndex,
      rowsPerPage,
      showNewClientForm,
      showNewProjectForm,
      isArchivedTab,
      isRenewContract,
      selectedProjectForRenew,
    } = this.state;
    const {
      currencyList,
      legalClientTypes,
      legalClientList,
      loading,
    } = this.props;

    return (
      <div className="common-container">
        <div className="tasktracker-title-section">
          <Breadcrumb name="Clients" />
          <div className="add">
            <Button
              bg="primary"
              icon={<MdAdd />}
              buttonType="icon-button"
              title="New Client"
              onClick={() => this.setState({ showNewClientForm: true })}
            />
          </div>
        </div>

        {tabs && (
          <Tabs noBackground>
            {tabs.map((tab, index) => {
              return (
                <Tab
                  key={index}
                  label={tab.label}
                  name={tab.tabId}
                  id={tab.tabId}
                  isActive={activeTab.tabId === tab.tabId}
                  onClick={() => this.handleTabChange(tab)}
                >
                  <>
                    <SearchAndFilter
                      handleChange={this.handleChange}
                      {...this.state}
                      legalClientTypes={legalClientTypes}
                    />
                    {loading ? (
                      <Loaders
                        loading={loading}
                        type="skeleton"
                        name="custom-table"
                      />
                    ) : (
                      <LegalClientTable
                        data={legalClientList}
                        pageIndex={pageIndex}
                        rowsPerPage={rowsPerPage}
                        isArchivedTab={isArchivedTab}
                        getLegalClientList={this.getLegalClientList}
                        handleRowChange={this.handleRowChange}
                        handleIndexChange={this.handleIndexChange}
                        handleFormDisplay={this.handleFormDisplay}
                        handleEditClient={this.handleEditClient}
                        handleDeleteClient={this.handleDeleteClient}
                        handleArchiveClient={this.handleArchiveClient}
                        handleRestoreClient={this.handleRestoreClient}
                        handleEditProject={this.handleEditProject}
                        handleDeleteProject={this.handleDeleteProject}
                        handleArchiveProject={this.handleArchiveProject}
                        handleRestoreProject={this.handleRestoreProject}
                        handleRenewContract={this.handleRenewContract}
                        SaveProjectContract={this.props.SaveProjectContract}
                      />
                    )}
                  </>
                </Tab>
              );
            })}
          </Tabs>
        )}

        {showNewClientForm && (
          <NewClientForm
            title="Create New Client"
            clientId={this.state.clientIdToEdit}
            history={this.props.history}
            currencyList={currencyList}
            legalClientTypes={legalClientTypes}
            isFormLoading={false}
            showForm={showNewClientForm}
            handleCallback={this.handleNewClientFormCallback}
            handleFormDisplay={(value) =>
              this.handleFormDisplay("showNewClientForm", value)
            }
          />
        )}

        {showNewProjectForm && (
          <NewProjectForm
            history={this.props.history}
            title={
              this.state.projectIdToEdit ? "Edit Project" : "Add New Project"
            }
            isFormLoading={false}
            showForm={showNewProjectForm}
            legalClientTypes={legalClientTypes}
            handleFormDisplay={(value) =>
              this.handleFormDisplay("showNewProjectForm", value)
            }
            projectDataToEdit={this.state.projectDataToEdit}
            clientId={this.state.clientIdToEdit}
            projectId={this.state.projectIdToEdit}
            getLegalClientList={this.getLegalClientList}
          />
        )}

        {isRenewContract && selectedProjectForRenew && (
          // <RenewContract
          // onModalClose={this.onModalClose}
          // reloadList={this.reloadList}
          // SaveProjectContract={this.props.SaveProjectContract}
          // selectedProjectForRenew={selectedProjectForRenew}
          // />
          // <RenewContract
          //   title={"Renew Contract"}
          //   isFormLoading={false}
          //   showForm={isRenewContract}
          //   selectedProjectForRenew={selectedProjectForRenew}
          //   reloadList={this.reloadList}
          //   handleRenewContract={this.handleRenewContract}
          //   handleFormDisplay={(value) =>
          //     this.handleFormDisplay("isRenewContract", value)
          //   }
          //   SaveProjectContract={this.props.SaveProjectContract}
          // />

          <RenewContractForm
            isRenewContract={true}
            history={this.props.history}
            title={"Renew Contract"}
            isFormLoading={false}
            showForm={isRenewContract}
            legalClientTypes={legalClientTypes}
            handleFormDisplay={(value) =>
              this.handleFormDisplay("isRenewContract", value)
            }
            clientId={selectedProjectForRenew.ClientId}
            projectId={selectedProjectForRenew.ProjectId}
            selectedProjectForRenew={selectedProjectForRenew}
            getLegalClientList={this.getLegalClientList}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currencyList: state.commonReducer.currencyList,
  legalClientTypes: state.taskTrackerReducer.legalClientTypes,
  legalClientList: state.taskTrackerReducer.legalClientList,
  loading: state.taskTrackerReducer.loading,
});

const mapDispatchToProps = {
  GetCurrencyList,
  GetDepartments,
  GetBillingDesignations,
  GetSecretarialChargesTypes,
  GetLegalClientTypes,
  GetMemberByName,
  GetActiveClientsList,
  GetLegalClientList,
  SaveProjectContract,
  DeleteClient,
  DeleteProject,
  ChangeArchiveStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
