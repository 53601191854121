import React, { useState, useEffect } from "react";
import Icons from "components/icons";
import Clock from "components/clock";
import { Date_Format, formatedDayMonthYearWDay } from "helpers/dateFormat";
import InOut from "./inOut";

function Card(props: any) {
  const { attendanceAuthority, attendanceByDate, companyInformation } = props;

  const [todayAttendance, setTodayAttendance] = useState(null);
  useEffect(() => {
    if (attendanceByDate && attendanceByDate.length > 0) {
      const todayAttendance = attendanceByDate.find(
        (f) => Date_Format(f.DateEng) == Date_Format(new Date())
      );
      setTodayAttendance(todayAttendance);
    }
  }, [attendanceByDate]);

  return (
    <>
      {attendanceByDate &&
        attendanceAuthority &&
        attendanceAuthority.IsManualAttendance && (
          <div className="web-attendance-body-card">
            <div className="web-attendance-body-card-datetime">
              <span className="web-attendance-body-card-datetime-time">
                <Icons name="Time" />
                {/* <span>8:55 am</span> */}
                <span>
                  <Clock />
                </span>
              </span>
              <span className="web-attendance-body-card-datetime-date">
                {formatedDayMonthYearWDay(
                  new Date(),
                  companyInformation.IsEnglishDate
                )}
              </span>
            </div>

            <InOut
              type="ClockIn"
              todayAttendance={todayAttendance}
              SaveAttendanceTime={props.SaveAttendanceTime}
              SaveAttendanceNote={props.SaveAttendanceNote}
              UpdateAttendanceNote={props.UpdateAttendanceNote}
            />

            <InOut
              type="ClockOut"
              todayAttendance={todayAttendance}
              SaveAttendanceTime={props.SaveAttendanceTime}
              SaveAttendanceNote={props.SaveAttendanceNote}
              UpdateAttendanceNote={props.UpdateAttendanceNote}
            />
          </div>
        )}
    </>
  );
}

export default Card;
