import FormGroup from "components/form";
import React from "react";

function Address({
  propsData,
  data,
  onChange,
  onChangeAndGet,
  onSameAsPermanent,
  errors,
}) {
  const title = "address";

  const {
    permanentProvience,
    permanentDistrict,
    permanentLocalBody,
    permanentWard,
    permanentLocality,
    correspondingProvience,
    correspondingDistrict,
    correspondingLocalBody,
    correspondingWard,
    correspondingLocality,

    permanentDistrictList,
    permanentLocalBodyList,
    correspondingDistrictList,
    correspondingLocalBodyList,
  } = data;

  const { districtList, provinceList } = propsData;

  const inputWidth = "calc(100% - 225px)";

  const onTextChange = (name,value) => {
    //const { name, value } = e.target;
    onChange(title, name, value);
  };

  const onSelectChange = (name, value, targetName = "", targetList = "") => {
    onChange(title, name, value);

    const id = value?.value;
    onChangeAndGet(title, name, { id }, targetName, targetList);
  };

  return (
    <div className="form-block">
      <div className="form-block__title">
        <h3>Address</h3>
      </div>
      <div className="form-partition">
        <div className="form-block__form">
          <div className="address-subtitle">
            <p>Permanent Address</p>
          </div>
          <FormGroup
            formName="reactselect"
            onChange={(name, value) =>
              onSelectChange(name, value, "district", "permanentDistrictList")
            }
            name="permanentProvience"
            value={permanentProvience}
            options={provinceList}
            //width="300px"
            label="Province"
            error={errors?.permanentProvience}
            className="inline-form-group"
          />
          <FormGroup
            formName="reactselect"
            onChange={(name, value) =>
              onSelectChange(name, value, "localBody", "permanentLocalBodyList")
            }
            name="permanentDistrict"
            value={permanentDistrict}
            options={permanentDistrictList && permanentDistrictList}
            //width="300px"
            label="District"
            error={errors?.permanentDistrict}
            className="inline-form-group"
          />
          <FormGroup
            formName="reactselect"
            onChange={onSelectChange}
            name="permanentLocalBody"
            value={permanentLocalBody}
            options={permanentLocalBodyList && permanentLocalBodyList}
            //width="300px"
            label="Local Body"
            error={errors?.permanentLocalBody}
            className="inline-form-group"
          />
          <FormGroup
            name="permanentWard"
            value={permanentWard}
            label="Ward"
            onChange={onTextChange}
            type={"number"}
            className="inline-form-group"
            error={errors.permanentWard}
          />
          <FormGroup
            formName="textarea"
            name="permanentLocality"
            value={permanentLocality}
            label="Locality"
            onChange={onTextChange}
            className="inline-form-group"
            error={errors.permanentLocality}
          />
          {/* <InputGroup
            name="permanentWard"
            value={permanentWard}
            label="Ward"
            onChange={onTextChange}
            type={"number"}
            className="inline-form-group"
            error={errors.permanentWard}
          />

          <InputGroup
            tag={"textarea"}
            name="permanentLocality"
            value={permanentLocality}
            label="Locality"
            onChange={onTextChange}
            className="inline-form-group"
            error={errors.permanentLocality}
          /> */}
        </div>

        <div className="form-block__form corresponding">
          <div className="address-subtitle flex">
            <p>Corresponding Address</p>
            <p className="address-subtitle-next" onClick={onSameAsPermanent}>
              Same as permanent
            </p>
          </div>
          <FormGroup
            formName="reactselect"
            onChange={(name, value) =>
              onSelectChange(
                name,
                value,
                "district",
                "correspondingDistrictList"
              )
            }
            name="correspondingProvience"
            value={correspondingProvience}
            options={provinceList}
            //width="300px"
            label="Province"
            error={errors?.correspondingProvience}
            className="inline-form-group"
          />
          <FormGroup
            formName="reactselect"
            onChange={(name, value) =>
              onSelectChange(
                name,
                value,
                "localBody",
                "correspondingLocalBodyList"
              )
            }
            name="correspondingDistrict"
            value={correspondingDistrict}
            options={correspondingDistrictList && correspondingDistrictList}
            //width="300px"
            label="District"
            error={errors?.correspondingDistrict}
            className="inline-form-group"
          />
          <FormGroup
            formName="reactselect"
            onChange={onSelectChange}
            name="correspondingLocalBody"
            value={correspondingLocalBody}
            options={correspondingLocalBodyList && correspondingLocalBodyList}
            //width="300px"
            label="Local Body"
            error={errors?.correspondingLocalBody}
            className="inline-form-group"
          />
          <FormGroup
            name="correspondingWard"
            value={correspondingWard}
            label="Ward"
            onChange={onTextChange}
            type={"number"}
            className="inline-form-group"
            error={errors?.correspondingWard}
          />
          <FormGroup
            formName="textarea"
            name="correspondingLocality"
            value={correspondingLocality}
            label="Locality"
            onChange={onTextChange}
            className="inline-form-group"
            error={errors?.correspondingLocality}
          />
          {/* <InputGroup
            name="correspondingWard"
            value={correspondingWard}
            label="Ward"
            onChange={onTextChange}
            type={"number"}
            className="inline-form-group"
            error={errors.correspondingWard}
          />

          <InputGroup
            tag={"textarea"}
            name="correspondingLocality"
            value={correspondingLocality}
            label="Locality"
            onChange={onTextChange}
            className="inline-form-group"
            error={errors.correspondingLocality}
          /> */}
        </div>
      </div>
    </div>
  );
}

export default Address;
