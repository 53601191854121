import React, {Component} from "react";
import Loader from "components/loaders";
import {connect} from "react-redux";
import {addLoading} from "actions/loadingActions";
import {refreshToken} from "actions/authAction";
import Loading from "components/common/loading";
import isEmpty from "helpers/isEmpty";
import axios from "axios";
import {toastMessage} from "actions/validateAction";

const LoadingHOC = (Components: any) => {
  class LoadingHOCClass extends Component<any, any> {
    componentWillMount() {
      const self = this;
      axios.interceptors.request.use(
        function (config) {
          // console.log(config)
          // this.setState({loadinng:true})
          if (config.headers.loading) {
            self.props.addLoading(true);
          }
          if (config.headers.type) {
            self.props.addLoading(true, config.headers.type);
          }
          return config;
        },
        function (error) {
          self.props.addLoading(false);
          return Promise.reject(error);
        }
      );
      axios.interceptors.response.use(
        function (response) {
          //   this.setState({loadinng:false})
          if (response.config.headers.loading) {
            self.props.addLoading(false);
          }
          if (response.config.headers.type) {
            self.props.addLoading(false, response.config.headers.type);
          }
          // console.log(response)
          return response;
        },
        function (error) {
          // console.log(error)
          // console.log(error.config)
          // console.log(error.response)
          self.props.addLoading(false);
          return new Promise((resolve, reject) => {
            const originalReq = error.config;

            if (
              self.props.isAuthenticated &&
              error.response.status === 401 &&
              error.config &&
              !error.config.__isRetryRequest
            ) {
              self.props.refreshToken();
            }
            return Promise.reject(error);
          });
        }
      );
    }
    // componentDidUpdate(prevProps){
    //         // console.log(prevProps);
    //         const {location:{pathname}}=this.props;
    //         const {location:{pathname:prevPath}}= prevProps;
    //         console.log(this.props)
    //         if(pathname !== prevPath){
    //             let title = pathname.split('/');
    //             console.log(title)
    //             if(!isEmpty(title) && title.length > 0 && Array.isArray(title)){
    //                 title=title[title.length - 1].toUpperCase();
    //             }else{
    //                 title="HRMS"
    //             }
    //             document.title = title;
    //         }

    // }
    render() {
      const {loading, load} = this.props;
      if (loading) {
        // return <Loader open={loading} />
        return null;
      }
      // if(load){
      //     return(
      //         <Loading />
      //     )
      // }
      // if(load){
      //   return null
      // }
      return <Components {...this.props} />;
    }
  }
  const mapStateToProps = (state) => ({
    loading: state.auth.loading,
    load: state.loadingReducer.loading,
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  });
  return connect(mapStateToProps, {addLoading, toastMessage, refreshToken})(
    LoadingHOCClass
  );
};

export default LoadingHOC;
