import React from "react";

function Count(props) {
  const { managerAssignmentSummary } = props;
  return (
    <div className="managers-count">
      <ul className="managers-count__list">
        {managerAssignmentSummary &&
          managerAssignmentSummary.map((mas, i) => {
            return (
              <li
                className="managers-count__item"
                key={i}
                onClick={() => props.handleClickSummary(mas.Tag)}
              >
                <div className="mangers-count__block">
                  <div className="managers-count__num">
                    <span>{mas.Value}</span>
                  </div>
                  <div className="managers-count__name">
                    <span>{mas.Label}</span>
                  </div>
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
}

export default Count;
