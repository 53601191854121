import React from "react";

function WorkAndExperience(props) {
  const { data } = props;
  return (
    <div className="information-wholeInfo-nav-block">
      <div className="information-wholeInfo-nav-block-title">
        <span>WORK EXPERIENCE</span>
        <span className="fonts-light_medium">{data.TotalExperience}</span>
      </div>

      {data &&
        data.map((d, i) => {
          return (
            <div className="information-wholeInfo-nav-block-subtitle" key={i}>
              <div className="information-wholeInfo-nav-block-subtitle-left">
                <span className="fonts-light_medium">
                  {d.FromDate + " - " + d.ToDate}
                </span>
              </div>

              <div className="information-wholeInfo-nav-block-subtitle-right">
                <div className="information-wholeInfo-nav-block-subtitle-description">
                  <span className="fonts-dark_medium">{d.Position}</span>
                  <span className="fonts-light_medium">at</span>
                  <span className="fonts-dark_medium">
                    {d.OrganizationName}
                  </span>
                  <span className="fonts-light_medium">for</span>
                  <span className="fonts-dark_medium">{d.Experience}</span>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default WorkAndExperience;
