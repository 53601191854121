import React from "react";
import { FaEdit } from 'react-icons/fa'
import { connect } from "react-redux";
import Button from 'components/button'

export class ContactInfo extends React.Component{
    render(){
        const {
            contactInfo : { 
                OfficePhone,
                OfficeEmail,
                PersonalPhone,
                PersonalMobile,
                PersonalEmail
            }
        } = this.props || { contactInfo : {} };
        return (
            <div className="personal-row">
            <div className="personal-column">
                <div className="personal-block">
                    <div className="personal-block__title">
                        <h2>Office Contact</h2>
                        <Button title="Change" bg="subtle" buttonClass="personal-block__btn" />
                    </div>
                    <div className="personal-block__body">
                        {/* <div className="personal-block__icon">
                            <FaEdit />
                        </div> */}
                        <div className="personal-block__div">
                            <div className="personal-label">
                                Office Phone
                            </div>
                            <div className="value">
                                {OfficePhone ? OfficePhone : '-'}
                            </div>
                        </div>
                        <div className="personal-block__div">
                            <div className="personal-label">Office Email</div>
                            <div className="value">
                                {OfficeEmail ? OfficeEmail : '-'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="personal-column">
                <div className="personal-block">
                    <div className="personal-block__title">
                        <h2>Home Contact</h2>

                        <Button title="Change" bg="subtle" buttonClass="personal-block__btn" />
                    </div>
                    <div className="personal-block__body">
                        {/* <div className="personal-block__icon">
                                <FaEdit />
                        </div> */}
                        <div className="personal-block__div">
                            <div className="personal-label">
                                Home Phone
                            </div>
                            <div className="value">
                                {PersonalPhone ? PersonalPhone : '-'}
                            </div>
                        </div>
                        <div className="personal-block__div">
                            <div className="personal-label">
                                Mobile Number
                            </div>
                            <div className="value">
                                {PersonalMobile ? PersonalMobile : '-'}
                            </div>
                        </div>
                        <div className="personal-block__div">
                            <div className="personal-label">
                                Personal E-mail
                            </div>
                            <div className="value">
                                {PersonalEmail ? PersonalEmail : '-'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        );
    }
};
const mapStateToProps = state => ({
    contactInfo: state.employeeDashboard.employeeDetail && state.employeeDashboard.employeeDetail.EmployeeContacts || {}
});

export default connect(mapStateToProps, null)(ContactInfo);
