import React, { Component } from 'react'
import { ColumnDirective, ColumnsDirective, GridComponent } from '@syncfusion/ej2-react-grids';
import {FaCheckDouble,FaRegTimesCircle} from 'react-icons/fa'

export class Table extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             
        }
        this.template = this.gridTemplate;
    }
    
    gridTemplate(props) {
        console.log(props)
        return (
            <tr className="leave-row">
            <td className="">
                <a href="#">
                    <span>
                        {props.EIN}
                    </span>
                </a>
                
            </td>
            <td className="">
                <a href="#">
                    <span>
                        {props.Name}
                    </span>
                </a>
                
            </td>
            <td className="">
                <span>
                    {props.Status}
                </span>
            </td>
            <td className="">
                <span>
                    {props.Designation}
                </span>
            </td>
            <td className="">
                <span>
                    {props["Current Salary"]}
                </span>
            </td>
            <td className="">
                <span>
                    {props["New Salary"]}
                </span>
            </td>
            <td>
                <span>
                    {props["Effective Date"]}
                </span>
            </td>
            <td>
                <span>
                {
                    props.Months || props.Months===""?
                    <input type="text" className="table-input"/>
                    :null
                }
                
                    
                </span>
            </td>
            <td>
                <span>
                {
                    props.Days || props.Days===""?
                    <input type="text" className="table-input"/>
                    :null
                }
                
                    
                </span>
            </td>
            <td>
                <span>
                {
                    props['Total Increase'] || props['Total Increase']===""?
                    <input type="text" className="table-input"/>
                    :null
                }
                
                </span>
            </td>
            <td>{
                    props.EIN?
                    <a href="#" className="btn btn-blue">Save</a>
                    :null
                }
            </td>
            <td>{
                    props.EIN?
                    <a href="#" className="btn btn-grey">Delete</a>
                    :null
                }
            </td>
        </tr>
        );
    }
    render() {
        const data = [
            {'EIN':'HO-325','Name':'Kamala Gharti','Status':'Permanent','Designation':'Finance Assistant',
            'Current Salary':'10,000.00','New Salary':'12,000.00','Effective Date':'2076/06/01',
            'Months':'','Days':'','Total Increase':''}
        ]
        return (
            <div className="arrear-table">
                <GridComponent dataSource={data}  rowTemplate={this.template.bind(this)}>
                    <ColumnsDirective>
                        <ColumnDirective field='EIN'  width='60'/>
                        <ColumnDirective field='Name' width='100'/>
                        <ColumnDirective field='Status' width='60'/>
                        <ColumnDirective field='Designation' width='100' textAlign="Center"/>
                        <ColumnDirective field='Current Salary' width='100'/>
                        <ColumnDirective field='New Salary' width='100'/>
                        <ColumnDirective field='Effective Date' width='100'/>
                        <ColumnDirective field='Months' width='70'/>
                        <ColumnDirective field='Days' width='70'/>
                        <ColumnDirective field='Total Increase' width='100'/>
                        <ColumnDirective field='' width='120'/>
                        <ColumnDirective field='' width='120'/>
                    </ColumnsDirective>
                </GridComponent>
            </div>
        )
    }
}

export default Table
