import React, { Component } from "react";
import classnames from "classnames";
import { expandIcon } from "scenes/taskTracker/common/expandIcon";

export class TableRowByProject extends Component {
  constructor(props) {
    super(props);
    this.state = { drop: false };
    this.toggleDetail = this.toggleDetail.bind(this);
  }

  componentDidMount() {
    if (this.props.index == this.props.indexToExpand) {
      this.setState({ drop: true });
    }
  }

  toggleDetail = (id, drop) => {
    this.setState({ drop: !drop });
    var toggleIcon = document.getElementById(`toggleIcon${id}`);
    toggleIcon.classList.toggle("expand");
    var detailsRow = document.querySelectorAll(`.details-${id}`);

    if (detailsRow) {
      for (var i = 0; i < detailsRow.length; i++) {
        detailsRow[i].classList.toggle("row-expand");
      }
    }
  };

  // componentDidMount() {
  //   this.toggleDetail(this.props.index);
  // }

  render() {
    const { props } = this;
    const { drop } = this.state;
    let trClass = "table-row";
    if (drop) {
      trClass = trClass + " expanded__tr";
    }
    return (
      <>
        <tr
          className={trClass}
          role="row"
          key={props.index}
          id={`toggleIcon${props.index}`}
          onClick={() => this.toggleDetail(props.index, drop)}
        >
          <td className="e-rowcell" role="gridcell">
            {props.PeopleInProject &&
              props.PeopleInProject.length > 0 &&
              expandIcon(drop)}
          </td>
          <td className="e-rowcell" role="gridcell">
            <span>{props.ClientName}</span>
          </td>
          <td className="e-rowcell" role="gridcell">
            <span></span>
          </td>
          <td className="e-rowcell" role="gridcell">
            <span></span>
          </td>
          <td className="e-rowcell" role="gridcell">
            <span></span>
          </td>
        </tr>

        {props.PeopleInProject &&
          props.PeopleInProject.map((detail, i) => {
            const rowClasses = classnames({
              "table-details": true,
              "custom-row": true,
              "expanded__tr-details": true,
              "row-expand": props.index == props.indexToExpand,
              [`details-${props.index}`]: true,
            });
            return (
              <tr className={rowClasses} key={i}>
                <td></td>
                <td>
                  <span className="detail">
                    <span className="project">
                      <span className="project-icon">P</span>
                      <span>{detail.ProjectName}</span>
                    </span>
                  </span>
                </td>
                <td>
                  <span className="detail">{detail.MemberName}</span>
                </td>

                <td>
                  <span className="detail">
                    {detail.BillingDesignationName}
                  </span>
                </td>

                <td>
                  <span>{/* //Hourly rate */}</span>
                </td>
              </tr>
            );
          })}
      </>
    );
  }
}

export default TableRowByProject;
