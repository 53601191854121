import * as React from "react";
import isEmpty from "isEmpty";
import { Skeleton } from "@material-ui/lab";

interface IAppProps {
  auditLogData: any;
  loadingType?: string;
  loading?: boolean;
}
const renderLoading = (props) => {
  const { loading, loadingType, width } = props;
  if (loadingType === "skeleton") {
    return (
      <div className="skeleton-card wd-100" style={{ padding: 0 }}>
        <Skeleton
          animation="wave"
          height={20}
          width="20%"
          style={{ marginTop: 12, borderRadius: 2, marginLeft: 15 }}
        />
        <Skeleton
          animation="wave"
          height={3}
          width="100%"
          style={{ marginTop: 12, borderRadius: 2 }}
        />
        <div className="skeleton-card__flex flex-column pl-md items-start pt-sm mb-sm">
          <Skeleton animation="wave" height={15} width="100px" />
          <Skeleton animation="wave" height={15} width="130px" />
        </div>
        <Skeleton
          animation="wave"
          height={3}
          width="97%"
          style={{ marginLeft: 7 }}
        />
        <div className="skeleton-card__flex flex-column pl-md items-start pt-sm mb-sm">
          <Skeleton animation="wave" height={15} width="100px" />
          <div className="flex">
            <Skeleton animation="wave" height={15} width="130px" />
            <Skeleton animation="wave" height={15} width="140px" />
          </div>
        </div>
        <Skeleton
          animation="wave"
          height={3}
          width="97%"
          style={{ marginLeft: 7 }}
        />
        <div className="skeleton-card__flex flex-column pl-md items-start pt-sm mb-sm">
          <Skeleton animation="wave" height={15} width="100px" />
          <Skeleton animation="wave" height={15} width="130px" />
        </div>
        <Skeleton
          animation="wave"
          height={3}
          width="97%"
          style={{ marginLeft: 7 }}
        />
        <div className="skeleton-card__flex flex-column pl-md items-start pt-sm mb-sm">
          <Skeleton animation="wave" height={15} width="100px" />
          <div className="flex">
            <Skeleton animation="wave" height={15} width="130px" />
            <Skeleton animation="wave" height={15} width="140px" />
          </div>
        </div>
      </div>
    );
  } else if (loadingType === "circle") {
    return <h2>Loading...</h2>;
  } else {
    return <h2>Loading...</h2>;
  }
};
const App: React.FunctionComponent<IAppProps> = (props) => {
  const { auditLogData, loading } = props;
  const load = !isEmpty(loading) ? loading : false;
  if (load) {
    return renderLoading(props);
  } else if (isEmpty(auditLogData)) return null;
  return (
    <div className="audit-history">
      <div className="audit-history__header">
        <span>Change History</span>
      </div>
      <div className="audit-history__body history-grid">
        {auditLogData?.map((x) => {
          return (
            <>
              <div className="history-grid__row">
                <span className="date">{x.ModifiedOn}</span>
                <span className="editor">{x.ChangedBy}</span>
              </div>
              <div className="history-grid__row">
                <span className="row-label">{x.Type}</span>
                <div>
                  <span className="old-value">{x.BeforeValue}</span>
                  <span className="new-value">{x.AfterValue}</span>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default App;
