import React from "react";
import isEmpty from "isEmpty";
import Avatar from "react-avatar";
import man from "assets/images/man.png";
import { useSelector } from 'react-redux';
import { getUploadPath } from 'constants/userDetails'

function EmployeeInfo(props) {
  const empInfo = useSelector((state: any) => state.sbiLoanReducer.loanRequesterInformation);
  const { EmployeeId, EmployeeName, Designation, Branch, Department, EmployeeStatus, ServicePeriod, UrlPhoto } = empInfo || {};
  if (isEmpty(empInfo)) return null;
  console.log("getUploadPath(UrlPhoto)", getUploadPath(UrlPhoto));
  return (
    <div className="loan-employee-info mb-md">
      <div className="loan-employee-image">
        {UrlPhoto ?  <Avatar
          alt={"x.FullName"}
          src={getUploadPath(UrlPhoto)}
          className="emp-image"
          size={"200"}
        /> : (
          <img src={man} alt="Employee Avatar" />
        )}
      </div>
      <div className="loan-employee-details mb-sm flex-column justify-between">
        <div className="loan-employee-details__id text-md mb-sm">
          ID {EmployeeId}
        </div>
        <div className="loan-employee-details__name mb-sm">
          {EmployeeName}
        </div>
        <div className="loan-employee-details__position text-md">
          {Designation}
        </div>
        <div className="loan-employee-details__department text-md mb-sm">
          {Branch}, {Department}
        </div>
        <span className="jobstatus-hsk">{EmployeeStatus}</span>
        <div className="loan-employee-details__service text-md mt-sm">
          Service: {ServicePeriod}
        </div>
      </div>
    </div>
  )
}
export default EmployeeInfo;
