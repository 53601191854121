import React, { Component } from "react";
import FormGroup from "components/form";
import CustomDateRange from "components/customdaterange";
import isEmpty from "helpers/isEmpty";
import EmployeeSearch from "components/common/employeeSearch";

export class SearchAndFilter extends Component {
  render() {
    const {
      searchEmployee,
      activePosition,
    } = this.props.parentState;
    const { designationList, handleAutoSelect, levelList } = this.props;
    return (
      <div className="filters">
        {/* <EmployeeSearch
          onChange={handleAutoSelect}
          value={searchEmployee}
          label={false}
          name="searchEmployee"
          placeholder={"Search Employee"}
          // width="220px"
        /> */}
        <FormGroup
          onChange={handleAutoSelect}
          name="searchEmployee"
          label={false}
          value={searchEmployee}
          placeholder="Employee name"
          width="200px"
        />
        <div className="filters-grid">
          <FormGroup
            onChange={this.props.handleAutoSelect}
            name="activePosition"
            value={activePosition}
            options={levelList}
            formName="reactselect"
            placeholder="Search Job Role"
          />
        </div>
      </div>
    );
  }
}

export default SearchAndFilter;
