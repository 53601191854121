import React from "react";
import { connect } from "react-redux";
import { FaEdit } from "react-icons/fa";
import Button from 'components/button';
export class PersonalDetail extends React.Component {

    render () {
        const {
            personalDetail : {
                EmployeeName,
                DateOfBirth,
                Gender,
                MaritalStatus,
                Nationality
            },
            familyDetail
        } = this.props || {};

        let familyObj = {};
        
        familyDetail.forEach(member => {
            switch (member.Relation.toLowerCase()) {
                case "spouse": 
                    return familyObj.Spouse = member.Name;
                // case "Wife": 
                //     return familyObj.Spouse = member.Name;
                case "children": 
                    return familyObj.Children = member.Name;
                case "grandfather": 
                case "grand father":
                    return familyObj.GrandFather = member.Name;
                case "father": 
                    return familyObj.Father = member.Name;
                case "mother": 
                case "mother1": 
                    return familyObj.Mother = member.Name;
                default: 
                    return {};
            }
        });
        return (
            <div className="personal-row">
            <div className="personal-column">
                <div className="personal-block">
                    <div className="personal-block__title">
                        <h2>Personal Detail</h2>
                        <Button title="Change" bg="subtle" buttonClass="personal-block__btn" />
                    </div>
                    <div className="personal-block__body">
                        {/* <div className="personal-block__icon">
                            <FaEdit />
                        </div> */}
                        <div className="personal-block__div">
                            <div className="personal-label">
                                Name
                            </div>
                            <div className="value1">
                                {EmployeeName}
                            </div>
                        </div>
                        <div className="personal-block__div">
                            <div className="personal-label">
                                Date of Birth
                            </div>
                            <div className="value1">
                                {DateOfBirth}
                            </div>
                        </div>
                        <div className="personal-block__div">
                            <div className="personal-label">
                                Gender
                            </div>
                            <div className="value1">
                                {Gender}
                            </div>
                        </div>
                        <div className="personal-block__div">
                            <div className="personal-label">
                                Marital Status
                            </div>
                            <div className="value1">
                                {MaritalStatus}
                            </div>
                        </div>
                        <div className="personal-block__div">
                            <div className="personal-label">
                                Nationality
                            </div>
                            <div className="value1">
                                {Nationality}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="personal-column">
                <div className="personal-block">
                    <div className="personal-block__title">
                        <h2>Family</h2>
                        <Button title="Change" bg="subtle" buttonClass="personal-block__btn" />
                    </div>
                    <div className="personal-block__body">
                        {/* <div className="personal-block__icon">
                            <FaEdit />
                        </div> */}
                        <div className="personal-block__div">
                            <div className="personal-label">
                                Father
                            </div>
                            <div className="value1">
                                {familyObj.Father ? familyObj.Father : '-'}
                            </div>
                        </div>
                        <div className="personal-block__div">
                            <div className="personal-label">
                                Mother
                            </div>
                            <div className="value1">
                                {familyObj.Mother ? familyObj.Mother : '-'}
                            </div>
                        </div>
                        <div className="personal-block__div">
                            <div className="personal-label">
                                Grandfather
                            </div>
                            <div className="value1">
                                {familyObj.GrandFather ? familyObj.GrandFather : '-'}
                            </div>
                        </div>
                        <div className="personal-block__div">
                            <div className="personal-label">
                                Spouse
                            </div>
                            <div className="value1">
                                {familyObj.Spouse ? familyObj.Spouse : '-'}
                            </div>
                        </div>
                        <div className="personal-block__div">
                            <div className="personal-label">
                                Children
                            </div>
                            <div className="value1">
                                {familyObj.Children ? familyObj.Children : '-'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
};
const mapStateToProps = state => ({
    personalDetail: state.employeeDashboard.employeeDetail && state.employeeDashboard.employeeDetail.PersonalDetail || {},
    familyDetail: state.employeeDashboard.employeeDetail && state.employeeDashboard.employeeDetail.Family || []
});

export default connect(mapStateToProps, null)(PersonalDetail);