import React, { Component } from "react";
import {Tabs,Tab} from "components/tabs/index.d";
import DisciplinaryTable from "./disciplinaryTable";

export class DisciplinaryTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fixedTabs: [
        { tabId: 1, stageId: 0, label: "New Cases" },
        { tabId: 2, stageId: 5, label: "Open Cases" },
        { tabId: 7, stageId: 6, label: "Closed" },
      ],
      activeTabId: 2,
    };
  }

  async componentDidMount() {
    await this.handleDisciplinaryStages();
  }

  handleDisciplinaryStages = async () => {
    const { disciplinaryStages } = this.props;
    let tabs = [...this.state.fixedTabs];
    Promise.all(
      disciplinaryStages &&
        disciplinaryStages.map((ds, i) => {
          tabs.push({
            tabId: i + 3, //note: this list returned from this api lies from 4 to 7
            stageId: ds.ActionStageId,
            label: ds.StageName,
          });
        })
    );

    tabs.sort((a, b) => a.tabId - b.tabId); //sort by tabId
    this.setState({ tabs: tabs });
  };

  handleTabChange = (tabId) => {
    const { tabs } = this.state;
    const activeStageId = tabs && tabs.find((f) => f.tabId == tabId).stageId;
    this.setState({ activeTabId: tabId });

    this.props.handleStageChange && this.props.handleStageChange(activeStageId);
  };

  render() {
    const { disciplinaryList } = this.props;
    const { tabs, activeTabId } = this.state;
    const { pageIndex, rowsPerPage } = this.props.parentState;
    const activeStageId =
      tabs && tabs.find((f) => f.tabId == activeTabId).stageId;
    return (
      <div>
        {tabs && (
          <Tabs 
            // type="block" 
            noBackground>
            {tabs.map((tab, index) => {
              return (
                <Tab
                  label={tab.label}
                  name={tab.tabId}
                  id={tab.tabId}
                  key={index}
                  isActive = {activeTabId===tab.tabId}
                  onClick={() => this.handleTabChange(tab.tabId)}
                >
                  <DisciplinaryTable
                    stageId={activeStageId}
                    data={disciplinaryList}
                    pageIndex={pageIndex}
                    rowsPerPage={rowsPerPage}
                    handleRowChange={this.props.handleRowChange}
                    handleIndexChange={this.props.handleIndexChange}
                    handleMoveToNextStage={this.props.handleMoveToNextStage}
                    handleEdit={this.props.handleEdit}
                    handleDelete={this.props.handleDelete}
                  />
                </Tab>
              );
            })}
          </Tabs>
        )}
      </div>
    );
  }
}

export default DisciplinaryTabs;
