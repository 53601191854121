import React from 'react'
import {Tabs,Tab} from 'components/tabs/index.d'
import Active from './active';
import History from './history'

function index(props) {

    const fetchHistory = () => {
        props.getEmployeeManagerHistory(props.employeeId);
    }
    
    // const fetchEmployeeManager = () => {
    //     props.getEmployeeManagers(props.employeeId);
    // }

    return (
        <div className="placement-reporting reporting">
            <div className="tertiary-title">
                <h3>Reporting to</h3>
            </div>
            <div className="reporting-tab">
                <Tabs>
                    <Tab label="Active" name="Active"
                        // onClick={fetchEmployeeManager}
                    >
                        <Active {...props}/>
                    </Tab>
                    <Tab label="History" name="History"
                        onTabClick={fetchHistory}
                    >
                        <History />
                    </Tab>
                </Tabs>
            </div>
        </div>
    )
}

export default index
