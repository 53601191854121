import React from 'react'
import Button from 'components/button'
import FormGroup from 'components/form'
import { connect } from "react-redux";
import {
  saveEmployeeAddressDetails,
  updateEmployeeEducationDetails,
  getEmployeeEducationList,
  GetQualificationList,
  getEmployeeAddressDetails,
} from '../action';
import {
  GetProvienceList,
  GetDistrictByProvince,
  GetLocalBodyByDistrict,
} from "actions/commonAction";
import { InputValidator } from "helpers/inputValidator";
import isEmpty from "isEmpty";

interface IAppProps {
  EmployeeId: any;
  EmployeeAddress: any;
  GetProvienceList: Function,
  GetDistrictByProvince: Function,
  GetLocalBodyByDistrict: Function,
  provinceList: any,
  districtList: any,
  localBodyList: any,
  editView?: any,
  getEmployeeAddressDetails?: Function,
  saveEmployeeAddressDetails: Function,
  employeeAddressSaved?: boolean,
  editMode?: boolean,
  toggleEditMode?: Function,
}

interface State {
  ProvinceP?: any,
  DistrictP?: any,
  LocalBodyP?: any,
  WardP?: any,
  LocalityP?: any,
  ProvinceT?: any,
  DistrictT?: any,
  LocalBodyT?: any,
  WardT?: any,
  LocalityT?: any,
  // comments: any[];
  errors?: any;
  provinceTList: any,
  provincePList: any,
  localBodyPList: any,
  localBodyTList: any,
  districtTList: any,
  districtPList: any,
  geoLocation: any,
  // employeeDetail: any,
  // empRequest: any,
}

class AddressForm extends React.Component<IAppProps, State> {
  // export class AddressForm extends React.Component {
    // const { EmployeeAddress, EmployeeId } = props;
    state = {
        ProvinceP: null,
        DistrictP: null,
        LocalBodyP: null,
        WardP: null,
        LocalityP: null,
        ProvinceT: null,
        DistrictT: null,
        LocalBodyT: null,
        WardT: null,
        LocalityT: null,
        errors: null,
        provinceTList: null,
        provincePList: null,
        localBodyPList: null,
        localBodyTList: null,
        districtTList: null,
        districtPList: null,
        geoLocation: null,
    };

    async componentDidMount() {
      const { EmployeeId, editMode, EmployeeAddress } = this.props;
      await this.props.GetProvienceList()
      if (EmployeeId && editMode && EmployeeAddress) {
        
        const {
          PEDistrictId, PELocalBodyId, PEProvinceId, PEWardNo, PELocality,
          PSDistrictId, PSLocalBodyId, PSProvinceId, PSWardNo, PSLocality,
          GeoLocation,
        } = EmployeeAddress;
        await this.props.GetDistrictByProvince(PEProvinceId);
        let districtPList = this.props.districtList;
        let DistrictP = districtPList.filter(d => d.value == PEDistrictId)?.[0];
        await this.props.GetLocalBodyByDistrict(PEDistrictId);
        let localBodyPList = this.props.localBodyList;
        let LocalBodyP = localBodyPList.filter(l => l.value == PELocalBodyId)?.[0];
        await this.props.GetDistrictByProvince(PSProvinceId);
        let districtTList = this.props.districtList;
        let DistrictT = districtTList.filter(d => d.value == PSDistrictId)?.[0];
        await this.props.GetLocalBodyByDistrict(PSDistrictId);
        let localBodyTList = this.props.localBodyList;
        let LocalBodyT = localBodyTList.filter(l => l.value == PSLocalBodyId)?.[0];

        let ProvinceP = this.props.provinceList.filter(p => p.value == PEProvinceId)?.[0];
        let ProvinceT = this.props.provinceList.filter(p => p.value == PSProvinceId)?.[0];
        const { provinceList } = this.props;
        this.setState({
          provincePList: provinceList,
          provinceTList: provinceList,
          districtPList, districtTList,
          localBodyPList, localBodyTList,
          ProvinceP, ProvinceT,
          DistrictP, DistrictT,
          LocalBodyP, LocalBodyT,
          WardP: PEWardNo,
          WardT: PSWardNo,
          LocalityP: PELocality,
          LocalityT: PSLocality,
          geoLocation: GeoLocation,
        })
      } else {
        const { provinceList } = this.props;
        this.setState({ provincePList: provinceList, provinceTList: provinceList });
      }
    }

    handleAutoSelect = async (label, value) => {
      if (label == 'ProvinceP' && value) {
        await this.props.GetDistrictByProvince(value.value);
        const { districtList } = this.props;
        this.setState({ [label]: value, districtPList: districtList, DistrictP: null } as Pick<State, keyof State>)
      }
      else if (label == 'ProvinceT' && value) {
        await this.props.GetDistrictByProvince(value.value);
        const { districtList } = this.props;
        this.setState({ [label]: value, districtTList: districtList, DistrictT: null } as Pick<State, keyof State>)
      } else if (label == 'DistrictP' && value) {
        await this.props.GetLocalBodyByDistrict(value.value);
        const { localBodyList } = this.props;
        this.setState({ [label]: value, localBodyPList: localBodyList, LocalBodyP: null } as Pick<State, keyof State>)

      } else if (label == 'DistrictT' && value) {
        await this.props.GetLocalBodyByDistrict(value.value);
        const { localBodyList } = this.props;
        this.setState({ [label]: value, localBodyTList: localBodyList, LocalBodyT: null } as Pick<State, keyof State>)
      } else 
        this.setState({ [label]: value } as Pick<State, keyof State>);
    };

    getAdditionalDetails = async (type, value) => {
      if (type == 'Province') {
        await this.props.GetDistrictByProvince(value);
      }
      else if (type == 'District')
        await this.props.GetLocalBodyByDistrict(value);

    }

    setSameTemporaryAddress = () => {
        const {
          ProvinceP,
          DistrictP,
          LocalBodyP,
          WardP,
          LocalityP,
        } = this.state;
        this.setState({
          ProvinceT: ProvinceP,
          DistrictT: DistrictP,
          LocalBodyT: LocalBodyP,
          WardT: WardP,
          LocalityT: LocalityP,
        });
    }

    handleSave = async () => {
      let errors = await InputValidator(document, this.state);

      if (!isEmpty(errors)) {
          this.setState({ errors });
          return;
      }
      let {
        ProvinceP, DistrictP, LocalBodyP, WardP, LocalityP,
        ProvinceT, DistrictT, LocalBodyT, WardT, LocalityT,
        geoLocation
      } = this.state;

      let data = {
        "employeeId": this.props.EmployeeId,
        
        "peProvinceId": ProvinceP.value,
        "peDistrictId": DistrictP.value,
        "peLocalBodyId": LocalBodyP.value,
        "peLocality": LocalityP,
        "peWardNo": WardP,

        "psProvinceId": ProvinceT.value,
        "psDistrictId": DistrictT.value,
        "psLocalBodyId": LocalBodyT.value,
        "psLocality": LocalityT,
        "psWardNo": WardT,
        geoLocation
      }
      
      // const options = {
      //     indices: true,
      //     nullsAsUndefineds: false,
      //     booleansAsIntegers: false,
      // };
      // const formData = objectToFormData(data, options);
      // formData.append(`File`, Files);

      // if (this.props.editView)
      //     await this.props.updateEmployeeEducationDetails(formData);
      // else
          await this.props.saveEmployeeAddressDetails(data);
      if (this.props.employeeAddressSaved) {
        this.props.getEmployeeAddressDetails(this.props.EmployeeId);
        this.props.toggleEditMode(false);
        // this.props.getEmployeeAddressDetails
          // this.closeAddSection(false)
      }
  }


    render() {
      const { provinceList, districtList, localBodyList } = this.props;
      const {
        ProvinceP, DistrictP, LocalBodyP, WardP, LocalityP,
        ProvinceT, DistrictT, LocalBodyT, WardT, LocalityT,
        errors, provincePList, provinceTList, districtPList,
        districtTList, localBodyPList, localBodyTList, geoLocation
      } = this.state;
        return (
            <div className="address-form">
                <div className="employee-block">
                    {/* <div className="employee-block__heading">
                        Address
                    </div> */}
                    <div className="address-display__row address-display__row-form">
                        <div className="address-display__col">
                            <h4 className="address-form__heading">
                                <span>

                                </span>
                                <span className='address-form__heading-name'>
                                    <span>Permanent Address</span>
                                </span>
                            </h4>
                            <div className="address-display__form">
                                <FormGroup 
                                    formName="reactselect"
                                    label="Province"
                                    alignment="horizontal"
                                    value={ProvinceP}
                                    name="ProvinceP"
                                    options={provincePList}
                                    onChange={this.handleAutoSelect}
                                    validators={["required"]}
                                    error={errors && errors.ProvinceP}
                                />
                            </div>
                            <div className="address-display__form">
                                <FormGroup 
                                    formName="reactselect"
                                    label="District"
                                    alignment="horizontal"
                                    value={DistrictP}
                                    name="DistrictP"
                                    options={districtPList}
                                    validators={["required"]}
                                    error={errors && errors.DistrictP}
                                    onChange={this.handleAutoSelect}
                                />
                            </div>
                            <div className="address-display__form">
                                <FormGroup 
                                    formName="reactselect"
                                    label="Local Body"
                                    alignment="horizontal"
                                    value={LocalBodyP}
                                    name="LocalBodyP"
                                    validators={["required"]}
                                    error={errors && errors.LocalBodyP}
                                    options={localBodyPList}
                                    onChange={this.handleAutoSelect}
                                />
                            </div>
                            <div className="address-display__form">
                                <FormGroup 
                                    // formName="reactselect"
                                    label="Ward"
                                    alignment="horizontal"
                                    value={WardP}
                                    name="WardP"
                                    validators={["required"]}
                                    error={errors && errors.WardP}
                                    // options={[{label:'5',value:'5'}]}
                                    onChange={this.handleAutoSelect}
                                />
                            </div>
                            <div className="address-display__form">
                                <FormGroup 
                                    formName="textarea"
                                    label="Locality"
                                    alignment="horizontal"
                                    value={LocalityP}
                                    validators={["required"]}
                                    error={errors && errors.LocalityP}
                                    name="LocalityP"
                                    onChange={this.handleAutoSelect}
                                />
                            </div>
                        </div>
                        <div className="address-display__col">
                            <h4 className="address-form__heading">
                                <span>

                                </span>
                                <span className='address-form__heading-name'>
                                    <span>Temporary Address</span>
                                    <a onClick={this.setSameTemporaryAddress}>Same as permanent</a>
                                </span>
                            </h4>
                            <div className="address-display__form">
                                <FormGroup 
                                    formName="reactselect"
                                    label="Province"
                                    alignment="horizontal"
                                    value={ProvinceT}
                                    validators={["required"]}
                                    error={errors && errors.ProvinceT}
                                    name="ProvinceT"
                                    options={provinceTList}
                                    onChange={this.handleAutoSelect}
                                />
                            </div>
                            <div className="address-display__form">
                                <FormGroup 
                                    formName="reactselect"
                                    label="District"
                                    alignment="horizontal"
                                    value={DistrictT}
                                    validators={["required"]}
                                    error={errors && errors.DistrictT}
                                    name="DistrictT"
                                    options={districtTList}
                                    onChange={this.handleAutoSelect}
                                />
                            </div>
                            <div className="address-display__form">
                                <FormGroup 
                                    formName="reactselect"
                                    label="Local Body"
                                    alignment="horizontal"
                                    value={LocalBodyT}
                                    name="LocalBodyT"
                                    validators={["required"]}
                                    error={errors && errors.LocalBodyT}
                                    options={localBodyTList}
                                    onChange={this.handleAutoSelect}
                                />
                            </div>
                            <div className="address-display__form">
                                <FormGroup 
                                    // formName="reactselect"
                                    label="Ward"
                                    alignment="horizontal"
                                    value={WardT}
                                    validators={["required"]}
                                    error={errors && errors.WardT}
                                    name="WardT"
                                    // options={[{label:'5',value:'5'}]}
                                    onChange={this.handleAutoSelect}
                                />
                            </div>
                            <div className="address-display__form">
                                <FormGroup 
                                    formName="textarea"
                                    label="Locality"
                                    alignment="horizontal"
                                    value={LocalityT}
                                    validators={["required"]}
                                    error={errors && errors.LocalityT}
                                    name="LocalityT"
                                    onChange={this.handleAutoSelect}
                                />
                            </div>
                            <div className="address-display__footer">
                                <Button
                                    title={"Save"}
                                    bg="primary"
                                    onClick={()=> this.handleSave()}
                                />
                                <Button
                                    title="Cancel"
                                    bg="secondary-dark"
                                    onClick={() => this.props.toggleEditMode(false)}
                                />
                            </div>
                        </div>
                        <div className="address-display__col">
                            <div className="address-display__form">
                                <FormGroup 
                                    formName="textarea"
                                    label="Geo Location"
                                    alignment="horizontal"
                                    value={geoLocation}
                                    name="geoLocation"
                                    onChange={this.handleAutoSelect}
                                /> 
                            </div>
                            <div style={{marginLeft:120}} className="">
                                <Button bg="white" title="View Map" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
  provinceList: state.commonReducer.provinceList,
  districtList: state.commonReducer.districtByProvienceList,
  localBodyList: state.commonReducer.localBodyList,
  employeeAddressSaved: state.employeeProfile.employeeAddressSaved,
});

const mapDispatchToProps = {
  GetProvienceList,
  GetDistrictByProvince,
  GetLocalBodyByDistrict,
  saveEmployeeAddressDetails,
  getEmployeeAddressDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressForm);
