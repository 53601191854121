import React, { useState } from 'react'
import Users from './users';
import Branches from './branches';
import Departments from './departments';
import classnames from 'classnames'
import './styles.scss';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getAssignedCourses } from '../helpers/action';


function AssignCourse(props) {
    const [active,setActive] = useState(0);
    const {courseId} = props.match.params;
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(getAssignedCourses(courseId))
    },[])
    return (
        <div className="course-assign">
            <div className="course-assign__header">
                <div className="course-assign__title">Assign Course</div>
            </div>
            <div className="course-assign__body">
                <div className="common">
                    <div className="common-tabs">
                        <div onClick={()=>setActive(0)} className={classnames("common-tab",{"active":active===0})}>
                            <span>Users</span>
                        </div>
                        <div onClick={()=>setActive(1)} className={classnames("common-tab",{"active":active===1})}>
                            <span>Branches</span>
                        </div>
                        <div onClick={()=>setActive(2)} className={classnames("common-tab",{"active":active===2})}>
                            <span>Departments</span>
                        </div>
                    </div>
                    <div className="common-tabs__body">
                        {active === 0 && <Users {...props} />}
                        {active === 1 && <Branches {...props} />}
                        {active === 2 && <Departments {...props} />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssignCourse
