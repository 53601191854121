import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import Form from "components/form";
import SvgIcons from "components/icons/svgIcons";
import { JobStages } from "../enums";
import classNames from "classnames";
import JobStageIcon from "./jobStageIcon";
import isEmpty from "isEmpty";

const JobStageSelect = (props) => {
  const {
    index,
    selectedCandidate,
    StageId,
    hiringStageAndSubStagesJobId,
    onRejectClick
  } = props;
  const [stages, setStages] = useState([]);
  const [selectedStage, setSelectedStage] = useState(null);

  useEffect(() => {
    if (hiringStageAndSubStagesJobId && hiringStageAndSubStagesJobId.value) {
      let ds = ConvertToDS(hiringStageAndSubStagesJobId.value)
      setStages(ds);

      let selectedStage = ds.find(x => x.StageId === StageId)
      if (selectedStage) {
        setSelectedStage(selectedStage)
      }
    }
  }, [hiringStageAndSubStagesJobId, selectedCandidate]);

  const ConvertToDS = (data) => {
    let arrayItem: any = [];
    if (data && Array.isArray(data)) {
      data.map((item, key) => {
        let opt = {
          ...item,
          label: item["Name"],
          value: item["Id"],
        };
        if (item.SubStages && item.SubStages.length > 0)
          opt.options =
            item.SubStages &&
            item.SubStages.map((subitem, skey) => ({
              ...subitem,
              label: subitem["Name"],
              value: item["Id"],
            }));
        arrayItem.push(opt);
      });

    }

    return arrayItem;
  };

  const renderIcon = (stageId) => {
    let name: any = null;
    switch (stageId) {
      case JobStages.Applied:
        name = "applied";
        break;
      case JobStages.Shortlisted:
        name = "shortlisted";
        break;
      case JobStages.Interview:
        name = "interview";
        break;
      case JobStages.Written_Exam:
        name = "written";
        break;
      case JobStages.Job_Offer:
        name = "jobOffer";
        break;
      case JobStages.Hired:
        name = "hired";
        break;
      default:
        return null;
    }
    return name ? <SvgIcons name={name} color="#3a3d43" size="17px" /> : null;
  };
  const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;
    let stageId =
      selectedCandidate &&
      (selectedCandidate.StageId || selectedCandidate.CandidateStatusId);

    return (
      <div
        className={classNames({
          "select-option": true,
          "select-option-sub-group": !data.SubStages,
        })}
        ref={innerRef}
        {...innerProps}
      >
        <JobStageIcon stageId={data.StageId} />

        <div>
          {/* <IconByCurrentStage candidateStatus={stageId} stage={data.value} />{" "} */}
          <span>{data.label}</span>
        </div>
      </div>
    );
    //<components.Option {...props} />
  };

  const Group = (props) => (
    <div className="select-option-group">
      <components.Group {...props} />
    </div>
  );

  const GroupHeading = (props) => {
    const { data } = props;
    return (
      <div className="select-option-group-heading">
        <JobStageIcon stageId={data.StageId} /> <span>{data.label}</span>
      </div>
    );
  };
  // const handleChange = (name, val) => {
  //   // props.onChange
  // }
  const onMoveToStageClicked = (selectedStage, substage = null) => {

    props.onChange && props.onChange("selectedJobStage", substage || selectedStage)
  }
  const onSendBackClicked = (e) => {

    if (index > stages.length)
      return
    let newIndex = index - 1;
    if (newIndex < 0)
      return
    let prevStage = stages[newIndex]
    if (prevStage) {
      let subStageCount = prevStage.options && prevStage.options.length || -1
      let substage = subStageCount > -1 ? prevStage.options[subStageCount - 1] : null
      props.onChange && props.onChange("selectedJobStage", substage || prevStage)
    }
  }

  const renerNextStageEl = () => {
    if (isEmpty(selectedStage))
      return null

    let options = selectedStage.options || []

    if (selectedCandidate.CandidateStatusId >= StageId)
      return null

    if (options.length > 0)
      return options.map(x =>
        <button className="button button-white" onClick={(e) => onMoveToStageClicked(selectedStage, x)}>Move to {x.Name}
        </button>)
    else {
      return <button className="button button-white" onClick={(e) => onMoveToStageClicked(selectedStage)}>Move to this stage</button>
    }
  }
  const renderActionEl = () => {

    const el = selectedCandidate.CandidateStatusId == StageId ?
      <div className="flex">
        {JobStages.Shortlisted !== StageId &&
          <button className="button button-white" onClick={onSendBackClicked}>Send Back</button>
        }
        <button className="button button-white" onClick={onRejectClick}>Reject Candidate</button>
      </div> : null
    return el

  }
  const nextStageEl = renerNextStageEl();
  const actionEl = renderActionEl();
  if (isEmpty(actionEl) && isEmpty(nextStageEl)) {
    return null
  }
  return (
    <div className="information-wholeInfo-milestone-common">
      <div className="information-wholeInfo-milestone-common-move">

        {nextStageEl}
        {actionEl}

        {/* <Form
        formName="reactselect"

        onChange={handleChange}
        options={stages}
        //components={{ Option: CustomOption, GroupHeading: GroupHeading }}
        components={{ Option: CustomOption, Group, GroupHeading }}
        name="selectedJobStage"
        placeholder="Move  To"
        {...props}
      /> */}
      </div>
    </div>
  );
};
export default JobStageSelect;
