import {
  ALLOWANCE_EXPENSE_LOADING,
  GET_ALLOWANCE_EXPENSE,
  ADD_ALLOWANCE_EXPENSE,
  LOAD_ALLOWANCE_EXPENSE,
  SET_EDIT_MODE,
  EDIT_ALLOWANCE_EXPENSE,
  DELETE_ALLOWANCE_EXPENSE,
} from "actions/types";

const initialState = {
  allowanceExpenseLoading: false,
  allowanceExpense: [],
  allowanceExpenseFormData: {
    AllowanceId: 0,
    IsAllowance: "true",
    Name: "",
    CalcMethod: null,
    IsBillRequired: false,
  },
  //searchedPage:{index:0,total:0},
  loading: true,
  isEdit: false,
  spinnerLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ALLOWANCE_EXPENSE_LOADING:
      return {
        ...state,
        spinnerLoading: action.payload,
      };
    case GET_ALLOWANCE_EXPENSE:
      return {
        ...state,
        allowanceExpense: action.payload,
        //searchedPage: { index: action.page.index, total: action.page.total },
        loading: false,
        isEdit: false,
        spinnerLoading: false,
      };
    case ADD_ALLOWANCE_EXPENSE:
      return {
        ...state,
        allowanceExpense: [action.payload, ...state.allowanceExpense],
        loading: false,
        isEdit: false,
        spinnerLoading: false,
      };
    case EDIT_ALLOWANCE_EXPENSE:
      return {
        ...state,

        allowanceExpense: state.allowanceExpense.map((item, i) =>
          item.AllowanceId === action.id ? action.payload : item
        ),
        loading: false,
        isEdit: false,
        spinnerLoading: false,
      };
    case DELETE_ALLOWANCE_EXPENSE:
      return {
        ...state,

        allowanceExpense: state.allowanceExpense.filter(
          (item) => item.AllowanceId !== action.id
        ),
        loading: false,
        spinnerLoading: false,
      };
    case LOAD_ALLOWANCE_EXPENSE:
      return {
        ...state,
        allowanceExpenseFormData: action.payload,
        loading: false,
        isEdit: false,
        spinnerLoading: false,
      };
    case SET_EDIT_MODE:
      return {
        ...state,
        isEdit: true,
      };
    default:
      return state;
  }
}
