import React, { useState } from "react";
import Modal from "components/modal";
import FormGroup from "components/form";
import MomentUtils from "@date-io/moment";
import { TextareaAutosize } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { ValidationComponent } from "helpers/getComponents";
import userFormValidation from "validations/useFormValidation";
import validateDisciplinaryActionForm from "validations/validateDisciplinaryActionForm";
import isEmpty from "helpers/isEmpty";
import { Radio, RadioGroup, FormControlLabel } from "@material-ui/core";
import Upload from "components/common/upload";
import {
  closeDisciplinaryCase,
  addCounsellingNote,
  addVerbalWarningNote,
  addWrittenWarningNote,
  addActionTakeNote,
} from "../action";
import { useDispatch, useSelector } from "react-redux";
import { Date_Format } from "constants/dateFormat";
import Button from "components/button";

function ActionForm({
  formData,
  isModalShow,
  closeModal,
  isEditPage,
  employeeList,
  disciplinaryCaseDetails,
  actionsStages,
}) {
  const {
    ActionId,
    SubStageId,
    ActionTitle,
    ActionDateTitle,
    ActionByTitle,
    ActionNoteTitle,
    SubmitButtonText,
  } = formData;

  const { caseTitle, caseStatement } = disciplinaryCaseDetails;
  let INITIAL_STATE;
  if (isEditPage) {
    // INITIAL_STATE = getEditObject(props);
  } else {
    INITIAL_STATE = {
      DisciplinaryCaseId: disciplinaryCaseDetails.DisciplinaryCaseId,
      ActionId,
      SubStageId,
      ActionTaken: "",
      ActionDate: null,
      ActionBy: "",
      ActionNote: "",
      ActionType: "1",
      SuspensionFrom: null,
      SuspensionTo: null,
      FileList: [],
    };
  }
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    clearValues,
    isSubmitting,
  } = userFormValidation(INITIAL_STATE, validateDisciplinaryActionForm);
  let [isActionResponseShow, setActionResponseShow] = useState(false);
  const dispatch = useDispatch();
  console.log(errors);
  if (isSubmitting && isEmpty(errors)) {
    console.log(values);
    SaveActionForm(dispatch, values, closeModal);
  }
  const onSelectChange = (name, value) => {
    handleChange(name, value);
    if (name === "ActionTaken") {
      setActionResponseShow(isEmpty(value) ? false : true);
    }
  };
  const onChange = (name, value) => {
    //let { name, value } = e.target;
    handleChange(name, value);
  };
  const onDateChange = (date) => {
    handleChange("ActionDate", date);
  };

  const isViewMode = false;
  const setSelectedFiles = (fileList) => {
    handleChange("FileList", fileList);
  };
  const ActionTaken =
    ActionId === 4
      ? actionsStages
          .find((x) => x.ActionStageId === 4)
          .SubStages.map((x) => {
            return { value: x.ActionSubStageId, label: x.SubStageName };
          })
      : [];
  return (
    <Modal
      open={isModalShow}
      onModalClose={closeModal}
      title={ActionTitle}
      className="dsp-action-modal"
    >
      <form>
        <div className="action-body">
          <div className="form-row">
            <span className="case-title">{caseTitle}</span>
            <span className="case-statement">{caseStatement}</span>
          </div>
          <div className="form-row">
            <div className="flex">
              {ActionId === 4 ? (
                <ValidationComponent
                  rootClassName="form-group select"
                  error={errors.ActionTaken}
                  errorClassName="error__message"
                >
                  <label htmlFor="ActionTaken" className="form-group__label">
                    Action Taken
                  </label>
                  <FormGroup
                    formName="reactselect"
                    onChange={onSelectChange}
                    name="ActionTaken"
                    value={values.ActionTaken}
                    options={ActionTaken}
                    width="300px"
                    placeholder="select an action"
                    disabled={isViewMode}
                  />
                </ValidationComponent>
              ) : null}
              <ValidationComponent
                rootClassName="form-group select"
                error={errors.ActionDate}
                errorClassName="error__message"
              >
                <label htmlFor="ActionDate" className="form-group__label">
                  {ActionDateTitle}
                </label>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <div className="pickers">
                    <KeyboardDatePicker
                      name="ActionDate"
                      variant="inline"
                      value={values.ActionDate}
                      width="300px"
                      onChange={onDateChange}
                      disabled={isViewMode}
                    />
                    {/* onDateChange */}
                  </div>
                </MuiPickersUtilsProvider>
              </ValidationComponent>
            </div>
          </div>
          {isActionResponseShow ? (
            <ActionResponse
              errors={errors}
              values={values}
              onDateChange={onDateChange}
              isViewMode={isViewMode}
            />
          ) : null}
          <div className="form-row">
            <ValidationComponent
              rootClassName="form-group select autoselect"
              error={errors.ActionBy}
              errorClassName="error__message"
            >
              <label htmlFor="ActionBy" className="form-group__label">
                {ActionByTitle}
              </label>
              <FormGroup
                formName="reactselect"
                onChange={onSelectChange}
                name="ActionBy"
                value={values.ActionBy}
                options={employeeList}
                width="300px"
                placeholder="select an employee"
                disabled={isViewMode}
                multiple
              />
            </ValidationComponent>
          </div>
          {ActionId === 3 ? (
            <div className="form-row">
              <div className="actiontype-row">
                <RadioGroup
                  name="ActionType"
                  onChange={(event, value) =>
                    onChange(event.target.name, value)
                  }
                  value={values.ActionType}
                  className="action-radio"
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Email"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Physical Document"
                  />
                </RadioGroup>
                {/* {values.ActionType === "2" ? ( */}
                <ValidationComponent
                  rootClassName="form-group select"
                  error={errors.FileList}
                  errorClassName="error__message"
                >
                  <Upload
                    fileList={values.FileList}
                    setSelectedFiles={setSelectedFiles}
                    uploadBtnText="Attach document"
                    noSelectedText="No document selected"
                    isViewMode={isViewMode}
                  />
                </ValidationComponent>
                {/* ) : null} */}
              </div>
            </div>
          ) : null}
          {ActionId === 4 ? (
            <div className="form-row">
              <ValidationComponent
                rootClassName="form-group select"
                error={errors.FileList}
                errorClassName="error__message"
              >
                <Upload
                  fileList={values.FileList}
                  setSelectedFiles={setSelectedFiles}
                  uploadBtnText="Attach document"
                  noSelectedText="No document selected"
                  isViewMode={isViewMode}
                />
              </ValidationComponent>
            </div>
          ) : null}
          <div className="form-row">
            <ValidationComponent
              rootClassName="form-group"
              error={errors.ActionNote}
              errorClassName="error__message"
            >
              <label htmlFor="ActionNote" className="form-group__label">
                {ActionNoteTitle}
              </label>
              <FormGroup
                formName="textarea"
                name="ActionNote"
                cols={50}
                rows={6}
                value={values.ActionNote}
                onChange={onChange}
                disabled={isViewMode}
              />
            </ValidationComponent>
          </div>
        </div>
        <div className="action-footer">
          <Button
            title="Cancel"
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
            isDisabled={isSubmitting}
            bg="secondary-dark"
          />
          <Button
            title={SubmitButtonText}
            onClick={handleSubmit}
            isDisabled={isSubmitting}
            bg="primary"
          />
        </div>
      </form>
    </Modal>
  );
}

const ActionResponse = ({ errors, values, isViewMode, onDateChange }) => {
  let ActionTaken = null;
  switch (values.ActionTaken.value) {
    case 1: {
      ActionTaken = (
        <div className="form-row">
          <div className="flex">
            <ValidationComponent
              rootClassName="form-group select"
              error={errors.SuspensionFrom}
              errorClassName="error__message"
            >
              <label htmlFor="SuspensionFrom" className="form-group__label">
                Suspension from
              </label>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <div className="pickers">
                  <KeyboardDatePicker
                    name="SuspensionFrom"
                    value={values.SuspensionFrom}
                    width="220px"
                    onChange={onDateChange}
                    disabled={isViewMode}
                  />
                </div>
              </MuiPickersUtilsProvider>
            </ValidationComponent>
            <ValidationComponent
              rootClassName="form-group select"
              error={errors.SuspensionTo}
              errorClassName="error__message"
            >
              <label htmlFor="SuspensionTo" className="form-group__label">
                Suspension to
              </label>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <div className="pickers">
                  <KeyboardDatePicker
                    name="SuspensionTo"
                    value={values.SuspensionTo}
                    width="220px"
                    onChange={onDateChange}
                    disabled={isViewMode}
                  />
                </div>
              </MuiPickersUtilsProvider>
            </ValidationComponent>
          </div>
        </div>
      );
    }
  }
  return ActionTaken;
};

const SaveActionForm = async (dispatch, data, closeModal) => {
  const {
    DisciplinaryCaseId,
    ActionId,
    SubStageId,
    ActionTaken,
    ActionDate,
    ActionBy,
    ActionNote,
    ActionType,
    SuspensionFrom,
    SuspensionTo,
    FileList,
  } = data;

  switch (ActionId) {
    case 0:
      {
        let saveData = {
          CaseRefId: DisciplinaryCaseId,
          ClosureDate: ActionDate,
          ClosureDateEng: ActionDate,
          AuthorizedBy: ActionBy.map((x) => x.value).join(","),
          ClosureNote: ActionNote,
        };
        await dispatch(closeDisciplinaryCase(saveData, closeModal));
      }
      break;
    case 1:
      {
        let saveData = {
          CaseRefId: DisciplinaryCaseId,
          CounselledDate: ActionDate,
          CounselledDateEng: ActionDate,
          CounselledBy: ActionBy.map((x) => x.value).join(","),
          CounsellingNote: ActionNote,
        };
        await dispatch(addCounsellingNote(saveData, closeModal));
      }
      break;
    case 2:
      let saveData = {
        CaseRefId: DisciplinaryCaseId,
        WarningDate: ActionDate,
        WarningDateEng: ActionDate,
        InPresenceOf: ActionBy.map((x) => x.value).join(","),
        WarningNote: ActionNote,
      };
      await dispatch(addVerbalWarningNote(saveData, closeModal));
      break;
    case 3:
      {
        // let saveData = {
        //   caseRefId: disciplinaryCaseId,
        //   subStageRef_Id: SubStageId,
        //   warningDate: ActionDate,
        //   warningDateEng: ActionDate,
        //   authorizedBy: ActionBy.map((x) => x.value).join(","),
        //   responseMedium: actionType,
        //   warningNote: ActionNote,
        //   fileList,
        // };
        const fd = new FormData();
        fd.append("CaseRefId", DisciplinaryCaseId);
        fd.append("SubStageRefId", SubStageId ? SubStageId : 0);
        fd.append("WarningDate", Date_Format(ActionDate));
        fd.append("WarningDateEng", Date_Format(ActionDate));
        fd.append("AuthorizedBy", ActionBy.map((x) => x.value).join(","));
        fd.append("ResponseMedium", ActionType);
        fd.append("WarningNote", ActionNote);
        FileList.map((x, i) => {
          fd.append(`Files`, x);
        });

        //fd.append("Files", FileList);
        await dispatch(
          addWrittenWarningNote(fd, DisciplinaryCaseId, closeModal)
        );
      }
      break;
    case 4:
      {
        // let saveData = {
        //   caseRefId: disciplinaryCaseId,
        //   actionTakenId: ActionTaken.value,
        //   actionDate,
        //   actionDateEng: actionDate,
        //   authorizedBy: ActionBy.map((x) => x.value).join(","),
        //   ActionNote,
        //   fileList,
        // };
        const fd = new FormData();
        fd.append("CaseRefId", DisciplinaryCaseId);
        fd.append("ActionTakenId", ActionTaken.value);
        fd.append("ActionDate", Date_Format(ActionDate));
        fd.append("ActionDateEng", Date_Format(ActionDate));
        fd.append("AuthorizedBy", ActionBy.map((x) => x.value).join(","));
        fd.append("ActionNote", ActionNote);
        FileList.map((x, i) => {
          fd.append(`Files`, x);
        });
        //fd.append("Files", FileList);
        await dispatch(addActionTakeNote(fd, DisciplinaryCaseId, closeModal));
      }
      break;
    default:
      break;
  }
};

export default ActionForm;
