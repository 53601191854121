import React, { useState, useEffect } from "react";
import isEmpty from "isEmpty";
import TitleCell from "../../customCell/titleCell";
import { Draggable } from "react-beautiful-dnd";

const RowItem = (props) => {
  const {
    task,
    onTaskClicked,
    onTaskSelect,
    rowIndex,
    onTaskRemove,
    selectedTask,
    clear,
    setClear,
    provided,
    snapshot,
    columns,
  } = props;
  const [selectTask, setSelectTask] = React.useState(false);
  useEffect(() => {
    if (selectTask) {
      onTaskSelect(task);
    } else {
      if (!isEmpty(selectedTask)) {
        onTaskRemove(task);
      }
    }
  }, [selectTask]);

  useEffect(() => {
    if (clear === true) {
      setSelectTask(false);
      setClear(false);
    }
  }, [clear]);

  return (
    <tr
      {...provided.draggableProps}
      // {...provided.dragHandleProps}
      ref={provided.innerRef}
    >
      <td className="td">
        <div
          // {...provided.dragHandleProps}
          className="taskBlock-item">
          <TitleCell
            {...props}
            task={task}
            provided={provided}
            onTaskClicked={onTaskClicked}
            selectTask={selectTask}
            setSelectTask={setSelectTask}
          />
        </div>
      </td>

      {columns.map((column, index) =>
        column.hide === true ? null : (
          <td className={column.className} key={index}>
            <div className="taskBlock-item">
              {column.cellRenderer ? (
                column.cellRenderer({ column, index, task, ...props })
              ) : (
                <div
                  className="taskBlock-section taskBlock-section-cell"
                  title={task[column.field]}
                >
                  {task[column.field]}
                </div>
              )}
            </div>
          </td>
        )
      )}

      <td>
        <span></span>
      </td>
    </tr>
  );
};

export default RowItem;
