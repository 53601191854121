import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "components/button";
import DetailCard from './detailCard';
import TrainingForm from './traningForm';
import { InputValidator } from "helpers/inputValidator";
import isEmpty from "isEmpty";
import { MdAdd } from "react-icons/md";
import  {
    currentlySelectedSection,
    deleteEmployeeTrainingDetails,
    getEmployeeTrainingList
} from '../action';

const CountryData = [
    {
        label: 'Nepal',
        value: 'Nepal'
    },
    {
        label: 'India',
        value: 'India'
    }
]
class Trainings extends Component{
    constructor(props) {
        super(props);
        this.state = {
            showAddSection: false,
            editView: false,
            errors: {},
            Course: '',
            Qualification: '',
            College: '',
            Country: '',
            University: '',
            PassedYear: null,
            GradeValue: null,
            MajorSubjects: null,
            IsGPA: true,
        }
    }

    handleInput = (name, value) => {
        console.log()
        this.setState(
            { [name]: value, },
            () => {
                this.updateErrors();
            }
        );
    }

    updateErrors = async () => {
        const { errors } = this.state;
        if (!isEmpty(errors)) {
        let errors = await InputValidator(document, this.state);
        this.setState({ errors });
        }
    };

    deleteEducation = async (trainingId) => {
        const {
            deleteEmployeeTrainingDetails,
            getEmployeeTrainingList,
            employeeId
        } = this.props;
        this.setState({ showAddSection: false });
        await deleteEmployeeTrainingDetails(trainingId);
        if (this.props.employeeTrainingDeleted)
            getEmployeeTrainingList(employeeId);
    }

    toggleAddSecton = (showAddSection, editView, title) => {
        this.setState({ showAddSection, editView, editViewData: null });
        this.props.currentlySelectedSection(title)
    }

    toggleEditView = (flag, editViewData, title) => {
        this.setState({ showAddSection: false, editView: editViewData && editViewData.TrainingId, editViewData });
        this.props.currentlySelectedSection(title);
    }

    render () {

        const { employeeTrainingList, currentlySelectedEduSection } = this.props;
        return (
            <div className="education__education">
                <div className="education-heading">
                    <h4>Training</h4>
                    <Button 
                        onClick={() => this.toggleAddSecton(true, false, 'training')}
                        bg="white" 
                        buttonType="icon-button"
                        leftIcon={<MdAdd />} 
                        title="Add" /> 
                </div>
                {this.state.showAddSection && currentlySelectedEduSection == 'training' &&
                <TrainingForm 
                    closeAddSection={() => this.toggleAddSecton(false, false, 'training')}
                    employeeId={this.props.employeeId}
                />
                }
                <div className="education__education__details">
                        {employeeTrainingList.length > 0 ? employeeTrainingList.map(edu => 
                        <>
                            <DetailCard
                                key={edu.TrainingId}
                                detail={edu}
                                toggleEditView={(data, title) => this.toggleEditView(true, data, title)}
                                deleteEmployeeItem={(educationId) => this.deleteEducation(educationId)}
                                editView={true}
                                title="training"
                                editViewId={this.state.editView}
                                currentlySelectedEduSection={currentlySelectedEduSection}
                            />
                            {this.state.editView == edu.TrainingId && currentlySelectedEduSection == 'training' &&
                            <TrainingForm
                                editView={true}
                                editViewData={edu}
                                toggleEditView={() => this.toggleEditView(false, null, 'training')}
                                employeeId={this.props.employeeId}
                            />}
                        </>
                        ) :  <div className="education__detail__details">
                        <div className="education-detail">
                                <div className="education-detail__primary">
                                    <p className="noData">No Data Found</p>
                                </div>
                        </div>
                    </div>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    employeeTrainingList: state.employeeProfile.employeeTrainingList || [],
    employeeTrainingDeleted: state.employeeProfile.employeeTrainingDeleted,
    currentlySelectedEduSection: state.employeeProfile.currentlySelectedEduSection,
});

const mapDispatchToProps = {
    currentlySelectedSection,
    deleteEmployeeTrainingDetails,
    getEmployeeTrainingList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Trainings);