import React from "react";
import classnames from "classnames";
import { FaRegFilePdf, FaGraduationCap, FaEdit } from "react-icons/fa";
import {AiOutlineFilePdf} from 'react-icons/ai'
import { connect } from "react-redux";
import Button from "components/button";
import grad from 'assets/images/icons/user-graduate.svg'
export class EducationDetail extends React.Component {
  render() {
    const travelClasses = () => {
      return classnames({
        badges: true,
        "badges-rounded": true,
        "badges-secondary": true,
      });
    };
    const { educationInfo } = this.props;
    console.log(this.props);
    return (
      <div className="personal-row">
        <div className="personal-column">
          <div className="personal-block">
            <div className="personal-block__title">
              <h2>Education, Training and Skills</h2>
              <Button
                title="Change"
                bg="subtle"
                buttonClass="personal-block__btn"
              />
            </div>
            <div className="personal-block__body">
              {/* <div className="personal-block__icon">
                                <FaEdit />
                        </div> */}
              {educationInfo.length > 0 ? (
                educationInfo.map((edu, i) => (
                  <div key={i + Math.random()} className="personal-block__div">
                    <span className="graduation-icon">
                      {/* <FaGraduationCap /> */}
                      <img src={grad} alt=""/>
                    </span>
                    <div style={{width:200}} className="personal-label bold-500">
                      {edu.Course}
                      {edu.PassedYear ? ", " + edu.PassedYear : ""}
                    </div>
                    <div className="value">
                      {edu.College ? edu.College + ", " : ""} {edu.University}{" "}
                      {edu.Percentage}
                    </div>
                    <span className="document-icon">
                      <AiOutlineFilePdf />
                    </span>
                  </div>
                ))
              ) : (
                <div className="profile-block__title nodata">
                  <h2>No Education Detail Available</h2>
                </div>
              )}
            </div>
            {educationInfo && educationInfo.length > 0 && (
              <div className="personal-block__div" style={{margin:'5px 0'}} >
                <div className="badges-group">
                  {educationInfo.map((edu, i) => (
                      !edu.Course ? null :
                    <div className={travelClasses()}>{edu.Course}</div>
                  ))}
                  {/* <div className={travelClasses()}>
                                        Financial Management
                                    </div>
                                    <div className={travelClasses()}>
                                        communication
                                    </div> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  educationInfo:
    (state.employeeDashboard.employeeDetail &&
      state.employeeDashboard.employeeDetail.EducationAndTraining) ||
    [],
});

export default connect(mapStateToProps, null)(EducationDetail);
