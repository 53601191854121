import React, { useState, useEffect } from "react";
import Modal from "components/modal";
import Icon from "components/icons";
import FormGroup from "components/form";
import Button from "components/button";
import isEmpty from "isEmpty";
import { addBoard } from "../helpers/action";
import { useDispatch } from "react-redux";
import { getDateISO } from "components/calendar/helpers/engCalendar";
import moment from "moment";

interface ErrorsInterface {
  BoardName?: string;
  BoardDescription?: string;
  DueDate?: string;
}
const boardInitialState = {
  BoardId: 0,
  BoardName: "",
  BoardDescription: "",
  BoardType: 1,
  StartDate: null,
  DueDate: null,
};
const BoardAdd = (props) => {
  const { open, onModalClose } = props;
  const [data, setData] = useState(boardInitialState);
  const [days, setDays] = useState("");
  useEffect(() => {
    if (props.data) {
      setData(props.data);
      setDays(calculateDaysRemaining(props.data.DueDate))
    }
  }, [props.data]);

  const [errors, setErrors] = useState<any>({});
  const dispatch = useDispatch();
  const getTitle = () => {
    if (data.BoardId === 0) return "Add Board";
    return "Edit Board";
  };
  const onSave = async () => {
    const { customErrors, isValid } = onValidate(data);
    setErrors(customErrors);
    if (!isValid) {
      return false;
    }
    if (data.BoardId === 0) data.BoardType = props.boardType;
    let isSuccess = await dispatch(addBoard(data));
    if (isSuccess) {
      setData(boardInitialState);
      onModalClose(false);
    }
  };
  const onValidate = (data) => {
    let customErrors: ErrorsInterface = {};
    if (isEmpty(data.BoardName)) {
      customErrors.BoardName = "Board Name field is required";
    }
    if (isEmpty(data.BoardDescription)) {
      customErrors.BoardDescription = "Board Description field is required";
    }
    if (!isEmpty(data.StartDate) && !isEmpty(data.DueDate)) {
      if (new Date(data.StartDate) > new Date(data.DueDate)) {
        customErrors.DueDate = "DueDate must be greater than Start Date";
      }
    }
    return { isValid: isEmpty(customErrors), customErrors };
  };

  const onChange = (name, value) => {
    let newData = { ...data };
    newData[name] = value;
    if (name === "DueDate") setDays(calculateDaysRemaining(value));
    setData(newData);
  };
  const convertDateISO = (date) => {
    return date ? getDateISO(new Date(date)) : "";
  };
  const calculateDaysRemaining = (date) => {
    let res: any;
    let day = "days";
    let dayDiff = 0;
    let type = "overdue";
    if (date) {
      var dueDate = moment(date);
      var start = moment(Date()).format("MM/DD/YYYY");
      dayDiff = dueDate.diff(start, "days");

      if (dayDiff >= -1 && dayDiff <= 1) day = "day";
      if (dayDiff >= 0) type = "";
      res = `${Math.abs(dayDiff)} ${day} ${type}`;
    }
    return res;
  };
  const maxLengthValidation = (e: any) => {

    const { value, name } = e.target
    let len = value && value.trim().length || 0
    let maxlength = 254
    if (name === "BoardName") {
      maxlength = 99
    }

    if (len > maxlength) {
      e.preventDefault();
    }
  }

  return (
    <Modal
      onModalClose={onModalClose}
      open={open}
      className="board-add-modal"
      width="600px"
      title={getTitle()}
    >
      <div className="board-add">
        <div className="board-add__form">
          <div className="board-add__row">

            <FormGroup
              value={data.BoardName}
              onChange={onChange}
              label="Board Title"
              name="BoardName"
              error={errors.BoardName}
              onKeyPress={maxLengthValidation}
            />
          </div>
          <div className="board-add__row">
            <FormGroup
              formName="textarea"
              value={data.BoardDescription}
              onChange={onChange}
              label="Board Description"
              name="BoardDescription"
              error={errors.BoardDescription}
              onKeyPress={maxLengthValidation}
            />
          </div>
          <div className="board-add__row board-add__row-flex">
            <div className="board-add__row-flex-date">
              <FormGroup
                formName="customdateinput"
                name="StartDate"
                onChange={onChange}
                label="Start date"
                value={convertDateISO(data.StartDate)}
                closeOnClick
                hideAction
              />
            </div>
            <div className="board-add__row-flex-date">
              <FormGroup
                formName="customdateinput"
                name="DueDate"
                onChange={onChange}
                label="Due date"
                value={convertDateISO(data.DueDate)}
                closeOnClick
                hideAction
                error={errors.DueDate}
              />
            </div>
            <span className="board-add__row-flex-day">{days}</span>
          </div>
        </div>
        <div className="board-add__footer">
          <Button title="Save" bg="primary" onClick={() => onSave()} />
          <Button
            title="Cancel"
            bg="secondary-dark"
            onClick={(e) => onModalClose(false)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default BoardAdd;
