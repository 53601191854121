import React, { Component } from "react";
import Avatar from "react-avatar";
import { MdAdd, MdMoreHoriz, MdKeyboardArrowDown } from "react-icons/md";
import { formatedMonthDayYear } from "constants/dateFormat";
import isEmpty from "isEmpty";
import RouteEnum from 'constants/routeEnum'
import Button from 'components/button'
import {getUploadPath} from 'constants/userDetails'

export class DisciplinaryTableRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drop: false,
      dropMoreOption: false,
    };
    this.dropdown = "";
    this.dropdownMoreOption = "";
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.dropdown && !this.dropdown.contains(event.target)) {
      this.setState({ drop: false });
    }
    if (
      this.dropdownMoreOption &&
      !this.dropdownMoreOption.contains(event.target)
    ) {
      this.setState({ dropMoreOption: false });
    }
  }

  onDrop() {
    this.setState({ drop: !this.state.drop });
  }

  onDropMoreOption() {
    this.setState({
      dropMoreOption: !this.state.dropMoreOption,
    });
  }

  handleMTNS = (disciplinaryCaseId, selected) => {
    let stageId = !isEmpty(selected.stageId) ? selected.stageId : null;
    let subStageId = !isEmpty(selected.subStageId) ? selected.subStageId : null; 
    this.props.handleMoveToNextStage({
      disciplinaryCaseId,
      stageId,
      subStageId,
    });
  };

  handleMoveToNextData = (props) => {
    //convert string format data to array
    let ddlData = [];
    if (!isEmpty(props.NextStageText)) {
      if (!isEmpty(props.NextSubStages)) {
        const nextSubStageList = props.NextSubStages.split(",");
        nextSubStageList &&
          nextSubStageList.map((ns, i) => {
            let subStageIdValue = ns.split(":");
            const subStageId = parseInt(subStageIdValue[0]);
            const subStageName = subStageIdValue[1];
            if (!isEmpty(subStageId) && !isEmpty(subStageName)) {
              ddlData.push({
                subStageId: subStageId,
                subStageName: subStageName,
              });
            }
          });
      } else if (!isEmpty(props.NextStages)) {
        const nextStageList = props.NextStages.split(",");
        nextStageList &&
          nextStageList.map((ns, i) => {
            let stageIdValue = ns.split(":");
            const stageId = parseInt(stageIdValue[0]);
            const stageName = stageIdValue[1];
            if (!isEmpty(stageId) && !isEmpty(stageName)) {
              ddlData.push({ stageId: stageId, stageName: stageName });
            }
          });
      }
    }
    return ddlData;
    //Ends
  };

  render() {
    const { props } = this;
    let ddlData = this.handleMoveToNextData(props);
    return (
      <tr
        className="table-row e-row"
        role="row"
        // onClick={() => this.handleDropmenu(props.DisciplinaryCaseId)}
        key={props.index}
      >
        <td className="e-rowcell e-templatecell" role="gridcell">
          <Avatar
            name={props.Name}
            size={45}
            round={true}
            // color="#e1e1e1"
            // fgColor="#787878"
            src={props.UrlPhoto &&  getUploadPath(props.UrlPhoto)}
          />
        </td>
        <td className="e-rowcell e-templatecell" role="gridcell">
          <span
            className="clickable"
            onClick={() =>
              props.push(RouteEnum.ViewDisciplinaryActionPath + props.DisciplinaryCaseId)
            }
          >
            {props.Name}
          </span>
        </td>
        <td className="e-rowcell e-templatecell" role="gridcell">
          <span>{props.INO}</span>
        </td>
        <td className="e-rowcell e-templatecell" role="gridcell">
          <div className="">
            <span>{props.Level}</span>
            <span className="color-light">{props.Position}</span>
          </div>
        </td>
        <td className="e-rowcell e-templatecell" role="gridcell">
          <div className="">
            <span>
              {props.ReportedDateEng &&
                formatedMonthDayYear(props.ReportedDateEng)}
            </span>
            {/* <span className="light-color">
              {" "}
              {props.Totaldays} {props.Totalnight}
            </span> */}
          </div>
        </td>
        <td className="e-rowcell e-templatecell" role="gridcell">
          <div className="">{props.CaseTitle}</div>
        </td>
        <td className="e-rowcell e-templatecell" role="gridcell">
          <div className="">{props.Stage}</div>
        </td>

        {/* <td
          className="e-custom-row e-table-move"
          style={{ overflow: "visible" }}
          role="gridcell"
        >
          {ddlData && ddlData.length > 0 && (
            <Button
              buttonType="drop-button"
              title={props.NextStageText}
              bg="white"
              onClick={() => this.handleMTNS(props.DisciplinaryCaseId, ddlData[0])}
              dropComponent={(
                <ul className="button-drop-list">
                  {ddlData.map((nsd, key) => {
                    return (
                      <li
                        key={key}
                        className={"button-drop-list__item"}
                        onClick={() => {
                          this.handleMTNS(props.DisciplinaryCaseId, nsd);
                        }}
                      >
                        <span className="button-drop-name">
                          {nsd.stageName ? nsd.stageName : nsd.subStageName}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              )}
            />
            // <div
            //   className={`moveStage drop ${this.state.drop ? "active" : ""}`}
            // >
            //   <span
            //     className="clickable"
            //     style={{ cursor: "pointer" }}
            //     onClick={() => {
            //       this.handleMTNS(props.disciplinaryCaseId, ddlData[0]);
            //     }}
            //   >
            //     {props.nextStages}
            //   </span>
            //   <div
            //     className="drop-down-caret moveStage-icon"
            //     ref={(dropdown) => (this.dropdown = dropdown)}
            //     onClick={this.onDrop.bind(this)}
            //   >
            //     <MdKeyboardArrowDown />
            //   </div>

            //   <div id={props.EmployeeId} className="drop__content">
            //     <ul className="drop-list">
            //       {ddlData.map((nsd, key) => {
            //         return (
            //           <li
            //             key={key}
            //             className={"drop-list__item"}
            //             onClick={() => {
            //               this.handleMTNS(props.disciplinaryCaseId, nsd);
            //             }}
            //           >
            //             <span className="drop-name">
            //               {nsd.stageName ? nsd.stageName : nsd.subStageName}
            //             </span>
            //           </li>
            //         );
            //       })}
            //     </ul>
            //   </div>
            // </div>
          )}
        </td> */}

        {/* <td className="e-rowcell e-templatecell" role="gridcell"> */}
        {props.stageId === 0 ? (
          <td
            className="e-custom-row"
            style={{ overflow: "visible" }}
            role="gridcell"
          >
            <div
              className={`more drop ${
                this.state.dropMoreOption ? "active" : ""
              }`}
            >
              <div
                className="drop-down-caret more-icon"
                ref={(dropdown) => (this.dropdownMoreOption = dropdown)}
                onClick={this.onDropMoreOption.bind(this)}
              >
                <MdMoreHoriz />

                <div id={"more" + props.EmployeeId} className="drop__content">
                  <ul className="drop-list">
                    <li
                      className={"drop-list__item"}
                      //onClick={() => props.handleEdit(props.disciplinaryCaseId)}
                      onClick={() => props.handleEdit(props.DisciplinaryCaseId)}
                    >
                      <span className="drop-name">Edit</span>
                    </li>
                    <li
                      className={"drop-list__item"}
                      onClick={() =>
                        props.handleDelete(props.DisciplinaryCaseId)
                      }
                    >
                      <span className="drop-name">Delete</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </td>
        ) : null}
      </tr>
    );
  }
}

export default DisciplinaryTableRow;
