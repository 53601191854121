import React, { useState } from "react";
import SvgIcons from "components/icons/svgIcons";
import { FaTimes } from "react-icons/fa";
import Modal from "components/modal";
import { checkExtension } from "helpers/checkExtension";
import { fileLocation, hostedUrl } from "constants/urls";
import ImageViewer from "components/imageViewer";
import { useSelector } from "react-redux";
import { Apis } from "src/config/api";
import getFullStringUrl from "helpers/getFullStringUrl";

const Preview = (props) => {
  const { files, onDelete, isValidUser } = props;
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [preview, setPreview] = useState(false);
  let { baseUrl } = useSelector((state: any) => ({
    baseUrl: state.auth.baseUrl,
  }));
  const base = baseUrl ? baseUrl.NetFrameworkAppBaseUrl : "";

  const getImage = (file, index) => {
    const { isDocs, isImage, isPdf } = checkExtension(file.ServerFileName);

    let icon = isDocs ? (
      <SvgIcons name="document" size="20" />
    ) : isPdf ? (
      <SvgIcons name="pdf" size="20" />
    ) : isImage ? (
      <img
        src={`${base}/${fileLocation}/${file.ServerFileName}`}
        width="20"
        height="20"
      />
    ) : (
      <SvgIcons name="fileInvoice" size="20" />
    );
    return icon;
  };

  const getDownloadLink = (file) => {
    const param = {
      serverFileName: file.ServerFileName,
      userFileName: file.UserFileName,
      fileType: file.FileType,
    };
    let url = getFullStringUrl(
      `${hostedUrl}${Apis.common.downloadFiles}`,
      param
    );
    return url;
  };

  const handleClick = (index) => {
    setSelectedIndex(index);
    const file = files[index];
    const { isImage } = checkExtension(file.ServerFileName);
    if (isImage) {
      setPreview(true);
    } else {
      window.open(getDownloadLink(file));
    }
  };

  //onDelete && onDelete(index)
  return (
    <>
      {files.map((file, index) => (
        <div
          key={index}
          className="attachments-item"
          title={file.UserFileName}
          onClick={(e) => {
            e.stopPropagation();
            handleClick(index);
          }}
        >
          {getImage(file, index)}

          <span className="item-label">{file.UserFileName}</span>
          {isValidUser() && (
            <div className="btn-remove">
              <FaTimes
                style={{ margin: "0px" }}
                size="12"
                color="#fff"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedIndex(index);
                  setOpen(true);
                }}
              />
            </div>
          )}
        </div>
      ))}

      <Modal
        type="confirm"
        func={() => selectedIndex > -1 && onDelete && onDelete(selectedIndex)}
        open={open}
        onModalClose={() => setOpen(false)}
      />

      {preview && (
        <ImageViewer
          hostedUrl={`${baseUrl.NetFrameworkAppBaseUrl}`}
          dowloadLinkUrl={getDownloadLink(files[selectedIndex])}
          show={preview}
          title={
            files[selectedIndex].UserFileName ||
            files[selectedIndex].ServerFileName
          }
          image={files[selectedIndex]}
          onClose={setPreview}
        />
      )}
    </>
  );
};

export default Preview;
