import Button from "components/button";
import Pagination from "components/common/pagination";
import Icons from "components/icons";
import getFormattedNumber from "helpers/getFormattedNumber";
import isEmpty from "isEmpty";
import React, { useState } from "react";
import { GoComment } from "react-icons/go";
import { RiGroupLine } from "react-icons/ri";
import { TiCancel } from "react-icons/ti";
import { useDispatch } from "react-redux";
import EmployeeDetails from "scenes/common/employeeDetails";
import ApprovalFlow from "../../../../common/approvalFlow";
import { getTravelRequestDetails } from "../actions";
import SvgIcons from "components/svgIcons";

function PendingSettlementBlock(props) {
  let { cancelledList } = props;
  let [modalOpen, setModalOpen] = useState("");
  const dispatch = useDispatch();
  const onModalClose = (id) => {
    setModalOpen(id);
  };
  const handleChangeSettlement = async (requestId, EmployeeId) => {
    await dispatch(getTravelRequestDetails(requestId));
    props.handleSettlementClick(requestId, false, EmployeeId);
  };
  const handleEditSettlement = async (requestId, EmployeeId) => {
    await dispatch(getTravelRequestDetails(requestId));
    props.handleSettlementClick(requestId, true, EmployeeId);
  };

  const handleCancelRequest = (requestId) => {
    props.handleConfirmCancelRequest(requestId);
  };

  const handleViewSettlement = async (requestId) => {
    await dispatch(getTravelRequestDetails(requestId));
    props.handleViewSettlementClick(requestId);
  };
  let {
    handleRowChange,
    handleIndexChange,
    pageIndex,
    rowsPerPage,
    searchedPage,
  } = props;
  return (
    <>
      {props.rows.map((row, rowIndex) => {
        let isCancelled =
          row.IsCancelled ||
          (!isEmpty(cancelledList) &&
            cancelledList.find((x) => x === row.RequestId));
        const employeeDetail = {
          Id: row["EmployeeId"],
          FullName: row["Name"],
          Imagepath: row["UrlPhoto"],
          Designation: row["Designation"],
          Branch: row["Branch"],
          Department: row["Department"],
        };
        return (
          <div className="request-details" key={rowIndex}>
            <div className="request-details__header">
              <EmployeeDetails empDetails={employeeDetail} size={50} />
              {/* <div className="emp-details flex">
                <Avatar
                  alt={row.Name}
                  src={row.UrlPhoto && BASE_URL + row.UrlPhoto}
                  className="emp-image"
                  round
                  size={50}
                />
                <div className="emp-name-desg">
                  <span className="emp-name">{row.Name}</span>
                  <span className="emp-desg">{row.Designation}</span>
                </div>
              </div> */}
              <div>
                <span>{row.PlaceName}</span>
              </div>
              <div>
                <span>{row.TotalDays}</span>
                <span>{row.TotalNights}</span>
              </div>
              <div>
                <span>{row.TravelStartEndDate}</span>
              </div>
              {row.IsCommented ? (
                <div className="comment">
                  <GoComment className="comment__icon"></GoComment>
                  {/* <span className="first-line"></span>
                <span className="second-line"></span> */}
                </div>
              ) : null}
              <div className="requested-on">
                <span>Requested on: </span>
                <span>{row.RequestedOn}</span>
              </div>
            </div>
            <div className="request-details__body">
              <div className="body-row">
                <div className="body-col">
                  <div className="reason">
                    <span>{row.Reason}</span>
                  </div>
                  {row.RequestDestinationDetail.map((dest, destIndex) => {
                    return (
                      <div className="destination dest" key={destIndex}>
                        <div className="dest__row">
                          <span className="district">
                            {dest.District}
                            {!isEmpty(dest.District) ? "," : null}
                          </span>
                          <span>{dest.PlaceName}</span>
                          {destIndex === 0 && !isEmpty(row.GroupEmployee) ? (
                            <div className="travel-people">
                              <RiGroupLine />
                              <span className="travel-people__name">
                                {row.GroupEmployee.map((emp, i) => {
                                  return i + 1 === row.GroupEmployee.length
                                    ? emp
                                    : emp + ", ";
                                })}
                              </span>
                            </div>
                          ) : null}
                        </div>
                        <div className="dest__row">
                          <span className="travel-date">{dest.DestinationStart}</span>
                          <span className="divider"> - </span>
                          <span className="travel-date">{dest.DestinationEnd}</span>
                        </div>
                        <div className="dest__row">
                          <span>{dest.TravelDays}</span>
                          <span className="divider"></span>
                          <span> {dest.TravelNights}</span>
                          <span className="hotel-name">{dest.HotelName}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="body-col">
                  <div className="amount-row">
                    <span>Eligible Amount</span>
                    <span>{getFormattedNumber(row.AssessedEligibilityAmt)}</span>
                  </div>
                  <div className="amount-row">
                    <span>Advance</span>
                    <span>{getFormattedNumber(row.AdvanceRequestAmt)}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="request-details__footer">
              {isCancelled ? (
                <div className="cancel">
                  <TiCancel />
                  <span>Cancelled</span>
                </div>
              ) : (
                <div className="details-btn">
                  {isEmpty(row.SettlementId) || row.SettlementId == 0 ? (
                    <Button
                      title="Cancel"
                      bg="subtle"
                      onClick={() => handleCancelRequest(row.RequestId)}
                      buttonType={"icon-button"}
                      leftIcon={<Icons name="Close" className="flex" />}
                    />
                  ) : (
                    // <button
                    //   type="button"
                    //   className="button button__primary"
                    //   onClick={() => handleCancelRequest(row.RequestId)}
                    // >
                    //   Cancel
                    // </button>
                    <Button
                      title="View"
                      bg="subtle"
                      // type="button-outline"
                      onClick={() => handleViewSettlement(row.RequestId)}
                      buttonType="icon-button"
                      leftIcon={<SvgIcons name={"DetailsList"} size={16} />}
                    />
                    // <button
                    //   type="button"
                    //   className="button button__primary"
                    //   onClick={() => handleViewSettlement(row.RequestId)}
                    // >
                    //   View
                    // </button>
                  )}
                </div>
              )}
              <ApprovalFlow
                approvalStatus={row.ApprovalStatusResults}
                rowIndex={rowIndex}
              />
              {(!isCancelled && isEmpty(row.SettlementId)) || row.SettlementId == 0 ? (
                <div className="btn-col btn-grp flex">
                  {/* <button
                    className="button with-icon button-primary"
                    onClick={() => handleChangeSettlement(row.RequestId)}
                  >
                    Change
                  </button>
                  <button
                    className="button with-icon button-primary"
                    onClick={() => handleEditSettlement(row.RequestId)}
                  >
                    Settlement
                  </button> */}
                  <Button
                    title="Change"
                    bg="subtle"
                    //type="button-outline"
                    onClick={() => handleChangeSettlement(row.RequestId, row.EmployeeId)}
                  />
                  <Button
                    title="Settlement"
                    bg="subtle"
                    //type="button-outline"
                    onClick={() => handleEditSettlement(row.RequestId, row.EmployeeId)}
                  />
                </div>
              ) : null}
            </div>
          </div>
        );
      })}
      {!isEmpty(props.rows) && (
        <Pagination
          handleRowChange={handleRowChange}
          handleIndexChange={handleIndexChange}
          pageIndex={pageIndex}
          searchedPage={searchedPage}
          rowsPerPage={rowsPerPage}
        />
      )}
    </>
  );
}

export default PendingSettlementBlock;
