import React, { Component } from "react";
import Avatar from "react-avatar";
import { MdAdd, MdKeyboardArrowDown } from "react-icons/md";
import { MdDone, MdCancel } from "react-icons/md";
import RouteEnum from 'constants/routeEnum';

export class EmployeeRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      drop: false,
      show: false,
    };
    this.dropdown = "";
    this.timerId = null;
    this.teamCard = "";
    this.avatar = "";
    this.handleClickOutside = this.handleClickOutside.bind(this);
    // this.onOutMouse = this.onOutMouse.bind(this);
    // this.onOverMouse = this.onOverMouse.bind(this);
    this.onCardClick = this.onCardClick.bind(this);
    this.clearState = this.clearState.bind(this);
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener('scroll', this.handleScroll.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener('scroll', this.handleScroll.bind(this));
  }

  handleClickOutside(event) {
    if (this.dropdown && !this.dropdown.contains(event.target)) {
      this.setState({ drop: false });
    }
    if(this.teamCard && !this.teamCard.contains(event.target)){
      this.setState({show:false})  
      this.clearState()
    }
  }
  handleScroll =(event)=> {
    // const measurements = this.avatar.getBoundingClientRect();
    // const ele = this.teamCard;
    // ele.style.left = measurements.left - 100 + "px";
    // ele.style.top = measurements.top + 50 + "px";
  }
  goToEmployeeProfile(id, event) {
    console.log("goToEmployeeProfile", id, event);

  }

  onDrop(id, event) {
    this.setState({ drop: !this.state.drop });
  }
  clearState = () => {
    //   this.setState({ currentCard: null, activeCard: "" });
    if (this.teamCard) {
      this.teamCard.classList.remove("active-card");
    }
  };
  // onOverMouse(e) {
  //   if (this.timerId) {
  //     clearInterval(this.timerId);
  //   }
  //   const measurements = e.currentTarget.getBoundingClientRect();
  //   const ele = this.teamCard;
  //   ele.style.position = "fixed";
  //   ele.style.left = measurements.left - 100 + "px";
  //   ele.style.top = measurements.top + 50 + "px";
  //   ele.classList.add("active-card");
  // }
  // onOutMouse(e) {
  //   if (this.timerId) {
  //     clearInterval(this.timerId);
  //   }
  //   this.timerId = setInterval(this.clearState, 100);
  // }
  onCardClick(e){
    const currentTarget = e.currentTarget;
    console.log()
    this.setState({show:!this.state.show},()=>{
      if(this.state.show){
        const measurements = this.avatar.getBoundingClientRect();
        const ele = this.teamCard;
        ele.style.position = "fixed";
        ele.style.left = measurements.left - 100 + "px";
        ele.style.top = measurements.top + 50 + "px";
        ele.classList.add("active-card");
      }else{
        this.clearState()
      }
    })

  }

  renderCardView = props => {
    return (
      <div ref={ref => (this.teamCard = ref)} className="team-card">
        {props ? (
          <>
            <div className="team-card-image">
              {/* <img src={currentCard.image} alt={currentCard.name} /> */}
              <Avatar
                name={props.Fullname}
                size={60}
                round={true}
                src={props.UrlPhoto &&  props.UrlPhoto}
              />
            </div>
            <div className="team-card-content">
              <div className="team-card-title">
                <h3>{props.Fullname}</h3>
              </div>
              <p className="mb-sm">{props.Branch + ", " + props.Department}</p>
              {/* <span className="team-card-text">{props.Position}</span> */}
              {/* <span className="team-card-text mb-sm">{props.Branch}</span> */}
              <span className="team-card-text">{props.Email}</span>
              <span className="team-card-text">{props.MobileNo}</span>
              <span className="team-card-text">{props.PhoneNo}</span>

              {/* <a href="#" className="team-card-link">
                <span>Send Message</span>
              </a> */}
            </div>
          </>
        ) : null}
      </div>
    );
  };

  render() {
    const { props } = this;
    let style = {};
    if (this.state.drop) {
      if (this.dropdown) {
        const {
          top,
          left,
          height,
          right
        } = this.dropdown.getBoundingClientRect();
        style = {
          position: "fixed",
          top: top + height + 10,
          left: left - 75,
          width: "12rem"
        };
      }
    }
    // const currentCard ={
    //   id: 1,
    //   image: man,
    //   name: "Keshari Bista",
    //   type: "Vp Operations",
    //   position: "Finance Manager",
    //   branch: "Head Office",
    //   email: "Keshari@rigonepal.com",
    //   mobile: "9845609760",
    //   phone: "4418520-12"
    // }
    
    return (
      <tr className="table-row e-row" role="row">
        {/* <td className="e-custom-row" role="gridcell">
          <div
            ref={ref=>this.avatar = ref}
            onClick = {e => this.onCardClick(e)}
            // onMouseOut={e => this.onOutMouse(e)}
            // onMouseOver={e => this.onOverMouse(e)}
          >
            <Avatar
              name={props.Fullname}
              size={45}
              round={true}
              src={props.UrlPhoto && props.UrlPhoto}
            />
          </div>
          {this.renderCardView(props)}
        </td>
        <td className="e-rowcell e-templatecell" role="gridcell">
          <span>{props.Fullname}</span>
        </td> */}
        <td className="e-rowcell e-templatecell" role="gridcell">
          <span>{props.IdCardNo}</span>
        </td>
        {/* <td className="e-rowcell e-templatecell" role="gridcell">
          <span>{props.Level}</span>
        </td> */}
        <td className="e-rowcell e-templatecell" role="gridcell">
          <span>{props.Position}</span>
        </td>
        <td className="e-rowcell e-templatecell" role="gridcell">
          <span>{props.Branch}</span>
        </td>
        <td className="e-rowcell e-templatecell" role="gridcell">
          <div className="">
            <span>{props.Department}</span>
            {/* <span className="light-color">
                within {props.SettlementDays} days of travel
              </span> */}
          </div>
        </td>
        <td className="e-rowcell e-templatecell" role="gridcell">
          <div className="">
            <span>{props.Status}</span>
          </div>
        </td>
        <td className="e-rowcell e-templatecell" role="gridcell">
          <div className="done">
            <MdDone />
            <span style={{ marginLeft: "5px" }}>{props.State}</span>
          </div>
        </td>

        <td
          className="e-custom-row"
          style={{ overflow: "visible" }}
          role="gridcell"
        >
          <div
            className={`profile profile-drop ${
              this.state.drop ? "active" : ""
            }`}
          >
            <span style={{"cursor": "pointer"}}
              onClick={() => {
              this.goToEmployeeProfile.bind(this, props)
              props.push({
                pathname: RouteEnum.EmployeeProfile + `/${props.EmployeeId }`,
                // state: { employeeId: props.EmployeeId }
              });
            }
          }
            >
              Profile
              </span>
            <div
              className="profile-down-caret profile-icon"
              ref={dropdown => (this.dropdown = dropdown)}
              onClick={this.onDrop.bind(this, props.EmployeeId)}
            >
              <MdKeyboardArrowDown />
            </div>
            <div
              id={props.EmployeeId}
              // style={style}
              className="profile-drop__content"
            >
              <ul className="profile-drop-list">
                <li className="profile-drop-list__item">
                  <span className="profile-drop-name">
                    Pay and Compensation
                  </span>
                </li>
                <li className="profile-drop-list__item with-hr">
                  <span className="profile-drop-name">Leave and Time</span>
                </li>
                <li className="profile-drop-list__item with-hr">
                  <span className="profile-drop-name">Delete</span>
                </li>
              </ul>
            </div>
          </div>
        </td>
      </tr>
    );
  }
}

export default EmployeeRow;
