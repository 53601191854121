import React from "react";
import { FaHandPaper, FaPenAlt, FaUserSecret } from "react-icons/fa";
import { IoMdCheckmark, IoMdCheckmarkCircleOutline } from "react-icons/io";
import { MdCheckCircle, MdNotInterested, MdEdit } from "react-icons/md";
import { JobStages } from "../../../../../enums";

export default function Favorite(props: any) {
  const { data } = props || {};

  return (
    <div className="currentstate-div">
      {data.CandidateStatusId === JobStages.Disqualified ? (
        <div className="currentstate-div-item red">
          <MdNotInterested /> {data.CurrentState}
        </div>
      ) : data.CandidateStatusId === JobStages.Shortlisted ? (
        <div className="currentstate-div-item blue">
          <IoMdCheckmark /> {data.CurrentState}
        </div>
      ) : data.CandidateStatusId === JobStages.Interview ? (
        <div className="currentstate-div-item blue">
          <FaUserSecret /> {data.CurrentState}
        </div>
      ) : data.CandidateStatusId === JobStages.Job_Offer ? (
        <div className="currentstate-div-item blue">
          <IoMdCheckmarkCircleOutline /> {data.CurrentState}
        </div>
      ) : data.CandidateStatusId === JobStages.Hired ? (
        <div className="currentstate-div-item blue">
          <MdCheckCircle /> {data.CurrentState}
        </div>
      ) : data.CandidateStatusId === JobStages.Written_Exam ? (
        <div className="currentstate-div-item blue">
          <FaPenAlt /> {data.CurrentState}
        </div>
      ) : (
        <div className="currentstate-div-item">
          <MdEdit /> {data.CurrentState}
        </div>
      )}
    </div>
  );
}
