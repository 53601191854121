import axios from "axios";
import { 
    GET_REGION_CLUSTER,
    GET_CLUSTER,
    UPDATE_CLUSTER,
    ADD_CLUSTER
 } from "actions/types";
import isEmpty from "isEmpty";
import { toastMessage } from "actions/validateAction";

export const getRegionCluster = (search='') => async dispatch =>{
    await axios
    .get(`/v1/organization/region-clusters?searchBranch=${search}`)
    .then(response => {
        if (response.data.Status) {
          dispatch({
            type: GET_REGION_CLUSTER,
            payload: response.data.Data
          });
        } else {
          toastMessage(dispatch, response);
        }
      })
      .catch(err => {
        dispatch({
          type: GET_REGION_CLUSTER,
          payload: []
        });
        const response = {
          data: {
            MessageType: "Danger",
            Message: err.message?err.message:"Failed to get region cluster list."
          }
        };
        toastMessage(dispatch, response);
    });
} 

export const getCluster = (provinceId) => async dispatch =>{
  await axios
  .get(`/v1/organization/clusters${provinceId ? '?provinceId=' + provinceId : ''} `)
  .then(response => {
      if (response.data.Status) {
        dispatch({
          type: GET_CLUSTER,
          payload: response.data.Data
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch(err => {
      dispatch({
        type: GET_CLUSTER,
        payload: []
      });
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message?err.message:"Failed to get region cluster list."
        }
      };
      toastMessage(dispatch, response);
  });
} 

export const moveCluster = (branchId,clusterId) => async dispatch =>{
  await axios
  .put(`/v1/organization/move-branch-to-cluster?branchId=${branchId}&clusterId=${clusterId}`)
  .then(response => {
    if(response.data.Status){
      dispatch(getRegionCluster());
    }
      toastMessage(dispatch, response);
    })
    .catch(err => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message?err.message:"Failed to get move branch."
        }
      };
      toastMessage(dispatch, response);
  });
} 

export const addCluster = (data,isEdit=false) => async dispatch =>{
  await axios
  .post('/v1/organization/clusters',data)
  .then(response => {
      if (response.data.Status) {
        if(isEdit){
          dispatch({
            type: UPDATE_CLUSTER,
            payload: response.data.Data
          });
        }else{
          dispatch({
            type: ADD_CLUSTER,
            payload: response.data.Data
          });
        }

      } 
      toastMessage(dispatch, response);
    })
    .catch(err => {
      
      toastMessage(dispatch, err);
  });
} 