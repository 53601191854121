import React, {useState, useEffect} from "react";
import Modal from "components/modal";
import FormGroup from "components/form";
import SetPermission from "./setPermission";
import AssignRoleModal from "./assignRoleModal";

function NewRole() {
  const [open, setOpen] = useState(false);
  const onModalClose = () => {
    setOpen(false);
  };
  return (
    <div className="admin-title space-between">
      <h1>Roles</h1>
      <button onClick={() => setOpen(true)} className="button button-primary">
        New Role
      </button>
      <Modal
        open={open}
        onModalClose={onModalClose}
        title="Role and Permission"
        isPrimary
      >
        <Roles close={onModalClose} />
      </Modal>
    </div>
  );
}

const Roles = ({close}) => {
  const [role, setRole] = useState("");
  return (
    <div className="permission">
      <div className="permission-body">
        <div className="permission-block">
          <FormGroup
            value={role}
            type="text"
            name="role"
            width={400}
            // placeholder="Search"
            label="Role name"
            onChange={(e) => setRole(e.target.value)}
          />
          <FormGroup
            value={role}
            type="text"
            name="role"
            tag="textarea"
            label="Description"
            onChange={(e) => setRole(e.target.value)}
          />
        </div>
        <div className="permission-block">
          <h2 className="permission-title">Permission</h2>
          <span className="permission-subtitle">Define permission for this role</span>
          <SetPermission />
        </div>
        <div className="permission-block">
          <h2 className="permission-title">Assign this Role to</h2>
          <span className="permission-subtitle">
            Select one or more groups or person who will get this role
          </span>
          <AssignRoleModal />
        </div>
      </div>
      <div className="permission-footer">
        <div className="button-group button-group__right">
          <button className="button button-primary">Save</button>
          <button onClick={() => close()} className="button">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewRole;
