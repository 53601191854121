import React, { Component } from "react";
import { Tabs, Tab } from "components/tabs/index.d";
import FormGroup from "components/form";
// import CommonEmployeeSearch from "components/common/employeeSearch";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from 'isEmpty'
import { getLeaveRequestStatus, searchLeave, getLeaveSource } from "./action";
import {
    searchAttendance,
    generateAttendance
} from "../attendanceRegister/action";
let statusList = [
  { label: 'Requested', value: 1 },
  { label: 'Approved', value: 2 },
  { label: 'Denied', value: 3 },
]
export class ListingTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        { id: 1, label: "Common Parameters" },
        { id: 2, label: "Advanced Parameters" },
      ],
      searchText: "",
      activeTabId: 1,
    };
    this.filters = ''
    this.onLeaveFilter = this.onLeaveFilter.bind(this);
    // this.onClear = this.onClear.bind(this);
    // this.onGenerate = this.onGenerate.bind(this);
    // this.onAttendanceFilter=this.onAttendanceFilter.bind(this);
  }

  componentDidMount = () => {
    const {activeTab}=this.props;
    const { selectedPayPeriod } = this.props.selectReducer;
    const { activeTabId } = this.state;
    // setTimeout(() => {
    // }, 2000)

    // if(activeTab !== 'leave'){
        if(!isEmpty(selectedPayPeriod)){
          this.props.onFilter(activeTabId);
            // this.onAttendanceFilter();
        }
    // }
  };
  // componentDidUpdate = (prevProps, prevState) => {
  //   const {activeTab} = this.props;
  //   const { selectedPayPeriod } = this.props.selectReducer;
    
  //   if (
  //       this.props.pageIndex !== prevProps.pageIndex ||
  //       this.props.rowsPerPage !== prevProps.rowsPerPage ||
  //       selectedPayPeriod !== prevProps.selectReducer.selectedPayPeriod ||
  //       activeTab !==prevProps.activeTab 
  //   )
  //   {
  //       if(activeTab==='leave'){
  //           this.onLeaveFilter();
  //       }else{
  //           this.onAttendanceFilter()
  //       }
        
  //   }
  // };

  handleTabChange = (tabId) => {
    this.setState({ activeTabId: tabId });
  };

  onLeaveFilter() {
    // console.log(this.filters.state)
    const { employee, branch, department, leaveStatus, position,leaveTypes } = this.state;
    const { selectedPayPeriod } = this.props.selectReducer;
    const searchedData = {
        // AvailableEmployees:
        //     employee.value === 0
        //         ? []
        //         : [
        //             {
        //                 Text: employee.label,
        //                 Value: employee.value,
        //                 Selected: true
        //             }
        //         ],
        // AvailableBranches: isEmpty(branch)
        //     ? []
        //     : branch.map(item => {
        //         return {
        //             Text: item.label,
        //             Value: item.value,
        //             Selected: true
        //         };
        //     }),
        // AvailableDepartments:
        //     department.value === 0
        //         ? []
        //         : [
        //             {
        //                 Text: department.label,
        //                 Value: department.value,
        //                 Selected: true
        //             }
        //         ],
        // LeaveRequestStatuses:
        //     leaveStatus.value === 0
        //         ? []
        //         : [
        //             {
        //                 Text: leaveStatus.label,
        //                 Value: leaveStatus.value,
        //                 Selected: true
        //             }
        //         ],
        // AvailablePositions:
        //     position.value === 0
        //         ? []
        //         : [
        //             {
        //                 Text: position.label,
        //                 Value: position.value,
        //                 Selected: true
        //             }
        //         ],
        // AvailableLeaveTypes: [],
        PayrollPeriodId: parseInt(selectedPayPeriod.Value),
        EmployeeId:!isEmpty(employee)? parseInt(employee.value):-1,
        DepartmentId: !isEmpty(employee)?parseInt(department.value):-1,
        LeaveRequestStatusId: !isEmpty(employee)?parseInt(leaveStatus.value):-1,
        PositionId: !isEmpty(employee)?parseInt(position.value):-1,
        LeaveTypeId: !isEmpty(employee)?parseInt(leaveTypes.value):-1,
        BranchIds: isEmpty(branch)
            ? []
            : branch.map(item => parseInt(item.value)),
        PageIndex: this.props.pageIndex,
        PageSize: this.props.rowsPerPage
    };
    this.props.searchLeave(searchedData);
  }

  render() {
    const { tabs, activeTabId } = this.state;
    return (
      <div className="employee-tabs">
        <Tabs
          activeTab={activeTabId}
          type="block"
          isCollapse={true}
          collapseText="FILTRATION"
        >
          {tabs.map((tab, index) => {
            return (
              <Tab
                label={tab.label}
                name={tab.id}
                id={tab.id}
                key={index}
                onTabClick={() => this.handleTabChange(tab.id)}
              >
                {tab.id === 1 ? <CommonFilter {...this.props} activeTabId={activeTabId}/>:<AdvanceFilter {...this.props} activeTabId={activeTabId} />}
              </Tab>
            );
          })}
        </Tabs>
      </div>
    );
  }
}

const genders = [
  { label: "Male", value: 1 },
  { label: "Female", value: 0 },
  { label: "Others", value: 2 },
];
const approval_state = [
  { label: "Approved", value: 'Approved' },
  { label: "Pending", value: 'Pending' },
  { label: "Completed", value: 'Completed' },
];
const new_joins_opt = [
  { label: "This Month", value: 1 },
  { label: "Last Month", value: 2 },
  { label: "This Year", value: 3 },
];
const retired_opt = [
  { label: "This Month", value: 1 },
  { label: "Last Month", value: 2 },
  { label: "This Year", value: 3 },
];
const state_opt = [
  { label: "Working Only", value: false },
  { label: "Retired Only", value: true },
  { label: "All", value: null },
];
const AdvanceFilter = (props) => {
  const {
    region,
    approvalState,
    leaveType,
  } = props.parentState;
  return (
    <div className="filters-input filters">
      <div className="filters-input__item">
        <FormGroup
          // formName="reactselect"
          onChange={props.handleInput}
          name="region"
          label={"Region"}
          value={region}
          // options={genders}
          placeholder="Region"
          width="200px"
        />
      </div>
      <div className="filters-input__item">
        <FormGroup
          formName="reactselect"
          onChange={props.handleAutoSelect}
          name="approvalState"
          label={"Approval State"}
          value={approvalState}
          options={approval_state}
          placeholder="Select Approval State"
          width="200px"
        />
      </div>
      <div className="filters-input__item">
        <FormGroup
          formName="reactselect"
          onChange={props.handleAutoSelect}
          name="leaveType"
          label={"Leave type"}
          value={leaveType}
          options={new_joins_opt}
          placeholder="Select Leave type"
          width="200px"
        />
      </div>
      <div className="filters-input__item">
        <button
          onClick={() => props.onFilter(props.activeTabId)}
          className="btn btn-primary"
        >
          Apply
        </button>
      </div>
    </div>
  );
};

const CommonFilter = (props) => {
  const {
    searchValue,
    activeBranch,
    activeDepartment,
    activePosition,
    activeStatus,
    // onFilter
  } = props.parentState;
  const { branches, departments, designations, /* statusList, */ activeTabId, leaveReducer } = props;
  const { leaveStatus: { value }} = leaveReducer;
  return (
    <div className="filters-input filters">
      <div className="filters-input__item search">
        <FormGroup
          formName="reactselect"
          onChange={props.handleAutoSelect}
          name="searchValue"
          label={"Employee"}
          value={searchValue}
          options={props.employeeListSelect}
          loadingType="skeleton"
          placeholder="Select"
          validators={["required"]}
          width="200px"
        />
      </div>
      <div className="filters-input__item">
        <FormGroup
          formName="reactselect"
          onChange={props.handleAutoSelect}
          name="activeBranch"
          label={"Branch"}
          value={activeBranch}
          options={branches}
          placeholder="Select Branch"
          width="200px"
        />
      </div>
      <div className="filters-input__item">
        <FormGroup
          formName="reactselect"
          onChange={props.handleAutoSelect}
          name="activeDepartment"
          label="Department"
          value={activeDepartment}
          options={departments}
          placeholder="Select Department"
          width="200px"
        />
      </div>
      <div className="filters-input__item">
        <FormGroup
          formName="reactselect"
          onChange={props.handleAutoSelect}
          name="activeStatus"
          label="Status"
          value={activeStatus}
          options={value}
          placeholder="Select Status"
          width="200px"
        />
      </div>

      <div className="filters-input__item">
        <FormGroup
          formName="reactselect"
          onChange={props.handleAutoSelect}
          name="activePosition"
          label="Position"
          value={activePosition}
          options={designations}
          placeholder="Select Position"
          width="200px"
        />
      </div>

      <div className="filters-input__item">
        <button
          onClick={() => props.onFilter(activeTabId)}
          className="btn btn-primary"
        >
          Apply
        </button>
      </div>
    </div>
  );
};

ListingTabs.propTypes = {
  searchLeave: PropTypes.func.isRequired,
  leaveReducer: PropTypes.object.isRequired,
  selectReducer: PropTypes.object.isRequired,
  searchAttendance: PropTypes.object.isRequired,
  generateAttendance: PropTypes.func.isRequired,
  payrollAttendanceReducer: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  leaveReducer: state.leaveReducer,
  selectReducer: state.selectReducer,
  payrollAttendanceReducer: state.payrollAttendanceReducer,
  employeeListSelect: state.commonReducer.employeeListSelect,
});

export default connect(mapStateToProps, 
  {   getLeaveRequestStatus,
      searchLeave,
      searchAttendance,
      generateAttendance,
      // getJobStatus
  })
  (ListingTabs);