import React, { Component } from "react";
import { connect } from "react-redux";
// import { IoIosCheckmarkCircle } from "react-icons/io";
// import classnames from "classnames";
import isEmpty from "isEmpty";
import AnimatedForm from "components/common/animatedForm";
import Form from "components/form";
import { CreateClient, GetClientByClientId, UpdateClient } from "../../action";
import { InputValidator } from "helpers/inputValidator";

export class NewClientForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientId: 0,
      clientName: "",
      shortName: "",
      contactPerson: "",
      contactPerson2: "",
      contactPerson3: "",
      phoneNo: "",
      phoneNo2: "",
      phoneNo3: "",
      email: "",
      email2: "",
      email3: "",
      address: "",
      note: "",
      selectedType: null,
      contractCurrency: null,

      isClientAlreadyExist: false,
      isShortNameAlreadyExist: false,
    };
  }
  async componentDidMount() {
    this.props.childRef.current = this;
    await this.getDataByClientId();
  }

  getDataByClientId = async () => {
    const { clientId, currencyList, legalClientTypes } = this.props.parent;

    if (clientId > 0) {
      await this.props.GetClientByClientId(clientId);
      if (this.props.legalClientById) {
        const {
          ContactPerson,
          ContactPerson2,
          ContactPerson3,
          PhoneNumber,
          PhoneNumber2,
          PhoneNumber3,
          Email,
          Email2,
          Email3,
          Address,
          Note,
          IsArchived,
          ClientId,
          ClientName,
          ShortName,
          ClientTypeRefId,
          ClientType,
          CurrencyCode,
        } = this.props.legalClientById;

        let contractCurrency = !isEmpty(CurrencyCode)
          ? currencyList.find((f) => f.label == CurrencyCode)
          : null;
        let selectedType = !isEmpty(ClientTypeRefId)
          ? legalClientTypes.find((f) => f.value == ClientTypeRefId)
          : null;

        this.setState({
          clientId: ClientId,
          clientName: ClientName,
          shortName: ShortName,
          contactPerson: ContactPerson,
          contactPerson2: ContactPerson2,
          contactPerson3: ContactPerson3,
          phoneNo: PhoneNumber,
          phoneNo2: PhoneNumber2,
          phoneNo3: PhoneNumber3,
          email: Email,
          email2: Email2,
          email3: Email3,
          address: Address,
          note: Note,
          selectedType: selectedType,
          contractCurrency: contractCurrency,
        });
      }
    }
  };

  handleInput = async (name, value) => {
    const { activeClientsList } = this.props;
    let { isClientAlreadyExist, isShortNameAlreadyExist } = this.state;

    if (activeClientsList) {
      if (name == "clientName") {
        const searchedClientName = activeClientsList.find(
          (f) => f.ClientName.toLocaleLowerCase() == value.toLocaleLowerCase()
        );
        isClientAlreadyExist = searchedClientName ? true : false;
      } else if (name == "shortName") {
        const searchedShortName = activeClientsList.find(
          (f) => f.ShortName.toLocaleLowerCase() == value.toLocaleLowerCase()
        );
        isShortNameAlreadyExist = searchedShortName ? true : false;
      }
    }

    this.setState(
      { [name]: value, isClientAlreadyExist, isShortNameAlreadyExist },
      () => {
        this.updateErrors();
      }
    );
  };

  handleAutoSelect = (name, selected) => {
    this.setState({ [name]: selected });
  };
  // handleMultiSelect = (list, selected) => {
  //   this.setState({ contractCurrency: list });
  // };

  handleChipClick = (name, value) => {
    this.setState({ [name]: value }, () => {
      this.updateErrors();
    });
  };

  updateErrors = async () => {
    const { errors } = this.state;
    if (!isEmpty(errors)) {
      let errors = await InputValidator(document, this.state);
      this.setState({ errors });
    }
  };

  handleSave = async () => {
    let errors = await InputValidator(document, this.state);
    if (!isEmpty(errors)) {
      this.setState({ errors });
      return;
    }
    const {
      clientId,
      clientName,
      shortName,
      contactPerson,
      contactPerson2,
      contactPerson3,
      phoneNo,
      phoneNo2,
      phoneNo3,
      email,
      email2,
      email3,
      address,
      note,
      selectedType,
      contractCurrency,
    } = this.state;

    // let comaSeperatedCurrencyCodes = null;
    // contractCurrency &&
    //   contractCurrency.map((currency) => {
    //     if (isEmpty(comaSeperatedCurrencyCodes)) {
    //       comaSeperatedCurrencyCodes = currency.label;
    //     } else {
    //       comaSeperatedCurrencyCodes =
    //         comaSeperatedCurrencyCodes + ", " + currency.label;
    //     }
    //   });

    const data = {
      ClientId: clientId,
      ClientTypeRefId: selectedType ? selectedType.value : 0,
      ClientType: selectedType ? selectedType.label : "",
      ClientName: clientName,
      ShortName: shortName,
      ContactPerson: contactPerson,
      ContactPerson2: contactPerson2,
      ContactPerson3: contactPerson3,
      PhoneNumber: phoneNo,
      PhoneNumber2: phoneNo2,
      PhoneNumber3: phoneNo3,
      Email: email,
      Email2: email2,
      Email3: email3,
      Address: address,
      CurrencyCode: contractCurrency ? contractCurrency.label : null,
      Note: note,
    };

    if (clientId > 0) {
      await this.props.UpdateClient(data, this.props);
    } else {
      await this.props.CreateClient(data, this.props);
    }
  };

  render() {
    const {
      clientName,
      shortName,
      contactPerson,
      contactPerson2,
      contactPerson3,
      phoneNo,
      phoneNo2,
      phoneNo3,
      email,
      email2,
      email3,
      address,
      note,
      selectedType,
      contractCurrency,
      errors,
      isClientAlreadyExist,
      isShortNameAlreadyExist,
    } = this.state;

    const { currencyList, legalClientTypes } = this.props.parent;

    // const selectedTypeId = selectedType ? selectedType.value : null;
    // const chipClasses = (typeId) => {
    //   return classnames({
    //     badges: true,
    //     "badges-rounded": true,
    //     "left-icon": selectedTypeId === typeId,
    //     "badges-primary": selectedTypeId === typeId,
    //     "badges-default": selectedTypeId !== typeId,
    //   });
    // };

    // const inputclass = (error) => {
    //   return classnames({
    //     "client-section-content": true,
    //     // "form-group": true,
    //     // "inline-form-group": true,
    //     // inputWidth: inputWidth,
    //     error: error,
    //   });
    // };

    return (
      <div className="client">
        <div className="client-section">
          <div className="client-section-content">
            {/* <InputGroup
              name="clientName"
              value={clientName}
              onChange={this.handleInput}
              label="Client Name"
              validators={["required"]}
              error={errors && errors.clientName}
            /> */}
            <Form
              name="clientName"
              value={clientName}
              onChange={this.handleInput}
              label="Client Name"
              validators={["required"]}
              error={
                isClientAlreadyExist
                  ? "This client name already exist"
                  : errors && errors.clientName
              }
            />
          </div>
          <div className="client-section-content">
            <Form
              name="shortName"
              value={shortName}
              onChange={this.handleInput}
              label="Short Name"
              error={
                isShortNameAlreadyExist ? "This short name already exist" : null
              }
              width={"25%"}
            />
          </div>
          <div className="client-section-content">
            <Form
              formName="chipsinput"
              name={"selectedType"}
              chipsArray={legalClientTypes}
              value={selectedType && selectedType.label}
              onChange={this.handleChipClick}
              label="Type"
              validators={["required"]}
              error={errors && errors.selectedType}
            />
          </div>
          <div className="client-section-horizontal">
            <div className="client-section-content">
              <Form
                name="contactPerson"
                value={contactPerson}
                onChange={this.handleInput}
                label="Contact Person 1"
                validators={["required"]}
                error={errors && errors.contactPerson}
              />
            </div>
            <div className="client-section-content">
              <Form
                name="contactPerson2"
                value={contactPerson2}
                onChange={this.handleInput}
                label="Contact Person 2"
              />
            </div>
            <div className="client-section-content">
              <Form
                name="contactPerson3"
                value={contactPerson3}
                onChange={this.handleInput}
                label="Contact Person 3"
              />
            </div>
          </div>

          <div className="client-section-horizontal">
            <div className="client-section-content">
              <Form
                name="phoneNo"
                value={phoneNo}
                onChange={this.handleInput}
                label="Phone Number 1"
                validators={["required"]}
                error={errors && errors.phoneNo}
              />
            </div>
            <div className="client-section-content">
              <Form
                name="phoneNo2"
                value={phoneNo2}
                onChange={this.handleInput}
                label="Phone Number 2"
              />
            </div>
            <div className="client-section-content">
              <Form
                name="phoneNo3"
                value={phoneNo3}
                onChange={this.handleInput}
                label="Phone Number 3"
              />
            </div>
          </div>

          <div className="client-section-horizontal">
            <div className="client-section-content">
              <Form
                name="email"
                value={email}
                onChange={this.handleInput}
                label="Email 1"
                validators={["required", "email"]}
                error={errors && errors.email}
              />
            </div>
            <div className="client-section-content">
              <Form
                name="email2"
                value={email2}
                onChange={this.handleInput}
                label="Email 2"
                validators={["email"]}
                error={errors && errors.email2}
              />
            </div>
            <div className="client-section-content">
              <Form
                name="email3"
                value={email3}
                onChange={this.handleInput}
                label="Email 3"
                validators={["email"]}
                error={errors && errors.email3}
              />
            </div>
          </div>
        </div>

        <div className="client-section">
          <div className="client-section-content">
            <Form
              formName="reactselect"
              name="contractCurrency"
              value={contractCurrency}
              options={currencyList}
              onChange={this.handleAutoSelect}
              label="Contract currency"
              placeholder="Select.."
              width="200px"
            />
          </div>
          <div className="client-section-content">
            <Form
              formName="textarea"
              name="address"
              value={address}
              onChange={this.handleInput}
              label="Address"
            />
          </div>
          <div className="client-section-content">
            <Form
              formName="textarea"
              name="note"
              value={note}
              label="Note"
              onChange={this.handleInput}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  legalClientById: state.taskTrackerReducer.legalClientById,
  activeClientsList: state.taskTrackerReducer.activeClientsList,
});

const mapDispatchToProps = {
  CreateClient,
  GetClientByClientId,
  UpdateClient,
};

export default AnimatedForm()(
  connect(mapStateToProps, mapDispatchToProps)(NewClientForm)
);
