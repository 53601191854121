import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
import classnames from "classnames";
import FormGroup from "components/form";
import isEmpty from "isEmpty";

import CreatableSelect from "react-select/creatable";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import {
  GetInterviewMedium,
  PostScheduleInterview,
  GetScheduledInterview,
} from "store/actions/recruitment/interview";
import {
  GetJobHiringTeams,
  GetJobHiringSubStages,
} from "store/actions/recruitment/job";
import { GetMilestoneDetail } from "store/actions/recruitment/dashboard";

import { ConvertToDataSource } from "helpers/getTextLabel";
import { stat } from "fs";
import value from "environment";
import { EmailRegexValidaion } from "helpers/regex";
import { JobStages } from "scenes/recruit/enums";

//#region Material UI
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  radioGroup: {
    display: "flex",
    flexDirection: "row",
    padding: "20px 0 0",
  },
  textField: {
    height: 28,
    width: 100,
    marginLeft: 5,
  },
  checkBoxContainer: {
    border: "2px solid #dfe1e6",
    padding: "10px",
  },
}));

const radioStyle = makeStyles({
  root: {
    margin: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 20,
    height: 20,
    boxShadow:
      "inset 0 0 0 2px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#fff",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#fff",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 12,
      height: 12,
      borderRadius: "50%",
      // backgroundImage: "radial-gradient(#666,#666 40%,transparent 44%)",
      backgroundColor: "#2680eb",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#d1d1d1",
    },
  },
});
//#endregion

// Inspired by blueprintjs
function StyledRadio(props) {
  const classes = radioStyle();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      onChange={props.onChange}
      checkedIcon={
        <span
          className={classnames({
            [classes.icon]: true,
            [classes.checkedIcon]: true,
          })}
        />
      }
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

export function ScheduleInterview(props) {
  let mediumInfoName = "";
  const { interviewMediums, hiringSubStages, data } = props;
  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const [hiringTeams, setHiringTeams] = useState([]);

  const [state, setState] = useState({
    subStage: {},
    interviewMedium: "",
    interviewers: [],
    externalInterviewers: "",
    confirmed: "confirmed",
    startTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    interviewDate: moment(new Date()),
    interviewTime: moment(new Date()),
  });

  useEffect(() => {
    console.log(data);
    props.GetInterviewMedium();
    props.GetJobHiringSubStages(data.JobId, JobStages.Interview);
    if (data) {
      props.GetJobHiringTeams(data.JobId);
      fetchScheduledInterview();
    }
  }, []);
  useEffect(() => {
    setToState(props.scheduledInterview);
  }, [props.scheduledInterview]);

  useEffect(() => {
    if (props.interviewHiringTeams) {
      setHiringTeams(
        ConvertToDataSource(props.interviewHiringTeams, "Name", "EmployeeId")
      );
    }
  }, [props.interviewHiringTeams]);

  const fetchScheduledInterview = async (subStage) => {
    let param = {
      candidateId: data.CandidateId,
    };
    if (subStage) param.subStageId = subStage;
    await props.GetScheduledInterview(param);
  };

  const handleChange = (name, value) => {
    let nextState = { ...state };
    //validateForm();
    console.log(name, value);
    nextState[name] = value;
    setState(nextState);
    if (name == "subStage") {
      fetchScheduledInterview(value && value.value);
    }
  };
  const handleDateChange = (name, momentObj) => {
    let nextState = { ...state };
    let { interviewDate, interviewTime } = nextState;
    nextState[name] = momentObj;

    if (name === "interviewDate") {
      interviewDate = momentObj;
    } else {
      interviewTime = momentObj;
    }
    let time = `${interviewDate.format("MM/DD/yyyy")} ${interviewTime.format("HH:mm:ss")}`;
    nextState["startTime"] = time
    setState(nextState);
  };

  const renderMediumInfo = () => {
    var info = "";
    const { mediumInfo, interviewMedium } = state;

    if (!isEmpty(interviewMedium)) {
      if (interviewMedium.value === 1) info = "Location";
      else if (interviewMedium.value === 2) info = "Link";
      else info = "Phone Number";
    }
    if (info === "") return null;
    mediumInfoName = info;

    return (
      <div>
        <FormGroup
          label={info}
          name="mediumInfo"
          onChange={handleChange}
          value={mediumInfo}
          width="200px"
        />
        {errors.mediumInfo && (
          <span className="auto-error__message">{errors.mediumInfo}</span>
        )}
      </div>
    );
  };
  const validateForm = () => {
    const {
      subStage,
      interviewMedium,
      mediumInfo,
      duration,
      externalInterviewers,
    } = state;
    const errors = {};
    if (hiringSubStages && hiringSubStages.length > 0 && isEmpty(subStage)) {
      errors.subStage = "Interview sub stage is required.";
    }
    if (isEmpty(interviewMedium)) {
      errors.interviewMedium = "Interview medium is required.";
    }
    if (!isEmpty(interviewMedium) && isEmpty(mediumInfo)) {
      errors.interviewMedium = `${mediumInfoName} is required.`;
    }
    if (isEmpty(duration)) {
      errors.duration = "Interview duration is required.";
    }

    setErrors(errors);

    if (!isEmpty(externalInterviewers)) {
      var emails = externalInterviewers.split(",");
      emails.forEach((email) => {
        if (!EmailRegexValidaion(email)) {
          errors.externalInterviewers = `Invalid email ${email}`;
          return;
        }
      });
    }

    return { isValid: isEmpty(errors), errors };
  };
  const onSendInvitation = async (e) => {
    const valid = validateForm();
    console.log(valid);
    if (valid.isValid) {
      const param = getParam();
      props.PostScheduleInterview &&
        (await props.PostScheduleInterview(param, (res) => {
          const { CandidateId, JobId } = data;
          props.onSuccessScheduling && props.onSuccessScheduling(res);
          props.GetMilestoneDetail({ CandidateId, JobId });
        }));

      console.log(param);
    }
  };
  const getParam = () => {
    const {
      subStage,
      confirmed,
      duration,
      externalInterviewers,
      interviewMedium,
      interviewers,
      mediumInfo,
      startTime,
      scheduleId,
    } = state;

    console.clear()
    console.log(state)
    let param = {
      scheduleId: scheduleId || 0,
      CandidateRefId: data.CandidateId,
      InterviewMediumRefId: interviewMedium.value,
      InterviewMediumInfoName: mediumInfoName,
      InterviewMediumInfoValue: mediumInfo,
      ExternalInterviewers: externalInterviewers,
      Duration: duration,
      StartTime: startTime,
      IsConfirm: confirmed === "confirmed" ? true : false,
    };

    if (!isEmpty(subStage)) {
      param.SubStageRefId = subStage.Id;
    }
    if (!isEmpty(interviewers)) {
      param.Interviewers = interviewers;
    }
    return param;
  };

  const setToState = (obj) => {
    let newState = { ...state };
    if (isEmpty(obj)) {
      mediumInfoName = "";
      newState.scheduleId = "";
      newState.mediumInfo = "";
      newState.externalInterviewers = "";
      newState.duration = "";
      newState.confirmed = "confirmed";
      newState.interviewMedium = {};
      newState.selectedInterviewers = [];
      newState.interviewDate = moment(new Date());
      newState.interviewTime = moment(new Date());
      //setHiringTeams(hiringTeamDs)

      setState(newState);

      return;
    }

    let selectedMediums = interviewMediums.find(
      (x) => x.InterviewMediumId == obj.InterviewMediumRefId
    );

    if (selectedMediums) {
      newState.interviewMedium = {
        ...selectedMediums,
        label: selectedMediums.Name,
        value: selectedMediums.InterviewMediumId,
      };
    }
    mediumInfoName = obj.InterviewMediumInfoName;
    newState.selectedInterviewers = obj.Interviewers || [];
    newState.scheduleId = obj.ScheduleId;
    newState.mediumInfo = obj.InterviewMediumInfoValue;
    newState.externalInterviewers = obj.ExternalInterviewers;
    newState.duration = obj.Duration;
    newState.confirmed = obj.IsConfirm ? "confirmed" : "tobe";
    newState.interviewDate = moment(obj.StartTime);
    newState.interviewTime = moment(obj.StartTime);
    // InterviewMediumRefId: interviewMedium.value,
    setState(newState);
  };

  return (
    <div className="schedule">
      <div className="schedule-header">
        <label>Current Stage</label>
        <h4>Interview</h4>
      </div>
      <div className="schedule-block">
        {hiringSubStages && hiringSubStages.length > 0 && (
          <div
            className={classnames({
              "schedule-block-input": true,
              "auto-error": !isEmpty(errors.subStage),
            })}
          >
            <FormGroup
              formName="reactselect"
              onChange={handleChange}
              options={ConvertToDataSource(
                hiringSubStages,
                "SubStageName",
                "Id"
              )}
              value={state.subStage}
              name="subStage"
              width="200px"
              label="Interview Type"
            />
            {errors.subStage && (
              <span className="auto-error__message">{errors.subStage}</span>
            )}
          </div>
        )}

        <div className="schedule-block-input">
          <div
            className={classnames({
              " flex new-type": true,
              "auto-error": !isEmpty(errors.interviewMedium),
            })}
          >
            <FormGroup
              formName="reactselect"
              onChange={handleChange}
              options={ConvertToDataSource(
                interviewMediums,
                "Name",
                "InterviewMediumId"
              )}
              value={state.interviewMedium}
              name="interviewMedium"
              width="200px"
              label="Medium"
            />

            {renderMediumInfo()}
          </div>
          {errors.interviewMedium && (
            <span className="auto-error__message">
              {errors.interviewMedium}
            </span>
          )}
        </div>
        {hiringTeams && hiringTeams.length > 0 && (
          <div className="schedule-block-input">
            <div>
              <div className="genericForm-group__label">
                <label className="genericForm-group__label">Interviewers</label>
              </div>
              <div className="schedule-block-checkBoxList">
                <FormGroup
                  formName="checkBoxList"
                  name="interviewers"
                  selectedItems={state.selectedInterviewers}
                  items={hiringTeams}
                  value={state.interviewers}
                  onChange={handleChange}
                />
              </div>
              {/* <CreatableSelect
              defaultValue={[]}
              isMulti
              name=""
              label="dfsdff"
              onChange={(value) => {
                //onSelectChange("tags", value);
                console.log(value);
                handleChange("interviewers", value);
              }}
              value={state.interviewers}
              options={ConvertToDataSource(hiringTeams, "Name", "EmployeeId")}
              className="tags-multi-select"
              // styles={colourStyles}
              classNamePrefix="select"
            /> */}
            </div>
          </div>
        )}
        <div
          className={classnames({
            "schedule-block-input": true,
            "auto-error": !isEmpty(errors.externalInterviewers),
          })}
        >
          <FormGroup
            onChange={handleChange}
            value={state.externalInterviewers}
            name="externalInterviewers"
            label="External Interviewers"
            placeholder="Enter email address with comma seperated"
          />
          {errors.externalInterviewers && (
            <span className="auto-error__message">
              {errors.externalInterviewers}
            </span>
          )}
        </div>
        <div className="schedule-block-input">
          <div className="flex">
            <div
              className={classnames({
                "auto-error": !isEmpty(errors.duration),
              })}
            >
              <FormGroup
                value={state.duration}
                type="number"
                name="duration"
                width="200px"
                label="Interview Duration in Minutes"
                onChange={handleChange}
              />
            </div>
            <div className="schedule-block-type">
              <label htmlFor="" className="form-group__label">
                Start Time
              </label>
              <div className="date-time-picker">
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    name="interviewDate"
                    value={state.interviewDate}
                    className={classes.textField}
                    onChange={(obj) => {
                      handleDateChange("interviewDate", obj);
                    }}
                  />
                  <TimePicker
                    name="interviewTime"
                    value={state.interviewTime}
                    className={classes.textField}
                    onChange={(obj) => {
                      handleDateChange("interviewTime", obj);
                    }}
                  />
                  {/* <DateTimePicker value={selectedDate} onChange={handleDateChange} /> */}
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>

          {errors.duration && (
            <span className="auto-error__message">{errors.duration}</span>
          )}
        </div>

        <div>
          <div className="schedule-block-type">
            <RadioGroup
              className={classes.radioGroup}
              defaultValue={""}
              aria-label="access"
              name="confirmed"
              onChange={(e, val) => {
                console.log(val);
                handleChange("confirmed", val);
              }}
              value={state.confirmed}
            >
              <FormControlLabel
                value="confirmed"
                control={<StyledRadio />}
                label="Confirmed Time"
                disabled
              />
              <FormControlLabel
                value="tobe"
                control={<StyledRadio />}
                label="To be confirmed by the candidate"
                disabled
              />
            </RadioGroup>
          </div>
        </div>
      </div>
      <div className="schedule-footer">
        <div className="button-group button-group__right">
          {/* <button className="btn btn-grey2">Cancel</button> */}
          <button className="btn btn-blue" onClick={onSendInvitation}>
            Send Invitation
          </button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  hiringStageAndSubStagesJobId:
    state.recruitment_dashboardReducer.hiringStageAndSubStagesJobId,
  interviewMediumLoading:
    state.recruitment_interviewReducer.interviewMediumLoading,
  interviewMediums: state.recruitment_interviewReducer.interviewMediums,
  interviewHiringTeams: state.recruitment_jobReducer.hiringTeams,
  hiringTeamsLoading: state.recruitment_jobReducer.hiringTeamsLoading,
  hiringSubStages: state.recruitment_jobReducer.hiringSubStages,
  scheduledInterview: state.recruitment_interviewReducer.scheduledInterview,
});
const mapDispatchToProps = {
  GetInterviewMedium,
  GetJobHiringTeams,
  GetJobHiringSubStages,
  PostScheduleInterview,
  GetScheduledInterview,
  GetMilestoneDetail,
};
export default connect(mapStateToProps, mapDispatchToProps)(ScheduleInterview);
