import React from 'react'


const RowSelectionCheckbox = React.forwardRef<any>(
    ({ indeterminate, ...rest }: any, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef: any = ref || defaultRef
    
        React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])
    
        return (
        <>
            <input className="table-check" type="checkbox" ref={resolvedRef} {...rest} />
        </>
        )
    }
    )

export default RowSelectionCheckbox
