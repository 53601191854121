import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import "./profile.scss";
import {
    getProfileDetail,
    getEmployeeManagers,
    getEmployeeManagerHistory,
    getEmployeeRolesHistory,
    getJobDescriptionByLevel,
    getEmployeeJobDescription,
    getEmployeeKsaRating
} from './action'
import {
    GetDesignations
} from 'actions/commonAction';
import Title from "../../common/title";
import Personal from './personal'
import ProfileInfo from './profileInfo';
import Loader from "components/loaders";
import isEmpty from "isEmpty";
import {Tabs,Tab} from 'components/tabs/index.d';
import Placement from './placement';
import Education from './education';
import Leave from './leave';
import KSA from './ksa';
import Payroll from './payroll'
import HealthFamily from './healthFamily'
import Address from './address';
import Journal from './journal'
// import Salary from '../../admin/employeeOnboarding/employeeForm/onboardingForm'
import Documents from './documents'
import RouteEnum from 'constants/routeEnum';

export class Profile extends Component {
    
    constructor(props) {
        super(props);
        this.state={
            tabs:[
                {name:'Personal Info',label:'Personal Info',component:<Personal />},
                {name:'Payroll',label:'Payroll',component:<Payroll />},
                {name:'Leave & Time',label:'Leave & Time', component: <Leave employeeId={props.match.params.id}/>},
                {name:'Job & Placement',label:'Job & Placement', key: '3', component:<Placement getPlacementData={this.getPlacementData} employeeId={props.match.params.id} {...props}/>},
                // {name:'Address',label:'Address',component:<Address />},
                {name:'Education & Experience',label:'Education & Experience', component: <Education employeeId={props.match.params.id} {...props} />},
                {name:'Documents',label:'Docs & Identification',component:<Documents employeeId={props.match.params.id} {...props} />},
                {name:'Health & Family',label:'Health & Family',component:<HealthFamily employeeId={props.match.params.id} />},
                {name:'KSA',label:'KSA', key: '8', component:<KSA employeeId={props.match.params.id} {...props}/>},
                {name:'Journal',label:'Employee Journal', key: '9', component:<Journal employeeId={props.match.params.id} {...props}/>},
            ]
        }
    }

    componentDidMount() {
        
        // if (!isEmpty(this.props.location.state)) {
        //     const employeeId = this.props.match.params.id;
        //     this.props.getProfileDetail(employeeId);
        // }
        const employeeId = this.props.match.params.id;
        this.props.getProfileDetail(employeeId);
    }

    fetchRequiredDataFromTab = (tabId) => {
        if (tabId === '3')
            this.getPlacementData();
        else if (tabId === '8')
            this.getKSAData();
    }

    getKSAData = async () => {
        const employeeId = this.props.match.params.id;
        await this.props.getEmployeeKsaRating(employeeId);
    }

    getPlacementData = async () => {
        if (!isEmpty(this.props.match.params)) {
            const employeeId = this.props.match.params.id;
            // initial assignable jobs to employee
            let data = {
                employeeId: 0,
                pageIndex: 0,
                pageSize: 10
            }
            //this.props.getJobDescriptionByLevel(data);
            // get assigned job description of employee
            this.props.getEmployeeJobDescription(employeeId);
            
            // history tab of reporting to tab
            // this.props.getEmployeeRolesHistory(7);
            this.props.GetDesignations();
        }
    }

    render() {
        const { 
            employeeProfile,
            isLoading,
            baseUrl
        } = this.props;
        const {tabs} = this.state;
        const titleName = employeeProfile ? employeeProfile.EmployeeProfileHeader ? employeeProfile.EmployeeProfileHeader.EmployeeName : 'Employee' : 'Employee';
        return (
            <>
            <div className="profile profile-bg">
            { employeeProfile ? (
                <div className="profile-container">
                    <div className="profile-title">
                        <Title isCustomBackLink customLink={{title:'Employee List', link:RouteEnum.EmployeeListPath}} name={titleName} />
                    </div>
                    
                    <ProfileInfo baseUrl={baseUrl} />
                    <div className="profile-tab">
                        <Tabs noBackground  activeTab="" >
                            {
                                tabs.map(tab=>{
                                    return(
                                        <Tab label={tab.label} name={tab.name} id={tab.name} onTabClick={() => this.fetchRequiredDataFromTab(tab.key)} >
                                            {
                                                tab.component ? tab.component :
                                                <span>
                                                    {tab.label}
                                                </span>
                                            }
                                        </Tab>
                                    )
                                })
                            }
                        </Tabs>
                    </div>
                    
                </div> ) : 
                    (isLoading && <Loader loading={isLoading} />)
            }
            </div>
        </>
        );
    }
}

const mapStateToProps = state => ({
    employeeProfile: state.employeeProfile.employeeProfile,
    isLoading: state.employeeProfile.isLoading,
    employeeKsaRating: state.employeeProfile.employeeKsaRating,
});

const mapDispatchToProps = {
    getProfileDetail,
    getEmployeeManagers,
    getEmployeeManagerHistory,
    getEmployeeRolesHistory,
    getJobDescriptionByLevel,
    getEmployeeJobDescription,
    GetDesignations,
    getEmployeeKsaRating
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));
