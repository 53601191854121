import React, { Component } from "react";
import FormGroup from "components/form";
import CustomDateRange from "components/customdaterange";
import isEmpty from "helpers/isEmpty";
import EmployeeSearch from "components/common/employeeSearch";

const loanTypes = [
  {
    label: "Home Loan",
    value: "PrimeHomeLoan",
  },
  {
    label: "Vehicle Loan",
    value: "PrimeVehicleLoan",
  },
  {
    label: "Staff OverDraft",
    value: "PrimeStaffODLoan",
  },
  {
    label: "Staff Personal Loan",
    value: "PrimeStaffPersonalLoan",
  },
];

const StatusType = [
  {
    label: "To Review",
    value: 1,
  },
  {
    label: "Forwared From Finance",
    value: 3,
  }
];
const StatusType2 = [
  {
    label: "Accepted",
    value: 4,
  },
  {
    label: "Rejected",
    value: 5,
  }
];
export class SearchAndFilter extends Component {
  render() {
    const {
      EmployeeName,
      LoanType,
      LoanStatus,
      isFinance,
      activeStageId,
    } = this.props.parentState;
    const { handleAutoSelect } = this.props;
    let statusOptions = StatusType;
    if (activeStageId == 2) {
      statusOptions = StatusType2
    }
    return (
      <div className="filters">
        {/* <EmployeeSearch
          onChange={handleAutoSelect}
          value={searchEmployee}
          label={false}
          name="searchEmployee"
          placeholder={"Search Employee"}
          // width="220px"
        /> */}
        <FormGroup
          onChange={handleAutoSelect}
          name="EmployeeName"
          label={false}
          value={EmployeeName}
          placeholder="Employee Name"
          width="200px"
        />
        <div className="filters-grid">
          <FormGroup
            onChange={handleAutoSelect}
            name="LoanType"
            value={LoanType}
            options={loanTypes}
            formName="reactselect"
            placeholder="Search By Loan Type"
          />
        </div>
        {activeStageId != 3 && !isFinance && (
          <div className="filters-grid">
            <FormGroup
              onChange={handleAutoSelect}
              name="LoanStatus"
              value={LoanStatus}
              options={statusOptions}
              formName="reactselect"
              placeholder="Search By Loan Status"
            />
          </div>
        )}
      </div>
    );
  }
}

export default SearchAndFilter;
