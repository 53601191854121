import {
    GET_ALLOWANCES
} from 'actions/types';
import axios from 'axios';
import {toastMessage} from 'actions/validateAction';
import isEmpty from 'isEmpty';
import { catchError } from "helpers";

export const getAllowanceRequest = (
    pageIndex,
    rowsPerPage,
    status,
    activeBranch,
    activeDepartment,
    allowanceType,
    employeeName,
    startDate,
    endDate
    ) =>async dispatch=>{
      const branchId = !isEmpty(activeBranch) ? activeBranch.value : -1;
      const departmentId = !isEmpty(activeDepartment) ? activeDepartment.value : -1;
      const statusId = !isEmpty(status) ? status : -1;
      const allowanceTypeId = !isEmpty(allowanceType) ? allowanceType.label : '';
      const sd = !isEmpty(startDate) ? startDate : null;
      const ed = !isEmpty(endDate) ? endDate : null;
      const em = !isEmpty(employeeName) ? employeeName : '';
      
    await axios.get(`v1/payroll/allowances/admin-view?PageIndex=${pageIndex}&PageSize=${rowsPerPage}&EmployeeName=${em}&RequestEndDate=${ed}&RequestStartDate=${sd}&OverTime=${allowanceTypeId}&Department=${departmentId}&Status=${statusId}&Branch=${branchId}`).then(response => {
      if (response.data.Status) {
        dispatch({
          type: GET_ALLOWANCES,
          payload: response.data.Data
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch(err => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message?err.message:"Failed to get allowance requests"
        }
      };
      toastMessage(dispatch, response);
    });
}


export const postToSalary = (ids,refreshFunc) => async dispatch =>{
  // RequestId
  await axios
  .post(`/v1/payroll/allowances/post-to-salary`, ids)
  .then((resp) => {
    const response = resp.data;
    if(response.Status){
      refreshFunc()
    }
    
  })
  .catch((err) => {
    catchError(dispatch, err);
  });
}


export const rejectAllowance = (id,refreshFunc) =>async dispatch=>{
  await axios.put(`/v1/payroll/allowances/reject${id}`)
  .then((resp) => {
    const response = resp.data;
    if(response.Status){
      refreshFunc()
    }else{
      toastMessage(dispatch, response);
    }
    
  })
  .catch((err) => {
    catchError(dispatch, err);
  });
}