import classnames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

export class CommonTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpand: true,
    };
  }

  render() {
    const { isExpand } = this.state;
    const {
      MyTotalRows,
      TotalRows,
      TotalSettlementRows,
      PendingSettlementRows,
      SettlementHistoryRows
    } = this.props.travelListCount;
    const tabContent = [
      {
        id: 0,
        num: "1",
        title: "Travel Requests",
        type: "success",
        link: "/travel_request",
        count: TotalRows,
      },
      {
        id: 3,
        num: "2",
        title: "Settlement Requests",
        type: "primary",
        link: "/settlement_request",
        count: TotalSettlementRows,
      },
      {
        id: 4,
        num: "3",
        title: "Pending Settlement",
        type: "success",
        link: "/pending_settlement",
        count: PendingSettlementRows,
      },
      {
        id: 1,
        num: "4",
        title: "My Requests",
        type: "success",
        link: "/my_travel_request",
        count: MyTotalRows,
      },
      {
        id: 2,
        num: "5",
        title: "History",
        type: "primary",
        link: "/travel_history",
        count: SettlementHistoryRows,
      },
    ];
    const sideClass = classnames({
      "payroll-sidetab": true,
      "sidetab-expand": isExpand === true,
      "sidetab-collapse": isExpand === false,
    });
    return (
      <div className="common-travel-tab">
        <div className="toptab">
          {tabContent.map((tab, key) => {
            return <Tab key={key} data={tab} match={this.props.match} />;
          })}
          <div className="unallocated-space"></div>
        </div>
      </div>
    );
  }
}

const Tab = ({ data, match }) => {
  const tabClass = classnames({
    "toptab-item": true,
    flex: true,
    [data.type]: data.type,
  });
  return (
    <NavLink
      to={{
        pathname: match.path + data.link,
        state: { selectedTabId: data.id },
      }}
      className={tabClass}
      activeClassName="selected"
    >
      <div className="toptab-contain">
        <div className="toptab-content">
          <h2 className="toptab-title">{data.title}</h2>
        </div>
      </div>
      {parseInt(data.num) < 5 && <span className="travel-list-count">{data.count}</span>}
    </NavLink>
  );
};

const mapStateToProps = (state) => ({
  travelListCount: state.travelInformationReducer.travelListCount,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CommonTabs);
