import React from 'react';
import { useSelector } from 'react-redux';

function Pending(props) {
    const {pending} = props;
    const payrollDashboard = useSelector(state => state.payrollDashboardReducer.payrollDashboard );
    const {PendingRequest}  = payrollDashboard;
    return (
        <div className="pending">
            {
                payrollDashboard && PendingRequest?(
                    <ul className="pending-list">
                        {
                            PendingRequest.map((pen,index)=>(
                                <li key={index + Math.random()} className="pending-list__item">
                                    <span className="pending-name">{pen.Title}</span>
                                    <span className="pending-value">{pen.Total}</span>
                                </li>
                            ))
                        }
                    </ul>
                ):
                <span className="no-found">No Pending Found</span>
            }
        </div>
    )
}

export default Pending
