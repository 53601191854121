import React, { useState } from 'react'
import ScheduleFilter from './scheduleFilter';
import ScheduleSidebar from './scheduleSidebar';
import ScheduleList from './scheduleList';
import {
    getRosterScheduleList,
    getRosterScheduleEmployeeList,
    getRosterShitsDropdownList,
    getRosterTeamEmployee,
    getRosterTeamRoleList,
    getRosterScheduleAllEmployeeList,
    getRosterScheduleEmployeeListWithWorkingHour,
    addRosterScheduleEmployee,
    updateRosterSchedule
} from '../../helpers/action'
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { GiTeamIdea } from 'react-icons/gi';
import { getDateRange } from '../../helpers/roosterDates'
import { DragDropContext } from "react-beautiful-dnd";


const intialState = {
    teamId: null,
    // startDate:'2021-09-12',
    // endDate:'2021-09-18',
    startDate: null,
    endDate: null,
    dates: null,
    dateIndex: 0,
}

function Schedules(props) {
    const [state, setState] = useState(intialState)
    const dispatch = useDispatch();
    const { teamList, teamRoleList, scheduleList } = useSelector((state: any) => state.roosterReducer);
    const onChange = (name, value) => {
        if (name === 'dates' || name === 'dateIndex') {
            if (value || value === 0) {
                const {
                    startDate,
                    endDate
                } = getDateRange(name === 'dates' ? value : state?.dates, name === 'dateIndex' ? value : state?.dateIndex)
                console.log({ startDate, endDate })
                const stDate = startDate ? dayjs(startDate).format("YYYY-MM-DD") : "";
                const edDate = endDate ? dayjs(endDate).format("YYYY-MM-DD") : "";
                setState(prev => ({
                    ...prev,
                    [name]: value,
                    startDate: stDate,
                    endDate: edDate
                }))
            } else {
                setState(prev => ({
                    ...prev,
                    [name]: value,
                    startDate: null,
                    endDate: null
                }))
            }

            return;
        }
        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }
    const getRosterSchedule = async () => {
        const param = {
            startDate: state.startDate,
            endDate: state.endDate,
            teamId: state.teamId ? state.teamId.value : null
        }
        const promiseArray = [
            dispatch(getRosterScheduleList(param)),
            dispatch(getRosterScheduleEmployeeList(param)),
        ]
        await Promise.all(promiseArray);
        if (state.teamId) {
            const teamParam = {
                teamId: state.teamId.value
            }
            dispatch(getRosterShitsDropdownList(teamParam.teamId))
            dispatch(getRosterTeamEmployee(teamParam))
            // dispatch(getRosterScheduleAllEmployeeList(teamParam)){
            if (param.startDate && param.endDate && param.teamId){
                dispatch(getRosterScheduleEmployeeListWithWorkingHour(param))
                dispatch(getRosterScheduleAllEmployeeList(teamParam))
            }
            dispatch(getRosterTeamRoleList(teamParam.teamId))
        }
    }
    React.useEffect(() => {
        (async () => await getRosterSchedule())();
        props.onTeamSet(state)
    }, [state.teamId, state.startDate, state.endDate]);
    const isPrev = state.dateIndex > 0;
    const onDragEnd = async (result) => {
        if (isPrev) {
            return;
        }
        if (!result.destination) {
            return;
        }
        if (result.destination.droppableId === result.source.droppableId) {
            return;
        }
        if (result.destination.droppableId === -1) {
            return;
        }
        const [newShiftScheduleId, nextRemainingPosition] = result?.destination?.droppableId?.split(",");
        if (result?.draggableId === "isOpen") {
            const findSchedule = scheduleList && scheduleList.find(item => item?.ShiftScheduleId === newShiftScheduleId);
            const finalData = {
                shiftId: findSchedule?.ShiftId,
                scheduleDate: findSchedule?.ScheduleDate,
                teamId: state.teamId ? state.teamId.value : null,
                enableAllowance: findSchedule?.EnableAllowance,
                enableSwapping: findSchedule?.EnableSwapping,
                positions: findSchedule?.Positions ? findSchedule?.Positions + 1 : 1,
                id: findSchedule?.ShiftScheduleId
            }
            const res: any = await dispatch(updateRosterSchedule(finalData));
            if (res && res?.Status === true) {
                const param = {
                    startDate: state.startDate,
                    endDate: state.endDate,
                    teamId: state.teamId ? state.teamId.value : null
                }
                dispatch(getRosterScheduleList(param))
            }
            return;
        }
        const [
            ShiftScheduleEmployeeId,
            EmployeeId,
            ShiftRotationStartDay,
            TeamRoleId,
            CurrentShiftScheduleId,
            remainingPosition
        ] = result?.draggableId?.split(",")
        const data = {
            // "id": 0,
            "ShiftScheduleId": newShiftScheduleId,
            "EmployeeId": parseInt(EmployeeId),
            "TeamRoleId": parseInt(TeamRoleId),
            "ShiftRotationStartDay": ShiftRotationStartDay
        }
        const stDate = state.startDate ? dayjs(state.startDate).format("YYYY-MM-DD") : "";
        const edDate = state.endDate ? dayjs(state.endDate).format("YYYY-MM-DD") : "";
        if (parseInt(nextRemainingPosition) === 0) {
            return;
        }
        const res: any = await dispatch(addRosterScheduleEmployee(data, stDate, edDate))
    }

    return (
        <DragDropContext onDragEnd={onDragEnd} >
            <div className="schedule">
                <ScheduleFilter onChange={onChange} filter={state} />
                <div className="schedule-contain">
                    <ScheduleSidebar
                        isPrev={isPrev}
                        teamId={state.teamId ? state.teamId.value : null} />
                    <ScheduleList
                        teamId={state.teamId}
                        isPrev={isPrev}
                        startDate={state.startDate}
                        endDate={state.endDate} />
                </div>
            </div>
        </DragDropContext>
    )
}

export default Schedules