import React, { useEffect, useState } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "react-avatar";
import { FaPencilAlt, FaPaperclip } from "react-icons/fa";
import { MdSchedule, MdMessage } from "react-icons/md";
import classNames from "classnames";
import isEmpty from "helpers/isEmpty";
import { taskUpdateDetails } from "scenes/tasks/helpers/action";
import FormGroup from "components/form";
import { connect } from "react-redux";
import { TmTaskPriorityEnum, TmViewType } from "scenes/tasks/helpers/enum";
import { formatedShortMonthDayYear } from "helpers/dateFormat";
import moment from "moment";
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const Task = (props) => {
  const { task, progress, onTaskClicked, rowIndex, stage, auth } = props;
  const base =
    auth && auth.baseUrl ? props.auth.baseUrl.NetFrameworkAppBaseUrl : "" || "";

  const taskBoardLabels = (task && task.TaskBoardLabels) || [];
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [editTaskName, setEditTaskName] = useState(false);
  const [taskName, setTaskName] = useState("");
  useEffect(() => {
    if (task && task.TeamMembers) {
      setSelectedMembers(task.TeamMembers);
    }
  }, [task]);

  const updateTaskName = async (val) => {
    if (!isEmpty(val) && val !== task.TaskName) {
      const updateTask = { ...task };
      updateTask.TaskName = val;

      await props.taskUpdateDetails({
        viewType: TmViewType.TableView,
        task: updateTask,
        stage,
        rowIndex,
      });
    }
    setEditTaskName(false);
  };
  const getClassName = (priority: TmTaskPriorityEnum) => {
    switch (priority) {
      case TmTaskPriorityEnum.High:
        return "board-task__priority task-priority-high";
      case TmTaskPriorityEnum.Medium:
        return " board-task__priority task-priority-medium";
      case TmTaskPriorityEnum.Low:
        return " board-task__priority task-priority-low";
      default:
        return "board-task__priority task-priority-none";
    }
  };
  const getDueDate = () => {
    return task.DueDate && (` (${(moment(new Date(task.DueDate)).format("MMM DD"))})`)
  }
  return (
    <div
      className="board-task"
      draggable={true}
      key={rowIndex}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onTaskClicked && onTaskClicked(task, stage, rowIndex);
      }}
    >
      <div className="board-task__block">
        <div className="board-task__header">
          <div
            className={getClassName(task.Priority)}
            title={task.PriorityText}
          ></div>
          <div className="board-task__title">
            {editTaskName ? (
              <FormGroup
                autoComplete="off"
                autoFocus
                value={taskName}
                name="taskName"
                onBlur={(name, value) => updateTaskName(value)}
                onChange={(name, value) => setTaskName(value)}
                onKeyUp={(e) => {
                  e.key === "Enter" && updateTaskName(e.target.value);
                }}
              />
            ) : (
              <>
                <h4>{task.TaskName}</h4>
                <div
                  className="board-task__edit"
                  onClick={(e) => {
                    e.stopPropagation();
                    setTaskName(task.TaskName);
                    setEditTaskName(!editTaskName);
                  }}
                >
                  <FaPencilAlt size={12} />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="board-task__section">
          {!isEmpty(task.DueDate) && (
            <div
              className={classNames("board-task__date", {
                overdue: task.IsOverDue,
              })}
            >
              <MdSchedule />
              <span>{formatedShortMonthDayYear(task.DueDate)}</span>
            </div>
          )}
          {console.log(task)}
          {!isEmpty(task.DueDays) && (
            <div className="board-task__day">
              <span>{task.DueDays}</span>
            </div>
          )}

          {task.TotalAttachments > 0 && (
            <div className="board-task__info">
              <FaPaperclip />
              <span>{task.TotalAttachments}</span>
            </div>
          )}
          {task.TotalComments > 0 && (
            <div className="board-task__info">
              <MdMessage />
              <span>{task.TotalComments}</span>
            </div>
          )}
        </div>

        <div className="board-task__label-teams">
          <div className="board-task__label">
            {taskBoardLabels.map((label, i) => (
              <div
                className="board-task__label-title"
                title={label.LabelName}
                key={i}
                style={label.Color ? { backgroundColor: label.Color } : {}}
              >
                {label.LabelName}
              </div>
            ))}
          </div>

          <div className="board-task__team">
            {selectedMembers &&
              selectedMembers.map((member, index) => (
                <Avatar
                  key={index}
                  className="board-task__team-avatar"
                  name={member.FullName}
                  src={`${base}${member.Imagepath}`}
                  size={"24"}
                  round={true}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({ auth: state.auth });
const mapDispatchToProps = {
  taskUpdateDetails,
};
export default connect(mapStateToProps, mapDispatchToProps)(Task);
