import React, {useState, useEffect} from "react";
import shortid from "shortid";
import "./App.scss";
import isEmpty from "helpers/isEmpty";
import {AuthorityList, StepList} from "../../helpers/enums";
import Button from "components/button";
import {postSbiSettingApprovalFlow} from "../../helpers/action";
import {useDispatch} from "react-redux";
import Table from "components/customTable";
import ButtonCell from "./buttonProps";
import Icons from "components/icons";

const ApprovalFlowTable = (param) => {
  const [rowData, setRowData] = useState([]);
  const [deletedValue, setDeletedValue] = useState([]);
  const {approvalFlowData, loanType, flowType} = param;

  const showButton = (props) => {
    return (
      <ButtonCell
        allValues={props.row.values}
        rowData={rowData}
        setRowData={setRowData}
      />
    );
  };

  const dispatch = useDispatch();

  const beforecolumns = [
    {
      Header: "Step",
      accessor: "StepId",
      width: 150,
      isEditable: true,
      editableType: "select",
      selectOptions: StepList,
      isFirstRowDisabled: true,
    },
    {
      Header: "Status Display Name",
      accessor: "StepName",
      width: 180,
      isEditable: true,
    },
    {
      Header: "Authority",
      accessor: "AuthorityType",
      width: 200,
      minWidth: 100,
      maxWidth: 500,
      isEditable: true,
      editableType: "select",
      selectOptions: AuthorityList,
      isFirstRowDisabled: true,
    },
    {
      accessor: "AuthorityTypeDisplayName",
      Header: "Commenter Display Name",
      width: 180,
      isEditable: true,
    },
    {
      accessor: "MultipleSpecificPersonName",
      Header: "Multiple Specific Person",
      width: 180,
      // Cell: ({row}) => showButton({row, rowData, setRowData}),
      Cell: showButton,
    },
    {
      Header: "Action",
      id: "id",
      width: 60,
      accessor: (str) => "delete",

      Cell: (tableProps) => (
        <>
          {tableProps.row.index !== 0 && (
            <span
              onClick={() => {
                const dataCopy = [...rowData];
                dataCopy.splice(tableProps.row.index, 1);
                setRowData(dataCopy);
              }}
              className="pointers"
            >
              <Icons name="Trash" />
            </span>
          )}
        </>
      ),
    },
  ];

  const handleSave = () => {
    let newValue = rowData?.filter(function (cv) {
      return !deletedValue?.find(function (e) {
        return e.id == cv.id;
      });
    });

    let validationData = newValue.map((values, index) => {
      return {
        sequenceNo: parseInt(values?.StepId),
        stepId: values?.StepId,
        stepName: values?.StepName,
        authorityType: values?.AuthorityType,
        authorityTypeDisplayName: values?.AuthorityTypeDisplayName,
        multipleSpecificPerson: values?.MultipleSpecificPerson,
      };
    });

    let allValue = {flowType: flowType, list: validationData};

    dispatch(postSbiSettingApprovalFlow(loanType, allValue));
  };

  const updateMyData = (data) => {
    console.log({data}, "updateMyData");
  };

  const onSave = (data) => {
    setRowData((prev) => prev.map((item) => (data.id === item.id ? data : item)));
  };

  const addRow = () => {
    let id = shortid.generate();
    let allValues = rowData.concat({
      AuthorityType: 0,
      AuthorityTypeDisplayName: null,
      MultipleSpecificPerson: null,
      SequenceNo: 0,
      StepId: 0,
      StepName: null,
      id: id,
    });
    setRowData(allValues);
  };

  useEffect(() => {
    if (!isEmpty(approvalFlowData)) {
      let newData = approvalFlowData.map((values) => {
        let id = shortid.generate();
        return {
          ...values,
          id: id,
        };
      });
      setRowData(newData);
    } else {
      setRowData([]);
    }
  }, [approvalFlowData]);

  return (
    <>
      <div className="approval-table">
        <Table
          isFixedWidth
          isEditable
          onSaveData={onSave}
          columns={beforecolumns}
          updateMyData={updateMyData}
          data={rowData}
        />
      </div>
      <Button bg="success" title="Add Row" onClick={() => addRow()} />
      <Button bg="primary" title="Save" onClick={() => handleSave()} />
    </>
  );
};

export default ApprovalFlowTable;
