import React, { Component } from 'react'
import {MdKeyboardArrowDown} from 'react-icons/md'
import RouteEnum from 'constants/routeEnum'
import Button from 'components/button'

export class EmpDropDown extends Component {
    constructor(props) {
    super(props);

        this.state = {
            drop: false,
        };
        this.dropdown = "";
        this.td="";
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }
    componentDidUpdate(prevProps){
        if(this.td){
            if(this.td.parentElement.classList){
                this.td.parentElement.classList = []
            }
        }
    }
    handleClickOutside(event) {
        if (this.dropdown && !this.dropdown.contains(event.target)) {
            this.setState({ drop: false });
        }
    }
    onDrop(id, event) {
        this.setState({ drop: !this.state.drop });
    }
    
    render() {
        let style = {};
        if (this.state.drop) {
          if (this.dropdown) {
            const {
              top,
              left,
              height,
              right
            } = this.dropdown.getBoundingClientRect();
            style = {
              position: "fixed",
              top: top + height + 10,
              left: left - 75,
              width: "12rem"
            };
          }
        }
        const props = this.props;
        return (
            <td
                className="e-custom-row"
                style={{ overflow: "visible" }}
                role="gridcell"
                ref={td=>this.td=td}
            >
              <Button 
                onClick={() => {
                  //   this.goToEmployeeProfile.bind(this, props)
                    props.push({
                      pathname: RouteEnum.EmployeeProfile + `/${props.EmployeeId }`,
                      state: { employeeId: props.EmployeeId }
                    });
                  }}
                  title="Profile"
                  buttonType="drop-button"
                  bg="white"
                  dropComponent={
                    <ul className="button-drop-list">
                        <li className="button-drop-list__item">
                          <span className="button-drop-name">
                            Pay and Compensation
                          </span>
                        </li>
                        <li className="button-drop-list__item with-hr">
                          <span className="button-drop-name">Leave and Time</span>
                        </li>
                        <li className="button-drop-list__item with-hr">
                          <span className="button-drop-name">Delete</span>
                        </li>
                      </ul>
                  }
              
              />
            {/* <div
                className={`profile profile-drop ${
                this.state.drop ? "active" : ""
                }`}
            >
            <span style={{"cursor": "pointer"}}
              onClick={() => {
                    //   this.goToEmployeeProfile.bind(this, props)
                      props.push({
                        pathname: EmployeeProfile,
                        state: { employeeId: props.EmployeeId }
                      });
                    }
                }
            >
              Profile
              </span>
            <div
              className="profile-down-caret profile-icon"
              ref={dropdown => (this.dropdown = dropdown)}
              onClick={this.onDrop.bind(this, props.EmployeeId)}
            >
              <MdKeyboardArrowDown />
            </div>
            <div
              id={props.EmployeeId}
            //   style={style}
              className="profile-drop__content"
            >
              <ul className="profile-drop-list">
                <li className="profile-drop-list__item">
                  <span className="profile-drop-name">
                    Pay and Compensation
                  </span>
                </li>
                <li className="profile-drop-list__item with-hr">
                  <span className="profile-drop-name">Leave and Time</span>
                </li>
                <li className="profile-drop-list__item with-hr">
                  <span className="profile-drop-name">Delete</span>
                </li>
              </ul>
            </div>
          </div> */}
            </td>
            
        )
    }
}

export default EmpDropDown
