import classnames from "classnames";
import FormGroup from "components/form";
import isEmpty from "isEmpty";
import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { inputWidth } from "./constants";
import { validateReferences } from "./validation";

function References({
  data,
  isMandatory,
  onChangeList,
  onNewList,
  onConfirmList,
  onRemoveList,
  onEditList,
  onDeleteList,
  blockError,
}) {
  const listName = "references";
  const title = "reference";

  const [errors, setErrors] = useState(null);

  let selectedReference = data.find((f) => f.isSelected);
  let unselectedReference = data.filter((f) => !f.isSelected);

  const inputclass = (error) => {
    return classnames({
      "form-group": true,
      "inline-form-group": true,
      inputWidth: inputWidth,
      error: error,
    });
  };

  const onTextChange = (name,value) => {
    //const { name, value } = e.target;
    onChangeList(listName, selectedReference.key, title, name, value);
    if (!isEmpty(errors)) {
      checkValidation();
    }
  };

  const handleNew = () => {
    const newKey = data.length + 1;
    onNewList(listName, title, newKey);
    setErrors(null);
  };

  const handleConfirmList = async () => {
    const isValidate = await checkValidation();
    if (!isMandatory || isValidate) {
      onConfirmList(listName, selectedReference.key);
    }
  };

  const checkValidation = async () => {
    if (isMandatory) {
      let returnedData = await validateReferences(selectedReference.reference);
      setErrors(returnedData);
      if (isEmpty(returnedData)) {
        return true;
      } else {
        return false;
      }
    }
  };

  const renderCreateForm = () => {
    if (!isEmpty(selectedReference)) {
      const {
        name,
        organization,
        position,
        phone,
        email,
      } = selectedReference.reference;

      return (
        <>
          <div className="form-block__form">
            <FormGroup
              name="name"
              value={name}
              label="Name"
              onChange={onTextChange}
              className="inline-form-group"
              error={errors && errors.name}
            />
            <FormGroup
              name="organization"
              value={organization}
              label="Organization"
              onChange={onTextChange}
              className="inline-form-group"
              error={errors && errors.organization}
            />
            <FormGroup
              name="position"
              value={position}
              label="Position"
              onChange={onTextChange}
              className="inline-form-group"
              error={errors && errors.position}
            />
            <FormGroup
              name="phone"
              value={phone}
              label="Phone"
              onChange={onTextChange}
              className="inline-form-group"
              error={errors && errors.phone}
            />
            <FormGroup
              name="email"
              value={email}
              label="E-mail"
              onChange={onTextChange}
              className="inline-form-group"
              error={errors && errors.email}
            />
          </div>
          <div className="detail-buttons">
            <button
              className="btn btn"
              onClick={() => onRemoveList(listName, selectedReference.key)}
            >
              Cancel
            </button>
            <button
              className="btn btn-blue"
              onClick={() => handleConfirmList()}
            >
              Confirm
            </button>
          </div>
        </>
      );
    }
  };

  let blockRequiredText = blockError;
  if (data && data.length > 0) {
    blockRequiredText = "";
  }

  return (
    <div className="form-block">
      <div className="form-block__title title__add">
        <h3>References</h3>
        {isEmpty(selectedReference) ? (
          <div className="title__add-button__position">
            <button
              className="btn-outline btn-outline__black"
              onClick={() => handleNew()}
            >
              Add
            </button>
          </div>
        ) : null}
        <div className={inputclass(blockRequiredText)}>
          <div className="error__message">{blockRequiredText}</div>
        </div>
      </div>

      {renderCreateForm()}

      {unselectedReference &&
        unselectedReference.map((data, index) => {
          const reference = data.reference;
          return (
            <div className="form-block detail-block" key={index}>
              <div className="detail-left">
                <p className="detail-dark__text">{reference.name}</p>
                <p className="detail-light__text">{reference.position}</p>
                <p className="detail-light__text">{reference.organization}</p>
              </div>
              <div className="detail-mid">
                <p className="detail-dark__text">{reference.phone}</p>
                <p className="detail-light__text">{reference.email}</p>
              </div>

              <div className="detail-right">
                <span
                  onClick={() => onEditList(listName, data.key)}
                  className="change-btn btn-edit"
                >
                  <MdEdit />
                </span>
                <span
                  onClick={() => onDeleteList(listName, data.key)}
                  className="change-btn btn-delete"
                >
                  <FaTrash />
                </span>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default References;
