export const GET_DASHBOARD_DETAIL = "GET_DASHBOARD_DETAIL";
export const GET_JOB_STAGES = "GET_JOB_STAGES";
export const GET_APPLIED_CANDIDATE_LIST_BY_JOBID =
  "GET_APPLIED_CANDIDATE_LIST_BY_JOBID";
export const GET_CANDIDATE_LIST_BY_JOB_AND_STAGE_ID =
  "GET_CANDIDATE_LIST_BY_JOB_AND_STAGE_ID";
export const GET_CANDIDATE_DETAIL = "GET_CANDIDATE_DETAIL";
export const GET_CANDIDATE_DETAIL_LOADING = "GET_CANDIDATE_DETAIL_LOADING";
export const GET_MILESTONE_DETAIL = "GET_MILESTONE_DETAIL";
export const GET_MILESTONE_DETAIL_LOADING = "GET_MILESTONE_DETAIL_LOADING";
export const CHANGE_CANDIDATE_STAGE = "CHANGE_CANDIDATE_STAGE";
export const GET_REQUISITION_NUMBER = "GET_REQUISITION_NUMBER";
export const GET_MY_REQUISITION = "GET_MY_REQUISITION";
export const GET_REQUISITION_DETAILS = "GET_REQUISITION_DETAILS";
export const GET_APPROVER_REQUISITION = "GET_APPROVER_REQUISITION";
export const IS_REQUISITION_DETAILS_LOADING = "IS_REQUISITION_DETAILS_LOADING";
export const SAVE_JOB_REQUISITION = "SAVE_JOB_REQUISITION";
export const SAVE_JOB_APPLY = "SAVE_JOB_APPLY";
export const GET_JOB_DETAIL_BY_ID = "GET_JOB_DETAIL_BY_ID";
export const GET_ACTIVE_JOBS = "GET_ACTIVE_JOBS";

export const GET_REQUISITION_LIST_COUNT = "GET_REQUISITION_LIST_COUNT";
export const CANCEL_JOB_REQUISITION = "CANCEL_JOB_REQUISITION";
export const GET_APPROVED_REQUISITION = "GET_APPROVED_REQUISITION";
export const APPROVED_REQUISITION_LOADING = "APPROVED_REQUISITION_LOADING";
export const GET_CANDIDATE_DETAILS_BY_JOB_ID =
  "GET_CANDIDATE_DETAILS_BY_JOB_ID";
export const EXPORT_CANDIDATE_DETAILS_BY_JOB_ID = "EXPORT_CANDIDATE_DETAILS_BY_JOB_ID"
export const CANDIDATE_DETAILS_LOADING = "CANDIDATE_DETAILS_LOADING";
export const UPDATE_CANDIDATE_DETAILS = "UPDATE_CANDIDATE_DETAILS";
export const CURRENT_CANDIDATE_DETAIL = "CURRENT_CANDIDATE_DETAIL";
export const CURRENT_CANDIDATE_DETAIL_LOADING =
  "CURRENT_CANDIDATE_DETAIL_LOADING";
export const UPDATE_CANDIDATE_DETAILS_FAV = "UPDATE_CANDIDATE_DETAILS_FAV";
export const GET_HIRING_STAGES_BY_JOB_ID = "GET_HIRING_STAGES_BY_JOB_ID";
export const HIRING_STAGES_BY_JOB_ID_LOADING =
  "HIRING_STAGES_BY_JOB_ID_LOADING";

export const GET_HIRING_STAGE_AND_SUBSTAGE_BY_JOB_ID =
  "GET_HIRING_STAGE_AND_SUBSTAGE_BY_JOB_ID";
export const GET_HIRING_STAGE_AND_SUBSTAGE_BY_JOB_ID_LOADING =
  "GET_HIRING_STAGE_AND_SUBSTAGE_BY_JOB_ID_LOADING";

export const UPDATE_TAGS = "UPDATE_TAGS";

//#region Job
export const GET_HIRING_TEAMS = "GET_HIRING_TEAMS";
export const GET_HIRING_TEAMS_LOADING = "GET_HIRING_TEAMS_LOADING";
export const GET_JOB_HIRING_SUBSTAGES = "GET_JOB_HIRING_SUBSTAGES";
export const GET_JOB_HIRING_SUBSTAGES_LOADING =
  "GET_JOB_HIRING_SUBSTAGES_LOADING";
//#endregion

//#region Report
export const GET_APPLICANTS = "GET_APPLICANTS";
export const GET_APPLICANTS_LOADING = "GET_APPLICANTS_LOADING";
export const GET_EXPORT_APPLICANTS = "GET_EXPORT_APPLICANTS";
//#endregion

export const GET_EMAIL_TEMPLATE = "GET_EMAIL_TEMPLATE";
export const GET_CANDIDATE_EMAILS = "GET_CANDIDATE_EMAILS";
export const GET_CANDIDATE_COMMENTS = "GET_CANDIDATE_COMMENTS";

//#region Reasons
export const GET_REJECT_REASONS = "GET_REJECT_REASONS";
export const GET_REJECT_REASONS_LOADING = "GET_REJECT_REASONS_LOADING";
export const SAVE_CANDIDATE_REJECT_LOADING = "SAVE_CANDIDATE_REJECT_LOADING";
//#endregion

//#region  Interview
export const GET_INTERVIEW_MEDIUM = "GET_INTERVIEW_MEDIUM";
export const GET_INTERVIEW_MEDIUM_LOADING = "GET_INTERVIEW_MEDIUM_LOADING";
export const SEND_INTERVIEW_INVITATION_LOADING =
  "SEND_INTERVIEW_INVITATION_LOADING";

export const GET_SCHEDULED_INTERVIEW = "GET_SCHEDULED_INTERVIEW";
export const GET_SCHEDULED_INTERVIEW_LOADING =
  "GET_SCHEDULED_INTERVIEW_LOADING";
//#endregion

//#region
export const PROFILEVIEW_CANDIDATE_DETAILS = "PROFILEVIEW_CANDIDATE_DETAILS";
//#endregion

// new dashboard for recruitment
export const GET_DASHBOARD_INFO = "GET_DASHBOARD_INFO";
export const UPDATE_CANDIDATE_REJECTION = "UPDATE_CANDIDATE_REJECTION";
