import React, { useState, useEffect } from "react";
import { Collapse, Tab, Tabs } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { AiOutlineMail } from "react-icons/ai";
import {
  FaCheck,
  FaRegCalendarAlt,
  FaRegCommentDots,
  FaStar,
} from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { MdRateReview } from "react-icons/md";

import { getBaseAxios } from "helpers/constants";
import getFullStringUrl from "helpers/getFullStringUrl";
import isEmpty from "isEmpty";
import ImageViewer from "components/imageViewer";
import Modal from "components/modal";
import Avatar from "react-avatar";
import { Apis } from "src/config/api";
import ComposeEmail from "scenes/common/email/compose";
import Comment from "./comment";
import SendComment from "./comment/send";
import Email from "./email";
import Milestone from "./milestone";
import Profile from "./profile";
import ScheduleInterview from "./scheduleInterview";
import { GetScheduledInterviewTemplate } from "store/actions/recruitment/interview";
import { connect } from "react-redux";
import {
  ComposeEmailType,
  JobStageList,
  JobStages,
} from "scenes/recruit/enums";

const tabList = [
  { label: "Profile", value: 0 },
  { label: "Email", value: 1 },
  { label: "Comment", value: 2 },
];

//#region  Material UI
const StyledTabs = withStyles({
  root: {
    borderBottom: "2px solid #ebebeb",
    minHeight: 43,
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > div": {
      maxWidth: 85,
      width: "100%",
      backgroundColor: "#006df9",
    },
  },
})((props: any) => (
  <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />
));

const StyledTab = withStyles((theme: any) => ({
  root: {
    textTransform: "none",
    color: "#343434",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    transition: "all .2s linear",
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
    },
    "&:hover": {
      color: "#666",
    },
  },
}))((props: any) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme: any) => ({
  expand: {
    width: "100%",
    height: "100%",
  },
  expandIcon: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    size: 10,
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandIcon_Open: {
    transform: "rotate(180deg)",
  },
}));
//#endregion

function WholeInfo(props: any) {
  const {
    jobStageList,
    selectedCandidate,
    candidateDetail,
    milestoneDetail,
    onChangeJobStage,
    addRemoveTags,
    handleRejectClick,
  } = props;

  const { CandidateProfile } = props.candidateDetail || {};

  const classes = useStyles();
  const [tabId, setTabId] = useState(0);
  const [expanded, setExpanded] = useState(true);
  const [showComposeModal, setComposeModal] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState(null);
  const [showCommentModal, setCommentModal] = useState(false);
  const [scheduleModal, setScheduleModal] = useState(false);
  const [dowloadLinkURL, setDowloadLinkURL] = useState("");
  const [base, setBase] = useState("");
  const [previewOriginalImage, setPreviewOriginalImage] = useState(false);
  const [originalImageFile, setOriginalImageFile] = useState<any>({});
  const [emailType, setEmailType] = useState(ComposeEmailType.recruitment);

  useEffect(() => {
    if (!isEmpty(CandidateProfile)) {
      const param = {
        serverFileName: CandidateProfile.ImageName,
        userFileName: CandidateProfile.ImageOriginalName,
        fileType: CandidateProfile.FileType,
        fileLocation: CandidateProfile.UploadLocation,
      };

      setOriginalImageFile({
        UserFileName: param.userFileName,
        ServerFileName: param.serverFileName,
        FileLocation: param.fileLocation,
        FileType: param.fileType,
      });

      const url = getFullStringUrl(
        getBaseAxios() + Apis.common.downloadFiles,
        param
      );
      setDowloadLinkURL(url);
    }
  }, [CandidateProfile]);

  useEffect(() => {
    let url = (props.baseUrl && props.baseUrl.NetFrameworkAppBaseUrl) || "";
    setBase(url);
  }, [props.baseUrl]);
  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };
  if (isEmpty(CandidateProfile)) return null;

  const handleTabChange = (event, value) => {
    setTabId(value);
  };

  const imageUrl =
    base +
    CandidateProfile.UploadLocation +
    CandidateProfile.ThumbnailCroppedImage;

  const selectedTags = (tags) => {
    const newTagds = tags.join(",");
    addRemoveTags({
      candidateId: selectedCandidate.CandidateId,
      tag: newTagds,
    });
  };

  const handleEmailClicked = async (obj) => {
    let res = await props.GetScheduledInterviewTemplate(obj.ScheduleId);
    //shceduleId is required to send candidate scheduled interview
    res["ScheduleId"] = obj.ScheduleId;
    setEmailType(ComposeEmailType.scheduleInterview);
    setEmailTemplate(res);
    setComposeModal(true);
  };

  return (
    <div>
      <div className="information-wholeInfo-applicant">
        <div className="information-wholeInfo-applicant-left">
          <div onClick={() => setPreviewOriginalImage(true)} className="">
            <Avatar
              className="user-avatar"
              name={selectedCandidate.CandidateName}
              size="60"
              round={true}
              src={imageUrl}
            />
          </div>
          <div className="information-wholeInfo-applicant-left-desc">
            <div className="flex">
              <span className="fonts-dark_larger">
                {selectedCandidate.CandidateName}

              </span>
              {CandidateProfile.IsMultipleApplied ? (
                <FiUsers
                  style={{ color: "#f26363", fontSize: 20, marginLeft: 6 }}
                />
              ) : null}
              {CandidateProfile.IsFavourite ? (
                <FaStar
                  style={{ color: "#006df9", fontSize: 20, marginLeft: 6 }}
                />
              ) : null}
              {CandidateProfile.IsShortListed ? (
                <FaCheck
                  style={{ color: "#006df9", fontSize: 20, marginLeft: 6 }}
                />
              ) : null}
            </div>

            <span
              className="fonts-dark_medium"
              style={{ fontSize: 15, color: "#333" }}
            >
              {selectedCandidate.Position}
            </span>
            {/* <div className="information-wholeInfo-applicant-left-tags">
              <MdAdd/>
              
              <span style={{marginLeft:"5px"}}>Add tag</span> 
            </div> */}
            <TagsInput
              selectedTags={selectedTags}
              tags={CandidateProfile.Tag && CandidateProfile.Tag.split(",")}
            />
          </div>
        </div>
        <div className="information-wholeInfo-applicant-right"></div>
      </div>

      <div className="information-wholeInfo-nav">
        <div className="information-wholeInfo-nav-tabs">
          <StyledTabs
            value={tabId}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
          >
            {tabList &&
              tabList.map((list, index) => {
                return (
                  <StyledTab
                    label={list.label}
                    value={list.value}
                    key={index}
                  />
                );
              })}
          </StyledTabs>
        </div>

        <div className="information-wholeInfo-nav-extra">
          <span
            className="information-wholeInfo-nav-extra-chips"
            onClick={() => setCommentModal(true)}
          >
            <span className="information-wholeInfo-nav-extra-chips-icon">
              <FaRegCommentDots />
            </span>
            <span className="fonts-dark_small blue">Add comment</span>
          </span>
          <span
            className="information-wholeInfo-nav-extra-chips"
            onClick={() => setComposeModal(true)}
          >
            <span className="information-wholeInfo-nav-extra-chips-icon">
              <AiOutlineMail />
            </span>
            <span className="fonts-dark_small blue">Write Email</span>
          </span>

          {selectedCandidate.CandidateStatusId === JobStages.Interview && (
            <span className="information-wholeInfo-nav-extra-chips">
              <span className="information-wholeInfo-nav-extra-chips-icon">
                <FaRegCalendarAlt />
              </span>
              <span
                onClick={() => setScheduleModal(true)}
                className="fonts-dark_small blue"
              >
                Schedule interview
              </span>
            </span>
          )}

          <span className="information-wholeInfo-nav-extra-chips">
            <span className="information-wholeInfo-nav-extra-chips-icon">
              <MdRateReview />
            </span>
            <span className="fonts-dark_small blue">Send for review</span>
          </span>
        </div>
        {/* 
          <IconButton
            className={clsx(classes.expandIcon, {
              [classes.expandIcon_Open]: expanded
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton> */}
      </div>

      <div className="information-wholeInfo-cv_milestone">
        <div className="information-wholeInfo-cv">
          <Collapse in={expanded}>
            <div className={classes.expand}>
              {tabId == 0 ? (
                <Profile candidateDetail={candidateDetail} />
              ) : tabId == 1 ? (
                <Email selectedCandidate={selectedCandidate} />
              ) : tabId == 2 ? (
                <Comment selectedCandidate={selectedCandidate} />
              ) : null}
            </div>
          </Collapse>
        </div>

        <Milestone
          selectedCandidate={selectedCandidate}
          milestoneDetail={milestoneDetail}
          onChangeJobStage={onChangeJobStage}
          jobStageList={jobStageList}
          hanleEmailCicked={handleEmailClicked}
          handleRejectClick={handleRejectClick}
          setScheduleModal={setScheduleModal}
        />
      </div>

      {previewOriginalImage && (
        <ImageViewer
          dowloadLinkUrl={dowloadLinkURL}
          hostedUrl={base}
          show={previewOriginalImage}
          title={originalImageFile.UserFileName}
          image={originalImageFile}
          onClose={setPreviewOriginalImage}
        />
      )}

      <ComposeEmail
        src={emailType}
        emailTemplateObj={emailTemplate}
        open={showComposeModal}
        handleModal={(value) => setComposeModal(value)}
        data={selectedCandidate}
      />

      <SendComment
        open={showCommentModal}
        handleModal={(value) => setCommentModal(value)}
        data={selectedCandidate}
      />

      <Modal
        title={`Schedule Interview with ${selectedCandidate.CandidateName}`}
        open={scheduleModal}
        onModalClose={() => setScheduleModal(false)}
        type=""
        className="modal-recruit-email"
      >
        <ScheduleInterview
          data={selectedCandidate}
          onSuccessScheduling={(data) => {
            var res = { ...data };
            setEmailType(ComposeEmailType.scheduleInterview);
            setEmailTemplate(res);
            setComposeModal(true);
            setScheduleModal(false);
          }}
        />
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  candidateDetail: state.recruitment_dashboardReducer.candidateDetail,
  candidateDetailLoading:
    state.recruitment_dashboardReducer.candidateDetailLoading,
  baseUrl: state.auth.baseUrl,
});
const mapDispatchToProps = {
  GetScheduledInterviewTemplate,
};
export default connect(mapStateToProps, mapDispatchToProps)(WholeInfo);
const TagsInput = (props: any) => {
  const [tags, setTags] = React.useState(props.tags);

  const removeTags = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
    props.selectedTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };

  const addTags = (event) => {
    if (event.target.value !== "") {
      if (!isEmpty(tags)) {
        setTags([...tags, event.target.value]);
        props.selectedTags([...tags, event.target.value]);
      } else {
        setTags([event.target.value]);
        props.selectedTags([event.target.value]);
      }
      event.target.value = "";
    }
  };

  return (
    <div className="tags-input">
      <ul id="tags">
        {tags &&
          tags.map((tag, index) => (
            <li key={index} className="tag">
              <span className="tag-title">{tag}</span>
              <span
                className="tag-close-icon"
                onClick={() => removeTags(index)}
              >
                <IoMdClose />
              </span>
            </li>
          ))}
      </ul>
      <input
        type="text"
        onKeyUp={(event) => (event.key === "Enter" ? addTags(event) : null)}
        placeholder="+ add tags"
      />
    </div>
  );
};
