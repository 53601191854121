import Upload from "components/common/upload";
import Form from "components/form";
import isEmpty from "isEmpty";
import React from "react";
import { connect } from "react-redux";
import { dispatchOtherExpenseList } from "../../actions";

export class AddOtherExpenses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documentsState: null,
      billAmount: "",
      fileList: isEmpty(this.props.initialValues.FileList)
        ? []
        : this.props.initialValues.FileList,
      formData: {},
      errors: {},
    };
  }
  componentDidMount() {
    this.setState({ formData: this.props.initialValues });
  }
  handleDocumentsTabState = (data) => {
    this.setState({ documentsState: data });
  };

  fileSelectedHandler = (event) => {
    const { fileList } = this.state;
    const file = event.target.files[0];
    fileList.push(file);
    this.setState({ fileList: fileList });
  };

  removeFile(name) {
    const { fileList } = this.state;
    let newFiles = fileList.filter((item) => item.name !== name);

    this.setState({ fileList: newFiles });
  }
  setSelectedFiles = (fileList) => {
    this.setState({ fileList });
  };
  onFormSubmit = (event) => {
    event.preventDefault();
    let { formData } = this.state;
    let errors = {};
    if (!formData.ExpenseName) {
      errors.ExpenseName = "Required";
    }
    if (!formData.Amount) {
      errors.Amount = "Required";
    }
    this.setState({ errors });
    if (isEmpty(errors)) {
      if (
        this.props.otherExpenses.filter((x) => x.Id === formData.Id).length > 0
      ) {
        let allowanceExpenseList = this.props.otherExpenses.map((x) => {
          let o = { ...x };
          if (x.Id === formData.Id) {
            o.ExpenseName = formData.ExpenseName;
            o.Amount = formData.Amount;
            o.Note = formData.Note;
            //x.BillAmount = data.BillAmount;
            o.FileList = this.state.fileList;
            o.FileUrl = !isEmpty(this.state.fileList)
              ? URL.createObjectURL(this.state.fileList[0])
              : "";
          }
          return o;
        });
        this.props.dispatchOtherExpenseList(allowanceExpenseList);
      } else {
        formData["FileList"] = this.state.fileList;
        formData["FileUrl"] = !isEmpty(this.state.fileList)
          ? URL.createObjectURL(this.state.fileList[0])
          : "";
        let allowanceExpenseList = this.props.otherExpenses.map((x) => {
          return { ...x };
        });
        allowanceExpenseList.push(formData);
        //this.props.otherExpenses.push(formData);
        this.props.dispatchOtherExpenseList(allowanceExpenseList);
      }
      this.props.onSubmit();
    }
  };
  handleInputChange = (name, value) => {
    this.setState((prevState) => ({
      formData: { ...prevState.formData, [name]: value },
    }));
  };
  render() {
    const { fileList, formData, errors } = this.state;
    //console.log(initialValues);
    return (
      <form className="add-other-expenses" onSubmit={this.onFormSubmit}>
        <input type="hidden" name="Id" />
        <div className="form-row">
          <Form
            name="ExpenseName"
            type="text"
            error={errors.ExpenseName}
            label="Expense name"
            value={formData.ExpenseName}
            onChange={this.handleInputChange}
            //width="250px"
          />
          {/* <Field
            name="ExpenseName"
            component={renderTextField}
            label="Expense name"
            validate={[required]}
            type="text"
            className="expense-name"
            //width="250px"
          /> */}
          <Form
            name="Amount"
            type="number"
            error={errors.Amount}
            label="Amount"
            value={formData.Amount}
            onChange={this.handleInputChange}
            width="150px"
          />
          {/* <Field
            name="Amount"
            component={renderTextField}
            label="Amount"
            validate={[required]}
            type="number"
            width="150px"
          /> */}
        </div>
        <div className="form-row">
          {/* <div className="form-label">
              <span>Note</span>
            </div> */}
          <Form
            formName="textarea"
            name="Note"
            rows={6}
            cols={60}
            label="Note"
            value={formData.Note}
            onChange={this.handleInputChange}
          />
          {/* <Field
              name="Note"
              component={renderTextArea}
              className="note"
              rows={6}
              cols={60}
            /> */}
        </div>
        <div className="form-row">
          <Upload
            fileList={fileList}
            setSelectedFiles={this.setSelectedFiles}
            uploadBtnText="Attach Bill"
            noSelectedText="No bill selected"
          />
        </div>
        <div className="form-row form-footer">
          <button
            type="button"
            className="btn btn-grey-2"
            onClick={this.props.closeModal}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Save
          </button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  otherExpenses: state.travelSettlementReducer.otherExpenses,
});

export default connect(mapStateToProps, { dispatchOtherExpenseList })(
  AddOtherExpenses
);
