import React,{useState,useEffect} from 'react';
import FormGroup from 'components/form';
import classnames from 'classnames';
import {getNotifications,getLogs,notificationMarkedAsRead} from './action';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'isEmpty';
import {Date_Format} from 'helpers/dateFormat';
import SvgIcons from 'components/icons/svgIcons';
import DatePicker from 'components/customdaterange/datepickerAsPerSetting'
import {BS2AD} from 'helpers/dateConvert';
import {GET_NOTIFICATIONS_LOGS} from 'actions/types'

const viewOptions = [
    {'label':'All',value:1},
    {'label':'Requests',value:2},
    {'label':'Approvals',value:3},
    {'label':'Comments',value:4},
    {'label':'Notices',value:5},
    {'label':'Data Changes',value:6},
]

const sortOptions = [
    {'label':'Newest',value:'Newest'},
    {'label':'Oldest',value:'Oldest'},
    {'label':'Unread',value:'Unread'},
]

const requestTypeOptions = [
    {'label':'Leave',value:'Leave'},
    {'label':'Time',value:'Time'},
    {'label':'Overtime',value:'Overtime'},
    {'label':'Allowance',value:'Allowance'},
    {'label':'Travel',value:'Travel'},
    {'label':'Others',value:'Others'},
]

const periodOptions = [
    {'label':'Last 30 Days',value:1},
    {'label':'Last 90 Days',value:2},
    {'label':'Date Range',value:3},
]

const getLastDate = (name):any =>{
    let now = new Date();
    let days = 0;
    if(parseInt(name) === 1){
        days = 30;
    }
    if(parseInt(name) === 2){
        days = 90;
    }
    let lastDays = new Date(now.setDate(now.getDate() - days));
    return { startDate : name ? Date_Format(lastDays) : '', endDate: name ? Date_Format(new Date()) : '' };
} 

function Notitifications() {
    const [view,setView] = useState(1)
    const [requestType,setRequestType] = useState('')
    const [sort,setSort] = useState('Newest')
    const [active,setActive] = useState('')
    const [dateRange,setDateRange] = useState({startDate:'',endDate:''})
    const [period,setPeriod] = useState(1);
    const dispatch = useDispatch();
    const {notifications} = useSelector((state:any)=>state.notificationReducer);


    useEffect(()=>{
        dispatch(getNotifications({}))
    },[]);
    useEffect(()=>{
        if( !isEmpty(sort) || !isEmpty(view) || !isEmpty(period) ){
            const {startDate,endDate} = getLastDate(period);
            let start = startDate;
            let end = endDate;
            if(period==3){
                start=dateRange.startDate
                end=dateRange.endDate

            }
            const data={
                noticeType:view,
                sortBy:sort,
                startDate:start,
                endDate:end,
                requestType
            }
            setActive('')
            dispatch(getNotifications(data))
        }

    },[view,sort,period,requestType]);
    const onDateRangeChange = (name,value) =>{
        setDateRange(value);
        if(value.startDate && value.endDate){
            var start = value.startDate
            var end = value.endDate
            if(value.startDate){
                start =BS2AD(dateRange.startDate).formatedDate;
            }
            if(value.endDate){
                end =BS2AD(dateRange.startDate).formatedDate;
            }
            const data={
                noticeType:view,
                sortBy:sort,
                startDate:start,
                endDate:end,
                requestType
            }
            setActive('')
            dispatch(getNotifications(data))
        }
    }
    const onNotifClick = (notif) =>{
        setActive(notif)
    }
    return (
        <div className="notification-page">
            <div className="notification-page-title">
                Notifications
            </div>
            <div className="notification-page-filters">
                <div className="notification-page-filters__col">
                    <FormGroup 
                        formName="selectInput"
                        options={viewOptions}
                        value={view}
                        name="view"
                        innerLabel="Viewing: "
                        onChange={(name,value)=>setView(value)}
                    />
                </div>
                <div className="notification-page-filters__col">
                    <FormGroup 
                        formName="selectInput"
                        options={sortOptions}
                        value={sort}
                        name="sort"
                        innerLabel="Sort by: "
                        onChange={(name,value)=>setSort(value)}
                    />
                </div>
                <div className="notification-page-filters__col">
                    <FormGroup 
                        formName="selectInput"
                        options={requestTypeOptions}
                        value={requestType}
                        name="requestType"
                        innerLabel="Request Type: "
                        onChange={(name,value)=>setRequestType(value)}
                    />
                </div>
                <div className="notification-page-filters__col">
                    {
                        period !==3 ? (
                            <FormGroup 
                                formName="selectInput"
                                options={periodOptions}
                                value={period}
                                name="period"
                                innerLabel="Period: "
                                onChange={(name,value)=>setPeriod(parseInt(value))}
                            />
                        )
                        :
                        (
                            <div className="flex">
                                <DatePicker 
                                    isDateRange
                                    value={dateRange}
                                    placeholder="Date Range"
                                    width="200px"
                                    isFullDetails
                                    // onChange={(name,value)=>console.log(value)}
                                    onChange={(name,value)=>onDateRangeChange(name,value)}
                                    name="dateRange"
                                />
                                <div 
                                    onClick={()=>setPeriod(1)}
                                    className="remove-icon">
                                    <SvgIcons name="times" size="12px" color="#fafafa" />
                                </div>
                            </div>

                        )
                    }
                </div>
            </div>
            <div className="notification-page-body">
                <div className="notification-page__left">
                    <ul className="notification-page-list">
                        {
                            !isEmpty(notifications) ? notifications.map(item=>{
                                return <NotifItem 
                                            onClick={onNotifClick}
                                            notification = {item}
                                            key={item.id}
                                            active={active}
                                        />
                            }) : 
                            (
                                <div className="mt-md text-light nodata">No Notifications Found</div>
                            )
                        }
                    </ul>
                </div>
                <div className="notification-page__right">
                    {
                        active && <NotifBody view={view} notification={active} />
                    }
                    
                </div>
            </div>
        </div>
    )
}

const RequestTypeValue = {
    Allowance:208,
    Overtime:207,
    Time:206,
    Leave:6,
    Settlment: 202
}

const NotifBody = (props) =>{
    const {notification,view} = props;
    const dispatch = useDispatch();
    const {currentLogs} = useSelector((state:any)=>state.notificationReducer)
    useEffect(()=>{
        if(notification.RequestType){
            if(notification.NoticeType===6){
                const id = RequestTypeValue[notification.RequestType]
                dispatch(getLogs(id))
            }
            else{
                dispatch({type:GET_NOTIFICATIONS_LOGS,payload:null})
            }
        }

    },[notification.RequestType,notification.NoticeType]);
    useEffect(()=>{
        if(notification.IsRead === false){
            const data ={
                notificationId:notification.Id,
                isRead:true,
                type:notification.Type
            }
            dispatch(notificationMarkedAsRead(data))
        }
    },[notification])
    return(
        <div className="notification-page-notif">
            <div className="notification-page-notif-col">
                <div 
                    dangerouslySetInnerHTML={{ __html: notification.Subject }}
                    className="notification-page-notif__title">
                {/* {notification.Subject} */}
                </div>
                <div className="notification-page-notif__time">
                    {notification.CreatedOn}
                </div>
                <div className="notification-page-notif__details">
                    <div className="notification-page-notif__details-title">Details</div>
                    <div className="notification-page-notif__details-content">
                        <p dangerouslySetInnerHTML={{ __html: notification.Body }}>
                        </p>
                    </div>
                    {
                        currentLogs && currentLogs.map(item=>{
                            return(
                                <div className="notification-page-notif__details-content">
                                    <div className="notification-page-notif__details-row">
                                        <div className="notification-page-notif__details-col">Document:</div>
                                        <div className="notification-page-notif__details-col">Home Leave</div>
                                    </div>
                                    <div className="notification-page-notif__details-row">
                                        <div className="notification-page-notif__details-col">Field:</div>
                                        <div className="notification-page-notif__details-col">{item.Type}</div>
                                    </div>
                                    <div className="notification-page-notif__details-row">
                                        <div className="notification-page-notif__details-col">Modified On:</div>
                                        <div className="notification-page-notif__details-col">{item.ModifiedOn}</div>
                                    </div>
                                    <div className="notification-page-notif__details-row">
                                        <div className="notification-page-notif__details-col">New Value:</div>
                                        <div className="notification-page-notif__details-col new-val">{item.AfterValue}</div>
                                    </div>
                                    <div className="notification-page-notif__details-row">
                                        <div className="notification-page-notif__details-col">Old Value:</div>
                                        <div className="notification-page-notif__details-col old-val">{item.BeforeValue}</div>
                                    </div>
                                    <div className="notification-page-notif__details-row">
                                        <div className="notification-page-notif__details-col">Changed By:</div>
                                        <div className="notification-page-notif__details-col">{item.ChangedBy}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            {/* <div className="notification-page-notif-col">
                <div 
                    className="notification-page-notif__title">
                        Data Change ({notification.RequestType} Request)
                </div>
                <div className="notification-page-notif__time">
                    7 minutes ago
                </div>
                <div className="notification-page-notif__details">
                    <div className="notification-page-notif__details-title">Details</div>
                    {
                        currentLogs && currentLogs.map(item=>{
                            return(
                                <div className="notification-page-notif__details-content">
                                    <div className="notification-page-notif__details-row">
                                        <div className="notification-page-notif__details-col">Document:</div>
                                        <div className="notification-page-notif__details-col">Home Leave</div>
                                    </div>
                                    <div className="notification-page-notif__details-row">
                                        <div className="notification-page-notif__details-col">Field:</div>
                                        <div className="notification-page-notif__details-col">{item.Type}</div>
                                    </div>
                                    <div className="notification-page-notif__details-row">
                                        <div className="notification-page-notif__details-col">Modified On:</div>
                                        <div className="notification-page-notif__details-col">{item.ModifiedOn}</div>
                                    </div>
                                    <div className="notification-page-notif__details-row">
                                        <div className="notification-page-notif__details-col">New Value:</div>
                                        <div className="notification-page-notif__details-col new-val">{item.AfterValue}</div>
                                    </div>
                                    <div className="notification-page-notif__details-row">
                                        <div className="notification-page-notif__details-col">Old Value:</div>
                                        <div className="notification-page-notif__details-col old-val">{item.BeforeValue}</div>
                                    </div>
                                    <div className="notification-page-notif__details-row">
                                        <div className="notification-page-notif__details-col">Changed By:</div>
                                        <div className="notification-page-notif__details-col">{item.ChangedBy}</div>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
            </div> */}
        </div>
    )
}

const NotifItem = (props) =>{
    const dispatch = useDispatch();
    const {active,unRead,notification,onClick} = props;
    const liClass = classnames("notification-page-item",{
        "notification-page-item__active":notification.Id === active?.Id
    });
    return(
        <li 
            onClick={()=>onClick(notification)}
            className={liClass}>
            <div className="notification-page-item-body">
               <div className="notification-page-item-content">
                    <h5 
                        dangerouslySetInnerHTML={{ __html: notification.Subject }}
                        className="notification-page-item__title">
                        {/* {notification.Subject} */}
                    </h5>
                    <p className="notification-page-item__subtitle">
                       {notification.RequestType}: {notification.Message}
                    </p>
                    <span className="notification-page-item__time">
                        {notification.CreatedOn}
                    </span>
               </div>
               <div className="notification-page-item-circle">
                    <div className={`read-circle ${notification.IsRead?'':'read-circle__fill'}`}></div>
               </div>
            </div>
        </li>
    )
}

export default Notitifications
