import React from "react";
// import EngCal from './../../../routes/navigate/cal/engCal';
import Calendar from 'components/datePicker';
import { useSelector } from 'react-redux';

function HolidayCalendar() {
  const {companyInformation} = useSelector(state=>state.commonReducer);
  return (
    <div className="calendar-block">
      <Calendar 
        isNepali={companyInformation && !companyInformation.IsEnglishDate}
        noFooter />
    </div>
  );
}


export default HolidayCalendar;
