import * as React from "react";
import { FaCheckCircle } from "react-icons/fa";
import Button from "./index";
interface IAppProps {
  handleApproveRequest: Function;
}

const App: React.FunctionComponent<IAppProps> = (props) => {
  return (
    <Button
      buttonType="icon-button"
      bg="success"
      title="Approve" 
      leftIcon={<FaCheckCircle className="text-xbg" />}
      onClick={() => props.handleApproveRequest()}
    />
    // <button
    //   className="button with-icon button-approve"
    //   onClick={() => props.handleApproveRequest()}
    // >
    //   <FaCheckCircle className="text-xbg" />
    //   <span>Approve</span>
    // </button>
  );
};

export default App;
