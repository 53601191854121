import React,{useEffect,useState} from 'react'
import { useDispatch,useSelector } from 'react-redux';
import {getCluster} from '../helpers/action'
import {MdArrowForward} from 'react-icons/md'
import  FormGroup from 'components/form';
import {moveCluster} from '../helpers/action'
import isEmpty from 'isEmpty';

function MoveCluster({close,cluster,branch}) {
    const [clusterId,setCluster] = useState([]);
    const [errors,setErrors] = useState([]);
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(getCluster(cluster.ProvinceRefId))
    },[]);
    const saveCluster = () =>{
        if(clusterId === null || clusterId === []){
            setErrors('Cluster is required');
            return false;
        }
        
        dispatch(moveCluster(branch.BranchId,clusterId.value))
        setErrors('');
        close()
    }
    const clusters = useSelector(state => state.clusterReducer.clusters);
    return (
        <div className="primary-modal">
            <div className="primary-modal-body">
                <div className="clusters-move">
                    <div className="clusters-move__branch">
                        <h3>{branch.BranchName}</h3>
                    </div>
                    <div className="clusters-move__cluster">
                        <div className="clusters-name">
                            <h4>{cluster.ClusterName}</h4>
                        </div>
                        <div className="clusters-move__icon">
                            <MdArrowForward />
                        </div>
                        <div className="clusters-move__select">
                            <FormGroup
                                formName="reactselect" 
                                options={clusters}
                                isArray
                                value={clusterId}
                                onChange={(name,value)=>setCluster(value)}
                                placeholder=""
                                name="cluster"
                                width="300px"
                                error={errors}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="primary-modal-footer">
                <div className="button-group button-group__right">
                    <button 
                        onClick = {()=>saveCluster()}
                        className="button button-primary">Move</button>
                    <button 
                        onClick={()=>close()}
                        className="button">Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default MoveCluster
