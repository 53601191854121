import React from 'react'
import CommonModuleLinks from 'components/common/commonModuleLinks'
import Title from "../../common/title";
import RouteEnum from 'constants/routeEnum';


const modules = (features) => [
    {
        groupTitle:'Leave/Attendance Reports',
        feature:features?.LeaveAndTime,
        links: [
            { title:'Yearly Leave Report', link:RouteEnum.YearlyReportsPath + '/yearly',isReact:true},
            { title:'Leave Summary', link:RouteEnum.YearlyReportsPath + '/leave',isReact:true},
            { title:'Attendance Details', link:RouteEnum.YearlyReportsPath + '/attendance',isReact:true},
            { title:'Team Attendance', link:RouteEnum.YearlyReportsPath + '/team',isReact:true},
            // { title:'Yearly Leave Report', link:'Employee/yearleavereport.aspx'},
            // { title:'Leave Summary', link:'Employee/leavesummary.aspx'},
            // { title:'Attendance Details', link:'Employee/attendancedetails.aspx'},
            // { title:'Team Attendance', link:'Employee/otheremployeeattendance.aspx'},
            { title:'Tax Calulation', link:RouteEnum.YearlyReportsPath + '/tax_calculation',isReact:true},
        ]
    },
]


function Reports(props) {
    return (
        <div className="employee-reports">
            <div style={{padding:'10px 25px 0'}} className="">
                <Title name="Reports" />
            </div>
            <CommonModuleLinks baseUrl={props.baseUrl} title="Employee Reports" localStorageTitle="ERT" modules={modules} />
        </div>
    )
}

export default Reports
