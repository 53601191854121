import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import ReactDatePicker from "react-date-picker";

const inputclass = (error) => {
  return classnames({
    "form-group": true,
    "inline-form-group": true,
    inputWidth: "100%",
    error: error,
  });
};


export function OneDatePicker(props) {
  const {
    label,
    views,
    value,
    format,
    maxDate,
    maxDetail,
    onChange,
    error,
    errorClassName,
    placeholder
  } = props;
  return (
    <div className={inputclass(error)}>
      {label && <label className="form-group__label">{label}</label>}
      <div className="jobApply__pickers"  >
        <ReactDatePicker
          onChange={onChange}
          value={value}
          format={format}
          maxDate={maxDate && new Date(maxDate)}
          dayPlaceholder="dd"
          monthPlaceholder="mm"
          yearPlaceholder="yyyy"
          monthAriaLabel="Month"
          maxDetail={maxDetail}
        />
      </div>
      {error ? <span className={errorClassName}>{error}</span> : null}
    </div >
  );
}

OneDatePicker.propTypes = {
  label: PropTypes.string,
  views: PropTypes.array,
  value: PropTypes.any,
  format: PropTypes.string,
  placeholder: PropTypes.string,
  // maxDate: PropTypes.any,
  maxDetail: PropTypes.string,
  onChange: PropTypes.func
};
OneDatePicker.defaultProps = {
  views: ["year", "month", "date"],
  value: new Date(),
  format: "yyyy/MM/dd",
  maxDate: "9999",
  maxDetail: "month",
};
