import React from "react";
import FormInput from "components/form";
import ExportExcel from "components/excel/exportExcel";
import {getNepaliDate} from "components/datePicker/helpers";
import Form from "components/form";

let export_name = "Project Review Report";

let export_columns = [
  {label: "Project Name", value: "ProjectName"},
  {label: "Client", value: "ClientName"},
  {label: "Project Type", value: "ClientTypeName"},
  {label: "Contact Start Date", value: "EventStartDate"},
  {label: "Contract End Date", value: "EventEndDate"},
  {label: "Status", value: "StatusName"},
];

let nepaliDate = getNepaliDate();

const commonFilter = (props) => {
  const {
    handleAutoText,
    initialData,
    handleAutoSelect,
    projectTypes,
    allStatus,
    handleDateSelect,
    data,
    handleExcelExport,
    exportTaskTrackerPorjectDetailsList,
  } = props;
  return (
    <div className="filters-input filters">
      <div className="filters-input__item">
        <FormInput
          formName="searchInput"
          onChange={handleAutoText}
          name="projectName"
          label={"Search Project"}
          value={initialData.projectName}
          placeholder="Search Project"
          width="160px"
        />
      </div>
      <div className="filters-input__item">
        <FormInput
          formName="searchInput"
          onChange={handleAutoText}
          name="clientName"
          label={"Search Client"}
          value={initialData.clientName}
          placeholder="Search Client"
          width="160px"
        />
      </div>
      <div className="filters-input__item">
        <FormInput
          formName="reactselect"
          onChange={handleAutoSelect}
          name="clientType"
          label={"Project Type"}
          value={initialData.clientType}
          options={projectTypes}
          placeholder="Project Type"
          width="165px"
        />
      </div>
      <div className="filters-input__item">
        <Form
          formName="customdateinput"
          name="contractEndDate"
          width="140px"
          onChange={handleDateSelect}
          label={"Contract End Date"}
          value={initialData.contractEndDate}
          placeholder="Select date"
          isNepali={false}
          isTextInNepali={false}
        />
      </div>
      <div className="filters-input__item">
        <FormInput
          formName="reactselect"
          onChange={handleAutoSelect}
          name="projectStatus"
          label={"Status"}
          value={initialData.projectStatus}
          options={allStatus}
          placeholder="Status"
          width="140px"
        />
      </div>
      {data.length > 0 && (
        <div className="filters-input__item">
          <ExportExcel
            onClick={handleExcelExport}
            fileName={export_name}
            columns={export_columns}
            sheets={[{sheetName: export_name, data: exportTaskTrackerPorjectDetailsList}]}
            className="button button-primary"
          />
        </div>
      )}
    </div>
  );
};

export default commonFilter;
