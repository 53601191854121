import "isomorphic-fetch";
import React from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";
import { emphasize, makeStyles, useTheme } from "@material-ui/core/styles";

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default function CustomAutoComplete(props) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  // const loading = open && options.length === 0;

  React.useEffect(() => {
    // console.log(props.options)
    setOptions(props.options);
  }, []);

  // React.useEffect(() => {
  //     let active = true;

  //     if (!loading) {
  //         return undefined;
  //     }

  //     (async () => {
  //         const response = await fetch('https://country.register.gov.uk/records.json?page-size=5000');

  //         await sleep(1e3); // For demo purposes.
  //         const countries = await response.json();
  //         if (active) {
  //             setOptions(Object.keys(countries).map(key => countries[key].item[0]));
  //         }
  //     })();

  //     return () => {
  //         active = false;
  //     };
  // }, [loading]);

  React.useEffect(() => {
    // if (!open) {
    // setOptions([]);
    // }
  }, [open]);
  const { label, loading, width, onChange, name, placeholder } = props;
  return (
    <div className="autocomplete">
      <label htmlFor={name} className="autocomplete-label">
        {label}
      </label>
      <Autocomplete
        id={name}
        style={{ width: width || 250 }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionSelected={(option, value) => {
          return option.value === value.value;
        }}
        getOptionLabel={(option) => option.label}
        options={options}
        loading={true}
        includeInputInList
        autoComplete
        disableOpenOnFocus
        onChange={(event, value) => onChange(name, value, event)}
        renderInput={(params) => (
          <TextField
            {...params}
            // label={label}
            placeholder={placeholder}
            variant="outlined"
            fullWidth
            onChange={() => console.log("changed")}
          />
        )}
        renderOption={(option) => {
          // const matches = option.structured_formatting.main_text_matched_substrings;
          // const parts = parse(
          //     option.structured_formatting.main_text,
          //     matches.map(match => [match.offset, match.offset + match.length]),
          // );
          // console.log(option)
          const { label, value, img } = option;

          return (
            <div className="input-row">
              <div className="input-image">
                <img src={img} alt="" />
              </div>
              <span className="input-value">{label}</span>
            </div>
          );
        }}
        // renderInput={params =>
        //     {
        //         return (
        //             <TextField
        //                 {...params}
        //                 label={label}
        //                 fullWidth
        //                 variant="outlined"
        //                 onChange={(e)=>console.log(e.target)}
        //                 InputProps={{
        //                 ...params.InputProps,
        //                 endAdornment: (
        //                     <React.Fragment>
        //                         {loading ? <CircularProgress color="inherit" size={20} /> : null}
        //                         {params.InputProps.endAdornment}
        //                     </React.Fragment>
        //                 ),
        //                 }}
        //             />
        //         )
        //     }
        // }
      />
    </div>
  );
}
