import React from 'react'
import MarkingTable from './markingTable';
import './style.scss'

function MarkingScheme() {
    return (
        <div className="marking">
            <div className="marking-container">
                <div className="marking-title">
                    Marking Scheme
                </div>
                <div className="marking-body">
                    <div className="marking-link">
                        <a href="#">Copy from another job</a>
                    </div>
                    <div className="marking-table-block">
                        
                        <MarkingTable />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MarkingScheme
