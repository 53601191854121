import FormGroup from "components/form";
import { SUMMARY_OF_POSITION_TYPE } from "constants/constants";
import { GrCheckmark, GrClose } from "react-icons/gr";
import { MdDelete, MdEdit } from "react-icons/md";
import isEmpty from "isEmpty";
import React, { Component } from "react";

const profileBlockType = SUMMARY_OF_POSITION_TYPE;
class SummaryOfPosition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNameEdit: false,
      subtitleToEdits: [],
      profileBlockType: "",
      errors: {},
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    var stateCopy = this.props.state.profileBlocks.map((x) => {
      return { ...x };
    });
    const currentSection = stateCopy.find((x) => x.type === profileBlockType);
    this.setState({ intialBlockData: currentSection, profileBlockType });
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  handleSectionNameEdit = (edit) => {
    this.setState({ isNameEdit: edit });
  };
  handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      this.handleSectionNameEdit(false);
      this.setState({ subtitleToEdits: [] });
    }
  };
  handleSubtitleEditClick = (id) => {
    let { subtitleToEdits } = this.state;
    subtitleToEdits.push(id);
    this.setState({ subtitleToEdits });
  };

  handleSaveTitle = (value) => {
    if (isEmpty(value)) this.setState({ errors: { title: "Required" } });
    else {
      this.setState((prevState) => ({
        ...prevState,
        intialBlockData: {
          ...prevState.intialBlockData,
          title: value,
        },
        errors: { ...prevState.errros, title: "" },
      }));

      this.handleSectionNameEdit(false);
    }
  };

  handleRemoveChangedTitle = () => {
    const currentSection = this.state.intialBlockData;
    this.props.handleProfileBlockTitle(profileBlockType, currentSection.title);
    this.handleSectionNameEdit(false);
  };

  handleClickOutside(event) {
    let containsTarget = false;
    let targetTitleEl = document.getElementsByClassName("title-action");
    for (let el of targetTitleEl) {
      if (el.contains(event.target)) {
        containsTarget = true;
        break;
      }
    }
    if (!isEmpty(targetTitleEl) && !containsTarget) {
      this.handleRemoveChangedTitle();
    }
  }
  render() {
    const { isNameEdit, errors } = this.state;
    //const { errors } = this.props.state;
    const currentSection = this.props.state.profileBlocks.find(
      (x) => x.type === profileBlockType
    );
    const { title } = currentSection;
    const { subtitle, paragraph } = currentSection.components[0];
    return (
      <div className="summary-of-position profile-block">
        <div className="profile-block__header">
          <div>
            {!isNameEdit ? (
              <span className="profile-text">{title}</span>
            ) : (
              <div className="flex">
                <FormGroup
                  name="title"
                  value={title}
                  onChange={(name, value) =>
                    this.props.handleProfileBlockTitle(profileBlockType, value)
                  }
                  label=""
                  error={errors?.title}
                  onKeyUp={this.handleKeyUp}
                  validators={["required"]}
                  width="250px"
                  autoFocus
                  className="title-action"
                />
                <div
                  className="edit-box-title title-action"
                  onClick={() => this.handleSaveTitle(currentSection.title)}
                >
                  <GrCheckmark />
                </div>
                <div
                  className="edit-box-title ml-xsm title-action"
                  onClick={() => this.handleRemoveChangedTitle()}
                >
                  <GrClose />
                </div>
              </div>
            )}
            <span className="profile-type">{currentSection.type}</span>
          </div>

          <div className="flex justify-end action-bar">
            {!isNameEdit && (
              <button
                className="button with-icon"
                onClick={() => this.handleSectionNameEdit(true)}
              >
                <MdEdit />
                <span> Edit Section Name</span>
              </button>
            )}
          </div>
        </div>
        <div className="profile-block__body">
          <FormGroup
            formName="textarea"
            name="paragraph"
            label={currentSection.type}
            cols={50}
            rows={6}
            value={isEmpty(paragraph) ? "" : paragraph}
            onChange={(name, value) =>
              this.props.handleComponentFieldChange(
                profileBlockType,
                currentSection.components[0].id,
                name,
                value
              )
            }
            disabled={this.props.isViewMode}
            placeholder="Write something..."
          />
        </div>
      </div>
    );
  }
}

export default SummaryOfPosition;
