import React from 'react'

const QuestionLabel = ({ label, icon }) => {
    return (
        <div className="question-label">
            <div className="question-label__icon">
                {icon}
            </div>
            <div className="question-label__text">
                {label}
            </div>
        </div>
    )
}
export default QuestionLabel
