import { validImageExtension } from "constants/validExtensions";
import isEmpty from "isEmpty";
import React, { useState } from "react";
import Avatar from "react-avatar";
import AvatarEdit from "react-avatar-edit";
import { AiFillCloseCircle } from "react-icons/ai";

function ImageUploader({ title, data, error, onChange, onError }) {
  const { isPhotoSelectionCompleted, photo, cropedPhoto } = data;
  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     preview: null,
  //     src:null,
  //   }
  //   this.onCrop = this.onCrop.bind(this)
  //   this.onClose = this.onClose.bind(this)
  //   this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this)
  // }

  // componentDidMount(){
  //   const {photo,isPhotoSelectionCompleted}=this.props;
  //   this.setState({preview:photo})
  // }

  const [preview, setPreview] = useState(cropedPhoto);
  const [src, setSrc] = useState(null);

  const handleDoneCrop = () => {
    onChange(title, "photo", src);
    onChange(title, "cropedPhoto", preview);
    onChange(title, "isPhotoSelectionCompleted", true);
  };

  const handleRemoveImage = () => {
    setPreview(null);
    setSrc(null);
    onChange(title, "photo", null);
    onChange(title, "cropedPhoto", null);
    onChange(title, "isPhotoSelectionCompleted", false);
  };

  const onClose = () => {
    setPreview(null);
  };

  const onCrop = preview => {
    setPreview(preview);
  };

  const onBeforeFileLoad = elem => {
    const file = elem.target.files[0];
    const validExtensions = validImageExtension;
    const extension = file.name.split(".")[1];
    const isValidExtension = validExtensions.find(f => f == extension)
      ? true
      : false;
    if (file.size > 556000) {
      //556000 Byte==556 Kilobyte
      onError("Maximum valid file size is 556 Kb.");
      elem.target.value = "";
    } else if (!isValidExtension) {
      onError("Valid extension are " + validExtensions);
      elem.target.value = "";
    } else {
      setSrc(file);
    }
  };

  return (
    <div>
      {!isPhotoSelectionCompleted ? (
        <div className="space-between">
          <div>
            <AvatarEdit
              width={390}
              height={295}
              onCrop={onCrop}
              onClose={onClose}
              onBeforeFileLoad={onBeforeFileLoad}
              src={src}
              label={"Upload photo"}
              borderStyle={{
                border: "2px solid #979797",
                borderStyle: "dashed",
                borderRadius: "8px",
                textAlign: "center"
              }}
            />
            {!isEmpty(preview) && (
              <div
                className="button-group button-group__center"
                style={{ margin: 0 }}
              >
                <button
                  className="btn-outline btn-outline__black"
                  onClick={handleDoneCrop}
                >
                  Done
                </button>
              </div>
            )}
            {!isEmpty(error) && (
              <span
                className={"error__message aligin-center"}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px"
                }}
              >
                {error}
              </span>
            )}
          </div>

          {!isEmpty(preview) && (
            <img src={preview} className="preview-img" alt="Preview" />
          )}
        </div>
      ) : (
        <div className="upload-section__new">
          <div className="upload-img__new">
            <Avatar
              className="user-avatar"
              name={"photo"}
              size={200}
              // round={true}
              src={preview}
            />
            <span className="upload-icon__new" onClick={handleRemoveImage}>
              <AiFillCloseCircle />
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default ImageUploader;
