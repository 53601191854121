import React, { Component } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Filter,
  Inject,
  Sort,
  // SortSettingsModel,
} from "@syncfusion/ej2-react-grids";
import CommonPagination from "components/common/pagination";
import TableRow from "./tableRow";

export class table extends Component {
  constructor(props) {
    super(props);
    this.widths = {
      date: 100,
      employeeName: 125,
      matterId: 100,
      clientName: 125,
      projectName: 125,
      natureOfWork: 150,
      hours: 90,
      billable: 90,
      nonBillable: 90,
      hourlyRate: 100,
      expenses: 100,
      estimatedFee: 120,
      description: 200,
    };
    // this.sortingOptions = () =>
    //   SortSettingsModel({
    //     columns: [
    //       { field: "SubmittedByDisplayText", direction: "Ascending" },
    //       { field: "ActivityDateDisplayText", direction: "Ascending" },
    //       { field: "ClientName", direction: "Ascending" },
    //       { field: "WorkedHoursDisplayText", direction: "Ascending" },
    //       { field: "BillableHoursDisplayText", direction: "Ascending" },
    //       { field: "NonBillableHoursDisplayText", direction: "Ascending" },
    //     ],
    //   });
    this.gridTemplate = this.gridTemplate;
    this.row = [];
  }

  gridTemplate(props, history) {
    return (
      <TableRow
        {...props}
        {...history}
        ref={(row) => {
          this.row = [...this.row, row];
        }}
      />
    );
  }

  render() {
    const {
      data,
      handleRowChange,
      handleIndexChange,
      pageIndex,
      rowsPerPage,
    } = this.props;

    return (
      <div className="default-table">
        <div className="pending-settlement-grid">
          <GridComponent
            dataSource={data}
            className="custom-grid"
            // toolbar={this.toolbar}
            // filterSettings={{ type: "Menu" }}
            // allowFiltering={true}
            //  height={!isEmpty(searchedLeaveAbsence)?"500":'80'}
            id="grid"
            // allowExcelExport={true}
            ref={(g) => (this.grid = g)}
            rowTemplate={this.gridTemplate.bind(this)}
            allowSorting={true}
            // sortSettings={this.sortingOptions}
          >
            <ColumnsDirective>
              <ColumnDirective
                // field="ActivityDateEng"
                headerText="Date"
                width={this.widths.date}
              />
              <ColumnDirective
                // field="MemberName"
                headerText="Employee"
                width={this.widths.employeeName}
              />
              <ColumnDirective
                // field="MatterId"
                headerText="Matter Id"
                width={this.widths.matterId}
              />
              <ColumnDirective
                // field="ClientName"
                headerText="Client"
                width={this.widths.clientName}
              />
              <ColumnDirective
                // field="ProjectName"
                headerText="Project"
                width={this.widths.projectName}
              />
              <ColumnDirective
                // field="ActivityTypes"
                headerText="Nature of Work"
                width={this.widths.natureOfWork}
              />
              <ColumnDirective
                // field="WorkedHoursText"
                headerText="Worked Hours"
                width={this.widths.hours}
              />
              <ColumnDirective
                // field="BillableHoursText"
                headerText="Billable Hours"
                width={this.widths.billable}
              />

              <ColumnDirective
                // field="NonBillableHoursText"
                headerText="Non-Billable Hours"
                width={this.widths.nonBillable}
              />
              <ColumnDirective
                // field="HourlyRate"
                headerText="Hourly Rate"
                textAlign="Right"
                width={this.widths.hourlyRate}
              />
              <ColumnDirective
                // field="PocketExpenses"
                headerText="Expenses"
                textAlign="Right"
                width={this.widths.expenses}
              />
              <ColumnDirective
                // field="EstimatedFee"
                headerText="Estimated Fee"
                textAlign="Right"
                width={this.widths.estimatedFee}
              />
              <ColumnDirective
                // field="Description"
                headerText="Description"
                width={this.widths.description}
              />
            </ColumnsDirective>
            {/* <Inject services={[Filter]} />
            <Inject services={[Filter,Sort]} />
            */}
            <Inject services={[Sort]} />
          </GridComponent>

          {data && data.length > 0 && (
            <>
              {/* <div classname="table-total-data">
                {"Total: " + data[0].TotalRows}
              </div> */}
              <CommonPagination
                handleRowChange={handleRowChange}
                handleIndexChange={handleIndexChange}
                pageIndex={pageIndex}
                rowsPerPage={rowsPerPage}
                total={data[0].TotalRows}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

export default table;
