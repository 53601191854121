import React from "react";
import "./icons.styles.scss";
function emailOpen() {
  return (
    <svg
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="mail 1">
        <g clipPath="url(#clip0)">
          <g id="env">
            <path
              id="env-back"
              d="M66 273.66L249.183 390.242C251.276 391.787 253.748 392.548 256.221 392.548C258.694 392.548 261.167 391.787 263.259 390.242L446.443 273.66L263.355 133.372C259.122 130.209 253.32 130.209 249.088 133.372L66 273.66Z"
              fill="#64B5F6"
            />
            <g id="mail">
              <path
                id="Vector"
                d="M374.554 274H136.778C123.676 274 113 284.676 113 297.778V559.332C113 565.895 118.326 571.221 124.889 571.221H386.443C393.006 571.221 398.332 565.895 398.332 559.332V297.778C398.332 284.676 387.68 274 374.554 274Z"
                fill="#ECEFF1"
              />
              <g id="Group">
                <path
                  id="Vector_2"
                  d="M172.444 345.333H338.888C345.451 345.333 350.777 340.007 350.777 333.444C350.777 326.881 345.451 321.555 338.888 321.555H172.444C165.882 321.555 160.555 326.881 160.555 333.444C160.555 340.007 165.882 345.333 172.444 345.333Z"
                  fill="#90A4AE"
                />
                <path
                  id="Vector_3"
                  d="M338.888 369.111H172.444C165.882 369.111 160.555 374.437 160.555 380.999C160.555 387.562 165.882 392.888 172.444 392.888H338.888C345.451 392.888 350.777 387.562 350.777 380.999C350.777 374.437 345.451 369.111 338.888 369.111Z"
                  fill="#90A4AE"
                />
                <path
                  id="Vector_4"
                  d="M267.555 416.666H172.444C165.882 416.666 160.555 421.992 160.555 428.555C160.555 435.117 165.882 440.444 172.444 440.444H267.555C274.118 440.444 279.444 435.117 279.444 428.555C279.444 421.992 274.118 416.666 267.555 416.666Z"
                  fill="#90A4AE"
                />
              </g>
            </g>
            <path
              id="env-mid"
              d="M263.259 390.242C261.167 391.787 258.694 392.548 256.221 392.548C253.748 392.548 251.276 391.787 249.183 390.242L66 273.66V487.659C66 500.784 76.6524 511.437 89.7777 511.437H422.665C435.79 511.437 446.443 500.784 446.443 487.659V273.66L263.259 390.242Z"
              fill="#1E88E5"
            />
            <path
              id="env-front"
              d="M422.665 511.437H89.7777C76.4384 511.437 66 500.998 66 487.659C66 483.878 67.8071 480.312 70.8506 478.077L249.183 359.188C251.276 357.643 253.748 356.882 256.221 356.882C258.694 356.882 261.167 357.643 263.259 359.188L441.592 478.077C444.636 480.312 446.443 483.878 446.443 487.659C446.443 500.998 436.004 511.437 422.665 511.437Z"
              fill="#2196F3"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="512" height="512" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default emailOpen;
