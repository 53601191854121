import React, { Component } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Filter,
  FilterSettingsModel,
  Inject,
  Freeze,
  Resize,
  Sort,
  // SortSettingsModel
} from "@syncfusion/ej2-react-grids";
import CommonPagination from "components/common/pagination";
import { DataUtil } from "@syncfusion/ej2-data";
import EmployeeRow from "./employeeRow";
import isEmpty from "isEmpty";
import ProfileImage from "./profileImage";
import EmpDropDown from "./empDropDown";

export class EmployeeTable extends Component {
  constructor(props) {
    super(props);
    this.widths = {
      image: 100,
      name: 300,
      cardNo: 150,
      level: 200,
      position: 200,
      branch: 200,
      department: 200,
      status: 150,
      state: 200,
      condition: 150,
    };

    // this.sortingOptions = () =>
    //   SortSettingsModel({
    //     columns: [{ field: "Name", direction: "Ascending" }]
    //   });
    this.gridTemplate = this.gridTemplate;
    this.empRow = [];
    this.profileTemplate = this.profileImage;
    // this.filterTemplate = this.filterTemplate.bind(this);
  }

  // filterTemplate = props => {
  //   return (
  //     <div className="">
  //       <div className="new">ABCDEF</div>
  //     </div>
  //   );
  // };
  profileImage(props) {
    return <ProfileImage data={props} />;
  }
  dropdownTemplate = (props) => {
    const history = this.props.history;
    return <EmpDropDown {...props} {...history} />;
  };
  gridTemplate(props, history) {
    return (
      <>
        <EmployeeRow
          {...props}
          {...history}
          ref={(empRow) => {
            this.empRow = [...this.empRow, empRow];
          }}
        />
      </>
    );
  }

  render() {
    const {
      data,
      handleRowChange,
      handleIndexChange,
      pageIndex,
      rowsPerPage,
      history,
    } = this.props;

    return (
      <div className="table">
        <div className="arrear-table">
          <div>
            <GridComponent
              dataSource={data}
              className="custom-grid"
              toolbar={this.toolbar}
              filterSettings={{ type: "Menu" }}
              allowFiltering={true}
              frozenColumns={1}
              //  height={!isEmpty(searchedLeaveAbsence)?"500":'80'}
              id="grid"
              // allowExcelExport={true}
              ref={(g) => (this.grid = g)}
              // rowTemplate={this.gridTemplate.bind(this, history)}
              allowSorting={true}
            >
              <ColumnsDirective>
                {/* <ColumnDirective  width={this.widths.image} /> */}
                <ColumnDirective
                  field="Fullname"
                  headerText="Name"
                  width={this.widths.name}
                  template={this.profileTemplate}
                />
                <ColumnDirective
                  field="Fullname"
                  headerText="Name"
                  // filterTemplate={this.filterTemplate}
                  width={0}
                />
                <ColumnDirective
                  headerText="CardNo"
                  width={this.widths.cardNo}
                />
                {/* <ColumnDirective
                  // field="Level"
                  headerText="Level"
                  width={this.widths.level}
                /> */}
                <ColumnDirective
                  field="Position"
                  headerText="Position"
                  width={this.widths.position}
                />
                <ColumnDirective
                  field="Branch"
                  headerText="Branch"
                  width={this.widths.branch}
                />
                <ColumnDirective
                  field="Department"
                  headerText="Department"
                  width={this.widths.department}
                />
                <ColumnDirective
                  field="Status"
                  headerText="Status"
                  width={this.widths.status}
                />
                <ColumnDirective
                  field="State"
                  headerText="State"
                  width={this.widths.state}
                />
                <ColumnDirective
                  field=""
                  width={140}
                  template={this.dropdownTemplate}
                />
              </ColumnsDirective>
              <Inject services={[Filter, Freeze, Resize]} />
            </GridComponent>

            {data && data.length > 0 && (
              <CommonPagination
                handleRowChange={handleRowChange}
                handleIndexChange={handleIndexChange}
                pageIndex={pageIndex}
                rowsPerPage={rowsPerPage}
                total={data[0].TotalRows}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default EmployeeTable;
