import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { History } from "history";
import { ApplicationState, reducers } from "../reducers";
import { newReducers } from "store/reducers";
import checkTokenExpirationMiddleware from "src/middleware/checkTokenExpirationMiddleware";
import { nodeEnv } from "constants/constants";
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";
import reduxFreeze from "redux-freeze";
import environment from "environment";
import { createBrowserHistory } from "history";
// export default function configureStore(history?: History, initialState?: ApplicationState) {
//     const middleware = [
//         thunk,
//         // routerMiddleware(history),
//         checkTokenExpirationMiddleware
//     ];

//     const rootReducer = combineReducers({
//         ...reducers,
//         // router: connectRouter(history)
//     });

//     const enhancers = [];
//     const windowIfDefined = typeof window === 'undefined' ? null : window as any;
//     if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
//         enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
//     }

//     return createStore(
//         rootReducer,
//         initialState,
//         compose(applyMiddleware(...middleware), ...enhancers)
//     );
// }

const initialState = {};

const history = createBrowserHistory<any>({ basename: "/" });

const enhancers = [];
const isDev = nodeEnv !== "production";
const windowIfDefined = typeof window === "undefined" ? null : (window as any);
if (isDev && windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
  enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
}
const middleware = [
  environment.isDevelopment ? reduxFreeze : null,
  thunk,
  routerMiddleware(history),
  checkTokenExpirationMiddleware,
  // errorToastMiddleware()
].filter(Boolean);
const rootReducer = combineReducers({
  ...reducers,
  ...newReducers,
  router: connectRouter(history),
});

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
