import Avatar from 'react-avatar'
import React from 'react'
import { RiUserLine } from 'react-icons/ri'
import user from 'assets/images/icons/user-tie.svg';
import { MdAdd } from 'react-icons/md';


interface userCardProps{
    name?:string,
    image?:string,
    designation?:string,
    working_hr?:string,
    ot_hr?:string,
    isOpen?:boolean,
    isLarge?:boolean,
    onClick?:Function,
    isJustIcon?:boolean,
    isDanger?:boolean,
}

function UserCard({
        name,
        isOpen = false, 
        isLarge = false,
        isJustIcon = false,
        isDanger = false,
        ot_hr = '', 
        working_hr = '', 
        designation='',
        onClick,
        image
    }:userCardProps) {
    
    if(isJustIcon){
        return(
            <div 
                onClick={()=>onClick && onClick()}
                style={{background:'#F5F5F5'}} className="user_card">
                <div className="user_card-body" style={{width:'100%',textAlign:'center'}} >
                    <div className="user_card-name" style={{fontSize:18,color:'#656F78',lineHeight:0}}>
                        <MdAdd />
                    </div>
                </div>
            </div>
        )
    }

    if(isOpen){
        return(
            <div 
                onClick={()=>onClick && onClick()}
                className="user_card user_card-open">
                <div className="user_card-image">
                    {/* <Avatar 
                        src={user}
                        color="#E64DDB"
                        size={isLarge ? "32" : "24"}
                        name={""}
                        round
                    /> */}
                </div>
                <div className="user_card-body">
                    <div className="user_card-name">
                        <span>{ name ? name : 'Open' }</span>
                    </div>
                </div>
            </div>
        )
    }
    if(isOpen){
        return(
            <div 
                onClick={()=>onClick && onClick()}
                className="user_card user_card-open">
                <div className="user_card-image">
                    <Avatar 
                        src={user}
                        color="#E64DDB"
                        size={isLarge ? "32" : "24"}
                        name={""}
                        round
                    />
                </div>
                <div className="user_card-body">
                    <div className="user_card-name">
                        <span>{ name ? name : 'Open' }</span>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div 
            onClick={()=>onClick && onClick()}
            datatype={isDanger ? "Day Off" : ''}
            className={`user_card ${isLarge ? 'user_card-large' : ''} ${isDanger ? 'user_card-danger' : ''} `}>
            <div className="user_card-image">
                <Avatar 
                    src={image}
                    size={isLarge ? "32" : "24"}
                    name={name}
                    round
                />
            </div>
            {
                isLarge ? (
                    <div className="user_card-body">
                        <div className="user_card-name">
                            <span>{name}</span>
                        </div>
                        <div className="user_card-designation">
                            {designation}
                        </div>
                        <div className="user_card-times">
                            <div className="user_card-time">
                                {working_hr}
                            </div>
                            <div className="user_card-time">
                                OT {ot_hr}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="user_card-body">
                        <div className="user_card-name">
                            <span>{name}</span>
                        </div>
                    </div>
                )
            }
        </div>
    )
}


export default UserCard
