import React,{useEffect,useState} from 'react';
import Formgroup from 'components/form';

function Search() {
    const [search,setSearch] = useState('')
    return (
        <div className="roles-search">
            <Formgroup 
                formName="searchInput"
                value={search}
                type="search"
                name="search"
                className="search"
                placeholder="Search"
                onChange={(e)=>setSearch(e.target.value)}
            />
        </div>
    )
}

export default Search
