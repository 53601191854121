import React, { Component } from "react";
import {
  AiOutlineLeftSquare,
  AiOutlineDoubleRight,
  AiOutlineDoubleLeft,
} from "react-icons/ai";
import { IoMdArrowRoundBack, IoIosArrowBack } from "react-icons/io";
import RouteEnum from "constants/routeEnum";
import { useHistory } from "react-router-dom";

function RecruitBreadcrumb(props) {
  const history = useHistory();
  const { name, parentProps } = props && props;
  return (
    <div className="common-route-title">
      <span
        className="module"
        onClick={() => history.push(RouteEnum.RecruitDashboardPath)}
        style={{ paddingLeft: 0 }}
      >
        Dashboard
      </span>
      <span onClick={() => history.goBack()}>
        <AiOutlineDoubleLeft className="right-arrow" />
        <span className="module">Back</span>
      </span>
      <span className="path-name">{name}</span>
    </div>
  );
}

export default RecruitBreadcrumb;
