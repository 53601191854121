import React, { Component } from 'react';
import {
    PieChart, Pie, Sector, Cell,Legend,
  } from 'recharts';
import {connect} from 'react-redux';
import {getCommaFormattedNumber} from 'helpers/getFormattedNumber';
import isEmpty from 'isEmpty'

export const pieChartValue = (items) =>{
    return items ? items.map(item=>{
        let ret = {}
        ret.name = item.HeaderName;
        ret.value = item.Amount;
        ret.percantage = item.ValuePercent;
        return ret
    }):[]
}

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent, index,
  }) => {
     const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

export class PieCharts extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            data:[],
            x:170,
            y:70,
            innerRadius:50,
            outerRadius:70
        }
        this.renderColorfulLegendText = this.renderColorfulLegendText.bind(this);
        this._renderPieData = this._renderPieData.bind(this);

        
    }
    componentDidMount(){
      const {
        data,
        innerRadius,
        outerRadius,
        x,y
       } = this.props;
       this.setState({
        data:data?data:[],
        // innerRadius:innerRadius?innerRadius:50,
        // outerRadius:outerRadius?outerRadius:70,
        // x:x?x:170,
        // y:y?y:100,
       })
    }
    renderColorfulLegendText(value, entry) {
        const { color,payload:{percent} } = entry;
        return <span className="legend-label">
                    <span 
                        style={{backgroundColor:color}}
                        className="legend-bg">    
                    </span>
                    <span className="legend-label-name">{value}</span>
                    <span className="legend-label-value">{`${(percent * 100).toFixed(0)}%`}</span>
                </span>;
    }
    _renderPieData(item){
        const {payrollDashboard} = this.props;
        const data = payrollDashboard[item];
        let items = {
            PayDetailThisMonth : [
                {name:'Gross Pay',label:'CurrentGross',color:'text-blue'},
                {name:'Net Pay',label:'CurrentNetPay',color:'text-green'},
                {name:'Deductions',label:'CurrentDeduction',color:'text-orange'},
                {name:'Taxes',label:'CurrentMonthTax',color:'text-red'}
            ],
            PayDetailLastMonth : [
                {name:'Gross Pay',label:'PrevGross',color:'text-blue'},
                {name:'Net Pay',label:'PrevNetPay',color:'text-green'},
                {name:'Deductions',label:'PrevDeduction',color:'text-orange'},
                {name:'Taxes',label:'PrevMonthTax',color:'text-red'}
            ]
        };
        // const PayComponentThisMonth = ['PrevGross','PrevDeduction','PrevNetPay','PrevMonthTax']
        return(
            <div className="piecharts-data">
                {
                    items[item] && items[item].map(pay=>{
                        return(
                            <div className={`piecharts-data__item ${pay.color}`}>
                                <div className="piecharts-data__title">
                                    <span>{pay.name}</span>
                                </div>
                                <div className="piecharts-data__price">
                                    <span>
                                        {
                                            getCommaFormattedNumber(data[pay.label])
                                        }
                                    </span>
                                </div>
                            </div>
                        )
                    })
                }
        </div>
        )
    }
    render() {
        const {
            data,
            innerRadius,
            outerRadius,
            x,y
           } = this.state;
           if(isEmpty(this.props.payrollDashboard)){
              return  <span className="no-found">No Data Found</span>
           }
           const {
                PayDetailThisMonth:{PayComponentThisMonth} , 
                PayDetailLastMonth:{PayComponentLastMonth} 
            } = this.props.payrollDashboard;

        //    pieChartValue
        return (
            <div className="piecharts">
                <div className="piecharts-this">
                    <div className="piecharts-header">
                        <span>This month</span>
                    </div>
                   {this._renderPieData('PayDetailThisMonth')}
                    <div className="piecharts-chart">
                        <PieChart width={350} height={160}>
                            <Pie
                                data={pieChartValue(PayComponentThisMonth)}
                                cx={x}
                                cy={y}
                                labelLine={false}
                                innerRadius={innerRadius}
                                outerRadius={outerRadius}
                                // label={renderCustomizedLabel}
                                fill="#8884d8"
                                dataKey="value"
                            >
                            {
                                data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                            }
                                
                            </Pie>
                            <Legend
                                formatter={this.renderColorfulLegendText} 
                                // wrapperStyle 
                                width={200} 
                                // cx={1200} 
                                />
                        </PieChart>
                    </div>
                </div>
                <div className="piecharts-last">
                    <div className="piecharts-header">
                        <span>Last month</span>
                    </div>
                    {this._renderPieData('PayDetailLastMonth')}
                    <div className="piecharts-chart">
                        <PieChart width={350} height={160}>
                            <Pie
                                data={pieChartValue(PayComponentLastMonth)}
                                cx={x}
                                cy={y}
                                labelLine={false}
                                innerRadius={innerRadius}
                                outerRadius={outerRadius}
                                // label={renderCustomizedLabel}
                                fill="#8884d8"
                                dataKey="value"
                            >
                            {
                                data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                            }
                                
                            </Pie>
                            <Legend
                                formatter={this.renderColorfulLegendText} 
                                // wrapperStyle 
                                width={200} 
                                // cx={1200} 
                                />
                        </PieChart>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state =>({
    payrollDashboard:state.payrollDashboardReducer.payrollDashboard
})

export default connect(mapStateToProps,null)(PieCharts)
