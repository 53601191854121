import React from "react";
import AddDocument from "./addDocument";
import { Off, Mandatory } from "./enum";

function CoverLetterAndCV({
  fileCollection,
  resumeRequiredType,
  coverLetterRequiredType,
  onChange,
  errors
}) {
  const { coverLetterFile, cvFile } = fileCollection;

  return (
    <div className="form-block">
      <div className="flex attach__file">
        {coverLetterRequiredType && coverLetterRequiredType != Off && (
          <AddDocument
            text={"Attach Cover Letter"}
            selectedFile={coverLetterFile}
            isMandatory={coverLetterRequiredType == Mandatory}
            handleFileSelection={file =>
              onChange("fileCollection", "coverLetterFile", file)
            }
            error={errors && errors.coverLetterFile}
          />
        )}
        {/* {errors && errors.coverLetterFile && <div>{errors.coverLetterFile}</div>} */}
        {resumeRequiredType && resumeRequiredType != Off && (
          <AddDocument
            text={"Attach CV"}
            selectedFile={cvFile}
            isMandatory={resumeRequiredType == Mandatory}
            handleFileSelection={file =>
              onChange("fileCollection", "cvFile", file)
            }
            error={errors && errors.cvFile}
          />
        )}
        {errors && errors.cvFile && <div>{errors.cvFile}</div>}
      </div>
    </div>
  );
}

export default CoverLetterAndCV;
