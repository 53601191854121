import React from "react";
import { FaTrash } from "react-icons/fa";
import { connect } from "react-redux";
import { MdAdd } from "react-icons/md";
import Form from "components/form";

const radioOptions = [
  { label: "Defendant", value: 1 },
  { label: "Plaintiff", value: 2 },
  { label: "Petitioner", value: 3 },
  { label: "Respondant", value: 4 },
];

function CounterParties(props) {
  const addCounterParties = (option, key) => {
    props.addCounterParties(option, key);
  };

  const onChangeText = (name, value) => {
    props.changeCounterParties(name, value, "input");
  };

  const onChangeRadio = (name, value) => {
    props.changeCounterParties(name, value, "radio");
  };

  const { counterParties } = props.data;
  return (
    <div className="counter_parties">
      <div className="counter_parties__title">
        <label>{"Who is the counterpart to the case?"}</label>
        <label className="ml-bg">
          {"What is the position of the counter party in the case?"}
        </label>
      </div>
      {counterParties &&
        counterParties.map((data, index) => (
          <div key={index + "A" + data.key} className="form-row ">
            <div>
              <Form
                formName="textinput"
                name={`${data.key}`}
                value={data.value}
                onChange={onChangeText}
                placeHolder="Search or add new counterparty"
                width="300px"
              />
            </div>
            <div className="ml-md">
              <div className="flex">
                {radioOptions.map((radio, index) => {
                  return (
                    <Form
                      formName="radiogroup"
                      key={index}
                      name={`${data.key}`}
                      id={"radiogroup" + index + data.key} //id need to be unique for all
                      label={radio.label}
                      value={radio.value}
                      checked={radio.value == data.selected}
                      onChange={onChangeRadio}
                    />
                  );
                })}
                <span
                  className="counter_parties-trash"
                  onClick={() => addCounterParties("remove", data.key)}
                >
                  <FaTrash />
                </span>
              </div>
            </div>
          </div>
        ))}
      <div className="add">
        <button
          onClick={() => addCounterParties("add")}
          className="btn btn-outline add-content"
        >
          <span className="add-content-icon">
            <MdAdd />
          </span>
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  // documentInfo:
  //   (state.employeeProfile.employeeProfile &&
  //     state.employeeProfile.employeeProfile.Identification) ||
  //   [],
});

export default connect(mapStateToProps, null)(CounterParties);
