import {
  DisplayClientSideMessage,
  GetCountryList,
  GetCurrencyList,
  GetDistrictList,
  GetEmployeeList,
} from "actions/commonAction";
import man from "assets/images/man.png";
import CommonEmployeeSearch from "components/common/employeeSearch";
import Button from "components/button";
import Loader from "components/loaders";
import {Tab, Tabs} from "components/tabs/index.d";
import {ADMIN_ROLE_ID} from "constants/constants";
import {RoleId} from "constants/userDetails";
import {ValidationComponent} from "helpers/getComponents";
import isEmpty from "isEmpty";
import moment from "moment";
import React, {Component} from "react";
import { EmployeeID } from "constants/userDetails";
import {Link} from "react-router-dom";
// import { Avatar } from "@material-ui/core";
import Avatar from "react-avatar";
import SelectEmployee from "scenes/employee/selectEmployee";
import {FaExclamationCircle} from "react-icons/fa";
import {MdClose} from "react-icons/md";
import AutoSelect from "components/common/autoSelect";
import {connect} from "react-redux";
import CommonCommentsHistory from "../../commonLayout/commentsHistory";
import CreateTravelInformationTab from "../../commonLayout/travelInformationTab";
import {
  ClearAdvanceAndExpensesList,
  EditTravelRequests,
  GetAdvanceAndExpensesList,
  GetApprovalAuthorities,
  GetApprovalHistory,
  GetTravelCategoryList,
  GetTravelHostAndHotel,
  GetTravelModeOfTransport,
  RejectTravelRequest,
  SaveAllTravelRequest,
} from "../actions";
import CreateApproversTab from "./createApproversTab";
import CreateAdvanceAndExpenses from "./createAdvanceAndExpenses";
import CreateDelegationTab from "./createDelegationTab";
import CreateDocumentsTab from "./createDocumentsTab";
import CreateGroupTab from "./createGroupTab";
import {BS2AD, AD2BS} from "helpers/dateConvert";
import {Date_Format, Nepali_Date_Format} from "helpers/dateFormat";
import Loaders from "components/loaders";
import RouteEnum from "constants/routeEnum";
import {getUploadPath} from "constants/userDetails";

function createTravelDestination() {
  return {
    selectedDistrict: [],
    placeName: null,
    selectedModeOfTransport: [],
    startDate: null,
    endDate: null,
    startDateEng: null,
    endDateEng: null,
    days: 0,
    nights: 0,
    hostName: null,
    selectedHost: [],
    hotelName: null,
    selectedHotel: [],
    phoneNumber: "",
  };
}

function removeFirstZeroFromInteger(value) {
  // parseInt(PhoneNumber, "10")  //it also works
  if (!isEmpty(value) && value > 0 && value.substr(0, 1) == 0) {
    value = value.substr(1);
  }
  return value;
}
export class CreateNewTravelRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TravelInformationDestinationDto: [],
      TravelInformationGroupDto: [],
      TravelInforamtionAllowanceExpenseDetailsDto: [],
      TravelInformationDocumentsDto: [],
      TravelInformationDto: {
        RequestId: 0,
        TravelType: 0,
        DestinationType: 0,
        Reason: null,
        // RequestedBy: 1,
        // RequestedOn: "2076-08-17",
        // RequestedOnEng: "2019-12-04",
        StartDate: null,
        EndDate: null,
        StartDateEng: null,
        EndDateEng: null,
        AdvanceRequestAmt: 0,
        NoteForAdvanceRequest: null,

        LeaveApprovalDelegationId: 0, //done
        IsDelegateLeave: true, //done
        TimeApprovalDelegateId: 0, //done
        IsDelegateTime: true, //done
        TravelApprovalDelegateId: 0, //done
        IsDelegateTravel: true, //done

        CountryCode: 0,
        CurrencyCode: null, //done
        ApprovalEmployeeId: 0,
        StatusId: 0, //save as draft=0 save=1
        IsGroup: 0, //only me=0 group=1
        GroupRequestLinkId: null,
        StatusName: null,
        ApprovedBy: 0,
        ApprovedOn: null,
        ApprovedOnEng: new Date().toJSON(),
        IsHRAssigned: false,
        IsCancelled: false,
        RequestNumber: null,
        RequestedBy: ""
      },

      travelInformationState: {
        travelRequestId: 0,
        travelTypeId: 1,
        destinationTypeId: 1,
        selectedTravelDestList: [{key: 0, travelDestination: createTravelDestination()}],
        selectedCountry: null,
        selectedCurrency: null,
        reason: "",
        RequestedOn: "",
        RequestedBy: '',
        StatusId: 0,
        RequestedOnEng: new Date().toJSON(),
        employeeToAssign: null,
      },

      delegationState: {
        selectedSubstitutePerson: null,
        delegationList: [
          {
            ApprovalTypeId: 1,
            ApprovalType: "Leave Approval",
            SubstituteId: 0,
            SubstituteName: "-",
            IsDelegate: true,
            IsChangeClicked: false,
          },
          {
            ApprovalTypeId: 2,
            ApprovalType: "Travel Approval",
            SubstituteId: 0,
            SubstituteName: "-",
            IsDelegate: true,
            IsChangeClicked: false,
          },
          {
            ApprovalTypeId: 3,
            ApprovalType: "Time Approval",
            SubstituteId: 0,
            SubstituteName: "-",
            IsDelegate: true,
            IsChangeClicked: false,
          },
        ],
        isSubstituteSelected: true,
      },

      groupState: {
        groupTypeId: 1,
        selectedTravellerList: [],
      },

      advanceAndExpensesState: {
        selectedTravelCategory: null,
        advanceRequestAmount: 0,
        note: "",
      },

      documentsState: {
        fileList: [],
        succesFileInfoList: [],
        isFileUploading: false,
      },

      approverState: {
        selectedApprovalList: [],
        selectedContributorList: [],
      },

      create_tabs: [
        {
          label: "Travel Information",
          id: 0,
          component: CreateTravelInformationTab,
        },
        {label: "Delegation", id: 1, component: CreateDelegationTab},
        {label: "Group", id: 2, component: CreateGroupTab},
        {
          label: "Advance & Expenses",
          id: 3,
          component: CreateAdvanceAndExpenses,
        },
        {label: "Documents", id: 4, component: CreateDocumentsTab},
        {label: "Approval", id: 5, component: CreateApproversTab},
      ],

      selectedCreateTabId: 0,
      isAllRequiredFieldValid: true,
      isEditPage: false,
      isViewPage: false,
      isEditDataFetched: false,
      submitStatusId: 1,
      RequestRef_Id: 0,
      selectedEmployee: {error: "", value: 0},
      isModalShow: false,
      roleId: null,
      isLoading: true,
    };
    this.commonComments = "";
    this.handleEscape = this.handleEscape.bind(this);
  }

  async componentDidMount() {
    await this.initialize();

    let selectedTabId = null;
    let isAssign = false;
    let isEditPage = false;
    let isViewPage = false;
    let travelRequestId = 0;
    let requestDetails = "";

    if (this.props.location && !isEmpty(this.props.location.state)) {
      //for same tab view
      selectedTabId = this.props.location.state.selectedTabId || null;
      isAssign = this.props.location.state.isAssign;
      isEditPage = !isEmpty(this.props.location.state.isEditPage)
        ? this.props.location.state.isEditPage
        : false;
      isViewPage = !isEmpty(this.props.location.state.isViewPage)
        ? this.props.location.state.isViewPage
        : false;
      travelRequestId = !isEmpty(this.props.location.state.travelRequestId)
        ? this.props.location.state.travelRequestId
        : 0;
      requestDetails = this.props.location.state.requestDetails;
    } else if (localStorage.travelRequestDataToNewTab) {
      //for new tab view
      let travelRequestDataToNewTab = JSON.parse(localStorage.travelRequestDataToNewTab);

      selectedTabId = travelRequestDataToNewTab.selectedTabId || null;
      isAssign = travelRequestDataToNewTab.isAssign || false;
      isEditPage = travelRequestDataToNewTab.isEditPage || false;
      isViewPage = travelRequestDataToNewTab.isViewPage || false;
      travelRequestId = travelRequestDataToNewTab.travelRequestId || 0;
      requestDetails = travelRequestDataToNewTab.requestDetails || 0;

      localStorage.removeItem("travelRequestDataToNewTab");
    } else {
      // // for model view
      // selectedTabId = this.props.selectedTabId || null;
      // isAssign = this.props.isAssign;
      // isEditPage = this.props.isEditPage;
      // isViewPage = this.props.isViewPage;
      // travelRequestId = this.props.travelRequestId || 0;
      // requestDetails=this.props.requestDetails
    }

    const roleId = RoleId();
    this.setState({
      selectedTabId,
      isAssign,
      isEditPage,
      isViewPage,
      travelRequestId,
      roleId,
    }, async () => {
      
      const empId = EmployeeID();
      if (!isAssign)
        await this.getApproverDetails(travelRequestId, this.state.submitStatusId, empId);
      if (isEditPage || isViewPage) {
        this.setState({requestDetails});
        await this.props.EditTravelRequests(travelRequestId);
      } else {
        this.setState({isLoading: false});
      }
    });


    // document.addEventListener("keyup", this.handleEscape);
  }

  componentWillUnmount() {
    // document.removeEventListener("keyup", this.handleEscape);
  }

  handleEscape(e) {
    if (e.key === "Escape") {
      this.setState({isModalShow: true});
      // this.props.history && this.props.history.goBack();
    }
  }

  async componentDidUpdate() {
    let {
      isEditPage,
      isViewPage,
      travelInformationState,
      delegationState,
      groupState,
      advanceAndExpensesState,
      documentsState,
      approverState,
      travelRequestId,
      requestDetails,
    } = this.state;

    const {
      travelInformationToEdit,
      countryList,
      currencyList,
      districtList,
      modeOfTransportList,
      travelHostList,
      travelHotelList,
      employeeList,
      travelCategoryList,
      travelEditLoading,
      companyInformation,
    } = this.props;
    let isNepaliDate = !companyInformation.IsEnglishDate;
    let travelData = [
      travelInformationToEdit,
      countryList,
      //currencyList,
      districtList,
      modeOfTransportList,
      // travelHostList,
      // travelHotelList,
      // employeeList,
      travelCategoryList,
    ];
    let emptyDataList = travelData.filter((x) => (isEmpty(x) ? true : false));
    const employeeListWithPhoto = await this.props.employeeListWithPhoto;
    const {isEditDataFetched} = this.state;

    if (
      (isEditPage || isViewPage) &&
      isEmpty(emptyDataList) &&
      !isEditDataFetched &&
      travelRequestId === travelInformationToEdit.TravelInformationDto.RequestId &&
      !travelEditLoading
    ) {
      this.setState({isEditDataFetched: true});

      let TravelInformationDtoToEdit = travelInformationToEdit.TravelInformationDto;
      let TravelInformationDestinationDtoToEdit =
        travelInformationToEdit.TravelInformationDestinationDto;
      let TravelInformationGroupDtoToEdit =
        travelInformationToEdit.TravelInformationGroupDto;
      let TravelInforamtionAllowanceExpenseDetailsDtoToEdit =
        travelInformationToEdit.TravelInformationAllowanceExpenseDetailsDto;
      let TravelInformationDocumentsDtoToEdit =
        //travelInformationToEdit.TravelInformationDocumentsDto;
        travelInformationToEdit.TravelAttachment;

      //Travel Information Tab starts
      let selectedCountry = countryList.find(
        (f) => f.value == TravelInformationDtoToEdit.CountryId
      );
      let selectedCurrency = currencyList.find(
        (f) => f.label == TravelInformationDtoToEdit.CurrencyCode
      );

      let newSelectedTravelDestList = [];
      TravelInformationDestinationDtoToEdit.map((destination, index) => {
        let selectedDistrict = districtList.find(
          (f) => f.value == destination.DistrictRefId
          // f => f.value == 4
        );

        let TransportModeIdList = destination.TransportModeRefId.split(",");
        // let TransportModeIdList = ("1,2").split(",");
        let selectedModeOfTransport = [];

        if (modeOfTransportList && modeOfTransportList.length > 0) {
          TransportModeIdList.map((tm) => {
            let currentModeOfTransport = modeOfTransportList.find((f) => f.value == tm);
            if (!isEmpty(currentModeOfTransport)) {
              selectedModeOfTransport.push(currentModeOfTransport);
            }
          });
        }

        let selectedHost = travelHostList.find((f) => f.label == destination.HostName);

        let selectedHotel = travelHotelList.find((f) => f.label == destination.HotelName);

        let startDate = "";
        if (destination.StartDateEng) {
          startDate = Nepali_Date_Format(destination.StartDateEng);
          if (isNepaliDate) {
            let conversionDate = AD2BS(startDate).en;
            startDate = `${conversionDate.year}/${conversionDate.month}/${conversionDate.day}`;
          }
        }

        let endDate = "";
        if (destination.EndDateEng) {
          endDate = Nepali_Date_Format(destination.EndDateEng);
          if (isNepaliDate) {
            let conversionDate = AD2BS(endDate).en;
            endDate = `${conversionDate.year}/${conversionDate.month}/${conversionDate.day}`;
          }
        }

        let newTravelDestination = {
          selectedDistrict: !isEmpty(selectedDistrict) ? selectedDistrict : [],
          placeName: destination.PlaceName,
          selectedModeOfTransport: selectedModeOfTransport,
          // !isEmpty(destination.StartDate)
          //   ? moment(destination.StartDate).format("YYYY/MM/DD HH:mm:ss")
          //   : null,
          // !isEmpty(destination.EndDate)
          //   ? moment(destination.EndDate).format("YYYY/MM/DD HH:mm:ss")
          //   : null,
          startDate: destination.StartDate,
          endDate: destination.EndDate,
          startDateEng: startDate,
          endDateEng: endDate,
          days: destination.TransportDays,
          nights: destination.TransportNights,
          hostName: destination.HostName,
          selectedHost: !isEmpty(selectedHost) ? selectedHost : [],
          hotelName: destination.HotelName,
          selectedHotel: !isEmpty(selectedHotel) ? selectedHotel : [],
          phoneNumber: destination.PhoneNo,
        };
        newSelectedTravelDestList.push({
          key: index,
          travelDestination: newTravelDestination,
        });
      });

      this.getOverAllDates(newSelectedTravelDestList);

      travelInformationState["travelRequestId"] = TravelInformationDtoToEdit.RequestId;
      travelInformationState["RequestedOn"] = TravelInformationDtoToEdit.RequestedOn;
      travelInformationState["RequestedOnEng"] =
        TravelInformationDtoToEdit.RequestedOnEng;
      travelInformationState["travelTypeId"] = TravelInformationDtoToEdit.TravelType;
      travelInformationState["destinationTypeId"] =
        TravelInformationDtoToEdit.DestinationType;
      travelInformationState["selectedCountry"] = !isEmpty(selectedCountry)
        ? selectedCountry
        : [];
      travelInformationState["selectedCurrency"] = !isEmpty(selectedCurrency)
        ? selectedCurrency
        : [];
      travelInformationState["reason"] = TravelInformationDtoToEdit.Reason;
      travelInformationState["selectedTravelDestList"] = newSelectedTravelDestList;
      travelInformationState["RequestedBy"] = TravelInformationDtoToEdit.RequestedBy;
      travelInformationState["StatusId"] = TravelInformationDtoToEdit.StatusId;

      //Travel Information Tab ends

      // Delegation Tab Starts

      let newDelegateList = [];

      delegationState.delegationList.map((m, i) => {
        if (m.ApprovalType == "Leave Approval") {
          let nameToFill = "-";
          let selectedEmployee =
            employeeListWithPhoto &&
            employeeListWithPhoto.find(
              (f) => f.value == TravelInformationDtoToEdit.LeaveApprovalDelegationId
            );
          if (!isEmpty(selectedEmployee)) {
            nameToFill = selectedEmployee.label;
          }
          m.SubstituteId = TravelInformationDtoToEdit.LeaveApprovalDelegationId;
          m.SubstituteName = nameToFill;
          m.IsDelegate = TravelInformationDtoToEdit.IsDelegateLeave;
        } else if (m.ApprovalType == "Travel Approval") {
          let nameToFill = "-";
          let selectedEmployee =
            employeeListWithPhoto &&
            employeeListWithPhoto.find(
              (f) => f.value == TravelInformationDtoToEdit.TravelApprovalDelegateId
            );
          if (!isEmpty(selectedEmployee)) {
            nameToFill = selectedEmployee.label;
          }
          m.SubstituteId = TravelInformationDtoToEdit.TravelApprovalDelegateId;
          m.SubstituteName = nameToFill;
          m.IsDelegate = TravelInformationDtoToEdit.IsDelegateTravel;
        } else if (m.ApprovalType == "Time Approval") {
          let nameToFill = "-";
          let selectedEmployee =
            employeeListWithPhoto &&
            employeeListWithPhoto.find(
              (f) => f.value == TravelInformationDtoToEdit.TimeApprovalDelegateId
            );
          if (!isEmpty(selectedEmployee)) {
            nameToFill = selectedEmployee.label;
          }
          m.SubstituteId = TravelInformationDtoToEdit.TimeApprovalDelegateId;
          m.SubstituteName = nameToFill;
          m.IsDelegate = TravelInformationDtoToEdit.IsDelegateTime;
        }

        newDelegateList.push(m);
      });

      delegationState["delegationList"] = newDelegateList;

      //Delegation Tab Ends

      //Group Tab Starts
      let newSelectedTravellerList = [];
      let isGroup = false;
      TravelInformationGroupDtoToEdit.map((m, i) => {
        isGroup = m.IsGroup;
        let selectedEmployeeListWithPhoto =
          employeeListWithPhoto &&
          employeeListWithPhoto.find((f) => f.value == m.EmployeeId);
        if (!isEmpty(selectedEmployeeListWithPhoto)) {
          newSelectedTravellerList.push(selectedEmployeeListWithPhoto);
        }
      });
      let requesterInfo =
        employeeListWithPhoto &&
        employeeListWithPhoto.find(
          (f) => f.value == TravelInformationDtoToEdit.RequestedBy
        );
      groupState["groupTypeId"] = isGroup ? 2 : 1;
      groupState["selectedTravellerList"] = newSelectedTravellerList;
      groupState["requesterInformation"] = requesterInfo;
      //Group Tab Ends

      //AdvanceAndExpenses Tab starts
      const {overAllDestinationFromDate, overAllDestinationToDate} = this.state;

      let categoryId = 0;
      if (TravelInforamtionAllowanceExpenseDetailsDtoToEdit.length > 0) {
        const firstDate = TravelInforamtionAllowanceExpenseDetailsDtoToEdit[0];
        categoryId = firstDate.CategoryRefId;
      }

      let travelCategory = travelCategoryList.find((f) => f.value == categoryId);

      if (
        !isEmpty(overAllDestinationFromDate) &&
        !isEmpty(overAllDestinationToDate) &&
        categoryId !== 0
      ) {
        this.props.GetAdvanceAndExpensesList({
          categoryId,
          overAllDestinationFromDate,
          overAllDestinationToDate,
        });
      }

      advanceAndExpensesState["selectedTravelCategory"] = !isEmpty(travelCategory)
        ? travelCategory
        : [];
      advanceAndExpensesState["advanceRequestAmount"] =
        TravelInformationDtoToEdit.AdvanceRequestAmt;
      advanceAndExpensesState["note"] = TravelInformationDtoToEdit.NoteForAdvanceRequest;

      //AdvanceAndExpenses Tab ends

      //Document section starts

      let newSuccesFileInfoList = [];
      TravelInformationDocumentsDtoToEdit.map((m, i) => {
        newSuccesFileInfoList.push({
          AttachmentId: m.AttachmentId,
          OriginalFileName: m.UserFileName,
          GeneratedFileName: m.ServerFileName,
          FileLocation: m.FileLocation,
          DocumentPath: m.FileLocation,
          Size: m.Size,
        });
      });

      documentsState["succesFileInfoList"] = newSuccesFileInfoList;

      //Document section ends

      //Approver section starts
      let {selectedApprovalList} = approverState;

      if (!isEmpty(this.props.approvalAuthorities.value)) {
        let selectedApproval = this.props.approvalAuthorities.value.AuthoritiesList.find(
          (f) => f.value == TravelInformationDtoToEdit.ApprovalEmployeeId
        );

        selectedApproval && selectedApprovalList.push(selectedApproval);

        approverState["selectedApprovalList"] = selectedApprovalList;
      }
      //Approver section ends

      let statusId =
        isEmpty(TravelInformationDtoToEdit.StatusId) ||
        (!isEmpty(requestDetails) && requestDetails.update)
          ? 1
          : TravelInformationDtoToEdit.StatusId;
      this.setState({
        travelInformationState,
        delegationState,
        groupState,
        advanceAndExpensesState,
        documentsState,
        approverState,
        submitStatusId: statusId,
        RequestRef_Id: TravelInformationDtoToEdit.RequestId,
        isLoading: false,
      });
      const empId = EmployeeID();

      await this.getApproverDetails(this.state.travelRequestId, statusId, this.state.isAssign ? 0 : empId);
    }
  }

  getApproverDetails = async (requestId, statusId, empId) => {
    const {GetApprovalAuthorities, GetApprovalHistory} = this.props;

    let ajaxApi = [
      GetApprovalAuthorities(statusId, empId ? empId : 0),
      GetApprovalHistory(requestId),
    ];
    Promise.all(
      ajaxApi.map(async (item) => {
        return await item;
      })
    );
    //await this.props.GetApprovalAuthorities(statusId);
    // //if (statusId !== 1) {
    //await this.props.GetApprovalHistory(requestId);
    // // }
    if (
      !this.props.approvalAuthorities.loading &&
      isEmpty(this.props.approvalAuthorities.value)
      && this.state.roleId === ADMIN_ROLE_ID
    ) {
      this.setState({submitStatusId: 15});
    }
  };

  initialize = async () => {
    const {
      ClearAdvanceAndExpensesList,
      GetCountryList,
      GetCurrencyList,
      GetDistrictList,
      GetTravelModeOfTransport,
      GetTravelHostAndHotel,
      GetEmployeeList,
      GetTravelCategoryList,
    } = this.props;
    let ajaxApi = [
      ClearAdvanceAndExpensesList(),
      GetCountryList(),
      GetCurrencyList(),
      GetDistrictList(),
      GetTravelModeOfTransport(),
      GetTravelHostAndHotel(),
      GetEmployeeList(),
      GetTravelCategoryList(1),
    ];
    const roleId = RoleId();

    // if (roleId === ADMIN_ROLE_ID)
    Promise.all(
      ajaxApi.map(async (item) => {
        return await item;
      })
    );
    // this.props.ClearAdvanceAndExpensesList();
    // this.props.GetCountryList();
    // this.props.GetCurrencyList();
    // this.props.GetDistrictList();
    // this.props.GetTravelModeOfTransport();
    // this.props.GetTravelHostAndHotel();
    // this.props.GetEmployeeList();
    // this.props.GetAllEmployee ();
    // this.props.GetTravelCategoryList();
  };

  getOverAllDates = (selectedTravelDestList) => {
    let overAllDestinationFromDate = null;
    let overAllDestinationToDate = null;
    if (!isEmpty(selectedTravelDestList)) {
      let selectedTravelDestLength = selectedTravelDestList.length;

      overAllDestinationFromDate = !isEmpty(
        selectedTravelDestList[0].travelDestination.startDateEng
      )
        ? selectedTravelDestList[0].travelDestination.startDateEng
        : null;
      overAllDestinationToDate = !isEmpty(
        selectedTravelDestList[selectedTravelDestLength - 1].travelDestination.endDateEng
      )
        ? selectedTravelDestList[selectedTravelDestLength - 1].travelDestination
            .endDateEng
        : null;
    }

    this.setState({
      overAllDestinationFromDate,
      overAllDestinationToDate,
    });
  };

  handleTravelInfoTabState = (name, value, isDestinationDateChanged) => {
    let {travelInformationState} = this.state;
    travelInformationState[name] = value;
    this.setState({travelInformationState});
    if (isDestinationDateChanged) {
      this.getOverAllDates(value);
    }
  };

  handleDelegationTabState = (name, value) => {
    let {delegationState} = this.state;
    delegationState[name] = value;
    this.setState({delegationState});
  };

  handleGroupTabState = (name, value) => {
    let {groupState} = this.state;
    groupState[name] = value;
    this.setState({groupState});
  };

  handleAdvanceAndExpensesTabState = (name, value) => {
    let {advanceAndExpensesState} = this.state;

    if (name == "advanceRequestAmount") {
      value = removeFirstZeroFromInteger(value);
    }
    advanceAndExpensesState[name] = value;
    this.setState({advanceAndExpensesState});
  };

  handleDocumentsTabState = (name, value) => {
    let {documentsState} = this.state;

    documentsState[name] = value;

    this.setState({documentsState});
  };

  handleApproversTabState = (name, value) => {
    let {approverState} = this.state;
    approverState[name] = value;
    this.setState({approverState: approverState});
  };

  manageTravelInformationTabData = async (statusId, id) => {
    let {
      TravelInformationDto,
      travelInformationState,
      overAllDestinationFromDate,
      overAllDestinationToDate,
      RequestRef_Id,
      selectedEmployee,
      submitStatusId,
      isAssign,
      advanceAndExpensesState,
      isEditPage
    } = this.state;
    let TravelInformationDestinationDto = [];
    let isAllRequiredFieldValid = false;
    let showMessage = false;
    if (id == 4) {
      if (!isEmpty(advanceAndExpensesState)) {
        const {selectedTravelCategory} = advanceAndExpensesState;
        if (isEmpty(selectedTravelCategory)) showMessage = true;
      } else showMessage = true;
    }
    if (showMessage) {
      const {travelCategoryList} = this.props;
      if (isEmpty(travelCategoryList)) {
        let message = {
          MessageType: "danger",
          Message:
            "Travel Configuration Rules are not complete, Please talk to your HR Department",
        };
        this.props.DisplayClientSideMessage(message);
      }
      return showMessage;
    }
    if (!isEmpty(travelInformationState)) {
      const {
        travelRequestId,
        RequestedOn,
        RequestedOnEng,
        selectedTravelDestList,
        travelTypeId,
        selectedCountry,
        selectedCurrency,
        destinationTypeId,
        reason,
        employeeToAssign,
        RequestedBy
      } = travelInformationState;

      let countryId = 0;
      let currencyCode = "";
      if (travelTypeId == 2) {
        if (!isEmpty(selectedCountry)) {
          countryId = selectedCountry.value;
        }

        if (!isEmpty(selectedCurrency)) {
          currencyCode = selectedCurrency.label;
        }
      }

      //Fill TravelInformationDto data section starts
      TravelInformationDto.RequestId = travelRequestId;
      TravelInformationDto.RequestedOn = RequestedOn;
      TravelInformationDto.RequestedOnEng = RequestedOnEng;
      TravelInformationDto.StatusId = statusId;
      TravelInformationDto.TravelType = travelTypeId;
      TravelInformationDto.CountryId = countryId;
      TravelInformationDto.CurrencyCode = currencyCode;
      TravelInformationDto.DestinationType = destinationTypeId;
      TravelInformationDto.Reason = !isEmpty(reason) ? reason : null;
      TravelInformationDto.StartDate = moment(overAllDestinationFromDate)
        .format("YYYY/MM/DD")
        .toString();
      TravelInformationDto.EndDate = moment(overAllDestinationToDate)
        .format("YYYY/MM/DD")
        .toString();
      const {companyInformation} = this.props;
      let isNepaliDate = !companyInformation.IsEnglishDate;
      let overallEngStartDate = isNepaliDate && BS2AD(TravelInformationDto.StartDate);

      let overallEngEndDate = isNepaliDate && BS2AD(TravelInformationDto.EndDate);
      TravelInformationDto.StartDateEng = isNepaliDate
        ? `${overallEngStartDate?.year}/${overallEngStartDate?.month}/${overallEngStartDate?.day}`
        : overAllDestinationFromDate;
      TravelInformationDto.EndDateEng = isNepaliDate
        ? `${overallEngEndDate?.year}/${overallEngEndDate?.month}/${overallEngEndDate?.day}`
        : overAllDestinationToDate;
      TravelInformationDto.RequestedBy = isEditPage ? RequestedBy : 0;
      //Fill TravelInformationDto data section ends

      (await selectedTravelDestList) &&
        selectedTravelDestList.map((data) => {
          let destination = data.travelDestination;

          const districtId =
            travelTypeId == 1 && !isEmpty(destination.selectedDistrict)
              ? destination.selectedDistrict.value
              : null;

          let transferModes = "";
          destination.selectedModeOfTransport.map((mode, index) => {
            if (index > 0) {
              transferModes = transferModes + "," + mode.value;
            } else {
              transferModes = mode.value;
            }
          });

          // const hostId = !isEmpty(destination.selectedHost)
          //   ? destination.selectedHost.value
          //   : null;

          // const hotelId = !isEmpty(destination.selectedHotel)
          //   ? destination.selectedHotel.value
          //   : null;

          if (
            // (!isAssign || (isAssign && employeeToAssign)) &&
            (travelTypeId == 2 || districtId > 0) &&
            !isEmpty(destination.placeName) &&
            !isEmpty(transferModes) &&
            !isEmpty(destination.startDateEng) &&
            !isEmpty(destination.endDateEng) &&
            !isEmpty(TravelInformationDto.Reason)
            //&&
            //// !isEmpty(destination.days) &&parseFloat(destination.days)>0 &&
            //// !isEmpty(destination.nights) &&parseFloat(destination.nights)>0 &&
            // (!isEmpty(destination.hostName) ||
            //   !isEmpty(destination.hotelName)) &&
            // !isEmpty(destination.phoneNumber)
          ) {
            isAllRequiredFieldValid = true;
            let startDate = moment(destination.startDateEng)
              .format("YYYY/MM/DD")
              .toString();
            let endDate = moment(destination.endDateEng).format("YYYY/MM/DD").toString();
            let engStartDate = isNepaliDate && BS2AD(startDate);
            let engEndDate = isNepaliDate && BS2AD(endDate);

            TravelInformationDestinationDto.push({
              // IsAssigned: isAssign || false,
              // EmployeeID: isAssign ? employeeToAssign.value : null,
              RequestRefId: RequestRef_Id,
              DistrictRefId: districtId,
              PlaceName: destination.placeName,
              TransportModeRefId: transferModes,
              StartDate: startDate,
              EndDate: endDate,
              StartDateEng: isNepaliDate
                ? `${engStartDate?.year}/${engStartDate?.month}/${engStartDate?.day}`
                : startDate,
              EndDateEng: isNepaliDate
                ? `${engEndDate?.year}/${engEndDate?.month}/${engEndDate?.day}`
                : endDate,
              TransportDays: destination.days,
              TransportNights: destination.nights,
              HostName: destination.hostName,
              HotelName: destination.hotelName,
              PhoneNo: destination.phoneNumber,
            });
          } else {
            isAllRequiredFieldValid = false;
          }
        });
    }

    if (
      this.state.roleId === ADMIN_ROLE_ID &&
      !this.state.isViewPage &&
      !this.state.isEditPage &&
      selectedEmployee.value === 0
    ) {
      isAllRequiredFieldValid = false;
    }
    this.setState((prevState) => ({
      TravelInformationDestinationDto: TravelInformationDestinationDto,
      TravelInformationDto: TravelInformationDto,
      isAllRequiredFieldValid: isAllRequiredFieldValid,
      selectedEmployee: {
        ...prevState.selectedEmployee,
        error: selectedEmployee.value === 0 ? "Required" : "",
      },
    }));
  };

  manageDelegateTabData = async () => {
    let {delegationState, TravelInformationDto} = this.state;

    if (!isEmpty(delegationState)) {
      const {delegationList} = delegationState;

      let IsDelegateLeave = true;
      let LeaveApprovalDelegationId = 0;
      let IsDelegateTravel = true;
      let TravelApprovalDelegateId = 0;
      let IsDelegateTime = true;
      let TimeApprovalDelegateId = 0;

      (await delegationList) &&
        delegationList.map((m) => {
          if (m.ApprovalType === "Leave Approval") {
            IsDelegateLeave = m.IsDelegate;
            LeaveApprovalDelegationId = m.SubstituteId;
          } else if (m.ApprovalType === "Travel Approval") {
            IsDelegateTravel = m.IsDelegate;
            TravelApprovalDelegateId = m.SubstituteId;
          } else if (m.ApprovalType === "Time Approval") {
            IsDelegateTime = m.IsDelegate;
            TimeApprovalDelegateId = m.SubstituteId;
          }
        });

      //Fill TravelInformationDto data section starts
      TravelInformationDto.LeaveApprovalDelegationId = LeaveApprovalDelegationId;
      TravelInformationDto.IsDelegateLeave = IsDelegateLeave;
      TravelInformationDto.TimeApprovalDelegateId = TimeApprovalDelegateId;
      TravelInformationDto.IsDelegateTime = IsDelegateTime;
      TravelInformationDto.TravelApprovalDelegateId = TravelApprovalDelegateId;
      TravelInformationDto.IsDelegateTravel = IsDelegateTravel;
      //Fill TravelInformationDto data section ends
    }

    this.setState({TravelInformationDto: TravelInformationDto});
  };

  manageGroupTabData = async () => {
    let {
      TravelInformationDto,
      groupState,
      RequestRef_Id,
      isViewPage,
      isEditPage,
      selectedEmployee,
    } = this.state;
    let TravelInformationGroupDto = [];
    if (!isEmpty(groupState)) {
      const {groupTypeId, selectedTravellerList} = groupState;
      const {myDetail} = this.props;

      let isGroup = groupTypeId == 2 ? true : false;

      //Fill TravelInformationDto data section starts
      TravelInformationDto.IsGroup = isGroup;
      //Fill TravelInformationDto data section ends

      if (isGroup) {
        (await selectedTravellerList) &&
          selectedTravellerList.map((m) => {
            TravelInformationGroupDto.push({
              RequestId: RequestRef_Id,
              IsGroup: isGroup,
              EmployeeId: m.value,
            });
          });
      } else if (!isGroup && !isEmpty(myDetail)) {
        let requesterId =
          isViewPage || isEditPage
            ? this.props.travelInformationToEdit.TravelInformationDto.RequestedBy
            : this.state.roleId === ADMIN_ROLE_ID
            ? selectedEmployee.value
            : myDetail.value;
        TravelInformationGroupDto.push({
          RequestId: RequestRef_Id,
          IsGroup: isGroup,
          EmployeeId: requesterId,
        });
      }
    }

    this.setState({
      TravelInformationGroupDto: TravelInformationGroupDto,
      TravelInformationDto: TravelInformationDto,
    });
  };

  manageAdvanceAndExpensesTabData = async (statusId) => {
    let {TravelInformationDto, advanceAndExpensesState, RequestRef_Id} = this.state;
    let TravelInforamtionAllowanceExpenseDetailsDto = [];
    const {selectedAdvanceAndExpensesList, travelCategoryList} = this.props;

    if (
      // isEmpty(selectedTravelCategory) &&
      // statusId == 1 &&
      isEmpty(travelCategoryList)
    ) {
      let message = {
        MessageType: "danger",
        Message:
          "Travel Configuration Rules are not complete, Please talk to your HR Department",
      };
      this.props.DisplayClientSideMessage(message);
      return;
    }
    if (!isEmpty(advanceAndExpensesState)) {
      const {advanceRequestAmount, note, selectedTravelCategory} =
        advanceAndExpensesState;
      // const { selectedAdvanceAndExpensesList, travelCategoryList } = this.props;

      //Fill TravelInformationDto data section starts
      TravelInformationDto.AdvanceRequestAmt = advanceRequestAmount;
      TravelInformationDto.NoteForAdvanceRequest = !isEmpty(note) ? note : null;
      //Fill TravelInformationDto data section ends

      // if (
      //   // isEmpty(selectedTravelCategory) &&
      //   // statusId == 1 &&
      //   isEmpty(travelCategoryList)
      // ) {
      //   let message = {
      //     MessageType: "danger",
      //     Message:
      //       "Travel Configuration Rules are not complete, Please talk to your HR Department",
      //   };
      //   this.props.DisplayClientSideMessage(message);
      //   return;
      // }
      const categoryId = !isEmpty(selectedTravelCategory)
        ? selectedTravelCategory.value
        : 0;
      debugger;
      let totalAmount = 0;
      (await selectedAdvanceAndExpensesList) &&
        selectedAdvanceAndExpensesList.map((m) => {
          totalAmount = totalAmount + m.amount;
          TravelInforamtionAllowanceExpenseDetailsDto.push({
            CategoryRefId: categoryId,
            RequestRefId: RequestRef_Id,
            AllowanceName: m.allowance_name,
            Units: m.units,
            Rate: m.rate,
            Amount: m.amount,
            IsBillRequired: m.isbillrequired,
            CalculationMethod: m.calculationmethod,
            AllowanceRef_Id: m.allowance_id,
          });
        });

      TravelInformationDto.AssessedEligibilityAmt = totalAmount;
    }
    this.setState({
      TravelInformationDto: TravelInformationDto,
      TravelInforamtionAllowanceExpenseDetailsDto:
        TravelInforamtionAllowanceExpenseDetailsDto,
    });
  };

  manageDocumentTabData = async () => {
    const {documentsState, RequestRef_Id} = this.state;
    let TravelInformationDocumentsDto = [];

    if (!isEmpty(documentsState)) {
      const {succesFileInfoList} = await documentsState;
      succesFileInfoList &&
        succesFileInfoList.map((data, index) => {
          TravelInformationDocumentsDto.push({
            RequestRefId: RequestRef_Id,
            AttachmentId: data.AttachmentId,
            OriginalName: data.OriginalFileName,
            DocumentName: data.GeneratedFileName,
            DocumentPath: data.DocumentPath,
            FileLocation: data.FileLocation,
            Size: data.Size,
          });
        });
    }

    this.setState({
      TravelInformationDocumentsDto: TravelInformationDocumentsDto,
    });
  };

  manageApproversTabData = async () => {
    const {TravelInformationDto, approverState} = this.state;
    let showMessage = false;
    if (!isEmpty(approverState)) {
      const {selectedApprovalList} = approverState;
      if (selectedApprovalList.length > 0) {
        TravelInformationDto.ApprovalEmployeeId = selectedApprovalList[0].value;
      } else showMessage = true;

      this.setState({
        TravelInformationDto: TravelInformationDto,
      });
    }
    return showMessage;
  };

  handleSaveAll = async (statusId, updateOrApprove) => {
    let showMessageTC = await this.manageTravelInformationTabData(statusId);
    if (showMessageTC) {
      return;
    }

    await this.manageDelegateTabData();
    await this.manageGroupTabData();
    await this.manageAdvanceAndExpensesTabData(statusId);
    await this.manageDocumentTabData();
    let showMessage = await this.manageApproversTabData();
    const {approvalAuthorities} = this.props;
    if (this.state.roleId === ADMIN_ROLE_ID)
      statusId = 15;
    if (isEmpty(approvalAuthorities) && statusId == 1) {
      let message = {
        MessageType: "danger",
        Message:
          "Travel Configuration Rules are not complete, Please talk to your HR Department",
      };
      this.props.DisplayClientSideMessage(message);
      return;
    } else if (showMessage && statusId == 1 && this.state.roleId !== ADMIN_ROLE_ID) {
      let message = {
        MessageType: "danger",
        Message: `Please Select ${this.state.isAssign ? 'an Employee and ': 'Approver to continue'}`,
      };
      this.props.DisplayClientSideMessage(message);
      return;
    } else {
      const props = this.props;
      const {
        TravelInformationDto,
        TravelInformationDocumentsDto,
        TravelInformationDestinationDto,
        TravelInformationGroupDto,
        TravelInforamtionAllowanceExpenseDetailsDto,
        isAllRequiredFieldValid,
        submitStatusId,
        documentsState,
        travelInformationState,
        isAssign,
      } = this.state;
      let approvalData = {};
      let newTravelInfo = TravelInformationDto;
    
      if (isAllRequiredFieldValid) {
        if (!isEmpty(this.commonComments)) {
          if (statusId !== 0) {
            if (
              isEmpty(this.commonComments.state.employee) &&
              this.state.roleId !== ADMIN_ROLE_ID &&
              submitStatusId !== 15
            ) {
              let message = {
                MessageType: "danger",
                Message: "You cannot forward without choosing an employee",
              };
              this.setState({selectedCreateTabId: 5});
              this.props.DisplayClientSideMessage(message);
              return false;
            }
            // this.commonComments.onApprove(1)
            approvalData = this.commonComments.onApproveReturn(this.state.submitStatusId);
            newTravelInfo.ApprovalEmployeeId = approvalData.ApprovalEmployeeId;
          }
        }
        if (
          this.state.roleId === ADMIN_ROLE_ID &&
          !this.state.isViewPage &&
          !this.state.isEditPage
        ) {
          TravelInformationDto.RequestedBy = this.state.selectedEmployee.value;
          TravelInformationDto.IsHRAssigned = true;
          approvalData.ApprovalEmployeeId = this.props.myDetail.value;
        }
        if (this.state.isEditPage) {
          newTravelInfo.ApprovalEmployeeId = !isEmpty(this.state.approverState.selectedApprovalList) ?
            this.state.approverState.selectedApprovalList[0].value : 0;
          // newTravelInfo.RequestedBy = this.state.selectedEmployee.value;

        }
        if (newTravelInfo.ApprovalEmployeeId == 0) {
          let message = {
            MessageType: "danger",
            Message: "Please Select a approver to continue",
          };
          this.props.DisplayClientSideMessage(message);
          // DisplayClientSideMessage
          return;
        }
        if (this.state.roleId === ADMIN_ROLE_ID)
          newTravelInfo.StatusId = 15
        
        if (isAssign) {
          newTravelInfo.RequestedBy = this.state.selectedEmployee.value;
        } else if (this.state.roleId === ADMIN_ROLE_ID){
          TravelInformationDto.RequestedBy = this.state.selectedEmployee.value;
          newTravelInfo.RequestedBy = this.state.selectedEmployee.value;
        } else if (!this.state.isEditPage)
          newTravelInfo.RequestedBy = 0;
        else {
          newTravelInfo.RequestedBy = TravelInformationDto.RequestedBy;
          newTravelInfo.StatusId = travelInformationState.StatusId;
        }
        if ((this.state.isEditPage && updateOrApprove == 1 && newTravelInfo.StatusId == 0)) {
          newTravelInfo.StatusId = 1;
        }
        debugger
        await this.props.SaveAllTravelRequest({
          // TravelInformationDto,
          TravelInformationDto: newTravelInfo,
          TravelInformationDestinationDto,
          TravelInformationGroupDto,
          TravelInforamtionAllowanceExpenseDetailsDto,
          TravelInformationDocumentsDto,
          TravelApprovalFlow: approvalData,
          // props: statusId === 0 ? null : props,
          props,
          documentsState,
        });
      
      } else {
        let message = {
          MessageType: "danger",
          Message: "Please complete the minimum required information.",
        };
        this.props.DisplayClientSideMessage(message);
      }
    }
  };

  handleEmployeeChange = (name, selected) => {
    const {employeeListWithPhoto} = this.props;
    this.setState(
      (prevState) => ({
        selectedEmployee: {
          ...prevState.selectedEmployee,
          value: isEmpty(selected) ? 0 : selected.value,
          label: isEmpty(selected) ? 0 : selected.label,
        },
        groupState: {
          ...prevState.groupState,
          requesterInformation: isEmpty(selected)
            ? null
            : employeeListWithPhoto.find((f) => f.value == selected.value),
        },
      }),
      () => {
        const {travelRequestId, submitStatusId} = this.state;
        if (!isEmpty(selected))
          this.getApproverDetails(travelRequestId, submitStatusId, selected.value);
      }
    );
  };

  handleRejectRequest = () => {
    const {remarks, statusId} = this.commonComments.state;
    if (!isEmpty(this.commonComments.state.remarks)) {
      let rejectData = {
        RequestId: this.state.RequestRef_Id,
        FlowHistoryDto: {
          HistoryID: 0,
          RequestId: 0,
          Status: statusId,
          ActionBy: "",
          ActionOn: "",
          ActionRemarks: remarks,
          ActionDisplayTitle: "",
          ApprovalEmployeeId: this.props.myDetail.value,
        },
        props: this.props,
      };
      this.props.RejectTravelRequest(rejectData);
    } else {
      let message = {
        MessageType: "danger",
        Message: "Comment is required!",
      };
      this.props.DisplayClientSideMessage(message);
      return false;
    }
  };

  closeModal = () => {
    this.setState({isModalShow: false});
    this.props.closeModal && this.props.closeModal();
  };

  // handleCancelRequest = () => {
  //   let el = document.getElementsByClassName("travel-fixed");
  //   el[0].classList.toggle("slide-up");
  //   setTimeout(() => {
  //     this.props.history && this.props.history.goBack();
  //     el[0].classList.toggle("slide-up");
  //   }, 850);
  // };

  handleTabChange = async (id) => {
    let showMessage = await this.manageTravelInformationTabData(null, id);
    const {isAllRequiredFieldValid, isEditPage, isViewPage} = this.state;
    let message = {};
    if (showMessage && id == 4 && !isEditPage && !isViewPage) {
      const {travelCategoryList} = this.props;
      if (isEmpty(travelCategoryList))
        message = {
          MessageType: "danger",
          Message:
            "Travel Configuration Rules are not complete, Please talk to your HR Department",
        };
      else
        message = {
          MessageType: "danger",
          Message: "Please fill Travel Category to continue",
        };
      this.props.DisplayClientSideMessage(message);
    } else if (isAllRequiredFieldValid) {
      this.setState({selectedCreateTabId: id});
    } else {
      message = {
        MessageType: "danger",
        Message: "Please fill your destination first.",
      };
      this.props.DisplayClientSideMessage(message);
    }
  };

  renderBody = (state, isEditPage, isViewPage) => {
    const {
      create_tabs,
      selectedCreateTabId,
      travelInformationState,
      delegationState,
      groupState,
      advanceAndExpensesState,
      documentsState,
      approverState,
      overAllDestinationFromDate,
      overAllDestinationToDate,
      isAllRequiredFieldValid,
      selectedEmployee,
      RequestRef_Id,
      requestDetails,
      isAssign
    } = this.state;
    const { user } = this.props;
    const label = (text, index) => {
      return (
        <div className="tab-title">
          <span className="tab-title__count">{index + 1}</span>
          <span className="tab-title__text">{text}</span>
        </div>
      );
    };
    const getKeyValue = (data, val) => {
      return data.find((item) => item.value === val);
    };

    // const requestDetails =
    //   (isEditPage || isViewPage) &&
    //   this.props.location &&
    //   !isEmpty(this.props.location.state) &&
    //   !isEmpty(this.props.location.state.requestDetails)
    //     ? this.props.location.state.requestDetails
    //     : "";
    return (
      <div className="travel-create">
        {/* <div className="travel-create__title flex">
          {(isEditPage || isViewPage) && (
            <button
              className="btn btn-grey"
              style={{
                marginTop: 0,
                padding: "0px 10px",
                fontSize: 20
              }}
              onClick={() => this.props.history && this.props.history.goBack()}
            >
              <IoMdArrowRoundBack />
            </button>
          )}
          <h3>
            {this.props.location &&
            !isEmpty(this.props.location.state) &&
            !isEmpty(this.props.location.state.title)
              ? this.props.location.state.title
              : "Create Travel Request"}
          </h3>
        </div> */}
        {(this.state.roleId === ADMIN_ROLE_ID &&
          !this.state.isViewPage &&
          !this.state.isEditPage &&
          this.props.employeeListSelect) ||
        isAssign ? (
          <div className="employee">
            <ValidationComponent
              rootClassName="inline-form-group select"
              error={selectedEmployee.error}
              errorClassName="error__message"
            >
              {isAssign ? (
                <div className="form-row ml-sm mr-sm">
                  <SelectEmployee
                    employeeToAssign={selectedEmployee}
                    // error={errors && errors.selectedEmployee}
                    onChange={this.handleEmployeeChange}
                  />
                </div>
              ) : (
                <CommonEmployeeSearch
                  onChange={this.handleEmployeeChange}
                  value={getKeyValue(
                    this.props.employeeListSelect,
                    selectedEmployee ? selectedEmployee.value : 0
                  )}
                  placeholder="Select Employee"
                  name="employee"
                  width="250px"
                  disabled={this.state.isViewPage}
                />
              )}
              {/* <AutoSelect
                name="employee"
                placeholder="Select Employee"
                value={getKeyValue(
                  this.props.employeeList,
                  selectedEmployee.value
                )}
                options={this.props.employeeList}
                onChange={this.handleEmployeeChange}
                disabled={this.state.isViewPage}
                width="250px"
              /> */}
            </ValidationComponent>{" "}
          </div>
        ) : null}

        <div className="travel-create__body">
          {this.props.renderTest && this.props.renderTest()}
          <Tabs type="block" activeTab={selectedCreateTabId}>
            {create_tabs &&
              create_tabs.map((ct, i) => {
                let TabComponent = ct.component;
                return (
                  <Tab
                    label={label(ct.label, i)}
                    name={ct.id}
                    id={ct.id}
                    key={i + Math.random()}
                    isActive={ct.id === selectedCreateTabId}
                    onClick={() => this.handleTabChange(ct.id)}
                  >
                    <div className="filters-input">
                      <div className="filters-input__item">
                        <TabComponent
                          {...this.props}
                          {...this.state}
                          isAllRequiredFieldValid={isAllRequiredFieldValid}
                          createTravelDestination={createTravelDestination()}
                          handleTravelInfoTabState={this.handleTravelInfoTabState}
                          handleDelegationTabState={this.handleDelegationTabState}
                          handleGroupTabState={this.handleGroupTabState}
                          handleAdvanceAndExpensesTabState={
                            this.handleAdvanceAndExpensesTabState
                          }
                          handleDocumentsTabState={this.handleDocumentsTabState}
                          handleApproversTabState={this.handleApproversTabState}
                          isViewMode={this.state.isViewPage}
                          isAssign={this.state.isAssign}
                          overAllDestinationToDate={overAllDestinationToDate}
                          selectedEmployee={this.state.selectedEmployee}
                          requesterId={
                            isEmpty(this.props.travelInformationToEdit)
                              ? ""
                              : this.props.travelInformationToEdit.TravelInformationDto
                                  .RequestedBy
                          }
                          RequestId={RequestRef_Id}
                        />
                        {/* {ct.id === 0 ? (
                          <CreateTravelInformationTab
                            isAllRequiredFieldValid={isAllRequiredFieldValid}
                            travelInformationState={travelInformationState}
                            createTravelDestination={createTravelDestination()}
                            handleTravelInfoTabState={
                              this.handleTravelInfoTabState
                            }
                            isViewMode={this.state.isViewPage}
                            isAssign={this.state.isAssign}
                          />
                        ) : ct.id === 1 ? (
                          <CreateDelegationTab
                            delegationState={delegationState}
                            handleDelegationTabState={
                              this.handleDelegationTabState
                            }
                            isViewMode={this.state.isViewPage}
                            isEditPage={this.state.isEditPage}
                            selectedEmployee={this.state.selectedEmployee}
                            requesterId={
                              isEmpty(this.props.travelInformationToEdit)
                                ? ""
                                : this.props.travelInformationToEdit
                                    .TravelInformationDto.RequestedBy
                            }
                          />
                        ) : ct.id === 2 ? (
                          <CreateGroupTab
                            groupState={groupState}
                            handleGroupTabState={this.handleGroupTabState}
                            isViewMode={this.state.isViewPage}
                            isEditPage={this.state.isEditPage}
                          />
                        ) : ct.id === 3 ? (
                          <CreateAdvanceAndExpenses
                            overAllDestinationFromDate={
                              overAllDestinationFromDate
                            }
                            overAllDestinationToDate={overAllDestinationToDate}
                            advanceAndExpensesState={advanceAndExpensesState}
                            handleAdvanceAndExpensesTabState={
                              this.handleAdvanceAndExpensesTabState
                            }
                            isViewMode={this.state.isViewPage}
                            isEditPage={this.state.isEditPage}
                            travelInformationState={travelInformationState}
                            RequestId={RequestRef_Id}
                          />
                        ) : ct.id === 4 ? (
                          <CreateDocumentsTab
                            documentsState={documentsState}
                            handleDocumentsTabState={
                              this.handleDocumentsTabState
                            }
                            isViewMode={this.state.isViewPage}
                          />
                        ) : ct.id === 5 ? (
                          <CreateApproversTab
                            approverState={approverState}
                            handleApproversTabState={
                              this.handleApproversTabState
                            }
                            isViewMode={this.state.isViewPage}
                          />
                        ) : null} */}
                      </div>
                    </div>
                  </Tab>
                );
              })}
          </Tabs>
          <div
            className="comment-history"
            // style={{ display: selectedCreateTabId === 5 ? "block" : "none" }}
            style={{display: "block"}}
          >
            {requestDetails && (
              <CommonCommentsHistory
                isApprover={requestDetails.page === "otherTravelRequest"}
                childRef={(ref) => (this.commonComments = ref)}
                RequestId={this.state.travelRequestId}
                StatusId={this.state.submitStatusId}
                approvalAuthorities={this.props.approvalAuthorities}
                approvalHistory={this.props.approvalHistory}
                isEditPage={this.state.isEditPage}
                isViewMode={this.state.isViewPage}
                disableApprover={true}
                hideCommentSection={isEmpty(this.props.approvalHistory.value)}
                isUpdate={!isEmpty(requestDetails) ? requestDetails.update : false}
                isRequester={requestDetails.EmployeeId == user.EmployeeId}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      isEditPage,
      isViewPage,
      isEditDataFetched,
      selectedCreateTabId,
      requestDetails,
      isLoading,
    } = this.state;
    const {travelEditLoading} = this.props;

    let isApprove =
      this.state.isEditPage &&
      ((!isEmpty(this.commonComments) && this.commonComments.state.isApprove) ||
        this.state.submitStatusId === 15 ||
        (!isEmpty(this.props.approvalAuthorities.value) &&
          this.props.approvalAuthorities.value.IsFinalApprover));

    if (isLoading) {
      return <Loaders type={"spinner"} loading={isLoading} />;
    }
    console.log("this.props and state", this.props, this.state);
    return (
      <>
        {travelEditLoading ? <Loader open={travelEditLoading} /> : null}

        {(!isViewPage && !isEditPage) || isEditDataFetched ? (
          <div className="travel-fixed-column">
            {/* <header className="travel-fixed-header">
              <div className="travel-header-div space-between">
                <div className="travel-header-title">{pathName}</div>
                <div
                  className="travel-header-icon"
                  onClick={(event) => {
                    let el = event.target.closest(".travel-fixed");
                    el.classList.toggle("slide-up");
                    setTimeout(() => {
                      this.props.history && this.props.history.goBack();
                      el.classList.toggle("slide-up");
                    }, 850);
                  }}
                >
                  <span>
                    <MdClose />
                  </span>
                </div>
              </div>
            </header> */}

            <div className="travel-fixed-main">
              {/* {requestDetails ? (
                <div className="header-employee employee">
                  <div className="employee-details">
                    <div className="employee-details__image">
                      {requestDetails.Name ? (
                        <Avatar
                          className="company-logo"
                          name={requestDetails.Name}
                          size={78}
                          round={true}
                          src={getUploadPath(requestDetails.UrlPhoto)}
                        />
                      ) : (
                        <img src={man} alt="Employee Avatar" />
                      )}
                    </div>
                    <div className="employee-details__content">
                      <h2>{requestDetails.Name}</h2>
                      <p className="position">
                        <span>{requestDetails.Desingnation}</span>
                      </p>
                      <p className="location">
                        <span>
                          {!isEmpty(requestDetails.Branch)
                            ? requestDetails.Branch + ","
                            : ""}
                        </span>
                        <span>Kathmandu</span>
                      </p>
                    </div>
                  </div>
                </div>
              ) : null} */}

              <div className="travel-header">
                <div className="travel-requestMainBody">
                  {this.renderBody(true, true)}
                </div>
              </div>
            </div>
            <footer className="travel-fixed-footer">
              <div className="travel-footer-div space-between">
                <div className="travel-footer">
                  <Link to={{pathname: RouteEnum.TeamTravelRequestPath}}>
                    <Button
                      // onClick={() =>
                      //   //this.props.history && this.props.history.goBack()
                      //   this.setState({ isModalShow: true })
                      // }
                      // className="btn-outline btn-outline__white"
                      bg="subtle"
                    >
                      Cancel
                    </Button>
                  </Link>
                  {isApprove || (isEditPage && !requestDetails.update) ? (
                    <Button onClick={() => this.handleRejectRequest()} bg="danger">
                      Reject
                    </Button>
                  ) : null}
                </div>
                <div className="">
                  {this.state.isViewPage ? null : (
                    <div
                      className="button-group button-group__right"
                      style={{marginTop: 0, padding: 0}}
                    >
                      {(!isEditPage && selectedCreateTabId == 5) ||
                      (isEditPage && requestDetails.update) ? (
                        <Button
                          bg="success"
                          onClick={() => this.handleSaveAll(this.state.submitStatusId, 1)}
                        >
                          {/* 1 for save */}
                          {!isEmpty(this.commonComments)
                            ? isApprove
                              ? "Approve"
                              : !isEmpty(requestDetails) && requestDetails.update
                              ? "Update"
                              : "Send for Approval"
                            : "Send for Approval"}
                        </Button>
                      ) : null}
                      {(!isEditPage && !selectedCreateTabId != 5) ||
                      (isEditPage &&
                        requestDetails.update &&
                        requestDetails.StatusId == 0 &&
                        this.state.roleId !== ADMIN_ROLE_ID) ? (
                        <Button bg="primary" onClick={() => this.handleSaveAll(0)}>
                          {/* 0 for draft */}
                          Save As Draft
                        </Button>
                      ) : null}
                      {selectedCreateTabId != 5 && (
                        <Button
                          bg="primary-light"
                          onClick={() => this.handleTabChange(selectedCreateTabId + 1)}
                        >
                          Next
                        </Button>
                      )}
                      {selectedCreateTabId != 0 && (
                        <Button
                          bg="primary-light"
                          onClick={() => this.handleTabChange(selectedCreateTabId - 1)}
                        >
                          Previous
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </footer>
            {/* {this.state.isModalShow && (
              <div className="modal__confrim confirm modal-travel-request">
                <div className="confirm-icon">
                  <FaExclamationCircle />
                </div>
                <h2 className="confirm-title">Are you Sure?</h2>
                <div className="confirm-buttons">
                  <button
                    className="confirm-buttons__confirm mr-md"
                    onClick={() => {
                      this.closeModal();
                    }}
                  >
                    <span>Yes</span>
                  </button>
                  <button
                    className="confirm-buttons__cancel"
                    onClick={() => this.closeModal()}
                  >
                    <span>No</span>
                  </button>
                </div>
              </div>
            )} */}
          </div>
        ) : (
          <Loader open={!isEditDataFetched} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedAdvanceAndExpensesList:
    state.travelInformationReducer.selectedAdvanceAndExpensesList,

  countryList: state.commonReducer.countryList,
  currencyList: state.commonReducer.currencyList,
  districtList: state.commonReducer.districtList,
  employeeList: state.commonReducer.employeeList,
  employeeListWithPhoto: state.commonReducer.employeeListWithPhoto,
  employeeListSelect: state.commonReducer.employeeListSelect,
  myDetail: state.commonReducer.myDetail,

  modeOfTransportList: state.travelInformationReducer.modeOfTransportList,
  travelHostList: state.travelInformationReducer.travelHostList,
  travelHotelList: state.travelInformationReducer.travelHotelList,

  travelCategoryList: state.travelInformationReducer.travelCategoryList,

  travelInformationToEdit: state.travelInformationReducer.travelInformationToEdit,
  travelEditLoading: state.travelInformationReducer.travelEditLoading,
  approvalAuthorities: state.travelInformationReducer.approvalAuthorities,
  approvalHistory: state.travelInformationReducer.approvalHistory,
  companyInformation: state.commonReducer.companyInformation,
  user: state.auth.user,

});

const mapDispatchToProps = {
  SaveAllTravelRequest,
  EditTravelRequests,
  ClearAdvanceAndExpensesList,
  GetCountryList,
  GetCurrencyList,
  GetDistrictList,
  GetTravelModeOfTransport,
  GetTravelHostAndHotel,
  GetEmployeeList,
  GetTravelCategoryList,
  GetAdvanceAndExpensesList,
  DisplayClientSideMessage,
  GetApprovalAuthorities,
  GetApprovalHistory,
  RejectTravelRequest,
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateNewTravelRequest);
