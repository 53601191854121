import React, { useState } from "react";
import Icons from "components/icons";
import classnames from "classnames";
import { Link } from "react-router-dom";
import isEmpty from "isEmpty";
import { connect } from "react-redux";
import { ToggleFavLink } from "store/actions/favLink"

const ModuleLinks = (props) => {
    const {
        moduleLink,
        baseUrl,
        isBaseUrl,
        ToggleFavLink,
        favLinks
    } = props

    const { LinkTitle, Link:URL, IsReactBased } = moduleLink;
    const isReact = IsReactBased ? true : false
    const isThere = favLinks && favLinks.filter(x => x.Link === URL).length > 0;

    const activeClass = classnames({
        "admin-module__link-favicon": true,
        "active-icon": isThere,
    });

    const onFavLink = (link) => {
        const param = { FeatureSubMenuId: link.FeatureSubMenuId, IsDashboardType: false }
        ToggleFavLink(param);
    }
    
    return (
        <li className="admin-module__links-item">
            <div className="admin-module__link">
                {isThere ? (
                    <span
                        onClick={() => onFavLink(moduleLink)}
                        className={activeClass}
                    >
                        <Icons name="Fav" justSVG />
                    </span>
                ) : (
                    <span
                        onClick={() => onFavLink(moduleLink)}
                        className={activeClass}
                    >
                        <Icons name="FavBorder" justSVG />
                    </span>
                )}
                {isBaseUrl && !isReact ? (
                    <a
                        href={`${baseUrl}/${URL}`}
                        className="admin-module__link-click"
                        target="_blank"
                    >
                        <div className="admin-module__link-main">
                            <div className="admin-module__link-title">
                                <span>{LinkTitle}</span>
                            </div>
                        </div>
                    </a>
                ) : (
                    <Link to={`/${URL}`} className="admin-module__link-click">
                        <div className="admin-module__link-main">
                            <div className="admin-module__link-title">
                                <span>{LinkTitle}</span>
                            </div>
                        </div>
                    </Link>
                )}

                <div className="admin-module__link-new"></div>
            </div>
        </li>
    );
};
const mapStateToProps = (state) => ({
    favLinks: state.favLinkReducer.favLinks
})
const mapDispatchToProps = { ToggleFavLink }
export default connect(mapStateToProps, mapDispatchToProps)(ModuleLinks);