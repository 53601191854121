import React, {useEffect} from 'react'
import {Tabs,Tab} from 'components/tabs/index.d'
import Current from './current'
import isEmpty from 'isEmpty'
import History from './history';

function Block({data}) {
    return (
        <div className="placement-job reporting">
            <div className="tertiary-title">
                <h3>Job Placement</h3>
            </div>
            <div className="reporting-tab">
                <Tabs>
                    <Tab label="Current" name="Current">
                        <Current data={data} />
                    </Tab>
                    <Tab label="History" name="History">
                        <History data={data} />
                    </Tab>
                </Tabs>
            </div>
        </div>
    )
}

export default Block
