import classnames from "classnames";
import FormGroup from "components/form";
import { ValidationComponent } from "helpers/getComponents";
import React from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import ImageUploader from "./imageUploader";
import { OneDatePicker } from "./pickers";

function PersonalInformation({ propsData, data, onChange, onError, errors }) {
  const title = "personalInformation";

  const { nationalityList } = propsData;
  const {
    honorificType,
    firstName,
    lastName,
    mobileNumber,
    email,
    nationality,
    dob,
  } = data;

  const inputWidth = "calc(100% - 225px)";

  const honorificClasses = (type) => {
    return classnames({
      badges: true,
      "badges-rounded": true,
      "left-icon": honorificType == type,
      "badges-primary": honorificType == type,
      "badges-default": honorificType != type,
    });
  };

  const handleHonorificChange = (type) => {
    onChange(title, "honorificType", type);
  };

  const onTextChange = (name,value) => {
    const names= ['kc','gc','bk','dc']
    if(name === 'lastName'){
        if(names.includes(value.toLowerCase())){
          onChange(title, name, value.toUpperCase());
        }else{
          let val = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
          onChange(title, name, val);
        }
      return;
    }
    if(name === 'firstName'){
      let val = value.charAt(0).toUpperCase() + value.slice(1);
      onChange(title, name, val);
      return;
    }
    //const { name, value } = e.target;
    onChange(title, name, value);
  };

  const onSelectChange = (name, value) => {
    onChange(title, name, value);
  };

  const handleDateChange = (name, value) => {
    onChange(title, name, value);
  };

  return (
    <div className="form-block">
      <div className="form-block__title">
        <h3>Position Information</h3>
      </div>
      <div className="form-partition">
        <div className="form-block__form">
          <ValidationComponent
            rootClassName="inline-form-group"
            error={errors && errors.honorificType}
            errorClassName="error__message"
          >
            <label></label>
            <div
              className={honorificClasses("mr")}
              onClick={() => handleHonorificChange("mr")}
            >
              {honorificType == "mr" ? <IoIosCheckmarkCircle /> : null}
              Mr
            </div>

            <div
              className={honorificClasses("miss")}
              onClick={() => handleHonorificChange("miss")}
            >
              {honorificType == "miss" ? <IoIosCheckmarkCircle /> : null}
              Miss
            </div>

            <div
              className={honorificClasses("mrs")}
              onClick={() => handleHonorificChange("mrs")}
            >
              {honorificType == "mrs" ? <IoIosCheckmarkCircle /> : null}
              Mrs
            </div>
          </ValidationComponent>
          <FormGroup
            name="firstName"
            value={firstName}
            label="First Name"
            onChange={onTextChange}
            className="inline-form-group"
            error={errors.firstName}
          />
          <FormGroup
            name="lastName"
            value={lastName}
            label="Last Name"
            onChange={onTextChange}
            className="inline-form-group"
            error={errors.lastName}
          />
          <FormGroup
            name="mobileNumber"
            value={mobileNumber}
            label="Mobile Number"
            onChange={onTextChange}
            className="inline-form-group"
            error={errors.mobileNumber}
          />
          <FormGroup
            type="email"
            name="email"
            value={email}
            label="Email Address"
            onChange={onTextChange}
            className="inline-form-group"
            error={errors.email}
          />
          <FormGroup
            formName="reactselect"
            onChange={onSelectChange}
            name="nationality"
            value={nationality}
            options={nationalityList}
            //width="300px"
            label="Nationality"
            error={errors?.nationality}
            className="inline-form-group"
          />

          {/* <InputGroup
            name="dob"
            value={dob}
            label="Date of Birth"
            //   onChange={onTextChange}
            className="inline-form-group"
          /> */}

          <OneDatePicker
            label="Date of Birth"
            value={dob}
            format={"yyyy/MM/dd"}
            onChange={(date) => handleDateChange("dob", date)}
            error={errors.dob}
            errorClassName="error__message"
          />
        </div>

        <div className="form-partition-upload">
          {/* <ReactFileReader base64={true} handleFiles={handleFiles}> */}

          <ImageUploader
            title={title}
            data={data}
            onChange={onChange}
            onError={onError}
            error={errors.cropedPhoto}
          />
        </div>
      </div>
    </div>
  );
}

export default PersonalInformation;
