import React, { Component } from "react";
import { MdSettings } from "react-icons/md";
import drag from "assets/images/icons/ellipse.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import shortid from "shortid";
import AttendanceStatistics from "./dashboard/attendanceStatistics";
// import PieChart from '../../../Components/Charts/PieChart'
import Pending from "./dashboard/pending";
import EmployeeList from "./dashboard/employeeList";
import LeavePieChart from "./dashboard/leavePieChart";
import LeaveAltChart from "./dashboard/leaveAltChart";
import isEmpty from 'isEmpty'


const innerRadius = 40;

const blockProps = {
  "Leave Requests":{
    id: shortid.generate(),
    component: LeavePieChart,
    props: { innerRadius, type: "LeaveRequest" },
    footer: "Details",
    footerLink:'/newhr/LeaveRequestList.aspx'
  },
  "Rejected Leaves":{
    id: shortid.generate(),
    component: LeaveAltChart,
    props: { innerRadius, type: "RejectedLeave" },
    footer: "+ 3 more",
  },
  "Unused Leaves":{
    component: LeavePieChart,
    props: { type: "UnusedLeave", innerRadius },
    footer: "Details",
    id: shortid.generate(),
  }, 
  "Approved Leaves":{
    component: LeaveAltChart,
    props: { type: "ApprovedLeave" },
    id: shortid.generate(),
  },
  "Device Statistics":{
    component: LeaveAltChart,
    props: { type: "DeviceStaticts" },
    footer: "Check Retirements",
    id: shortid.generate(),
  },
  "Attendance Statistics":{
    component: AttendanceStatistics,
    footer: "Review Transfers",
    id: shortid.generate(),
  },
  "Pending Requests":{
    component: Pending,
    id: shortid.generate(), 
  },
  "Employees On Leave":{
    component: EmployeeList,
    footer: "Employees On Leave",
    id: shortid.generate(),
  }
}

const initial = [
  {
    id: shortid.generate(),
    cards: [
      { name: "Leave Requests", ...blockProps['Leave Requests'] },
      { name: "Rejected Leaves", ...blockProps['Rejected Leaves']},
      { name: "Unused Leaves", ...blockProps['Unused Leaves']},
    ],
  },
  {
    id: shortid.generate(),
    cards: [
      { name: "Approved Leaves",...blockProps['Approved Leaves']},
      { name: "Device Statistics", ...blockProps['Device Statistics']},
      { name: "Attendance Statistics", ...blockProps['Attendance Statistics']},
    ],
  },
  {
    id: shortid.generate(),
    cards: [
      { name: "Pending Requests",...blockProps['Pending Requests'] },
      {name: "Employees On Leave", ...blockProps['Employees On Leave']},
    ],
  },
];

export class DragBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lists: initial,
    };
    this._renderBlock = this._renderBlock.bind(this);
    this.onDragChange = this.onDragChange.bind(this);
  }

  componentDidUpdate(prevProps,prevState){
    const {blocks} = this.props;
    const {lists} = this.state;
    if(!isEmpty(blocks) && blocks !== prevProps.blocks){
        let sortedBlock;
        let leaveBlocks = blocks.filter(item => item.DashboardName === 'Leave');
        if(!isEmpty(leaveBlocks)){
          sortedBlock = leaveBlocks.sort((a,b)=>a.BlockOrder - b.BlockOrder);

          const newList = lists.map((item,index)=>{
              let num = index + 3;
              let threes = sortedBlock.slice(index * 3, ( index + 1 ) * 3 )
              let newCards = threes.map(card => {
                  return {
                      name:card.BlockName,
                      ...blockProps[card.BlockName]
                  }
              })
              item.cards = [...newCards]
  
              return item
          })
          this.setState({lists:newList})
        }

    }
  }

  onDragChange = () =>{
    const {lists} = this.state;
    const toPost = lists.map((item,ind)=>{
        let b = item.cards.map((card,i)=>{
            return  {
                DashboardName:'Leave',
                BlockName:card.name,
                // BlockOrder:(i +1) + (ind * 3)
            }
        })
        return b;
    })
    let c = toPost.flat()
    c = c.map((item,i)=>{
        return{
            BlockOrder:i+1,
            ...item
        }
    });
    this.props.addDashboardBlocks(c)
}
  onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;
    if (!destination) {
      return;
    }
    const { lists } = this.state;
    const droppableIdStart = source.droppableId;
    const droppableIdEnd = destination.droppableId;
    const droppableIndexEnd = destination.index;
    const droppableIndexStart = source.index;

    if (droppableIdStart === droppableIdEnd) {
      const listStart = lists.find((list) => list.id === droppableIdStart);

      const card = listStart.cards.splice(droppableIndexStart, 1);
      listStart.cards.splice(droppableIndexEnd, 0, ...card);
      this.setState((prevState) => ({
        ...prevState,
        lists: prevState.lists.map((list) => {
          if (list.id === listStart.id) {
            return listStart;
          }
          return list;
        }),
      }));
    }
    if (droppableIdStart !== droppableIdEnd) {
      // find the list where the drag happened
      const listStart = lists.find((list) => list.id === droppableIdStart);
      // pull out the card from this list
      const card = listStart.cards.splice(droppableIndexStart, 1);
      // find the list where the drag ended
      const listEnd = lists.find((list) => list.id === droppableIdEnd);
      // put the card in the new list
      listEnd.cards.splice(droppableIndexEnd, 0, ...card);
      this.setState((prevState) => ({
        ...prevState,
        lists: prevState.lists.map((list) => {
          if (list.id === listEnd.id) {
            return listEnd;
          } else if (list.id === listStart.id) {
            return listStart;
          }
          return list;
        }),
      }));
    }
    this.onDragChange()
  };
  _renderBlock(props, index) {
    const {baseUrl} = this.props;
    const base = baseUrl ? baseUrl.NetFrameworkAppBaseUrl : '';
    return (
      <Draggable draggableId={String(props.id)} index={index} key={props.id}>
        {(provided) => (
          <div
            {...provided.draggableProps}
            // {...provided.dragHandleProps}
            ref={provided.innerRef}
            className="drag-block__item drag-box"
          >
            <div className="drag-box__header">
              <div className="drag-box__title">
                <h4>{props.name}</h4>
              </div>
              <div className="drag-box__icons">
                <span className="drag-box__icon">
                  <MdSettings />
                </span>
                <span
                  {...provided.dragHandleProps}
                  className="drag-box__icon drag"
                >
                  <img src={drag} width="16" height="16" />
                </span>
              </div>
            </div>
            <div className="drag-box__body">
              <props.component {...props.props} />
            </div>
            {props.footer ? (
              <div className="drag-box__footer">
                  {
                    props.footerLink? <a target="_blank"  href={base + props.footerLink}>
                        <span>{props.footer}</span>
                    </a>
                    :
                    <span>{props.footer}</span>    
                }
                
              </div>
            ) : null}
          </div>
        )}
      </Draggable>
    );
  }
  render() {
    const { lists } = this.state;
    return (
      <div className="drag-block">
        <div className="drag-block__container">
          <div className="drag-block__items">
            <DragDropContext onDragEnd={this.onDragEnd}>
              {lists.map((item, index) => {
                return (
                  <Droppable
                    droppableId={String(item.id)}
                    index={index}
                    type="list"
                  >
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        nf={provided}
                        index={index}
                        className="drag-block__items-list"
                      >
                        {item.cards.map(this._renderBlock)}
                      </div>
                    )}
                  </Droppable>
                );
              })}
            </DragDropContext>
          </div>
        </div>
      </div>
    );
  }
}

export default DragBlock;
