import {
    GET_OVERTIME_REQUEST
} from 'actions/types';
import axios from 'axios';
import {toastMessage} from 'actions/validateAction'
import isEmpty from 'isEmpty';
import { catchError } from "helpers";

export const getOvertimeRequest = (
  pageIndex,
  rowsPerPage,
  status,
  activeBranch,
  activeDepartment,
  overtimeType,
  employeeName,
  startDate,
  endDate
  ) =>async dispatch=>{
    const branchId = !isEmpty(activeBranch) ? activeBranch.value : -1;
    const departmentId = !isEmpty(activeDepartment) ? activeDepartment.value : -1;
    const statusId = !isEmpty(status) ? status : -1;
    const overtimeTypeId = !isEmpty(overtimeType) ? overtimeType.label : '';
    const sd = !isEmpty(startDate) ? startDate : null;
    const ed = !isEmpty(endDate) ? endDate : null;
    const em = !isEmpty(employeeName) ? employeeName : '';

    await axios.get(`v1/payroll/overtime-requests/admin-view?PageIndex=${pageIndex}&PageSize=${rowsPerPage}&EmployeeName=${em}&RequestEndDate=${ed}&RequestStartDate=${sd}&OverTime=${overtimeTypeId}&Department=${departmentId}&Status=${statusId}&Branch=${branchId}`).then(response => {
      if (response.data.Status) {
        dispatch({
          type: GET_OVERTIME_REQUEST,
          payload: response.data.Data
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch(err => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message?err.message:"Failed to get overtime requests"
        }
      };
      toastMessage(dispatch, response);
    });
}


export const postToSalary = (ids,refreshFunc) => async dispatch =>{
  // RequestId
  await axios
  .post(`/v1/payroll/overtime-requests/post-to-salary`, ids)
  .then((resp) => {
    const response = resp.data;
    if(response.Status){
      refreshFunc()
    }
    
  })
  .catch((err) => {
    catchError(dispatch, err);
  });
}

export const rejectOvertime = (id,refreshFunc) =>async dispatch=>{
  await axios.put(`v1/payroll/overtime-requests/admin-view/${id}`)
  .then((resp) => {
    const response = resp.data;
    if(response.Status){
      refreshFunc()
    }else{
      toastMessage(dispatch, response);
    }
  })
  .catch((err) => {
    catchError(dispatch, err);
  });
}