export default function validateStopPayment(values) {
    let errors = {};
    // Email Errors
    if (!values.employee) {
        errors.employee = "Required Employees";
    } 
    // fromDate Errors
    if (!values.fromDate) {
        errors.fromDate = "Required From Date";
    }
    // toDate Errors
    if (!values.toDate) {
        errors.toDate = "Required To Date";
    }
    return errors;
}