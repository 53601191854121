import React, {useState} from "react";
import Button from "components/button";
import Modal from "components/modal";
import {NavLink, Link} from "react-router-dom";
import {BsFillCaretDownFill} from "react-icons/bs";
import {useHistory} from "react-router-dom";
import SvgIcons from "components/svgIcons";
import SvgIcon from "components//icons/svgIcons";
import {Features} from "helpers/getPermissions";
import {isFeatureEnabledValue} from "helpers/checkPermissions";
import OvertimeRequestForm from "scenes/employee/overtimeRequest";
import AllowanceRequestForm from "scenes/employee/allowance/allowanceRequest";
import TimeRequestForm from "scenes/employee/time/timeRequest";
import LeaveRequestForm from "scenes/employee/leaves/leaveRequest/form";
import {useSelector} from "react-redux";
import CreateNewTravelRequest from "scenes/travel/common/travelInformation/createTravelRequest/createNewTravelRequest";
import RouteEnum from "constants/routeEnum";

function SBILoanRequestButton(props) {
  const {isHRorFinance} = useSelector((state: any) => state.auth);

  const features = Features();

  return (
    <>
      <Button
        buttonType="drop-button"
        title={!props.isAssign ? props.buttonTitle : ""}
        customDropIcon={<BsFillCaretDownFill style={{fontSize: 15}} />}
        justDrop
        bg={props.isAssign ? "primary" : "success"}
        dropComponent={
          <div className="request-drop">
            <ul className="request-drop__list">
              {(!props.isAssign ||
                (props.isAssign && props.isLeaveTimeReviewerOrApprover)) && (
                <>
                  {isFeatureEnabledValue(features.CoreHR) ? (
                    <NavLink
                      // to={() =>
                      //   REACT_APP_IS_LEAVE_OLD_URL === "true"
                      //     ? window.open(getLink("leave"))
                      //     : showLeaveRequestForm(true)
                      // }
                      to={RouteEnum.ConsumerLoanRequest}
                      className="request-drop__item"
                    >
                      <div className="request-drop__item-name">
                        <span>Consumer Loan</span>
                      </div>
                    </NavLink>
                  ) : null}
                  {isFeatureEnabledValue(features.CoreHR) ? (
                    <NavLink
                      // to={() => {
                      //   REACT_APP_IS_TIME_OLD_URL === "true"
                      //     ? window.open(getLink("time"))
                      //     : setShowTimeRequestForm(true);
                      // }}
                      to={RouteEnum.SocialLoanRequest}
                      className="request-drop__item"
                    >
                      <div className="request-drop__item-name">
                        <span>Social Loan</span>
                      </div>
                    </NavLink>
                  ) : null}
                  {isFeatureEnabledValue(features.CoreHR) ? (
                    <NavLink
                      // to={() => {
                      //   REACT_APP_IS_TIME_OLD_URL === "true"
                      //     ? window.open(getLink("time"))
                      //     : setShowTimeRequestForm(true);
                      // }}
                      to={RouteEnum.MyAppliedLoanRequests}
                      className="request-drop__item"
                    >
                      <div className="request-drop__item-name">
                        <span>My Loan Requests</span>
                      </div>
                    </NavLink>
                  ) : null}
                  {isFeatureEnabledValue(features.CoreHR) ? (
                    <NavLink
                      to={RouteEnum.LoanStatusHistory}
                      className="request-drop__item"
                    >
                      <div className="request-drop__item-name">
                        <span>Employee Loan Requests</span>
                      </div>
                    </NavLink>
                  ) : null}
                </>
              )}
              {/* <>
                {isFeatureEnabledValue(features.CoreHR) ? (
                  <NavLink
                    className="request-drop__item"
                    to={RouteEnum.CreateStaffODLoanPath}
                    //
                  >
                    <div className="request-drop__item-name">
                      <span>Staff OverDraft/Renewal</span>
                    </div>
                  </NavLink>
                ) : null}
                {isFeatureEnabledValue(features.CoreHR) ? (
                  <NavLink
                    className="request-drop__item"
                    to={{
                      pathname: RouteEnum.CreateStaffPersonalLoanPath,
                      state: {
                        data: "1",
                      },
                    }}
                  >
                    <div className="request-drop__item-name">
                      <span>Staff Personal Loan</span>
                    </div>
                  </NavLink>
                ) : null}
                {isFeatureEnabledValue(features.CoreHR) ? (
                  <NavLink
                    className="request-drop__item"
                    to={{
                      pathname: RouteEnum.CreateStaffAdvanceLoanPath,
                      state: {
                        data: "2",
                      },
                    }}
                  >
                    <div className="request-drop__item-name">
                      <span>Staff Advance Salary</span>
                    </div>
                  </NavLink>
                ) : null}
              </> */}
              {/* <NavLink
                // to={(RouteEnum.AdminLoanManagement)}
                to={RouteEnum.EmployeeLoanList}
                className="request-drop__item"
              >
                <div className="request-drop__item-name">
                  <span>My Loan Requests</span>
                </div>
              </NavLink>
              {isHRorFinance && (
                <NavLink to={RouteEnum.LoanManagement} className="request-drop__item">
                  <div className="request-drop__item-name">
                    <span>Loan Management</span>
                  </div>
                </NavLink>
              )} */}
            </ul>
          </div>
        }
      ></Button>
    </>
  );
}

export default SBILoanRequestButton;
