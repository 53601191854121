import React, { useState, useRef, useEffect } from "react";
import useOutsideClick from "helpers/customHooks/outsideClickHook";
import CommonLinearProgress from "components/common/commonLinearProgress";
import isEmpty from "isEmpty";
import {
  taskDetailsInitialState,
  taskCheckListState,
} from "../../../../taskFormStates";
import { BsCardChecklist } from "react-icons/bs";
import FormGroup from "components/form";
import classNames from "classnames";
import {
  FaRegUserCircle,
  FaRegComment,
  FaRegSmile,
  FaCheck,
  FaTimes,
  FaPencilAlt,
  FaTrashAlt,
} from "react-icons/fa";
import Modal from "components/modal";

const CheckListSection = (props) => {
  const { data } = props;
  const [checkText, setCheckText] = useState("");
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [selectedText, setSelectedText] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [openConfirm, setOpenConfirm] = useState(false);
  const ref = useRef<any>(null);
  useOutsideClick(ref, () => {
    setSelectedIndex(-1);
  });
  const onCheckAdd = (event: any) => {
    if (event.key === "Enter") {
      let nextData = { ...data };
      let newCheck = taskCheckListState;
      newCheck.CheckListName = checkText;
      newCheck.TaskDetailsRefId = nextData.TmTaskDetailsDto.TaskDetailsId;
      if (isEmpty(nextData.TmTaskCheckListDto)) {
        nextData.TmTaskCheckListDto = [newCheck];
      } else {
        nextData.TmTaskCheckListDto = [
          newCheck,
          ...nextData.TmTaskCheckListDto,
        ];
      }
      setCheckText("");
      props.onCheckAdd && props.onCheckAdd(nextData);
    }
  };

  const onCheck = (index, status) => {
    let nextData = { ...data };

    nextData.TmTaskCheckListDto = nextData.TmTaskCheckListDto.map(
      (check, checkIndex) => {
        let obj = { ...check };
        if (checkIndex === index) {
          obj["Status"] = status;
        }
        return obj;
      }
    );

    props.onCheck && props.onCheck(nextData);
  };
  const onDelete = (index) => {
    let nextData = JSON.parse(JSON.stringify(data));
    nextData.TmTaskCheckListDto.splice(index, 1);
    props.onCheck && props.onCheck(nextData);
  };

  const onEdit = (index) => {
    setSelectedText(data.TmTaskCheckListDto[index].CheckListName);
    setSelectedIndex(index);
  };

  const onSave = (index, value) => {
    if (value.length === 0) {
      return;
    }
    let nextData = JSON.parse(JSON.stringify(data));
    nextData.TmTaskCheckListDto[index].CheckListName = value;
    console.log(nextData);
    setSelectedText("");
    setSelectedIndex(-1);
    //nextData.TmTaskCheckListDto.splice(index, 1);
    props.onCheck && props.onCheck(nextData);
  };
  const onEnter = (e, index) => {
    if (e.key === "Enter") {
      onSave(index, selectedText);
    }
  };

  const calculateProgress = () => {
    const dto = (data && data.TmTaskCheckListDto) || [];

    if (dto.length === 0) return 0;
    const total = dto.length;
    const completed = dto.filter((x) => x.Status).length;
    return (parseFloat(completed) / parseFloat(total)) * 100;
  };
  const getTextDecorationStyle = (status) => {
    return status ? { textDecoration: "line-through" } : {};
  };
  return (
    <div ref={ref} className="taskmodal__checklist">
      <div className="taskmodal__checklist-title">
        <BsCardChecklist />
        <h4>Checklists</h4>
      </div>
      <div className="taskmodal__checklist-progress">
        <CommonLinearProgress
          type={calculateProgress() > 50 ? "success" : "danger"}
          progress={calculateProgress()}
        />
      </div>
      {isEmpty(data.TmTaskCheckListDto)
        ? null
        : data.TmTaskCheckListDto.map((item, index) => {
            return (
              <div key={index} className="taskmodal__checklist-checks">
                {selectedIndex === index ? (
                  <FormGroup
                    name="task"
                    placeholder="Add checklist item"
                    onChange={(name, value) => setSelectedText(value)}
                    value={selectedText}
                    onKeyUp={(e) => onEnter(e, index)}
                  />
                ) : (
                  <>
                    <label className="custom-check label-right">
                      <span style={getTextDecorationStyle(item.Status)}>
                        {item.CheckListName}
                      </span>
                      <input
                        type="checkbox"
                        name="task"
                        checked={item.Status}
                        onChange={(e) =>
                          onCheck && onCheck(index, e.target.checked)
                        }
                        value={item.Status}
                      />
                      <div className="checkmark"></div>
                    </label>
                  </>
                )}
                <div
                  className={classNames("taskmodal__checklist-checks-action", {
                    inactive: selectedIndex !== -1 && selectedIndex !== index,
                  })}
                >
                  {selectedIndex !== index ? (
                    <>
                      <span onClick={(e) => onEdit(index)}>
                        <FaPencilAlt />
                      </span>
                      <span
                        onClick={(e) => {
                          setDeleteIndex(index);
                          setOpenConfirm(true);
                        }}
                      >
                        <FaTrashAlt />
                      </span>
                    </>
                  ) : (
                    <>
                      <span onClick={(e) => onSave(index, selectedText)}>
                        <FaCheck />
                      </span>
                      <span>
                        <FaTimes onClick={(e) => setSelectedIndex(-1)} />
                      </span>
                    </>
                  )}
                </div>
              </div>
            );
          })}

      <div className="taskmodal__checklist-input">
        <FormGroup
          name="task"
          placeholder="Add checklist item"
          onChange={(name, value) => setCheckText(value)}
          value={checkText}
          onKeyUp={onCheckAdd}
        />
      </div>
      {openConfirm && (
        <Modal
          type="confirm"
          func={() => deleteIndex > -1 && onDelete(deleteIndex)}
          open={openConfirm}
          onModalClose={() => setOpenConfirm(false)}
        />
      )}
    </div>
  );
};

export default CheckListSection;
