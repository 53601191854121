import React, { useState } from "react";

function EducationDetail(props) {
  const { data } = props;
  return (
    <div className="information-wholeInfo-nav-block">
      <div className="information-wholeInfo-nav-block-title">
        <span>EDUCATION</span>
      </div>

      {data &&
        data.map((d, i) => {
          return (
            <div className="information-wholeInfo-nav-block-subtitle" key={i}>
              <div className="information-wholeInfo-nav-block-subtitle-left">
                <span className="fonts-light_medium">{d.PassedYear}</span>
              </div>

              <div className="information-wholeInfo-nav-block-subtitle-right">
                <div className="information-wholeInfo-nav-block-subtitle-description">
                  <span className="fonts-dark_medium">{d.DegreeName}</span>
                  <span className="fonts-light_medium">at</span>
                  <span className="fonts-dark_medium">
                    {d.InstitutionName + ", " + d.UniversityName}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default EducationDetail;
