import React, { useEffect, useState } from "react";
import Icons from "components/icons";
import { DateFormat } from "helpers/dateFormat";
import moment from "moment";
import classNames from "classnames";

const TodoHeader = ({ data, handleClick, fromDate, toDate }) => {

  const [selectedTab, setSelectedTab] = useState("thisWeek")
  const onTabTitleClick = (tab) => {
    setSelectedTab(tab)
    handleClick && handleClick(tab)
  }
  return (
    <div className="task-todo-header">
      <h3>My To Do</h3>
      <div className="task-todo-dates">
        <div
          className={classNames("task-todo-date with-left-icon", { active: selectedTab === 'previous' })}
          onClick={() => onTabTitleClick("previous")}
        >
          <i className="task-todo-date__icon">
            <Icons name="ArrowLeft" justSVG />
          </i>
          <span>Previous Week ({data.LastWeekTasks.length})</span>
        </div>
        <div
          className={classNames("task-todo-date", { active: selectedTab === 'thisWeek' })}
          onClick={() => onTabTitleClick("thisWeek")}
        >
          <span>
            {moment(fromDate).format("MMMM DD")} -{" "}
            {moment(toDate).format("MMMM DD")} ({data.ThisWeekTasks.length})
          </span>
        </div>
        <div
          className={classNames("task-todo-date with-right-icon", { active: selectedTab === 'nextWeek' })}
          onClick={() => onTabTitleClick("nextWeek")}
        >
          <span>Next Week ({data.NextWeekTasks.length})</span>
          <i className="task-todo-date__icon">
            <Icons name="ArrowRight" justSVG />
          </i>
        </div>
      </div>
      <div className=""></div>
    </div>
  );
};

export default TodoHeader;
