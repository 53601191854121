import isEmpty from "isEmpty";
import React, { Component } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { IoMdCheckmark } from "react-icons/io";
import FormGroup from "components/form";
import { connect } from "react-redux";
import { JOB_PROFILE_EDUCATION_TYPE } from "constants/constants";

class InsertEduQualForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eduQalList: [],
      hovered: 0,
      searchFilter: "",
    };
    this.handleHover = this.handleHover.bind(this);
  }

  componentDidMount() {
    let {
      components,
      insertType,
      educationList,
      qualificationList,
    } = this.props;
    let eduQalList =
      insertType === JOB_PROFILE_EDUCATION_TYPE
        ? educationList
        : qualificationList;
    let currentComponent = components.find((x) => x.type === insertType);
    let newEduQalList = eduQalList.map((x) => {
      let o = { ...x };
      o.Selected = !isEmpty(
        currentComponent[insertType.toLowerCase()].find((i) => i.id === x.Id)
      )
        ? true
        : false;

      return o;
    });
    this.setState({ eduQalList: newEduQalList });
  }
  handleHover(id) {
  
    this.setState({ hovered: id });
  }
  handleChangeSelected = (id, select) => {
    let { eduQalList } = this.state;
    const newEduQalList = eduQalList.map((p) =>
      p.Id === id ? { ...p, Selected: select } : p
    );

    this.setState({ eduQalList: newEduQalList });
  };

  handleSearchFilterChange = (name, value) => {
    this.setState({ searchFilter: value });
  };

  handleAddSubSections = () => {
    let { eduQalList } = this.state;
    const newEduQalList = eduQalList.filter((x) => x.Selected);
    this.props.handleAddSubSections(
      this.props.sectionId,
      this.props.insertType,
      newEduQalList
    );
    this.props.closeAddEduQualForm();
  };

  render() {
    let { eduQalList, hovered, searchFilter } = this.state;
    let filteredEduQalList = eduQalList.filter((x) =>
      x.Name.toLowerCase().includes(searchFilter.toLowerCase())
    );
    const { closeAddEduQualForm } = this.props;
    return (
      <div className="insert-edu-qual-form">
        <div className="add-section-form__body">
          <div className="form-row">
            <FormGroup
              name="searchFilter"
              value={searchFilter}
              onChange={this.handleSearchFilterChange}
              label=""
              width="250px"
              placeholder="Search or filter"
            />
          </div>
          <div className="form-row">
            <div className="edu-qual">
              {filteredEduQalList.map((x) => (
                <div className="edu-qual__li flex">
                  <div className="btn-grp">
                    {x.Selected ? (
                      hovered === x.Id ? (
                        <button
                          className="button with-icon button-white remove-btn"
                          onClick={() => this.handleChangeSelected(x.Id, false)}
                          onMouseLeave={() => this.handleHover(0)}
                        >
                          <AiOutlineClose />
                          Remove
                        </button>
                      ) : (
                        <button
                          className="button with-icon  selected-btn"
                          onMouseEnter={() => this.handleHover(x.Id)}
                        >
                          <IoMdCheckmark />
                          Selected
                        </button>
                      )
                    ) : (
                      <button
                        className="button with-icon select-btn button-white"
                        onClick={() => this.handleChangeSelected(x.Id, true)}
                      >
                        <IoMdCheckmark />
                        Select
                      </button>
                    )}
                  </div>
                  <span>{x.Name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="add-section-form__footer">
          <button
            className="button button-white"
            onClick={() => closeAddEduQualForm()}
          >
            Cancel
          </button>
          <button
            className="button button-primary"
            onClick={() => this.handleAddSubSections()}
          >
            Add
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  educationList: state.jobProfileReducer.educationList,
  qualificationList: state.jobProfileReducer.qualificationList,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InsertEduQualForm);
