import React, { Component } from 'react'
import { ColumnDirective, ColumnsDirective, GridComponent } from '@syncfusion/ej2-react-grids';
import {IoIosCalculator} from 'react-icons/io'

export class Table extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             
        }
        this.template = this.gridTemplate;
    }
    
    gridTemplate(props) {
        return (
            <tr className="leave-row bordered">
            <td className="">
                <a href="#">
                    <span>
                        {props.EIN}
                    </span>
                </a>
                
            </td>
            <td className="">
                <a href="#">
                    <span>
                        {props.Name}
                    </span>
                </a>
                
            </td>
            <td className="">
                <span>
                    {props['Basic Salary']}
                </span>
            </td>
            <td className="">
                <span>
                    {props['General Allowance']}
                </span>
            </td>
            <td className="">
                <span>
                    {props["Provident Fund"]}
                </span>
            </td>
            <td className="">
                <span class="text-bold">
                    {props["Gross Salary"]}
                </span>
            </td>
            <td>
                <span class="text-red">
                    {props["PF Deduction"]}
                </span>
            </td>
            <td>
                <span class="text-red">
                    {props["Tax"]}
                </span>
            </td>
            <td>
                <span class="text-green">
                    {props["Net Pay"]}
                </span>
            </td>
            <td>{
                    props.EIN?
                    <a href="#" class="flex">
                        <span style={{fontSize:20,marginRight:4}}>
                            <IoIosCalculator />
                        </span>
                        Recalculate
                    </a>
                    :null
                }
            </td>
        </tr>
        );
    }
    render() {
        const data = [
            {'EIN':'HO-325','Name':'Kamala Gharti','Basic Salary':'10,000.00','General Allowance':'5,000.00',
            'Provident Fund':'1,000.00','Gross Salary':'16,000.00','PF Deduction':'2,000.00',
            'Tax':'2,000.00','Net Pay':'13,800.00'},
            {'EIN':'HO-325','Name':'Mahensh Dhital','Basic Salary':'10,000.00','General Allowance':'5,000.00',
            'Provident Fund':'1,000.00','Gross Salary':'16,000.00','PF Deduction':'2,000.00',
            'Tax':'2,000.00','Net Pay':'13,800.00'},
            {'EIN':'HO-325','Name':'Next Employee','Basic Salary':'10,000.00','General Allowance':'5,000.00',
            'Provident Fund':'1,000.00','Gross Salary':'16,000.00','PF Deduction':'2,000.00',
            'Tax':'2,000.00','Net Pay':'13,800.00'},
        ]
        return (
            <div className="arrear-table">
                <GridComponent dataSource={data}  rowTemplate={this.template.bind(this)}>
                    <ColumnsDirective>
                        <ColumnDirective field='EIN'  width='60'/>
                        <ColumnDirective field='Name' width='100'/>
                        <ColumnDirective field='Basic Salary' width='80'/>
                        <ColumnDirective field='General Allowance' width='80'/>
                        <ColumnDirective field='Provident Fund' width='80'/>
                        <ColumnDirective field='Gross Salary' width='80'/>
                        <ColumnDirective field='PF Deduction' width='80'/>
                        <ColumnDirective field='Tax' width='70'/>
                        <ColumnDirective field='Net Pay' width='70'/>
                        <ColumnDirective field='' width='70'/>
                    </ColumnsDirective>
                </GridComponent>
            </div>
        )
    }
}

export default Table
