import React from "react";
import "../style.scss";
import { connect } from "react-redux";
import moment from 'moment';

function EmployeeDetails({ employeeDetailForLoan, loanDetails: { DisbursementDate, NoOfLoanTaken, Status } }) {
  const {
    Branch,
    DateOfRetirement,
    DateOfRetirementEng,
    Department,
    Group,
    JoinDate,
    JoinDateNep,
    Level,
    Name,
    PermanentDate,
    PermanentDateEng,
    Position,
    Title,
    YearOfService,
    BankACNo,
    // DisbursementDate,
    // NoOfLoanTaken,
  } = employeeDetailForLoan || {
    Branch: "",
    DateOfRetirement: "",
    DateOfRetirementEng: "",
    Department: "",
    Group: "",
    JoinDate: "",
    JoinDateNep: "",
    Level: "",
    Name: "",
    PermanentDate: "",
    PermanentDateEng: "",
    Position: "",
    Title: "",
    YearOfService: "",
    BankACNo: "",
    // DisbursementDate: "",
    // NoOfLoanTaken: "",
  };

  return (
    <div className="homeloan-view-employeedetails healthCard__health mt-md">
      <div className="healthCard-heading">
        <h4>Employee Details</h4>
      </div>
      <div className="healthCard__health__details">
        <div className="placement-details">
          <div className="placement-details__block">
            <div className="placement-details__table">
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Name
                </div>
                <div className="placement-details__col placement-val">
                  {Title + " " + Name || "-"}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Position
                </div>
                <div className="placement-details__col placement-val">
                  {Position || "-"}
                </div>
              </div>
              {/* <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Level
                </div>
                <div className="placement-details__col placement-val">
                  {Level || "-"}
                </div>
              </div> */}
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Branch
                </div>
                <div className="placement-details__col placement-val">
                  {Branch || "-"}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Department
                </div>
                <div className="placement-details__col placement-val">
                  {Department || "-"}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Join Date
                </div>
                <div className="placement-details__col placement-val">
                  {JoinDateNep || "-"}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Permanent Date
                </div>
                <div className="placement-details__col placement-val">
                  {PermanentDate || "-"}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Retirement Date
                </div>
                <div className="placement-details__col placement-val">
                  {DateOfRetirement || "-"}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  SS Account Number
                </div>
                <div className="placement-details__col placement-val">
                  {BankACNo || "-"}
                </div>
              </div>
              {Status > 3 && (
                <div className="placement-details__row">
                  <div className="placement-details__col placement-title">
                    Loans Taken
                  </div>
                  <div className="placement-details__col placement-val">
                    {NoOfLoanTaken || "-"}
                  </div>
                </div>
              )}
              {Status > 3 && (
                <div className="placement-details__row">
                  <div className="placement-details__col placement-title">
                    Latest Disbursement
                  </div>
                  <div className="placement-details__col placement-val">
                    {DisbursementDate ? moment(DisbursementDate).format('YYYY/MM/DD') : "-"}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  employeeDetailForLoan: state.loanReducer.employeeDetailForLoan,
});

export default connect(mapStateToProps, {})(EmployeeDetails);
