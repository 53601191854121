import React, { useState, useEffect } from "react";
import "./style.scss";
import * as calendarFuncs from "./helpers";
import classnames from "classnames";
import { getPagedYears, MONTH, YEAR, validateFormat } from "./helpers";
import { useSelector } from "react-redux";
import isEmpty from "isEmpty";
import * as engCalendarFuncs from "./engHelpers";
import {
  CommonHeader,
  CommonFooter,
  CommonAaction,
  CommonCore,
  CommonCell,
  CommonMonthTab,
  CommonYearTab,
} from "./common";
import useDateRange from "./useDateRange";
import { useHolidays } from "./common";

const isInNepali = false;

const getArrayFomatedDate = ({ month, day, year }) => {
  // return Formated Date YYYY/MM/DD
  return `${year}/${month}/${day}`;
};

const getDateFormatedFromArray = (date) => {
  // return Formated Date YYYY/MM/DD from [YYYY,MM,DD]
  return `${date[0]}/${date[1]}/${date[2]}`;
};
const getCalendarNum = (num) => {
  // const { isInNepali } = props;
  if (isInNepali) {
    return calendarFuncs.convertNumToNepali(num);
  } else {
    return num;
  }
};

interface EngCalendarProps {
  date?: any;
  noFooter?: boolean;
  onDateChange?: Function;
  isInNepali?: boolean;
  isFullDetails?: boolean;
  isDateRange?: boolean;
  minDate?: any;
  maxDate?: any;
  enableOkButton?: boolean;
  onOkButtonClicked?: Function;
  hideAction?: boolean;
}

function EngCal(props: EngCalendarProps) {
  // const formattedDate = dateFns.format(new Date(),'mm/dd/yyyy')
  // const [currentNepDate, setCurrentNepDate] = useState(AD2BS(formattedDate));

  const {
    isDateRange,
    date,
    onDateChange,
    isFullDetails,
    enableOkButton,
    hideAction,
  } = props;
  const { dateRange, setDateRange } = useDateRange(props, false);
  const [currentDate, setCurrentDate] = useState(engCalendarFuncs.getDateISO());
  const [isInNepali, setIsInNepali] = useState(false);
  const [today, setToday] = useState(engCalendarFuncs.getDateISO());
  const [selectedDate, setSelectedDate] = useState(null);
  const [tab, setTab] = useState(null);
  const [yearSetting, setYearSetting] = useState({
    indexPoint: 0,
    indexLeap: 6,
  });
  const [hoveredDate, setHoveredDate] = React.useState("");
  const holidays = useHolidays(false);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);

  useEffect(() => {
    if (date) {
      if (isDateRange) {
        if ("startDate" in date) {
          if (validateFormat(date.startDate, true)) {
            setSelectedDate(date.startDate);
            date.startDate
              ? setCurrentDate(date.startDate)
              : engCalendarFuncs.getDateISO();
          }
        }
      } else {
        if (validateFormat(date, true)) {
          setSelectedDate(date);
          setCurrentDate(date);
        }
      }
    }
  }, [date]);

  useEffect(() => {
    if (props.minDate) {
      setMinDate(props.minDate);
    }
    if (props.maxDate) {
      setMaxDate(props.maxDate);
    }
  }, [props.minDate, props.maxDate]);

  const header = () => {
    const dateFormat = "mmmm yyyy";
    const { year, month, day } = calendarFuncs.getNepDateSeperated(currentDate);
    const monthname = Object.keys(engCalendarFuncs.CALENDAR_MONTHS)[
      Math.max(0, Math.min(month - 1, 11))
    ];
    const engMonthname = Object.keys(
      engCalendarFuncs.CALENDAR_MONTHS_ENG_NEPALI
    )[Math.max(0, Math.min(month - 1, 11))];
    return (
      <CommonHeader
        tab={tab}
        setTab={setTab}
        year={getCalendarNum(year)}
        month={monthname}
        secondaryMonth={engMonthname}
      />
    );
  };
  const footer = () => {
    var yesterday = engCalendarFuncs.getDateISO(
      new Date(new Date().setDate(new Date().getDate() - 1))
    );
    var tomorrow = engCalendarFuncs.getDateISO(
      new Date(new Date().setDate(new Date().getDate() + 1))
    );
    return (
      <CommonFooter
        onClick={onDateClick}
        yesterday={yesterday}
        today={today}
        tomorrow={tomorrow}
        maxDate={props.maxDate}
        minDate={props.minDate}
        enableOkButton={props.enableOkButton}
        onOkClick={() => {
          let date: any = currentDate;
          if (isFullDetails) {
            let engDate = engCalendarFuncs.getFullEngDate(date);
            let nepaliDate = calendarFuncs.getFullNepaliDate(date);
            props.onOkButtonClicked &&
              props.onOkButtonClicked(date, {
                date: date,
                nepaliDate,
                engDate,
              });
          } else {
            props.onOkButtonClicked && props.onOkButtonClicked(date);
          }
        }}
      />
    );
  };
  const actions = () => {
    return (
      <CommonAaction
        current={() => {
          setTab(false);
          setCurrentDate(today);
        }}
        prevMonth={prevMonth}
        nextMonth={nextMonth}
        prevYear={prevYear}
        nextYear={nextYear}
      />
    );
  };
  const renderDayLabels = (day) => {
    return (
      <div className="calendar-body__cell calendar-body__day">
        {engCalendarFuncs.WEEK_DAYS[day].toUpperCase()}
      </div>
    );
  };
  const days = () => {
    return (
      <div className="calendar-body__grid">
        {Object.keys(engCalendarFuncs.WEEK_DAYS).map(renderDayLabels)}
      </div>
    );
  };
  const ClickMonth = (changeMonth) => {
    const [year, month, day] = calendarFuncs.getNepDateArray(currentDate);
    setCurrentDate(getArrayFomatedDate({ year, day, month: changeMonth }));
    setTab(null);
  };
  const ClickYear = (changeYear) => {
    const [year, month, day] = calendarFuncs.getNepDateArray(currentDate);
    setCurrentDate(getArrayFomatedDate({ year: changeYear, day, month }));
    setTab(null);
  };
  // Rendering months and years after on month or year tab click
  const MonthYearTabs = () => {
    const [year, month, day] = calendarFuncs.getNepDateArray(currentDate);
    const months = Object.keys(engCalendarFuncs.CALENDAR_MONTHS);
    const monthname = Object.keys(calendarFuncs.CALENDAR_MONTHS)[
      Math.max(0, Math.min(month - 1, 11))
    ];
    if (tab === MONTH) {
      return (
        <CommonMonthTab
          onMonthClick={ClickMonth}
          currentMonth={monthname}
          months={months}
        />
      );
    }
    const { leftArrow, rightArrow, SplittedYears } = getPagedYears(
      year,
      yearSetting,
      true
    );
    if (tab === YEAR) {
      return (
        <CommonYearTab
          leftArrow={leftArrow}
          rightArrow={rightArrow}
          SplittedYears={SplittedYears}
          OnYearClick={ClickYear}
          yearSetting={yearSetting}
          year={year}
          setYearSetting={setYearSetting}
        />
      );
    }
    setTab(null);
    return null;
  };
  // Date Cell, Active Month Dates
  const cells = () => {
    const monthDates = engCalendarFuncs.calendarDates(currentDate);
    // const rows = [];
    const rows = monthDates.map((item, index) => {
      const date = getDateFormatedFromArray(item);

      const isStartGreater =
        engCalendarFuncs.dateDiff(dateRange.startDate, hoveredDate) < 0;
      const isStartNeutral = dateRange.endDate
        ? engCalendarFuncs.dateDiff(dateRange.startDate, dateRange.endDate) ==
            0 &&
          engCalendarFuncs.isSameDay(dateRange.startDate, date) &&
          engCalendarFuncs.isSameDay(dateRange.endDate, date)
        : false;

      const isStart =
        dateRange.startDate &&
        engCalendarFuncs.isSameDay(date, dateRange.startDate);
      const isEnd =
        dateRange.endDate &&
        engCalendarFuncs.isSameDay(date, dateRange.endDate);
      const isInBetweenHoverDate = isStartGreater
        ? engCalendarFuncs.inBetween(dateRange.startDate, hoveredDate, date)
        : engCalendarFuncs.inBetween(hoveredDate, dateRange.startDate, date);
      const isInBetweenDate = engCalendarFuncs.inBetween(
        dateRange.startDate,
        dateRange.endDate,
        date
      );

      const inMonth = engCalendarFuncs.isSameMonth(date, currentDate);
      const isToday = engCalendarFuncs.isSameDay(date, today);
      const isCurrent =
        selectedDate &&
        engCalendarFuncs.isSameDay(date, selectedDate) &&
        !isDateRange;
      const isHoliday = Object.keys(holidays).includes(date);
      const holiday = isHoliday ? holidays[date] : null;
      const isDisabled =
        minDate || maxDate
          ? minDate && maxDate
            ? engCalendarFuncs.isAfterDay(minDate, date) ||
              engCalendarFuncs.isBeforeDay(maxDate, date)
            : minDate
            ? engCalendarFuncs.isAfterDay(minDate, date)
            : maxDate
            ? engCalendarFuncs.isBeforeDay(maxDate, date)
            : false
          : false;

      return (
        <CommonCell
          holiday={holiday}
          cell={getCalendarNum(item[2])}
          onCellClick={onDateClick}
          date={date}
          inMonth={inMonth}
          isToday={isToday}
          isCurrent={isCurrent}
          isHoliday={isHoliday}
          isStart={isStart}
          isStartNeutral={isStartNeutral}
          isInBetweenHoverDate={isInBetweenHoverDate}
          isEnd={isEnd}
          isInBetweenDate={isInBetweenDate}
          isDateRange={isDateRange}
          dateRange={dateRange}
          hoveredDate={hoveredDate}
          isDisabled={isDisabled}
          setHoveredDate={setHoveredDate}
        />
      );
    });
    return <div className="calendar-body__grid ">{rows}</div>;
  };
  const nextMonth = () => {
    // returns {month + 1, year, day}
    const dates = engCalendarFuncs.getNextMonth(currentDate);
    setTab(false);
    setCurrentDate(getArrayFomatedDate(dates));
  };
  const prevMonth = () => {
    // returns {month - 1, year, day}
    const dates = engCalendarFuncs.getPreviousMonth(currentDate);
    setTab(false);
    setCurrentDate(getArrayFomatedDate(dates));
  };
  const prevYear = () => {
    // returns {month, year -1, day}
    const dates = engCalendarFuncs.getPreviousYear(currentDate);
    setTab(false);
    setCurrentDate(getArrayFomatedDate(dates));
  };
  const nextYear = () => {
    // returns {month, year + 1, day}
    const dates = engCalendarFuncs.getNextYear(currentDate);
    setTab(false);
    setCurrentDate(getArrayFomatedDate(dates));
  };
  const onDateClick = (day) => {
    setCurrentDate(day);
    setSelectedDate(day);
    if (isDateRange) {
      setDateRange(day);
    } else {
      setSelectedDate(day);
      setCurrentDate(day);
      onDateChange && onDateChange(day);
      if (isFullDetails) {
        let engDate = engCalendarFuncs.getFullEngDate(day);
        let nepaliDate = calendarFuncs.getFullNepaliDate(day);
        props.onDateChange &&
          props.onDateChange(day, { date: day, nepaliDate, engDate });
      } else {
        props.onDateChange && props.onDateChange(day);
      }
    }
    setTab(false);
  };
  return (
    <CommonCore
      header={header}
      days={days}
      cells={cells}
      tab={tab}
      actions={!hideAction && actions}
      monthYearTabs={MonthYearTabs}
      noFooter={props.noFooter}
      footer={footer}
    />
  );
}

export default EngCal;
