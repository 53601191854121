import React, { useState, useEffect, useRef } from "react";

import { GrAttachment } from "react-icons/gr";
import Avatar from "react-avatar";
import CommentEditor from "./commentEditor";
import classNames from "classnames";
import {
  FaRegUserCircle,
  FaRegComment,
  FaRegSmile,
  FaCheck,
  FaTimes,
  FaPencilAlt,
  FaTrashAlt,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { hostedUrl } from "constants/urls";
import {
  saveComment,
  getCommentsByTaskId,
} from "../../../../../helpers/action";
import { connect } from "react-redux";

import CommentBody from "./commentBody";
import { Scrollbars } from "react-custom-scrollbars";
import { UPDATE_TABLE_VIEW } from "scenes/tasks/helpers/types";
const CommentSection = (props: any) => {
  const event = new Event("scroll");
  const { taskComments, onFetchingComplete, task, rowIndex, stage } = props;
  const scrollRef = useRef<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.commentScroll) scrollRef.current.scrollToBottom();
  }, [props.commentScroll, props.taskComments]);

  const handleSubmit = async (param) => {
    await props.saveComment(param);
  };

  useEffect(() => {
    console.log("taskComments", taskComments)
    taskComments && updateBoardInfo();
  }, [taskComments]);

  const updateBoardInfo = () => {
    let newTask = {
      ...task,
      TotalComments: taskComments.length,
    };

    dispatch({
      //type: TmViewType.TableView ? UPDATE_TABLE_VIEW : "", //board view
      type: UPDATE_TABLE_VIEW,
      payload: {
        task: newTask,
        rowIndex: rowIndex,
        stage: stage,
      },
    });
  };
  useEffect(() => {
    if (props.taskId) {
      fetchComments();
    }
  }, [props.taskId]);

  const fetchComments = async () => {
    await props.getCommentsByTaskId(props.taskId);
    onFetchingComplete && onFetchingComplete();
  };
  return (
    <>
      <div className="taskmodal__activities-title flex">
        <GrAttachment />
        <h3>Activities</h3>
      </div>
      <div className="taskmodal__activities-content">
        <Scrollbars
          ref={scrollRef}
          autoHide
          style={{ width: "100%" }}
          onScroll={(e) => {
            document.dispatchEvent(event);
          }}
        >
          <div className="scroll-content">

            {taskComments.map((x, index) => (
              <CommentBody key={index} data={x} members={props.members || []} />
            ))}
          </div>
        </Scrollbars>
      </div>
      {/* <CommentTools
        value="test commentss"
        name="comment"
        members={props.members || []}
        taskId={props.taskId}
        handleSubmit={handleSubmit}
      /> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  taskComments: state.taskReducer.taskComments,
  commentScroll: state.taskReducer.commentScroll,
});
const mapDispatchToProps = { saveComment, getCommentsByTaskId };
export default connect(mapStateToProps, mapDispatchToProps)(CommentSection);
