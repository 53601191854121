import React, { Component } from "react";
import { MdSettings } from "react-icons/md";
import PropTypes from "prop-types";
import drag from "assets/images/icons/ellipse.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export class DragableBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lists: props.initial,
    };
    this._renderBlock = this._renderBlock.bind(this);
  }

  onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;
    if (!destination) {
      return;
    }
    const { lists } = this.state;
    const droppableIdStart = source.droppableId;
    const droppableIdEnd = destination.droppableId;
    const droppableIndexEnd = destination.index;
    const droppableIndexStart = source.index;

    if (droppableIdStart === droppableIdEnd) {
      const listStart = lists.find((list) => list.id === droppableIdStart);

      const card = listStart.cards.splice(droppableIndexStart, 1);
      listStart.cards.splice(droppableIndexEnd, 0, ...card);
      this.setState((prevState) => ({
        ...prevState,
        lists: prevState.lists.map((list) => {
          if (list.id === listStart.id) {
            return listStart;
          }
          return list;
        }),
      }));
    }
    if (droppableIdStart !== droppableIdEnd) {
      // find the list where the drag happened
      const listStart = lists.find((list) => list.id === droppableIdStart);
      // pull out the card from this list
      const card = listStart.cards.splice(droppableIndexStart, 1);
      // find the list where the drag ended
      const listEnd = lists.find((list) => list.id === droppableIdEnd);
      // put the card in the new list
      listEnd.cards.splice(droppableIndexEnd, 0, ...card);
      this.setState((prevState) => ({
        ...prevState,
        lists: prevState.lists.map((list) => {
          if (list.id === listEnd.id) {
            return listEnd;
          } else if (list.id === listStart.id) {
            return listStart;
          }
          return list;
        }),
      }));
    }
  };

  _renderBlock(props, index) {
    return (
      <Draggable draggableId={String(props.id)} index={index} key={props.id}>
        {(provided) => (
          <div
            {...provided.draggableProps}
            // {...provided.dragHandleProps}
            ref={provided.innerRef}
            className="drag-block__item drag-box"
          >
            <div className="drag-box__header">
              <div className="drag-box__title">
                <h4>{props.name}</h4>
              </div>
              <div className="drag-box__icons">
                {props.showSetting && (
                  <span className="drag-box__icon">
                    <MdSettings />
                  </span>
                )}
                <span
                  {...provided.dragHandleProps}
                  className="drag-box__icon drag"
                >
                  <img src={drag} width="16" height="16" />
                </span>
              </div>
            </div>
            <div className="drag-box__body">
              <props.component {...props.props} />
            </div>
            {props.footer && (
              <div className="drag-box__footer">
                <span>{props.footer}</span>
              </div>
            )}
          </div>
        )}
      </Draggable>
    );
  }

  render() {
    const { lists } = this.state;
    return (
      <div className="drag-block">
        <div className="drag-block__container">
          <div className="drag-block__items">
            <DragDropContext onDragEnd={this.onDragEnd}>
              {lists.map((item, index) => {
                return (
                  <Droppable
                    droppableId={String(item.id)}
                    index={index}
                    type="list"
                  >
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        nf={provided}
                        index={index}
                        className="drag-block__items-list"
                      >
                        {item.cards.map(this._renderBlock)}
                      </div>
                    )}
                  </Droppable>
                );
              })}
            </DragDropContext>
          </div>
        </div>
      </div>
    );
  }
}

DragableBlock.propTypes = {
  showSetting: PropTypes.bool,
  footer: PropTypes.any,
};
DragableBlock.defaultProps = {
  showSetting: true,
  footer: null,
};

export default DragableBlock;
