import React, {useEffect} from 'react'
import Search from './components/search';
import NewUserGroup from './components/newUserGroup';
import Table from './components/table'
import './authorized.styles.scss';
import {getUserMemberPoolOptions} from './helpers/action'
import { useDispatch } from 'react-redux';

function Authorized() {
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(getUserMemberPoolOptions())
    },[])
    return (
        <div className="admin roles">
            <div className="admin-container">
                <NewUserGroup />
                <div className="admin-body">
                    <Search />
                    <Table />
                </div>
            </div>
        </div>
    )
}

export default Authorized
