import axios from "axios";
import { toastMessage } from "actions/validateAction";
import {
  IS_EMPLOYEE_PROFILE_LOADING,
  GET_EMPLOYEE_PROFILE_DETAIL,
  GET_EMPLOYEE_MANAGERS_FOR_JOB,
  GET_EMPLOYEE_MANAGER_HISTORY,
  GET_JOB_DESC_BY_LEVEL,
  ASSIGN_JOB_DESCRIPTION,
  CHANGE_ASSIGNED_JOB_DESCRIPTION,
  GET_EMPLOYEE_ASSIGNED_JOB_DESCRIPTION,
  SET_MANAGER_FOR_EMPLOYEE,
  EMPLOYEE_KSA_RATING,
  UPDATE_EMPLOYEE_KSA_RATING,
  GET_EMPLOYEE_ROLES_HISTORY,
  EMPLOYEE_EDUCATION_LIST,
  SAVE_EMPLOYEE_EDUCATION_DETAILS,
  DELETE_EMPLOYEE_EDUCATION_DETAIL,
  CURRENTLY_SELECTED_EDU_SECTION,
  GET_EMPLOYEE_TRAINING_LIST,
  SAVE_EMPLOYEE_TRAINING_DETAILS,
  DELETE_EMPLOYEE_TRAINING_DETAIL,
  GET_EMPLOYEE_EXPERIENCE_LIST,
  SAVE_EMPLOYEE_EXPERIENCE_DETAILS,
  DELETE_EMPLOYEE_EXPERIENCE_DETAIL,
  GET_EMPLOYEE_REFERENCE_LIST,
  SAVE_EMPLOYEE_REFERENCE_DETAILS,
  DELETE_EMPLOYEE_REFERENCE_DETAIL,
  GET_EMPLOYEE_LEAVE_LIST,
  GET_EDUCTION_QUALIFICATION_LIST,
  GET_EMPLOYEE_ADDITIONAL_DOCUMENTS,
  ADD_EMPLOYEE_ADDITIONAL_DOCUMENTS,
  EDIT_EMPLOYEE_ADDITIONAL_DOCUMENTS,
  ASSIGN_JOB_DESCRIPTION_HISTORY,
  DELETE_EMPLOYEE_ADDITIONAL_DOCUMENTS,
  SAVE_EMPLOYEE_FAMILY_INFO,
  SAVE_EMPLOYEE_HEALTH_INFO,
  GET_EMPLOYEE_ADDRESS_DETAILS,
  SAVE_EMPLOYEE_ADDRESS_DETAILS,
  GET_EMPLOYEE_CITIZENSHIP_DETAILS,
  GET_EMPLOYEE_DRIVING_DETAILS,
  GET_EMPLOYEE_PASSPORT_DETAILS,
  DELETE_EMPLOYEE_DOCS,
  EMPLOYEE_FAMILIES_DETAIL,
  EMPLOYEE_HEALTH_DETAIL,
} from "actions/types";
import { loadingHeaders } from "helpers/getHeaders";

export const getProfileDetail = (employeeId) => async (dispatch) => {
  
  dispatch({
    type: IS_EMPLOYEE_PROFILE_LOADING,
    isLoading: true,
  });
  await axios
    .get(`v1/employee-record/employees/profile?id=${employeeId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: GET_EMPLOYEE_PROFILE_DETAIL,
          payload: response.Data,
        });
        dispatch({
          type: IS_EMPLOYEE_PROFILE_LOADING,
          isLoading: false,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to Fetch Detail.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const getEmployeeManagers = (employeeId) => async (dispatch) => {
  dispatch({
    type: GET_EMPLOYEE_MANAGERS_FOR_JOB,
    payload: [],
  });
  await axios
    .get(`/v1/hr/managers/${employeeId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: GET_EMPLOYEE_MANAGERS_FOR_JOB,
          payload: response.Data,
        });
      } else {
        dispatch({
          type: GET_EMPLOYEE_MANAGERS_FOR_JOB,
          payload: [],
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to Fetch Employee's Managers.",
        },
      };
      dispatch({
        type: GET_EMPLOYEE_MANAGERS_FOR_JOB,
        payload: [],
      });
      toastMessage(dispatch, response);
    });
};

export const getEmployeeManagerHistory = (employeeId) => async (dispatch) => {
  await axios
    .get(`/v1/hr/managers/history/${employeeId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: GET_EMPLOYEE_MANAGER_HISTORY,
          payload: response.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to Fetch Employee's Managers.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const getJobDescriptionByLevel = (data) => async (dispatch) => {
  await axios
    .post(`/v1/jd/assignments/applicable/search`, data)
    .then((resp) => {
      // .post(`/v1/jp/change-job-discription`, data)
      // .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        let formatedData = [];
        Promise.all(
          response.Data.map((d) => {
            formatedData.push({
              label: d.JobTitle,
              value: d.JobDescriptionId,
              code: d.JobCode,
              totalRows: d.TotalRows,
            });
          })
        );
        dispatch({
          type: GET_JOB_DESC_BY_LEVEL,
          payload: formatedData,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to Fetch Employee's Managers.",
        },
      };
      toastMessage(dispatch, response);
    });
};
// export const getJobDescriptionByLevel = (employeeId) => async dispatch => {
//     await axios
//         .get(`/v1/jp/job-discriptions-by-level?emplpoyeeId=${employeeId}`)
//         .then(resp => {
//         const response = resp.data;
//         if (response.Status) {
//             let formatedData = [];
//             Promise.all(
//             response.Data.map((d) => {
//                 formatedData.push({ label: d.JobTitle, value: d.JobDescriptionId });
//             })
//             );
//             dispatch({
//                 type: GET_JOB_DESC_BY_LEVEL,
//                 payload: formatedData
//             });
//         } else {
//             toastMessage(dispatch, response);
//         }
//         })
//         .catch(err => {
//         const response = {
//             data: {
//             MessageType: "Danger",
//             Message: "Failed to Fetch Employee's Managers."
//             }
//         };
//         toastMessage(dispatch, response);
//     });
// };

export const getEmployeeRolesHistory = (employeeId) => async (dispatch) => {
  await axios
    .get(`/v1/jp/job-discription-history?jobDiscriptionId=${employeeId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: GET_EMPLOYEE_MANAGER_HISTORY,
          payload: response.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to Fetch Employee's Managers.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const getJDViewDetails = (jobId) => async (dispatch) => {
  await axios
    .get(`/v1/jp/job-descriptions/${jobId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: GET_EMPLOYEE_ASSIGNED_JOB_DESCRIPTION,
          payload: response.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to Fetch Employee's Managers.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const assignJobDescription = (data, props) => async (dispatch) => {
  dispatch({
    type: ASSIGN_JOB_DESCRIPTION,
    payload: false,
  });
  await axios
    .post(`/v1/jd/assignments`, data)
    .then((resp) => {
      const response = resp.data;
      if (response) {
        dispatch({
          type: ASSIGN_JOB_DESCRIPTION,
          payload: response.Data,
        });
        toastMessage(dispatch, response);
      } else toastMessage(dispatch, response);
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to create Project.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const assignJobDescriptionHistory = (jdId) => async (dispatch) => {
  await axios
    .get(`/v1/jd/assignments/history?jdId=${jdId}`)
    .then((resp) => {
      const response = resp.data;
      if (response) {
        dispatch({
          type: ASSIGN_JOB_DESCRIPTION_HISTORY,
          payload: response.Data,
        });
        toastMessage(dispatch, response);
      } else toastMessage(dispatch, response);
    })
    .catch((err) => {
      dispatch({
        type: ASSIGN_JOB_DESCRIPTION_HISTORY,
        payload: [],
      });
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to create Project.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const changeAssignedJobDescription = (data, props) => async (
  dispatch
) => {
  dispatch({
    type: CHANGE_ASSIGNED_JOB_DESCRIPTION,
    payload: false,
  });
  await axios
    .put(`/v1/jd/assignments`, data)
    .then((resp) => {
      const response = resp.data;
      if (response) {
        dispatch({
          type: CHANGE_ASSIGNED_JOB_DESCRIPTION,
          payload: true,
        });
        toastMessage(dispatch, response);
      } else toastMessage(dispatch, response);
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to create Project.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const getEmployeeJobDescription = (employeeId, props) => async (
  dispatch
) => {
  dispatch({
    type: GET_EMPLOYEE_ASSIGNED_JOB_DESCRIPTION,
    payload: null,
  });
  await axios
    .get(`/v1/jd/assignments/current?employeeId=${employeeId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: GET_EMPLOYEE_ASSIGNED_JOB_DESCRIPTION,
          payload: response.Data,
        });
      } else {
        dispatch({
          type: GET_EMPLOYEE_ASSIGNED_JOB_DESCRIPTION,
          payload: null,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to Fetch Employee's Managers.",
        },
      };
      dispatch({
        type: GET_EMPLOYEE_ASSIGNED_JOB_DESCRIPTION,
        payload: null,
      });
      toastMessage(dispatch, response);
    });
};

export const setManagerForEmployee = (data, onClose) => async (dispatch) => {
  await axios
    .post(`/v1/hr/managers`, data)
    .then((resp) => {
      const response = resp.data;
      if (response) {
        dispatch({
          type: SET_MANAGER_FOR_EMPLOYEE,
          payload: response.Data[0],
        });
        onClose && onClose();
        toastMessage(dispatch, response);
      } else {
        onClose && onClose();
        dispatch({
          type: SET_MANAGER_FOR_EMPLOYEE,
          payload: false,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to create Project.",
        },
      };
      dispatch({
        type: SET_MANAGER_FOR_EMPLOYEE,
        payload: false,
      });
      toastMessage(dispatch, response);
    });
};

export const getEmployeeKsaRating = (employeeId, props) => async (dispatch) => {
  await axios
    .get(`/v1/jp/ksa-ratings?employeeId=${employeeId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        let responseData = response.Data;
        dispatch({
          type: EMPLOYEE_KSA_RATING,
          payload: responseData,
        });
      } else {
        dispatch({
          type: EMPLOYEE_KSA_RATING,
          payload: null,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to Fetch Employee's Managers.",
        },
      };
      dispatch({
        type: EMPLOYEE_KSA_RATING,
        payload: null,
      });
      toastMessage(dispatch, response);
    });
};

export const updateEmployeeKsaRating = (data, onClose) => async (dispatch) => {
  await axios
    .put(`/v1/jp/ksa-ratings`, data)
    .then((resp) => {
      const response = resp.data;
      if (response) {
        dispatch({
          type: UPDATE_EMPLOYEE_KSA_RATING,
          payload: true,
        });
        onClose && onClose();
        toastMessage(dispatch, response);
      } else {
        onClose && onClose();
        dispatch({
          type: UPDATE_EMPLOYEE_KSA_RATING,
          payload: false,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to create Project.",
        },
      };
      onClose && onClose();
      dispatch({
        type: UPDATE_EMPLOYEE_KSA_RATING,
        payload: false,
      });
      toastMessage(dispatch, response);
    });
};

export const saveEmployeeKsaRating = (data, onClose) => async (dispatch) => {
  await axios
    .post(`/v1/jp/ksa-ratings`, data)
    .then((resp) => {
      const response = resp.data;
      if (response) {
        dispatch({
          type: SET_MANAGER_FOR_EMPLOYEE,
          payload: response.Data[0],
        });
        onClose && onClose();
        toastMessage(dispatch, response);
      } else {
        onClose && onClose();
        dispatch({
          type: SET_MANAGER_FOR_EMPLOYEE,
          payload: false,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to create Project.",
        },
      };
      onClose && onClose();
      dispatch({
        type: SET_MANAGER_FOR_EMPLOYEE,
        payload: false,
      });
      toastMessage(dispatch, response);
    });
};

// education

export const getEmployeeEducationList = (employeeId) => async (dispatch) => {
  await axios
    .get(`/v1/employee-record/educations/${employeeId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        let responseData = response.Data;
        dispatch({
          type: EMPLOYEE_EDUCATION_LIST,
          payload: responseData,
        });
      } else {
        dispatch({
          type: EMPLOYEE_EDUCATION_LIST,
          payload: [],
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      dispatch({
        type: EMPLOYEE_EDUCATION_LIST,
        payload: [],
      });
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to Fetch Employee's Education List",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const saveEmployeeEducationDetails = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_EMPLOYEE_EDUCATION_DETAILS,
    payload: false,
  });
  
  await axios
    .post(`/v1/employee-record/educations`, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((resp) => {
      const response = resp.data;
      if (response) {
        dispatch({
          type: SAVE_EMPLOYEE_EDUCATION_DETAILS,
          payload: true,
        });
        toastMessage(dispatch, response);
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to Save Education Details.",
        },
      };
      dispatch({
        type: SAVE_EMPLOYEE_EDUCATION_DETAILS,
        payload: false,
      });
      toastMessage(dispatch, response);
    });
};

export const updateEmployeeEducationDetails = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_EMPLOYEE_EDUCATION_DETAILS,
    payload: false,
  });
  
  await axios
    .put(`/v1/employee-record/educations`, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((resp) => {
      const response = resp.data;
      if (response) {
        dispatch({
          type: SAVE_EMPLOYEE_EDUCATION_DETAILS,
          payload: true,
        });
        toastMessage(dispatch, response);
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to Save Education Details.",
        },
      };
      dispatch({
        type: SAVE_EMPLOYEE_EDUCATION_DETAILS,
        payload: false,
      });
      toastMessage(dispatch, response);
    });
};

export const deleteEmployeeEducation = (educationId) => async (dispatch) => {
  dispatch({
    type: DELETE_EMPLOYEE_EDUCATION_DETAIL,
    payload: false,
  });
  await axios
    .delete(`/v1/employee-record/educations/${educationId}`)
    .then((resp) => {
      const response = resp.data;
      if (response) {
        dispatch({
          type: DELETE_EMPLOYEE_EDUCATION_DETAIL,
          payload: true,
        });
        toastMessage(dispatch, response);
      } else {
        dispatch({
          type: DELETE_EMPLOYEE_EDUCATION_DETAIL,
          payload: false,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to delete Education Details.",
        },
      };
      dispatch({
        type: DELETE_EMPLOYEE_EDUCATION_DETAIL,
        payload: false,
      });
      toastMessage(dispatch, response);
    });
};

export const currentlySelectedSection = (title) => async (dispatch) => {
  dispatch({
    type: CURRENTLY_SELECTED_EDU_SECTION,
    payload: title,
  });
};

// Training Section
export const getEmployeeTrainingList = (employeeId) => async (dispatch) => {
  await axios
    .get(`/v1/employee-record/trainings/${employeeId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        let responseData = response.Data;
        dispatch({
          type: GET_EMPLOYEE_TRAINING_LIST,
          payload: responseData,
        });
      } else {
        dispatch({
          type: GET_EMPLOYEE_TRAINING_LIST,
          payload: [],
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_EMPLOYEE_TRAINING_LIST,
        payload: [],
      });
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to Fetch Employee's Training List",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const saveEmployeeTrainingDetails = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_EMPLOYEE_TRAINING_DETAILS,
    payload: false,
  });
  await axios
    .post(`/v1/employee-record/trainings`, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((resp) => {
      const response = resp.data;
      if (response) {
        dispatch({
          type: SAVE_EMPLOYEE_TRAINING_DETAILS,
          payload: true,
        });
        toastMessage(dispatch, response);
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to Save Training Details.",
        },
      };
      dispatch({
        type: SAVE_EMPLOYEE_TRAINING_DETAILS,
        payload: false,
      });
      toastMessage(dispatch, response);
    });
};

export const updateEmployeeTrainingDetails = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_EMPLOYEE_TRAINING_DETAILS,
    payload: false,
  });
  await axios
    .put(`/v1/employee-record/trainings`, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((resp) => {
      const response = resp.data;
      if (response) {
        dispatch({
          type: SAVE_EMPLOYEE_TRAINING_DETAILS,
          payload: true,
        });
        toastMessage(dispatch, response);
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to Update Training Details.",
        },
      };
      dispatch({
        type: SAVE_EMPLOYEE_TRAINING_DETAILS,
        payload: false,
      });
      toastMessage(dispatch, response);
    });
};

export const deleteEmployeeTrainingDetails = (trainingId) => async (
  dispatch
) => {
  dispatch({
    type: DELETE_EMPLOYEE_TRAINING_DETAIL,
    payload: false,
  });
  await axios
    .delete(`/v1/employee-record/trainings/${trainingId}`)
    .then((resp) => {
      const response = resp.data;
      if (response) {
        dispatch({
          type: DELETE_EMPLOYEE_TRAINING_DETAIL,
          payload: true,
        });
        toastMessage(dispatch, response);
      } else {
        dispatch({
          type: DELETE_EMPLOYEE_TRAINING_DETAIL,
          payload: false,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to delete Education Details.",
        },
      };
      dispatch({
        type: DELETE_EMPLOYEE_TRAINING_DETAIL,
        payload: false,
      });
      toastMessage(dispatch, response);
    });
};

// WorkExperience Section
export const getEmployeeExperienceList = (employeeId) => async (dispatch) => {
  await axios
    .get(`/v1/employee-record/employments-previous/employee/${employeeId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        let responseData = response.Data;
        dispatch({
          type: GET_EMPLOYEE_EXPERIENCE_LIST,
          payload: responseData,
        });
      } else {
        dispatch({
          type: GET_EMPLOYEE_EXPERIENCE_LIST,
          payload: [],
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to Fetch Employee's Work Experience List",
        },
      };
      dispatch({
        type: GET_EMPLOYEE_EXPERIENCE_LIST,
        payload: [],
      });
      toastMessage(dispatch, response);
    });
};

export const saveEmployeeExperienceDetails = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_EMPLOYEE_EXPERIENCE_DETAILS,
    payload: false,
  });
  await axios
    .post(`/v1/employee-record/employments-previous`, data)
    .then((resp) => {
      const response = resp.data;
      if (response) {
        dispatch({
          type: SAVE_EMPLOYEE_EXPERIENCE_DETAILS,
          payload: true,
        });
        toastMessage(dispatch, response);
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to Save Experience Details.",
        },
      };
      dispatch({
        type: SAVE_EMPLOYEE_EXPERIENCE_DETAILS,
        payload: false,
      });
      toastMessage(dispatch, response);
    });
};

export const updateEmployeeExperienceDetails = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_EMPLOYEE_EXPERIENCE_DETAILS,
    payload: false,
  });
  await axios
    .put(`/v1/employee-record/employments-previous`, data)
    .then((resp) => {
      const response = resp.data;
      if (response) {
        dispatch({
          type: SAVE_EMPLOYEE_EXPERIENCE_DETAILS,
          payload: true,
        });
        toastMessage(dispatch, response);
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to Update Experience Details.",
        },
      };
      dispatch({
        type: SAVE_EMPLOYEE_EXPERIENCE_DETAILS,
        payload: false,
      });
      toastMessage(dispatch, response);
    });
};

export const deleteEmployeeExperienceDetails = (trainingId) => async (
  dispatch
) => {
  dispatch({
    type: DELETE_EMPLOYEE_EXPERIENCE_DETAIL,
    payload: false,
  });
  await axios
    .delete(`/v1/employee-record/employments-previous/${trainingId}`)
    .then((resp) => {
      const response = resp.data;
      if (response) {
        dispatch({
          type: DELETE_EMPLOYEE_EXPERIENCE_DETAIL,
          payload: true,
        });
        toastMessage(dispatch, response);
      } else {
        dispatch({
          type: DELETE_EMPLOYEE_EXPERIENCE_DETAIL,
          payload: false,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to delete Experience Details.",
        },
      };
      dispatch({
        type: DELETE_EMPLOYEE_EXPERIENCE_DETAIL,
        payload: false,
      });
      toastMessage(dispatch, response);
    });
};

// Referenec Section
export const getEmployeeReferenceList = (employeeId) => async (dispatch) => {
  await axios
    .get(`/v1/employee-record/references/employee/${employeeId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        let responseData = response.Data;
        dispatch({
          type: GET_EMPLOYEE_REFERENCE_LIST,
          payload: responseData,
        });
      } else {
        dispatch({
          type: GET_EMPLOYEE_REFERENCE_LIST,
          payload: [],
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_EMPLOYEE_REFERENCE_LIST,
        payload: [],
      });
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to Fetch Employee's Reference List",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const saveEmployeeReferenceDetails = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_EMPLOYEE_REFERENCE_DETAILS,
    payload: false,
  });
  await axios
    .post(`/v1/employee-record/references`, data)
    .then((resp) => {
      const response = resp.data;
      if (response) {
        dispatch({
          type: SAVE_EMPLOYEE_REFERENCE_DETAILS,
          payload: true,
        });
        toastMessage(dispatch, response);
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to Save Reference Details.",
        },
      };
      dispatch({
        type: SAVE_EMPLOYEE_REFERENCE_DETAILS,
        payload: false,
      });
      toastMessage(dispatch, response);
    });
};

export const updateEmployeeReferenceDetails = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_EMPLOYEE_REFERENCE_DETAILS,
    payload: false,
  });
  await axios
    .put(`/v1/employee-record/references`, data)
    .then((resp) => {
      const response = resp.data;
      if (response) {
        dispatch({
          type: SAVE_EMPLOYEE_REFERENCE_DETAILS,
          payload: true,
        });
        toastMessage(dispatch, response);
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to Update Reference Details.",
        },
      };
      dispatch({
        type: SAVE_EMPLOYEE_REFERENCE_DETAILS,
        payload: false,
      });
      toastMessage(dispatch, response);
    });
};

export const deleteEmployeeReferenceDetails = (referenceId) => async (
  dispatch
) => {
  dispatch({
    type: DELETE_EMPLOYEE_REFERENCE_DETAIL,
    payload: false,
  });
  await axios
    .delete(`/v1/employee-record/references/${referenceId}`)
    .then((resp) => {
      const response = resp.data;
      if (response) {
        dispatch({
          type: DELETE_EMPLOYEE_REFERENCE_DETAIL,
          payload: true,
        });
        toastMessage(dispatch, response);
      } else {
        dispatch({
          type: DELETE_EMPLOYEE_REFERENCE_DETAIL,
          payload: false,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to delete Reference Details.",
        },
      };
      dispatch({
        type: DELETE_EMPLOYEE_REFERENCE_DETAIL,
        payload: false,
      });
      toastMessage(dispatch, response);
    });
};

// leave list
export const getEmployeeLeaveList = (employeeId) => async (dispatch) => {
  await axios
    .get(`/v1/leave-time/employees/leave-list/${employeeId}`, loadingHeaders)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        // let responseData = response.Data;
        // dispatch({
        //   type: GET_EMPLOYEE_LEAVE_LIST,
        //   payload: responseData,
        // });
        let headings = [];
        let rows = [];
        if (response.Data) {
          const { LeaveType, LeaveTracking } = response.Data;
          headings.push({
            LeaveTypeId: "Tracking",
            Title: "Tracking",
            Visibility: true,
          });
          LeaveType.map((lt) => {
            headings.push({
              LeaveTypeId: lt.LeaveTypeId,
              Title: lt.Title,
              Visibility: false,
            });
          });
          headings.push({
            LeaveTypeId: "Total",
            Title: "Total",
            Visibility: true,
          });

          let lastYearBalanceRow = ["Last Year Balance"];
          let addedThisYearRow = ["Added This Year"];
          let adjustedRow = ["Adjusted"];
          let totalRow = ["Total"];
          let leaveUsedRow = ["Leave Used"];
          let leaveUnderReviewRow = ["Leave under review"];
          let encashedRow = ["Encashed"];
          let remainingLeaveRow = ["Remaining Leaves"];

          LeaveTracking.map((t, i) => {
            //1
            lastYearBalanceRow.push(t.Opening);

            //2
            let addedThisYear = "-";
            if (t.Accural != "-" && t.Adjusted != "-") {
              addedThisYear = parseFloat(t.Accural) + parseFloat(t.Adjusted);
              addedThisYear = addedThisYear.toFixed(2);
            } else if (t.Accural != "-") {
              addedThisYear = t.Accural;
            } else if (t.Adjusted != "-") {
              addedThisYear = t.Adjusted;
            }

            addedThisYearRow.push(addedThisYear);

            //3
            adjustedRow.push(t.Adjusted);

            //4
            let total = "-";
            if (t.Opening != "-" && addedThisYear != "-") {
              total = parseFloat(t.Opening) + parseFloat(addedThisYear);
              total = total.toFixed(2);
            } else if (t.Opening != "-") {
              total = t.Opening;
            } else if (addedThisYear != "-") {
              total = addedThisYear;
            }
            totalRow.push(total);

            //5
            let leaveUsed = "-";
            if (t.Taken != "-" && t.LapsedEncashed != "-") {
              leaveUsed = parseFloat(t.Taken) + parseFloat(t.LapsedEncashed);
              leaveUsed = leaveUsed.toFixed(2);
            } else if (t.Taken != "-") {
              leaveUsed = t.Taken;
            } else if (t.LapsedEncashed != "-") {
              leaveUsed = t.LapsedEncashed;
            }
            leaveUsedRow.push(leaveUsed);

            //6
            leaveUnderReviewRow.push(t.UnApprovedLeaveRequest);

            //7
            encashedRow.push(t.LapsedEncashed);

            //8
            let remainingLeave =
              parseFloat(total != "-" ? total : 0) -
              (parseFloat(leaveUsed != "-" ? leaveUsed : 0) +
                parseFloat(
                  t.UnApprovedLeaveRequest != "-" ? t.UnApprovedLeaveRequest : 0
                ));
            remainingLeaveRow.push(remainingLeave.toFixed(2));
          });

          rows.push(lastYearBalanceRow);
          rows.push(addedThisYearRow);
          rows.push(adjustedRow);
          rows.push(totalRow);
          rows.push(leaveUsedRow);
          rows.push(leaveUnderReviewRow);
          rows.push(encashedRow);
          rows.push(remainingLeaveRow);
        }

        //#region  Check wether all value are null or "-"
        rows.map((row) => {
          row.map((r, i) => {
            if (headings[i].Visibility == false && r != "-" && r != 0) {
              headings[i].Visibility = true;
            }
          });
        });
        //#endregion

        dispatch({
          type: GET_EMPLOYEE_LEAVE_LIST,
          response: response.Data,
          formatedResponse: headings.length > 0 ? { headings, rows } : null,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to Fetch Employee's Leave List",
        },
      };
      toastMessage(dispatch, response);
    });
};

// education level list
export const GetQualificationList = () => async (dispatch) => {
  await axios
    .get(`/v1/jp/educations/level`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        let formattedData = [];
        response.Data.forEach((edu) => {
          formattedData.push({
            label: edu.Text,
            value: parseInt(edu.Value),
          });
        });
        dispatch({
          type: GET_EDUCTION_QUALIFICATION_LIST,
          payload: formattedData,
        });
      } else {
        toastMessage(dispatch, response);
        dispatch({
          type: GET_EDUCTION_QUALIFICATION_LIST,
          payload: [],
        });
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to Fetch Qualification List",
        },
      };
      dispatch({
        type: GET_EDUCTION_QUALIFICATION_LIST,
        payload: [],
      });
      toastMessage(dispatch, response);
    });
};

export const getEmployeeAdditionalDocuments = (employeeId) => async (
  dispatch
) => {
  await axios
    .get(`/v1/employee-record/additional-documents/${employeeId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: GET_EMPLOYEE_ADDITIONAL_DOCUMENTS,
          payload: response.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to Fetch Employee's Additional Documents.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const addEmployeeAdditionalDocuments = (data) => async (dispatch) => {
  await axios
    .post(`/v1/employee-record/additional-documents`, data)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: ADD_EMPLOYEE_ADDITIONAL_DOCUMENTS,
          payload: response.Data,
        });
        toastMessage(dispatch, response);
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to Add Employee's Additional Documents.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const editEmployeeAdditionalDocuments = (data) => async (dispatch) => {
  await axios
    .put(`/v1/employee-record/additional-documents`, data)
    .then((resp) => {
      const response = resp.data;
      
      if (response.Status) {
        dispatch({
          type: EDIT_EMPLOYEE_ADDITIONAL_DOCUMENTS,
          payload: response.Data,
        });
        toastMessage(dispatch, response);
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to Update Employee's Additional Documents.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const deleteEmployeeAdditionalDocuments = (id) => async (dispatch) => {
  await axios
    .delete(`/v1/employee-record/additional-documents/${id}`)
    .then((resp) => {
      const response = resp.data;
      if (response) {
        dispatch({
          type: DELETE_EMPLOYEE_ADDITIONAL_DOCUMENTS,
          payload: id,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to delete Additional Details.",
        },
      };
      toastMessage(dispatch, response);
    });
};


export const saveEmployeeFamilyInfo = (data) => async (
  dispatch
) => {
  await axios
    .post(`/v1/employee-record/families`, data)
    .then((resp) => {
      const response = resp.data;
      if (response) {
        dispatch({
          type: SAVE_EMPLOYEE_FAMILY_INFO,
          payload: true,
        });
        toastMessage(dispatch, response);
      }
    })
}
export const updateEmployeeFamilyInfo = (data) => async (
  dispatch
) => {
  dispatch({
    type: SAVE_EMPLOYEE_FAMILY_INFO,
    payload: false,
  });
  await axios
    .put(`/v1/employee-record/families`, data)
    .then((resp) => {
      const response = resp.data;
      if (response) {
        dispatch({
          type: SAVE_EMPLOYEE_FAMILY_INFO,
          payload: true,
        });
        toastMessage(dispatch, response);
      }
    })
}
       
export const getEmployeeAddressDetails = (employeeId) => async (dispatch) => {
  await axios
    .get(`/v1/employee-record/addresses?employeeId=${employeeId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: GET_EMPLOYEE_ADDRESS_DETAILS,
          payload: response.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to Fetch Employee's Additional Documents.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const saveEmployeeAddressDetails = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_EMPLOYEE_ADDRESS_DETAILS,
    payload: false,
  });
  await axios
    .put(`/v1/employee-record/addresses`, data)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: SAVE_EMPLOYEE_ADDRESS_DETAILS,
          payload: true,
        });
        toastMessage(dispatch, response);
      } else {
        dispatch({
          type: SAVE_EMPLOYEE_ADDRESS_DETAILS,
          payload: false,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      dispatch({
        type: SAVE_EMPLOYEE_ADDRESS_DETAILS,
        payload: false,
      });
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to Add Employee's Address",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const getEmployeeCitizenshipDetails = (employeeId) => async (
  dispatch
) => {
  await axios
    .get(`/v1/employee-record/citizenships/${employeeId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: GET_EMPLOYEE_CITIZENSHIP_DETAILS,
          payload: response.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to Fetch Employee's Citizenship details",
        },
      };
      toastMessage(dispatch, response);
    });
};


export const saveEmployeeHealthInfo = (data) => async (
  dispatch
) => {
  dispatch({
    type: SAVE_EMPLOYEE_HEALTH_INFO,
    payload: false,
  });
  await axios
    .post(`/v1/employee-record/health`, data)
    .then((resp) => {
      const response = resp.data;
      if (response) {
        dispatch({
          type: SAVE_EMPLOYEE_HEALTH_INFO,
          payload: true,
        });
        toastMessage(dispatch, response);
      } else {
        toastMessage(dispatch, response);
      }
  })
}

export const updateEmployeeHealthInfo = (data) => async (
  dispatch
) => {
  dispatch({
    type: SAVE_EMPLOYEE_HEALTH_INFO,
    payload: false,
  });
  await axios
    .put(`/v1/employee-record/health`, data)
    .then((resp) => {
      const response = resp.data;
      if (response) {
        dispatch({
          type: SAVE_EMPLOYEE_HEALTH_INFO,
          payload: true,
        });
        toastMessage(dispatch, response);
      } else {
        toastMessage(dispatch, response);
      }
  })
} 
export const getEmployeeDrivingDetails = (employeeId) => async (dispatch) => {
  await axios
    .get(`/v1/employee-record/driving-licences/${employeeId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: GET_EMPLOYEE_DRIVING_DETAILS,
          payload: response.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to Fetch Employee's Driving License details",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const getEmployeePassportDetails = (employeeId) => async (dispatch) => {
  await axios
    .get(`/v1/employee-record/passports/${employeeId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: GET_EMPLOYEE_PASSPORT_DETAILS,
          payload: response.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to Fetch Employee's Passport details",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const saveEmployeeDocumentDetails = (
  doc,
  onModalClose,
  type,
  refreshData,
  EmployeeId
) => async (dispatch) => {
  let urlTo =
    type == "1"
      ? `/v1/employee-record/citizenships`
      : type == "2"
      ? `/v1/employee-record/driving-licences`
      : `/v1/employee-record/passports`;
  await axios
    // .delete(`${urlTo}${doc.id}`)
    .post(`${urlTo}`, doc, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        // dispatch({
        //   type: DELETE_EMPLOYEE_DOCS,
        //   payload: response.Data,
        // });
        refreshData(EmployeeId);
        onModalClose(doc);
        toastMessage(dispatch, response);
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to Fetch Employee's Passport details",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const updateEmployeeDocumentDetails = (
  doc,
  onModalClose,
  type,
  refreshData,
  EmployeeId
) => async (dispatch) => {
  let urlTo =
    type == "1"
      ? `/v1/employee-record/citizenships`
      : type == "2"
      ? `/v1/employee-record/driving-licences`
      : `/v1/employee-record/passports`;
  await axios
    // .delete(`${urlTo}${doc.id}`)
    .put(`${urlTo}`, doc, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        // dispatch({
        //   type: DELETE_EMPLOYEE_DOCS,
        //   payload: response.Data,
        // });
        refreshData(EmployeeId);
        onModalClose(doc);
        toastMessage(dispatch, response);
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to Fetch Employee's Passport details",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const deleteEmployeeDocument = (doc, onItemDelete) => async (
  dispatch
) => {
  let urlTo =
    doc.type == "1"
      ? `/v1/employee-record/citizenships/`
      : doc.type == "2"
      ? `/v1/employee-record/driving-licences/`
      : `/v1/employee-record/passports/`;
  await axios
    .delete(`${urlTo}${doc.id}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: DELETE_EMPLOYEE_DOCS,
          payload: response.Data,
        });
        onItemDelete(doc);
        toastMessage(dispatch, response);
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to delete Additional Details.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const GetEmployeeFamilies = (employeeId) => async (
  dispatch
) => {
  await axios
    .get(`/v1/employee-record/families?employeeId=${employeeId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: EMPLOYEE_FAMILIES_DETAIL,
          payload: response.Data,
        });
      } else {
        dispatch({
          type: EMPLOYEE_FAMILIES_DETAIL,
          payload: null,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to fetch family details",
        },
      };
      dispatch({
        type: EMPLOYEE_FAMILIES_DETAIL,
        payload: null,
      });
      toastMessage(dispatch, response);
    });
};

export const GetEmployeeHealthDetail = (employeeId) => async (
  dispatch
) => {
  await axios
    .get(`/v1/employee-record/health?employeeId=${employeeId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: EMPLOYEE_HEALTH_DETAIL,
          payload: response.Data,
        });
      } else {
        dispatch({
          type: EMPLOYEE_HEALTH_DETAIL,
          payload: null,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to fetch employee health detail",
        },
      };
      dispatch({
        type: EMPLOYEE_HEALTH_DETAIL,
        payload: null,
      });
      toastMessage(dispatch, response);
    });
};
