import React, { Component } from "react";
import Avatar from "react-avatar";
import { ConfigProvider } from "react-avatar"; 
import EmployeeAvatar from 'components/common/employeeAvatar';

export class MyTeam extends Component {
  render() {
    const { data } = this.props;
    if (!data) {
      return null;
    }
    return (
      <div className="section">
        <div className="myteam">
          <div className="bloch__title">My Team</div>

          <ConfigProvider colors={["#ffefee","#e6f1ff"]}>
          <div className="myteam-block">
            {data.map((d, i) => {
                return (
                  <div className="myteam-block-photo" key={i}>
                    {/* <Avatar
                      name={d.Name}
                      size={74}
                      round={false}
                      src={d.UrlPhoto}
                    /> */}
                    <EmployeeAvatar employee={d} />
                  </div>
                );
              })}
          </div>

          </ConfigProvider>
         
        </div>
      </div>
    );
  }
}

export default MyTeam;
