import React, { Component } from "react";
import RouteBaseClass from "components/routeBaseClass";
import { connect } from "react-redux";
import "../scss";
import Breadcrumb from "../../common/breadcrumb";
import TimeFunction from "../timeFunction";
import WaitingReview from "./waitingReview";
import RunningClock from "./runningClock";
import ProjectReview from "./projectReview";
import TodaysClock from "./todaysClock";
import MyActivities from "./myActivities";
import ActivitiesCalendar from "./activitiesCalendar";
import CounterReport from "../reports/counterReport";
import NewActivityForm from "../reports/activityReport/newActivityForm";
import { RoleName } from "constants/userDetails";
import {
  GetTodaysClockWork,
  GetMyTeamActivitiesAndMember,
  GetCalendarActivities,
  DismissTask,
  GetMemberByName,
  GetActivityDashboard,
} from "../action";

export class index extends RouteBaseClass {
  constructor(props) {
    super(props, ["Employee", "Administrator"]);
    this.state = {
      showActivityForm: false,
      submitedClockDetail: [],
    };
  }

  async componentDidMount() {
    await this.initialize();
  }

  initialize = async () => {
    let userRole = await RoleName();
    this.setState({ userRole });
    if (userRole == "Employee") {
      await this.props.GetTodaysClockWork();
    }
    await this.props.GetMyTeamActivitiesAndMember();

    await this.props.GetActivityDashboard();

    // await this.props.GetMemberByName();
  };

  // getCalendarActivities = async (date, activeMember) => {
  //   const d = new Date(date);
  //   const data = {
  //     year: d.getFullYear(),
  //     month: d.getMonth() + 1,
  //     memberId: activeMember.MemberId,
  //     memberTypeId: activeMember.MemberType,
  //   };
  //   return await this.props.GetCalendarActivities(data);
  // };

  dismissTask = (taskId) => {
    this.props.DismissTask(taskId, this.initialize);
  };

  submitedActivityReport = () => {
    this.initialize();
  };

  handleActivityForm = (value) => {
    this.setState({ showActivityForm: value });
  };

  handleSubmitActivityReportClick = (submitedClockDetail) => {
    this.setState({
      showActivityForm: true,
      submitedClockDetail: submitedClockDetail,
    });
  };

  render() {
    const { userRole } = this.state;

    const { showActivityForm } = this.state;
    const {
      clockData,
      myTeamActivities,
      activityDashboard,
      // myTeamMembers,
      // calendarActivities,
    } = this.props;
    const { submitedClockDetail } = this.state;
    let isApproverReviewer =
      activityDashboard &&
      activityDashboard.ActivitiesWaitingReview &&
      activityDashboard.ActivitiesWaitingReview.IsReviewerOrApprover;

    return (
      <div className="common-container activity">
        <Breadcrumb name="Activity Dashboard" />

        {userRole == "Employee" && (
          <TodaysClock
            clockData={clockData}
            handleSubmitActivityReportClick={
              this.handleSubmitActivityReportClick
            }
            dismissTask={this.dismissTask}
          />
        )}

        <WaitingReview
          {...activityDashboard}
          isApproverReviewer={isApproverReviewer}
        />

        {isApproverReviewer && <RunningClock {...activityDashboard} />}

        <MyActivities
          data={myTeamActivities}
          isApproverReviewer={isApproverReviewer}
        />

        {isApproverReviewer && <ProjectReview {...activityDashboard} />}

        {showActivityForm && (
          <NewActivityForm
            title="New Activity"
            footerButtons={["saveAndFinish", "saveAndNext"]}
            history={this.props.history}
            propsData={submitedClockDetail}
            isFormLoading={false}
            showForm={showActivityForm}
            handleFormDisplay={(value) => this.handleActivityForm(value)}
            submitedActivityReport={this.submitedActivityReport}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  clockData: state.taskTrackerReducer.clockData,
  // myTeamMembers: state.taskTrackerReducer.membersByName,
  myTeamActivities: state.taskTrackerReducer.myTeamActivities,
  // calendarActivities: state.taskTrackerReducer.calendarActivities,
  activityDashboard: state.taskTrackerReducer.activityDashboard,
});

const mapDispatchToProps = {
  GetTodaysClockWork,
  GetMyTeamActivitiesAndMember,
  GetCalendarActivities,
  DismissTask,
  GetMemberByName,
  GetActivityDashboard,
};
export default connect(mapStateToProps, mapDispatchToProps)(index);
