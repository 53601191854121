import Modal from "components/modal";
import isEmpty from "isEmpty";
import * as React from "react";
import { connect } from "react-redux";
import EmployeeDetails from "scenes/common/employeeDetails";
import "./overtime-details.scss";
import MyOvertimeRequest from "./myOvertimeRequest";
import OverTimeHistory from "./overTimeHistory";
import {
  getEmployeeOvertimeRequestsHistory,
  getEmployeeOvertimeRequest
} from '../action';
import * as employeeRequestService from "services/employeeRequests";
import { DisplayClientSideMessage, GetCompanyInformation } from "actions/commonAction";
import { EMPLOYEE_OVERTIME_REQUEST_MODULE_VALUE } from "constants/constants";
import { GetAuditLogOfRequests } from "scenes/employee/requests/actions";
import AuditHistory from "components/common/auditHistory";

export interface IAppProps {
  employeeOverTimeHistory: any,
  getEmployeeOvertimeRequestsHistory: any,
  getEmployeeOvertimeRequest?:any,
  overTimeRequestDetails?: any,
  isViewMode?: any,
  closeDetailsModal: any,
  refreshRequestData?: any,
  DisplayClientSideMessage: Function,
  requestData?: any,
  GetAuditLogOfRequests: Function,
  auditLogData?: any,
  companyInformation?: any,
  GetCompanyInformation: Function,
  isAdmin?:boolean
  singleOvertimeRequest?:any
}

interface State {
  comments: any[];
  errors: any;
  employeeDetail: any,
  empRequest: any,
  
}

class OvertimeDetails extends React.Component<IAppProps, State> {
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      errors: {},
      employeeDetail: {},
      empRequest: {},
    };
  }

  async componentDidMount() {
    
    const { overTimeRequestDetails, GetCompanyInformation, isAdmin } = this.props;
    // await GetCompanyInformation();
    console.log(this.props.singleOvertimeRequest)
    if(isAdmin){
      this.props.getEmployeeOvertimeRequestsHistory(overTimeRequestDetails.EmployeeId);
      this.props.getEmployeeOvertimeRequest(overTimeRequestDetails.EmployeeId)
    }else{
      this.props.getEmployeeOvertimeRequestsHistory();
    }
    if (!isEmpty(overTimeRequestDetails))
      this.initializeData();
      await this.props.GetAuditLogOfRequests({
        requestId: overTimeRequestDetails.RequestId,
        moduleId: EMPLOYEE_OVERTIME_REQUEST_MODULE_VALUE,
      });
  }


  async componentDidUpdate(prevProps, prevState) {
    const {
      requestData
    } = this.props;
      if (prevProps.requestData !== requestData) {
        this.initializeData();
        
      }
  }
  
  initializeData = () => {
    let { overTimeRequestDetails, requestData } = this.props;
    overTimeRequestDetails = requestData?.find(
      (x) => x.RequestId === this.props.overTimeRequestDetails.RequestId
    );
    let { employeeDetail, empRequest, comments } = this.state;
    if (!isEmpty(overTimeRequestDetails) && !isEmpty(requestData)) {

    employeeDetail = {
      Id: overTimeRequestDetails["EmployeeId"],
      FullName: overTimeRequestDetails["Requester"],
      Imagepath: overTimeRequestDetails["RequesterImage"],
      Designation: overTimeRequestDetails["Designation"],
      Branch: overTimeRequestDetails["Branch"],
      Department: overTimeRequestDetails["Department"],
    };
    empRequest = {
      ...overTimeRequestDetails,
      approvers: [
        {
          Imagepath: overTimeRequestDetails["RecommendedImage"],
          FullName: overTimeRequestDetails["Reccommender"],
          ApprovalStatus: overTimeRequestDetails["RecommendedStatus"],
        },
        {
          Imagepath: overTimeRequestDetails["ApprovalImage"],
          FullName: overTimeRequestDetails["Approval"],
          ApprovalStatus: overTimeRequestDetails["ApprovalStatus"],
        },
      ],
    };
    comments = overTimeRequestDetails["IsCommented"]
      ? overTimeRequestDetails["Comments"]?.map((x) => {
          return {
            commenter: x.CommentedBy,
            commentDate: x.CommentedDate,
            comment: x.Comments,
            commenterId: x.CommenterId,
          };
        })
      : [];
    this.setState({ employeeDetail, empRequest, comments });
    }
  }

  saveComment = async (comment) => {
    const { overTimeRequestDetails } = this.props;
    let requestData = {
      requestId: overTimeRequestDetails.RequestId,
      requestType: overTimeRequestDetails["RequestType"],
      comment,
    };
    let result = await employeeRequestService.saveReviewRequestComment(
      requestData
    );
    if (result.Status) {
      await this.props.refreshRequestData();
    }
    this.props.DisplayClientSideMessage(result);
    return result.Status;
  };

  handleApproveRequest = async () => {
    const { overTimeRequestDetails,
      refreshRequestData,
      closeDetailsModal,
      DisplayClientSideMessage
    } = this.props;
    let requestData = {
      requestId: overTimeRequestDetails.RequestId,
      requestType: overTimeRequestDetails["RequestType"],
      reason: "",
    };
    let result = await employeeRequestService.approveReviewRequests(
      requestData
    );
    if (result.Status) {
      await refreshRequestData();
      closeDetailsModal();
    }
    DisplayClientSideMessage(result);
  };

  handleRejectRequest = async () => {
    const { overTimeRequestDetails,
      refreshRequestData,
      closeDetailsModal,
      DisplayClientSideMessage } = this.props;
    let requestData = {
      requestId: overTimeRequestDetails.RequestId,
      requestType: overTimeRequestDetails["RequestType"],
      reason: "",
    };
    let result = await employeeRequestService.rejectReviewRequests(requestData);
    if (result.Status) {
      await refreshRequestData();
      closeDetailsModal();
    }
    DisplayClientSideMessage(result);
  };

  getEmployeeDetail = (data) => {
    return {
      Id: 1,
      FullName: data.Requester,
      Imagepath: data.RequesterImage,
      Designation: data.Designation,
      Branch: data.Branch,
      Department: data.Departmen,
    }
  }

  public render() {

    const { employeeOverTimeHistory, isViewMode,
      refreshRequestData, requestData, auditLogData,
      companyInformation
    } = this.props;

    const { errors, empRequest, employeeDetail,
      comments } = this.state;

    return (
      <Modal
        title=""
        open={true}
        onModalClose={() => this.props.closeDetailsModal()}
        type=""
        className="modal-overtime-details"
      >
        <div className="overtime-details">
          <div className="overtime-details__header flex">
            <EmployeeDetails empDetails={employeeDetail} size={60} />
          </div>
          <div className="overtime-details__body flex items-start justify-between">
            <div className="my-overtime-details flex-column wd-70">
              <MyOvertimeRequest
                empRequest={empRequest}
                comments={comments}
                errors={errors}
                saveComment={this.saveComment}
                isViewMode={isViewMode}
                handleApproveRequest={this.handleApproveRequest}
                handleRejectRequest={this.handleRejectRequest}
                refreshRequestData={refreshRequestData}
                requestData={requestData}
                isDateNepali={!companyInformation.IsEnglishDate}
              />
            </div>
            <div className="flex-column wd-30">
            {auditLogData.loading
                  ? null
                  : !isEmpty(auditLogData.value) && (
                      <AuditHistory auditLogData={auditLogData.value} />
                    )}
              <OverTimeHistory overTimeData={employeeOverTimeHistory} />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeOverTimeHistory: state.employeeOvertimeRequestReducer.employeeOverTimeHistory,
  auditLogData: state.employeeRequestReducer.auditLogData,
  singleOvertimeRequest:state.employeeOvertimeRequestReducer.singleOvertimeRequest,
  companyInformation: state.commonReducer.companyInformation,
});

const mapDispatchToProps = {
  getEmployeeOvertimeRequestsHistory,
  DisplayClientSideMessage,
  GetAuditLogOfRequests,
  GetCompanyInformation,
  getEmployeeOvertimeRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(OvertimeDetails);
