import React from "react";
import { FaPlus } from "react-icons/fa";
import { MdAdd } from "react-icons/md";
import { NavLink, withRouter } from "react-router-dom";
import isEmpty from "isEmpty";
import { TRAVEL_PATHS } from "constants/paths";
import Button from "components/button";
function CommonRoutes(props) {
  let pathName = !isEmpty(
    TRAVEL_PATHS.find((x) => x.PathName === props.location.pathname)
  )
    ? TRAVEL_PATHS.find((x) => x.PathName === props.location.pathname).Title
    : "Travel Requests";

  return (
    <div className="travel-route">
      <span className="path-name">{pathName}</span>
      <div>
        {/* <NavLink
          to={{
            pathname: CreateTravelRequestPath,
            state: { selectedTabId: -1 }
          }}
          activeClassName="active-btn"
        >
          <Button
            bg="primary"
            icon={<MdAdd />}
            buttonType="icon-button"
            title="New Travel Request"
          />
        </NavLink> */}
      </div>
    </div>
  );
}

export default withRouter(CommonRoutes);
