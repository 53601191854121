import {
  GET_LEGAL_CLIENT_TYPES,
  GET_PROJECT_ACTIVITIES,
  GET_UNSUBMITTED_ACTIVITIES,
  GET_UNSUBMITTED_ACTIVITIES_EXPORT_EXCEL,
  GET_PROJECT_ACTIVITY_DETAIL,
  GET_LEGAL_CLIENT_LIST,
  GET_LEGAL_CLIENT_BY_ID,
  GET_PROJECTS_FOR_TIME_FUNCTION,
  GET_PROJECTS_FOR_TIME_FUNCTION_FORMATED,
  GET_RUNNING_TASK,
  GET_CLIENT_PROJECT_DDL_LIST,
  GET_BILLING_DESIGNATIONS,
  GET_ACTIVE_CLIENTS_LIST,
  GET_TODAYS_CLOCK_WORK,
  GET_MY_TEAM_ACTIVITIES,
  GET_CALENDAR_ACTIVITIES,
  GET_NATURE_OF_WORK_DDL,
  SET_LEGAL_CLIENT_LOADING,
  CONTRACT_SAVED,
  GET_ACTIVITY_AND_BILLING_ROLES,
  GET_HOURS_WORKED_BY_CLIENT,
  GET_HOURS_WORKED_BY_PEOPLE,
  GET_RAW_REPORT,
  GET_HOURS_COMMITTED,
  GET_ACTIVITY_DASHBOARD,
  GET_PEOPLE_IN_PROJECT_VIEW_BY_PROJECT,
  GET_PEOPLE_IN_PROJECT_VIEW_BY_PEOPLE,
  GET_MEMBER_BY_NAME,
  GET_COUNTER_REPORT,
  GET_PROJECT_MATTER_ID,
  SAVE_CLIENT_PROJECT,
  GET_PROJECT_DETAILS,
  GET_CONTRACT_DETAILS,
  DELETE_PROJECT_CONTRACT,
  GET_SECRETARIAL_CHARGES_TYPES,
  GET_DEPARTMENTS_TO_HANDLE_PROJECT_CASE,
} from "actions/types";
import axios from "axios";
import isEmpty from "isEmpty";
import { toastMessage } from "actions/validateAction";
import { catchError } from "helpers";
import { Enum_TaskState } from "./enums";
import { loadingHeaders } from "helpers/getHeaders";
import { Date_Format_Dot } from "constants/dateFormat";
import { minutesToHrMin } from "scenes/taskTracker/common/minutesToHrMin";
import { formatedDottDateWDayForExcel } from "helpers/dateFormat";

const urlPrefix = "/v1/tt/";

//#region TTClient

export const GetLegalClientList = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  await axios
    .get(
      `${urlPrefix}clients?searchText=${data.searchText}&clientTypeId=${data.clientTypeId}&isArchived=${data.isArchived}&pageIndex=${data.pageIndex}&pageSize=${data.rowsPerPage}`,
      loadingHeaders
    )
    .then((resp) => {
      const response = resp.data;
      dispatch(setLoading(false));
      if (response.Status) {
        dispatch({
          type: GET_LEGAL_CLIENT_LIST,
          payload: response.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const CreateClient = (data, props) => async (dispatch) => {
  await axios
    .post(`${urlPrefix}clients`, data)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        props.parent.handleCallback();
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const UpdateClient = (data, props) => async (dispatch) => {
  await axios
    .put(`${urlPrefix}clients`, data)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        props.parent.handleCallback();
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const GetClientByClientId = (clientId) => async (dispatch) => {
  await axios
    .get(`${urlPrefix}clients/${clientId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: GET_LEGAL_CLIENT_BY_ID,
          payload: response.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const DeleteClient = (clientId, props) => async (dispatch) => {
  await axios
    .delete(`${urlPrefix}clients?id=${clientId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        props.getLegalClientList();
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const ChangeArchiveStatus = (data, props) => async (dispatch) => {
  await axios
    .post(`${urlPrefix}clients/archive-restore`, data)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        props.getLegalClientList();
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

//#endregion

// #region TTACtivity
export const GetProjectActivities = (data) => async (dispatch) => {
  await axios
    .post(`${urlPrefix}activities/project`, data)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: GET_PROJECT_ACTIVITIES,
          payload: response.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const GetUnsubmittedActivities = (param, isExport) => async (
  dispatch
) => {
  await axios
    .post(`${urlPrefix}activities/not-suubmitted`, param)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        if (isExport) {
          let exportData = [];
          response.Data &&
            response.Data.map((data) => {
              const EmployeeNameAndId = `${data.EmployeeName} (${data.EmployeeId})`;
              exportData.push({
                ...data,
                EmployeeNameAndId,
                ActivityNotSubmittedDaysAndDetail:
                  data.ActivityNotSubmittedDays,
              });
              data.Detail &&
                data.Detail.map((detail) => {
                  exportData.push({
                    ActivityNotSubmittedDaysAndDetail: formatedDottDateWDayForExcel(
                      detail.DateEng
                    ),
                  });
                });
            });
          dispatch({
            type: GET_UNSUBMITTED_ACTIVITIES_EXPORT_EXCEL,
            payload: exportData,
          });
        } else {
          dispatch({
            type: GET_UNSUBMITTED_ACTIVITIES,
            payload: response.Data,
          });
        }
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const GetActivityDetailByActivityIDs = (activityIds) => async (
  dispatch
) => {
  activityIds = activityIds ? activityIds : "";
  await axios
    .get(`${urlPrefix}activities/detail?activityIds=${activityIds}`)
    .then(async (resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: GET_PROJECT_ACTIVITY_DETAIL,
          payload: response.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const ApproveProjectActivities = (activityIds, props) => async (
  dispatch
) => {
  await axios
    .put(`${urlPrefix}activities/approval-action?activityIds=${activityIds}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        props.getProjectActivity();
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const CreateNewActivity = (
  data,
  props,
  clearInputFields,
  isSaveAndFinish = true
) => async (dispatch) => {
  await axios
    .post(`${urlPrefix}activities`, data)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        props.refreshActivityList && props.refreshActivityList();
        if (isSaveAndFinish) {
          props.handleFormDisplay && props.handleFormDisplay(false);

          props.submitedActivityReport && props.submitedActivityReport();
        } else {
          clearInputFields && clearInputFields();
        }
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const UpdateActivity = (
  data,
  props,
  clearInputFields,
  isSaveAndFinish = true
) => async (dispatch) => {
  await axios
    .put(`${urlPrefix}activities`, data)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        props.refreshActivityList && props.refreshActivityList();
        if (isSaveAndFinish) {
          props.handleFormDisplay && props.handleFormDisplay(false);

          props.submitedActivityReport && props.submitedActivityReport();
        } else {
          clearInputFields && clearInputFields();
        }
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const GetClientProjectDDl = (data) => async (dispatch) => {
  await axios
    .get(
      `${urlPrefix}projects/dropdown-list?memberId=${data.memberId}&memberTypeId=${data.memberTypeId}`
    )
    .then(async (resp) => {
      const response = resp.data;
      if (response.Status) {
        let formatedData = [];
        response.Data.map((d) => {
          formatedData.push({ label: d.Text, value: d.Value });
        });
        dispatch({
          type: GET_CLIENT_PROJECT_DDL_LIST,
          payload: formatedData,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const GetTodaysClockWork = () => async (dispatch) => {
  await axios
    .get(`${urlPrefix}activities/unsubmitted-work`)
    .then(async (resp) => {
      const response = resp.data;
      if (response.Status) {
        //#region Group by date
        // this gives an object with dates as keys
        const groups = response.Data.reduce((groups, item) => {
          const date = item.TaskStartDTime.split("T")[0];
          if (!groups[date]) {
            groups[date] = [];
          }
          groups[date].push(item);
          return groups;
        }, {});

        // Edit: to add it in the array format instead
        let groupedByDate = Object.keys(groups).map((date) => {
          return {
            date,
            items: groups[date],
          };
        });

        groupedByDate = groupedByDate.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        //#endregion Group by date

        dispatch({
          type: GET_TODAYS_CLOCK_WORK,
          payload: groupedByDate,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const GetMyTeamActivitiesAndMember = () => async (dispatch) => {
  await axios
    .get(`${urlPrefix}activities/team`)
    .then(async (resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: GET_MY_TEAM_ACTIVITIES,
          payload: response.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const GetCalendarActivities = (data) => async (dispatch) => {
  await axios
    .post(`${urlPrefix}activities/calendar`, data)
    .then(async (resp) => {
      const response = resp.data;
      if (response.Status) {
        let formatedResponse = [];
        // formatedResponse.push({title:'10 p',start:new Date('2020/3/31'),end:new Date('2020/3/31'),label:'label'})
        Promise.all(
          response.Data.map((d) => {
            formatedResponse.push({
              title: d.Title,
              start: d.Start,
              end: d.End,
              label: d.Label,
            });
          })
        );
        dispatch({
          type: GET_CALENDAR_ACTIVITIES,
          payload: formatedResponse,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const GetHoursWorkedByClient = (data) => async (dispatch) => {
  await axios
    .post(`${urlPrefix}activities/client`, data)
    .then(async (resp) => {
      const response = resp.data;
      if (response.Status) {
        let indexToExpand = null;
        response.Data &&
          response.Data.map((d, i) => {
            if (isEmpty(indexToExpand)) {
              if (d.ActivityDetail && d.ActivityDetail.length > 0) {
                indexToExpand = i;
              }
            }
          });

        dispatch({
          type: GET_HOURS_WORKED_BY_CLIENT,
          payload: { data: response.Data, indexToExpand: indexToExpand },
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const GetHoursWorkedByPeople = (data) => async (dispatch) => {
  await axios
    .post(`${urlPrefix}activities/member`, data)
    .then(async (resp) => {
      const response = resp.data;
      if (response.Status) {
        let indexToExpand = null;
        response.Data &&
          response.Data.map((d, i) => {
            if (isEmpty(indexToExpand)) {
              if (d.ActivityDetail && d.ActivityDetail.length > 0) {
                indexToExpand = i;
              }
            }
          });
        dispatch({
          type: GET_HOURS_WORKED_BY_PEOPLE,
          payload: { data: response.Data, indexToExpand: indexToExpand },
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const GetRawReport = (params, isExport = false) => async (dispatch) => {
  await axios
    .post(`${urlPrefix}activities/detail-member`, params)
    .then(async (resp) => {
      const response = resp.data;
      if (response.Status) {
        let formatedData = [];
        let totalWorkedHours = 0;
        let totalBillableHours = 0;
        let totalNonBillableHours = 0;
        let totalExpenses = 0;
        let estimatedFee = 0;

        response.Data &&
          response.Data.map((d) => {
            d.ActivityDateEng =
              d.ActivityDateEng && Date_Format_Dot(d.ActivityDateEng);
            d.WorkedHoursText = minutesToHrMin(d.WorkedHours);
            d.BillableHoursText = minutesToHrMin(d.BillableHours);
            d.NonBillableHoursText = minutesToHrMin(d.NonBillableHours);
            d["EstimatedFee"] =
              !isEmpty(d.BillableHours) && !isEmpty(d.HourlyRate)
                ? (d.BillableHours / 60) * d.HourlyRate
                : 0;

            totalWorkedHours += d.WorkedHours || 0;
            totalBillableHours += d.BillableHours || 0;
            totalNonBillableHours += d.NonBillableHours || 0;
            totalExpenses += d.PocketExpenses || 0;
            estimatedFee += d.EstimatedFee || 0;

            formatedData.push(d);
          });

        formatedData.length > 0 &&
          formatedData.push({
            ActivityTypes: "TOTAL: ",
            WorkedHoursText: minutesToHrMin(totalWorkedHours),
            BillableHoursText: minutesToHrMin(totalBillableHours),
            NonBillableHoursText: minutesToHrMin(totalNonBillableHours),
            PocketExpenses: totalExpenses,
            EstimatedFee: estimatedFee,
          });

        dispatch({
          type: GET_RAW_REPORT,
          payload: formatedData,
          isExport: isExport,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const GetHoursCommitted = (data) => async (dispatch) => {
  await axios
    .post(`${urlPrefix}activities/client-commitment`, data)
    .then(async (resp) => {
      const response = resp.data;
      if (response.Status) {
        let indexToExpand = null;
        response.Data &&
          response.Data.map((d, i) => {
            if (isEmpty(indexToExpand)) {
              if (d.CommitmentDetail && d.CommitmentDetail.length > 0) {
                indexToExpand = i;
              }
            }
          });
        dispatch({
          type: GET_HOURS_COMMITTED,
          payload: { data: response.Data, indexToExpand: indexToExpand },
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const GetActivityDashboard = () => async (dispatch) => {
  await axios
    .get(`${urlPrefix}activities/dashboard`)
    .then(async (resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: GET_ACTIVITY_DASHBOARD,
          payload: response.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};
// #endregion

// #region TTProject
export const SaveProjectContract = (
  data,
  renewDocuments,
  onModalClose,
  handleFormDisplay
) => async (dispatch) => {
  let fd = new FormData();
  // fd.append("TTProjectContractDto", JSON.stringify(data));

  // renewDocuments &&
  //   renewDocuments.map((doc) => {
  //     doc.file && fd.append(doc.file.name, doc.file);
  //   });

  renewDocuments.map((doc, i) => {
    fd.append(`Files`, doc.file);
  });

  data.ContractId && fd.append("ContractId", data.ContractId);
  fd.append("EventTypeId", data.EventTypeId);
  fd.append("EventStartDateEng", data.EventStartDateEng);
  fd.append("EventEndDateEng", data.EventEndDateEng);
  fd.append("EventNote", data.EventNote ? data.EventNote : "");

  await axios
    .put(`${urlPrefix}projects/contract-new`, fd, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        onModalClose && onModalClose();
        handleFormDisplay && handleFormDisplay(false);
        dispatch({
          type: CONTRACT_SAVED,
          payload: true,
        });
      }
      handleFormDisplay && handleFormDisplay(false);
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      dispatch({
        type: CONTRACT_SAVED,
        payload: false,
      });
      catchError(dispatch, err);
    });
};

export const GetPeopleInProject_ViewByProject = (data) => async (dispatch) => {
  await axios
    .post(`${urlPrefix}projects/people`, data)
    .then(async (resp) => {
      const response = resp.data;
      if (response.Status) {
        let indexToExpand = null;
        response.Data &&
          response.Data.map((d, i) => {
            if (isEmpty(indexToExpand)) {
              if (d.PeopleInProject && d.PeopleInProject.length > 0) {
                indexToExpand = i;
              }
            }
          });
        dispatch({
          type: GET_PEOPLE_IN_PROJECT_VIEW_BY_PROJECT,
          payload: { data: response.Data, indexToExpand: indexToExpand },
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const GetPeopleInProject_ViewByPeople = (data) => async (dispatch) => {
  await axios
    .post(`${urlPrefix}projects/member-association`, data)
    .then(async (resp) => {
      const response = resp.data;
      if (response.Status) {
        let indexToExpand = null;
        response.Data &&
          response.Data.map((d, i) => {
            if (isEmpty(indexToExpand)) {
              if (
                d.ProjectAssociationDetail &&
                d.ProjectAssociationDetail.length > 0
              ) {
                indexToExpand = i;
              }
            }
          });
        dispatch({
          type: GET_PEOPLE_IN_PROJECT_VIEW_BY_PEOPLE,
          payload: { data: response.Data, indexToExpand: indexToExpand },
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const GetMemberByName = (searchTerm) => async (dispatch) => {
  let finalUrl = `${urlPrefix}projects/member-search`;
  if (!isEmpty(searchTerm)) {
    finalUrl = `${finalUrl}?searchTerm=${searchTerm}`;
  }
  await axios
    .get(finalUrl)
    .then(async (resp) => {
      const response = resp.data;
      if (response.Status) {
        let formatedData = [];
        response.Data &&
          response.Data.map((d) => {
            formatedData.push({
              label: d.MemberName,
              value: d.MemberId,
              memberTypeId: d.MemberType,
              billingDesignationId: d.BillingDesignationId,
              billingDesignationName: d.BillingDesignationName,
              Imgpath: d.ImgPath,
            });
          });
        dispatch({
          type: GET_MEMBER_BY_NAME,
          membersByName: response.Data,
          membersByNameDll: formatedData,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const GetProjectMatterId = (data) => async (dispatch) => {
  await axios
    .get(
      `${urlPrefix}projects/matterid?clientId=${data.clientId}&departmentId=${data.departmentId}&contractDate=${data.contractDate}`,
      { loadingHeaders }
    )
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: GET_PROJECT_MATTER_ID,
          payload: response.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

// add new project
export const AddNewProject = (formData, handleFormDisplay) => async (
  dispatch
) => {
  await axios
    .post(`${urlPrefix}projects`, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then(async (resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: SAVE_CLIENT_PROJECT,
          payload: response.Data,
        });
        dispatch({
          type: GET_PROJECT_MATTER_ID,
          payload: null,
        });
        handleFormDisplay && handleFormDisplay(false);
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const UpdateProject = (formData, handleFormDisplay) => async (
  dispatch
) => {
  
  // const fd = new FormData();
  // fd.append("ClientRefId", data.ClientRefId);
  // fd.append("ContractStartDateEng", data.ContractStartDateEng);
  // fd.append("CounterPartyList", data.CounterPartyList);

  // fd.append("CourtHearingDateEng", data.CourtHearingDateEng);
  // fd.append("ExternalDeadlineEng", data.ExternalDeadlineEng);
  // fd.append("HandlingDepartmentId", data.HandlingDepartmentId);
  // fd.append("HourlyRateList", data.HourlyRateList);
  // fd.append("InternalDeadlineEng", data.InternalDeadlineEng);
  // fd.append("MatterId", data.MatterId);

  // fd.append("ProjectId", data.ProjectId);
  // fd.append("ProjectName", data.ProjectName);
  // fd.append("Approval", data.Approval);

  // fd.append("Contract", data.Contract);
  // fd.append("Fee", data.Fee);

  await axios
    .put(`${urlPrefix}projects`, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: SAVE_CLIENT_PROJECT,
          payload: response.Data,
        });
        dispatch({
          type: GET_PROJECT_MATTER_ID,
          payload: null,
        });
        // handleUdateResponse && handleUdateResponse(response.Data.ProjectId);
        handleFormDisplay && handleFormDisplay(false);
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const DeleteProject = (projectId, props) => async (dispatch) => {
  await axios
    .delete(`${urlPrefix}projects?id=${projectId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        props.getLegalClientList();
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

// get Project Details
export const GetProjectDetails = (projectId, props) => async (dispatch) => {
  await axios
    .get(`${urlPrefix}projects/${projectId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: GET_PROJECT_DETAILS,
          payload: response.Data,
        });
      } else {
        dispatch({
          type: GET_PROJECT_DETAILS,
          payload: null,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_PROJECT_DETAILS,
        payload: null,
      });
      catchError(dispatch, err);
    });
};
// delete project event
export const DeleteProjectContract = (contractId, props) => async (
  dispatch
) => {
  await axios
    .delete(`${urlPrefix}projects/contract/${contractId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: DELETE_PROJECT_CONTRACT,
          payload: true,
        });
      } else {
        dispatch({
          type: DELETE_PROJECT_CONTRACT,
          payload: false,
        });
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      dispatch({
        type: DELETE_PROJECT_CONTRACT,
        payload: false,
      });
      catchError(dispatch, err);
    });
};

export const GetProjectsForTimeFunction = (searchTerm) => async (dispatch) => {
  let finalUrl = `${urlPrefix}projects/search`;
  if (!isEmpty(searchTerm)) {
    finalUrl = `${finalUrl}?searchTerm=${searchTerm}`;
  }

  await axios
    .get(finalUrl)
    .then(async (resp) => {
      const response = resp.data;
      if (response.Status) {
        let formatedData = [];
        Promise.all(
          response.Data.map((d) => {
            formatedData.push({ label: d.ProjectName, value: d.ProjectId });
          })
        );

        dispatch({
          type: GET_PROJECTS_FOR_TIME_FUNCTION_FORMATED,
          payload: formatedData,
        });
        dispatch({
          type: GET_PROJECTS_FOR_TIME_FUNCTION,
          payload: response.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

// #endregion

// #region TTTask

export const GetRunningTask = () => async (dispatch) => {
  await axios
    .get(`${urlPrefix}tasks/running`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: GET_RUNNING_TASK,
          payload: response.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const SaveTaskState = (data, saveResponseTaskId) => async (dispatch) => {
  await axios
    .post(`${urlPrefix}tasks/state`, data)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        saveResponseTaskId && saveResponseTaskId(response.Data.TaskId);

        if (data.StateId == Enum_TaskState.Completed) {
          dispatch(GetTodaysClockWork());
          dispatch(GetActivityDashboard());
        }
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const DismissTask = (taskId, reInitialize) => async (dispatch) => {
  await axios
    .put(`${urlPrefix}tasks/dismiss?taskId=${taskId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        reInitialize && reInitialize();
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const GetCounterReport = () => async (dispatch) => {
  await axios
    .get(`${urlPrefix}dashboard-counters`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: GET_COUNTER_REPORT,
          payload: response.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};
// #endregion

// #region TTLegalClient

// billing-designation
export const GetActiveClientsList = () => async (dispatch) => {
  await axios
    .get(`${urlPrefix}clients/active`)
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: GET_ACTIVE_CLIENTS_LIST,
          payload: response.data,
        });
      } else {
        toastMessage(dispatch, "Unable to get active clients");
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

// #endregion

//#region TTCommon
export const GetLegalClientTypes = () => async (dispatch) => {
  await axios
    .get(`${urlPrefix}client-types`)
    .then(async (response) => {
      if (response.status === 200) {
        let formatedData = [];
        Promise.all(
          response.data.map((d) => {
            formatedData.push({ label: d.Text, value: d.Value });
          })
        );
        dispatch({
          type: GET_LEGAL_CLIENT_TYPES,
          payload: formatedData,
        });
      } else {
        toastMessage(dispatch, "Unable to get client types");
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const GetNatureOfWorkDDL = () => async (dispatch) => {
  await axios
    .get(`${urlPrefix}actity-types`)
    .then(async (response) => {
      if (response.status == 200) {
        let formatedData = [];
        Promise.all(
          response.data.map((d) => {
            formatedData.push({ label: d.Text, value: d.Value });
          })
        );
        dispatch({
          type: GET_NATURE_OF_WORK_DDL,
          payload: formatedData,
        });
      } else {
        toastMessage(dispatch, "Unable to get nature of works.");
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const GetBillingDesignations = () => async (dispatch) => {
  await axios
    .get(`${urlPrefix}billing-designations`)
    .then(async (response) => {
      if (response.status === 200) {
        let formatedData = [];
        Promise.all(
          response.data.map((d) => {
            formatedData.push({ label: d.Text, value: d.Value });
          })
        );
        dispatch({
          type: GET_BILLING_DESIGNATIONS,
          payload: response.data,
          formatedBillingDesignations: formatedData,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

// Get Secretarial Charges types
export const GetSecretarialChargesTypes = () => async (dispatch) => {
  await axios
    .get(`${urlPrefix}secretarial-charge-calc-Types`)
    .then((response) => {
      if (response.status === 200) {
        let formatedData = [];
        Promise.all(
          response.data.map((d) => {
            formatedData.push({ label: d.Text, value: d.Value });
          })
        );
        dispatch({
          type: GET_SECRETARIAL_CHARGES_TYPES,
          payload: formatedData,
        });
      } else {
        toastMessage(dispatch, "Unable to get secretarial charges types");
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const GetDepartmentToHandleCase = () => async (dispatch) => {
  await axios
    .get(`/v1/tt/departments`)
    .then((res) => {
      if (res.status === 200) {
        const formatedData = [];
        res.data.map((d) => {
          formatedData.push({ label: d.Text, value: d.Value });
        });
        dispatch({
          type: GET_DEPARTMENTS_TO_HANDLE_PROJECT_CASE,
          payload: formatedData,
        });
      } else {
        toastMessage(dispatch, "Failed to get departments.");
      }
    })
    .catch((err) => catchError(dispatch, err));
};

//#endregion

//#region TTBillingDesignation
export const GetActivityAndBillingRoles = (data) => async (dispatch) => {
  await axios
    .post(`${urlPrefix}member-billing-designation-relations/search`, data)
    .then(async (resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: GET_ACTIVITY_AND_BILLING_ROLES,
          payload: response.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const ArchiveOrDeleteActivityOrBillings = (
  memberId,
  refreshList
) => async (dispatch) => {
  await axios
    .delete(`${urlPrefix}external-team-members/${memberId}`)
    .then(async (resp) => {
      const response = resp.data;
      if (response.Status) {
        refreshList && refreshList();
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const CreateActivityOrBillings = (data, handleSuccessResponse) => async (
  dispatch
) => {
  await axios
    .post(`${urlPrefix}external-team-members`, data)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        handleSuccessResponse && handleSuccessResponse();
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const SaveMemberBillingDesignation = (data, refreshList) => async (
  dispatch
) => {
  await axios
    .post(`${urlPrefix}member-billing-designation-relations`, data)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        refreshList && refreshList();
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const UpdateMemberBillingDesignation = (data, refreshList) => async (
  dispatch
) => {
  await axios
    .put(`${urlPrefix}member-billing-designation-relations`, data)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        refreshList && refreshList();
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

//#endregion

//#region TTProjectContract

export const GetContractDetails = (contractId) => async (dispatch) => {
  await axios
    .get(`${urlPrefix}projects/contract-renew/${contractId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: GET_CONTRACT_DETAILS,
          payload: response.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const CreateRenewContract = (formData, handleFormDisplay) => async (
  dispatch
) => {
  await axios
    .post(`${urlPrefix}projects/contract-renew`, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then(async (resp) => {
      const response = resp.data;
      if (response.Status) {
        handleFormDisplay && handleFormDisplay(false);
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const UpdateRenewContract = (data, handleFormDisplay) => async (
  dispatch
) => {
  await axios
    .put(`${urlPrefix}projects/contract-renew`, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        handleFormDisplay && handleFormDisplay(false);
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

//#endregion

const setLoading = (pay) => (dispatch) => {
  return dispatch({
    type: SET_LEGAL_CLIENT_LOADING,
    payload: pay,
  });
};
