import {
  GET_COUNTRY_LIST,
  GET_CURRENCY_LIST,
  GET_DISTRICT_LIST,
  GET_EMPLOYEE_LIST,
  GET_EMPLOYEE_LIST_WITH_PHOTO,
  GET_EMPLOYEE_BY_BRANCH_DEPARTMENT,
  GET_EDUCATION_LEVELS,
  GET_PROVINCE_LIST,
  GET_COMPANY_HOLIDAYS_WHOLE,
  GET_DISTRICT_BY_PROVINCE,
  GET_LOCALBODY_BY_DISTRICT,
  GET_EXPERIENCE_DURATION,
  GET_JOB_STATUS,
  GET_SALARY_PAY_DURATION,
  GET_COMPANY_INFORMATION,
  GET_COMPANY_LOGO,
  ADD_LOADING,
  GET_NATIONALITY,
  GET_DEPARTMENTS,
  GET_SUB_DEPARTMENTS,
  GET_DESIGNATION,
  SORT_DRAG,
  GET_LEVELS,
  GET_BRANCHES,
  UPLOAD_FILES,
  GET_LEVELS_WITH_GROUPS,
  GET_JOB_PROFILE_DROP_LIST,
  GET_JOB_DESCRIPTION_DROP_LIST,
  SET_SELECTED_RECORD,
  GET_COMPANY_HOLIDAYS,
  GET_OVERTIME_TYPES,
  SET_LEVELS_WITH_GROUPS_LOADING,
  SET_JOB_PROFILE_DROP_LIST_LOADING,
  SET_JOB_DESCRIPTION_DROP_LIST_LOADING,
  GET_BLOOD_GROUP_LIST,
  GET_PAYROLL_INCOME_HEADS,
  GET_HIRING_METHODS,
  GET_APP_VERSION,
  GET_EMP_MENUS
} from "./types";
import axios from "axios";
import { EmployeeID } from "constants/userDetails";
import { toastMessage } from "./validateAction";
//   import { getBaseAxios } from "../helpers/Constants";
import { Arrays } from "helpers/getTextLabel";
import dispatchError from "helpers/dispatchError";
import { loadingHeaders } from "helpers/getHeaders";
import async from "react-select/async";


export const GetAppVersion =
  (): any =>
    async (dispatch: any): Promise<any> => {
      await axios
        .get(`/v1/common/app-version`, loadingHeaders)
        .then((response) => {
          dispatch({
            type: GET_APP_VERSION,
            payload: response.data,
          });
        })
        .catch((err) => {
          dispatchError(err, dispatch, GET_BRANCHES, "Failed to get country list.");
        });
    }

export const GetEmpMenus = (): any => async (dispatch: any): Promise<any> => {
  await axios
    .get(`/v1/common/emp-menus`, loadingHeaders)
    .then((response) => {
      dispatch({
        type: GET_EMP_MENUS,
        payload: response.data && response.data.Data || [],
      });
    })
    .catch((err) => {
      dispatchError(err, dispatch, GET_BRANCHES, "Failed to get country list.");
    });
}

export const GetCountryList = () => async (dispatch: Function) => {
  await axios
    .get(`/v1/common/countries`, loadingHeaders)
    .then((response) => {
      dispatch({
        type: GET_COUNTRY_LIST,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatchError(err, dispatch, GET_BRANCHES, "Failed to get country list.");
    });
};

export const SortDrag = (
  droppableIdStart,
  droppableIdEnd,
  droppableIndexStart,
  droppableIndexEnd,
  draggableId,
  type
) => {
  return (dispatch, getState) => {
    dispatch({
      type: SORT_DRAG,
      payload: {
        droppableIdStart,
        droppableIdEnd,
        droppableIndexEnd,
        droppableIndexStart,
        draggableId,
        type,
      },
    });
  };
};

export const GetCurrencyList = () => async (dispatch: Function) => {
  await axios
    .get(`/v1/common/currencies`, loadingHeaders)
    .then((response) => {
      dispatch({
        type: GET_CURRENCY_LIST,
        payload: response.data,
      });
    })
    .catch((err) =>
      dispatchError(
        err,
        dispatch,
        GET_CURRENCY_LIST,
        "Failed to get currency list."
      )
    );
};

export const GetDistrictList = () => async (dispatch: Function) => {
  await axios
    .get(`/v1/common/districts`, loadingHeaders)
    .then((response) => {
      dispatch({
        type: GET_DISTRICT_LIST,
        payload: Arrays(response.data),
      });
    })
    .catch((err) =>
      dispatchError(
        err,
        dispatch,
        GET_DISTRICT_LIST,
        "Failed to get district list."
      )
    );
};

export const GetEmployeeList = () => async (dispatch: Function) => {
  await axios
    .get(`/v1/common/employees`, loadingHeaders)
    .then((response) => {
      if (response.status == 200) {
        let formatedEmployeeListWithPhoto = [];
        let formatedMyDetail = null;
        response.data.length > 0 &&
          response.data.map((m: any) => {
            if (m.EmployeeId == EmployeeID()) {
              formatedMyDetail = {
                label: m.FullName,
                value: m.EmployeeId,
                Imagepath: m.Imagepath,
              };
            } else {
              formatedEmployeeListWithPhoto.push({
                label: m.FullName,
                value: m.EmployeeId,
                Imagepath: m.Imagepath,
              });
            }
          });

        dispatch({
          type: GET_EMPLOYEE_LIST_WITH_PHOTO,
          formatedEmployeeListWithPhoto,
          formatedMyDetail,
        });
        dispatch({
          type: GET_EMPLOYEE_LIST,
          payload: response.data,
        });
      }
    })
    .catch((err) =>
      dispatchError(
        err,
        dispatch,
        GET_EMPLOYEE_LIST,
        "Failed to get employee list."
      )
    );
};

// export const GetAllEmployeeListWithPhoto = () => async (dispatch: Function) => {
//   await axios
//     .get(`/v1/common/employees-with-photo`)
//     .then((response) => {
//       if (response.status == 200) {
//         let formatedEmployeeListWithPhoto = [];
//         let formatedMyDetail = null;

//         response.data.length > 0 &&
//           response.data.map((m: any) => {
//             if (m.EmployeeId == EmployeeID()) {
//               formatedMyDetail = {
//                 label: m.fullName,
//                 value: m.employeeId,
//                 Imagepath: m.imagepath,
//               };
//             } else {
//               formatedEmployeeListWithPhoto.push({
//                 label: m.fullName,
//                 value: m.employeeId,
//                 Imagepath: m.imagepath,
//               });
//             }
//           });

//         dispatch({
//           type: GET_EMPLOYEE_LIST_WITH_PHOTO,
//           formatedEmployeeListWithPhoto,
//           formatedMyDetail,
//         });
//       }
//     })
//     .catch((err) =>
//       dispatchError(
//         err,
//         dispatch,
//         GET_EMPLOYEE_LIST_WITH_PHOTO,
//         "Failed to get employee list."
//       )
//     );
// };

// export const GetAllEmployeeByBranchDepartment = () => async (dispatch:Function) => {
//   await axios
//     .get(`/v1/common/shared/employees-by-branch-department`)
//     .then((response) => {
//       if (response.status == 200) {
//         dispatch({
//           type: GET_EMPLOYEE_BY_BRANCH_DEPARTMENT,
//            formatedEmployeeListWithPhoto,
//            formatedMyDetail,
//         });
//       }
//     })
//     .catch((err) => {
//       toastMessage(dispatch, err.message);
//     });
// };

export const GetEducationLevel = () => async (dispatch: Function) => {
  await axios
    //.get(`/v1/common/shared/education-level`)
    .get(`/v1/jp/educations`)
    .then((response) => {
      dispatch({
        type: GET_EDUCATION_LEVELS,
        // payload: response.data.map((x) => {
        //   return { value: x.Key, label: x.Value };
        // }),
        payload: response.data?.Data?.map((x) => {
          return { value: x.EducationId, label: x.Name };
        }),
      });
    })
    .catch((err) =>
      dispatchError(
        err,
        dispatch,
        GET_EDUCATION_LEVELS,
        "Failed to get education level list."
      )
    );
};

export const GetProvienceList = () => async (dispatch: Function) => {
  await axios
    .get(`/v1/common/provinces`)
    .then((response) => {
      if (response.status == 200) {
        dispatch({
          type: GET_PROVINCE_LIST,
          payload: response.data.map((x) => {
            return { value: x.ProvinceId, label: x.Name };
          }),
        });
      }
    })
    .catch((err) =>
      dispatchError(
        err,
        dispatch,
        GET_PROVINCE_LIST,
        "Failed to get province list."
      )
    );
};

export const GetDistrictByProvince =
  (provinceId: any) => async (dispatch: Function) => {
    await axios
      .get(`/v1/common/${provinceId}/districts`)
      .then((response) => {
        if (response.status == 200) {
          dispatch({
            type: GET_DISTRICT_BY_PROVINCE,
            payload: response.data.map((x) => {
              return { value: x.DistrictId, label: x.District };
            }),
          });
        }
      })
      .catch((err) =>
        dispatchError(
          err,
          dispatch,
          GET_DISTRICT_BY_PROVINCE,
          "Failed to get district list."
        )
      );
  };

export const GetLocalBodyByDistrict =
  (districtId: any) => async (dispatch: Function) => {
    await axios
      .get(`/v1/common/${districtId}/local-bodies`)
      .then((response) => {
        if (response.status == 200) {
          dispatch({
            type: GET_LOCALBODY_BY_DISTRICT,
            payload: response.data.map((x) => {
              return { value: x.Id, label: x.Name };
            }),
          });
        }
      })
      .catch((err) =>
        dispatchError(
          err,
          dispatch,
          GET_LOCALBODY_BY_DISTRICT,
          "Failed to get local body list."
        )
      );
  };

export const GetJobStatus = () => async (dispatch: Function) => {
  await axios
    .get(`/v1/common/shared/status`)
    .then((response) => {
      dispatch({
        type: GET_JOB_STATUS,
        payload: response.data.Data.map((x) => {
          return { value: x.StatusId, label: x.Name };
        }),
      });
    })
    .catch((err) =>
      dispatchError(
        err,
        dispatch,
        GET_JOB_STATUS,
        "Failed to get job status list."
      )
    );
};

export const GetExperienceDuration = () => async (dispatch: Function) => {
  await axios
    .get(`/v1/common/experience-duration`)
    .then((response) => {
      dispatch({
        type: GET_EXPERIENCE_DURATION,
        payload: Arrays(response.data),
      });
    })
    .catch((err) =>
      dispatchError(
        err,
        dispatch,
        GET_EXPERIENCE_DURATION,
        "Failed to get exepeience duration list."
      )
    );
};

export const GetSalaryPayDuration = () => async (dispatch: Function) => {
  await axios
    .get(`/v1/recruitment/jobs/salary-pay-durations`)
    .then((response) => {
      let data = Arrays(response.data);
      dispatch({
        type: GET_SALARY_PAY_DURATION,
        payload: Arrays(response.data.Data),
      });
    })
    .catch((err) =>
      dispatchError(
        err,
        dispatch,
        GET_SALARY_PAY_DURATION,
        "Failed to get saalary pay duration list."
      )
    );
};

export const getComapnyLogo = () => async (dispatch: Function) => {
  // dispatch({ type: ADD_LOADING, payload: true });
  await axios
    .get(`/v1/common/company-logo`, { headers: { Authorization: "a" } })
    .then((response) => {
      // dispatch({ type: ADD_LOADING, payload: false });
      dispatch({
        type: GET_COMPANY_LOGO,
        payload: response.data,
      });
    })
    .catch((err) =>
      dispatchError(
        err,
        dispatch,
        GET_COMPANY_LOGO,
        "Failed to get company information"
      )
    );
};

// export const UploadFiles = (data: any, iCompany = false, type = "") => async (
//   dispatch
// ) => {
//   const { file, fileList, fileListWithKey, folderNameToSaveFile = "" } = data;

//   let fd = new FormData();
//   // fileList.map((file, index) => {
//   //   fd.append("files[]", file);
//   // });

//   if (file) {
//     fd.append(file.name, file);
//   }

//   fileList &&
//     fileList.map((file, i) => {
//       fd.append(file.name, file);
//     });

//   fileListWithKey &&
//     fileListWithKey.map((f, i) => {
//       fd.append(f.key, f.file);
//     });

//   await axios
//     .post(
//       `/v1/common/shared/UploadFiles?folderName=${folderNameToSaveFile}&isCompany=${iCompany}`,
//       fd,
// {
//   headers: {
//     "content-type": "multipart/form-data",
//   },
// }
//     )
//     .then((response) => {
//       if (response.data.Status) {
// dispatch({
//   type: UPLOAD_FILES,
//   payload: response.data.Data,
//   uploadType: type,
// });
//       } else {
//         dispatch({
//           type: UPLOAD_FILES,
//           payload: [],
//           uploadType: "",
//         });
//       }
//       toastMessage(dispatch, response);
//     })
//     .catch((err) => {
//       dispatch({
//         type: UPLOAD_FILES,
//         payload: [],
//         uploadType: "",
//       });
//       const response = {
//         data: {
//           MessageType: "Danger",
//           Message: "Failed to upload files.",
//         },
//       };
//       toastMessage(dispatch, response);
//     });
// };

export const UploadFiles =
  (data: any, isCompany = false, type = "") =>
    async (dispatch) => {
      await axios
        .post(`/v1/common/shared/UploadFiles`, data)
        .then((response) => {
          if (response.data.Status) {
            dispatch({
              type: UPLOAD_FILES,
              payload: response.data.Data,
              uploadType: type,
            });
          }
          toastMessage(dispatch, response);
        })
        .catch((err) => {
          dispatch({
            type: UPLOAD_FILES,
            payload: [],
            uploadType: "",
          });
          const response = {
            data: {
              MessageType: "Danger",
              Message: "Failed to upload files.",
            },
          };
          toastMessage(dispatch, response);
        });
    };

export const DeleteFiles = (fileInfos: any) => async (dispatch: Function) => {
  await axios
    .delete(`/v1/common/shared/DeleteFiles`, fileInfos)
    .then((response) => {
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      toastMessage(dispatch, "Failed to delete files");
    });
};

export const GetCompanyInformation = (isAdmin, baseUrl) => async (dispatch: Function) => {
  await axios
    .get("/v1/common/company-information")
    .then((response) => {
      dispatch({
        type: GET_COMPANY_INFORMATION,
        payload: response.data,
      });
      if (!response.data && isAdmin) {
        window.location.replace(baseUrl + "/CP/ManageCompany.aspx")
      }
    })
    .catch((err) =>
      dispatchError(
        err,
        dispatch,
        GET_COMPANY_INFORMATION,
        "Failed to get company information"
      )
    );
};

export const GetNationality = () => async (dispatch: Function) => {
  await axios
    .get(`/v1/common/nationalities`)
    .then((response) => {
      dispatch({
        type: GET_NATIONALITY,
        payload: response.data.map((x) => {
          return { value: x.NationalityId, label: x.Nationality };
        }),
      });
    })
    .catch((err) =>
      dispatchError(
        err,
        dispatch,
        GET_NATIONALITY,
        "Failed to get nationality list."
      )
    );
};

export const GetBranches = () => async (dispatch: Function) => {
  await axios
    .get(`/v1/common/branches`, loadingHeaders)
    .then((res) => {
      if (res.status === 200) {
        const formatedData = [];
        res.data.map((d) => {
          formatedData.push({ label: d.Text, value: d.Value });
        });
        dispatch({
          type: GET_BRANCHES,
          payload: formatedData,
        });
      } else {
        toastMessage(dispatch, "Failed to get branches.");
      }
    })
    .catch((err) =>
      dispatchError(err, dispatch, GET_BRANCHES, "Failed to get branch list.")
    );
};

export const GetDepartments = () => async (dispatch: Function) => {
  await axios
    .get(`/v1/common/departments`)
    .then((res) => {
      if (res.status === 200) {
        const formatedData = [];
        res.data.map((d) => {
          formatedData.push({ label: d.Text, value: d.Value });
        });
        dispatch({
          type: GET_DEPARTMENTS,
          payload: formatedData,
        });
      } else {
        toastMessage(dispatch, "Failed to get departments.");
      }
    })
    .catch((err) =>
      dispatchError(
        err,
        dispatch,
        GET_DEPARTMENTS,
        "Failed to get departments list."
      )
    );
};

export const GetSubDepartments = () => async (dispatch: Function) => {
  await axios
    .get(`/v1/common/sub-departments`)
    .then((res) => {
      if (res.status === 200) {
        const formatedData = [];
        res.data.map((d) => {
          formatedData.push({ label: d.Text, value: d.Value });
        });
        dispatch({
          type: GET_SUB_DEPARTMENTS,
          payload: formatedData,
        });
      } else {
        toastMessage(dispatch, "Failed to get sub department");
      }
    })
    .catch((err) =>
      dispatchError(
        err,
        dispatch,
        GET_SUB_DEPARTMENTS,
        "Failed to get sub department list."
      )
    );
};

//Position or designation are same
export const GetDesignations = () => async (dispatch: Function) => {
  await axios
    .get(`/v1/common/designations`)
    .then((res) => {
      if (res.status === 200) {
        const formatedData = [];
        res.data.map((d) => {
          formatedData.push({ label: d.Text, value: d.Value });
        });
        dispatch({
          type: GET_DESIGNATION,
          payload: formatedData,
        });
      } else {
        toastMessage(dispatch, "Failed to get designation");
      }
    })
    .catch((err) =>
      dispatchError(
        err,
        dispatch,
        GET_DESIGNATION,
        "Failed to get designation list."
      )
    );
};

//Level or job role are same
export const GetLevels = () => async (dispatch: Function) => {
  await axios
    .get(`/v1/common/shared/levels`)
    .then((res) => {
      if (res.status === 200) {
        const formatedData = [];
        res.data.map((d) => {
          formatedData.push({ label: d.Text, value: d.Value });
        });
        dispatch({
          type: GET_LEVELS,
          payload: formatedData,
        });
      } else {
        toastMessage(dispatch, "Failed to get levels");
      }
    })
    .catch((err) =>
      dispatchError(err, dispatch, GET_LEVELS, "Failed to get levels list.")
    );
};

export const DisplayClientSideMessage = (data: any) => (dispatch: Function) => {
  let response = null;
  if (data.MessageType) {
    response = data;
  } else {
    response = {
      MessageType: "Danger",
      Message: data,
    };
  }
  toastMessage(dispatch, response);
};

export const GetJobProfiles = () => async (dispatch: Function) => {
  dispatch({
    type: SET_JOB_PROFILE_DROP_LIST_LOADING,
    payload: true,
  });
  await axios
    .get(`/v1/jp/job-profiles/dropdown-list`, loadingHeaders)
    .then((response) => {
      dispatch({
        type: GET_JOB_PROFILE_DROP_LIST,
        payload: response.data.Data,
      });
    })
    .catch((err) =>
      dispatchError(
        err,
        dispatch,
        GET_JOB_PROFILE_DROP_LIST,
        "Failed to get job profile list."
      )
    );
};

export const GetJobDescriptions = () => async (dispatch: Function) => {
  dispatch({
    type: SET_JOB_DESCRIPTION_DROP_LIST_LOADING,
    payload: true,
  });
  await axios
    .get(`/v1/jp/job-descriptions/dropdown-list`, loadingHeaders)
    .then((response) => {
      dispatch({
        type: GET_JOB_DESCRIPTION_DROP_LIST,
        payload: Arrays(response.data.Data),
      });
    })
    .catch((err) =>
      dispatchError(
        err,
        dispatch,
        GET_JOB_DESCRIPTION_DROP_LIST,
        "Failed to get job description list."
      )
    );
};

export const GetLevelsWithGroups = () => async (dispatch: Function) => {
  dispatch({
    type: SET_LEVELS_WITH_GROUPS_LOADING,
    payload: true,
  });
  await axios
    .get(`/v1/common/levels-with-groups`, loadingHeaders)
    .then((response) => {
      dispatch({
        type: GET_LEVELS_WITH_GROUPS,
        payload: response.data,
      });
    })
    .catch((err) =>
      dispatchError(
        err,
        dispatch,
        GET_LEVELS_WITH_GROUPS,
        "Failed to get level with group list."
      )
    );
};

export const GetCompanyHolidays = (data: any) => async (dispatch: Function) => {
  await axios
    .post(`/v1/common/company-holidays`, data, loadingHeaders)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: GET_COMPANY_HOLIDAYS,
          payload: response.Data,
        });
      }
    })
    .catch((err) =>
      dispatchError(
        err,
        dispatch,
        GET_COMPANY_HOLIDAYS,
        "Failed to get company holidays."
      )
    );
};

export const GetCompanyHolidaysWhole =
  (): any => async (dispatch: Function) => {
    const data = {
      holidayCategory: 0,
      pageIndex: 0,
      pageSize: 300,
    };
    await axios
      .post(`/v1/common/company-holidays`, data, loadingHeaders)
      .then((resp) => {
        const response = resp.data;
        if (response.Status) {
          dispatch({
            type: GET_COMPANY_HOLIDAYS_WHOLE,
            payload: response.Data,
          });
        }
      })
      .catch((err) =>
        dispatchError(
          err,
          dispatch,
          GET_COMPANY_HOLIDAYS_WHOLE,
          "Failed to get company holidays."
        )
      );
  };

export const SetSelectedRecords = (selectedRecord) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SELECTED_RECORD,
      payload: selectedRecord,
    });
  } catch (err) {
    toastMessage(dispatch, err.message);
  }
};

export const GetOverTimeTypes = () => async (dispatch: Function) => {
  await axios
    .get(`/v1/payroll/employee/overtimeType`)
    .then((response) => {
      dispatch({
        type: GET_OVERTIME_TYPES,
        payload: response.data.Data.map((x) => {
          return { value: x.OvertimeTypeId, label: x.Name };
        }),
      });
    })
    .catch((err) =>
      dispatchError(
        err,
        dispatch,
        GET_OVERTIME_TYPES,
        "Failed to get level with group list."
      )
    );
};

//get blood group types
export const GetBloodGroupList = () => async (dispatch: Function) => {
  await axios
    .get(`/v1/common/blood-group`)
    .then((res) => {
      if (res.status === 200) {
        const formatedData = [];
        res.data.Data.map((d) => {
          formatedData.push({ label: d.BloodGroupName, value: d.Id });
        });
        dispatch({
          type: GET_BLOOD_GROUP_LIST,
          payload: formatedData,
        });
      } else {
        toastMessage(dispatch, "Failed to get levels");
      }
    })
    .catch((err) =>
      dispatchError(
        err,
        dispatch,
        GET_BLOOD_GROUP_LIST,
        "Failed to get levels list."
      )
    );
};

// Get Level-Dropdown
export const getLevels = () => async (dispatch) => {
  //dispatch(setAllowancExpenseLoading());
  await axios
    .get(`/v1/common/levels`)
    .then((res) => {
      //console.log(res);
      dispatch({
        type: GET_LEVELS,
        payload: res.data,
        //page:{index:res.data.PageIndex,total:res.data.RecordsTotal}
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_LEVELS,
        payload: null,
      })
    );
};

// Get Payroll-Income-head-Dropdown
export const GetPayrollIncomeHeads = () => async (dispatch) => {
  //dispatch(setAllowancExpenseLoading());
  await axios
    .get(`/v1/payroll/income/heads`)
    .then((res) => {
      //console.log(res);
      dispatch({
        type: GET_PAYROLL_INCOME_HEADS,
        payload: res.data.Data,
        //page:{index:res.data.PageIndex,total:res.data.RecordsTotal}
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_PAYROLL_INCOME_HEADS,
        payload: null,
      })
    );
};

// get hiring methods
export const GetHiringMethods = () => async (dispatch: Function) => {
  await axios
    .get(`/v1/common/hiremethod`)
    .then((res) => {
      if (res.status === 200) {
        const formatedData = [];
        res.data.map((d) => {
          formatedData.push({ label: d.Text, value: d.Value });
        });
        dispatch({
          type: GET_HIRING_METHODS,
          payload: formatedData,
        });
      } else {
        toastMessage(dispatch, "Failed to get hirign");
      }
    })
    .catch((err) =>
      dispatchError(
        err,
        dispatch,
        GET_HIRING_METHODS,
        "Failed to get Hiring methods list."
      )
    );
};
