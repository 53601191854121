import React from "react";
import TeamMember from "./teamMember";

const TeamMemberSection = (props) => {
  const { data, onMemberRemoved, onAddMembers, isValidUser } = props;
  return (
    <div className="taskmodal__assigned">
      <h4>MEMBERS</h4>
      <div className="taskmodal__assigned-images">
        {data.TmTaskMemberListDto.map((member, index) => (
          <TeamMember
            key={index}
            member={member}
            index={index}
            isValidUser={isValidUser}
            onMemberRemoved={onMemberRemoved}
          />
        ))}
        {isValidUser() && (
          <TeamMember
            selectedMembers={data.TmTaskMemberListDto}
            onAddMembers={onAddMembers}
          />
        )}
      </div>
    </div>
  );
};
export default TeamMemberSection;
