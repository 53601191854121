import React, { useState } from 'react';
import Button from 'components/button'
import Modal from 'components/modal';
import AddShiftPattern from './addShiftPattern';
import AddShiftTemplate from './addShiftTemplate'
import { MdAdd } from 'react-icons/md'
import Table from 'components/customTable';
import Confirm from 'components/common/commonConfimation'
import {
    deleteRosterShiftPattern,
    getRosterPatternList,
} from '../helpers/action'
import { useDispatch, useSelector } from 'react-redux';
import Pagination from "components/common/pagination";


const ButtonCell = (props) => {
    const [open, setOpen] = useState(false);
    const onModalOpen = () => setOpen(true)
    const onModalClose = () => setOpen(false);
    const dispatch = useDispatch();
    const onEdit = () => {
        console.log("Edit")
    }
    const onDelete = () => {
        dispatch(deleteRosterShiftPattern(props?.value))
    }
    return (
        <div className="shifts-table__col">
            <div className="shifts-table__links">
                <span onClick={() => onModalOpen()} >Edit</span> |
                {/* <span>Archive</span> | */}
                <Confirm action={onDelete} >
                    <span>Delete</span>
                </Confirm> 
            </div>
            <Modal
                open={open}
                onModalClose={onModalClose}
                title="Add Shift Pattern"
            >
                <AddShiftTemplate isEdit editData={props?.row?.original} onModalClose={onModalClose} />
            </Modal>
        </div>
    )
}

const ShiftName = ({ value, row }) => {

    const [openPattern, setOpenPattern] = useState(false);
    const onModalPatternOpen = () => setOpenPattern(true)
    const onModalPatternClose = () => setOpenPattern(false);
    return <>
        <div
            onClick={() => onModalPatternOpen()}
            className="link-text">
            {value}

        </div>
        <Modal
            open={openPattern}
            onModalClose={onModalPatternClose}
            title="Add Shift Pattern"
            width="500px"
        >
            <AddShiftPattern shiftData={row.original} onModalClose={onModalPatternClose} />
        </Modal>
    </>
}

function ShiftPattern() {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const onModalOpen = () => setOpen(true)

    const onModalClose = () => setOpen(false);

    const onEdit = () => {
        console.log("Edit")
    }

    React.useEffect(() => {
        getShiftPatterns()
    }, []);

    const getShiftPatterns = async () => {
        dispatch(getRosterPatternList())
    }
    const cols = [
        {
            Header: 'Shift Pattern Name',
            accessor: 'Name',
            width: 160,
            Cell: ShiftName
        },
        {
            Header: 'Description',
            accessor: 'Description',
            width: 160,
        },
        {
            Header: 'Total No Of Days',
            accessor: 'TotalNoOfDays',
            width: 160,
        },
        {
            Header: '',
            accessor: 'Id',
            Cell: ButtonCell,
            width: 160,
        },
    ];
    const { patternList } = useSelector((state: any) => state.roosterReducer)
    return (
        <div className="shifts" >
            <div className="shifts-container">
                <div className="shifts-title">
                    Shifts Pattern
                </div>
                <div className="">
                    <Button title="Add Shift Pattern" bg="primary" buttonType="icon-button" icon={<MdAdd />} onClick={() => onModalOpen()} />
                </div>
                <Table
                    isFixedWidth
                    data={patternList}
                    columns={cols}
                />

            </div>
            <Modal
                open={open}
                onModalClose={onModalClose}
                title="Add Shift Pattern"
            >
                <AddShiftTemplate onModalClose={onModalClose} />
            </Modal>

        </div>
    )
}

export default ShiftPattern
