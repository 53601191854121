import React, { Component } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  ExcelExport,
  Grid,
  Inject,
  Toolbar,
  ToolbarItems
} from "@syncfusion/ej2-react-grids";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEmpty from "isEmpty";
import Loader from '../../../components/loaders'
import CommonPagination from '../../../components/common/pagination'
import { getBaseAxios } from "../../../helpers/constants";
import SvgIcons from "components/svgIcons";
import './style.scss';
class Table extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.template = this.gridTemplate;
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.toolbarClick = this.toolbarClick.bind(this);
  }
  componentDidMount() {
    this.grid = Grid || null;
    this.toolbar = ["ExcelExport"];
  }
  toolbarClick = id => {
    if (this.grid && id === "grid_excelexport") {
      this.grid.excelExport();
    }
  };
  gridTemplate(props) {
    return (
      <tr className="leave-row">
        <td className="">
          <a href="#">
            <span>{props.EmployeeId}</span>
          </a>
        </td>
        <td className="">
          <a
            href={
              getBaseAxios() + "/EmployeeDetails.aspx?Id=" + props.EmployeeId
            }
            target="_blank"
          >
            <span>{props.Name}</span>
          </a>
        </td>
        <td className="">
          <span>{props.Leave}</span>
        </td>
        <td className="">
          <span>{props.FromDateNep}</span>
        </td>
        <td className="">
          <span>{props.ToDateNep}</span>
        </td>
        <td className="">
          <a>
            <span>{props.Reason}</span>
          </a>
          {/* <a href="#">
            <span>{props.Reason}</span>
          </a> */}
        </td>
        <td
          className={
            props.Status === "Approved"
              ? "leave-status success"
              : "leave-status danger"
          }
        >
          <span className="leave-icon">
            {props.Status === "Approved" ? (
              <FaCheckDouble />
            ) : (
              <FaRegTimesCircle />
            )}
          </span>
          <span>{props.Status}</span>
        </td>
      </tr>
    );
  }
  handleChangePage = (event, newPage) => {
    this.props.handleIndexChange(newPage);
  };

  handleChangeRowsPerPage = event => {
    this.props.handleIndexChange(0);
    this.props.handleRowChange(event.target.value);
  };
  render() {
    const {
      searchedLeaveAbsence,
      searchedPage,
      loading
    } = this.props.leaveReducer;
    return (
      <div>
        <div className="arrear-table">
          {loading ? <Loader open={loading} /> : null}
          <div className="payroll-grid">
            <GridComponent
              dataSource={searchedLeaveAbsence}
              // toolbar={this.toolbar}
              //  height={!isEmpty(searchedLeaveAbsence)?"500":'80'}
              id="grid"
              className="custom-grid"
              allowExcelExport={true}
              ref={g => (this.grid = g)}
              rowTemplate={this.template.bind(this)}
            >
              <ColumnsDirective>
                <ColumnDirective field="EmployeeId" width="50" headerText="EID" />
                <ColumnDirective field="Name" width="100" headerText="Name" />
                <ColumnDirective field="Leave" width="60" headerText="Leave" />
                <ColumnDirective
                  field="FromDateNep"
                  width="100"
                  // textAlign="Center"
                  headerText="From Date"
                />
                <ColumnDirective field="LeaveTo" headerText="To Date" width="100" />
                <ColumnDirective field="Reason" headerText="Reason" width="100" />
                <ColumnDirective field="Status" headerText="Status" width="100" />
              </ColumnsDirective>
              {/* <Inject services={[ExcelExport]} /> */}
            </GridComponent>
            {isEmpty(searchedLeaveAbsence) ? null : (
              <CommonPagination
                handleRowChange={this.props.handleRowChange}
                handleIndexChange={this.props.handleIndexChange}
                pageIndex={this.props.pageIndex}
                searchedPage={searchedPage}
                rowsPerPage={this.props.rowsPerPage}
              />
            )}
          </div>
          {isEmpty(searchedLeaveAbsence) ? null : <>
            <div className="bottom-bar-buttons">
              <div className="button-group button-group__right">
                  <h3 className="card-sm__title align-center">Verified by Rina Dhungel on 2076 Ashwin 30, 04:05 pm</h3>
              </div>
              <div className="button-group button-group__right">
                <div className="card-sm green flex align-center">
                  <SvgIcons name="ApprovedCircle" className="approved-icon ml-xsm" />
                  <h3 className="card-sm__title align-center">Verified</h3>
                </div>
              </div>
            </div>
          </>}
          {/* )} */}
        </div>
      </div>
    );
  }
}

Table.propTypes = {
  leaveReducer: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  leaveReducer: state.leaveReducer
});

export default connect(mapStateToProps)(Table);
