import isEmpty from "isEmpty";
import { DateTime_Format } from "constants/dateFormat";
import { BS2AD } from "helpers/dateConvert";
export const getSaveObject = (data, isNepaliDate) => {
  let engStartDate = data.JobStartDate; // isNepaliDate && BS2AD(data.JobStartDate);
  let engEndDate = data.JobEndDate; // && BS2AD(data.JobEndDate);

  if (isNepaliDate) {
    try {
      engStartDate = BS2AD(data.JobStartDate);
      engStartDate = `${engStartDate?.year}/${engStartDate?.month}/${engStartDate?.day}`;
      //end date
      engEndDate = BS2AD(data.JobEndDate);
      engEndDate = `${engEndDate?.year}/${engEndDate?.month}/${engEndDate?.day}`;
    } catch (ex) { }
  }
  return {
    RequisitionId: data.RequisitionId,
    RequisitionNumber: data.RequisitionNumber,
    InternalJobTitle: data.InternalJobTitle,
    ExternalJobTitle: data.ExternalJobTitle,
    EmploymentTypeId: !isEmpty(data.EmploymentType)
      ? data.EmploymentType.value
      : 0,
    DivisionId: !isEmpty(data.Division) ? data.Division.value : 0,
    BranchId: !isEmpty(data.Branch) ? data.Branch.value : 0,
    LocationId: !isEmpty(data.Location) ? data.Location.value : 0,
    NumberOfOpenings: data.Openings,
    StartDate: data.JobStartDate !== null ? data.JobStartDate : "",
    EndDate: data.JobEndDate !== null ? data.JobEndDate : "",
    StartDateEng: engStartDate,
    EndDateEng: engEndDate,
    QualificationLevelId: !isEmpty(data.EducationLevel)
      ? data.EducationLevel.value
      : 0,
    AdditionalQualification: data.AdditionalQualification,
    IsExperience: data.IsExperienceRequired,
    Experience: data.NoofExperience,
    ExperienceDurationType: !isEmpty(data.ExperienceDurationType)
      ? data.ExperienceDurationType.value
      : 0,
    JobDescription: data.JobSummary,
    Memo: data.Memo,
    IsInternalJob: data.IsInternalJob
    // RequisitonStatusId: 0,
    // RequisitionStatusName: "string",
    // RequestBy: 0,
    // RequestOn: "string",
    // RequestOnEng: "2020-01-28T11:52:37.459Z",
    // ApprovedBy: 0,
    // ApprovedOn: "string",
    // ApprovedOnEng: "2020-01-28T11:52:37.459Z",
    // IsCancelled: true,
    // CancelledOn: "string",
    // CancelledOnEng: "2020-01-28T11:52:37.459Z"
  };
};

export const getEditObject = (recruitment_commonReducer, requisitionDetails) => {
  let data = requisitionDetails;
  console.log("get formatted objects: ", data)
  if (isEmpty(data)) {
    return null
  }
  let {
    branches,
    departments,
    locations,
    employeeStatus,
    educationLevels,
    approvers,
    experienceDuration,
    loading,
  } = recruitment_commonReducer //props.recruitment_commonReducer;
  let selectedEmployeeStatus = employeeStatus.find(
    (x) => x.value == data.EmploymentTypeId
  );
  let selectedDepartment = departments.find((x) => x.value == data.DivisionId);
  let selectedBranch = branches.find((x) => x.value == data.BranchId);
  let selectedLocation = locations.find((x) => x.value == data.LocationId);
  let selectedEducationLevel = educationLevels.find(
    (x) => x.value == data.QualificationLevelId
  );
  let selectedExperienceDuration = experienceDuration.find(
    (x) => x.value == data.ExperienceDurationType
  );
  return {
    RequisitionId: data.RequisitionId,
    RequisitionNumber: data.RequisitionNumber,
    InternalJobTitle: data.InternalJobTitle,
    ExternalJobTitle: data.ExternalJobTitle,
    EmploymentType: !isEmpty(selectedEmployeeStatus)
      ? selectedEmployeeStatus
      : "",
    Division: !isEmpty(selectedDepartment) ? selectedDepartment : "",
    Branch: !isEmpty(selectedBranch) ? selectedBranch : "",
    Location: !isEmpty(selectedLocation) ? selectedLocation : "",
    Openings: data.NumberOfOpenings,
    JobStartDate: data.StartDate,
    JobEndDate: data.EndDate,
    EducationLevel: !isEmpty(selectedEducationLevel)
      ? selectedEducationLevel
      : "",
    AdditionalQualification: data.AdditionalQualification,
    IsExperienceRequired: data.IsExperience,
    NoofExperience: data.Experience,
    ExperienceDurationType: !isEmpty(selectedExperienceDuration)
      ? selectedExperienceDuration
      : "",
    JobSummary: data.JobDescription,
    Memo: data.Memo,
    IsInternalJob: data.IsInternalJob
  };
};
