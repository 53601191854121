import React, { useState,useEffect } from 'react'
import Modal from 'components/modal';
//import man from  'assets/images/man_img.png';
//import group from  'assets/images/group.png';
import FormGroup from 'components/form';
// import { Authorized } from 'scenes/Roles/Authorized';
let group = "";
let man="";
function AssignRoleModal() {
    const [open,setOpen] = useState(false);
    const onModalClose = () =>{
        setOpen(false)
    }
    return (
        <div className="permission-btn">
            <button 
                onClick={()=>setOpen(true)}
                className="button button-primary">Select</button>
            <Modal
                open={open}
                onModalClose={onModalClose}
                title="Assign role to"
                isPrimary
                className="assign-modal"
            >
                <AssignRole close={onModalClose} />
            </Modal>
        </div>
    )
}
 
const managerOption = [
    {label:'Everyone',value:'Everyone'},
    {label:'Managers',value:'Managers'},
    {label:'Matrix Managers',value:'Matrix Managers'},
    {label:'Custom Managers',value:'Custom Managers'},
    {label:'Authorised User Group',value:'Authorised User Group'},
    {label:'Person',value:'Person'},
] 

const AssignRole = ({close}) =>{
    const [manager, setManager] = useState('');
    const [directReport, setDirectReport] = useState('');
    const [include, setInclude] = useState(false);
    const [allow, setAllow] = useState(false);
    const [managerSelect, setManagerSelect] = useState([]);
    const onChange = (e) =>{
        const {name,value} = e.target;
        if(name==='manager'){
            setManager(value)
        }
        if(name==='directReport'){
            setDirectReport(value)
        }
        if(name==='include'){
            setInclude(value)
        }
        if(name==='include'){
            setAllow(value)
        }
    }
    let isManagers = false;
    if(managerSelect && managerSelect.value === 'Managers'){
        isManagers = true
    }
    let isAuthorizedGroup = false;
    if(managerSelect && managerSelect.value === 'Authorised User Group'){
        isAuthorizedGroup = true
    }
    let isPerson = false;
    if(managerSelect && managerSelect.value === 'Person'){
        isPerson = true
    }
    return(
        <div className="permission">
            <div className="permission-body">
                <div className="assign">
                    <div className="assign-block">
                        <h3>1. Define who will receive this role permission.</h3>
                        <div className="assign-block__item">
                            <div className="assign-block__img">
                                <img src={man} alt="Man Icon"/>
                            </div>
                            <div className="assign-block__fields">
                                <div className="assign-select">
                                    <FormGroup
                                        formName="reactselect" 
                                        width="300px"
                                        options={managerOption}
                                        onChange={(name,value)=>setManagerSelect(value)}
                                        value={managerSelect}
                                        name="managerSelect"
                                    />
                                </div>
                                {
                                    isManagers && (
                                        <div className="assign-radio-group">
                                            <FormGroup
                                                formName="radiogroup"
                                                name="manager"
                                                label="All Managers"
                                                value="All Managers"
                                                checked={manager === "All Managers"}
                                                labelPosition="right"
                                                onChange={onChange}
                                            />
                                            <FormGroup
                                                formName="radiogroup"
                                                name="manager"
                                                label="Only Managers in these groups"
                                                value="Only Managers in these groups"
                                                checked={manager === "Only Managers in these groups"}
                                                labelPosition="right"
                                                onChange={onChange}
                                            />
                                    </div>
                                    )
                                }
                                
                                <div className="assign-btn">
                                    <button className="button button-primary">
                                        Change Authorised User Group
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="assign-block">
                        <h3>2. Specify the target population whose information can be accessed</h3>
                        {
                             isManagers && <ManagerOption /> 
                        }
                        {
                            isAuthorizedGroup && <AuthorizedOptions />
                        }
                        {
                            isPerson && <AuthorizedOptions />
                        }
                    </div>
                </div>
            </div>
            <div className="permission-footer">
                <div className="button-group button-group__right">
                    <button className="button button-primary">Save</button>
                    <button 
                        onClick={()=>close()}
                        className="button">Cancel</button>
                </div>
            </div>
        </div>
        
    )
}

const AuthorizedOptions = () =>{
    const [directReport, setDirectReport] = useState('');
    const [include, setInclude] = useState(false);
    const onChange = (e) =>{
        const {name,value} = e.target;
        if(name==='directReport'){
            setDirectReport(value);
            return;
        }
        if(name==='include'){
            setInclude(value);
            return;
        }
    }
    return(
        <div className="assign-block__item">
            <div className="assign-block__img">
                <img src={group} alt="Man Icon"/>
            </div>
            <div className="assign-block__fields">
                <div className="assign-radio-group">
                    <FormGroup
                        formName="radiogroup"
                        name="directReport"
                        label="Everyone"
                        value="Everyone"
                        checked={directReport === "Everyone"}
                        labelPosition="right"
                        onChange={onChange}
                    />
                    <FormGroup
                        formName="radiogroup"
                        name="directReport"
                        label="Authorised User Group’s Department"
                        value="Authorised User Group’s Department"
                        checked={directReport === "Authorised User Group’s Department"}
                        labelPosition="right"
                        onChange={onChange}
                    />
                    <FormGroup
                        formName="radiogroup"
                        name="directReport"
                        label="Authorized User Group’s Branch"
                        value="Authorized User Group’s Branch"
                        checked={directReport === "Authorized User Group’s Branch"}
                        labelPosition="right"
                        onChange={onChange}
                    />
                    <FormGroup
                        formName="radiogroup"
                        name="directReport"
                        label="Authorized User Group’s Cluster"
                        value="Authorized User Group’s Cluster"
                        checked={directReport === "Authorized User Group’s Cluster"}
                        labelPosition="right"
                        onChange={onChange}
                    />
                    <FormGroup
                        formName="radiogroup"
                        name="directReport"
                        label="Authorized User Group’s Region"
                        value="Authorized User Group’s Region"
                        checked={directReport === "Authorized User Group’s Region"}
                        labelPosition="right"
                        onChange={onChange}
                    />
                    <FormGroup
                        formName="radiogroup"
                        name="directReport"
                        label="Specific Authorized User Group"
                        value="Specific Authorized User Group"
                        checked={directReport === "Specific Authorized User Group"}
                        labelPosition="right"
                        onChange={onChange}
                    />
                </div>
                <div className="assign-btn">
                    <button className="button button-primary">
                        Select Authorised User Group
                    </button>
                </div>
                <div className="assign-check-group">
                    <FormGroup
                        formName="checkBoxGroup" 
                        name="include"
                        label="Do not allow to access own record"
                        value={include}
                        checked={include}
                        labelPosition="right"
                        onChange={onChange}
                    />
                </div>
            </div>
        </div>
    )
}


const ManagerOption = () =>{
    const [directReport, setDirectReport] = useState('');
    const [include, setInclude] = useState(false);
    const [allow, setAllow] = useState(false);
    const onChange = (e) =>{
        const {name,value} = e.target;
        if(name==='directReport'){
            setDirectReport(value);
            return;
        }
        if(name==='include'){
            setInclude(value);
            return;
        }
        if(name==='allow'){
            setAllow(value);
            return;
        }
    }
    return(
        <div className="assign-block__item">
            <div className="assign-block__img">
                <img src={group} alt="Man Icon"/>
            </div>
            <div className="assign-block__fields">
                <div className="assign-radio-group">
                        <FormGroup
                            formName="radiogroup"
                            name="directReport"
                            label="Direct Reports (subordinates)"
                            value="directReportSub"
                            checked={directReport === "directReportSub"}
                            labelPosition="right"
                            onChange={onChange}
                        />
                        <FormGroup
                            formName="radiogroup"
                            name="directReport"
                            label="Only Direct Reports to the users in these groups"
                            value="directReportUser"
                            checked={directReport === "directReportUser"}
                            labelPosition="right"
                            onChange={onChange}
                        />
                </div>
                <div className="assign-btn">
                    <button className="button button-primary">
                        Select Authorised User Group
                    </button>
                </div>
                <div className="assign-check-group">
                    <FormGroup
                        formName="checkgroup" 
                        name="include"
                        label="Include Direct Reports of the Direct Reports"
                        value={include}
                        checked={include}
                        labelPosition="right"
                        onChange={onChange}
                    />
                </div>
                <div className="assign-select-sm">
                    <FormGroup
                        formName="reactselect" 
                        width="100px"
                    />
                </div>
                <div className="assign-check-group">
                    <FormGroup 
                        formName="checkgroup"
                        name="allow"
                        label="Do not allow to access own record"
                        value={allow}
                        checked={allow}
                        labelPosition="right"
                        onChange={onChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default AssignRoleModal
