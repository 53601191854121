import React, { Component } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Filter,
  Inject,
  Sort,
  // SortSettingsModel,
} from "@syncfusion/ej2-react-grids";
import Pagination from "components/common/pagination";
import TableRow from "./tableRow";

export class table extends Component {
  constructor(props) {
    super(props);
    this.widths = {
      clientAndProject: 400,
      committedHours: 200,
      workedHours: 200,
      remainingHours: 200,
      progress: 200,
      expand: 30,
    };

    this.gridTemplate = this.gridTemplate;
    this.row = [];
  }

  gridTemplate(props, history) {
    return (
      <TableRow
        {...props}
        {...history}
        indexToExpand={this.props.indexToExpand}
        ref={(row) => {
          this.row = [...this.row, row];
        }}
      />
    );
  }

  render() {
    const {
      data,
      handleRowChange,
      handleIndexChange,
      pageIndex,
      rowsPerPage,
    } = this.props;

    return (
      <div className="default-table">
        {/* <div className="arrear-table"> */}
        <div className="pending-settlement-grid">
          <GridComponent
            dataSource={data}
            className="custom-grid"
            // toolbar={this.toolbar}
            // filterSettings={{ type: "Menu" }}
            // allowFiltering={true}
            //  height={!isEmpty(searchedLeaveAbsence)?"500":'80'}
            id="grid"
            // allowExcelExport={true}
            ref={(g) => (this.grid = g)}
            rowTemplate={this.gridTemplate.bind(this)}
            allowSorting={true}
            // sortSettings={this.sortingOptions}
          >
            <ColumnsDirective>
              <ColumnDirective width={this.widths.expand} />
              <ColumnDirective
                field="ClientName"
                headerText="Client: Project"
                width={this.widths.clientAndProject}
              />
              <ColumnDirective
                field="ContractHours"
                headerText="Committed Hours"
                width={this.widths.committedHours}
              />
              <ColumnDirective
                field="WorkedHours"
                headerText="Worked Hours"
                width={this.widths.workedHours}
              />
              <ColumnDirective
                field="RemainingHours"
                headerText="Remaining Hours"
                width={this.widths.remainingHours}
              />
              <ColumnDirective
                field="Progress"
                headerText="Progress"
                width={this.widths.progress}
              />
            </ColumnsDirective>
            {/* <Inject services={[Filter]} />
            <Inject services={[Filter,Sort]} />
            */}
            <Inject services={[Sort]} />
          </GridComponent>

          {data && data.length > 0 && (
            <Pagination
              handleRowChange={handleRowChange}
              handleIndexChange={handleIndexChange}
              pageIndex={pageIndex}
              rowsPerPage={rowsPerPage}
              total={data[0].TotalRows}
            />
          )}
        </div>
        {/* </div> */}
      </div>
    );
  }
}

export default table;
