import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { otherInputInterface } from "ts/interfaces/formInterface";

const HourMinuteInput = (props: otherInputInterface): JSX.Element => {
  const { name, type, value, autoFocus, disabled, placeholder } = props;

  const [hours, setHours] = useState(null);
  const [minutes, setMinutes] = useState(null);

  useEffect(() => {
    if (value) {
      let hrs: any = 0;
      hrs = value / 60;
      hrs = parseInt(hrs);
      setHours(hrs);

      let mins = value % 60;
      setMinutes(mins);
    }
  }, []);

  const handleChange = (inputName, event) => {
    let { name, value, max } = event.target;

    if (value) {
      value = value < 0 ? 0 : value;
      value = value.split("e")[0].split(".")[0];
    }

    let totalMinute: any = 0;
    let minFromHours: any = 0;
    let minFromMinutes: any = 0;
    if (name === "hour") {
      setHours(value);
      if (value) {
        minFromHours = value * 60;
        minFromHours = parseInt(minFromHours);
      }
      if (minutes) {
        minFromMinutes = parseInt(minutes);
      }
    } else if (name === "minute") {
      if (parseInt(value) > parseInt(max)) {
        value = max;
      }

      setMinutes(value);
      if (value) {
        minFromMinutes = parseInt(value);
      }
      if (hours) {
        minFromHours = hours * 60;
        minFromHours = parseInt(minFromHours);
      }
    }
    totalMinute = minFromHours + minFromMinutes;
    props.onChange && props.onChange(inputName, totalMinute);
  };

  const handleKeyUp = (inputName, event) => {
    const { name, value } = event.target;
    props.onKeyUp && props.onKeyUp(event);
  };

  const handleBlur = (inputName, event) => {
    const { name, value } = event.target;
    props.onBlur && props.onBlur(name, value);
  };
  const handleFocus = (inputName, event) => {
    if (type === "number") {
      event.target.select();
    }
  };

  return (
    <span className="genericForm-group__hour_minutes">
      <span className="genericForm-group__hour_minutes-inputs">
        <input
          placeholder={"hh"}
          value={hours}
          type={"number"}
          name={"hour"}
          autoFocus={autoFocus}
          disabled={disabled}
          onKeyUp={(e) => handleKeyUp(name, e)}
          onFocus={(e) => handleFocus(name, e)}
          onBlur={(e) => handleBlur(name, e)}
          onChange={(e) => handleChange(name, e)}
        />
        <span className="colon">:</span>
        <input
          placeholder={"mm"}
          value={minutes}
          type={"number"}
          name={"minute"}
          max={59}
          disabled={disabled}
          onKeyUp={(e) => handleKeyUp(name, e)}
          onFocus={(e) => handleFocus(name, e)}
          onBlur={(e) => handleBlur(name, e)}
          onChange={(e) => handleChange(name, e)}
        />
      </span>
    </span>
  );
};

HourMinuteInput.defaultProps = {
  name: "",
  type: "number",
  autoFocus: false,
  placeholder: "",
};

export default HourMinuteInput;
