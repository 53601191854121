import isEmpty from "isEmpty";
const getFormattedNumber = num => {
  let number = parseInt(num);
  return number.toLocaleString(navigator.language, {
    minimumFractionDigits: 2
  });
};

export const getCommaFormattedNumber = num => {
  if (isEmpty(num)) {
    return num;
  } else {
    let number = parseInt(num);
    return number.toLocaleString(navigator.language, {
      minimumFractionDigits: 0
    });
  }
};

export default getFormattedNumber;
