import Http from "src/utils/http/http";
import Action from "src/utils/http/actionUtility";
import environment from "environment";
import types from "constants/types";
import RouteEnum from "constants/routeEnum";

export const getConsumerLoanPurposeList = () => async (dispatch) => {
  const res = await Http.get(environment.api.getConsumerLoanPurposeList);
  const actionConfig = {
    dispatch,
    actionType: types.GET_CONSUMER_LOAN_PURPOSE_LIST,
    effect: res,
    isSelect: true,
    isValueKey: false,
    isJustData: false,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const getLoanSettingsByType = (LoanType) => async (dispatch) => {
  console.log(
    "environment.api.getLoanSettingsByType + LoanType",
    environment.api.getLoanSettingsByType + LoanType,
    `${environment.api.getLoanSettingsByType}/${LoanType}`
  );
  const res = await Http.get(`${environment.api.getLoanSettingsByType}/${LoanType}`);
  const actionConfig = {
    dispatch,
    actionType: types.LOAN_SETTING_BY_TYPE,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const getEmployeeInfo = (EmployeeId) => async (dispatch) => {
  const res = await Http.get(environment.api.getEmployeeInfo + EmployeeId);
  const actionConfig = {
    dispatch,
    actionType: types.GET_REQUESTER_INFORMATION,
    effect: res,
    // isSelect: false,
    // isValueKey: true,
    // isJustData: true,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const calculatePaymentSchedule = (data) => async (dispatch) => {
  const res: any = await Http.post(environment.api.calculatePaymentSchedule, data);
  const actionConfig = {
    dispatch,
    actionType: types.CALCULATE_PAYMENT_SCHEDULE,
    effect: res,
    message: "",
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
  return res.data.Data || [];
};

export const LoanApproverList = ({ EmployeeId,LoanType, FlowType, CurrentStep}) => async (dispatch) => {

  const res: any = await Http.get(
    environment.api.loanApproverList +
      `?employeeId=${EmployeeId}&loanType=${LoanType}&flowType=${FlowType}&currentStep=${CurrentStep}`
  );
  let formattedData = [];
  res.data.forEach((emp) => {
    formattedData.push({
      ...emp,
      FullName: emp.EmployeeName,
      Imagepath: emp.UrlPhoto,
      // Designation: emp.DesignationName,
      Department: emp.Department,
      Id: emp.EmployeeId,
    });
  })
  let resData = {
    ...res,
    data: formattedData
  }
  const actionConfig = {
    dispatch,
    actionType: types.LOAN_APPROVER_LIST,
    effect: resData,
    message: "",
    isLoading: true,
    isJustData: true
  };
  await Action.createThunkEffect(actionConfig);
  
};
export const GenerateIncomeDeductionList = ({ EmployeeId, LoanId, LoanType }) => async (dispatch) => {
  const res = await Http.get(
    environment.api.generateIncomeDeductionList + EmployeeId + `?LoanId=${LoanId}&LoanType=${LoanType}`
  );
  const actionConfig = {
    dispatch,
    actionType: types.GENERATE_INCOME_DEDUCTION_LIST,
    effect: res,
    message: "",
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const saveConsumerLoanRequest = (data, status, isEditMode) => async (dispatch) => {
  let postOrPut = isEditMode ? 'put' : 'post';
  const res: any = await Http[postOrPut](
    status == 0
      ? environment.api.draftConsumerLoanRequest
      : environment.api.saveConsumerLoanRequest,
    data
  );
  const actionConfig = {
    dispatch,
    actionType: types.SAVE_CONSUMER_LOAN_REQUEST,
    effect: res,
    message: isEditMode ? "Loan Updated Successfully" : status == 0 ? "Loan Saved Successfully" : "Loan Applied successfully",
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
  if (res.data.MessageType == 'Success') {
    return true;
  }
};

export const getConsumerLoanDetails = (loanId) => async (dispatch) => {
  const res: any = await Http.get(environment.api.getConsumerLoanDetails + loanId);
  const actionConfig = {
    dispatch,
    actionType: types.CONSUMER_LOAN_DETAILS,
    effect: res,
    message: "Loan Data fetched successfully",
    isLoading: true,
    isJustData: true
  };
  await Action.createThunkEffect(actionConfig);
  // if (res.data.MessageType == "Success") {
  //   return true;
  // }
};

export const resetLoanDetails = () => async (dispatch) => {
  dispatch({type: types.CONSUMER_LOAN_DETAILS, payload: null});
};

export const deleteRequestedLoan = (LoanId, LoanType) => async (dispatch) => {
  // dispatch({type: types.DELETE_REQUESTED_LOAN, payload: false});
  await dispatch(Action.createAction(types.DELETE_REQUESTED_LOAN, false));
    let apiURI =
      LoanType == 1
        ? environment.api.deleteConsumerLoan
        : environment.api.deleteSocialLoan;
    const res: any = await Http.delete(apiURI + LoanId);
  // const res: any = await Http.delete(environment.api.deleteConsumerLoan + LoanId);
  const actionConfig = {
    dispatch,
    actionType: types.DELETE_REQUESTED_LOAN,
    effect: res,
    message: "Loan Deleted successfully",
    isLoading: true,
    isJustData: true,
  };
  await Action.createThunkEffect(actionConfig);
  return res.data.Status || [];

};

// social loan
// export const saveSocialLoanRequest = (data) => async (dispatch) => {
//   const res = Http.post(environment.api.saveSocialLoanRequest, data);
//   const actionConfig = {
//     dispatch,
//     actionType: types.SAVE_CONSUMER_LOAN_REQUEST,
//     effect: res,
//     message: "Loan Applied successfully",
//     isLoading: true,
//   };
//   await Action.createThunkEffect(actionConfig);
// };

export const getAppliedLoanList = (data) => async (dispatch) => {
  const res: any = await Http.get(environment.api.getAppliedLoanList + data.EmployeeId);
  const actionConfig = {
    dispatch,
    actionType: types.MY_APPLIED_LOAN_LIST_REQUEST,
    effect: res,
    // isSelect: false,
    // isValueKey: true,
    // isJustData: true,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

// Get loan details
export const getLoanDetails = (loanId) => async (dispatch) => {
  const res: any = await Http.get(environment.api.getConsumerLoanDetails + loanId);
  const actionConfig = {
    dispatch,
    actionType: types.GET_LOAN_DETAILS,
    effect: res,
    // isSelect: false,
    // isValueKey: true,
    // isJustData: true,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

// Get outstanding loans
export const getOutstandingLoans = ({ EmployeeId, LoanType, LoanId}) => async (dispatch) => {
  const res: any = await Http.get(environment.api.getOutstandingLoans + "/" + EmployeeId + `?loanType=${LoanType}&loanId=${LoanId}`);
  const actionConfig = {
    dispatch,
    actionType: types.GET_OUTSTANDING_LOANS,
    effect: res,
    // isSelect: false,
    // isValueKey: true,
    // isJustData: true,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};
// save outstandingloans
export const saveOutstandingLoan = (data, action) => async (dispatch) => {
  await dispatch(Action.createAction(types.SAVE_OUTSTANDING_LOANS, false));
  const res: any = await Http[action](environment.api.getOutstandingLoans, data);
  const actionConfig = {
    dispatch,
    actionType: types.SAVE_OUTSTANDING_LOANS,
    effect: res,
    // isSelect: false,
    // isValueKey: true,
    // isJustData: true,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
  return res.data.Status ? res.data.Data : res.data.Status;
};

// delete saved outstandingloans
export const deleteOutstandingLoan = (LoanId) => async (dispatch) => {
  await dispatch(Action.createAction(types.DELETE_OUTSTANDING_LOANS, false));
  const res: any = await Http.delete(environment.api.getOutstandingLoans + '/' + LoanId);
  const actionConfig = {
    dispatch,
    actionType: types.DELETE_OUTSTANDING_LOANS,
    effect: res,
    // isSelect: false,
    // isValueKey: true,
    // isJustData: true,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
  return res.data.Status;
};

// delete saved outstandingloans
export const getLoanTypes = () => async (dispatch) => {
  // await dispatch(Action.createAction(types.DELETE_OUTSTANDING_LOANS, false));
  const res: any = await Http.get(environment.api.getSbiLoanTypes);
  const actionConfig = {
    dispatch,
    actionType: types.SBI_LOAN_TYPES,
    effect: res,
    isSelect: true,
    // isValueKey: true,
    // isJustData: true,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

// delete saved outstandingloans
export const getLoanCommentsHistory =
  ({ loanType, loanId }) =>
  async (dispatch) => {
    // await dispatch(Action.createAction(types.DELETE_OUTSTANDING_LOANS, false));
    const res: any = await Http.get(
      environment.api.getLoanCommentsHistory +
        "" +
        `?loanType=${loanType}&loanId=${loanId}`
    );
    const actionConfig = {
      dispatch,
      actionType: types.LOAN_COMMENT_HISTORY,
      effect: res,
      // isSelect: true,
      // isValueKey: true,
      // isJustData: true,
      isLoading: true,
      // isJustData: true,
    };
    await Action.createThunkEffect(actionConfig);
  };

// SOCIAL LOAN

export const saveSocialLoanRequest = (data, status, isEditMode) => async (dispatch) => {
  let postOrPut = isEditMode ? 'put' : 'post';
  const res: any = await Http[postOrPut](
    status == 0
      ? environment.api.draftSocialLoanRequest
      : environment.api.saveSocialLoanRequest,
    data
  );
  const actionConfig = {
    dispatch,
    actionType: types.SAVE_SOCIAL_LOAN_REQUEST,
    effect: res,
    message: isEditMode ? "Loan Updated Successfully" : status == 0 ? "Loan Saved Successfully" : "Loan Applied successfully",
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
  if (res.data.MessageType == 'Success') {
    return true;
  }
};


export const deleteSocialLoan = (LoanId, LoanType) => async (dispatch) => {
  // dispatch({type: types.DELETE_REQUESTED_LOAN, payload: false});
  await dispatch(Action.createAction(types.DELETE_REQUESTED_LOAN, false));
  let apiURI = LoanType == 1 ? environment.api.deleteConsumerLoan : environment.api.deleteSocialLoan
  const res: any = await Http.delete(apiURI + LoanId);
  const actionConfig = {
    dispatch,
    actionType: types.DELETE_SOCIAL_LOAN,
    effect: res,
    message: "Loan Deleted successfully",
    isLoading: true,
    isJustData: true,
  };
  await Action.createThunkEffect(actionConfig);
  return res.data.Status || [];
};

export const getSocialLoanDetails = (loanId) => async (dispatch) => {
  const res: any = await Http.get(environment.api.getSocialLoanDetails + loanId);
  const actionConfig = {
    dispatch,
    actionType: types.CONSUMER_LOAN_DETAILS,
    effect: res,
    message: "Loan Data fetched successfully",
    isLoading: true,
    isJustData: true,
  };
  await Action.createThunkEffect(actionConfig);

  // if (res.data.MessageType == "Success") {
  //   return true;
  // }
};

