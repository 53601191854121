import axios from "axios";
import {
  GET_EMPLOYEE_MANAGERS,
  GET_EMPLOYEE_MANAGERS_FOR_EXPORT,
  GET_EMPLOYEE_MANAGERS_BY_ID,
  REPORTING_TO_MANAGERS,
} from "actions/types";
import { toastMessage } from "actions/validateAction";
import isEmpty from "isEmpty";

const urlPrefix = "v1/hr/";

export const GetEmployeeManagers = (data, isExport) => async (dispatch) => {
  await axios
    .post(`${urlPrefix}/employee-managers`, data)
    .then(async (resp) => {
      const response = resp.data;
      if (response.Status) {
        if (!isExport) {
          dispatch({
            type: GET_EMPLOYEE_MANAGERS,
            managerAssignmentSummary: response.ManagerAssignmentSummary,
            employeeManagers: response.Data,
          });
        } else {
          let formatedExportData = [];
          Promise.all(
            response.Data.map((d) => {
              let row = {};
              row["Employee"] = d.Name + " [" + d.EmployeeId + "]";
              row["DirectManagerEffectiveDate"] = d.DEffectiveDateEng;
              row["MatrixManagerEffectiveDate"] = d.MEffectiveDateEng;
              row["CustomManagerEffectiveDate"] = d.CEffectiveDateEng;

              if (d.DManagerialType == 1) {
                row["DirectManager"] =
                  d.DManagerialPositionName +
                  " [" +
                  d.DManagerialPosition +
                  "]";
              } else if (d.DManagerialType == 2) {
                row["DirectManager"] =
                  d.DManagerialPersonName + " [" + d.DManagerialPerson + "]";
              }

              if (d.MManagerialType == 1) {
                row["MatrixManager"] =
                  d.MManagerialPositionName +
                  " [" +
                  d.MManagerialPosition +
                  "]";
              } else if (d.MManagerialType == 2) {
                row["MatrixManager"] =
                  d.MManagerialPersonName + " [" + d.MManagerialPerson + "]";
              }

              if (d.CManagerialType == 1) {
                row["MatrixManager"] =
                  d.CManagerialPositionName +
                  " [" +
                  d.CManagerialPosition +
                  "]";
              } else if (d.CManagerialType == 2) {
                row["MatrixManager"] =
                  d.CManagerialPersonName + " [" + d.CManagerialPerson + "]";
              }
              formatedExportData.push(row);
            })
          );

          dispatch({
            type: GET_EMPLOYEE_MANAGERS_FOR_EXPORT,
            employeeManagersForExport: [
              {
                sheetName: "Managers",
                data: formatedExportData,
              },
            ],
          });
        }
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const SaveImportedExcelData = (data) => async (dispatch) => {
  await axios
    .post(`${urlPrefix}/employee-managers-import`, data)
    .then((resp) => {
      const response = resp.data;
      // if (response.Status) {
      //   dispatch({
      //     type: GET_EMPLOYEE_MANAGERS,
      //     managerAssignmentSummary: response.ManagerAssignmentSummary,
      //     employeeManagers: response.Data,
      //   });
      // } else {
      //   toastMessage(dispatch, response);
      // }

      toastMessage(dispatch, response);
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const GetEmployeeManagerById = (employeeId) => async (dispatch) => {
  // dispatch({
  //   type: GET_EMPLOYEE_MANAGERS_BY_ID,
  //   payload: null,
  // });
  await axios
    .get(`${urlPrefix}/employee-managers/${employeeId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: GET_EMPLOYEE_MANAGERS_BY_ID,
          payload: response.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const UpdateEmployeeManagers = (data, refreshList) => async (
  dispatch
) => {
  await axios
    .post(`${urlPrefix}/reporting-to-managers`, data)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        // dispatch({
        //   type: REPORTING_TO_MANAGERS,
        //   payload: response.Data,
        // });
        refreshList && refreshList();
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const DeleteEmployeeManager = (employeeId, refreshList) => async (
  dispatch
) => {
  await axios
    .delete(`${urlPrefix}/employee-managers/${employeeId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        refreshList && refreshList();
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};
