import React, {useState} from "react";
import ProfileDocument from "./components/document";
import EducationDetail from "./components/educationDetail";
import CustomInformation from "./components/customInformation";
import TrainingInformation from "./components/trainingInformation";
import WorkAndExperience from "./components/workAndExperience";
import AddressInformation from "./components/addressInformation";
import Loader from "components/loaders";
import {connect} from "react-redux";
function Profile(props) {
  const {candidateDetail} = props;
  const {CandidateProfile, CandidateDocuments} = candidateDetail || {};

  const personalInforamations =
    (candidateDetail.CandidateProfile && [
      {label: "Phone", value: CandidateProfile.MobileNo},
      {label: "E-mail", value: CandidateProfile.Email},
      {
        label: "Date of Birth",
        value: CandidateProfile.BirthDate,
        light: CandidateProfile.Age,
      },
      {label: "Passport", value: CandidateProfile.HasPassport},
      {label: "Driving License", value: CandidateProfile.DrivingLicense},
      {label: "Employee ID", value: CandidateProfile.EmployeeId},
    ]) ||
    [];

  const familyInformations =
    (candidateDetail.CandidateProfile && [
      {label: "Father's Name", value: CandidateProfile.FatherName},
      {label: "Mother's Name", value: CandidateProfile.MohterName},
      {label: "Grandfather's Name", value: CandidateProfile.GrandFatherName},
      {label: "Marital Status", value: CandidateProfile.MaritalStatus},
      {label: "Spouse's Name", value: CandidateProfile.SpouseName},
      {
        label: "Father in law's Name",
        value: CandidateProfile.FatherInLawName,
      },
    ]) ||
    [];

  const referenceInformations =
    (candidateDetail.CandidateReferenceDto &&
      candidateDetail.CandidateReferenceDto.map((x) => {
        return [{label: x.Name, value: `${x.Position},${x.Organization}`}];
      })) ||
    [];

  return (
    <div>
      <div className="information-wholeInfo-nav-container">
        <Loader
          type="normal"
          className="loader-icon abs-position-center"
          loading={props.candidateDetailLoading}
          name="card"
          cardType="dashboard"
        />
        {personalInforamations.length > 0 && (
          <CustomInformation title="Personal Information" data={personalInforamations} />
        )}
        {candidateDetail.CandidateProfile && (
          <AddressInformation profile={candidateDetail.CandidateProfile} />
        )}

        {familyInformations.length > 0 && (
          <CustomInformation title="Family" data={familyInformations} />
        )}

        {candidateDetail.CandidateWorkExperience &&
          candidateDetail.CandidateWorkExperience.length > 0 && (
            <WorkAndExperience data={candidateDetail.CandidateWorkExperience} />
          )}
        {candidateDetail.CandidateEducation &&
          candidateDetail.CandidateEducation.length > 0 && (
            <EducationDetail data={candidateDetail.CandidateEducation} />
          )}
        {candidateDetail.CandidateTrainings &&
          candidateDetail.CandidateTrainings.length > 0 && (
            <TrainingInformation data={candidateDetail.CandidateTrainings} />
          )}
        {referenceInformations.length > 0 && (
          <CustomInformation title="Reference" data={referenceInformations} />
        )}
      </div>

      {CandidateDocuments && CandidateDocuments.length > 0 && (
        <ProfileDocument documents={CandidateDocuments} />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  candidateDetailLoading: state.recruitment_dashboardReducer.candidateDetailLoading,
});
export default connect(mapStateToProps, {})(Profile);
