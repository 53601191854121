import React, { useEffect } from 'react'
import FormItem from 'components/formGroup/formItem'
import { useForm } from 'react-hook-form'
import Button from 'components/button'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import { useSelector } from 'react-redux';
import { dateSelectOptions, getDateRange } from '../../helpers/roosterDates';
import dayjs from "dayjs";
var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

function ScheduleFilter(props) {
    const { onChange, filter } = props;
    const method = useForm()
    const { control, setValue } = method;
    const { teamList, teamRoleList } = useSelector((state: any) => state.roosterReducer);
    const startDateShort = filter?.startDate && dayjs(filter?.startDate).format('DD MMM')
    const endDateShort = filter?.endDate && dayjs(filter?.endDate).format('DD MMM')
    useEffect(() => {
        onChange("dates", dateSelectOptions[0]);
    }, [])
    return (
        <div className="schedule-filters">
            <div className="schedule-filters__col">
                <FormItem
                    control={control}
                    width="200px"
                    name="teamId"
                    value={filter.teamId}
                    onChange={onChange}
                    formName="reactselect"
                    placeholder='Select Team'
                    options={teamList}
                />
                <div className="schedule-action__btns">
                    <div className="schedule-action__btn">
                        <Button 
                            onClick={()=>onChange('dateIndex',filter?.dateIndex + 1)}
                            buttonType="icon-button" bg="secondary" icon={<ChevronLeft />} />
                    </div>
                    <div className="schedule-action__btn">
                        <Button 
                            onClick={()=>onChange('dateIndex',filter?.dateIndex - 1)}
                            buttonType="icon-button" bg="secondary" icon={<ChevronRight />} />
                    </div>
                </div>
                <div className="schedule-action__btn">
                    <Button 
                        onClick={()=>onChange('dateIndex',0)}
                        isDisabled={filter?.dateIndex === 0}
                        title="Today" bg="secondary" 
                    />
                </div>
                <FormItem
                    control={control}
                    formName="reactselect"
                    name="dates"
                    width="150px"
                    value={filter.dates}
                    onChange={onChange}
                    placeholder='Select Dates'
                    options={dateSelectOptions}
                />
                {
                    endDateShort && startDateShort && <div className="schedule-action__dates">
                        {startDateShort + '  -  ' + endDateShort}
                    </div>
                }
            </div>
            <div className="schedule-filters__col">
                <div className="schedule-filters__label"><span>Group By</span></div>
                <FormItem
                    control={control}
                    width="200px"
                    name="group"
                    formName="reactselect"
                    placeholder='Role, None'
                    options={[]}
                />
            </div>
        </div>
    )
}

export default ScheduleFilter
