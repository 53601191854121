import React, { Component } from "react";
import { Tabs, Tab } from "components/tabs/index.d";
import LoanListingTable from "./loanListingTable";
import { connect } from "react-redux";

export class LoansTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        // { tabId: 1, stageId: 0, label: "All Loans" },
        { tabId: 1, stageId: 1, label: "Loan to Review" },
        { tabId: 2, stageId: 2, label: "Approved/Rejected Loan" },
        { tabId: 3, stageId: 3, label: "Forwarded Loan" },
      ],
      activeStageId: 1,
    };
  }

  handleTabChange = (tabId) => {
    const { tabs } = this.state;
    const activeStageId = tabs && tabs.find((f) => f.tabId == tabId).stageId;
    this.setState({ activeStageId: tabId });
    this.props.handleStageChange && this.props.handleStageChange(activeStageId);
  };

  render() {
    const { tabs, activeStageId } = this.state;
    const {
      parentState: { PageIndex, PageSize },
      isHR,
    } = this.props;

    return (
      <div>
        {tabs && (
          <Tabs
            // type="block"
            noBackground
          >
            {tabs.map((tab, index) => {
              if (tab.tabId == 2 && !isHR) return null;
              return (
                <Tab
                  label={tab.label}
                  name={tab.tabId}
                  id={tab.tabId}
                  key={index}
                  isActive={activeStageId === tab.tabId}
                  onClick={() => this.handleTabChange(tab.tabId)}
                >
                  <LoanListingTable
                    // data={forwardedLoanListToReview}
                    pageIndex={PageIndex}
                    rowsPerPage={PageSize}
                    activeStageId={activeStageId}
                    handleRowChange={this.props.handleRowChange}
                    handleStageChange={() => this.props.handleStageChange(activeStageId)}
                    handleIndexChange={this.props.handleIndexChange}
                    forwardToApprover={this.props.forwardToApprover}
                    handleInput={this.props.handleInput}
                  />
                </Tab>
              );
            })}
          </Tabs>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  isHR: state.loanReducer.isHR,
});
export default connect(mapStateToProps, null)(LoansTabs);
