import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SvgIcons from "components/icons/svgIcons";
import Icons from "components/icons";
import ImageViewer from "components/imageViewer";
import getFullStringUrl from "helpers/getFullStringUrl";
import { getBaseAxios } from "helpers/constants";
import { Apis } from "src/config/api";
import { checkExtension } from "helpers/checkExtension";
import classNames from "classnames";

function FileBlock({ file }) {
  const [preview, setPreview] = useState(false);
  const [hostedUrl, setHostedUrl] = useState("");

  const param = {
    serverFileName: file.ServerFileName,
    userFileName: file.UserFileName,
    fileType: file.FileType,
  };

  const { baseUrl } = useSelector((state) => {
    return state.auth;
  });

  useEffect(() => {
    const url = (baseUrl && baseUrl.NetFrameworkAppBaseUrl) || getBaseAxios();
    setHostedUrl(url);
  }, [baseUrl]);

  const { isDocs, isImage, isPdf, isXls, ext } = checkExtension(
    file.UserFileName
  );
  const downloadLinkUrl = getFullStringUrl(
    getBaseAxios() + Apis.common.downloadFiles,
    param
  );

  return (
    <>
      {isDocs || isPdf ? (
        <div
          onClick={(e) => window.open(downloadLinkUrl)}
          className={classNames({
            document: true,
          })}
        >
          <div
            className={classNames({
              "document-border": !isImage,
            })}
          >
            <span
              className={classNames({
                "document-ext": true,
                pdf: isPdf,
                doc: isDocs,
                xls: isXls,
              })}
            >
              {ext}
            </span>

            <span className="document__name">{file.UserFileName}</span>
          </div>
        </div>
      ) : (
        <div
          onClick={(e) => {
            e.stopPropagation();
            setPreview(true);
          }}
          className="document"
        >
          {isImage && (
            <div className="document-image">
              {/* <div className="document-name">{file.UserFileName}</div> */}

              <img
                alt={file.UserFileName}
                src={`${hostedUrl}${file.FileLocation}${file.ServerThumbnailFileName}`}
                style={{ width: "100%" }}
              />
            </div>
          )}
          {preview && (
            <ImageViewer
              dowloadLinkUrl={downloadLinkUrl}
              hostedUrl={hostedUrl}
              show={preview}
              title={file.UserFileName}
              image={file}
              onClose={setPreview}
            />
          )}
        </div>
      )}
    </>
  );
}

export default FileBlock;
