import React, { Component } from "react";
import classnames from "classnames";
import { expandIcon } from "scenes/taskTracker/common/expandIcon";

export class TableRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drop: false,
    };
    this.toggleDetail = this.toggleDetail.bind(this);
  }
  componentDidMount() {
    if (this.props.index == this.props.indexToExpand) {
      this.setState({ drop: true });
    }
  }

  // toggleDetail = (id) => {
  //   var toggleIcon = document.getElementById(`toggleIcon${id}`);
  //   toggleIcon.classList.toggle("expand");
  //   var detailsRow = document.getElementById(`details${id}`);

  //   if (detailsRow.offsetHeight === 0) {
  //     detailsRow.style.height = detailsRow.scrollHeight + "px";
  //   } else {
  //     detailsRow.style.height = "0";
  //   }
  // };
  toggleDetail = (id, drop) => {
    this.setState({ drop: !drop });
    var toggleIcon = document.getElementById(`toggleIcon${id}`);
    toggleIcon.classList.toggle("expand");
    var detailsRow = document.querySelectorAll(`.details-${id}`);

    if (detailsRow) {
      for (var i = 0; i < detailsRow.length; i++) {
        detailsRow[i].classList.toggle("row-expand");
      }
    }
  };

  // componentDidMount() {
  //   this.toggleDetail(this.props.index);
  // }

  render() {
    const { props } = this;
    let { drop } = this.state;
    let trClass = "table-row";
    if (drop) {
      trClass = trClass + " expanded__tr";
    }
    return (
      <>
        <tr
          className={trClass}
          role="row"
          key={props.index}
          id={`toggleIcon${props.index}`}
          onClick={() => this.toggleDetail(props.index, drop)}
        >
          <td className="e-rowcell" role="gridcell">
            {props.CommitmentDetail &&
              props.CommitmentDetail.length > 0 &&
              expandIcon(drop)}
          </td>
          <td className="e-rowcell" role="gridcell">
            <span>{props.ClientName}</span>
          </td>
          <td className="e-rowcell" role="gridcell">
            <span>{/* //Committed Hours */}</span>
          </td>
          <td className="e-rowcell" role="gridcell">
            <span>{/* //Worked Hours */}</span>
          </td>
          <td className="e-rowcell " role="gridcell">
            <span>{/* //Remaininng Hours */}</span>
          </td>
          <td className="e-rowcell " role="gridcell">
            <span>{/* //Progress */}</span>
          </td>
        </tr>

        {props.CommitmentDetail &&
          props.CommitmentDetail.map((cd, i) => {
            const rowClasses = classnames({
              "table-details": true,
              "custom-row": true,
              "expanded__tr-details": true,
              "row-expand": props.index == props.indexToExpand,
              [`details-${props.index}`]: true,
            });
            return (
              <tr className={rowClasses} key={i}>
                <td>
                  <span className="detail"></span>
                </td>
                <td>
                  <span className="detail">
                    <span className="project">
                      <span className="project-icon">P</span>
                      <span>{cd.ProjectName}</span>
                    </span>
                  </span>
                </td>

                <td>
                  <span className="detail">{cd.ContractHoursText}</span>
                </td>

                <td>
                  <span className="detail">{cd.WorkedHoursText}</span>
                </td>

                <td>
                  <span className="detail">{cd.RemainingHoursText}</span>
                </td>

                <td>
                  <span className="detail">{cd.Progress}</span>
                </td>
              </tr>
            );
          })}
      </>
    );
  }
}

export default TableRow;
