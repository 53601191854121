import { GET_ERRORS, CLEAR_ERRORS } from "actions/types";
import { Action, Reducer } from 'redux';


const initialState = {};

interface ErrorAction {
    type: string;
    payload: any;
}
export const reducer:Reducer = (state,incomingAction:Action) =>{
    if (state === undefined) {
        return initialState;
    }
    const action = incomingAction as ErrorAction;
    switch (action.type) {
        case GET_ERRORS:
          return action.payload ? action.payload : {};
        case CLEAR_ERRORS:
          return {};
        default:
          return state;
      }
}