import axios from "axios";
import {
  ALLOWANCE_EXPENSE_LOADING,
  GET_ALLOWANCE_EXPENSE,
  ADD_ALLOWANCE_EXPENSE,
  ADD_MESSAGE,
  LOAD_ALLOWANCE_EXPENSE,
  SET_EDIT_MODE,
  EDIT_ALLOWANCE_EXPENSE,
  DELETE_ALLOWANCE_EXPENSE,
  HANDLE_MODAL_SHOW,
  CLEAR_ERRORS,
} from "actions/types";

// Add Allowance-Expense
export const addAllowanceExpense = (data, handleModalShow) => (dispatch) => {
  dispatch(setAllowanceExpenseLoading(true));
  axios
    .post(`/v1/travel/allowance-expenses`, data)
    .then((res) => {
      if (res.data.MessageType === "Success" && res.data.Status === true) {
        handleModalShow(false, "normal");
        dispatch({
          type: ADD_ALLOWANCE_EXPENSE,
          payload: res.data.Data,
          //page: { index: res.data.PageIndex, total: res.data.RecordsTotal }
        });
      } else {
        dispatch(setAllowanceExpenseLoading(false));
      }
      dispatch(toastMessage(res));
    })
    .catch((err) =>
      dispatch({
        type: ADD_ALLOWANCE_EXPENSE,
        payload: null,
      })
    );
};

// Edit Allowance-Expense
export const editAllowanceExpense = (data, handleModalShow) => (dispatch) => {
  dispatch(setAllowanceExpenseLoading(true));
  axios
    .put(`/v1/travel/allowance-expenses`, data)
    .then((res) => {
      // console.log(res)
      if (res.data.MessageType === "Success" && res.data.Status === true) {
        handleModalShow(false, "normal");

        dispatch({
          type: EDIT_ALLOWANCE_EXPENSE,
          payload: res.data.Data,
          id: data.AllowanceId,
        });
      } else {
        dispatch(setAllowanceExpenseLoading(false));
      }
      dispatch(toastMessage(res));
    })
    .catch((err) =>
      dispatch({
        type: EDIT_ALLOWANCE_EXPENSE,
        payload: null,
      })
    );
};

// Get Allowance-Expense
export const getAllowanceExpense = (id) => (dispatch) => {
  //dispatch(setAllowanceExpenseLoading());
  axios
    .get(`/v1/travel/allowance-expenses`)
    .then((res) => {
      console.log(res);
      dispatch({
        type: GET_ALLOWANCE_EXPENSE,
        payload: res.data,
        //page:{index:res.data.PageIndex,total:res.data.RecordsTotal}
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ALLOWANCE_EXPENSE,
        payload: null,
      })
    );
};

// Delete Allowance-Expense
export const deleteAllowanceExpense = (id) => (dispatch) => {
  dispatch(setAllowanceExpenseLoading(true));
  axios
    .delete(`/v1/travel/allowance-expenses?id=${id}`)
    .then((res) => {
      //console.log(res);
      if (res.data.MessageType === "Success" && res.data.Status === true) {
        dispatch({
          type: DELETE_ALLOWANCE_EXPENSE,
          id,
          //page:{index:res.data.PageIndex,total:res.data.RecordsTotal}
        });
      } else {
        dispatch(setAllowanceExpenseLoading(false));
      }
      dispatch(toastMessage(res));
    })
    .catch((err) =>
      dispatch({
        type: DELETE_ALLOWANCE_EXPENSE,
        payload: null,
      })
    );
};

// export const handleModalShow = (data) => (dispatch) => {
//   dispatch({
//     type: HANDLE_MODAL_SHOW,
//     payload: data,
//   });
// };

export const setEditMode = () => (dispatch) => {
  dispatch({
    type: SET_EDIT_MODE,
  });
};
export const loadAllowanceExpense = (data) => (dispatch) => {
  dispatch({
    type: LOAD_ALLOWANCE_EXPENSE,
    payload: data,
  });
};
export const toastMessage = (res) => {
  return {
    type: ADD_MESSAGE,
    payload: {
      type: res.data.MessageType,
      message: res.data.Message,
      position: "bottom-right",
    },
  };
};

// Set loading state
export const setAllowanceExpenseLoading = (data) => {
  return {
    type: ALLOWANCE_EXPENSE_LOADING,
    payload: data,
  };
};

// Clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
