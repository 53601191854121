import React,{useState,useEffect} from 'react'
import './ksa.styles.scss';
import shortid from "shortid";
import ScoreCard from './ScoreCard';
import RadarChart from './RadarChart';
import { connect } from 'react-redux';

// const skillData = [
//     {Id:shortid.generate(),num:1,name:'JavaScript',min:5,max:6, RatingId : 1, score:6},
//     {Id:shortid.generate(),num:2,name:'CSS',min:4,max:6, RatingId :3, score:5},
//     {Id:shortid.generate(),num:3,name:'React',min:3,max:6, RatingId : 6,  score:3},
//     {Id:shortid.generate(),num:4,name:'SQL',min:5,max:6, RatingId :4,  score:0},
//     {Id:shortid.generate(),num:5,name:'Secure Development',min:4,max:6, RatingId : 0, score:2},
// ]

function index(props) {
    let data = [];
    let skillData = [];
    let abilitiesData = [];
    if (props.employeeKsaRating) {
        props.employeeKsaRating.Knowledge.forEach((k, index) =>
            data.push(getfilterData(k, index))
        );
        props.employeeKsaRating.Abilities.forEach((k, index) =>
            skillData.push(getfilterData(k, index))
        )
        props.employeeKsaRating.Skills.forEach((k, index) =>
            abilitiesData.push(getfilterData(k, index))
        );
    }

    return (
        <div className="ksa">
            <div className="ksa-container">
                <KSABlock 
                    title="Knowledge"
                    data={data}
                    {...props}
                />
                <KSABlock 
                    title="Skills"
                    data={skillData}
                    {...props}
                />
                <KSABlock 
                    title="Abilities"
                    data={abilitiesData}
                    {...props}
                />

            </div>
        </div>
    )
}

const getfilterData = (k, index) => {
    return {
            Id: k.Id,
            num: index + 1,
            name: k.KSAStatement,
            min: k.ExpectedRatingValue || 1,
            max: 6,
            score: k.RatingValue || 0,
            id: k.Id,
            EmployeeRef_Id: k.EmployeeRefId,
            JobDescriptionRef_Id: k.JobDescriptionRef_Id,
            KSARef_Id: k.KSARefId ? k.KSARefId : k.KSAId,
            Type: k.Type,
            RatingId: k.RatingValue || 0
        }
}


const KSABlock = ({ title, data, ...props }) =>{
    return(
        data.length > 0 ? <div className="ksa-block">
            <div className="ksa-title">
                <h3>{title}</h3>
            </div>
            <div className="ksa-block__data">
                <div className="ksa-block__left">
                    <ul className="ksa-block__list">
                        {
                            data.map((item, index) => 
                            <ScoreCard item={item} index={index} {...props}/>
                            )
                        }
                    </ul>
                </div>
                <div className="ksa-block__right">
                    <RadarChart data={data} />
                </div>
            </div>
        </div> : null
    )
}

const mapStateToProps = state => ({
    employeeKsaRating: state.employeeProfile.employeeKsaRating,
    employeeAssignedJobDescription: state.employeeProfile.employeeAssignedJobDescription,
});


export default connect(mapStateToProps, null)(index);