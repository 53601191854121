import React, { useState, useEffect } from "react";
import UploadedFileBlock from "./uploadFileBlock";
import classnames from "classnames";
import { checkExt, validateFile } from "./utils";
import isEmpty from "isEmpty";
import { UploadFiles } from "../../../store/actions/common";
import { connect } from "react-redux";
import { objectToFormData } from "object-to-formdata";
import {
  validApplicationExtension,
  validImageExtension,
} from "../../../constants/validExtensions";

const UploadInput = (props) => {
  const {
    name,
    disabled,
    isMultiple,
    maxFiles,
    maxSizeInMb,
    uploadSize,
    uploadTitle,
  } = props;
  const [error, setError] = useState("");
  const [value, setValues] = useState([]);

  let height = 60;
  if (isMultiple && maxFiles > 1) {
    height = 75;
  }
  if (error) {
    height += 20;
  }

  useEffect(() => {
    if (!isEmpty(props.value)) {
      setValues(props.value);
    }
  }, [props.value]);

  let showUploadSection = () =>
    !disabled &&
    ((!isMultiple && !value) ||
      (isMultiple && (!value || value.length < (maxFiles || 5))));

  const onDelete = (index) => {
    let newValue = [...value];
    newValue.splice(index, 1);
    setValues(newValue);
    props.onChange && props.onChange(name, newValue);
  };
  const uploadToServer = async (newFiles) => {
    const formData = objectToFormData({});

    for (var i = 0; i < newFiles.length; i++) {
      formData.append("files", newFiles[i]);
    }
    let response: any = await props.UploadFiles(formData);

    //files.push(response);
    const newValue = [...value, ...response];
    props.onChange && props.onChange(name, newValue);
    setValues(newValue);
  };

  const handleChange = (event) => {
    if (!showUploadSection()) return;
    const existingFile = value.map((file) => file.UserFileName);

    const { files } = event.target;
    let newFiles = [];
    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      if (!existingFile.includes(file.name))
        newFiles.push(
          Object.assign(file, {
            UserFileName: file.name,
            ServerFileName: file.name,
          })
        );
    }
    if (newFiles.length > 0) {
      let totalFiles = newFiles.length + existingFile.length;
      if (totalFiles > maxFiles) {
        setError(`Max file number is ${maxFiles}`);
        return;
      }
      let isValid = true;
      for (let i = 0; i < newFiles.length; i++) {
        isValid = validateFile(files[i], props, setError);
        if (!isValid) break;
      }
      if (isValid) {
        setError("");
        uploadToServer(newFiles);
      }
    }
  };

  return (
    <div className="flex">
      <div className="genericForm-group__upload">
        {showUploadSection() && (
          <>
            <input
              style={{
                height: uploadSize ? "100%" : height + "px",
                borderBottom: value && value.length > 0 ? "1px solid #fff" : "",
              }}
              className={classnames({
                "genericForm-group__upload-input": true,
                "disable-input": disabled,
              })}
              type="file"
              multiple={isMultiple}
              name={name}
              disabled={disabled}
              onChange={(event) => handleChange && handleChange(event)}
            />
            <div className="genericForm-group__upload-text-section">
              <span className="text1">{uploadTitle} </span>
              <span className="text2">
                {`Max file size is ${maxSizeInMb} Mb`}
              </span>
              {/* {isMultiple && maxFiles > 1 && (
                    <span className="text2">
                      {`Click Ctrl to select multiple.`}
                    </span>
                  )} */}
              {error && <div className="error__message  mt-xsm">{error}</div>}
            </div>
          </>
        )}
        {!isEmpty(value) && (
          <div
            className={classnames("uploaded-files", {
              // "up-col": value && checkExt(value, isMultiple),
              "up-col": true,
            })}
            style={{ marginTop: showUploadSection ? "25px" : "" }}
          >
            {value.map((v, i) => (
              <UploadedFileBlock
                disabled={disabled}
                onDelete={onDelete}
                file={v}
                index={i}
                key={i}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
UploadInput.defaultProps = {
  validExtensions: [...validImageExtension, ...validApplicationExtension],
  minSizeInKb: 1,
  maxSizeInMb: 3,
  disabled: false,
  isMultiple: false,
  maxFiles: 5,
  uploadTitle: "Browse and Upload Documents",
  uploadSize: null, //{ width: "100px", height: "100px" },
  isCropImage: false,
};
const mapStateToProps = (state: any) => ({});
const mapDispatchToProps = {
  UploadFiles,
};
export default connect(mapStateToProps, mapDispatchToProps)(UploadInput);
