import isEmpty from "helpers/isEmpty";
export const convetToDataSource = (
  items,
  isSelected = null,
  base = "",
  selectedList = []
) => {
  let newItems = (items && [...items]) || [];
  return newItems.map((x) => ({
    ...x,
    isChecked: isEmpty(isSelected) ? false : isSelected,
    BaseUrl: base,
  }));
};

export const convetFilterToDataSource = (
  items,
  selectedList = [],
  base = ""
) => {
  let newItems = (items && [...items]) || [];
  let selected = selectedList.map((x) => x.EmployeeId);
  newItems = newItems.map((x) => ({
    ...x,
    isChecked: selected.includes(x.EmployeeId),
    BaseUrl: base,
  }));

  return newItems;
};
