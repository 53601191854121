import { ReactComponent as LeaveIcon } from "assets/images/icons/leave.svg";
import { ReactComponent as TimeIcon } from "assets/images/icons/time.svg";
import { ReactComponent as OvertimeIcon } from "assets/images/icons/overtime.svg";
import { ReactComponent as AllowanceIcon } from "assets/images/icons/allowance.svg";
import { ReactComponent as TravelIcon } from "assets/images/icons/travel.svg";
import RouteEnum from 'constants/routeEnum';
export const getmyUpDates = (myUpDates) => {
  let myNewUpDateList = [];
  myUpDates?.UnApprovedLeave?.map((x) => {
    myNewUpDateList.push({
      title: "UnApproved Leave",
      data: x,
      link: RouteEnum.MyRequestPath,
      requestType: "Leave",
      icon: LeaveIcon,
    });
  });
  myUpDates?.UnApprovedTime?.map((x) => {
    myNewUpDateList.push({
      title: "UnApproved Leave",
      data: x,
      link: RouteEnum.MyRequestPath,
      requestType: "Time",
      icon: TimeIcon,
    });
  });
  myUpDates?.UnApprovedOverTime?.map((x) => {
    myNewUpDateList.push({
      title: "UnApproved Overtime",
      data: x,
      link: RouteEnum.MyRequestPath,
      requestType: "Overtime",
      icon: OvertimeIcon,
    });
  });
  myUpDates?.UnApprovedAllowance?.map((x) => {
    myNewUpDateList.push({
      title: "UnApproved Allowance",
      data: x,
      link: RouteEnum.MyRequestPath,
      requestType: "Allowance",
      icon: AllowanceIcon,
    });
  });
  myUpDates?.UnApprovedTravel?.map((x) => {
    myNewUpDateList.push({
      title: "UnApproved Travel",
      data: x,
      link: RouteEnum.MyTravelRequestPath,
      requestType: "Travel",
      icon: TravelIcon,
    });
  });
  myUpDates?.ApprovedLeave?.map((x) => {
    myNewUpDateList.push({
      title: "Approved Leave",
      data: x,
      link: RouteEnum.MyRequestHistoryPath,
      requestType: "Leave",
      icon: LeaveIcon,
    });
  });
  myUpDates?.ApprovedTime?.map((x) => {
    myNewUpDateList.push({
      title: "Approved Time",
      data: x,
      link: RouteEnum.MyRequestHistoryPath,
      requestType: "Time",
      icon: TimeIcon,
    });
  });
  myUpDates?.ApprovedOvertime?.map((x) => {
    myNewUpDateList.push({
      title: "Approved Overtime",
      data: x,
      link: RouteEnum.MyRequestHistoryPath,
      requestType: "Overtime",
      icon: OvertimeIcon,
    });
  });
  myUpDates?.ApprovedAllowance?.map((x) => {
    myNewUpDateList.push({
      title: "Approved Allowance",
      data: x,
      link: RouteEnum.MyRequestHistoryPath,
      requestType: "Allowance",
      Icon: AllowanceIcon,
    });
  });
  myUpDates?.ApprovedTravel?.map((x) => {
    myNewUpDateList.push({
      title: "Approved Travel",
      data: x,
      link: RouteEnum.TravelHistoryPath,
      requestType: "Travel",
      Icon: TravelIcon,
    });
  });
  myUpDates?.SettlementRequest?.map((x) => {
    myNewUpDateList.push({
      title: "Settlement Request",
      data: x,
      link: RouteEnum.PendingSettlementPath,
      requestType: "Travel",
    });
  });
  myUpDates?.TravelRequest?.map((x) => {
    myNewUpDateList.push({
      title: "Travel Request",
      data: x,
      link: RouteEnum.MyTravelRequestPath,
      requestType: "Travel",
    });
  });

  return myNewUpDateList;
};
