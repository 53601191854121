import DataSelect from "components/dataselect";
import ExportExcel from "components/excel/exportExcel";
import FormGroup from "components/form";
import { ADMIN_ROLE_ID } from "constants/constants";
import { RoleId } from "constants/userDetails";
import isEmpty from "isEmpty";
import moment from "moment";
import React, { Component } from "react";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { IoIosArrowBack, IoMdAdd } from "react-icons/io";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import RouteEnum from 'constants/routeEnum'
import { GetOthersTravelRequests_ExcelExport } from "../common/travelInformation/actions";
import { GetSettlementRequest_ExcelExport } from "../common/travelSettlement/actions";
import "../travelRequest.scss";

class CommonHeaderSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeName: "",
      branches: [{ label: "one", value: 1 }],
      tabs: [
        { id: 0, name: "Travel Request", link: RouteEnum.TeamTravelRequestPath },
        { id: 3, name: "Settlement Requests", link: RouteEnum.SettlementRequestPath },
        { id: 4, name: "Pending Settlements", link: RouteEnum.PendingSettlementPath },
        { id: 1, name: "My Request", link: RouteEnum.MyTravelRequestPath },
        { id: 2, name: "History", link: RouteEnum.TravelHistoryPath },
      ],
      selectedBranch: null,
      selectedBranchId: -1,
      selectedTabId: !isEmpty(props.selectedTabId) ? props.selectedTabId : 0,

      isLoadingExport: false,
      travelRequest_ExcelColumns: [
        { label: "NAME", value: "name" },
        { label: "POSITION", value: "position" },
        { label: "PLACE OF TRAVEL", value: "placeOfTravel" },
        { label: "DATES", value: "dates" },
        { label: "STATUS", value: "status" },
        { label: "APPROVED BY", value: "approvedBy" },
      ],
      settlementRequest_ExcelColumns: [
        { label: "NAME", value: "name" },
        { label: "POSITION", value: "position" },
        { label: "PLACE OF TRAVEL", value: "placeOfTravel" },
        { label: "DATES", value: "dates" },
        { label: "RETURNED ON", value: "returnedOn" },
        { label: "ADVANCE", value: "advance" },
        { label: "CLAIM", value: "claim" },
        { label: "APPROVED BY", value: "approvedBy" },
      ],
      searchFlowSteps: [],
      searchFlowStepsString: "",
      fromDate: "",
      toDate: "",
    };
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.setDateRangeRef = this.setDateRangeRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    const { selectedTabId } = this.state;
    if (selectedTabId === 2) {
      var date = new Date();
      date.setDate(date.getDate() - 7);
      let range = {
        startDate: date,
        endDate: new Date(),
      };
      let startDate = moment(range.startDate, "YYYYMMDDHHmmss")
        .startOf("day")
        .format("YYYY/MM/DD HH:mm:ss");
      let endDate = moment(range.endDate, "YYYYMMDDHHmmss")
        .endOf("day")
        .format("YYYY/MM/DD HH:mm:ss");
      this.setState({ fromDate: startDate, toDate: endDate });
    }
  }
  setWrapperRef(node) {
    this.wrapperRef = node;
  }
  setDateRangeRef(node) {
    this.dateRangeRef = node;
  }
  handleDateClick = (event) => {
    if (
      this.wrapperRef.contains(event.target) &&
      !this.dateRangeRef.contains(event.target)
    )
      this.wrapperRef.classList.toggle("active");
  };
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.wrapperRef.classList.remove("active");
    }
  }
  handleText = (name,value) => {
    //const { name, value } = e && e.target;
    this.setState({ [name]: value });
    this.props.handleSearchTextChange &&
      this.props.handleSearchTextChange(value);
  };

  handleDropdown = (name, selected) => {
    let branchId = -1;
    if (!isEmpty(selected)) {
      branchId = selected.value;
    }
    this.setState({ [name]: selected, selectedBranchId: branchId });
    this.props.handleBranchChange && this.props.handleBranchChange(branchId);
  };

  handleSelectChange = (name, selected) => {
    let selectedFlowSteps = "";
    selected.map((x, i) => {
      if (i === 0) selectedFlowSteps = x.value;
      else selectedFlowSteps += "," + x.value;
    });

    this.setState({
      searchFlowSteps: selected,
      searchFlowStepsString: selectedFlowSteps,
    });
    this.props.handleFilterChange(name, selectedFlowSteps);
  };
  // handleTab = e => {
  //   const { id } = e.target;
  //   this.setState({ selectedTabId: parseInt(id) });
  // };

  handleExcelExport = async () => {
    //this.setState({ isLoadingExport: true });
    const { selectedTabId } = this.state;
    const searchFlowSteps = this.state.searchFlowStepsString;
    const searchEmployeeText = this.state.employeeName;
    const branchId = this.state.selectedBranchId;
    const flag = selectedTabId === 2 ? "history" : "";
    const fromDate = this.state.fromDate;
    const toDate = this.state.toDate;
    if (selectedTabId === 0) {
      //Travel Request
      await this.props.GetOthersTravelRequests_ExcelExport({
        searchEmployeeText,
        branchId,
        searchFlowSteps,
        fromDate,
        toDate,
      });
    } else if (selectedTabId === 3 || selectedTabId === 2) {
      //Settlement Request
      await this.props.GetSettlementRequest_ExcelExport({
        searchEmployeeText,
        branchId,
        searchFlowSteps,
        flag,
        fromDate,
        toDate,
      });
    }
    //this.setState({ isLoadingExport: false });
  };
  handleDateSelectForMultiple = (range) => {
    if (isEmpty(range.startDate) && isEmpty(range.endDate)) {
      this.props.handleDateRangeChange({ fromDate: null, toDate: null });
      this.setState({ fromDate: '', toDate: '' });
    } else {
      let startDate = moment(new Date(range.startDate), "YYYYMMDDHHmmss")
        .startOf("day")
        .format("YYYY/MM/DD HH:mm:ss");
      let endDate = moment(new Date(range.endDate), "YYYYMMDDHHmmss")
        .endOf("day")
        .format("YYYY/MM/DD HH:mm:ss");
      this.setState({ fromDate: startDate, toDate: endDate });
      this.props.handleDateRangeChange({ fromDate: startDate, toDate: endDate });
    }
  };

  render() {
    let {
      tabs,
      employeeName,
      branches,
      selectedBranch,
      selectedTabId,
      isLoadingExport,
      travelRequest_ExcelColumns,
      settlementRequest_ExcelColumns,
      searchFlowSteps,
      fromDate,
      toDate,
    } = this.state;
    const {
      otherTravelRequestExportExcel,
      settlementRequestExportExcel,
      flowSteps,
    } = this.props;

    const totalMyTravelRequests = this.props.travelListCount.MyTotalRows;
    const totalOtherTravelRequest = this.props.travelListCount.TotalRows;
    const totalSettlementRows = this.props.travelListCount.TotalSettlementRows;
    const totalPendingSettlementRows = this.props.travelListCount
      .PendingSettlementRows;
    const totalTravelHistoryRows = this.props.travelListCount
      .SettlementHistoryRows;
    //const { totalMyTravelRequests, totalOtherTravelRequest } = this.props;
    //const totalHistory = 0;
    let pathName = !isEmpty(this.props.pathName)
      ? this.props.pathName
      : "Travel Request";

    const roleId = RoleId();
    if (roleId === ADMIN_ROLE_ID) {
      //Admin
      tabs = tabs.filter((f) => f.id != 1 && f.id != 4);
    }

    let export_name = null;
    let export_columns = [];
    let export_data = null;

    //if (isLoadingExport) {
    if (selectedTabId === 0 && otherTravelRequestExportExcel) {
      export_name = "Travel Requests";
      export_columns = travelRequest_ExcelColumns;
      export_data = otherTravelRequestExportExcel;
    } else if (
      (selectedTabId === 3 || selectedTabId === 2) &&
      settlementRequestExportExcel
    ) {
      export_name =
        selectedTabId === 3 ? "Settlement Requests" : "Travel History";
      export_columns = settlementRequest_ExcelColumns;
      export_data = settlementRequestExportExcel;
    }
    //}

    return (
      <div className="travel-header">
        <div className="travel-route-title">
          {/* <button
            className="btn btn-back"
            onClick={() => this.props.history && this.props.history.goBack()}
          >
            <IoIosArrowBack />
          </button> */}
          <span
            className="module"
            onClick={() =>
              this.props.history &&
              this.props.history.push(RouteEnum.TeamTravelRequestPath)
            }
          >
            Travel Management
          </span>
          <AiOutlineDoubleRight className="right-arrow" />
          <span className="path-name">{pathName}</span>
        </div>
        <div className="travel-requestMainHeading">
          <div className="flex top-bar">
            <div className="travel-tabs">
              <ul className="travel-tabs__list">
                {tabs &&
                  tabs.map((data, index) => {
                    console.log({data,index})
                    return (
                      <NavLink
                        to={{
                          pathname: data.link,
                          state: { selectedTabId: data.id },
                        }}
                        activeClassName="selectedBorderBottom"
                        className={
                          "travel-tabs__item" 
                        }
                        key={index + Math.random()}
                      >
                        {data.name}
                        <span className="badge badge-primary">
                          {data.id == 0
                            ? totalOtherTravelRequest
                            : data.id == 1
                            ? totalMyTravelRequests
                            : data.id == 2
                            ? totalTravelHistoryRows
                            : data.id === 3
                            ? totalSettlementRows
                            : data.id === 4
                            ? totalPendingSettlementRows
                            : 0}
                        </span>
                      </NavLink>
                    );
                  })}
              </ul>
            </div>

            <div className="travel-header-calendar">
              <FormGroup
                formName="reactdaterange"
                startDate={!isEmpty(fromDate) ? fromDate : new Date()}
                endDate={!isEmpty(toDate) ? toDate : new Date()}
                onChange={(name, range) =>
                  this.handleDateSelectForMultiple(range)
                }
                placeholder="Select date"
              />
              {/* <div
                className="daterangepicker"
                tabIndex="0"
                ref={this.setWrapperRef}
                onClick={(event) => this.handleDateClick(event)}
              >
                <span className="daterangepicker__date">
                  {!isEmpty(fromDate && toDate) ? (
                    <>
                      <span>
                        <Moment format="D MMM YYYY" withTitle>
                          {fromDate}
                        </Moment>
                      </span>
                      -
                      <span>
                        <Moment format="D MMM YYYY" withTitle>
                          {toDate}
                        </Moment>
                      </span>
                    </>
                  ) : null}
                </span>
                <div
                  className="daterangepicker__range"
                  ref={this.setDateRangeRef}
                >
                  <DateRange
                    startDate={moment(Date())}
                    endDate={moment(Date())}
                    // onInit={range =>
                    //   this.handleDateSelectForMultiple(range, key)

                    // }
                    onChange={(range) =>
                      this.handleDateSelectForMultiple(range)
                    }
                  />
                </div>

                <span className="daterangepicker__icon">
                  <FaRegCalendarAlt />
                </span>
              </div> */}
              <div
                className="travel-month"
                onClick={() => {
                  let range = {
                    startDate: moment().startOf("month"),
                    endDate: moment().endOf("month"),
                  };
                  this.handleDateSelectForMultiple(range);
                }}
              >
                {moment(Date()).format("MMMM")}
              </div>
              <div
                className="travel-month"
                onClick={() => {
                  let range = {
                    startDate: moment().subtract(1, "month").startOf("month"),
                    endDate: moment().subtract(1, "month").endOf("month"),
                  };
                  this.handleDateSelectForMultiple(range);
                }}
              >
                {moment().subtract(1, "month").startOf("month").format("MMMM")}
              </div>
              {/* <div className="travel-month current">Mangsir</div> */}
            </div>

            <div>
              <NavLink
                to={{
                  pathname: RouteEnum.CreateTravelRequestPath,
                  state: { selectedTabId: -1 },
                }}
                // className={"btn btn-grey-2 btnTravelRequest"}
                activeClassName="active-btn"
                // className={selectedTabId == -1 ? " selectedBorderBottom" : ""}
              >
                {/* <button className={"btn  btnTravelRequest"}> */}
                <button
                  style={{ maxWidth: "250px" }}
                  className={"button button-primary with-icon"}
                >
                  <IoMdAdd />{" "}
                  <span>
                    {roleId == 1 ? "Assign request" : "New Travel Request"}
                  </span>
                </button>
              </NavLink>
            </div>
          </div>

          {this.props.showFilterSection && (
            <div
              className="flex filter-top filters-input"
              style={{ alignItems: "flex-end" }}
            >
              <FormGroup
                name="employeeName"
                className="search"
                placeholder="Search Employee"
                type="search"
                value={employeeName}
                onChange={this.handleText}
                width="250px"
              />
              <DataSelect
                onChange={this.handleDropdown}
                value={selectedBranch}
                // label="Branch"
                name="selectedBranch"
                // multiple
                placeholder="Filter branches"
                actionType="branch"
                width="200px"
              />
              <FormGroup
                formName="reactselect"
                name="searchFlowSteps"
                placeholder="Filter flow steps"
                value={searchFlowSteps}
                options={flowSteps}
                onChange={this.handleSelectChange}
                width={250}
                multiple={true}
              />
              {(selectedTabId === 0 ||
                selectedTabId === 3 ||
                selectedTabId === 2) && (
                <ExportExcel
                  onClick={this.handleExcelExport}
                  fileName={export_name}
                  columns={export_columns}
                  sheets={[{ sheetName: export_name, data: export_data }]}
                  className="button button-subtle"
                />
                // <button
                //   disabled={isLoadingExport}
                //   onClick={() => this.handleExcelExport()}
                //    style={{ height: 35, padding: 10, color: "#fff" }}
                //   className={
                //     isLoadingExport
                //       ? "button button-disabled with-icon"
                //       : "button button-primary with-icon"
                //   }
                // >
                //   <AiOutlineFileExcel />
                //   {isLoadingExport ? "Waiting.." : "Export Excel"}
                // </button>
              )}
              {/* {export_data && (
                <ExportExcel
                  name={export_name}
                  columns={export_columns}
                  data={export_data}
                />
              )} */}
            </div>
          )}
        </div>

        <div className="travel-requestMainBody">{this.props.renderBody}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  totalOtherTravelRequest: state.travelInformationReducer.totalTravelRequests,
  totalMyTravelRequests: state.travelInformationReducer.totalMyTravelRequests,
  travelListCount: state.travelInformationReducer.travelListCount,
  otherTravelRequestExportExcel:
    state.travelInformationReducer.otherTravelRequestExportExcel,
  settlementRequestExportExcel:
    state.travelSettlementReducer.settlementRequestExportExcel,
  flowSteps: state.travelInformationReducer.flowSteps,
});

const mapDispatchToProps = {
  GetOthersTravelRequests_ExcelExport,
  GetSettlementRequest_ExcelExport,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CommonHeaderSection)
);
