import React, {useEffect, useState} from "react";
import ApprovalTable from "./approvalSetting/index";
import FormGroup from "components/form";
import {getSbiSettingApprovalFlow} from "../helpers/action";
import {useDispatch, useSelector} from "react-redux";
import {ApprovalProcessDropdownItem} from "../helpers/enums";
import isEmpty from "isEmpty";

const ApproveFlowSettingTable = (param) => {
  const {loanType, flowType} = param;

  const [selectedJobType, setSelectedJobType]: any = useState();
  const [processFlow, setProcessFlow] = useState();
  const [approvalFlowData, setApprovalFlowData] = useState([]);

  const dispatch = useDispatch();
  const {sbiSettingApprovalFlow} = useSelector(
    (state: any) => state.sbiLoanSettingReducer
  );

  const handleChange = (name: string, data: any): void => {
    setProcessFlow(data?.value);
    setSelectedJobType(data);
    dispatch(getSbiSettingApprovalFlow(loanType, data?.value));
  };

  useEffect(() => {
    if (!isEmpty(sbiSettingApprovalFlow)) {
      setApprovalFlowData(sbiSettingApprovalFlow);
    }
  }, [sbiSettingApprovalFlow]);

  useEffect(() => {
    setSelectedJobType(ApprovalProcessDropdownItem[flowType - 1]);
    setProcessFlow(flowType);
    dispatch(getSbiSettingApprovalFlow(loanType, flowType));
  }, []);

  return (
    <>
      <hr className="seperate" />
      <h2 className="personal-loan-block-heading">Approval Flow Settings</h2>
      <div style={{position: "relative", zIndex: 2}}>
        <FormGroup
          formName="reactselect"
          onChange={handleChange}
          value={selectedJobType}
          options={ApprovalProcessDropdownItem}
          name="selectedJobType"
          width="300px"
          label="Loan Request"
          placeholder="Select"
        />
      </div>

      <ApprovalTable
        approvalFlowData={approvalFlowData}
        loanType={loanType}
        flowType={processFlow}
      />
    </>
  );
};

export default ApproveFlowSettingTable;
