import React from "react";
import { JobDetailsInterface } from './model'

interface props {
    jobStageDetail: JobDetailsInterface
}

const HiringInformation = (props: props) => {
    const percentageCalculation = (upperValue: number, lowerValue: number) => {
        let percentage: any = ((upperValue / lowerValue) * 100).toFixed(2);
        return isNaN(percentage) ? 0 : percentage;
    };
    const { jobStageDetail } = props
    let hiringInformations = Object.entries(jobStageDetail);
    const { ShortListed, Interview, Applicants } = jobStageDetail;
    let information = hiringInformations.map((values, index: number) => {
        return (
            <div key={index} className="recruitment-hiring-stage">
                <span>{values[0]}</span>

                <span className="center-span">{values[1]}</span>
                {values[0] === "ShortListed" ? (
                    <span className={values[0]}>
                        {percentageCalculation(ShortListed, Applicants) + "%"}
                    </span>
                ) : values[0] === "Interview" ? (
                    <span className={values[0]}>
                        {percentageCalculation(Interview, ShortListed) + "%"}
                    </span>
                ) : (
                    <span className="hiringRight"></span>
                )}
            </div>
        );
    });

    return <>{information}</>
};

export default HiringInformation;