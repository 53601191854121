import { objectToFormData } from "object-to-formdata";
import isEmpty from "isEmpty";
import { api, post } from "../../../networkService";

export const UploadFiles = (formData: any) => async (dispatch: Function) => {
  // const formData = objectToFormData({});
  // formData.append(`files`, file);

  const res: any = await post(
    api.common.uploadFiles,
    dispatch,
    formData,
    false,
    true
  );

  return res.Data;
};

export const DeleteFiles = (fileInfos: any) => async (dispatch: Function) => {
  // const res: any = await deletion_with_param(
  //   api.common.uploadFiles,
  //   dispatch,
  //   fileInfos,
  //   true
  // );
};
