import React, {useEffect} from 'react'
import FormGroup from "components/form";
import Button from "components/button";
import Icons from "components/icons";
import { GetBranches, GetDepartments } from "actions/commonAction";
import isEmpty from 'isEmpty';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import DatePickerAsPerSetting from "components/customdaterange/datepickerAsPerSetting";
import {
    GetAllowanceRequestType,
  } from "scenes/employee/allowance/action";

function Filters(props) {
    let {branchList,departmentList} = useSelector((state:any)=>state.commonReducer);
    let allowanceTypes= useSelector((state:any)=>state.employeeAllowanceReducer.employeeAllowanceTypeList);
    
    const dispatch = useDispatch();

    useEffect(()=>{
        if(isEmpty(branchList)){
            dispatch(GetBranches())
        }
        if(isEmpty(departmentList)){
            dispatch(GetDepartments())
        }
        if(isEmpty(allowanceTypes)){
            dispatch(GetAllowanceRequestType());
        }
    },[]);
    return (
        <div className="filters mt-sm mb-sm">
            <div className="filters-grid" style={{flexWrap:'wrap'}}>
                <FormGroup
                    name="employeeName"
                    className="search"
                    placeholder="Search Employee"
                    type="search"
                    value={props.employeeName}
                    onChange={props.handleAutoSelect}
                    // width="250px"
                />
            </div>
            <div className="filters-grid">
                <FormGroup
                    formName="reactselect"
                    onChange={props.handleAutoSelect}
                    name="allowanceType"
                    value={props.allowanceType}
                    options={allowanceTypes}
                    label="Allowance type"
                    // width="200px"
                />
            </div>
            <div className="filters-grid">
                <FormGroup
                    formName="reactselect"
                    onChange={props.handleAutoSelect}
                    value={props.branches}
                    name="branch"
                    options={branchList}
                    label="Branch"
                    // width="200px"
                />
            </div>
            <div className="filters-grid">
                <FormGroup
                    formName="reactselect"
                    onChange={props.handleAutoSelect}
                    value={props.departments}
                    options={departmentList}
                    name="department"
                    label="Department"
                    // width="200px"
                />
            </div>
            <div className="filters-grid">
            <DatePickerAsPerSetting
                onChange={(name, range) => props.handleAutoSelect(name, range)}
                value={{
                    startDate: !isEmpty(props.startDate) ? props.startDate : "",
                    endDate: !isEmpty(props.endDate) ? props.endDate : "",
                }}
                placeholder="Date Range"
                width="250px"
                name="dateRange"
                isDateRange
            />
            </div>
        </div>
    )
}

export default Filters
