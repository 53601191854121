import React, { Component } from "react";
import "./style.scss";
import { connect } from "react-redux";
import Title from "../../common/title";
import ListingTabs from "./listingTabs";
import EmployeeTable from "./employeeTable";
import { GetEmployeeListForAdmin } from "./action";
import {GetBranches,GetDepartments,GetDesignations,GetJobStatus} from "actions/commonAction";
import EmployeeTableAg from './employeeTableAg';
import moment from 'moment';
import isEmpty from 'isEmpty'

const getDate = (name) =>{
  var date = new Date();
  var firstDay = null;
  var lastDay= null;
  const label = !isEmpty(name) ? name.label : '';
  if(label === "This Month"){
    firstDay = moment(date).startOf('month').format('YYYY/MM/DD');
    lastDay =  moment(date).endOf('month').format('YYYY/MM/DD');
  }
  if(label === "Last Month"){
    firstDay = moment(date).subtract(1,'months').startOf('month').format('YYYY/MM/DD');
    lastDay =  moment(date).subtract(1,'months').endOf('month').format('YYYY/MM/DD');
  }
  if(label === "This Year"){
    firstDay = moment().startOf('year').format('YYYY/MM/DD');
    lastDay =  moment().endOf('year').format('YYYY/MM/DD');
  }
  return {firstDay,lastDay}
}

export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      pageIndex: 0,
      rowsPerPage: 25,
      searchValue: "",
      activeBranch: null,
      activeDepartment: null,
      activePosition: null,
      activeStatus: null,      
      gender: null,      
      maritalStatus: null,      
      newJoinees: null,      
      state: { label: "Working Only", value: false },      
      retired: null,      
    };
  }

  componentDidMount() {
    this.initialize();
    this.props.GetBranches();
    this.props.GetDepartments();
    this.props.GetDesignations();
    this.props.GetJobStatus();
  }

  initialize = async () => {
    this.setState({ isLoading: true });

    const {
      pageIndex,
      rowsPerPage,
      searchValue,
      activeBranch,
      activeDepartment,
      activePosition,
      activeStatus,
      gender,    
      maritalStatus,    
      newJoinees,    
      state,    
      retired,
    } = this.state;
    const {firstDay:fromDate ,lastDay:endDate } = getDate(newJoinees);
    const {firstDay:retirementStartDate ,lastDay:retirementEndDate } = getDate(retired);

    await this.props.GetEmployeeListForAdmin({
      pageIndex,
      rowsPerPage,
      searchValue,
      gender,
      maritalStatus,
      activeBranch,
      state,
      fromDate,
      endDate,
      retirementStartDate,
      retirementEndDate,
      activeDepartment,
      activePosition,
      activeStatus
    });

    this.setState({ isLoading: false });
  };

  handleInput = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value, pageIndex: 0 }, () => {
      this.initialize();
    });
  };

  handleAutoSelect = (name, selected) => {
    this.setState({ [name]: selected, pageIndex: 0 }, () => {
      this.initialize();
    });
  };

  handleRowChange = num => {
    this.setState({ rowsPerPage: num }, () => {
      this.initialize();
    });
  };

  handleIndexChange = num => {
    this.setState({ pageIndex: num }, () => {
      this.initialize();
    });
  };

  render() {
    const { employeeListForAdmin,branches,searchValue,departments,designations,statusList, history } = this.props;
    return (
      <div className="common-container">
        <Title name="Employees" />

        <ListingTabs
          parentState={this.state}
          branches={branches}
          searchValue={searchValue}
          departments={departments}
          designations={designations}
          statusList={statusList}
          handleAutoSelect={this.handleAutoSelect}
          handleInput={this.handleInput}
        />

        {/* <EmployeeTable
          data={employeeListForAdmin}
          pageIndex={this.state.pageIndex}
          rowsPerPage={this.state.rowsPerPage}
          handleRowChange={this.handleRowChange}
          handleIndexChange={this.handleIndexChange}
          history={history}
        /> */}
        <EmployeeTableAg 
          data={employeeListForAdmin}
          pageIndex={this.state.pageIndex}
          rowsPerPage={this.state.rowsPerPage}
          handleRowChange={this.handleRowChange}
          handleIndexChange={this.handleIndexChange}
          history={history}
          />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  employeeListForAdmin: state.admin_EmployeeListReducer.employeeListForAdmin,
  branches:state.commonReducer.branchList,
  departments:state.commonReducer.departmentList,
  designations:state.commonReducer.designationList,
  statusList:state.commonReducer.jobStatusList
});

const mapDispatchToProps = {
  GetEmployeeListForAdmin,
  GetBranches,
  GetDepartments,
  GetDesignations,
  GetJobStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
