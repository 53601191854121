import React from 'react'
import CommonModuleLinks from 'components/common/commonModuleLinks'

const modules = (features) => [
    {
        groupTitle:'Settings',
        feature:features?.CoreHR,
        links: [
            { title:'Approval Flow', link:'newhr/ApprovalFlow.aspx'},
            { title:'Overtime Types', link:'CP/OvertimeType.aspx'},
            { title:'Allowance Request Types', link:'CP/AllowanceType.aspx'},
            { title:'Travel Allowances', link:'CP/AllowanceSettings.aspx'},
            { title:'Travel Allowance Location', link:'CP/AllowanceLocation.aspx'},
        ]
    },
]

function Settings(props) {
    return (
        <div>
            <div className="admin-contain">
                {/* <Title name="HR Common Task" parentProps={props} /> */}
                <CommonModuleLinks baseUrl={props.baseUrl} title="Human Resource Settings" localStorageTitle="HRST" modules={modules} />
            </div>
        </div>
    )
}

export default Settings
