import React, {useState, useEffect} from "react";
import {OneDatePicker} from "../../../common/pickers";
import {GetDashboardInfoJobs} from "store/actions/recruitment/dashboard";
import {useDispatch, useSelector} from "react-redux";
import JobDetails from "./jobDetails";
import isEmpty from "isEmpty";

const JobList = ({jobType, setSelectedJob}) => {
  const [searchJobParam, setSearchJobParam] = useState({
    pageIndex: 0,
    pageSize: 10,
    jobTitle: "",
    jobStartDate: "",
    jobType: "",
  });
  const dispatch = useDispatch();
  const [jobs, setJobs] = useState([]);
  const defaultPageSize = 20;
  useEffect(() => {
    setSearchJobParam({
      pageIndex: 0,
      pageSize: defaultPageSize,
      jobTitle: "",
      jobStartDate: "",
      jobType: jobType,
    });
  }, [jobType]);

  useEffect(() => {
    getJobs(jobType);
  }, [searchJobParam]);

  const getJobs = async (jobType) => {
    const {pageIndex, pageSize, jobTitle, jobStartDate} = searchJobParam;
    let month = null;
    let year = null;
    if (jobStartDate) {
      month = (jobStartDate as any).getMonth() + 1;
      year = (jobStartDate as any).getFullYear();
    }
    let res: any = await dispatch(
      GetDashboardInfoJobs({
        pageIndex,
        pageSize,
        jobTitle,
        jobStartDate,
        jobType,
        month,
        year,
      })
    );
    setJobs(res);
    setSelectedJob && setSelectedJob(null);
  };
  const handleJobSearchParamChange = (name, value) => {
    let nextParam = {...searchJobParam};
    nextParam[name] = value;
    nextParam.pageSize = defaultPageSize;
    nextParam.pageIndex = 0;
    setSearchJobParam(nextParam);
  };
  const handleRowChange = (size) => {
    let nextParam = {...searchJobParam};
    nextParam.pageSize = size;
    nextParam.pageIndex = 0;
    setSearchJobParam(nextParam);
  };
  const handleIndexChange = (page) => {
    let nextParam = {...searchJobParam};
    nextParam.pageIndex = page;
    setSearchJobParam(nextParam);
  };
  const handleJobSelected = (job) => {
    setSelectedJob && setSelectedJob(job);
  };

  return (
    <div className="recruitment__dashboard-item item6">
      <div className="search-jobtitle">
        <div className="jobfiltertitle">
          <div className="genericForm-group__search  ">
            <input
              type="text"
              name="jobTitle"
              value={searchJobParam.jobTitle}
              className="genericForm-group__search-input"
              placeholder="search job by id or title"
              onChange={(e) => {
                const {name, value} = e.target;
                handleJobSearchParamChange(name, value);
              }}
            />
          </div>
        </div>
        <OneDatePicker
          format="yyyy/MM"
          views={["year", "month"]}
          placeholder="search by month and year"
          maxDetail="year"
          value={searchJobParam.jobStartDate}
          onChange={(date) => {
            handleJobSearchParamChange("jobStartDate", date);
          }}
        />
      </div>
      <div className="joblistwrapper">
        <JobDetails
          jobs={jobs}
          pageIndex={searchJobParam.pageIndex}
          rowsPerPage={searchJobParam.pageSize}
          handleRowChange={handleRowChange}
          handleIndexChange={handleIndexChange}
          onJobSelected={handleJobSelected}
        />
      </div>
    </div>
  );
};

export default JobList;
