import React, { useState, useEffect, useRef } from 'react'
import FormGroup from 'components/form';
import Popup from 'components/popup';
import ReactDatePicker from "react-date-picker";
import CommonEmployeeSearch from "components/common/employeeSearch";
import {
    setManagerForEmployee 
} from '../../action';
import moment from "moment";
import { connect } from 'react-redux';
import { IconName } from "react-icons/ti";
const ManagerTypes = ["Manager", "MatrixManager"];

class Active extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // SrNo1: 0,
            // SrNo2: 0,
            // SrNo3: 0,
            // effectiveDate1: new Date(),
            // effectiveDate2: new Date(),
            effectiveDate3: new Date(),
            personData: null
        }
    }
    componentDidMount = async () => {
        await this.props.getEmployeeManagers(this.props.employeeId);
        let { getEmployeeManagersForJob } = this.props;
        if (getEmployeeManagersForJob && getEmployeeManagersForJob.length > 0){
            getEmployeeManagersForJob.forEach(m => {
                if (m.ReportingToManager === 1 && m.SrNo > 0)
                    this.setState({
                        ...this.state,
                        SrNo1: m.SrNo,
                        effectiveDate1: m.EffectiveDateEng
                    });
                else if (m.ReportingToManager === 2 && m.SrNo > 0)
                    this.setState({
                        ...this.state,
                        SrNo2: m.SrNo,
                        effectiveDate2: m.EffectiveDateEng
                    });
                else if (m.ReportingToManager === 3 && m.SrNo > 0) {
                    this.setState({
                        ...this.state,
                        SrNo3: m.SrNo,
                        personData: {
                            label: m.ManagerialPersonName,
                            value: m.ManagerialPerson,
                            effectiveDate3: m.EffectiveDateEng
                        }
                    });
                } else {
                    this.setState({
                        SrNo3: 0,
                        effectiveDate1: new Date(),
                        effectiveDate2: new Date(),
                        personData: {
                            effectiveDate3: new Date()
                        }
                    });
                }
            });
        } else {
            this.setState({
                SrNo3: 0,
                SrNo1: 0,
                SrNo2: 0,
                effectiveDate3: new Date(),
            });
        }

    }

    handleRepotingStatus = async (data, onClose) => {
        let { SrNo1, SrNo2, SrNo3 } = this.state;
        if (data.reportingToManager === 1) data.srNo = SrNo1;
        else if (data.reportingToManager === 2) data.srNo = SrNo2;
        else data.srNo = SrNo3;
        data.employeeRefId = this.props.employeeId;
        
        await this.props.setManagerForEmployee([data], onClose);
        let { savedReportingManagerForEmployee } = this.props
        if (savedReportingManagerForEmployee) {
            let { SrNo } = savedReportingManagerForEmployee;
            if (data.reportingToManager === 1) this.setState({ SrNo1: SrNo });
            else if (data.reportingToManager === 2) this.setState({ SrNo2: SrNo });
            else this.setState({ SrNo3: SrNo });
        }
    }
    render() {
        const { personData, effectiveDate1, effectiveDate2, SrNo1, SrNo2, SrNo3 } = this.state;
        return (
            <div className="reporting-active">
                {effectiveDate1 && <Manager title={ManagerTypes[0]} {...this.props} effectiveDate={effectiveDate1} handleSubmit={this.handleRepotingStatus} />}
                {effectiveDate2 && <Manager title={ManagerTypes[1]}  {...this.props} effectiveDate={effectiveDate1} handleSubmit={this.handleRepotingStatus}/>}
                {SrNo3 >= 0 && <Person {...this.props} personData={personData} handleSubmit={this.handleRepotingStatus}/>}
            </div>
        )
    }
}

export class Person extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popup: false,
            employee: null,
            effectiveDate: new Date(),

        }
    this.selectLabel = React.createRef();;

    }

    componentDidMount = () => {
        const { personData } = this.props;
        if (personData) {
            this.setState({
                effectiveDate: new Date(personData.effectiveDate3),
                employee: {
                    ...personData
                }
            });
        }
    }

    onClose = () =>{
        this.setState({
            popup: false
        })
    }
    handleAutoSelect = (event, value) => {
        this.setState({ employee: value });
    };

    handleRepotingStatus = (setClose) => {
        const { effectiveDate, employee } = this.state;
        let finalDate = new Date(new Date(effectiveDate).setHours(0, 0, 0, 0));
        let data = {
            reportingToManager: 3,
            managerialType: 2,
            managerialPosition: null ,
            managerialPerson: employee.value,
            effectiveDateEng: new Date(new Date(finalDate).setDate(new Date(finalDate).getDate() + 1)),
        };
        this.props.handleSubmit(data, setClose);
    }

    onChangeDate = (date) => {
        this.setState({
            effectiveDate: new Date(date)
        });
    }


    
    render() {
        const { employee, popup, effectiveDate } = this.state;
        let personLabel = employee && employee.label ? employee.label : 'Select a Person';
        return(
            <div className="reporting-active__row">
                <div className="reporting-active__column">
                    <span className="reporting-active__title">
                        Custom Manager (specific purpose)
                    </span>
                </div>
                <div className="reporting-active__column">
                <div className="reporting-position">
                        <div className="reporting-position-top">
                            <div ref={this.selectLabel} className="reporting-position__select">
                                <a 
                                    onClick={(e)=>{
                                        this.setState({ popup: true })
                                    }}
                                >
                                    <span>{personLabel}</span>
                                </a>
                                <Popup 
                                    onClose={this.onClose}
                                    open={popup} 
                                    parentPop = {this.selectLabel}
                                    onDone={(setClose) => this.handleRepotingStatus(setClose)}
                                    disableClickOutside={true}

                                >
                                    <div className="reporting-input">
                                        <CommonEmployeeSearch
                                            onChange={this.handleAutoSelect}
                                            value={employee}
                                            name={`Name`}
                                            width="220px"
                                            label="Select team member"
                                        />
                                    </div>
                                    <div className="create-content assign-job__row">
                                        <label>Effecting from</label>
                                        <div>
                                            <ReactDatePicker
                                                onChange={(date) => this.onChangeDate(date)}
                                                value={effectiveDate}
                                            />
                                        </div>
                                    </div>
                                </Popup>
                            </div>
                        </div>
                        <div className="reporting-position-bottom">
                            <div className="reporting-position__from pt-sm">
                                <span className="from">appliable from</span>
                                <span className="from-date">{moment(effectiveDate).format("MMMM D, YYYY")}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="reporting-active__column"></div>
            </div>
        )
    }
}   

export const Manager = ({ title, designationList, getEmployeeManagersForJob, handleSubmit }) =>{
    const [position, setPosition] = useState('');
    const [person, setPerson] = useState('');
    const [popup, setPopup] = useState(false);
    const [personpopup, setPersonpopup] = useState(false);
    const [effectiveDateM, setEffectiveDateM] = useState(new Date);
    const [effectiveDateMx, setEffectiveDateMx] = useState(new Date);
    const [employeeM, setEmployeeM] = useState(null);
    const [employeeMx, setEmployeeMx] = useState(null);
    const [selectedPositionM, setSelectedPositionM] = useState(null)
    const [selectedPositionMx, setSelectedPositionMx] = useState(null);

    useEffect( ()=>{
        if (title === ManagerTypes[0]) {
            if(getEmployeeManagersForJob && getEmployeeManagersForJob.length > 0) {
                getEmployeeManagersForJob.forEach(m => {
                    if(m.ReportingToManager === 1 && m.SrNo > 0) {
                        if (m.ManagerialType === 1) {
                            setPosition('position1');
                            setPerson(null)
                            setSelectedPositionM({
                                label: m.ManagerialPositionName,
                                value: m.ManagerialPosition
                            });
                            setEmployeeM(null);
                            setEffectiveDateM(new Date(m.EffectiveDateEng))
                        } else if (m.ManagerialType === 2) {
                            setPosition(null)
                            setPerson('person1');
                            setSelectedPositionM(null);
                            setEmployeeM({
                                label: m.ManagerialPersonName,
                                value: m.ManagerialPerson,
                            });
                            setEffectiveDateM(new Date(m.EffectiveDateEng))
                            setPersonpopup(false);
                        }
                    }
                })
            }
        } else if (title === ManagerTypes[1]) {
            if(getEmployeeManagersForJob && getEmployeeManagersForJob.length > 0) {
                getEmployeeManagersForJob.forEach(m => {
                    if(m.ReportingToManager === 2 && m.SrNo > 0) {
                        if (m.ManagerialType === 1) {
                            setPerson(null)
                            setPosition('position2');
                            setSelectedPositionMx({
                                label: m.ManagerialPositionName,
                                value: m.ManagerialPosition
                            });
                            setEmployeeMx(null);
                            setEffectiveDateMx(new Date(m.EffectiveDateEng))
                        } else if (m.ManagerialType === 2) {
                            setPerson('person2');
                            setPosition(null)
                            setSelectedPositionMx(null);
                            setEmployeeMx({
                                label: m.ManagerialPersonName,
                                value: m.ManagerialPerson,
                                img: '',
                                
                            });
                            setPersonpopup(false);
                            setEffectiveDateMx(new Date(m.EffectiveDateEng))
                        }
                    }
                })
            }
        }
    }, [getEmployeeManagersForJob]);


    const onChange = (e) =>{
        const {name,value} = e.target;
        if (name === 'position1'){
            setEmployeeM(null)
            setPersonpopup(true)
            setPosition(value)
            setPerson(null)

        } else if (name === 'person1') {
            setSelectedPositionM(null)
            setPopup(true)
            setPosition(null)
            setPerson(value)
        }
        if (name === 'position2'){
            setEmployeeMx(null)
            setPersonpopup(true)
            setPosition(value)
            setPerson(null)
        } else if (name === 'person2') {
            setSelectedPositionMx(null)
            setPopup(true)
            setPosition(null)
            setPerson(value)
        }
    }

    const onClose = () =>{
        setPopup(false)
    }

    const onPersonClose = () =>{
        setPersonpopup(false)
    }

    const onChangeDate = (date) => {
        if (title === ManagerTypes[0])
            setEffectiveDateM(date)
        else if (title === ManagerTypes[1])
            setEffectiveDateMx(date)
    }

    const handleAutoSelect = (event, value) => {
        if (title === ManagerTypes[0])
            setEmployeeM(value);
        else if (title === ManagerTypes[1])
            setEmployeeMx(value);
    };

    const handleRepotingStatus = (setClose) => {
        if (position === 'position1' && !selectedPositionM 
        || position === 'position2' && !selectedPositionMx
        || person === 'person1' && !employeeM 
        || person ==='person2' && !employeeMx)
            return;
        let finalDate = title === ManagerTypes[0] ?  new Date(new Date(effectiveDateM).setHours(0, 0, 0, 0)) 
                        : new Date(new Date(effectiveDateMx).setHours(0, 0, 0, 0)) ;
    
        let data = {
            effectiveDateEng: title === ManagerTypes[0] ? new Date(new Date(finalDate).setDate(new Date(finalDate).getDate() + 1)) 
            : new Date(new Date(finalDate).setDate(new Date(finalDate).getDate() + 1)),
            managerialPosition: (position === 'position1' || position === 'position2') ? 
                (title === ManagerTypes[0] ? parseInt(selectedPositionM.value) : parseInt(selectedPositionMx.value)) : null ,
            managerialPerson: (person === 'person1' || person === 'person2') ? 
                (title === ManagerTypes[0] ? parseInt(employeeM.value) : parseInt(employeeMx.value)) : null,
            managerialType: position === 'position1' || position === 'position2' ? 1 : 2,
            reportingToManager: title === ManagerTypes[0] ? 1 : 2, 
        };

        handleSubmit(data, setClose);

    }

    const onPositionChange = (e, value) => {
        if (title === ManagerTypes[0])
            setSelectedPositionM(value);
        if (title === ManagerTypes[1])
            setSelectedPositionMx(value);
    }

    const selectLabel = useRef(null);
    const selectLabel1 = useRef(null);

    let positionLabel = (selectedPositionM ||  selectedPositionMx) && title === ManagerTypes[0] ? selectedPositionM.label :
        (selectedPositionM ||  selectedPositionMx) &&  title === ManagerTypes[1] ? selectedPositionMx.label  : 'Select a position';
    
    return(
        <div className="reporting-active__row">
            <div className="reporting-active__column">
                <span className="reporting-active__title">
                    {title}
                </span>
            </div>
            <div className="reporting-active__column">
                <div className="reporting-position">
                    <div className="reporting-position-top">
                        <div className="reporting-position__radio">
                            <FormGroup
                                formName="radiogroup" 
                                onChange={(e) => onChange(e)}
                                value={title === ManagerTypes[0] ? "position1": "position2"}
                                id={title === ManagerTypes[0] ? "position1": "position2"}
                                name={title === ManagerTypes[0] ? "position1": "position2"}
                                checked={title === ManagerTypes[0] ? position === "position1" : position === "position2"}
                                label="Position"
                                labelPosition="right"
                            />
                        </div>
                        <div ref={title === ManagerTypes[0] ?  selectLabel : selectLabel1} className="reporting-position__select" position="relative">
                            <a 
                                onClick={(e)=>{
                                    e.stopPropagation()
                                    setPersonpopup(true);
                                    setPerson(null)
                                    if (title === ManagerTypes[0]) {
                                        setPosition('position1')
                                        setEmployeeM(null)
                                    } else {
                                        setPosition('position2')
                                        setEmployeeMx(null)
                                    }
                                }}
                            >
                                <span>{positionLabel}</span>
                            </a>
                            <Popup 
                                onClose={onClose}
                                open={personpopup /* && title === ManagerTypes[0] ? position === "position1" : position === "position2" */} 
                                parentPop = {title === ManagerTypes[0] ?  selectLabel : selectLabel1}
                                onDone={(setClose) => handleRepotingStatus(setClose)}
                                disableClickOutside={true}

                            >
                                <div className="reporting-input">
                                    <FormGroup
                                        formName="reactselect" 
                                        label="Position"
                                        onChange={(e,value) => onPositionChange(e, value)}
                                        value={title === ManagerTypes[0] ? selectedPositionM : selectedPositionMx}
                                        options={designationList || []}
                                        width="100%"
                                        name="position"
                                    />
                                </div>
                                <div className="create-content assign-job__row">
                                    <label>Assign the JD Effective from  </label>
                                    <div>
                                    <ReactDatePicker
                                        onChange={(date) => onChangeDate(date)}
                                        value={title === ManagerTypes[0] ? effectiveDateM : effectiveDateMx}
                                        //   format={format}
                                    />
                                    </div>
                                </div>
                            </Popup>
                        </div>
                    </div>
                    <div className="reporting-position-bottom">
                        <div className="reporting-position__from ml-md">
                            <span className="from">appliable from</span>
                            <span className="from-date">{moment(title === ManagerTypes[0] ? effectiveDateM : effectiveDateMx).format("MMMM D, YYYY")}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="reporting-active__column">
                <div ref={selectLabel1} className="reporting-position__select" position="relative">
                    <a 
                        onClick={(e)=>{
                            e.stopPropagation()
                            setPopup(true);
                            setPosition(null)
                            if (title === ManagerTypes[0]) {
                                setPerson('person1')
                                setSelectedPositionM(null)

                            } else {
                                setPerson('person2')
                                setSelectedPositionMx(null)
                            }
                        }}
                    >
                        <FormGroup
                            formName="radiogroup" 
                            onChange={(e) => onChange(e)}
                            value={title === ManagerTypes[0] ? "person1" : "person2"}
                            id={title === ManagerTypes[0] ? "person1" : "person2"}
                            name={title === ManagerTypes[0] ? "person1" : "person2"}
                            checked={title === ManagerTypes[0] ? person === "person1" : person === "person2"}
                            label="Person"
                            labelPosition="right"
                        />
                        <span>{title === ManagerTypes[0] ? (employeeM && employeeM.label) : (employeeMx && employeeMx.label)}</span>
                    </a>

                    <Popup 
                        onClose={onPersonClose}
                        open={popup /* && title === ManagerTypes[0] ? person === "person1" : person === "person2" */} 
                        parentPop = {selectLabel1}
                        onDone={(setClose) => handleRepotingStatus(setClose)}
                        disableClickOutside={true}

                    >
                        <div className="reporting-input">
                            <CommonEmployeeSearch
                                onChange={handleAutoSelect}
                                value={(title === ManagerTypes[0] ? employeeM : employeeMx) || ''}
                                name={`Name`}
                                width="220px"
                                label="Select team member"
                                />
                        </div>
                        <div className="create-content assign-job__row">
                            <label>Assign the JD Effective from  </label>
                            <div>
                                <ReactDatePicker
                                    onChange={(date) => onChangeDate(date)}
                                    value={title === ManagerTypes[0] ? effectiveDateM : effectiveDateMx}
                                />
                            </div>
                        </div>
                    </Popup>
                </div>
                
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    designationList: state.commonReducer.designationList,
    getEmployeeManagersForJob: state.employeeProfile.getEmployeeManagersForJob,
    savedReportingManagerForEmployee: state.employeeProfile.savedReportingManagerForEmployee,
});

const mapDispatchToProps = {
    setManagerForEmployee
};

export default connect(mapStateToProps, mapDispatchToProps)(Active)
