import Form from "components/form";
import { ValidationComponent } from "helpers/getComponents";
import isEmpty from "isEmpty";
import React from "react";
import { connect } from "react-redux";
const loanReqCategory = [
  "LoanPurchaseCost",
  "RegistrationFee",
  "TotalConstructionCost",
  "DrawingApprovalCost",
  "ContengencyCost",
  "OtherCost"
]

function LoanRequirement({ data, onChange }) {

  const currencyCommaSeperator = (num) => {
    if (num == "" || num == undefined) return "";
    else {
        num = num.toString().replace(/,/g, "");
        return parseInt(num).toLocaleString();
        // .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };
  const calculateLoanReqTotal = () => {
    let total = 0;
    loanReqCategory.forEach(loan => {
      let loanAmount = !isEmpty(data[loan]) ? data[loan].toString().replace(/,/g, "") : 0;
      total += (!isNaN(loanAmount) ? parseInt(loanAmount) : 0);
    });
    // onChange('TotalTotalConstructionCost', total);
    return total;
  }

  return (
    <>
      <div className="homeloan-propertydetail homeloan-propertydetail-loan align-center">
        <div className="form-row">
          <span className="form-label">Land Purchase Cost</span>
          <div className="input-grp">
            <Form
              name="LoanPurchaseCost"
              value={currencyCommaSeperator(data.LoanPurchaseCost)}
              onChange={onChange}
              // type="number"
              //   onKeyUp={}
              // label={"Land Purchase Cost"}
              alignment={"horizontal"}
              width={"80%"}
            />
          </div>
        </div>
        <div className="form-row">
          <span className="form-label">Registration Fees</span>
          <div className="input-grp">
            <Form
              name="RegistrationFee"
              value={currencyCommaSeperator(data.RegistrationFee)}
              onChange={onChange}
              // type="number"
              // label={"Registration Fees"}
              alignment={"horizontal"}
              width={"80%"}
            />
          </div>
        </div>
        <div className="form-row">
          <span className="form-label">Total Construction Cost</span>
          <div className="input-grp">
            <Form
              name="TotalConstructionCost"
              value={currencyCommaSeperator(data.TotalConstructionCost)}
              onChange={onChange}
              // type="number"
              // label={"Total Construction Cost"}
              alignment={"horizontal"}
              width={"80%"}
            />
          </div>
        </div>
        <div className="form-row">
          <span className="form-label">Drawing, Approval & other</span>
          <div className="input-grp">
            <Form
              name="DrawingApprovalCost"
              value={currencyCommaSeperator(data.DrawingApprovalCost)}
              onChange={onChange}
              // type="number"
              // label={"Drawing, Approval & other"}
              alignment={"horizontal"}
              width={"80%"}
            />
          </div>
        </div>
        <div className="form-row">
          <span className="form-label">Contengency Cost</span>
          <div className="input-grp">
            <Form
              name="ContengencyCost"
              value={currencyCommaSeperator(data.ContengencyCost)}
              onChange={onChange}
              // type="number"
              // label={"Contengency Cost"}
              alignment={"horizontal"}
              width={"80%"}
            />
          </div>
        </div>
        <div className="form-row">
          <span className="form-label">Other Cost</span>
          <div className="input-grp">
            <Form
              name="OtherCost"
              value={currencyCommaSeperator(data.OtherCost)}
              onChange={onChange}
              // type="number"
              // label={"Other Cost"}
              alignment={"horizontal"}
              width={"80%"}
            />
          </div>
        </div>
        <div className="form-row">
          <h3 className="form-label">Total Estimated Cost required</h3>
          <div className="input-grp pd-total">
            <Form
              name="OtherCost"
              value={currencyCommaSeperator(calculateLoanReqTotal())}
              // onChange={onChange}
              // type="number"
              // label={"Other Cost"}
              disabled
              alignment={"horizontal"}
              width={"80%"}
            />
          {/* {currencyCommaSeperator(calculateLoanReqTotal())} */}
          </div>
        </div>
      </div>
    </>
  );
}

export default LoanRequirement;
