import React, { useState } from "react";
import PropTypes from "prop-types";
import * as XLSX from "xlsx";
import isEmpty from "isEmpty";

function ImportExcel(props) {
  const {
    responseDataHeaders,
    expectedExcelHeadersToImport,
    text,
    extraClassName,
  } = props;

  const onImport = (e) => {
    e.preventDefault();

    let files = e.target.files;
    let f = files[0];
    const extension = f.name.split(".")[1];
    if (extension != "xlsx") {
      props.getMessage &&
        props.getMessage("Only .xlsx file type is supported.");
      return null;
    }

    let reader = new FileReader();

    reader.onload = function (e) {
      let data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      //   setFileUploaded(dataParse);
      convertToList(dataParse);
    };
    reader.readAsBinaryString(f);
  };

  const convertToList = (array) => {
    const firstIndex = array[0];
    let isHeaderMatched = true;

    //#region check imported excel sheed header
    expectedExcelHeadersToImport.map((eh, k) => {
      if (isHeaderMatched) {
        if (eh != firstIndex[k]) {
          props.getMessage &&
            props.getMessage(
              "Excel header didnot match with provided template"
            );
          isHeaderMatched = false;
        }
      }
    });
    //#endregion
    if (isHeaderMatched) {
      //#region finalize the exporting data key for key-value pair
      let headerFields = responseDataHeaders || firstIndex;
      //replace space with "_"
      headerFields = headerFields.map((hf) => {
        return hf.replace(/ /g, "_");
      });
      //#endregion

      //#region Prepare list
      const data = array.slice(1);
      let result = [];
      data &&
        data.map((d, i) => {
          let row = {};
          headerFields.map((h, k) => {
            row[h] = d[k];
          });
          result.push(row);
        });
      //#endregion

      props.getResponse && props.getResponse(result);
    }
  };

  return (
    <div className="upload-btn-wrapper">
      <button className={"button button-primary-light " + extraClassName}>
        {text}
        <input accept=".xlsx" type="file" name="file" onChange={onImport} />
      </button>
    </div>
  );
}

ImportExcel.propTypes = {
  text: PropTypes.string,
  extraClassName: PropTypes.string,
};
ImportExcel.defaultProps = {
  text: "Import",
  extraClassName: "",
};

export default ImportExcel;
