import React, { Component } from "react";
import Avatar from "react-avatar";
import { formatedMonthDayYear } from "constants/dateFormat";
import Button from 'components/button'
import {getUploadPath} from 'constants/userDetails'
export class OnBoardingTableRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drop: false,
      dropMoreOption: false,
    };
    this.dropdown = "";
    this.dropdownMoreOption = "";
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.dropdown && !this.dropdown.contains(event.target)) {
      this.setState({ drop: false });
    }
    if (
      this.dropdownMoreOption &&
      !this.dropdownMoreOption.contains(event.target)
    ) {
      this.setState({ dropMoreOption: false });
    }
  }

  onPackageInitiate = async (EmployeeId) => {
    await this.props.GetOnboardingEmployeeDetail(EmployeeId);
    this.props.push(`/admin/employee_onboarding/packages/${EmployeeId}`);
  }

  render() {
    const { props } = this;
    return (
      <tr
        className="table-row e-row"
        role="row"
        key={props.index}
      >
        <td className="e-rowcell e-templatecell" role="gridcell">
          <Avatar
            name={props.EmployeeName}
            size={45}
            round={true}
            // color="#e1e1e1"
            // fgColor="#787878"
            src={props.EmployeeImage &&  getUploadPath(props.EmployeeImage)}
          />
        </td>
        <td className="e-rowcell e-templatecell" role="gridcell">
          <span
            className="clickable"
            onClick={(e) => props.handleEdit(props)}
          >
            {props.EmployeeName}
          </span>
        </td>
        <td className="e-rowcell e-templatecell" role="gridcell">
          <div className="">
            <span>{props.Designation}</span>
            <span className="color-light">{props.Level}</span>
          </div>
        </td>
        <td className="e-rowcell e-templatecell" role="gridcell">
          <div className="">
            <span>{props.Branch}</span>
            <span className="color-light">{props.Department}</span>
          </div>
        </td>
        <td className="e-rowcell e-templatecell" role="gridcell">
          <div className="">
            <span>
              {props.Added &&
                formatedMonthDayYear(props.Added)}
            </span>
          </div>
        </td>
        <td className="e-rowcell e-templatecell" role="gridcell">
          <div className="">{props.JoinDate ?
                formatedMonthDayYear(props.JoinDate) : '-'}</div>
        </td>
        <td className="e-rowcell e-templatecell" role="gridcell">
          <div className="">{props.CurrentStage}</div>
        </td>

        {(props.stageId != 0 && props.stageId != 3) && <td
          className="e-custom-row e-table-move"
          style={{ overflow: "visible" }}
          role="gridcell"
        >
          { 
            <Button
              buttonType="drop-button"
              bg="secondary"
              title="Review"
              onClick={(e) => null}
              dropClass={"common-drop onboarding-dropdown"}
              dropComponent={
              <ul
                  id={"Project" + 1}
                  className="common-drop-list"
              >
                {props.CurrentStage == 'Draft' ? 
                <li
                className={"common-drop-list__item"}
                onClick={() => this.onPackageInitiate(props.EmployeeId)}
                >
                <span className="common-drop-name">Initial Onboarding</span>
                </li>
                : null}

                {props.CurrentStage == 'Draft' || props.CurrentStage == 'Onboarding Process' ? 
                <li
                className={"common-drop-list__item"}
                onClick={(e) => props.handleEdit(props)}
                >
                  <span className="common-drop-name">Edit</span>
                </li>
                : null}
                {props.CurrentStage == 'Draft' || props.CurrentStage == 'Onboarding Process' ? 
                <li
                className={"common-drop-list__item"}
                onClick={(e) => props.deleteOnBoardingEmployee(props.EmployeeId)}
                >
                  <span className="common-drop-name">Delete</span>
                </li>
                : null}
              </ul>
              }
            />
          }
        </td>}
      </tr>
    );
  }
}

export default OnBoardingTableRow;
