import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ExportExcel from "components/excel/exportExcel";
import FormGroup from "components/form";
import DataSelect from "components/dataselect";
import isEmpty from "isEmpty";
import Moment from "react-moment";
import { DateRange } from "react-date-range";
import moment from "moment";
import { FaRegCalendarAlt } from "react-icons/fa";
import classNames from "classnames";
import Chipsfilter from "./chipsFilter";
import {
  EMPLOYEE_REQUEST_CHIPS_FILTER_LIST,
  EMPLOYEE_REQUEST_CHIPS_EVERYTHING_VALUE,
} from "constants/constants";
import DatePickerAsPerSetting from "components/customdaterange/datepickerAsPerSetting";
import { BS2AD } from "helpers/dateConvert";

export interface IAppProps {
  handleFilterParamChange: Function;
  handleDateRangeChange: Function;
  location?: any;
  showEmployeeFilter?: boolean;
  companyInformation: any;
}

interface State {
  employeeName: string;
  eventStartDate: Date;
  eventEndDate: Date;
  requestStartDate: Date;
  requestEndDate: Date;
  chipsFilters: any[];
}

class App extends React.Component<IAppProps, State> {
  constructor(props) {
    super(props);
    this.state = {
      employeeName: "",
      eventStartDate: null,
      eventEndDate: null,
      requestStartDate: null,
      requestEndDate: null,
      chipsFilters: EMPLOYEE_REQUEST_CHIPS_FILTER_LIST.filter(
        (x) => x.value === EMPLOYEE_REQUEST_CHIPS_EVERYTHING_VALUE
      ),
    };
  }
  componentDidMount() {
    if (!isEmpty(this.props?.location?.state?.RequestType)) {
      const requestType = this.props?.location?.state.RequestType;

      let chipsFilters = EMPLOYEE_REQUEST_CHIPS_FILTER_LIST.filter(
        (x) => x.label === requestType
      );

      if (chipsFilters.length <= 0) {
        chipsFilters = EMPLOYEE_REQUEST_CHIPS_FILTER_LIST.filter(
          (x) => x.label === "Everything"
        ); //Everything
      }
      this.setState({ chipsFilters });
    }
  }
  handleFieldChange = (name, value) => {
    this.setState({ [name]: value } as Pick<State, keyof State>);
    this.props.handleFilterParamChange(name, value);
  };
  handleDateChange = (name, range) => {
    // let startDate = !isEmpty(range.startDate)
    //   ? moment(new Date(range.startDate), "YYYYMMDDHHmmss")
    //       .startOf("day")
    //       .format("YYYY/MM/DD HH:mm:ss")
    //   : null;
    // let endDate = !isEmpty(range.endDate)
    //   ? moment(new Date(range.endDate), "YYYYMMDDHHmmss")
    //       .endOf("day")
    //       .format("YYYY/MM/DD HH:mm:ss")
    //   : null;
    let { startDate, endDate } = range;

    if (isEmpty(endDate)) {
      endDate = startDate;
    }

    this.setState({
      [(name + "StartDate") as any]: startDate,
      [(name + "EndDate") as any]: endDate,
    } as Pick<State, keyof State>);
    const { companyInformation } = this.props;
    let isNepaliDate = !companyInformation.IsEnglishDate;
    let engStartDate = isNepaliDate && (BS2AD(startDate) as any);

    let engEndDate = isNepaliDate && (BS2AD(endDate) as any);
    let fromDateEng = isNepaliDate
      ? `${engStartDate?.year}/${engStartDate?.month}/${engStartDate?.day}`
      : startDate;
    let toDateEng = isNepaliDate
      ? `${engEndDate?.year}/${engEndDate?.month}/${engEndDate?.day}`
      : startDate;
    this.props.handleDateRangeChange(name, fromDateEng, toDateEng);
  };
  handleChipsFilterChange = (name, selected) => {
    let { chipsFilters } = this.state;
    if (chipsFilters.find((x) => x.value === selected.value)) {
      chipsFilters = chipsFilters.filter((x) => x.value !== selected.value);
    } else {
      if (selected.value === EMPLOYEE_REQUEST_CHIPS_EVERYTHING_VALUE)
        chipsFilters = chipsFilters.filter(
          (x) => x.value === EMPLOYEE_REQUEST_CHIPS_EVERYTHING_VALUE
        );
      else if (selected.value !== EMPLOYEE_REQUEST_CHIPS_EVERYTHING_VALUE)
        chipsFilters = chipsFilters.filter(
          (x) => x.value !== EMPLOYEE_REQUEST_CHIPS_EVERYTHING_VALUE
        );
      chipsFilters.push(selected);
    }
    this.setState({ chipsFilters });

    this.props.handleFilterParamChange(
      "requestType",
      chipsFilters
        .map((e) =>
          e.value === EMPLOYEE_REQUEST_CHIPS_EVERYTHING_VALUE ? "" : e.label
        )
        .join(",")
    );
  };
  public render() {
    const {
      employeeName,
      eventStartDate,
      eventEndDate,
      requestStartDate,
      requestEndDate,
      chipsFilters,
    } = this.state;
    const { showEmployeeFilter } = this.props;
    return (
      <div>
        <div
          className="flex filter-top filters-input"
          style={{ alignItems: "flex-end" }}
        >
          {showEmployeeFilter && (
            <FormGroup
              name="employeeName"
              className="search"
              placeholder="Search Employee"
              type="search"
              value={employeeName}
              onChange={this.handleFieldChange}
              width="250px"
            />
          )}
          <DatePickerAsPerSetting
            onChange={(name, range) => this.handleDateChange(name, range)}
            value={{
              startDate: !isEmpty(eventStartDate) ? eventStartDate : "",
              endDate: !isEmpty(eventEndDate) ? eventEndDate : "",
            }}
            placeholder="Event Date Range"
            width="250px"
            name="event"
            isDateRange
          />
          <DatePickerAsPerSetting
            onChange={(name, range) => this.handleDateChange(name, range)}
            value={{
              startDate: !isEmpty(requestStartDate) ? requestStartDate : "",
              endDate: !isEmpty(requestEndDate) ? requestEndDate : "",
            }}
            placeholder="Requested Date Range"
            width="250px"
            name="request"
            isDateRange
          />
          {/* <FormGroup
            formName="reactdaterange"
            name="event"
            startDate={!isEmpty(eventStartDate) ? eventStartDate : new Date()}
            endDate={!isEmpty(eventEndDate) ? eventEndDate : new Date()}
            onChange={(name, range) => this.handleDateChange(name, range)}
            placeholder="Event Date Range"
            width="250px"
          /> */}
          {/* <FormGroup
            formName="reactdaterange"
            name="request"
            startDate={
              !isEmpty(requestStartDate) ? requestStartDate : new Date()
            }
            endDate={!isEmpty(requestEndDate) ? requestEndDate : new Date()}
            onChange={(name, range) => this.handleDateChange(name, range)}
            placeholder="Requested Date Range"
            width="250px"
          /> */}
        </div>
        <div className="filters-chip">
          <Chipsfilter
            handleFieldChange={this.handleChipsFilterChange}
            chipsFilterValue={chipsFilters}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  totalOtherTravelRequest: state.travelInformationReducer.totalTravelRequests,
  totalMyTravelRequests: state.travelInformationReducer.totalMyTravelRequests,
  travelListCount: state.travelInformationReducer.travelListCount,
  otherTravelRequestExportExcel:
    state.travelInformationReducer.otherTravelRequestExportExcel,
  settlementRequestExportExcel:
    state.travelSettlementReducer.settlementRequestExportExcel,
  flowSteps: state.travelInformationReducer.flowSteps,
  companyInformation: state.commonReducer.companyInformation,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(App);
