import React,{useState,useEffect} from 'react'
import Modal from 'components/modal';

interface Props{
    children:any,
    document:any
}

const imageExtentions = ['png','jpeg','jpg','svg'];
const pdfExtentiosn = ['pdf','txt','logs']
const docsExtentiosn = 'docs'
const excelsExtentiosn = 'excel'

function CommonDocumentViewer(props:Props) {
    const [open,setOpen] = useState(false);
    const onModalClose = () =>setOpen(false);
    const {children,document} = props;
    const childProps = {
        onClick:()=>setOpen(true),
        ...children.props
    }
    const ext = document.split(".").pop();
    let docs;
    if(imageExtentions.includes(ext)){
        docs = (
            <div className="documents-image">
                    <img src={document} alt="Document Image"/>
            </div>
        )
    }
    if(pdfExtentiosn.includes(ext)){
        docs = (
            <div className="documents-pdf">
                <iframe src={document} frameBorder="0"></iframe>
            </div>
        )  
    }
    return (
        <>
            <Modal 
                open={open}
                onModalClose={onModalClose}
                title="Document Viewer"
                width="700px"
            >
                <div className="documents">
                    <div className="documents-file">
                        {docs}
                    </div>
                </div>
            </Modal>
            {
                React.cloneElement(
                    children, 
                    childProps
                  )
            }
        </>
    )
}

export default CommonDocumentViewer
