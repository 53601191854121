import React, { Component } from "react";
import { connect } from "react-redux";
import './style.scss';
import Education from './education';
import Training from './trainings';
import WorkExperience from './workExperience';
import Refrences from './reference';
import Tried from 'routes/navigate/try';
import {
    saveEmployeeEducationDetails,
    getEmployeeEducationList,
    getEmployeeTrainingList,
    getEmployeeExperienceList,
    getEmployeeReferenceList
} from '../action';
import {
    GetCountryList,
} from 'actions/commonAction'


export class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addingTab: '',
            sections: [
                {
                    name: 'Education',
                    component: <Education {...props}/>
                },
                {
                    name: 'Training',
                    component: <Training {...props}/>
                },
                {
                    name: 'Work and Experience',
                    component: <WorkExperience {...props}/>
                },
                {
                    name: 'Refrences',
                    component: <Refrences {...props}/>
                }
            ]
        }
    }

    componentWillMount() {
        const {
            employeeId,
            getEmployeeEducationList,
            getEmployeeTrainingList,
            getEmployeeExperienceList,
            getEmployeeReferenceList,
            GetCountryList,
        } = this.props;
        if (employeeId) {
            getEmployeeEducationList(employeeId);
            getEmployeeTrainingList(employeeId);
            getEmployeeExperienceList(employeeId);
            getEmployeeReferenceList(employeeId);
            GetCountryList();
        }
    }

    render() {
        return (
        <div className="education-container">
            {
                this.state.sections.map(sec => {
                    return(
                        <div className="education-body" >
                            <div className="education-section">
                                {sec.component}
                            </div>
                        </div>
                    )
                })
            }
        </div>
        );
    }
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = {
    saveEmployeeEducationDetails,
    getEmployeeEducationList,
    getEmployeeTrainingList,
    getEmployeeExperienceList,
    getEmployeeReferenceList,
    GetCountryList,
};

export default connect(mapStateToProps, mapDispatchToProps)(index);