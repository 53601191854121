import React, { Component } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Filter,
  Inject,
  Sort,
  // SortSettingsModel,
} from "@syncfusion/ej2-react-grids";
import Pagination from "components/common/pagination";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import RouteEnum from "constants/routeEnum";
import Button from "components/button";
import moment from "moment";

const formatNumber = (params) => {
  return (
    Math.floor(params?.value)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + ".00"
  );
};

const formatDate = (date) => {
  if (date && date.value) return moment(date?.value).format("YYYY/MM/DD");
  else return "-";
};

const ButtonGroup = (props) => {
  return (
    <div className="flex flex-end align-right view-button-pd">
      <Button
        bg="secondary"
        size="large"
        buttonClass="wd-90"
        onClick={() =>
          props.api.viewLoanDetail(props.data.LoanType, props.data)
        }
        title={props?.data?.IsDraft ? "Edit" : "View"}
      />
    </div>
  );
};

class MyLoanListingTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          field: "LoanTypeName",
          headerName: "Loan Type",
          width: 190,
        },
        {
          field: "LoanPurpose",
          headerName: "Loan Purpose",
          width: 360,
        },
        {
          field: "RequestedOn",
          width: 150,
          headerName: "Requested on",
          valueFormatter: formatDate,
        },
        {
          field: "RequestAmount",
          width: 180,
          headerName: "Amount (NPR)",
          type: "rightAligned",
          valueFormatter: formatNumber,
        },
        {
          field: "StatusName",
          headerName: "Status",
          width: 215,
        },
        { field: "", cellRenderer: "ButtonGroup", headerName: "", width: 200 },
      ],
      domLayout: "autoHeight",
      defaultColDef: {
        // width: 145,
        // resizable: true,
      },
      // loadingCellRenderer: 'customLoadingCellRenderer',
      // loadingCellRendererParams: { loadingMessage: 'One moment please...' },
      frameworkComponents: {
        ButtonGroup,
      },

      getRowHeight: function (params) {
        return 44;
      },
    };
    this.widths = {
      employeeName: 120,
      requestedAmount: 100,
      activityNotSubmitted: 120,
      workDays: 100,
    };
  }

  getUpdateRoute = (type) => {
    switch (type) {
      case "PrimeHomeLoan":
        return RouteEnum.UpdateHomeLoanPath;
      case "PrimeStaffODLoan":
        return RouteEnum.UpdateStaffODLoanPath;
      case "PrimeStaffPersonalLoan":
        return RouteEnum.UpdateStaffPersonalLoanPath;
      case "PrimeStaffAdvanceSalary":
        return RouteEnum.UpdateStaffPersonalLoanPath;
      case "PrimeVehicleLoan":
        return RouteEnum.UpdateVehicleLoanPath;
      default:
        return RouteEnum.UpdateHomeLoanPath;
    }
  };

  viewLoanDetail = async (type, loan) => {
    if (loan.IsDraft) {
      let updateRoute = this.getUpdateRoute(type);
      this.props.history.push({
        pathname: `${updateRoute}/${loan.Id}`,
        state: {
          data: loan.LoanType,
          EmployeeId: loan.EmployeeId,
          Status: loan.Status,
          DisbursementDate: loan.DisbursementDate,
          NoOfLoanTaken: loan.NoOfLoanTaken,
          viewMode: true,
        },
      });
    } else
      this.props.history.push({
        pathname: `${RouteEnum.ViewHomeLoanPath}/${loan.Id}`,
        state: {
          data: loan.LoanType,
          EmployeeId: loan.EmployeeId,
          Status: loan.Status,
          DisbursementDate: loan.DisbursementDate,
          NoOfLoanTaken: loan.NoOfLoanTaken,
          viewMode: true
        },
      });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.setAutoHeight();
    // const updateData = (data) => {
    //   this.setState({ rowData: data });
    // };

    // if (this.props.data?.Data) {
    //   updateData(this.props.data.Data);
    // }
    this.gridApi.viewLoanDetail = this.viewLoanDetail;
    // this.gridApi.submitLoanApproved = this.submitLoanApproved;
    // this.gridApi.setOpen = this.setOpen;
  };
  setAutoHeight = () => {
    this.gridApi.setDomLayout("autoHeight");
    const grid = document.querySelector("#myGrid");
    if (grid) grid.style.height = "";
  };

  render() {
    const { data, handleRowChange, handleIndexChange, pageIndex, rowsPerPage } =
      this.props;
    return (
      <div className="employee-homeloan employee-homeloan__selfApplied pending-settlement-grid mt-sm">
        <div
          id="myGrid"
          style={{
            height: "100%",
            // width: "67%",
          }}
          className="ag-theme-alpine"
        >
          <AgGridReact
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            frameworkComponents={this.state.frameworkComponents}
            onGridReady={this.onGridReady}
            getRowHeight={this.state.getRowHeight}
            // loadingCellRenderer={this.state.loadingCellRenderer}
            // loadingCellRendererParams={this.state.loadingCellRendererParams}
            rowData={data.Data}
            alwaysShowHorizontalScroll={true}
          />
        </div>
        {/* {data && data.Data.length > 0 && (
            <Pagination
              handleRowChange={handleRowChange}
              handleIndexChange={handleIndexChange}
              pageIndex={pageIndex}
              rowsPerPage={rowsPerPage}
              total={data && data.RecordsTotal}
            />
          )} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default withRouter(connect(mapStateToProps, {})(MyLoanListingTable));
