import React from "react";

export default function PayslipTitleBar(props) {
  
  const {
    CompanyName,
    CompanyAddress,
    EmployeeName,
    EmployeeId,
    Designation,
  } = props.payslip;

  return (
    <div className="payslip-title_bar">
      <div className="payslip-title_bar-row">
        <div className="description-company">
          <span className="fs-bo fs-bg">{CompanyName || "-"}</span>
          <span className="fs-li">{CompanyAddress || "-"}</span>
        </div>
      </div>
      <div className="payslip-title_bar-row">
        <div className="description-employee">
          <div>
            <span className="name fs-bo ">{EmployeeName || "-"}</span>
            <span className="fs-li fs-sm">{"EID: " + (EmployeeId || "-")}</span>
          </div>
          <span className="fs-li fs-sm">{Designation || "-"}</span>
        </div>
        <div className="description-slip">
          <span className="fs-bo">{props.slipName}</span>
          <span className="fs-li fs-sm">{props.activeMonth.Name}</span>
        </div>
      </div>
    </div>
  );
}
