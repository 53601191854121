import FormGroup from "components/form";
import { CUSTOM_PROFILE_BLOCK_TYPE } from "constants/constants";
import isEmpty from "isEmpty";
import React, { Component } from "react";
import { GrCheckmark, GrClose } from "react-icons/gr";
import {
  MdArrowDownward,
  MdArrowUpward,
  MdDelete,
  MdEdit,
} from "react-icons/md";

class CustomProfileBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNameEdit: false,
      subtitleToEdits: [],
      profileBlockType: "",
    };
  }

  componentDidMount() {
    const { id } = this.props;
    const profileBlockType = CUSTOM_PROFILE_BLOCK_TYPE + id;
    var stateCopy = this.props.state.profileBlocks.map((x) => {
      return { ...x };
    });
    const currentSection = stateCopy.find((x) => x.type === profileBlockType);
    this.setState({ intialBlockData: currentSection, profileBlockType });
  }

  render() {
    const { isNameEdit, subtitleToEdits, profileBlockType } = this.state;
    const {
      handleInput,
      handleBlockMove,
      index,
      subComponent,
      id,
      isViewMode,
      handleRemoveSection,
    } = this.props;
    //const profileBlockType = "customProfileBlock" + id;
    const { profileBlocks, errors, initialBlocks } = this.props.state;
    const currentSection = this.props.state.profileBlocks.find(
      (x) => x.type === profileBlockType
    );
    if (isEmpty(currentSection)) return null;
    const { title } = currentSection;
    const { subtitle, paragraph } = currentSection.components[0];
    return (
      <div className="duties-resp profile-block">
        <div className="profile-block__header">
          <span className="profile-text">{currentSection.title}</span>
        </div>
        <div className="profile-block__body">
          <FormGroup
            formName="textarea"
            name="paragraph"
            //label={subtitle}
            cols={50}
            rows={6}
            value={paragraph}
            onChange={(name, value) =>
              this.props.handleComponentFieldChange(
                profileBlockType,
                currentSection.components[0].id,
                name,
                value
              )
            }
            disabled={this.props.isViewMode}
            placeholder="Write something..."
          />
        </div>
      </div>
    );
  }
}

export default CustomProfileBlock;
