import {
  GET_EMPLOYEE_MANAGERS,
  GET_EMPLOYEE_MANAGERS_FOR_EXPORT,
  GET_EMPLOYEE_MANAGERS_BY_ID,
  REPORTING_TO_MANAGERS,
} from "actions/types";

const initialState = {
  managerAssignmentSummary: null,
  employeeManagers: null,
  employeeManagerById: null,
  employeeManagersForExport: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEE_MANAGERS:
      return {
        ...state,
        managerAssignmentSummary: action.managerAssignmentSummary,
        employeeManagers: action.employeeManagers,
      };

    case GET_EMPLOYEE_MANAGERS_BY_ID:
      return {
        ...state,
        employeeManagerById: action.payload,
      };

    case GET_EMPLOYEE_MANAGERS_FOR_EXPORT:
      return {
        ...state,
        employeeManagersForExport: action.employeeManagersForExport,
      };
    default:
      return state;
  }
}
