import React from "react";
import classnames from "classnames";

export const Sidetab = (props) => {
  const { currentStep, onStepChange } = props;
  const tabContent = [
    {
      num: 1,
      title: "Final Pay Sheet",
      //   details: "Define the Job Position you are hiring",
    },
    {
      num: 2,
      title: "Pay Summary",
      //   details: "Select the components in Application Form",
    }
  ];
  return (
    <div className="recruit-header">
      <div className="flex">
        {tabContent.map((item) => (
          <Tab
            tab={item}
            onStepChange={onStepChange}
            currentStep={currentStep}
            //   isAt={isAt}
          />
        ))}
      </div>
      <div className="button-group button-group__right flex">
        <button className="btn btn-grey-2">Export</button>
      </div>
    </div>
  );
};

export const Tab = ({ tab, currentStep, onStepChange, isAt }) => {
  const classes = classnames({
    "recruit-header-item": true,
    // disabled: tab.num > isAt,
    selected: tab.num === currentStep,
  });
  return (
    <a
      key={tab.num}
      className={classes}
      disabled={true}
      onClick={() => onStepChange(tab.num)}
    >
      <div className="recruit-header-title">{tab.title}</div>
      {/* <div className="recruit-header-body">
        <p>{tab.details}</p>
      </div> */}
    </a>
  );
};

export default Sidetab;
