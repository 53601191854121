import React from "react";
import Icons from "components/icons";
import {
  Date_Format,
  Time_Format_HM,
  getHrsMinFormatFromMinutes,
  formatedToMonthDay,
} from "helpers/dateFormat";
import Button from "components/button";

export default function TodaysClock({
  clockData,
  handleSubmitActivityReportClick,
  dismissTask,
}) {
  if (!clockData || clockData.length <= 0) {
    return null;
  }
  const getDateNotation = (date) => {
    let notation = null;
    let inputDate = Date_Format(new Date(date));

    let today = new Date();
    let yesterday = new Date();

    yesterday = yesterday.setDate(today.getDate() - 1);
    yesterday = Date_Format(yesterday);
    today = Date_Format(today);

    if (inputDate == today) {
      notation = "Today";
    } else if (inputDate == yesterday) {
      notation = "Yesterday";
    } else {
      notation = formatedToMonthDay(date);
    }
    return notation;
  };

  return (
    <>
      {clockData &&
        clockData.map((d, i) => {
          return (
            <div className="activity-clock" key={i}>
              <h4 className="activity-title">{getDateNotation(d.date)}</h4>
              <div className="activity-clock-body">
                <div className="activity-clock-body-list">
                  {d.items &&
                    d.items.map((item, k) => {
                      return (
                        <div className="activity-clock-body-content" key={k}>
                          <div className="activity-clock-body-content-detail">
                            <div className="activity-clock-body-content-detail-time">
                              <span>{Time_Format_HM(item.TaskStartDTime)}</span>
                              <span>
                                <Icons name="ArrowForward" color="grey" />
                              </span>
                              <span>{Time_Format_HM(item.TaskEndDTime)}</span>
                              <span className="hours">
                                {getHrsMinFormatFromMinutes(item.TaskSpentTime)}
                              </span>
                            </div>
                            <div className="activity-clock-body-content-detail-tender">
                              <span className="tproject">
                                {item.ProjectName}
                              </span>
                              <span className="tclient">{item.ClientName}</span>
                            </div>
                          </div>
                          <div>
                            {/* <button
                              onClick={() => dismissTask(item.TaskId)}
                              className="button button-grey flex"
                            >
                              <Icons name="Delete" />
                              Remove
                            </button> */}

                            <Button
                              title="Remove"
                              buttonType="icon-button"
                              bg="subtle"
                              size="small"
                              onClick={() => dismissTask(item.TaskId)}
                              leftIcon={<Icons name="Close" justSVG />}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
                {/* <button
                  onClick={() => handleSubmitActivityReportClick(d)}
                  className="btn btn-primary"
                >
                  Submit activity report
                </button> */}
                <div className="activity-clock-body-footer">
                  <Button
                    title="Submit activity report"
                    bg="primary"
                    onClick={() => handleSubmitActivityReportClick(d)}
                  />
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
}
