import React from "react";
import "./jobListing.scss";
import CareerDetails from "./careerDetails";
import { getActiveJobs } from "./action";
import { connect } from "react-redux";
import Loader from "components/loaders";
import { withRouter } from "react-router-dom";
import Breadcrumb from "scenes/common/breadcrumb";

export class JobListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isBlockShow: true,
      jobId: 0,
    };
  }
  componentDidMount() {
    this.props.getActiveJobs();
  }

  render() {
    const { spinnerLoading, loading } = this.props.jobListingReducer;
    const { isBlockShow, jobId } = this.state;
    return (
      <div className="common-container career">
        <Breadcrumb name="Latest Careers" />
        <div className="career__header">
          <span>Latest</span>
          <span> Careers</span>
        </div>
        {isBlockShow && !spinnerLoading ? <CareerDetails /> : null}
        <Loader open={spinnerLoading} />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  jobListingReducer: state.jobListingReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    getActiveJobs,
  })(JobListing)
);
