export const currencyCommaSeperator = (num) => {
  if (num == "" || num == undefined) return "";
  else {
    num = num.toString().replace(",", "");
    return Number(parseFloat(num).toFixed(2)).toLocaleString("en", {
      minimumFractionDigits: 2,
    });
  }
};

export const formatNumber = (number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}
