import React from "react";

function FinalSubmit({ isLoading, onSubmit }) {
  const btnSubmitStyle = !isLoading ? "btn-green" : "btn-grey-2";
  return (
    <div className="form-block">
      <div className="flex final__submit">
        <button
          className="btn btn"
          //   onClick={() => onRemoveList(listName,selectedReference.key)}
        >
          Preview
        </button>
        {/* <button
          className="btn btn-blue"
          //   onClick={() => onAddList(listName,selectedReference.key)}
        >
          Save And Finish Later
        </button> */}
        <button
          className={"btn " + btnSubmitStyle}
          onClick={!isLoading && onSubmit}
        >
          {!isLoading ? "Submit Job Application" : "Please wait.."}
        </button>
      </div>
    </div>
  );
}

export default FinalSubmit;
