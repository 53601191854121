import {
  SAVE_EMPLOYEE_OVERTIME_REQUESTS,
  EMPLOYEE_OVERTIME_HISTORY,
  EMPLOYEE_OVERTIME_REVIEWAPPROVAL_LIST,
  EMPLOYEE_SINGLE_OVERTIME_REQUEST,
} from "actions/types";
import axios from "axios";
import { toastMessage } from "actions/validateAction";
import { catchError } from "helpers";
import { loadingHeaders } from "helpers/getHeaders";
import { EmployeeID } from "constants/userDetails";

const urlPrefix = "/v1/leave-time/employees/";

//#region TTClient

export const saveEmployeeOvertimeRequests = (data) => async (dispatch) => {
  const employeeId = EmployeeID();
  await axios
    .post(`/v1/payroll/overtime-requests`, data)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        if (response.Data) {
          dispatch({
            type: SAVE_EMPLOYEE_OVERTIME_REQUESTS,
            payload: true,
          });
          toastMessage(dispatch, response);
        }
      } else {
        dispatch({
          type: SAVE_EMPLOYEE_OVERTIME_REQUESTS,
          payload: false,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      dispatch({
        type: SAVE_EMPLOYEE_OVERTIME_REQUESTS,
        payload: false,
      });
      catchError(dispatch, err);
    });
};

export const updateEmployeeOvertimeRequests = (data) => async (dispatch) => {
  const employeeId = EmployeeID();
  await axios
    .put(`/v1/payroll/overtime-requests`, data)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        if (response.Data) {
          dispatch({
            type: SAVE_EMPLOYEE_OVERTIME_REQUESTS,
            payload: true,
          });
          toastMessage(dispatch, response);
        }
      } else {
        dispatch({
          type: SAVE_EMPLOYEE_OVERTIME_REQUESTS,
          payload: false,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      dispatch({
        type: SAVE_EMPLOYEE_OVERTIME_REQUESTS,
        payload: false,
      });
      catchError(dispatch, err);
    });
};

// get overtime-request history
export const getEmployeeOvertimeRequestsHistory = (data) => async (dispatch) => {
  const employeeId = EmployeeID();
  await axios
    .get(`v1/payroll/overtime-requests/history/${employeeId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        if (response.Data) {
          dispatch({
            type: EMPLOYEE_OVERTIME_HISTORY,
            payload: response.Data,
          });
        }
      } else {
        dispatch({
          type: EMPLOYEE_OVERTIME_HISTORY,
          payload: null,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      dispatch({
        type: EMPLOYEE_OVERTIME_HISTORY,
        payload: null,
      });
      catchError(dispatch, err);
    });
};

// get overtime approval reviewer list
export const GetOverTimeApprovalReviewerList = (employeeId) => async (
  dispatch
) => {
  await axios
    .get(
      `/v1/payroll/overtime-requests/approval-persons?employeeId=${employeeId}`
    )
    .then((resp) => {
      const response = resp.data;
      if (response) {
        if (response.Data) {
          let finalData = {
            approverList: [],
            reviewerList: [],
          };
          response.Data.Approvals.forEach((a) =>
            finalData.approverList.push({
              Id: a.EmployeeId,
              FullName: a.EmployeeName,
              Imagepath: a.Image,
              Designation: a.Position,
            })
          );
          response.Data.Recommended.forEach((a) =>
            finalData.reviewerList.push({
              Id: a.EmployeeId,
              FullName: a.EmployeeName,
              Imagepath: a.Image,
              Designation: a.Position,
            })
          );

          dispatch({
            type: EMPLOYEE_OVERTIME_REVIEWAPPROVAL_LIST,
            payload: finalData,
          });
        }
      } else {
        dispatch({
          type: EMPLOYEE_OVERTIME_REVIEWAPPROVAL_LIST,
          payload: null,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      dispatch({
        type: EMPLOYEE_OVERTIME_REVIEWAPPROVAL_LIST,
        payload: null,
      });
      catchError(dispatch, err);
    });
};


export const getEmployeeOvertimeRequest = (id) => async (dispatch) => {
  await axios
    .get(`/v1/employee/overtime/${id}`,)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        if (response.Data) {
          dispatch({
            type:EMPLOYEE_SINGLE_OVERTIME_REQUEST,
            payload:response.Data
          })
        }
      } else {
        dispatch({
          type: EMPLOYEE_SINGLE_OVERTIME_REQUEST,
          payload: null,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      dispatch({
        type: EMPLOYEE_SINGLE_OVERTIME_REQUEST,
        payload: null,
      });
      catchError(dispatch, err);
    });
};