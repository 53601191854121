import React, { Component } from "react";
import Tabs from "../../../components/tabs/tabs";
import Table from "./table";
import Filter from "./filter";
import AttendanceTable from "./attendanceTable";
import Filters from "../attendanceRegister/filters";
import {
  GetBranches,
  GetDepartments,
  GetDesignations,
  GetJobStatus,
} from "actions/commonAction";
import { connect } from "react-redux";
import { GetEmployeeListForAdmin } from "../../employee/employeeList/action";
import moment from "moment";
import isEmpty from "isEmpty";
import { getLeaveRequestStatus, searchLeave } from "./action";
import {
  searchAttendance,
  generateAttendance,
} from "../attendanceRegister/action";

const getDate = (name) => {
  var date = new Date();
  var firstDay = null;
  var lastDay = null;
  const label = !isEmpty(name) ? name.label : "";
  if (label === "This Month") {
    firstDay = moment(date).startOf("month").format("YYYY/MM/DD");
    lastDay = moment(date).endOf("month").format("YYYY/MM/DD");
  }
  if (label === "Last Month") {
    firstDay = moment(date)
      .subtract(1, "months")
      .startOf("month")
      .format("YYYY/MM/DD");
    lastDay = moment(date)
      .subtract(1, "months")
      .endOf("month")
      .format("YYYY/MM/DD");
  }
  if (label === "This Year") {
    firstDay = moment().startOf("year").format("YYYY/MM/DD");
    lastDay = moment().endOf("year").format("YYYY/MM/DD");
  }
  return { firstDay, lastDay };
};
export class Leave_Absence extends Component {
  constructor(props) {
    super(props);

    this.state = {
      leftValue: "",
      actTab: "leave",
      searchValue: "",
      activeBranch: "",
      activeDepartment: "",
      activePosition: "",
      activeStatus: "",
      region: "",
      approval_state: "",
      leave_type: "",
      rowsPerPage: 10,
      pageIndex: 0,
      attendanceRowsPerPage: 10,
      attendancePageIndex: 0,
    };
    this.tab = "";
    this.onChange = this.onChange.bind(this);
    this.handleRowChange = this.handleRowChange.bind(this);
    this.handleIndexChange = this.handleIndexChange.bind(this);
    this.onFilter = this.onFilter.bind(this);
  }
  componentDidMount() {
    this.props.getLeaveRequestStatus();
  }

  async componentDidUpdate(prevProps) {
    const { fiscalPeriodChanged, changeFiscalPeriod } = this.props;
    if (
      fiscalPeriodChanged &&
      fiscalPeriodChanged !== prevProps.fiscalPeriodChanged
    ) {
     
      await this.onFilter();
      changeFiscalPeriod();
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleRowChange = (num) => {
    const { actTab } = this.state;

    if (actTab === "attendance") {
      this.setState({ attendanceRowsPerPage: num });
    } else {
      this.setState({ rowsPerPage: num });
    }
  };
  // handleIndexChange(num){
  //     const {actTab}=this.state;

  //     if(actTab==='attendance'){
  //         this.setState({attendancePageIndex:num})
  //     }else{
  //         this.setState({pageIndex:num})
  //     }

  // }

  handleInput = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, pageIndex: 0 }, () => {
      // this.initialize();
    });
  };

  handleAutoSelect = (name, selected) => {
    this.setState({ [name]: selected, pageIndex: 0 }, () => {
      // this.initialize();
    });
  };

  handleRowChange = (num) => {
    this.setState({ rowsPerPage: num }, () => {
      this.onFilter();
    });
  };

  handleIndexChange = (num) => {
    this.setState({ pageIndex: num }, () => {
      this.onFilter();
    });
  };

  onFilter(filterType) {
    const {
      searchValue,
      activeBranch,
      activeDepartment,
      pageIndex,
      activePosition,
      activeStatus,
    } = this.state;
    const { selectedPayPeriod } = this.props.selectReducer;
      let searchedData = {
        employeeId: !isEmpty(searchValue) ? searchValue.value : "0",
        departmentId: !isEmpty(activeDepartment) ? activeDepartment.value : "0",
        leaveRequestStatusId: !isEmpty(activeStatus) ? activeStatus.value : "0",
        positionId: !isEmpty(activePosition) ? activePosition.value : "0",
        leaveTypeId: /* !isEmpty(activeStatus) ? activeStatus.value : */ "0",
        branchIds: !isEmpty(activeBranch) ? [activeBranch.value] : [],
        payrollPeriodId: !isEmpty(selectedPayPeriod) ? selectedPayPeriod.Value : '0',
        // payrollPeriodId: "0",
        pageIndex,
        pageSize: 10,
      };
      
      this.props.searchLeave(searchedData);
  }

  render() {
    const {
      actTab,
      pageIndex,
      attendancePageIndex,
      rowsPerPage,
      attendanceRowsPerPage,
    } = this.state;
    const index = actTab === "attendance" ? attendancePageIndex : pageIndex;
    const perPage =
      actTab === "attendance" ? attendanceRowsPerPage : rowsPerPage;
    const {
      employeeListForAdmin,
      branches,
      searchValue,
      departments,
      designations,
      statusList,
      history,
    } = this.props;

    return (
      <div className="payroll-body">
        <Filter
          pageIndex={index}
          // handleRowChange={this.handleRowChange}
          // handleIndexChange={this.handleIndexChange}
          rowsPerPage={perPage}
          activeTab={this.state.actTab}
          parentState={this.state}
          branches={branches}
          searchValue={searchValue}
          departments={departments}
          designations={designations}
          statusList={statusList}
          handleAutoSelect={this.handleAutoSelect}
          handleInput={this.handleInput}
          onFilter={this.onFilter}
        />
        <div className="panel blue space-between payroll-panel">
          <div className="flex">
            <h4>Leave and Abseentism</h4>
            <p>pay has been deducted for the following leaves or absentism</p>
          </div>
          <div>
            <a className="btn-text btn-text__white">Export</a>
          </div>
        </div>
        {/* <Tabs> */}
        {/* <div
          onClick={() => this.setState({ actTab: "leave" })}
          label="Leave & Absence"
          name="Leave & Absence"
        > */}
          <Table
            pageIndex={this.state.pageIndex}
            rowsPerPage={this.state.rowsPerPage}
            handleRowChange={this.handleRowChange}
            handleIndexChange={this.handleIndexChange}
          />
        {/* </div> */}
        {/* <div onClick={()=>this.setState({actTab:"attendance"})} label="Attendance Register" name="Attendance Register">
                        <AttendanceTable pageIndex={this.state.attendancePageIndex} 
                            rowsPerPage={this.state.attendanceRowsPerPage} 
                            handleRowChange={this.handleRowChange}
                            handleIndexChange={this.handleIndexChange}
                        />
                    </div> */}
        {/* </Tabs> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeListForAdmin: state.admin_EmployeeListReducer.employeeListForAdmin,
  branches: state.commonReducer.branchList,
  departments: state.commonReducer.departmentList,
  designations: state.commonReducer.designationList,
  statusList: state.commonReducer.jobStatusList,
  leaveReducer: state.leaveReducer,
  selectReducer: state.selectReducer,
  payrollAttendanceReducer: state.payrollAttendanceReducer,
});

const mapDispatchToProps = {
  GetEmployeeListForAdmin,
  GetBranches,
  GetDepartments,
  GetDesignations,
  GetJobStatus,
  searchLeave,
  searchAttendance,
  generateAttendance,
  getLeaveRequestStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(Leave_Absence);
