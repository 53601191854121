import * as React from "react";
import CommonFilters from "../common/commonFilters";
import MyRequestBlock from "./myRequestBlock";
import { GetMyRequests } from "../actions";
import { connect } from "react-redux";
import Modal from "components/modal";
import * as employeeRequestService from "services/employeeRequests";
import {
  // GetLevelsWithGroups,
  DisplayClientSideMessage,
  // GetJobProfiles,
} from "actions/commonAction";
import isEmpty from "isEmpty";

export interface IAppProps {
  GetMyRequests: Function;
  myRequests: any;
  DisplayClientSideMessage: Function;
  location: any;
}

interface State {
  pageNumber: number;
  pageSize: number;
  employeeName: string;
  requestType: string;
  requestStartDate: string;
  requestEndDate: string;
  eventStartDate: string;
  eventEndDate: string;
  isModalShow: boolean;
  selectedRequestId: number;
  selectedRequestType: string;
}
class App extends React.Component<IAppProps, State> {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 0,
      pageSize: 10,
      employeeName: "",
      requestType: "",
      requestStartDate: null,
      requestEndDate: null,
      eventStartDate: null,
      eventEndDate: null,
      isModalShow: false,
      selectedRequestId: 0,
      selectedRequestType: "",
    };
  }
  componentDidMount() {
    if (!isEmpty(this.props?.location?.state?.RequestType)) {
      const requestType = this.props?.location?.state.RequestType;
      this.setState({ requestType }, () => this.initialize());
    } else {
      this.initialize();
    }
  }
  handleRowChange = (num) => {
    this.setState({ pageSize: num }, () => {
      this.initialize();
    });
  };
  handleIndexChange = (num) => {
    this.setState({ pageNumber: num }, () => {
      this.initialize();
    });
  };
  handleFilterParamChange = (name, value) => {
    this.setState({ [name]: value } as Pick<State, keyof State>, () => {
      this.initialize();
    });
  };
  handleDateRangeChange = (name, startDate, endDate) => {
    this.setState(
      {
        [(name + "StartDate") as any]: startDate,
        [(name + "EndDate") as any]: endDate,
      } as Pick<State, keyof State>,
      () => {
        this.initialize();
      }
    );
  };
  initialize = () => {
    const {
      pageNumber,
      pageSize,
      employeeName,
      requestType,
      requestStartDate,
      requestEndDate,
      eventStartDate,
      eventEndDate,
    } = this.state;
    let searchData = {
      pageNumber,
      pageSize,
      employeeName,
      requestType,
      requestStartDate,
      requestEndDate,
      eventStartDate,
      eventEndDate,
    };
    this.props.GetMyRequests(searchData);
  };

  handleConfirmCancelRequest = (data) => {
    this.setState({
      selectedRequestId: data.RequestId,
      selectedRequestType: data.RequestType,
      isModalShow: true,
    });
  };

  handleCancelRequest = async () => {
    const { selectedRequestId, selectedRequestType } = this.state;
    let requestData = {
      requestId: selectedRequestId,
      requestType: selectedRequestType,
    };
    let result = await employeeRequestService.cancelMyRequests(requestData);
    if (result.Status) {
      this.initialize();
    }
    this.props.DisplayClientSideMessage(result);
  };
  closeModal = () => {
    this.setState({ isModalShow: false });
  };
  public render() {
    const { myRequests } = this.props;
    return (
      <div className="my-request my-requeest-new">
        <CommonFilters
          handleFilterParamChange={this.handleFilterParamChange}
          handleDateRangeChange={this.handleDateRangeChange}
          {...this.props}
          showEmployeeFilter={false}
        />
        <div className="my-request__block employee-request-block">
          <MyRequestBlock
            request={myRequests}
            handleRowChange={this.handleRowChange}
            handleIndexChange={this.handleIndexChange}
            pageIndex={this.state.pageNumber}
            rowsPerPage={this.state.pageSize}
            refreshRequestData={this.initialize}
            handleConfirmCancelRequest={this.handleConfirmCancelRequest}
          />
        </div>
        <Modal
          title=""
          open={this.state.isModalShow}
          onModalClose={this.closeModal}
          type="confirm"
          func={() => this.handleCancelRequest()}
          className="modal-travel-category"
        ></Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  myRequests: state.employeeRequestReducer.myRequests,
});

const mapDispatchToProps = {
  GetMyRequests,
  DisplayClientSideMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
