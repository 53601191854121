import React from "react";
import EmployeeDetails from "./employeeDetails";
import PropertyDetails from "./propertyDetails";
import AreaDetails from "./areaDetails";
import VehicleLoanDetails from "./vehicleLoanDetails";
import StaffODDetails from "./staffODDetails";
import StaffPersonalLoanDetail from "./staffPersonalLoanDetail";
import LoanRequirementDetails from "./loanRequirements";
import DocumentsAttached from "./documentsAttached";

function RequestedForm({ loanDetails }) {
  const { data } = loanDetails;
  return (
    <>
      <EmployeeDetails loanDetails={loanDetails} />
      {data == "PrimeHomeLoan" && <PropertyDetails />}
      {data == "PrimeHomeLoan" && <AreaDetails /> }
      {data == "PrimeVehicleLoan" && <VehicleLoanDetails /> }
      {data == "PrimeStaffODLoan" && <StaffODDetails /> }
      {(data == "PrimeStaffPersonalLoan" || data == "PrimeStaffAdvanceSalary") && <StaffPersonalLoanDetail /> }
      {data == "PrimeHomeLoan" && <LoanRequirementDetails />}
      <DocumentsAttached loanDetails={data}/>
    </>
  );
}

export default RequestedForm;
