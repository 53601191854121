import {
  GET_HIRING_TEAMS_LOADING,
  GET_HIRING_TEAMS,
  GET_JOB_HIRING_SUBSTAGES_LOADING,
  GET_JOB_HIRING_SUBSTAGES,
} from "store/types";
const initialState = {
  hiringTeams: [],
  hiringTeamLoading: false,
  hiringSubStages: [],
  hiringSubStageLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_HIRING_TEAMS_LOADING:
      return {
        ...state,
        hiringTeamLoading: action.payload,
      };
    case GET_HIRING_TEAMS:
      return {
        ...state,
        hiringTeams: action.payload,
        hiringTeamLoading: false,
      };
    case GET_JOB_HIRING_SUBSTAGES_LOADING:
      return {
        ...state,
        hiringSubStageLoading: action.payload,
      };
    case GET_JOB_HIRING_SUBSTAGES:
      return {
        ...state,
        hiringSubStages: action.payload,
        hiringSubStageLoading: false,
      };
    default:
      return state;
  }
}
