import Button from 'components/button';
import React, { useEffect } from 'react'
import FormGroup from 'components/formGroup'
import FormItem from 'components/formGroup/formItem';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getRosterTeamRoleList, updateRosterTeamEmployee, addRosterTeamEmployee } from 'scenes/roster/helpers/action';

function EmployeeEditForm(props) {
    const { editData, onModalClose } = props;

    const {
        teamList,
        teamRoleList,
        
    } = useSelector((state: any) => state.roosterReducer)
    const method = useForm()
    const {
        watch,
        setValue,
    } = method
    const dispatch = useDispatch();
    const onSubmit = (data) => {
        const final = {
            Id: editData?.Id ?? 0,
            TeamRoleId: data?.TeamRoleId?.value,
            RoleName: data?.TeamRoleId?.label,
            TeamId: data?.TeamId?.value,
            TeamName: data?.TeamId?.label,
            EmployeeId: editData?.EmployeeId ?? 0,
            EmployeeName: editData?.EmployeeName ?? '',

        }
        let res;
        if (editData?.Id === 0) {
            res = dispatch(addRosterTeamEmployee(final))
        } else {
            res = dispatch(updateRosterTeamEmployee(final))
        }

        if (res) {
            onModalClose && onModalClose()
        }
    }
    useEffect(() => {
        if (editData) {
            console.log({ editData, teamRoleList, teamList })
            editData && Object.keys(editData).map(item => {
                if (item === 'TeamRoleId') {
                    const red = teamRoleList?.find(item => item.value === editData['TeamRoleId'])
                    setValue("TeamRoleId", red)
                    return;
                }
                if (item === 'TeamId') {
                    const ted = teamList?.find(item => item.value === editData['TeamId'])
                    setValue("TeamId", ted)
                    return;
                }
                setValue(item, editData[item])
            });
        }
    }, [editData])
    const teamId = watch("TeamId")
    useEffect(() => {
        if (teamId) {
            dispatch(getRosterTeamRoleList(teamId?.value))
        } else if (editData) {
            dispatch(getRosterTeamRoleList(editData?.TeamId))
        }
    }, [teamId, editData])
    return (
        <FormGroup onSubmit={onSubmit} method={method} >
            <div className="sched-modal__container">
                <div className="sched-modal__body">
                    <FormItem
                        formName="reactselect"
                        name="TeamId"
                        placeholder="Filter Team"
                        width="300px"
                        options={teamList}
                        className="pb-sm"
                    />

                    <FormItem
                        formName="reactselect"
                        name="TeamRoleId"
                        placeholder="Filter Role"
                        width="300px"
                        options={teamRoleList}
                    />
                    
                </div>
                <div className="sched-modal__btns mt-md">
                    <Button title="Save" htmlButtonType="submit" bg="primary" />
                    <Button title="Cancel" bg="subtle" onClick={() => onModalClose()} />
                </div>
            </div>
        </FormGroup>

    )
}

export default EmployeeEditForm
