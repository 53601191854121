import React from 'react'
import './style.scss';
import Family from './family';
import Health from './health';
import './style.scss';

interface IAppProps {
  employeeId: any,
}

interface State {
  sections: any,
}

class index extends React.Component<IAppProps, State> {

  constructor(props) {
    super(props);
    this.state = {
      sections: [
        {
          name: 'Family',
          component: <Family {...props} />
        },
        {
          name: 'Health',
          component: <Health {...props} />
        },
      ]
    }
  }

  render() {
    return (
      <div className="education-container">
        {
          this.state.sections.map(sec => {
            return (
              <div className="education-body" >
                <div className="education-section">
                  {sec.component}
                </div>
              </div>
            )
          })
        }
      </div>
    );
  }
}

export default index;