import React from "react";
import moment from "moment";
import {
  getEngDate,
  getNepaliDate,
  getNepPreviousMonthWithDay,
} from "components/datePicker/helpers";
import { getMonthsDays } from "helpers/getDateMonthDates";

export const getPreviousNepaliMonth = () => {
  let previousMonthNepaliDate = getNepPreviousMonthWithDay();
  const previousMonthTotalDays = getMonthsDays(previousMonthNepaliDate.year)[
    previousMonthNepaliDate.month
  ];
  const previousMonthFirstNepaliDate =
    previousMonthNepaliDate.year +
    "/" +
    previousMonthNepaliDate.month +
    "/" +
    1;
  const previousMonthLastNepaliDate =
    previousMonthNepaliDate.year +
    "/" +
    previousMonthNepaliDate.month +
    "/" +
    previousMonthTotalDays;
  const previousMonthFirstNepaliDate_to_english = getEngDate(
    previousMonthFirstNepaliDate
  );
  const previousMonthLastNepaliDate_to_english = getEngDate(
    previousMonthLastNepaliDate
  );

  return {
    firstDate: previousMonthFirstNepaliDate_to_english,
    lastDate: previousMonthLastNepaliDate_to_english,
  };
};

export const getFirstAndLastNepaliDateOfCurrentMonthTillToday = () => {
  let nepaliDate = getNepaliDate();
  let splitedData = nepaliDate.split("/");
  const year = splitedData[0];
  const month = splitedData[1];
  const day = splitedData[2];

  const firstNepaliDate = year + "/" + month + "/" + 1;
  const firstNepaliDate_to_english = getEngDate(firstNepaliDate);

  // const lastNepaliDate_to_english = getEngDate(new Date());
  // const totalDays = getMonthsDays(year)[month];
  // const lastNepaliDate = year + "/" + month + "/" + totalDays;
  // const lastNepaliDate_to_english = getEngDate(lastNepaliDate);

  return {
    firstDate: firstNepaliDate_to_english,
    lastDate: moment(new Date()).format("YYYY/MM/DD"),
  };
};
