import React from "react";
import EmployeeDetails from "./employeeDetails";
// import PropertyDetails from "./propertyDetails";
// import AreaDetails from "./areaDetails";
// import LoanRequirementDetails from "./loanRequirements";
// import DocumentsAttached from "./documentsAttached";

function RequestedForm(props) {
  return (
    <>
      <EmployeeDetails />
      {/* <PropertyDetails />
      <AreaDetails />
      <LoanRequirementDetails />
      <DocumentsAttached /> */}
      
    </>
  );
}

export default RequestedForm;
