import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getPersonalDetail } from './action'
import "./personal.styles.scss";
import ContactInfo from './contactInfo'
import AddressDetail from './addressDetail';
import DocumentsDetail from './documentsDetail'
import EducationDetail from './educationDetail'
import HealthRecord from './healthRecord'
import PersonalDetails from './personalDetail'
import Loader from "components/loaders";
import Title from "../../common/title";

export class Personal extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    
    componentDidMount() {
        this.getPersonalDetail();
    }

    getPersonalDetail = () => {
        this.props.getPersonalDetail();
    };

    render() {
        const { 
            employeeDetail,
            isLoading
        } = this.props;
        return (
            <>
            
            {/* <PersonalHeader /> */}
            <div className="personal">
                { employeeDetail ? (
                <div className="personal-container">
                    <Title name="Personal  Information" />
                    <ContactInfo />

                    <PersonalDetails />

                    <AddressDetail />

                    <EducationDetail />
                    
                    <DocumentsDetail />
                
                    <HealthRecord />
                    </div> ) :
                    (isLoading && <Loader loading={isLoading}  />)
                }
            </div>
        </>
        );
    }
}

const mapStateToProps = state => ({
    employeeDetail: state.employeeDashboard.employeeDetail,
    isLoading: state.employeeDashboard.isLoading,
});

const mapDispatchToProps = {
    getPersonalDetail,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Personal));
