import React, { Component } from "react";
import classnames from "classnames";
import { expandIcon } from "../../common/expandIcon";
import { formatedDottDateWDay } from "../../../../helpers/dateFormat";

export class TableRows extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drop: false,
    };
    this.toggleDetail = this.toggleDetail.bind(this);
    this.dropdown = "";
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.dropdown && !this.dropdown.contains(event.target)) {
      this.setState({ drop: false });
    }
  }

  toggleDetail = (id, drop) => {
    this.setState({ drop: !drop });
    var toggleIcon = document.getElementById(`toggleIcon${id}`);
    toggleIcon.classList.toggle("expand");
    var detailsRow = document.querySelectorAll(`.details-${id}`);

    if (detailsRow) {
      for (var i = 0; i < detailsRow.length; i++) {
        detailsRow[i].classList.toggle("row-expand");
      }
    }
  };

  render() {
    const { props } = this;
    const { drop } = this.state;

    let trClass = "table-row";
    if (drop) {
      trClass = trClass + " expanded__tr";
    }
    return (
      <>
        <tr
          className={trClass}
          role="row"
          key={props.index}
          id={`toggleIcon${props.index}`}
          onClick={() => this.toggleDetail(props.index, drop)}
        >
          <td className="e-rowcell" role="gridcell">
            {props.Detail && props.Detail.length > 0 && expandIcon(drop)}
          </td>
          <td className="e-rowcell " role="gridcell">
            <span>{`${props.EmployeeName} (${props.EmployeeId})`}</span>
          </td>
          <td className="e-rowcell " role="gridcell">
            <span>{props.DepartmentName}</span>
          </td>
          <td className="e-rowcell " role="gridcell">
            <span>{props.ActivityNotSubmittedDays}</span>
          </td>
          <td className="e-rowcell " role="gridcell">
            <span>{props.WorkDays}</span>
          </td>
        </tr>

        {props.Detail &&
          props.Detail.map((d, i) => {
            const rowClasses = classnames({
              "table-details": true,
              "custom-row": true,
              "expanded__tr-details": true,
              [`details-${props.index}`]: true,
            });

            return (
              <tr className={rowClasses} key={i}>
                <td>
                  <span className="detail"></span>
                </td>
                <td>
                  <span className="detail"></span>
                </td>
                <td>
                  <span className="detail"></span>
                </td>

                <td>
                  <span className="detail">
                    {formatedDottDateWDay(d.DateEng)}
                  </span>
                </td>

                <td>
                  <span className="detail"></span>
                </td>
              </tr>
            );
          })}
      </>
    );
  }
}

export default TableRows;
