import axios from "axios";
import async from "react-select/async";
import {
  GET_ERRORS,
  GET_FEATURE_FLAG,
  GET_ACTIVE_FEATURE_FLAG,
  GET_ACTIVE_FEATURES_CATEGORIES,
} from "./types";
import { toastMessage } from "./validateAction";

export const getFeatureFlag =
  (): any =>
  async (dispatch: any): Promise<any> => {
    await axios
      .get("/v1/common/featureflags")
      .then((res) => {
        dispatch({
          type: GET_FEATURE_FLAG,
          payload: res.data,
        });
      })
      .catch((err) => {
        const response = {
          data: {
            MessageType: "Danger",
            Message: "Failed to get feature flags",
          },
        };
        toastMessage(dispatch, response);
      });
  };

export const getActiveFeatures =
  (): any =>
  async (dispatch: any) => {
    try {
      var res = await axios.get("/v1/common/active-feature-categories");
      dispatch({
        type: GET_ACTIVE_FEATURES_CATEGORIES,
        payload: res.data,
      });
    } catch (err) {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to get active features",
        },
      };
      toastMessage(dispatch, response);
    }
  
  };

export const getActiveFeatureFlag =
  (): any =>
  async (dispatch: any): Promise<any> => {
    await axios
      .get("/v1/common/active-featureflags")
      .then((res) => {
        dispatch({
          type: GET_ACTIVE_FEATURE_FLAG,
          payload: res.data,
        });
      })
      .catch((err) => {
        const response = {
          data: {
            MessageType: "Danger",
            Message: "Failed to get active feature flags",
          },
        };
        toastMessage(dispatch, response);
      });
  };

export const activeFlags =
  (data): any =>
  (dispatch) => {
    dispatch({
      type: GET_ACTIVE_FEATURE_FLAG,
      payload: data,
    });
  };
