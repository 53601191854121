import React, { Component } from "react";
import Avatar from "react-avatar";
import Icons from "components/icons";
import Button from 'components/button'
import LeaveRequestForm from "./leaveRequest/form";

export class MyRequests extends Component {
  state = {
    showLeaveRequestForm: false,
  };

  handleLeaveRequestForm = (value) => {
    this.setState({ showLeaveRequestForm: value });
  };

  render() {
    const { showLeaveRequestForm } = this.state;
    return (
      <div className="my-requests leave-block">
        <div className="bloch__title">My Leave Requests</div>
        <div className="leave-request">
          <div className="leave-request-top">
            <div className="leave-request-top-user__detail">
              <Avatar name={"name"} size={40} round={true} src={null} />
              <div className="name_designation">
                <span className="name_designation-name">Sumnima Shahi</span>
                <span className="name_designation-designation">
                  Finance Manager
                </span>
              </div>
            </div>

            <div className="leave-request-top-leave__detail">
              <div className="leave-request-top-leave__detail-type">
                <Icons name="Leave" size={25} />
                <span className="leave-request-top-leave__detail-type-name">
                  Home Leave
                </span>
              </div>
              <div className="leave-request-top-leave__detail-days">2 days</div>
              <div className="leave-request-top-leave__detail-date">
                {"Monday January 20 - Tuesday January 21, 2020"}
              </div>
            </div>
          </div>
          <div className="leave-request-mid">
            <div className="leave-request-mid-reason">Leave Reason</div>
            <div className="leave-request-mid-replacement">
              <span className="leave-request-mid-replacement-name">
                Carmela Lander
              </span>
              <span className="leave-request-mid-replacement-text">
                Replacement
              </span>
            </div>
          </div>
          <div className="leave-request-bottom">
            <div className="leave-request-bottom-left">
              <Button 
                  buttonType="icon-button"
                  leftIcon={<Icons name="Edit" className="flex" />}
                  bg="white"
                  onClick={() => this.handleLeaveRequestForm(true)}
                  title="Edit"
                />
            </div>
            <div className="leave-request-bottom-right">
              <div className="leave-request-bottom-right-status">
                <Avatar name={"name"} size={30} round={true} src={null} />
                <span className="leave-request-bottom-right-status-sender">
                  Carmela Lander
                </span>
                <span className="leave-request-bottom-right-status-arrow">
                  <Icons name="ArrowForward" />
                </span>
                <Avatar name={"name"} size={30} round={true} src={null} />
                <span className="leave-request-bottom-right-status-receiver">
                  Jhumpa Lehari
                </span>
              </div>
              <div className="leave-request-bottom-right-cancel">
                <Button 
                  buttonType="icon-button"
                  leftIcon={<Icons name="Close" className="flex" />}
                  bg="white"
                  title="Cancel"
                  // onClick={(e) => this.props.handleEdit(props.Id)}
                  />
              </div>
            </div>
          </div>
        </div>
        {showLeaveRequestForm && (
          <LeaveRequestForm closeForm={this.handleLeaveRequestForm} />
        )}
      </div>
    );
  }
}

export default MyRequests;
