import {
  SET_SPINNER_LOADING,
  GET_DISCIPLINARY_CASE_DETAILS,
  DISCIPLINARY_CASE_DETAILS_LOADING,
  GET_DISCIPLINARY_ACTION_LIST,
  GET_DISCIPLINARY_STAGES,
  MOVE_DISCIPLINARY_ACTION_TO_NS,
} from "actions/types";

const initialState = {
  spinnerLoading: false,
  disciplinaryCaseDetails: { loading: false, value: [] },
  disciplinaryStages: null,
  disciplinaryList: null,
  moveDisciplinaryResponse: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SPINNER_LOADING: {
      return {
        ...state,
        spinnerLoading: action.payload,
      };
    }
    case DISCIPLINARY_CASE_DETAILS_LOADING: {
      return {
        ...state,
        settlementReport: { loading: true, value: [] },
      };
    }
    case GET_DISCIPLINARY_CASE_DETAILS: {
      return {
        ...state,
        disciplinaryCaseDetails: { loading: false, value: action.payload },
      };
    }
    case GET_DISCIPLINARY_ACTION_LIST: {
      return {
        ...state,
        disciplinaryList: action.payload,
      };
    }
    case GET_DISCIPLINARY_ACTION_LIST: {
      return {
        ...state,
        disciplinaryList: action.payload,
      };
    }
    case GET_DISCIPLINARY_STAGES: {
      return {
        ...state,
        disciplinaryStages: action.payload,
      };
    }
    case MOVE_DISCIPLINARY_ACTION_TO_NS: {
      return {
        ...state,
        moveDisciplinaryResponse: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
