import React from 'react'
import env from 'environment'

function OnlyDev(props) {
    const {REACT_APP_ENVIRONMENT} = process.env;
    if(env.isDevelopment || env.isStaging){
        return props.children
    }

    // if(REACT_APP_ENVIRONMENT === "development" || REACT_APP_ENVIRONMENT === "staging"){
    //     return props.children
    // }
    return null;
}

export default OnlyDev
