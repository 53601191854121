import React from "react";

function AddressInformation(props) {
  const {profile} = props;

  return (
    <div className="information-wholeInfo-nav-block">
      <div className="information-wholeInfo-nav-block-title">
        <span>ADDRESS</span>
      </div>
      <div className="information-wholeInfo-nav-block-section">
        <div className="information-wholeInfo-nav-block-section-left">
          <div className="information-wholeInfo-nav-block-section-subtitle heading">
            <span className="fonts-light_medium ">Permanet Address</span>
          </div>
          <div className="information-wholeInfo-nav-block-section-subtitle">
            <span className="fonts-dark_medium">{profile.PDistrict} District</span>
          </div>

          <div className="information-wholeInfo-nav-block-section-subtitle">
            <span className="fonts-dark_medium">
              {profile.PLocalBody} - {profile.PWardNo}
            </span>
            <span className="fonts-dark_medium">{profile.PLocality}</span>
          </div>
        </div>
        <div>
          <div className="information-wholeInfo-nav-block-section-subtitle heading">
            <span className="fonts-light_medium  ">Current Address</span>
          </div>
          <div className="information-wholeInfo-nav-block-section-subtitle">
            <span className="fonts-dark_medium">{profile.CDistrict} District</span>
          </div>

          <div className="information-wholeInfo-nav-block-section-subtitle">
            <span className="fonts-dark_medium">
              {profile.CLocalBody} - {profile.CWardNo}
            </span>
            <span className="fonts-dark_medium">{profile.CLocality}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddressInformation;
