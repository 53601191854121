import React from "react";
import "../style.scss";
import {
  getPrimeLoanSalaryHead,
  getPrimeLoanSavedSalaryHead,
} from "../../../helpers/action";
import { connect } from "react-redux";
import { currencyCommaSeperator } from "../../../helpers/currencyFormatter";
import moment from "moment";
import Loader from "components/loaders";
import isEmpty from "helpers/isEmpty";
class SalaryDetails extends React.Component {
  constructor() {
    super(...arguments);
    this.state = {
      ShowNewRecordForm: false,
      LoanName: "",
      Outstanding: "",
      AsOnDate: new Date(),
      MonthlyDeduction: "",
      CompletesOn: new Date(),
      employeeOutstandingLoan: [],
      EditView: false,
      errors: {},
      incomeHeads: [],
      deductionHeads: [],
      isLoading: true,
    };
    this.template = this.gridTemplate;
  }
  async componentDidMount() {
    let {
      loanDetails: {
        EmployeeId,
        Status,
        data,
        LoanId,
        NoOfLoanTaken,
        DisbursementDate,
      },
      employeePrimeLoanSalary,
    } = this.props;
    if (EmployeeId) {
      let loanData = {
        EmployeeId,
        LoanType: data,
        LoanId,
      };
      // if (isEmpty(employeePrimeLoanSalary)) {
      if (Status == 1) await this.props.getPrimeLoanSalaryHead(EmployeeId);
      else await this.props.getPrimeLoanSavedSalaryHead(loanData);
      // }
      employeePrimeLoanSalary = this.props.employeePrimeLoanSalary;
      let incomeHeads = [];
      let deductionHeads = [];
      let incomeTotal = 0;
      let deductionTotal = 0;
      let netSalary = 0;

      employeePrimeLoanSalary &&
        employeePrimeLoanSalary.forEach((loan) => {
          if (loan.IsIncome) {
            incomeTotal = incomeTotal + parseFloat(loan.Amount);
            incomeHeads.push({
              ...loan,
              Amount: currencyCommaSeperator(loan.Amount),
            });
          } else {
            deductionTotal = deductionTotal + parseFloat(loan.Amount);
            deductionHeads.push({
              ...loan,
              Amount: currencyCommaSeperator(loan.Amount),
            });
          }
        });
      netSalary = incomeTotal - deductionTotal;
      incomeTotal = currencyCommaSeperator(incomeTotal);
      deductionTotal = currencyCommaSeperator(deductionTotal);
      this.setState({
        incomeHeads,
        deductionHeads,
        incomeTotal,
        deductionTotal,
        netSalary: currencyCommaSeperator(netSalary),
        EmployeeId,
        Status,
        data,
        LoanId,
        isLoading: false,
        NoOfLoanTaken,
        DisbursementDate,
      });
    }
  }

  render() {
    const {
      incomeHeads,
      deductionHeads,
      incomeTotal,
      deductionTotal,
      netSalary,
      Status,
      NoOfLoanTaken,
      DisbursementDate,
      data,
      isLoading,
    } = this.state;
    let distribShown =
      data == "PrimeStaffPersonalLoan" || data == "PrimeVehicleLoan";
    if (isLoading) return <Loader loading={isLoading} />;
    return (
      <div className="homeloan-view-salaryDetails healthCard__health">
        <h3 className="text-grey mt-md mb-md">Salary Information </h3>

        <div className="healthCard-heading">
          <span className="bold-500 mb-sm">Incomes</span>
        </div>
        <div className="homeloan-view-salaryDetails__block">
          <div className="homeloan-view-salaryDetails__table">
            {incomeHeads &&
              incomeHeads.map((head) => (
                <div className={`homeloan-view-salaryDetails__row`}>
                  <div
                    className={`placement-details__col placement-title bold-500`}
                  >
                    {head.SalaryHead}
                  </div>
                  <div className={`placement-details__col placement-val`}>
                    {head.Amount || "-"}
                  </div>
                </div>
              ))}
          </div>
          <div className="homeloan-view-salaryDetails__table salary_total">
            <div className="homeloan-view-salaryDetails__row total-row">
              <div className="placement-details__col total-row-salaryhead align-right bold-500">
                Gross Salary
              </div>
              <div className="placement-details__col placement-val salary_total_value">
                NPR {incomeTotal || "-"}
              </div>
            </div>
          </div>
        </div>

        <div className="healthCard-heading">
          <span className="bold-500 mb-sm">Deductions</span>
        </div>
        <div className="homeloan-view-salaryDetails__block">
          <div className="homeloan-view-salaryDetails__table">
            {deductionHeads &&
              deductionHeads.map((head) => (
                <div className={`homeloan-view-salaryDetails__row`}>
                  <div
                    className={`placement-details__col placement-title bold-500`}
                  >
                    {head.SalaryHead}
                  </div>
                  <div className={`placement-details__col placement-val`}>
                    {head.Amount || "-"}
                  </div>
                </div>
              ))}
          </div>
          <div className="homeloan-view-salaryDetails__table salary_total">
            <div className="homeloan-view-salaryDetails__row total-row">
              <div className="placement-details__col total-row-salaryhead align-right bold-500">
                Total Deduction
              </div>
              <div className="placement-details__col placement-val salary_total_value">
                NPR {deductionTotal || "-"}
              </div>
            </div>
          </div>
        </div>

        <div className="homeloan-view-salaryDetails__block">
          <div className="homeloan-view-salaryDetails__table salary_total">
            <div className="homeloan-view-salaryDetails__row total-row">
              <div className="placement-details__col total-row-takehome bold-500">
                Net Take Home Salary
              </div>
              <div className="placement-details__col placement-val salary_total_value">
                NPR {netSalary || "-"}
              </div>
            </div>
          </div>
        </div>
        {/* {Status > 2 && distribShown && (
          <>
            <div className="homeloan-view-salaryDetails__block">
              <div className="homeloan-view-salaryDetails__table salary_total">
                <div className="homeloan-view-salaryDetails__row total-row">
                  <div className="placement-details__col total-row-takehome bold-500">
                    Number of time loan Taken:
                  </div>
                  <div className="placement-details__col placement-val salary_total_value">
                    {NoOfLoanTaken || "-"}
                  </div>
                </div>
              </div>
            </div>
            <div className="homeloan-view-salaryDetails__block">
              <div className="homeloan-view-salaryDetails__table salary_total">
                <div className="homeloan-view-salaryDetails__row total-row">
                  <div className="placement-details__col total-row-takehome bold-500">
                    Latest Disbursement Date
                  </div>
                  <div className="placement-details__col placement-val salary_total_value">
                    {!isEmpty(DisbursementDate)
                      ? moment(DisbursementDate).format("YYYY/MM/DD")
                      : "-"}
                  </div>
                </div>
              </div>
            </div>
          </>
        )} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  employeePrimeLoanSalary: state.loanReducer.employeePrimeLoanSalary, // initial fetch
});

const mapDispatchToProps = {
  getPrimeLoanSalaryHead,
  getPrimeLoanSavedSalaryHead,
};

export default connect(mapStateToProps, mapDispatchToProps)(SalaryDetails);
