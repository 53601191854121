import React from "react";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";

function NumberToCash(props: any) {
  const { value, toFixed, showPrefix } = props;
  return (
    <NumberFormat
      value={value && parseFloat(value).toFixed(toFixed)}
      displayType={"text"}
      thousandSeparator={true}
      thousandsGroupStyle="lakh"
      prefix={showPrefix ? "Rs " : ""}
    />
  );
}

NumberToCash.propTypes = {
  value: PropTypes.any,
  toFixed: PropTypes.number,
  showPrefix: PropTypes.bool,
};

NumberToCash.defaultProps = {
  value: null,
  toFixed: 0,
  showPrefix: false,
};

export default NumberToCash;
