import {
  GET_BRANCHES,
  BRANCH_SELECT_LOADING,
  GET_EMPLOYEES,
  EMPLOYEE_SELECT_LOADING,
  GET_DEPARTMENTS,
  DEPARTMENT_SELECT_LOADING,
  GET_FISCAL_YEAR,
  GET_PAYROLL_PERIOD,
  PAYROLL_PERIOD_LOADING,
  FISCAL_LOADING,
  GET_LEAVE_TYPES,
  GET_SALARY_TYPES,
  SALARY_TYPES_SELECT_LOADING,
  LEAVE_TYPES_SELECT_LOADING,
  SUB_DEPARTMENT_SELECT_LOADING,
  GET_SUB_DEPARTMENTS,
  SELECTED_PAYROLL,
  DESIGNATION_SELECT_LOADING,
  GET_DESIGNATION,
  GET_LOCATION,
  LOCATION_SELECT_LOADING,
  GET_GENDERS,
  GENDER_SELECT_LOADING,
  GET_PROVINCE_LIST,
  GET_MARITAL_STATUS,
  MARITAL_STATUS_SELECT_LOADING,
  GET_EMPLOYEE_STATES,
  LEVELS_SELECT_LOADING,
  GET_LEVELS,
  EMPLOYEE_STATES_SELECT_LOADING,
  GET_JOB_STATUS_SELECT,
  JOB_STATUS_SELECT_LOADING,
} from "../actions/types";

const initialState = {
  branch: { loading: false, value: [] },
  employee: { loading: false, value: [] },
  department: { loading: false, value: [] },
  subDepartment: { loading: false, value: [] },
  fiscal: { loading: false, value: [] },
  salaryTypes: { loading: false, value: [] },
  leaveTypes: { loading: false, value: [] },
  payrollPeriod: { loading: false, value: [] },
  selectedPayPeriod: {},
  location: { loading: false, value: [] },
  genders: { loading: false, value: [] },
  maritialStatus: { loading: false, value: [] },
  levels: { loading: false, value: [] },
  jobStatus: { loading: false, value: [] },
  employeeStates: { loading: false, value: [] },
  designations: { loading: false, value: [] },
  provinceList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BRANCH_SELECT_LOADING:
      return {
        ...state,
        branch: { loading: true, value: [] },
      };
    case GET_BRANCHES:
      return {
        ...state,
        branch: { loading: false, value: action.payload },
    };
    case GET_PROVINCE_LIST: {
      return {
        ...state,
        provinceList: action.payload,
      };
    }
    case EMPLOYEE_SELECT_LOADING:
      return {
        ...state,
        employee: { loading: true, value: [] },
      };
    case GET_EMPLOYEES:
      return {
        ...state,
        employee: { loading: false, value: action.payload },
      };
    case DEPARTMENT_SELECT_LOADING:
      return {
        ...state,
        department: { loading: true, value: [] },
      };
    case GET_DEPARTMENTS:
      return {
        ...state,
        department: { loading: false, value: action.payload },
      };
    case SUB_DEPARTMENT_SELECT_LOADING:
      return {
        ...state,
        sub_department: { loading: true, value: [] },
      };
    case GET_SUB_DEPARTMENTS:
      return {
        ...state,
        subDepartment: { loading: false, value: action.payload },
      };
    case LEVELS_SELECT_LOADING:
      return {
        ...state,
        levels: { loading: true, value: [] },
      };
    case GET_LEVELS:
      return {
        ...state,
        levels: { loading: false, value: action.payload },
      };
    case JOB_STATUS_SELECT_LOADING:
      return {
        ...state,
        jobStatus: { loading: true, value: [] },
      };
    case GET_JOB_STATUS_SELECT:
      //selecte reducer
      return {
        ...state,
        jobStatus: { loading: false, value: action.payload },
      };
    case LEAVE_TYPES_SELECT_LOADING:
      return {
        ...state,
        leaveTypes: { loading: true, value: [] },
      };
    case GET_LEAVE_TYPES:
      return {
        ...state,
        leaveTypes: { loading: false, value: action.payload },
      };
    case SALARY_TYPES_SELECT_LOADING:
      return {
        ...state,
        salaryTypes: { loading: true, value: [] },
      };
    case GET_SALARY_TYPES:
      return {
        ...state,
        salaryTypes: { loading: false, value: action.payload },
      };
    case FISCAL_LOADING:
      return {
        ...state,
        fiscal: { loading: true, value: [] },
      };
    case GET_FISCAL_YEAR:
      return {
        ...state,
        fiscal: { loading: false, value: action.payload },
      };
    case DESIGNATION_SELECT_LOADING:
      return {
        ...state,
        designations: { loading: true, value: [] },
      };
    case GET_DESIGNATION:
      return {
        ...state,
        designations: { loading: false, value: action.payload },
      };
    case LOCATION_SELECT_LOADING:
      return {
        ...state,
        location: { loading: true, value: [] },
      };
    case GET_LOCATION:
      return {
        ...state,
        location: { loading: false, value: action.payload },
      };
    case EMPLOYEE_STATES_SELECT_LOADING:
      return {
        ...state,
        employeeStates: { loading: true, value: [] },
      };
    case GET_EMPLOYEE_STATES:
      return {
        ...state,
        employeeStates: { loading: false, value: action.payload },
      };
    case MARITAL_STATUS_SELECT_LOADING:
      return {
        ...state,
        maritialStatus: { loading: true, value: [] },
      };
    case GET_MARITAL_STATUS:
      return {
        ...state,
        maritialStatus: { loading: false, value: action.payload },
      };
    case GENDER_SELECT_LOADING:
      return {
        ...state,
        genders: { loading: true, value: [] },
      };
    case GET_GENDERS:
      return {
        ...state,
        genders: { loading: false, value: action.payload },
      };
    case PAYROLL_PERIOD_LOADING:
      return {
        ...state,
        payrollPeriod: { loading: true, value: [] },
      };
    case GET_PAYROLL_PERIOD:
      return {
        ...state,
        payrollPeriod: { loading: false, value: action.payload },
      };
    case SELECTED_PAYROLL:
      return {
        ...state,
        selectedPayPeriod: action.payload,
      };
    default:
      return state;
  }
}
