import React, { Component } from "react";
import { connect } from "react-redux";
import './style.scss';
import Form from "components/form";
import Button from "components/button";
import { InputValidator } from "helpers/inputValidator";
import isEmpty from "isEmpty";
import { GetCountryList } from "actions/commonAction";
import { monthsValue, getYears } from 'constants/ydmValue';
import {
    saveEmployeeExperienceDetails,
    updateEmployeeExperienceDetails,
    getEmployeeExperienceList
} from '../action';
class WorkExperienceForm extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            showAddSection: false,
            editView: false,
            errors: {},
            Organization: '',
            JobResponsibility: '',
            Place: '',
            Position: '',
            Country: [],
            Year: null,
            Files: [],
            UserFileName: '',
            FromDate: {},
            ToDate: {},
        }
    }

    async componentDidMount () {
        const {
            editViewData,
            editView,
            countryList,
            GetCountryList
        } = this.props;
        if(isEmpty(countryList)){
            await GetCountryList();
        }
        if (editView) {
            const index = countryList && countryList.findIndex(e => e.value == editViewData.CountryId);
            const fromMonthindex = monthsValue.findIndex(e => e.value == editViewData.FromMonth);
            let yearsArray = getYears();
            const fromYearIndex = yearsArray.findIndex(e => e.value == parseInt(editViewData.FromYear));
            const toMonthIndex = monthsValue.findIndex(e => e.value == editViewData.ToMonth);
            const toYearIndex = yearsArray.findIndex(e => e.value == parseInt(editViewData.ToYear));
            this.setState({
                ...editViewData,
                Files: {
                    name: editViewData.UserFileName
                },
                Country: countryList[index],
                FromDate: {
                    months: monthsValue[fromMonthindex],
                    years: yearsArray[fromYearIndex]
                },
                ToDate: {
                    months: monthsValue[toMonthIndex],
                    years: yearsArray[toYearIndex]
                }
            });
        }
    }

    handleInput = (name, value) => {
        console.log()
        this.setState(
            { [name]: value, },
            () => {
                this.updateErrors();
            }
        );
    }

    updateErrors = async () => {
        const { errors } = this.state;
        if (!isEmpty(errors)) {
            let errors = await InputValidator(document, this.state);
            let ToDate = this.toFromdateValidator() ? 'Required' 
                        : this.experienceValidation() ? 'Invalid Date' : null;
            this.setState({ errors: {...errors, ToDate }});
        }
    };

    handleAutoSelect = (label, value) => {
        const { errors } = this.state
        this.setState({ [label]: value }, () => {
            this.updateErrors()
        });
    }
    // , errors: { ...errors, [label]: null } }
    toFromdateValidator = () => {
        const { FromDate, ToDate } = this.state;
        return (FromDate.months == null || FromDate.years == null
            || ToDate.months == null || ToDate.years == null);
    }

    experienceValidation = () => {
        const { FromDate, ToDate, errors } = this.state;
        if ((FromDate.years.value > ToDate.years.value
            || (FromDate.years.value == ToDate.years.value
            && FromDate.months.value > ToDate.months.value))
            || (ToDate.years.value == new Date().getFullYear()
            && ToDate.months.value > (new Date().getMonth()+ 1))
            || ToDate.years.value > new Date().getFullYear()
            ) {
            this.setState({ errors: { ...errors, ToDate: 'Invalid Date' } })
            return true;
        } else return false
    }

    handleSave = async () => {
        let errors = await InputValidator(document, this.state);
        let {
            FromDate,
            ToDate
        } = this.state
        if (!isEmpty(errors)) {
            if (this.toFromdateValidator())
                this.setState({
                    errors: {
                    ...errors,
                    ToDate: 'Required'
                    }
                });
            else this.setState({ errors });
            return;
        } else if (this.toFromdateValidator()) {
            this.setState({
                errors: { 
                    // ...this.state.errors,
                    ToDate: 'Required'
                }
            });
            return
        }
        if (this.experienceValidation()) return;
        let {
            Organization,
            Place,
            JobResponsibility,
            Country,
            Position,
        } = this.state;

        let data = {
            EmployeeId: parseInt(this.props.employeeId),
            PrevEmploymentId: this.props.editView ? this.props.editViewData.PrevEmploymentId : 0,
            Organization,
            Place,
            JobResponsibility,
            Position,
            FromMonth: FromDate.months && FromDate.months.value,
            FormYear: FromDate.years && FromDate.years.value,
            ToMonth: ToDate.months && ToDate.months.value,
            ToYear: ToDate.years && ToDate.years.value,
            CountryId: Country.value,
        }
        if (this.props.editView)
            await this.props.updateEmployeeExperienceDetails(data);
        else
            await this.props.saveEmployeeExperienceDetails(data);
        if (this.props.employeeExperienceSaved) {
            this.props.getEmployeeExperienceList(this.props.employeeId);
            this.closeAddSection(false)
        }
    }


    closeAddSection = () => {
        if (this.props.editView) this.props.toggleEditView();
        else this.props.closeAddSection();
    }

    render() {
        const {
            Organization,
            Place,
            Position,
            errors,
            Country,
            JobResponsibility,
            FromDate,
            ToDate,
        } = this.state;

        const { editView, countryList } = this.props;
        let errorDate = errors.ToDate ? 'education__datePickerError_label' : '';
        let errorInputDate = errors.ToDate ? 'education__datePickerError' : '';
        return (
            <div className="education__education__body workAndExperience">
                <div className="education__education__div">
                    <div className="education__education__div__label workAndExperience__workBody">
                        <div className="education__education__labels">
                            <Form
                                name="Organization"
                                value={Organization}
                                onChange={this.handleInput}
                                alignment="horizontal"
                                label="Organization"
                                validators={["required"]}
                                error={errors && errors.Organization}
                            />
                        </div>
                        <div className="education__education__labels">
                            <Form
                                formName="reactselect"
                                name="Country"
                                value={Country}
                                options={countryList}
                                onChange={this.handleAutoSelect}
                                alignment="horizontal"
                                label="Country"
                                placeholder="Select.."
                                validators={['required']}
                                error={errors && errors.Country}
                                
                            />
                        </div>
                        <div className="education__education__labels">
                            <Form
                                name="Place"
                                value={Place}
                                onChange={this.handleInput}
                                alignment="horizontal"
                                label="Place"
                                validators={["required"]}
                                error={errors && errors.Place}
                            />
                        </div>
                        <div className="education__education__labels education__education__year">
                            <div className="education__education__year-group">
                                <label htmlFor="FromDate" className={errorDate}>Year *</label>
                                <div className={errorInputDate}>
                                    <div className="education__education__year-inputs">
                                        <Form
                                            formName="ydmInput"
                                            name="FromDate"
                                            alignment="horizontal"
                                            onChange={this.handleAutoSelect}
                                            value={FromDate}
                                            validators={['required']}
                                            inputShow={['months','years']}
                                            // error={errors && errors.FromDate}
                                        />
                                        <Form
                                            formName="ydmInput"
                                            name="ToDate"
                                            alignment="horizontal"
                                            onChange={this.handleAutoSelect}
                                            value={ToDate}
                                            validators={['required']}
                                            inputShow={['months','years']}
                                            // error={errors && errors.ToDate}
                                        />
                                        {errors && errors.ToDate && <div class={errorDate}>{errors.ToDate}</div>}
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="education__education__labels">
                            <Form
                                name="Position"
                                value={Position}
                                onChange={this.handleInput}
                                alignment="horizontal"
                                label="Position"
                                validators={['required']}
                                error={errors && errors.Position}
                            />
                        </div>
                        <div className="education__education__labels">
                            <Form
                                formName="textarea"
                                name="JobResponsibility"
                                value={JobResponsibility}
                                onChange={this.handleInput}
                                alignment="horizontal"
                                label="Key Responsibilities"
                                validators={["required"]}
                                error={errors && errors.JobResponsibility}
                                height="60px"
                            />
                        </div>
                        <div className="button-group button-group__right education__savebutton">
                            <Button bg="primary" onClick={this.handleSave}>{!editView ? 'Add' : 'Update' }</Button>
                            <Button onClick={() => this.closeAddSection(false)} bg="secondary">
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    employeeExperienceSaved: state.employeeProfile.employeeExperienceSaved,
    countryList: state.commonReducer.countryList || [],
});

const mapDispatchToProps = {
    saveEmployeeExperienceDetails,
    updateEmployeeExperienceDetails,
    getEmployeeExperienceList,
    GetCountryList,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkExperienceForm);