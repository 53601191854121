import isEmpty from "isEmpty";
export default function validateJobRequisition(values) {
  let errors = {};
  debugger
  if (!values.InternalJobTitle && values.IsInternalJob) {
    errors.InternalJobTitle = "Required";
  }
  if (!values.ExternalJobTitle && !values.IsInternalJob) {
    errors.ExternalJobTitle = "Required";
  }
  if (!values.EmploymentType) {
    errors.EmploymentType = "Required";
  }
  if (!values.Division) {
    errors.Division = "Required";
  }
  if (!values.Branch) {
    errors.Branch = "Required";
  }
  // if (!values.Location) {
  //   errors.Location = "Required";
  // }
  if (!values.Openings) {
    errors.Openings = "Required";
  }
  if (!values.EducationLevel) {
    errors.EducationLevel = "Required";
  }
  // if (!values.AdditionalQualification) {
  //   errors.AdditionalQualification = "Required";
  // }
  if (isEmpty(values.JobStartDate)) {
    errors.JobStartDate = "Required";
  }
  if (isEmpty(values.JobEndDate)) {
    errors.JobEndDate = "Required";
  }
  if (values.JobStartDate !== null || values.JobEndDate !== null) {
    if (
      values.JobStartDate === null ||
      values.JobStartDate >= values.JobEndDate
    ) {
      errors.JobStartDate = "Start date must be less than end date";
    }
  }
  if (values.IsExperienceRequired) {
    if (!values.NoofExperience) {
      errors.IsExperienceRequired = "No of experience required";
    } else if (!values.ExperienceDurationType) {
      errors.IsExperienceRequired = "Duration is required";
    }
  }
  return errors;
}
