import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import FormGroup from "components/form";
import Modal from "components/modal";
import isEmpty from "isEmpty";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Consumer } from "../helpers/context";
import HiringTeamTable from "./hiringTeamTable";
import HiringTeamModal from "./components/hiringTeamModal";

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 20,
    height: 20,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#d9d9d9",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 20,
      height: 20,
      backgroundImage: "radial-gradient(#666,#666 40%,transparent 44%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#d1d1d1",
    },
  },
});

// Inspired by blueprintjs
function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      onChange={props.onChange}
      checkedIcon={
        <span
          className={classnames({
            [classes.icon]: true,
            [classes.checkedIcon]: true,
          })}
        />
      }
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

export class Hiring_Team extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      user: [],
      errors: {},
      access: "",
    };
    this.onModalClose = this.onModalClose.bind(this);
    this._renderForm = this._renderForm.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onRadioChange = this.onRadioChange.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.removeUser = this.removeUser.bind(this);
  }
  onModalClose = () => {
    this.setState({ modalOpen: false });
  };
  onSelectChange(name, value) {
    this.setState({ [name]: value });
  }
  componentDidUpdate(prevProps, prevState) {
    const { HiringTeam, employeeList } = this.props;
    if (HiringTeam !== prevProps.HiringTeam && !isEmpty(employeeList)) {
    }
  }
  onAdd() {
    const { access, user } = this.state;
    const { isAuth, errors } = this.onValidate({ access, user });
    this.setState({ errors });
    if (!isAuth) {
      return false;
    }
    const { employeeList } = this.props;
    const selectedEmployee = employeeList.find(
      (x) => x.EmployeeId === user.value
    );
    const data = {
      Id: 0,
      JobRef_Id: 0,
      UserId: user.value,
      HasStandardAccess: access !== "all",
      HasAllAccess: access === "all",
      Name: selectedEmployee.FullName,
      Email: selectedEmployee.Email,
      Designation: selectedEmployee.Designation,
      //UserId: shortid.generate(),
      ImagePath: selectedEmployee.Imagepath,
    };
    const { dispatch } = this.props;
    dispatch({ type: "addHiringTeam", payload: data });
    this.setState({ modalOpen: false, user: [] });
    console.log("done");
  }
  removeUser(id) {
    const { dispatch } = this.props;
    dispatch({ type: "removeHiringTeam", payload: id });
  }
  onValidate(data) {
    const { access, user } = data;
    const errors = {};
    if (isEmpty(access)) {
      errors.access = "Access Field is Required";
    }
    if (isEmpty(user)) {
      errors.user = "User Field is Required";
    }
    return { isAuth: isEmpty(errors), errors };
  }
  onRadioChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    console.log(this.state);
  }

  _renderForm() {
    const { errors } = this.state;
    const standard = (
      <div className="hiringTeam-form__radioLabel">
        <h4>Standar Access</h4>
        <p>Standard Access user can collaborate on this job</p>
      </div>
    );
    const all = (
      <div className="hiringTeam-form__radioLabel">
        <h4>All Access</h4>
        <p>All Access user can modify,publish or unpublish this job</p>
      </div>
    );
    const { HiringTeam, employeeListSelect } = this.props;
    let options = [];
    employeeListSelect &&
      employeeListSelect.map((x) => {
        if (
          !isEmpty(HiringTeam) &&
          HiringTeam.filter((y) => y.UserId === x.value).length === 0
        ) {
          options.push(x);
        }
      });
    console.log(employeeListSelect);
    return (
      <div className="hiringTeam-modal">
        <div className="hiringTeam-form">
          <div
            className={classnames({
              "hiringTeam-form__input": true,
              "auto-error": !isEmpty(errors.user),
            })}
          >
            <label className="label" htmlFor="user">
              Select a user
            </label>
            <FormGroup
              formName="reactselect"
              name="user"
              onChange={this.onSelectChange}
              value={this.state.user}
              options={options}
              // label=""
              width={"100%"}
            />
            {errors.user && (
              <span className="auto-error__message">{errors.user}</span>
            )}
          </div>
          <div className="hiringTeam-form__input">
            <RadioGroup defaultValue={""} aria-label="access" name="access">
              <FormControlLabel
                value="standard"
                control={<StyledRadio onChange={this.onRadioChange} />}
                label={standard}
                style={{ marginBottom: 20 }}
              />
              <FormControlLabel
                value="all"
                control={<StyledRadio onChange={this.onRadioChange} />}
                label={all}
              />
            </RadioGroup>
            {errors.access && (
              <span className="auto-error__message">{errors.access}</span>
            )}
          </div>
        </div>
        <div className="hiringTeam-modal__footer modal-footer">
          <button onClick={() => this.onAdd()} className="btn btn-blue">
            Add
          </button>
          <button
            onClick={() => this.setState({ modalOpen: false })}
            className="btn btn-grey2"
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }
  // handleChange = (name, value) => {
  //   this.setState({ [name]: value });
  //   console.log("this.settate", this.state);
  // };
  // handleOnAdd = (data) => {
  //   const { dispatch } = this.props;
  //   dispatch({ type: "addHiringTeam", payload: data });
  //   this.setState({ modalOpen: false, user: [] });
  //   console.log("done");
  // };
  render() {
    const { HiringTeam } = this.props;

    return (
      <div className="hiringTeam">
        <h3 className="tertiary-title">Hiring Team</h3>
        <div className="hiringTeam-container">
          <h4 className="hiringTeam-container__title">Team Members</h4>
          <HiringTeamTable
            HiringTeam={HiringTeam}
            removeUser={this.removeUser}
          />
        </div>
        <div className="hiringTeam-button">
          <button
            onClick={() => this.setState({ modalOpen: true })}
            className="btn-outline btnv__black"
          >
            Add a new member
          </button>
        </div>

        {/* <HiringTeamModal
          state={this.state}
          showModal={this.state.modalOpen}
          HiringTeam={HiringTeam}
          handleModelClose={(val) => {
            this.setState({ modalOpen: val });
          }}
          handleOnAdd={this.handleOnAdd}
          handleChange={this.handleChange}
        /> */}

        <Modal
          open={this.state.modalOpen}
          onModalClose={this.onModalClose}
          className="hiring-stages-modal"
          title={"Add a new member"}
        >
          {this._renderForm()}
        </Modal>
      </div>
    );
  }
}

Hiring_Team.propTypes = {
  employeeListSelect: PropTypes.array.isRequired,
  employeeList: PropTypes.array.isRequired,
};

export const mapStateToProps = (state) => ({
  employeeList: state.commonReducer.employeeList,
  employeeListSelect: state.commonReducer.employeeListSelect,
});

const HiringTeam = (props) => (
  <Consumer>
    {(value) => {
      const {
        jobCreate: { RecruitmentJobHiringTeamDto },
        jobHiringTeam,
        dispatch,
      } = value;
      return (
        <Hiring_Team
          {...props}
          dispatch={dispatch}
          HiringTeam={jobHiringTeam}
        />
      );
    }}
  </Consumer>
);

export default connect(mapStateToProps, {})(HiringTeam);
