import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { ValidationComponent } from "helpers/getComponents";
import Form from "components/form";
import Button from "components/button";
import Modal from "components/modal";
import { SaveEmailTemplates } from "store/actions/recruitment/setting";

// const editorConfiguration = {
//   //plugins: [ Essentials, Bold, Italic, Paragraph ],
//   toolbar: ["bold", "italic"],
//   toolbarLocation: "right",
// };

function AddForm(props: any) {
  const { dataToEdit, jobStageList } = props;
  const [cEditor, setEditor] = useState(null);

  const [state, setState] = useState<any>({
    id: 0,
    name: "",
    stage: "",
    subject: "",
    body: "",
  });
  const errors: any = {};

  useEffect(() => {
    if (dataToEdit && jobStageList && jobStageList.length > 0) {
      const activeStage =
        jobStageList.find((f) => f.value == dataToEdit.StageId) || null;

      setState({
        ...state,
        id: dataToEdit.Id,
        name: dataToEdit.TemplateName,
        stage: activeStage,
        subject: dataToEdit.Subject,
        body: dataToEdit.Body,
      });
    }
  }, [dataToEdit, jobStageList]);

  const onChange = (name, value) => {
    setState({ ...state, [name]: value });
  };

  const onSubmit = () => {
    const param = {
      id: state.id,
      stageId: state.stage ? state.stage.value : 0,
      templateName: state.name,
      subject: state.subject,
      body: state.body,
    };

    props.SaveEmailTemplates(param, onSuccess);
  };

  const onSuccess = () => {
    props.getEmailTemplates();
    closeModal();
  };

  const closeModal = () => {
    setState({
      ...state,
      id: "",
      name: "",
      stage: "",
      subject: "",
      body: "",
    });
    props.handleModal(false);
  };

  const addPlaceHolder = (placeholder) => {
    if (!cEditor) {
      return;
    }
    const content = `<b>${placeholder}</b>`;
    const viewFragment = cEditor.data.processor.toView(content);
    const modelFragment = cEditor.data.toModel(viewFragment);
    cEditor.model.insertContent(modelFragment);
  };

  return (
    <Modal
      title="Email Template"
      open={props.show}
      onModalClose={closeModal}
      className="modal-email-template"
    >
      <div className="dashboard-compose-email">
        <div className="form">
          <div className="form-row">
            <div className="col-3">
              <Form
                formName="textinput"
                name="name"
                value={state.name}
                label="Template Name"
                onChange={onChange}
              />
            </div>
            <div className="col-3">
              <Form
                formName="reactselect"
                onChange={onChange}
                name="stage"
                label="Stage"
                value={state.stage}
                options={props.jobStageList}
                placeholder="Select Stage"
              />
            </div>
          </div>

          <div className="form-row">
            <Form
              formName="textinput"
              name="subject"
              value={state.subject}
              label="Subject Name"
              onChange={onChange}
            />
          </div>
          <div className="editor">
            <div>E-mail Text</div>
            <div className="editor-top">
              <label>Available Placeholders</label>
              <Button
                bg="secondary"
                title="{Applicant_Name}"
                onClick={() => addPlaceHolder("{Applicant_Name}")}
              />
              <Button
                bg="secondary"
                title="{Job_ID}"
                onClick={() => {
                  addPlaceHolder("{Job_ID}");
                }}
              />
            </div>

            <CKEditor
              name="myeditor"
              editor={ClassicEditor}
              data={state.body}
              onChange={(event, editor) => {
                const data = editor.getData();
                onChange("body", data);
                console.log({ event, editor, data });
                setEditor(editor);
              }}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.

                setEditor(editor);
                console.log("Editor is ready to use!", editor);
              }}

              // onBlur={(event, editor) => {
              //   console.log("Blur.", editor);
              // }}
              // onFocus={(event, editor) => {
              //   console.log("Focus.", editor);
              // }}
            />
          </div>
          <div className="footer-row">
            <Button bg="danger" title="Cancel" onClick={closeModal} />
            <Button bg="primary" title="Save" onClick={onSubmit} />
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  //
});
const mapDispatchToProps = {
  SaveEmailTemplates,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddForm);
