import React, {useState} from "react";
import Form from "components/form";
import Button from "components/button";
import "./styles.scss";
import classNames from "classnames";
import {AgGridReact} from "ag-grid-react";
import {CustomCheck} from "../common/tableComponents";
import {IoCheckmarkCircleSharp, IoCloseCircle} from "react-icons/io5";
import {FaUserFriends} from "react-icons/fa";
import {getCourseList, getCourseCategories, updateCourseStatus} from "../helpers/action";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {useHistory} from "react-router";
import RouteEnum from "constants/routeEnum";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import CommonPagination from "components/common/pagination";
import FormGroup from "components/formGroup";
import FormItem from "components/formGroup/formItem";
import Dropdown from "components/dropdown";
import {useForm} from "react-hook-form";

const PublishedFrame = (props) => {
  return (
    <div className="course-publish">
      {props.value === 1 ? (
        <span className="course-publish__item course-publish__yes">
          <IoCheckmarkCircleSharp />
          <span>Yes</span>
        </span>
      ) : (
        <span className="course-publish__item course-publish__no">
          <IoCloseCircle />
          <span>No</span>
        </span>
      )}
    </div>
  );
};

const AssignedFrame = (props) => {
  return (
    <div className="course-assigned">
      <span color={props.value == 0 ? "#FA541C" : ""}>{props.value}</span>
      <FaUserFriends color={props.value == 0 ? "#FA541C" : ""} />
    </div>
  );
};

const CourseLink = (props) => {
  return (
    <div className="course-assigned">
      <Link className="text-primary" to={RouteEnum.lmsCourseAdd + "/" + props.data.Id}>
        {props.value}
      </Link>
    </div>
  );
};

const CourseDate = (props) => {
  return <div className="course-assigned">{dayjs(props.value).format("YYYY.MM.DD")}</div>;
};

const AssignedButton = (props) => {
  const history = useHistory();
  // console.log({props})
  return (
    <div className="">
      <Button
        bg="highlight"
        onClick={() => history.push(RouteEnum.lmsCourseAssign + "/" + props.data.Id)}
        title="Assign Course"
      />
      {/* <Button bg="highlight" onClick={()=>history.push(RouteEnum.lmsCourseSettings)} title="Assign" /> */}
    </div>
  );
};

const AssignedDrop = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const onStatusChanges = () => {
    dispatch(updateCourseStatus(props.data.Id, props.data.Status === 1 ? 0 : 1));
  };
  const onStatusDiscontinue = () => {
    dispatch(updateCourseStatus(props.data.Id, 2));
  };
  return (
    <div className="course-drop">
      <Dropdown isFixed>
        <div className="course-drop__list">
          <div
            onClick={() => history.push(RouteEnum.lmsCourseAdd + "/" + props.data.Id)}
            className="course-drop__item"
          >
            <span>Edit</span>
          </div>
          <div
            onClick={() =>
              history.push(RouteEnum.lmsCourseSettings + "/" + props.data.Id)
            }
            className="course-drop__item"
          >
            <span>Course Options</span>
          </div>
          <div
            onClick={() => onStatusChanges()}
            className="course-drop__item course-drop__item-blue"
          >
            <span>{props.data.Status === 0 ? "Publish" : "Unpublish"}</span>
          </div>
          <div
            onClick={() => onStatusDiscontinue()}
            className="course-drop__item course-drop__item-red"
          >
            <span>Discontinue</span>
          </div>
        </div>
      </Dropdown>
    </div>
  );
};

const agSettings = {
  domLayout: "autoHeight",
  defaultColDef: {
    width: 145,
    resizable: false,
  },
  getRowHeight: function (params) {
    return 48;
  },
};
const data = [
  {
    CourseName: "User Experience Design",
    Category: "Information Technology",
    Assigned: "5",
    Published: true,
    Created: "2021.02.23",
  },
  {
    CourseName: "Human Resources Management",
    Category: "HR",
    Assigned: "0",
    Published: true,
    Created: "2021.02.23",
  },
  {
    CourseName: "Database Administration",
    Category: "Information Technology",
    Assigned: "5",
    Published: false,
    Created: "2021.02.23",
  },
  {
    CourseName: "Proposal Writing",
    Category: "Information Technology",
    Assigned: "5",
    Published: true,
    Created: "2021.02.23",
  },
];

const initialFilters = {
  pageIndex: 0,
  pageSize: 10,
  // "courseName": "",
  // "categoryId": 0,
  // "createdOn": "",
  // "status": 0
};

const courseQtys = {
  TotalCourse: 0,
  TotalHistoryCourse: 0,
  TotalNotPublishedCourse: 0,
  TotalPublishedCourse: 0,
};

function CourseManagement(props) {
  const [state, setState] = useState(initialFilters);
  const [active, setActive] = useState(-1);
  const [gridApi, setGridApi] = useState(null);
  const [selectedRow, setSelectedRow] = useState([]);
  const [courseQty, setCourseQty] = useState(courseQtys);
  const dispatch = useDispatch();
  const method = useForm();
  const {setValue} = method;
  const {courseCategories, courseList} = useSelector((state: any) => state.lmsReducer);
  useEffect(() => {
    dispatch(getCourseList(state));
  }, [state]);
  // console.log(selectedRow)
  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  useEffect(() => {
    if (courseList && courseList.length > 0) {
      const crs = courseList[0];
      setCourseQty((prev) => {
        return {
          TotalCourse: crs.TotalCourse,
          TotalHistoryCourse: crs.TotalHistoryCourse,
          TotalNotPublishedCourse: crs.TotalNotPublishedCourse,
          TotalPublishedCourse: crs.TotalPublishedCourse,
        };
      });
    }
  }, [courseList]);
  const onSelectionChanged = () => {
    var selectedRows = gridApi.getSelectedRows();
    setSelectedRow(selectedRows);
    // document.querySelector('#selectedRows').innerHTML =
    //   selectedRows.length === 1 ? selectedRows[0].athlete : '';
  };
  const onSelect = (item, checked) => {
    if (checked) {
      setSelectedRow((prev) => [item, ...prev]);
    } else {
      setSelectedRow((prev) => prev.filter((d) => d.Id !== item.Id));
    }
  };
  const onAssign = () => {
    console.log(selectedRow);
  };
  const handleRowChange = (num) => {
    setState((prev) => ({
      ...prev,
      ["pageSize"]: num,
    }));
  };
  const handleIndexChange = (num) => {
    setState((prev) => ({
      ...prev,
      ["pageIndex"]: num,
    }));
  };
  const onSearch = (data) => {
    let filter = {
      ...data,
      categoryId: data.categoryId ? data.categoryId.value : 0,
      status: data.status ? data.status.value : 0,
    };
    if (!data.createdOn) {
      delete filter.createdOn;
    }
    if (!data.categoryId) {
      delete filter.categoryId;
    }
    if (!data.status) {
      delete filter.status;
    }
    dispatch(getCourseList({...state, ...filter}));
  };
  const onCourseStatus = (id) => {
    setActive(id);
    setValue("status", id);

    let filter = {
      status: id,
    };
    dispatch(getCourseList({...state, ...filter}));
  };
  return (
    <div className="course-management">
      <div className="course-management__header">
        <div className="course-management__title">Course Management</div>
        <Link to={RouteEnum.lmsCourseAdd} className="btn btn-primary ">
          Add Course
        </Link>
      </div>
      <div className="course-management__tags">
        <div
          onClick={() => onCourseStatus(-1)}
          className={classNames("course-management__tag", {
            "courses-active": active === -1,
          })}
        >
          <div className="course-management__tag-num">{courseQty.TotalCourse}</div>
          <div className="course-management__tag-text">Course</div>
        </div>
        <div
          onClick={() => onCourseStatus(1)}
          className={classNames("course-management__tag", {
            "courses-active": active === 1,
          })}
        >
          <div className="course-management__tag-num">
            {courseQty.TotalPublishedCourse}
          </div>
          <div className="course-management__tag-text">Publish</div>
        </div>
        <div
          onClick={() => onCourseStatus(2)}
          className={classNames("course-management__tag", {
            "courses-active": active === 2,
          })}
        >
          <div className="course-management__tag-num">
            {courseQty.TotalNotPublishedCourse}
          </div>
          <div className="course-management__tag-text">Not Publish</div>
        </div>
        <div
          onClick={() => onCourseStatus(3)}
          className={classNames("course-management__tag", {
            "courses-active": active === 3,
          })}
        >
          <div className="course-management__tag-num">{courseQty.TotalHistoryCourse}</div>
          <div className="course-management__tag-text">History</div>
        </div>
      </div>
      <FormGroup method={method} onSubmit={onSearch}>
        <div className="course-management__filters">
          <FormItem
            formName="searchInput"
            width="200px"
            name="courseName"
            placeholder="Search course"
            label="search_course"
          />
          <FormItem
            formName="customdateinput"
            width="200px"
            name="createdOn"
            placeholder="created date range"
            label="created_at"
          />
          <FormItem
            formName="reactselect"
            options={courseCategories}
            name="categoryId"
            width="200px"
            label="Category"
          />
          <FormItem
            formName="reactselect"
            options={[
              {label: "Published", value: 1},
              {label: "Not Published", value: 0},
            ]}
            name="status"
            width="200px"
            label="Published"
          />
          <Button htmlButtonType="submit" title="search" bg="white" />
          <Button htmlButtonType="button" title="Export" bg="white" />
        </div>
      </FormGroup>

      <div className="course-management__table">
        <div
          id="myGrid"
          style={{
            height: "100%",
          }}
          className="ag-theme-alpine"
        >
          <AgGridReact
            frameworkComponents={{
              PublishedFrame,
              CourseDate,
              AssignedDrop,
              AssignedButton,
              AssignedFrame,
              CourseLink,
              CustomCheck: (props) => (
                <CustomCheck
                  {...props}
                  onSelect={onSelect}
                  setSelectedRow={setSelectedRow}
                  selectedRow={selectedRow}
                />
              ),
            }}
            columnDefs={[
              {field: "ID", cellRenderer: "CustomCheck", width: 80},
              {
                field: "Title",
                headerName: "Course Name",
                cellRenderer: "CourseLink",
                width: 200,
              },
              {field: "CategoryName", headerName: "Category"},
              {field: "Assigned", cellRenderer: "AssignedFrame"},
              {field: "Status", headerName: "Published", cellRenderer: "PublishedFrame"},
              {field: "CreatedOn", headerName: "Created", cellRenderer: "CourseDate"},
              {field: "", cellRenderer: "AssignedButton"},
              {field: "", cellRenderer: "AssignedDrop"},
            ]}
            {...agSettings}
            onGridReady={onGridReady}
            rowData={courseList}
            rowSelection={"multiple"}
            suppressRowClickSelection={true}
          />
        </div>
        {courseList && courseList.length > 0 && (
          <CommonPagination
            handleRowChange={handleRowChange}
            handleIndexChange={handleIndexChange}
            pageIndex={state.pageIndex}
            rowsPerPage={state.pageSize}
            total={courseList[0].TotalRows}
          />
        )}
      </div>
    </div>
  );
}

export default CourseManagement;
