import React, { useEffect } from 'react'
import { Tabs, Tab } from 'components/tabs/index.d';
import CommonFilter from './commmonFilter'
import CommonTable from './taskReportTable'
import {getBoardsDetails} from '../helpers/action'
import './styles.scss'
import { useDispatch } from 'react-redux';
import {    
    getBranches,
    getDepartments,
} from 'components/dataselect/action';
import useDebounce from 'helpers/customHooks/useDebounce'

const initialFilter = { 
    search:'',
    branchId:null,
    departmentId:null,
    owner:"",
    dl:null,
    pageIndex:0,
    pageSize:10
}

const tabs = [
    {id:1,name:'active_tasks',label:'Active Tasks'},
    {id:2,name:'completed_tasks',label:'Commpleted Tasks'},
    {id:3,name:'archive',label:'Archive'},
]

function Reports() {
    const dispatch = useDispatch()
    const [filter,setFilter] = React.useState(initialFilter);
    const [activeTab,setActivetab] = React.useState(tabs[0]);
   
    const onChange = (name,value) =>{
        setFilter(prev=>({
            ...filter,
            [name]:value
        }))
    }
    useEffect(()=>{
        dispatch(getBranches())
        dispatch(getDepartments())
    },[])
    useEffect(()=>{
        getBords(activeTab)
    },[filter.pageIndex,filter.pageSize,filter?.branchId,filter?.departmentId, filter.dl])
    useDebounce(() =>  getBords(activeTab), 1000, [filter.search,filter.owner])
    const getBords = (tab) => {
        const data = {
            "completed": tab.id === 2 || null,
            pageIndex:filter.pageIndex,
            pageSize:filter.pageSize,
            "archived": tab.id === 3 || null,
            "boardName": filter.search,
            "owner": filter.owner,
            branchId:filter?.branchId ? filter?.branchId?.value : null,
            departmentId:filter?.departmentId ? filter?.departmentId?.value : null,
            "overDue": filter.dl ? filter.dl.value === '2' ? true :filter.dl.value === '1' ? null : false : null
        }
        dispatch(getBoardsDetails(data))
    }
    const handleTabChange = (tab) =>{
        setActivetab(tab)
        getBords(tab)
    }
    const handleRowChange = (num) => {
        setFilter((filter:any)=>{
            return {
                ...filter,
                pageSize:num,
                pageIndex:0
            }
        })
      };
    
      const handleIndexChange = (num) => {
        setFilter((filter:any)=>{
            return {
                ...filter,
                pageIndex:num
            }
        })
    };
    
    return (
        <div className="admin">
            <div className="admin-container">
                <div className="admin-title">
                    <h1>Tasks</h1>
                </div>
                <div className="tasksReport-tabs">
                    <Tabs noBackground>
                        {
                            tabs.map(tab=>(
                                <Tab
                                    label={tab.label}
                                    name={tab.name}
                                    id={tab.id}
                                    key={tab.id}
                                    isActive={activeTab.id === tab.id}
                                    onClick={() => handleTabChange(tab)}
                                >
                                    <CommonView 
                                        onChange={onChange} 
                                        filter={filter} 
                                        isComplete={activeTab.id === 2} 
                                        isArchive={activeTab.id === 3} 
                                        pageIndex={filter.pageIndex}
                                        pageSize={filter.pageSize}
                                        handleRowChange={handleRowChange}
                                        handleIndexChange={handleIndexChange}
                                    />
                                </Tab>
                            ))
                        }
                    </Tabs>
                </div>

            </div>
        </div>
    )
}


const CommonView = ({onChange, filter,...rest}) =>{
    return <>
        <CommonFilter onChange={onChange} filter={filter}  />
        <CommonTable {...rest} />
    </>
}

export default Reports
