import isEmpty from "isEmpty";
// import {
//   EmailRegexValidaion,
//   PhoneNumberRegexValidation,
//   numberAndDecimals,
//   numbersOnly,
// } from "./regex";

export async function dateFieldValidator(document, validationFields) {
  const errors = {};
  let fields = Object.keys(validationFields).map(function (key) {
    return { id: key, value: validationFields[key] };
  });

  if (fields) {
    Promise.all(
      fields.map(async (field) => {
        const getElementById = document.getElementById(field.id);

        let validators =
          getElementById && getElementById.getAttribute("validators");
        const conditions = validators ? validators.split(",") : null;

        if (conditions) {
          Promise.all(
            conditions.map((condition) => {
              let splitedCondition = condition.split(":");
              if (splitedCondition && splitedCondition.length > 1) {
                let conditionValue = splitedCondition[0];
                let affectingField = splitedCondition[1];

                let value = fields.find((f) => f.id == affectingField).value;

                if (conditionValue == "greaterThan") {
                  const errMsg = validationConditions(
                    conditionValue,
                    value,
                    field.value
                  );
                  if (errMsg) {
                    errors[field.id] = errMsg;
                  }
                }
                // else {
                
                //   const errMsg = validationConditions(conditionValue, value);
                //   if (errMsg) {
                //     errors[affectingField] = errMsg;
                //   }
                // }
              } else {
                const errMsg = validationConditions(condition, field.value);
                if (errMsg) {
                  errors[field.id] = errMsg;
                }
              }
            })
          );
        }
      })
    );
  }
  return errors;
}

const validationConditions = (condition, value, value2) => {
  switch (condition) {
    case "required": {
      return !value || isEmpty(value) ? "Required" : "";
    }

    case "greaterThan": {
      return value2 && value2 <= value ? "Must be greater" : "";
    }

    // case "email": {
    //   const isValid = !isEmpty(value) ? EmailRegexValidaion(value) : true;
    //   return !isValid ? "Invalid Email" : "";
    // }

    // case "numberAndDecimals": {
    //   const isValid = !isEmpty(value) ? numberAndDecimals(value) : true;
    //   return !value ? "Required" : !isValid ? "Numbers Only" : "";
    // }

    // case "numbers": {
    //   const isValid = !isEmpty(value) ? numbersOnly(value) : true;
    //   return !value ? "Required" : !isValid ? "Numbers Only" : "";
    // }

    default:
      return "";
  }
};
