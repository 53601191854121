import React, { Component } from "react";
import "../../scss";
import { connect } from "react-redux";
import { GetUnsubmittedActivities } from "../../action";
import SearchAndFilter from "./searchAndFilter";
import ActivityReportTable from "./table";
import Breadcrumb from "../../../common/breadcrumb";
import Loaders from "components/loaders";
import RouteBaseClass from "components/routeBaseClass";
import { getFirstAndLastNepaliDateOfCurrentMonthTillToday } from "scenes/taskTracker/common/getDates";
import ExportExcel from "components/common/exportExcel";

let export_name = "Activity Not Submitted";
let export_columns = [
  { label: "EMPLOYEE NAME (EIN)", value: "EmployeeNameAndId" },
  { label: "DEPARTMENT", value: "DepartmentName" },
  {
    label: "ACTIVITY NOT SUBMITTED",
    value: "ActivityNotSubmittedDaysAndDetail",
  },
  { label: "WORK DAYS", value: "WorkDays" },
];

export class index extends RouteBaseClass {
  constructor(props) {
    super(props, ["Employee", "Administrator"]);
    this.state = {
      isLoading: true,
      pageIndex: 0,
      rowsPerPage: 10,
      searchEmployee: "",
      fromDate: null,
      toDate: null,
    };
  }

  // async componentDidMount() {
  //   await this.getUnsubmittedActivities();
  // }

  async componentDidMount() {
    let month = getFirstAndLastNepaliDateOfCurrentMonthTillToday();

    this.setState(
      {
        fromDate: month?.firstDate,
        toDate: month?.lastDate,
      },
      async () => {
        await this.getUnsubmittedActivities();
      }
    );
  }

  getUnsubmittedActivities = async (isExport = false) => {
    this.setState({ isLoading: true });
    const {
      searchEmployee,
      fromDate,
      toDate,
      pageIndex,
      rowsPerPage,
    } = this.state;

    const data = {
      startDate: fromDate,
      endDate: toDate,
      empName: searchEmployee,
      pageIndex: isExport ? 0 : pageIndex,
      pageSize: isExport ? 999999999 : rowsPerPage,
    };

    await this.props.GetUnsubmittedActivities(data, isExport);
    this.setState({ isLoading: false });
  };

  handleExcelExport = async () => {
    await this.getUnsubmittedActivities(true);
  };

  handleRowChange = (num) => {
    this.setState({ rowsPerPage: num }, async () => {
      await this.getUnsubmittedActivities();
    });
  };

  handleIndexChange = (num) => {
    this.setState({ pageIndex: num }, async () => {
      await this.getUnsubmittedActivities();
    });
  };

  handleTabChange = (tab) => {
    this.setState({ pageIndex: 0 }, async () => {
      await this.getUnsubmittedActivities();
    });
  };

  handleInput = (name, value) => {
    this.setState({ [name]: value, pageIndex: 0 }, async () => {
      await this.getUnsubmittedActivities();
    });
  };

  handleDateRangePicker = (name, date) => {
    const { startDate, endDate } = date;
    this.setState(
      { fromDate: startDate, toDate: endDate, pageIndex: 0 },
      async () => {
        await this.getUnsubmittedActivities();
      }
    );
  };

  render() {
    const { isLoading, pageIndex, rowsPerPage } = this.state;
    const {
      unSubmittedActivities,
      unSubmittedActivitiesExportExcel,
    } = this.props;
    return (
      <div className="common-container">
        <div className="tasktracker-title-section">
          <Breadcrumb name="Activity Not Submitted" />
        </div>

        <div className="flex">
          <SearchAndFilter
            {...this.state}
            handleDateRangePicker={this.handleDateRangePicker}
            handleInput={this.handleInput}
            types={this.state.types}
          />

          <ExportExcel
            onClick={this.handleExcelExport}
            fileName={export_name}
            columns={export_columns}
            sheets={[
              {
                sheetName: export_name,
                data: unSubmittedActivitiesExportExcel,
              },
            ]}
            className="button button-primary"
          />
        </div>

        {isLoading ? (
          <Loaders loading={isLoading} type="skeleton" name="custom-table" />
        ) : (
          <ActivityReportTable
            data={unSubmittedActivities}
            pageIndex={pageIndex}
            rowsPerPage={rowsPerPage}
            handleRowChange={this.handleRowChange}
            handleIndexChange={this.handleIndexChange}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  unSubmittedActivities: state.taskTrackerReducer.unSubmittedActivities,
  unSubmittedActivitiesExportExcel:
    state.taskTrackerReducer.unSubmittedActivitiesExportExcel,
});

const mapDispatchToProps = {
  GetUnsubmittedActivities,
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
