import React from 'react'
import Modal from 'components/modal'
import { Tabs, Tab } from 'components/tabs/index.d';
import BasicShift from './components/basicShift'
import ShiftAssignment from './components/shiftAssignment';

function AddShift(props) {
    const {
        open,
        onModalClose,
        date,
        isEdit
    } = props;
    return (
        <Modal
            open={open}
            onModalClose={onModalClose}
            className="schedule-modal"
            width={isEdit ? "600px" : "450px" } 
            // title="Add Shift"
        >
            <div className="schedule-modal__container">
                <div className="schedule-modal__title">
                    <span>Add Shift</span>
                </div>
                <div className="schedule-modal__tabs">
                    {
                        isEdit ? (
                            <Tabs noBackground>
                                <Tab label="Basic" name="Basic" >
                                    <BasicShift {...props} />
                                </Tab>
                                <Tab label="Assignment" name="Assignment" >
                                    <ShiftAssignment {...props} />
                                </Tab>
                            </Tabs>
                        ) : (
                            <BasicShift {...props} />
                        )
                    }

                </div>
            </div>
        </Modal>
    )
}


export default AddShift
