import React from "react";
import TaskTitle from "./taskTitle";
import TaskDescription from "./taskDescription";

const HeaderSection = (props) => {
  const { data, onEnter, isValidUser } = props;
  return (
    <>
      <TaskTitle
        name="TaskName"
        task={data.TmTaskDetailsDto}
        onEnter={onEnter}
        isValidUser={isValidUser}
      />
      <TaskDescription
        name="TaskDescription"
        task={data.TmTaskDetailsDto}
        onEnter={onEnter}
        isValidUser={isValidUser}
      />
    </>
  );
};

export default HeaderSection;
