import React, { Component } from "react";
import Avatar from "react-avatar";
import {getUploadPath} from 'constants/userDetails'

export class MyManager extends Component {
  render() {
    const { data } = this.props;
    console.log( getUploadPath(data.UrlPhoto))
    return (
      <div className="manager">
        <div className="bloch__title">My Manager</div>
        <div className="manager-block">
          <Avatar
            name={data.ManagerName}
            size={100}
            round={false}
            src={ getUploadPath(data.UrlPhoto)}
          />
          <div className="manager-block-detail">
            <span className="manager-block-detail-designation">
              {data.Department}
            </span>
            <span className="manager-block-detail-name">
              {data.ManagerName}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default MyManager;
