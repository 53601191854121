import React, { useState } from "react";
import Avatar from "react-avatar";
import { GoComment } from "react-icons/go";
import { IoMdCloudDownload } from "react-icons/io";
import { MdRateReview } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import ApprovalFlow from "../../../../common/approvalFlow";
import Pagination from "components/common/pagination";
import { BASE_URL } from "constants/constants";
import getFormattedNumber from "helpers/getFormattedNumber";
import isEmpty from "isEmpty";
import RouteEnum from "constants/routeEnum";
import Button from "components/button";
import { MdCloudDownload } from "react-icons/md";
import EmployeeDetails from "scenes/common/employeeDetails";
import SvgIcons from "components/svgIcons";

function TravelHistoryBlock(props) {
  let { cancelledList } = props;
  let [modalOpen, setModalOpen] = useState("");
  const dispatch = useDispatch();
  const onModalClose = (id) => {
    setModalOpen(id);
  };
  const handleDownload = (data) => {
    props.history.push({
      pathname: RouteEnum.ExportPdfPath,
      state: {
        id: data.RequestId,
        source: "travelReport",
      },
    });
  };

  const handleEditTravelRequest = (data) => {
    props.clearEditTravelRequest(); //clear previous record to fix for details not updating
    props.history.push({
      pathname: RouteEnum.UpdateTravelRequestPath,
      state: {
        selectedTabId: -2,
        travelRequestId: data.RequestId,
        isViewPage: true,
        title: `${data.Name} - ${data.PlaceName} (${data.TravelStart} - ${data.TravelEnd})`,
        requestDetails: data,
      },
    });
  };

  let {
    travelHistory,
    handleRowChange,
    handleIndexChange,
    pageIndex,
    rowsPerPage,
  } = props;
  let searchedPage = useSelector(
    (state) => state.travelInformationReducer.travelHistorySearchedPage
  );
  return (
    <>
      {props &&
        props.rows.map((row, rowIndex) => {
          let isCancelled =
            !isEmpty(cancelledList) &&
            cancelledList.find((x) => x === row.RequestId);
          const employeeDetail = {
            Id: row["EmployeeId"],
            FullName: row["Name"],
            Imagepath: row["UrlPhoto"],
            Designation: row["Designation"],
            Branch: row["Branch"],
            Department: row["Department"],
          };
          return (
            <div className="request-details" key={rowIndex}>
              <div className="request-details__header">
                <EmployeeDetails empDetails={employeeDetail} size={50} />

                {/* <div className="emp-details flex">
                  <Avatar
                    alt={row.Name}
                    src={row.UrlPhoto && BASE_URL + row.UrlPhoto}
                    className="emp-image"
                    round
                    size={50}
                  />
                  <div className="emp-name-desg">
                    <span className="emp-name">{row.Name}</span>
                    <span className="emp-desg">{row.Designation}</span>
                  </div>
                </div> */}
                <div>
                  <span>{row.PlaceName}</span>
                </div>
                <div>
                  <span>{row.TotalDays} </span>
                  <span>{row.TotalNight}</span>
                </div>
                <div>
                  <span>{row.TravelStartEndDate}</span>
                </div>
                {row.IsCommented ? (
                  <div className="comment">
                    <GoComment className="comment__icon"></GoComment>
                    {/* <span className="first-line"></span>
                <span className="second-line"></span> */}
                  </div>
                ) : null}
                <div className="requested-on">
                  <span>Requested on: </span>
                  <span>{row.RequestedOn}</span>
                </div>
              </div>
              <div className="request-details__body">
                <div className="body-row">
                  <div className="body-col">
                    <div className="reason">
                      <span>{row.Reason}</span>
                    </div>
                    {row.TravelSettlementDestination.map((dest, destIndex) => {
                      return (
                        <div className="destination dest" key={destIndex}>
                          <div className="dest__row">
                            <span className="district">
                              {dest.District}
                              {!isEmpty(dest.District) ? "," : null}
                            </span>
                            <span>{dest.PlaceName}</span>
                            {destIndex === 0 && !isEmpty(row.GroupEmployee) ? (
                              <div className="travel-people">
                                {/* <RiGroupLine /> */}
                                <span className="travel-people__name">
                                  {row.GroupEmployee.map((emp, i) => {
                                    return i + 1 === row.GroupEmployee.length
                                      ? emp
                                      : emp + ", ";
                                  })}
                                </span>
                              </div>
                            ) : null}
                          </div>
                          <div className="dest__row">
                            <span className="travel-date">
                              {dest.DestinationStart}
                            </span>
                            <span className="divider"> - </span>
                            <span className="travel-date">
                              {dest.DestinationEnd}
                            </span>
                          </div>
                          <div className="dest__row">
                            <span>{dest.TravelDays}</span>
                            <span className="divider"></span>
                            <span> {dest.TravelNights}</span>
                            <span className="hotel-name">{dest.HotelName}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="body-col">
                    <div className="amount-row">
                      <span>Eligible Amount</span>
                      <span>
                        {getFormattedNumber(row.AssessedEligibilityAmt)}
                      </span>
                    </div>
                    <div className="amount-row">
                      <span>Advance</span>
                      <span>{getFormattedNumber(row.AdvanceRequestAmt)}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="request-details__footer">
                <div className="details-btn">
                  <NavLink
                    target="_blank"
                    to={{
                      pathname: RouteEnum.ExportPdfPath,
                      state: {
                        id: row.RequestId,
                        source: "travelReport",
                      },
                      search: "travelReport/" + row.RequestId,
                    }}
                    // onClick={() => handleExportToPdf(row.RequestId)}
                  >
                    {/* <button
                      className="button button-white with-icon"
                      //onClick={() => handleDownload(row)}
                    >
                      <IoMdCloudDownload />
                      <span>Download</span>
                    </button> */}
                    <Button
                      title="Download"
                      buttonType="icon-button"
                      // type="button-outline"
                      leftIcon={<MdCloudDownload />}
                      bg="subtle"
                    />
                  </NavLink>
                </div>
                <ApprovalFlow
                  approvalStatus={row.ApprovalStatus}
                  rowIndex={rowIndex}
                />
                <div className="btn-col btn-grp flex">
                  {/* <button
                    className="button button-primary with-icon"
                    onClick={() => handleEditTravelRequest(row)}
                  >
                    <MdRateReview />
                    <span>View</span>
                  </button> */}
                  <Button
                    title="View"
                    bg="subtle"
                    // type="button-outline"
                    onClick={() => handleEditTravelRequest(row)}
                    buttonType="icon-button"
                    leftIcon={<SvgIcons name={"DetailsList"} size={16} />}
                  />
                </div>
              </div>
            </div>
          );
        })}
      {isEmpty(props.rows) ? null : (
        <Pagination
          handleRowChange={handleRowChange}
          handleIndexChange={handleIndexChange}
          pageIndex={pageIndex}
          searchedPage={searchedPage}
          rowsPerPage={rowsPerPage}
        />
      )}
    </>
  );
}

export default TravelHistoryBlock;
