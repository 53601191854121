import React, { Component } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Filter,
  Inject,
  Sort,
  // SortSettingsModel,
} from "@syncfusion/ej2-react-grids";
import Pagination from "components/common/pagination";
import TableRow from "./tableRow";

export class Table extends Component {
  constructor(props) {
    super(props);
    this.widths = {
      employeeName: 200,
      type: 150,
      role: 200,
      action: 300,
    };

    // this.sortingOptions = () =>
    //   SortSettingsModel({
    //     columns: [{ field: "EmployeeName", direction: "Ascending" }],
    //   });
    this.gridTemplate = this.gridTemplate;
    this.row = [];
  }

  gridTemplate(props, history) {
    return (
      <TableRow
        {...props}
        widths={this.widths}
        {...this.props}
        {...history}
        ref={(row) => {
          this.row = [...this.row, row];
        }}
      />
    );
  }

  sortBy = (option) => {};

  render() {
    const {
      data,
      handleRowChange,
      handleIndexChange,
      pageIndex,
      rowsPerPage,
    } = this.props;

    return (
      <div className="default-table">
        <div className="pending-settlement-grid">
          <GridComponent
            dataSource={data}
            className="custom-grid"
            toolbar={this.toolbar}
            filterSettings={{ type: "Menu" }}
            // allowFiltering={true}
            //  height={height}
            id="grid"
            // allowExcelExport={true}
            ref={(g) => (this.grid = g)}
            rowTemplate={this.gridTemplate.bind(this)}
            allowSorting={true}
            // sortSettings={this.sortingOptions}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="MemberName"
                headerText="Employee Name"
                width={this.widths.employeeName}
                sortComparer={() => this.sortBy("employee")}
              />
              <ColumnDirective
                field="MemberTypeName"
                headerText="Type"
                width={this.widths.type}
                // allowSorting={false}
              />
              <ColumnDirective
                field="BillingDesignationName"
                headerText="Role"
                width={this.widths.role}
                // allowSorting={false}
              />
              <ColumnDirective width={this.widths.action} />
            </ColumnsDirective>
            <Inject services={[Sort]} />
          </GridComponent>

          {data && data.length > 0 && (
            <Pagination
              handleRowChange={handleRowChange}
              handleIndexChange={handleIndexChange}
              pageIndex={pageIndex}
              rowsPerPage={rowsPerPage}
              total={data[0].TotalRows}
            />
          )}
        </div>
      </div>
    );
  }
}

export default Table;
