import React from "react";
import AgGridTable from "components/table/agGridTable";
import WorkExperienceCell from "./cells/workExperience";
import AddressCell from "./cells/address";
import CandidateNameCell from "./cells/candidateName";

const columnDefs = [
  { field: "InternalId", headerName: "Internal Id", width: 100 },
  {
    field: "CandidateName",
    headerName: "Candidate Name",
    cellRenderer: "CandidateNameCell",
  },
  { field: "JobTitle", headerName: "Job Title" },
  { field: "Level", width: 100 },
  { field: "EducationLevel", headerName: "Education Level", width: 100 },
  { field: "MobileNo", headerName: "Mobile", width: 100 },
  { field: "Email" },
  { field: "Nationality", width: 100 },
  { field: "Age" },
  { field: "Gender", width: 100 },
  { field: "Address", cellRenderer: "AddressCell" },
  { field: "FatherName", headerName: "Father Name" },
  { field: "MotherName", headerName: "Mother Name" },
  {
    field: "WorkExperience",
    headerName: "Experience",
    cellRenderer: "WorkExperienceCell",
  },
  { field: "Trainings" },
  { field: "MaritalStatus", headerName: "Marital Status", width: 100 },
];

export default function Table(props: any) {
  const frameworkComponents = {
    WorkExperienceCell,
    AddressCell,
    //CandidateNameCell,
    CandidateNameCell: (rowProps: any) => (
      <CandidateNameCell {...rowProps} history={props.history} />
    ),
  };

  return (
    <AgGridTable
      columnDefs={columnDefs}
      rowData={props.data}
      frameworkComponents={frameworkComponents}
      // page={{ pageIndex: props.pageIndex, rowsPerPage: props.rowsPerPage }}
      // handlePagination={props.handlePagination}
    />
  );
}
