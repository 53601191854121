import React from "react";
import "./managers.styles.scss";
import { connect } from "react-redux";
import Count from "./component/count";
import Search from "./component/search";
import Table from "./component/table";
import Modal from "components/modal";
import AssignRole from "./component/assignRole";
import {
  GetEmployeeManagers,
  DeleteEmployeeManager,
  SaveImportedExcelData,
} from "./action";
import {
  GetEmployeeList,
  GetDesignations,
  DisplayClientSideMessage,
} from "actions/commonAction";

class index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchEmployee: null,
      managerTag: null,
      pageIndex: 0,
      pageSize: 10,
      showEditModal: false,
      selectedEmployeeId: 0,
    };
    this.getData = this.getData.bind(this);
    this.handleClickSummary = this.handleClickSummary.bind(this);
    this.handleSearchEmployee = this.handleSearchEmployee.bind(this);
    this.handleExportGrid = this.handleExportGrid.bind(this);
    this.handleImportedExcel = this.handleImportedExcel.bind(this);
    this.displayMessage = this.displayMessage.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }
  async componentDidMount() {
    await this.getData();
    this.props.GetEmployeeList();
    this.props.GetDesignations();
  }

  async getData(isExport = false) {
    const { searchEmployee, managerTag, pageIndex, pageSize } = this.state;
    const data = {
      SearchEmployee: searchEmployee,
      ManagerDefTag: managerTag,
      PageIndex: pageIndex,
      PageSize: !isExport ? pageSize : 11111111, //brings all data
    };

    await this.props.GetEmployeeManagers(data, isExport);
  }

  handleClickSummary(tagId) {
    this.setState({ managerTag: tagId }, async () => {
      await this.getData();
    });
  }

  handleSearchEmployee(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value }, async () => {
      await this.getData();
    });
  }

  async handleExportGrid() {
    await this.getData(true);
  }

  async handleImportedExcel(data) {
    await this.props.SaveImportedExcelData(data);
  }

  handleDelete(employeeId) {
    this.props.DeleteEmployeeManager(employeeId, this.getData);
  }
  async handleEdit(employeeId) {
    this.setState({ showEditModal: true, selectedEmployeeId: employeeId });
  }

  refreshList = () => {
    this.setState({ showEditModal: false });
    this.getData();
  };

  displayMessage(message) {
    this.props.DisplayClientSideMessage(message);
  }

  handleRowChange = (num) => {
    this.setState({ pageSize: num }, async () => {
      await this.getData();
    });
  };

  handleIndexChange = (num) => {
    this.setState({ pageIndex: num }, async () => {
      await this.getData();
    });
  };
  render() {
    const { showEditModal, selectedEmployeeId } = this.state;
    return (
      <div className="admin roles">
        <div className="admin-container">
          <div className="admin-title">
            <h1>Managers</h1>
          </div>

          <Count {...this.props} handleClickSummary={this.handleClickSummary} />

          <div className="admin-body">
            <Search
              {...this.state}
              {...this.props}
              handleSearchEmployee={this.handleSearchEmployee}
              handleExportGrid={this.handleExportGrid}
              handleImportedExcel={this.handleImportedExcel}
              displayMessage={this.displayMessage}
            />
            <Table
              {...this.props}
              {...this.state}
              handleEdit={this.handleEdit}
              handleDelete={this.handleDelete}
              handleIndexChange={this.handleIndexChange}
              handleRowChange={this.handleRowChange}
            />
          </div>

          {showEditModal && (
            <Modal
              open={showEditModal}
              onModalClose={() => this.setState({ showEditModal: false })}
              title="Assign Role to"
              className="roles-modal managers-modal"
            >
              <AssignRole
                close={() => this.setState({ showEditModal: false })}
                employeeId={selectedEmployeeId}
                refreshList={this.refreshList}
              />
            </Modal>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  managerAssignmentSummary: state.managersReducer.managerAssignmentSummary,
  employeeManagers: state.managersReducer.employeeManagers,
  employeeManagersForExport: state.managersReducer.employeeManagersForExport,
});

const mapDispatchToProps = {
  GetEmployeeManagers,
  SaveImportedExcelData,
  DeleteEmployeeManager,
  GetEmployeeList,
  GetDesignations,
  DisplayClientSideMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
