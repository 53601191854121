import {
  GET_DISTRIBUTED_INCOME_BY_AMOUNT,
  SAVE_SALARY_TEMPLATE,
  SALARY_TEMPLATE_LIST,
  SALARY_TEMPLATE_DETAILS,
  DELETE_SAVE_SALARY_TEMPLATE,
} from "actions/types";

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DISTRIBUTED_INCOME_BY_AMOUNT: {
      return {
        ...state,
        distributedIncomeByAmount: action.payload,
      };
    }
    case SAVE_SALARY_TEMPLATE: {
      return {
        ...state,
        saveSalaryTemplate: true,
      };
    }
    case SALARY_TEMPLATE_LIST: {
      return {
        ...state,
        salaryTemplateList: action.payload,
      };
    }
    case SALARY_TEMPLATE_DETAILS: {
      return {
        ...state,
        salaryTemplateDetail: action.payload,
      };
    }
    case DELETE_SAVE_SALARY_TEMPLATE: {
      return {
        ...state,
        deleteSalaryTemplate: true,
      };
    }
    default:
      return state;
  }
}
