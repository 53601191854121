import { getEnglishDate } from "components/datePicker/helpers";
import isEmpty from "isEmpty";
import moment from "moment";

export const getSettlementReportSaveData = (
  props,
  localState,
  approverData
) => {
  const { otherExpenses, settlementReport, billsAndClaimsList } = props;
  const travelInformation = localState.travelInformationState;
  debugger
  const totalBillAmount = billsAndClaimsList.reduce(
    (a, b) => a + (b["Amount"] || 0),
    0
  );
  const totalOtherExpenseAmount = otherExpenses.reduce(
    (a, b) => a + (b["Amount"] || 0),
    0
  );
  const totalClaimAmount = totalBillAmount + totalOtherExpenseAmount;
  return {
    TravelSettlementDto: {
      SettlementId: localState.settlementId,
      RequestRefId: localState.requestId,

      SettelementOn: new Date(),
      SettlementOnEng: new Date().toJSON(),
      TravelType: isEmpty(travelInformation)
        ? 0
        : travelInformation.travelTypeId,
      DestinationType: isEmpty(travelInformation)
        ? 0
        : travelInformation.destinationTypeId,
      Reason: isEmpty(travelInformation) ? "" : travelInformation.reason,

      ApprovalEmployeeId: approverData.ApprovalEmployeeId,
      StatusId: approverData.Status,

      AssessedEligibilityAmt: !isEmpty(totalBillAmount) ? totalBillAmount : 0,
      TotalClaimAmt: totalClaimAmount,
      CountryId:
        isEmpty(travelInformation) || isEmpty(travelInformation.selectedCountry)
          ? 0
          : travelInformation.selectedCountry.value,
      CurrencyCode:
        isEmpty(travelInformation) ||
        isEmpty(travelInformation.selectedCurrency)
          ? 0
          : travelInformation.selectedCurrency.value,
      //CountryId: 0,
      IsInformationChanged: true,
      // StartDate: moment(localState.overAllDestinationFromDate)
      //   .format("YYYY/MM/DD")
      //   .toString(),
      // EndDate: moment(localState.overAllDestinationToDate)
      //   .format("YYYY/MM/DD")
      //   .toString(),
      StartDate: localState.overAllDestinationFromDate,
      EndDate: localState.overAllDestinationToDate,
      StartDateEng: getEnglishDate(localState.overAllDestinationFromDate),
      EndtDateEng: getEnglishDate(localState.overAllDestinationToDate),
    },

    TravelSettlementAllowanceExpenseDetailsDto: billsAndClaimsList.map((x) => {
      return {
        Id: x.Id,
        SettlementRefId: localState.settlementId,
        CategoryRefId: localState.categoryId,
        AllowanceRef_Id: x.AllowanceRef_Id,
        AllowanceName: x.Name,
        Units: x.Units,
        Rate: x.Rate,
        Amount: x.Amount,
        IsBillRequired: x.IsBillRequired,
        CalculationMethod: x.CalculationMethod,
      };
    }),
    TravelSettlementDestinationsDto: isEmpty(travelInformation)
      ? []
      : travelInformation.selectedTravelDestList.map((x) => {
          let dest = x.travelDestination;
          let modeOfTransportation = "";
          dest.selectedModeOfTransport.map((t) => {
            modeOfTransportation += "," + t.value;
          });

          return {
            Id: dest.Id,
            SettlementRefId: localState.settlementId,
            DistrictRefId: dest.selectedDistrict.value,
            PlaceName: dest.placeName,
            TransportModeRefId: modeOfTransportation,
            StartDate: dest.startDate,
            EndDate: dest.endDate,
            StartDateEng: getEnglishDate(dest.startDateEng),
            EndDateEng: getEnglishDate(dest.endDateEng),
            TravelDays: dest.days,
            TravelNights: dest.nights,
            HostName: dest.hostName,
            HotelName: dest.hotelName,
            PhoneNo: dest.phoneNumber,
          };
        }),
    TravelSettlementJobDocumentsDto: [],
    TravelSettlementBillsDocumentsDto: [],
    TravelSettlementOtherExpensesDto: [],
    TravelSettlementOtherExpensesDocDto: [],
  };
};
