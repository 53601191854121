import React, { Component } from 'react'
import { GridComponent, ColumnsDirective,
    ColumnDirective, Edit, Toolbar, Page, Inject } from '@syncfusion/ej2-react-grids';

export class ActivityTable extends Component {
    constructor() {
        super(...arguments);
        this.template = this.gridTemplate;
    }
    gridTemplate(props) {
        console.log(props)
        return (
            <tr className="leave-row bordered">
            <td className="">
                <a href="#">
                    <span>
                        {props.activity}
                    </span>
                </a>
                
            </td>
            <td className="">
                <span className="text-blue">
                    {props.count} employees
                </span>
            </td>
            <td className="">
            <span className="text-bold">
                    {props.done_by.Name}
                </span>
                <span className="text-light ml-sm">
                    on {props.done_by.Date}
                </span>
            </td>
            <td className="">
                <span className="text-bold">
                    {props.reviewed_by.Name}
                </span>
                <span className="text-light ml-sm">
                    on {props.reviewed_by.Date}
                </span>
            </td>
        </tr>
        );
    }
    render() {
        const data = [
            {activity:'Leave Deduction',count:'3',done_by:{Name:"Anuradha",Date:"Shrawan 23, 2076 3:30pm"},
            reviewed_by:{Name:"Charulata",Date:"Shrawan 23, 2076 3:30pm"}},
        ]
        return (
            <div className="arrear-table">
                <GridComponent dataSource={data} 
                    rowTemplate={this.template.bind(this)}
                    >
                        <ColumnsDirective>
                            <ColumnDirective field='Activity' headerText="Activity" width='100'/>
                            <ColumnDirective field='Count' headerText="Count" width='80'/>
                            <ColumnDirective field='Done by' headerText="Done By" width='150'/>
                            <ColumnDirective field='Reviwed by' headerText="Reviewed By" width='150'/>
                        </ColumnsDirective>
                </GridComponent>
            </div>
        )
    }
}

export default ActivityTable

