import React, { Component } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Edit,
  Toolbar,
  Page,
  Inject,
} from "@syncfusion/ej2-react-grids";
import { MdDateRange } from "react-icons/md";
import { FaUserEdit } from "react-icons/fa";
import classnames from "classnames";
import { Tooltip } from "@material-ui/core";
import isEmpty from "isEmpty";
import { Prompt } from "react-router";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Loader from "../../../components/loaders";
import getFormattedNumber from "../../../helpers/getFormattedNumber";
import UpdateInput from "../../../components/common/updateInput";
import CommonPagination from '../../../components/common/pagination'
import Moment from "react-moment";
import SvgIcons from "components/svgIcons";

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaved: false,
    };
    this.template = this.gridTemplate;
    this.tablerow = [];
    this.onEnterEdit = this.onEnterEdit.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
  }
  onUpdate(index) {
  
    const tableLength = this.tablerow.length;
    const ind = parseInt(index);
    if (ind < tableLength - 1) {
      const tab = this.tablerow[ind + 1];
      // tab.onNoteEdit();
      tab.onOpen("sst");
    }
  }
  gridTemplate(props) {
    return (
      <TableRow
        args={props}
        actClass={false}
        onRowUpdateChange={this.onUpdate}
        onEnterEdit={this.onEnterEdit}
        ref={(tablerow) => {
          this.tablerow = [...this.tablerow, tablerow];
        }}
      />
    );
  }

  onEnterEdit(data) {
    const { adjust_tds, adjust_sst } = this.tablerow[data.index].props.args;
    if (adjust_tds !== data.tds || adjust_sst !== data.sst) {
      this.setState({ isSaved: true });
    } else {
      this.setState({ isSaved: false });
    }
    // console.log(this.tablerow[data.index].props.actClass)
  }
  checkIfSaved() {
    let a = this.shouldBlockNavigation();
    console.log(a);
    if (a) {
      let message = JSON.stringify({
        confirmText: "",
        title: "Are You Sure ?",
        messageText: `It looks like you might have some unsaved  
                                hanges! Are you sure you want to continue?`,
        cancelText: "Do not Continue",
      });
      return message;
    } else {
      return true;
    }
  }
  shouldBlockNavigation() {
    if (!isEmpty(this.tablerow)) {
      //   console.log(this.tablerow)
      let isEdit = false;
      this.tablerow.map((table) => {
        const { AdjustmentSST, AdjustmentTDS } = table.state;
        const { adjust_tds, adjust_sst } = table.props.args;
        if (adjust_tds !== AdjustmentTDS || adjust_sst !== AdjustmentSST) {
          isEdit = true;
        }
      });
      if (isEdit) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  // componentWillMount() {
  //     let a =this.shouldBlockNavigation();
  //     if (a) {
  //         window.onbeforeunload = () => true
  //     } else {
  //         window.onbeforeunload = null
  //     }
  // }
  // componentWillUnmount() {
  //     onbeforeunload = null
  // }
  render() {
    const data = [
      {
        EIN: "HO-325",
        Name: "Kamala Gharti",
        pay_before_tax: "10,000.00",
        sst: "300.00",
        tds: "100.00",
        net_pay: "15,700.00",
        adjust_sst: "",
        adjust_tds: "",
        adjusted_sst: "200.00",
        adjusted_tds: "100.00",
        adjusted_net_pay: "15,700.00",
      },
      {
        EIN: "HO-315",
        Name: "New Employee",
        pay_before_tax: "12,000.00",
        sst: "500.00",
        tds: "200.00",
        net_pay: "18,000.00",
        adjust_sst: "",
        adjust_tds: "",
        adjusted_sst: "300.00",
        adjusted_tds: "200.00",
        adjusted_net_pay: "18,000.00",
      },
    ];
    const {
      searchedTaxAdjustment,
      loading,
      searchedPage,
    } = this.props.taxAdjustmentReducer;

    return (
      <div className="control-pane">
        <div className="arrear-table">
          {/* <Prompt
                        message={this.checkIfSaved.bind(this)}
                        // when={this.state.isSaved}
                        // message='You have unsaved changes, are you sure you want to leave?'
                    /> */}
          {loading || searchedTaxAdjustment === null ? (
            <Loader open={loading} />
          ) : (
            <GridComponent
              dataSource={searchedTaxAdjustment}
              //   pageSettings={this.pageSettings}
              //   toolbar={this.toolbarOptions}
              allowPaging={true}
              rowTemplate={this.template.bind(this)}
              className="custom-grid"
              //   editSettings={this.editSettings}
            >
              <ColumnsDirective>
                <ColumnDirective field="EIN" width="70" />
                <ColumnDirective
                  field="Name"
                  headerText="Name"
                  hello="sa"
                  width="140"
                />
                <ColumnDirective
                  field="pay_before_tax"
                  headerText="Pay Before Tax"
                  width="100"
                />
                <ColumnDirective field="sst" headerText="SST" width="100" />
                <ColumnDirective field="tds" headerText="TDS" width="90" />
                <ColumnDirective
                  field="net_pay"
                  headerText="Net Pay"
                  width="100"
                />
                <ColumnDirective
                  field="adjust_sst"
                  headerText="Adjust SST"
                  width="100"
                />
                <ColumnDirective
                  field="adjust_sst"
                  headerText="Adjust TDS"
                  width="100"
                />
                <ColumnDirective
                  field="adjusted_sst"
                  headerText="Adjusted SST"
                  width="100"
                />
                <ColumnDirective
                  field="adjusted_tds"
                  headerText="Adjusted TDS"
                  width="100"
                />
                <ColumnDirective
                  field="adjusted_net_pay"
                  headerText="Adjusted Net Pay"
                  width="100"
                />
                <ColumnDirective field="" width="120" />
              </ColumnsDirective>
              {/* <Inject services={[Edit]}/> */}
            </GridComponent>
          )}
          <div className="bottom-bar">
          {isEmpty(searchedTaxAdjustment) ? null : (
            <CommonPagination
              handleRowChange={this.props.handleRowChange}
              handleIndexChange={this.props.handleIndexChange}
              pageIndex={this.props.pageIndex}
              searchedPage={searchedPage}
              rowsPerPage={this.props.rowsPerPage}
              />
              )}
            {isEmpty(searchedTaxAdjustment) ? null :<>
            <div className="bottom-bar-buttons">
              <div className="button-group button-group__right">
                  <h3 className="card-sm__title align-center">Verified by Rina Dhungel on 2076 Ashwin 30, 04:05 pm</h3>
              </div>
              <div className="button-group button-group__right">
                <div className="card-sm green flex align-center">
                  <SvgIcons name="ApprovedCircle" className="approved-icon ml-xsm" />
                  <h3 className="card-sm__title align-center">Verified</h3>
                </div>
              </div>
              <div className="button-group button-group__right">
                <div className="card-sm green flex align-center">
                  <h3 className="card-sm__title align-center">Send For Verification</h3>
                </div>
              </div>
              <div className="button-group button-group__right">
                <div className="card-sm blue flex align-center">
                  <h3 className="card-sm__title align-center">Recalculate Tax</h3>
                </div>
              </div>
            </div>
            </>}
          </div>
        </div>
      </div>
    );
  }
}

export class TableRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sstEdit: false,
      tdsEdit: false,
      AdjustmentSST: "",
      AdjustmentTDS: "",
    };
    this.sst = "";
    this.tds = "";
    this.onEdit = this.onEdit.bind(this);
    this.onEnter = this.onEnter.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleEscKey = this.handleEscKey.bind(this);
    this.handleClick = this.handleClick.bind(this);

    // this.onSave=this.onSave.bind(this);
    // this.onModalClose=this.onModalClose.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.onOpen = this.onOpen.bind(this);
    // this.onNoteEdit=this.onNoteEdit.bind(this);
  }
  componentDidMount() {
    document.addEventListener("keyup", this.handleEscKey);
    document.addEventListener("mousedown", this.handleClick);
  }
  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleEscKey);
    document.removeEventListener("mousedown", this.handleClick);
  }
  handleEscKey(e) {
    if (e.keyCode === 27 && e.key === "Escape") {
      if (this.sst && !this.sst.contains(e.target)) {
        this.setState({ tdsEdit: false });
      }
      if (this.tds && !this.tds.contains(e.target)) {
        this.setState({ sstEdit: false });
      }
    }
  }
  handleClick(e) {
    const ele = document.getElementById("uploadInput");
    if (
      this.sst &&
      !this.sst.contains(e.target) &&
      ele &&
      !ele.contains(e.target)
    ) {
      this.setState({ sstEdit: false });
    }
    if (
      this.tds &&
      !this.tds.contains(e.target) &&
      ele &&
      !ele.contains(e.target)
    ) {
      this.setState({ tdsEdit: false });
    }
  }
  onEdit(e, name) {
    if (e.target.tagName.toUpperCase() !== "INPUT") {
      if (name === "sst") {
        this.setState({ sstEdit: !this.state.sstEdit });
      }
      if (name === "tds") {
        this.setState({ tdsEdit: !this.state.tdsEdit });
      }
    }
  }
  onEnter(e, name) {
    const { onEnterEdit, args } = this.props;
    if (e.keyCode === 13) {
      // const data={tds:this.state.AdjustmentTDS,sst:this.state.AdjustmentSST,index:args.index};
      // onEnterEdit(data);
      // this.onEdit(e,name)
      if (name === "sst") {
        this.setState({ sstEdit: !this.state.sstEdit });
      }
      if (name === "tds") {
        this.setState({ tdsEdit: !this.state.tdsEdit });
      }
    }
    if (e.keyCode === 27 && e.key === "Escape") {
      if (name === "sst") {
        this.setState({ sstEdit: false });
      }
      if (name === "tds") {
        this.setState({ tdsEdit: false });
      }
    }
  }
  onOpen(name) {
    if (name === "sst") {
      this.setState({ sstEdit: true });
    } else if (name === "tds") {
      this.setState({ tdsEdit: true });
    }

   
  }
  // formatNum(num){
  //     let number = parseInt(num)
  //     // let fixed = number.toFixed(2);
  //     return number.toLocaleString(navigator.language, { minimumFractionDigits: 2 })
  //     // console.log(number.toLocaleString())
  // }
  onChange = (value) => {
    console.log(value);
    const { AdjustmentSST, AdjustmentTDS } = value;
    const newSst = isEmpty(AdjustmentSST) ? null : parseInt(AdjustmentSST);
    const newTds = isEmpty(AdjustmentTDS) ? null : parseInt(AdjustmentTDS);
    this.setState(
      {
        AdjustmentTDS: newTds,
        AdjustmentSST: newSst,
        isEdit: true,
      },
      () => {
        this.onUpdate();
      }
    );
  };
  // onNoteEdit(name){
  //     if(name==='note'){
  //         this.setState({isNote:true})
  //     }else{
  //         this.setState({isNote:false})
  //     }
  // }

  onCancel = () => {
    this.setState({ sstEdit: false });
  };
  onUpdate = () => {
    const { onRowUpdateChange, args } = this.props;
    // const {AdjustmentAmount,Note} = this.state;
    onRowUpdateChange(args.index);
    this.onCancel();

    // if(!isEmpty(AdjustmentAmount) || !isEmpty((Note))){
    //     this.onSave();
    // }
  };
  render() {
    const { args, actClass } = this.props;
    const { AdjustmentSST, AdjustmentTDS, sstEdit, tdsEdit } = this.state;
    const rowClass = classnames({
      "leave-row": true,
      "tax-row": true,
      bordered: true,
      "active-row": sstEdit || tdsEdit,
      active:
        (AdjustmentSST !== args.AdjustSST && !isEmpty(args.AdjustSST)) ||
        (AdjustmentTDS !== args.AdjustTDS && !isEmpty(args.AdjustTDS)),
      // ||
      // !isEmpty()
    });
    const sstTdClass = classnames({
      "align-right": true,
      "editable-td": true,
      editedCol: sstEdit,
    });
    const tdsTdClass = classnames({
      "align-right": true,
      "editable-td": true,
      editedCol: tdsEdit,
    });
    return (
      <tr className={rowClass}>
        <td className="">
          <a href="#">
            <span>{args.EmployeeId}</span>
          </a>
        </td>
        <td className="">
          <a href="#">
            <span>{args.Name}</span>
          </a>
        </td>
        <td className="">
          <span className="text-bold" textAlign="Right">
            {getFormattedNumber(args.PayBeforeTax)}
          </span>
        </td>
        <td className="">
          <span className="text-red" textAlign="Right">
            {getFormattedNumber(args.SST)}
          </span>
        </td>
        <td className="">
          <span className="text-red">{getFormattedNumber(args.TDS)}</span>
        </td>
        <td className="">
          <span className="text-green">{getFormattedNumber(args.NetPay)}</span>
        </td>
        <td
          className={sstTdClass}
          ref={(sst) => (this.sst = sst)}
          onKeyUp={(e) => this.onEnter(e, "sst")}
          onClick={(e) => this.onEdit(e, "sst")}
          // onDoubleClick={e => this.onEdit(e, "sst")}
        >
          {this.state.sstEdit ? (
            <>
              <span className="editedText">
                {isEmpty(args.AdjustmentSST)
                  ? 0
                  : getFormattedNumber(args.AdjustmentSST)}
              </span>
              <UpdateInput
                ele={this.sst}
                onChange={this.onChange}
                amountName="AdjustmentSST"
                // noteName="Note"
                noNote
                onUpdate={this.onUpdate}
                onCancel={this.onCancel}
                amountValue={AdjustmentSST}
                // noteValue={Note}
                isNote={this.state.isNote}
              />
            </>
          ) : (
            // <input
            //     type="number"
            //     name="AdjustmentSST"
            //     autoFocus
            //     value={this.state.AdjustmentSST}
            //     className="table-input"
            //     onChange={this.onChange}
            // />
            <span className="text-blue">
              {isEmpty(AdjustmentSST)
                ? isEmpty(args.AdjustSST)
                  ? "Adjust"
                  : args.AdjustSST
                : AdjustmentSST >= 0
                ? `+ ${AdjustmentSST}`
                : `- ${AdjustmentSST}`}
            </span>
          )}
        </td>
        <td
          className={tdsTdClass}
          ref={(tds) => (this.tds = tds)}
          onKeyUp={(e) => this.onEnter(e, "tds")}
          onClick={(e) => this.onEdit(e, "tds")}
          // onDoubleClick={e => this.onEdit(e, "tds")}
        >
          {this.state.tdsEdit ? (
            <input
              type="number"
              name="AdjustmentTDS"
              autoFocus
              value={this.state.AdjustmentTDS}
              className="table-input"
              onChange={this.onChange}
            />
          ) : (
            <span className="text-blue">
              {isEmpty(AdjustmentTDS)
                ? isEmpty(args.AdjustTDS)
                  ? "Adjust"
                  : args.AdjustTDS
                : AdjustmentTDS >= 0
                ? `+ ${AdjustmentTDS}`
                : `${AdjustmentTDS}`}
            </span>
          )}
        </td>
        <td className="">
          <span className="text-red">{args.AdjustedSST}</span>
        </td>
        <td className="">
          <span className="text-red">{args.AdjustedTDS}</span>
        </td>
        <td className="">
          <span className="text-green">{args.AdjustedNetPay}</span>
        </td>
        <td>
          <div className="flex">
            <div className="tool">
              {!isEmpty(args.AdjustedBy) ? (
                <Tooltip
                  title={
                    <div className="tooltip">
                      <span className="name" style={{ marginBottom: "5px" }}>
                        {args.AdjustedBy && args.AdjustedBy}
                      </span>
                      <span className="date">
                        <Moment format="YYYY/MM/DD">
                          {args.AdjustedOn && args.AdjustedOn}
                        </Moment>
                      </span>
                    </div>
                  }
                  placement="bottom"
                >
                  <span className="tool-icon">
                    <MdDateRange />
                    {/* <FaUserEdit /> */}
                  </span>
                </Tooltip>
              ) : null}
            </div>
            <button className="btn btn-blue">Save</button>
          </div>
        </td>
      </tr>
    );
  }
}

Table.propTypes = {
  taxAdjustmentReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  taxAdjustmentReducer: state.taxAdjustmentReducer,
});

export default connect(mapStateToProps)(Table);
