import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles,makeStyles } from '@material-ui/core/styles';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 8,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: '#edf1f6',
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#63e094',
    },
  }))(LinearProgress);

  const useCustomStyle = makeStyles((theme) => ({
    root: {
        backgroundColor: '#333',
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#333',
    },
    barDanger:{
        backgroundColor:'#f55f5f'
    }
  }));

function CmmonLinearProgress({progress,type}) {
    const classes = useCustomStyle();

    return (
        <BorderLinearProgress 
            classes={
                type === 'danger' ? {bar:classes.barDanger} 
                : null
            } 
            variant="determinate" 
            value={progress} 
        />
    )
}

CmmonLinearProgress.defaultProps={
    type:'success'
}

export default CmmonLinearProgress
