import React from 'react';
import {getUploadPath} from 'constants/userDetails'

export default function ViewDocument ({ viewDocs }) {
    const { file, isFetched } = viewDocs
    
    if (file) {
      let fileDest = getUploadPath(viewDocs.ServerFileName);
      if (!isFetched) {
        return (
          <div className="file-view">
            <iframe
              className={"filetype"}
              width="100%"
              height="600"
              frameborder="0"
              src={URL.createObjectURL(file)}
            ></iframe>
          </div>
        );
      } else return <span></span>;
    }
}