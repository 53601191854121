import React, { Component } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import logo from "assets/images/rigo_logo.png";
import isEmpty from "isEmpty";
import classnames from "classnames";
import { connect } from "react-redux";
import { Features } from "helpers/getPermissions";
import SvgIcons from "components/icons/svgIcons";
import SideLink from "./sideLink";
import RouteEnum from "constants/routeEnum";

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      side: "",
      features: {},
      companyInfo: null,
      selectedIndex: -1,
    };
    this.timerID = null;
    // this.wrapperRef = React.createRef();
    this.onSideClick = this.onSideClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this._renderSideBar = this._renderSideBar.bind(this);
    this.handleMove = this.handleMove.bind(this);
    this.getCategoryIcon = this.getCategoryIcon.bind(this);
    this.getLinkForCategory = this.getLinkForCategory.bind(this);
  }

  onLogoutClick(e) {
    e.preventDefault();
    // this.props.clearCurrentProfile();
    this.props.logoutUser();
  }
  onSideClick(index) {
    this.setState({ selectedIndex: index });
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    // document.addEventListener("mousemove", this.handleMove);
    this.timerID = setInterval(() => {
      const features = Features();
      this.handleMove();
    }, 500);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    clearInterval(this.timerID);
  }
  handleMove(e) {
    const features = Features();
    if (isEmpty(this.state.feature)) {
      if (!isEmpty(features)) {
        this.setState({ features });
        clearInterval(this.timerID);
      }
    }
  }
  handleClickOutside(e) {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      this.setState({ selectedIndex: -1 });
    }
  }
  getCategoryIcon(catId) {
    switch (catId) {
      case 1:
        return "techo";
      case 2:
        return "tasks";
      case 3:
        return "scroll";
      case 4:
        return "sliders";
      default:
        return "";
    }
  }

  getLinkForCategory(catId) {
    const { selectedIndex } = this.state;
    const { activeFeaturesCategories } = this.props;
    const sideContent = activeFeaturesCategories[selectedIndex];

    if (catId === 1) {
      //dashboard
      const FeatureCategories = sideContent.FeatureCategories.find(
        (x) => x.Category === 1
      );
      const LinkCategories =
        (FeatureCategories &&
          FeatureCategories.LinkCategories &&
          FeatureCategories.LinkCategories) ||
        [];
      let link = "/";
      if (LinkCategories.length > 0) {
        const links = LinkCategories[0].Links || [];
        link = `${"/"}${links.length > 0 ? links[0].Link : ""}`;
      }
      return link;
    } else {
      return `${RouteEnum.Links}/${sideContent.Feature}/${catId}`;
    }
  }

  _renderSideBar() {
    const { activeFeaturesCategories } = this.props;
    const { selectedIndex } = this.state;
    if (selectedIndex === null || selectedIndex === -1) {
      return null;
    }
    const sideContent = activeFeaturesCategories[selectedIndex];
    return (
      <div className="right-side">
        <div className="right-side-header">
          <div className="right-side-header__icon">
            <SvgIcons size="20px" name={sideContent.Icon} color="#222" />
          </div>
          <div className="right-side-header__name">
            <h3>{sideContent.Title}</h3>
          </div>
        </div>
        <div className="right-side-body">
          <ul className="right-side-links">
            {sideContent.FeatureCategories.map((cat) => {
              return (
                <li className="right-side-links__item">
                  <NavLink
                    to={this.getLinkForCategory(cat.Category)}
                    activeClassName="active-link"
                  >
                    <span className="right-side-links_icon">
                      <SvgIcons
                        size="20px"
                        name={this.getCategoryIcon(cat.Category)}
                        color="#222"
                      />
                    </span>
                    <span className="right-side-links_name">
                      {cat.CategoryName}
                    </span>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
  render() {
    const { activeFeaturesCategories } = this.props;
    // const { companyInfo } = this.state;
    const getClass = (item) => {
      let classe = classnames({
        "sidebar-link__item": true,
        "with-side": true,
        active: item === this.state.side,
      });
      return classe;
    };
    const getSideClass = (item) => {
      let classes = classnames({
        "sidebar-sidetab": true,
        sidetab: true,
        open: item === this.state.side,
      });
      return classes;
    };
    const getLink = (item) => {
      let link;
      // link = getBaseAxios() + item;
      return link;
    };

    const getLinkClass = (item) => {
      return classnames({
        "active-link":
          item === this.state.side ||
          this.props.location.pathname.includes(item),
      });
    };
    const { side, selectedIndex } = this.state;
    // const features = Features();
    const features = this.state.features;

    return (
      <div
        id="sidebar"
        ref={(ref) => (this.wrapperRef = ref)}
        className="sidebar sidebar-open"
      >
        <div className="sidebar-logo">
          <div className="sidebar-logo-image">
            <img src={logo} height="23" width="23" alt="Rigo Logo" />
          </div>
          {/* <h4 className="sidebar-logo__heading">Rigo Tech</h4> */}
        </div>
        <ul className="sidebar-link">
          {activeFeaturesCategories &&
            activeFeaturesCategories.map((feature, index) => (
              <SideLink
                side={side}
                selectedIndex={selectedIndex}
                index={index}
                onSideClick={this.onSideClick}
                name={feature.Feature}
                title={feature.Title}
                feature={feature.Feature}
                featureId={feature.FeatureId}
                icon={feature.Icon}
              />
            ))}
        </ul>
        {this._renderSideBar()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeFeaturesCategories: state.permissionReducer.activeFeaturesCategories,
  // companyInformation: state.commonReducer.companyInformation,
});

// export default connect(mapStateToProps,{logoutUser})(Sidebar);
export default withRouter(connect(mapStateToProps, null)(Sidebar));
