import * as React from "react";
import FormGroup from "components/form";
import { EMPLOYEE_REQUEST_CHIPS_FILTER_LIST } from "constants/constants";

export interface IAppProps {
  handleFieldChange: Function;
  chipsFilterValue: any;
}

export default function App(props: IAppProps) {
  const { handleFieldChange, chipsFilterValue } = props;
  return (
    <div>
      <FormGroup
        formName="chipsinput"
        name="chipsFilters"
        chipsArray={EMPLOYEE_REQUEST_CHIPS_FILTER_LIST}
        value={chipsFilterValue}
        onChange={handleFieldChange}
        className="leave-chipsFilter"
        multiple={true}
      />
    </div>
  );
}
