import React from "react";
import Breadcrumb from "scenes/common/sbiLoanBreadcrumb";
import LoanSetting from "./loanSettingtable";

const index = () => {
  return (
    <div className="common-container dashboard">
      <Breadcrumb name="Loan Settings" />
      <header className="dashboard__header">
        <div className="space-between panel-section">
          <h3 className="tertiary-title">Loan Settings</h3>
        </div>
      </header>
      <LoanSetting />
    </div>
  );
};

export default index;
