import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import classnames from "classnames";
import UpdateInput from "../../../../components/common/updateInput";
import FormGroup from "components/form";
import referenceForm from "scenes/employee/employeeProfile/education/referenceForm";
import { ListItemSecondaryAction } from "@material-ui/core";
import isEmpty from "helpers/isEmpty";
import { Consumer } from "../helpers/context";

export default (props) => (
  <Consumer>
    {(value) => {
      const { jobCreate, dispatch } = value;
      // const {jobCreate:{RecruitmentPositionDetailDto},dispatch}= value;
      return (
        <MarkingTable
          dispatch={dispatch}
          jobCreate={jobCreate}
          errors={props.errors}
        />
      );
    }}
  </Consumer>
);

function MarkingTable(props) {
  return (
    <div className="marking-table">
      <div className="marking-table__header marking-table__row">
        <div className="marking-table__heading">
          <span>Marking Areas</span>
        </div>
        <div className="marking-table__heading">
          <span>Highest Marks</span>
        </div>
      </div>
      <MarkingBody {...props} />
    </div>
  );
}

const MarkingBody = (props) => {
  const [total, setTotal] = useState(0);
  const [jobCreate, setJobCreate] = useState(props.jobCreate);
  //  dispatch({ type: "onPositionChange", payload: { name, value } });
  const handleChange = (items) => {
    const { RecruitmentJobMarkingSchemeDto: jm } = jobCreate;

    var total = 0;
    var payload: any = {};
    if (jm) {
      payload.Id = jm.Id || 0;
      payload.JobRef_Id = jm.JobRef_Id || 0;
    }
    items.forEach((el) => {
      let val = (el.value && parseInt(el.value)) || 0;
      total += val;
      payload[el.name] = val;
      el.items &&
        el.items.forEach((x) => {
          payload[x.name] = x.value;
        });
    });

    setTotal(total);
    const { dispatch } = props;
    dispatch({ type: "onMarkingSchemeChange", payload: payload });
  };
  const onTotalUpdate = (total) => {
    setTotal(total);
  };
  return (
    <div className="marking-table__body">
      <MarkingBodyItem
        onChange={handleChange}
        onTotalUpdate={onTotalUpdate}
        {...props}
      />
      <div className="marking-table__body-row">
        <div className="marking-table__row marking-table__body-footer">
          <div className="marking-table__body-footer-title">Total</div>
          <div className="marking-table__body-footer-title">{total}</div>
        </div>
      </div>
    </div>
  );
};

export interface IAppProps {
  onChange: any;
}

interface State {
  open: boolean;
  name: string;
  sstEdit: boolean;
  bodyItems: any[];
  title: string;
  amountValue: any;
}
const classes = (flag) => {
  return classnames("marking-table__body-row", {
    "row-expanded": flag,
  });
};
const sstTdClass = (flag) => {
  return classnames({
    "align-right": true,
    "editable-td": true,
    editedCol: flag,
  });
};

class MarkingBodyItem extends React.Component<IAppProps, State> {
  sst: any;
  referencedItems: any = {};
  onValueChanged: any;
  constructor(props) {
    super(props);
    this.onValueChanged = props.onChange;
    this.state = {
      open: false,
      title: props.title,
      name: props.name,

      bodyItems: this.getMarkingBodyItems(props),
      sstEdit: false,
      amountValue: "",
    };

    this.sst = React.createRef();
  }

  calculateTotal(items) {
    let total = 0;
    items.forEach((el) => {
      let val = (el.value && parseInt(el.value)) || 0;
      total += val;
    });
    return total;
  }
  getMarkingBodyItems(props) {
    const { RecruitmentJobMarkingSchemeDto: jm } = props && props.jobCreate;
    const itemValues: any = {};

    itemValues["Scorecard"] = (jm && jm.Scorecard) || 0;
    itemValues["SkillsHMarks"] = (jm && jm.SkillsHMarks) || 0;
    itemValues["PersonalTraitsHMarks"] = (jm && jm.PersonalTraitsHMarks) || 0;
    itemValues["QualificationsHMarks"] = (jm && jm.QualificationsHMarks) || 0;
    itemValues["OtherDetailsHMarks"] = (jm && jm.OtherDetailsHMarks) || 0;
    itemValues["WrittenExamHMarks"] = (jm && jm.WrittenExamHMarks) || 0;
    itemValues["InterviewHMarks"] = (jm && jm.InterviewHMarks) || 0;

    const markingBodyItems = [
      {
        isOpened: true,
        name: "Scorecard",
        title: "Scorecard",
        value: itemValues["Scorecard"],
        isEdit: false,
        items: [
          {
            title: "Skills",
            name: "SkillsHMarks",
            value: itemValues["SkillsHMarks"],
          },
          {
            title: "Persona Traits",
            name: "PersonalTraitsHMarks",
            isEdit: false,
            value: itemValues["PersonalTraitsHMarks"],
          },
          {
            title: "Qualifications",
            name: "QualificationsHMarks",
            isEdit: false,
            value: itemValues["QualificationsHMarks"],
          },
          {
            title: "Other Details",
            name: "OtherDetailsHMarks",
            isEdit: false,
            value: itemValues["OtherDetailsHMarks"],
          },
        ],
      },
      {
        isOpened: true,
        name: "WrittenExamHMarks",
        title: "Written Exam",
        items: [],
        value: itemValues["WrittenExamHMarks"],
        isEdit: false,
      },
      {
        isOpened: true,
        name: "InterviewHMarks",
        title: "Interview",
        items: [],
        value: itemValues["InterviewHMarks"],
        isEdit: false,
      },
    ];

    const total = this.calculateTotal(markingBodyItems);
    props.onTotalUpdate && props.onTotalUpdate(total);
    return markingBodyItems;
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    let targetName = (e.target && e.target.localName) || "";
    if (targetName.toUpperCase() === "INPUT") {
      return;
    }
    for (let key in this.referencedItems) {
      let item = this.referencedItems[key];

      item.ref &&
        item.ref.handleParentAction &&
        item.ref.handleParentAction(item);
    }
  };

  update(parentIndex, index, key, item) {
    const nextState = { ...this.state };

    if (this.referencedItems[key])
      this.referencedItems[key].inEditMode = item.isEdit;

    if (!isEmpty(index)) {
      nextState.bodyItems[parentIndex].items[index] = item;
      let items = nextState.bodyItems[parentIndex].items;
      var total = 0;
      for (var i = 0; i < items.length; i++) {
        if (items[i].value) {
          total += parseInt(items[i].value);
        }
      }
      nextState.bodyItems[parentIndex].value = total;
    } else {
      nextState.bodyItems[parentIndex] = item;
    }

    this.setState(nextState);
    this.onValueChanged && this.onValueChanged(nextState.bodyItems);
  }

  render() {
    const { open, amountValue, bodyItems } = this.state;
    const elem = bodyItems.map((item, parentIndex) => {
      const { name, title, items, value, isOpened, isEdit } = item;
      const markingHeaderItem = { name: name, title: title, value: value };

      return (
        <div className={classes(parentIndex == 0)}>
          <div
            className="marking-table__body-header"
            onClick={() => {
              //this.setState({ open: !this.state.open });
            }}
          >
            <MarkingItem
              ref={(instance) => {
                this.referencedItems[name] = {
                  ref: instance,
                  inEditMode: false,
                };
              }}
              className="marking-table__row  marking-table__body-heading"
              item={markingHeaderItem}
              parentIndex={parentIndex}
              isEditable={(items && items.length === 0) || false}
              onChange={(parentIndex, index, key, item) => {
                this.update(parentIndex, index, key, item);
              }}
            />
          </div>
          <div className="marking-table__body-items">
            {items &&
              items.map((_item, index) => {
                return (
                  <MarkingItem
                    ref={(instance) => {
                      let key = _item.name + "-" + index;
                      this.referencedItems[key] = {
                        ref: instance,
                        inEditMode: false,
                      };
                    }}
                    className="marking-table__row marking-table__body-item"
                    item={_item}
                    parentIndex={parentIndex}
                    index={index}
                    onChange={(parentIndex, index, key, item) => {
                      this.update(parentIndex, index, key, item);
                    }}
                  />
                );
              })}
          </div>
        </div>
      );
    });
    return elem;
  }
}

const MarkingItem = forwardRef((props: any, ref: any) => {
  const [item, setItem] = useState({ ...props.item });
  const [parentIndex, setParentIndex] = useState(props.parentIndex);
  const [index, setIndex] = useState(props.index);
  const [isEditable, setIsEditable] = useState(
    isEmpty(props.isEditable) ? true : props.isEditable
  );
  const { className } = props;
  const [inEditMode, setInEditMode] = useState(false);

  useEffect(() => {
    const newItem = { ...props.item };
    setItem(newItem);
  }, [props.item]);

  useImperativeHandle(ref, () => ({
    async handleParentAction(itm) {
      setInEditMode(false);
    },
  }));

  const onEnter = (e) => {
    if (e.keyCode === 27 || e.keyCode === 13) {
      setInEditMode(false);
    }
  };
  const onEdit = (e) => {
    if (!isEditable) {
      return;
    }
    if (e.target.tagName.toUpperCase() !== "INPUT") {
      setInEditMode(true);
    }
  };

  const onChange = (name, value) => {
    const newItem = { ...item };
    newItem.value = value;
    newItem.isEdit = inEditMode;
    setItem(newItem);
    let key = newItem.name;

    if (!isEmpty(index)) {
      key = key + "-" + index;
    }
    props.onChange && props.onChange(parentIndex, index, key, newItem);
  };

  const renderer = () => {
    const { name, title, value } = item;

    return (
      <div className={className}>
        <span>{title}</span>
        {inEditMode ? (
          <span
            className={sstTdClass(inEditMode)}
            onKeyUp={(e) => onEnter(e)}
            onClick={(e) => onEdit(e)}
          >
            <FormGroup
              autoFocus
              name={name}
              value={value}
              onChange={onChange}
              width="100%"
              validators={["required"]}
            />
          </span>
        ) : (
          <span
            className={sstTdClass(inEditMode)}
            //ref={this.sst}
            onKeyUp={(e) => onEnter(e)}
            onClick={(e) => onEdit(e)}
          >
            {isEmpty(value) ? "-" : value}
          </span>
        )}
      </div>
    );
  };

  return renderer();
});

//export default MarkingTable;
