import * as React from "react";
import FormGroup from "components/form";
import Button from "components/button";
import Icons from "components/icons";
import { BrowserRouter, Route, Link } from "react-router-dom";
import RouteEnum from 'constants/routeEnum'
import ExportExcel from "components/excel/exportExcel";

export interface IAppProps {
  jobFamily: any;
  jobFamilyList: any;
  jobRole: any;
  jobRoleList: [];
  jobCode: string;
  jobTitle: string;
  handleInputChange: Function;
  type: string;
  exportName: string;
  exportColumns: any;
  exportData: any;
  handleExcelExport: Function;
  isMultiDeleteEnable: boolean;
  handleDelMultipleRecord: Function;
}

export default function App(props: IAppProps) {
  const {
    jobFamily,
    jobFamilyList,
    jobRole,
    jobRoleList,
    jobCode,
    jobTitle,
    handleInputChange,
    type,
    exportName,
    exportColumns,
    exportData,
    handleExcelExport,
    isMultiDeleteEnable,
    handleDelMultipleRecord,
  } = props;
  return (
    <div className="filters">
      <div className="filters-grid">
        <FormGroup
          formName="reactselect"
          onChange={handleInputChange}
          name="jobFamily"
          value={jobFamily}
          options={jobFamilyList.value}
          // loading={jobFamilyList.loading}
          // loadingType="skeleton"
          placeholder="Select Job Family"
          //width="200px"
        />
      </div>
      <div className="filters-grid">
        <FormGroup
          formName="reactselect"
          onChange={handleInputChange}
          name="jobRole"
          value={jobRole}
          options={jobRoleList}
          placeholder="Select Job Role"
          // width="200px"
        />
      </div>

      <div className="filters-grid">
        <FormGroup
          onChange={handleInputChange}
          name="jobCode"
          value={jobCode}
          type="text"
          placeholder="Search Job Code"
          // width="200px"
        />
      </div>

      <div className="filters-grid">
        <FormGroup
          onChange={handleInputChange}
          name="jobTitle"
          value={jobTitle}
          type="text"
          placeholder="Search Job Title"
          //width="200px"
        />
      </div>
      <div className="library">
        <Link
          to={
            type === "jobProfile"
              ? RouteEnum.CreateJobProfilePath
              : RouteEnum.CreateJobDescriptionPath
          }
          target={type === "jobProfile" ? "_blank" : null}
        >
          <Button
            buttonType="icon-button"
            type="button"
            bg="white"
            icon={<Icons name="Add" />}
          >
            {type === "jobProfile" ? "Add Job Profile" : "Add Job Description"}
          </Button>
        </Link>
      </div>
      <div className="library">
        <ExportExcel
          onClick={handleExcelExport}
          fileName={exportName}
          columns={exportColumns}
          sheets={[{ sheetName: exportName, data: exportData }]}
          className="button button-subtle"
        />
      </div>
      {isMultiDeleteEnable && (
        <div className="filters-grid library">
          <Button
            buttonType="icon-button"
            type="button"
            bg="white"
            icon={<Icons name="Delete" />}
            onClick={() => handleDelMultipleRecord()}
          >
            Delete Selected
          </Button>
        </div>
      )}
    </div>
  );
}
