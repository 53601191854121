import React from "react";
import { connect } from "react-redux";
import { FaHandPaper, FaRegCheckCircle } from "react-icons/fa";
import { MdNotInterested } from "react-icons/md";
import { MdMailOutline } from "react-icons/md";

import isEmpty from "isEmpty";
import { ConvertToDataSource } from "helpers/getTextLabel";
import FormGroup from "components/form";
import { JobStages } from "../../../../enums";
import JobStageSelect from "src/scenes/recruit/common/jobStageSelect";
import { AiOutlineMail, AiOutlineDelete, AiOutlineCloseCircle } from "react-icons/ai";
import { IoArrowUpCircleOutline } from "react-icons/io5";
import { BsExclamationCircle } from "react-icons/bs";
import { FiCheckCircle } from "react-icons/fi";
import { TiMessage } from "react-icons/ti";
import { BiExport } from "react-icons/bi";
import ExportExcel from "components/excel/exportExcel";

const exportColumns = [
  { value: "CandidateId", label: "Candidate Id" },
  { value: "CandidateName", label: "Candidate Name" },
  { value: "CurrentState", label: "Current State" },
  { value: "CurrentCompany", label: "Current Company" },
  { value: "CurrentPosition", label: "Current Position" },
  { value: "ExperienceInDays", label: "Experience In Days" },
  { value: "ExperienceInMonths", label: "Experience In Months" },
  { value: "ExperienceInYears", label: "Experience In Years" },
  { value: "Institute", label: "Institute" },
  { value: "TotalExp", label: "Total Expereince" },
  { value: "TotalOrgWorked", label: "Total Org Worked" },
];
const exportName = "Candidates"
function Activities(props: any) {
  const {
    seletedJobStage,
    selectedCandidates,
    hiringStageAndSubStagesJobId,
    history,
    currentCandidateDetail,
    setActivitiesState,
    pageIndex,
    rowsPerPage,
    handleExcelExport,
    candidateList
  } = props;

  const onBulkAction = async (actionType, item) => {
    if (isEmpty(selectedCandidates)) {
      setActivitiesState({
        modalOpen: true,
        modalTitle: "No Candidates Selected",
        modalContent: "Please choose a candidate to use these action",
      });
      // alert("Please choose a candidate to use these action");
      return false;
    }
    if (!currentCandidateDetail) {
      return;
    }
    switch (actionType) {
      case "jobStage":
        updateCandidatesStage(item.StageId, item.HiringSubStageId);
        break;
      case "reject":
        rejectCandidates();
        break;
      case "disqualify":
        disqualifyCandidates();
        break;
      default:
        break;
    }
    return;

    if (item === "email") {
    } else {
      if (currentCandidateDetail) {
        let jobId = currentCandidateDetail.Jobid;
        if (isEmpty(selectedCandidates)) {
          setActivitiesState({
            modalOpen: true,
            modalTitle: "No Candidates Selected",
            modalContent: "Please choose a candidate to use these action",
          });
          // alert("Please choose a candidate to use these action");
          return false;
        }
        const candidateIds = selectedCandidates.map((item) => item.CandidateId);
        const currentIds = selectedCandidates.map((item) => item.CandidateStatusId);
        if (currentIds && currentIds.includes(JobStages.Disqualified)) {
          setActivitiesState({
            modalOpen: true,
            modalTitle: "Disqualified Candidate",
            modalContent: (
              <span>
                <span>You cannot change status of disqualified candidates,</span>
                <span>Please uncheck all the deisqualified candidates</span>
              </span>
            ),
          });
          // alert("You cannot change status of  disqualified candidates, Please uncheck all the deisqualified candidates");
          return false;
        }

        // props.updateCandiates();
      } else {
        alert("No JobId! Please go back and open again ");
      }
    }
  };

  const handleEmailClicked = () => {
    if (isEmpty(selectedCandidates)) {
      setActivitiesState({
        modalOpen: true,
        modalTitle: "No Candidates Selected",
        modalContent: "Please choose a candidate to use these action",
      });
      // alert("Please choose a candidate to use these action");
      return false;
    }
    let jobId = currentCandidateDetail.Jobid;
    props.handleEmailClicked &&
      props.handleEmailClicked({
        jobId,
        selectedCandidates,
      });
  };
  const rejectCandidates = () => {
    let jobId = currentCandidateDetail.Jobid;
    const candidateIds = selectedCandidates.map((item) => item.CandidateId);
    if (
      selectedCandidates.filter(
        (x) =>
          x.CandidateStatusId < JobStages.Shortlisted ||
          x.CandidateStatusId === JobStages.Hired
      ).length > 0
    ) {
      setActivitiesState({
        modalOpen: true,
        modalTitle: "Rejected Candidate",
        modalContent: (
          <span>
            <span>Hired Candidates can not be rejected.</span>
            <span>Please uncheck the candidates</span>
          </span>
        ),
      });
      return false;
    }
    props.handleRejectClick && props.handleRejectClick(jobId, selectedCandidates);
  };
  const disqualifyCandidates = () => {
    let jobId = currentCandidateDetail.Jobid;
    const candidateIds = selectedCandidates.map((item) => item.CandidateId);
    if (selectedCandidates.filter((x) => x.CandidateStatusId > JobStages.Revert).length > 0) {
      setActivitiesState({
        modalOpen: true,
        modalTitle: "Disqualify Candidate",
        modalContent: (
          <span>
            <span>Sourced or Applied Candidates can be only disqualified.</span>
            <span>Please uncheck the candidates</span>
          </span>
        ),
      });
      return false;
    }
    updateCandidatesStage(JobStages.Disqualified);
  };

  const handleDeletClicked = async () => {
    if (selectedCandidates.length == 0) {
      setActivitiesState({
        modalOpen: true,
        modalTitle: "No Candidates Selected",
        modalContent: (
          <span>
            <span>Please choose a candidate to use these action</span>
          </span>
        ),
      });
      return
    }
    const candidateIds = selectedCandidates.map((item) => item.CandidateId);
    let data = {
      jobId: currentCandidateDetail.Jobid,
      pageIndex: pageIndex,
      pageSize: rowsPerPage,
      candidateId: candidateIds.join(","),
      currentStageId: seletedJobStage,
    };
    await props.SetAsDelete(data, props.history);
  }
  const updateCandidatesStage = async (stageId, hiringStageId = null) => {
    let jobId = currentCandidateDetail.Jobid;
    const candidateIds = selectedCandidates.map((item) => item.CandidateId);
    const currentIds = selectedCandidates.map((item) => item.CandidateStatusId);
    if (selectedCandidates.filter((x) => x.IsRejected).length > 0) {
      setActivitiesState({
        modalOpen: true,
        modalTitle: "Rejected Candidate",
        modalContent: (
          <span>
            <span>You cannot change status of rejected candidates,</span>
            <span>Please uncheck all the rejected candidates</span>
          </span>
        ),
      });
      return false;
    }
    if (currentIds) {
      if (currentIds.includes(JobStages.Disqualified)) {
        setActivitiesState({
          modalOpen: true,
          modalTitle: "Disqualified Candidate",
          modalContent: (
            <span>
              <span>You cannot change status of disqualified candidates,</span>
              <span>Please uncheck all the deisqualified candidates</span>
            </span>
          ),
        });
        return false;
      }
    }
    const data = {
      jobId,
      pageIndex: 0,
      pageSize: 10,
      candidateId: candidateIds.join(","),
      stageId: stageId,
      subStageId: hiringStageId,
    };
    await props.updateMultipleCandidateDetails(data, history);
  };

  const onMoveToNextStage = async (stage = 0) => {

    if (selectedCandidates.length == 0) {
      setActivitiesState({
        modalOpen: true,
        modalTitle: "Move To Next Stage",
        modalContent: (
          <span>
            <span>Please choose a candidate to use these action</span>
          </span>
        ),
      });
      return
    }
    const candidateIds = selectedCandidates.map((item) => item.CandidateId);

    let data = {
      jobId: currentCandidateDetail.Jobid,
      pageIndex: pageIndex,
      pageSize: rowsPerPage,
      candidateId: candidateIds.join(","),
      currentStageId: seletedJobStage,
      stageId: stage,
    };

    await props.moveCandidateToNextStage(data, props.history);
  };
  return (
    <>
      {/* <div className="flex action-buttons">
        <div className="blue-auto-select">
          <JobStageSelect
            onChange={(name, val) => {
              onBulkAction("jobStage", val);
            }}
            hiringStageAndSubStagesJobId={props.hiringStageAndSubStagesJobId}
            width="220px"
          />
        </div>
      </div> */}
      <div className="name">
        <div className="jobsaction-buttons">
          <div className="jobsaction jobsaction-stages">
            <button type="button" className="button button-white" onClick={(e) => { onMoveToNextStage(0) }}>
              <IoArrowUpCircleOutline />
              Move to Next Stage
            </button>

            {seletedJobStage == JobStages.Applied ? (
              <button type="button" className="button button-white"
                onClick={() => onBulkAction("disqualify", { stageId: JobStages.Disqualified })}>
                <BsExclamationCircle />
                Disqualify
              </button>

            ) : seletedJobStage !== JobStages.Hired ? (

              <button type="button" className="button button-white"
                onClick={() => onBulkAction("reject", JobStages.Reject)}>
                <AiOutlineCloseCircle />
                Reject
              </button>
            ) : (
              ""
            )}
            {seletedJobStage == JobStages.Applied ? (

              <button type="button" className="button button-white"
                onClick={() =>
                  onBulkAction("jobStage", { stageId: JobStages.Shortlisted })
                }>
                <FiCheckCircle />
                Shortlist
              </button>
            ) : null}


          </div>
          <div className="jobsaction jobsaction-delivers">
            <button type="button" className="button button-white" onClick={handleEmailClicked}>
              <AiOutlineMail />
              Send Email
            </button>
            {/* <button type="button" className="button button-white ">
              <TiMessage />
              Send SMS
            </button> */}
          </div>

          <div className=" jobsaction jobsaction-dismiss">
            <button type="button" className="button button-white" onClick={handleDeletClicked}>
              <AiOutlineDelete />
              Delete Selected
            </button>
          </div>

          <div className=" jobsaction jobsaction-export">
            <ExportExcel
              onClick={handleExcelExport}
              fileName={exportName}
              columns={exportColumns}
              sheets={[{ sheetName: exportName, data: props.exportCandidateTableViewDetails }]}
              className="button button-white"
              showIcon
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Activities;
