import React, { useState, useRef } from "react";
import CustomPopup from "components/common/customPopup";
import { TmTaskPriorityEnum, TmViewType } from "../../../../../../helpers/enum";
import { taskUpdateDetails } from "../../../../../../helpers/action";
import { connect } from "react-redux";
import isEmpty from "helpers/isEmpty";
import { IoMdAdd } from "react-icons/io";
import { MdCheck } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";

const PriorityCell = (props) => {
  const { task, stage, rowIndex } = props;
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const getClassName = (priority: TmTaskPriorityEnum) => {
    switch (priority) {
      case TmTaskPriorityEnum.High:
        return "task-priority-high";
      case TmTaskPriorityEnum.Medium:
        return "task-priority-medium";
      case TmTaskPriorityEnum.Low:
        return "task-priority-low";
      default:
        return "task-priority-none";
    }
  };

  const handlePriorityClick = async (priority) => {
    const updateTask = { ...task };
    updateTask.Priority = task.Priority === priority ? 0 : priority;

    await props.taskUpdateDetails({
      viewType: TmViewType.TableView,
      task: updateTask,
      stage,
      rowIndex,
    });
    //setPriority(priority);
    setOpen(false);
  };

  const getPriorityText = (priority) => {
    switch (priority) {
      case TmTaskPriorityEnum.High:
        return "High";
      case TmTaskPriorityEnum.Medium:
        return "Medium";
      case TmTaskPriorityEnum.Low:
        return "Low";
      default:
        return "";
    }
  };
  const priorities = [
    TmTaskPriorityEnum.High,
    TmTaskPriorityEnum.Medium,
    TmTaskPriorityEnum.Low,
  ];
  const cls = `taskBlock-section taskBlock-section-priority`;

  return (
    <div
      ref={ref}
      // style={task.priorityColor ? { backgroundColor: task.priorityColor } : {}}
      className={cls}
    >
      {!isEmpty(task.Priority) && task.Priority !== 0 ? (
        <span
          onClick={(e) => setOpen(!open)}
          className={`taskBlock-section-priority-title  ${getClassName(
            task.Priority
          )}`}
        >
          {getPriorityText(task.Priority)}
        </span>
      ) : (
        <div className="btn-add" onClick={(e) => setOpen(!open)}>
          <IoMdAdd size={15} />
        </div>
      )}

      {open && ref && ref.current && (
        <CustomPopup
          parentRef={ref}
          open={open}
          handleOutsideClick={() => setOpen(false)}
        >
          <div className="task-priority">
            <div className="task-priority__list">
              {priorities.map((priority, index) => (
                <div key={index} className="task-priority__item">
                  <div
                    className={`task-priority__box  ${getClassName(priority)}`}
                    onClick={() => handlePriorityClick(priority)}
                  >
                    {priority === task.Priority && <FaCheckCircle />}
                    <span className="task-priority__box-label">
                      {getPriorityText(priority)}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </CustomPopup>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  taskUpdateDetails,
};
export default connect(mapStateToProps, mapDispatchToProps)(PriorityCell);
