import {
  SAVE_ONBOARDING_EMPLOYEE,
  GET_ONBOARDING_EMPLOYEE_DETAIL,
  GET_ONBOARDING_EMPLOYEE_LIST,
  DELETE_ONBOARDING_EMPLOYEE,
  GET_ONBOAREDING_EMPLOYEE_EMAIL_PREVIEW,
  GET_EMPLOYEE_SALARY_TEMPLATE_DETAILS
} from "actions/types";

const initialState = {
  saveOnboardingEmployee: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_ONBOARDING_EMPLOYEE: {
      return {
        ...state,
        saveOnboardingEmployee: action.payload,
      };
    }
    case GET_ONBOARDING_EMPLOYEE_DETAIL: {
      return {
        ...state,
        onboardingEmployeeDetail: action.payload,
      };
    }
    case GET_ONBOARDING_EMPLOYEE_LIST: {
      return {
        ...state,
        onboardingEmployeeList: action.payload,
      };
    }
    case DELETE_ONBOARDING_EMPLOYEE: {
      return {
        ...state,
        deleteOnboardingEmployee: action.payload,
      };
    }
    case GET_ONBOAREDING_EMPLOYEE_EMAIL_PREVIEW: {
      return {
        ...state,
        employeePreviewEmail: action.payload,
      };
    }
    case GET_EMPLOYEE_SALARY_TEMPLATE_DETAILS: {
      return {
        ...state,
        employeeSalaryTemplateDetails: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
