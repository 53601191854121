import axios from "axios";
import {
  GET_EMPLOYEE_LIST_FOR_ADMIN,
  GET_EMPLOYEE_DASHBOARD,
  GET_EMPLOYEE_TRAVEL_DASHBOARD,
  GET_PAYROLL_SLIP,
  GET_EMPLOYEE_DASHBOARD_MESSAGES,
  GET_EMPLOYEE_DASHBOARD_NEWS,
  GET_PAYROLL_PERIOD,
  GET_EMPLOYEE_DASHBOARD_LOADING,
  GET_EMPLOYEE_DASHBOARD_JOBINFORMATION,
} from "actions/types";
import isEmpty from "isEmpty";
import {toastMessage} from "actions/validateAction";
import {loadingHeaders} from "helpers/getHeaders";

export const GetEmployeeListForAdmin = (data) => async (dispatch) => {
  const {
    pageIndex,
    rowsPerPage,
    searchValue,
    activeBranch,
    activeDepartment,
    activePosition,
    activeStatus,
  } = data;
  const branchId = !isEmpty(activeBranch) ? activeBranch.value : -1;
  const departmentId = !isEmpty(activeDepartment) ? activeDepartment.value : -1;
  const positionId = !isEmpty(activePosition) ? activePosition.value : -1;
  const statusId = !isEmpty(activeStatus) ? activeStatus.value : -1;

  await axios
    .get(
      `/v1/employee/employee-list-for-admin?searchValue=${searchValue}&branchId=${branchId}&departmentId=${departmentId}&positionId=${positionId}&statusId=${statusId}&currentPage=${pageIndex}&pageSize=${rowsPerPage}`
    )
    .then((response) => {
      if (response.data.Status) {
        dispatch({
          type: GET_EMPLOYEE_LIST_FOR_ADMIN,
          payload: response.data.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to get employee list.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const getEmployeeDashboard = () => async (dispatch) => {
  dispatch({type: GET_EMPLOYEE_DASHBOARD_LOADING});
  await axios
    .get(`/v1/core-hr/employees/information`, loadingHeaders)
    .then((response) => {
      console.log(response);
      if (response.data.Status) {
        dispatch({
          type: GET_EMPLOYEE_DASHBOARD,
          payload: response.data.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_EMPLOYEE_DASHBOARD,
        payload: [],
      });
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to get employee dashboard.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const getEmployeeTravelDashboard = () => async (dispatch) => {
  dispatch({type: GET_EMPLOYEE_DASHBOARD_LOADING});
  await axios
    .get(`v1/travel/employee-dasboard/information`, loadingHeaders)
    .then((response) => {
      console.log(response);
      if (response.data.Status) {
        let MyUpdate = {
          UnApprovedTravel: [],
          ApprovedTravel: [],
          ApprovedSettlement: [],
          UnApprovedSettlement: [],
        };
        let d = response.data.Data?.TravelRequest?.forEach((element) => {
          if (element.Status === "Approved") {
            MyUpdate.ApprovedTravel.push(element);
          } else {
            MyUpdate.UnApprovedTravel.push(element);
          }
        });
        let empData = {
          RequestCount: !isEmpty(response.data.Data?.TravelRequestCount)
            ? {
                ...response.data.Data?.TravelRequestCount[0],
              }
            : {
                RequestType: "Travel",
                Title: "Travel Requests",
                Total: 0,
              },
          // MyUpdate,
        };
        let s = response.data.Data?.SettlementRequest?.forEach((element) => {
          if (element.Status === "Approved") {
            MyUpdate.ApprovedSettlement.push(element);
          } else {
            MyUpdate.UnApprovedSettlement.push(element);
          }
        });
        let empDataS = {
          SettlementRequestCount: !isEmpty(response.data.Data?.SettlementRequestCount)
            ? {
                ...response.data.Data?.SettlementRequestCount[0],
              }
            : {
                RequestType: "Travel",
                Title: "Travel Requests",
                Total: 0,
              },
          MyUpdate,
          ...empData,
        };

        dispatch({
          type: GET_EMPLOYEE_TRAVEL_DASHBOARD,
          payload: empDataS,
        });
      } else {
        dispatch({
          type: GET_EMPLOYEE_TRAVEL_DASHBOARD,
          payload: [],
        });
        // toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_EMPLOYEE_TRAVEL_DASHBOARD,
        payload: [],
      });
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to get employee dashboard.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const getEmployeeMessages = (data) => async (dispatch) => {
  await axios
    .post(`/v1/payroll/payroll-messages`, data)
    .then((res) => {
      if (res.data.Status) {
        dispatch({
          type: GET_EMPLOYEE_DASHBOARD_MESSAGES,
          payload: res.data.Data,
        });
      } else {
        toastMessage(dispatch, res);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to get messages.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const getEmployeeNotices = () => async (dispatch) => {
  await axios
    .get(`/v1/notices`)
    .then((res) => {
      if (res.data.Status) {
        dispatch({
          type: GET_EMPLOYEE_DASHBOARD_NEWS,
          payload: res.data.Data,
        });
      } else {
        toastMessage(dispatch, res);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to get news.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const GetJobInformation = () => async (dispatch) => {
  await axios
    .get(`/v1/core-hr/employees/job-information`)
    .then((response) => {
      if (response.data.Status) {
        dispatch({
          type: GET_EMPLOYEE_DASHBOARD_JOBINFORMATION,
          payload: response.data.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to get job information.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const GetPayrollSlip = (year, month, addOnId) => async (dispatch) => {
  const base = "/v1/payroll/employees/payslip/";
  const api =
    year && month
      ? base + `?payRollYear=${year}&payRollMonth=${month}&addOnId=${addOnId}`
      : base;
  await axios
    .get(api)
    .then((response) => {
      if (response.data.Status) {
        dispatch({
          type: GET_PAYROLL_SLIP,
          payload: response.data.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to get payslip.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const GetPayrollPeriod = () => async (dispatch) => {
  await axios
    // .get(`/v1/payroll/payrollPeriod`)
    .get(`/v1/payroll/latestPayrollPeriods`)
    .then((response) => {
      dispatch({
        type: GET_PAYROLL_PERIOD,
        payload: response.data,
      });
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to get payrollperiod.",
        },
      };
      toastMessage(dispatch, response);
    });
};
