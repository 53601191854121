import FormGroup from "components/form";
import { getBaseAxios } from "helpers/constants";
import isEmpty from "isEmpty";
import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { Scrollbars } from "react-custom-scrollbars";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import { JobStages } from "scenes/recruit/enums";
import { GetCandidatesForProfileView } from "store/actions/recruitment/dashboard";

function Listing(props: any) {
  const { handleCandidateClick, currentCandidateDetail, seletedJobStage, searchByName } = props;
  const { profileViewCandidatesDetails } = props || {};
  const [rowData, setRowData] = useState([]);
  const [hasMore, setHasMore] = useState(profileViewCandidatesDetails.loadMore || true);
  const [base, setBase] = useState("");

  useEffect(() => {
    let url = (props.baseUrl && props.baseUrl.NetFrameworkAppBaseUrl) || "";
    setBase(url);
  }, [props.baseUrl]);
  const [state, setState] = useState({ items: [] });

  useEffect(() => {
    const { candidates, loadMore, pageIndex } = profileViewCandidatesDetails;
    if (candidates) {
      setState({ items: candidates });
      setHasMore(loadMore);
    }
  }, [props.profileViewCandidatesDetails]);

  useEffect(() => {
    fetchMoreData(true);
  }, [props.seletedJobStage, searchByName]);

  const [currentCandidateId, setCurrentCandidateId] = useState(null);

  useEffect(() => {
    props.selectedCandidate && setCurrentCandidateId(props.selectedCandidate.CandidateId);
  }, [props.selectedCandidate]);



  const onCandidateClick = (candidate) => {
    setCurrentCandidateId(candidate.CandidateId);
    handleCandidateClick(candidate);
  };

  const _renderList = (candidate) => {
    let dynamicClass = "information-listing-list";
    if (candidate.CandidateId == currentCandidateId) {
      dynamicClass = "information-listing-list active";
    }

    const imageUrl = base + candidate.ImageName;
    return (
      <div
        className={dynamicClass}
        onClick={() => onCandidateClick(candidate)}
        key={candidate.CamdidateId}
      >
        <div className="information-listing-avatar">
          <Avatar
            className="user-avatar"
            name={candidate.CandidateName}
            size="40"
            round={true}
            src={imageUrl}
          />
        </div>
        <div className="information-listing-desc">
          <span>{candidate.CandidateName}</span>
          <span className="fonts-light_small">
            {isEmpty(candidate.HighestDegree) ? "" : candidate.HighestDegree}
          </span>
          <span className="fonts-light_small">
            {isEmpty(candidate.Position && candidate.OrganisationName)
              ? ""
              : candidate.Position + " at " + candidate.OrganisationName}
          </span>
        </div>
      </div>
    );
  };

  const fetchMoreData = async (refresh = false) => {
    const { rowsPerPage } = props;
    const { Jobid } = currentCandidateDetail;

    if (!Jobid) return;

    const { profileViewCandidatesDetails } = props || {};
    const { candidates, pageIndex, stage, hasMore } = profileViewCandidatesDetails;

    const data = {
      jobId: Jobid,
      pageIndex: refresh ? 0 : pageIndex + 1,
      pageSize: rowsPerPage || 20,
      stageId: seletedJobStage || JobStages.Applied,
      candidateName: searchByName
    };
    let res = await props.GetCandidatesForProfileView(data, refresh);
    setHasMore(hasMore);
  };

  return (
    <div className="information-listing">

      {/* {`${hasMore}`} */}
      <InfiniteScroll
        dataLength={state.items.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={""}
      >
        {state.items.map((candidate, index) => (
          <div key={index}>{_renderList(candidate)}</div>
        ))}

        {/* {state.items.map((candidate, index) => _renderList(candidate))} */}
      </InfiniteScroll>

      {/* <Scrollbars
        onScrollStop={(e) => {
          console.log(e);
        }}
        className="information-listing-scroll"
        style={{ width: "100%" }}
      >
        {rowData &&
          rowData
            .filter((item) =>
              item.CandidateName.toLowerCase().includes(
                searchText.toLowerCase()
              )
            )
            .map((candidate) => {
              return _renderList(candidate);
            })}
      </Scrollbars>
      <hr /> */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  profileViewCandidatesDetails:
    state.recruitment_dashboardReducer.profileViewCandidatesDetails,
  baseUrl: state.auth.baseUrl,
});
const mapDispatchToProps = {
  GetCandidatesForProfileView,
};
export default connect(mapStateToProps, mapDispatchToProps)(Listing);
