import RouteEnum from "constants/routeEnum";

export const TRAVEL_PATHS = [
  {
    PathName: RouteEnum.TeamTravelRequestPath,
    Title: "Travel Requests",
  },
  {
    PathName: RouteEnum.MyTravelRequestPath,
    Title: "My Travel Requests",
  },
  {
    PathName: RouteEnum.CreateTravelRequestPath,
    Title: "Create Travel Requests",
  },
  {
    PathName: RouteEnum.UpdateTravelRequestPath,
    Title: "Update Travel Requests",
  },
  {
    PathName: RouteEnum.SettlementRequestPath,
    Title: "Settlement Requests",
  },
  {
    PathName: RouteEnum.PendingSettlementPath,
    Title: "Pending Settlements",
  },
  {
    PathName: RouteEnum.TravelHistoryPath,
    Title: "Travel History",
  },
];

export const LEAVE_PATHS = [
  {
    PathName: RouteEnum.EmployeeRequestReviewPath,
    Title: "Requests for Review",
  },
  {
    PathName: RouteEnum.EmployeeRequestHistoryPath,
    Title: "History",
  },
];
