import FormGroup from "components/form";
import { InputValidator } from "helpers/inputValidator";
import React from "react";
import { connect } from "react-redux";
import "./allowanceRequest.scss";
import isEmpty from "helpers/isEmpty";
import classNames from "classnames";
import {
  GetAllowanceRequestType,
  GetAllowanceApproverReviewerList,
  saveEmployeeAllowanceRequest,
  updateEmployeeAllowanceRequest,
} from "../action";
import {
  DisplayClientSideMessage,
  GetCompanyInformation,
} from "actions/commonAction";
import moment from "moment";
import { geDateDifference } from "helpers/dateFormat";
import { EmployeeID } from "constants/userDetails";
import { Date_Format_Reverse } from "constants/dateFormat";
import { BS2AD, AD2BS } from "helpers/dateConvert";
import { MdInfo } from "react-icons/md";
import { EMPLOYEE_ALLOWANCE_REQUEST_MODULE_VALUE } from "constants/constants";
import { GetAuditLogOfRequests } from "scenes/employee/requests/actions";
import SelectEmployee from "scenes/employee/selectEmployee";
interface IAppProps {
  match?: any;
  GetAllowanceRequestType: Function;
  GetAllowanceApproverReviewerList?: Function;
  DisplayClientSideMessage: Function;
  closeForm: Function;
  allowanceRequestId?: any;
  allowanceTypes: any[];
  approveReviewList?: any;
  saveEmployeeAllowanceRequest?: Function;
  updateEmployeeAllowanceRequest?: Function;
  isEmployeeAllowanceSaved?: any;
  editMode?: boolean;
  refreshRequestData?: any;
  isAdmin?: boolean;
  isApprover?: any;
  user: any;
  companyInformation?: any;
  GetCompanyInformation: Function;
  GetAuditLogOfRequests: Function;
  isAssign?: Boolean;
}
interface State {
  errors: any;
  allowanceType: any;
  startDate: any;
  endDate: any;
  reason: string;
  checkedApprover: any;
  checkedReviewer: any;
  // approverList: any[];
  units: number;
  approveReviewList?: any;
  editMode: boolean;
  employeeToAssign: any;
}
class App extends React.Component<IAppProps, State> {
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      units: null,
      allowanceType: {},
      errors: {},
      startDate: "",
      endDate: "",
      reason: "",
      editMode: false,
      checkedApprover: null,
      checkedReviewer: null,
      approveReviewList: {},
      employeeToAssign: null,
    };
  }

  async componentDidMount() {
    const {
      GetAllowanceRequestType,
      GetCompanyInformation,
      user: { EmployeeId },
    } = this.props;
    // await GetCompanyInformation();
    await GetAllowanceRequestType();

    const {
      editMode,
      allowanceRequestId,
      allowanceTypes,
      isApprover,
      companyInformation,
    } = this.props;

    if (editMode) {
      await this.props.GetAllowanceApproverReviewerList(EmployeeId);
      const { approveReviewList } = this.props;
      let getSelectedAllowanceType =
        allowanceTypes &&
        allowanceTypes.filter(
          (item) => item.label == allowanceRequestId.AllowanceType
        );
      let filteredApproveReviewList = {
        approverList: [
          {
            Id: allowanceRequestId.ApprovalId,
            FullName: allowanceRequestId.Approval,
            Imagepath: allowanceRequestId.ApprovalImage,
            Designation: "",
          },
        ],
        reviewerList: [
          {
            Id: allowanceRequestId.RecommenderId,
            FullName: allowanceRequestId.Reccommender,
            Imagepath: allowanceRequestId.RecommendedImage,
            Designation: "",
          },
        ],
      };
      if (isApprover) filteredApproveReviewList = filteredApproveReviewList;
      else filteredApproveReviewList = approveReviewList;

      let checkedApprover = allowanceRequestId.ApprovalId;
      let checkedReviewer = allowanceRequestId.RecommenderId;
      let nepaliStartDate = moment(allowanceRequestId.FromDateEng)
        .format("YYYY/MM/DD")
        .toString();
      let nepaliEndDate = moment(allowanceRequestId.ToDateEng)
        .format("YYYY/MM/DD")
        .toString();

      if (!companyInformation.IsEnglishDate) {
        let conversionDate = AD2BS(nepaliStartDate).en;
        nepaliStartDate = `${conversionDate.year}/${conversionDate.month}/${conversionDate.day}`;
        conversionDate = AD2BS(nepaliEndDate).en;
        nepaliEndDate = `${conversionDate.year}/${conversionDate.month}/${conversionDate.day}`;
      }

      let data = {
        startDate: nepaliStartDate,
        endDate: nepaliEndDate,
        checkedReviewer,
        checkedApprover,
        allowanceType: getSelectedAllowanceType[0],
        reason: allowanceRequestId.Reason,
        units: allowanceRequestId.Units,
        approveReviewList: filteredApproveReviewList,
        editMode: true,
      };
      this.setState(data);
    } else {
      if (!this.props.isAssign) {
        this.manipulateApproverReviewer(EmployeeId);
      }
    }
  }

  manipulateApproverReviewer = async (employeeId = null) => {
    await this.props.GetAllowanceApproverReviewerList(employeeId);
    const { approveReviewList } = this.props;
    let checkedReviewer = null;
    let checkedApprover = null;
    if (approveReviewList?.reviewerList?.length == 1)
      checkedReviewer = approveReviewList.reviewerList[0].Id;
    if (approveReviewList?.approverList?.length == 1)
      checkedApprover = approveReviewList.approverList[0].Id;
    this.setState({ approveReviewList, checkedReviewer, checkedApprover });
  };

  handleFieldChange = (name: string, value: any) => {
    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: null,
      },
    } as Pick<State, keyof State>);

    if (name == "employeeToAssign") {
      this.manipulateApproverReviewer(value.value);
    }
  };
  handleDateChange = (range) => {
    const { startDate, endDate } = range;
    this.setState({
      startDate,
      endDate,
      errors: {
        ...this.state.errors,
        startDate: null,
      },
    });
  };

  handleCheckBoxChange = (name, value) => {
    this.setState({ [name]: value } as Pick<State, keyof State>);
  };

  handleSaveAllowanceRequest = async () => {
    let {
      allowanceType,
      startDate,
      endDate,
      reason,
      checkedApprover,
      checkedReviewer,
      units,
      editMode,
      employeeToAssign,
    } = this.state;
    let fieldsToValidate = {
      employeeToAssign,
      allowanceType,
      startDate,
      endDate,
      reason,
      units,
    };
    let errors = await InputValidator(document, fieldsToValidate);
    if (!isEmpty(startDate) && isEmpty(endDate))
      errors.startDate = "EndDate is required";
    if (isEmpty(errors)) {
      const {
        updateEmployeeAllowanceRequest,
        saveEmployeeAllowanceRequest,
        closeForm,
        refreshRequestData,
        allowanceRequestId,
        companyInformation,
        DisplayClientSideMessage,
        isAdmin
      } = this.props;
      if (checkedReviewer == null && !isAdmin) {
        this.props.DisplayClientSideMessage("At least one reviewer is requied");
        return;
      }
      if (checkedApprover == null && !isAdmin) {
        this.props.DisplayClientSideMessage("At least one approver is requied");
        return;
      }
      if (!companyInformation.IsEnglishDate) {
        startDate = startDate;
        endDate = endDate;
      } else {
        startDate = moment(startDate).format("YYYY/MM/DD").toString();
        endDate = moment(endDate).format("YYYY/MM/DD").toString();
      }

      let data = {
        IsAssigned: this.props.isAssign || false,
        CounterRequestID: 0,
        EveningCounterTypeId: allowanceType.value,
        StartDate: startDate,
        EndDate: endDate,
        Reason: reason,
        Days: units,
        ApprovalID: checkedApprover,
        RecommendedBy: checkedReviewer,
        // ApprovalID: 10001,
        // RecommendedBy: 10005,
        EmployeeID: this.props.isAssign
          ? employeeToAssign.value
          : editMode
            ? allowanceRequestId.EmployeeId
            : this.props.user.EmployeeId,
      };
      if (!companyInformation.IsEnglishDate) {
        data.StartDate = `${BS2AD(data.StartDate).year}/${BS2AD(data.StartDate).month
          }/${BS2AD(data.StartDate).day}`;
        data.EndDate = `${BS2AD(data.EndDate).year}/${BS2AD(data.EndDate).month
          }/${BS2AD(data.EndDate).day}`;
      }
      if (allowanceType.warn) {
        if (data.Days > 9999) {
          this.props.DisplayClientSideMessage("Units must be less than 9999");
          return;
        }
      } else {
        if (geDateDifference(data.StartDate, data.EndDate) + 1 < data.Days) {
          this.props.DisplayClientSideMessage(
            `Units can be upto ${geDateDifference(data.StartDate, data.EndDate) + 1
            }`
          );
          return;
        }
      }

      if (editMode) {
        data.CounterRequestID = allowanceRequestId.RequestId;
        await updateEmployeeAllowanceRequest(data);
      } else await saveEmployeeAllowanceRequest(data);
      if (this.props.isEmployeeAllowanceSaved) {
        if (editMode) {
          await refreshRequestData();
          await this.props.GetAuditLogOfRequests({
            requestId: data.CounterRequestID,
            moduleId: EMPLOYEE_ALLOWANCE_REQUEST_MODULE_VALUE,
          });
        }
        closeForm();
      }
    } else {
      this.setState({ errors });
    }
  };
  public render() {
    const {
      allowanceType,
      errors,
      startDate,
      endDate,
      reason,
      checkedApprover,
      checkedReviewer,
      units,
      approveReviewList,
      employeeToAssign,
    } = this.state;
    const {
      allowanceTypes,
      isApprover,
      editMode,
      companyInformation,
    } = this.props;
    const approverReviewerFlag = approveReviewList.approverList?.length == 0;

    return (
      <>
        <div className="allowance-request-form__body">
          {this.props.isAssign && (
            <div className="form-row ml-sm mr-sm">
              <SelectEmployee
                employeeToAssign={employeeToAssign}
                error={errors && errors.employeeToAssign}
                onChange={this.handleFieldChange}
              />
            </div>
          )}
          <div className="form-row ml-sm mr-sm">
            <FormGroup
              formName="reactselect"
              onChange={this.handleFieldChange}
              name="allowanceType"
              value={allowanceType}
              options={allowanceTypes}
              placeholder="Select"
              validators={["required"]}
              error={errors && errors.allowanceType}
              width="250px"
              label="Select Allowance type"
            />
          </div>
          <div className="form-row ml-sm mr-sm">
            <FormGroup
              formName="customdateinput"
              value={
                /* isHalfDaTypeSelected
                    ? startDate
                    : */ {
                  startDate,
                  endDate,
                }
              }
              // startDate={startDate}
              // endDate={endDate}
              onChange={(name, range) => this.handleDateChange(range)}
              placeholder="Select date"
              label="Dates"
              width="250px"
              validators={["required"]}
              error={errors && (errors.startDate || errors.endDate)}
              name="startDate"
              isDateRange={true}
              isNepali={!companyInformation?.IsEnglishDate}
              isTextInNepali={!companyInformation.IsEnglishDate}
            />
          </div>
          <div className="form-row ml-sm mr-sm wd-20">
            <FormGroup
              type="number"
              name="units"
              value={units}
              onChange={this.handleFieldChange}
              label="Units"
              error={errors && errors.units}
              width="100%"
              validators={["required", "posNumbers"]}
            />
          </div>
          <div className="form-row ml-sm mr-sm">
            <FormGroup
              name="reason"
              value={reason}
              onChange={this.handleFieldChange}
              label="Reason"
              error={errors && errors.reason}
              width="100%"
              validators={["required"]}
            />
          </div>
          {
            this.props.isAdmin ? null :
              <div
                className={classNames({
                  "form-row ml-sm mr-sm items-start": true,
                })}
              >
                <FormGroup
                  formName="checkBoxGroup"
                  name="checkedReviewer"
                  // validators={['required']}
                  label="Reviewer"
                  checkGroupArray={approveReviewList.reviewerList}
                  checkedGroupValue={checkedReviewer}
                  onChange={this.handleFieldChange}
                  multiple={false}
                  disabled={isApprover}
                  // loadingType="skeleton"
                  width="50%"
                />
                <FormGroup
                  formName="checkBoxGroup"
                  name="checkedApprover"
                  label="Approver"
                  // validators={['required']}
                  checkGroupArray={approveReviewList.approverList}
                  checkedGroupValue={checkedApprover}
                  onChange={this.handleFieldChange}
                  multiple={false}
                  disabled={isApprover}
                  width="50%"
                />
              </div>
          }

          {approverReviewerFlag && (
            <div
              className={classNames({
                "form-row ml-sm mr-sm items-start": true,
              })}
            >
              <div className="genericForm-group__label allowance-noapprover">
                <label className="genericForm-group__label allowance-noapprover">
                  {"Approver *"}
                </label>
                <div className="flex items-start mt-xsm">
                  <MdInfo className="info-icon" />
                  <span className="ml-xsm text-md">
                    There is no approver set for this request type. Please
                    contact your HR Administrator. At least one approver is
                    required to initiate the request
                  </span>
                </div>
              </div>
              {/* <FormGroup
                formName="checkBoxGroup"
                name="checkedReviewer"
                // validators={['required']}
                label="Reviewer"
                checkGroupArray={approveReviewList.reviewerList}
                checkedGroupValue={checkedReviewer}
                onChange={this.handleFieldChange}
                multiple={false}
                disabled={isApprover}
              /> */}
            </div>
          )}
          {/* <div
              className={classNames({
                "form-row ml-sm mr-sm items-start": true,
              })}
            >
              
            </div> */}
        </div>
        <div className="allowance-request-form__footer flex justify-center">
          <button
            className="button  button-white"
            onClick={() => this.props.closeForm(false)}
          >
            <span> Cancel</span>
          </button>

          <button
            className="button  button-primary"
            // disabled={approverReviewerFlag}
            onClick={() => this.handleSaveAllowanceRequest()}
          >
            <span>
              {isApprover || editMode ? "Update Request" : "Send Request"}
            </span>
          </button>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  allowanceTypes: state.employeeAllowanceReducer.employeeAllowanceTypeList,
  approveReviewList:
    state.employeeAllowanceReducer.employeeAllowanceApproverReviewList || [],
  isEmployeeAllowanceSaved:
    state.employeeAllowanceReducer.isEmployeeAllowanceSaved,
  companyInformation: state.commonReducer.companyInformation,
  user: state.auth.user,
});

const mapDispatchToProps = {
  GetAllowanceRequestType,
  GetAllowanceApproverReviewerList,
  DisplayClientSideMessage,
  saveEmployeeAllowanceRequest,
  updateEmployeeAllowanceRequest,
  GetCompanyInformation,
  GetAuditLogOfRequests,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
