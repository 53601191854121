import FormGroup from "components/form";
import {
  SCOPE_IMPACT_TYPE,
  JOB_PROFILE_PARAGRAPH_TYPE,
} from "constants/constants";
import isEmpty from "isEmpty";
import React, { Component } from "react";
import { GrCheckmark, GrClose } from "react-icons/gr";
import {
  MdArrowDownward,
  MdArrowUpward,
  MdDelete,
  MdEdit,
} from "react-icons/md";

const profileBlockType = SCOPE_IMPACT_TYPE;
class ScopeImpact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNameEdit: false,
      subtitleToEdits: [],
      intialBlockData: {},
      errors:{},
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount() {
    var stateCopy = this.props.state.profileBlocks.map((x) => {
      return { ...x };
    });
    const currentSection = stateCopy.find((x) => x.type === profileBlockType);
    this.setState({ intialBlockData: currentSection });
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  handleSectionNameEdit = (edit) => {
    this.setState({ isNameEdit: edit });
  };
  handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      this.handleSectionNameEdit(false);
      this.setState({ subtitleToEdits: [] });
    }
  };
  handleSubtitleEditClick = (id) => {
    let { subtitleToEdits } = this.state;
    subtitleToEdits.push(id);
    this.setState({ subtitleToEdits });
  };
  handleInputChange = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value });
  };
  handleSaveTitle = (value) => {
    if (isEmpty(value)) this.setState({ errors: { title: "Required" } });
    else {
      this.setState((prevState) => ({
        ...prevState,
        intialBlockData: {
          ...prevState.intialBlockData,
          title: value,
        },
        errors: { ...prevState.errros, title: "" },
      }));

      this.handleSectionNameEdit(false);
    }
  };
  handleSaveSubtitle = (value, id) => {
    let newIntialBlockData = this.state.intialBlockData.components.map((x) => {
      let o = { ...x };
      if (x.id === id) {
        o.subtitle = value;
      }
      return o;
    });
    this.setState((prevState) => ({
      ...prevState,
      intialBlockData: {
        ...prevState.intialBlockData,
        components: newIntialBlockData,
      },
      subtitleToEdits: [],
    }));
  };
  handleRemoveChangedTitle = () => {
    const currentSection = this.state.intialBlockData;
    this.props.handleProfileBlockTitle(profileBlockType, currentSection.title);
    this.handleSectionNameEdit(false);
  };

  handleClickOutside(event) {
    let containsTarget = false;
    let targetTitleEl = document.getElementsByClassName("title-action");
    for (let el of targetTitleEl) {
      if (el.contains(event.target)) {
        containsTarget = true;
        break;
      }
    }

    if (!isEmpty(targetTitleEl) && !containsTarget) {
      this.handleRemoveChangedTitle();
    }
  }

  render() {
    const { isNameEdit, subtitleToEdits, errors } = this.state;
    const {
      handleInput,
      handleBlockMove,
      index,
      subComponent,
      id,
      isViewMode,
      handleRemoveSection,
    } = this.props;

    const { profileBlocks, initialBlocks } = this.props.state;
    const currentSection = this.props.state.profileBlocks.find(
      (x) => x.type === profileBlockType
    );

    return (
      <div className="duties-resp profile-block">
        <div className="profile-block__header">
          <div>
            {!isNameEdit ? (
              <span className="profile-text">{currentSection.title}</span>
            ) : (
              <div className="flex">
                <FormGroup
                  name="title"
                  value={currentSection.title}
                  onChange={(name, value) =>
                    this.props.handleProfileBlockTitle(profileBlockType, value)
                  }
                  label=""
                  error={errors?.title}
                  onKeyUp={this.handleKeyUp}
                  validators={["required"]}
                  width="250px"
                  autoFocus
                  className="title-action"
                />
                <div
                  className="edit-box-title title-action"
                  onClick={() => this.handleSaveTitle(currentSection.title)}
                >
                  <GrCheckmark />
                </div>
                <div
                  className="edit-box-title ml-xsm title-action"
                  onClick={() => this.handleRemoveChangedTitle()}
                >
                  <GrClose />
                </div>
              </div>
            )}
            <span className="profile-type">{currentSection.type}</span>
          </div>
          <div className="flex justify-end action-bar">
            {!isNameEdit && (
              <button
                className="button with-icon"
                onClick={() => this.handleSectionNameEdit(true)}
              >
                <MdEdit />
                <span> Edit Section Name</span>
              </button>
            )}
            <button
              className="button with-icon"
              onClick={() => handleRemoveSection(currentSection.id)}
            >
              <MdDelete />
              <span> Remove Section</span>
            </button>
          </div>
          <div className="arrow-grp">
            {index < initialBlocks.length - 1 ? (
              <div
                className="arrow-grp__down"
                onClick={() => handleBlockMove(index, index + 1)}
              >
                <MdArrowDownward />
                <span className="icon-line"></span>
              </div>
            ) : null}
            {index > 0 ? (
              <div
                className="arrow-grp__up"
                onClick={() => handleBlockMove(index, index - 1)}
              >
                <span className="icon-line"></span>
                <MdArrowUpward />
              </div>
            ) : null}
          </div>
        </div>
        <div className="profile-block__body">
          {currentSection.components.map((x, i) => {
            return (
              <>
                <div className="form-row">
                  <FormGroup
                    label={currentSection.type}
                    formName="texteditor"
                    value={x.paragraph}
                    toolbarId={"scopeImpact" + i + 1}
                    placeholder={"Write something"}
                    name="paragraph"
                    onChange={(name, value) => {
                      this.props.handleComponentFieldChange(
                        profileBlockType,
                        x.id,
                        name,
                        value
                      );
                    }}
                    width="100%"
                  />
                </div>
              </>
            );
          })}
        </div>
      </div>
    );
  }
}

export default ScopeImpact;
