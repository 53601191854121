import React from 'react';
import {Tabs,Tab} from 'components/tabs/index.d'
import ActiveTable from './activeTable'

function Table() {
    return (
        <div className="roles-table">
            <Tabs>
                <Tab label="Active Roles" name="Active Roles" >
                    <ActiveTable />
                </Tab>
                <Tab label="Disabled Roles" name="Disabled Roles" >
                    <span>Disabled</span>
                </Tab>
            </Tabs>
        </div>
    )
}

export default Table
