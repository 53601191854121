import React from "react";
import { useSelector } from "react-redux";
import PieChart from "components/charts/pieChart";
import { pieChartValue } from "helpers/getTextLabel";

// const items = [
//   { Title: "A", Total: 111, ValuePercentage: 14 },
//   { Title: "B", Total: 167, ValuePercentage: 16 },
//   { Title: "C", Total: 250, ValuePercentage: 35 },
//   { Title: "D", Total: 250, ValuePercentage: 35 },
// ];

export function ProjectPieChart() {
  const counterReport = useSelector(
    (state) => state.taskTrackerReducer.counterReport
  );
  const { Projects } = counterReport;

  let grandTotal = 0;
  Projects &&
    Projects.map((p) => {
      grandTotal += p.Key ? parseInt(p.Key) : 0;
    });

  let itemsWithPercentage = [];
  Projects &&
    Projects.map((p) => {
      const total = p.Key ? parseInt(p.Key) : 0;
      const percentage = (total / grandTotal) * 100;

      itemsWithPercentage.push({
        Title: p.Value,
        Total: total,
        ValuePercentage: percentage,
      });
    });

  return (
    <PieChart showValueOnly={true} data={pieChartValue(itemsWithPercentage)} />
  );
}

export function ClientTypePieChart() {
  const counterReport = useSelector(
    (state) => state.taskTrackerReducer.counterReport
  );
  const { ClientTypes } = counterReport;

  let grandTotal = 0;
  ClientTypes &&
    ClientTypes.map((p) => {
      grandTotal += p.Key ? parseInt(p.Key) : 0;
    });

  let itemsWithPercentage = [];
  ClientTypes &&
    ClientTypes.map((p) => {
      const total = p.Key ? parseInt(p.Key) : 0;
      const percentage = (total / grandTotal) * 100;

      itemsWithPercentage.push({
        Title: p.Value,
        Total: total,
        ValuePercentage: percentage,
      });
    });

  return (
    <PieChart showValueOnly={true} data={pieChartValue(itemsWithPercentage)} />
  );
}

export function ActivitiesPieChart() {
  const counterReport = useSelector(
    (state) => state.taskTrackerReducer.counterReport
  );
  const { Activities } = counterReport;

  let grandTotal = 0;
  Activities &&
    Activities.map((p) => {
      grandTotal += p.Key ? parseInt(p.Key) : 0;
    });

  let itemsWithPercentage = [];
  Activities &&
    Activities.map((p) => {
      const total = p.Key ? parseInt(p.Key) : 0;
      const percentage = (total / grandTotal) * 100;

      itemsWithPercentage.push({
        Title: p.Value,
        Total: total,
        ValuePercentage: percentage,
      });
    });

  return (
    <PieChart showValueOnly={true} data={pieChartValue(itemsWithPercentage)} />
  );
}
