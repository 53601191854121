import { SAVE_JOB_APPLY, GET_JOB_DETAIL_BY_ID } from "actions/types";
import axios from "axios";
import { toastMessage } from "actions/validateAction";
import { JobListingPath } from "routes/commonLinks";
const requirementBaseApi = "/v1/recruitment/";

export const SaveJobApply = (data, props) => async (dispatch) => {
  
  await axios
    .post(`${requirementBaseApi}applyJob`, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((resp) => {
      
      const response = resp.data;
      if (response.Status) {
        props &&
          props.history.push({
            pathname: JobListingPath,
          });
        toastMessage(dispatch, response);
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to apply job.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const GetJobDetailById = (jobId) => async (dispatch) => {
  await axios
    .get(`${requirementBaseApi}jobs/${jobId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: GET_JOB_DETAIL_BY_ID,
          payload: response.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to get job detail.",
        },
      };
      toastMessage(dispatch, response);
    });
};
