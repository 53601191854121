import { DisplayClientSideMessage } from "actions/commonAction";
import classNames from "classnames";
import EmployeeSearch from "components/common/employeeSearch";
import FormGroup from "components/form";
import Modal from "components/modal";
import SelectEmployee from "scenes/employee/selectEmployee";
import {
  EMPLOYEE_LEAVE_REQUEST_MODULE_VALUE,
  FIRST_HALF_LEAVE_PERIOD_VALUE,
  LEAVE_PERIOD_LIST,
  SECOND_HALF_LEAVE_PERIOD_VALUE,
} from "constants/constants";
import { BS2AD } from "helpers/dateConvert";
import { InputValidator } from "helpers/inputValidator";
import isEmpty from "helpers/isEmpty";
import moment from "moment";
import { objectToFormData } from "object-to-formdata";
import React from "react";
import { MdInfo } from "react-icons/md";
import { connect } from "react-redux";
import { getEmployeeDashboard } from "scenes/employee/action";
import {
  GetAuditLogOfRequests,
  GetMyRequests,
} from "scenes/employee/requests/actions";
import * as leaveRequestService from "services/leaveRequest";
import {
  GetLeaveRequestApprover,
  GetLeaveRequestById,
  GetLeaveRequestType,
  GetLeaveRequestDays,
} from "../actions";
import "../leaveRequest.scss";
import { getSettingWiseDate } from "helpers/getSettingWiseDate";
import DatepickerAsPerSetting from "components/customdaterange/datepickerAsPerSetting";
interface IAppProps {
  match?: any;
  GetMyRequests: Function;
  GetLeaveRequestType: Function;
  GetLeaveRequestApprover: Function;
  GetLeaveRequestById: Function;
  DisplayClientSideMessage: Function;
  leaveRequestTypes: any;
  employeeId?:any,
  leaveRequestApprover: any;

  closeForm: Function;
  leaveRequestId?: number;
  leaveRequestDetails: {
    loading: false;
    value: {
      LeaveRequestDto: {};
      Attachment: [];
    };
  };
  user: any;
  refreshRequestData?: Function;
  isApprover?: boolean;
  getEmployeeDashboard: Function;
  companyInformation: any;
  GetAuditLogOfRequests: Function;
  GetLeaveRequestDays: Function;
  leaveRequestDays: any;
  isAssign?: Boolean;
}
interface State {
  errors: any;
  leaveRequestId: number;
  leaveType: any;
  startDate: string;
  endDate: string;
  reason: string;
  substitute: any;
  leavePeriod: any;
  attachment: any;
  checkedApprover: any;
  checkedReviewer: any;
  leaveActionType: number;
  leaveRequestTypeList: any[];
  approverList: any[];
  reviewerList: any[];
  previousAttachment: any[];
  leaveRemaining: number;
  isApprover: boolean;
  noApprover: boolean;
  editDataFetched: boolean;
  isApproverThere: boolean;
  isReviewThere: boolean;
  employeeToAssign: any;
}
const getLeaveRequestDaysLabel = (leaveRequestDays) => {
  if (leaveRequestDays.loading) return "(...)";
  if (leaveRequestDays.value > 0) {
    return (
      `(${leaveRequestDays.value}` +
      (leaveRequestDays.value === 1 ? " day)" : " days)")
    );
  } else return "";
};

class App extends React.Component<IAppProps, State> {
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      leaveRequestId: 0,
      leaveType: {},
      errors: {},
      startDate: "",
      endDate: "",
      reason: "",
      substitute: {},
      leavePeriod: LEAVE_PERIOD_LIST[0],
      attachment: [],
      checkedApprover: 0,
      checkedReviewer: 0,
      leaveActionType: 1,
      leaveRequestTypeList: [],
      approverList: [],
      reviewerList: [],
      previousAttachment: [],
      leaveRemaining: 0,
      isApprover: false,
      isApproverThere:false,
      isReviewThere:false,
      noApprover: false,
      editDataFetched: false,
      employeeToAssign: null,
    };
  }

  async componentDidMount() {
    const { leaveRequestId,employeeId } = this.props;
    if (!isEmpty(leaveRequestId)) {
      this.setState({ leaveRequestId });
    }
    await this.props.GetLeaveRequestById(leaveRequestId);

    if (!this.props.isAssign) {
      if(employeeId){
        await this.initialize(employeeId);
      }else{
        await this.initialize();
      }
      
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const {
      leaveRequestTypes,
      leaveRequestApprover,
      leaveRequestDetails,
    } = this.props;

    if (
      prevProps.leaveRequestTypes.value !== leaveRequestTypes.value ||
      prevProps.leaveRequestApprover.value !== leaveRequestApprover.value ||
      prevProps.leaveRequestDetails.value !== leaveRequestDetails.value
    ) {
      let {
        leaveRequestTypeList,
        approverList,
        reviewerList,
        leaveType,
        startDate,
        endDate,
        reason,
        substitute,
        checkedApprover,
        checkedReviewer,
        leavePeriod,
        leaveActionType,
        attachment,
        leaveRemaining,
        isApprover,
        editDataFetched,
      } = this.state;

      if (prevProps.leaveRequestTypes.value !== leaveRequestTypes.value)
        leaveRequestTypeList = leaveRequestTypes?.value?.map((x) => {
          return {
            label: x.Title,
            value: x.LeaveTypeId,
            isHalfDayAllowed: x.IsHalfDayAllowed,
            freqOfAccrual: x.FreqOfAccrual,
          };
        });

      if (
        // this.props.leaveRequestId !== 0 &&
        // prevProps.leaveRequestDetails.value !== leaveRequestDetails.value
        !isEmpty(leaveRequestDetails.value) &&
        !isEmpty(leaveRequestTypeList) &&
        !editDataFetched
      ) {
        let leaveRequest = leaveRequestDetails?.value?.LeaveRequestDto;

        if (!isEmpty(leaveRequest)) {
          await this.props.GetLeaveRequestApprover(leaveRequest["EmployeeId"]);
          leaveType =
            leaveRequest &&
            leaveRequestTypeList?.find(
              (x) =>
                x.value ===
                (leaveRequest["LeaveTypeId"] === 0
                  ? -1
                  : leaveRequest["LeaveTypeId"])
            );
          const { companyInformation } = this.props;
          let isEnglishDate = companyInformation.IsEnglishDate;

          startDate = moment(
            isEnglishDate
              ? leaveRequest["FromDateEng"]
              : leaveRequest["FromDate"]
          )
            .format("YYYY/MM/DD")
            .toString();
          endDate = moment(
            isEnglishDate ? leaveRequest["ToDateEng"] : leaveRequest["ToDate"]
          )
            .format("YYYY/MM/DD")
            .toString();
          reason = leaveRequest["Reason"];
          substitute = {
            label: leaveRequest["SubstituteEmployee"],
            value: leaveRequest["SubstituteEmployeeId"],
            img: "",
          };
          checkedApprover =
            leaveRequest["SupervisorEmployeeIdInCaseOfSelection"];
          checkedReviewer = leaveRequest["RecommendEmployeeId"];
          leavePeriod = LEAVE_PERIOD_LIST.find(
            (x) => x.value === leaveRequest["HalfDayType"]
          );
          leaveActionType = leaveRequest["CompensatoryIsAdd"]
            ? 1
            : leaveRequest["CompensatoryIsDeduct"]
            ? 2
            : 1;
          leaveRemaining = leaveRequestTypes?.value?.find(
            (x) => x.LeaveTypeId === leaveType?.value
          )?.NewBalance;
          // isApprover =
          //   checkedApprover === this.props.user.EmployeeId ||
          //   checkedReviewer === this.props.user.EmployeeId;
          isApprover = this.props.isApprover;
          attachment = leaveRequestDetails?.value.Attachment?.map((x) => {
            return { ...(x as object), name: x["UserFileName"] };
          });
          this.setState({ editDataFetched: true });
        }
      }

      this.setState({
        leaveRequestTypeList,
        leaveType,
        startDate,
        endDate,
        reason,
        substitute,
        checkedApprover,
        checkedReviewer,
        attachment,
        leavePeriod,
        leaveActionType,
        leaveRemaining,
        isApprover,
      });
    }
  }

  initialize = async (employeeId: any = null) => {
    const { GetLeaveRequestApprover, leaveRequestId, user } = this.props;
    if (!employeeId) {
      employeeId = user.EmployeeId;
    }

    this.manipulateApproverReviewer(employeeId);
    await this.props.GetLeaveRequestType({
      employeeId: employeeId,
      enabledOnly: true,
      isLeaveRequest: true,
    });
  };

  checkIfRequestor = (list,val) =>{
    const {EmployeeId} = this.props.user;
    let employeed = list.find(item=>item.Id===EmployeeId);
    return {isThere:!isEmpty(employeed),requestor:employeed}
  }

  manipulateApproverReviewer = async (employeeId) => {
    await this.props.GetLeaveRequestApprover(employeeId);
    let { leaveRequestApprover, leaveRequestDetails } = this.props;
    let {
      approverList,
      reviewerList,
      checkedApprover,
      checkedReviewer,
      isApprover,
    } = this.state;

    let approvers = leaveRequestApprover?.value?.Approvals;
    let leaveRequest = leaveRequestDetails?.value?.LeaveRequestDto;
    if (isApprover && !isEmpty(leaveRequest))
      approvers = approvers?.filter(
        (x) =>
          x.EmployeeId === leaveRequest["SupervisorEmployeeIdInCaseOfSelection"]
      );

    approverList = approvers?.map((x) => {
      return {
        Id: x?.EmployeeId,
        FullName: x.EmployeeName,
        Imagepath: x.Image,
        Designation: x.Position,
      };
    });
    if (isEmpty(this.props.leaveRequestId) || this.props.leaveRequestId === 0)
      checkedApprover = approverList?.length == 1 ? approverList[0].Id : 0;

    const {isThere:isApproverThere, requestor:approveRequestor} = this.checkIfRequestor(approverList,'Approve');
    if(isApproverThere){
      checkedApprover = approveRequestor.Id
    }

    let recommenders = leaveRequestApprover?.value?.Recommended;
    if (isApprover && !isEmpty(leaveRequest))
      recommenders = recommenders?.filter(
        (x) => x.EmployeeId === leaveRequest["RecommendEmployeeId"]
      );
    reviewerList = recommenders?.map((x) => {
      return {
        Id: x?.EmployeeId,
        FullName: x.EmployeeName,
        Imagepath: x.Image,
        Designation: x.Position,
      };
    });
    const {isThere:isReviewThere, requestor:reviewRequestor} = this.checkIfRequestor(reviewerList,'Review');
    console.log({isReviewThere,reviewRequestor})
    if (isEmpty(this.props.leaveRequestId) || this.props.leaveRequestId === 0)
      checkedReviewer = reviewerList?.length == 1 ? reviewerList[0].Id : 0;
    if(isReviewThere){
      checkedReviewer=reviewRequestor.Id
    }
    this.setState({
      approverList,
      isReviewThere,
      isApproverThere,
      reviewerList,
      checkedApprover,
      checkedReviewer,
    });
  };

  handleFieldChange = (name: string, value: any) => {
    console.log({name,value})
    if (name === "leavePeriod") {
      if (
        value.value === FIRST_HALF_LEAVE_PERIOD_VALUE ||
        value.value === SECOND_HALF_LEAVE_PERIOD_VALUE
      ) {
        const { startDate } = this.state;
        this.setState({ endDate: startDate });
      }
    }
    if (name === "leaveType") {
      const { leaveRequestTypes } = this.props;
      let leaveRemaining = leaveRequestTypes.value?.find(
        (x) => x.LeaveTypeId === value?.value
      )?.NewBalance;
      this.setState({ leavePeriod: LEAVE_PERIOD_LIST[0], leaveRemaining });
    }
    if (name === "substitute") {
      if (this.state.leaveRequestId !== 0) {
        const { leaveRequestDetails } = this.props;
        const { isApprover } = this.state;
        let leaveRequest = leaveRequestDetails?.value?.LeaveRequestDto;
        if (isApprover) {
          if (value?.value === leaveRequest["EmployeeId"]) {
            let message = {
              MessageType: "Danger",
              Message: ["Please choose substitute other than requester!."],
              Status: false,
            };
            this.props.DisplayClientSideMessage(message);
            return;
          }
        }
      } else if (value?.value === this.props.user.EmployeeId) {
        let message = {
          MessageType: "Danger",
          Message: ["You cannot be substitute for your own request!."],
          Status: false,
        };
        this.props.DisplayClientSideMessage(message);
        return;
      }
    }

    if (name == "employeeToAssign") {
      this.setState({ leaveType: {} });
      this.initialize(value.value);
    }

    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: null,
      },
    } as Pick<State, keyof State>);
  };

  handleDateChange = async (range) => {
    let { startDate, endDate } = range;
    const { leavePeriod, leaveType, leaveRequestId } = this.state;
    if (
      leavePeriod &&
      (leavePeriod.value === SECOND_HALF_LEAVE_PERIOD_VALUE ||
        leavePeriod.value === FIRST_HALF_LEAVE_PERIOD_VALUE)
    ) {
      startDate = endDate = range;
    }
    if (isEmpty(endDate)) {
      endDate = startDate;
    }
    const { leaveRequestDetails } = this.props;
    debugger
    let requestData = {
      leaveTypeId: !isEmpty(leaveType?.value) ? leaveType?.value : 0,
      employeeId:
        leaveRequestId === 0
          ? this.props.user.EmployeeId
          : leaveRequestDetails.value.LeaveRequestDto["EmployeeId"],
      fromDateEng: getSettingWiseDate(startDate),
      toDateEng: getSettingWiseDate(endDate),
    };
    await this.props.GetLeaveRequestDays(requestData);
    this.setState({
      startDate,
      endDate,
      errors: {
        ...this.state.errors,
        startDate: null,
      },
    });
  };

  handleCheckBoxChange = (name, value) => {
    this.setState({ [name]: value } as Pick<State, keyof State>);
    //}
  };
  handleSaveLeaveRequest = async () => {
    const {
      leaveRequestId,
      leaveType,
      startDate,
      endDate,
      reason,
      substitute,
      leavePeriod,
      attachment,
      checkedApprover,
      checkedReviewer,
      leaveActionType,
      previousAttachment,
      approverList,
      reviewerList,
      employeeToAssign,
    } = this.state;

    const { isAssign } = this.props;

    let fieldsToValidate = {
      employeeToAssign,
      leaveType,
      startDate,
      endDate,
      reason,
    };

    if (reviewerList.length > 0)
      fieldsToValidate["checkedReviewer"] = checkedReviewer;
    if (approverList.length > 0)
      fieldsToValidate["checkedApprover"] = checkedApprover;
    let errors = await InputValidator(document, fieldsToValidate);
    if (!isEmpty(startDate) && isEmpty(endDate))
      errors.startDate = "EndDate is required";
    const { leaveRequestDetails } = this.props;
    if (isEmpty(approverList)) {
      this.setState({ errors, noApprover: true });
      this.props.DisplayClientSideMessage(
        "At least one approver is required to initiate the request."
      );
      return;
    }
    if (isEmpty(errors)) {
      const object = {
        IsAssigned: isAssign || false,
        LeaveRequestDto: {
          LeaveRequestId: leaveRequestId,
          EmployeeId: isAssign
            ? employeeToAssign.value
            : leaveRequestId === 0
            ? this.props.user.EmployeeId
            : leaveRequestDetails.value.LeaveRequestDto["EmployeeId"],
          LeaveTypeId: leaveType.value,
          FromDate: moment(startDate).format("YYYY/MM/DD").toString(),
          FromDateEng: getSettingWiseDate(startDate),
          ToDate: moment(endDate).format("YYYY/MM/DD").toString(),
          ToDateEng: getSettingWiseDate(endDate),
          IsHour: false,
          Reason: reason,
          IsHalfDayLeave:
            leaveType.isHalfDayAllowed &&
            leavePeriod &&
            (leavePeriod?.value === FIRST_HALF_LEAVE_PERIOD_VALUE ||
              leavePeriod?.value === SECOND_HALF_LEAVE_PERIOD_VALUE),
          Status: null,
          EditSequence: null,
          HalfDayType: leavePeriod?.value,
          SupervisorEmployeeIdInCaseOfSelection: checkedApprover,
          SubstituteEmployeeId: substitute.value,
          RecommendEmployeeId: checkedReviewer,
          LeaveSource: null,
          CompensatoryIsAdd:
            leaveType?.freqOfAccrual === "Compensatory"
              ? leaveActionType == 1
              : null,
          CompensatoryIsDeduct:
            leaveType?.freqOfAccrual === "Compensatory"
              ? leaveActionType == 2
              : null,
          IsSpecialCase: false,
          Days1: null,
          Days2: null,
          DaysOrHours: null,
          StartTime: null,
          EndTime: null,
        },
        Attachment: attachment?.filter((x) => !isEmpty(x.AttachmentId)),
      };
      const options = {
        indices: true,

        nullsAsUndefineds: false,

        booleansAsIntegers: false,
      };

      const formData = objectToFormData(
        object,
        options // optional
      );
      attachment
        ?.filter((x) => isEmpty(x.AttachmentId))
        .map((x, i) => {
          formData.append(`Files`, x);
        });
      //console.log(object);
      let result =
        leaveRequestId === 0
          ? await leaveRequestService.createLeaveRequest(formData)
          : await leaveRequestService.updateLeaveRequest(formData);
      if (result.Status) {
        const { isApprover } = this.state;
        if (leaveRequestId === 0) {
          await this.props.getEmployeeDashboard();
          let searchData = {
            pageNumber: 0,
            pageSize: 10,
            employeeName: "",
            requestType: "",
            requestStartDate: null,
            requestEndDate: null,
            eventStartDate: null,
            eventEndDate: null,
          };
          this.props.GetMyRequests(searchData);
        }
        if (leaveRequestId !== 0) await this.props.refreshRequestData();
        if (leaveRequestId !== 0 && isApprover)
          await this.props.GetAuditLogOfRequests({
            requestId: leaveRequestId,
            moduleId: EMPLOYEE_LEAVE_REQUEST_MODULE_VALUE,
          });

        this.props.closeForm(false);
      }
      this.props.DisplayClientSideMessage(result);
    } else {
      this.setState({ errors });
      let message = {
        MessageType: "Danger",
        Message: ["Please fill all the required fields."],
        Status: false,
      };
      this.props.DisplayClientSideMessage(message);
    }
  };
  public render() {
    const {
      leaveType,
      errors,
      startDate,
      endDate,
      reason,
      substitute,
      leavePeriod,
      attachment,
      checkedApprover,
      leaveActionType,
      checkedReviewer,
      leaveRequestTypeList,
      approverList,
      reviewerList,
      leaveRemaining,
      leaveRequestId,
      isApprover,
      noApprover,
      employeeToAssign,
    } = this.state;
    const {
      leaveRequestTypes,
      leaveRequestApprover,
      leaveRequestDetails,
      user,
      companyInformation,
      leaveRequestDays,
      isAssign,
    } = this.props;
    // const isRequester =
    //   leaveRequestId === 0 ||
    //   (checkedApprover !== this.props.user.EmployeeId &&
    //     checkedReviewer !== this.props.user.EmployeeId);

    const isHalfDaTypeSelected =
      leaveType?.isHalfDayAllowed &&
      leavePeriod &&
      (leavePeriod.value === SECOND_HALF_LEAVE_PERIOD_VALUE ||
        leavePeriod.value === FIRST_HALF_LEAVE_PERIOD_VALUE)
        ? true
        : false;
    // const approverList = [
    //   {
    //     Id: 1,
    //     FullName: "Mahesh Shrestha Prashad",
    //     Imagepath: "",
    //     Designation: "Manager",
    //   },
    //   {
    //     Id: 2,
    //     FullName: "Mahesh Shrestha Prashad",
    //     Imagepath: "",
    //     Designation: "Manager",
    //   },
    //   {
    //     Id: 3,
    //     FullName: "Mahesh Shrestha Prashad",
    //     Imagepath: "",
    //     Designation: "Manager",
    //   },
    //   {
    //     Id: 4,
    //     FullName: "Mahesh Shrestha Prashad",
    //     Imagepath: "",
    //     Designation: "Manager",
    //   },
    // ];
    // const reviewerList = [
    //   {
    //     Id: 1,
    //     FullName: "Mahesh Shrestha Prashad",
    //     Imagepath: "",
    //     Designation: "Manager",
    //   },
    //   {
    //     Id: 2,
    //     FullName: "Mahesh Shrestha Prashad",
    //     Imagepath: "",
    //     Designation: "Manager",
    //   },
    //   {
    //     Id: 3,
    //     FullName: "Mahesh Shrestha Prashad",
    //     Imagepath: "",
    //     Designation: "Manager",
    //   },
    //   {
    //     Id: 4,
    //     FullName: "Mahesh Shrestha Prashad",
    //     Imagepath: "",
    //     Designation: "Manager",
    //   },
    // ];

    return (
      <Modal
        title={isAssign ? "Assign Leave" : "Leave Request"}
        open={true}
        onModalClose={() => this.props.closeForm(false)}
        type=""
        className="modal-leave-request leave-request-form"
      >
        <>
          <div className="leave-request-form__body">
            {isAssign && (
              <div className="form-row ml-sm mr-sm">
                <SelectEmployee
                  employeeToAssign={employeeToAssign}
                  error={errors && errors.employeeToAssign}
                  onChange={this.handleFieldChange}
                />
              </div>
            )}
            <div className="form-row ml-sm mr-sm">
              <FormGroup
                formName="reactselect"
                onChange={this.handleFieldChange}
                name="leaveType"
                value={leaveType}
                options={leaveRequestTypeList}
                placeholder="Select"
                validators={["required"]}
                error={errors && errors.leaveType}
                width="250px"
                label="Select leave type"
                loading={
                  leaveRequestTypes.loading && leaveRequestDetails.loading
                }
                loadingType="skeleton"
              />
              {!isEmpty(leaveType) && leaveType.label !== "Unpaid Leave" && (
                <span className="genericForm-group__label ml-md">
                  {leaveRemaining} days {leaveType.label} remaining
                </span>
              )}
            </div>
            <div className="form-row ml-sm mr-sm">
              {/* <FormGroup
                formName="reactdaterange"
                startDate={startDate}
                endDate={endDate}
                onChange={(name, range) => this.handleDateChange(range)}
                placeholder="Select date"
                label="Dates"
                width="250px"
                validators={["required"]}
                error={errors && (errors.startDate || errors.endDate)}
                name="startDate"
              /> */}

              <DatepickerAsPerSetting
                formName="customdateinput"
                onChange={(name, range) => {debugger;this.handleDateChange(range)}}
                value={
                  isHalfDaTypeSelected
                    ? startDate
                    : {
                        startDate,
                        endDate,
                      }
                }
                width="250px"
                placeholder="Select date"
                label={`Dates${getLeaveRequestDaysLabel(leaveRequestDays)}`}
                validators={["required"]}
                error={errors && (errors.startDate || errors.endDate)}
                name="startDate"
                isDateRange={!isHalfDaTypeSelected}
              />
              {/* <FormGroup
                formName="customdateinput"
                onChange={(name, range) => this.handleDateChange(range)}
                value={
                  isHalfDaTypeSelected
                    ? startDate
                    : {
                        startDate,
                        endDate,
                      }
                }
                placeholder="Select date"
                label={`Dates${getLeaveRequestDaysLabel(leaveRequestDays)}`}
                // isNepali
                width="250px"
                validators={["required"]}
                error={errors && (errors.startDate || errors.endDate)}
                name="startDate"
                isDateRange={!isHalfDaTypeSelected}
                isNepali={!companyInformation.IsEnglishDate}
                isTextInNepali={!companyInformation.IsEnglishDate}
              /> */}
              {leaveType?.isHalfDayAllowed && (
                <FormGroup
                  formName="chipsinput"
                  name="leavePeriod"
                  chipsArray={LEAVE_PERIOD_LIST}
                  value={leavePeriod && leavePeriod.label}
                  onChange={this.handleFieldChange}
                  validators={["required"]}
                  error={errors && errors.selectedType}
                  className="leave-period ml-md"
                />
              )}
              {leaveType?.freqOfAccrual === "Compensatory" && (
                <div className="flex ml-md">
                  <FormGroup
                    formName="radiogroup"
                    name="leaveActionType"
                    label="Add"
                    value={1}
                    checked={leaveActionType == 1}
                    labelPosition="right"
                    onChange={this.handleFieldChange}
                    validators={["required"]}
                    error={errors && errors.leaveActionType}
                    width="75px"
                  />
                  <FormGroup
                    formName="radiogroup"
                    name="leaveActionType"
                    label="Deduct"
                    value={2}
                    checked={leaveActionType == 2}
                    labelPosition="right"
                    onChange={this.handleFieldChange}
                    validators={["required"]}
                    error={errors && errors.leaveActionType}
                    width="75px"
                  />
                </div>
              )}
            </div>
            <div className="form-row ml-sm mr-sm">
              <FormGroup
                name="reason"
                value={reason}
                onChange={this.handleFieldChange}
                label="Reason for taking leave"
                error={errors && errors.reason}
                width="100%"
                validators={["required"]}
              />
            </div>
            <div className="form-row ml-sm mr-sm">
              <EmployeeSearch
                onChange={this.handleFieldChange}
                name="substitute"
                value={substitute}
                placeholder="Search (3 letters..) and Select"
                label="Substitute in my absence"
              />
              {errors && errors.substitute && (
                <div className="genericForm-group__message">
                  {errors.substitute}
                </div>
              )}
            </div>
            <div className="form-row ml-sm mr-sm">
              <FormGroup
                formName="dragAndDropUpload"
                onChange={this.handleFieldChange}
                name="attachment"
                value={attachment}
                placeholder="Select"
                validators={["required"]}
                error={errors && errors.attachment}
                width="100%"
                label=""
              />
            </div>

            <div
              className={classNames({
                "form-row ml-sm mr-sm items-start": true,
              })}
            >
              <FormGroup
                formName="checkBoxGroup"
                name="checkedReviewer"
                label="Reviewer"
                checkGroupArray={reviewerList}
                checkedGroupValue={checkedReviewer}
                onChange={this.handleFieldChange}
                multiple={false}
                // loading={leaveRequestApprover.loading}
                // loadingType="skeleton"
                validators={["required"]}
                error={errors && errors.checkedReviewer}
                disabled={isApprover || this.state.isReviewThere}
                width="50%"
              />
              <FormGroup
                formName="checkBoxGroup"
                name="checkedApprover"
                label="Approver"
                checkGroupArray={approverList}
                checkedGroupValue={checkedApprover}
                onChange={this.handleFieldChange}
                multiple={false}
                // loading={leaveRequestApprover.loading}
                // loadingType="skeleton"
                validators={["required"]}
                error={errors && errors.checkedApprover}
                disabled={isApprover || this.state.isApproverThere}
                width="50%"
              />
              {noApprover && (
                <div className="no-approver">
                  <div className="genericForm-group__label">
                    <label className="genericForm-group__label">
                      Approver *
                    </label>
                  </div>
                  <div className="flex items-start">
                    <MdInfo className="info-icon" />
                    <span className="ml-xsm text-md">
                      There is no approver set for this request type. Please
                      contact your HR Administrator. At least one approver is
                      required to initiate the request.
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="leave-request-form__footer flex justify-center">
            <button
              className="button  button-white"
              onClick={() => this.props.closeForm(false)}
            >
              <span> Cancel</span>
            </button>

            <button
              className="button  button-primary"
              onClick={() => this.handleSaveLeaveRequest()}
            >
              <span>
                {this.state.leaveRequestId === 0
                  ? "Send Request"
                  : "Update Request"}{" "}
              </span>
            </button>
          </div>
        </>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  leaveRequestTypes: state.employeeLeaveRequestReducer.leaveRequestTypes,
  leaveRequestApprover: state.employeeLeaveRequestReducer.leaveRequestApprover,
  leaveRequestDetails: state.employeeLeaveRequestReducer.leaveRequestDetails,
  leaveRequestDays: state.employeeLeaveRequestReducer.leaveRequestDays,
  user: state.auth.user,
  companyInformation: state.commonReducer.companyInformation,
});

const mapDispatchToProps = {
  GetLeaveRequestType,
  GetLeaveRequestApprover,
  DisplayClientSideMessage,
  GetLeaveRequestById,
  getEmployeeDashboard,
  GetAuditLogOfRequests,
  GetMyRequests,
  GetLeaveRequestDays,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
