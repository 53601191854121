import React, { useState, useEffect, useRef } from "react";
import { GrAttachment } from "react-icons/gr";
import UploadInput from "./upload";
import Icons from "components/icons";
import SvgIcons from "components/icons/svgIcons";
import { IoIosClose, IoIosAt } from "react-icons/io";
import { FaCross, FaPlus, FaTimes } from "react-icons/fa";

const AttachmentSection = (props: any) => {
  const { data, onAttachmentChange, name, isValidUser } = props;
  const [files, setFiles] = useState([]);

  useEffect(() => {
    data && data.Attachment && setFiles(data.Attachment);
  }, [data]);

  return (
    <div className="taskmodal__attachment">
      <div className="taskmodal__attachment-title flex">
        <GrAttachment />
        <h3>Attachment</h3>
      </div>
      <div className="taskmodal__attachment-body">
        <UploadInput
          isValidUser={isValidUser}
          value={files}
          name={name}
          isMultiple={true}
          maxFiles={5}
          onChange={(name, items) => {
            setFiles(items);
            onAttachmentChange && onAttachmentChange(name, items);
          }}
        />
      </div>
    </div>
  );
};

export default AttachmentSection;
