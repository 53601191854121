import {
  COUNT_TRAVEL_REQUESTS,
  GET_MY_TRAVEL_REQUESTS,
  GET_OTHERS_TRAVEL_REQUESTS,
  GET_OTHERS_TRAVEL_REQUESTS_EXCEL_EXPORT,
  GET_TRAVEL_REQUEST_REPORT,
  EDIT_TRAVEL_REQUESTS,
  RELOAD_TRAVEL_REQUESTS,
  GET_HOST_AND_HOTEL,
  GET_MODE_OF_TRANSPORT,
  // GET_COUNTRY_LIST,
  // GET_CURRENCY_LIST,
  // GET_DISTRICT_LIST,
  // GET_EMPLOYEE_LIST,
  // GET_EMPLOYEE_LIST_WITH_PHOTO,
  GET_TRAVEL_CATEGORY_LIST,
  GET_CATEGORY_ALL_EXPENSE_LIST,
  UPLOAD_FILES,
  UPLOAD_FILE_STATUS,
  GET_APPROVAL_AUTHORITIES,
  APPROVAL_HISTORY_LOADING,
  GET_TRAVEL_APPROVAL_HISTORY,
  PUT_APPROVE_TRAVEL_REQUEST,
  APPROVAL_AUTHORITIES_LOADING,
  TRAVEL_EDIT_LOADING,
  IS_LOADING,
  GET_TRAVEL_APPROVAL_FLOW_STEPS,
  GET_TRAVEL_HISTORY,
  TRAVEL_HISTORY_LOADING,
  UPDATE_TRAVEL_COUNT,
} from "actions/types";

const initialState = {
  totalTravelRequests: 0,
  totalMyTravelRequests: 0,
  myTravelRequestList: null,
  travelInformationToEdit: null,
  othersTravelRequestList: null,
  otherTravelRequestExportExcel: null,
  travelRequestReport: null,
  reloadData: false,
  travelHostList: [],
  travelHotelList: [],
  travelHostAndHotelPhoneList: [],
  modeOfTransportList: [],
  // countryList: [],
  // currencyList:[],
  // districtList: [],
  // employeeList:[],
  // employeeListWithPhoto:[],
  // myDetail:null,
  travelCategoryList: [],
  selectedAdvanceAndExpensesList: [],
  isFileUploadSuccess: false,
  succesFileInfoList: [],
  approvalAuthorities: { loading: false, value: [] },
  approvalHistory: { loading: false, value: [] },
  travelEditLoading: false,
  isLoading: true,
  travelListCount: {},
  otherTravelRequestInitialLoad: false,
  myTravelRequestInitialLoad: false,
  flowSteps: [],
  travelHistory: { loading: false, value: [] },
  travelHistorySearchedPage: { index: 0, total: 0 },
  travelHistoryInitialLoad: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }
    case TRAVEL_EDIT_LOADING: {
      return {
        ...state,
        travelEditLoading: action.payload,
      };
    }
    case COUNT_TRAVEL_REQUESTS: {
      return {
        ...state,
        totalTravelRequests: action.totalTravelRequests,
        totalMyTravelRequests: action.totalMyTravelRequests,
        travelListCount: action.payload,
      };
    }
    case APPROVAL_AUTHORITIES_LOADING: {
      return {
        ...state,
        approvalAuthorities: { loading: true, value: [] },
      };
    }
    case APPROVAL_HISTORY_LOADING: {
      return {
        ...state,
        approvalHistory: { loading: true, value: [] },
      };
    }
    case GET_TRAVEL_APPROVAL_HISTORY: {
      return {
        ...state,
        approvalHistory: { loading: false, value: action.payload },
      };
    }
    case GET_APPROVAL_AUTHORITIES: {
      return {
        ...state,
        approvalAuthorities: { loading: false, value: action.payload },
      };
    }
    case GET_MY_TRAVEL_REQUESTS:
      return {
        ...state,
        myTravelRequestList: action.payload,
        myTravelRequestInitialLoad: true,
      };

    case GET_OTHERS_TRAVEL_REQUESTS:
      return {
        ...state,
        othersTravelRequestList: action.payload,
        otherTravelRequestInitialLoad: true,
      };

    case GET_OTHERS_TRAVEL_REQUESTS_EXCEL_EXPORT:
      return {
        ...state,
        otherTravelRequestExportExcel: action.payload,
      };

    case GET_TRAVEL_REQUEST_REPORT:
      return {
        ...state,
        travelRequestReport: action.payload,
      };

    case EDIT_TRAVEL_REQUESTS:
      return {
        ...state,
        travelInformationToEdit: action.payload,
        travelEditLoading: false,
      };

    case RELOAD_TRAVEL_REQUESTS:
      return {
        ...state,
        reloadData: action.reloadData,
      };

    case GET_HOST_AND_HOTEL:
      let formatedHoteList = [];
      let formatedHostist = [];
      let formatedHostAndHoteList = [];
      action &&
        action.payload.length > 0 &&
        action.payload.map((m) => {
          if (m.IsHotel) {
            formatedHoteList.push({ label: m.Name, value: m.Id });
          } else {
            formatedHostist.push({ label: m.Name, value: m.Id });
          }
          formatedHostAndHoteList.push({ label: m.PhoneNo, value: m.Id });
        });

      return {
        ...state,
        travelHostList: formatedHostist,
        travelHotelList: formatedHoteList,
        travelHostAndHotelPhoneList: formatedHostAndHoteList,
      };

    case GET_MODE_OF_TRANSPORT:
      return {
        ...state,
        modeOfTransportList: action.payload,
      };

    // case GET_COUNTRY_LIST:
    //   let formatedCountryList = [];
    //   action &&
    //     action.payload.length > 0 &&
    //     action.payload.map(m => {
    //       formatedCountryList.push({
    //         label: m.CountryName,
    //         value: m.CountryId
    //       });
    //     });
    //   return {
    //     ...state,
    //     countryList: formatedCountryList
    //   };

    //   case GET_CURRENCY_LIST:
    //   let formatedCurrencyList = [];
    //   action &&
    //     action.payload.length > 0 &&
    //     action.payload.map(m => {
    //       formatedCurrencyList.push({
    //         label: m.CurrencyCode,
    //         value: m.Id,
    //          currencyId: m.CurrencyId,
    //          currencyDescription: m.CurrencyDescription
    //       });
    //     });
    //   return {
    //     ...state,
    //     currencyList: formatedCurrencyList
    //   };

    // case GET_DISTRICT_LIST:
    //   let formatedDistrictList = [];
    //   action &&
    //     action.payload.length > 0 &&
    //     action.payload.map(m => {
    //       formatedDistrictList.push({ label: m.District, value: m.DistrictId });
    //     });
    //   return {
    //     ...state,
    //     districtList: formatedDistrictList
    //   };

    // case GET_EMPLOYEE_LIST:
    //   let formatedEmployeeList = [];
    //   action &&
    //     action.payload.length > 0 &&
    //     action.payload.map(m => {
    //       formatedEmployeeList.push({
    //         label: m.EmployeeName,
    //         value: m.EmployeeId
    //       });
    //     });
    //   return {
    //     ...state,
    //     employeeList: formatedEmployeeList
    //   };

    // case GET_EMPLOYEE_LIST_WITH_PHOTO:
    //   let formatedEmployeeListWithPhoto = [];
    //   let formatedMyDetail = null;

    //   action &&
    //     action.payload.length > 0 &&
    //     action.payload.map(m => {
    //       if (m.EmployeeId == action.currentEmployeeID) {
    //         formatedMyDetail = {
    //           label: m.FullName,
    //           value: m.EmployeeId,
    //           Imagepath: m.Imagepath
    //         };
    //       } else {
    //         formatedEmployeeListWithPhoto.push({
    //           label: m.FullName,
    //           value: m.EmployeeId,
    //           Imagepath: m.Imagepath
    //         });
    //       }
    //     });
    //   return {
    //     ...state,
    //     employeeListWithPhoto: formatedEmployeeListWithPhoto,
    //     myDetail: formatedMyDetail
    //   };

    case GET_TRAVEL_CATEGORY_LIST:
      let formatedTravelCategoryList = [];
      action &&
        action.payload.length > 0 &&
        action.payload.map((m) => {
          formatedTravelCategoryList.push({ label: m.Text, value: m.Value });
        });
      return {
        ...state,
        travelCategoryList: formatedTravelCategoryList,
      };

    case GET_CATEGORY_ALL_EXPENSE_LIST:
      return {
        ...state,
        selectedAdvanceAndExpensesList: action.payload,
      };

    case UPLOAD_FILES:
      return {
        ...state,
        isFileUploadSuccess: action.isFileUploadSuccess,
        succesFileInfoList: [...action.payload, ...state.succesFileInfoList],
      };

    case UPLOAD_FILE_STATUS:
      return {
        ...state,
        succesFileInfoList: [],
        isFileUploadSuccess: action.isFileUploadSuccess,
      };
    case GET_TRAVEL_APPROVAL_FLOW_STEPS:
      return {
        ...state,
        flowSteps: action.payload,
      };
    case TRAVEL_HISTORY_LOADING: {
      return {
        ...state,
        travelHistory: { loading: true, value: [] },
      };
    }
    case GET_TRAVEL_HISTORY: {
      return {
        ...state,
        travelHistory: { loading: false, value: action.payload },
        travelHistorySearchedPage: {
          index: action.page.index,
          total: action.page.total,
        },
        travelHistoryInitialLoad: true,
      };
    }
    case UPDATE_TRAVEL_COUNT: {
      let updateField = action.payload;
      return {
        ...state,
        travelListCount: {
          ...state.travelListCount,
          [updateField.Name]: updateField.Value,
        },
      };
    }
    default:
      return state;
  }
}
