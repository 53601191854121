import React, {useState} from "react";
import Unavaiiability from "./unavailability";
import Locations from "./locations";
import Team from "./team";
import Schedule from "./schedules";
import Requests from "./requests";
import Roles from "./roles";
import "./styles.scss";
import classnames from "classnames";
import {BiCopy} from "react-icons/bi";
import ScheduleCopyModal from "./schedules/components/scheduleCopyModal";
import {IoCopyOutline} from "react-icons/io5";
import {FiPrinter} from "react-icons/fi";

const tabs = [
  {id: 1, name: "schedule", label: "Schedule", component: Schedule},
  {id: 2, name: "requests", label: "Requests", component: Requests},
  {id: 3, name: "unavailability", label: "Unavailability", component: Unavaiiability},
  {id: 4, name: "team", label: "Team", component: Team},
  {id: 5, name: "locations", label: "Locations", component: Locations},
  {id: 6, name: "roles", label: "Roles", component: Roles},
];

function Schedules() {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState(null);
  const onTeamSet = (st) => setState(st);
  const onModalClose = () => setOpen(false);
  const onTabClick = (tabItem) => {
    setActiveTab(tabItem);
  };
  const ActiveComponent: any = activeTab.component;

  return (
    <div className="scheduler">
      <div className="scheduler-tabs">
        <div className="scheduler-tabs__container">
          <ul className="scheduler-tabs__list">
            {tabs &&
              tabs.map((tab) => (
                <SchedulerTab
                  active={activeTab}
                  key={tab.id}
                  tab={tab}
                  onTabClick={onTabClick}
                />
              ))}
          </ul>

          {state && state.teamId && state.startDate && state.endDate && (
            <div className="scheduler-tabs__btns">
              <div onClick={() => setOpen(true)} className="scheduler-tabs__btn">
                <IoCopyOutline />
              </div>
              <ScheduleCopyModal
                open={open}
                onModalClose={onModalClose}
                teamId={state.teamId}
                isSingle={false}
                startDate={state.startDate}
                endDate={state.endDate}
              />
              <div className="scheduler-tabs__btn">
                <FiPrinter />
              </div>
            </div>
          )}
        </div>
        <div className="scheduler-tabs__body">
          <ActiveComponent onTeamSet={onTeamSet} />
        </div>
      </div>
    </div>
  );
}

export const SchedulerTab = ({tab, onTabClick, active}) => {
  const tabClass = classnames("scheduler-tabs__item", {
    "scheduler-tabs__active": active.id === tab.id,
  });
  return (
    <li className={tabClass} onClick={() => onTabClick(tab)}>
      <div className="scheduler-tabs__label">{tab.label}</div>
    </li>
  );
};

export default Schedules;
