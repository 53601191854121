import React from 'react'
import HealthForm from './healthForm';
import HealthDetailCard from './healthDetailCard';
import { connect } from "react-redux";
import {
  GetEmployeeHealthDetail
} from '../action';
import './style.scss';
import isEmpty from "isEmpty";

interface IAppProps {
  employeeId: any,
  employeeHealthDetail: any,
  GetEmployeeHealthDetail: Function,
}

interface State {
  isEditView: any,
}

class index extends React.Component<IAppProps, State> {

  constructor(props) {
    super(props);
    this.state = {
      isEditView: false,
    }
  }

  async componentDidMount() {
    const {
      employeeId,
      GetEmployeeHealthDetail
    } = this.props;
    if (employeeId) {
      await GetEmployeeHealthDetail(employeeId);
    }
    const { employeeHealthDetail } = this.props;
    if (!isEmpty(employeeHealthDetail)) {
      this.setState({ isEditView: false });
    }
  }

  toggleEditView = (flag) => {
    this.setState({ isEditView: flag });
  }

  render () {
    const { employeeHealthDetail, employeeId } = this.props;
    const { isEditView } = this.state;
    return (
    <div className="healthCard__health">
        <div className="healthCard-heading">
            <h4>Health Information</h4>
        </div>
        <div className="healthCard__health__details">
          <>
          {!isEmpty(employeeHealthDetail) &&
            <HealthDetailCard
              key="1"
              detail={employeeHealthDetail}
              isEditView={isEditView}
              toggleEditView={(flag) => this.toggleEditView(flag)}
            />}
          {(isEditView || isEmpty(employeeHealthDetail)) &&
            <HealthForm
              editView={isEditView}
              editViewData={employeeHealthDetail}
              toggleEditView={(flag) => this.toggleEditView(flag)}
              EmployeeId={employeeId}
            />}
        </>
        </div>
    </div>
    );
}
}

const mapStateToProps = state => ({
  employeeHealthDetail: state.employeeProfile.employeeHealthDetail,
});

const mapDispatchToProps = {
  GetEmployeeHealthDetail
};

export default connect(mapStateToProps, mapDispatchToProps)(index);