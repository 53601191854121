import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { inputWidth } from "./constants";
import ReactDatePicker from "react-date-picker";

const inputclass = error => {
  return classnames({
    "form-group": true,
    "inline-form-group": true,
    inputWidth: inputWidth,
    error: error
  });
};

export function TwoDatePicker(props) {
  const {
    label,
    views,
    format,
    maxDate,
    fromValue,
    toValue,
    onFromChange,
    onToChange,
    error,
    errorClassName
  } = props;
  return (
    <div className={inputclass(error)}>
      <label className="form-group__label">{label}</label>
      <div className="jobApply__pickers" style={{ width: "142px" }}>
        <ReactDatePicker
          onChange={onFromChange}
          value={fromValue}
          format={format}
          maxDate={new Date(maxDate)}
        />
      </div>

      <div
        className="jobApply__pickers"
        style={{ marginLeft: "16px", width: "142px" }}
      >
        <ReactDatePicker
          onChange={onToChange}
          value={toValue}
          format={format}
          maxDate={new Date(maxDate)}
        />
      </div>
      {error ? <span className={errorClassName}>{error}</span> : null}
    </div>
  );
}

export function OneDatePicker(props) {
  const {
    label,
    views,
    value,
    format,
    maxDate,
    onChange,
    error,
    errorClassName
  } = props;
  return (
    <div className={inputclass(error)}>
      <label className="form-group__label">{label}</label>
      <div className="jobApply__pickers">
        <ReactDatePicker
          onChange={onChange}
          value={value}
          format={format}
          maxDate={new Date(maxDate)}
        />
      </div>
      {error ? <span className={errorClassName}>{error}</span> : null}
    </div>
  );
}

TwoDatePicker.propTypes = {
  label: PropTypes.string,
  views: PropTypes.array,
  fromValue: PropTypes.any,
  toValue: PropTypes.any,
  format: PropTypes.string,
  maxDate:PropTypes.string
};
TwoDatePicker.defaultProps = {
  label: "label",
  views: ["year", "month"],
  fromValue: new Date(),
  toValue: new Date(),
  format: "yyyy/MM/dd",
  maxDate:"9999"
};

OneDatePicker.propTypes = {
  label: PropTypes.string,
  views: PropTypes.array,
  value: PropTypes.any,
  format: PropTypes.string,
  maxDate:PropTypes.string
};
OneDatePicker.defaultProps = {
  label: "label",
  views: ["year", "month", "date"],
  value: new Date(),
  format: "yyyy/MM/dd",
  maxDate:"9999"
};
