import React from "react";
import isEmpty from "isEmpty";
import Avatar from "react-avatar";

import { getBaseAxios } from "helpers/constants";

function ViewTravelInformation(props) {
  const data = {
    travelType: props.settlementReport.TravelType,
    purpose: isEmpty(props.settlementReport.Reason)
      ? "-"
      : props.settlementReport.Reason,
    destination: props.settlementReport.SettlementDestinationDto.map((x) => {
      return {
        Place: x.PlaceName,
        District: x.District,
        Mode: x.TransportMode,
        Host: isEmpty(x.HostName) ? "-" : x.HostName,
        Hotel: isEmpty(x.HotelName) ? "-" : x.HotelName,
        Dates: x.StartDate + " - " + x.EndDate,
        Days: x.TravelDays + " days",
        Nights: x.TravelNights + " nights",
        Phone: isEmpty(x.PhoneNo) ? "-" : x.PhoneNo,
      };
    }),
    approver: props.settlementReport.TravelApprover.map((x) => {
      return {
        Url: x.ApproverImagePath,
        Name: x.ApproverName,
      };
    }),
  };

  let {
    travelType,
    destination,
    approver,
    isEditTravelinformation,
    purpose,
  } = data;
  return (
    <>
      <div className="dest-info-approver">
        <div className="dest-info">
          <div className="dest-info__header">
            <span>
              {travelType == 1 ? "Domestic Travel" : "International Travel"}
            </span>
          </div>
          <div className="dest-info__body">
            {destination.map((x, i) => {
              return (
                <div className="dest-place" key={i}>
                  <div className="dest-place__count">
                    <span>{i + 1}</span>
                  </div>
                  <div className="dest-grid">
                    <div className="dest-grid__row">
                      <span className="field">Place</span>
                      <span className="value">{x.Place}</span>
                    </div>

                    <div className="dest-grid__row">
                      <span className="field">District</span>
                      <span className="value">{x.District}</span>
                    </div>
                    <div className="dest-grid__row">
                      <span className="field">Mode</span>
                      <span className="value">{x.Mode}</span>
                    </div>
                    <div className="dest-grid__row">
                      <span className="field">Host</span>
                      <span className="value">{x.Host}</span>
                    </div>
                    <div className="dest-grid__row">
                      <span className="field">Hotel</span>
                      <div>
                        <span className="value">{x.Hotel}</span>
                        <span className="value">, {x.Phone}</span>
                      </div>
                    </div>
                    <div className="dest-grid__row">
                      <span className="field">Dates</span>
                      <div>
                        <span className="value">{x.Dates}</span>
                        <span className="value">{x.Days}</span>
                        <span className="value">{x.Nights}</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="dest-info__purpose">
              <span className="field">Purpose</span>
              <span className="value">{purpose}</span>
            </div>
          </div>
        </div>
        <div className="approver">
          <div className="approver__header">Travel Approved by</div>
          <div className="approver__body">
            <div className="approver-grid">
              {approver.map((x, i) => {
                return (
                  <div className="approver-grid__row" key={i}>
                    <Avatar
                      alt="Avatar"
                      src={getBaseAxios() + x.Url}
                      className="emp-image"
                      round
                      size={50}
                      alt={x.Name}
                      name={x.Name}
                    />
                    {/* <img
                      className="approver-image"
                      src={getBaseAxios() + x.Url}
                    /> */}
                    <span className="approver-name">{x.Name}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ViewTravelInformation;
