import React, { Component } from 'react'
import FormGroup from '../form'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'isEmpty';
import {    
    getBranches,
    getDepartments,
    getEmployees,
    getSubDepartments,
    getJobStatus,
    getLevels,
    getProvinceList
} from './action'
import {Skeleton} from '@material-ui/lab';
import CreatableSelect from 'react-select/creatable';


interface DataSelectProps {
    actionType: 'branch' | 'department' | 'departmentList' | 'subDepartment' | 'employee' | 'jobStatus' | 'levels' | 'levels' | 'provinceList',
    getBranches: Function,
    getEmployees:Function,
    getDepartments:Function,
    getSubDepartments:Function,
    onChange:Function,
    value: any,
    label?:string,
    width?:string,
    type:string,
    placeholder?:string,
    name:string,
    selectReducer: any,
    getJobStatus:Function,
    getProvinceList:Function,
    getLevels:Function,
    isArrayKeys?:boolean,
    isDisabled?:boolean,
    multiple?:boolean,
    colorType?:any,
    styles?:any,
    defaultValue?:any
}

export class DataSelect extends Component<DataSelectProps , any> {
    componentDidMount = () => {
        this.callAPI()
    };
    componentDidUpdate = (prevProps,prevState) =>{
        const {actionType}=this.props;
        if(actionType && actionType !== prevProps.actionType){
            this.callAPI()
        }
    }

    callAPI = ()=>{
        const {actionType}=this.props;
      
        if(actionType==='branch'){
            this.props.getBranches();
        }
        if(actionType==='department' || actionType === 'departmentList'){
            this.props.getDepartments();
        }
        if(actionType==='subDepartment'){
            this.props.getSubDepartments();
        }
        if(actionType==='employee'){
            this.props.getEmployees();
        }
        if(actionType==='jobStatus'){
            this.props.getJobStatus();
        }
        if(actionType ==='levels'){
            this.props.getLevels()
        }
        if(actionType === 'provinceList'){
            this.props.getProvinceList()
        }
    }
    render() {
        const {
                onChange,
                value,
                label,
                isArrayKeys,
                isDisabled,
                name,
                width,
                multiple,
                placeholder,
                selectReducer,
                defaultValue,
                actionType,
                colorType,
                styles,
                type
            }=this.props;
        const Arrays = (data: { Text:string, Value:string|number } [])=>{
            let arrayItem=[];
            if(data){
                data.map((item,key)=>{
                    arrayItem.push({label:item.Text,value:item.Value})
                })
            }
            return arrayItem;
        }
        const empArrays =(data)=>{
            let arrayItem=[];
            if(data){
                data.map((item,key)=>{
                    arrayItem.push({label:item.FullName,value:item.EmployeeId})
                })
            }
            return arrayItem;
        }
        console.log({[actionType]:selectReducer[actionType]})
        const data= actionType ? selectReducer[actionType]?.value ? selectReducer[actionType]?.value :selectReducer[actionType] : [];
        // const {loading}=selectReducer[actionType]
        let selValue=[];
        if(!isEmpty(data)){
            if(actionType==='employee' ){
                selValue= empArrays(data)
            }else{
                selValue= Arrays(data)
            }
        }
        if(type && type === 'tags'){
            return(
                <CreatableSelect
                    defaultValue={defaultValue}
                    isMulti 
                    name={name}
                    onChange={(value)=>onChange(value,name)}
                    options={selValue}
                    colorType={colorType}
                    placeholder={!isEmpty(placeholder)?placeholder:''}
                    className="tags-multi-select"
                    styles={styles}
                    classNamePrefix="select"
                />
            )
        }
        return (
            <FormGroup
                formName="reactselect" 
                onChange={onChange} 
                value={value} 
                disabled={isDisabled}
                options={data}
                label={!isEmpty(label)?label:''}
                placeholder={!isEmpty(placeholder)?placeholder:''}
                name={name}
                width={!isEmpty(width)?width:''}
                multiple={multiple}
                isArray
                // loading={loading}
                // loadingType="skeleton"
                isArrayKeys={isArrayKeys}
            />
        )
    }
}


const mapStateToProps = state => ({
    selectReducer: state.selectReducer,
});

const mapDispatchToProps = {
    getProvinceList,
    getLevels,
    getJobStatus,
    getBranches,
    getDepartments,
    getEmployees,
    getSubDepartments
}
    
export default connect(mapStateToProps, mapDispatchToProps)(DataSelect);