import React, {useRef, useState} from "react";
import classnames from "classnames";
import isEmpty from "helpers/isEmpty";
import Icons from "../icons";
import "./styles.scss";
import useEventListener from "helpers/customHooks/useEventListener";
import {createPortal} from "react-dom";
const {Provider, Consumer: DropConsumer} = React.createContext<any>({useOpen: null});
export {DropConsumer};
interface DropdownProps {
  children?: any;
  onClick?: Function;
  customDropIcon?: any;
  dropClass?: string;
  title?: any;
  isIcon?: boolean;
  isFixed?: boolean;
  dropComponent?: any;
  width?: number;
  lists?: Array<any>;
}

function DropDown(props: DropdownProps) {
  const {
    children,
    onClick,
    customDropIcon,
    dropClass,
    dropComponent,
    lists,
    isIcon,
    width,
    isFixed,
    title,
    ...rest
  } = props;
  const settingsIcon = customDropIcon ? customDropIcon : <Icons name="ArrowDown" />;
  const ref = useRef(null);
  const contentRef = useRef(null);
  const [open, setOpen] = useState(false);
  const onDrop = (e: any) => {
    // e.stopPropagation();
    setOpen(!open);
  };
  const dropClasses = classnames({
    "drop-down": true,
    "drop-down__active": open,
    "drop-down__fixed": isFixed,
    [dropClass]: !isEmpty(dropClass),
  });
  useEventListener("click", (event: any) => {
    // debugger
    const isCustomChecks =
      event.target &&
      event.target.parentElement &&
      !event.target.parentElement.classList.contains("custom-check");

    if (isFixed) {
      const content =
        contentRef && contentRef.current && !contentRef.current.contains(event.target);
      if (
        content &&
        ref &&
        ref.current &&
        isCustomChecks &&
        !ref.current.contains(event.target)
      ) {
        setOpen(false);
      }
    } else {
      if (ref && ref.current && isCustomChecks && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    }
    // if(ref && ref.current && event.target.parentElement && !event.target.parentElement.classList.contains("custom-check")){
    //     setOpen(false);
    // }
  });
  // let rect = el.getBoundingClientRect();
  const dropRect = isFixed ? ref.current && ref.current.getBoundingClientRect() : {};
  const widthStyle = width ? {width} : {};
  const finalStyles: any = {
    position: "fixed",
    top: dropRect?.top,
    left: dropRect?.left,
    bottom: dropRect?.bottom,
    right: dropRect?.right,
    ...widthStyle,
  };
  const onlyWidth = {...widthStyle};
  return (
    <Provider value={{setOpen}}>
      <div ref={ref} className={dropClasses}>
        <div onClick={(e) => onDrop(e)} className="dropdown__main">
          {isIcon && settingsIcon}
          {title && <div className="drop-down__main-icon">{title}</div>}
        </div>
        {isFixed ? (
          createPortal(
            <div
              ref={contentRef}
              style={finalStyles}
              className={classnames("drop-down__content", {
                "drop-down__content-active": open,
              })}
            >
              <DropContent setOpen={setOpen} {...props} />
            </div>,
            document.body
          )
        ) : (
          <div style={onlyWidth} className="drop-down__content">
            <DropContent setOpen={setOpen} {...props} />
          </div>
        )}
      </div>
    </Provider>
  );
}

DropDown.defaultProps = {
  isIcon: true,
};

const DropContent = (props) => {
  if (!isEmpty(props.lists)) {
    return (
      <div className="drop-down__content-body">
        <ul className="drop-down__content-list">
          {props.lists.map((item, key) => {
            const onClick = () => {
              props.setOpen(false);
              item.onClick && item.onClick();
            };
            console.log({item});
            if (item.isCustom) return item.label;
            return (
              <li
                onClick={(e) => {
                  e.stopPropagation();
                  onClick();
                }}
                className="drop-down__content-item"
                key={key}
              >
                <span>{item.label}</span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
  if (!isEmpty(props.children)) {
    return <div className="drop-down__content-body">{props.children}</div>;
  }
  return null;
};

export default DropDown;
