import React, { Component } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Filter,
  Inject,
  Sort,
  // SortSettingsModel,
} from "@syncfusion/ej2-react-grids";
import Pagination from "components/common/pagination";
import TableRows from "./tableRows";

export class LegalClientTable extends Component {
  constructor(props) {
    super(props);
    this.widths = {
      clientName: 280,
      type: 130,
      activeProject: 180,
      blank1: 220,
      blank2: 250,
      expand: 30,
    };

    // this.sortingOptions = () =>
    //   SortSettingsModel({
    //     columns: [
    //       { field: "ClientName", direction: "Ascending" },
    //       { field: "TypeName", direction: "Ascending" },
    //       { field: "ActiveProjectCount", direction: "Ascending" },
    //     ],
    //   });
    this.gridTemplate = this.gridTemplate;
    this.row = [];
  }
  componentDidUpdate(prevProps){
    const ele = document.querySelector(".e-headercell.e-mousepointer")
    setTimeout(()=>{
      if(ele){
        ele.click()
      } 
    },[500])
  }
  gridTemplate(props, history) {
    return (
      <TableRows
        {...props}
        isArchivedTab={this.props.isArchivedTab}
        getLegalClientList={this.props.getLegalClientList}
        handleFormDisplay={this.props.handleFormDisplay}
        handleEditClient={this.props.handleEditClient}
        handleDeleteClient={this.props.handleDeleteClient}
        handleArchiveClient={this.props.handleArchiveClient}
        handleRestoreClient={this.props.handleRestoreClient}
        handleEditProject={this.props.handleEditProject}
        handleDeleteProject={this.props.handleDeleteProject}
        handleArchiveProject={this.props.handleArchiveProject}
        handleRestoreProject={this.props.handleRestoreProject}
        SaveProjectContract={this.props.SaveProjectContract}
        handleRenewContract={this.props.handleRenewContract}
        {...history}
        ref={(row) => {
          this.row = [...this.row, row];
        }}
      />
    );
  }

  render() {
    const {
      data,
      handleRowChange,
      handleIndexChange,
      pageIndex,
      rowsPerPage,
    } = this.props;

    // let element = document.getElementById('grid');
    // let height=element ? parseInt(element.offsetHeight)+40:0;
    // console.log(height)

    return (
      <div className="default-table">
        <div className="pending-settlement-grid">
          <GridComponent
            dataSource={data}
            className="custom-grid"
            // toolbar={this.toolbar}
            // filterSettings={{ type: "Menu" }}
            // allowFiltering={true}
            //  height={height}
            id="grid"
            // allowExcelExport={true}
            ref={(g) => (this.grid = g)}
            rowTemplate={this.gridTemplate.bind(this)}
            allowSorting={true}
            // sortSettings={this.sortingOptions}
          >
            <ColumnsDirective>
              <ColumnDirective width={this.widths.expand} />
              <ColumnDirective
                field="ClientName"
                headerText="Client Name"
                width={this.widths.clientName}
              />
              <ColumnDirective
                field="TypeName"
                headerText="Type"
                width={this.widths.type}
              />
              <ColumnDirective
                field="ActiveProjectCount"
                headerText="Projects"
                width={this.widths.activeProject}
              />
              <ColumnDirective width={this.widths.blank1} />
              <ColumnDirective width={this.widths.blank2} />
            </ColumnsDirective>
            {/* <Inject services={[Filter]} />
            <Inject services={[Filter,Sort]} />
            */}
            <Inject services={[Sort]} />
          </GridComponent>

          {data && data.length > 0 && (
            <Pagination
              handleRowChange={handleRowChange}
              handleIndexChange={handleIndexChange}
              pageIndex={pageIndex}
              rowsPerPage={rowsPerPage}
              total={data[0].TotalRows}
            />
          )}
        </div>
      </div>
    );
  }
}

export default LegalClientTable;
