import FormItem from 'components/formGroup/formItem'
import React, { useState,useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import RolesTable from './rolesTable';
import Button from 'components/button';
import Modal from 'components/modal';
import AddRole from './addRole';
import { getRosterTeamRoleList } from 'scenes/roster/helpers/action';

const intialState = {
    teamId:null,
}

function Roles() {
    const method = useForm()
    const { control, setValue } = method;
    const [open,setOpen] = useState(false);
    const onModalClose = () => setOpen(false);
    const [state, setState] = useState(intialState)
    const [edit, setEdit] = useState(null)
    const dispatch = useDispatch();
    const {teamList,teamRoleList,scheduleList} = useSelector((state:any)=>state.roosterReducer);
    const onChange = (name,value) =>{
        setState(prev=>({
            ...prev,
            [name]:value
        }))
    }
    useEffect(()=>{
        dispatch(getRosterTeamRoleList(-1))
    },[])
    useEffect(()=>{
        if(state.teamId){
            dispatch(getRosterTeamRoleList(state.teamId.value))
        }
    },[state.teamId])
    return (
        <div className="schedule">
            <div className="schedule-filters">
                <div className="schedule-filters__col">
                    <FormItem
                        control={control}
                        width="200px"
                        name="teamId"
                        value={state.teamId}
                        onChange={onChange}
                        formName="reactselect"
                        placeholder='Select Team'
                        options={teamList}
                    />
                </div>
                <Button 
                    onClick={()=>setOpen(true)}
                    title="Add Role" 
                    bg="primary" 
                />
            </div>  
            <div className="schedule-filters__table">
                <RolesTable />
            </div>
            <AddRole 
                onModalClose={onModalClose}
                open={open}       
                isEdit = {Boolean(edit)} 
            />
        </div>
    )
}

export default Roles
