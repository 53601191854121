import {
  DisplayClientSideMessage,
  GetLevelsWithGroups,
  SetSelectedRecords,
} from "actions/commonAction";
import Modal from "components/modal";
import isEmpty from "isEmpty";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as jobProfileService from "services/jobProfile";
import { GetJobDescription } from "./actions";
import SearchAndFilter from "./searchAndFilter";
import Table from "./table/jobDescriptionTable";
class jobDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobFamily: {},
      jobRole: {},
      jobCode: "",
      jobTitle: "",
      pageIndex: 0,
      pageSize: 10,
      modalState: { show: false, type: "normal" },
      exportName: "Job Description",
      exportColumns: [
        { label: "Job Code", value: "JobCode" },
        { label: "Job Title", value: "JobTitle" },
        { label: "Job Family", value: "LevelName" },
        { label: "Job Level/Role", value: "LevelGroupName" },
        { label: "Assigned to", value: "AssignedTo" },
        { label: "Last Updated", value: "LastUpdatedDate" },
      ],
      exportData: [],
      isMultiDeleteEnable: false,
    };
  }

  async componentDidMount() {
    await this.props.GetLevelsWithGroups();
    await this.getJobDescriptionList();
  }
  getJobDescriptionList = async () => {
    const {
      jobFamily,
      jobRole,
      jobCode,
      jobTitle,
      pageIndex,
      pageSize,
    } = this.state;
    let searchData = {
      levelGroupId: !isEmpty(jobRole) ? jobRole.value : 0,
      levelId: !isEmpty(jobFamily) ? jobFamily.value : 0,
      jobCode: jobCode,
      jobTitle: jobTitle,
      pageIndex: pageIndex,
      pageSize: pageSize,
    };
    await this.props.GetJobDescription(searchData);
  };
  handleInputChange = (name, value) => {
    //const { name, value } = e.target;
    this.setState({ [name]: value, pageIndex: 0 }, async () => {
      await this.getJobDescriptionList();
    });
  };

  handleConfirmDelRecord = (id) => {
    this.setState({
      selectedId: id,
    });
    this.handleModalShow(true, "confirm");
  };
  handleModalShow = (show, type) => {
    this.setState({ modalState: { show, type } });
  };
  handleDelRecord = async (args) => {
    let result = await jobProfileService.deleteJobDescription(
      this.state.selectedId
    );
    if (result.Status) {
      await this.getJobDescriptionList();
      this.props.SetSelectedRecords([]);
    }
    this.props.DisplayClientSideMessage(result);
  };

  handleRowChange = (num) => {
    this.setState({ pageSize: num }, async () => {
      await this.getJobDescriptionList();
    });
  };

  handleIndexChange = (num) => {
    this.setState({ pageIndex: num }, async () => {
      await this.getJobDescriptionList();
    });
  };
  handleModalShow = (show, type) => {
    this.setState({ modalState: { show, type } });
  };
  handleExcelExport = async () => {
    let searchData = {
      levelGroupId: 0,
      levelId: 0,
      jobCode: "",
      jobTitle: "",
      pageIndex: 0,
      pageSize: 99999999,
    };
    let result = await jobProfileService.getJobDescription(searchData);
    if (result.Status) {
      let data = result.Data.map((x) => {
        x.LastUpdatedDate = moment(
          new Date(x.LastUpdatedDate),
          "YYYYMMDDHHmmss"
        ).format("MMMM D, YYYY");
        return x;
      });
      this.setState({ exportData: data });
    }
  };
  handleMultiDeleteOption = (enable) => {
    this.setState({ isMultiDeleteEnable: enable });
  };
  handleDelMultipleRecord = () => {
    let selectedId = this.props.selectedRecords.join(",");
    this.setState({ selectedId }, async () => {
      await this.handleDelRecord();
    });
  };
  render() {
    const {
      jobFamily,
      jobRole,
      jobCode,
      jobTitle,
      pageIndex,
      pageSize,
      exportName,
      exportColumns,
      exportData,
      isMultiDeleteEnable,
    } = this.state;
    const {
      jobDescriptionList,
      history,
      jobFamilyList,
      jobRoleList,
    } = this.props;
    const { modalState } = this.state;
    return (
      <div className="job-description">
        <SearchAndFilter
          handleInputChange={this.handleInputChange}
          jobFamily={jobFamily}
          jobRole={jobRole}
          jobCode={jobCode}
          jobTitle={jobTitle}
          jobFamilyList={jobFamilyList}
          jobRoleList={jobRoleList}
          type="jobDescription"
          exportName={exportName}
          exportColumns={exportColumns}
          exportData={exportData}
          handleExcelExport={this.handleExcelExport}
          isMultiDeleteEnable={isMultiDeleteEnable}
          handleDelMultipleRecord={this.handleDelMultipleRecord}
        />
        <Table
          data={jobDescriptionList}
          pageIndex={pageIndex}
          pageSize={pageSize}
          handleRowChange={this.handleRowChange}
          handleIndexChange={this.handleIndexChange}
          handleEdit={this.handleEdit}
          handleConfirmDelRecord={this.handleConfirmDelRecord}
          history={history}
          handleMultiDeleteOption={this.handleMultiDeleteOption}
        />
        {modalState.show && (
          <Modal
            title=""
            open={modalState.show}
            onModalClose={() => this.handleModalShow(false, "normal")}
            type={modalState.type}
            func={() => this.handleDelRecord()}
          ></Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  jobDescriptionList: state.jobProfileReducer.jobDescriptionList,
  jobFamilyList: state.commonReducer.jobFamilyList,
  jobRoleList: state.commonReducer.jobRoleList,
  selectedRecords: state.commonReducer.selectedRecords,
});

const mapDispatchToProps = {
  GetJobDescription,
  GetLevelsWithGroups,
  DisplayClientSideMessage,
  SetSelectedRecords,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(jobDescription)
);
