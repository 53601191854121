import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DataSelect from "./index";
import DataAutocomplete from "./dataAutocomplete";
import EmployeeSearch from "../common/employeeSearch";
import {
  // getJobStatus,
  // getLevels,
  // getDesignations
  getLeaveTypes,
} from "./action.js";
// import {

//     getLeaveRequestStatus,
//     getLeaveSource
// } from "scenes/Payroll/Leave_Absence/action";

import FormGroup from "components/form";

export class CommonFilterSelect extends Component {
  componentDidMount() {
    const { isLeaveSource, isLeaveStatus, isLeaveTypes } = this.props;
    // if(isLeaveSource) this.props.getLeaveSource();
    // if(isLeaveStatus) this.props.getLeaveRequestStatus();
    if (isLeaveTypes) this.props.getLeaveTypes();
  }

  render() {
    const {
      onChange,
      value,
      isJobStatus,
      isLeaveSource,
      isLeaveStatus,
      isLeaveTypes,
    } = this.props;
    const { leaveTypes } = this.props.selectReducer;
    const { leaveSource, leaveStatus } = this.props.leaveReducer;
    return (
      <>
        <div className="filters-input__item">
          <EmployeeSearch
            onChange={onChange}
            value={value.employee}
            label="Employee"
            name="employee"
            width="220px"
          />
        </div>
        <div className="filters-input__item">
          <DataSelect
            onChange={onChange}
            value={value.branch}
            label="Branch"
            name="branch"
            multiple
            actionType="branch"
            width="200px"
          />
        </div>
        <div className="filters-input__item">
          <DataSelect
            onChange={onChange}
            value={value.department}
            label="Department"
            name="department"
            actionType="department"
            width="200px"
          />
        </div>
        <div className="filters-input__item">
          {isJobStatus ? (
            <DataSelect
              onChange={onChange}
              value={value.jobStatus}
              label="Status"
              name="jobStatus"
              actionType="jobStatus"
              width="200px"
              isArray
              isArrayKeys
              multiple
            />
          ) : null}
        </div>
        <div className="filters-input__item">
          {isLeaveSource ? (
            <FormGroup
              formName="reactselect"
              onChange={onChange}
              value={value.leaveSource}
              options={leaveSource.value}
              label="Leave Source"
              name="leaveSource"
              width="200px"
              isArray
              loading={leaveSource.loading}
              loadingType="skeleton"
            />
          ) : null}
        </div>
        {isLeaveStatus ? (
          <div className="filters-input__item">
            <FormGroup
              formName="reactselect"
              onChange={this.onSelectChange}
              value={value.leaveStatus}
              options={leaveStatus.value}
              label="Leave Status"
              name="leaveStatus"
              width="200px"
              isArray
              loading={leaveStatus.loading}
              loadingType="skeleton"
            />
          </div>
        ) : null}
        {isLeaveTypes ? (
          <div className="filters-input__item">
            <FormGroup
              formName="reactselect"
              onChange={this.onSelectChange}
              value={value.leaveTypes}
              options={leaveTypes.value}
              label="Leave Types"
              name="leaveTypes"
              width="200px"
              isArray
              loading={leaveTypes.loading}
              loadingType="skeleton"
            />
          </div>
        ) : null}
      </>
    );
  }
}

CommonFilterSelect.propTypes = {
  // getLeaveSource: PropTypes.func.isRequired,
  // getLeaveRequestStatus: PropTypes.func.isRequired,
  getLeaveTypes: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  selectReducer: PropTypes.object.isRequired,
  leaveReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  selectReducer: state.selectReducer,
  leaveReducer: state.leaveReducer,
});
export default connect(mapStateToProps, {
  // getLeaveSource,
  // getLeaveRequestStatus,
  getLeaveTypes,
})(CommonFilterSelect);
