import React from "react";
import { AiOutlineDoubleRight } from "react-icons/ai";
import isEmpty from "isEmpty";
import { withRouter } from "react-router-dom";
import { LEAVE_PATHS } from "constants/paths";
import RequestAndAssign from "scenes/common/requestAndAssign";

function CommonHeader(props) {
  let pathName = !isEmpty(
    LEAVE_PATHS.find((x) => x.PathName === props.location.pathname)
  )
    ? LEAVE_PATHS.find((x) => x.PathName === props.location.pathname).Title
    : "Requests for Review";
  return (
    <>
      <div className="common-header-title">
        <span className="route-name">{pathName}</span>
      </div>
      <RequestAndAssign />
    </>
  );
}

export default withRouter(CommonHeader);
