import React, { useState, Component } from "react";
import Form from "components/form";
import { connect } from "react-redux";
import { ValidationComponent } from "helpers/getComponents";
import Upload from "components/common/upload";
import Button from "components/button";
import DetailCard from './detailCard';
import WorkExperienceForm from './workExperienceForm';
import { InputValidator } from "helpers/inputValidator";
import isEmpty from "isEmpty";
import { MdAdd } from "react-icons/md";
import  {
    currentlySelectedSection,
    deleteEmployeeExperienceDetails,
    getEmployeeExperienceList,
} from '../action';

const CountryData = [
    {
        label: 'Nepal',
        value: 'Nepal'
    },
    {
        label: 'India',
        value: 'India'
    }
]
class WorkExperience extends Component{
    constructor(props) {
        super(props);
        this.state = {
            showAddSection: false,
            editView: false,
            errors: {},
            Course: '',
            Qualification: '',
            College: '',
            Country: '',
            University: '',
            PassedYear: null,
            GradeValue: null,
            MajorSubjects: null,
            IsGPA: true,
            Files: [],
        }
    }

    handleInput = (name, value) => {
        console.log()
        this.setState(
            { [name]: value, },
            () => {
                this.updateErrors();
            }
        );
    }

    updateErrors = async () => {
        const { errors } = this.state;
        if (!isEmpty(errors)) {
        let errors = await InputValidator(document, this.state);
        this.setState({ errors });
        }
    };

    deleteEmployeeExperience= async (id) => {
        const {
            deleteEmployeeExperienceDetails,
            getEmployeeExperienceList,
            employeeId
        } = this.props;
        this.setState({ showAddSection: false });
        await deleteEmployeeExperienceDetails(id);
        if (this.props.employeeExperienceDeleted)
            getEmployeeExperienceList(employeeId)
    }

    toggleAddSecton = (showAddSection, editView, title) => {
        this.setState({ showAddSection, editView, editViewData: null });
        this.props.currentlySelectedSection(title)
    }

    toggleEditView = (flag, editViewData, title) => {
        this.setState({
            showAddSection: false,
            editView: editViewData
            && editViewData.PrevEmploymentId,
            editViewData
        });
        this.props.currentlySelectedSection(title);
    }

    render () {

        const { employeeExperienceList, currentlySelectedEduSection } = this.props;
        return (
            <div className="education__education">
                <div className="education-heading">
                    <h4>Work and Experience</h4>
                    <Button 
                        onClick={() => this.toggleAddSecton(true, false, 'workExperience')}
                        bg="white" 
                        buttonType="icon-button"
                        leftIcon={<MdAdd />} 
                        title="Add" /> 
                </div>
                {this.state.showAddSection && currentlySelectedEduSection == 'workExperience' &&
                <WorkExperienceForm 
                    closeAddSection={() => this.toggleAddSecton(false, false, 'workExperience')}
                    employeeId={this.props.employeeId}
                />
                }
                <div className="education__education__details">
                        {employeeExperienceList.length > 0 ? employeeExperienceList.map(edu => 
                        <>
                            <DetailCard
                                key={edu.PrevEmploymentId}
                                detail={edu}
                                toggleEditView={(data, title) => this.toggleEditView(true, data, title)}
                                deleteEmployeeItem={(id) => this.deleteEmployeeExperience(id)}
                                editView={true}
                                title="workExperience"
                                editViewId={this.state.editView}
                                currentlySelectedEduSection={currentlySelectedEduSection}
                            />
                            {this.state.editView == edu.PrevEmploymentId && currentlySelectedEduSection == 'workExperience' &&
                            <WorkExperienceForm
                                editView={true}
                                editViewData={edu}
                                toggleEditView={() => this.toggleEditView(false, null, 'workExperience')}
                                employeeId={this.props.employeeId}
                            />}
                        </>
                        ):  <div className="education__detail__details">
                        <div className="education-detail">
                                <div className="education-detail__primary">
                                    <p className="noData">No Data Found</p>
                                </div>
                        </div>
                    </div>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    employeeExperienceList: state.employeeProfile.employeeExperienceList || [],
    employeeExperienceDeleted: state.employeeProfile.employeeExperienceDeleted,
    currentlySelectedEduSection: state.employeeProfile.currentlySelectedEduSection,
});

const mapDispatchToProps = {
    currentlySelectedSection,
    deleteEmployeeExperienceDetails,
    getEmployeeExperienceList,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkExperience);