import React from 'react'
import isEmpty from 'isEmpty';

const data = [
    {
        title:'Summary of the position',
        details:'Responsible for the financial health of a company or organisation, Producing Financial reports and developing dtrategies based on financial reports. Guiding senior executives in making sound business descision in long term and short term'
    },
    {
        title:'Primary Duties and responsiblities',
        details:[
            'Monitor day to day financial operations within the company, suchas payroll, invoicing and other transaction',
            'Oversee financial department employees,including financial assistant and accountants',
            'Contract outside service for tax prepration, auditing, banking, investment and other financial needs as necessary',
            'Track the company financial status and performance to identify areas for potential improvement',
            'Seek out methods for minimizing financial risk to the company',
            'Research and analyze financial resports and marketing trends',
            'Review Financial data and prepare monthly and annual report'
        ]
    },
    {
        title:'Education & Qualification',
        details:[
            {
                subtitle:'Education',
                subdetails:<span>
                    <span className="text-blue"> Chartered Accountant </span>
                    or <span className="text-blue"> Masters in Finance </span>
                    with three years of experience
                </span>
            },
            {
                subtitle:'Qualification',
                subdetails:<span>
                    <span className="text-blue"> PMP Certification </span>
                    with three years of experience
                </span>
            }
        ]
    },
    {
        title:'Knowledge, Skills and Abilities (KSA)',
        details:[
            {
                subtitle:'Knowledege',
                subdetails:[
                    (
                        <span>
                            Knowledge of business and management principle involved in
                            <span className="text-blue"> Strategic planning </span>
                            resources allocations and human resources
                        </span>
                    ),
                    (
                        <span>
                            Knowledge of
                            <span className="text-blue"> generally accepted accounting procedure and principles 
                            </span>
                        </span>
                    )
                ]
            },
            {
                subtitle:'Abilities',
                subdetails:[
                    (
                        <span>Ability to 
                            <span className="text-blue"> Plan implement and evaluate program </span>
                        </span>
                    ),
                    (
                        <span>Ability to direct and 
                            <span className="text-blue"> origanize program activity </span>
                        </span>
                    ),
                    (
                        <span>Ability to
                            <span className="text-blue"> establish goals and objectives </span>
                        </span>
                    )
                ]
            }
        ]
    }
]
function JobDetails(props) {
    let { Sections } = props.jobDesc || { JobDiscriptionSections: {} }

    let data = !isEmpty(Sections) ? filterData(Sections) : [];
    return (
        <div className="jobdesc-blocks">
            {
                data.map(Block)
            }
        </div>
    )
}

const filterData = (Sections) => {
    if(!isEmpty(Sections)) {
        return Sections.map((k, index) => {
            const { 
                SectionName,
                SectionSummary,
                Components,
            } = k;
            return {
                title: SectionName,
                num: index,
                name: `${SectionName}${index}`,
                details: Array.isArray(Components) && Components.length > 0 ? 
                    Components.map(j =>  {
                            return {
                                subtitle: j.ComponentName,
                                subdetails: [(
                                    <div dangerouslySetInnerHTML={{__html:j.ComponentSummary}}></div>
                                )]      
                            }
                    }) :SectionSummary
            }
        });
    }
} 

const Block = (data,index)=>{
    return(
        <div key={index + data.title} className="jobdesc-block">
            <Title title={data.title} />
            <Body>
                <BodyText details={data.details} />    
            </Body>
        </div>
    )
}

const Body = ({children})=>{
    return (
    <div className="jobdesc-block__body">
        {children}
    </div>
    )
}

const BodyText = ({details})=>{
    if(typeof details === 'string'){
        return(
            <div dangerouslySetInnerHTML={{__html:details}}>
            </div>
        )
    }
    if(Array.isArray(details)){
       return (
            <ul>
                {details.map((item,index)=>{
                    if(typeof item === 'string'){
                        return <li key={index + Math.random()}>
                                    <span>- {item}</span>
                                </li>
                    }
                    if(typeof item === 'object' && !Array.isArray(item)){
                        return(
                            <li className="jobdesc-subitems">
                                <SubItems item={item} />
                            </li>
                        )
                    }
                    return null
                    
                })}
            </ul>
            )
    }
    return null
}

const SubItems = ({item}) =>{
    let details;
    if(typeof item.subdetails === 'object' && !Array.isArray(item.subdetails)){
        details = item.subdetails;
    }
    if(Array.isArray(item.subdetails)){
        details=(
            <ul className="jobdesc-subitems__list">
                {
                    item.subdetails.map(item=>{
                        console.log(item);
                        return <li className="jobdesc-subitems__item">
                                 {item}
                        </li>
                    })
                }
            </ul>
        )
    }
    return(
        <div className="jobdesc-subitems__block">
            <div className="jobdesc-subitems__title">
                <h3>{item.subtitle}</h3>
            </div>
            <div className="jobdesc-subitems__details">
                {details}
            </div>
        </div>
    )
}

const Title = ({title})=>{
    return(
        <div className="jobdesc-title">
            <h3>{title}</h3>
        </div>
    )
}

export default JobDetails
