import React, { useEffect, useState } from 'react'
import Form from 'components/form';
import Button from 'components/button';
import { useDispatch, useSelector } from 'react-redux';
import { addCoursePage, updateCoursePage } from '../helpers/action';
// import {AddContent,ShowItem} from './contents';
import {ShowItem} from './contents';
import {contentTypes} from 'constants/enums';
import AddContent from './addContent';
import AddQuestions from './addQuestions';

// declare let FroalaEditor: any;
const initState = {
    title: '',
    author: '',
    description: '',
    cover: [],
}

function PageForm(props) {
    const [state, setState] = useState(initState)
    const [error, setError] = useState("")
    const dispatch = useDispatch();
    const {coursePages,course,coursePage,courseContent} = useSelector((state:any)=>state.lmsReducer)
    const onChange = (name, value) => {
        setState((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    useEffect(()=>{
        if(coursePage){
            setState(prev=>({
                ...prev,
                title:coursePage.PageName,
               
            }))
        }
    },[coursePage])
    const addPage = async () => {
        setError("")
        if(!state.title){
            setError("Title is required")
            return;
        }
        const data={
            "id": 0,
            "courseId": props.courseId,
            "pageName": state.title,
            "pageOrder": 0
        }
        if(props.isJustForm){
            await dispatch(addCoursePage(data,props.history))
        }else{
            data['id'] = coursePage.Id
            await dispatch(updateCoursePage(data))
        }
    }
    const newContentArray = courseContent &&  [...courseContent]
    return (
        <div className="courseForm__form-main">
            <div className="courseForm__form flex">
                <Form
                    name="title"
                    label="Page title"
                    onChange={onChange}
                    value={state.title}
                    error={error}
                    onKeyUp={(e)=>e.key === "Enter" && addPage()}
                />
                {/* <Button bg="primary" title={props.isJustForm ? "Add Page" : "Update Page"} onClick={()=>addPage()}  /> */}
            </div>
            {
                // courseContent && courseContent.map(item=><ShowItem {...item} />)
                newContentArray && newContentArray.sort((a,b)=>a?.ContentOrder - b?.ContentOrder).map(item=><ShowItem {...item} />)
            }
            {/* {
                !props.isJustForm && <AddContent />
            } */}
            <AddContent {...props} />
            <AddQuestions {...props} />
        </div>
    )
}


export default PageForm
