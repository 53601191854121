import React, { Component } from "react";
import Popup from "components/popup";
// import AutoSelect from "~/Components/Common/AutoSelect";
import Form from "components/form";

export class TableRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popup: false,
      selectRole: null,
      isChangeRole: false,
    };
    this.popupRef = React.createRef();
  }

  handleChangeRole = (props) => {
    let selectRole = null;
    if (props.BillingDesignationId && props.BillingDesignationName) {
      selectRole = {
        label: props.BillingDesignationName,
        value: props.BillingDesignationId,
      };
    }

    this.setState({ popup: true, selectRole: selectRole, isChangeRole: true });
  };

  handleSelectRole = () => {
    this.setState({ popup: true, selectRole: null, isChangeRole: false });
  };

  handleRoleSelectOrChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleClose = () => {
    this.setState({ popup: false, selectRole: null });
  };

  handleDone = (props) => {
    const { selectRole, isChangeRole } = this.state;
    if (selectRole) {
      const data = {
        RelationId: props.RelationId,
        MemberRefId: props.MemberId,
        MemberTypeRefId: props.MemberType,
        BillingDesignationRefId: selectRole.value,
      };
      props.handleSaveOrUpdateDillingDesignation(data, isChangeRole);
    } else {
      props.displayClientSideMessage("Role is not selected");
    }
  };

  render() {
    const { props } = this;
    const { popup, selectRole } = this.state;
    return (
      <>
        <tr className="table-row" role="row">
          <td className="e-rowcell e-templatecell" role="gridcell">
            <span>{props.MemberName}</span>
          </td>
          <td className="e-rowcell e-templatecell" role="gridcell">
            <span>{props.MemberTypeName}</span>
          </td>
          <td className="e-custom-row role">
            {props.activeTab.tabId == 1 ? (
              <div ref={this.popupRef}>
              
                {props.BillingDesignationName ? (
                  <span
                    className="role-choosed"
                    style={{ width: props.widths.role }}
                  >
                    <span>{props.BillingDesignationName}</span>
                    <span
                      className="role-link"
                      onClick={() => this.handleChangeRole(props)}
                    >
                      Change
                    </span>
                  </span>
                ) : (
                  <span className="role-link" onClick={this.handleSelectRole}>
                    Select a Role
                  </span>
                )}
                <div className="role-position__select">
                  <Popup
                    onClose={this.handleClose}
                    onDone={() => this.handleDone(props)}
                    open={popup}
                    parentPop={this.popupRef}
                    disableClickOutside={true}
                  >
                    {/* <AutoSelect
                      name={"selectRole"}
                      label={"Select Role"}
                      value={selectRole}
                      options={props.billingDesignationsDdl}
                      width="100%"
                      onChange={this.handleRoleSelectOrChange}
                    /> */}

                    <Form
                      formName="reactselect"
                      name={"selectRole"}
                      value={selectRole}
                      options={props.billingDesignationsDdl}
                      onChange={this.handleRoleSelectOrChange}
                      label={"Select Role"}
                    />
                  </Popup>
                </div>
              </div>
            ) : (
              <span>{props.BillingDesignationName}</span>
            )}
          </td>

          {props.activeTab.tabId == 1 && (
            <td className="e-custom-row">
              {/* MemberType==1 is Employee */}
              {props.MemberType != 1 && (
                <div className="flex actions">
                  <button
                    className="button with-drop button-secondary"
                    onClick={() => props.handleDeleteOrArchive(props.MemberId)}
                  >
                    <span>Delete</span>
                  </button>
                  {/* {props.isActiveTab && (
                  <button
                    className="button with-drop button-secondary"
                    onClick={() => props.handleDeleteOrArchive(props.MemberId)}
                  >
                    <span>Archive</span>
                  </button>
                )} */}
                </div>
              )}
            </td>
          )}
        </tr>
      </>
    );
  }
}

export default TableRow;
