import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Form from "components/form";
import Modal from "components/modal";
import Button from "components/button";
import {
  GetEmailTemplates,
  SaveCandidateEmail,
  SaveScheduledInterviewEmail,
} from "store/actions/recruitment/setting";
import { ComposeEmailType } from "scenes/recruit/enums";

function ComposeEmail(props) {
  const { src, data, reasons, emailTemplateObj } = props;

  const [templateObj, setTemplateObj] = useState(null);
  const [showCC, setCC] = useState(false);
  const [showBCC, setBCC] = useState(false);
  const [ckBody, setCkBody] = useState(""); //Note: donot keep ckBody inside state, it will occurs render issue
  const [state, setState] = useState({
    template: null,
    subject: "",
    cc: "",
    bcc: "",
  });

  useEffect(() => {
    props.GetEmailTemplates();
  }, []);
  useEffect(() => {
    if (emailTemplateObj) {
      let nextState = { ...state };
      nextState["subject"] = emailTemplateObj.Subject;

      setState(nextState);
      setCkBody(emailTemplateObj.Body);
      setTemplateObj(emailTemplateObj);
    }
  }, [emailTemplateObj]);
  const onChange = (name, value) => {
    let nextState = { ...state };

    if (name === "template") {
      nextState["subject"] = value.Subject;
      nextState["stageId"] = value.StageId;
      nextState["templateId"] = value.Id;
      setCkBody(value.Body);
    }
    nextState[name] = value;
    setState(nextState);
  };

  const reset = () => {
    setTemplateObj(null);
    setState({});
    setCkBody("");
  };
  const onSend = async () => {
    let CandidateIds = [];
    let jobID = 0;
    if (Array.isArray(data)) {
      CandidateIds = data.map((x) => x.CandidateId);
      jobID = data.length > 0 && data[0].JobId;
    } else {
      CandidateIds = [data.CandidateId];
      jobID = data.JobId;
    }
    const emailParam = {
      id: 0,
      templateId: state.templateId,
      stageId: state.stageId,
      candidateRef_Ids: CandidateIds,
      jobRef_Id: jobID,
      subject: state.subject,
      body: ckBody,
      cc: showCC ? state.cc : "",
      bcc: showBCC ? state.bcc : "",
    };
    if (src === ComposeEmailType.recruitment) {
      await props.SaveCandidateEmail(emailParam, onSuccess);
    } else if (src === ComposeEmailType.scheduleInterview) {
      const param = {
        scheduleId: emailTemplateObj.ScheduleId,
        jobId: data.JobId,
        CandidateId: data.CandidateId,
        candidateEmail: emailParam,
      };
      await props.SaveScheduledInterviewEmail(param, onSuccess);
    }
  };

  const onSuccess = () => {
    props.handleModal(false);
  };

  return (
    <Modal
      title={props.title ? props.title : `Compose Email`}
      open={props.open}
      onModalClose={() => {
        reset();
        props.handleModal(false);
      }}
      className="modal-compose-email"
      width="55%"
    >
      <div className="compose-email">
        {reasons && reasons.length > 0 ? (
          <div className="email-reject-reason">
            <div className="reason">
              Rejected with reason - {reasons.join(", ")}
            </div>
            <div className="info">
              <span> Send Email to Candidate?</span>{" "}
              <span
                className="info-action"
                onClick={() => {
                  props.handleModal(false);
                }}
              >
                Not now
              </span>
            </div>
          </div>
        ) : null}

        <div className="email-section email-from">
          <div className="flex">
            <label>From</label>

            {/* <Form
              formName="reactselect"
              onChange={onChange}
              value={state.from}
              name="from"
              options={[]}
              width="180px"
            /> */}
            <span>You</span>
          </div>
          <div>
            <Form
              formName="reactselect"
              onChange={onChange}
              name="template"
              options={props.emailTemplates || []}
              value={state.template}
              width="250px"
              disabled={templateObj ? true : false}
              placeholder="Select template"
            />
          </div>
        </div>
        <div className="email-section">
          <div className="flex">
            <label>To</label>
            {Array.isArray(data) ? (
              data.map((x) => (
                <span className="email-to__name">{x.CandidateName || ""}</span>
              ))
            ) : (
              <span className="email-to__name">{data.CandidateName || ""}</span>
            )}
          </div>
          <div className="flex">
            <Button
              bg="secondary"
              title={`${showCC ? "-" : "+"} Cc`}
              onClick={() => setCC(!showCC)}
            />
            <Button
              bg="secondary"
              title={`${showBCC ? "-" : "+"} BCc`}
              onClick={() => setBCC(!showBCC)}
            />
          </div>
        </div>
        {showCC && (
          <div className="email-section">
            <label>Cc</label>
            <Form
              formName="textarea"
              onChange={onChange}
              name="cc"
              value={state.cc}
              placeholder="Enter Cc emails (with coma seperated)"
            />
          </div>
        )}

        {showBCC && (
          <div className="email-section">
            <label>BCc</label>
            <Form
              formName="textarea"
              onChange={onChange}
              name="bcc"
              value={state.bcc}
              placeholder="Enter BCc emails (with coma seperated)"
            />
          </div>
        )}
        <div className="email-section">
          <Form
            formName="textinput"
            onChange={onChange}
            name="subject"
            value={state.subject}
            placeholder="Subject"
          />
        </div>
        <div className="email-section">
          <CKEditor
            editor={ClassicEditor}
            data={ckBody}
            onChange={(event, editor) => {
              const data = editor.getData();
              setCkBody(data);
            }}
          />
        </div>
        <div className="email-section">
          <span />
          <div>
            {/* <Button
              bg="secondary"
              title="Send Tommorow 11 AM"
              onClick={onSendTommorow}
            /> */}
            <Button bg="primary" title="Send" onClick={onSend} />
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  emailTemplates: state.recruitment_settingReducer.emailTemplates,
});

const mapDispatchToProps = {
  GetEmailTemplates,
  SaveCandidateEmail,
  SaveScheduledInterviewEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(ComposeEmail);
