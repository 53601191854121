import RouteEnum from 'constants/routeEnum';
import React from "react";
import { Link } from "react-router-dom";

function WaitingReview({ ActivitiesWaitingReview, isApproverReviewer }) {
  return (
    <div className="new-dashboard">
      <div className="activity-title">Activity Waiting Review</div>

      <div className="new-dashboard-group">
        {isApproverReviewer && (
          <Link
            to={{
              pathname: RouteEnum.ActivityReportPath,
              state: {
                isThisMonth: true,
                isMyActivities: false,
              },
            }}
          >
            <div className="new-dashboard-waiting_review">
              <div className="new-dashboard-waiting_review-top">
                TEAM ACTIVITIES
              </div>
              <div className="new-dashboard-waiting_review-body">
                <span className="count">
                  {ActivitiesWaitingReview?.TeamActivitiesPendingReviewCount ||
                    0}
                </span>
                <span className="count_text">activities waiting review</span>
              </div>
            </div>
          </Link>
        )}

        <Link
          to={{
            pathname: RouteEnum.ActivityReportPath,
            state: {
              isThisMonth: true,
              isMyActivities: true,
            },
          }}
        >
          <div className="new-dashboard-waiting_review">
            <div className="new-dashboard-waiting_review-top">
              MY ACTIVITIES
            </div>
            <div className="new-dashboard-waiting_review-body">
              <span className="count">
                {ActivitiesWaitingReview?.MyActivitiesPendingReviewCount || 0}
              </span>
              <span className="count_text">activities waiting review</span>
            </div>
          </div>
        </Link>
        <div className="new-dashboard-waiting_review">
          <div className="new-dashboard-waiting_review-top">MY PROJECTS</div>
          <div className="new-dashboard-waiting_review-body">
            <span className="count">
              {ActivitiesWaitingReview?.MyProjectsCount || 0}
            </span>
            <span className="count_text">active projects</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WaitingReview;
