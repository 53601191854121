import FormGroup from "components/form";
import { SUMMARY_OF_POSITION_TYPE } from "constants/constants";
import { GrCheckmark, GrClose } from "react-icons/gr";
import { MdDelete, MdEdit } from "react-icons/md";
import isEmpty from "isEmpty";
import React, { Component } from "react";

const profileBlockType = SUMMARY_OF_POSITION_TYPE;
class SummaryOfPosition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNameEdit: false,
      subtitleToEdits: [],
      profileBlockType: "",
    };
  }

  componentDidMount() {
    var stateCopy = this.props.state.profileBlocks.map((x) => {
      return { ...x };
    });
    const currentSection = stateCopy.find((x) => x.type === profileBlockType);
    this.setState({ intialBlockData: currentSection, profileBlockType });
  }

  render() {
    const { isNameEdit } = this.state;
    const { errors } = this.props.state;
    const currentSection = this.props.state.profileBlocks.find(
      (x) => x.type === profileBlockType
    );
    const { title } = currentSection;
    const { subtitle, paragraph } = currentSection.components[0];

    return (
      <div className="summary-of-position profile-block">
        <div className="profile-block__header justify-start">
          <span className="profile-text">{title}</span>
          <span className="profile-type">{currentSection.type}</span>
        </div>
        <div className="profile-block__body">
          <FormGroup
            formName="textarea"
            name="paragraph"
            label={subtitle}
            cols={50}
            rows={6}
            value={isEmpty(paragraph) ? "" : paragraph}
            onChange={(name, value) =>
              this.props.handleComponentFieldChange(
                profileBlockType,
                currentSection.components[0].id,
                name,
                value
              )
            }
            disabled={this.props.isViewMode}
            placeholder="Write something..."
          />
        </div>
      </div>
    );
  }
}

export default SummaryOfPosition;
