import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { MdCheck, MdClose } from "react-icons/md";
import isEmpty from 'isEmpty'

export class UpdateInput extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            top:'',
            left:'',
            amount:null,
            note:''
        }
        this.onEnter=this.onEnter.bind(this);
        this.onUpdate=this.onUpdate.bind(this);
        this.onCancel=this.onCancel.bind(this);
        this.onChange=this.onChange.bind(this);
    }
    
    componentDidMount = () => {
        this.uploadTarget = document.createElement("div");
        this.uploadTarget.id="uploadInput";
        document.body.appendChild(this.uploadTarget);
        this._render();
        const { ele } = this.props;
        const { x, y } = ele && ele.getBoundingClientRect();
        this.setState({top:y,left:x});
        document.addEventListener('scroll', this.handleScroll.bind(this));
    };
    componentDidUpdate() {
        this._render();
        
    }
    componentWillUnmount() {
        ReactDOM.unmountComponentAtNode(this.uploadTarget);
        document.body.removeChild(this.uploadTarget);
        document.removeEventListener('scroll', this.handleScroll.bind(this));
    }
    handleScroll =(event)=> {
        const {ele}=this.props;
        const { x, y, width, height } = ele && ele.getBoundingClientRect();
        const {left,top}=this.state;
        if(x !== left || top!==y){
            this.setState({top:y,left:x})
        }
    }
    
    onEnter(e){
        if(e.key==='Enter'){
            this.onUpdate()
        }
        if(e.key==='Escape'){
            this.onCancel()
        }
    }
    onChange(e){
        const {value,name}=e.target;
        this.setState({[name]:value});
    }
    onUpdate(){
        const {onChange,noNote,amountName}=this.props;
        if(noNote){
            const {amount}=this.state;
            let newAmount = !isEmpty(amount) ? amount : null;
            onChange({[amountName]:newAmount});
        }else{
            const {note,amount}=this.state;
            let newAmount = !isEmpty(amount) ? amount : null;
            onChange({note,amount:newAmount});
        }
        
        // this.props.onUpdate()
    }
    onCancel(){
        this.props.onCancel()
    }
    _render() {
        const { onChange, amountValue, noteValue,noNote, ele,isNote } = this.props;
        const { x, y, width, height } = ele && ele.getBoundingClientRect();
        const {top,left}=this.state;
        const newTtop = top+ height +20;
        const newLeft = left;
        const style={
            top:newTtop,
            left:newLeft
        }
        ReactDOM.render(
            <div className="update-container" style={style}>
                <div className="update-group">
                    <div className="update-group__input">
                        <input
                            autoFocus
                            value={this.state.amount}
                            name={'amount'}
                            onChange={this.onChange}
                            onKeyDown={this.onEnter}
                            type="number"
                            placeholder="New Amount"
                        />
                    </div>
                    {
                        noNote?null:
                        <div className="update-group__input">
                            <input
                                name={'note'}
                                onChange={this.onChange}
                                value={this.state.note}
                                onKeyDown={this.onEnter}
                                type="text"
                                autoFocus={isNote}
                                placeholder="Why...?"
                            />
                        </div>
                    }
                </div>
                <div className="update-group__btn">
                    <button 
                        onClick={(e)=>this.onCancel(e)}
                        className="update-close-btn">
                        {/* <MdClose /> */}
                        <span>Cancel</span>
                    </button>
                    <button 
                        onClick={(e)=>this.onUpdate(e)}
                        className="update-primary-btn">
                        <MdCheck />
                        <span>Update</span>
                    </button>
                </div>
            </div>,
            this.uploadTarget
        );
    }
    render() {
        return <noscript />;
    }
}

UpdateInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    amountValue: PropTypes.number.isRequired,
    // amountName: PropTypes.number.isRequired,
    noteValue: PropTypes.string,
    // noteName:PropTypes.string,
};

export default UpdateInput;
