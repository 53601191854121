import React, {useState, useEffect, useCallback} from "react";
import {Tabs, Tab} from "components/tabs/index.d";
import Breadcrumb from "../../common/breadcrumb";
import Table from "components/customTable";
import useDebounce from "helpers/customHooks/useDebounce";
import {postProjectDetailsList, getProjectType} from "./helpers/action";
import {useDispatch, useSelector} from "react-redux";
import Moment from "react-moment";
import moment from "moment";
import CommonFilter from "./commonFilter";
import CommonPagination from "components/common/pagination";

const allStatus = [
  {label: "All", value: ""},
  {label: "Active", value: "Active"},
  {label: "Expiring", value: "Expiring"},
  {label: "Expired", value: "Expired"},
];

const ProjectInformation = (props) => {
  const dispatch = useDispatch();

  const {taskTrackerPorjectDetailsList} = useSelector(
    (state: any) => state.taskTrackerDetailsReducer
  );
  const {taskTrackerProjectTypes} = useSelector(
    (state: any) => state.taskTrackerDetailsReducer
  );
  const {exportTaskTrackerPorjectDetailsList} = useSelector(
    (state: any) => state.taskTrackerDetailsReducer
  );

  const handleIndexChange = (num) => {
    setInitialData((prev) => ({
      ...prev,
      ["pageIndex"]: num,
    }));
  };

  let parma1 = props.location.param1;

  const initialFilters = {
    pageIndex: 0,
    pageSize: 10,
    projectStatus: parma1 ? {label: parma1, value: parma1} : "",
    projectName: "",
    clientType: 0,
    clientName: "",
    contractEndDate: "",
  };

  const [tabs, settabs] = useState([{id: 1, label: "Filter Options"}]);
  const [activeTabId, setActiveTabId] = useState();
  const [data, setData] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [initialData, setInitialData] = useState(initialFilters);

  const handleRowChange = (num) => {
    setInitialData((prev) => ({
      ...prev,
      ["pageSize"]: num,
    }));
  };

  const DayFormatter = ({value}) => {
    return (
      <div className="">
        <Moment format="YYYY-MM-DD">{value}</Moment>
      </div>
    );
  };

  const beforecolumns = [
    {
      Header: "Project Name",
      accessor: "ProjectName",
      width: 250,
    },
    {
      Header: "Client",
      accessor: "ClientName",
      width: 250,
    },
    {
      Header: "Project Type",
      accessor: "ClientTypeName",
      width: 120,
    },
    {
      Header: "Contract Start Date",
      accessor: "EventStartDateEng",
      width: 140,
      Cell: DayFormatter,
    },
    {
      Header: "Contract End Date",
      accessor: "EventEndDateEng",
      width: 140,
      Cell: DayFormatter,
    },
    {
      Header: "Status",
      accessor: "StatusName",
      width: 120,
    },
  ];

  const handleTabChange = (tabId) => {
    setActiveTabId(tabId);
  };

  const handleExcelExport = async () => {
    dispatch(postProjectDetailsList(initialData, initialData.projectStatus, true));
  };

  const getProjectDetails = () => {
    let allData = {
      ...initialData,
      contractEndDate:
        initialData.contractEndDate !== ""
          ? moment(initialData?.contractEndDate)?.format("YYYY-MM-DD")
          : "",
    };
    dispatch(postProjectDetailsList(allData, initialData.projectStatus));
  };

  const handleAutoText = (name, selected) => {
    setInitialData((prev) => ({...prev, [name]: selected}));
  };

  const handleAutoSelect = (name, selected) => {
    if (name === "clientType") {
      setInitialData({...initialData, [name]: selected ? selected : 0});
    } else {
      setInitialData({...initialData, [name]: selected ? selected : ""});
    }
  };

  const handleDateSelect = (name, value) => {
    let date = value ? moment(value).format("YYYY-MM-DD") : "";
    setInitialData((prev) => ({...prev, [name]: date}));
  };

  useDebounce(() => getProjectDetails(), 1000, [
    initialData.projectName,
    initialData.clientName,
  ]);

  useEffect(() => {
    dispatch(getProjectType());
  }, []);

  useEffect(() => {
    getProjectDetails();
  }, [
    initialData.projectStatus,
    initialData.clientType,
    initialData.contractEndDate,
    initialData.pageIndex,
    initialData.pageSize,
  ]);

  useEffect(() => {
    taskTrackerPorjectDetailsList && setData(taskTrackerPorjectDetailsList);
  }, [taskTrackerPorjectDetailsList]);

  useEffect(() => {
    if (taskTrackerProjectTypes) {
      let types = taskTrackerProjectTypes?.map((values) => {
        return {label: values.Text, value: parseInt(values.Value)};
      });

      setProjectTypes(types);
    }
  }, [taskTrackerProjectTypes]);

  return (
    <div className="common-container">
      <Breadcrumb name="Project Review" />

      <div className="employee-tabs">
        <Tabs activeTab={activeTabId} type="block" isCollapse={true}>
          {tabs.map((tab, index) => {
            return (
              <Tab
                label={tab.label}
                name={tab.id.toString()}
                id={tab.id}
                key={index}
                onTabClick={() => handleTabChange(tab.id)}
              >
                {tab.id === 1 && (
                  <CommonFilter
                    {...props}
                    handleAutoText={handleAutoText}
                    initialData={initialData}
                    handleAutoSelect={handleAutoSelect}
                    handleDateSelect={handleDateSelect}
                    projectTypes={projectTypes}
                    allStatus={allStatus}
                    data={data}
                    exportTaskTrackerPorjectDetailsList={
                      exportTaskTrackerPorjectDetailsList
                    }
                    handleExcelExport={handleExcelExport}
                  />
                )}
              </Tab>
            );
          })}
        </Tabs>
      </div>

      <Table isFixedWidth columns={beforecolumns} data={data} />
      {data && data.length > 0 && (
        <CommonPagination
          handleRowChange={handleRowChange}
          handleIndexChange={handleIndexChange}
          pageIndex={initialData.pageIndex}
          rowsPerPage={initialData.pageSize}
          total={data[0].TotalRows}
        />
      )}
    </div>
  );
};

export default ProjectInformation;
