import FormGroup from "components/form";
import {
  EDUCATION_QUALIFICATION_TYPE,
  JOB_PROFILE_PARAGRAPH_TYPE,
} from "constants/constants";
import isEmpty from "isEmpty";
import React, { Component } from "react";
import { GrCheckmark, GrClose, GrFormClose } from "react-icons/gr";
import { MdDelete, MdEdit } from "react-icons/md";
import Modal from "components/modal";
import InsertEduQualForm from "../common/insertEduQualForm";
import Button from 'components/button'

const profileBlockType = EDUCATION_QUALIFICATION_TYPE;
class EducationAndQualification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNameEdit: false,
      isComponentFormShow: false,
      subtitleToEdits: [],
      isEduQualFormShow: false,
      selectedType: "",
      defaultComponents: [],
      intialBlockData: {},
    };
  }
  componentDidMount() {
    const { defaultJobProfiles } = this.props;
    var stateCopy = this.props.state.profileBlocks.map((x) => {
      return { ...x };
    });
    const currentSection = stateCopy.find((x) => x.type === profileBlockType);
    let currentDefaultProfile = defaultJobProfiles.Sections.find(
      (x) => x.SectionTypeName === profileBlockType
    );
    let defaultComponents =
      currentDefaultProfile &&
      currentDefaultProfile.Components.map((x) => {
        return {
          Id: x.ComponentTypeRefId,
          Name: x.ComponentName,
          Type: x.ComponentTypeName,
        };
      });
    this.setState({ intialBlockData: currentSection, defaultComponents });
  }
 

 
  showcloseAddEduQualForm = (type) => {
    this.setState({ selectedType: type, isEduQualFormShow: true });
  };
  closeAddEduQualForm = () => {
    this.setState({ isEduQualFormShow: false });
  };

  handleRemoveSubSection = (type, id) => {
    this.props.handleRemoveSubSection(this.props.id, type, id);
  };
 
  render() {
    const {
      isNameEdit,
      subtitleToEdits,
      isComponentFormShow,
      selectedType,
      isEduQualFormShow,
      defaultComponents,
    } = this.state;
    const {
      handleInput,
      handleBlockMove,
      index,
      subComponent,
      isViewMode,
      handleRemoveSection,
    } = this.props;
    const { profileBlocks, errors, initialBlocks } = this.props.state;
    const currentSection = this.props.state.profileBlocks.find(
      (x) => x.type === profileBlockType
    );
    return (
      <div className="edu-quali profile-block">
        <div className="profile-block__header justify-start">
          <span className="profile-text">{currentSection.title}</span>
          <span className="profile-type">{currentSection.type}</span>
        </div>
        <div className="profile-block__body">
          {currentSection.components.map((x, i) => {
            return (
              <>
                <div className="form-row component-header">
                  <span className="subtitle">{x.subtitle}</span>
                  <span className="ml-md subtitle-type"> {x.type}</span>
                </div>
                {x.type !== JOB_PROFILE_PARAGRAPH_TYPE && (
                  <>
                    <div className="form-row">
                      <Button 
                        bg="primary-light"
                        title={x.type}
                        onClick={() => this.showcloseAddEduQualForm(x.type)}
                      />
                    </div>
                    {x[x.type.toLowerCase()].length > 0 && (
                      <div className="form-row flex-column items-start">
                        {x[x.type.toLowerCase()].map((p, i) => {
                          return (
                            <div className="flex edu-qual-li">
                              <span className="edu-qual-count flex justify-center text-md">
                                {i + 1}
                              </span>
                              <span className="pl-sm text-md edu-qual-text">
                                {p.name}
                              </span>
                              <GrFormClose
                                className="close-icon cursor-pointer ml-sm text-md"
                                onClick={() =>
                                  this.handleRemoveSubSection(x.type, p.id)
                                }
                              />
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </>
                )}
                <div className="form-row">
                  <FormGroup
                    formName="texteditor"
                    value={x.paragraph}
                    toolbarId={"eduAndQual" + i + 1}
                    placeholder={"Write something"}
                    name="paragraph"
                    onChange={(name, value) => {
                      this.props.handleComponentFieldChange(
                        profileBlockType,
                        x.id,
                        name,
                        value
                      );
                    }}
                    width="100%"
                  />
                </div>
              </>
            );
          })}
        </div>
        {isEduQualFormShow && (
          <Modal
            title={
              selectedType === "Education"
                ? "Add Education"
                : "Add Qualification"
            }
            open={isEduQualFormShow}
            onModalClose={() => this.closeAddEduQualForm()}
            type=""
            className="modal-insert-edu-qual"
          >
            <InsertEduQualForm
              insertType={selectedType}
              closeAddEduQualForm={this.closeAddEduQualForm}
              handleAddSubSections={this.props.handleAddSubSections}
              components={currentSection.components}
              sectionId={this.props.id}
            />
          </Modal>
        )}
      </div>
    );
  }
}

export default EducationAndQualification;
