import React, { Component } from "react";
import FormGroup from "components/form";
import { CALENDAR_MONTHS_NEPALI_ENG } from "components/datePicker/helpers";
import { connect } from "react-redux";
import { GetPayrollSlip, GetPayrollPeriod } from "../action";
import isEmpty from "isEmpty";
import SvgIcons from "components/svgIcons";
import Modal from "components/modal";
import SalarySlip from "./salarySlip";

export class Payslip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMonth: null,
      showTableModal: false,
    };
    // this.onSelectChange = this.onSelectChange.bind(this);
    this.onGetPaySlip = this.onGetPaySlip.bind(this);
  }
  async componentDidMount() {
    await this.props.GetPayrollPeriod();
  }

  onGetPaySlip = async (data) => {
    await this.props.GetPayrollSlip(data.Year, data.Month, data.AddOnId);
  };

  handleMonthClick = async (data) => {
    this.setState({ activeMonth: data, showTableModal: true });
    await this.onGetPaySlip(data);
  };

  handleModalClose = () => {
    this.setState({ showTableModal: false, activeMonth: null });
  };

  render() {
    let { activeMonth, showTableModal } = this.state;
    const { payrollPeriod, payslip } = this.props;
    
    const latestPayrollPeriod = payrollPeriod.value && payrollPeriod.value[0];

    return (
      <div className="segment">
        <div className="segment-payslip">
          <div className="yearly-payslip">
            <span className="title">Latest Payslip</span>
            {latestPayrollPeriod ? (
              <div className="yearly-payslip-section">
                <div
                  className="yearly-payslip-block latest"
                  onClick={() => this.handleMonthClick(latestPayrollPeriod)}
                >
                  {latestPayrollPeriod.IsAddOn && (
                    <SvgIcons
                      name="PlusCircle"
                      size={20}
                      className={"addon-icon"}
                      color={"#40a0ff"}
                    />
                  )}

                  <SvgIcons name="Pay" size={75} />
                  <span className="month-name">{latestPayrollPeriod.Name}</span>
                </div>
              </div>
            ) : (
              <span className="no_payslip">No latest payslip available.</span>
            )}
            <span className="title">Earlier Payslip</span>
            <div className="yearly-payslip-section ">
              {payrollPeriod.value && payrollPeriod.value.length > 1 ? (
                payrollPeriod.value.map((data, index) => {
                  if (index > 0) {
                    return (
                      <div
                        className="yearly-payslip-block earlier"
                        key={index}
                        onClick={() => this.handleMonthClick(data)}
                      >
                        {data.IsAddOn && (
                          <SvgIcons
                            name="PlusCircle"
                            size={16}
                            className={"addon-icon"}
                            color={"#40a0ff"}
                          />
                        )}

                        <SvgIcons name="Pay" size={60} />
                        <span className="month-name small-font">
                          {data.Name}
                        </span>
                      </div>
                    );
                  }
                })
              ) : (
                <span className="no_payslip">
                  No earlier payslip available.
                </span>
              )}
            </div>
          </div>

          {showTableModal && activeMonth && (
            <SalarySlip
              activeMonth={activeMonth}
              payslip={payslip}
              showTableModal={showTableModal}
              handleModalClose={this.handleModalClose}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  GetPayrollSlip,
  GetPayrollPeriod,
};

const mapStateToProps = (state) => ({
  payslip: state.employeeReducer.paySlip,
  payrollPeriod: state.selectReducer.payrollPeriod,
});

export default connect(mapStateToProps, mapDispatchToProps)(Payslip);
