import axios from "axios";

import {
  ADD_LOADING,
  REMOVE_LOADING,
} from "./types";
import shortid from "shortid";

export const addLoading = (data:any,type:string="normal") => (dispatch:Function) => {
  dispatch({
    type: ADD_LOADING,
    payload:data,
    loadingType:type
  });
};
