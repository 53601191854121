import React from "react";
import { FaTrash } from "react-icons/fa";
import { connect } from "react-redux";
import { MdAdd } from "react-icons/md";
import Avatar from "react-avatar";
import Form from "components/form";
import { getBaseAxios } from "helpers/constants";

class TeamAndContribution extends React.Component {
  constructor(props) {
    super(props);
  }

  addContributingMember = (option, key) => {
    this.props.addTeamMember(option, key);
  };

  handleAutoSelect = (event, value) => {
    this.props.handleTeamMember(event, value);
  };

  changeMemberEstHours = (name, value) => {
    this.props.changeMemberEstHours(name, value);
  };

  render() {
    const { contributingMembers } = this.props.data;
    const { membersByNameDll } = this.props;

    return (
      <div className="team_contribution">
        {/* <div className="bold_title">Who will be involved in the case</div> */}
        {contributingMembers &&
          contributingMembers.map((data, index) => {
            let amount = 0;
            if (data.estHours && data.hourlyRate) {
              amount = parseFloat(
                (data.hourlyRate / 60) * data.estHours
              ).toFixed(2);
            }
            return (
              <div key={index + data.key} className="team_contribution-row">
                <Form
                  formName="reactselect"
                  onChange={this.handleAutoSelect}
                  name={`team${data.key}`}
                  value={data || ""}
                  options={membersByNameDll}
                  placeholder="Select team member"
                  label="Who will be involved in the case"
                  width={"450px"}
                />
                <span className="team_contribution-row-avatar">
                  <Avatar
                    name={data.Fullname}
                    size={35}
                    round={true}
                    src={getBaseAxios() + data.image}
                  />
                </span>
                <Form
                  formName="hourMinute"
                  name={`estHours${data.key}`}
                  value={data.estHours}
                  onChange={this.changeMemberEstHours}
                  label="Estimated Hours"
                  width="130px"
                />

                <Form
                  formName="textinput"
                  name={`estHours${data.key}`}
                  value={data.hourlyRate}
                  onChange={this.props.changeMemberHourlyRate}
                  width="100px"
                  label="Rate"
                />

                <Form
                  disabled
                  formName="textinput"
                  name={`amount${data.key}`}
                  value={amount}
                  width="100px"
                  label="Amount"
                />
                {contributingMembers && contributingMembers.length > 1 && (
                  <span
                    onClick={() =>
                      this.addContributingMember("remove", data.key)
                    }
                    className="team_contribution-row-trash"
                  >
                    <FaTrash />
                  </span>
                )}
              </div>
            );
          })}
        <div className="add">
          <button
            onClick={() => this.addContributingMember("add")}
            className="btn btn-outline add-content"
          >
            <span className="add-content-icon">
              <MdAdd />
            </span>
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // employees: state.employees,
});

export default connect(mapStateToProps, null)(TeamAndContribution);
