import React from 'react'
import { useSelector } from 'react-redux';

function Absentism(props) {
    const { absent } = props;
    const hrDashboard = useSelector(state => state.hrReducer.hrDashboard );
    const {EmployeeAbsent}  = hrDashboard;

    return (
        <div className="absent">
            {
                hrDashboard && EmployeeAbsent ? 
                <ul className="absent-list">
                    {
                        EmployeeAbsent.map((item,index)=>{
                            return(
                                <li key={index + Math.random()} className="absent-list__item">
                                    <span className="absent-list__more">
                                        More than  
                                            <span className="bold-500">
                                                {item.AbsentDays} 
                                            </span> 
                                        Days
                                    </span>
                                    <span className="absent-list__employee">
                                        <span className="bold-500">
                                            <span className="text-blue"> {item.AbsentCount} </span>
                                        </span>
                                        employee
                                    </span>
                                </li>
                            )
                        })
                    }
                </ul>
                :
                <span className="no-found">No Absentism Found</span>
            }
        </div>
    )
}

export default Absentism
