import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import isEmpty from "isEmpty";
import { getTableView, getBoardLabel } from "../../../helpers/action";
import TaskTable from "./table";
import { TMStage } from "../../../helpers/enum";

function TableView(props) {
  const { boardId } = props;
  const { tableView } = useSelector((state: any) => state.taskReducer);
  const dispacth = useDispatch();
  const fetchData = async () => {
    //await dispacth(getTableView(boardId));
    // await dispacth(getBoardLabel(boardId));
  };
  useEffect(() => {
    if (!isEmpty(boardId)) {
      fetchData();
    }
  }, [boardId]);
  if (!isEmpty(tableView)) {
    return (
      <>
        <div className="task-todo">
          <TaskTable
            {...props}
            title={`Things To Do (${tableView.ThingsToDo.length})`}
            stage={TMStage.ThingsToDo}
            theme="things-to-do"
            tasks={tableView.ThingsToDo}
            enableAddTask={true}
          />
          <TaskTable
            {...props}
            title={`In Progress (${tableView.InProgress.length})`}
            stage={TMStage.InProgress}
            theme="in-progress"
            tasks={tableView.InProgress}
            enableAddTask={true}
          />
          <TaskTable
            {...props}
            title={`Completed (${tableView.Completed.length})`}
            stage={TMStage.Completed}
            theme="completed"
            tasks={tableView.Completed}
          />
          <TaskTable
            {...props}
            title={`On Hold (${tableView.OnHold.length})`}
            stage={TMStage.OnHold}
            theme="on-hold"
            tasks={tableView.OnHold}
          />
          <TaskTable
            {...props}
            title={`Cancelled (${tableView.Cancelled.length})`}
            stage={TMStage.Cancelled}
            theme="cancelled"
            tasks={tableView.Cancelled}
          />
        </div>
      </>
    );
  }
  return null;
}
const mapStateToProps = (state) => ({ board: state.taskReducer.board });
export default connect(mapStateToProps, {})(TableView);
