import React, {useEffect,useState} from 'react'
import Title from "../../common/title";
import Button from 'components/button';
import { Tabs, Tab } from "components/tabs/index.d";
import Filters from './filters'
import Tables from './tables';
import './style.scss'
import {getAllowanceRequest,postToSalary} from './helpers/action'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {BS2AD} from 'helpers/dateConvert';
import Modal from 'components/modal';
import AllowanceRequestForm from "scenes/employee/allowance/allowanceRequest";

const tabs = [
      { id: '-1', label: "All" },
      { id: '0', label: "Pending" },
      { id: '1', label: "Recommended" },
      { id: '2', label: "Approved" },
      { id: '3', label: "Rejected" },
      { id: '4', label: "Posted to pay" },
];

function Allowance() {
    const dispatch = useDispatch();

    const [activeTab,setActiveTab] = useState('-1');
    const [branches,setBranches] = useState('');
    const [open,setOpen] = useState(false);
    const [departments,setDepartments] = useState('');
    const [allowanceType,setAllowanceType] = useState('');
    const [startDate,setStartDate] = useState('');
    const [endDate,setEndDate] = useState('');
    const [employeeName,setEmployeeName] = useState('')
    const [pageIndex,setPageIndex] = useState(0);
    const [rowsPerPage,setRowsPerPage] = useState(10);
    const {allowanceRequest} = useSelector((state:any)=>state.allowanceReducer)
    
    useEffect(()=>{
        initialize()
    },[]);
    
    const initialize = async () => {
        let start = '';
        let end = '';
        if(startDate){
            start =BS2AD(startDate).formatedDate;
        }
        if(endDate){
            end =BS2AD(endDate).formatedDate;
        }
        await dispatch(getAllowanceRequest(
            pageIndex,
            rowsPerPage,
            activeTab,
            branches,
            departments,
            allowanceType,
            employeeName,
            start,
            end
        ))
    }

    const handleRowChange = async num => {
        setRowsPerPage(num);
    };
    
    const handleIndexChange = async num => {
        await setPageIndex(num);
    };

    useEffect(()=>{
        initialize()
    },[branches,departments,pageIndex,rowsPerPage,activeTab,allowanceType,employeeName,startDate,endDate]);

    const handleAutoSelect = (name, selected) => {
        if(name === 'branch') setBranches(selected);
        if(name === 'department') setDepartments(selected);
        if(name === 'allowanceType') setAllowanceType(selected);
        if(name === 'employeeName') setEmployeeName(selected);
        if(name === 'dateRange') {
            const { startDate, endDate } = selected;
            setStartDate(startDate);
            setEndDate(endDate)
        }
    };
    const onTabChange = (e,a) =>{
        setActiveTab(e);
    }

    const postToPay = (ids) =>{
        dispatch(postToSalary(ids,initialize));
        
    }
    return (
        <div style={{width: 1425,maxWidth:'calc(100vw - 70px)'}} className="allowance-req admin common-container">
            <Title name="Allowance Management" />
            <div className="space-between">
                <div className="tertiary-title">
                    Allowance Management
                </div>
                <Button onClick={() => setOpen(true)} title="Assign Allowance" bg="primary" />
                <Modal
                    title={"Assign Allowance"}
                    open={open}
                    onModalClose={() => setOpen(false)}
                    type=""
                    className="modal-allowance-request allowance-request-form"
                    >
                    <AllowanceRequestForm
                        isAssign={true}
                        closeForm={() => setOpen(false)}
                    />
                </Modal>
            </div>
            <div className="mt-md allowance-tabs">
                <Tabs noBackground>
                    {
                        tabs.map(item=>{
                            return <Tab 
                                        isActive={activeTab===item.id}
                                        onClick={onTabChange} 
                                        label={item.label} 
                                        name={item.id} 
                                    >
                                <div className="">
                                    <Filters 
                                        branches={branches}
                                        departments={departments}
                                        startDate={startDate}
                                        endDate={endDate}
                                        allowanceType={allowanceType}
                                        employeeName={employeeName}
                                        handleAutoSelect={handleAutoSelect}
                                    />
                                    <Tables 
                                        pageIndex={pageIndex}
                                        rowsPerPage={rowsPerPage}
                                        initialize={initialize}
                                        activeTab={activeTab}
                                        handleRowChange={handleRowChange}
                                        postToPay={postToPay}
                                        handleIndexChange={handleIndexChange}
                                        data={allowanceRequest} />
                                </div>
                            </Tab>
                        })
                    }
                </Tabs>
            </div>
        </div>
    )
}

export default Allowance
