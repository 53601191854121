import React,{Component} from 'react'
import './style.scss';
import FormGroup from 'components/form'
import Button from 'components/button'
import { connect } from "react-redux";
import { saveEmployeeHealthInfo,
  updateEmployeeHealthInfo, GetEmployeeHealthDetail } from '../action';
import { InputValidator } from "helpers/inputValidator";
import { DisplayClientSideMessage, GetBloodGroupList } from "actions/commonAction";
import isEmpty from "helpers/isEmpty";

const treatmentOptions = [
    {label:'Yes',value:'Yes'},
    {label:'No',value:'No'},
    
]

const bloodOptions = [
    {label:'A+', value:'1'},
    {label:'A-', value:'2'},
    {label:'AB+', value:'3'},
    {label:'AB-', value:'4'},
    {label:'B+', value:'5'},
    {label:'B-', value:'6'},
    {label:'O+', value:'7'},
    {label:'O-', value:'8'},
]

interface validatingField {
  blood: any,
  health_condition: string,
  e_contact_number: string,
  e_contact_name: string,
  e_contact_relation: string,
  treatment: string,
  doctor_phone?: string,
  hospital_phone?: string,
  doctor_name?: string,
  hospital_name?: string,
}
interface IAppProps {
  saveEmployeeHealthInfo: Function,
  updateEmployeeHealthInfo: Function,
  employeeHealthInfoSaved: Function,
  DisplayClientSideMessage: Function,
  EmployeeId: any,
  editView?: any,
  editViewData?: any,
  toggleEditView: any,
  GetEmployeeHealthDetail: any,
  GetBloodGroupList: any,
  bloodGroupList: any,
}
interface State {
  blood: any,
  errors: any,
  e_contact_number: any,
  e_contact_name: any,
  e_contact_relation: any,
  treatment: any,
  doctor_phone: any,
  hospital_phone: any,
  doctor_name: any,
  hospital_name: any,
  bloodGroupOptions: any
  editView: any,

}

class App extends React.Component<IAppProps, State> {
    state = {
      blood: {
        label: null,
        value: null,
      },
      errors: null,
      health_condition: null,
      e_contact_number: null,
      e_contact_name: null,
      e_contact_relation: null,
      treatment: null,
      doctor_phone: null,
      hospital_phone: null,
      doctor_name: null,
      hospital_name: null,
      bloodGroupOptions: null,
      editView: false,
    }

    async componentDidMount () {
      const { editView, editViewData, GetBloodGroupList } = this.props;
      await GetBloodGroupList();
      const { bloodGroupList } = this.props;
      if (editView && editViewData) {
        let blood = bloodGroupList.find(f => f.value == editViewData.BloodGroup);
        let data = {
          blood,
          health_condition: editViewData.SpecialHealthCondition,
          e_contact_number: parseInt(editViewData.EmergencyContactNumber),
          e_contact_name: editViewData.EmergencyContactName,
          e_contact_relation: editViewData.EmergencyContactRelation,
          treatment: editViewData.OngoingTreatment ? treatmentOptions[0].label : treatmentOptions[1].label,
          doctor_phone: parseInt(editViewData.DoctorNumber),
          hospital_phone: parseInt(editViewData.HospitalNumber),
          doctor_name: editViewData.DoctorName,
          hospital_name: editViewData.HospitalClinicName,

        }
        this.setState({ ...this.state, ...data,
          bloodGroupOptions: bloodGroupList,
          editView: true });
        // let data = {
        //   health_condition: 
        // }
      } else this.setState({ bloodGroupOptions: bloodGroupList });
  }
    // const [blood,setBlood] = useState<any>([]);
    // const [treatment,setTreatment] = useState<any>([]);
    handleFieldChange = (name: string, value: any) => {
      this.setState({
          [name]: value,
          errors: {
          ...this.state.errors,
          [name]: null,
          },
      } as Pick<State, keyof State>);
    };

    handleSave = async () => {
      const {
        blood, health_condition, e_contact_number, editView,
        e_contact_name, e_contact_relation, treatment,
        doctor_phone, hospital_phone, doctor_name, hospital_name,
      } = this.state;

      let fieldsToValidate : validatingField = {
        blood,health_condition, e_contact_number,
        e_contact_name, e_contact_relation, treatment,
      }
      
      if (treatment == treatmentOptions[0].value) {
        fieldsToValidate = {
          ...fieldsToValidate,
          doctor_phone,
          hospital_phone,
          doctor_name,
          hospital_name,
        }
      }
      let errors = await InputValidator(document, fieldsToValidate);
      if (isEmpty(errors)) {
        let healthData = {
          "healthId": editView ? this.props.editViewData?.HealthId : 0,
          "employeeId": this.props.EmployeeId,
          "bloodGroup": blood.value,
          "specialHealthCondition": health_condition,
          "emergencyContactNumber": e_contact_number.toString(),
          "emergencyContactName": e_contact_name,
          "emergencyContactRelation": e_contact_relation,
          "ongoingTreatment": treatment == treatmentOptions[0].label,
          "hospitalClinicName": treatment == treatmentOptions[0].label ? hospital_name : null,
          "hospitalNumber": treatment == treatmentOptions[0].label ? hospital_phone.toString() : null,
          "doctorName": treatment == treatmentOptions[0].label ? doctor_name : null,
          "doctorNumber": treatment == treatmentOptions[0].label ? doctor_phone.toString() : null,
        }
        const {
          toggleEditView, updateEmployeeHealthInfo,
          saveEmployeeHealthInfo, GetEmployeeHealthDetail,
          EmployeeId
        } = this.props;

        if (editView) await updateEmployeeHealthInfo(healthData);
        else await saveEmployeeHealthInfo(healthData);

        if (this.props.employeeHealthInfoSaved) {
          GetEmployeeHealthDetail(EmployeeId);
          toggleEditView(false);
        }
        
      } else {
        this.setState({ errors });
        let message = {
          MessageType: "Danger",
          Message: ["Please fill all the required fields."],
          Status: false,
        };
        this.props.DisplayClientSideMessage(message);
      }


    }

    render() {
        const {
          blood, health_condition, e_contact_number,
          e_contact_name, e_contact_relation, treatment,
          doctor_phone, hospital_phone, doctor_name, hospital_name,
          errors, bloodGroupOptions
        } = this.state;
      return(
          <div className="health-item">
              {/* <div className="tertiary-title">
                  <h3>Health Information</h3>
              </div> */}
              <div className="health-block">
                  <div className="health-block__row">
                      <div className="health-block__col health-col">
                          <FormGroup
                              formName="chipsinput"
                              name={"blood"}
                              alignment="horizontal"
                              chipsArray={bloodGroupOptions}
                              value={blood?.label || null}
                              onChange={(name,value)=> this.handleFieldChange(name, value)}
                              label="Blood Group"
                              badgeColor="green"
                              validators={["required"]}
                              error={errors && errors.blood}
                          />
                      </div>
                  </div>
                  <div className="health-block__row">
                      <div className="health-block__col">
                          <FormGroup 
                              formName="textarea"
                              label="Special Health Condition"
                              value={health_condition}
                              name="health_condition"
                              onChange={(name,value) => this.handleFieldChange(name, value)}
                              error={errors && errors.health_condition}
                              validators={["required"]}
                          />
                      </div>
                  </div>
                  <div className="health-block__row col-3">
                      <div className="health-block__col">
                          <FormGroup 
                              label="Emergency Contact Number"
                              value={e_contact_number}
                              name="e_contact_number"
                              onChange={(name,value) => this.handleFieldChange(name, value)}
                              error={errors && errors.e_contact_number}
                              validators={["phone"]}
                              type="number"
                          />
                      </div>
                      <div className="health-block__col">
                          <FormGroup 
                              label="Name"
                              value={e_contact_name}
                              name="e_contact_name"
                              onChange={(name,value) => this.handleFieldChange(name, value)}
                              error={errors && errors.e_contact_name}
                              validators={["required"]}
                          />
                      </div>
                      <div className="health-block__col">
                          <FormGroup 
                              label="Relation"
                              value={e_contact_relation}
                              name="e_contact_relation"
                              onChange={(name,value) => this.handleFieldChange(name, value)}
                              validators={["required"]}
                              error={errors && errors.e_contact_relation}
                          />
                      </div>
                  </div>
                  <div className="health-block__row">
                      <div className="health-block__col health-col">
                          <FormGroup
                              formName="chipsinput"
                              name={"treatment"}
                              alignment="horizontal"
                              chipsArray={treatmentOptions}
                              value={treatment}
                              onChange={(name,value) => this.handleFieldChange(name, value.value)}
                              label="Ongoing Treatment"
                              badgeColor="green"
                              validators={["required"]}
                              error={errors && errors.treatment}
                          />
                      </div>
                  </div>
                  {
                      treatment && treatment === treatmentOptions[0].value ? (
                          <>
                          <div className="health-block__row col-2">
                              <div className="health-block__col">
                              <FormGroup
                                      label="Hospital Name"
                                      value={hospital_name}
                                      name="hospital_name"
                                      onChange={(name,value) => this.handleFieldChange(name, value)}
                                      validators={["required"]}
                                      error={errors && errors.hospital_name}
                              />
                              </div>
                              <div className="health-block__col">
                                  <FormGroup 
                                      label="Doctor's Name"
                                      value={doctor_name}
                                      name="doctor_name"
                                      onChange={(name,value) => this.handleFieldChange(name, value)}
                                      validators={["required"]}
                                      error={errors && errors.doctor_name}
                                  />
                              </div>
                          </div>
                          <div className="health-block__row col-2">
                              <div className="health-block__col">
                                  <FormGroup 
                                      label="Hospital Phone"
                                      value={hospital_phone}
                                      name="hospital_phone"
                                      onChange={(name,value) => this.handleFieldChange(name, value)}
                                      validators={["phone"]}
                                      error={errors && errors.hospital_phone}
                                      type="number"
                                  />
                              </div>
                              <div className="health-block__col">
                                  <FormGroup 
                                      label="Doctor's Phone"
                                      value={doctor_phone}
                                      name="doctor_phone"
                                      onChange={(name,value) => this.handleFieldChange(name, value)}
                                      validators={["phone"]}
                                      error={errors && errors.doctor_phone}
                                      type="number"
                                  />
                              </div>
                          </div>
                      </>
                      ) : null
                  }
                  <div className="flex saveButton">
                    <Button 
                      bg="primary"
                      title="Save"
                      onClick={() => this.handleSave()}
                    />
                    <Button  
                      onClick={() => this.props.toggleEditView(false)}
                      bg="white"
                      title="Cancel"
                    />
                  </div>
              </div>
          </div>
      )
    }
}


const mapStateToProps = (state) => ({
  employeeHealthInfoSaved: state.employeeProfile.employeeHealthInfoSaved,
  bloodGroupList: state.commonReducer.bloodGroupList,
});
  
const mapDispatchToProps = {
  saveEmployeeHealthInfo,
  DisplayClientSideMessage,
  updateEmployeeHealthInfo,
  GetEmployeeHealthDetail,
  GetBloodGroupList,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
