import { TextareaAutosize } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import Modal from "components/modal";
import { ValidationComponent } from "helpers/getComponents";
import userFormValidation from "validations/useFormValidation";
import isEmpty from "isEmpty";
import { addDisciplinaryComments } from "../action";
import FormGroup from "components/form";

const validateDisciplinaryCommentForm = (values) => {
  let errors = {};
  if (!values.Comments) errors.Comments = "Required";
  return errors;
};

function AddCommentsForm({
  ActionId,
  closeCommentModal,
  isCommentModalShow,
  isEditPage,
  CaseId,
}) {
  let INITIAL_STATE;
  if (isEditPage) {
    // INITIAL_STATE = getEditObject(props);
  } else {
    INITIAL_STATE = {
      CaseId,
      ActionId,
      Comments: "",
    };
  }
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    clearValues,
    isSubmitting,
  } = userFormValidation(INITIAL_STATE, validateDisciplinaryCommentForm);

  const dispatch = useDispatch();
  if (isSubmitting && isEmpty(errors)) {
    //console.log(values);
    let data = {
      CaseId,
      DisciplinaryActionRefId: values.ActionId,
      Comments: values.Comments,
    };
    dispatch(addDisciplinaryComments(data, closeCommentModal));
  }

  const onChange = (name,value) => {
    //let { name, value } = e.target;
    handleChange(name, value);
  };

  return (
    <Modal
      title="Add Comments"
      open={isCommentModalShow}
      onModalClose={() => closeCommentModal()}
      type=""
      className="dsp-comment-modal"
    >
      <form>
        <div className="form-row">
          <FormGroup
            formName="textarea"
            name="Comments"
            cols={50}
            rows={6}
            value={values.Comments}
            onChange={onChange}
            //disabled={isViewMode}
          />
        </div>

        <div className="dsp-comment-footer">
          <button
            className="btn btn-del"
            // onClick={() => handleSettlementActive(false)}
            type="button"
            disabled={isSubmitting}
            onClick={() => closeCommentModal()}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={handleSubmit}
            type="submit"
            disabled={isSubmitting}
          >
            Add Comment
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default AddCommentsForm;
