import React, { Component } from "react";
import Table from "./table";
import Filter from "./filter";
import {
  GetBranches,
  GetDepartments,
  GetDesignations,
  GetJobStatus,
} from "actions/commonAction";
import { connect } from "react-redux";
import { GetEmployeeListForAdmin } from "../../employee/employeeList/action";
import moment from "moment";
import isEmpty from "isEmpty";
const MyContext = React.createContext();

const getDate = (name) => {
  var date = new Date();
  var firstDay = null;
  var lastDay = null;
  const label = !isEmpty(name) ? name.label : "";
  if (label === "This Month") {
    firstDay = moment(date).startOf("month").format("YYYY/MM/DD");
    lastDay = moment(date).endOf("month").format("YYYY/MM/DD");
  }
  if (label === "Last Month") {
    firstDay = moment(date)
      .subtract(1, "months")
      .startOf("month")
      .format("YYYY/MM/DD");
    lastDay = moment(date)
      .subtract(1, "months")
      .endOf("month")
      .format("YYYY/MM/DD");
  }
  if (label === "This Year") {
    firstDay = moment().startOf("year").format("YYYY/MM/DD");
    lastDay = moment().endOf("year").format("YYYY/MM/DD");
  }
  return { firstDay, lastDay };
};
export class Salary_Adjustment extends Component {
  static contextType = MyContext;
  constructor(props) {
    super(props);

    this.state = {
      leftValue: "",
      searchValue: "",
      activeBranch: "",
      activeDepartment: "",
      activePosition: "",
      activeStatus: "",
      income_deduct: "",
      position: "",
      region: "",
      approval_state: "",
      leave_type: "",
      rowsPerPage: 10,
      pageIndex: 0,
      incomeDeduction: "",
      region: "",
      changedByAmount: "",
      changedBy: "",
    };
    this.onChange = this.onChange.bind(this);
    this.handleRowChange = this.handleRowChange.bind(this);
    this.handleIndexChange = this.handleIndexChange.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  componentDidMount() {
    this.initialize();
    this.props.GetBranches();
    this.props.GetDepartments();
    this.props.GetDesignations();
    this.props.GetJobStatus();
  }
  // handleRowChange(num){
  //     this.setState({rowsPerPage:num})
  // }
  initialize = async () => {
    this.setState({ isLoading: true });

    const {
      pageIndex,
      rowsPerPage,
      searchValue,
      activeBranch,
      activeDepartment,
      activePosition,
      activeStatus,
      gender,
      maritalStatus,
      newJoinees,
      state,
      retired,
    } = this.state;
    const { firstDay: fromDate, lastDay: endDate } = getDate(newJoinees);
    const {
      firstDay: retirementStartDate,
      lastDay: retirementEndDate,
    } = getDate(retired);

    await this.props.GetEmployeeListForAdmin({
      pageIndex,
      rowsPerPage,
      searchValue,
      gender,
      maritalStatus,
      activeBranch,
      state,
      fromDate,
      endDate,
      retirementStartDate,
      retirementEndDate,
      activeDepartment,
      activePosition,
      activeStatus,
    });

    this.setState({ isLoading: false });
  };

  handleInput = (e) => {
    const { name, value } = e.target;
    
    this.setState({ [name]: value, pageIndex: 0 }, () => {
      this.initialize();
    });
  };

  handleAutoSelect = (name, selected) => {
    this.setState({ [name]: selected, pageIndex: 0 }, () => {
      this.initialize();
    });
  };

  handleRowChange = (num) => {
    this.setState({ rowsPerPage: num }, () => {
      this.initialize();
    });
  };

  handleIndexChange = (num) => {
    this.setState({ pageIndex: num }, () => {
      this.initialize();
    });
  };
  render() {
    const {
      employeeListForAdmin,
      branches,
      searchValue,
      departments,
      designations,
      statusList,
      history,
    } = this.props;
    const {
      actTab,
      pageIndex,
      attendancePageIndex,
      rowsPerPage,
      attendanceRowsPerPage,
    } = this.state;

    return (
      <div className="payroll-body">
        <Filter
          // pageIndex={index}
          // handleRowChange={this.handleRowChange}
          // handleIndexChange={this.handleIndexChange}
          // rowsPerPage={perPage}
          // activeTab = {this.state.actTab}
          parentState={this.state}
          branches={branches}
          searchValue={searchValue}
          departments={departments}
          designations={designations}
          statusList={statusList}
          handleAutoSelect={this.handleAutoSelect}
          handleInput={this.handleInput}
        />

        <Table
          pageIndex={this.state.pageIndex}
          rowsPerPage={this.state.rowsPerPage}
          handleRowChange={this.handleRowChange}
          handleIndexChange={this.handleIndexChange}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeListForAdmin: state.admin_EmployeeListReducer.employeeListForAdmin,
  branches: state.commonReducer.branchList,
  departments: state.commonReducer.departmentList,
  designations: state.commonReducer.designationList,
  statusList: state.commonReducer.jobStatusList,
});

const mapDispatchToProps = {
  GetEmployeeListForAdmin,
  GetBranches,
  GetDepartments,
  GetDesignations,
  GetJobStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(Salary_Adjustment);
