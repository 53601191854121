import React, { Component } from "react";
import Form from "components/form";
import ExportExcel from "components/excel/exportExcel";

const loanTypes = [
  {
    label: "Staff Home Loan",
    value: "PrimeHomeLoan",
  },
  {
    label: "Staff Vehicle Loan",
    value: "PrimeVehicleLoan",
  },
  {
    label: "Staff OverDraft",
    value: "PrimeStaffODLoan",
  },
  {
    label: "Staff Personal Loan",
    value: "PrimeStaffPersonalLoan",
  },
  {
    label: "Staff Advance Salary",
    value: "PrimeStaffAdvanceSalary",
  },
];

const StatusType = [
  {
    label: "Requested",
    value: 1,
  },
  {
    label: "HR Approved",
    value: 2,
  },
  {
    label: "Finance Approved",
    value: 3,
  },
  {
    label: "Rejected",
    value: 4,
  },
  {
    label: "Approved",
    value: 5,
  },
];

export class SearchAndFilter extends Component {
  render() {

    const {
      parentState: {
        EmployeeName,
        StartDate,
        EndDate,
        LoanType,
        LoanStatus,
        exportColumns,
        exportData,
        exportName
      },
      handleExcelExport,
      handleAutoSelect,
    } = this.props;
    return (
      <div className="filters">
        <div className="filters-grid">
          <Form
            formName="textinput"
            name="EmployeeName"
            value={EmployeeName}
            onChange={handleAutoSelect}
            placeholder="Search Employee"
          />
        </div>

        <div className="filters-grid">
          <Form
            formName="reactdaterange"
            startDate={StartDate}
            endDate={EndDate}
            onChange={this.props.handleDateRangePicker}
            placeholder="Date filter"
          />
        </div>
        <div className="filters-grid">
          <Form
            onChange={handleAutoSelect}
            name="LoanType"
            value={LoanType}
            options={loanTypes}
            formName="reactselect"
            placeholder="Search By Loan Type"
          />
        </div>
        <div className="filters-grid">
          <Form
            onChange={handleAutoSelect}
            name="LoanStatus"
            value={LoanStatus}
            options={StatusType}
            formName="reactselect"
            placeholder="Search By Loan Status"
          />
        </div>
        <div className="library">
          <ExportExcel
            onClick={handleExcelExport}
            fileName={exportName}
            columns={exportColumns}
            sheets={[{ sheetName: exportName, data: exportData }]}
            className="button button-subtle"
          />
        </div>
      </div>
    );
  }
}

export default SearchAndFilter;
