import { PDFViewer, StyleSheet } from "@react-pdf/renderer"; //https://react-pdf.org/components
import isEmpty from "isEmpty";
import React, { Component } from "react";
import { connect } from "react-redux";
import { GetTravelRequestReport } from "scenes/travel/common/travelInformation/actions";
import travelPdfTemplate from "scenes/travel/common/travelInformation/common/travelPdfTemplate";
import { GetSettlementRequestReport } from "scenes/travel/common/travelSettlement/actions";
import settlementPdfTemplate from "scenes/travel/common/travelSettlement/common/settlementPdfTemplate";
import Modal from "components/modal";
class ExportPdf extends Component {
  state = {
    numPages: null,
    pageNumber: 1,
    pdfSource: null,
  };

  async componentDidMount() {
    const { search } = this.props.location;

    const splitSearch = search.split("?")[1].split("/");
    let pdfSource = splitSearch[0];
    let id = splitSearch[1];
    this.setState({ pdfSource });
    if (pdfSource == "travelReport") {
      await this.props.GetTravelRequestReport(id);
    } else if (pdfSource == "settlementReport") {
      await this.props.GetSettlementRequestReport(id);
    }
  }

  render() {
    const { travelRequestReport, settlementReportPdf } = this.props;
    const { pdfSource } = this.state;
    const onClose = () => {
      this.props.history.goBack();
    };
    if (pdfSource == "travelReport" && !isEmpty(travelRequestReport)) {
      return (
        <Modal open={true} className="pdf-modal" onModalClose={onClose}>
          <PDFViewer style={styles.container}>
            {travelPdfTemplate(travelRequestReport)}
          </PDFViewer>
        </Modal>
      );
    } else if (
      pdfSource == "settlementReport" &&
      !isEmpty(settlementReportPdf)
    ) {
      return (
        <Modal open={true} className="pdf-modal" onModalClose={onClose}>
          <PDFViewer style={styles.container}>
            {settlementPdfTemplate(settlementReportPdf)}
          </PDFViewer>
        </Modal>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  travelRequestReport: state.travelInformationReducer.travelRequestReport,
  settlementReportPdf: state.travelSettlementReducer.settlementReportPdf,
});

const mapDispatchToProps = {
  GetTravelRequestReport,
  GetSettlementRequestReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportPdf);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "stretch",
    height: "100%",
    width: "100%",
  },
});
