import React, {useEffect, useState} from "react";
import FormGroup from "components/form";
import ExportExcel from "components/common/exportExcel";
import ImportExcel from "components/common/importExcel";

const templateExcelColumns = [
  {label: "EIN"},
  {label: "Name"},
  {label: "Effective Date Eng"},
  {label: "Direct Manager"},
  {label: "Matrix Manager"},
  {label: "Custom Manager"},
];

const responseDataHeaders = [
  "EmployeeRefId",
  "Name",
  "EffectiveDateEng",
  "DirectManager",
  "MatrixManager",
  "CustomManager",
];

const gridExcelColumns = [
  {label: "Employee Name", value: "Employee"},
  {label: "Direct Manager", value: "DirectManager"},
  {
    label: "Direct Manager Effective Date",
    value: "DirectManagerEffectiveDate",
  },
  {label: "Matrix Manager", value: "MatrixManager"},
  {
    label: "Matrix Manager Effective Date",
    value: "MatrixManagerEffectiveDate",
  },
  {label: "Custom Manager", value: "CustomManager"},
  {
    label: "Custom Manager Effective Date",
    value: "CustomManagerEffectiveDate",
  },
];

function Search(props) {
  const {searchEmployee, employeeManagersForExport} = props;

  let expectedExcelHeadersToImport = [];
  templateExcelColumns.map((tec) => {
    expectedExcelHeadersToImport.push(tec.label);
  });

  return (
    <div className="roles-search space-between">
      <FormGroup
        formName="searchInput"
        value={searchEmployee}
        type="search"
        name="searchEmployee"
        className="search"
        placeholder="search employee or manager"
        onChange={props.handleSearchEmployee}
      />
      <div className="flex flex-auto ">
        <ExportExcel
          fileName={"Template for Import"}
          columns={templateExcelColumns}
          sheets={[]}
          className="button button-primary-light"
          displayText="Export Template"
        />
        <ImportExcel
          responseDataHeaders={responseDataHeaders}
          expectedExcelHeadersToImport={expectedExcelHeadersToImport}
          getResponse={props.handleImportedExcel}
          getMessage={props.displayMessage}
        />

        <ExportExcel
          onClick={props.handleExportGrid}
          fileName={"Managers"}
          columns={gridExcelColumns}
          sheets={employeeManagersForExport}
          className="button button-primary-light"
        />
      </div>
    </div>
  );
}

export default Search;
