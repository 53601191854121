import {
  GET_REJECT_REASONS,
  GET_REJECT_REASONS_LOADING,
  SAVE_CANDIDATE_REJECT_LOADING,
} from "store/types";
import { api, get, post, deletion } from "networkService";
import { toastMessage } from "actions/validateAction";

export const GetRejectReasons = () => async (dispatch) => {
  dispatch({ type: GET_REJECT_REASONS_LOADING, payload: true });
  const res: any = await get(api.recruitment.reject.reasons, dispatch, {});
  if (res.Status) dispatch({ type: GET_REJECT_REASONS, payload: res.Data });
};

export const SaveCandidateReject = (param, onSuccess) => async (dispatch) => {
  try {
    if (param.reasons.length === 0) {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Please selecte atleast one reason",
        },
      };
      toastMessage(dispatch, response);
      return;
    }
    dispatch({ type: SAVE_CANDIDATE_REJECT_LOADING, payload: true });
    const res: any = await post(api.recruitment.reject.save, dispatch, param);
    dispatch({ type: SAVE_CANDIDATE_REJECT_LOADING, payload: false });
    onSuccess && onSuccess();
  } catch (ex) {
    console.log(ex);
    dispatch({ type: SAVE_CANDIDATE_REJECT_LOADING, payload: false });
  }
};
