import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Form from "components/form";
import { saveLoanApprovers, deleteLoanApprover, getQualifiedApprovers } from './helpers/actions';
import {
  GetEmployeeList,
  DisplayClientSideMessage
} from "actions/commonAction";
import isEmpty from "isEmpty";
import EmployeeSearch from "components/common/employeeSearch";

const approverNumbers = [4, 4, 4];

class LoanSettings extends React.Component<any, any> {

  constructor(props) {
    super(props)
    this.state = {
      hrApprover: {},
      finApprover: {},
      credApprover: {},
      initial: true,
    }
  }

  // const [hrApprover, setHrApprover] = useState({});
  // const [finApprover, setFinApprover] = useState({});
  // const [initial, setInitial] = useState(true);
  // const [, setDisableButton] = useState(false);

  handleAutoSelect = async (name, value) => {
    let { hrApprover, initial } = this.state;
    let hrApproverlist = { ...hrApprover };
    let selectedEmp = hrApproverlist[name];
    if (!value) {
      debugger
      if (!initial && selectedEmp) {
        selectedEmp = {
          ...selectedEmp,
          isHR: 1,
        }
        await this.props.deleteLoanApprover(selectedEmp);
        const { adminLoanApproverDeleted, DisplayClientSideMessage } = this.props;
        if (adminLoanApproverDeleted)
          delete hrApproverlist[name];
        else {
          DisplayClientSideMessage("Can't Delete, Employee has Assigned loans")
        }
      } else
        delete hrApproverlist[name];
    } else {
      if (!selectedEmp) {
        hrApproverlist[name] = value;
      } else {
        await this.props.deleteLoanApprover(selectedEmp);
        const { adminLoanApproverDeleted, DisplayClientSideMessage } = this.props;
        if (adminLoanApproverDeleted)
          hrApproverlist[name] = value;
        else {
          DisplayClientSideMessage("Can't Delete, Employee has Assigned loans")
          hrApproverlist[name] = selectedEmp;
        }

      }
    }
    this.setState({ hrApprover: hrApproverlist });
    // setHrApprover(hrApproverlist);
  };

  handleAutoFinanceSelect = async (name, value) => {
    let { finApprover, initial } = this.state;
    let finApproverlist = { ...finApprover };
    let selectedEmp = finApproverlist[name];
    // finApproverlist[name] = value;
    if (!value) {
      if (!initial && selectedEmp) {
        selectedEmp = {
          ...selectedEmp,
          isHR: 3
        }
        await this.props.deleteLoanApprover(selectedEmp);
        const { adminLoanApproverDeleted, DisplayClientSideMessage } = this.props;
        if (adminLoanApproverDeleted)
          delete finApproverlist[name];
        else {
          DisplayClientSideMessage("Can't Delete, Employee has Assigned loans")
        }
      } else
        delete finApproverlist[name];

    } else {
      if (!selectedEmp) {
        finApproverlist[name] = value;
      } else {
        await this.props.deleteLoanApprover(selectedEmp);
        const { adminLoanApproverDeleted, DisplayClientSideMessage } = this.props;
        if (adminLoanApproverDeleted)
          finApproverlist[name] = value;
        else {
          DisplayClientSideMessage("Can't Delete, Employee has Assigned loans")
          finApproverlist[name] = selectedEmp;
        }
      }
    }
    this.setState({ finApprover: finApproverlist })
  };

  handleAutoCreditSelect = async (name, value) => {
    let { credApprover, initial } = this.state;
    let credApproverlist = { ...credApprover };
    let selectedEmp = credApproverlist[name];
    // credApproverlist[name] = value;
    if (!value) {
      if (!initial && selectedEmp) {
        selectedEmp = {
          ...selectedEmp,
          isHR: 2
        }
        await this.props.deleteLoanApprover(selectedEmp);
        const { adminLoanApproverDeleted, DisplayClientSideMessage } = this.props;
        if (adminLoanApproverDeleted)
          delete credApproverlist[name];
        else {
          DisplayClientSideMessage("Can't Delete, Employee has Assigned loans")
        }
      } else
        delete credApproverlist[name];

    } else {
      if (!selectedEmp) {
        credApproverlist[name] = value;
      } else {
        await this.props.deleteLoanApprover(selectedEmp);
        const { adminLoanApproverDeleted, DisplayClientSideMessage } = this.props;
        if (adminLoanApproverDeleted)
          credApproverlist[name] = value;
        else {
          DisplayClientSideMessage("Can't Delete, Employee has Assigned loans")
          credApproverlist[name] = selectedEmp;
        }
      }
    }
    this.setState({ credApprover: credApproverlist })
  };

  getLoanApprovers = async () => {
    await this.props.GetEmployeeList();
    await this.props.getQualifiedApprovers();
    const { adminLoanApproverList, employeeListSelect } = this.props;
    if (!isEmpty(adminLoanApproverList)) {
      let newHRApprovalList = {};
      let newFinApprovalList = {};
      let newCredApprovalList = {};
      let hrindex = 0;
      let finindex = 0;
      let credindex = 0;
      await adminLoanApproverList.forEach((app, i) => {
        if (app.Role == 1) {
          newHRApprovalList[`hrApprover${hrindex}`] = app;
          hrindex += 1;
        }
      });
      await adminLoanApproverList.forEach((app, i) => {
        if (app.Role == 3) {
          newFinApprovalList[`finApprover${finindex}`] = app;
          finindex += 1;
        }
      });
      await adminLoanApproverList.forEach((app, i) => {
        if (app.Role == 2) {
          newCredApprovalList[`credApprover${credindex}`] = app;
          credindex += 1;
        }
      });
      this.setState({
        hrApprover: newHRApprovalList, finApprover: newFinApprovalList,
        credApprover: newCredApprovalList, initial: false
      });
    } else
      this.setState({ initial: true });

  }


  async componentDidMount() {

    await this.getLoanApprovers();
  }

  doesArrayHaveDuplicates = array => {
    return array.length !== new Set(array).size
  }

  saveApprovers = () => {
    let hrApproverList = [];
    let finApproverList = [];
    let selectedIdList = [];
    let credApproverList = [];
    const { hrApprover, finApprover, credApprover } = this.state;


    if (isEmpty(hrApprover) || isEmpty(finApprover)) {
      this.props.DisplayClientSideMessage("At least one HR and Credit Administration Department/Finance Department required");
      return;
    } else {

      for (var key of Object.keys(hrApprover)) {
        if (!isEmpty(hrApprover[key])) {
          selectedIdList.push(hrApprover[key].value);
          hrApproverList.push({
            Id: 0,
            EmployeeId: hrApprover[key].value,
            IsHR: true,
            Role: 1, 
          });
        }
      }
      for (var key of Object.keys(finApprover)) {
        if (!isEmpty(finApprover[key])) {
          selectedIdList.push(finApprover[key].value);
          finApproverList.push({
            Id: 0,
            EmployeeId: finApprover[key].value,
            Role: 3,
          })
        }
      }
      for (var key of Object.keys(credApprover)) {
        if (!isEmpty(credApprover[key])) {
          selectedIdList.push(credApprover[key].value);
          credApproverList.push({
            Id: 0,
            EmployeeId: credApprover[key].value,
            Role: 2,
          })
        }
      }
      if (this.doesArrayHaveDuplicates(selectedIdList)) {
        this.props.DisplayClientSideMessage("Please select different person for each approvals");
        return
      } else {
        let submitData = [...hrApproverList, ...finApproverList, ...credApproverList];
        this.props.saveLoanApprovers(submitData);
      }
    }
  }

  render() {
    const { hrApprover, finApprover, credApprover } = this.state
    console.log("credApprover", credApprover, finApprover, hrApprover);
    return (
      <div className="">
        <div className="form-row mb-bg ml-md mt-md flex">
          <div>
            <label className="approval-label">Human Resource Department</label>
            {[...Array(approverNumbers[0])].map((hrAppr, i) => {
              return <div className={i == 0 ? "approval mt-sm" : "approval"}>
                <span className="approval_index">{i + 1}</span>
                <EmployeeSearch
                  onChange={this.handleAutoSelect}
                  value={hrApprover[`hrApprover${i}`]}
                  name={`hrApprover${i}`}
                  label={null}
                // width="220px"
                />
                {/* <Form
                  formName="reactselect"
                  name={`hrApprover${i}`}
                  value={hrApprover[`hrApprover${i}`]}
                  options={this.props.employeeListSelect}
                  onChange={(name, value) => this.handleAutoSelect(name, value)}
                  placeholder="Select..."
                  width="220px"
                /> */}
              </div>
            })}

          </div>
          <div className="ml-bg">
            <label className="approval-label">Credit Administration Department</label>
            {[...Array(approverNumbers[2])].map((financearr, i) => {
              return <div className="approval mt-sm">
                <span className="approval_index">{i + 1}</span>
                <EmployeeSearch
                  onChange={this.handleAutoCreditSelect}
                  value={credApprover[`credApprover${i}`]}
                  name={`credApprover${i}`}
                  label={null}
                // width="220px"
                />
                {/* <Form
                  formName="reactselect"
                  name={`finApprover${i}`}
                  options={this.props.employeeListSelect}
                  value={finApprover[`finApprover${i}`]}
                  onChange={(name, value) => this.handleAutoFinanceSelect(name, value)}
                  placeholder="Select..."
                  width="220px"
                /> */}
              </div>
            })}
          </div>
          <div className="ml-bg">
            <label className="approval-label">Finance Administration Department</label>
            {[...Array(approverNumbers[1])].map((financearr, i) => {
              return <div className="approval mt-sm">
                <span className="approval_index">{i + 1}</span>
                <EmployeeSearch
                  onChange={this.handleAutoFinanceSelect}
                  value={finApprover[`finApprover${i}`]}
                  name={`finApprover${i}`}
                  label={null}
                // width="220px"
                />
                {/* <Form
                  formName="reactselect"
                  name={`finApprover${i}`}
                  options={this.props.employeeListSelect}
                  value={finApprover[`finApprover${i}`]}
                  onChange={(name, value) => this.handleAutoFinanceSelect(name, value)}
                  placeholder="Select..."
                  width="220px"
                /> */}
              </div>
            })}
          </div>
        </div>
        <div className="travel-settings-footer">
          <button
            onClick={() => this.saveApprovers()}
            className="btn btn-primary">Save</button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeListSelect: state.commonReducer.employeeListSelect,
  adminLoanApproverList: state.settingsReducer.adminLoanApproverList,
  adminLoanApproverDeleted: state.settingsReducer.adminLoanApproverDeleted,
});
const mapDispatchToProps = {
  getQualifiedApprovers,
  GetEmployeeList,
  saveLoanApprovers,
  DisplayClientSideMessage,
  deleteLoanApprover,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoanSettings);
