import isEmpty from "isEmpty";
import {
  EmailRegexValidaion,
  PhoneNumberRegexValidation
} from "helpers/regex";
import { Mandatory } from "./enum";

export const validateJobApply = async data => {
  let errors = {};
  const personalInformationValidateField = [
    "cropedPhoto",
    "honorificType",
    "firstName",
    "lastName",
    "mobileNumber",
    "email",
    "nationality",
    "dob"
  ];
  const addressValidateField = [
    "permanentProvience",
    "permanentDistrict",
    "permanentLocalBody",
    "permanentWard",
    "permanentLocality",
    "correspondingProvience",
    "correspondingDistrict",
    "correspondingLocalBody",
    "correspondingWard",
    "correspondingLocality"
  ];

  const identificationInformationValidateField = [
    "documentTypeId",
    "havePassport",
    "drivingLicenseTypeId"
  ];

  const familyInformationValidateField = [
    "fatherName",
    "motherName",
    "grandFatherName",
    "maritalStatusId"
  ];

  const blocks = [
    "educations",
    "trainings",
    "workAndExperiences",
    "references"
  ];

  Promise.all(
    personalInformationValidateField.map(x => {
      if (!data.personalInformation[x]) {
        errors[x] = "required";
      } else if (x == "email") {
        let isEmailValid = EmailRegexValidaion(data.personalInformation.email);
        if (!isEmailValid) {
          errors[x] = "Invalid";
        }
      }
    })
  );

  Promise.all(
    addressValidateField.map(x => {
      if (!data.address[x]) {
        errors[x] = "required";
      }
      // else if(x=="permanentWard"){
      //   if(data.address[x])
      //   errors[x]
      // }
    })
  );

  if (data.blockRequiredType["identificationInformation"] == Mandatory) {
    Promise.all(
      identificationInformationValidateField.map(x => {
        if (!data.identificationInformation[x]) {
          errors[x] = "required";
        }
      })
    );
  }

  if (data.blockRequiredType["familyInformation"] == Mandatory) {
    Promise.all(
      familyInformationValidateField.map(x => {
        if (!data.familyInformation[x]) {
          errors[x] = "required";
        }
      })
    );
  }

  if (
    data.blockRequiredType["coverLetterFile"] == Mandatory &&
    !data.fileCollection.coverLetterFile
  ) {
    errors["coverLetterFile"] = "required";
  }

  if (
    data.blockRequiredType["cvFile"] == Mandatory &&
    !data.fileCollection.cvFile
  ) {
    errors["cvFile"] = "required";
  }

  if (
    data.blockRequiredType["identificationInformation"] == Mandatory &&
    !data.fileCollection.identificationFile
  ) {
    errors["identificationFile"] = "required";
  }

  Promise.all(
    blocks.map(x => {
      if (
        data.blockRequiredType[x] == Mandatory &&
        (isEmpty(data.blockData[x]) || data.blockData[x].length <= 0)
      ) {
        errors[x] = "required";
      }
    })
  );
  return errors;
};

export const validateEducation = async data => {
  let errors = {};
  const fields = [
    "degreeName",
    "qualificationLevel",
    "institution",
    "country",
    "university",
    "year",
    "grade",
    "majorSubject",
    "file"
  ];

  Promise.all(
    fields.map(x => {
      if (!data[x]) {
        errors[x] = "required";
      }
    })
  );

  return errors;
};

export const validateTraining = async data => {
  let errors = {};
  const fields = ["trainingName", "institution", "country", "year", "file"];

  Promise.all(
    fields.map(x => {
      if (!data[x]) {
        errors[x] = "required";
      }
    })
  );

  return errors;
};

export const validateWorkAndExperience = async data => {
  let errors = {};
  const fields = [
    "organization",
    "country",
    "place",
    "fromYear",
    "toYear",
    "position",
    "responsibilities"
  ];

  Promise.all(
    fields.map(x => {
      if (!data[x]) {
        errors[x] = "required";
      }
    })
  );
  return errors;
};

export const validateReferences = async data => {
  let errors = {};
  const fields = ["name", "organization", "position", "phone", "email"];

  Promise.all(
    fields.map(x => {
      if (!data[x]) {
        errors[x] = "required";
      } else if (x == "email") {
        let isEmailValid = EmailRegexValidaion(data.email);
        if (!isEmailValid) {
          errors[x] = "Invalid";
        }
      }
      // else if (x == "phone") {
      //   let isPhoneValid = PhoneNumberRegexValidation(data.phone);

      //   if (!isPhoneValid) {
      //     errors[x] = "Invalid";
      //   }
      // }
    })
  );

  return errors;
};
