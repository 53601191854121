import React from "react";
import {Link} from "react-router-dom";

function ProjectReview({ProjectReview}) {
  return (
    <div className="new-dashboard mt-md">
      <div className="activity-title">Project Review</div>

      <div className="new-dashboard-group">
        <div className="new-dashboard-project_review">
          <div className="new-dashboard-project_review-top">PROJECTS</div>
          <div className="new-dashboard-project_review-body">
            <div className="new-dashboard-project_review-body-block">
              <Link
                to={{
                  pathname: `/task_tracker/projectReview`,
                  param1: "Active",
                }}
                className="count"
              >
                {ProjectReview?.ActiveProjectsCount || 0}
              </Link>
              <span className="count_text">active</span>
            </div>

            <div className="new-dashboard-project_review-body-block">
              {/* <span className="count">{ProjectReview?.ExpiringProjectsCount || 0}</span> */}

              <Link
                to={{
                  pathname: `/task_tracker/projectReview`,
                  param1: "Expiring",
                }}
                className="count"
              >
                {ProjectReview?.ExpiringProjectsCount || 0}
              </Link>
              <span className="count_text">expiring</span>
            </div>

            <div className="new-dashboard-project_review-body-block">
              {/* <span className="count">{ProjectReview?.ExpiredProjectsCount || 0}</span> */}
              <Link
                to={{
                  pathname: `/task_tracker/projectReview`,
                  param1: "Expired",
                }}
                className="count"
              >
                {ProjectReview?.ExpiredProjectsCount || 0}
              </Link>
              <span className="count_text expired">expired</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectReview;
