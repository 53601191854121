import React, { useState, useEffect, useRef } from "react";
import useOutsideClick from "helpers/customHooks/outsideClickHook";
import Icons from "components/icons";
import { menuProps, menuNavProps } from "../../../types";
import classnames from "classnames";
import isEmpty from "isEmpty";
import Button from "components/button";
import FormGroup from "components/form";

import { useSelector, useDispatch } from "react-redux";
import { CSSTransition } from "react-transition-group";
import MenuPopup from "./menuPopup";

const NavItem = ({
  title,
  icon,
  count,
  isView,
  onClick,
  active,
  isActive,
  children,
  popupTitle,
  menuPopupClassName,
}: menuNavProps) => {
  const getClass = (type: string): string =>
    isView ? "task-view__" + type : "task-menu__" + type;
  const [openMenu, setOpenMenu] = useState(false);

  const menuClass = classnames({
    [getClass("item")]: true,
    active: isActive || active === title,
  });

  const handleTitleClick = () => {
    if (children) {
      setOpenMenu(true);
    }

    onClick && onClick(title);
  };

  return (
    <li className={menuClass} onClick={handleTitleClick}>
      <span className={getClass("icon")}>
        <Icons justSVG name={icon} />
      </span>
      <span className={getClass("title")}>{title}</span>
      {!isEmpty(count) ? (
        <span className={getClass("badge")}>
          <span className="badge badge-primary sm">{count}</span>
        </span>
      ) : null}

      {children ? (
        <MenuPopup
          className={menuPopupClassName}
          popupTitle={popupTitle}
          open={openMenu}
          onChangeOpen={setOpenMenu}
        >
          {children}
        </MenuPopup>
      ) : null}
    </li>
  );
};

export default NavItem;
