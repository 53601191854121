import React, { useState,useEffect } from 'react';
import Modal from 'components/modal'
import AuthorizedModal from './authorizedModal'

function NewUserGroup() {
    const [open,setOpen] = useState(false);
    const onModalClose = () =>{
        setOpen(false)
    }
    return (
        <div className="admin-title space-between">
            <h1>Authorised User Groups</h1>
            <button 
                onClick={()=>setOpen(true)}
                className="button button-primary-light">New UserGroup</button>
            <Modal
                open={open}
                onModalClose={onModalClose}
                title="Authorised User Groups"
                className="roles-modal authorized-modal"
            >
                <AuthorizedModal close={onModalClose} />
            </Modal>
        </div>
    )
}

export default NewUserGroup