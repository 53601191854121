import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { taskUpdateDetails } from "scenes/tasks/helpers/action";
import AvatarGroup from "scenes/tasks/board/taskBody/tableView/table/component/avatarGroup";

const TeamCell = (props) => {
  const { task } = props;
  const ref = useRef(null);
  const [taskOpen, setTaskOpen] = useState(false);
  useEffect(() => {
    if (task && task.TeamMembers) {
      setSelectedMembers(task.TeamMembers);
    }
  }, [task]);

  const [selectedMembers, setSelectedMembers] = React.useState([]);
  return (
    <div className="taskBlock-section taskBlock-section-team">
      {/* <AvatarGroup appearance="stack" data={data} maxCount={3} size="small" /> */}
      <AvatarGroup
        maxCount={2}
        selectedMembers={selectedMembers}
        setSelectedMembers={setSelectedMembers}
        {...props}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({ auth: state.auth });
const mapDispatchToProps = {
  taskUpdateDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamCell);
