import React, { useState } from "react";
import { menuProps, menuNavProps } from "../../../types";
import classnames from "classnames";

import { useSelector, useDispatch } from "react-redux";
import { CSSTransition } from "react-transition-group";
const MenuNavBar = ({ children, isView }: menuProps) => {
  const menuClasses = classnames({
    "task-menu__nav": !isView,
    "task-view__nav": isView,
  });
  const menuListClasses = classnames({
    "task-menu__list": !isView,
    "task-view__list": isView,
  });
  return (
    <div className={menuClasses}>
      <ul className={menuListClasses}>{children}</ul>
    </div>
  );
};
export default MenuNavBar;
