import React, { Component } from "react";
import PositionalForm from "./positionalForm";
import QualificationForm from "./qualificationForm";
import JobDescription from "./jobDescription";
import Tags from "./tags";
import { Consumer } from "../helpers/context";
import { getSettingWiseDate } from "helpers/getSettingWiseDate";

export class Position_Details extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      postData: {
        positionDetails: {
          InternalJobTitle: "",
          internalID: "",
          externalJobTitle: "",
          level: [],
          StartDateEng: null,
          EndDateEng: null,
          division: [],
          anywhere: false,
          donot: false,
          branch: [],
          jobType: [],
          category: [],
          payTime: [],
          payFrom: "",
          payTo: "",
          openings: "",
          pay: null,
        },
        desiredQualification: {
          educationLevel: [],
          experience: [],
          isExperience: false,
        },
        jobDescription: { description: "" },
        tags: [],
      },
    };
    this.onChange = this.onChange.bind(this);
  }
  // onChange(title, name, value) {
  //     this.setState(prevState => ({
  //         postData: {
  //             ...prevState.postData,
  //             [title]: {
  //                 ...prevState.postData[title],
  //                 [name]: value
  //             }
  //         }
  //     }));
  //     const {dispatch} = this.props;
  //     dispatch({type:'onPositionChange',payload:{name,value}})
  // }
  onChange(name, value) {
    const { dispatch } = this.props;
    // if (name == 'IsPayShow')
    // value = !value;
    console.log("onPositionChange", name, value);
    dispatch({ type: "onPositionChange", payload: { name, value } });
  }
  render() {
    const {
      positionDetails,
      desiredQualification,
      jobDescription,
      tags,
    } = this.state.postData;
    const {
      jobCreate: { RecruitmentPositionDetailDto },
      errors,
    } = this.props;
    return (
      <div>
        <PositionalForm
          data={RecruitmentPositionDetailDto}
          onChange={this.onChange}
          errors={errors}
        />
        <QualificationForm
          data={RecruitmentPositionDetailDto}
          onChange={this.onChange}
          errors={errors}
        />
        <JobDescription
          data={RecruitmentPositionDetailDto.JobDescription}
          onChange={this.onChange}
          errors={errors}
        />
        <Tags
          data={RecruitmentPositionDetailDto.Tags}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

export default (props) => (
  <Consumer>
    {(value) => {
      const { jobCreate, dispatch } = value;
      // const {jobCreate:{RecruitmentPositionDetailDto},dispatch}= value;
      return (
        <Position_Details
          dispatch={dispatch}
          jobCreate={jobCreate}
          errors={props.errors}
        />
      );
    }}
  </Consumer>
);
