import React, { Component } from "react";
import { MdSettings } from "react-icons/md";
import drag from "assets/images/icons/ellipse.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import shortid from "shortid";
import EmployeeList from "./employeeList";
import Pending from "./pending";
import PayChanges from "./payChanges";
import PieCharts from "./pieCharts";
import SomeComponent from "./someComponent";
import isEmpty from 'isEmpty'


const newJoins = {
  employees: [
    {
      name: "Jhumpa Lehari",
      position: "Operations Incharge",
      branch: "Hetauda Operations",
    },
    {
      name: "Jhumpa Lehari",
      position: "Operations Incharge",
      branch: "Hetauda Operations",
    },
    {
      name: "Jhumpa Lehari",
      position: "Operations Incharge",
      branch: "Hetauda Operations",
    },
    {
      name: "Jhumpa Lehari",
      position: "Operations Incharge",
      branch: "Hetauda Operations",
    },
  ],
};
const retirement = {
  employees: [
    {
      name: "Jhumpa Lehari",
      position: "Operations Incharge",
      branch: "Hetauda Operations",
    },
    {
      name: "Jhumpa Lehari",
      position: "Operations Incharge",
      branch: "Hetauda Operations",
    },
  ],
};
const data = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];
const paychanges = [
  { title: "Pay revisions", num: 3, pay: [-59000, -2000] },
  { title: "New Join", num: 1, pay: [-7000, -12000] },
  { title: "Revisions", num: 1, pay: [13000] },
];

const pending = [
  { num: 1, name: "Leave" },
  { num: 1, name: "Allowance" },
  { num: 1, name: "Overtime" },
  { num: 1, name: "Data Change" },
  { num: 1, name: "Pay Info Change" },
];

const pending2 = [
  { num: 3, name: "Stop payment" },
  { num: "-", name: "Adjustments" },
  { num: "-", name: "Arrears" },
  { num: "-", name: "No pay for LoA" },
  { num: "23 days of 2 emp", name: "LWP deduction days" },
  { num: "Late deductions", name: "24 hrs of 2 emp" },
];

const blockProps = {
  "Pay Comparison": {
    id: shortid.generate(),
    component: PieCharts,
    props: { data },
  },
  "Pay Changes": {
    component: PayChanges,
    props: { data: paychanges },
    footer: "Details",
    footerLink: "CP/Report/NewHrReport/VarianceSummary.aspx",
    id: shortid.generate(),
  },
  "Stop Payments": {
    id: shortid.generate(),
    component: SomeComponent,
    props: { pending: pending2 },
    footer: "Details",
    footerLink: "CP/StopPaymentPG.aspx",
  },
  "Pending Requests": {
    component: Pending,
    props: { pending },
    id: shortid.generate(),
  },
  "Latest Events": {
    component: EmployeeList,
    props: newJoins,
    id: shortid.generate(),
    footer: "All Events",
  },
};

const initial = [
  {
    id: shortid.generate(),
    cards: [{ name: "Pay Comparison", ...blockProps["Pay Comparison"] }],
  },
  {
    id: shortid.generate(),
    cards: [
      { name: "Pay Changes", ...blockProps["Pay Changes"] },
      { name: "Stop Payments", ...blockProps["Stop Payments"] },
    ],
  },
  {
    id: shortid.generate(),
    cards: [
      { name: "Pending Requests", ...blockProps["Pending Requests"] },
      { name: "Latest Events", ...blockProps["Latest Events"] },
    ],
  },
];

export class DragBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lists: initial,
    };
    this._renderBlock = this._renderBlock.bind(this);
    this.onDragChange = this.onDragChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { blocks } = this.props;
    const { lists } = this.state;
    if (!isEmpty(blocks) && blocks !== prevProps.blocks) {
      let hrBlocks = blocks.filter((item) => item.DashboardName === "Payroll");
      if (!isEmpty(hrBlocks)) {
        let sortedBlock;
        sortedBlock = hrBlocks.sort((a, b) => a.BlockOrder - b.BlockOrder);

        const newList = lists.map((item, index) => {
          let num = index + 3;
          let threes = sortedBlock.slice(index * 3, (index + 1) * 3);
          let newCards = threes.map((card) => {
            return {
              name: card.BlockName,
              ...blockProps[card.BlockName],
            };
          });
          item.cards = [...newCards];

          return item;
        });
        console.log({newList,hrBlocks})
        // this.setState({ lists: newList });
      }
    }
  }
  onDragChange = () => {
    const { lists } = this.state;
    const toPost = lists.map((item, ind) => {
      let b = item.cards.map((card, i) => {
        return {
          DashboardName: "Payroll",
          BlockName: card.name,
          // BlockOrder:(i +1) + (ind * 3)
        };
      });
      return b;
    });
    let c = toPost.flat();
    c = c.map((item, i) => {
      return {
        BlockOrder: i + 1,
        ...item,
      };
    });
    this.props.addDashboardBlocks(c);
  };

  onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;
    if (!destination) {
      return;
    }
    const { lists } = this.state;
    const droppableIdStart = source.droppableId;
    const droppableIdEnd = destination.droppableId;
    const droppableIndexEnd = destination.index;
    const droppableIndexStart = source.index;

    if (droppableIdStart === droppableIdEnd) {
      const listStart = lists.find((list) => list.id === droppableIdStart);

      const card = listStart.cards.splice(droppableIndexStart, 1);
      listStart.cards.splice(droppableIndexEnd, 0, ...card);
      this.setState((prevState) => ({
        ...prevState,
        lists: prevState.lists.map((list) => {
          if (list.id === listStart.id) {
            return listStart;
          }
          return list;
        }),
      }));
    }
    if (droppableIdStart !== droppableIdEnd) {
      // find the list where the drag happened
      const listStart = lists.find((list) => list.id === droppableIdStart);
      // pull out the card from this list
      const card = listStart.cards.splice(droppableIndexStart, 1);
      // find the list where the drag ended
      const listEnd = lists.find((list) => list.id === droppableIdEnd);
      // put the card in the new list
      listEnd.cards.splice(droppableIndexEnd, 0, ...card);
      this.setState((prevState) => ({
        ...prevState,
        lists: prevState.lists.map((list) => {
          if (list.id === listEnd.id) {
            return listEnd;
          } else if (list.id === listStart.id) {
            return listStart;
          }
          return list;
        }),
      }));
    }
    this.onDragChange();
  };
  _renderBlock(props, index) {
    return (
      <Draggable draggableId={String(props.id)} index={index} key={props.id}>
        {(provided) => (
          <div
            {...provided.draggableProps}
            // {...provided.dragHandleProps}
            ref={provided.innerRef}
            className="drag-block__item drag-box"
          >
            <div className="drag-box__header">
              <div className="drag-box__title">
                <h4>{props.name}</h4>
              </div>
              <div className="drag-box__icons">
                <span className="drag-box__icon">
                  <MdSettings />
                </span>
                <span
                  {...provided.dragHandleProps}
                  className="drag-box__icon drag"
                >
                  <img src={drag} width="16" height="16" />
                </span>
              </div>
            </div>
            <div className="drag-box__body">
              <props.component {...props.props} />
            </div>
            {props.footer ? (
              <div className="drag-box__footer">
                {props.footerLink ? (
                  <a
                    href={this.props.baseUrl + props.footerLink}
                    target="_blank"
                  >
                    <span>{props.footer}</span>
                  </a>
                ) : (
                  <span>{props.footer}</span>
                )}
              </div>
            ) : null}
          </div>
        )}
      </Draggable>
    );
  }
  render() {
    const { lists } = this.state;
    return (
      <div className="drag-block">
        <div className="drag-block__container">
          <div className="drag-block__items">
            <DragDropContext onDragEnd={this.onDragEnd}>
              {lists.map((item, index) => {
                return (
                  <Droppable
                    droppableId={String(item.id)}
                    key={index + Math.random()}
                    index={index}
                    type="list"
                  >
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        nf={provided}
                        index={index}
                        className="drag-block__items-list"
                      >
                        {item.cards.map(this._renderBlock)}
                      </div>
                    )}
                  </Droppable>
                );
              })}
            </DragDropContext>
          </div>
        </div>
      </div>
    );
  }
}

export default DragBlock;
