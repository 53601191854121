import React, { Component } from "react";
import man from "assets/images/man.png";
import { trash } from "assets/images/svgs/icons/trash";
import { email } from "assets/images/svgs/icons/email";
import emailOpen from "assets/images/svgs/icons/emailOpen";
import { FaRegTimesCircle } from "react-icons/fa";
import isEmpty from "isEmpty";
import { getEmployeeMessages } from "../action";
import { connect } from "react-redux";
import { UserByStore } from "constants/userDetails";
import { formatedShortMonthDayYear } from "helpers/dateFormat";
import Pagination from "components/common/pagination";

export class Messages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      messages: [],
      pageIndex: 0,
      rowsPerPage: 10,
    };
    this.messages = null;
    this.onViewOpen = this.onViewOpen.bind(this);
    // this._renderMessages = this._renderMessages.bind(this);
  }
  componentDidMount() {
    this.onDataLoad(3);

    // const { Message } = this.props;
    // this.setState({messages:Message.slice(0, 3)})
  }
  componentDidUpdate(prevProps, prevState) {
    // const { Message } = this.props;
    // if(this.state.open !== prevState.open && this.state.open){
    //   this.setState({messages:Message})
    // }
    // if(this.state.open !== prevState.open && !this.state.open){
    //   this.setState({messages:Message.slice(0, 3)})
    // }
  }
  onDataLoad = () => {
    const user = UserByStore();
    if (user) {
      const data = {
        userName: user.UserName,
        pageIndex: this.state.pageIndex,
        pageSize: this.state.rowsPerPage,
      };
      this.props.getEmployeeMessages(data);
    }
  };
  handleRowChange = (num) => {
    this.setState({ rowsPerPage: num }, async () => {
      await this.onDataLoad();
    });
  };

  handleIndexChange = (num) => {
    this.setState({ pageIndex: num }, async () => {
      await this.onDataLoad();
    });
  };
  onViewOpen() {
    const { messages } = this;

    this.setState({ open: !this.state.open }, () => {
      var five;
      var eight;
      if (this.state.open) {
        this.handleRowChange(10);
        const cont = document.getElementById("messages");
        const client = cont.getBoundingClientRect();
        messages.style.position = "fixed";
        messages.style.top = client.top + 10;
        messages.style.left = client.left;

        five = setTimeout(function () {
          messages.classList.add("messages-back");
          // messages.style.boxShadow = '0 2px 3px rgba(0,0,0,0.2)';
          // messages.style.transform='translateY(-10px)'
        }, 250);
        eight = setTimeout(function () {
          messages.style.top = 0;
          messages.style.left = 0;
          messages.style.width = "100%";
          messages.style.height = "100vh";
          // messages.style.padding = "20px";
          messages.style.background = "#fff";
          messages.style.margin = "0px";
          messages.classList.add("messages-fixed");
        }, 600);
      } else {
        this.handleRowChange(3);
        messages.classList.remove("messages-fixed");
        messages.classList.remove("messages-back");
        const cont = document.getElementById("messages");
        const client = cont.getBoundingClientRect();

        messages.style.width = "calc(80%)";
        messages.style.background = "transparent";
        messages.style.padding = "unset";
        messages.style.height = "450px";
        messages.style.top = client.top + 10;
        messages.style.left = client.left;

        clearInterval(five);
        clearInterval(eight);
        setTimeout(function () {
          messages.removeAttribute("style");
        }, 250);
      }
    });
  }

  render() {
    const { messages } = this.state;
    const { employeeMessages } = this.props;

    return (
      <div
        id="messages"
        className="messages-div"
        style={isEmpty(messages) ? { height: "unset" } : {}}
      >
        <div
          ref={(refs) => (this.messages = refs)}
          className="messages"
          style={isEmpty(messages) ? { height: "unset" } : {}}
        >
          <h3 className="mypage-section-title">
            <span>Messages</span>
            <span className="close-icon" onClick={() => this.onViewOpen()}>
              <FaRegTimesCircle />
            </span>
          </h3>
          {isEmpty(employeeMessages) ? (
            <div className="no-found-container">
              <span className="no-found">No Messages Found</span>
            </div>
          ) : (
            <div className="messages-container">
              <div>
                <ul className="messages-list">
                  {employeeMessages.map(item=><RenderMessage item={item} />)}
                </ul>

                {!this.state.open ? (
                  <div className="view-btn">
                    <a onClick={() => this.onViewOpen()}>
                      <span>View All</span>
                    </a>
                  </div>
                ) : null}
              </div>
              {this.state.open ? (
                <div className="messages-paginate">
                  {employeeMessages && employeeMessages.length > 0 && (
                    <Pagination
                      handleRowChange={this.handleRowChange}
                      handleIndexChange={this.handleIndexChange}
                      pageIndex={this.state.pageIndex}
                      rowsPerPage={this.state.rowsPerPage}
                      total={employeeMessages[0].TotalRows}
                    />
                  )}
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    );
  }
}


const RenderMessage = ({item}) =>{
  const [height,setHeight] = React.useState(false);
  return(
    <li className="messages-list__item">
    <div className="message">
      <div className="message__image">
        <div className="img-circle img-circle-60">
          <img src={man} alt="" />
        </div>
      </div>
      <div className="message__notice">
        <p className="notice-title">Notice</p>
        <span className="notice-time">
          {formatedShortMonthDayYear(item.ReceivedDateEng)}
        </span>
      </div>
      <div className="message__data">
        <div className="message-title">
          <h4>{item.Subject}</h4>
        </div>
        <div className="message-content">
          <p 
            style={{height:height?'auto':48}}
            dangerouslySetInnerHTML={{ __html: item.Body }}>
            {/* {item.Body} */}
          </p>
          <div  
              onClick={()=>setHeight(!height)}
              className="message-content-btn">{height ? "Hide Message" : "View Full Message"}</div>
          </div>
      </div>
      <div className="message__icons">
        <div className="message__icons-item">
          <div className="svg-icon email-icon">{email()}</div>
        </div>
        <div className="message__icons-item">
          <div className="svg-icon email-open-icon">{emailOpen()}</div>
        </div>
        <div className="message__icons-item">
          <div className="svg-icon trash-icon">{trash()}</div>
        </div>
      </div>
    </div>
  </li>
  )
}

const mapDispatchToProps = {
  getEmployeeMessages,
};

const mapStateToProps = (state) => ({
  employeeMessages: state.employeeReducer.employeeMessages,
});

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
