import React, { useState, useEffect } from "react";
import "./style.scss";
import * as calendarFuncs from "./helpers";
import classnames from "classnames";
import { getPagedYears, MONTH, YEAR, validateFormat } from "./helpers";
import { useSelector } from "react-redux";
import isEmpty from "isEmpty";
import {
  CommonHeader,
  CommonFooter,
  CommonAaction,
  CommonCore,
  CommonCell,
  CommonMonthTab,
  CommonYearTab,
} from "./common";
import useDateRange from "./useDateRange";
import { useHolidays } from "./common";

const isInNepali = false;

const getArrayFomatedDate = ({ month, day, year }) => {
  // return Formated Date YYYY/MM/DD
  return `${year}/${month}/${day}`;
};

const getDateFormatedFromArray = (date) => {
  // return Formated Date YYYY/MM/DD from [YYYY,MM,DD]
  return `${date[0]}/${date[1]}/${date[2]}`;
};
const getCalendarNum = (num, isNep = false) => {
  // const { isInNepali } = props;
  if (isNep) {
    return calendarFuncs.convertNumToNepali(num);
  } else {
    return num;
  }
};

interface NepCalendarProps {
  date?: any;
  onDateChange?: Function;
  isInNepali?: boolean;
  noFooter?: boolean;
  isFullDetails?: boolean;
  isDateRange?: boolean;
  minDate?: any;
  maxDate?: any;
}

function NepCal(props: NepCalendarProps) {
  const { onDateChange, date, isDateRange, isInNepali } = props;

  // const formattedDate = dateFns.format(new Date(),'mm/dd/yyyy')
  // const [currentNepDate, setCurrentNepDate] = useState(AD2BS(formattedDate));
  const { dateRange, setDateRange } = useDateRange(props, true);
  const [currentDate, setCurrentDate] = useState(calendarFuncs.getNepaliDate());
  // const [isInNepali, setIsInNepali] = useState(false);
  const [today, setToday] = useState(calendarFuncs.getNepaliDate());
  const [selectedDate, setSelectedDate] = useState(null);
  const [tab, setTab] = useState(null);
  const [yearSetting, setYearSetting] = useState({
    indexPoint: 0,
    indexLeap: 6,
  });
  const [hoveredDate, setHoveredDate] = useState("");
  const holidays = useHolidays(true);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);

  useEffect(() => {
    if (date) {
      if (isDateRange) {
        if ("startDate" in props.date) {
          if (validateFormat(props.date.startDate, false)) {
            setSelectedDate(props.date.startDate);
            date.startDate
              ? setCurrentDate(date.startDate)
              : setCurrentDate(calendarFuncs.getNepaliDate());
            // setCurrentDate(props.date.startDate)
          }
        }
      } else {
        if (validateFormat(props.date, false)) {
          setSelectedDate(props.date);
          setCurrentDate(props.date);
        }
      }
    }
  }, [date]);

  useEffect(() => {
    if (props.minDate) {
      setMinDate(props.minDate);
    }
    if (props.maxDate) {
      setMaxDate(props.maxDate);
    }
  }, [props.minDate, props.maxDate]);

  const header = () => {
    const dateFormat = "mmmm yyyy";
    const { year, month, day } = calendarFuncs.getNepDateSeperated(currentDate);
    const monthname = Object.keys(
      isInNepali
        ? calendarFuncs.CALENDAR_MONTHS_NEPALI
        : calendarFuncs.CALENDAR_MONTHS_NEPALI_ENG
    )[Math.max(0, Math.min(month - 1, 11))];
    const engMonthname = Object.keys(
      calendarFuncs.CALENDAR_MONTHS_NEPALI_NEP_ENG
    )[Math.max(0, Math.min(month - 1, 11))];
    return (
      <CommonHeader
        tab={tab}
        setTab={setTab}
        year={getCalendarNum(year, isInNepali)}
        month={monthname}
        secondaryMonth={engMonthname}
      />
    );
  };
  const footer = () => {
    var yesterday = calendarFuncs.getNepaliDate(
      new Date(new Date().setDate(new Date().getDate() - 1))
    );
    var tomorrow = calendarFuncs.getNepaliDate(
      new Date(new Date().setDate(new Date().getDate() + 1))
    );
    return (
      <CommonFooter
        onClick={onDateClick}
        yesterday={yesterday}
        today={today}
        tomorrow={tomorrow}
        maxDate={props.maxDate}
        minDate={props.minDate}
      />
    );
  };
  const actions = () => {
    return (
      <CommonAaction
        current={() => {
          setTab(false);
          setCurrentDate(today);
        }}
        prevMonth={prevMonth}
        nextMonth={nextMonth}
        prevYear={prevYear}
        nextYear={nextYear}
      />
    );
  };
  const renderDayLabels = (day) => {
    return (
      <div
        className={`calendar-body__cell calendar-body__day ${
          isInNepali ? "cell-in-nepali" : null
        } `}
      >
        {isInNepali
          ? calendarFuncs.NEPALI_WEEK_DAYS[day].toUpperCase()
          : calendarFuncs.WEEK_DAYS[day].toUpperCase()}
      </div>
    );
  };
  const days = () => {
    return (
      <div className="calendar-body__grid">
        {Object.keys(
          isInNepali ? calendarFuncs.NEPALI_WEEK_DAYS : calendarFuncs.WEEK_DAYS
        ).map(renderDayLabels)}
      </div>
    );
  };
  const ClickMonth = (changeMonth) => {
    const [year, month, day] = calendarFuncs.getNepDateArray(currentDate);
    setCurrentDate(getArrayFomatedDate({ year, day, month: changeMonth }));
    setTab(null);
  };
  const ClickYear = (changeYear) => {
    const [year, month, day] = calendarFuncs.getNepDateArray(currentDate);
    setCurrentDate(getArrayFomatedDate({ year: changeYear, day, month }));
    setTab(null);
  };
  // Rendering months and years after on month or year tab click
  const MonthYearTabs = () => {
    const [year, month, day] = calendarFuncs.getNepDateArray(currentDate);
    const months = Object.keys(
      isInNepali
        ? calendarFuncs.CALENDAR_MONTHS_NEPALI
        : calendarFuncs.CALENDAR_MONTHS_NEPALI_ENG
    );
    const monthname = Object.keys(
      isInNepali
        ? calendarFuncs.CALENDAR_MONTHS_NEPALI
        : calendarFuncs.CALENDAR_MONTHS_NEPALI_ENG
    )[Math.max(0, Math.min(month - 1, 11))];
    if (tab === MONTH) {
      return (
        <CommonMonthTab
          onMonthClick={ClickMonth}
          currentMonth={monthname}
          months={months}
          isInNepali={isInNepali}
        />
      );
    }
    const { leftArrow, rightArrow, SplittedYears } = getPagedYears(
      year,
      yearSetting
    );
    if (tab === YEAR) {
      return (
        <CommonYearTab
          leftArrow={leftArrow}
          rightArrow={rightArrow}
          SplittedYears={SplittedYears}
          OnYearClick={ClickYear}
          yearSetting={yearSetting}
          year={year}
          isInNepali={isInNepali}
          setYearSetting={setYearSetting}
        />
      );
    }
    setTab(null);
    return null;
  };
  // Date Cell, Active Month Dates
  const cells = () => {
    const monthDates = calendarFuncs.calendarDates(currentDate);
    // const rows = [];
    const rows = monthDates.map((item, index) => {
      const date = getDateFormatedFromArray(item);

      const isStartGreater =
        calendarFuncs.nepDateDiff(dateRange.startDate, hoveredDate) < 0;
      const isStartNeutral = dateRange.endDate
        ? calendarFuncs.nepDateDiff(dateRange.startDate, dateRange.endDate) ==
            0 &&
          calendarFuncs.isSameDay(dateRange.startDate, date) &&
          calendarFuncs.isSameDay(dateRange.endDate, date)
        : false;

      const inMonth = calendarFuncs.isNepSameMonth(date, currentDate);
      const isToday = calendarFuncs.isSameDay(date, today);
      const isStart =
        dateRange.startDate &&
        calendarFuncs.isSameDay(date, dateRange.startDate);
      const isEnd =
        dateRange.endDate && calendarFuncs.isSameDay(date, dateRange.endDate);
      const isCurrent =
        selectedDate &&
        calendarFuncs.isSameDay(date, selectedDate) &&
        !isDateRange;
      const isHoliday = Object.keys(holidays).includes(date);
      const holiday = isHoliday ? holidays[date] : null;
      let isInBetweenHoverDate: boolean = false;
      let isInBetweenDate: boolean = false;
      if (!isEmpty(dateRange.startDate) && !isEmpty(hoveredDate)) {
        isInBetweenHoverDate = isStartGreater
          ? calendarFuncs.inBetween(dateRange.startDate, hoveredDate, date)
          : calendarFuncs.inBetween(hoveredDate, dateRange.startDate, date);
      }
      if (!isEmpty(dateRange.startDate) && !isEmpty(dateRange.endDate)) {
        isInBetweenDate = calendarFuncs.inBetween(
          dateRange.startDate,
          dateRange.endDate,
          date
        );
      }
      const isDisabled =
        minDate || maxDate
          ? minDate && maxDate
            ? calendarFuncs.isAfterDay(minDate, date) ||
              calendarFuncs.isBeforeDay(maxDate, date)
            : minDate
            ? calendarFuncs.isAfterDay(minDate, date)
            : maxDate
            ? calendarFuncs.isBeforeDay(maxDate, date)
            : false
          : false;
      return (
        <CommonCell
          holiday={holiday}
          cell={getCalendarNum(item[2], isInNepali)}
          onCellClick={onDateClick}
          date={date}
          inMonth={inMonth}
          isToday={isToday}
          isCurrent={isCurrent}
          isHoliday={isHoliday}
          isNepali
          isInNepali={isInNepali}
          isStart={isStart}
          isStartNeutral={isStartNeutral}
          isInBetweenHoverDate={isInBetweenHoverDate}
          isEnd={isEnd}
          isInBetweenDate={isInBetweenDate}
          isDateRange={isDateRange}
          dateRange={dateRange}
          hoveredDate={hoveredDate}
          isDisabled={isDisabled}
          setHoveredDate={setHoveredDate}
        />
      );
    });
    return <div className="calendar-body__grid ">{rows}</div>;
  };
  const nextMonth = () => {
    // returns {month + 1, year, day}
    const dates = calendarFuncs.getNepNextMonthWithDay(currentDate);
    setTab(false);
    setCurrentDate(getArrayFomatedDate(dates));
  };
  const prevMonth = () => {
    // returns {month - 1, year, day}
    const dates = calendarFuncs.getNepPreviousMonthWithDay(currentDate);
    setTab(false);
    setCurrentDate(getArrayFomatedDate(dates));
  };
  const prevYear = () => {
    // returns {month, year -1, day}
    const dates = calendarFuncs.getNepPrevYearWithDay(currentDate);
    setTab(false);
    setCurrentDate(getArrayFomatedDate(dates));
  };
  const nextYear = () => {
    // returns {month, year + 1, day}
    const dates = calendarFuncs.getNepNextYearWithDay(currentDate);
    setTab(false);
    setCurrentDate(getArrayFomatedDate(dates));
  };
  const onDateClick = (day) => {
    setCurrentDate(day);
    setSelectedDate(day);
    if (isDateRange) {
      setDateRange(day);
    } else {
      setSelectedDate(day);
      setCurrentDate(day);
      if (props.isFullDetails) {
        let engDate = calendarFuncs.getFullNepali2EngDate(day);
        let nepaliDate = calendarFuncs.getFullNepaliDateFromNep(day);
        props.onDateChange &&
          props.onDateChange(day, { date: day, nepaliDate, engDate });
      } else {
        props.onDateChange && props.onDateChange(day);
      }
    }
    setTab(false);
  };
  return (
    <CommonCore
      header={header}
      days={days}
      cells={cells}
      tab={tab}
      actions={actions}
      monthYearTabs={MonthYearTabs}
      noFooter={props.noFooter}
      footer={footer}
    />
  );
}

export default NepCal;
