import React, { Fragment } from "react";
import ReactPDF, {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import { getUploadPath } from "constants/userDetails";
import moment from "moment";
import { getBaseAxios } from "helpers/constants";
import isEmpty from "isEmpty";
import { currencyCommaSeperator } from "../../../helpers/currencyFormatter";
import { numberToNepaliCurrency } from "helpers/regex";
let bankName = "PRIME COMMERCIAL BANK LTD";
let consentText =
  "I hereby declare that all the particulars given in the application form are correct, complete and up to date in all respect and I have not withheld any information. I agree to provide sufficient mortgage to the bank. In case it is found that mortgage property to the bank has claim with any other authority for recovering any liability, the bank may ask to adjust the loan in full. I hereby agree that at any given time of inspection if it is found that the loan already disbursed to me has not been satisfactory utilized, the bank may withhold the disbursement of the subsequent installments. I hereby agree to accept the valuation done by the Bank's approved valuator.";
export default function LoanPdfTemplate(props) {
  const {
    EmployeeDetail,
    LoanDetails,
    OutstandingLoan,
    LoanFilledInformation,
    TotalGrossSalary,
    RequestedBy,
    Position,
  } = props || {};
  const EmployeeInfoTable = ({ data }) => <ItemsTable data={data} />;

  const ItemsTable = ({ data }) => (
    <View wrap={false} style={stylesItemTable.tableContainer}>
      <TableRow items={data} />
    </View>
  );

  const TableRow = ({ items }) => {
    let length = items.length;
    const rows =
      items &&
      items.map((item, i) => (
        <View
          style={i + 1 != length ? stylesTableRow.row : stylesTableRow.lastRow}
          key={i}
        >
          <Text style={stylesTableRow.title}>{item.title}</Text>
          <Text style={stylesTableRow.description}>{item.description}</Text>
        </View>
      ));
    return <Fragment>{rows}</Fragment>;
  };

  // --------------------- outstanding loan ---------------------
  const OutstandingTable = ({ data }) => <OutItemsTable data={data} />;

  const OutItemsTable = ({ data }) => (
    <View wrap={false} style={stylesItemTable.tableContainer}>
      <OutTableRow items={data} />
    </View>
  );

  const OutTableRow = ({ items }) => {
    let length = items.length;
    if (length == 0) {
      return (
        <View style={{ padding: 10 }}>
          <Text style={{ fontSize: 10 }}>No Outstanding Loan</Text>
        </View>
      );
    } else {
      const rows = [
        <View
          wrap={false}
          style={{
            ...outStylesTableRow.headerRow,
            borderBottom:
              length == 1 ? "1px solid #b6bec9" : "unset !important",
          }}
          key={"456t78iuu"}
        >
          <Text style={{ ...outStylesTableRow.header, fontWeight: "600" }}>
            Loan
          </Text>
          <Text
            style={{
              ...outStylesTableRow.headerAmount,
              fontWeight: "600",
            }}
          >
            Outstanding Amount(NPR)
          </Text>
          <Text
            style={{
              ...outStylesTableRow.headerDates,
              fontWeight: "600",
            }}
          >
            As on
          </Text>
          <Text
            style={{
              ...outStylesTableRow.headerAmount,
              fontWeight: "600",
            }}
          >
            Monthly Deduction(NPR)
          </Text>
          <Text
            style={{
              ...outStylesTableRow.headerDates,
              fontWeight: "600",
              borderRight: "unset",
            }}
          >
            Completes On
          </Text>
        </View>,
      ];
      let dataRows = items.map((item, i) => (
        <View
          style={
            i + 1 != length ? outStylesTableRow.row : outStylesTableRow.lastRow
          }
          key={i}
        >
          <>
            <Text style={outStylesTableRow.header}>{item.LoanName}</Text>
            <Text style={outStylesTableRow.headerAmount}>
              {currencyCommaSeperator(item.Outstanding)}
            </Text>
            <Text style={outStylesTableRow.headerDates}>{item.AsOn}</Text>
            <Text style={outStylesTableRow.headerAmount}>
              {currencyCommaSeperator(item.MonthlyDeduction)}
            </Text>
            <Text
              style={{ ...outStylesTableRow.headerDates, borderRight: "unset" }}
            >
              {item.CompletesOn}
            </Text>
          </>
        </View>
      ));
      rows.push(dataRows);
      return <Fragment>{rows}</Fragment>;
    }
  };

  // --------------------- APPROVER LIST SECTION TABLE ---------------------

  const ApproverListTable = ({ data, position }) => (
    <View wrap={false} style={stylesItemTable.tableContainer}>
      <ApproverTableRow items={data} position={position} />
    </View>
  );

  const ApproverTableRow = ({ items, position }) => {
    let length = items.length;
    if (position !== "first" && length < 4) return null;

    position == "first"
      ? (items = items.slice(0, 3))
      : (items = items.slice(3, 6));
    return (
      <Fragment>
        <View style={approverStylesTableRow.row}>
          {items.map((item, i) => (
            <View
              style={approverStylesTableRow.column}
              // style={
              //   i + 1 != length ? approverStylesTableRow.row : approverStylesTableRow.lastRow
              // }
              key={i}
            >
              <>
                <Text style={approverStylesTableRow.header}>
                  {(i + 1) % 3 == 1 && position == "first"
                    ? "HR Forward"
                    : "Loan information filled by"}
                </Text>
                <Text style={approverStylesTableRow.header}>
                  {item.EmployeeName}
                </Text>
                <Text style={approverStylesTableRow.header}>
                  {item.DepartmentName}
                </Text>
                <Text style={approverStylesTableRow.header}>
                  {moment(item.FilledDate).format("YYYY/MM/DD hh:mm A")}
                </Text>
              </>
            </View>
          ))}
        </View>
      </Fragment>
    );
    // rows.push(dataRows);
    // return <Fragment>{rows}</Fragment>;
  };
  // let advanceAndExpense_Total = 0;
  return (
    <Document title="Home Loan Application Form.pdf">
      <Page style={styles.body} size="A4" orientation="portrait" wrap={false}>
        {/* ------------------------BANK HEADER----------------------- */}
        <Text style={styles.bankNameHeader}>{bankName}</Text>
        <Text style={styles.loanTypeHeader}>
          {LoanDetails && LoanDetails.LoanTitle + " APPLICATION FORM"}
        </Text>
        <Text style={styles.applicationDate}>
          Application Date: {LoanDetails && LoanDetails.RequestedOn}
        </Text>
        {/* ------------------------EMPLOYEE INFORMATION----------------------- */}
        <Text style={styles.titleHeader}>Employee Information</Text>
        <View wrap={false} style={{ marginBottom: "20px" }}>
          <EmployeeInfoTable data={EmployeeDetail || []} />
        </View>
        {/* ------------------------BUILDING SECTION----------------------- */}
        <Text style={styles.titleHeader}>
          Details of the Land, Building/Apartment
        </Text>
        <Text style={{ ...styles.propertyHeader, backgroundColor: "#f4f6f9" }}>
          Property Detail
        </Text>
        {LoanDetails && LoanDetails.IsBuilding ? (
          <Text
            style={{ ...styles.titleSubHeader, backgroundColor: "#f4f6f9" }}
          >
            Building
          </Text>
        ) : (
          // <View wrap={false} style={{ marginBottom: "20px" }}>
          //   <EmployeeInfoTable  />
          // </View>
          <Text
            style={{ ...styles.titleSubHeader, backgroundColor: "#f4f6f9" }}
          >
            Apartment
          </Text>
        )}
        <View wrap={false} style={{ marginBottom: "10px" }}>
          <EmployeeInfoTable data={LoanDetails?.PropertyDetail || []} />
        </View>
        {/* ------------------------AREA SECTION----------------------- */}
        <Text style={{ ...styles.titleSubHeader, backgroundColor: "#f4f6f9" }}>
          Area
        </Text>
        <View wrap={false} style={{ marginBottom: "110px" }}>
          <EmployeeInfoTable data={LoanDetails?.AreaDetail || []} />
        </View>
        {/* ------------------------LOAN REQUIREMENT SECTION----------------------- */}
        <Text style={styles.titleHeader}>Loan Requirement</Text>
        <View wrap={false} style={{ marginBottom: "10px" }}>
          <EmployeeInfoTable data={LoanDetails?.AreaDetail || []} />
        </View>

        {/* ------------------------GROSS SALARY SECTION----------------------- */}
        {/* <Text style={styles.titleHeader}>
          Gross Salary
        </Text>
        <Text style={styles.salaryAmount}>
          345,000
        </Text> */}

        {/* ------------------------OUTSTANDING LOAN SECTION----------------------- */}
        <Text style={styles.titleHeader}>Outstanding Loans</Text>
        <View wrap={false} style={{ marginBottom: "10px" }}>
          <OutstandingTable data={OutstandingLoan || []} />
        </View>
        {/* ------------------------ CONSENT SECTION ----------------------- */}
        <Text
          style={{
            whiteSpace: "pre-wrap",
            overflowWrap: "break-all",
            fontSize: 10,
            marginBottom: 32,
            marginTop: 32,
            lineHeight: 1.5,
            // letterSpacing: ,
          }}
        >
          {consentText}
        </Text>

        <Text style={styles.RequestedBy}>Loan requested by</Text>
        <Text style={styles.RequestedBy}>{RequestedBy}</Text>
        <Text style={styles.RequestedBy}>{Position}</Text>
        <Text
          style={[
            {
              // borderBottomWidth: 0.5,
              width: "100%",
              marginBottom: "20px",
              borderBottom: "1px solid #000000",
            },
          ]}
        >
          {" "}
        </Text>
        {/* ------------------------APPROVER LISTS SECTION----------------------- */}
        <View wrap={false} style={{ marginBottom: "20px" }}>
          <ApproverListTable
            data={LoanFilledInformation || {}}
            position={"first"}
          />
        </View>
        <View wrap={false}>
          <ApproverListTable
            data={LoanFilledInformation || {}}
            position={"second"}
          />
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Page>
    </Document>
  );
}

const stylesItemTable = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
});

const stylesTableRow = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    // border: "1px solid #b6bec9",
  },
  lastRow: {
    flexDirection: "row",
    alignItems: "center",
    // border: "1px solid #b6bec9",
    // borderTop: "unset !important",
  },
  title: {
    width: "50%",
    // borderRight: "1px solid #b6bec9",
    padding: "4px 10px",
    fontSize: "10px",
    fontWeight: "300",
    color: "#000000",
    // color: "#28417b",
  },
  description: {
    width: "50%",
    fontSize: "10px",
    padding: "4px 10px",
    fontWeight: "300",
    color: "#000000",
  },
});

const approverStylesTableRow = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  column: {
    flexDirection: "column",
    // alignItems: "center",
    width: "100%",
  },
  lastRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  header: {
    width: "33.33%",
    // paddingLeft: "10px",
    fontSize: "10px",
    marginBottom: 2,
  },
  firstHeader: {
    width: "33.33%",
    fontSize: "10px",
  },
});

const outStylesTableRow = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    border: "1px solid #b6bec9",
  },
  lastRow: {
    flexDirection: "row",
    alignItems: "center",
    border: "1px solid #b6bec9",
    borderTop: "unset !important",
  },
  headerRow: {
    flexDirection: "row",
    alignItems: "center",
    border: "1px solid #b6bec9",
    borderBottom: "unset !important",
  },
  header: {
    width: "20%",
    borderRight: "1px solid #b6bec9",
    padding: 2,
    paddingLeft: "10px",
    fontSize: "10px",
  },
  headerAmount: {
    width: "25%",
    borderRight: "1px solid #b6bec9",
    padding: 2,
    paddingLeft: "10px",
    fontSize: "10px",
  },
  headerDates: {
    width: "15%",
    borderRight: "1px solid #b6bec9",
    padding: 2,
    paddingLeft: "10px",
    fontSize: "10px",
  },
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  RequestedBy: {
    fontSize: "10px",
    marginBottom: 2,
    // letterSpacing: 10,
    lineHeight: 1,

    // fontFamily: "Times-Roman",
  },
  row: {
    flex: 1,
    flexDirection: "row",
    marginVertical: 2.5,
  },
  bankNameHeader: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "#000000",
    fontWeight: 600,
    textDecoration: "underline",
  },
  loanTypeHeader: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "#000000",
  },
  applicationDate: {
    fontSize: 10,
    marginBottom: 20,
    textAlign: "left",
    color: "#000000",
  },
  titleHeader: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "left",
    color: "#000000",
    fontWeight: 600,
    textDecoration: "underline",
  },
  titleSubHeader: {
    fontSize: 10,
    padding: 10,
    // marginBottom: 10,
    textAlign: "left",
    color: "#000000",
  },
  propertyHeader: {
    fontSize: 10,
    // marginBottom: 10,
    padding: "10px 10px 0px 10px",
    textAlign: "left",
    fontWeight: "600",
    color: "#000000",
  },
  salaryAmount: {
    fontSize: 10,
    marginBottom: 10,
    textAlign: "left",
    // color: "#28417b",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});
