import React, { Component } from "react";
import { Tabs, Tab } from "components/tabs/index.d";
import ClientProjectFee from "../newProject/clientProjectFee";
import TeamAndContribution from "../newProject/teamAndContribution";
import Approvals from "../newProject/approvals";
import CounterParties from "../newProject/counterParties";
import DateAndDocument from "./dateAndDocument";

const tabs = [
  { id: 1, label: "Date and Documents" },
  { id: 2, label: "Fees" },
  { id: 3, label: "Team and Contribution" },
  { id: 4, label: "Approvals" },
  { id: 5, label: "Counterparties" },
];
export class ClientProjectTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // // tabs: [
      // //   { id: 1, label: "Fees" },
      // //   { id: 2, label: "Team and Contribution" },
      // //   { id: 3, label: "Approvals" },
      // //   { id: 4, label: "Counterparties" },
      // //   { id: 5, label: "Contract History" },
      // // ],
      activeTabId: 1,
    };
  }

  handleTabChange = (tabId) => {
    this.setState({ activeTabId: tabId });
  };

  render() {
    const { activeTabId } = this.state;
    return (
      <Tabs>
        {tabs.map((tab, index) => {
          return (
            <Tab
              key={index}
              label={tab.label}
              name={tab.id}
              id={tab.id}
              isActive={activeTabId === tab.id}
              onClick={() => this.handleTabChange(tab.id)}
            >
              {activeTabId == 1 ? (
                <DateAndDocument
                  // reloadList={reloadList}
                  // SaveProjectContract={SaveProjectContract}
                  {...this.props}
                />
              ) : activeTabId == 2 ? (
                <ClientProjectFee {...this.props} />
              ) : activeTabId == 3 ? (
                <TeamAndContribution {...this.props} />
              ) : activeTabId == 4 ? (
                <Approvals {...this.props} />
              ) : (
                <CounterParties {...this.props} />
              )}
            </Tab>
          );
        })}
      </Tabs>
    );
  }
}

export default ClientProjectTabs;
