import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {Table} from "@material-ui/core";
import {TableBody} from "@material-ui/core";
import {TableCell} from "@material-ui/core";
import {TableContainer} from "@material-ui/core";
import {TableHead} from "@material-ui/core";
import {TableRow} from "@material-ui/core";
import {TableFooter} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import getFormattedNumber from "helpers/getFormattedNumber";

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#fafafa",
    color: "#777 !important",
    borderBottom: "2px solid #cbcbcb",
    padding: "12px",
    fontWeight: "400",
    fontSize: 15
  },
  body: {
    fontSize: 14,
    padding: "12px"
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.background.default
    }
  }
}))(TableRow);

const StyledPaper = withStyles(theme => ({
  root: {
    boxShadow: "unset",
    width: 600
  }
}))(Paper);

function createData(Allowance, Units, Rate, Amount) {
  return { Allowance, Units, Rate, Amount };
}

const useStyles = makeStyles({
  table: {
    minWidth: 200
  },
  footerCell: {
    fontSize: "15px !important",
    color: "#444 !important",
    fontWeight: 600
  }
});

export default function AdvanceAndExpensesTable(props) {
  const { data } = props;

  let rows = [];
  data &&
    data.map(m => {
      rows.push(createData(m.allowance_name, m.units, m.rate, m.amount));
    });

  const classes = useStyles();
  let totalAmount = 0;
  return (
    <TableContainer component={StyledPaper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Allowance</StyledTableCell>
            <StyledTableCell align="right">Units</StyledTableCell>
            <StyledTableCell align="right">Rate</StyledTableCell>
            <StyledTableCell align="right">Amount</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows &&
            rows.map(row => {
              totalAmount = totalAmount + row.Amount;
              return (
                <StyledTableRow key={row.Allowance}>
                  <StyledTableCell component="th" scope="row">
                    {row.Allowance}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.Units}</StyledTableCell>
                  <StyledTableCell align="right">
                    {getFormattedNumber(row.Rate)}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {getFormattedNumber(row.Amount)}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <StyledTableCell className={classes.footerCell} align="left">
              Total
            </StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell className={classes.footerCell} align="right">
              {getFormattedNumber(totalAmount)}
            </StyledTableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
