import React, { Component, useEffect } from "react";
import ReactDOM from "react-dom";
import { FaTimesCircle, FaExclamationCircle } from "react-icons/fa";
import isEmpty from "isEmpty";

const PromptPopup = (message,onConfirm, onCancel) => {
    const container = document.createElement("div");
    container.setAttribute("custom-confirmation-navigation", "");
    document.body.appendChild(container);
    const closeModal = callbackState => {
        unMount();
        // callback(callbackState);
        if(callbackState){
            onConfirm()
        }else{
            onCancel()
        }
        
    };
    const unMount = () => {
        ReactDOM.unmountComponentAtNode(container);
        document.body.removeChild(container);
    };
    const textObj = JSON.parse(message);
    ReactDOM.render(
        <div className="modal">
            <div className="modal__confrim confirm">
                <div className="confirm-icon">
                    <FaExclamationCircle />
                </div>
                <h2 className="confirm-title">{textObj.title}</h2>
                <p>{textObj.messageText}</p>
                <div className="confirm-buttons">
                    <button
                        className="confirm-buttons__confirm"
                        onClick={() => closeModal(true)}
                    >
                        <span>
                            {isEmpty(textObj.confirmText)
                                ? "Yes, I am sure"
                                : textObj.confirmText}
                        </span>
                    </button>
                    <button
                        className="confirm-buttons__cancel"
                        onClick={() => closeModal(false)}
                    >
                        <span>
                            {isEmpty(textObj.cancelText)
                                ? "Cancel"
                                : textObj.cancelText}
                        </span>
                    </button>
                </div>
            </div>
        </div>,
        container
    );
};

export default PromptPopup;
