import React, { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import Pagination from "./pagination";
import Loaders from "../loaders";

const defaultColDef = {
  // width: 145,
  resizable: true,
  sortable: true,

  tooltipShowDelay: 0,
  enableBrowserTooltips: true,

  //suppressHorizontalScroll: true,
  // flex: 1,
  // editable: true,
  // enableRowGroup: true,
  // enablePivot: true,
  // enableValue: true,
  // filter: true,
  // minWidth: 100,
};

function AgGrid(props: any) {
  let {
    rowData,
    totalData,
    pagination,
    page,
    height,
    rowHeight,
    rowSelection,
    onSelectionChanged,
  } = props;
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [state, setState] = useState({
    pageIndex: page ? page.pageIndex : 1,
    rowsPerPage: page ? page.rowsPerPage : 0,
  });

  const getRowHeight = (params) => {
    return rowHeight || 45;
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    const root: any = document.getElementsByClassName("ag-root-wrapper");
    if (root) {
      root[0].parentElement.style.height = "";
    }
    props.onGridReady && props.onGridReady(params);
    // gridApi.setDomLayout('autoHeight');
    // const grid:any = document.querySelector('#myGrid');
    // if (grid) grid.style.height = '';
  };

  const handleRowChange = (pageIndex, rowsPerPage) => {
    setState({ ...state, pageIndex: pageIndex, rowsPerPage: rowsPerPage });
    props.handlePagination && props.handlePagination(pageIndex, rowsPerPage);
  };

  const handleIndexChange = (pageIndex) => {
    const { page } = props;
    if (page) {
      setState({ ...state, pageIndex: pageIndex });
      props.handlePagination &&
        props.handlePagination(pageIndex, page.rowsPerPage);
    }
  };

  if (rowData && rowData.Data) {
    if (rowData.Data.length > 0 && rowData.Data[0].TotalRows) {
      totalData = rowData.Data[0].TotalRows;
    } else if (rowData.TotalCount) {
      totalData = rowData.TotalCount;
    } else {
      totalData = 0;
    }
    rowData = rowData.Data;
  }

  return (
    <div>
      <div
        className="ag-theme-alpine"
        style={{
          height: height ? height : "auto",
          //  maxWidth: "1300px",
          width: "100%",
        }}
      >
        {!props.rowData ? (
          <Loaders
            loading={true}
            type="skeleton"
            // backgroundColor="#bbb"
            // foregroundColor="#dedede"
            name="custom-table"
            //cardType="block"
          />
        ) : (
          <AgGridReact
            columnDefs={props.columnDefs}
            defaultColDef={defaultColDef}
            // colResizeDefault={"shift"}  // caution: it makes only one column resizable at a time
            getRowHeight={getRowHeight}
            onGridReady={onGridReady}
            rowSelection={rowSelection}
            frameworkComponents={props.frameworkComponents}
            rowData={rowData && rowData}
            domLayout="autoHeight"
            suppressRowClickSelection={props.suppressRowClickSelection}
            onSelectionChanged={() => {
              var selectedRows = gridApi.getSelectedRows();
              onSelectionChanged && onSelectionChanged(selectedRows);
            }}
            // pagination={!isEmpty(pagination) ? pagination : true}
            animateRows={true}
          />
        )}
      </div>
      {totalData && totalData > 0 ? (
        <Pagination
          handleRowChange={handleRowChange}
          handleIndexChange={handleIndexChange}
          pageIndex={state.pageIndex} //{props.pageIndex}
          rowsPerPage={state.rowsPerPage} //{props.rowsPerPage}
          total={totalData}
        />
      ) : null}
    </div>
  );
}

export default AgGrid;
