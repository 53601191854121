import FormGroup from "components/form";
import { ValidationComponent } from "helpers/getComponents";
import React from "react";
import { connect } from "react-redux";
const propertyType = [
  {
    label: "Building",
    value: "1",
  },
  {
    label: "Apartment",
    value: "2",
  },
];

function PropertyDetails({data, onChange, errors}) {
  return (
    <div className="homeloan-propertydetail">
      <div className="homeloan-checkboxes-pd wd-50 flex">
        {propertyType &&
          propertyType.map((radio, index) => {
            return (
              <label class="checkbox-container mb-md">
                {radio.label}
                <input
                  type="checkbox"
                  checked={radio.label == data.PropertyType}
                  onChange={() => onChange("PropertyType", radio.label)}
                />
                <span class="checkmark"></span>
              </label>
            );
          })}
      </div>
      {/* {
        errors.PropertyDetailErrors && 
      } */}
      {errors && errors.PropertyDetailErrors && (
        <div className="homeloan__checkbox__required text-red">Required</div>
      )}
      {data.PropertyType == propertyType[0].label && (
        <>
          <div className="homeloan-propertydetail-section flex">
            <div className="wd-50 mr-bg mb-md">
              <FormGroup
                name="Location"
                value={data.Location}
                onChange={onChange}
                label={"Location"}
                validators={["required"]}
                error={data.errors.Location}
              />
            </div>
            <div className="wd-50 mr-bg mb-md">
              <FormGroup
                name="PropertyOwner"
                value={data.PropertyOwner}
                onChange={onChange}
                label={"Owner of the Property"}
                validators={["required"]}
                error={data.errors.PropertyOwner}
              />
            </div>
          </div>
          <div className="homeloan-propertydetail-section flex">
            <div className="wd-50 mr-bg mb-md">
              <FormGroup
                name="LandBuilderName"
                value={data.LandBuilderName}
                onChange={onChange}
                label={"Builder's Name"}
                // validators={['required']}
                // error={data.errors.LandBuilderName}
              />
            </div>
            <div className="wd-50 mr-bg mb-md">
              <FormGroup
                formName="reactselect"
                name="BluePrintApproved"
                value={data.BluePrintApproved}
                onChange={onChange}
                options={[
                  {value: "1", label: "Yes"},
                  {value: "0", label: "No"},
                ]}
                label={"BluePrint Approved"}
                width={"50%"}
                validators={["required"]}
                error={data.errors.BluePrintApproved}
              />
            </div>
          </div>
        </>
      )}
      {data.PropertyType == propertyType[1].label && (
        <>
          <div className="homeloan-propertydetail-section flex">
            <div className="wd-50 mr-bg mb-md">
              <FormGroup
                name="ApartmentName"
                value={data.ApartmentName}
                onChange={onChange}
                label={"Apartment Name"}
                validators={["required"]}
                error={data.errors.ApartmentName}
              />
            </div>
            <div className="wd-50 mr-bg mb-md">
              <FormGroup
                name="ApartmentLocation"
                value={data.ApartmentLocation}
                onChange={onChange}
                label={"Apartment Location"}
                // width={"50%"}
                validators={["required"]}
                error={data.errors.ApartmentLocation}
              />
            </div>
          </div>
          <div className="homeloan-propertydetail-section flex">
            <div className="wd-50 mr-bg mb-md">
              <FormGroup
                name="ApartmentBuilderName"
                value={data.ApartmentBuilderName}
                onChange={onChange}
                label={"Builder's Name"}
                validators={["required"]}
                error={data.errors.ApartmentBuilderName}
              />
            </div>
            <div className="wd-50 mr-bg mb-md">
              <FormGroup
                name="ApartmentNumber"
                value={data.ApartmentNumber}
                onChange={onChange}
                label={"Apartment Number"}
                // width={"50%"}
                validators={["required"]}
                error={data.errors.ApartmentNumber}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default PropertyDetails;
