import React, { Component } from "react";

export class OfficeAndPosition extends Component {
  render() {
    const { data } = this.props;
    if (!data) {
      return null;
    }
    return (
      <div className="personal-row">
        <div className="personal-column">
          <div className="personal-block">
            <div className="personal-block__title">
              <h2>Office and Position</h2>
              {/* <Button title="Change" bg="subtle" buttonClass="personal-block__btn" /> */}
            </div>
            <div className="personal-block__body">
              <div className="personal-block__div">
                <div className="personal-label">Group</div>
                <div className="value">{data.Group}</div>
              </div>
              <div className="personal-block__div">
                <div className="personal-label">Level</div>
                <div className="value">{data.Level}</div>
              </div>
              <div className="personal-block__div">
                <div className="personal-label">Position</div>
                <div className="value">{data.Position}</div>
              </div>
              <div className="personal-block__div">
                <div className="personal-label">Branch</div>
                <div className="value">{data.Branch}</div>
              </div>
              <div className="personal-block__div">
                <div className="personal-label">Department</div>
                <div className="value">{data.Department}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="personal-column" style={{marginTop:17}}>
          <div className="personal-block">
            <div className="personal-block__title">
              <h2></h2>
              {/* <Button title="Change" bg="subtle" buttonClass="personal-block__btn" /> */}
            </div>
            <div className="personal-block__body">
              <div className="personal-block__div">
                <div className="personal-label">Join Date</div>
                <div className="value">{data.JoinDate}</div>
              </div>
              <div className="personal-block__div">
                <div className="personal-label">Permanent Date</div>
                <div className="value">{data.PermanentDateEng}</div>
              </div>
              <div className="personal-block__div">
                <div className="personal-label">Year of Service</div>
                <div className="value">{data.YearOfService}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OfficeAndPosition;
