import React, { Component } from "react";
import "./style.scss";
import { connect } from "react-redux";
import LoanListingTabs from "./loanListingTabs";
import SearchAndFilter from "./searchAndFilter";
import {
  GetDesignations,
  getLevels,
  DisplayClientSideMessage,
} from "actions/commonAction";
import {
  getEmployeeForwaredListToReview,
  getVehicleLoanListForAdmin,
  getStaffODListForAdmin,
  getStaffPersonalLoanListForAdmin,
  getQualifiedApprovers,
} from "./helpers/action";
import RouteEnum from "constants/routeEnum";
import isEmpty from "isEmpty";

export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      PageIndex: 0,
      PageSize: 10,
      EmployeeName: "",
      searchActionStatement: "",
      fromDate: null,
      Status1: 1,
      Status2: 3,
      toDate: null,
      activeJobRole: null,
      activePosition: null,
      activeStageId: 1,
      showCreateNewEmployee: false,
      isEditPage: false,
      LoanType: "",
      LoanStatus: "",
      isFinance: false,
      isCred: false,
      isLoading: true
    };
  }

  async componentDidMount() {
    const {
      user: { EmployeeId, RoleId, RoleName },
    } = this.props;
    await this.props.getQualifiedApprovers(EmployeeId);
    const { loanApproverList } = this.props;
    if (!isEmpty(loanApproverList)) {
      let employee = loanApproverList.find((loan) => loan.Id == EmployeeId);
      if (!isEmpty(employee) || (RoleId == 1 && RoleName == "Administrator")) {
        if (employee?.Role) {
          this.setState(
            {
              Status1: 1,
              Status2: employee?.Role == 3 ? 8 : 3,
              isFinance: employee?.Role == 3,
              isCred: employee?.Role == 2,
            },
            () => {
              this.getEmployeeForwaredListToReview();
            }
          );
        } else {
          this.getEmployeeForwaredListToReview();
        }
      } else {
        this.props.DisplayClientSideMessage(
          "You do not have permission to visit this page"
        );
        this.props.history.push(RouteEnum.EmployeeLoanList);
      }
    }
  }

  getEmployeeForwaredListToReview = async () => {
    let {
      PageIndex,
      PageSize,
      EmployeeName,
      Status1,
      Status2,
      activeStageId,
      LoanType,
      LoanStatus,
      isFinance,
      isCred,
    } = this.state;
    if (!isEmpty(LoanType)) LoanType = LoanType.value;
    else LoanType = "";
    const {
      user: { EmployeeId },
    } = this.props;
    debugger
    if (activeStageId == 1) {
      Status2 = isFinance ? 8 : isCred ? 2 : Status2; 
    }
    if (activeStageId == 2) {
      Status1 = 4;
      Status2 = 5;
    }
    if (activeStageId == 3) {
      Status1 = 0;
      Status2 = isFinance ? 3 : isCred ? 8 : 2;
    }
    if (!isEmpty(LoanStatus)) {
      Status1 = 0;
      Status2 = LoanStatus.value;
    }
    let searchDetail = {
      EmployeeId,
      Status1,
      Status2,
      PageIndex,
      PageSize,
      LoanType,
      EmployeeName,
      IsSelfForward: activeStageId == 3 ? true : false,
    };
    await this.props.getEmployeeForwaredListToReview(searchDetail);
    this.setState({ isLoading: false })
  };

  handleStageChange = (activeStageId) => {
    this.setState({ PageIndex: 0, activeStageId, LoanStatus: null }, async () => {
      await this.getEmployeeForwaredListToReview();
    });
  };

  handleAutoSelect = (name, selected) => {
    this.setState({ [name]: selected, PageIndex: 0 }, async () => {
      await this.getEmployeeForwaredListToReview();
    });
  };

  handleRowChange = (num) => {
    this.setState({ PageSize: num }, async () => {
      await this.getEmployeeForwaredListToReview();
    });
  };

  handleIndexChange = (num) => {
    this.setState({ PageIndex: num }, async () => {
      await this.getEmployeeForwaredListToReview();
    });
  };
  handleFormDisplay = (value) => {
    this.setState({ showCreateNewEmployee: value });
  };

  forwardToApprover = () => {};

  render() {
    let height = this.state.showCreateNewEmployee ? 0 : "auto";
    const {
      isLoading,
      isFinance,
      activeStageId,
    } = this.state;

    if (isLoading) return null;
    return (
      <div
        className="common-container employee-onboarding-table employee-homeloan"
        style={{ height: height }}
      >
        <SearchAndFilter
          handleAutoSelect={this.handleAutoSelect}
          parentState={this.state}
          isFinance={isFinance}
          activeStageId={activeStageId}
        />
        <LoanListingTabs
          handleIndexChange={this.handleIndexChange}
          handleRowChange={this.handleRowChange}
          handleStageChange={this.handleStageChange}
          parentState={this.state}
          forwardToApprover={this.forwardToApprover}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  forwardedLoanListToReview: state.loanReducer.forwardedLoanListToReview,
  loanApproverList: state.loanReducer.loanApproverList,
  user: state.auth.user,
});

const mapDispatchToProps = {
  GetDesignations,
  getLevels,
  getEmployeeForwaredListToReview,
  getVehicleLoanListForAdmin,
  getStaffODListForAdmin,
  getStaffPersonalLoanListForAdmin,
  getQualifiedApprovers,
  DisplayClientSideMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
