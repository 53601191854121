import * as React from "react";
import CommonFilters from "../common/commonFilters";
import TeamRequestHistoryBlock from "./teamRequestHistoryBlock";
import { GetTeamRequestHistory } from "../actions";
import { connect } from "react-redux";
import isEmpty from "isEmpty";

export interface IAppProps {
  GetTeamRequestHistory: Function;
  teamRequestHistory: any;
  location: any;
}

interface State {
  pageNumber: number;
  pageSize: number;
  employeeName: string;
  requestType: string;
  requestStartDate: string;
  requestEndDate: string;
  eventStartDate: string;
  eventEndDate: string;
}
class App extends React.Component<IAppProps, State> {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 0,
      pageSize: 10,
      employeeName: "",
      requestType: "",
      requestStartDate: null,
      requestEndDate: null,
      eventStartDate: null,
      eventEndDate: null,
    };
  }
  componentDidMount() {
    if (!isEmpty(this.props?.location?.state?.RequestType)) {
      const requestType = this.props?.location?.state.RequestType;
      this.setState({ requestType }, () => this.initialize());
    } else {
      this.initialize();
    }
  }
  handleRowChange = (num) => {
    this.setState({ pageSize: num }, () => {
      this.initialize();
    });
  };
  handleIndexChange = (num) => {
    this.setState({ pageNumber: num }, () => {
      this.initialize();
    });
  };
  handleFilterParamChange = (name, value) => {
    this.setState({ [name]: value } as Pick<State, keyof State>, () => {
      this.initialize();
    });
  };
  handleDateRangeChange = (name, startDate, endDate) => {
    this.setState(
      {
        [(name + "StartDate") as any]: startDate,
        [(name + "EndDate") as any]: endDate,
      } as Pick<State, keyof State>,
      () => {
        this.initialize();
      }
    );
  };
  initialize = () => {
    const {
      pageNumber,
      pageSize,
      employeeName,
      requestType,
      requestStartDate,
      requestEndDate,
      eventStartDate,
      eventEndDate,
    } = this.state;
    let searchData = {
      pageNumber,
      pageSize,
      employeeName,
      requestType,
      requestStartDate,
      requestEndDate,
      eventStartDate,
      eventEndDate,
    };
    this.props.GetTeamRequestHistory(searchData);
  };

  public render() {
    const { teamRequestHistory } = this.props;

    return (
      <div className="team-history">
        <CommonFilters
          handleFilterParamChange={this.handleFilterParamChange}
          handleDateRangeChange={this.handleDateRangeChange}
          {...this.props}
          showEmployeeFilter={true}
        />
        <div className="team-history__block employee-request-block">
          <TeamRequestHistoryBlock
            request={teamRequestHistory}
            handleRowChange={this.handleRowChange}
            handleIndexChange={this.handleIndexChange}
            pageIndex={this.state.pageNumber}
            rowsPerPage={this.state.pageSize}
            refreshRequestData={this.initialize}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  teamRequestHistory: state.employeeRequestReducer.teamRequestHistory,
});

const mapDispatchToProps = {
  GetTeamRequestHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
