import React from 'react'
import {FaPlusSquare,FaMinusSquare} from 'react-icons/fa';
import { useSelector } from 'react-redux';


function PayChanges(props) {
    const {data} = props;
    const payrollDashboard = useSelector(state => state.payrollDashboardReducer.payrollDashboard );
    const {PayChangesDetail}  = payrollDashboard;
    return (
        <div className="pay-changes">
            {
                payrollDashboard && PayChangesDetail ? 
                (
                    <ul className="pay-changes__list">
                        <List 
                            title="Pay revisions" 
                            count={PayChangesDetail.PayChangesCount} 
                            amounts={[PayChangesDetail.PayChagesAmount]}
                            />  
                        <List 
                            title="New Join" 
                            count={PayChangesDetail.NewJoinCount} 
                            amounts={[PayChangesDetail.NewJoinMonthlyAmount,PayChangesDetail.NewJoinThisMonthAmount]}
                            />  
                        <List 
                            title="Revisions" 
                            count={PayChangesDetail.TerminationCount} 
                            amounts={[PayChangesDetail.TerminationAmount]}
                            />                    
                    </ul>
                )
                :
                <span className="no-found">No Pay Changes Found</span>
            }
            
        </div>
    )
}

const List = ({title,count,amounts})=>{
    if(count || count ===0 ){
        return(
            <li className="pay-changes__item">
                <div className="pay-changes__title">
                    <span className="">{title}</span>
                    <span className="text-blue bold-500 text-md">{count}</span>
                </div>
                <div className="pay-changes__price">
                    {
                        amounts.map(item=>{
                            return(
                                <div className="pay-changes__price-item">
                                    <div className="pay-changes__icon">
                                        {item >= 0 ? 
                                            <span className="text-bg text-green">
                                                <FaPlusSquare />
                                            </span> : 
                                            <span className="text-bg text-red">
                                                <FaMinusSquare />
                                            </span>
                                            }
                                    </div>
                                    <div className="pay-changes__amount">
                                        <span>
                                            {item ? item >= 0 ? item : -item : 0}
                                        </span>
                                    </div>
                                </div>  
                            )
                        })
                    }
                    
                </div>
            </li>
            )
    }
    return null
}

export default PayChanges
