import React, { Component } from 'react'
import CustomAutoComplete from '../common/customAutoComplete'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from '../../helpers/isEmpty'
import {getBranches,getDepartments,getEmployees} from './action'
import {Skeleton} from '@material-ui/lab';
// import man from '../../img/man.png'

export class DataAutocomplete extends Component {
    componentDidMount = () => {
        const {actionType}=this.props;
        if(actionType==='employee' || actionType==='employeeAction'){
            this.props.getEmployees();
        }
    };
    render() {
        const {
                onChange,
                value,
                label,
                name,
                width,
                multiple,
                placeholder,
                selectReducer,
                actionType
            }=this.props;
            
        const Arrays = (data,name)=>{
            let arrayItem=[];
            if(data){
                data.map((item,key)=>{
                    item && arrayItem.push({label:item.FullName,value:item.EmployeeId,img:item.Imagepath})
                })
            }
            return arrayItem;
        }
        const data=selectReducer[actionType].value;
        const {loading}=selectReducer[actionType]
        let selValue=[];
        if(!isEmpty(data)){
            selValue= Arrays(data)
        }
        return (
            loading?
            <div className="form-group">
                <Skeleton variant="text" width={75} height={15}  />
                <Skeleton variant="rect" width={210} height={40}  />
            </div>
            :
            <CustomAutoComplete 
                onChange={onChange} 
                value={value} 
                options={selValue}
                label={!isEmpty(label)?label:''}
                placeholder={!isEmpty(placeholder)?placeholder:''}
                name={name}
                width={!isEmpty(width)?width:''}
                multiple={multiple}
            />
        )
    }
}


DataAutocomplete.propTypes = {
    getEmployees:PropTypes.func.isRequired,
    onChange:PropTypes.func.isRequired,
    value:PropTypes.any.isRequired,
    actionType:PropTypes.string.isRequired,
    label:PropTypes.string,
    width:PropTypes.string,
    placeholder:PropTypes.string,
    name:PropTypes.string.isRequired,
    selectReducer: PropTypes.object.isRequired,
};
    
const mapStateToProps = state => ({
    selectReducer: state.selectReducer,
});
    
export default connect(mapStateToProps, {getEmployees })(DataAutocomplete);