import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getBaseAxios } from "helpers/constants";
import Avatar from "react-avatar";
import { getUploadPath } from "constants/userDetails";
import { GetCandidateEmails } from "store/actions/recruitment/setting";

function Email(props: any) {
  const { selectedCandidate } = props;

  useEffect(() => {
    getCandidateEmails();
  }, []);

  const getCandidateEmails = async () => {
    const param = {
      pageIndex: 0,
      pageSize: 100000,
      candidateId: selectedCandidate.CandidateId,
      jobId: selectedCandidate.JobId,
    };
    await props.GetCandidateEmails(param);
  };

  // const email_default = [
  //   {
  //     EmailFrom: "Chetana Sharma",
  //     EmailSubject: "Re - Work Outstation",
  //     EmailBody:
  //       "Will there be additional pay when working outstation. I have to  move my family",
  //     EmailTime: "12 Jan (Sun) 5:30 pm",
  //     ImagePath: "",
  //   },
  //   {
  //     EmailFrom: "Chetana Sharma",
  //     EmailSubject: "Re - Work Outstation",
  //     EmailBody: "Yes, for one year, if required.",
  //     EmailTime: "12 Jan (Sun) 2:30 pm",
  //     ImagePath: "",
  //   },
  //   {
  //     EmailFrom: "Sumnima Sharma",
  //     EmailSubject: "Work Outstation",
  //     EmailBody: "Can you work at Biratnagar?",
  //     EmailTime: "12 Feb (Sun) 2:30 pm",
  //     ImagePath: "",
  //   },
  // ];

  return (
    <div className="dashboard-email">
      <div className="dashboard-email__header">
        {/* <span>E-Mail Conversations with Chetana Sharma</span> */}
        <span>E-Mail Conversations</span>
      </div>
      <div className="dashboard-email__body">
        {props.candidateEmails.map((email, index) => {
          return (
            <div className="email-row" key={index}>
              <div className="img-content">
                {/* <img
                  className="emp-image"
                  src={getBaseAxios() + "/images/photos/user2.png"}
                /> */}
                <Avatar
                  alt={email.UserName}
                  name={email.UserName}
                  src={getUploadPath(email?.UserImage)}
                  className="emp-img"
                  round
                  size="60"
                />
              </div>
              <div className="email-content">
                <span className="email-from">{email.UserName}</span>
                <span className="email-subject">{email.Subject}</span>
                <span className="email-body">
                  <div dangerouslySetInnerHTML={{ __html: email.Body }} />
                </span>
              </div>
              <div className="email-date">
                <span className="email-time">
                  {email.FormattedCreatedDateEng}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  candidateEmails: state.recruitment_settingReducer.candidateEmails,
});

const mapDispatchToProps = {
  GetCandidateEmails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Email);
