import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import SearchTeamMember from "scenes/tasks/board/taskBody/common/member/teamMember";
import Avatar from "react-avatar";
import { taskUpdateDetails } from "scenes/tasks/helpers/action";
import { TmViewType } from "scenes/tasks/helpers/enum";
import CustomPopup from "components/common/customPopup";
import { IoMdAdd } from "react-icons/io";
import classNames from "classnames";

const AvatarGroup = (props) => {
  const {
    selectedMembers,
    setSelectedMembers,
    maxCount,
    auth,
    task,
    rowIndex,
    stage,
    isStackView,
    hideAddButton,
  } = props;
  const [open, setOpen] = useState(false);

  const ref = useRef(null);
  const renderAvatar = () => {
    let items = [];
    const base =
      auth && auth.baseUrl
        ? props.auth.baseUrl.NetFrameworkAppBaseUrl
        : "" || "";
    let index = 0;
    // for (let i = length - 1; i >= 0; i--) {
    for (let i = 0; i < selectedMembers.length; i++) {
      const member = selectedMembers[i];
      if (i >= maxCount) break;
      items.push(
        <li
          key={index++}
          className={classNames({ stack: isStackView, grid: !isStackView })}
        >
          <div className="avatar-more">
            <Avatar
              className="avatar"
              name={member.FullName}
              src={`${base}${member.Imagepath}`}
              size={"24"}
              round={true}
            />
          </div>
        </li>
      );
    }
    let more = selectedMembers.length - maxCount;
    if (more > 0) {
      items.push(
        <li key={index++}>
          <div className="avatar-more">
            <div className="avatar-more-div">
              <span>+{more}</span>
            </div>
          </div>
        </li>
      );
    }
    !hideAddButton &&
      items.push(
        <li key={index++}>
          <div className="avatar-more">
            <div className="avatar-more-div avatar-more-add">
              <span>
                <IoMdAdd size={15} />
              </span>
            </div>
          </div>
        </li>
      );
    return items;
  };
  const handleSelectedMemberChanged = async (members) => {
    const updateTask = { ...task };
    updateTask.TeamMembers = members;

    await props.taskUpdateDetails({
      viewType: TmViewType.TableView,
      task: updateTask,
      stage,
      rowIndex,
    });
    setSelectedMembers(members);
  };
  return (
    <div
      className="taskBlock-avatar-group"
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
        setOpen(!open);
      }}
    >
      <ul className={classNames({ "no-margin": !isStackView })}>
        {renderAvatar()}
      </ul>
      {open && ref && ref.current && (
        <CustomPopup
          parentRef={ref}
          open={open}
          handleOutsideClick={() => setOpen(false)}
        >
          <div style={{ paddingTop: 10 }}>
            <SearchTeamMember
              disableRemoteSearch={true}
              selectedMembers={selectedMembers}
              onSelectionChange={handleSelectedMemberChanged}
              onMemberRemoved={handleSelectedMemberChanged}
            />
          </div>
        </CustomPopup>
      )}
    </div>
  );
};
AvatarGroup.defaultProps = {
  maxCount: 0,
  isStackView: true,
  hideAddButton: false,
};
export default AvatarGroup;
