import React from "react";
import Modal from "components/modal";

import AddLabel from "./addLabel";
const AddLabelModal = (props) => {
  const { task, open, setOpen } = props;
  // const [open, setOpen] = React.useState(false);
  const onModalClose = () => setOpen(false);
  return (
    <Modal
      // title="Header"
      onModalClose={onModalClose}
      open={open}
      width="350px"
      className="board-label-modal"
    >
      <div className="task-modal__body">
        <AddLabel onModalClose={onModalClose} {...props} />
      </div>
    </Modal>
  );
};
export default AddLabelModal;
