import "isomorphic-fetch";
import React, { useState, useEffect, Component } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import { components } from "react-select";

export class AsyncAutoSelect extends Component {
  ddlOption(props) {
    const { label } = props.data;
    return (
      <components.Option {...props}>
        <div className="input-row">
          <span className="input-value">{label}</span>
        </div>
      </components.Option>
    );
  }
  render() {
    const {
      options,
      value,
      width,
      name,
      label,
      placeholder,
      disabled,
    } = this.props;

    return (
      <AsyncSelect
        className="employees-select"
        classNamePrefix="tmdb_select"
        isSearchable="true"
        isClearable="true"
        cacheOptions
        components={{
          Option: this.ddlOption,
        }}
        defaultValue={value}
        onChange={(value) => this.props.onChange(name, value)}
        defaultOptions={options}
        loadOptions={(value) => this.props.loadOptions(value)}
        onBlur={() => this.props.loadOptions("")}
        placeholder={placeholder}
        isDisabled={disabled}
      />
    );
  }
}

AsyncAutoSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  label: PropTypes.string,
  width: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  selectReducer: PropTypes.object.isRequired,
};

export default AsyncAutoSelect;
