import React, { useEffect, useState, useRef } from "react";
import { TabsInterface, TabInterface } from "ts/interfaces/componentInterface";
import isEmpty from "helpers/isEmpty";

import TabItem from "./tabItem";
import TabBlockItems from "./tabBlockItems";

function Tabs(props: TabsInterface) {
  const { children, activeTab, type } = props;

  const [active, setActive] = useState("");

  const [single, setSingle] = useState(null);

  //
  useEffect(() => {
    //Setting active tab by default, if more than 1 tab 1st tab is active
    if (Array.isArray(children)) {
      setSingle(false);
      if (isEmpty(activeTab)) {
        const {
          props: { label: tabLabel, children: tabChilren, name: tabName },
        } = children[0];
        setActive(tabName);
      } else {
        setActive(activeTab);
      }
    } else {
      setSingle(true);
      const {
        props: { label: tabLabel, children: tabChilren, name: tabName },
      } = children;
      setActive(tabName);
    }
    // const len = children.length;
  }, [activeTab]);

  const onClickTabItem = (tab: string) => {
    setActive(tab);
  };

  const renderTabType = (): any => {
    switch (type) {
      case "block":
        //Tab style block with expand and collapse
        return (
          <TabBlockItems
            single={single}
            onClick={onClickTabItem}
            active={active}
            {...props}
          />
        );
      //Normal tab
      default:
        return (
          <TabItem
            single={single}
            onClick={onClickTabItem}
            active={active}
            {...props}
          />
        );
    }
  };
  return <div>{renderTabType()}</div>;
}

Tabs.defaultProps = {
  type: "normal",
};

export default Tabs;
