import React, { Component,Suspense  } from "react";
import classnames from "classnames";
import man from "assets/images/man.png";
import isEmpty from 'isEmpty';
import { ConfigProvider } from "react-avatar"; 
import {Tabs,Tab} from 'components/tabs/index.d'
import EmployeeAvatar from 'components/common/employeeAvatar';

export class MyTeam extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "TeamMembers",
      activeCard: "",
      currentCard: {}
    };
    this.timerId = null;
    this.teamCard = null;
    this.onTabClick = this.onTabClick.bind(this);
    this._renderTabList = this._renderTabList.bind(this);
    this._renderTabBody = this._renderTabBody.bind(this);
    this._renderTabImage=this._renderTabImage.bind(this)
  }
  onTabClick(item) {
    this.setState({ activeTab: item });
  }
  _renderTabList() {
    const { activeTab } = this.state;
    const {TeamMembers,OnLeave,Birthday,Absent}= this.props;
    const getClass = type => {
      return classnames({
        "team-block__tablist-item": true,
        "active-tab": activeTab === type
      });
    };
    
    const badgeActive ={
      dark:' badge-dark-light',
      red:' badge-danger',
      blue:' badge-blue',
    };
    const TeamTab = (name,length) => <span className="team-block__tablist-context">
                          <span>{name}</span>
                          <span className={`badge`}>{length}</span>
                      </span>
    
    return (
      <Tabs>
          <Tab label={TeamTab('My Managers' ,TeamMembers?.length)} id="My Managers" name="My Managers">
            {
              this._renderTabImage(this.props['TeamMembers'])
            }
          </Tab>
          <Tab label={TeamTab('On Leave' ,OnLeave?.length)} id="On Leave" name="On Leave">
            {
              this._renderTabImage(this.props['OnLeave'])
            }
          </Tab>
          <Tab label={TeamTab('Absent' ,Absent?.length )} id="Absent" name="Absent">
            {
              this._renderTabImage(this.props['Absent'])
            }
          </Tab>
          <Tab label={TeamTab('Birthdays' ,Birthday?.length )} id="Birthdays" name="Birthdays">
            {
              this._renderTabImage(this.props['Birthday'])
            }
          </Tab>
      </Tabs>
    );
  }
  _renderTabImage(props){
    const { activeTab,currentCard } = this.state;
    if(isEmpty(props) || props.length===0){
      return(<span className="no-found">
        No {activeTab} Found
      </span>)
    }
    return(
      <>
        <ul className="team-images-list">
          {props && props.map((person,index) => {
            return (
              <li key={index + Math.random()} className="team-images-list__item">
                <div className="team-image">
                    <EmployeeAvatar employee={person} />
                </div>
              </li>
            );
          })}
        </ul>
      </>
    )
  }
  _renderTabBody() {
    const { activeTab, currentCard } = this.state;
    
    if (!activeTab) {
      return null;
    }
    return (
      <div className="team-block__images">
        {
           this._renderTabImage(this.props[activeTab])
        }
      </div>
    );
  }
  render() {
    return (
      <div className="team">
        <h3 className="bloch__title">My Team</h3>
        <div className="team-block">
          <ConfigProvider colors={["#ffefee","#e6f1ff"]}>
              <div className="team-block__tab">{this._renderTabList()}</div>
          </ConfigProvider>
          {/* <div className="team-block__body">{this._renderTabBody()}</div> */}
        </div>
      </div>
    );
  }
}

export default MyTeam;
