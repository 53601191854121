import React, { Component } from "react";
import "./icons.styles.scss";
export const email = () => {
  return (
    <svg
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="email">
        <g id="env">
          <path
            id="back"
            d="M422.25 512H89.75C76.64 512 66 500.352 66 486V252C66 237.648 76.64 226 89.75 226H422.25C435.36 226 446 237.648 446 252V486C446 500.352 435.36 512 422.25 512Z"
            fill="#1E88E5"
          />
          <path
            id="bottom"
            d="M422.25 512H89.75C76.4263 512 66 500.586 66 486C66 481.866 67.805 477.966 70.845 475.522L248.97 345.522C251.06 343.832 253.53 343 256 343C258.47 343 260.94 343.832 263.03 345.522L441.155 475.522C444.195 477.966 446 481.866 446 486C446 500.586 435.574 512 422.25 512Z"
            fill="#2196F3"
          />
          <path
            id="top"
            d="M422.25 226H89.75C76.4263 226 66 237.414 66 252C66 256.134 67.805 260.034 70.845 262.478L249.065 402.54C253.198 405.79 258.779 405.79 262.911 402.54L441.131 262.478C444.195 260.034 446 256.134 446 252C446 237.414 435.574 226 422.25 226Z"
            fill="#64B5F6"
          />
        </g>
      </g>
    </svg>
  );
};
