import React from "react";
import isEmpty from "isEmpty";
import Icons from "components/icons";
import classnames from 'classnames';

interface LoanProps {
  data: Array<any>,
  title?: string
}

export default function LoanDataTable({ data, title }: LoanProps) {

  const dropClass = (row, header, colorCode) => classnames({
    "loan-datatable__row": true,
    "loan-datatable__borderunset": row !== 0,
    [`loan-datatable__header__${colorCode} flex`]: header,
    "loan-datatable__headerText": !header,
    [`loan-datatable__headerText__${colorCode}`]: !header
  });

  // const { data, title } = props;
  return (
    <div>
      {title && <div className="consumer-loan__payment__schedule__title mb-sm text-bg">
        {title}
      </div>}
      <div className="loan-datatable__body">
        {!isEmpty(data) && data.map((d, i) => {
          return < div className="loan-datatable" >
            <div className={dropClass(i, true, d.colorCode)}>
              {d.title}
              {d.ticker && <Icons name="CircleCheck" className="checkmark-align ml-sm" color="green" />}
            </div>
            <div className={dropClass(i, false, d.colorCode)}>
              {d.amount}
            </div>
          </div>
        })}
      </div>

    </div>
  )
}