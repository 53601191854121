import React, { useState, useEffect } from "react";
import TextareaAutosize from "react-textarea-autosize";

const TaskDescription = (props) => {
  const { task, name, onEnter, isValidUser } = props;
  const [value, setValue] = useState("");
  const [show, setShow] = useState(false);
  const [edited, setEdited] = useState(false);
  useEffect(() => {
    if (task) setValue(task[name]);
  }, [task.TaskName]);

  const onChange = (e) => {
    setEdited(true);
    setValue((e.target && e.target.value && e.target.value.trim()) || "");
  };
  const onKeyUp = (e) => {
    if (e.key === "Enter") {
      setShow(false);
      setEdited(false);
      onEnter && onEnter(e, name, value);
    }
  };
  const onBlur = (e) => {
    edited && onEnter && onEnter({ key: "Enter" }, name, value);
    setShow(false);
    setEdited(false);
  };
  
  return (
    <div>
      {!show ? (
        <h3
          className="taskmodal__title"
          onClick={() => {
            if (isValidUser()) {
              setValue(task[name]);
              setShow(true);
            }
          }}
        >
          <p className="taskmodal__desc">{task[name] || "Description...."}</p>
        </h3>
      ) : (
        <TextareaAutosize
          className="genericForm-group__input"
          minRows={1}
          autoFocus
          name={name}
          value={value}
          placeholder="Write a Description..."
          onChange={onChange}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
        />

        // <FormGroup
        //   autoFocus
        //   type="textarea"
        //   name={name}
        //   value={value}
        //   placeholder="Description"
        //   onChange={(name, value) => setValue(value)}
        //   onKeyUp={(e) => {
        //     if (e.key === "Enter") {
        //       setShow(false);
        //       onEnter && onEnter(e, name, value);
        //     }
        //   }}
        // />
      )}
    </div>
  );
};

export default TaskDescription;
