import React, { useState, useEffect } from "react";
import classnames from "classnames";
import Modal from "components/modal";
import FormGroup from "components/form";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import isEmpty from "isEmpty";
import {
  // GetAllEmployeeListWithPhoto,
  GetEmployeeList,
} from "actions/commonAction";
import { connect } from "react-redux";

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 20,
    height: 20,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#d9d9d9",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 20,
      height: 20,
      backgroundImage: "radial-gradient(#666,#666 40%,transparent 44%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#d1d1d1",
    },
  },
});
const StyledRadio = (props) => {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      onChange={props.onChange}
      checkedIcon={
        <span
          className={classnames({
            [classes.icon]: true,
            [classes.checkedIcon]: true,
          })}
        />
      }
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
};

function HiringTeamModel(props) {
  const [showModal, setModal] = useState(false);
  const [state, setState] = useState(props.state);

  useEffect(() => {
    console.log("hirirng model is::");
    console.log(props);
    props.GetEmployeeList();
  }, []);

  useEffect(() => {
    setModal(props.showModal);
  }, [props.showModal]);

  const onModalClose = () => {
    //setModal(false);
    props.handleModelClose(false);
  };
  const onValidate = (data) => {
    const { access, user } = data;
    const errors = {};
    if (isEmpty(access)) {
      errors.access = "Access Field is Required";
    }
    if (isEmpty(user)) {
      errors.user = "User Field is Required";
    }
    return { isAuth: isEmpty(errors), errors };
  };
  const onSelectChange = (name, value) => {
    let nextState = { ...state };
    state[name] = value;
    setState(nextState);
    console.log(state);
    props.handleChange(name, value);
  };

  const onRadioChange = (e) => {
    const { name, value } = e.target;
    let nextState = { ...state };
    state[name] = value;
    setState(nextState);
    props.handleChange(name, value);
  };

  const onAdd = () => {
    const { access, user } = state;
    const { isAuth, errors } = onValidate({ access, user });

    setState({ errors });
    if (!isAuth) {
      return false;
    }
    const { employeeList } = props;
    const selectedEmployee = employeeList.find(
      (x) => x.EmployeeId === user.value
    );
    const data = {
      Id: 0,
      JobRef_Id: 0,
      UserId: user.value,
      HasStandardAccess: access !== "all",
      HasAllAccess: access === "all",
      Name: selectedEmployee.FullName,
      Email: selectedEmployee.Email,
      Designation: selectedEmployee.Designation,
      //UserId: shortid.generate(),
      ImagePath: selectedEmployee.Imagepath,
    };
    props.handleOnAdd(data);
  };
  const renderForm = () => {
    const { errors, user } = state;
    const standard = (
      <div className="hiringTeam-form__radioLabel">
        <h4>Standar Access</h4>
        <p>Standard Access user can collaborate on this job</p>
      </div>
    );
    const all = (
      <div className="hiringTeam-form__radioLabel">
        <h4>All Access</h4>
        <p>All Access user can modify,publish or unpublish this job</p>
      </div>
    );
    const { HiringTeam, employeeListSelect } = props;
    let options = [];
    employeeListSelect &&
      employeeListSelect.map((x) => {
        if (
          !isEmpty(HiringTeam) &&
          HiringTeam.filter((y) => y.UserId === x.value).length === 0
        ) {
          options.push(x);
        }
      });

    return (
      <div className="hiringTeam-modal">
        <div className="hiringTeam-form">
          <div
            className={classnames({
              "hiringTeam-form__input": true,
              "auto-error": !isEmpty(errors.user),
            })}
          >
            <label className="label" htmlFor="user">
              Select a user
            </label>
            <FormGroup
              formName="reactselect"
              name="user"
              onChange={onSelectChange}
              value={user}
              options={options}
              // label=""
              width={"100%"}
            />
            {errors.user && (
              <span className="auto-error__message">{errors.user}</span>
            )}
          </div>
          <div className="hiringTeam-form__input">
            <RadioGroup defaultValue={""} aria-label="access" name="access">
              <FormControlLabel
                value="standard"
                control={<StyledRadio onChange={onRadioChange} />}
                label={standard}
                style={{ marginBottom: 20 }}
              />
              <FormControlLabel
                value="all"
                control={<StyledRadio onChange={onRadioChange} />}
                label={all}
              />
            </RadioGroup>
            {errors.access && (
              <span className="auto-error__message">{errors.access}</span>
            )}
          </div>
        </div>
        <div className="hiringTeam-modal__footer modal-footer">
          <button onClick={() => onAdd()} className="btn btn-blue">
            Add
          </button>
          <button onClick={() => onModalClose()} className="btn btn-grey2">
            Cancel
          </button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={showModal}
      onModalClose={onModalClose}
      className="hiring-stages-modal"
      title={"Add a new member"}
    >
      {renderForm()}
    </Modal>
  );
}
const mapStateToProps = (state) => ({
  commonReducer: state.commonReducer,
  employeeList: state.commonReducer.employeeList,
  employeeListSelect: state.commonReducer.employeeListSelect,
});

const mapDispatchToProps = {
  GetEmployeeList,
};
export default connect(mapStateToProps, mapDispatchToProps)(HiringTeamModel);
