import {
  GET_DISCIPLINARY_STAGES,
  EDIT_DISCIPLINARY_STAGES,
  ADD_DISCIPLINARY_SUBSTAGES,
  DELETE_DISCIPLINARY_SUBSTAGES,
  ADD_LOADING,
  DISCIPLINARY_STAGES_LOADING,
  EDIT_DISCIPLINARY_SUB_STAGES
} from "actions/types";
import axios from "axios";
import isEmpty from "isEmpty";
import { toastMessage } from "actions/validateAction";

export const getDisciplinaryStages = () => async (dispatch) => {
  addLoading(dispatch, true);
  await axios
    .get("/v1/disciplinary-action/stages")
    .then((response) => {
      addLoading(dispatch, false);
      dispatch({
        type: GET_DISCIPLINARY_STAGES,
        payload: response.data,
      });
    })
    .catch((err) => {
      addLoading(dispatch, false);
      dispatch({
        type: GET_DISCIPLINARY_STAGES,
        payload: null,
      });
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to get disciplinary stages.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const updateDisciplinaryStages = (data) => async (dispatch) => {
  addLoading(dispatch, true);
  await axios
    .put("/v1/disciplinary-action/stages", data)
    .then((response) => {
      addLoading(dispatch, false);
      if (response.data.Status) {
        dispatch({
          type: EDIT_DISCIPLINARY_STAGES,
          payload: response.data.Data,
        });
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      addLoading(dispatch, false);
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to get disciplinary actio stages list.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const addDisciplinarySubStages = (data) => async (dispatch) => {
  addLoading(dispatch, true);
  await axios
    .post("/v1/disciplinary-action/sub-stages", data)
    .then((response) => {
      addLoading(dispatch, false);
      if (response.data.Status) {
        dispatch({
          type: ADD_DISCIPLINARY_SUBSTAGES,
          payload: response.data.Data,
        });
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      addLoading(dispatch, false);
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to get disciplinary actio stages list.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const updateDisciplinarySubStages = (data) => async (dispatch) => {
  addLoading(dispatch, true);
  await axios
    .put("/v1/disciplinary-action/sub-stages", data)
    .then((response) => {
      addLoading(dispatch, false);
      if (response.data.Status) {
        dispatch({
          type: ADD_DISCIPLINARY_SUBSTAGES,
          payload: response.data.Data,
        });
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      addLoading(dispatch, false);
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to get disciplinary actio stages list.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const deleteDisciplinarySubStages = (id) => async (dispatch) => {
  addLoading(dispatch, true);
  await axios
    .delete(`/v1/disciplinary-action/sub-stages?subStageId=${id}`)
    .then((response) => {
      addLoading(dispatch, false);
      if (response.data.Status) {
        dispatch({
          type: DELETE_DISCIPLINARY_SUBSTAGES,
          // payload: response.data.data,
          payload: id,
        });
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      addLoading(dispatch, false);
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to get disciplinary actio stages list.",
        },
      };
      toastMessage(dispatch, response);
    });
};

const addDisLoading = () => {
  return {
    type: DISCIPLINARY_STAGES_LOADING,
  };
};

const addLoading = (dispatch, loading) => {
  dispatch({
    type: ADD_LOADING,
    payload: loading,
  });
};
