import React from "react";
import Avatar from "react-avatar";
import {getUploadPath} from 'constants/userDetails';

function RunningClock({ RunningClocks }) {
  if (!RunningClocks || RunningClocks.length <= 0) {
    return null;
  }

  return (
    <div className="mt-sm">
      <div className="activity-title">Running Clocks</div>

      <div className="new-dashboard-group">
        {RunningClocks &&
          RunningClocks.map((clock, index) => (
            <div className="new-dashboard-running_clock" key={index}>
              <Avatar
                className="new-dashboard-running_clock-image"
                name={clock.MemberName}
                size={70}
                round={true}
                src={getUploadPath(clock.ImgPath)}
              />

              <span className="new-dashboard-running_clock-name">
                {clock.MemberName || "-"}
              </span>
              <span className="new-dashboard-running_clock-project">
                {clock.ProjectName || "-"}
              </span>
              <span className="new-dashboard-running_clock-time">
                {clock.SpentTime || "-"}
              </span>
            </div>
          ))}
      </div>
    </div>
  );
}

export default RunningClock;
