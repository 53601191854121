import React from "react";
import { Link } from "react-router-dom";
import RouteEnum from 'constants/routeEnum'

export default function TodaysClock({ data, isApproverReviewer }) {
  if (!data || data.length <= 0) {
    return null;
  }

  const activityTitle = (text, value) => {
    return (
      <div className="activity_activities_title">
        <span>{text}</span>
        <span className="month">{value}</span>
      </div>
    );
  };

  const activityPendingBlock = (value, isThisMonth, isMyActivities) => {
    return (
      // <Link to={isThisMonth && RouteEnum.ActivityReportPath}>
      <Link
        to={{
          pathname: RouteEnum.ActivityReportPath,
          state: {
            isThisMonth,
            isMyActivities,
          },
        }}
        // `${RouteEnum.ActivityReportPath}/isThisMonth=${isThisMonth}&isMyActivities=${isMyActivities}`}
      >
        <div>
          <span className="count">{value || 0}</span>
          <span className={"activity-reviews"}>activities pending Review</span>
        </div>
      </Link>
    );
  };

  const clientProjectBlock = (value) => {
    return (
      <div>
        <span className="count">{value}</span>
        <span className="reviews">client projects</span>
      </div>
    );
  };

  const reportedHoursBlock = (value) => {
    return (
      <div>
        <span className="count">
          {value ? parseFloat(value / 60).toFixed(1) : 0}
        </span>
        <span className="reviews">reported hours</span>
      </div>
    );
  };

  const billableHoursBlock = (value) => {
    return (
      <div>
        <span className="count">
          {value ? parseFloat(value / 60).toFixed(1) : 0}
        </span>
        <span className="reviews">bilable hours</span>
      </div>
    );
  };

  const myThisMonthActivity = data[0];
  const mylastMonthActivity = data[1];
  const teamThisMonthActivity = data[2];
  const teamLastMonthActivity = data[3];

  return (
    <div className="activity_activities">
      <div className="activity_activities_column first_col">
        <div className="activity-title">My activities</div>
        <div className="activity_activities_one">
          <div className="activity_activities_body">
            {activityTitle("This Month", myThisMonthActivity.Title)}
            <div className="activity_activities_this_month">
              {/* {activityPendingBlock(
                myThisMonthActivity.ActivitiesPendingReviewCount,
                true,
                true
              )} */}
              {clientProjectBlock(myThisMonthActivity.ClientProjectCount)}
              {reportedHoursBlock(myThisMonthActivity.ReportedHourCount)}
              {billableHoursBlock(myThisMonthActivity.BillableHourCount)}
            </div>
          </div>
          <div className="activity_activities_body">
            {activityTitle("Last Month", mylastMonthActivity.Title)}
            <div className="activity_activities_this_month">
              {/* {activityPendingBlock(
                mylastMonthActivity.ActivitiesPendingReviewCount,
                false,
                true
              )} */}
              {clientProjectBlock(mylastMonthActivity.ClientProjectCount)}
              {reportedHoursBlock(mylastMonthActivity.ReportedHourCount)}
              {billableHoursBlock(mylastMonthActivity.BillableHourCount)}
            </div>
          </div>
        </div>
      </div>

      {isApproverReviewer && (
        <div className="activity_activities_column">
          <label htmlFor="activities">My team</label>
          <div className="activity_activities_one">
            <div className="activity_activities_body">
              {activityTitle("This Month", teamThisMonthActivity.Title)}
              <div className="activity_activities_this_month">
                {/* {activityPendingBlock(
                  teamThisMonthActivity.ActivitiesPendingReviewCount,
                  true,
                  false
                )} */}
                {clientProjectBlock(teamThisMonthActivity.ClientProjectCount)}
                {reportedHoursBlock(teamThisMonthActivity.ReportedHourCount)}
                {billableHoursBlock(teamThisMonthActivity.BillableHourCount)}
              </div>
            </div>
            <div className="activity_activities_body">
              {activityTitle("Last Month", teamLastMonthActivity.Title)}
              <div className="activity_activities_this_month">
                {/* {activityPendingBlock(
                  teamLastMonthActivity.ActivitiesPendingReviewCount,
                  false,
                  false
                )} */}
                {clientProjectBlock(teamLastMonthActivity.ClientProjectCount)}
                {reportedHoursBlock(teamLastMonthActivity.ReportedHourCount)}
                {billableHoursBlock(teamLastMonthActivity.BillableHourCount)}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
