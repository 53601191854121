export const jobsTypeNewList = [
  { label: "All Active Jobs", value: "active" },
  { label: "All Closed Jobs", value: "closed" },
];

export const jobTypeList = [
  { label: "Active Jobs", value: "active" },
  { label: "Closed Jobs", value: "closed" },
];

export const timeOptionList = [
  { label: "This Week", value: 1 },
  { label: "Last Week", value: 2 },
  { label: "This Month", value: 3 },
  { label: "Last Month", value: 4 },
  { label: "This Quater", value: 5 },
  { label: "This Year", value: 6 },
  { label: "Last Year", value: 7 },
];

export const JobStageList = [
  { label: "Openings", value: -1 },
  { label: "Sourced", value: -2 },
  { label: "Applied", value: -3 },
  { label: "Shortlisted", value: 1 },
  { label: "Written Exam", value: 3 },
  { label: "Interview", value: 4 },
  { label: "Job Offer", value: 5 },
  { label: "Hired", value: 6 },
];

export const JobStages = {
  Openings: -1,
  Sourced: -2,
  Applied: -3,
  Revert: 0,
  Shortlisted: 1,
  Screening_Call: 2,
  Written_Exam: 3,
  Interview: 4,
  Job_Offer: 5,
  Hired: 6,
  Disqualified: 10,
  Reject: 11,
};

export const jobApply_components = {
  Personal_Information: 1,
  Address: 2,
  Family_Information: 3,
  Identification_Information: 4,
  Education: 5,
  Training: 11,
  Work_Experience: 6,
  References: 7,
  Cover_Letter: 8,
  Resume: 9,
  Payment_Voucher: 10,
};

export const ComposeEmailType = {
  recruitment: "recruitment",
  scheduleInterview: "scheduleInterview",
};
export const Mandatory = 1;
export const Optional = 2;
export const Off = 3;
