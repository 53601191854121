import React from "react";
import { Tabs, Tab } from "components/tabs/index.d";
import isEmpty from "isEmpty";
import { useSelector } from "react-redux";

function LeaveTab() {
  const { employeeLeaveList } = useSelector(
    (state: any) => state.employeeProfile
  );
  console.log(employeeLeaveList);
  const renderData = () => {
    if (!isEmpty(employeeLeaveList)) {
      return <Active data={employeeLeaveList.LeaveTracking} />;
    }
    return <div className="no-tab-data">No active leave found</div>;
  };
  return (
    <div className="leave-tabs">
      <Tabs>
        <Tab label="Active" name="Active">
          {renderData()}
        </Tab>
        <Tab label="Disabled" name="Disabled">
          <div className="no-tab-data">no disabled leaves found</div>
        </Tab>
      </Tabs>
    </div>
  );
}

const Active = (props) => {
  const { data } = props;
  return (
    <div className="leave-table">
      <div className="leave-table__header">
        <div className="leave-table__row">
          <div className="leave-table__col-header">Leave Name</div>
          <div className="leave-table__col-header">Accrual</div>
          <div className="leave-table__col-header">Eligeble</div>
          <div className="leave-table__col-header"></div>
        </div>
      </div>
      <div className="leave-table__body">
        {data.map((item) => {
          return (
            <div className="leave-table__row">
              <div className="leave-table__col">
                <div className="flex">
                  <div className="table__circle"></div>
                  <span className="text-primary">{item.Title}</span>
                </div>
              </div>
              <div className="leave-table__col">
                <span>{item.Accrue}</span>
              </div>
              <div className="leave-table__col">
                <span>{item.Status === "Eligible" ? "Yes" : "No"}</span>
              </div>
              <div className="leave-table__col"></div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LeaveTab;
