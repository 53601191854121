import React from 'react'
import man from 'assets/images/man.png'
import Avatar from "react-avatar";
import { useSelector } from 'react-redux';
import isEmpty from 'isEmpty';
import RouteEnum from 'constants/routeEnum'
import {Link} from 'react-router-dom'

function EmployeeList(props) {
    const {employees} = props;
    const payrollDashboard = useSelector(state => state.payrollDashboardReducer.payrollDashboard );
    const {EmployeeLatestEvent}  = payrollDashboard;
    return (
        <div className="employees-list">
            {
                payrollDashboard && !isEmpty(EmployeeLatestEvent) ? 
                EmployeeLatestEvent.slice(10,0).map(renderEmployee) : <div className="no-found">No Employee Found</div>
            }
        </div>
    )
}

export const renderEmployee = (props,index)=>{
    return(
        <div key={index + Math.random()} className="employees-list__item employee">
            <div className="employee-avatar">
                <Avatar
                    className="employee-logo"
                    name={props.Name}
                    size={40}
                    round={true}
                    src={props.UrlPhoto}
                />
            </div>
            <div className="employee-value">
                <EmployeeLink id={props.EmployeeId} name={props.Name}  />
                <div className="flex">
                    <span className="position">{props.LatestEventName}</span>
                </div>
                <div>
                <span className="branch text-pink">{props.StatusName}</span>
                </div>
            </div>
        </div>
    )
}


const EmployeeLink = ({id,name})=>{
    return(
        // <a 
        //     target="_blank"
        //     href={`/newhr/EmployeeDetails.aspx?Id=${id}`} 
        //     className="employee-name">
        //     <span>{name}</span>
        // </a>
        <Link to={`${RouteEnum.EmployeeProfile}/${id}`}>
            <span>{name}</span>
        </Link>
    )
}

export default EmployeeList
