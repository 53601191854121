import React, { useState, useEffect } from "react";
import Button from 'components/button'
import FormGroup from 'components/form'

function SalaryReview({
  onClose, getSalaryDistribution, distributedIncomeByAmount,
  grossPay, salaryTemplateDetail, changeGrossPay, changeDistributionPercentage,
  recalculateSalary, changeRecalculated, submitEmployeeSalaryStructure, DisplayClientSideMessage,
  totalPercentage, templateId
}) {
  const [payAmountInput, setPayAmountInput] = useState(null);
  const [isGrossPay, setIsGrossPay] = useState(null);
  useEffect(() => {
    if (salaryTemplateDetail.SalaryTemplateDto
      && salaryTemplateDetail.SalaryTemplateDto.IsOnGrossDistribution) {
      setPayAmountInput(grossPay);
      setIsGrossPay(true)
    } else {
      let basicAmount = distributedIncomeByAmount.SalaryTemplateDetailDto.find(d => d.Title == 'Basic Salary');
      setPayAmountInput(basicAmount.Amount);
      changeGrossPay(basicAmount.Amount);
    }
  }, []);

  const calculateSalaryDistribution = () => {
    
    if (totalPercentage != 100 && isGrossPay) {
      DisplayClientSideMessage('Adjust Salary Percentage before distributing');
    } else {
      changeRecalculated(true);
      getSalaryDistribution(true);
    }
  }
  const currencyCommaSeperator = (num) => {
    if (isNaN(num)) return "0";
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  }
  const changeSalaryAmount = (name, value) => {
    changeRecalculated(false)
    setPayAmountInput(value);
    changeGrossPay(value);
  }

  const changeDistributionPercentageField = (name, value, salary) => {
    changeRecalculated(false);
    changeDistributionPercentage(name, value, salary)
  }

  const onSalaryTemplateSave = () => {
    if(!recalculateSalary) {
      DisplayClientSideMessage('Distribute Salary Before Saving');
    } else {
      submitEmployeeSalaryStructure(isGrossPay)
    }
  }
  return (
    <div className="salary-review">
      <div className="salary-review__container">
        <div className="salary-review__header">
          <div className="salary-review__header-title">
            Template: { templateId ? templateId.label : salaryTemplateDetail?.SalaryTemplateDto?.TemplateName}
          </div>
          <div className="salary-review__header-subtitle">
            <span>Rule Based on {isGrossPay ? 'Gross' : 'Basic'} Salary</span>
            <span></span>
            <span>{isGrossPay ? 'Gross' : 'Basic'} pay</span>
            <span>
              <FormGroup
                autoFocus
                name="grossPay"
                value={payAmountInput}
                onChange={(name, value) => changeSalaryAmount(name, value)}
                type={"number"}
                minValue={"0"}
                validators={["required"]}
              />
            </span>
            <span>
              <Button
                title="Re-calculate"
                bg="secondary"
                onClick={() => calculateSalaryDistribution()}
              />
            </span>
          </div>
        </div>
        <div className="salary-review__body">
          {distributedIncomeByAmount?.SalaryTemplateDetailDto &&
            distributedIncomeByAmount?.SalaryTemplateDetailDto.map(sal =>
              <>
                <div className="salary-review__row">
                  <span>{sal.Title}</span>
                  <span>{sal.Type}</span>
                  {((sal.Abbreviation != 'BS' && !isGrossPay) || (isGrossPay)) ?
                    <FormGroup
                      name="new_amount"
                      type="number"
                      value={sal.Percentages}
                      disabled={sal.Type !== 'Fixed' || (sal.Abbreviation == 'BS' && !isGrossPay)}
                      alignment="horizontal"
                      onChange={(name, value) => changeDistributionPercentageField(name, value, sal)}
                    /> : <span>-</span>}
                    
                  <span></span>
                  <span className="salary-review-total">Rs. {currencyCommaSeperator(Math.floor(sal.Amount).toFixed(2))}</span>
                </div>
              </>)}
          <div className="salary-review__row totalRow">
            <span>Total</span>
            <span></span>
            <span className="align-center">{totalPercentage.toFixed(2)}</span>
            <span></span>
            <span className="salary-review-total">Rs. {currencyCommaSeperator(Math.floor(distributedIncomeByAmount.TotalAmount).toFixed(2))}</span>
          </div>
        </div>
      </div>

      <div className="salary-review__footer">
        <Button bg="primary" title="Save" onClick={() => onSalaryTemplateSave()} />
        <Button bg="subtle" onClick={() => onClose()} title="Cancel" />
      </div>
    </div>
  )
}

export default SalaryReview
