import React, { Component } from "react";
import Dropdown from "../../components/common/dropDown";
// import Dropdown2 from '../../Components/Dropdown/Dropdown2'
import SideTab from "./sideTab";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import {
  GetBranches,
  GetDepartments,
  GetDesignations,
  GetJobStatus,
  GetEmployeeList,
} from "actions/commonAction";
// import { GetEmployeeListForAdmin } from "../employee/employeeList/action";
import {
  getFiscalYear,
  getPayrollPeriods,
  selectedPayrollPeriods,
} from "./action";
import isEmpty from "isEmpty";
import { Skeleton } from "@material-ui/lab";
import parseQuery from "../../helpers/parseQuery";
import loadable from "react-loadable";
import AttendanceRegister from "./attendanceRegister";
import LeaveAbsence from "./leaveAbsence";
import StopPayment from "./stopPayment";
import IncomeCalculation from "./incomeCalculation";
import TaxAdjustment from "./taxAdjustment";
import ReviewFinalize from "./reviewFinalize";
import SalaryAdjustment from "./salaryAdjustment";
import ArrearCalculation from "./arrearCalculation";
import RFAdjustment from "./rfAdjustment/rfAdjustment";
import TaxCalculation from "./taxCalaulation";
const MyContext = React.createContext();

export class Payroll extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onOpen: false,
      onPayrollOpen: false,
      isExpand: true,
      isSearched: true,
      month: "",
      day: "",
      year: "",
      leftValue: "",
      search: "",
      branch: "",
      department: "",
      status: "",
      position: "",
      region: "",
      approval_state: "",
      leave_type: "",
      selectedTab: "1",
      fiscalPeriodChanged: false,
      monthsToDisplay: [],
    };
    this.onDropClick = this.onDropClick.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  async componentDidMount() {
    await this.props.getFiscalYear();
    // this.props.getFiscalYear();
    // this.props.GetEmployeeListForAdmin();
    this.props.GetBranches();
    this.props.GetDepartments();
    this.props.GetDesignations();
    this.props.GetJobStatus();
    this.props.GetEmployeeList();
    // const { selectedPayPeriod } = this.props.selectReducer;
    // if (isEmpty(selectedPayPeriod))
    // this.props.getFiscalYear();;
  }
  async onDropClick(e, data) {
    if (data.left) {
      this.setState({ [data.name]: data.value, leftValue: data.leftValue });
    }
    this.setState({ [data.name]: data.value });
    if (data.name === "year") {
      await this.getPeriod(data.value);
      // this.changeFiscalPeriod();
    }

    if (data.name === "month") {
      await this.props.selectedPayrollPeriods(data.value);
      this.changeFiscalPeriod();
    }
    // if (this.state.selectedTab == "1") {
      // this.setState({ fiscalPeriodChanged: true })
    // }
  }
  displayedMonthClicked = async (payrollPeriod) => {
    await this.props.selectedPayrollPeriods(payrollPeriod);
    this.changeFiscalPeriod();
  };
  getPeriod = async (val) => {
    if (isEmpty(val)) {
      await this.props.getPayrollPeriods({ Value: 0 }, this.changeFiscalPeriod);
    } else {
      await this.props.getPayrollPeriods(val, this.changeFiscalPeriod);
      // this.changeFiscalPeriod(); async await not working as expected
    }
    this.setState({ onPayrollOpen: !this.state.onPayrollOpen });
  }

  changeFiscalPeriod = () => {
    this.setState({ fiscalPeriodChanged: !this.state.fiscalPeriodChanged });
  };

  onCancel(e, name) {
    this.setState({ [name]: "", leftValue: "" });
    if (name === "year") {
      this.getPeriod();
    }
    if (name === "month") {
      this.props.selectedPayrollPeriods({});
    }
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  renderSwitch = (selectedTab) => {
    const { fiscalPeriodChanged } = this.state;
    switch (selectedTab) {
      case "1":
        return (
          <AttendanceRegister
            fiscalPeriodChanged={fiscalPeriodChanged}
            changeFiscalPeriod={this.changeFiscalPeriod}
          />
        );
      case "2":
        return (
          <LeaveAbsence
            fiscalPeriodChanged={fiscalPeriodChanged}
            changeFiscalPeriod={this.changeFiscalPeriod}
          />
        );
      case "3":
        return (
          <StopPayment
            fiscalPeriodChanged={fiscalPeriodChanged}
            changeFiscalPeriod={this.changeFiscalPeriod}
          />
        );
      case "4":
        return (
          <ArrearCalculation
            fiscalPeriodChanged={fiscalPeriodChanged}
            changeFiscalPeriod={this.changeFiscalPeriod}
          />
        );
      case "5":
        return (
          <IncomeCalculation
            fiscalPeriodChanged={fiscalPeriodChanged}
            changeFiscalPeriod={this.changeFiscalPeriod}
          />
        );
      case "6":
        return (
          <TaxAdjustment
            fiscalPeriodChanged={fiscalPeriodChanged}
            changeFiscalPeriod={this.changeFiscalPeriod}
          />
        );
      case "7":
        return (
          <ReviewFinalize
            fiscalPeriodChanged={fiscalPeriodChanged}
            changeFiscalPeriod={this.changeFiscalPeriod}
          />
        );
    }
  };

  getPayrollTitle = () => {
    const { selectedTab } = this.state;
    switch (selectedTab) {
      case "1":
        return "Attendance Register";
      case "2":
        return "Leave Absence";
      case "3":
        return "Stop Payment";
      case "4":
        return "Arrear Calculation";
      case "5":
        return "Income Calculation";
      case "6":
        return "Tax Adjustment";
      case "7":
        return "Final Review";
    }
  };

  onTabChanged = (selectedTab) => {
    this.setState({ selectedTab });
  };

  getMonthToDisplayList = (item, i, payrollPeriod) => {
    let monthsToDisplay;
    if (i == 0) {
      let blueMonth = item.Text.split("/");
      let title = `${blueMonth[0]} ${blueMonth[1]}`;
      monthsToDisplay = [{ ...item, type: "blue", title }];
    } else if (i == 1) {
      let blueMonth = item.Text.split("/");
      let title = `${blueMonth[0]} ${blueMonth[1]}`;
      let greenMonth = payrollPeriod.value[i - 1].Text.split("/");
      let greenMonthtitle = `${greenMonth[0]} ${greenMonth[1]}`;
      monthsToDisplay = [
        {
          ...payrollPeriod.value[i - 1],
          type: "green",
          title: greenMonthtitle,
        },
        { ...item, type: "blue", title },
      ];
    } else {
      let blueMonth = item.Text.split("/");
      let title = `${blueMonth[0]} ${blueMonth[1]}`;
      let greenMonth = payrollPeriod.value[i - 1].Text.split("/");
      let greenMonthtitle = `${greenMonth[0]} ${greenMonth[1]}`;
      let closedMonth = payrollPeriod.value[i - 2].Text.split("/");
      let closedMonthTitle = `${closedMonth[0]} ${closedMonth[1]}`;
      monthsToDisplay = [
        {
          ...payrollPeriod.value[i - 2],
          type: "closed",
          title: closedMonthTitle,
        },
        {
          ...payrollPeriod.value[i - 1],
          type: "green",
          title: greenMonthtitle,
        },
        { ...item, type: "blue", title },
      ];
    }
    return monthsToDisplay;
  };

  render() {
    const { match, selectReducer } = this.props;
    const { fiscal, payrollPeriod } = selectReducer;
    if (!this.state.onOpen && !isEmpty(fiscal.value)) {
      fiscal.value.map((item) => {
        if (item.Selected) {
          this.setState({ year: item, onOpen: true });
          this.props.getPayrollPeriods(item);
        }
      });
    }
    if (!this.state.onPayrollOpen && !isEmpty(payrollPeriod.value)) {
      payrollPeriod.value.map((item, i) => {
        if (item.Selected) {
          this.props.selectedPayrollPeriods(item);
          this.setState({
            month: item,
            onPayrollOpen: true,
            monthsToDisplay: this.getMonthToDisplayList(item, i, payrollPeriod),
          });
        }
      });
    }
    const { search } = this.props.location;
    if (!isEmpty(search)) {
      const parsed = parseQuery(search);
      if (!isEmpty(parsed.payroll) && !isEmpty(payrollPeriod.value)) {
        if (this.state.isSearched) {
          payrollPeriod.value.map((item) => {
            if (item.Value === parsed.payroll) {
              this.setState({ month: item, isSearched: false });
              this.props.selectedPayrollPeriods(item);
            }
          });
        }
      }
    }
    const {
      location: { pathname },
    } = this.props;
    // let { title } = this.state;
    // // let title = pathname.replace("/payroll/", "");
    // // let title = 'Payroll'
    // if (!isEmpty(title)) {
    //   title = title.replace(/_/g, " ");
    // } else {
    //   title = "Payroll";
    // }
    const { selectedTab, monthsToDisplay } = this.state;
    return (
      <div className="page-contain payroll">
        <div className="space-between">
          <h3 className="page-heading" style={{ textTransform: "capitalize" }}>
            {this.getPayrollTitle()}
          </h3>
          {/* <div className="nav-expand">
                        <button 
                            className={isExpand?"btn-outline btn-outline__primary":"btn btn-primary"}
                            onClick={()=>this.setState({isExpand:!isExpand})}    
                            >
                                {isExpand?"Collapse":"Expand"}
                        </button>
                    </div> */}
        </div>
        <div className="filters">
          <div className="flex drop-container">
            <div className="filters-item">
              {fiscal.loading ? (
                <div className="form-group">
                  <Skeleton variant="rect" width={175} height={44} />
                </div>
              ) : (
                <Dropdown
                  title="Fiscal Year"
                  leftIcon="FY"
                  name="year"
                  active={this.state.year}
                  onClick={this.onDropClick}
                  onCancel={this.onCancel}
                >
                  {!isEmpty(fiscal.value)
                    ? fiscal.value.map((item, key) => {
                        return (
                          <li key={key} className=" with-hr" value={item}>
                            <span dynamic="false">{item.Text}</span>
                          </li>
                        );
                      })
                    : null}
                </Dropdown>
              )}
            </div>
            <div className="filters-item">
              {payrollPeriod.loading || fiscal.loading ? (
                <div className="form-group">
                  <Skeleton variant="rect" width={175} height={44} />
                </div>
              ) : (
                <Dropdown
                  title="Payroll Period"
                  leftIcon="M"
                  name="month"
                  active={this.state.month}
                  dynamicLeft={true}
                  leftValue={this.state.leftValue}
                  onClick={this.onDropClick}
                  onCancel={this.onCancel}
                >
                  {payrollPeriod &&
                    payrollPeriod.value &&
                    payrollPeriod.value.map((item, key) => {
                      if (item.Text.includes("__")) {
                        return (
                          <li className="pl-bg" key={key} value={item}>
                            <span className="badge badge-blue">A</span>
                            <span dynamic="false">{item.Text}</span>
                          </li>
                        );
                      } else {
                        return (
                          <li key={key} className=" with-hr" value={item}>
                            <span className="badge badge-blue">M</span>
                            <span dynamic="false">{item.Text}</span>
                          </li>
                        );
                      }
                    })}
                </Dropdown>
              )}
              {/* <Dropdown 
                                title="Month"
                                leftSide="M"
                                name="month"
                                active={this.state.month}
                                onClick={this.onDropClick}
                                leftValue={this.state.leftValue}
                                onCancel={this.onCancel}
                            >
                                <li className=" hr" value="Bisakh">
                                    <span className="badge badge-blue" dynamic="true">M</span>
                                    <span>Baisakh</span>
                                </li>
                                <li value="Jestha">
                                    <span className="badge badge-blue" dynamic="true">M</span>
                                    <span>Jestha</span>
                                </li>
                                <li className="pl-bg" value="Dashain Allowance">
                                    <span className="badge badge-blue" dynamic="true">A</span>
                                    <span>Dashain Allowance</span>
                                </li>
                                <li className="pl-bg hr" value="Overtime">
                                    <span className="badge badge-blue " dynamic="true">A</span>
                                    <span>Overtime</span>
                                </li>
                                
                                <li className="hr" value="Asadh">
                                    <span className="badge badge-blue" dynamic="true">M</span>
                                    <span>Asadh</span>
                                </li>
                            </Dropdown> */}
            </div>
          </div>
          <div className="flex">
            {monthsToDisplay.map((month, i) => (
              <div
                onClick={() => this.displayedMonthClicked(month)}
                className={`filters-item displayingMonths ${
                  i == 0 ? "ml-sm" : ""
                }`}
              >
                <div className={`card-sm ${month.type}`}>
                  <h3 className="card-sm__title">{month.title}</h3>
                  <p className="card-sm__status">
                    {month.type == "closed"
                      ? month.type
                      : month.type == "green"
                      ? "completed"
                      : "processing"}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="payroll-row">
          <SideTab
            match={match}
            month={this.state.month}
            selectedTab={selectedTab}
            onTabChanged={this.onTabChanged}
          />
          <div className="">{this.renderSwitch(selectedTab)}</div>

          {/* <MyContext.Provider value={{ month: this.state.month }}>
                        {this.props.children}
                    </MyContext.Provider> */}
          {/* {
                        this.props.children
                    }  */}
        </div>
      </div>
    );
  }
}

Payroll.propTypes = {
  getFiscalYear: PropTypes.func.isRequired,
  getPayrollPeriods: PropTypes.func.isRequired,
  selectedPayrollPeriods: PropTypes.func.isRequired,
  selectReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  selectReducer: state.selectReducer,
  employeeListSelect: state.commonReducer.employeeListSelect,
});

export default connect(mapStateToProps, {
  getFiscalYear,
  getPayrollPeriods,
  selectedPayrollPeriods,
  // GetEmployeeListForAdmin,
  GetBranches,
  GetDepartments,
  GetDesignations,
  GetJobStatus,
  GetEmployeeList,
})(Payroll);

// export default Payroll
