import React, { useState,useEffect } from 'react'
import Modal from 'components/modal';
import {GoCheck,GoX} from 'react-icons/go'
import {FaTimesCircle} from 'react-icons/fa'
import {MdExpandLess} from 'react-icons/md'
import classnames from 'classnames';
import {  TransitionGroup, CSSTransition } from 'react-transition-group';

function SetPermission() {
    const [open,setOpen] = useState(false);
    const onModalClose = () =>{
        setOpen(false)
    }
    return (
        <div className="permission-btn">
            <button 
                onClick={()=>setOpen(true)}
                className="button button-primary">Set Permission</button>
            <Modal
                open={open}
                onModalClose={onModalClose}
                title="Permission Settings"
                className="roles-modal permission-modal"
            >
                <Permissions close={onModalClose} />
            </Modal>
        </div>
    )
}

const data = [
    {
        module_name: "Settings and Administration",
        modules:[
            {
                module_name:'Settings',
                module_settings:{ view:true, create:false, edit:false, delete:false, share:false}
            },
        ]
    },
    {
        module_name: "Core HRMS",
        modules:[
            {
                module_name:'Employee Record',
                module_settings:{ view:false, create:false, edit:false, delete:false, share:false}
            },
            {
                module_name:'Onboarding',
                module_settings:{ view:true, create:true, edit:true, delete:true, share:true}
            },
            {
                module_name:'Retirement & Termination',
                module_settings:{ view:false, create:false, edit:false, delete:false, share:false}
            },
            {
                module_name:'Disciplinary Action',
                module_settings:{ view:false, create:false, edit:false, delete:false, share:false}
            },
        ]
    },
    {
        module_name: "Payroll",
        modules:[
            {
                module_name:'Benefits & pay',
                module_settings:{ view:false, create:false, edit:false, delete:false, share:false}
            },
        ]
    },
    {
        module_name: "Leave & Time",
        modules:[
            {
                module_name:'Time & Attendance',
                module_settings:{ view:false, create:false, edit:false, delete:false, share:false}
            },
            {
                module_name:'Leave',
                module_settings:{ view:true, create:true, edit:true, delete:true, share:true}
            }
        ]
    },
    {
        module_name: "Recruitment",
        modules:[
            {
                module_name:'Recruitment',
                module_settings:{ view:false, create:false, edit:false, delete:false, share:false}
            },
        ]
    },
]

const Permissions = ({close}) =>{
    return(
        <div className="permission">
            <div className="permission-body">
                <div className="permission-header">
                    <span>Define Permission for</span>
                    <a>
                        Role Name
                    </a>
                </div>
                <div className="permission-modules">
                    <div className="permission-modules__header">
                        <span>Modules or information</span>
                        <span>All</span>
                        <span>View</span>
                        <span>Create</span>
                        <span>Edit</span>
                        <span>Delete</span>
                        <span>Share Report</span>
                        <span>Clear</span>
                    </div>
                    <div className="permission-modules__body">
                        {
                            data.map(item => {
                                return(
                                    <Modules data={item} />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="permission-footer">
                <div className="button-group button-group__right">
                    <button className="button button-primary">Save</button>
                    <button 
                        onClick={()=>close()}
                        className="button">Cancel</button>
                </div>
            </div>
        </div>

    )
}

export const Modules = ({data}) =>{
    const [open,setOpen] = useState(true);
    const modClass = classnames({
        "modules-control":true,
        "collapse":!open
    });
    // const onExpand = (type)=>{
    //     if(open !== type){
    //         setOpen(type);
    //         return;
    //     }
    //     setOpen('')
    // }
    return (
        <div className="modules">
            <div className="modules-header">
                <div className={modClass} onClick={()=>setOpen(!open)}>
                    <span>
                        {data.module_name}
                    </span>
                    <span className="icon">
                        <MdExpandLess />
                    </span>
                </div>
                <button className="modules-btn">
                    <GoX />
                </button>
                <button className="modules-btn color-green"><GoCheck /></button>
                <button className="modules-btn"><GoX /></button>
                <button className="modules-btn"><GoX /></button>
                <button className="modules-btn"><GoX /></button>
                <button className="modules-btn color-green"><GoCheck /></button>
                <button className="modules-btn"><FaTimesCircle /></button>
            </div> 
            <TransitionGroup className="modules-list" component="ul">
                <ModuleItems data={data.modules} open={open} />
            </TransitionGroup>
        </div>
    )
}
export const ModuleItems = ({data,open}) => {
    const items =  data;
    // const items =  open ? data : [];
    
    return items.map((item, i) => {
        const btn = item.module_settings;
        const all = btn['view'] && btn['create'] && btn['edit'] && btn['delete'] && btn['share'];

        const btnClasses = (item) => classnames({
            "modules-btn" : true,
            "color-green" : item
        })
        return (
            <CSSTransition 
                    in={open}
                    key={item.module_name} 
                    timeout={300}
                    classNames="collapse"
                    //  unmountOnExit 
                     >
                <li className="modules-list__item">
                    <span>
                        {item.module_name}
                    </span>
                    <button className={btnClasses(all)}>
                        {all ? <GoCheck /> : <GoX />}
                    </button>
                    <button className={btnClasses(btn['view'])}>{btn['view'] ? <GoCheck /> : <GoX />}</button>
                    <button className={btnClasses(btn['create'])}>{btn['create'] ? <GoCheck /> : <GoX />}</button>
                    <button className={btnClasses(btn['edit'])}>{btn['edit'] ? <GoCheck /> : <GoX />}</button>
                    <button className={btnClasses(btn['delete'])}>{btn['delete'] ? <GoCheck /> : <GoX />}</button>
                    <button className={btnClasses(btn['share'])}>{btn['share'] ? <GoCheck /> : <GoX />}</button>
                    <button className={btnClasses(false)}><FaTimesCircle /></button>
                </li>
            </CSSTransition>
        );	
    });	
}


export default SetPermission
