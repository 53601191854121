import MomentUtils from "@date-io/moment";
import { TextareaAutosize } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { Component } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { connect } from "react-redux";
import {
  // GetAllEmployeeListWithPhoto,
  GetEmployeeList,
} from "actions/commonAction";
import AnimatedForm from "components/common/animatedForm";
import Loader from "components/loaders";
import Upload from "components/common/upload";
import FormGroup from "components/form";
import { ValidationComponent } from "helpers/getComponents";
import isEmpty from "isEmpty";
import userFormValidation from "validations/useFormValidation";
import validateDisciplinaryCase from "validations/validateDisciplinaryCase";
import EmployeeDetails from "../../../common/employeeDetails";
import { RegisterDisciplinaryCase, EditDisciplinaryCase } from "../action";
import "./disciplinaryCase.scss";
import { getDisciplinaryCaseData } from "./getSaveData";
import Button from "components/button";
import EmployeeSearch from "components/common/employeeSearch";

class DisciplinaryCaseForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEmployee: {},
      isAnimate: true,
      employeeList: [],
      employeeListWithPhoto: [],
    };
  }
  async componentDidMount() {
    this.setState({ isAnimate: false });
    const {
      GetEmployeeList,
      // GetAllEmployeeListWithPhoto
    } = this.props;
    // let ajaxApi = [GetEmployeeList, GetAllEmployeeListWithPhoto];
    let ajaxApi = [GetEmployeeList];
    Promise.all(
      ajaxApi.map(async (item) => {
        return await item();
      })
    );
  }
  componentDidUpdate(prevProps, prevState) {
    let { employeeList, employeeListWithPhoto } = this.props;
    if (prevProps.employeeList !== employeeList) {
      this.setState({ employeeList });
    }
    // if (prevProps.employeeListWithPhoto !== employeeListWithPhoto) {
    //   this.setState({ employeeListWithPhoto });
    // }
  }

  onClose() {
    this.setState({ isAnimate: true });
    setTimeout(() => {
      this.props.history.goBack();
    }, 1000);
  }
  render() {
    const {
      selectedEmployee,
      employeeList,
      // employeeListWithPhoto,
    } = this.state;
    let { spinnerLoading, disciplinaryCaseDetails } = this.props;
    let isEditPage = this.props.parent.isEditPage;
    let isFormReadytoLoad =
      isEditPage &&
      !isEmpty(employeeList) &&
      // !isEmpty(employeeListWithPhoto) &&
      (!disciplinaryCaseDetails.loading ||
        !isEmpty(disciplinaryCaseDetails.value));
    return (
      <>
        <Loader open={spinnerLoading} />
        {/* <AnimateShowHide
          show={this.state.isAnimate}
          config={{ duration: 1000 }}
          fromStyle={{
            opacity: 0,
            transform: "translate3d(0,-150%,0)"
          }}
          toStyle={{
            opacity: 1,
            transform: this.state.isAnimate
              ? "translate3d(0,-150%,0)"
              : "translate3d(0,0,0)"
          }}
        > */}
        {isFormReadytoLoad || !isEditPage ? (
          <Form
            onClose={this.onClose.bind(this)}
            props={this.props}
            setAnimate={() => this.setAnimate()}
            state={this.state}
          />
        ) : null}
        {/* </AnimateShowHide> */}
      </>
    );
  }
}

const Form = ({ props, onClose, setAnimate, state }) => {
  let { employeeList, employeeListWithPhoto } = state;
  let { disciplinaryCaseDetails } = props;
  let dspCaseDetails = disciplinaryCaseDetails.value;
  let isEditPage = props.parent.isEditPage;
  let INITIAL_STATE;
  if (isEditPage) {
    INITIAL_STATE = {
      DisciplinaryCaseId: dspCaseDetails.DisciplinaryCaseId,
      Employee: employeeList.find(
        (x) => x.value === dspCaseDetails.EmployeeRefId
      ),
      CaseTitle: dspCaseDetails.CaseTitle,
      CaseStatement: dspCaseDetails.CaseStatement,
      CaseDate: dspCaseDetails.ReportedDateEng,
      ReportedBy: isEmpty(dspCaseDetails.ReportedBy)
        ? []
        : dspCaseDetails.ReportedBy.split(",").map((x) => {
            return employeeList.find((item) => item.value == x);
          }),
      Notify: isEmpty(dspCaseDetails.NotifyTo)
        ? []
        : dspCaseDetails.NotifyTo.split(",").map((x) => {
            return employeeList.find((item) => item.value == x);
          }),
      FileList: dspCaseDetails.DisciplinaryCaseDocuments.map((x) => {
        return {
          UserFileName: x.UserFileName,
          FileLocation: x.FileLocation,
          DocumentName: x.DocumentName,
          AttachmentId: x.AttachmentId,
          Id: x.AttachmentId,
        };
      }),
    };
  } else {
    INITIAL_STATE = {
      DisciplinaryCaseId: 0,
      Employee: {},
      CaseTitle: "",
      CaseStatement: "",
      CaseDate: null,
      ReportedBy: [],
      Notify: [],
      FileList: [],
    };
  }
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    clearValues,
    isSubmitting,
  } = props.parent.showForm
    ? userFormValidation(INITIAL_STATE, validateDisciplinaryCase)
    : { values: INITIAL_STATE, errors: {} };

  console.log(errors);

  if (isSubmitting && isEmpty(errors)) {
    const data = getDisciplinaryCaseData(values);
    //console.log(data);
    if (isEditPage) {
      props.EditDisciplinaryCase(
        data,
        props.parent.getDisciplinaryActionList,
        props.onClose
      );
    } else
      props.RegisterDisciplinaryCase(
        data,
        props.parent.getDisciplinaryActionList,
        props.onClose
      );
  }
  const onSelectChange = (name, value) => {
    handleChange(name, value);
  };
  const onChange = (name, value) => {
    handleChange(name, value);
  };
  const onDateChange = (date) => {
    handleChange("CaseDate", date);
  };

  const setSelectedFiles = (fileList) => {
    handleChange("FileList", fileList);
    //this.setState({ selectedSummaryFileList: fileList });
  };
  const isViewMode = false;
  const urlPhoto = "../Web/images/photos/user2.png";
  // const empDetails =
  //   !isEmpty(employeeListWithPhoto) &&
  //   !isEmpty(values.Employee) &&
  //   employeeListWithPhoto.find((x) => x.employeeId === values.employee.value);
  const empDetails =
    !isEmpty(employeeList) &&
    !isEmpty(values.Employee) &&
    employeeList.find((x) => x.employeeId === values.Employee.value);
  const [selectedDate, setSelectedDate] = React.useState("12/06/2020");

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  return (
    <form className="disciplinary-case dsp-form" onSubmit={handleSubmit}>
      <div className="dsp-form__header">
        <span>New Disciplinary Case</span>
        <span className="close-icon" onClick={() => props.onClose()}>
          <IoIosCloseCircleOutline />
        </span>
      </div>
      <div className="dsp-form__body">
        <div className="form-row">
          <ValidationComponent
            rootClassName="form-group"
            error={errors.Employee}
            errorClassName="error__message"
          >
            <EmployeeSearch
              onChange={onSelectChange}
              value={values.Employee}
              label="Select the Employee"
              name="Employee"
              disabled={isViewMode}
            />
            {/* <FormGroup
              formName="reactselect"
              onChange={onSelectChange}
              name="Employee"
              value={values.Employee}
              options={employeeList}
              width="300px"
              disabled={isViewMode}
              label="Select the Employee"
            /> */}
          </ValidationComponent>
          {!isEmpty(values.Employee) && !isEmpty(empDetails) ? (
            <EmployeeDetails empDetails={empDetails} />
          ) : null}
        </div>

        <div className="form-row">
          <FormGroup
            type="text"
            name="CaseTitle"
            value={values.CaseTitle}
            label="Case title"
            onChange={handleChange}
            error={errors.CaseTitle}
            disabled={isViewMode}
          />
        </div>
        <div className="form-row">
          <FormGroup
            formName="textarea"
            name="CaseStatement"
            cols={100}
            rows={6}
            label="Case Statement"
            value={values.CaseStatement}
            onChange={handleChange}
            error={errors.CaseStatement}
            disabled={isViewMode}
          />
        </div>
        <div className="form-row">
          <ValidationComponent
            rootClassName="form-group"
            error={errors.CaseDate}
            errorClassName="error__message"
          >
            <label htmlFor="CaseStatement" className="form-group__label">
              When did this happen
            </label>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <div className="pickers">
                <KeyboardDatePicker
                  name="CaseDate"
                  variant="inline"
                  value={values.CaseDate}
                  width={80}
                  onChange={onDateChange}
                  disabled={isViewMode}
                />
              </div>
            </MuiPickersUtilsProvider>
            {/* <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  // label="Date picker inline"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
            </MuiPickersUtilsProvider> */}
          </ValidationComponent>
        </div>
        <div className="form-row">
          <ValidationComponent
            rootClassName="form-group"
            error={errors.ReportedBy}
            errorClassName="error__message"
          >
            {/* <FormGroup
              formName="reactselect"
              onChange={onSelectChange}
              name="ReportedBy"
              label="Reported By"
              value={values.ReportedBy}
              options={employeeList}
              width="300px"
              disabled={isViewMode}
              multiple
            /> */}
            <EmployeeSearch
              onChange={onSelectChange}
              value={values.ReportedBy}
              label="Reported By"
              name="ReportedBy"
              width="300px"
              disabled={isViewMode}
              isMultiple={true}
            />
          </ValidationComponent>
          <ValidationComponent
            rootClassName="form-group"
            error={errors.FileList}
            errorClassName="error__message"
          >
            <Upload
              fileList={values.FileList}
              setSelectedFiles={setSelectedFiles}
              uploadBtnText="Attach document"
              noSelectedText="No document selected"
              isViewMode={isViewMode}
            />
          </ValidationComponent>
        </div>
        <div className="form-row">
          <ValidationComponent
            rootClassName="form-group"
            error={errors.Notify}
            errorClassName="error__message"
          >
            {/* <FormGroup
              formName="reactselect"
              onChange={onSelectChange}
              name="Notify"
              label="Notify"
              value={values.Notify}
              options={employeeList}
              width="300px"
              disabled={isViewMode}
              multiple
            /> */}
            <EmployeeSearch
              onChange={onSelectChange}
              value={values.Notify}
              label="Notify"
              name="Notify"
              width="300px"
              disabled={isViewMode}
              isMultiple={true}
            />
          </ValidationComponent>
        </div>
      </div>
      <div className="dsp-form__footer">
        <Button
          bg="secondary"
          isDisabled={isSubmitting}
          onClick={() => props.onClose()}
          title="Cancel"
        />
        <Button
          bg="primary"
          isDisabled={isSubmitting}
          title={isSubmitting ? "Please wait" : "Register the case"}
          //onClick={this.handleSaveSettlement}
        />
      </div>
    </form>
  );
};

DisciplinaryCaseForm = connect(
  (state) => ({
    employeeList: state.commonReducer.employeeListSelect,
    // employeeListWithPhoto: state.commonReducer.employeeWithPhotos,
    spinnerLoading: state.disciplinaryActionReducer.spinnerLoading,
    disciplinaryCaseDetails:
      state.disciplinaryActionReducer.disciplinaryCaseDetails,
  }),
  {
    GetEmployeeList,
    // GetAllEmployeeListWithPhoto,
    EditDisciplinaryCase,
    RegisterDisciplinaryCase,
  }
)(DisciplinaryCaseForm);

//export default DisciplinaryCaseForm;

export default AnimatedForm()(DisciplinaryCaseForm);
