import Slider from "components/common/slider";
import FormGroup from "components/form";
import { Tab, Tabs } from "components/tabs/index.d";
import {
  JOB_PROFILE_KNOWLEDGE_TYPE,
  JOB_PROFILE_SKILL_TYPE,
} from "constants/constants";
import isEmpty from "isEmpty";
import React, { Component } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { IoMdCheckmark } from "react-icons/io";
import { RiErrorWarningLine } from "react-icons/ri";
import { connect } from "react-redux";
class InsertKSAForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ksaList: [],
      marks: [
        {
          value: 1,
          label: "1",
          desc: "none",
        },
        {
          value: 2,
          label: "2",
          desc: "very poor",
        },
        {
          value: 3,
          label: "3",
          desc: "poor",
        },
        {
          value: 4,
          label: "4",
          desc: "average",
        },
        {
          value: 5,
          label: "5",
          desc: "good",
        },
        {
          value: 6,
          label: "6",
          desc: "very good",
        },
      ],
      hovered: 0,
      searchFilter: "",
      activeTab: 1,
      tabs: [
        {
          id: 1,
          label: "Select Knowledge Statements",
          component: "knowledgeStatement",
        },
        {
          id: 2,
          label: "Define Desired Knowledge Level",
          component: "knowledgeLevel",
        },
      ],
      isError: false,
    };
    this.handleHover = this.handleHover.bind(this);
  }

  componentDidMount() {
    let {
      components,
      insertType,
      knowledgeList,
      skillList,
      abilityList,
    } = this.props;
    let ksaList =
      insertType === JOB_PROFILE_KNOWLEDGE_TYPE
        ? knowledgeList
        : insertType === JOB_PROFILE_SKILL_TYPE
        ? skillList
        : abilityList;
    let currentComponent = components.find((x) => x.type === insertType);
    let newKSAList = ksaList.map((x) => {
      let currentComponentKSA = currentComponent[insertType.toLowerCase()].find(
        (i) => i.id === x.Id
      );
      let o = {
        ...x,
        Level: !isEmpty(currentComponentKSA)
          ? currentComponentKSA.level
          : x.Level,
      };
      o.Selected = !isEmpty(currentComponentKSA) ? true : false;

      return o;
    });
    this.setState({ ksaList: newKSAList });
  }
  handleHover(id) {
  
    this.setState({ hovered: id });
  }
  handleChangeSelected = (id, select) => {
    let { ksaList } = this.state;
    const newKSAList = ksaList.map((p) =>
      p.Id === id ? { ...p, Selected: select } : p
    );

    this.setState({ ksaList: newKSAList, isError: false });
  };

  handleSearchFilterChange = (name, value) => {
    this.setState({ searchFilter: value });
  };

  handleAddSubSections = () => {
    let { ksaList } = this.state;
    const newKSAList = ksaList.filter((x) => x.Selected);
    this.props.handleAddSubSections(
      this.props.sectionId,
      this.props.insertType,
      newKSAList
    );
    this.props.closeKSAForm();
  };

  onSilderChange = (id, newValue) => {
    let { ksaList } = this.state;
    const newKSAList = ksaList.map((p) =>
      p.Id === id ? { ...p, Level: newValue } : p
    );
    this.setState({ ksaList: newKSAList });
  };

  handleTabChange = (id) => {
    let { ksaList } = this.state;
    const newKSAList = ksaList.filter((x) => x.Selected);
    if (!isEmpty(newKSAList)) {
      this.setState({ activeTab: id, isError: false });
    } else {
      this.setState({ isError: true });
    }
  };

  knowledgeStatement() {
    let { ksaList, hovered, searchFilter, isError } = this.state;
    let filteredKSAList = ksaList.filter((x) =>
      x.Name.toLowerCase().includes(searchFilter.toLowerCase())
    );
    return (
      <div className="add-section-form__body">
        <div className="form-row flex items-center justify-between">
          <FormGroup
            name="searchFilter"
            value={searchFilter}
            onChange={this.handleSearchFilterChange}
            label=""
            width="250px"
            placeholder="Search or filter"
          />
          {isError && (
            <div className="error-message flex">
              <span>Please select atleast one {this.props.insertType}</span>
              <RiErrorWarningLine className="text-bg ml-sm" />
            </div>
          )}
        </div>
        <div className="form-row">
          <div className="edu-qual">
            {filteredKSAList.map((x) => (
              <div className="edu-qual__li flex">
                <div className="btn-grp">
                  {x.Selected ? (
                    hovered === x.Id ? (
                      <button
                        className="button with-icon button-white remove-btn"
                        onClick={() => this.handleChangeSelected(x.Id, false)}
                        onMouseLeave={() => this.handleHover(0)}
                      >
                        <AiOutlineClose />
                        Remove
                      </button>
                    ) : (
                      <button
                        className="button with-icon selected-btn"
                        onMouseEnter={() => this.handleHover(x.Id)}
                      >
                        <IoMdCheckmark />
                        Selected
                      </button>
                    )
                  ) : (
                    <button
                      className="button with-icon select-btn button-white"
                      onClick={() => this.handleChangeSelected(x.Id, true)}
                    >
                      <IoMdCheckmark />
                      Select
                    </button>
                  )}
                </div>
                <span>{x.Name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
  knowledgeLevel() {
    let { ksaList, marks } = this.state;
    return (
      <div className="add-section-form__body">
        <div className="form-row space-between">
          <div className="flex-column items-end wd-100 text-md">
            <div className="wd-60 space-between pb-sm">
              {marks.map((x) => (
                <span className="level-count">{x.label}</span>
              ))}
            </div>
            <div className="wd-60 space-between">
              {marks.map((x) => (
                <span className="level-desc">{x.desc}</span>
              ))}
            </div>
          </div>
        </div>

        {ksaList.map((x, i) => {
          if (x.Selected)
            return (
              <div className="form-row space-between ksa-slider-row">
                <div className="ksa-name flex">
                  <span className="ksa-count">{i + 1}</span>
                  <span className="ksa-name">{x.Name}</span>
                </div>
                <div className="ksa-slider flex wd-60 justify-center">
                  <Slider
                    step={1}
                    marks={marks}
                    defaultValue={x.Level}
                    value={x.Level}
                    valueLabelDisplay={true}
                    min={1}
                    max={6}
                    name={`level${i + 1}`}
                    onChange={(name, newValue) =>
                      this.onSilderChange(x.Id, newValue)
                    }
                    rootElStyle={{
                      width: "98%",
                    }}
                  />
                </div>
              </div>
            );
        })}
      </div>
    );
  }
  render() {
    let { ksaList, hovered, searchFilter, marks, activeTab, tabs } = this.state;
    const activeTabLabel = tabs.find((x) => x.id === activeTab).label;
    const { closeKSAForm } = this.props;
    return (
      <div className="insert-ksa-form">
        {tabs && (
          <Tabs
            // type="block"
            noBackground
            activeTab={activeTab}
          >
            {tabs.map((tab, index) => {
              return (
                <Tab
                  label={tab.label}
                  name={tab.id}
                  id={tab.id}
                  key={index}
                  // isActive = {activeTabId===tab.tabId}
                   onClick={() => this.handleTabChange(tab.id)}
                >
                  <>{this[tab.component]()}</>
                </Tab>
              );
            })}
          </Tabs>
        )}
        <div className="add-section-form__footer">
          <div className="cancel">
            <button
              className="button button-white"
              onClick={() => closeKSAForm()}
            >
              Cancel
            </button>
          </div>
          <div className="save">
            {activeTab === 1 ? (
              <button
                className="button button-primary"
                onClick={() => this.handleTabChange(2)}
              >
                Next
              </button>
            ) : (
              <>
                <button
                  className="button button-primary"
                  onClick={() => this.handleTabChange(1)}
                >
                  Back
                </button>
                <button
                  className="button button-primary"
                  onClick={() => this.handleAddSubSections()}
                >
                  Save
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  knowledgeList: state.jobProfileReducer.knowledgeList,
  skillList: state.jobProfileReducer.skillList,
  abilityList: state.jobProfileReducer.abilityList,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InsertKSAForm);
