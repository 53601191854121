import React, { Component } from "react";
import { connect } from "react-redux";
import Loader from "components/loaders";
import { RoleId } from "constants/userDetails";
import { ADMIN_ROLE_ID } from "constants/constants";
import isEmpty from "isEmpty";
import {
  DeleteTravelRequests,
  GetMyTravelRequests,
  clearEditTravelRequest,
} from "../actions";
import CommonHeaderSection from "../../../admin/commonHeaderSection";
//import MyTravelRequestTable from "./MyTravelRequestTable";
import TravelDetailsBlock from "../common/travelDetailsBlock";

class MyTravelRequestScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 10,
    };
  }

  componentDidMount() {
    // if (!this.props.intitalLoad)
    // this.initialize();
    this.initialize();
  }

  componentDidUpdate() {
    if (this.props.reloadData && this.state.needReload) {
      this.setState({ needReload: false });
      this.initialize();
    }
  }

  initialize = () => {
    const { pageNumber, pageSize } = this.state;
    this.props.GetMyTravelRequests({ pageNumber, pageSize });
  };

  rowPerPage = (pageNumber, pageSize) => {
    this.setState({ pageNumber: pageNumber, pageSize: pageSize }, () => {
      this.initialize();
    });
  };

  handleDelete = (requestId) => {
    this.setState({ needReload: true }, () => {
      this.props.DeleteTravelRequests({ requestId });
    });
  };

  // handlePagination = (pageNumber, pageSize) => {
  //   this.setState({ pageNumber, pageSize }, () => {
  //     this.initialize();
  //   });
  // };

  handleRowChange = (num) => {
    this.setState({ pageSize: num }, () => {
      this.initialize();
    });
  };
  handleIndexChange = (num) => {
    this.setState({ pageNumber: num + 1 }, () => {
      this.initialize();
    });
  };

  renderBody = () => {
    const { myTravelRequestList, isLoading } = this.props;
    return (
      <div className="travel-create">
        {/* <div className="travel-create__title">
          <h3>My Travel Requests</h3>
        </div> */}
        <div className="travel-create__body">
          {myTravelRequestList ? (
            <TravelDetailsBlock
              rows={myTravelRequestList}
              parentState={this.state}
              handleDelete={(requestId) => this.handleDelete(requestId)}
              handleRowChange={this.handleRowChange}
              handleIndexChange={this.handleIndexChange}
              history={this.props.history}
              clearEditTravelRequest={this.props.clearEditTravelRequest}
              page="myTravelRequest"
              pageIndex={this.state.pageNumber}
              rowsPerPage={this.state.pageSize}
            />
          ) : (
            // <MyTravelRequestTable
            //   rows={myTravelRequestList}
            //   parentState={this.state}
            //   handleDelete={requestId => this.handleDelete(requestId)}
            //   handleRowChange={this.handleRowChange}
            //   handleIndexChange={this.handleIndexChange}
            //   // handlePagination={(pageNumber,pageSize=this.state.pageSize) => this.handlePagination(pageNumber,pageSize)}
            // />
            isLoading && <Loader type="skeleton" name="card" cardType="travel" noRows={10} loading={isLoading} />
          )}
        </div>
      </div>
    );
  };

  render() {
    const selectedTabId = !isEmpty(this.props.location.state)
      ? this.props.location.state.selectedTabId
      : null;
    const roleId = RoleId();
    return (
      <>
        {roleId == ADMIN_ROLE_ID ? (
          <CommonHeaderSection
            renderBody={this.renderBody()}
            selectedTabId={selectedTabId}
            pathName="My Request"
          />
        ) : (
          <div className="travel__filter-body">
            <div className="travel-requestMainBody">{this.renderBody()}</div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  myTravelRequestList: state.travelInformationReducer.myTravelRequestList,
  reloadData: state.travelInformationReducer.reloadData,
  isLoading: state.travelInformationReducer.isLoading,
  intitalLoad: state.travelInformationReducer.myTravelRequestInitialLoad,
});

const mapDispatchToProps = {
  GetMyTravelRequests,
  DeleteTravelRequests,
  clearEditTravelRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyTravelRequestScreen);
