import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TableFooter,
} from "@material-ui/core";
// import { TableBody } from "@material-ui/core";
// import { TableCell } from "@material-ui/core";
// import { TableHead } from "@material-ui/core";
// import { TableRow } from "@material-ui/core";

// import CommonPagination from "~/Components/Common/CommonPagination";

import { MdModeEdit, MdDoNotDisturb } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";
import { FaRegBuilding } from "react-icons/fa";

import Avatar from "react-avatar";
import { getBaseAxios } from "helpers/constants";

// const data = [
//   {
//     name: "Dominique Callas",
//     direct: "Sumnima Rana",
//     matrix: "Chief Operating Officer",
//     custom: "Interneal Audit Head",
//   },
//   {
//     name: "Lori Bermudes",
//     direct: "Finance Manager",
//     matrix: "Anuj Khatri",
//     custom: "",
//   },
//   {
//     name: "Nickole Hearon",
//     direct: "Dominique Callas",
//     matrix: "Anuj Khatri",
//     custom: "Mina Kumari",
//   },
// ];

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F5F5F8",
    color: "#353F5A",
    padding: "10px 20px",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    padding: "10px 20px",
    backgroundColor: "#fff",
    //   '&:nth-of-type(odd)': {
    //     backgroundColor: theme.palette.background.default,
    //   },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    border: "1px solid #E1E1E1",
  },
  name: {
    color: "#0052AB",
    letterSpacing: 0,
    fontSize: 14,
    fontWeight: 500,
  },
  value: {
    color: "#303130",
    marginRight: 10,
    fontSize: 14,
    letterSpacing: 0,
    fontWeight: 500,
    marginLeft: 10,
  },
  buttonGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});

// function renderAvatar(name, imagePath) {
//   return (

//   );
// }

function renderTableCell(classes, text, value, imagePath = null) {
  console.log(imagePath);
  return (
    <>
      {text && (
        <>
          <span style={{ marginRight: "5px" }}>
            {imagePath ? (
              <Avatar
                name={text}
                size={25}
                round={true}
                src={getBaseAxios() + imagePath}
              />
            ) : (
              <FaRegBuilding size={16} color={"grey"} />
            )}
          </span>
          <span className={classes.name}>{text}</span>
          <span className={classes.value}>[{value}]</span>
        </>
      )}
    </>
  );
}

function CustomTable(props) {
  const { employeeManagers } = props;
  const classes = useStyles();

  return (
    <div className="roles-table">
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Employee Name</StyledTableCell>
            <StyledTableCell>Direct Manager</StyledTableCell>
            <StyledTableCell>Matrix Manager</StyledTableCell>
            <StyledTableCell>Custom Manager</StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {employeeManagers &&
            employeeManagers.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>
                  {renderTableCell(
                    classes,
                    row.Name,
                    row.EmployeeId,
                    row.ImgPath
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {row.DManagerialType == 1
                    ? renderTableCell(
                        classes,
                        row.DManagerialPositionName,
                        row.DManagerialPosition
                      )
                    : row.DManagerialType == 2 &&
                      renderTableCell(
                        classes,
                        row.DManagerialPersonName,
                        row.DManagerialPerson,
                        row.DMImagePath
                      )}
                </StyledTableCell>
                <StyledTableCell>
                  {row.MManagerialType == 1
                    ? renderTableCell(
                        classes,
                        row.MManagerialPositionName,
                        row.MManagerialPosition
                      )
                    : row.MManagerialType == 2 &&
                      renderTableCell(
                        classes,
                        row.MManagerialPersonName,
                        row.MManagerialPerson,
                        row.MMImagePath
                      )}
                </StyledTableCell>
                <StyledTableCell>
                  {row.MManagerialType == 1
                    ? renderTableCell(
                        classes,
                        row.CManagerialPositionName,
                        row.CManagerialPosition
                      )
                    : row.MManagerialType == 2 &&
                      renderTableCell(
                        classes,
                        row.CManagerialPersonName,
                        row.CManagerialPerson,
                        row.CMImagePath
                      )}
                </StyledTableCell>
                <StyledTableCell>
                  <div className={classes.buttonGroup}>
                    <button
                      className="button button-primary-light with-icon"
                      onClick={() => props.handleEdit(row.EmployeeId)}
                    >
                      <MdModeEdit />
                      <span>Edit</span>
                    </button>
                    <button
                      className="button button-primary-light with-icon"
                      onClick={() => props.handleDelete(row.EmployeeId)}
                    >
                      <AiOutlineDelete />
                      <span>Clear</span>
                    </button>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
      {employeeManagers && employeeManagers.length > 0 && (
        <TableFooter>
          <TableRow>
            {/* <CommonPagination
              handleRowChange={props.handleRowChange}
              handleIndexChange={props.handleIndexChange}
              pageIndex={props.pageIndex}
              rowsPerPage={props.pageSize}
              total={employeeManagers[0].TotalRows}
            /> */}
          </TableRow>
        </TableFooter>
      )}
    </div>
  );
}

export default CustomTable;
