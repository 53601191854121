import React, { useState, useEffect } from "react";
import Icons from "components/icons";
import FormGroup from "components/form";
import isEmpty from "isEmpty";
import classNames from "classnames";
import { MdClose } from "react-icons/md";
import { GoPin } from "react-icons/go";
import moment from "moment";
import { useSelector } from "react-redux";
import SvgIcons from "components/svgIcons";
import Button from "components/button";
interface IAppProps {
  comments?: any;
  submitComment: Function;
  submitErrors?: any;
  isViewMode?: boolean;
  deleteComment?: Function;
  showDeleteButton?: boolean;
}

const App: React.FunctionComponent<IAppProps> = (props) => {
  const user = useSelector((state) => state["auth"].user);
  const { comments, submitComment, submitErrors, isViewMode, deleteComment, showDeleteButton } = props;
  const [comment, setComment] = React.useState("");
  const [allComments, setComments] = React.useState(comments);
  const [myComment, setMyComment] = React.useState(
    allComments?.find((x) => x.commenterId === user.EmployeeId)
  );
  const [errors, setErrors] = React.useState({ comment: "" });
  useEffect(() => {
    setErrors(submitErrors);
  }, [submitErrors]);
  const onCommentChange = (name, value) => {
    setComment(value);
    setErrors({ comment: "" });
  };
  const saveComment = async () => {
    if (isEmpty(comment)) setErrors({ comment: "Required" });
    else {
      let status = await submitComment(comment);
      if (status) {
        let myComment = {
          commenter: "My Comment",
          commentDate: moment(new Date(), "YYYYMMDDHHmmss").format(
            "MMM DD, YYYY hh:mm A"
          ),
          comment: comment,
          commenterId: user.EmployeeId,
        };
        setMyComment(myComment);
        let newComments = allComments.map((x) => {
          return { ...x };
        });
        newComments.push(myComment);
        setComments(newComments);
      }
    }
  };
  const removeComment = () => {
    setMyComment("");
    setComment("");
    submitComment("");
  };
  if (isViewMode && isEmpty(allComments)) return null;
  return (
    <div className="comment">
      <div className="comment__header">
        <span>Comments</span>
      </div>
      <div className="comment__body mt-sm">
        {allComments?.map((item, index) => {
          return (
            <div className="flex items-start comment-li">
              {item.commenterId === user.EmployeeId ? (
                <GoPin
                  name="Comment"
                  className={classNames({
                    "text-xbg": true,
                  })}
                />
              ) : (
                <Icons name="Comment" />
              )}
              <div
                className={classNames({
                  "flex-column ml-sm items-start  comment-details": true,
                  "last-child": index + 1 === allComments.length,
                })}
              >
                <div className="flex">
                  <span className="commenter">{item.commenter}</span>
                  <span className="comment-date ml-sm">{item.commentDate}</span>
                  {showDeleteButton && <span onClick={() => deleteComment(item)}><Icons name="Delete"/> </span>}
                </div>
                <span className="comment-text">{item.comment}</span>
                </div>
              </div>
          );
        })}
              {!isEmpty(myComment)
                ? null
                : // <div className="flex items-start comment-li">
                //   <GoPin
                //     className={classNames({
                //       "text-xbg": true,
                //       "mt-xsm ": !isEmpty(comments),
                //     })}
                //   />
                //   <div
                //     className={classNames({
                //       "flex ml-sm my-comment items-start wd-100": true,
                //       "no-comment": isEmpty(comments),
                //     })}
                //   >
                //     <div className="flex-column">
                //       <span className="my-comment__header">My comment</span>
                //       <span>{myComment}</span>
                //     </div>

                //     {/* <MdClose
                //       onClick={() => removeComment()}
                //       className="ml-md close-icon cursor-pointer"
                //     /> */}
                //   </div>
                // </div>
                !isViewMode && (
                  <div
                    className={classNames({
                      "comment-input mt-sm flex": true,
                      "items-start": !isEmpty(errors?.comment),
                      "no-comment": isEmpty(allComments),
                    })}
                  >
                    <FormGroup
                      name="comment"
                      value={comment}
                      onChange={onCommentChange}
                      label=""
                      error={errors && errors?.comment}
                      width="400px"
                      validators={["required"]}
                    />
                    <div
                      className="flex cursor-pointer check-button"
                      onClick={saveComment}
                    >
                      <Icons name="Check" />
                    </div>
                  </div>
                )}
            </div>
    </div>
  );
};

export default App;
