import React, {useState} from "react";
import Modal from "components/modal";
import EmployeeSelection from "./employeeSelection";

const ButtonCell = (props) => {
  const {setRowData, rowData, allValues} = props;
  const [open, setOpen] = useState(false);
  const onModalOpen = () => {
    setOpen(true);
  };

  return allValues.AuthorityType === 6 ? (
    <div>
      <p>
        {allValues?.MultipleSpecificPersonName
          ? allValues.MultipleSpecificPersonName?.split(",")?.map((place) => (
              <p> {place} </p>
            ))
          : ""}
      </p>
      <p className="pointers" onClick={() => onModalOpen()}>
        {allValues?.MultipleSpecificPersonName ? "| Edit" : "Add"}
      </p>

      <Modal
        className="modal_employee_open"
        open={open}
        onModalClose={() => setOpen(false)}
        title="Add Multiple Specific Person"
      >
        <EmployeeSelection
          setOpen={setOpen}
          multipleData={allValues}
          rowData={rowData}
          setRowData={setRowData}
        />
      </Modal>
    </div>
  ) : (
    <> </>
  );
};

export default ButtonCell;
