import FormGroup from "components/form";
import { getCommaFormattedNumber } from "helpers/getFormattedNumber";
import isEmpty from "isEmpty";
import React from "react";
import { connect } from "react-redux";

export class BillsAndClaims extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategoryId: this.props.settlementReport.CategoryId,
    };
  }

  handleCategoryChange = (name, selected) => {
    this.setState({ selectedCategoryId: selected.value });
    this.props.handleCategoryChange(name, selected);
  };
  render() {
    let {
      travelCategoryList,
      billsAndClaimsList,
      otherExpenses,
      travelRequestDetails,
      isViewMode,
      handleCategoryChange,
      settlementReport,
    } = this.props;
    const { selectedCategoryId } = this.state;
    const totalBillAmount = billsAndClaimsList.reduce(
      (a, b) => a + (b["Amount"] || 0),
      0
    );
    const totalOtherExpenseAmount = otherExpenses.reduce(
      (a, b) => a + (b["Amount"] || 0),
      0
    );

    const totalClaimAmount = totalBillAmount + totalOtherExpenseAmount;
    //() => this.setClaimAmount(totalClaimAmount);
    const advanceTaken = isEmpty(settlementReport.AdvanceTakenAmount)
      ? 0
      : settlementReport.AdvanceTakenAmount;
    return (
      <>
        <div className="form-row">
          <div className="autoselect">
            <label>Travel category</label>
            <FormGroup
              formName="reactselect"
              placeholder="select category"
              value={{
                label:
                  travelCategoryList.find(
                    (x) => x.value == selectedCategoryId
                  ) &&
                  travelCategoryList.find((x) => x.value == selectedCategoryId)
                    .label,
                value: selectedCategoryId,
              }}
              options={travelCategoryList}
              onChange={this.handleCategoryChange}
              disabled={isViewMode}
              width={250}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="grid bill-grid">
            <div className="report-grid-header">
              <span>Allowance</span>
              <span>Units</span>
              <span>Rate</span>
              <span>Amount</span>
              <span>Attachments</span>
              <span>Bill Amount</span>
            </div>
            <div className="grid__body">
              {billsAndClaimsList.map((x, i) => {
                return (
                  <div className="report-grid-row" key={i}>
                    <div>
                      <span>{x.Name}</span>
                    </div>
                    <div>
                      <span>{x.Units}</span>
                    </div>
                    <div className="amount">
                      <span>{getCommaFormattedNumber(x.Rate)}</span>
                    </div>
                    <div className="amount">
                      <span>{getCommaFormattedNumber(x.Amount)}</span>
                    </div>
                    <div className="attachment">
                      <div className="attachment__flex">
                        {parseInt(x.BillCount) !== 0 && x.BillCount !== "" ? (
                          <span className="attachment-count">
                            {x.BillCount}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="amount bill-amount">
                      <div className="bill-amount__flex">
                        {parseInt(x.BillAmount) !== 0 ? (
                          <span>{getCommaFormattedNumber(x.BillAmount)}</span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="grid__footer">
              <div className="grid__footer__row">
                <div>
                  <span>Total assessed eligibility </span>
                </div>
                <span></span>
                <span></span>
                <div className="amount">
                  <span>{getCommaFormattedNumber(totalBillAmount)}</span>
                </div>
                <span className="old-value">
                  {settlementReport.OldAssessedEligibilityAmt}
                </span>
              </div>
              {otherExpenses.map((x, i) => {
                return (
                  <div className="grid__footer__row" key={i}>
                    <div>
                      <span
                        className="other-expense-name"
                        // onClick={() => this.handleModalShow(2, x)}
                      >
                        {x.ExpenseName}
                      </span>
                    </div>
                    <span></span>
                    <span></span>
                    <div className="amount">
                      <span className="other-expense-amount">
                        {getCommaFormattedNumber(x.Amount)}
                      </span>
                    </div>
                    <span></span>
                  </div>
                );
              })}
              <div className="grid__footer__row summary">
                <div></div>
                <div className="summary__label">
                  <span className="claim-label">Total Claim Amount</span>
                  <span className="advance-label">
                    {totalClaimAmount > advanceTaken
                      ? "Less"
                      : totalClaimAmount === advanceTaken
                      ? "Equal"
                      : "More"}
                    : Advance taken
                  </span>
                  <span className="settle-label">Amount Receivable</span>
                </div>
                <div className="amount summary__amount">
                  <span className="claim-amount">
                    {getCommaFormattedNumber(totalClaimAmount)}
                  </span>
                  <span className="advance-amount">
                    {getCommaFormattedNumber(advanceTaken)}
                  </span>
                  <span className="settle-amount">
                    {getCommaFormattedNumber(totalClaimAmount - advanceTaken)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div>
            <div className="form-label">
              <span>Note</span>
            </div>
            <div>
              <span>{settlementReport.Note}</span>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {};

BillsAndClaims = connect(
  (state) => ({
    travelCategoryList: state.travelInformationReducer.travelCategoryList,
    travelRequestDetails: state.travelSettlementReducer.travelRequestDetails,
    otherExpenses: state.travelSettlementReducer.otherExpenses,
    billsAndClaimsList: state.travelSettlementReducer.billsAndClaims,
  }),
  mapDispatchToProps
)(BillsAndClaims);

export default BillsAndClaims;
