import React, { useState, useEffect } from "react";
// import AutoSelect from "Components/Common/AutoSelect";
import InputGroup from "components/form";
import Button from "components/button";
import { connect } from "react-redux";
import ReactDatePicker from "react-date-picker";
import { FaTrash } from "react-icons/fa";
import { assignJobDescription, getJobDescriptionByLevel } from "../../action";
import Pagination from "components/common/pagination";
import isEmpty from "isEmpty";

function AssignJobModal({
  onClose,
  jobDescriptionByLevel,
  assignJobDescription,
  changeAssignedJobDescription,
  employeeId,
  assignJobDescriptionData,
  changedJobDescriptionData,
  mode,
  getEmployeeJobDescription,
  getJobDescriptionByLevel,
}) {
  let [searchedJob, setSearchJob] = useState("");
  let [selectedjob, setSelectedJob] = useState("");
  let [page, setPage] = useState(1);
  let [activityDate, setActivityDate] = useState(new Date());
  let [jobs, setJobs] = useState(jobDescriptionByLevel);
  useEffect(() => {
    setJobs(jobDescriptionByLevel);
  }, [jobDescriptionByLevel]);
  const searchJobDesc = async (name, value) => {
    //let { value } = e.target;
    let initJobDesc = jobDescriptionByLevel;
    setSearchJob(value);
    // if (value !== "") {
    //   jobs = initJobDesc.filter((j) => j.label.includes(value));
    //   setJobs(jobs);
    // } else {
    //   jobs = initJobDesc;
    //   setJobs(jobs);
    // }
    let searchData = {
      EmployeeId: employeeId,
      JobTitle: value,
      PageIndex: pageIndex,
      PageSize: pageSize,
    };
    await getJobDescription(searchData);
  };

  const onChangeDate = (date) => {
    setActivityDate(date);
  };

  const setEffectiveDate = (job) => {
    if (page === 1) {
      setPage(2);
      setSelectedJob(job);
    } else if (page === 2) {
      setPage(1);
      setSelectedJob();
    }
    console.log("submmit page");
  };

  const postJobDescription = async () => {
    if (page === 1) {
      return;
    } else if (page === 2) {
      let jobDescData = {
        // "id": 10,
        employeeRefId: employeeId,
        jdRefId: selectedjob.value,
        effectiveDateEng: new Date(activityDate),
      };
      // if (mode === "new") 
      await assignJobDescription(jobDescData);
      // else if (mode === "update")
        // await changeAssignedJobDescription(jobDescData);
      await getEmployeeJobDescription(employeeId);
      onClose();
      setSelectedJob(null);
    }
  };

  let [pageIndex, setPageIndex] = useState(0);
  let [pageSize, setPageSize] = useState(10);

  const handleRowChange = async (row) => {
    let searchData = {
      EmployeeId: employeeId,
      JobTitle: searchedJob,
      PageIndex: pageIndex,
      PageSize: row,
    };
    setPageSize(row);
    await getJobDescription(searchData);
  };
  const handleIndexChange = async (index) => {
    let searchData = {
      EmployeeId: employeeId,
      JobTitle: searchedJob,
      PageIndex: index,
      PageSize: pageSize,
    };
    setPageIndex(index);
    await getJobDescription(searchData);
  };
  const getJobDescription = async (searchData) => {
    await getJobDescriptionByLevel(searchData);
  };
  return (
    <div className="defaultModal-body">
      <div className="defaultModal-container">
        <div className="assign-job">
          {page === 1 ? (
            <div className="assign-job__contain">
              <div className="assign-job__row">
                <InputGroup
                  // options={[]}
                  onChange={searchJobDesc}
                  name="searchJob"
                  value={searchedJob}
                  className="search-input"
                  width="300"
                  placeholder="Search and filter"
                />
              </div>
              <div className="assign-job__row">
                Suggestion based on Employee Position
              </div>
              <div className="assign-job__row">
                <div className="assign-job__row__block">
                  {jobs &&
                    jobs.map((d, i) => (
                      <span
                        onClick={() => setEffectiveDate(d)}
                        className="assign-job__row__title"
                        key={i}
                      >
                        {d.label}
                      </span>
                    ))}
                </div>
              </div>
              {isEmpty(jobs) ? null : (
                <Pagination
                  handleRowChange={handleRowChange}
                  handleIndexChange={handleIndexChange}
                  pageIndex={pageIndex}
                  searchedPage={{
                    index: pageIndex,
                    total: jobs ? jobs[0].totalRows : 0,
                  }}
                  rowsPerPage={pageSize}
                />
              )}
            </div>
          ) : (
            <div className="assign-job__contain">
              <div className="assign-job__row">Job Description Selected</div>
              <div className="assign-job__row__selected">
                <a className="assign-job__selectedjob">
                  <span>{selectedjob && selectedjob.label}</span>
                </a>
                <span className="assign-job__trash">
                  <FaTrash onClick={() => setEffectiveDate(null)} />
                </span>
              </div>
              <div className="create-content assign-job__row">
                <label>Assign the JD Effective from </label>
                <div>
                  <ReactDatePicker
                    onChange={(date) => onChangeDate(date)}
                    value={activityDate}
                    //   format={format}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="defaultModal-footer">
        <div className="button-group button-group__right">
          <Button bg="primary" onClick={postJobDescription}>
            Add
          </Button>
          <Button onClick={() => onClose()} bg="secondary-dark">
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  jobDescriptionByLevel:
    (state.employeeProfile.jobDescriptionByLevel &&
      state.employeeProfile.jobDescriptionByLevel) ||
    [],
  assignJobDescriptionData:
    state.employeeProfile.assignJobDescriptionData &&
    state.employeeProfile.assignJobDescriptionData,
  changedJobDescriptionData:
    state.employeeProfile.changedJobDescriptionData &&
    state.employeeProfile.changedJobDescriptionData,
});

const mapDispatchToProps = {
  assignJobDescription,
  getJobDescriptionByLevel,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignJobModal);
