import React from "react";
import EmployeeDetails from "./employeeDetails";
import LoanInformation from "./loanInformation";
import DocumentsAttached from "./documentsAttached";

function RequestedForm({ loanDetails }) {
  // const { data } = loanDetails;
  return (
    <>
      <EmployeeDetails loanDetails={loanDetails} />
      <LoanInformation />
      <DocumentsAttached />
    </>
  );
}

export default RequestedForm;
