import React, { Component } from "react";
import Icons from "components/icons";

export class TableRow extends Component {
  render() {
    const { props } = this;

    let fields = Object.keys(props).map(function (key) {
      return { key: key, value: props[key] };
    });

    fields = fields.filter(
      (f) =>
        f.key !== "index" &&
        f.key != "Id" &&
        f.key != "handleDelete" &&
        f.key != "handleEdit"
    );
    return (
      <tr className="table-row e-row" role="row" key={props.index}>
        {fields &&
          fields.map((field, i) => {
            return (
              <>
                {field.key === "Actions" ? (
                  <td className="e-custom-row" role="gridcell" key={i}>
                    <div className="flex library">
                      <button
                        className="button with-icon button-white"
                        onClick={(e) => this.props.handleEdit(props.Id)}
                      >
                        <Icons name="Edit" className="flex"/>
                        <span>Edit</span>
                      </button>
                      <button
                        className="button with-icon button-white"
                        onClick={(e) => this.props.handleDelete(props.Id)}
                      >
                        <Icons name="Delete"  className="flex"/>
                        <span>Delete</span>
                      </button>
                    </div>
                  </td>
                ) : (
                  <td className="e-rowcell e-templatecell" role="gridcell">
                    <span>{field.value}</span>
                  </td>
                )}
              </>
            );
          })}
      </tr>
    );
  }
}

export default TableRow;
