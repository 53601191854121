import React from 'react'
import {Tabs,Tab} from 'components/tabs/index.d'
import Current from './current';
import History from './history'

function index(props) {
    return (
        <div className="placement-roles reporting">
            <div className="tertiary-title">
                <h3>Roles & Responsibility</h3>
            </div>
            <div className="reporting-tab">
                <Tabs>
                    <Tab label="Active" name="Active">
                        <Current {...props}/>
                    </Tab>
                    <Tab label="History" name="History">
                        <History />
                    </Tab>
                </Tabs>
            </div>
        </div>
    )
}

export default index
