import React from "react";
import classnames from "classnames";

export const Sidetab = (props) => {
  const { currentStep, onStepChange, isAt, stepsSetting } = props;

  const tabContent = [
    {
      num: 1,
      title: "The Position",
      details: "Define the Job Position you are hiring",
    },
    {
      num: 2,
      title: "Application Form",
      details: "Select the components in Application Form",
    },
    {
      num: 3,
      title: "Hiring Stages",
      details: "Define or Select the stages the application will go through",
    },
    {
      num: 4,
      title: "Scorecard",
      details: "Define how th candidate will be scored for the job",
    },
    {
      num: 5,
      title: "Marking Scheme",
      details: "How the candidate will be Scored based on hiring stages",
    },
    {
      num: 6,
      title: "Hiring Team",
      details: "Define who will be involved in this position's hiring process",
    },
  ];

  return (
    <div className="recruit-header">
      {tabContent.map((item) => (
        <Tab
          tab={item}
          onStepChange={onStepChange}
          currentStep={currentStep}
          stepsSetting={stepsSetting}
          isAt={isAt}
        />
      ))}
    </div>
  );
};

export const Tab = ({ tab, currentStep, onStepChange, isAt, stepsSetting }) => {
  const { lastStep, hideSteps } = stepsSetting || {};
  if (!lastStep || (hideSteps && tab.num > lastStep)) {
    return null;
  }

  const classes = classnames({
    "recruit-header-item": true,
    disabled: tab.num > isAt || tab.num > lastStep,
    selected: tab.num === currentStep,
  });

  return (
    <a
      key={tab.num}
      className={classes}
      disabled={true}
      onClick={() => onStepChange(tab.num)}
    >
      <div className="recruit-header-title">{tab.title}</div>
      <div className="recruit-header-body">
        <p>{tab.details}</p>
      </div>
    </a>
  );
};

export default Sidetab;
