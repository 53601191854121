import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getBaseAxios } from "helpers/constants";
import Avatar from "react-avatar";
import { getUploadPath } from "constants/userDetails";
import { GetCandidateComments } from "store/actions/recruitment/setting";

function Comment(props: any) {
  const { selectedCandidate } = props;

  useEffect(() => {
    getCandidateComments();
  }, []);

  const getCandidateComments = async () => {
    const param = {
      candidateId: selectedCandidate.CandidateId,
      jobId: selectedCandidate.JobId,
    };
    await props.GetCandidateComments(param);
  };
  return (
    <div className="dashboard-email">
      <div className="dashboard-email__header">
        <span>Comments</span>
      </div>
      <div className="dashboard-email__body">
        {props.candidateComments.map((comment, index) => {
          return (
            <div className="email-row" key={index}>
              <div className="img-content">
                <Avatar
                  alt={comment.CommentedBy}
                  name={comment.CommentedBy}
                  src={getUploadPath(comment?.UserImage)}
                  className="emp-img"
                  round
                  size="60"
                />
              </div>
              <div className="email-content">
                <span className="email-from">{comment.CommentedBy}</span>
                {/* <span className="email-subject">{comment.Subject}</span> */}
                <span className="email-body">{comment.Comments}</span>
              </div>
              <div className="email-date">
                <span className="email-time">
                  {comment.FormattedCreatedDateEng}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  candidateComments: state.recruitment_settingReducer.candidateComments,
});

const mapDispatchToProps = {
  GetCandidateComments,
};

export default connect(mapStateToProps, mapDispatchToProps)(Comment);
