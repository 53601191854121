import React from "react";
import { useSelector } from "react-redux";
import {Link} from 'react-router-dom'

function Pending(props) {
  const { pending } = props;
  const hrDashboard = useSelector((state) => state.hrReducer.hrDashboard);
  const { PendingRequest } = hrDashboard;
  
  return (
    <div className="pending">
      {hrDashboard && PendingRequest ? (
        <ul className="pending-list">
          {PendingRequest.map((pen, index) => {
            const isAspx = pen.PageUrl.split('.').includes('aspx');
            if(isAspx){
              return <a href={props.base + pen.PageUrl}>
              <li key={index + Math.random()} className="pending-list__item">
                <span className="pending-name">{pen.Title}</span>
                <span className="pending-value">{pen.Total}</span>
              </li>
            </a>
            }
            return <Link to={ pen.PageUrl}>
              <li key={index + Math.random()} className="pending-list__item">
                <span className="pending-name">{pen.Title}</span>
                <span className="pending-value">{pen.Total}</span>
              </li>
            </Link>
          })}
        </ul>
      ) : (
        <span className="no-found">No Pending Found</span>
      )}
    </div>
  );
}

export default Pending;
