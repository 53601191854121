import {Tab, Tabs} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import FormGroup from "components/form";
import {getBaseAxios} from "helpers/constants";
import isEmpty from "isEmpty";
import React, {useEffect, useState} from "react";
import Avatar from "react-avatar";
import {Scrollbars} from "react-custom-scrollbars";

//#endregion Material UI
const StyledTabs = withStyles({
  root: {
    borderBottom: "2px solid #ebebeb",
    minHeight: 43,
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > div": {
      maxWidth: 85,
      width: "100%",
      backgroundColor: "#006df9",
    },
  },
})((props: any) => <Tabs {...props} TabIndicatorProps={{children: <div />}} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#343434",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    transition: "all .2s linear",
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
    },
    "&:hover": {
      color: "#666",
    },
  },
}))((props: any) => <Tab disableRipple {...props} />);
//#endregion

const tabList = [
  {label: "Applied", value: 0},
  {label: "Shortlisted", value: 1},
];

export default function Listing(props: any) {
  const {appliedCandidateList, candidateListByStage, handleCandidateClick} = props;

  const [tabId, setTabId] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [isSelectAll, setSelectAll] = useState(false);
  const [currentCandidateId, setCurrentCandidateId] = useState(null);

  let [tabwiseCandidates, setCurrentCandidates] = useState(
    appliedCandidateList ? appliedCandidateList : candidateListByStage
  );
  //let [showTab, setHideShowTab] = useState(appliedCandidateList ? true : false);
  let [showTab, setHideShowTab] = useState(false);

  useEffect(() => {
    props.selectedCandidate && setCurrentCandidateId(props.selectedCandidate.CandidateId);
  }, [props.selectedCandidate]);

  const handleTabChange = (event, value) => {
    setTabId(value);
    if (value == 0) {
      setCurrentCandidates(appliedCandidateList);
    } else if (value == 1) {
      setCurrentCandidates(candidateListByStage);
    }
  };

  const handleSearch = (name, value) => {
    setSearchText(value);
  };

  const handleSelectAll = (e) => {
    const {checked} = e && e.target;
    setSelectAll(checked);
  };

  const onCandidateClick = (candidate) => {
    setCurrentCandidateId(candidate.CandidateId);
    handleCandidateClick(candidate);
  };

  let candidates = [];
  if (showTab) {
    candidates = tabwiseCandidates;
  } else {
    candidates = candidateListByStage;
  }

  const _renderList = (candidate) => {
    let dynamicClass = "information-listing-list";
    if (candidate.CandidateId == currentCandidateId) {
      dynamicClass = "information-listing-list active";
    }

    const imageUrl = getBaseAxios() + candidate.ImageName;
    return (
      <div
        className={dynamicClass}
        onClick={() => onCandidateClick(candidate)}
        key={candidate.CamdidateId}
      >
        <div className="information-listing-avatar">
          <Avatar
            className="user-avatar"
            name={candidate.CandidateName}
            size="40"
            round={true}
            src={imageUrl}
          />
        </div>
        <div className="information-listing-desc">
          <span>{candidate.CandidateName}</span>
          <span className="fonts-light_small">
            {isEmpty(candidate.HighestDegree) ? "" : candidate.HighestDegree}
          </span>
          <span className="fonts-light_small">
            {isEmpty(candidate.Position && candidate.OrganisationName)
              ? ""
              : candidate.Position + " at " + candidate.OrganisationName}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="information-listing">
      <div className="information-tabs">
        {showTab && (
          <StyledTabs
            value={tabId}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
          >
            {tabList &&
              tabList.map((list, index) => {
                const tabLabel =
                  list.label +
                  " " +
                  (list.value == 0
                    ? appliedCandidateList && appliedCandidateList.length
                    : list.value == 1
                    ? candidateListByStage && candidateListByStage.length
                    : 0);

                return <StyledTab label={tabLabel} value={list.value} key={index} />;
              })}
          </StyledTabs>
        )}
      </div>

      <div className="information-inputs">
        <FormGroup
          name="searcText"
          className="search"
          placeholder="Search by name or #tag"
          type="search"
          value={searchText}
          onChange={handleSearch}
        />
      </div>

      {/* {candidates && candidates.length > 0 && (
        <div className="information-listing-checkbox">
          <FormGroup
            formName="checkgroup"
            name="isSelectAll"
            value={isSelectAll}
            label="Select all"
            className="IsAnywhere-check"
            checked={isSelectAll}
            onChange={handleSelectAll}
            labelPosition={"right"}
          />
        </div>
      )} */}

      <Scrollbars className="information-listing-scroll" style={{width: "100%"}}>
        {candidates &&
          candidates
            .filter((item) =>
              item.CandidateName.toLowerCase().includes(searchText.toLowerCase())
            )
            .map((candidate) => {
              return _renderList(candidate);
            })}
      </Scrollbars>
    </div>
  );
}
