import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import FormGroup from "components/form";
import EmployeeSearch from "components/common/employeeSearch";
import React from "react";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

function createData(
  ApprovalTypeId,
  ApprovalType,
  SubstituteId,
  SubstituteName,
  IsDelegate,
  IsChangeClicked
) {
  return {
    ApprovalTypeId,
    ApprovalType,
    SubstituteId,
    SubstituteName,
    IsDelegate,
    IsChangeClicked,
  };
}

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  paper: {
    overflow: "unset !important",
  },
});

const handleChange = (props, id, isChangeClicked) => {
  props.handleChange && props.handleChange(id, isChangeClicked);
};
const handleDelegate = (props, id, isDelegate) => {
  props.handleDelegate && props.handleDelegate(id, isDelegate);
};

const handleDelegationTableDropdown = (props, id, name, selected) => {
  console.log("name and selected", name, selected);
  props.handleDelegationTableDropdown &&
    props.handleDelegationTableDropdown(id, selected);
};

export default function DelegationTable(props) {
  const {data, hasActions, employeeList, isViewMode} = props;

  let rows = [];
  data &&
    data.map((m) => {
      rows.push(
        createData(
          m.ApprovalTypeId,
          m.ApprovalType,
          m.SubstituteId,
          m.SubstituteName,
          m.IsDelegate,
          m.IsChangeClicked
        )
      );
    });
  const classes = useStyles();
  return (
    <TableContainer component={Paper} className={classes.paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableBody>
          {rows &&
            rows.map((row) => {
              const selectedSubstitutePerson = employeeList.find(
                (f) => f.value == row.SubstituteId
              );

              return (
                <StyledTableRow key={row.ApprovalType} height="60">
                  <StyledTableCell component="th" scope="row" width="250">
                    {row.ApprovalType}
                  </StyledTableCell>
                  <StyledTableCell align="left" width="250">
                    {row.IsDelegate ? row.SubstituteName : "-"}
                  </StyledTableCell>
                  {hasActions && (
                    <StyledTableCell align="left" width="350">
                      <div className="flex">
                        {row.IsDelegate ? (
                          !row.IsChangeClicked ? (
                            <a
                              className="change-link"
                              onClick={() =>
                                isViewMode
                                  ? null
                                  : handleChange(props, row.ApprovalTypeId, true)
                              }
                            >
                              <span>Change</span>
                            </a>
                          ) : (
                            <EmployeeSearch
                              onChange={(name, selected) =>
                                handleDelegationTableDropdown(
                                  props,
                                  row.ApprovalTypeId,
                                  name,
                                  selected
                                )
                              }
                              // onChange={(name, selected) =>
                              //   handleDelegationTableDropdown(
                              //     props,
                              //     row.ApprovalTypeId,
                              //     name,
                              //     selected
                              //   )
                              // }
                              name="selectedSubstitutePerson"
                              value={selectedSubstitutePerson}
                              placeholder="Search (3 letters..) and Select"
                              label="Select a person"
                              width={250}
                            />
                            // <FormGroup
                            //   formName="reactselect"
                            //   name="selectedSubstitutePerson"
                            //   placeholder="select a person"
                            //   value={selectedSubstitutePerson}
                            //   options={employeeList.map((x) => {
                            //     return {
                            //       label: x.FullName,
                            //       value: x.EmployeeId,
                            //     };
                            //   })}
                            //   width={250}
                            //   onChange={(name, selected) =>
                            //     handleDelegationTableDropdown(
                            //       props,
                            //       row.ApprovalTypeId,
                            //       name,
                            //       selected
                            //     )
                            //   }
                            // />
                          )
                        ) : null}

                        <a
                          className="change-link"
                          onClick={() =>
                            isViewMode
                              ? null
                              : handleDelegate(props, row.ApprovalTypeId, !row.IsDelegate)
                          }
                        >
                          <span>{row.IsDelegate ? "Do not delegate" : "Delegate"}</span>
                        </a>
                      </div>
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
