import axios from "axios";
import {
  GET_JOB_DETAILS,
  IS_ACTIVE_JOBS_LOADING,
  GET_ACTIVE_JOBS,
  SET_SPINNER_LOADING
} from "actions/types";
import { toastMessage } from "actions/validateAction";
const requirementBaseApi = "/v1/recruitment/";

export const getJobDetails = jobId => dispatch => {
  dispatch(setSpinnerLoading(true));
  axios
    .get(`${requirementBaseApi}active/${jobId}`)
    .then(response => {
      dispatch({
        type: GET_JOB_DETAILS,
        payload: response.data
      });
      dispatch(setSpinnerLoading(false));
    })
    .catch(err => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to get job details."
        }
      };
      dispatch(setSpinnerLoading(false));
      toastMessage(dispatch, response);
    });
};

export const getActiveJobs = () => dispatch => {
  dispatch(setSpinnerLoading(true));
  axios
    .get(`${requirementBaseApi}active`)
    .then(response => {
      dispatch({
        type: GET_ACTIVE_JOBS,
        payload: response.data
      });
      dispatch(setSpinnerLoading(false));
    })
    .catch(err => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to get job details."
        }
      };
      dispatch(setSpinnerLoading(false));
      toastMessage(dispatch, response);
    });
};

// Set loading state
export const setSpinnerLoading = data => {
  return {
    type: SET_SPINNER_LOADING,
    payload: data
  };
};
