import React, { useState } from "react";
import Avatar from "react-avatar";
import { MdMailOutline } from "react-icons/md";
import { FaRegFilePdf, FaRegCommentDots } from "react-icons/fa";
import Modal from "components/modal";
import {getUploadPath} from 'constants/userDetails'

function DisciplinaryStageDetails({
  stageDetails,
  handleCommentModalShow,
  isCaseClosed,
}) {
  const [iframeShow, setIframeShow] = useState(false);
  const [fileLocation, setFileLocation] = useState("");
  return (
    <>
      <div className="stage-details">
        <div className="stage-details__header">
          <span>{stageDetails.ActionDateText}</span>
          {isCaseClosed ? null : (
            <div className="comment-block">
              <FaRegCommentDots className="comment-block__icon" />
              <span
                className="comment-block__text"
                onClick={() =>
                  handleCommentModalShow(stageDetails.ActionStageId)
                }
              >
                Add Comment
              </span>
            </div>
          )}
        </div>
        <div className="stage-details__body">
          <div className="body-row">
            <div className="stage-title">
              <span className="stage-title__name">
                {stageDetails.ActionTitle}
              </span>
              <span className="stage-title__value">
                {stageDetails.ActionRemarks}
              </span>
            </div>
          </div>
          {stageDetails.ActionStageId === 3 ? (
            <div className="body-row response-medium">
              <div className="action-mode">
                <span className="action-mode__title">Mode</span>
                <span className="action-mode__value">
                  {stageDetails.ResponseMedium == 1
                    ? "E-mail"
                    : "Physical Document"}
                </span>
              </div>
              <div className="action-medium flex">
                {stageDetails.ResponseMedium == 1 ? (
                  <div className="action-medium__mail flex">
                    <MdMailOutline className="mail-icon" />
                    <span className="mail-text">View Mail</span>
                  </div>
                ) : (
                  stageDetails.Attachments.map((doc) => {
                    const splittedFile = doc.UserFileName.split(".");
                    const fileType = splittedFile[splittedFile.length - 1];
                    const imageTypes = ["gif", "jpeg", "png", "jpg"];

                    return (
                      <div className="action-medium__attachment flex">
                        {imageTypes.includes(fileType) ? (
                          <div
                            className="flex doc"
                            onClick={() => {
                              setFileLocation(doc.FileLocation);
                              setIframeShow(true);
                            }}
                          >
                            <FaRegFilePdf className="doc-icon" />
                            <span className="doc-name">{doc.UserFileName}</span>
                          </div>
                        ) : (
                          <a
                            href={ doc.FileLocation}
                            //target="_blank"
                            className="flex"
                            download
                          >
                            <FaRegFilePdf className="doc-icon" />
                            <span className="doc-name">{doc.UserFileName}</span>
                          </a>
                        )}
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          ) : null}

          <div className="body-row">
            <div className="actionby">
              <span className="actionby__title">
                {stageDetails.ActionByDisplayText}
              </span>
              <div className="actionby__value">
                {stageDetails?.ActionByEmployees?.map((x, i) => {
                  return (
                    <div className="actionby-details" key={i}>
                      <Avatar
                        alt={x.FullName}
                        src={x.Imagepath &&  getUploadPath(x.Imagepath)}
                        className="emp-image"
                        round
                        size={50}
                      />
                      <div className="emp-detail">
                        <span className="emp-name">{x.FullName}</span>
                        <span className="emp-desg">{x.Designation}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="createdby">
              <span>
                Recorded by {stageDetails.CreatedByText} on{" "}
                {stageDetails.CreatedOnText}
              </span>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Image viewer"
        open={iframeShow}
        onModalClose={() => setIframeShow(false)}
        className="iframe-modal"
      >
        <IframeScreen fileLocation={fileLocation} />{" "}
      </Modal>
    </>
  );
}

const IframeScreen = ({ fileLocation }) => {
  return (
    <div className="iframe-screen">
      <iframe
        class="iframe"
        width="1000"
        height="600"
        frameborder="0"
        src={fileLocation}
      ></iframe>
    </div>
  );
};
export default DisciplinaryStageDetails;
