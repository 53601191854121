import React, { useState, Component } from "react";
import { connect } from "react-redux";
import Button from "components/button";
import DetailCard from './detailCard';
import EducationForm from './educationForm';
import { InputValidator } from "helpers/inputValidator";
import isEmpty from "isEmpty";
import { MdAdd } from "react-icons/md";

import  {
    deleteEmployeeEducation,
    getEmployeeEducationList,
    currentlySelectedSection,
    GetQualificationList
} from '../action';
class Education extends Component{
    
    constructor(props) {
        super(props);
        this.state = {
            showAddSection: false,
            editView: false,
            errors: {},
            Course: '',
            Qualification: '',
            College: '',
            Country: '',
            University: '',
            PassedYear: null,
            GradeValue: null,
            MajorSubjects: null,
            IsGPA: true,
            Files: [],
        }
    }

    componentDidMount() {
        this.props.GetQualificationList();
    }

    handleInput = (name, value) => {
        console.log()
        this.setState(
            { [name]: value, },
            () => {
                this.updateErrors();
            }
        );
    }

    updateErrors = async () => {
        const { errors } = this.state;
        if (!isEmpty(errors)) {
        let errors = await InputValidator(document, this.state);
        this.setState({ errors });
        }
    };

    setSelectedFiles = (e) => {
        const file = e.files[0];
        if (file) {
            let { Files } = this.state;
            Files.push({
                AttachmentId: Files.length + 1,
                file: file,
            });
            this.setState({ Files });
        }
    };

    changeGradingSystem = IsGPA => this.setState({ IsGPA });

    handleAutoSelect = (label, value) => {
        this.setState({ [label]: value });
    }

    deleteEducation = async (educationId) => {
        const {
            deleteEmployeeEducation,
            getEmployeeEducationList,
            employeeId,
        } = this.props;
        this.setState({ showAddSection: false })
        await deleteEmployeeEducation(educationId);
        if (this.props.employeeEducatonDeleted)
            getEmployeeEducationList(employeeId)
    }
    
    toggleAddSecton = (showAddSection, editView, title) => {
        this.setState({ showAddSection, editView, editViewData: null });
        this.props.currentlySelectedSection(title);
    }

    toggleEditView = (flag, editViewData, title) => {
        this.setState({ showAddSection: false, editView: editViewData && editViewData.EductionId, editViewData });
        this.props.currentlySelectedSection(title);
    }

    render () {

        const { employeeEducationList, currentlySelectedEduSection, educationQualificationList } = this.props;
        return (
        <div className="education__education">
            <div className="education-heading">
                <h4>Education</h4>
                <Button 
                    onClick={() => this.toggleAddSecton(true, false, 'education')}
                    bg="white" 
                    buttonType="icon-button"
                    leftIcon={<MdAdd />} 
                    title="Add" /> 
            </div>
            {this.state.showAddSection && currentlySelectedEduSection == 'education' &&
            <EducationForm 
            closeAddSection={() => this.toggleAddSecton(false, false, 'education')}
            employeeId={this.props.employeeId}
            />
            }
            <div className="education__education__details">
                    {employeeEducationList.length > 0 ?  employeeEducationList.map(edu => 
                        <>
                        <DetailCard
                        key={edu.EductionId}
                        detail={edu}
                        toggleEditView={(data, title) => this.toggleEditView(true, data, title)}
                        editView={true}
                        deleteEmployeeItem={(educationId) => this.deleteEducation(educationId)}
                        educationQualificationList={educationQualificationList}
                        title="education"
                        baseUrl={this.props.baseUrl}
                        // editView={this.state.editView == edu.EductionId && currentlySelectedEduSection == 'education'}
                        editViewId={this.state.editView}
                        currentlySelectedEduSection={currentlySelectedEduSection}
                        />
                        {this.state.editView == edu.EductionId && currentlySelectedEduSection == 'education' &&
                        <EducationForm
                            editView={true}
                            editViewData={edu}
                            employeeId={this.props.employeeId}
                            toggleEditView={() => this.toggleEditView(false, null)}
                            employeeId={this.props.employeeId}
                        />}
                    </>
                    ) :  <div className="education__detail__details">
                            <div className="education-detail">
                                    <div className="education-detail__primary">
                                        <p className="noData">No Data Found</p>
                                    </div>
                            </div>
                        </div>}
            </div>
        </div>
        );
    }
}

const mapStateToProps = state => ({
    employeeEducationList: state.employeeProfile.employeeEducationList || [],
    employeeEducatonDeleted: state.employeeProfile.employeeEducatonDeleted,
    currentlySelectedEduSection: state.employeeProfile.currentlySelectedEduSection,
    educationQualificationList: state.employeeProfile.educationQualificationList,
});

const mapDispatchToProps = {
    deleteEmployeeEducation,
    getEmployeeEducationList,
    currentlySelectedSection,
    GetQualificationList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Education);