import React from 'react'
import {
    Date_Format
} from 'constants/dateFormat';
import { connect  } from 'react-redux';

function History(props) {
    const { employeeManagersHistory } = props;
    let dmData = [];
    let mxData = [];
    let cmData = [];
    let dataToFilter = [
        employeeManagersHistory.length > 0 && employeeManagersHistory.map(dm => {
            if (dm.ReportingToManager === 1) {
                dmData.push({
                    date: Date_Format(dm.EffectiveDateEng),
                    position: dm.ManagerialType === 1 ? dm.ManagerialPositionName : dm.ManagerialPersonName,
                    type: dm.ManagerialTypeName
                })
            } else if (dm.ReportingToManager === 2) {
                mxData.push({
                    date: Date_Format(dm.EffectiveDateEng),
                    position: dm.ManagerialType === 1 ? dm.ManagerialPositionName : dm.ManagerialPersonName,
                    type: dm.ManagerialTypeName
                })
            } else if (dm.ReportingToManager === 3) {
                cmData.push({
                    date: Date_Format(dm.EffectiveDateEng),
                    position: dm.ManagerialType === 1 ? dm.ManagerialPositionName : dm.ManagerialPersonName,
                    type: dm.ManagerialTypeName
                })
            }
        }),
    ];
    Promise.all(dataToFilter);
    return (
        <div className="reporting-history">
            <HistoryBlock title="Direct Manager" data={dmData} />
            <HistoryBlock title="Matrix Manager" data={mxData} />
            <HistoryBlock title="Custom Manager" data={cmData} />
        </div>
    )
}

const HistoryBlock = ({ title, data }) =>{
    return(
        <div className="reporting-history__block">
            <div className="reporting-history__title">
                <h4>{title}</h4>
            </div>
            {
                data && data.length > 0 ? data.map(item=>{
                    return <HistoryRow data={item} key={item.position} />
                }) 
                :
                <span className="no-data">No data</span>
            }
        </div>
    )
}

const HistoryRow = ({ data }) =>{
    return(
        <div className="reporting-history__row">
            <div className="reporting-history__col">
                {data.date}
            </div>
            <div className="reporting-history__col">
                {data.type}
            </div>
            <div className="reporting-history__col">
                {data.position}
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    employeeManagersHistory: state.employeeProfile.employeeManagersHistory || [],
});

export default connect(mapStateToProps, null)(History)
