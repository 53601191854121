import React, { useState, useEffect } from "react";
import Icons from "components/icons";
import SvgIcons from "components/svgIcons";
import Button from "components/button";
import FormGroup from "components/form";
import { Date_Format, Time_Format } from "helpers/dateFormat";

function useTimer(todayAttendance, type) {
  const [att, setAtt] = useState(null);
  // let todayAttendance = null;
  // useEffect(() => {
  //   if (attendanceByDate) {
  //     setAtt(attendanceByDate);
  //   }
  // }, [attendanceByDate]);

  // if (att && att.length > 0) {
  //   todayAttendance = att.find(
  //     (f) => Date_Format(f.DateEng) == Date_Format(new Date())
  //   );
  // }

  let attTime = null;
  let attNote = null;
  let timeText = null;
  let clockButtonText = null;
  let noteLabel = null;
  let btn_bg_color = "";
  let color = "";

  let displayClockOut = false;

  if (type == "ClockIn") {
    attTime =
      todayAttendance && (todayAttendance.InTimeDv || todayAttendance.InTime);
    attNote = todayAttendance && todayAttendance.InNote;

    timeText = "Clocked-in";
    clockButtonText = "CLOCK IN";
    noteLabel = "Clock-in note";
    btn_bg_color = "in_bg_color";
    color = "in_color";
  } else if (type == "ClockOut") {
    attTime =
      todayAttendance && (todayAttendance.OutTimeDv || todayAttendance.OutTime);
    attNote = todayAttendance && todayAttendance.OutNote;

    if (
      todayAttendance &&
      (todayAttendance.InTimeDv || todayAttendance.InTime)
    ) {
      displayClockOut = true;
    }
    timeText = "Clocked-out";
    clockButtonText = "CLOCK OUT";
    noteLabel = "Clock-out note";
    btn_bg_color = "out_bg_color";
    color = "out_color";
  }

  return {
    timeText,
    clockButtonText,
    noteLabel,
    btn_bg_color,
    color,
    displayClockOut,
    attTime,
    attNote,
  };
}

function InOut(props: any) {
  const { type, todayAttendance } = props;
  const {
    timeText,
    clockButtonText,
    noteLabel,
    btn_bg_color,
    color,
    displayClockOut,
    attTime,
    attNote,
  } = useTimer(todayAttendance, type);

  const [note, setNote] = useState(attNote);
  const [time, setTime] = useState(attTime);
  const [showClockOut, setShowClockOut] = useState(displayClockOut);

  const [isNoteSaved, setIsNoteSaved] = useState(attNote ? true : false);
  const [isEditNote, setNoteState] = useState(false);

  const onClockInOut = async () => {
    const isCheckIn = type == "ClockIn";
    const currentTime = Time_Format(new Date());
    const param = { ManualVisibleTime: currentTime };

    await props.SaveAttendanceTime(isCheckIn, param);

    if (isCheckIn) {
      setShowClockOut(true);
    }
    setTime(currentTime);
  };

  const onChangeNote = (name, value) => {
    setNote(value);
  };

  const onSaveNote = () => {
    const isCheckIn = type == "ClockIn";
    const param = { note: note };

    if (isEditNote) {
      props.UpdateAttendanceNote(isCheckIn, param);
    } else {
      props.SaveAttendanceNote(isCheckIn, param);
    }
    setIsNoteSaved(true);
  };

  const onEditNote = () => {
    setNoteState(true);
    setIsNoteSaved(false);
  };

  useEffect(() => {
    if (attTime) {
      setTime(attTime);
    }
    if (attNote) {
      setNote(attNote);
      setIsNoteSaved(attNote ? true : false);
    }
    if (displayClockOut) {
      setShowClockOut(displayClockOut);
    }
  }, [attTime, attNote, displayClockOut]);

  if (type == "ClockOut" && !showClockOut) {
    return null;
  }
  return (
    <div className="web-attendance-body-card-inOut">
      {time && (
        <div className={"web-attendance-body-card-inOut-text " + color}>
          <Icons name="CircleCheck" />
          <span className="web-attendance-body-card-inOut-text-time">
            {time}
          </span>
          <span>{timeText}</span>
        </div>
      )}
      {(!time || type == "ClockOut") && (
        <Button
          buttonClass={btn_bg_color}
          onClick={onClockInOut}
          buttonType="icon-button"
          type="button"
          bg="primary"
          icon={<SvgIcons name="Time" />}
        >
          {clockButtonText}
        </Button>
      )}

      {isNoteSaved ? (
        <div className="web-attendance-body-card-inOut-note">
          <span>{note}</span>
          <Button
            onClick={onEditNote}
            buttonType="icon-button"
            type="button"
            bg="primary-light"
            icon={<Icons name="Edit" />}
          >
            Edit
          </Button>
        </div>
      ) : (
        <div className="web-attendance-body-card-inOut-fill_note">
          <FormGroup
            name="note"
            value={note}
            onChange={onChangeNote}
            label={noteLabel}
            // error={errors && errors?.comment}
            // validators={["required"]}
          />
          <div
            className="web-attendance-body-card-inOut-fill_note-check"
            onClick={onSaveNote}
          >
            <Icons name="Check" />
          </div>
        </div>
      )}
    </div>
  );
}

export default InOut;
