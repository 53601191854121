import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import moment from "moment";
import "./time-request.style.scss";
import Title from "../../../common/title";
import Icons from "components/icons";
import Form from "components/form";
import Modal from "components/modal";
import SelectEmployee from "scenes/employee/selectEmployee";
import Approvers from "./approvers";
import { convertTime12to24 } from "helpers/timeConvert";
import { InputValidator } from "helpers/inputValidator";
import {
  CreateTimeRequest,
  UpdateTimeRequest,
  GetTimeApprovalRecommendedForEmployee,
} from "../action";
import { GetAttendanceByDate } from "../attendance/action";
import { DisplayClientSideMessage } from "actions/commonAction";
import isEmpty from "isEmpty";
import { Date_Format } from "helpers/dateFormat";
import { isFirstDayOfMonth } from "date-fns";
import { getEngDate, getNepaliDate } from "components/datePicker/helpers";
import { EmpTimeAttRequestThreshold_Enum } from "../../enums";

function timeAttendanceTemplate(id) {
  return {
    id: id,
    date: null,
    deviceInTime: null,
    deviceOutTime: null,
    actualInTime: null,
    actualOutTime: null,
    inNote: "",
    outNote: "",
  };
}

export class index extends Component {
  state = {
    minDate: new Date(),
    maxDate: new Date(),
    timeRequestId: 0,
    reviewerList: [],
    approverList: [],
    checkedReviewer: null,
    checkedApprover: null,
    timeAttendance: [timeAttendanceTemplate(1)],
    employeeToAssign: null,
    isApproverReviewerSame: false,
  };

  async componentDidMount() {
    const { dataToEdit } = this.props;

    const dateRange = await this.getDateRange();
    let employeeId = 0;
    if (!isEmpty(dataToEdit) && dataToEdit.TimeRequests) {
      employeeId = this.manageDataToEdit(dataToEdit);
    } else if (!this.props.isAssign) {
      await this.props.GetAttendanceByDate(
        dateRange.minDate,
        dateRange.maxDate
      );
    }

    if (!this.props.isAssign) {
      await this.manipulateApproverReviewer(employeeId);
    }
  }

  getDateRange = async () => {
    const { hrCommonSettings } = this.props;
    let minDate = new Date();
    let maxDate = new Date();

    if (hrCommonSettings) {
      const { EmpTimeAttRequestThreshold } = hrCommonSettings;

      switch (EmpTimeAttRequestThreshold) {
        case EmpTimeAttRequestThreshold_Enum.AnyDaysUptoToday:
          minDate.setDate(minDate.getDate() - 365);
          break;
        case EmpTimeAttRequestThreshold_Enum.TodayOnly:
          minDate = new Date();
          break;
        case EmpTimeAttRequestThreshold_Enum.ThisWeek:
          minDate = moment(minDate).startOf("week").toDate();
          break;
        case EmpTimeAttRequestThreshold_Enum.ThisMonth:
          minDate = moment(minDate).startOf("month").toDate();
          break;
        case EmpTimeAttRequestThreshold_Enum.PastThreeDaysAndToday:
          minDate.setDate(minDate.getDate() - 3);
          break;
        case EmpTimeAttRequestThreshold_Enum.PastTwoDaysAndToday:
          minDate.setDate(minDate.getDate() - 2);
          break;
        case EmpTimeAttRequestThreshold_Enum.PastOneDayAndToday:
          minDate.setDate(minDate.getDate() - 1);
          break;
      }
    }

    this.setState({ minDate, maxDate });
    return { minDate, maxDate };
  };

  manipulateApproverReviewer = async (employeeId) => {
    const { dataToEdit } = this.props;
    await this.props.GetTimeApprovalRecommendedForEmployee(employeeId);

    const { timeRequestApprovalRecommended } = this.props;
    let approverList = [];
    let reviewerList = [];
    if (!isEmpty(timeRequestApprovalRecommended)) {
      timeRequestApprovalRecommended.Approvals.map((approval) => {
        approverList.push({
          Id: approval.EmployeeId,
          FullName: approval.EmployeeName,
          Imagepath: approval.Image,
          Designation: approval.Position,
        });
      });

      timeRequestApprovalRecommended.Recommended.map((approval) => {
        reviewerList.push({
          Id: approval.EmployeeId,
          FullName: approval.EmployeeName,
          Imagepath: approval.Image,
          Designation: approval.Position,
        });
      });
    }

    let checkedReviewer = null;
    let checkedApprover = null;

    if (dataToEdit) {
      checkedReviewer = dataToEdit.RecommenderId;
    } else if (reviewerList.length == 1) {
      checkedReviewer = reviewerList[0].Id;
    }

    if (dataToEdit) {
      checkedApprover = dataToEdit.ApprovalId;
    }
    if (approverList.length == 1) {
      checkedApprover = approverList[0].Id;
    }

    let isApproverReviewerSame = false;
    if (
      !isEmpty(checkedReviewer) &&
      !isEmpty(checkedApprover) &&
      approverList.length == 1 &&
      reviewerList.length == 1 &&
      checkedReviewer == checkedApprover
    ) {
      isApproverReviewerSame = true;
    }

    this.setState({
      approverList,
      reviewerList,
      checkedApprover,
      checkedReviewer,
      isApproverReviewerSame,
    });
  };

  manageDataToEdit = (dataToEdit) => {
    const { RequestId, RecommenderId, ApprovalId, EmployeeId } = dataToEdit;

    let timeAttendance = [];
    dataToEdit.TimeRequests.map((timeRequest, index) => {
      timeAttendance.push({
        id: index + 1,
        date: Date_Format(timeRequest.RequestDateEng),
        deviceInTime: timeRequest.InTimeDv,
        deviceOutTime: timeRequest.OutTimeDv,
        actualInTime: timeRequest.InTime
          ? convertTime12to24(timeRequest.InTime)
          : null,
        actualOutTime: timeRequest.OutTime
          ? convertTime12to24(timeRequest.OutTime)
          : null,
        inNote: timeRequest.InNote,
        outNote: timeRequest.OutNote,
      });
    });

    this.setState({
      timeAttendance,
      timeRequestId: RequestId,
      checkedApprover: ApprovalId,
      checkedReviewer: RecommenderId,
    });

    return EmployeeId;
  };

  handleChange = (name, value, id) => {
    if (value && name == "date") {
      value = getEngDate(value);
    }

    let { timeAttendance } = this.state;
    let selectedAttendance = timeAttendance.find((f) => f.id == id);
    selectedAttendance[name] = value;

    if (name == "date") {
      const { attendanceByDate } = this.props;

      const attendanceForDate = attendanceByDate.find(
        (f) => Date_Format(f.DateEng) == Date_Format(value)
      );

      if (attendanceForDate) {
        selectedAttendance.deviceInTime =
          attendanceForDate.InTimeDv || attendanceForDate.InTime;
        selectedAttendance.deviceOutTime =
          attendanceForDate.OutTimeDv || attendanceForDate.OutTime;
      } else {
        selectedAttendance.deviceInTime = null;
        selectedAttendance.deviceOutTime = null;
      }
    }
    this.setState({ timeAttendance });
  };

  handleFieldChange = async (name, value) => {
    const { minDate, maxDate } = this.state;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.updateErrors();
      }
    );
    if (name == "employeeToAssign") {
      let employeeId = value.value;
      this.manipulateApproverReviewer(employeeId);
      await this.props.GetAttendanceByDate(minDate, maxDate, employeeId);
    }
  };

  addNewAttendance = () => {
    let { timeAttendance } = this.state;

    if (timeAttendance.length <= 6) {
      const id = timeAttendance.length + 1;
      timeAttendance.push(timeAttendanceTemplate(id));
      this.setState({ timeAttendance });
    }
  };

  removeAttendance = (id) => {
    let { timeAttendance } = this.state;
    timeAttendance = timeAttendance.filter((f) => f.id != id);
    this.setState({ timeAttendance });
  };

  handleSaveTimeRequest = async () => {
    const { timeRequestApprovalRecommended } = this.props;
    let {
      timeRequestId,
      timeAttendance,
      checkedApprover,
      checkedReviewer,
      employeeToAssign,
    } = this.state;
    timeAttendance = timeAttendance.sort(function (a, b) {
      return new Date(a.date) - new Date(b.date);
    });

    let fieldsToValidate = { employeeToAssign };
    let errors = await InputValidator(document, fieldsToValidate);
    if (!isEmpty(errors)) {
      this.setState({ errors });
      return null;
    }

    if (
      timeRequestApprovalRecommended &&
      timeRequestApprovalRecommended.Recommended.length > 0 &&
      !checkedReviewer
    ) {
      this.props.DisplayClientSideMessage("Please select Reviewer.");
      return;
    }

    if (
      timeRequestApprovalRecommended &&
      timeRequestApprovalRecommended.Approvals.length > 0 &&
      !checkedApprover
    ) {
      this.props.DisplayClientSideMessage("Please select Approver.");
      return;
    }

    let isAllValid = true;
    let isDateRepeated = false;
    timeAttendance.map((ta) => {
      //#endregion validate required fields
      if (isAllValid && (!ta.date || (!ta.actualInTime && !ta.actualOutTime))) {
        isAllValid = false;
      }
      //#endregion

      //#region check date repeation
      let getByDate = timeAttendance.filter((f) => f.date == ta.date);
      if (getByDate.length > 1) {
        isDateRepeated = true;
      }
      //#endregion
    });

    if (!isAllValid) {
      this.props.DisplayClientSideMessage("Please fill all required fields.");
      return;
    }
    if (isDateRepeated) {
      this.props.DisplayClientSideMessage("Time request date is repeated.");
      return;
    }

    let finalTimeRequestLineData = [];
    timeAttendance.map((ta) => {
      finalTimeRequestLineData.push({
        dateEng: ta.date,
        deviceInTime: ta.deviceInTime,
        deviceOutTime: ta.deviceOutTime,
        actualInTime: ta.actualInTime,
        actualOutTime: ta.actualOutTime,
        inNote: ta.inNote,
        outNote: ta.outNote,
      });
    });
    const data = {
      isAssigned: this.props.isAssign || false,
      employeeId: this.props.isAssign ? employeeToAssign.value : null,
      timeRequestId: timeRequestId,
      approvalEmployeeId: checkedApprover,
      recommendEmployeeId: checkedReviewer,
      startDateEng: timeAttendance[0].date,
      endDateEng: timeAttendance[timeAttendance.length - 1].date,
      timeRequestLineDto: finalTimeRequestLineData,
    };

    if (timeRequestId > 0) {
      this.props.UpdateTimeRequest(data, this.props);
    } else {
      this.props.CreateTimeRequest(data, this.props);
    }
  };

  updateErrors = async () => {
    const { employeeToAssign } = this.state;
    if (!isEmpty(this.state.errors)) {
      let fieldsToValidate = { employeeToAssign };
      let errors = await InputValidator(document, fieldsToValidate);
      this.setState({ errors });
    }
  };

  render() {
    const {
      timeRequestApprovalRecommended,
      isReview,
      companyInformation,
      isAssign,
    } = this.props;
    const {
      errors,
      timeAttendance,
      reviewerList,
      approverList,
      checkedReviewer,
      checkedApprover,
      employeeToAssign,
      isApproverReviewerSame,
      maxDate,
      minDate,
    } = this.state;

    return (
      // <div className="common-container">
      //   <Title name="Time Request" parentProps={this.props} />

      <Modal
        title={isAssign ? "Assign Time" : "Time Request"}
        open={this.props.showModal}
        onModalClose={() => this.props.onModalClose(false)}
        type={""}
        className="time-modal"
      >
        <div className="time-request">
          {/* <div className="time-request-title">Time attendance Request</div> */}
          <div className="time-request-body">
            {this.props.isAssign && (
              <div className="mb-md">
                <SelectEmployee
                  employeeToAssign={employeeToAssign}
                  error={errors && errors.employeeToAssign}
                  onChange={this.handleFieldChange}
                />
              </div>
            )}
            <div className="time-request-list">
              <span className="heading date">{"Date  *"}</span>
              <span className="heading device-time">Device in</span>
              <span className="heading device-time">Device out</span>
              <span className="heading time">{"Actual in time  *"}</span>
              <span className="heading note">In Note</span>
              <span className="heading time">{"Actual out time  *"}</span>
              <span className="heading note">Out Note</span>
              <span className="delete-time-attendance"> </span>
            </div>

            {timeAttendance &&
              timeAttendance.map((attendance, index) => {
                let nepaliDate = attendance.date
                  ? getNepaliDate(new Date(attendance.date))
                  : null;
                return (
                  <div className="time-request-list" key={index}>
                    <span className="body date">
                      {/* <Form
                        formName="dateinput"
                        name="date"
                        date={attendance.date}
                        // minDate={minDate}
                        // maxDate={maxDate}
                        onChange={(name, value) =>
                          this.handleChange(name, value, attendance.id)
                        }
                        placeholder="Date.."
                      /> */}

                      <Form
                        formName="customdateinput"
                        name="date"
                        onChange={(name, value) =>
                          this.handleChange(name, value, attendance.id)
                        }
                        value={nepaliDate}
                        minDate={getNepaliDate(minDate)}
                        maxDate={getNepaliDate(maxDate)}
                        placeholder="Select date"
                        isNepali={!companyInformation.IsEnglishDate}
                        isTextInNepali={!companyInformation.IsEnglishDate}
                      />
                      {/* {!attendance.date && <label>*</label>} */}
                    </span>
                    <span className="body device-time">
                      {attendance.deviceInTime || "-"}
                    </span>
                    <span className="body device-time">
                      {attendance.deviceOutTime || "-"}
                    </span>
                    <span className="body time">
                      <Form
                        formName="timepicker"
                        name="actualInTime"
                        value={attendance.actualInTime}
                        onChange={(name, value) =>
                          this.handleChange(name, value, attendance.id)
                        }
                        defaultMeridiem={"am"}
                        placeholder="Time.."
                      />
                      {/* {!attendance.actualInTime && <label>*</label>} */}
                    </span>
                    <span className="body note">
                      <Form
                        formName="textinput"
                        name="inNote"
                        value={attendance.inNote}
                        onChange={(name, value) =>
                          this.handleChange(name, value, attendance.id)
                        }
                        placeholder="In note.."
                      />
                    </span>
                    <span className="body time">
                      <Form
                        formName="timepicker"
                        name="actualOutTime"
                        value={attendance.actualOutTime}
                        onChange={(name, value) =>
                          this.handleChange(name, value, attendance.id)
                        }
                        defaultMeridiem={"pm"}
                        placeholder="Time.."
                      />
                      {/* {!attendance.actualOutTime && <label>*</label>} */}
                    </span>
                    <span className="body note">
                      <Form
                        formName="textinput"
                        name="outNote"
                        value={attendance.outNote}
                        onChange={(name, value) =>
                          this.handleChange(name, value, attendance.id)
                        }
                        placeholder="Out note.."
                      />
                    </span>
                    {timeAttendance && timeAttendance.length > 1 && (
                      <span
                        className="delete-time-attendance"
                        onClick={() => this.removeAttendance(attendance.id)}
                      >
                        <Icons name="Delete" className="flex" />
                      </span>
                    )}
                  </div>
                );
              })}

            {timeAttendance.length <= 6 && (
              <div className="time-request-add">
                <button
                  className="button with-icon button-primary-light"
                  onClick={() => this.addNewAttendance()}
                >
                  <Icons name="Add" className="flex" />
                  <span>Add another</span>
                </button>
              </div>
            )}

            <div
              className={classNames({
                approver_reviewer: true,
                "flex ml-sm mr-sm items-start": true,
                "mt-sm": true,
              })}
            >
              {!isApproverReviewerSame && reviewerList.length > 0 && (
                <Form
                  formName="checkBoxGroup"
                  name="checkedReviewer"
                  label={"Reviewer"}
                  checkGroupArray={reviewerList}
                  checkedGroupValue={checkedReviewer}
                  onChange={this.handleFieldChange}
                  disabled={isReview}
                  multiple={false}
                />
              )}
              {approverList.length > 0 && (
                <Form
                  formName="checkBoxGroup"
                  name="checkedApprover"
                  label={"Approver"}
                  checkGroupArray={approverList}
                  checkedGroupValue={checkedApprover}
                  onChange={this.handleFieldChange}
                  disabled={isReview}
                  multiple={false}
                />
              )}
            </div>
            <div className="allowance-request-form__footer flex justify-end">
              <button
                className="button  button-white"
                onClick={() => this.props.onModalClose(false)}
              >
                <span> Cancel</span>
              </button>

              <button
                className="button  button-primary"
                onClick={this.handleSaveTimeRequest}
              >
                <span> Send Request</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  timeRequestApprovalRecommended:
    state.employeeTimeReducer.timeRequestApprovalRecommended,
  attendanceByDate: state.attendanceReducer.attendanceByDate,
  companyInformation: state.commonReducer.companyInformation,
  hrCommonSettings: state.auth.hrCommonSettings,
});

const mapDispatchToProps = {
  GetTimeApprovalRecommendedForEmployee,
  CreateTimeRequest,
  UpdateTimeRequest,
  GetAttendanceByDate,
  DisplayClientSideMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
