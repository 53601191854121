import React, { useEffect, useState } from "react";
import classnames from "classnames";

import Button from "components/button";
import isEmpty from "isEmpty";
import { useSelector, useDispatch } from "react-redux";

import { addTask } from "../../../helpers/action";

const AddTask = (props) => {
  const { board, stage } = props;
  const [task, setTask] = useState("");
  const [showControl, setShowControl] = useState(false);
  const dispatch = useDispatch();
  const onSave = () => {
    if (isEmpty(task)) {
      return false;
    }
    const data = {
      taskId: 0,
      boardRefId: board.BoardId,
      taskName: task,
      isRecurring: board.IsRecurring,
      stageId: stage,
    };
    debugger;
    dispatch(addTask(data, "board"));
    setTask("");
    setShowControl(false);
  };
  return (
    <div className="board-task-add">
      <div className="board-task-add__input">
        <input
          type="text"
          autoComplete="off"
          placeholder="Add Task"
          name="task"
          value={task}
          onKeyUp={(e) => e.key === "Enter" && onSave()}
          onFocus={(e) => setShowControl(true)}
          onChange={(e) => setTask(e.target.value)}
        />
      </div>
      {showControl && (
        <div className="board-task-add__buttons">
          <Button title="Save" bg="primary-dark" onClick={() => onSave()} />
          <Button
            title="Cancel"
            bg="secondary-dark"
            onClick={() => {
              setShowControl(false);
              setTask("");
            }}
          />
        </div>
      )}
    </div>
  );
};

export default AddTask;
