import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import thumbsDown from "assets/images/icons/thumbsDownColor.svg";
import thumbsUp from "assets/images/icons/thumbsUpColor.svg";
import comment from "assets/images/icons/commentBlue.svg";
import AnimatedForm from "components/common/animatedForm";
import man from "assets/images/man.png";
import Avatar from "react-avatar";
import Icons from "components/icons";
import FormGroup from "components/form";
import Button from "components/button";
import Modal from "components/modal";
import { connect } from "react-redux";
import Form from "components/form";
import SalaryReview from "./salaryReview";
import classnames from "classnames";
import RouteEnum from "constants/routeEnum";
import {
  GetBranches,
  GetDepartments,
  GetDesignations,
} from "actions/commonAction";
import {
  GetAllSalaryTemplate,
  GetSalaryTemplateDetails,
  GetDistributedSalary,
} from "../../../settings/salaryTemplate/salaryTemplateAction";
import { SaveOnboardingEmployee } from "../action";
import { UploadPofile } from "components/layout/profilePictureChange";
import "./style.scss";
// import salaryTemplate from 'scenes/settings/salaryTemplate';
import isEmpty from "isEmpty";
import { InputValidator } from "helpers/inputValidator";

const titleOptions = [
  { label: "Mr", value: "Mr" },
  { label: "Miss", value: "Miss" },
  { label: "Mrs", value: "Mrs" },
];
class OnboardingForm extends React.Component {
  state = {
    // employeeTitle: [{
    //   label: 'abc',
    //   value: 'ABC',
    // }, {
    //   label: 'abc',
    //   value: 'ABC',
    // }, {
    //   label: 'abc',
    //   value: 'ABC',
    // }],
    employeeTitle: null,
    salaryReview: false,
    firstName: null,
    middleName: null,
    lastName: null,
    mobile: null,
    salaryTemplate: null,
    destination: null,
    branch: null,
    department: null,
    personalEmail: null,
    officeEmail: null,
    hiringMethod: null,
    appointmentDate: null,
    hiringMethods: [
      {
        label: "Interview",
        value: "1",
      },
      {
        label: "Referal",
        value: "2",
      },
    ],
    includePackage: false,
    grossPay: 0,
    files: null,
  };

  async componentDidMount() {
    await this.props.GetBranches();
    await this.props.GetDepartments();
    await this.props.GetDesignations();
    await this.props.GetAllSalaryTemplate();
    const { salaryTemplateList } = this.props;
    if (salaryTemplateList) {
      // let salaryTemplates = salaryTemplateList.map(sal => {
      //   return {
      //     label: sal.TemplateName,
      //     value: sal.TemplateId,
      //   }
      // });
      this.setState({ salaryTemplates: salaryTemplateList });
    }
  }

  handleFieldChange = (name, value) => {
    console.log("sss");
    if (name === "salaryTemplate")
      this.setState({ [name]: value, grossPay: value.GrossPay }, () => {
        this.updateErrors();
      });
    else
      this.setState({ [name]: value }, () => {
        this.updateErrors();
      });
  };

  onCheckChange = (e) => {
    this.setState({ includePackage: e.target.checked });
  };

  onClose = () => {
    this.setState({ salaryReview: false });
  };

  updateErrors = async () => {
    const { errors } = this.state;
    if (!isEmpty(errors)) {
      let errors = await InputValidator(document, this.state);
      this.setState({ errors });
    }
  };

  handleFormSubmit = async () => {
    const {
      employeeTitle,
      firstName,
      branch,
      destination,
      department,
      mobile,
      lastName,
      middleName,
      hiringMethod,
      appointmentDate,
      hiringMethods,
      includePackage,
      salaryTemplate,
      personalEmail,
      officeEmail,
      grossPay,
      files,
    } = this.state;
    let errors = await InputValidator(document, this.state);

    if (!isEmpty(errors)) {
      this.setState({ errors });
      return;
    }
    let data = {
      employeeDto: {
        employeeId: 0,
        salutation: employeeTitle,
        firstName,
        middleName,
        lastName,
        branchId: branch.value,
        departmentId: department.value,
        designationId: destination.value,
        Name: firstName + " " + (middleName ? middleName + " " : "") + lastName,
      },
      employeePhotoDto: {
        employeeId: 0,
        photo: "string",
      },
      addressDto: {
        employeeId: 0,
        mobile,
        personalEmail,
        officeEmail,
      },
      employeeOnBoardingDto: {
        empOnBoardId: 0,
        employeeId: 0,
        stage: 1,
        hiringSourceId: 0,
        appointmentDate,
        salaryTemplateId: salaryTemplate.value,
        payAmount: grossPay,
        // joinDate: "2020-10-07T05:24:37.970Z",
        // "officeInTime": {},
        // "place": "string",
        // "contactToId": 0,
        // "managerId": 0,
        // "buddyId": 0,
        // "instruction": "string",
        // "welcomeMessage": "string"
      },
      // files,
      // attachment: [
      //   files
      // ]
    };

    await this.props.SaveOnboardingEmployee(data);
    const { saveOnboardingEmployee, GetEmployeeOnboardingList } = this.props;

    if (!includePackage && saveOnboardingEmployee) {
      delete data.files;
      delete data.attachment;
      const {
        saveOnboardingEmployee: {
          EmployeeDto: { EmployeeId },
        },
      } = this.props;
      data.employeeId = EmployeeId;

      this.props.history.push({
        pathname: `/admin/employee_onboarding/packages/${EmployeeId}`,
        state: {
          subData: data,
        },
      });
    } else {
      this.props.history.push({
        pathname: RouteEnum.EmployeeOnboarding,
      });
    }
  };

  getSalaryDistribution = async () => {
    const { grossPay } = this.state;
    const {
      salaryTemplateDetail: { SalaryTemplateDetailDto, SalaryTemplateDto },
    } = this.props;
    let data = {
      payAmount: grossPay,
      isGrossPay: SalaryTemplateDto.IsOnGrossDistribution,
    };
    if (SalaryTemplateDetailDto) {
      data.salaryArray = SalaryTemplateDetailDto;
    }
    await this.props.GetDistributedSalary(data);
  };

  uploadProfileImageToParent = (data) => {
    this.setState({ files: [data] });
  };

  render() {
    const {
      employeeTitle,
      firstName,
      salaryTemplate,
      salaryTemplates,
      lastName,
      middleName,
      hiringMethod,
      appointmentDate,
      errors,
      hiringMethods,
      includePackage,
      branch,
      department,
      destination,
      salaryReview,
      mobile,
      personalEmail,
      officeEmail,
      grossPay,
      files,
    } = this.state;

    const {
      departmentList,
      branchList,
      designationList,
      salaryTemplateList,
      GetSalaryTemplateDetails,
      salaryTemplateDetail,
    } = this.props;

    return (
      <div className="employee-onboarding">
        {/* <div className="employee-onboarding-container"> */}
        <div className="employee-onboarding-header borderbtm commonPd">
          Employee Onboarding
        </div>
        <div className="employee-onboarding-introduction onboardTitle borderbtm commonPd">
          INTRODUCTION
        </div>
        <div className="employee-onboarding-info borderbtm">
          <div className="employee-onboarding-info employee-onboarding-info-title commonPd">
            <FormGroup
              formName="chipsinput"
              name={"employeeTitle"}
              alignment="horizontal"
              chipsArray={titleOptions}
              value={employeeTitle}
              onChange={(name, value) =>
                this.handleFieldChange(name, value.value)
              }
              // label="Children"
              badgeColor="green"
              validators={["required"]}
              error={errors && errors.employeeTitle}
            />
          </div>
          <div className="employee-onboarding-info employee-onboarding-info-name flex commonPd">
            <FormGroup
              autoFocus
              name="firstName"
              value={firstName}
              onChange={this.handleFieldChange}
              label="First Name"
              error={errors?.firstName}
              width="205px"
              validators={["required"]}
              // disabled={isViewMode}
            />
            <FormGroup
              autoFocus
              name="middleName"
              value={middleName}
              onChange={this.handleFieldChange}
              label="Middle Name"
              // error={errors?.middleName}
              width="205px"
              // validators={["required"]}
              // disabled={isViewMode}
            />
            <FormGroup
              autoFocus
              name="lastName"
              value={lastName}
              onChange={this.handleFieldChange}
              label="Last Name"
              error={errors?.lastName}
              width="205px"
              validators={["required"]}
              // disabled={isViewMode}
            />
          </div>
          {/* <div className="employee-onboarding-info employee-onboarding-photo commonPd">
                  <UploadPofile
                    previewImage={files ?  files[0]: null}
                    isNotModal
                    EmployeeId={1}
                    uploadProfileImageToParent={(data) => this.uploadProfileImageToParent(data)}
                    uploadOnly={true}
                  />
                </div> */}
          <div className="employee-onboarding-info onboardTitle commonPd">
            JOB INFORMATION
          </div>
        </div>
        <div className="employee-onboarding-job borderbtm">
          <div className="employee-onboarding-job-hiring flex commonPd">
            <FormGroup
              formName="reactselect"
              onChange={(name, value) => this.handleFieldChange(name, value)}
              // onChange={() => console.log("clos")}
              name="hiringMethod"
              value={hiringMethod}
              options={hiringMethods}
              // loading={jobProfileList.loading}
              loadingType="skeleton"
              placeholder="Select"
              // disabled={isViewMode}
              validators={["required"]}
              error={errors?.hiringMethod}
              width="425px"
              label="How the employee was hired*"
            />
            <Form
              formName="customdateinput"
              name="appointmentDate"
              onChange={(name, value) => this.handleFieldChange(name, value)}
              label="Appointment date"
              value={appointmentDate}
              placeholder="Date.."
              width="150px"
              error={errors?.appointmentDate}
              validators={["required"]}
              // isNepali={!companyInformation?.IsEnglishDate}
              // isTextInNepali={!companyInformation?.IsEnglishDate}
            />
          </div>
          <div className="employee-onboarding-job-placement onboardTitle commonPd">
            PLACEMENT
          </div>
        </div>
        <div className="employee-onboarding-placement borderbtm">
          <div className="employee-onboarding-placement-branch flex commonPd">
            <FormGroup
              formName="reactselect"
              onChange={(name, value) => this.handleFieldChange(name, value)}
              // onChange={() => console.log("clos")}
              name="branch"
              value={branch}
              options={branchList}
              // loading={jobProfileList.loading}
              loadingType="skeleton"
              placeholder="Select"
              // disabled={isViewMode}
              validators={["required"]}
              error={errors?.branch}
              width="205px"
              label="Branch"
            />
            <FormGroup
              formName="reactselect"
              onChange={(name, value) => this.handleFieldChange(name, value)}
              // onChange={() => console.log("clos")}
              name="department"
              value={department}
              options={departmentList}
              // loading={jobProfileList.loading}
              loadingType="skeleton"
              placeholder="Select"
              // disabled={isViewMode}
              validators={["required"]}
              error={errors?.department}
              width="205px"
              label="Department"
            />
          </div>
          <div className="employee-onboarding-placement-position commonPd">
            <FormGroup
              formName="reactselect"
              onChange={(name, value) => this.handleFieldChange(name, value)}
              // onChange={() => console.log("clos")}
              name="destination"
              value={destination}
              options={designationList}
              // loading={jobProfileList.loading}
              loadingType="skeleton"
              placeholder="Select"
              // disabled={isViewMode}
              validators={["required"]}
              error={errors?.destination}
              width="425px"
              label="Job Position"
            />
          </div>

          <div className="employee-onboarding-info onboardTitle commonPd">
            SALARY AND COMPENSATION
          </div>
        </div>
        <div className="employee-onboarding-compensation borderbtm">
          <div className="employee-onboarding-compensation flex commonPd">
            <FormGroup
              formName="reactselect"
              onChange={(name, value) => this.handleFieldChange(name, value)}
              // onChange={() => console.log("clos")}
              name="salaryTemplate"
              value={salaryTemplate}
              options={salaryTemplates}
              // loading={jobProfileList.loading}
              loadingType="skeleton"
              placeholder="Select"
              // disabled={isViewMode}
              validators={["required"]}
              error={errors?.salaryTemplate}
              width="425px"
              label="Salary template"
            />
            <div className="employee-onboarding-pay">
              <span>Gross Pay</span>
              <span>Rs. {grossPay}</span>
              <span>
                <Button
                  title="Review"
                  bg="primary"
                  // isDisabled
                  onClick={() => this.setState({ salaryReview: true })}
                />
              </span>
            </div>
          </div>
          <div className="employee-onboarding-info onboardTitle commonPd">
            CONTACT
          </div>
          <Modal
            open={salaryReview}
            onModalClose={this.onClose}
            title="Salary Review"
            className="salary-review__modal"
          >
            <SalaryReview
              onClose={this.onClose}
              grossPay={grossPay}
              templateId={salaryTemplate && salaryTemplate.value}
              GetSalaryTemplateDetails={GetSalaryTemplateDetails}
              salaryTemplateDetail={salaryTemplateDetail}
              changeGrossPay={(grossPay) => this.setState({ grossPay })}
              getSalaryDistribution={this.getSalaryDistribution}
            />
          </Modal>
        </div>
        <div className="employee-onboarding-info borderbtm">
          <div className="employee-onboarding-info employee-onboarding-info-name flex commonPd">
            <FormGroup
              autoFocus
              name="mobile"
              value={mobile}
              onChange={this.handleFieldChange}
              label="Mobile Number"
              error={errors?.mobile}
              width="205px"
              validators={["required", "phone"]}
              // disabled={isViewMode}
            />
            <FormGroup
              autoFocus
              name="personalEmail"
              value={personalEmail}
              onChange={this.handleFieldChange}
              label="Personal email"
              error={errors?.personalEmail}
              width="205px"
              validators={["required", "email"]}
              // disabled={isViewMode}
            />
            <FormGroup
              autoFocus
              name="officeEmail"
              value={officeEmail}
              onChange={this.handleFieldChange}
              label="Office email"
              error={errors?.officeEmail}
              width="205px"
              validators={["required", "email"]}
              // disabled={isViewMode}
            />
          </div>
          <div className="employee-onboarding-compensation flex commonPd">
            <FormGroup
              formName="checkgroup"
              name="includePackage"
              label="Do not Create Onboarding Package"
              value={includePackage}
              checked={includePackage}
              labelPosition="right"
              onChange={this.onCheckChange}
            />
          </div>
        </div>
        <div className="employee-onboarding__footer flex justify-end commonPd">
          <Link to={{ pathname: RouteEnum.EmployeeOnboarding }}>
            <Button
              bg="highlight"
              title="Cancel"
              // onClick={() => this.props.handleFormDisplay(false)}
            />
            <Button
              bg="primary"
              title="Save"
              onClick={() => this.handleFormSubmit()}
            />
          </Link>
        </div>
      </div>
    );
  }
}

OnboardingForm = connect(
  (state) => ({
    departmentList: state.commonReducer.departmentList,
    branchList: state.commonReducer.branchList,
    designationList: state.commonReducer.designationList,
    salaryTemplateList: state.salaryTemplateReducer.salaryTemplateList,
    salaryTemplateDetail: state.salaryTemplateReducer.salaryTemplateDetail,
    saveOnboardingEmployee: state.employeeOnboarding.saveOnboardingEmployee,
  }),
  {
    GetBranches,
    GetDesignations,
    GetDepartments,
    GetAllSalaryTemplate,
    SaveOnboardingEmployee,
    GetSalaryTemplateDetails,
    GetDistributedSalary,
  }
)(OnboardingForm);

export default OnboardingForm;
