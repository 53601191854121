import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Form from "components/form";
import FormGroup from "components/form";
import Modal from "components/modal";
import {
  GetRejectReasons,
  SaveCandidateReject,
} from "store/actions/recruitment/reject";
import Button from "components/button";
import ComposeEmail from "scenes/common/email/compose";

import { FiPlus, FiX } from "react-icons/fi";
import { ConvertToDataSource } from "helpers/getTextLabel";

function RejectCandidate(props) {
  const { src, data, rejectReasons, saveCandidateRejectLoading } = props;

  const [selectedReasons, setSelectedReasons] = useState([]);
  const [showAddExplanation, setAddExplanation] = useState(true);
  const [showComposeModal, setComposeModal] = useState(false);
  const [state, setState] = useState({
    template: null,
    explanation: "",
    reasons: [],
  });

  useEffect(() => {
    props.GetRejectReasons();
  }, []);

  const onChange = (name, value) => {
    let nextState = { ...state };
    nextState[name] = value;
    setState(nextState);

    if (value.includes(5)) {
      setAddExplanation(false);
    }
  };

  const onReject = async () => {
    let joibId = data.jobId;
    const candidateIds = data.candidates.map((x) => x.CandidateId);
    const param = {
      id: 0,
      candidates: candidateIds,
      jobId: joibId,
      explanation: state.explanation,
      reasons: state.reasons,
    };
    await props.SaveCandidateReject(param, onSuccess);
    props.handleModal(false);
  };

  const handleComposeModal = (value) => {
    setComposeModal(value);
    props.handleModal(false);
  };
  const onSuccess = () => {
    //zprops.handleModal(false);
    var reasons = [];
    state.reasons.forEach((el) => {
      var obj = rejectReasons.find((y) => y.Id === el);
      if (obj) {
        if (obj.Id === 5) reasons.push(state.explanation);
        else reasons.push(obj.Reason);
      }
    });
    setSelectedReasons(reasons);
    setComposeModal(true);
  };

  return (
    <div>
      <Modal
        title={props.title ? props.title : `Reject Candidate`}
        open={props.open}
        onModalClose={() => props.handleModal(false)}
        className="modal-reject-candiate"
        width="55%"
      >
        <div className="reject-candidate">
          <div className="item">
            <label className="item-label">Please select reason</label>
            <div className="item-content">
              <div className="item-content-box">
                <Form
                  formName="checkBoxList"
                  name="reasons"
                  items={
                    (rejectReasons &&
                      ConvertToDataSource(rejectReasons, "Reason", "Id")) ||
                    []
                  }
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
          {showAddExplanation ? (
            <div className="item">
              <label
                className="item-label action"
                onClick={() => setAddExplanation(false)}
              >
                <span className="icon">
                  <FiPlus />
                </span>
                Add Explanation
              </label>
            </div>
          ) : (
            <div className="item">
              <label className="item-label">Explanation</label>
              <label
                className="item-label action left-margin"
                onClick={() => setAddExplanation(true)}
              >
                Remove
              </label>
              <div className="item-content">
                <Form
                  formName="textarea"
                  onChange={onChange}
                  name="explanation"
                  value={state.explanation}
                  placeholder="This is the explanation"
                />
              </div>
            </div>
          )}
          <div className="email-section">
            <span />
            <div>
              {/* <button
              className={"btn " + btnSubmitStyle}
              onClick={!isLoading && onSubmit}
            >
              {!isLoading ? "Submit Job Application" : "Please wait.."}
            </button> */}
              <Button
                bg="primary"
                title={
                  !saveCandidateRejectLoading ? "Reject" : "Please wait .."
                }
                onClick={!saveCandidateRejectLoading && onReject}
              />
            </div>
          </div>
        </div>
      </Modal>

      <ComposeEmail
        src="recruitment"
        reasons={selectedReasons}
        open={showComposeModal}
        handleModal={(value) => handleComposeModal(value)}
        data={data.candidates || {}}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  rejectReasons: state.recruitment_rejectReducer.rejectReasons,
  saveCandidateRejectLoading:
    state.recruitment_rejectReducer.saveCandidateRejectLoading,
});

const mapDispatchToProps = {
  GetRejectReasons,
  SaveCandidateReject,
};

export default connect(mapStateToProps, mapDispatchToProps)(RejectCandidate);
