import axios from "axios";
import { toastMessage } from "actions/validateAction";
import types from "./types";
import { CONSTANTS } from "../scorecard/trello/types";
import { RecruitDashboardPath } from "routes/commonLinks";
import isEmpty from "isEmpty";

const requirementBaseApi = "/v1/recruitment/";

export const getJobNumber = () => (dispatch) => {
  dispatch({ type: types.JOB_NUMBER_LOADING });
  axios
      .get(`${requirementBaseApi}jobs/hr-number`)
    .then((res) => {
      dispatch({
        type: types.GET_JOB_NUMBER,
        payload: res.data.Data,
      });

      //toastMessage(dispatch, res);
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_JOB_NUMBER,
        payload: null,
      });
    });
};

export const getJobScorecard = () => (dispatch) => {
  dispatch({ type: types.SCORECARD_LOADING });
  axios
    .get(`${requirementBaseApi}jobs/score-cards`)
    .then((res) => {
      dispatch({
        type: types.GET_JOB_SCORECARD,
        payload: res.data.Data,
      });
      const a = res.data.Data.map((item) => {
        item.cards = [];
        return item;
      });
      dispatch({
        type: CONSTANTS.GET_LISTS,
        payload: a,
      });
      //toastMessage(dispatch, res);
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_JOB_SCORECARD,
        payload: null,
      });
    });
};

export const getJobHiringStages = () => (dispatch) => {
  dispatch({ type: types.HIRINGSTAGES_LOADING });
  axios
    .get(`${requirementBaseApi}jobs/hiring-stages`)
    .then((res) => {
      dispatch({
        type: types.GET_JOB_HIRING_STAGES,
        payload: res.data.Data,
      });
      //toastMessage(dispatch, res);
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_JOB_HIRING_STAGES,
        payload: null,
      });
    });
};

export const getJobApplicationForm = () => (dispatch) => {
  dispatch({ type: types.APPLICATIONFORM_LOADING });
  axios
    .get(`${requirementBaseApi}jobs/application-form-titles`)
    .then((res) => {
      dispatch({
        type: types.GET_JOB_APPLICATION_FORM,
        payload: res.data.Data,
      });
      //toastMessage(dispatch, res);
    })
    .catch((err) => {
      dispatch({
        type: types.GET_JOB_APPLICATION_FORM,
        payload: null,
      });
    });
};

export const getJobCategory = () => (dispatch) => {
  dispatch({ type: types.JOB_CATEGORY_LOADING });
  axios
    .get(`${requirementBaseApi}jobs/categories`)
    .then((res) => {
      dispatch({
        type: types.GET_JOB_CATEGORY,
        payload: res.data.Data,
      });
      //toastMessage(dispatch, res);
    })
    .catch((err) => {
      dispatch({
        type: types.GET_JOB_CATEGORY,
        payload: null,
      });
    });
};

export const getJobRequisitionById = (jobid) => (dispatch) => {
  dispatch({ type: types.CREATE_AND_UPDATE_JOB_LOADING });
  axios
    .get(`${requirementBaseApi}requisition/${jobid}`)
    .then((res) => {
      if (res.data.Status) {
        const response = res.data.Data;
        const data = {
          RecruitmentPositionDetailDto: {
            JobId: 0,
            InternalId: "",
            InternalJobTitle: response.InternalJobTitle,
            ExternalJobTitle: response.ExternalJobTitle,
            LevelId: 0,
            DivisionId: response.DivisionId,
            BranchId: response.BranchId,
            IsAnywhere: true,
            EmployementTypeId: response.EmploymentTypeId,
            CategoryId: 0,
            NumberOfOpenings: response.NumberOfOpenings,
            StartDate: "",
            EndDate: "",
            StartDateEng: response.StartDateEng,
            EndDateEng: response.EndDateEng,
            IsPayShow: true,
            SalaryRangeFrom: "",
            SalaryRangeTo: "",
            SalaryDurationType: 0,
            QualificationLevelId: response.QualificationLevelId,
            IsExperience: response.IsExperience,
            Experience: response.Experience,
            ExperienceDurationType: response.ExperienceDurationType,
            JobDescription: response.JobDescription,
            Tags: "",
            SaveDraftStage: 0,
            IsClosed: false,
            IsPublished: false,
            PublishedBy: 0,
            WizardStage: 1,
          },
          RecruitmentJobApplicationFormDto: [],
          RecruitmentJobHiringStageDto: [],
          RecruitmentJobHiringSubStageDto: [],
          RecruitmentJobScorecardDto: [],
          RecruitmentJobScorecardDetailDto: [],
          RecruitmentJobHiringTeamDto: [],
        };
        dispatch({
          type: types.CREATE_AND_UPDATE_JOB_REQ,
          payload: data,
        });
      } else {
        toastMessage(dispatch, res.data);
      }
    })
    .catch((err) => {
      console.log(err);
      // dispatch({
      //     type: types.CREATE_AND_UPDATE_JOB,
      //     payload: null
      // });
    });
};

export const getJobById = (jobid, isJobId) => (dispatch) => {
  dispatch({ type: types.CREATE_AND_UPDATE_JOB_LOADING });
  axios
    .get(`${requirementBaseApi}jobs/${jobid}`)
    .then((res) => {
      if (res.data.Status) {
        const response = res.data.Data;
        const data = {
          RecruitmentPositionDetailDto: {
            ...response.RecruitmentPositionDetail,
            JobId: isJobId ? jobid : 0,
            InternalId: isJobId
              ? response.RecruitmentPositionDetail.InternalId
              : "",
            IsPublished: isJobId
              ? response.RecruitmentPositionDetail.IsPublished
              : false,
          },
          RecruitmentJobApplicationFormDto:
            response.RecruitmentJobApplicationForm,
          RecruitmentJobHiringStageDto: response.RecruitmentJobHiringStage,
          RecruitmentJobHiringSubStageDto:
            response.RecruitmentJobHiringSubStage,
          RecruitmentJobScorecardDto: response.RecruitmentJobScorecard,
          RecruitmentJobScorecardDetailDto:
            response.RecruitmentJobScorecardDetail,
          RecruitmentJobHiringTeamDto: response.RecruitmentJobHiringTeam,
          RecruitmentJobMarkingSchemeDto: response.RecruitmentJobMarkingScheme,
        };
        dispatch({
          type: types.CREATE_AND_UPDATE_JOB,
          payload: data,
        });

        if (!isEmpty(response.RecruitmentJobScorecard)) {
          let a = {
            lists: response.RecruitmentJobScorecard,
            cards: response.RecruitmentJobScorecardDetail,
          };
          dispatch({
            type: types.UPDATE_TRELLO_CARDS,
            payload: a,
          });
        }
      } else {
        toastMessage(dispatch, res.data);
      }
    })
    .catch((err) => {
      console.log(err);
      // dispatch({
      //     type: types.CREATE_AND_UPDATE_JOB,
      //     payload: null
      // });
    });
};

export const addJob = (data, history, isDraft, success) => (dispatch) => {
  dispatch({ type: types.CREATE_AND_UPDATE_JOB_LOADING });

  axios
    .post(`${requirementBaseApi}jobs`, data)
    .then((res) => {
      data = {
        RecruitmentPositionDetailDto: res.data.Data.RecruitmentPositionDetail,
        RecruitmentJobApplicationFormDto:
          res.data.Data.RecruitmentJobApplicationForm,
        RecruitmentJobHiringStageDto: res.data.Data.RecruitmentJobHiringStage,
        RecruitmentJobHiringSubStageDto:
          res.data.Data.RecruitmentJobHiringSubStage,
        RecruitmentJobScorecardDto: res.data.Data.RecruitmentJobScorecard,
        RecruitmentJobScorecardDetailDto:
          res.data.Data.RecruitmentJobScorecardDetail,
        RecruitmentJobHiringTeamDto: res.data.Data.RecruitmentJobHiringTeam,
        RecruitmentJobMarkingSchemeDto:
          res.data.Data.RecruitmentJobMarkingScheme,
      };
      dispatch({
        type: types.CREATE_AND_UPDATE_JOB,
        payload: data,
      });

      success && success();
      if (!isEmpty(res.data.Data.RecruitmentJobScorecard)) {
        let a = {
          lists: res.data.Data.RecruitmentJobScorecard,
          cards: res.data.Data.RecruitmentJobScorecardDetail,
        };
        dispatch({
          type: types.UPDATE_TRELLO_CARDS,
          payload: a,
        });
      }
      if (isDraft) {
        history.push(RecruitDashboardPath);
      }
      toastMessage(dispatch, res);
      if (data.RecruitmentPositionDetailDto.IsPublished)
        history.push(RecruitDashboardPath);
    })
    .catch((err) => {
      console.log(err);
      // dispatch({
      //     type: types.CREATE_AND_UPDATE_JOB,
      //     payload: null
      // });
    });
};
export const updateJob =
  (data, history, isPublished, success) => (dispatch) => {
    dispatch({ type: types.CREATE_AND_UPDATE_JOB_LOADING });
    axios
      .put(`${requirementBaseApi}jobs`, data)
      .then((res) => {
        data = {
          RecruitmentPositionDetailDto: res.data.Data.RecruitmentPositionDetail,
          RecruitmentJobApplicationFormDto:
            res.data.Data.RecruitmentJobApplicationForm,
          RecruitmentJobHiringStageDto: res.data.Data.RecruitmentJobHiringStage,
          RecruitmentJobHiringSubStageDto:
            res.data.Data.RecruitmentJobHiringSubStage,
          RecruitmentJobScorecardDto: res.data.Data.RecruitmentJobScorecard,
          RecruitmentJobScorecardDetailDto:
            res.data.Data.RecruitmentJobScorecardDetail,
          RecruitmentJobHiringTeamDto: res.data.Data.RecruitmentJobHiringTeam,
          RecruitmentJobMarkingSchemeDto:
            res.data.Data.RecruitmentJobMarkingScheme,
        };

        dispatch({
          type: types.CREATE_AND_UPDATE_JOB,
          payload: data,
        });
        success && success();
        if (!isEmpty(res.data.Data.RecruitmentJobScorecard)) {
          let a = {
            lists: res.data.Data.RecruitmentJobScorecard,
            cards: res.data.Data.RecruitmentJobScorecardDetail,
          };
          dispatch({
            type: types.UPDATE_TRELLO_CARDS,
            payload: a,
          });
        }
        // if (isDraft) {
        //   history.push(RecruitDashboardPath);
        // }
        toastMessage(dispatch, res);
        if (isPublished) history.push(RecruitDashboardPath);
      })
      .catch((err) => {
        console.log(err);
        // dispatch({
        //     type: types.CREATE_AND_UPDATE_JOB,
        //     payload: null
        // });
      });
  };

export const getJobStepsSetting = () => async (dispatch) => {
  let res = await axios.get(`${requirementBaseApi}job-steps-setting`);
  const data = {
    lastStep: res.data.Data.LastStep,
    hideSteps: res.data.Data.HideSteps,
  };

  dispatch({
    type: types.GET_JOB_STEPS_SETTING,
    payload: data,
  });
};

export const updateJobCloseStatus = (data, history) => async (dispatch) => {
  data = {
    ...data,
    RecruitmentPositionDetailDto: {
      ...data.RecruitmentPositionDetailDto,
      IsClosed: !data.RecruitmentPositionDetailDto.IsClosed,
      IsPublished: false,
    },
  };
  updateJob(data, history, false);
};

export const unpublishJobCloseStatus = (data, history) => async (dispatch) => {
  data = {
    ...data,
    RecruitmentPositionDetailDto: {
      ...data.RecruitmentPositionDetailDto,
      IsPublished: false,
    },
  };
  updateJob(data, history, false);
};
