import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Grid,
  Inject,
} from "@syncfusion/ej2-react-grids";
import Pagination from "components/common/pagination";
import Icons from "components/icons";
import * as React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import RouteEnum from 'constants/routeEnum'
import FormGroup from "components/form";
import isEmpty from "isEmpty";
import { connect } from "react-redux";
import { SetSelectedRecords } from "actions/commonAction";
import { getSelectedRecords } from "../getProfileData";

export interface IAppProps {
  data: any;
  handleRowChange: Function;
  handleIndexChange: Function;
  pageIndex: BigInteger;
  pageSize: BigInteger;
  history: any;
  handleConfirmDelRecord: Function;
  handleMultiDeleteOption: Function;
  selectedRecords: any;
  SetSelectedRecords: Function;
}

class App extends React.Component<IAppProps> {
  private grid: Grid | null;
  constructor(props: IAppProps) {
    super(props);
    this.gridTemplate = this.gridTemplate.bind(this);
  }

  gridTemplate(props) {
    return (
      <div className="flex library">
        <button
          className="button with-icon button-white"
          onClick={(e) => this.handleEditRecord(props.JobDescriptionId)}
        >
          <Icons name="Edit" className="flex" />
          <span>Edit</span>
        </button>

        <button
          className="button with-icon button-white"
          onClick={(e) => this.handleDelRecord(props.JobDescriptionId)}
        >
          <Icons name="Delete" className="flex" />
          <span>Delete</span>
        </button>
      </div>
    );
  }

  handleCheckboxChange = (event) => {
    let selectedRecords = getSelectedRecords(event, this.props);

    this.props.SetSelectedRecords(selectedRecords);
    //this.grid.refresh();
    this.props.handleMultiDeleteOption(
      !isEmpty(selectedRecords) ? true : false
    );
  };
  handleEditRecord = (descriptionId) => {
    this.props.history.push(RouteEnum.UpdateJobDescriptionPath + descriptionId);
    //window.open(UpdateJobProfilePath + "/" + profileId);
  };

  handleDelRecord = (jobDescriptionId) => {
    this.props.handleConfirmDelRecord(jobDescriptionId);
  };
  public render() {
    const {
      data,
      handleRowChange,
      handleIndexChange,
      pageIndex,
      pageSize,
    } = this.props;
    return (
      <div className="table">
        <div className="arrear-table">
          {/* <Loader open={travelSettlement.loading} /> */}

          <div className="job-profile-grid">
            <GridComponent
              dataSource={data}
              className="custom-grid"
              filterSettings={{ type: "Menu" }}
              allowFiltering={true}
              //  height={!isEmpty(searchedLeaveAbsence)?"500":'80'}
              id="grid"
              allowExcelExport={true}
              allowSorting={true}
              ref={(g) => (this.grid = g)}
              rowSelecting={this.handleCheckboxChange}
              rowDeselecting={this.handleCheckboxChange}
            >
              <ColumnsDirective>
                <ColumnDirective type="checkbox" width="25" />
                <ColumnDirective
                  field="JobCode"
                  headerText="Job Code"
                  width={90}
                />
                <ColumnDirective
                  field="JobTitle"
                  headerText="Job Title"
                  width={130}
                />

                <ColumnDirective
                  field="LevelName"
                  headerText="Job Family"
                  width={100}
                />
                <ColumnDirective
                  field="LevelGroupName"
                  headerText="Job Level/Role"
                  width={100}
                />
                <ColumnDirective
                  field="AssignedTo"
                  headerText="Assigned to"
                  width={95}
                />
                <ColumnDirective
                  field="LastUpdatedDate"
                  headerText="Last Updated"
                  width={100}
                />
                <ColumnDirective
                  field=""
                  width="130"
                  template={this.gridTemplate.bind(this)}
                />
              </ColumnsDirective>
              <Inject services={[Filter]} />
            </GridComponent>

            {data && data.length > 0 && (
              <Pagination
                handleRowChange={handleRowChange}
                handleIndexChange={handleIndexChange}
                pageIndex={pageIndex}
                rowsPerPage={pageSize}
                total={data[0].TotalRows}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedRecords: state.commonReducer.selectedRecords,
});

const mapDispatchToProps = {
  SetSelectedRecords,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
