import {
  SUMMARY_OF_POSITION_ID,
  SUMMARY_OF_POSITION_TYPE,
  PRIMARY_DUITIES_RESPONSIBILTY_ID,
  PRIMARY_DUITIES_RESPONSIBILTY_TYPE,
  EDUCATION_QUALIFICATION_ID,
  EDUCATION_QUALIFICATION_TYPE,
  KNOWLEDGE_SKILL_ABILITY_ID,
  KNOWLEDGE_SKILL_ABILITY_TYPE,
  SCOPE_IMPACT_ID,
  SCOPE_IMPACT_TYPE,
  CUSTOM_PROFILE_BLOCK_TYPE,
  COMPONENT_TYPE,
  JOB_PROFILE_PARAGRAPH_TYPE,
  CUSTOM_PROFILE_BLOCK_ID,
} from "constants/constants";
import isEmpty from "isEmpty";
import CustomJobProfileBlock from "./form/jobProfile/customProfileBlock";
import JobProfileEducationQualification from "./form/jobProfile/educationAndQualification";
import JobProfileKnowledgeSkillAbilities from "./form/jobProfile/knowledgeSkillAbilities";
import JobProfilePrimaryDutiesResponsibilites from "./form/jobProfile/pDutiesAndResp";
import JobProfileScopeImpact from "./form/jobProfile/scopeImpact";
import CustomJobDescriptionBlock from "./form/jobDescription/customProfileBlock";
import JobDescriptionEducationQualification from "./form/jobDescription/educationAndQualification";
import JobDescriptionKnowledgeSkillAbilities from "./form/jobDescription/knowledgeSkillAbilities";
import JobDescriptionPrimaryDutiesResponsibilites from "./form/jobDescription/pDutiesAndResp";
import JobDescriptionScopeImpact from "./form/jobDescription/scopeImpact";

export const getProfileBlockComponent = (opt, type) => {
  var component: any = "";

  switch (opt) {
    case PRIMARY_DUITIES_RESPONSIBILTY_TYPE:
      component =
        type === "jobProfile"
          ? JobProfilePrimaryDutiesResponsibilites
          : JobDescriptionPrimaryDutiesResponsibilites;
      break;
    case EDUCATION_QUALIFICATION_TYPE:
      component =
        type === "jobProfile"
          ? JobProfileEducationQualification
          : JobDescriptionEducationQualification;
      break;

    case KNOWLEDGE_SKILL_ABILITY_TYPE:
      component =
        type === "jobProfile"
          ? JobProfileKnowledgeSkillAbilities
          : JobDescriptionKnowledgeSkillAbilities;
      break;
    case SCOPE_IMPACT_TYPE:
      component =
        type === "jobProfile"
          ? JobProfileScopeImpact
          : JobDescriptionScopeImpact;
      break;
    case CUSTOM_PROFILE_BLOCK_TYPE:
      component =
        type === "jobProfile"
          ? CustomJobProfileBlock
          : CustomJobDescriptionBlock;
      break;
  }

  return component;
};

export const getProfileInitialBlocks = (initialData, type) => {
  let sortedSections = [...initialData?.Sections]?.sort(function (a, b) {
    return a.DisplayOrder - b.DisplayOrder;
  });
  let customProfileBlockId = CUSTOM_PROFILE_BLOCK_ID;
  let profileBlocks = initialData?.Sections?.map((x) => {
    let returnData = {
      id:
        x.SectionTypeName === CUSTOM_PROFILE_BLOCK_TYPE
          ? customProfileBlockId
          : x.SectionTypeRefId,
      type:
        x.SectionTypeName === CUSTOM_PROFILE_BLOCK_TYPE
          ? x.SectionTypeName + customProfileBlockId
          : x.SectionTypeName,
      title: x.SectionName,
      components:
        x.SectionTypeName === SUMMARY_OF_POSITION_TYPE ||
        x.SectionTypeName === SCOPE_IMPACT_TYPE ||
        x.SectionTypeName === CUSTOM_PROFILE_BLOCK_TYPE
          ? [
              {
                id: COMPONENT_TYPE[JOB_PROFILE_PARAGRAPH_TYPE],
                subtitle: x.SectionName,
                paragraph: x.SectionSummary,
              },
            ]
          : x.Components.map((comp) => {
              return {
                id: comp.ComponentTypeRefId,
                type: comp.ComponentTypeName,
                [comp.ComponentTypeName.toLowerCase()]:
                  x.SectionTypeName === EDUCATION_QUALIFICATION_TYPE
                    ? comp.ComponentTypeValueList.map((c) => {
                        return {
                          id: c.Key,
                          name: c.Value,
                        };
                      })
                    : comp.KSARatings.map((c) => {
                        return {
                          id: c.KSARefId,
                          name: c.KSAStatement,
                          level: c.RatingValue,
                        };
                      }),
                subtitle: !isEmpty(comp.ComponentName)
                  ? comp.ComponentName
                  : "No title",
                paragraph: comp.ComponentSummary,
              };
            }),
    };
    if (x.SectionTypeName === CUSTOM_PROFILE_BLOCK_TYPE)
      customProfileBlockId += 1;
    return returnData;
  });
  customProfileBlockId = CUSTOM_PROFILE_BLOCK_ID;
  let initialBlocks = [];
  sortedSections?.map((x) => {
    if (x.SectionTypeName !== SUMMARY_OF_POSITION_TYPE) {
      initialBlocks.push({
        id:
          x.SectionTypeName === CUSTOM_PROFILE_BLOCK_TYPE
            ? customProfileBlockId
            : x.SectionTypeRefId,
        type:
          x.SectionTypeName === CUSTOM_PROFILE_BLOCK_TYPE
            ? x.SectionTypeName + customProfileBlockId
            : x.SectionTypeName,
        component: getProfileBlockComponent(x.SectionTypeName, type),
      });
      if (x.SectionTypeName === CUSTOM_PROFILE_BLOCK_TYPE)
        customProfileBlockId += 1;
    }
  });
  return { profileBlocks, initialBlocks };
};

export const getSectionsDataForSave = (profileBlocks) => {
  let sections = profileBlocks?.map((x) => {
    let isParagraphType =
      x.type === SUMMARY_OF_POSITION_TYPE ||
      x.type === SCOPE_IMPACT_TYPE ||
      x.type === CUSTOM_PROFILE_BLOCK_TYPE + x.id;
    return {
      sectionTypeRefId:
        x.type === CUSTOM_PROFILE_BLOCK_TYPE + x.id
          ? CUSTOM_PROFILE_BLOCK_ID
          : x.id,
      sectionName: x.title,
      sectionSummary: isParagraphType ? x.components[0].paragraph : "",
      components: isParagraphType
        ? []
        : x.components &&
          x.components.map((comp, i) => {
            return {
              componentTypeRefId:
                comp.type === JOB_PROFILE_PARAGRAPH_TYPE
                  ? COMPONENT_TYPE[comp.type]
                  : comp.id,
              componentName: comp.subtitle,
              componentSummary: comp.paragraph,
              componentTypeValues:
                isParagraphType ||
                x.type === PRIMARY_DUITIES_RESPONSIBILTY_TYPE ||
                isEmpty(comp.type) ||
                comp.type === JOB_PROFILE_PARAGRAPH_TYPE
                  ? null
                  : comp[comp.type.toLowerCase()]
                      .map((compType) => compType.id)
                      .join(","),
              ksaRatings:
                x.type === KNOWLEDGE_SKILL_ABILITY_TYPE
                  ? isEmpty(comp.type) ||
                    comp.type === JOB_PROFILE_PARAGRAPH_TYPE
                    ? []
                    : comp[comp.type.toLowerCase()].map((compType) => {
                        return {
                          ksaRefId: compType.id,
                          ratingValue: compType.level,
                        };
                      })
                  : [],
            };
          }),
    };
  });
  return sections;
};

export const getSelectedRecords = (event, props) => {
  let { name } = event;
  let data = Array.isArray(event.rowIndex) ? props.data : event.data;
  let selectedRecords = [...props.selectedRecords];

  if (name === "rowSelecting") {
    if (Array.isArray(data))
      data?.map((x) => {
        selectedRecords.push(x.JobDescriptionId);
      });
    else selectedRecords.push(data.JobDescriptionId);
  } else {
    if (Array.isArray(data))
      data?.map((x) => {
        const index = selectedRecords.indexOf(x.JobDescriptionId);
        if (index > -1) {
          selectedRecords.splice(index, 1);
        }
      });
    else {
      const index = selectedRecords.indexOf(data.JobDescriptionId);
      if (index > -1) {
        selectedRecords.splice(index, 1);
      }
    }
  }
  return selectedRecords;
};
