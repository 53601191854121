import React, { Component } from "react";
import { connect } from "react-redux";
// import { IoIosCheckmarkCircle } from "react-icons/io";
// import classnames from "classnames";
import isEmpty from "isEmpty";
import AnimatedForm from "components/common/animatedForm";
import Form from "components/form";
import { DisplayClientSideMessage } from "actions/commonAction";
import { FaCheckCircle, FaLessThanEqual } from "react-icons/fa";
import "../../style.scss";
// import { CreateClient, GetClientByClientId, UpdateClient } from "../../action";
import Modal from "components/modal";
import { InputValidator } from "helpers/inputValidator";
import Button from "components/button";
import { Tabs, Tab } from "components/tabs/index.d";
import RouteEnum from "constants/routeEnum";
import RequestedForm from "./requestedForm";
// import SalaryHR from "./salaryHR";
// import OutstandingLoan from "./outstandingLoan";
import { FaEdit } from "react-icons/fa";
import Breadcrumb from "../../../../common/breadcrumb";
import SvgIcons from "components/svgIcons";
import AssignApprover from "../../../AssignApprover";
import { FiPlus } from "react-icons/fi";
import CommonCommentSection from "components/common/commonCommentSection";
import {
  getHomeLoanDetails,
  getEmployeeOutstandingLoan,
  saveMultipleOutstandingLoan,
  getEmployeeDetailForLoan,
  setEmployeeLoanPulledStatus,
  changeRecommendation,
  saveMultiplePrimeLoanSalaryHead,
  getQualifiedApprovers,
  assignForwaredLoan,
  approveOrRejectLoan,
  forwardLoanToFinance,
  forwardLoanToHR,
} from "../../../helpers/action";

export const getLoanTypeText = (num) => {
  switch (num) {
    case 1:
      return "PrimeHomeLoan";
    case 2:
      return "PrimeStaffODLoan";
    case 3:
      return "PrimeStaffPersonalLoan";
    case 4:
      return "PrimeVehicleLoan";
    default:
      return "PrimeHomeLoan";
  }
};
export class HomeLoanForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "Request Form",
      constructionStage: "1",
      FileList: [],
      open: false,
      AssignTo: "",
      ForwardTo: [],
      modalTitle: "Assign To",
      forwardTitle: false,
      comments: [
        {
          commenter: "Aditya",
          commentDate: "2018.11.23",
          comment: "hello there",
          commenterId: "123",
        },
      ],
      tabs: [
        {
          name: "Request Form",
          label: "Request Form",
          key: "0",
          component: <RequestedForm />,
        },
        // {
        //   name: "Salary HR",
        //   label: "Salary (HR)",
        //   key: "1",
        //   component: <SalaryHR {...props} />,
        // },
        // {
        //   name: "Outstanding Loans",
        //   label: "Outstanding Loans",
        //   key: "2",
        //   component: <OutstandingLoan match={props.match} {...props}/>,
        // },
      ],
    };
  }

  async componentDidMount() {
    const { match, getHomeLoanDetails, getEmployeeDetailForLoan } = this.props;
    const { id } = match.params;
    if (id) {
      await getHomeLoanDetails(id);
      let {
        homeLoanDetail,
        loanApproverList,
        user: { EmployeeId },
      } = this.props;
      if (!isEmpty(homeLoanDetail)) {
        if (isEmpty(loanApproverList)) {
          await this.props.getQualifiedApprovers(EmployeeId);
        }
        loanApproverList = this.props.loanApproverList;
        if (homeLoanDetail && loanApproverList)
          await getEmployeeDetailForLoan(homeLoanDetail.EmployeeId);
        loanApproverList =
          loanApproverList &&
          loanApproverList.filter(
            (loan) => loan.Id != EmployeeId && loan.IsHR == true
          );
        this.setState({
          loanApproverList,
          AssignTo: loanApproverList[0].Id,
          LoanId: homeLoanDetail.Id,
          Status: homeLoanDetail.Status,
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.setEmployeeLoanPulledStatus(false);
  }

  handleInput = async (name, value) => {
    this.setState({ [name]: value });
  };
  onChangeRadio = (name, value) => {
    this.setState({ [name]: value });
  };

  onTabChange = (activeTab) => {
    this.setState({ activeTab });
    // if (activeTab == "Outstanding Loans")
    // this.props.getEmployeeOutstandingLoan(10002);
  };
  saveComment = () => {
    this.setState({});
  };

  // handleFormSubmit = () => {
  //   const { activeTab } = this.state;
  //   if (activeTab == "Outstanding Loans")
  //     this.saveMultipleOutstandingLoan();
  //   else if (activeTab == "Salary HR") this.saveMultipleSalaryHead();
  // };

  handleFormSubmit = async (isApprove) => {
    let {
      homeLoanDetail: { Status },
      loanApproverList,
    } = this.props;
    const { LoanId } = this.state;
    if (Status == 1) {
      loanApproverList = loanApproverList.filter((loan) => loan.IsHR == false);
      this.setState({
        open: true,
        loanApproverList,
        modalTitle: "Forward To",
        approverTitle: "Credit Administration Department/Finance Department",
      });
    } else if (Status == 2) {
      loanApproverList = loanApproverList.filter((loan) => loan.IsHR == true);
      this.setState({
        open: true,
        loanApproverList,
        modalTitle: "Forward To",
        approverTitle: "Human Resource Department",
      });
    } else if (Status == 3) {
      let submitData = {
        isApprove,
        loanId: LoanId,
      };
      await this.props.approveOrRejectLoan(submitData);
      const { loanApprovedStatus, history } = this.props;
      if (loanApprovedStatus) {
        history.goBack();
      }
    }
  };

  saveMultipleSalaryHead = async () => {
    const { employeePrimeLoanSalary, Recommendation } = this.props;
    let primeLoanSalaryHead = [];
    // employeePrimeLoanSalary.forEach(salary => {
    //   primeLoanSalaryHead.push({
    //     ...salary,
    //     ID: 0,
    //     LoanId: salary.LoanId,

    //   })
    // })
    let data = {
      PrimeLoanSalaryHead: [...employeePrimeLoanSalary],
      Recommendation,
    };
    await this.props.saveMultiplePrimeLoanSalaryHead(data);
    const { multiplePrimeLoanSalaryHeadSaved } = this.props;
    if (multiplePrimeLoanSalaryHeadSaved) this.props.history.goBack();
  };

  saveMultipleOutstandingLoan = async () => {
    const {
      saveMultipleOutstandingLoan,
      employeePayrollOutstandingLoan,
      match,
      Recommendation,
    } = this.props;
    const { activeTab } = this.state;

    let loanArray = [];
    if (match.params.id && !isEmpty(employeePayrollOutstandingLoan))
      employeePayrollOutstandingLoan &&
        employeePayrollOutstandingLoan.forEach((loan) => {
          loanArray.push({
            ...loan,
            Id: 0,
            LoanId: parseInt(match.params.id),
            LoanType: getLoanTypeText(1),
          });
        });
    else return;
    let data = {
      PrimeOutstandingLoanDto: loanArray,
      Recommendation,
    };
    await saveMultipleOutstandingLoan(data);
    const { multipleOutstandingLoanSaved } = this.props;
    if (multipleOutstandingLoanSaved) {
      this.props.history.goBack();
    }
  };

  editHomeDetails = async (homeLoanDetail) => {
    // if (homeLoanDetail.LoanType == 'PrimeHomeLoan')
    this.props.history.push(
      `${RouteEnum.UpdateHomeLoanPath}/${homeLoanDetail.Id}`
    );
    // else if (homeLoanDetail.LoanType == 2)
    //   this.props.push(`${RouteEnum.UpdateHomeLoanPath}/${homeLoanDetail.Id}`);
    // else if (homeLoanDetail.LoanType == 3)
    //   this.props.push(`${RouteEnum.UpdateHomeLoanPath}/${homeLoanDetail.Id}`);
    // else this.props.push(`${RouteEnum.UpdateHomeLoanPath}/${homeLoanDetail.Id}`);
  };

  onClose = () =>
    this.setState({ open: false, ForwardTo: [], loanToForward: null });

  handleAssignerSubmit = async () => {
    const { LoanId, AssignTo, modalTitle, ForwardTo, Status } = this.state;
    if (isEmpty(AssignTo)) {
      this.props.DisplayClientSideMessage(
        "Please Select an Assignee to Procced"
      );
      return;
    }
    let data = {
      LoanId,
      EmployeeId: AssignTo,
      LoanType: "PrimeHomeLoan",
    };
    if (modalTitle == "Forward To") {
      let forwardData = {
        loanId: LoanId,
        loanType: "PrimeHomeLoan",
        employeeIds: ForwardTo,
        // loanType: loanToForward.type
      };
      if (Status == 1) await this.props.forwardLoanToFinance(forwardData);
      else if (Status == 2) await this.props.forwardLoanToHR(forwardData);
      this.props.history.goBack();
    } else if (modalTitle == "Assign To") {
      await this.props.assignForwaredLoan(data);
      const { isLoanAssigned } = this.props;
      if (isLoanAssigned) {
        this.props.history.goBack();
      }
    }
  };

  handleAssignInput = (name, value) => {
    const { modalTitle } = this.state;
    if (modalTitle == "Forward To") {
      this.setState({ ForwardTo: value });
    } else if (modalTitle == "Assign To") this.setState({ AssignTo: value });
  };

  render() {
    const {
      activeTab,
      tabs,
      comments,
      open,
      AssignTo,
      loanApproverList,
      modalTitle,
      forwardTitle,
      approverTitle,
      ForwardTo,
    } = this.state;
    const {
      homeLoanDetail,
      user: { EmployeeId },
      isHR,
    } = this.props;
    let isSelfApplied = EmployeeId == homeLoanDetail?.EmployeeId;
    return (
      <div className="common-container dashboard">
        <div className="tasktracker-title-section">
          <Breadcrumb name="Loan View" />
        </div>
        <div className="homeloan homeloan-view mb-bg">
          <div className="homeloan-body">
            <div className="homeloan-header mb-bg flex justify-between">
              <h3>Home Loan Request</h3>
              {!isSelfApplied && homeLoanDetail?.Status == 1 ? (
                <div className="flex">
                  <span
                    onClick={() => this.editHomeDetails(homeLoanDetail)}
                    className="education-detail__edit"
                  >
                    <FaEdit />
                  </span>
                  <Button
                    bg="primary"
                    buttonType="icon-button"
                    leftIcon={
                      <SvgIcons name="PeopleArrows" color="#fff" size={28} />
                    }
                    onClick={() =>
                      this.setState({
                        open: true,
                        forwardTitle: true,
                        approverTitle: "Human Resource Department",
                        modalTitle: "Assign To",
                      })
                    }
                    // onClick={() => this.onStepChange(currentStep - 1)}
                    title="Assign"
                  />
                </div>
              ) : null}
            </div>
            <Modal
              onModalClose={this.onClose}
              title={modalTitle}
              className="current-modal"
              open={open}
            >
              <AssignApprover
                closeModal={this.onClose}
                // {...props}
                // mode={mode}
                hrEmpDetails={loanApproverList}
                ForwardTo={modalTitle == "Assign To" ? AssignTo : ForwardTo}
                approverTitle={approverTitle}
                handleSubmit={this.handleAssignerSubmit}
                handleInput={this.handleAssignInput}
                multiple={modalTitle == "Assign To" ? false : true}
                forwardTitle={forwardTitle}
                // setOpen={
                //   (loanToForward) => this.setOpen(loanToForward)
                //   // this.setState({ open: true, loanToForward })
                // }
              />
            </Modal>
            <div className="homeloan-section-content mt-bg">
              <Tabs noBackground activeTab={activeTab}>
                {tabs.map((tab) => {
                  if (isSelfApplied && tab.key != "0") return null;
                  else if (isHR && tab.key == "2") return null;
                  else if (!isHR && tab.key == "1") return null;
                  else {
                    return (
                      <Tab
                        label={tab.label}
                        name={tab.name}
                        id={tab.name}
                        onTabClick={() => this.onTabChange(tab.name)}
                      >
                        {tab.component ? (
                          tab.component
                        ) : (
                          <span>{tab.label}</span>
                        )}
                      </Tab>
                    );
                  }
                })}
              </Tabs>
            </div>
            {/* <div className="homeloan-section-content">
            {this.renderCurrentStage(currentStep)}
          </div> */}
          </div>
          {activeTab == "Request Form" && (
            <div className="homeloan-view-comments">
              <CommonCommentSection
                submitComment={this.saveComment}
                comments={comments}
                // submitErrors={errors}
                isViewMode={false}
              />
            </div>
          )}
          <div className="homeloan-view-footer flex">
            <div className="homeloan-section-footer-item">
              <Button
                bg="subtle"
                onClick={() => this.props.history.goBack()}
                title="Close"
              />
            </div>
            {/* {activeTab == "Request Form" && !isSelfApplied ? (
              <div className="homeloan-section-footer-item">
                <Button
                  bg="primary"
                  // onClick={() => this.onClose()}
                  title="Send Back to Requester"
                />
              </div>
            ) : (
            )} */}
            <div className="homeloan-section-footer-item wd-90"></div>
            {!isSelfApplied && homeLoanDetail?.Status < 4 ? (
              <div className="flex wd-30">
                {homeLoanDetail?.Status == 3 && (
                  <div className="homeloan-section-footer-item">
                    <Button
                      buttonType="icon-button"
                      bg="danger"
                      title={"Reject"}
                      leftIcon={<FaCheckCircle className="text-xbg" />}
                      onClick={() => this.handleFormSubmit(false)}
                    />
                  </div>
                )}
                <div className="homeloan-section-footer-item">
                  <Button
                    buttonType="icon-button"
                    bg="success"
                    title={homeLoanDetail?.Status == 3 ? "Approve" : "Forward"}
                    leftIcon={<FaCheckCircle className="text-xbg" />}
                    onClick={() => this.handleFormSubmit(true)}
                  />
                </div>
              </div>
            ) : (
              <div className="homeloan-section-footer-item"></div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  homeLoanDetail: state.loanReducer.homeLoanDetail,
  employeeOutstandingLoan: state.loanReducer.employeeOutstandingLoan,
  employeePayrollOutstandingLoan:
    state.loanReducer.employeePayrollOutstandingLoan,
  Recommendation: state.loanReducer.Recommendation,
  multipleOutstandingLoanSaved: state.loanReducer.multipleOutstandingLoanSaved,
  employeePrimeLoanSalary: state.loanReducer.employeePrimeLoanSalary,
  loanApproverList: state.loanReducer.loanApproverList,
  loanApprovedStatus: state.loanReducer.loanApprovedStatus,
  isLoanAssigned: state.loanReducer.isLoanAssigned,
  isHR: state.loanReducer.isHR,
  multiplePrimeLoanSalaryHeadSaved:
    state.loanReducer.multiplePrimeLoanSalaryHeadSaved,
  user: state.auth.user,
});

const mapDispatchToProps = {
  getHomeLoanDetails,
  getEmployeeOutstandingLoan,
  saveMultipleOutstandingLoan,
  getEmployeeDetailForLoan,
  setEmployeeLoanPulledStatus,
  changeRecommendation,
  saveMultiplePrimeLoanSalaryHead,
  getQualifiedApprovers,
  DisplayClientSideMessage,
  assignForwaredLoan,
  approveOrRejectLoan,
  forwardLoanToFinance,
  forwardLoanToHR,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeLoanForm);
