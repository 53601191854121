import React, { Component } from "react";
import moment from "moment";
import { FaRegCalendarAlt } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { DateRange } from "react-date-range";
import { startDatetime, endDatetime, Date_Format } from "helpers/dateFormat";
import isEmpty from "isEmpty";

export class index extends Component {
  constructor(props) {
    super(props);
    this.setState = {};
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.setDateRangeRef = this.setDateRangeRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.wrapperRef = null;
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  setWrapperRef(node) {
    this.wrapperRef = node;
  }
  setDateRangeRef(node) {
    this.dateRangeRef = node;
  }
  handleDateClick = (event) => {
    if (
      this.wrapperRef.contains(event.target) &&
      !this.dateRangeRef.contains(event.target)
    )
      this.wrapperRef.classList.toggle("active");
  };
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.wrapperRef.classList.remove("active");
    }
  }

  handleDateRangePicker = (range) => {
    let startDate = range.startDate ? startDatetime(range.startDate) : null;
    let endDate = range.endDate ? endDatetime(range.endDate) : null;

    this.props.handleDateRangePicker &&
      this.props.handleDateRangePicker(startDate, endDate);
  };

  removeSelectedDate = () => {
    this.props.handleDateRangePicker &&
      this.props.handleDateRangePicker(null, null);
  };

  render() {
    const { startDate, endDate, placeholder } = this.props;
    return (
      <div
        className="daterangepicker"
        ref={this.setWrapperRef}
        onClick={(e) => this.handleDateClick(e)}
      >
        <span className="daterangepicker__date">
          {!isEmpty(startDate) && !isEmpty(endDate) ? (
            <span>{Date_Format(startDate) + " -" + Date_Format(endDate)}</span>
          ) : (
            <span className="placeholder">{placeholder}</span>
          )}
        </span>
        <div className="daterangepicker__range" ref={this.setDateRangeRef}>
          <DateRange
            startDate={
              !isEmpty(startDate) ? moment(startDate) : moment(new Date())
            }
            endDate={!isEmpty(endDate) ? moment(endDate) : moment(new Date())}
            // onInit={this.handleDateRangePicker}
            onChange={this.handleDateRangePicker}
          />
        </div>
        {isEmpty(startDate) && isEmpty(endDate) ? (
          <span className="daterangepicker__icon">
            <FaRegCalendarAlt />
          </span>
        ) : (
          <span
            className="daterangepicker__icon"
            onClick={this.removeSelectedDate}
          >
            <MdClose />
          </span>
        )}
      </div>
    );
  }
}

export default index;
