import React from 'react'
import './styles.scss'
import { FaUserFriends, FaYoutube, FaLock } from 'react-icons/fa'
import { BsClock } from 'react-icons/bs'
import { MdCheckCircle, MdPlayCircleFilled } from 'react-icons/md';
import {BiArrowToRight} from 'react-icons/bi'
import classnames from 'classnames'
import { useHistory } from 'react-router';
import RouteEnum from 'constants/routeEnum';

function Sidebar(props) {
    const {coursePages,coursePage} = props;
    const history = useHistory()
    return (
            <div className="course-sidebar">
                <div className="course-sidebar__icon">
                    <BiArrowToRight />
                </div>
                <div className="course-sidebar-title">Outline</div>
                <ul className="course-sidebar-list">
                    <li 
                        onClick={()=>history.push(RouteEnum.lmsCourse + '/' + coursePage?.CourseId)}
                        className="course-sidebar-item">
                        <MdCheckCircle />
                        <span>Introduction</span>
                    </li>
                    {
                        coursePages && coursePages.map(item=>{
                            const classes = classnames("course-sidebar-item cursor-pointer",{
                                "active":item.Id === coursePage?.Id
                            })
                            return(
                                <li 
                                    onClick={()=>{
                                        history.push(RouteEnum.lmsCourse + '/' + item.CourseId +'/pages/' + item.Id)
                                    }}
                                    className={classes}>
                                    <MdPlayCircleFilled />
                                    <span>{item.PageName}</span>
                                </li>
                            )
                        })
                    }
                    {/* <li className="course-sidebar-item">
                        <div className="course-sidebar-circle"></div>
                        <span>What is User Experience</span>
                    </li>
                    <li className="course-sidebar-item">
                        <FaLock />
                        <span>UI vs UX</span>
                    </li>
                    <li className="course-sidebar-item">
                        <FaLock />
                        <span>Final Quiz</span>
                    </li>
                    <li className="course-sidebar-item">
                        <FaLock />
                        <span>Certificate</span>
                    </li> */}
                </ul>
            </div>
    )
}


export default Sidebar
