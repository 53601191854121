import {
  GET_TIME_REQUEST_DETAIL,
  GET_TIME_REQUEST_APPROVAL_RECOMMENDED,
} from "actions/types";

const initialState = {
  timeRequestDetail: null,
  timeRequestApprovalRecommended: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TIME_REQUEST_DETAIL:
      return {
        ...state,
        timeRequestDetail: action.payload,
      };
    case GET_TIME_REQUEST_APPROVAL_RECOMMENDED:
      return {
        ...state,
        timeRequestApprovalRecommended: action.payload,
      };

    default:
      return state;
  }
}
