import React, { Component } from "react";

function transpose(a) {
  return Object.keys(a).map(function (c) {
    return a.map(function (r) {
      return r[c];
    });
  });
}

export class MyLeaves extends Component {
  render() {
    const data = this.props.employeeLeaveList_Formated;
    if (!data) {
      return null;
    }

    let rowLength = 0;
    data.headings.map((h) => {
      if (h.Visibility) {
        rowLength += 1;
      }
    });
    let allDash = [];
    let trans = transpose(data.rows);
    (() => {
      allDash = trans.map((row) => {
        row.pop();
        return row.every((col) => col === "-");
      });
    })();
    const finalLen = allDash.reduce(function (a, b) {
      return b ? a + 1 : a;
    });
    const rowStyle = {
      gridTemplateColumns: `minmax(100px,200px) repeat(${
        rowLength - 1 - finalLen
      },1fr)`,
    };
    return (
      <div className="my-leave leave-block">
        <div className="bloch__title space-between">
          <span>My Leaves</span>
        </div>
        <div className="my-leave-body">
          <div style={rowStyle} className="my-leave-body-row row-header">
            {data.headings.map((heading, i) => {
              if (allDash[i]) {
                return false;
              }
              if (heading.Visibility) {
                return (
                  <span className="cell" key={i}>
                    {heading.Title}
                  </span>
                );
              }
            })}
          </div>

          {data.rows.map((row, index) => {
            let rowClassName = "my-leave-body-row";
            if (index == 3) {
              rowClassName = rowClassName + " leave-used";
            } else if (index == 4) {
              rowClassName = rowClassName + " leave-under_review";
            } else if (index == 5) {
              rowClassName = rowClassName + " leave-remaining";
            }
            {
              /* console.log(row) */
            }
            let totalAtEnd = 0;
            return (
              <div style={rowStyle} className={rowClassName} key={index}>
                {row.map((cell, c) => {
                  if (c > 0 && cell != "-") {
                    totalAtEnd = totalAtEnd + parseFloat(cell);
                  }
                  if (allDash[c]) {
                    return false;
                  }
                  if (data.headings[c].Visibility) {
                    return (
                      <span className="cell" key={c}>
                        {cell}
                      </span>
                    );
                  }
                })}
                <span className="cell">{totalAtEnd.toFixed(2)}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default MyLeaves;
