import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import "./style.scss";
import { connect } from "react-redux";
import { MdAdd } from "react-icons/md";
import Title from "../../common/title";
import EmployeeOnboardingTabs from "./onBoardingTabs";
import SearchAndFilter from "./searchAndFilter";
import { GetDesignations, getLevels } from "actions/commonAction";
import {
  GetOnboardingEmployeeDetail,
  GetOnBoardingEmployeeList,
  DeleteOnBoardingEmployee,
} from "./action";
import {} from "./action";
import OnboardingForm from "./form";
import RouteEnum from "constants/routeEnum";
// import OnboardingForm from "./form/index";
// import DisciplinaryCaseForm from "./forms/disciplinaryCaseForm";
import isEmpty from "isEmpty";
import Button from "components/button";
import Icons from "components/icons";

const OnBoardingStages = [
  {
    tabId: 0,
    stageId: 0,
    label: "All Onboarding",
  },
  {
    tabId: 1,
    stageId: 1,
    label: "Drafts",
  },
  {
    tabId: 2,
    stageId: 2,
    label: "Onboarding Process",
  },
  {
    tabId: 3,
    stageId: 3,
    label: "Onboarding Completed",
  },
];
export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      pageIndex: 0,
      rowsPerPage: 10,
      searchEmployee: "",
      searchActionStatement: "",
      fromDate: null,
      toDate: null,
      activeJobRole: null,
      activePosition: null,
      activeStageId: 1,
      showCreateNewEmployee: false,
      isEditPage: false,
      onBoardingStages: OnBoardingStages,
    };
  }

  async componentDidMount() {
    await this.props.GetDesignations();
    await this.props.getLevels();
    await this.GetEmployeeOnboardingList();
    const { levelList } = this.props;
    let levelLists =
      levelList &&
      levelList.map((lvl) => {
        return {
          label: lvl.Text,
          value: lvl.Value,
        };
      });
    this.setState({ levelLists });
  }
  componentDidUpdate(prevProps, prevState) {
    const { onboardingEmployeeList } = this.props;

    if (prevProps.onboardingEmployeeList !== onboardingEmployeeList) {
      this.setState({ onboardingEmployeeList });
    }
  }

  GetEmployeeOnboardingList = async () => {
    this.setState({ isLoading: true });
    const {
      pageIndex,
      rowsPerPage,
      activeStageId,
      searchEmployee,
      // searchActionStatement,
      // fromDate,
      // toDate,
      // activeJobRole,
      activePosition,
    } = this.state;

    const data = {
      EmployeeName: searchEmployee,
      LevelId: !isEmpty(activePosition) ? activePosition.value : 0,
      // PositionId: !isEmpty(activePosition) ? activePosition.value : -1,
      Stage: activeStageId,
      PageIndex: pageIndex,
      PageSize: rowsPerPage,
    };
    data.requestUrl = `/v1/onBoarding/employees?`;
    if (data.EmployeeName) {
      data.requestUrl = data.requestUrl + `EmployeeName=${data.EmployeeName}&`;
    }
    if (data.LevelId) {
      data.requestUrl = data.requestUrl + `LevelId=${data.LevelId}&`;
    }
    if (data.Stage) {
      data.requestUrl = data.requestUrl + `Stage=${data.Stage}&`;
    }
    data.requestUrl =
      data.requestUrl + `PageIndex=${data.PageIndex}&PageSize=${data.PageSize}`;
    await this.props.GetOnBoardingEmployeeList(data);

    this.setState({ isLoading: false });
  };

  handleEdit = async (props) => {
    if (props.CurrentStage == "Onboarding Process")
      this.props.history.push(`/admin/employee_onboarding/packages/${props.EmployeeId}`);
    else if (props.CurrentStage == "Draft")
      this.props.history.push("/admin/employee_onboarding/update/" + props.EmployeeId);
  };

  handleStageChange = (stageId) => {
    this.setState({ activeStageId: stageId, pageIndex: 0 }, async () => {
      await this.GetEmployeeOnboardingList();
    });
  };

  handleAutoSelect = (name, selected) => {
    this.setState({ [name]: selected, pageIndex: 0 }, async () => {
      await this.GetEmployeeOnboardingList();
    });
  };

  handleRowChange = (num) => {
    this.setState({ rowsPerPage: num }, async () => {
      await this.GetEmployeeOnboardingList();
    });
  };

  handleIndexChange = (num) => {
    this.setState({ pageIndex: num }, async () => {
      await this.GetEmployeeOnboardingList();
    });
  };
  handleFormDisplay = (value) => {
    this.setState({ showCreateNewEmployee: value });
  };

  // handleAddNewEmployee = (disciplinaryCaseDetails) => {
  //   // localStorage.setItem(
  //   //   "onboardingEmployeeDataToNewTab",
  //   //   JSON.stringify({
  //   //     title: "New Onboarding Employee",
  //   //     isEditPage: false,
  //   //     formExists: true,
  //   //     isFormLoading: disciplinaryCaseDetails.loading,
  //   //   })
  //   // );

  //   {
  //     /* {showCreateNewEmployee ? (
  //         <OnboardingForm
  //           history={history}
  //           title="New Onboarding Employee"
  //           handleFormDisplay={this.handleFormDisplay}
  //           isEditPage={isEditPage}
  //           showForm={showCreateNewEmployee}
  //           formExists={true}
  //           isFormLoading={disciplinaryCaseDetails.loading}
  //           GetEmployeeOnboardingList={this.GetEmployeeOnboardingList}
  //         />
  //       ) : null} */
  //   }
  // };

  deleteOnBoardingEmployee = async (EmployeeId) => {
    await this.props.DeleteOnBoardingEmployee(EmployeeId);
    const { deleteOnboardingEmployee } = this.props;
    if (deleteOnboardingEmployee) {
      this.GetEmployeeOnboardingList();
    }
  };
  render() {
    const { designationList, history, GetOnboardingEmployeeDetail } =
      this.props;
    let height = this.state.showCreateNewEmployee ? 0 : "auto";
    const {
      showCreateNewEmployee,
      isEditPage,
      onBoardingStages,
      levelLists,
      onboardingEmployeeList,
      searchEmployee,
    } = this.state;

    console.log(this.props);
    return (
      <div
        className="common-container employee-onboarding-table"
        style={{ height: height }}
      >
        <Title name="Employee Onboarding" parentProps={this.props} />

        <div className="add flex">
          {/* <Button
            onClick={() =>
              this.setState({ isEditPage: false, showCreateNewEmployee: true })
              // this.props.history.push(RouteEnum.EmployeeOnboardingAddEmployee)
            }
            className="btn btn-outline add-content"
            leftIcon={<MdAdd />}
            buttonType="icon-button"
            bg="white"
          >
            New Employee
          </Button> */}

          <Link
            to={{ pathname: RouteEnum.CreateOnboardingEmployee }}
            // to={{ pathname: `${RouteEnum.ViewHomeLoanPath}/6`}}
            // target="_blank"
          >
            <Button
              // onClick={() =>
              //   this.setState({ isEditPage: false, showCreateNewEmployee: true })
              // }
              buttonType="icon-button"
              type="button"
              bg="white"
              icon={<Icons name="Add" />}
            >
              New Employee
            </Button>
          </Link>
        </div>

        <SearchAndFilter
          handleAutoSelect={this.handleAutoSelect}
          parentState={this.state}
          designationList={designationList}
          levelList={levelLists || []}
          searchEmployee={searchEmployee}
        />
        {!isEmpty(onBoardingStages) ? (
          <EmployeeOnboardingTabs
            handleIndexChange={this.handleIndexChange}
            handleRowChange={this.handleRowChange}
            handleStageChange={this.handleStageChange}
            handleEdit={this.handleEdit}
            parentState={this.state}
            onBoardingStages={onBoardingStages}
            GetEmployeeOnboardingList={this.GetEmployeeOnboardingList}
            GetOnboardingEmployeeDetail={GetOnboardingEmployeeDetail}
            onboardingEmployeeList={onboardingEmployeeList}
            deleteOnBoardingEmployee={this.deleteOnBoardingEmployee}
          />
        ) : null}

        {showCreateNewEmployee ? (
          <OnboardingForm
            history={history}
            title="New Onboarding Employee"
            handleFormDisplay={this.handleFormDisplay}
            isEditPage={isEditPage}
            showForm={showCreateNewEmployee}
            formExists={false}
            hideFooter={true}
            GetEmployeeOnboardingList={this.GetEmployeeOnboardingList}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  designationList: state.commonReducer.designationList,
  levelList: state.commonReducer.levelList,
  onboardingEmployeeList: state.employeeOnboarding.onboardingEmployeeList,
  deleteOnboardingEmployee: state.employeeOnboarding.deleteOnboardingEmployee,
});

const mapDispatchToProps = {
  GetDesignations,
  getLevels,
  GetOnboardingEmployeeDetail,
  GetOnBoardingEmployeeList,
  DeleteOnBoardingEmployee,
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
