import React, { Component } from "react";
import Tabs from "../../../components/tabs/tabs";
// import InputGroup from '../../../Components/Form/InputGroup'
import { MdAddCircleOutline } from "react-icons/md";
import { IoIosCheckmarkCircle } from "react-icons/io";
import Form from "components/form";
import Table from "./table";
import { connect } from "react-redux";
import Filters from "./filters";
import moment from "moment";
import isEmpty from "isEmpty";
import {
  GetBranches,
  GetDepartments,
  GetDesignations,
  GetJobStatus,
} from "actions/commonAction";
import { GetEmployeeListForAdmin } from "../../employee/employeeList/action";
import DateFnsUtils from "@date-io/date-fns";
import "./style.scss";
import Sidetab from "./sideTabs";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import CommonFilters from "../../../components/dataselect/commonFilters";
import SvgIcons from "components/svgIcons";

const getDate = (name) => {
  var date = new Date();
  var firstDay = null;
  var lastDay = null;
  const label = !isEmpty(name) ? name.label : "";
  if (label === "This Month") {
    firstDay = moment(date).startOf("month").format("YYYY/MM/DD");
    lastDay = moment(date).endOf("month").format("YYYY/MM/DD");
  }
  if (label === "Last Month") {
    firstDay = moment(date)
      .subtract(1, "months")
      .startOf("month")
      .format("YYYY/MM/DD");
    lastDay = moment(date)
      .subtract(1, "months")
      .endOf("month")
      .format("YYYY/MM/DD");
  }
  if (label === "This Year") {
    firstDay = moment().startOf("year").format("YYYY/MM/DD");
    lastDay = moment().endOf("year").format("YYYY/MM/DD");
  }
  return { firstDay, lastDay };
};

export class Arrear_Calculation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      leftValue: "",
      searchValue: "",
      activeBranch: "",
      activeDepartment: "",
      activePosition: "",
      activeStatus: "",
      income_deduct: "",
      position: "",
      region: "",
      approval_state: "",
      leave_type: "",
      date: "",
      currentStep: 1,
    };
    this.filters = "";
    this.onChange = this.onChange.bind(this);
    this.onApply = this.onApply.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  initialize = async () => {
    this.setState({ isLoading: true });

    const {
      pageIndex,
      rowsPerPage,
      searchValue,
      activeBranch,
      activeDepartment,
      activePosition,
      activeStatus,
      gender,
      maritalStatus,
      newJoinees,
      state,
      retired,
    } = this.state;
    const { firstDay: fromDate, lastDay: endDate } = getDate(newJoinees);
    const { firstDay: retirementStartDate, lastDay: retirementEndDate } =
      getDate(retired);

    await this.props.GetEmployeeListForAdmin({
      pageIndex,
      rowsPerPage,
      searchValue,
      gender,
      maritalStatus,
      activeBranch,
      state,
      fromDate,
      endDate,
      retirementStartDate,
      retirementEndDate,
      activeDepartment,
      activePosition,
      activeStatus,
    });

    this.setState({ isLoading: false });
  };

  handleInput = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, pageIndex: 0 }, () => {
      this.initialize();
    });
  };

  handleAutoSelect = (name, selected) => {
    this.setState({ [name]: selected, pageIndex: 0 }, () => {
      this.initialize();
    });
  };

  handleRowChange = (num) => {
    this.setState({ rowsPerPage: num }, () => {
      this.initialize();
    });
  };

  handleIndexChange = (num) => {
    this.setState({ pageIndex: num }, () => {
      this.initialize();
    });
  };
  handleDateChange = (date) => {
    // setSelectedDate(date);
    this.setState({ date });
    console.log(date);
  };
  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };
  onApply() {
    console.log(this.filters.state);
  }
  changeSideTab = (currentStep) => {
    this.setState({ currentStep });
  };
  render() {
    const {
      employeeListForAdmin,
      branches,
      searchValue,
      departments,
      designations,
      statusList,
      history,
    } = this.props;
    const {
      actTab,
      pageIndex,
      attendancePageIndex,
      rowsPerPage,
      attendanceRowsPerPage,
      date,
      currentStep,
    } = this.state;
    return (
      <div className="payroll-body arrear">
        <Filters
          // pageIndex={this.state.pageIndex}
          // handleRowChange={this.handleRowChange}
          // handleIndexChange={this.handleIndexChange}
          // rowsPerPage={this.state.rowsPerPage}
          parentState={this.state}
          branches={branches}
          searchValue={searchValue}
          departments={departments}
          designations={designations}
          statusList={statusList}
          handleAutoSelect={this.handleAutoSelect}
          handleInput={this.handleInput}
        />
        {/* <Tabs type="tabs-block">
                    <div label="Common parameters" name="Common parameters">
                        <div className="filters-input">
                            <div className="filters-input__item">
                                <InputGroup
                                        name="search"
                                        type="search"
                                        value={this.state.search}
                                        onChange={this.onChange}
                                        className="search"
                                        placeholder="Search Employee"
                                        size="med"
                                    />
                            </div>
                            <div className="filters-input__item">
                                <InputGroup
                                    name="branch"
                                    value={this.state.branch}
                                    onChange={this.onChange}
                                    label="Branch"
                                    placeholder="Branch"
                                    size="med"
                                />
                            </div>
                            <div className="filters-input__item">
                                <InputGroup
                                    name="department"
                                    value={this.state.department}
                                    onChange={this.onChange}
                                    label="Department"
                                    placeholder="Department"
                                    size="med"
                                />
                            </div>
                            <div className="filters-input__item">
                                <InputGroup
                                    name="status"
                                    value={this.state.status}
                                    onChange={this.onChange}
                                    label="Status"
                                    placeholder="Status"
                                    size="med"
                                />
                            </div>
                            <div className="filters-input__item">
                                <InputGroup
                                    name="position"
                                    value={this.state.position}
                                    onChange={this.onChange}
                                    label="Position"
                                    // placeholder="Position"
                                    size="med"
                                />
                            </div>
                            <div className="filters-input__item">
                                <button className="btn-submit">Apply</button>
                            </div>
                        </div>
                    </div>
                    <div label="Advanced parameters" name="Advanced parameters">
                        <div className="filters-input">
                            <div className="filters-input__item">
                                <InputGroup
                                        name="region"
                                        value={this.state.region}
                                        onChange={this.onChange}
                                        label="Region"
                                        // placeholder="Region"
                                        size="med"
                                    />
                            </div>
                            <div className="filters-input__item">
                                <InputGroup
                                        name="approval_state"
                                        value={this.state.approval_state}
                                        onChange={this.onChange}
                                        label="Approval State"
                                        // placeholder="Approval State"
                                        size="med"
                                    />
                            </div>
                            <div className="filters-input__item">
                                <InputGroup
                                        name="leave_type"
                                        value={this.state.leave_type}
                                        onChange={this.onChange}
                                        label="Leave Type"
                                        // placeholder="Leave Type"
                                        size="med"
                                    />
                            </div>
                            <div className="filters-input__item">
                                <button className="btn-submit">Apply</button>
                            </div>
                        </div>
                    </div>
                </Tabs> */}
        <div className="space-between mt-md arrear-btns">
          <div></div>
          <div className="flex">
            <a href="#" className="btn btn-rounded green btn-green">
              <div>Arrear 1</div>
              <span>2076/06/1</span>
            </a>
            <a href="" className="btn btn-rounded btn-grey-2">
              <MdAddCircleOutline />
            </a>
          </div>
        </div>
        <div className="arrear-block">
          <h4 className="arrear-title">
            <span>Step 1 </span>
            Select Primary income for Arrear Calculation
          </h4>
          <div className="flex badges-group">
            <div className="badges left-icon badges-rounded badges-blue">
              <IoIosCheckmarkCircle />
              <span>Basic Salary</span>
            </div>
            <div className="badges badges-rounded">
              <span>Other Income A</span>
            </div>
          </div>
        </div>
        <div className="arrear-block">
          <h4 className="arrear-title">
            <span>Step 2 </span>
            Calculation
          </h4>
          <div className="area wd-90">
            <div className="area-header">
              <h3>Selected Primary Income</h3>
            </div>
            <div className="area-body">
              <div className="area-body__item space-between">
                <span>Basic Salary</span>
                <div className="space-between">
                  <span>Increase from</span>
                  {/* <span>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <div className="pickers recruit-picker">
                                                <KeyboardDatePicker 
                                                    value={this.state.date}
                                                    
                                                    onChange={this.handleDateChange} />
                                            </div>
                                        </MuiPickersUtilsProvider>
                                        </span> */}
                  <span className="desc desc_date">
                    <Form
                      formName="customdateinput"
                      name="date"
                      onChange={(name, value) => this.handleChange(name, value)}
                      value={date}
                      placeholder="Date.."
                      // isNepali={!companyInformation?.IsEnglishDate}
                      // isTextInNepali={!companyInformation?.IsEnglishDate}
                    />
                  </span>
                </div>
                <div>
                  <span>
                    <b>3</b> Months{" "}
                  </span>
                  <span>
                    <b>2</b> days
                  </span>
                </div>
                <div>
                  <span>Not yet calculated</span>
                </div>
                <div className="buttons">
                  <a href="#" className="btn btn-large btn-blue">
                    Calculate
                  </a>
                  <a
                    href="#"
                    className="btn btn-large btn-grey"
                    style={{ marginTop: 0, height: "unset" }}
                  >
                    Delete
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="area wd-60">
          <div className="area-header">
            <h3>Incomes affected by Arrear Increase in Basic Salary</h3>
          </div>
          <div className="area-body">
            <div className="area-body__item space-between">
              <div>
                <span>Provident Fund</span>
              </div>
              <div>
                <span>Not yet calculated</span>
              </div>
              <div className="buttons">
                <a href="#" className="btn btn-large btn-blue">
                  Calculate
                </a>
                <a
                  href="#"
                  className="btn btn-large btn-grey"
                  style={{ marginTop: 0, height: "unset" }}
                >
                  Delete
                </a>
              </div>
            </div>
            <div className="area-body__item space-between">
              <div>
                <span>Provident Fund</span>
              </div>
              <div>
                <span>Not yet calculated</span>
              </div>
              <div className="buttons">
                <a href="#" className="btn btn-large btn-blue">
                  Calculate
                </a>
                <a
                  href="#"
                  className="btn btn-large btn-grey"
                  style={{ marginTop: 0, height: "unset" }}
                >
                  Delete
                </a>
              </div>
            </div>
            <div className="area-body__item space-between">
              <div>
                <span>Provident Fund</span>
              </div>
              <div>
                <span>Not yet calculated</span>
              </div>
              <div className="buttons">
                <a href="#" className="btn btn-large btn-blue">
                  Calculate
                </a>
                <a
                  href="#"
                  className="btn btn-large btn-grey"
                  style={{ marginTop: 0, height: "unset" }}
                >
                  Delete
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="arrear-block">
          <h4 className="arrear-title">
            <span>Step 3 </span>
            Amounts and Adjustment
          </h4>
          <div className="arrear-tabs">
            <div className="tax-adj-tabs mt-sm payroll-tax">
              <Sidetab
                currentStep={currentStep}
                onStepChange={(step) => this.changeSideTab(step)}
              />
            </div>
            <div label="Basic Salary" name="Basic Salary" active>
              <Table />
            </div>
            {/* <Tabs>
                <div label="Total Arrear" name="Total Arrear">
                  <span>Total Arrear</span>
                </div>
                <div label="Income A" name="Income A">
                  <span>Income A</span>
                </div>
                <div label="Another Income" name="Another Income">
                  <span>Another Income</span>
                </div>
              </Tabs> */}
          </div>
        </div>
        <div className="bottom-bar-buttons">
          <div className="buttons">
            <a href="#" className="btn btn-large btn-grey">
              Delete
            </a>
            <a
              href="#"
              className="btn btn-large btn-blue"
              style={{ marginTop: 0, height: "unset" }}
            >
              Send For Approval
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeListForAdmin: state.admin_EmployeeListReducer.employeeListForAdmin,
  branches: state.commonReducer.branchList,
  departments: state.commonReducer.departmentList,
  designations: state.commonReducer.designationList,
  statusList: state.commonReducer.jobStatusList,
});

const mapDispatchToProps = {
  GetEmployeeListForAdmin,
  GetBranches,
  GetDepartments,
  GetDesignations,
  GetJobStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(Arrear_Calculation);
