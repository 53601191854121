import React from "react";
import AgGridTable from "components/table/agGridTable";
import Button from "components/button";
import Icons from "components/icons";

const columnDefs = [
  { field: "TemplateName", headerName: "Template Name", width: 400 },
  { field: "StageName", headerName: "Stage", width: 220 },
  { field: "", cellRenderer: "actionCell", width: 100 },
];

export default function Table(props: any) {
  const actionCell = (rowProps) => {
    return (
      <div className="flex">
        <span
          onClick={() => props.onEdit(rowProps.data)}
          style={{ cursor: "pointer" }}
        >
          <Icons name="Edit" color="grey" />
        </span>
        <span
          onClick={() => props.onDelete(rowProps.data)}
          style={{ marginLeft: "30px", cursor: "pointer" }}
        >
          <Icons name="Delete" color="gray" />
        </span>

        {/* <Button
          title=""
          bg="secondary"
          buttonType="icon-button"
          leftIcon={<Icons name="Edit" />}
          onClick={() => props.onEdit(rowProps.data)}
        />
        <Button
          title=""
          bg="secondary"
          buttonType="icon-button"
          leftIcon={<Icons name="Delete" />}
          onClick={() => props.onDelete(rowProps.data)}
        /> */}
      </div>
    );
  };

  const frameworkComponents = {
    actionCell,
  };

  return (
    <AgGridTable
      columnDefs={columnDefs}
      rowData={props.data}
      frameworkComponents={frameworkComponents}
      // page={{ pageIndex: props.pageIndex, rowsPerPage: props.rowsPerPage }}
      // handlePagination={props.handlePagination}
    />
  );
}
