import types from 'constants/types';

const initialState = {
    courseCategories:[],
    courseList:[],
    courseEnrollements:[],
    courseNavigationPolicies:[],
    courseShowTo:[],
    courseOption:{},
    course:{},
    coursePages:[],
    coursePage:null,
    courseContent:[],
    assignCourses:[],
    branchesTable:[],
    answers:[],
    departmentTable:[],
    courseDashboard:[],
    questionDetails:[],
    assignedCourseDashboard:[],
    courseCompleted:null,
}

export default function (state: any = initialState, action: any) {
    switch (action.type) {
        case types.GET_ASSIGNED_COURSE_DASHBOARD: {
            return{
                ...state,
                assignedCourseDashboard:action.payload
            }
        }
        case types.GET_BRANCHES_DETAILS:{
            return{
                ...state,
                branchesTable:action.payload
            }
        }
        case types.GET_COURSE_QUESTION_DETAILS:{
            return{
                ...state,
                questionDetails:action.payload
            }
        }
        case types.ADD_COURSE_QUESTION_DETAILS:{
            return{
                ...state,
                questionDetails:[...state.questionDetails,...action.payload,]
            }
        }
        case types.ADD_ANSWER:{
            return {
                ...state,
                answers:[action.payload,...state.answers]
            }
        }
        case types.GET_COURSE_COMPLETED:{
            return{
                ...state,
                courseCompleted:action.payload
            }
        }
        case types.ADD_COURSE_COMPLETED:{
            return{
                ...state,
                courseCompleted:state.courseCompleted ? [...state.courseCompleted, action.payload] : [action.payload]
            }
        }
        case types.GET_ANSWERS:{
            return {
                ...state,
                answers:action.payload
            }
        }
        case types.DELETE_COURSE_QUESTION_DETAILS:{
            return{
                ...state,
                questionDetails:state.questionDetails.filter(item=>item.Id !== action.payload)
            }
        }
        case types.GET_DEPARTMENT_DETAILS:{
            return{
                ...state,
                departmentTable:action.payload
            }
        }
        case types.GET_COURSE_DASHBOARD: {
            return{
                ...state,
                courseDashboard:action.payload
            }
        }
        case types.GET_COURSE_ENROLLMENT: {
            return{
                ...state,
                courseEnrollements:action.payload
            }
        }
        case types.GET_COURSE_COURSE_NAVIGATION_POLICY:{
            return{
                ...state,
                courseNavigationPolicies:action.payload
            }
        }
        case types.GET_COURSE:{
            const data = Array.isArray(action.payload) ? action.payload[0] : action.payload
            return{
                ...state,
                course:data
            }
        }
        case types.EDIT_COURSE_STATUS:{
            return{
                ...state,
                courseList:state.courseList && state.courseList.map(item=>{
                    if(item.Id === action.courseId){
                        return {
                            ...item,
                            status:action.status
                        }
                    }
                    return item
                })
            }
        }
        case types.GET_COURSE_OPTIONS:{
            return{
                ...state,
                courseOption:action.payload
            }
        }
        case types.GET_SHOW_COURSE_TO: {
            return{
                ...state,
                courseShowTo:action.payload
            }
        }
        case types.GET_COURSE_CATEGORIES: {
            return{
                ...state,
                courseCategories:action.payload
            }
        }
        case types.GET_COURSE_LIST: {
            return{
                ...state,
                courseList:action.payload
            }
        }
        case types.GET_COURSE_PAGES: {
            return{
                ...state,
                coursePages:action.payload
            }
        }
        case types.GET_COURSE_PAGE: {
            return{
                ...state,
                coursePage:action.payload
            }
        }
        case types.ADD_COURSE_PAGE: {
            return{
                ...state,
                coursePages:[action.payload,...state.coursePages]
            }
        }
        case types.Update_COURSE: {
            return{
                ...state,
                course:action.payload
            }
        }
        case types.ASSIGN_COURSES:{
            return{
                ...state,
                assignCourses:action.payload
            }
        }
        case types.UPDATE_COURSE_PAGE: {
            return{
                ...state,
                coursePages:state.coursePages.map(item=>{
                    if(item.Id === action.payload.Id){
                        return action.payload
                    }
                    return item 
                }),
                coursePage:action.payload,
            }
        }
        case types.DELETE_COURSE_PAGE: {
            return{
                ...state,
                coursePage:state.coursePages.filter(item=>item.Id !== action.id)
            }
        }
        case types.GET_COURSE_CONTENT: {
            return{
                ...state,
                courseContent:action.payload
            }
        }
        case types.ADD_COURSE_CONTENT: {
            return{
                ...state,
                courseContent:[...action.payload,...state.courseContent,]
            }
        }
        case types.GET_ASSIGNED_COURSE: {
            return{
                ...state,
                assignCourses:action.payload
            }
        }
        case types.UPDATE_COURSE_CONTENT: {
            return{
                ...state,
                courseContent:state.courseContent.map(item=>{
                    const newItem = action.payload.find(pay=>item.Id === pay.Id)
                    if(newItem){
                        return newItem
                    }
                    return item;
                })
            }
        }
        case types.DELETE_COURSE_CONTENT: {
            return{
                ...state,
                courseContent:state.courseContent.filter(item=>item.Id !== action.payload)
            }
        }
        default:
      return state;
    }
}