import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ExportExcel from "components/excel/exportExcel";
import FormGroup from "components/form";
import DataSelect from "components/dataselect";
import isEmpty from "isEmpty";
import { GetOthersTravelRequests_ExcelExport } from "../common/travelInformation/actions";
import { GetSettlementRequest_ExcelExport } from "../common/travelSettlement/actions";
import Moment from "react-moment";
import { DateRange } from "react-date-range";
import moment from "moment";
import { FaRegCalendarAlt } from "react-icons/fa";
import classNames from "classnames";
import DatepickerAsPerSetting from "components/customdaterange/datepickerAsPerSetting";
import { Date_Format } from "helpers/dateFormat";
import { BS2AD, AD2BS } from "helpers/dateConvert";

class CommonFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeName: "",
      branches: [{ label: "one", value: 1 }],
      selectedBranch: null,
      selectedBranchId: -1,
      selectedTabId: !isEmpty(props.selectedTabId) ? props.selectedTabId : 0,

      isLoadingExport: false,
      travelRequest_ExcelColumns: [
        { label: "NAME", value: "name" },
        { label: "POSITION", value: "position" },
        { label: "PLACE OF TRAVEL", value: "placeOfTravel" },
        { label: "DATES", value: "dates" },
        { label: "STATUS", value: "status" },
        { label: "APPROVED BY", value: "approvedBy" },
      ],
      settlementRequest_ExcelColumns: [
        { label: "NAME", value: "name" },
        { label: "POSITION", value: "position" },
        { label: "PLACE OF TRAVEL", value: "placeOfTravel" },
        { label: "DATES", value: "dates" },
        { label: "RETURNED ON", value: "returnedOn" },
        { label: "ADVANCE", value: "advance" },
        { label: "CLAIM", value: "claim" },
        { label: "APPROVED BY", value: "approvedBy" },
      ],
      searchFlowSteps: [],
      searchFlowStepsString: "",
      fromDate: "",
      toDate: "",
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.setDateRangeRef = this.setDateRangeRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    const { selectedTabId } = this.state;
    if (selectedTabId === 2) {
      var date = new Date();
      date.setDate(date.getDate() - 7);
      let range = {
        startDate: date,
        endDate: new Date(),
      };
      let startDate = moment(range.startDate, "YYYYMMDDHHmmss")
        .startOf("day")
        .format("YYYY/MM/DD HH:mm:ss");
      let endDate = moment(range.endDate, "YYYYMMDDHHmmss")
        .endOf("day")
        .format("YYYY/MM/DD HH:mm:ss");
      this.setState({ fromDate: startDate, toDate: endDate });
    }
  }
  setWrapperRef(node) {
    this.wrapperRef = node;
  }
  setDateRangeRef(node) {
    this.dateRangeRef = node;
  }
  handleText = (name, value) => {
    //const { name, value } = e && e.target;
    this.setState({ [name]: value });
    this.props.handleSearchTextChange &&
      this.props.handleSearchTextChange(value);
  };

  handleDateClick = (event) => {
    if (
      this.wrapperRef.contains(event.target) &&
      !this.dateRangeRef.contains(event.target)
    )
      this.wrapperRef.classList.toggle("active");
  };
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.wrapperRef.classList.remove("active");
    }
  }
  handleDropdown = (name, selected) => {
    let branchId = -1;
    if (!isEmpty(selected)) {
      branchId = selected.value;
    }
    this.setState({ [name]: selected, selectedBranchId: branchId });
    this.props.handleBranchChange && this.props.handleBranchChange(branchId);
  };

  handleSelectChange = (name, selected) => {
    let selectedFlowSteps = "";
    selected.map((x, i) => {
      if (i === 0) selectedFlowSteps = x.value;
      else selectedFlowSteps += "," + x.value;
    });

    this.setState({
      searchFlowSteps: selected,
      searchFlowStepsString: selectedFlowSteps,
    });
    this.props.handleFilterChange(name, selectedFlowSteps);
  };
  handleExcelExport = async () => {
    this.setState({ isLoadingExport: true });
    const { selectedTabId, fromDate, toDate } = this.state;
    const searchFlowSteps = this.state.searchFlowStepsString;
    const searchEmployeeText = this.state.employeeName;
    const branchId = this.state.selectedBranchId;
    if (selectedTabId === 0) {
      //Travel Request
      await this.props.GetOthersTravelRequests_ExcelExport({
        searchEmployeeText,
        branchId,
        searchFlowSteps,
        fromDate,
        toDate,
      });
    } else if (selectedTabId === 3) {
      //Settlement Request
      await this.props.GetSettlementRequest_ExcelExport({
        searchEmployeeText,
        branchId,
        searchFlowSteps,
        fromDate,
        toDate,
      });
    }
    this.setState({ isLoadingExport: false });
  };

  handleDateSelectForMultiple = (range) => {
    let isNepaliDate = !this.props.companyInformation.IsEnglishDate;
    this.setState({ fromDate: range.startDate, toDate: range.endDate });
    // let startDate = moment(new Date(range.startDate), "YYYYMMDDHHmmss")
    //   .startOf("day")
    //   .format("YYYY/MM/DD HH:mm:ss");
    // let endDate = moment(new Date(range.endDate), "YYYYMMDDHHmmss")
    //   .endOf("day")
    //   .format("YYYY/MM/DD HH:mm:ss");
    let startDate = range.startDate;
    let endDate = range.endDate;
    if (isNepaliDate) {
      startDate = startDate && BS2AD(startDate).formatedDate;
      endDate = endDate && BS2AD(endDate).formatedDate;
    }
    this.props.handleDateRangeChange({
      fromDate: startDate,
      toDate: endDate,
    });
  };

  render() {
    let {
      employeeName,
      selectedBranch,
      selectedTabId,
      searchFlowSteps,
      isLoadingExport,
      travelRequest_ExcelColumns,
      settlementRequest_ExcelColumns,
      fromDate,
      toDate,
    } = this.state;
    const {
      otherTravelRequestExportExcel,
      settlementRequestExportExcel,
      flowSteps,
    } = this.props;

    let export_name = null;
    let export_columns = [];
    let export_data = null;

    //if (isLoadingExport) {
    if (selectedTabId === 0 && !isEmpty(otherTravelRequestExportExcel)) {
      export_name = "Travel Requests";
      export_columns = travelRequest_ExcelColumns;
      export_data = otherTravelRequestExportExcel;
    } else if (selectedTabId === 3 && !isEmpty(settlementRequestExportExcel)) {
      export_name = "Settlement Requests";
      export_columns = settlementRequest_ExcelColumns;
      export_data = settlementRequestExportExcel;
    }
    //}
    return (
      <div
        className="flex filter-top filters-input"
        style={{ alignItems: "flex-end" }}
      >
        <FormGroup
          name="employeeName"
          className="search"
          placeholder="Search Employee"
          type="search"
          value={employeeName}
          onChange={this.handleText}
          width="250px"
        />
        <DataSelect
          onChange={this.handleDropdown}
          value={selectedBranch}
          // label="Branch"
          name="selectedBranch"
          // multiple
          placeholder="Filter branches"
          actionType="branch"
          width="200px"
        />
        <FormGroup
          formName="reactselect"
          name="searchFlowSteps"
          placeholder="Filter flow steps"
          value={searchFlowSteps}
          options={flowSteps}
          onChange={this.handleSelectChange}
          width={250}
          multiple={true}
        />
        <div>
          <DatepickerAsPerSetting
            formName="customdateinput"
            isDateRange
            onChange={(name, range) => this.handleDateSelectForMultiple(range)}
            value={{
              startDate: fromDate,
              endDate: toDate,
            }}
            width="250px"
            placeholder="Select date"
          />

          {/* <FormGroup
            formName="reactdaterange"
            startDate={!isEmpty(fromDate) ? fromDate : new Date()}
            endDate={!isEmpty(toDate) ? toDate : new Date()}
            onChange={(name, range) => this.handleDateSelectForMultiple(range)}
            placeholder="Select date"
          /> */}
        </div>
        {/* <div
          className="daterangepicker"
          tabIndex="0"
          ref={this.setWrapperRef}
          onClick={(event) => this.handleDateClick(event)}
        >
          <span className="daterangepicker__date">
            {!isEmpty(fromDate && toDate) ? (
              <>
                <span>
                  <Moment format="D MMM YYYY" withTitle>
                    {fromDate}
                  </Moment>
                </span>
                -
                <span>
                  <Moment format="D MMM YYYY" withTitle>
                    {toDate}
                  </Moment>
                </span>
              </>
            ) : null}
          </span>
          <div className="daterangepicker__range" ref={this.setDateRangeRef}>
            <DateRange
              startDate={moment(Date())}
              endDate={moment(Date())}
              // onInit={range =>
              //   //this.handleDateSelectForMultiple(range, key)

              // }
              onChange={(range) => this.handleDateSelectForMultiple(range)}
            />
          </div>

          <span className="daterangepicker__icon">
            <FaRegCalendarAlt />
          </span>
        </div> */}

        {(selectedTabId === 0 || selectedTabId === 3) && (
          <ExportExcel
            onClick={this.handleExcelExport}
            fileName={export_name}
            columns={export_columns}
            sheets={[{ sheetName: export_name, data: export_data }]}
            className="button button-subtle"
          />
          // <button
          //   disabled={isLoadingExport}
          //   onClick={() => this.handleExcelExport()}
          //   style={{ height: 35, padding: 10, color: "#fff" }}
          //   className={classNames({
          //     "ml-sm": true,

          //     "btn-disable btn-rounded": isLoadingExport,
          //     "btn-rounded primary": !isLoadingExport,
          //   })}
          // >
          //   {isLoadingExport ? "Waiting.." : "Export Excel"}
          // </button>
        )}
        {/* {export_data && (
          <ExportExcel
            name={export_name}
            columns={export_columns}
            data={export_data}
          />
        )} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  totalOtherTravelRequest: state.travelInformationReducer.totalTravelRequests,
  totalMyTravelRequests: state.travelInformationReducer.totalMyTravelRequests,
  travelListCount: state.travelInformationReducer.travelListCount,
  otherTravelRequestExportExcel:
    state.travelInformationReducer.otherTravelRequestExportExcel,
  settlementRequestExportExcel:
    state.travelSettlementReducer.settlementRequestExportExcel,
  flowSteps: state.travelInformationReducer.flowSteps,
  companyInformation: state.commonReducer.companyInformation,
});

const mapDispatchToProps = {
  GetOthersTravelRequests_ExcelExport,
  GetSettlementRequest_ExcelExport,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CommonFilters)
);
