import React from 'react'
import EmployeeInfo from './employeeInfo';
import AddressDetail from './addressDetail';
import DocumentsDetail from './documentsDetail';
import EducationDetail from './educationDetail';
import HealthRecord from './healthRecord';
import ServiceHistory from './serviceHistory';
import './styles.scss';

const index = () =>{
    return(
        <>
            <ServiceHistory />
            <EmployeeInfo />
            <AddressDetail />
            <EducationDetail />
            <DocumentsDetail />
            <HealthRecord />
        </>
    )
}

export default index