import moment from "moment";
import isEmpty from "isEmpty";
import { getSettingWiseDate } from "helpers/getSettingWiseDate";
import { BS2AD, AD2BS } from "helpers/dateConvert";
import store from "src/store/configureStore";
import { Date_Format, Nepali_Date_Format } from "helpers/dateFormat";

// export const getSettingWiseDate = (date) => {
//   if (date) {
//     //console.log("state", store.getState());
//     const { commonReducer } = store.getState();

//     const isNepaliDate = !commonReducer.companyInformation.IsEnglishDate;
//     if (isNepaliDate) {
//       let d: any = Nepali_Date_Format(date);
//       d = AD2BS(d).en;
//       return `${d.year}/${d.month}/${d.day}`;
//     } else {
//       return Date_Format(date);
//     }
//   } else {
//     return null;
//   }
// };

export const getTravelInformationData = (localState, props) => {
  const { commonReducer } = store.getState();
  const isNepaliDate = !commonReducer.companyInformation.IsEnglishDate;
  let {
    travelRequestDetails,
    countryList,
    currencyList,
    districtList,
    modeOfTransportList,
    travelHostList,
    travelHotelList,
  } = props;

  let { travelInformationState } = localState;

  let TravelInformationDtoToEdit = travelRequestDetails.value;
  let TravelInformationDestinationDtoToEdit =
    TravelInformationDtoToEdit.TravelSettlementDestinationsDto;

  //Travel Information Tab starts
  let selectedCountry = countryList.find(
    (f) => f.value == TravelInformationDtoToEdit.CountryId
  );
  let selectedCurrency = currencyList.find(
    (f) => f.label == TravelInformationDtoToEdit.CurrencyCode
  );

  let newSelectedTravelDestList = [];
  TravelInformationDestinationDtoToEdit &&
    TravelInformationDestinationDtoToEdit.map((destination, index) => {
      let selectedDistrict = districtList.find(
        (f) => f.value == destination.DistrictRefId
        // f => f.value == 4
      );

      let TransportModeIdList = destination.TransportModeRefId.split(",");
      // let TransportModeIdList = ("1,2").split(",");
      let selectedModeOfTransport = [];

      if (modeOfTransportList && modeOfTransportList.length > 0) {
        TransportModeIdList.map((tm) => {
          let currentModeOfTransport = modeOfTransportList.find(
            (f) => f.value == tm
          );
          if (!isEmpty(currentModeOfTransport)) {
            selectedModeOfTransport.push(currentModeOfTransport);
          }
        });
      }
      let selectedHost = travelHostList.find(
        (f) => f.label == destination.HostName
      );

      let selectedHotel = travelHotelList.find(
        (f) => f.label == destination.HotelName
      );

      let startDate = Nepali_Date_Format(destination.StartDateEng);
      if (destination.StartDateEng && isNepaliDate) {
        try {
          let conversionDate = AD2BS(startDate).en;
          startDate = `${conversionDate.year}/${conversionDate.month}/${conversionDate.day}`;
        } catch (e) {}
      }

      let endDate = Nepali_Date_Format(destination.EndDateEng);
      if (destination.EndDateEng && isNepaliDate) {
        try {
          let conversionDate = AD2BS(endDate).en;
          endDate = `${conversionDate.year}/${conversionDate.month}/${conversionDate.day}`;
        } catch (e) {}
      }

      let newTravelDestination = {
        Id: destination.Id,
        selectedDistrict: !isEmpty(selectedDistrict) ? selectedDistrict : [],
        placeName: destination.PlaceName,
        selectedModeOfTransport: selectedModeOfTransport,
        startDate: destination.StartDate,
        endDate: destination.EndDate,
        startDateEng: startDate,
        endDateEng: endDate,
        days: destination.TravelDays,
        nights: destination.TravelNights,
        hostName: destination.HostName,
        selectedHost: !isEmpty(selectedHost) ? selectedHost : [],
        hotelName: destination.HotelName,
        selectedHotel: !isEmpty(selectedHotel) ? selectedHotel : [],
        phoneNumber: destination.PhoneNo,
      };

      newSelectedTravelDestList.push({
        key: index,
        travelDestination: newTravelDestination,
      });
    });

  //this.getOverAllDates(newSelectedTravelDestList);

  travelInformationState["travelRequestId"] =
    TravelInformationDtoToEdit.RequestId;
  travelInformationState["RequestedOn"] =
    TravelInformationDtoToEdit.RequestedOn;
  travelInformationState["RequestedOnEng"] =
    TravelInformationDtoToEdit.RequestedOnEng;
  travelInformationState["travelTypeId"] =
    TravelInformationDtoToEdit.TravelType;
  travelInformationState["destinationTypeId"] = !isEmpty(
    TravelInformationDtoToEdit.DestinationType
  )
    ? TravelInformationDtoToEdit.DestinationType
    : TravelInformationDtoToEdit.Destination;
  travelInformationState["selectedCountry"] = !isEmpty(selectedCountry)
    ? selectedCountry
    : [];
  travelInformationState["selectedCurrency"] = !isEmpty(selectedCurrency)
    ? selectedCurrency
    : [];
  travelInformationState["reason"] = TravelInformationDtoToEdit.Reason;
  travelInformationState["selectedTravelDestList"] = newSelectedTravelDestList;

  //Travel Information Tab ends
  return { travelInformationState, newSelectedTravelDestList };
};
