import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {CustomTable} from "components/table";
import {MdModeEdit, MdDoNotDisturb} from "react-icons/md";
import {AiOutlineDelete} from "react-icons/ai";

const data = [
  {
    name: "Region Administrator",
    created: {time: "January 1, 2020 3:30 pm", by: "Anuj Khatri"},
    modified: {time: "January 1, 2020 3:45 pm", by: "Mina Kumari"},
  },
  {
    name: "HR Administrator",
    created: {time: "January 1, 2020 3:30 pm", by: "Anuj Khatri"},
    modified: {time: "January 1, 2020 3:45 pm", by: "Mina Kumari"},
  },
  {
    name: "Leave Administrator",
    created: {time: "January 1, 2020 3:30 pm", by: "Anuj Khatri"},
    modified: {time: "January 1, 2020 3:45 pm", by: "Mina Kumari"},
  },
];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    border: "1px solid #E1E1E1",
  },
  name: {
    color: "#0052AB",
    letterSpacing: 0,
    fontSize: 14,
    fontWeight: 500,
  },
  time: {
    color: "#303130",
    marginRight: 10,
    fontSize: 14,
    letterSpacing: 0,
    fontWeight: 500,
  },
  by: {
    color: "#303130",
    fontSize: 14,
    letterSpacing: 0,
    fontWeight: 500,
  },
  buttonGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});
const renderRow = (row) => {
  // const classes = useStyles();
  return (
    <tr key={row.name}>
      <td component="th" scope="row">
        <span

        // className={classes.name}
        >
          {row.name}
        </span>
      </td>
      <td>
        <span
        // className={classes.time}
        >
          {row.created.time}
        </span>
        <span
        // className={classes.by}
        >
          {row.created.by}
        </span>
      </td>
      <td>
        <span
        // className={classes.time}
        >
          {row.modified.time}
        </span>
        <span
        // className={classes.by}
        >
          {row.modified.by}
        </span>
      </td>
      <td className="buttoncolumn">
        <div
          className="buttonwrapper"
          // className={classes.buttonGroup}
        >
          <button className="button button-primary-light with-icon">
            <MdModeEdit />
            <span>Edit</span>
          </button>
          <button className="button button-primary-light with-icon">
            <MdDoNotDisturb />
            <span>Disable</span>
          </button>
          <button className="button button-primary-light with-icon">
            <AiOutlineDelete />
            <span>Delete</span>
          </button>
        </div>
      </td>
    </tr>
  );
};

function ActiveTable() {
  const classes = useStyles();
  return (
    <div>
      <CustomTable
        headings={["Role Name", "Created", "Last Modified", ""]}
        data={data}
        template={renderRow}
        className={classes.table}
        aria-label="Active Table"
      />
    </div>
  );
}

export default ActiveTable;
