import { DisplayClientSideMessage } from "actions/commonAction";
import Modal from "components/modal";
import isEmpty from "isEmpty";
import * as React from "react";
import { connect } from "react-redux";
import EmployeeDetails from "scenes/common/employeeDetails";
import { getEmployeeLeaveList } from "scenes/employee/employeeProfile/action";
import { GetAuditLogOfRequests } from "scenes/employee/requests/actions";
import * as employeeRequestService from "services/employeeRequests";
import { GetTeamMemberLeaveDetails } from "../leaveRequest/actions";
import EmployeeOnLeave from "./employeeOnLeave";
import "./leaveDetails.scss";
import MyLeaveBalance from "./myLeaveBalance";
import MyLeaveRequest from "./myRequest";
import Loaders from "components/loaders";
import CardSkeleton from "./cardSkeleton";
import classNames from "classnames";
import { EMPLOYEE_LEAVE_REQUEST_MODULE_VALUE } from "constants/constants";
import AuditHistory from "components/common/auditHistory";

export interface IAppProps {
  leaveRequestDetails: any;
  closeDetailsModal: Function;
  getEmployeeLeaveList: Function;
  employeeLeaveBalance: any;
  teamMembersLeaveDetails: any[];
  GetTeamMemberLeaveDetails: Function;
  isViewMode?: boolean;
  DisplayClientSideMessage: Function;
  refreshRequestData: Function;
  requestData: any[];
  page?: string;
  GetAuditLogOfRequests: Function;
  auditLogData: any;
}

interface State {
  comment: string;
  errors: any;
  leaveRequestId: number;
  employeeDetail: any;
  empRequest: any;
  comments: any[];
  leaveBalance: any[];
  empOnLeave: any[];
  division: string;
  isTeamMemberDetailsLoading: boolean;
  isMyLeaveBalanceLoading: boolean;
  attachments: any;
}

class App extends React.Component<IAppProps, State> {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",
      errors: {},
      leaveRequestId: 0,
      employeeDetail: {},
      empRequest: {},
      comments: [],
      leaveBalance: [],
      empOnLeave: [],
      division: "dept",
      isTeamMemberDetailsLoading: true,
      isMyLeaveBalanceLoading: true,
      attachments: [],
    };
  }
  async componentDidMount() {
    const { leaveRequestDetails } = this.props;

    if (!isEmpty(leaveRequestDetails)) {
      let leaveRequestId = leaveRequestDetails["RequestId"];
      const { division } = this.state;
      this.setState({ leaveRequestId });
      this.initialize();
      if (this.props.page !== "myHistory") {
        await this.props.getEmployeeLeaveList(
          leaveRequestDetails["EmployeeId"]
        );
        await this.props.GetTeamMemberLeaveDetails({
          leaveRequestId,
          division,
        });
      }

      await this.props.GetAuditLogOfRequests({
        requestId: leaveRequestId,
        moduleId: EMPLOYEE_LEAVE_REQUEST_MODULE_VALUE,
      });
    }
  }
  async componentDidUpdate(prevProps, prevState) {
    const {
      employeeLeaveBalance,
      teamMembersLeaveDetails,
      leaveRequestDetails,
      requestData,
    } = this.props;
    if (
      prevProps.employeeLeaveBalance !== employeeLeaveBalance ||
      prevProps.teamMembersLeaveDetails !== teamMembersLeaveDetails ||
      prevProps.requestData !== requestData
    ) {
      let { leaveBalance, empOnLeave } = this.state;
      if (prevProps.employeeLeaveBalance !== employeeLeaveBalance) {
        leaveBalance = employeeLeaveBalance?.LeaveTracking?.map((x) => {
          return {
            leaveType: x.Title,
            leaveRemaining:
              x.CurrentBalance === "-"
                ? x.CurrentBalance
                : parseInt(x.CurrentBalance),
            totalLeave: x.Balance === "-" ? x.Balance : parseInt(x.Balance),
          };
        });
        this.setState({ leaveBalance, isMyLeaveBalanceLoading: false });
      }
      if (prevProps.teamMembersLeaveDetails !== teamMembersLeaveDetails) {
        empOnLeave = teamMembersLeaveDetails.map((x) => {
          return {
            Imagepath: x.EmployeeImage,
            FullName: x.EmployeeName,
            Designation: x.Designation,
            LeaveType: x.LeaveType,
            LeaveDate: x.FromDate + " - " + x.ToDate,
            Status: x.Status,
            RequestedDate: x.RequestDate,
          };
        });
        this.setState({ isTeamMemberDetailsLoading: false, empOnLeave });
      }
      if (prevProps.requestData !== requestData) {
        this.initialize();
      }
    }
  }

  initialize = () => {
    let { requestData } = this.props;
    let leaveRequestDetails = requestData?.find(
      (x) => x.RequestId === this.props.leaveRequestDetails.RequestId
    );
    let { employeeDetail, empRequest, comments, attachments } = this.state;
    if (!isEmpty(leaveRequestDetails)) {
      employeeDetail = {
        Id: leaveRequestDetails["EmployeeId"],
        FullName: leaveRequestDetails["Requester"],
        Imagepath: leaveRequestDetails["RequesterImage"],
        Designation: leaveRequestDetails["Designation"],
        Branch: leaveRequestDetails["Branch"],
        Department: leaveRequestDetails["Department"],
      };
      empRequest = {
        requestType: leaveRequestDetails["LeaveName"],
        requestPeriod:
          leaveRequestDetails["FromDate"] +
          " - " +
          leaveRequestDetails["ToDate"],
        LeaveDayType: !isEmpty(leaveRequestDetails["LeaveDayType"]) ? ` (${leaveRequestDetails["LeaveDayType"]})` : "",
        requestDays:
          leaveRequestDetails["AppliedDays"] +
          (leaveRequestDetails["AppliedDays"] > 1 ? " days" : " day"),
        requestedOn: leaveRequestDetails["RequestedOn"],
        reason: leaveRequestDetails["Reason"],
        substitute: !isEmpty(leaveRequestDetails["SubstituteEmployee"])
          ? {
              Imagepath: leaveRequestDetails["SubstituteEmployeeImage"],
              FullName: leaveRequestDetails["SubstituteEmployee"],
            }
          : {},
        approvers: [
          {
            Imagepath: leaveRequestDetails["RecommendedImage"],
            FullName: leaveRequestDetails["Reccommender"],
            ApprovalStatus: leaveRequestDetails["RecommendedStatus"],
          },
          {
            Imagepath: leaveRequestDetails["ApprovalImage"],
            FullName: leaveRequestDetails["Approval"],
            ApprovalStatus: leaveRequestDetails["ApprovalStatus"],
          },
        ],
      };
      comments = leaveRequestDetails["IsCommented"]
        ? leaveRequestDetails["Comments"]?.map((x) => {
            return {
              commenter: x.CommentedBy,
              commentDate: x.CommentedDate,
              comment: x.Comments,
              commenterId: x.CommenterId,
            };
          })
        : [];
      attachments = leaveRequestDetails["Attachments"];
    }
    this.setState({ employeeDetail, empRequest, comments, attachments });
  };
  saveComment = async (comment) => {
    const { leaveRequestId } = this.state;
    const { leaveRequestDetails } = this.props;
    let requestData = {
      requestId: leaveRequestId,
      requestType: leaveRequestDetails["RequestType"],
      comment,
    };
    let result = await employeeRequestService.saveReviewRequestComment(
      requestData
    );
    // if (result.Status) {
    //   await this.props.refreshRequestData();
    //   //sthis.props.closeDetailsModal();
    // }
    this.props.DisplayClientSideMessage(result);
    //this.setState({ comment });
    return result.Status;
  };
  handleApproveRequest = async () => {
    // const { comment } = this.state;
    // let errors = {
    //   comment: "",
    // };
    // if (isEmpty(comment)) errors.comment = "Required";
    // this.setState({ errors });
    const { leaveRequestId, comment } = this.state;
    const { leaveRequestDetails } = this.props;
    let requestData = {
      requestId: leaveRequestId,
      requestType: leaveRequestDetails["RequestType"],
      //reason: comment,
      reason: "",
    };
    let result = await employeeRequestService.approveReviewRequests(
      requestData
    );
    if (result.Status) {
      await this.props.refreshRequestData();
      this.props.closeDetailsModal();
    }
    this.props.DisplayClientSideMessage(result);
  };
  handleRejectRequest = async () => {
    // const { comment } = this.state;
    // let errors = {
    //   comment: "",
    // };
    // if (isEmpty(comment)) {
    //   errors.comment = "Required";
    //   this.setState({ errors });
    // } else {
    const { leaveRequestId, comment } = this.state;
    const { leaveRequestDetails } = this.props;
    let requestData = {
      requestId: leaveRequestId,
      requestType: leaveRequestDetails["RequestType"],
      //reason: comment,
      reason: "",
    };
    let result = await employeeRequestService.rejectReviewRequests(requestData);
    if (result.Status) {
      await this.props.refreshRequestData();
      this.props.closeDetailsModal();
    }
    this.props.DisplayClientSideMessage(result);
    // }
  };

  handleDivisionChange = async (division) => {
    const { leaveRequestId } = this.state;
    this.setState({ division, isTeamMemberDetailsLoading: true });
    await this.props.GetTeamMemberLeaveDetails({ leaveRequestId, division });
    this.setState({ isTeamMemberDetailsLoading: false });
  };
  public render() {
    const {
      errors,
      leaveRequestId,
      employeeDetail,
      empRequest,
      comments,
      leaveBalance,
      empOnLeave,
      division,
      isTeamMemberDetailsLoading,
      isMyLeaveBalanceLoading,
      attachments,
    } = this.state;
    const { leaveRequestDetails, isViewMode, page, auditLogData } = this.props;
    const logData = [
      {
        EmployeeId: 0,
        ChangedBy: "string",
        ModifiedOn: "string",
        Type: "Reason",
        BeforeValue: "dsdsdssdsd",
        AfterValue: "string",
      },
      {
        EmployeeId: 0,
        ChangedBy: "string",
        ModifiedOn: "string",
        Type: "Reason",
        BeforeValue: "dsdsdssdsd",
        AfterValue: "string",
      },
    ];
    const smallModal =
      (page === "myHistory" || page === "myRequest") &&
      isEmpty(auditLogData.value);
    return (
      <Modal
        title=""
        open={true}
        onModalClose={() => this.props.closeDetailsModal()}
        type=""
        className={classNames({
          "modal-leave-details": true,
          "sm-modal": smallModal,
        })}
      >
        <div className="leave-details">
          <div className="leave-details__header flex">
            <EmployeeDetails empDetails={employeeDetail} size={60} />
            {/* <IoIosCloseCircleOutline className="close-icon" /> */}
          </div>
          <div className="leave-details__body flex items-start justify-between">
            <div
              className={classNames({
                "my-leave-details flex-column wd-50": true,
                "wd-100": smallModal,
              })}
            >
              <MyLeaveRequest
                leaveRequestId={leaveRequestId}
                empRequest={empRequest}
                comments={comments}
                empDetails={employeeDetail}
                errors={errors}
                saveComment={this.saveComment}
                handleApproveRequest={this.handleApproveRequest}
                isViewMode={isViewMode}
                handleRejectRequest={this.handleRejectRequest}
                refreshRequestData={this.props.refreshRequestData}
                attachments={attachments}
              />

              {(page === "requestReview" || page === "teamHistory") &&
                (isMyLeaveBalanceLoading ? (
                  <Loaders
                    loading={isMyLeaveBalanceLoading}
                    type="skeleton"
                    backgroundColor="#bbb"
                    foregroundColor="#dedede"
                    name="card"
                    cardType="block"
                  />
                ) : (
                  <MyLeaveBalance leaveBalance={leaveBalance} />
                ))}
            </div>

            <div className="flex-column wd-50 overflow-hidden right-col">
              <AuditHistory
                auditLogData={auditLogData.value}
                loadingType="skeleton"
                loading={auditLogData.loading}
              />
              {(page === "requestReview" || page === "teamHistory") &&
                (isTeamMemberDetailsLoading ? (
                  // <Loaders
                  //   loading={isLoading}
                  //   type="skeleton"
                  //   backgroundColor="#bbb"
                  //   foregroundColor="#dedede"
                  //   name="card"
                  // />
                  <div style={{ marginTop: "-1.2rem" }}>
                    <CardSkeleton
                      backgroundColor="#bbb"
                      foregroundColor="#dedede"
                    />
                  </div>
                ) : (
                  <EmployeeOnLeave
                    employeeOnLeave={empOnLeave}
                    handleDivisionChange={this.handleDivisionChange}
                    division={division}
                  />
                ))}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeLeaveBalance: state.employeeProfile.employeeLeaveList,
  teamMembersLeaveDetails:
    state.employeeLeaveRequestReducer.teamMembersLeaveDetails,
  auditLogData: state.employeeRequestReducer.auditLogData,
});

const mapDispatchToProps = {
  getEmployeeLeaveList,
  GetTeamMemberLeaveDetails,
  DisplayClientSideMessage,
  GetAuditLogOfRequests,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
