import React, { useState, useRef, useEffect } from "react";
import classnames from "classnames";
import { IoIosAdd, IoIosArrowDropright } from "react-icons/io";
import { MdMessage } from "react-icons/md";
import { connect } from 'react-redux';
import man from "assets/images/man_small.png";
import {getUploadPath} from 'constants/userDetails'

import Avatar from 'react-avatar'
function useOutsideAlerter(ref, setDrop) {
    /**
     * Alert if clicked on outside of element
     */

    function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
        setDrop(false);
    }
    }

    useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
    };
    });
}
function ProfileInfos(props) {
    
    const [drop, setDrop] = useState(false);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, setDrop);
    
        const travelClasses = (flag) => {
            return classnames({
            badges: true,
            // "badges-rounded": true,
            "badges-pink": flag == "pink" ? true : false,
            "badges-grey-dark": flag == "grey-dark" ? true : false,
            "badges-grey-light": flag == "grey-light" ? true : false,
            "badges-blue": flag == "blue" ? true : false,
            "badges-purple": flag == "purple" ? true : false,
            "badges-yellow": flag == "yellow" ? true : false,
            "badges-alternate": flag == "alternate" ? true : false,
            "badges-leave": flag == "leave" ? true : false,
            "badges-message": flag == "message" ? true : false,
            "mb-10": flag == "leave" ? true : false,
            "addButton" : flag == "add" ? true : false,
            });
        };
        let { profileInfo:
            { 
                EmployeeName,
                Designation,
                Branch,
                Department,
                EmployeeStatus,
                IdCard,
                Age,
                UrlPhoto,
                Gender,
                ServicePeriod,
                JoiningDate,
                FromDateNep,
                Subordinate,
                OfficeEmail,
                OfficePhone,
                PersonalPhone,
                PersonalMobile,
                PersonalEmail,
                District,
                PEVDCMuncipality,
                CountryName,
                PELocality,
                PEHouseNo,
                PEStreet,
                EmergencyRelation,
                EmergencyName,
                EmergencyMobile,
                LeaveStatus,
            },
            baseUrl
        } = props;
        let urlImage = UrlPhoto ? getUploadPath(UrlPhoto) : Gender == 'Male' ? UrlPhoto = man : UrlPhoto = man;
        // if (UrlPhoto == null) Gender == 'Male' ? UrlPhoto = man : UrlPhoto = man;
        const renderLeaveStatus = (title) =>{
            if(!title){
                return null
            }
            switch(title){
                case 'Absent':
                    return <div className={travelClasses("grey-dark")}>{title}</div>
                case 'Present':
                    return <div className={travelClasses("blue")}>{title}</div>
                case 'Probation':
                    return <div className={travelClasses("grey-light")}>{title}</div>
                case 'Any Other':
                    return <div className={travelClasses("grey-light")}>{title}</div>
                case 'Temporary':
                    return <div className={travelClasses("pink")}>{title}</div>
                case 'Contract':
                    return <div className={travelClasses("yellow")}>{title}</div>
                case 'On Leave Today':
                    return <div className={travelClasses("purple")}>{title}</div>
                default :
                    return null
            }
        }
        return (
            <div className="profile-row">
                <div className="profile-column header-block">
                    <div className="profile-info">
                        <div className="profile-left">
                            <div className="profile-info__body profile-image">
                                {/* <img src={UrlPhoto} alt="profile pic"/> */}
                                    <Avatar
                                        name={EmployeeName}
                                        size={200}
                                        round={false}
                                        src={urlImage}
                                    />
                            </div>
                            <div className="profile-info__container">
                                <div className="profile-info__body">
                                    <div className="profile-info__title">
                                        <h2>{EmployeeName}</h2>
                                        <h4>{Designation}</h4>
                                        <span>{Branch}{Department && Branch ? ", " : ''}{Department}</span>
                                    </div>
                                    <div className="flex profile-secondary">
                                        <div className="profile-secondary__left">
                                            <div className="flex badges-group">
                                                {
                                                    EmployeeStatus && 
                                                    (
                                                        <div className={travelClasses("blue")}>
                                                            {EmployeeStatus}
                                                        </div>
                                                    )
                                                }
                                                 {
                                                    IdCard && 
                                                    (
                                                        <div className={travelClasses("grey-light")}>
                                                            {IdCard}
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div className="flex-column">
                                                <span><strong>Age </strong>{Age}</span>
                                                <span><strong>Service</strong>{ServicePeriod}</span>
                                                <span>Since {FromDateNep} ( {JoiningDate} )</span>
                                            </div>
                                        </div>
                                        <div className="flex-column profile-suboridinates">
                                            <h3>
                                                {Subordinate} 
                                                <span>Subordinates</span>
                                            </h3>
                                            <div className="profile-field">
                                                <div className="profile-field__label">
                                                    <span>Work</span>
                                                </div>
                                                <div className="profile-field__item">
                                                    <span>{OfficePhone}</span>
                                                </div>
                                                <div className="profile-field__item">
                                                    <span>{OfficeEmail}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="profile-info__body">
                                    <div className="profile-field">
                                        <div className="profile-field__label">
                                            <span>Home</span>
                                        </div>
                                        <div className="profile-field__item">
                                            <span>{PELocality} {PEVDCMuncipality}{District}</span>
                                        </div>
                                        <div className="profile-field__item">
                                            <span className="mb-16">{PersonalPhone}
                                                {PersonalMobile && PersonalPhone ? " ," : ""}
                                                {PersonalMobile}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="profile-field">
                                        <div className="profile-field__label">
                                            <span>Emergency</span>
                                        </div>
                                        <div className="profile-field__item">
                                            <span>
                                                {EmergencyRelation}
                                                {EmergencyRelation && EmergencyName ? ", " : ''}{EmergencyName}
                                                {EmergencyName && EmergencyMobile ? ", " : "" } {EmergencyMobile}
                                            </span>
                                        </div>
                                        <div className="profile-field__item">
                                            <a href="#">
                                                <span>
                                                    Emergency Health Information
                                                </span>
                                                <IoIosArrowDropright />
                                            </a> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profile-right">
                            <div className="profile-block__div">
                                <div className="badges-group">
                                    {
                                        renderLeaveStatus(LeaveStatus)
                                    }
                                    
                                    {/* <div className={travelClasses("message")}>
                                        <MdMessage /> &nbsp;
                                        Send Message
                                    </div> */}
                                </div>
                            </div>
                            <button 
                                onClick={()=>setDrop(!drop)}
                                ref={wrapperRef}
                                className="round-btn">
                                <IoIosAdd 
                            />
                                <div 
                                    className="request-dropdown" 
                                    >
                                    <ul className="request-dropdown-list">
                                    <li className="reques dropdown-list__item">
                                        <span>Some Other Request</span>
                                    </li>
                                    <li className="reques dropdown-list__item">
                                        <span>Other Request</span>
                                    </li>
                                    </ul>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

const mapStateToProps = state => ({
    profileInfo: state.employeeProfile.employeeProfile && state.employeeProfile.employeeProfile.EmployeeProfileHeader,
    // familyDetail: state.employeeDashboard.employeeDetail && state.employeeDashboard.employeeDetail.Family
});

export default connect(mapStateToProps, null)(ProfileInfos);