import React from "react";
import {ReactComponent as Brackets} from "assets/images/icons/brackets.svg";
import {ReactComponent as DueDate} from "assets/images/icons/due-date.svg";
import {ReactComponent as Priority} from "assets/images/icons/priority.svg";
import {ReactComponent as Status} from "assets/images/icons/status.svg";
import {ReactComponent as Team} from "assets/images/icons/team.svg";
import {ReactComponent as BookReader} from "assets/images/icons/book-reader.svg";
import {ReactComponent as EyeDropper} from "assets/images/icons/eye-dropper.svg";
import {ReactComponent as SimlePlus} from "assets/images/icons/smile-plus.svg";
import {ReactComponent as Simle} from "assets/images/icons/smile.svg";
import {ReactComponent as AngleRight} from "assets/images/icons/angle-right.svg";
import {ReactComponent as AngleLeft} from "assets/images/icons/angle-left.svg";
import {ReactComponent as ArrowDown} from "assets/images/icons/arrow-down.svg";
import {ReactComponent as ArrowUp} from "assets/images/icons/arrow-up.svg";
import {ReactComponent as EllipsisV} from "assets/images/icons/ellipsis-v.svg";
import {ReactComponent as ArrowCircleRight} from "assets/images/icons/arrow-circle-right.svg";
import {ReactComponent as Tasks} from "assets/images/icons/tasks.svg";
import {ReactComponent as Allowance} from "assets/images/icons/allowance.svg";
import {ReactComponent as Calculator} from "assets/images/icons/calculator-alt.svg";
import {ReactComponent as ApprovedCircle} from "assets/images/icons/approved-check-circle.svg";
import {ReactComponent as Leave} from "assets/images/icons/leave.svg";
import {ReactComponent as Home} from "assets/images/icons/home-heart.svg";
import {ReactComponent as Shift} from "assets/images/icons/calendar-check.svg";
import {ReactComponent as Loan} from "assets/images/icons/calendar-check.svg";
import {ReactComponent as Scroll} from "assets/images/icons/scroll.svg";
import {ReactComponent as Sliders} from "assets/images/icons/sliders-h.svg";
import {ReactComponent as Techo} from "assets/images/icons/tachometer-alt-fast.svg";
import {ReactComponent as User} from "assets/images/icons/user-tie.svg";
import {ReactComponent as Users} from "assets/images/icons/users-class.svg";
import {ReactComponent as Cubes} from "assets/images/icons/cubes.svg";
import {ReactComponent as Cog} from "assets/images/icons/users-cog.svg";
import {ReactComponent as Clipboard} from "assets/images/icons/clipboard-user.svg";
import {ReactComponent as TaskAlt} from "assets/images/icons/taskSvg.svg";
import {ReactComponent as Disciplinary} from "assets/images/icons/disciplinary.svg";
import {ReactComponent as Job} from "assets/images/icons/jobBreif.svg";
import {ReactComponent as FileInvoice} from "assets/images/icons/file-invoice.svg";
import {ReactComponent as Repeat} from "assets/images/icons/repeat.svg";
import {ReactComponent as Key} from "assets/images/icons/key.svg";
import {ReactComponent as Sun} from "assets/images/icons/sun.svg";
import {ReactComponent as Moon} from "assets/images/icons/moon.svg";
import {ReactComponent as Times} from "assets/images/icons/times.svg";
import {ReactComponent as SignOut} from "assets/images/icons/sign-out.svg";
import {ReactComponent as Time} from "assets/images/icons/time.svg";
import {ReactComponent as Travel} from "assets/images/icons/travel.svg";
import {ReactComponent as Overtime} from "assets/images/icons/overtime.svg";
import {ReactComponent as List} from "assets/images/icons/details-list-ul.svg";
import {ReactComponent as Clock} from "assets/images/icons/clock.svg";
import {ReactComponent as Forward} from "assets/images/icons/forwarded.svg";
import {ReactComponent as ThumbsUp} from "assets/images/icons/thumbs-up.svg";
import {ReactComponent as Document} from "assets/icons/document.svg";
import {ReactComponent as Pdf} from "assets/icons/pdf.svg";
import {ReactComponent as Applied} from "assets/images/stageIcons/applied.svg";
import {ReactComponent as Delete} from "assets/images/stageIcons/delete.svg";
import {ReactComponent as Hired} from "assets/images/stageIcons/hired.svg";
import {ReactComponent as Interview} from "assets/images/stageIcons/interview.svg";
import {ReactComponent as JobOffer} from "assets/images/stageIcons/job-offer.svg";
import {ReactComponent as Reject} from "assets/images/stageIcons/reject.svg";
import {ReactComponent as Shortlisted} from "assets/images/stageIcons/shortlisted.svg";
import {ReactComponent as Written} from "assets/images/stageIcons/written.svg";
import {ReactComponent as AddCircle} from "assets/images/plus.svg";
import {ReactComponent as EyeView} from "assets/images/icons/eye.svg";
import {ReactComponent as ShareView} from "assets/images/icons/forwardShare.svg";
import {ReactComponent as Archive} from "assets/images/icons/archive.svg";
import {ReactComponent as Check} from "assets/images/icons/check__1_.svg";

function getIconComponent(name) {
  switch (name) {
    case "archive":
      return Archive;
    case "brackets":
      return Brackets;
    case "check":
      return Check;
    case "dueDate":
      return DueDate;
    case "priority":
      return Priority;
    case "status":
      return Status;
    case "ellipsis-v":
      return EllipsisV;
    case "arrow-down":
      return ArrowDown;
    case "arrow-up":
      return ArrowUp;
    case "team":
      return Team;
    case "eyeDropper":
      return EyeDropper;
    case "simle":
      return Simle;
    case "simlePlus":
      return SimlePlus;
    case "angleRight":
      return AngleRight;
    case "angleLeft":
      return AngleLeft;
    case "arrowCircleRight":
      return ArrowCircleRight;
    case "tasks":
      return Tasks;
    case "bookReader":
      return BookReader;
    case "allowance":
      return Allowance;
    case "approved":
      return ApprovedCircle;
    case "calculator":
      return Calculator;
    case "home":
      return Home;
    case "shift":
      return Shift;
    case "calendar-check":
      return Shift;
    case "list":
      return List;
    case "users":
      return Users;
    case "cubes":
      return Cubes;
    case "user":
      return User;
    case "scroll":
      return Scroll;
    case "sliders":
      return Sliders;
    case "techo":
      return Techo;
    case "cog":
      return Cog;
    case "clipboard":
      return Clipboard;
    case "taskAlt":
      return TaskAlt;
    case "disciplinary":
      return Disciplinary;
    case "job":
      return Job;
    case "fileInvoice":
      return FileInvoice;
    case "repeat":
      return Repeat;
    case "key":
      return Key;
    case "sun":
      return Sun;
    case "moon":
      return Moon;
    case "sign-out":
      return SignOut;
    case "overtime":
      return Overtime;
    case "times":
      return Times;
    case "time":
      return Time;
    case "travel":
      return Travel;
    case "leave":
      return Leave;
    case "forwarded":
      return Forward;
    case "clock":
      return Clock;
    case "thumbs-up":
      return ThumbsUp;
    case "document":
      return Document;
    case "pdf":
      return Pdf;
    case "applied":
      return Applied;
    case "delete":
      return Delete;
    case "hired":
      return Hired;
    case "interview":
      return Interview;
    case "jobOffer":
      return JobOffer;
    case "reject":
      return Reject;
    case "shortlisted":
      return Shortlisted;
    case "written":
      return Written;
    case "addCircle":
      return AddCircle;
    case "eyeView":
      return EyeView;
    case "shareView":
      return ShareView;
    default:
      return null;
  }
}

interface Icons {
  name:
    | "brackets"
    | "due-date"
    | "archive"
    | "check"
    | "priority"
    | "status"
    | "team"
    | "eyeDropper"
    | "simle"
    | "simlePlus"
    | "angleRight"
    | "angleLeft"
    | "arrowCircleRight"
    | "tasks"
    | "calendar-check"
    | "allowance"
    | "thumbs-up"
    | "forwarded"
    | "clock"
    | "leave"
    | "approved"
    | "time"
    | "sun"
    | "moon"
    | "calculator"
    | "home"
    | "shift"
    | "users"
    | "user"
    | "cubes"
    | "scroll"
    | "sliders"
    | "techo"
    | "list"
    | "cog"
    | "clipboard"
    | "overtime"
    | "travel"
    | "times"
    | "taskAlt"
    | "disciplinary"
    | "sign-out"
    | "job"
    | "key"
    | "repeat"
    | "fileInvoice"
    | "applied"
    | "delete"
    | "hired"
    | "interview"
    | "jobOffer"
    | "reject"
    | "shortlisted"
    | "written"
    | "document"
    | "pdf"
    | "ellipsis-v"
    | "arrow-down"
    | "arrow-up"
    | "addCircle";

  size?: number | string;
  height?: number | string;
  color?: string;
  className?: string;
  styles?: object;
}

const SvgIcons = ({size, color, name, height = null}: Icons) => {
  let SvgIcon = getIconComponent(name);
  return <SvgIcon width={size} height={size || height} fill={color} />;
};

SvgIcons.defaultProps = {
  size: "25px",
  color: "#3a3a3a",
};

export default SvgIcons;
