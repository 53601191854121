import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Popup from "components/popup";
// import AutoSelect from "components/Common/AutoSelect";
// import Radiogroup from "components/Form/RadioGroup";
// import DatePicker from "components/Calendar/DatePicker";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { GetEmployeeManagerById, UpdateEmployeeManagers } from "../action";
import { DisplayClientSideMessage } from "actions/commonAction";
import FormGroup from 'components/form';

class AssignRole extends React.Component {
  constructor(props) {
    super(props);
    this.state = { employeeManager: null };
  }

  async componentDidMount() {
    const { employeeId } = this.props;
    await this.props.GetEmployeeManagerById(employeeId);
    this.setState({ employeeManager: this.props.employeeManagerById });
  }

  onRadioChange = (section, e) => {
    let { name, id } = e.target;
    let { employeeManager } = this.state;
    id = id.replace(/[0-9]/g, "");

    let currentEmployeeManager = this.getCurrentEmployeeManager(section);

    currentEmployeeManager.ManagerialTypeName = id;
    if (id == "Person") {
      currentEmployeeManager.ManagerialType = 2;
    } else if (id == "Position") {
      currentEmployeeManager.ManagerialType = 1;
    }
    
    // employeeManager[currentIndex] = currentEmployeeManager;

    this.setEmployeeManager(employeeManager);
  };

  onDateChange = (section, name, date) => {
    let { employeeManager } = this.state;
    let currentEmployeeManager = this.getCurrentEmployeeManager(section);

    currentEmployeeManager[name] = date;

    // employeeManager[currentIndex] = currentEmployeeManager;
    this.setEmployeeManager(employeeManager);
  };

  onChangePersonOrPosition = (section, name, value) => {
    let { employeeManager } = this.state;

    let currentEmployeeManager = this.getCurrentEmployeeManager(section);

    if (name == "Person" && value) {
      currentEmployeeManager.ManagerialPersonName = value.label;
      currentEmployeeManager.ManagerialPerson = value.value;
    } else {
      currentEmployeeManager.ManagerialPersonName = null;
      currentEmployeeManager.ManagerialPerson = null;
    }
    if (name == "Position" && value) {
      currentEmployeeManager.ManagerialPositionName = value.label;
      currentEmployeeManager.ManagerialPosition = value.value;
    } else {
      currentEmployeeManager.ManagerialPositionName = null;
      currentEmployeeManager.ManagerialPosition = null;
    }

    this.setEmployeeManager(employeeManager);
  };

  setEmployeeManager = (employeeManager) => {
    this.setState({ employeeManager: employeeManager });
  };

  getCurrentEmployeeManager = (section) => {
    const { employeeManager } = this.state;
    let currentIndex = null;
    if (section === "direct") {
      currentIndex = 0;
    } else if (section === "matrix") {
      currentIndex = 1;
    } else if (section === "custom") {
      currentIndex = 2;
    }

    let currentEmployeeManager = employeeManager[currentIndex];
    return currentEmployeeManager;
  };

  handleUpdate = async () => {
    const { employeeManager } = this.state;
    const data = [];
    let employeeManagerCount = 0;
    employeeManager &&
      employeeManager.map((em) => {
        if (em.ManagerialType && em.ManagerialType > 0) {
          employeeManagerCount += 1;
          data.push({
            SrNo: em.SrNo,
            EmployeeRefId: em.EmployeeRefId,
            ReportingToManager: em.ReportingToManager,
            ManagerialType: em.ManagerialType,
            ManagerialPosition: em.ManagerialPosition,
            ManagerialPerson: em.ManagerialPerson,
            EffectiveDateEng: em.EffectiveDateEng,
          });
        }
      });

    if (employeeManagerCount > 0) {
      this.props.UpdateEmployeeManagers(data, this.props.refreshList);
    } else {
      this.props.DisplayClientSideMessage("Nothing to save");
    }
  };

  render() {
    const { employeeManager } = this.state;
    return (
      <div className="permission">
        <div className="permission-body">
          <div className="assign-group">
            {employeeManager &&
              employeeManager.map((list, index) => {
                let section = list.ReportingToManagerName.split(
                  " "
                )[0].toLowerCase();

                return (
                  <div className="assign-group__item" key={index}>
                    <div className="managers-type">
                      <h5 className="managers-type__title">
                        {list.ReportingToManagerName}
                      </h5>
                      <span className="managers-type__subtitle">
                        {section == "direct"
                          ? "Reports to"
                          : section == "matrix"
                          ? "Dotted line"
                          : ""}
                      </span>
                    </div>
                    <div className="managers-position">
                      <div className="managers-position__radio">
                        <FormGroup
                          formName="radiogroup"
                          name={section}
                          label={"Position"}
                          id={"Position" + index}
                          // value={list.ManagerialTypeName === "Position"}
                          checked={list.ManagerialTypeName === "Position"}
                          labelPosition="right"
                          onChange={(e) => this.onRadioChange(section, e)}
                        />
                        <FormGroup
                          formName="radiogroup"
                          name={section}
                          label={"Person"}
                          id={"Person" + index}
                          // value={list.ManagerialTypeName === "Person"}
                          checked={list.ManagerialTypeName === "Person"}
                          labelPosition="right"
                          onChange={(e) => this.onRadioChange(section, e)}
                        />
                      </div>
                      <SelectPosition
                        section={section}
                        list={list}
                        {...this.props}
                        onChangePersonOrPosition={this.onChangePersonOrPosition}
                      />
                    </div>
                    <div className="managers-date">
                      <label>Effective from</label>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className="pickers recruit-picker">
                          <KeyboardDatePicker
                            // name={name}
                            value={list.EffectiveDateEng}
                            onChange={(date) =>
                              this.onDateChange(
                                section,
                                "EffectiveDateEng",
                                date
                              )
                            }
                          />
                        </div>
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="permission-footer">
          <div className="button-group button-group__right">
            <button
              className="button button-primary"
              onClick={() => this.handleUpdate()}
            >
              Save
            </button>
            <button onClick={() => this.props.close()} className="button">
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function SelectPosition({
  section,
  list,
  employeeList,
  designationList,
  onChangePersonOrPosition,
}) {
  const managerialTypeName = list.ManagerialTypeName;
  const [popup, setPopup] = useState(false);
  let selectLabel = useRef(null);
  if (!managerialTypeName) {
    return null;
  }


  let personOrPosition = "";
  if (managerialTypeName == "Person") {
    personOrPosition = {
      label: list.ManagerialPersonName,
      value: list.ManagerialPerson,
    };
  } else if (managerialTypeName == "Position") {
    personOrPosition = {
      label: list.ManagerialPositionName,
      value: list.ManagerialPosition,
    };
  }

  return (
    <div
      className={
        managerialTypeName == "Person"
          ? "managers-position__select align-down "
          : "managers-position__select"
      }
      ref={selectLabel}
    >
      <a onClick={(e) => setPopup(true)}>{"Select a " + managerialTypeName}</a>
      <Popup
        onClose={(e) => setPopup(false)}
        open={popup}
        parentPop={selectLabel}
      >
        <div className="managers-input">
          <FormGroup
            formName="reactselect"
            name={managerialTypeName}
            label={managerialTypeName}
            value={personOrPosition}
            options={
              managerialTypeName == "Person" ? employeeList : designationList
            }
            width="100%"
            onChange={(name, value) =>
              onChangePersonOrPosition(section, name, value)
            }
          />
        </div>
      </Popup>
    </div>
  );
}

const mapStateToProps = (state) => ({
  employeeManagerById: state.managersReducer.employeeManagerById,

  employeeList: state.commonReducer.employeeList,
  designationList: state.commonReducer.designationList,
});

const mapDispatchToProps = {
  GetEmployeeManagerById,
  UpdateEmployeeManagers,
  DisplayClientSideMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignRole);
