import React, { Component } from "react";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    ExcelExport,
    Grid,
    Inject,
    Toolbar,
    ToolbarItems
} from "@syncfusion/ej2-react-grids";
import classnames from "classnames";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEmpty from "isEmpty";
import {CssBaseline} from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import {TablePagination} from "@material-ui/core";
import Loader from '../../../components/loaders'
import { getColors } from "../attendanceRegister/action";

const theme = createMuiTheme();

export const WEEK_DAYS = {
    S: "Sunday",
    M: "Monday",
    T: "Tuesday",
    W: "Wednesday",
    T: "Thursday",
    F: "Friday",
    S: "Saturday"
};
export const HOLIDAY = {
    NH: "National Holiday",
    WH: "Weekly Holiday",
    "WH/2": "Half Weekly Holiday",
    FH: "Female Holiday",
    CH: "Caste Holiday"
};
export class AttendanceTable extends Component {
    constructor(props) {
        super(props);

        this.state = {};
        this.template = this.gridTemplate;
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    }
    componentDidMount() {
        this.grid = Grid || null;
        this.props.getColors();
    }
    toolbarClick = id => {
        if (this.grid && id === "grid_excelexport") {
            this.grid.excelExport();
        }
    };
    gridTemplate(props) {
        const {
            colors: { loading, value }
        } = this.props.payrollAttendanceReducer;
        if (loading) {
            return <Loader type="normal" loaderSize={100} />;
        } else {
            return (
                <tr className="leave-row">
                    <td className="">
                        <span>{props.Name}</span>
                    </td>
                    <td className="">
                        <span>{props.EmployeeId}</span>
                    </td>
                    <td className="">
                        <span>{props.PayDays}</span>
                    </td>
                    <td className="">
                        <span>{props.PresentDays}</span>
                    </td>
                    {isEmpty(props.AttendanceList)
                        ? [...Array(props.TotalDays)].map((x, i) => {
                                return (
                                    <td
                                        style={{
                                            background: "#eaeaea",
                                            border: "1px solid #d7d7d7"
                                        }}
                                        className={"tdDate"}
                                    ></td>
                                );
                            })
                        : props.AttendanceList.map(day => {
                                const tdClasses = classnames({
                                    tdDate: true,
                                    present: day.Dayvalue === "P",
                                    absent:
                                        day.Dayvalue === "AB" ||
                                        day.Dayvalue === "ABS"
                                    // sick: day.Dayvalue === "SL",
                                    // annual: day.Dayvalue === "ML",
                                    // holiday:
                                    //     day.Dayvalue === "WH" ||
                                    //     day.Dayvalue === "WH," ||
                                    //     day.Dayvalue === "WH/2"
                                });
                              // value.map(item=>{
                              //     console.log(item.Abbreviation,day.Dayvalue)
                              //     if(item.Abbreviation===day.Dayvalue){
                              //         return (
                              //             <td style={{background:item.LegendColor}} className={tdClasses}>
                              //                 {day.Dayvalue === "P" ? "" : day.Dayvalue}
                              //             </td>
                              //         );
                              //     }
                              // })
                                let color;
                                if (
                                    day.Dayvalue !== "P" ||
                                    day.Dayvalue !== "ABS"
                                ) {
                                    color = this.getColorValue(
                                        value,
                                        day.Dayvalue
                                    );
                                }
                                let styleColor = !isEmpty(color)
                                    ? {
                                        backgroundColor: color[0].LegendColor,
                                        color: "#fff"
                                    }
                                    : {};
                                return (
                                    <td style={styleColor} className={tdClasses}>
                                        {day.Dayvalue === "P" ? "" : day.Dayvalue}
                                    </td>
                                );
                            })}
                </tr>
            );
        }
    }
    getColorValue(colors, actColor) {
        return colors.filter(color => color.Abbreviation === actColor);
    }
    handleChangePage = (event, newPage) => {
        this.props.handleIndexChange(newPage);
    };

    handleChangeRowsPerPage = event => {
        this.props.handleIndexChange(0);
        this.props.handleRowChange(event.target.value);
    };
    days = props => {
        console.log(props);
    };
    addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }
    getWeekDay(day) {
        return Object.keys(WEEK_DAYS)[Math.max(0, Math.min(day - 1, 6))];
    }
    getWeekLetter(date, days) {
        let newDate = this.addDays(date, days);
        let day = newDate.getDay();
        const week = ["S", "M", "T", "W", "T", "F", "S"];
        return week[day];
    }
    render() {
        
        const {
            searchedAttendanceRegister,
            searchedPage,
            loading,
            colors: { loading: colorLoading, value }
        } = this.props.payrollAttendanceReducer;
        
        return (
            <div>
                <div className="arrear-table">
                    {loading || searchedAttendanceRegister === null ? (
                        <Loader open={loading} />
                    ) : null}
                        <div
                            className="payroll-grid"
                            style={{ maxHeight: "unset" }}
                        >
                            <GridComponent
                                dataSource={searchedAttendanceRegister}
                                //  height={!isEmpty(searchedLeaveAbsence)?"500":'80'}
                                id="grid"
                                allowExcelExport={true}
                                ref={g => (this.grid = g)}
                                rowTemplate={this.template.bind(this)}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field="Name" width="180" />
                                    <ColumnDirective field="ID" width="80" />
                                    <ColumnDirective field="DED" width="80" />
                                    <ColumnDirective field="ABS" width="80" />

                                    {isEmpty(searchedAttendanceRegister)
                                        ? null
                                        : !isEmpty(
                                            searchedAttendanceRegister[0]
                                                .AttendanceList
                                        )
                                        ? searchedAttendanceRegister[0].AttendanceList.map(
                                            day => {
                                                const dayLetter = this.getWeekLetter(
                                                    searchedAttendanceRegister[0]
                                                        .StartDate,
                                                    parseInt(day.DayNumber) -
                                                        1
                                                );
                                                return (
                                                    <ColumnDirective
                                                        key={day.DayNumber}
                                                        columns={[
                                                            {
                                                                field:
                                                                    "DayNumber",
                                                                headerText: dayLetter,
                                                                width: "60"
                                                            }
                                                        ]}
                                                        field="DayNumber"
                                                        headerText={
                                                            day.DayNumber
                                                        }
                                                        width="60"
                                                    />
                                                );
                                            }
                                        )
                                        : [
                                            ...Array(
                                                searchedAttendanceRegister[0]
                                                    .TotalDays
                                            )
                                        ].map((x, i) => {
                                            const dayLetter = this.getWeekLetter(
                                                searchedAttendanceRegister[0]
                                                    .StartDate,
                                                i
                                            );
                                            return (
                                                <ColumnDirective
                                                    key={i}
                                                    columns={[
                                                        {
                                                            field:
                                                                "DayNumber",
                                                            headerText: dayLetter,
                                                            width: "60"
                                                        }
                                                    ]}
                                                    field="DayNumber"
                                                    headerText={i + 1}
                                                    width="60"
                                                />
                                            );
                                        })}
                                </ColumnsDirective>
                            </GridComponent>
                            {isEmpty(searchedAttendanceRegister) ? null : (
                                <div className="paginate">
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 100, 200]}
                                        component="div"
                                        count={searchedPage.total}
                                        rowsPerPage={this.props.rowsPerPage}
                                        page={this.props.pageIndex}
                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={
                                            this.handleChangeRowsPerPage
                                        }
                                    />
                                    <MuiThemeProvider theme={theme}>
                                        <CssBaseline />
                                        <Pagination
                                            limit={this.props.rowsPerPage}
                                            offset={
                                                this.props.rowsPerPage *
                                                this.props.pageIndex
                                            }
                                            total={searchedPage.total}
                                            textPrimary="#2680eb"
                                            otherPageColor={"#646464"}
                                            onClick={(de, offset) =>
                                                this.props.handleIndexChange(
                                                    offset /
                                                        this.props.rowsPerPage
                                                )
                                            }
                                        />
                                    </MuiThemeProvider>
                                </div>
                            )}
                        </div>
                    {/* )} */}
                </div>
                {colorLoading ? (
                    <div className="color-container">
                        <Loader open={colorLoading} type="normal" loaderSize={35} />
                    </div>
                ) : (
                    <div className="color-container">
                        {/* <h3 className="color-heading">Leave Legends</h3> */}
                        <div className="color-leave">
                            {value && value.map((item, key) => {
                                const back = {
                                    backgroundColor: item.LegendColor
                                };
                                if (item.Title !== null) {
                                    return (
                                        <div className="color__col" key={key}>
                                            <div
                                                style={back}
                                                className="color-box"
                                            ></div>
                                            <span className="color-title">
                                                {item.Title} (
                                                {item.Abbreviation})
                                            </span>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                        <span className="hl"></span>
                        {/* <h3 className="color-heading">Holiday Legends</h3> */}
                        <div className="color-holiday">
                            {!value && value.map((item, key) => {
                                const back = {
                                    backgroundColor: item.LegendColor
                                };
                                if (item.Title === null) {
                                    return (
                                        <div className="color__col" key={key}>
                                            <div
                                                style={back}
                                                className="color-box"
                                            ></div>
                                            <span className="color-title">
                                                {HOLIDAY[item.Abbreviation]} ({item.Abbreviation})
                                            </span>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

AttendanceTable.propTypes = {
    payrollAttendanceReducer: PropTypes.object.isRequired,
    getColors: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    payrollAttendanceReducer: state.payrollAttendanceReducer
});

export default connect(mapStateToProps, { getColors })(AttendanceTable);
