import React, {useEffect} from "react";
import FormItem from "components/formGroup/formItem";
import {useForm} from "react-hook-form";
import FormGroup from "components/formGroup";
import Button from "components/button";
import {useDispatch} from "react-redux";
import {updateSbiSettingAmountLimit} from "../helpers/action";

const LoanLimit = (props) => {
  const method = useForm();
  const dispatch = useDispatch();

  const {setValue} = method;

  const {loanLimitAmount, loanType} = props;

  const onSearch = (data) => {
    delete data.loanRequest;
    const person: any = Object.values(data);
    let updatedValue = loanLimitAmount.map((values, index) => {
      if (person[index]) {
        return {levelId: values.LevelId, amount: parseInt(person[index])};
      }
    });
    let param = updatedValue.filter((values) => {
      if (values?.amount) {
        return {levelId: values.LevelId, amount: values.amount};
      }
    });
    dispatch(updateSbiSettingAmountLimit(loanType, param));
  };

  useEffect(() => {
    if (loanLimitAmount) {
      let test = loanLimitAmount.map((values, index) => {
        setValue(`limitAmount${index}`, values.Amount ?? "");

        setValue(`loanRequest${index}`, values.LevelName ?? "");
      });
    }
  }, [loanLimitAmount]);

  return (
    <FormGroup method={method} onSubmit={onSearch}>
      {loanLimitAmount.map((values, index) => (
        <div key={index} style={{display: "flex", marginBottom: "12px", zIndex: -1}}>
          <FormItem
            name={`loanRequest`}
            value={values.LevelName}
            width="300px"
            placeholder="Loan Request"
            disabled
          />
          <FormItem
            type="number"
            name={`limitAmount${index}`}
            width="300px"
            placeholder="Rs 0"
          />
        </div>
      ))}

      <Button title="Save" bg="primary" htmlButtonType="submit" />
    </FormGroup>
  );
};

export default LoanLimit;
