import {
  GET_DASHBOARD_DETAIL,
  GET_JOB_STAGES,
  GET_APPLIED_CANDIDATE_LIST_BY_JOBID,
  GET_CANDIDATE_LIST_BY_JOB_AND_STAGE_ID,
  GET_CANDIDATE_DETAIL,
  GET_CANDIDATE_DETAIL_LOADING,
  GET_MILESTONE_DETAIL,
  GET_MILESTONE_DETAIL_LOADING,
  GET_CANDIDATE_DETAILS_BY_JOB_ID,
  EXPORT_CANDIDATE_DETAILS_BY_JOB_ID,
  // CANDIDATE_DETAILS_LOADING,
  CHANGE_CANDIDATE_STAGE,
  UPDATE_CANDIDATE_DETAILS_FAV,
  GET_HIRING_STAGES_BY_JOB_ID,
  HIRING_STAGES_BY_JOB_ID_LOADING,
  GET_HIRING_STAGE_AND_SUBSTAGE_BY_JOB_ID_LOADING,
  GET_HIRING_STAGE_AND_SUBSTAGE_BY_JOB_ID,
  UPDATE_TAGS,
  UPDATE_CANDIDATE_DETAILS,
  CURRENT_CANDIDATE_DETAIL,
  CURRENT_CANDIDATE_DETAIL_LOADING,
  PROFILEVIEW_CANDIDATE_DETAILS,
  GET_DASHBOARD_INFO,
  UPDATE_CANDIDATE_REJECTION,
} from "store/types";

import isEmpty from "isEmpty";

const initialState = {
  dashboardDetail: [],
  dashboardInfo: [],
  currentCandidateDetail: null,
  currentCandidateDetailLoading: [],
  jobStageList: [],
  hiringStagesJobId: { loading: false, value: [] },
  hiringStageAndSubStagesJobId: { loading: false, value: [] },
  appliedCandidateList: null,
  candidateListByStage: null,
  candidateDetailLoading: false,
  candidateDetail: null,
  milestoneDetailLoading: false,
  milestoneDetail: null,
  candidateTableViewDetails: [],
  exportCandidateTableViewDetails: [],
  profileViewCandidatesDetails: {
    candidates: [],
    loadMore: true,
    pageIndex: -1,
    stageId: -3,
  },
  updateCandiateRejection: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DASHBOARD_DETAIL: {
      return {
        ...state,
        dashboardDetail: action.payload,
      };
    }

    case GET_DASHBOARD_INFO: {
      return {
        ...state,
        dashboardInfo: action.payload,
      };
    }

    case HIRING_STAGES_BY_JOB_ID_LOADING: {
      return {
        ...state,
        hiringStagesJobId: { loading: true, value: [] },
      };
    }

    case GET_HIRING_STAGES_BY_JOB_ID: {
      return {
        ...state,
        hiringStagesJobId: { loading: false, value: action.payload },
      };
    }

    case GET_HIRING_STAGE_AND_SUBSTAGE_BY_JOB_ID_LOADING: {
      return {
        ...state,
        hiringStageAndSubStagesJobId: { loading: true, value: [] },
      };
    }

    case GET_HIRING_STAGE_AND_SUBSTAGE_BY_JOB_ID: {
      return {
        ...state,
        hiringStageAndSubStagesJobId: { loading: false, value: action.payload },
      };
    }

    case CHANGE_CANDIDATE_STAGE: {
      return {
        ...state,
        currentCandidateDetail: action.currentDashboardDetail,
        // candidateListByStage:action.candidateListByStage
      };
    }

    case UPDATE_CANDIDATE_DETAILS_FAV: {
      var stringData = JSON.stringify(state.candidateTableViewDetails);
      let data = JSON.parse(stringData);
      const index = state.candidateTableViewDetails.findIndex(
        (x) => x.CandidateId === action.payload.candidateId
      );
      if (index >= 0) {
        data[index].IsFavorite = action.payload.isFavourite;
      }

      return {
        ...state,
        candidateTableViewDetails: data,
      };
    }
    case UPDATE_CANDIDATE_REJECTION: {
      return {
        ...state,
        updateCandiateRejection: { value: action.payload },
      };
    }

    case UPDATE_TAGS: {
      return {
        ...state,
        candidateDetail: {
          ...state.candidateDetail,
          CandidateProfile: {
            ...state.candidateDetail.CandidateProfile,
            Tag: action.payload.Tag,
          },
        },
        candidateTableViewDetails: state.candidateTableViewDetails.map(
          (item) => {
            if (item.CandidateId === action.payload.candidateId) {
              let a = item;
              a.Tag = action.payload.Tag;
              return a;
            }
            return item;
          }
        ),
      };
    }

    case CURRENT_CANDIDATE_DETAIL: {
      return {
        ...state,
        currentCandidateDetail: action.payload,
        currentCandidateDetailLoading: false,
      };
    }

    case CURRENT_CANDIDATE_DETAIL_LOADING:
      return {
        ...state,
        currentCandidateDetailLoading: action.payload,
      };
    case UPDATE_CANDIDATE_DETAILS: {
      //RecruitmentDashboard
      const a = state.candidateTableViewDetails.map((item) => {
        let a = action.candidates.filter(
          (candidate) => candidate.CandidateId === item.CandidateId
        );
        if (!isEmpty(a)) {
          return a[0];
        }
        return item;
      });

      return {
        ...state,
        dashboardDetail: state.dashboardDetail.map((item) => {
          if (item.Jobid === action.RecruitmentDashboard.Jobid) {
            return action.RecruitmentDashboard;
          }
          return item;
        }),
        currentCandidateDetail: action.RecruitmentDashboard,
        candidateTableViewDetails: action.candidates,
      };
    }

    case GET_JOB_STAGES: {
      const jobStageList = [];
      action.payload &&
        action.payload.map((list) => {
          if (!isEmpty(list.StageType)) {
            jobStageList.push({
              // label: list.StageName,
              label: list.StageType,
              value: list.StageId,
            });
          }
        });

      return {
        ...state,
        jobStageList: jobStageList,
      };
    }

    case GET_APPLIED_CANDIDATE_LIST_BY_JOBID: {
      return {
        ...state,
        appliedCandidateList: action.appliedCandidateList,
        candidateListByStage: action.candidateListByStage,
      };
    }

    case GET_CANDIDATE_DETAIL: {
      return {
        ...state,
        candidateDetailLoading: false,
        candidateDetail: action.payload,
      };
    }
    case GET_CANDIDATE_DETAIL_LOADING: {
      return {
        ...state,
        candidateDetailLoading: action.payload,
      };
    }

    case GET_MILESTONE_DETAIL: {
      return {
        ...state,
        milestoneDetailLoading: false,
        milestoneDetail: action.payload,
      };
    }
    case GET_MILESTONE_DETAIL_LOADING: {
      return {
        ...state,
        milestoneDetailLoading: action.payload,
      };
    }

    case GET_CANDIDATE_LIST_BY_JOB_AND_STAGE_ID: {
      return {
        ...state,
        candidateListByStage: action.payload,
      };
    }
    case EXPORT_CANDIDATE_DETAILS_BY_JOB_ID: {
      return {
        ...state,
        exportCandidateTableViewDetails: action.payload,
      };
    }
    case GET_CANDIDATE_DETAILS_BY_JOB_ID: {
      return {
        ...state,
        candidateTableViewDetails: action.payload,
      };
    }

    case PROFILEVIEW_CANDIDATE_DETAILS: {
      const { profileViewCandidatesDetails } = state;
      const { pageIndex, stageId } = profileViewCandidatesDetails;
      let candidates = [];
      if (action.payload.pageIndex > 0) {
        candidates = [...profileViewCandidatesDetails.candidates];
      }

      candidates = candidates.concat(action.payload.candidates);

      return {
        ...state,
        profileViewCandidatesDetails: {
          ...profileViewCandidatesDetails,
          candidates: candidates,
          loadMore: action.payload.candidates.length > 0,
          pageIndex: action.payload.pageIndex,
          stageId: action.payload.stageId,
        },
      };
    }

    default:
      return state;
  }
}
