import React from "react";
import { connect } from "react-redux";
import DragableBlock from "components/dragableBlock";
import shortid from "shortid";
import {
  ProjectPieChart,
  ClientTypePieChart,
  ActivitiesPieChart,
} from "./pieCharts";
import { GetCounterReport } from "../../action";
import isEmpty from "isEmpty";
import RouteBaseClass from "components/routeBaseClass";

class index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { initial: null };
  }
  async componentDidMount() {
    await this.props.GetCounterReport();
    if (!isEmpty(this.props.counterReport)) {
      const initial = [
        {
          id: shortid.generate(),
          cards: [
            {
              id: shortid.generate(),
              name: "Projects",
              component: ProjectPieChart,
            },
          ],
        },
        {
          id: shortid.generate(),
          cards: [
            {
              id: shortid.generate(),
              name: "Client Types",
              component: ClientTypePieChart,
            },
          ],
        },
        {
          id: shortid.generate(),
          cards: [
            {
              id: shortid.generate(),
              name: "Activities",
              component: ActivitiesPieChart,
            },
          ],
        },
      ];

      this.setState({ initial: initial });
    }
  }

  render() {
    const { initial } = this.state;
    return (
      <>
         {initial && <DragableBlock initial={initial} showSetting={false} />}       
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  counterReport: state.taskTrackerReducer.counterReport,
});

const mapDispatchToProps = {
  GetCounterReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
