import React, { Component } from 'react';
import './leave.styles.scss';
import DragBlock from './components/dragBlock'
import AreaChart from 'components/charts/areaChart';
import {getLeaveTimeDashboard} from './helpers/action';
import {addDashboardBlocks} from '../hr/helpers/action';
import {connect} from 'react-redux';
import isEmpty from 'isEmpty';
import SvgIcons from 'components/icons/svgIcons';
import FavIconModal from 'components/common/favIconModal';
import Button from 'components/button'
import RouteEnum from 'constants/routeEnum';
import {modules as common} from './commonTasks/index'
import {modules as report} from './reports/index'
import { Features } from "helpers/getPermissions";

export class LeaveTimeDashboard extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             
        }
        this._renderFavButton = this._renderFavButton.bind(this);
        this._renderCount = this._renderCount.bind(this);
    }
    componentDidMount(){
        this.props.getLeaveTimeDashboard()
    }
    _renderCount(){
        const {LeaveEmployeeCount, CheckInOutChart} = this.props.leaveTimeDashboard;
        return(
            <div className="leave-count">
                <div className="leave-count-left">
                    <ul className="leave-count__list">
                        <li className="leave-count__item">
                            <div className="leave-time-block">
                                <div className="leave-time-at">
                                    <span className="text-blue bold-600 head-text">{LeaveEmployeeCount.AtWorkEmployee}</span> at work
                                </div>
                                <div className="leave-time-out">
                                    <span className="bold-500">{LeaveEmployeeCount.AtWorkPercent}% </span> of <span className="bold-500">{LeaveEmployeeCount.TotalEmployees}</span>
                                </div>
                                <div className="leave-time-sub">
                                    <span>recorded attendance</span>
                                </div>
                            </div>
                        </li>
                        <li className="leave-count__item">
                            <div className="leave-time-block">
                                <div className="leave-time-at">
                                    <span className="text-pink bold-600 head-text">{LeaveEmployeeCount.LeaveEmployee}</span> on Leave
                                </div>
                                <div className="leave-time-out">
                                    <span className="bold-500">{LeaveEmployeeCount.OnLeavePercent}% </span> of <span className="bold-500">{LeaveEmployeeCount.TotalEmployees}</span>
                                </div>
                                <div className="leave-time-sub">
                                    <span>with leave approval</span>
                                </div>
                            </div>
                        </li>
                        <li className="leave-count__item">
                            <div className="leave-time-block">
                                <div className="leave-time-at">
                                    <span className="text-red bold-600 head-text">{LeaveEmployeeCount.AbsentEmployee}</span> absent
                                </div>
                                <div className="leave-time-out">
                                    <span className="bold-500">{LeaveEmployeeCount.AbsentPercent}% </span> of <span className="bold-500">{LeaveEmployeeCount.TotalEmployees}</span>
                                </div>
                                <div className="leave-time-sub">
                                    <span>without leave approval</span>
                                </div>
                            </div>
                        </li>
                        <li className="leave-count__item">
                            <div className="leave-time-block">
                                <div className="leave-time-at">
                                    <span className="bold-600 head-text">3:30 pm</span> today, 
                                    <span className="second-text"> yesterday, Jul 30</span>
                                </div>
                                <div className="leave-time-out">
                                    <span className="bold-500">1% </span> of <span className="bold-500">100</span>
                                </div>
                                <div className="leave-time-sub">
                                    <span>without leave approval</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="left-count-right">
                    <div className="leave-count-diagram">
                        <AreaChart data={CheckInOutChart} />
                    </div>
                </div>
            </div>
        )
    }
    _renderFavButton(){
        const features = Features();
        return(
            <div className="fav-button-block">
                <div className="fav-button-group">
                    <Button 
                        buttonType="icon-button"
                        icon={<SvgIcons name="tasks" color="#3a3d43" size="17px" />}
                        title="Common Tasks"
                        bg="white"
                        onClick={()=>this.props.history.push(RouteEnum.LeaveTimeDashboardCommonTaskPath)}
                    />
                    <Button 
                        buttonType="icon-button"
                        icon={<SvgIcons name="fileInvoice" color="#3a3d43" size="14px" />}
                        title="Reports"
                        bg="white"
                        onClick={()=>this.props.history.push(RouteEnum.LeaveTimeDashboardReportsPath)}
                    />
                    <FavIconModal common={common(features)} reports={report(features)} dashboardName="Leave"  /> 
                </div>
            </div>
        )
    }
    render() {
        const {leaveTimeDashboard} = this.props;
        return (
            <div className="admin">
                <div className="admin-container">
                    <div className="admin-title">
                        <h1>Leave & Time Dashboard</h1>
                    </div>
                    {
                        isEmpty(leaveTimeDashboard)?
                        <span className="no-found">No Info Found</span>
                        :
                        (
                            <div className="admin-body">
                                {this._renderCount()}
                                { this._renderFavButton() }
                                <DragBlock addDashboardBlocks={this.props.addDashboardBlocks} blocks={this.props.dashboardBlock} baseUrl={this.props.baseUrl} />
                            </div>
                        )
                    }
                    
                </div>
            </div>
        )
    }
}


const mapDispatchToProps = {
    getLeaveTimeDashboard,
    addDashboardBlocks
}

const mapStateToProps = state => ({
    leaveTimeDashboard:state.leaveTimeReducer.leaveTimeDashboard,
    baseUrl:state.commonReducer.baseUrl,
    dashboardBlock:state.hrReducer.dashboardBlock
})

export default connect(mapStateToProps,mapDispatchToProps)(LeaveTimeDashboard)
