import {
  GET_HR_DASHBOARD,
  GET_DASHBOARD_BLOCK,
  ADD_DASHBOARD_BLOCK,
  GET_DASHBOARD_MENUS,
  ADD_DASHBOARD_MENUS,
  REMOVE_DASHBOARD_MENUS,
} from "actions/types";
import axios from "axios";
import {toastMessage} from "actions/validateAction";
import environment from "environment";
import types from "constants/types";
import Http from "src/utils/http/http";
import Action from "src/utils/http/actionUtility";
import async from "react-select/async";

export const getHrDashboard = () => async (dispatch) => {
  await axios
    .get(`/v1/core-hr/admin/information`)
    .then((response) => {
      if (response.data.Status) {
        dispatch({
          type: GET_HR_DASHBOARD,
          payload: response.data.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_HR_DASHBOARD,
        payload: null,
      });
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to get employee list.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const getDashboardMenus = () => async (dispatch) => {
  await axios
    .get("v1/dashboard/menus")
    .then((response) => {
      if (response.data.Status) {
        dispatch({
          type: GET_DASHBOARD_MENUS,
          payload: response.data.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_DASHBOARD_MENUS,
        payload: null,
      });
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to get dashboard menus",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const addDashboardMenu = (data) => async (dispatch) => {
  await axios
    .post("v1/dashboard/menus", data)
    .then((response) => {
      if (response.data.Status) {
        dispatch({
          type: ADD_DASHBOARD_MENUS,
          payload: response.data.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to post dashboard menus",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const removeDashboardMenu = (id) => async (dispatch) => {
  await axios
    .delete("v1/dashboard/menus/" + id)
    .then((response) => {
      if (response.data.Status) {
        dispatch({
          type: REMOVE_DASHBOARD_MENUS,
          id,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to remove dashboard menus",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const getDashboardBlocks = () => async (dispatch) => {
  await axios
    .get("v1/dashboard/blocks")
    .then((response) => {
      if (response.data.Status) {
        dispatch({
          type: GET_DASHBOARD_BLOCK,
          payload: response.data.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to get dashboard blocks",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const addDashboardBlocks = (data) => async (dispatch) => {
  await axios
    .post("v1/dashboard/blocks", data)
    .then((response) => {
      if (response.data.Status) {
        dispatch({
          type: ADD_DASHBOARD_BLOCK,
          payload: response.data.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to post dashboard blocks",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const GetAdvanceTravelRequest = (data) => async (dispatch) => {
  console.log(`data`, data);
  // const res = Http.get(
  //   environment.api.advanceTravelRequest +
  //     `?pageindex=${data.pageIndex}&pagesize=${data.pageSize}`
  // );
  const res = Http.get(environment.api.advanceTravelRequest, data);
  const actionConfig = {
    dispatch,
    actionType: types.GET_ADVANCE_TRAVEL_REQUEST,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

// export const GetAdvanceTravelRequest = (data) => async (dispatch) => {
//   const res = Http.post(environment.api.advanceTravelRequest, data);
//   const actionConfig = {
//     dispatch,
//     actionType: types.GET_ADVANCE_TRAVEL_REQUEST,
//     effect: res,
//     isLoading: true,
//   };
//   await Action.createThunkEffect(actionConfig);
// };

export const GetTravelCategoryLists = (filter) => async (dispatch) => {
  const res = Http.get(
    environment.api.advanceTravelCategoryList + `?travelType=${filter}`
  );
  const actionConfig = {
    dispatch,
    actionType: types.GET_ADVANCE_TRAVEL_CATEGORY_LIST,
    effect: res,
    isSelect: true,
    isValueKey: true,
    isJustData: true,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const ExportAdvanceTravelRequest = (param, isExport) => async (dispatch) => {
  const res = Http.get(environment.api.advanceTravelRequest, param);
  const actionConfig = {
    dispatch,
    actionType: types.EXPORT_ADVANCE_TRAVEL_REQUEST,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
  return res;
};
