import React, { Component } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Filter,
  Inject,
  Sort,
  // SortSettingsModel,
} from "@syncfusion/ej2-react-grids";
import Pagination from "components/common/pagination";
import TableRows from "./tableRow";

export class ActivityReportTable extends Component {
  constructor(props) {
    super(props);
    this.state={
      data:[],
    }
    this.widths = {
      submitedBy: 180,
      date: 100,
      
      clientAndProject: 280,
      workedHours: 100,
      billable: 90,
      nonBillable: 90,
      expenses: 90,
      status: 120,
    };
    // this.sortingOptions = () =>
    //   SortSettingsModel({
    //     columns: [
    //       { field: "SubmittedByDisplayText", direction: "Ascending" },
    //       { field: "ActivityDateDisplayText", direction: "Ascending" },
    //       { field: "ClientName", direction: "Ascending" },
    //       { field: "WorkedHoursDisplayText", direction: "Ascending" },
    //       { field: "BillableHoursDisplayText", direction: "Ascending" },
    //       { field: "NonBillableHoursDisplayText", direction: "Ascending" },
    //     ],
    //   });
    this.gridTemplate = this.gridTemplate;
    this.row = [];
  }
  componentDidMount(){
    if(this.props.data){
      this.setState({data:this.props.data})
    }
  }
  componentDidUpdate(prevProps,prevState){
    if(this.props.data && prevProps.data !== this.props.data){
      this.setState({data:this.props.data})
      const ele = document.querySelector(".e-headercell.e-mousepointer")
      setTimeout(()=>{
        if(ele){
          ele.click()
        } 
      },[500])
      
    }
  }

  gridTemplate(props, history) {
    return (
      <TableRows
        {...props}
        {...history}
        // hideApproveBtn={this.props.hideApproveBtn}
        activeTab={this.props.activeTab}
        handleApprove={this.props.handleApprove}
        handleView={this.props.handleView}
        handleEditProject={this.props.handleEditProject}
        ref={(row) => {
          this.row = [...this.row, row];
        }}
      />
    );
  }

  render() {
    const {
      data,
      handleRowChange,
      handleIndexChange,
      pageIndex,
      rowsPerPage,
    } = this.props;

    return (
      <div className="default-table">
        {/* <div className="arrear-table"> */}
        <div className="pending-settlement-grid">
          <GridComponent
            dataSource={this.state.data}
            className="custom-grid"
            // toolbar={this.toolbar}
            // filterSettings={{ type: "Menu" }}
            // allowFiltering={true}
            //  height={!isEmpty(searchedLeaveAbsence)?"500":'80'}
            id="grid"
            // allowExcelExport={true}
            ref={(g) => (this.grid = g)}
            rowTemplate={this.gridTemplate.bind(this)}
            allowSorting={true}
            // sortSettings={this.sortingOptions}
          >
            <ColumnsDirective>
              <ColumnDirective width={30} />
              <ColumnDirective
                field="SubmittedBy"
                headerText="Submited By"
                width={this.widths.submitedBy}
              />
              <ColumnDirective
                field="ActivityDateEng"
                headerText="Date"
                width={this.widths.date}
              />
              <ColumnDirective
                field="ClientName"
                headerText="Client: Project"
                width={this.widths.clientAndProject}
              />
              <ColumnDirective
                field="WorkedHours"
                headerText="Worked Hours"
                width={this.widths.workedHours}
              />
              <ColumnDirective
                field="BillableHours"
                headerText="Billable"
                width={this.widths.billable}
              />
              <ColumnDirective
                field="NonBillableHours"
                headerText="Non-Billable"
                width={this.widths.nonBillable}
              />
              <ColumnDirective
                field="Expenses"
                headerText="Expenses"
                width={this.widths.expenses}
              />
              <ColumnDirective
                field="StatusDisplayText"
                headerText="Status"
                width={this.widths.status}
              />

              <ColumnDirective width={100} />
            </ColumnsDirective>
            {/* <Inject services={[Filter]} />
            <Inject services={[Filter,Sort]} />
            */}
            <Inject services={[Sort]} />
          </GridComponent>

          {data && data.length > 0 && (
            <Pagination
              handleRowChange={handleRowChange}
              handleIndexChange={handleIndexChange}
              pageIndex={pageIndex}
              rowsPerPage={rowsPerPage}
              total={data[0].TotalRows}
            />
          )}
        </div>
        {/* </div> */}
      </div>
    );
  }
}

export default ActivityReportTable;
