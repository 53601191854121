import React, { Component } from "react";
// import InputGroup from "../../../../Components/Form/InputGroup";
import Form from "components/form";

export class SearchAndFilter extends Component {
  render() {
    const { searchClient, searchProject } = this.props;
    return (
      <div className="filters">
        <div className="filters-grid">
          {/* <InputGroup
            name="searchClient"
            value={searchClient}
            onChange={this.props.handleInput}
            placeholder="Search Client"
          /> */}
          <Form
            formName="textinput"
            name="searchClient"
            value={searchClient}
            onChange={this.props.handleInput}
            placeholder="Search Client"
          />
        </div>

        <div className="filters-grid">
          {/* <InputGroup
            name="searchProject"
            value={searchProject}
            onChange={this.props.handleInput}
            placeholder="Search Project"
          /> */}
          <Form
            formName="textinput"
            name="searchProject"
            value={searchProject}
            onChange={this.props.handleInput}
            placeholder="Search Project"
          />
        </div>
      </div>
    );
  }
}

export default SearchAndFilter;
