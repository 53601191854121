import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import FormGroup from "components/form";
import Modal from "components/modal";
import { Tab, Tabs } from "components/tabs/index.d";
import isEmpty from "isEmpty";
import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { FaExclamationCircle, FaRegEdit } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import { IoIosClose } from "react-icons/io";
import { connect } from "react-redux";
import Select from "react-select";
import { change, reduxForm, SubmissionError } from "redux-form";

export class TravelCategoryForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allowanceExpense: null,
      allowanceExpenses: [{ Value: 0, Text: "Add New" }],
      positionRates: [],
      allowanceExpenseRate: [],
      activeTab: 0,
      currentTabRateType: "1",
      isModalShow: false,
      warningMessage: "",
      isLevelSelected: false,
      selectedLevels: [],
      selectedLevelAmount: "",
      levelList: [],
      levelGridList: [],
      isLevelEdit: false,
      selectedLevelGroupId: 0,
      levels: [],
      allowanceExpenseSelectList: [],
      fieldError: "",
      activeTabLabel: "Add New",
      formData: {
        CategoryId: 0,
        CategoryName: "",
        Description: "",
        TravelCategoryType: 1,
        TenantId: "",
      },
      errors: {},
    };
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onPositionRateChange = this.onPositionRateChange.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleRateTypeChange = this.handleRateTypeChange.bind(this);
    this.onFlatRateChange = this.onFlatRateChange.bind(this);
    this.getInputPositionRate = this.getInputPositionRate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
  }

  componentDidMount = () => {
    if (this.state.positionRates.length <= 0) {
      let arrayItem = [];
      if (this.props.levels && this.props.levels.length > 0) {
        this.props.levels.map((item, key) => {
          arrayItem.push({ Id: item.Value, Name: item.Text });
          return null;
        });

        this.setState({ positionRates: arrayItem });
      }
    }
    if (this.state.allowanceExpenses.length === 1) {
      this.initialState();
    }
    if (!isEmpty(this.props.levels) && this.props.levels.length > 0) {
      this.setState({ levels: this.Arrays(this.props.levels) });
    }
    if (
      !isEmpty(this.props.allowanceExpense) &&
      this.props.allowanceExpense.length > 0
    ) {
      this.setState({
        allowanceExpenseSelectList: this.Arrays(this.props.allowanceExpense),
      });
    }
  };

  onSelectChange(name, item) {
    // this.setState({ [name]: item.value });
    // this.setState(prevState => ({
    //   allowanceExpenses: [
    //     { Value: item.value, Text: item.label },
    //     ...prevState.allowanceExpenses
    //   ]
    // }));
    this.setState((prevState) => ({
      [name]: item.value,
      allowanceExpenses: [
        { Value: item.value, Text: item.label },
        ...prevState.allowanceExpenses,
      ],
      allowanceExpenseRate: [
        ...prevState.allowanceExpenseRate,
        {
          AllowanceId: item.value,
          IsFlatRate: true,
          FlatRateId: 0,
          FlatRate: null,
          LevelRates: [],
        },
      ],
      activeTab: item.value,
      activeTabLabel: item.label,
    }));
  }
  onFlatRateChange = (name, value) => {
    var stateCopy = Object.assign([], this.state.allowanceExpenseRate);
    stateCopy = stateCopy.map((x) => {
      const o = {
        ...x,
      };
      if (x.AllowanceId === this.state.activeTab) {
        o["FlatRate"] = value;
      }
      return o;
    });
    this.setState({ allowanceExpenseRate: stateCopy });
  };

  onPositionRateChange = (name, value) => {
    // var stateCopy = Object.assign([], this.state.allowanceExpenseRate);
    // stateCopy.filter(
    //   x => x.AllowanceId === this.state.activeTab
    // )[0].LevelRates = stateCopy
    //   .filter(x => x.AllowanceId === this.state.activeTab)[0]
    //   .LevelRates.map(x => {
    //     const o = {
    //       ...x
    //     };
    //     if (x.LevelId === id) {
    //       o["Amount"] = event.target.value;
    //     }
    //     return o;
    //   });
    // ////stateCopy.find(x => x.Id === id).Rate = event.target.value;
    // this.setState({ allowanceExpenseRate: stateCopy });
    var error = isEmpty(value) ? "Required" : "";
    this.setState({
      selectedLevelAmount: value,
      fieldError: error,
    });
  };

  handleTabChange = (id) => {
    var currentRates = this.state.allowanceExpenseRate.filter(
      (x) => x.AllowanceId === id
    );
    var isFlatRate =
      currentRates.length > 0 ? currentRates[0].IsFlatRate : true;
    this.setState(
      {
        activeTab: id,
        currentTabRateType: isFlatRate ? "1" : "2",
        activeTabLabel: this.state.allowanceExpenses.find((x) => x.Value === id)
          .Text,
      },
      () => (id !== 0 ? this.getLevelGridList() : {})
    );
  };

  handleRateTypeChange = (event) => {
    var stateCopy = Object.assign([], this.state.allowanceExpenseRate);
    stateCopy
      .filter((x) => x.AllowanceId === this.state.activeTab)
      .filter((x) => {
        x.IsFlatRate = event.target.value === "1" ? true : false;
      });
    this.setState(
      {
        currentTabRateType: event.target.value,
        allowanceExpenseRate: stateCopy,
      },
      () => this.getLevelGridList()
    );
    // var stateCopy = Object.assign([], this.state.allowanceExpenseRate);
    // var positionRates = stateCopy.filter(
    //   x => x.AllowanceId === this.state.activeTab
    // )[0].LevelRates;
    // //event.target.value === "1" ? [] : this.state.positionRates;
    // if (positionRates.length === 0 && event.target.value !== "1") {
    //   this.state.positionRates.map(x => {
    //     positionRates.push({ Id: 0, LevelId: x.Id, Amount: "" });
    //   });
    // }
    // stateCopy
    //   .filter(x => x.AllowanceId === this.state.activeTab)
    //   .filter(x => {
    //     x.IsFlatRate = event.target.value === "1" ? true : false;
    //     x.LevelRates = positionRates;
    //   });
    // this.setState({ allowanceExpenseRate: stateCopy });
  };

  getInputPositionRate = (AllowanceId, PositionId) => {
    var currentRates = this.state.allowanceExpenseRate.filter(
      (x) => x.AllowanceId === AllowanceId
    );
    return currentRates[0].LevelRates.length > 0
      ? "" +
          currentRates[0].LevelRates.find((x) => x.LevelId === PositionId)
            .Amount
      : "";
  };

  getInputFlatRate = (id) => {
    var rate = this.state.allowanceExpenseRate.find((y) => y.AllowanceId === id)
      .FlatRate;
    return isEmpty(rate) || rate === 0 ? "" : "" + rate;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.onSubmit(this.state);
  };

  handleReset = () => {
    this.props.reset();
    // this.setState(
    //   {
    //     activeTab: 0,
    //     allowanceExpenses: [{ Value: 0, Text: "Add New" }],
    //     allowanceExpenseRate: []
    //   },
    //   () => this.initialState()
    // );
    this.initialState();
  };
  initialState = () => {
    if (
      this.props.initialValues &&
      this.props.initialValues.allowanceExpenseRate.length > 0 &&
      this.props.allowanceExpense &&
      this.props.allowanceExpense.length > 0
    ) {
      const {
        CategoryId,
        CategoryName,
        Description,
        TravelCategoryType,
        TenantId,
      } = this.props.initialValues;
      var stateCopy = [];
      this.props.initialValues.allowanceExpenseRate.map((x) => {
        stateCopy.push({
          Value: x.AllowanceId,
          Text: this.props.allowanceExpense.find(
            (y) => y.Value == x.AllowanceId
          ).Text,
        });
        return null;
      });
      stateCopy.push({ Value: 0, Text: "Add New" });
      const isEdit = CategoryId !== 0 ? true : false;
      let activeTab = isEdit ? stateCopy[0].Value : 0;
      let isFlatRate = this.props.initialValues.allowanceExpenseRate.find(
        (x) => x.AllowanceId === stateCopy[0].Value
      ).IsFlatRate;
      this.setState(
        {
          formData: {
            CategoryId,
            CategoryName,
            Description,
            TravelCategoryType: parseInt(TravelCategoryType),
            TenantId: TenantId,
          },
          allowanceExpenses: stateCopy,
          allowanceExpenseRate: this.props.initialValues.allowanceExpenseRate.map(
            (x) => {
              const o = { ...x };
              return o;
            }
          ),
          activeTab,
          currentTabRateType: isFlatRate ? "1" : "2",
        },
        () => this.getLevelGridList()
      );
    }
  };

  closeModal = () => {
    this.setState({ isModalShow: false });
  };

  isValidForm = () => {
    //let valid = true;
    let errors = {};
    this.state.allowanceExpenseRate.map((x, i) => {
      if (x.IsFlatRate && isEmpty(x.FlatRate)) {
        //valid = false;
        errors[`FlatRate-${x.AllowanceId}`] = "Required";

        if (
          this.state.allowanceExpenseRate.length > 1 &&
          x.AllowanceId !== this.state.activeTab
        )
          errors.PositionRates = "Required";
        return errors;
        //return valid;
      } else if (!x.IsFlatRate && x.LevelRates.length <= 0) {
        errors.PositionRates = "Required";
        //valid = false;
        //return valid;
        return errors;
      }
      // else if (!x.IsFlatRate && x.LevelRates.length > 0) {
      //   x.LevelRates.map(y => {
      //     if (isEmpty(y.Amount)) {
      //       valid = false;
      //       return valid;
      //     }
      //   });
      // }
    });
    return errors;
  };

  onLevelChange = (data, actionType) => {
    let levelSelected = isEmpty(data) ? false : true;
    let levelRates = this.state.allowanceExpenseRate.filter(
      (x) => x.AllowanceId === this.state.activeTab
    )[0].LevelRates;
    let Id = levelSelected
      ? this.state.isLevelEdit
        ? this.state.selectedLevelGroupId
        : levelRates.length === 0
        ? 1
        : Math.max.apply(
            Math,
            levelRates.map((x) => {
              return x.GroupId;
            })
          ) + 1
      : 0;
    let selectedLevels = levelSelected
      ? data.map((x) => {
          x.Id = Id;
          return x;
        })
      : [];
    this.setState({
      isLevelSelected: levelSelected,
      selectedLevels: selectedLevels,
    });
  };

  Arrays = (data, name) => {
    let arrayItem = [];
    if (data) {
      data.map((item, key) => {
        arrayItem.push({ label: item.Text, value: item.Value });
        return null;
      });
    }
    return arrayItem;
  };

  handleLevelSave = () => {
    if (isEmpty(this.state.selectedLevelAmount)) {
      this.setState({ fieldError: "Required" });
    } else {
      let selectedLevels = this.state.selectedLevels.map((x) => {
        return {
          LevelId: x.value,
          Name: x.label,
          Amount: this.state.selectedLevelAmount,
          GroupId: x.Id,
        };
      });
      var stateCopy = Object.assign([], this.state.allowanceExpenseRate);
      stateCopy.filter(
        (x) => x.AllowanceId === this.state.activeTab
      )[0].LevelRates = stateCopy
        .filter((x) => x.AllowanceId === this.state.activeTab)[0]
        .LevelRates.filter((x) => x.GroupId !== this.state.selectedLevelGroupId)
        .concat(selectedLevels);
      // var mergedList = this.state.levelList
      //   .filter(x => x.GroupId !== this.state.selectedLevelGroupId)
      //   .concat(selectedLevels);

      this.setState(
        {
          allowanceExpenseRate: stateCopy,
          selectedLevels: [],
          selectedLevelAmount: "",
          isLevelSelected: false,
          isLevelEdit: false,
          selectedLevelGroupId: 0,
        },
        () => this.getLevelGridList()
      );

      //this.props.dispatch(change("travelCategoryForm", "PositionRate", ""));
    }
  };

  getLevelGridList = () => {
    let list = [];
    let activeAllowanceExpenseRate = this.state.allowanceExpenseRate.filter(
      (x) => x.AllowanceId === this.state.activeTab
    )[0];
    if (!isEmpty(activeAllowanceExpenseRate)) {
      activeAllowanceExpenseRate.LevelRates.map((x) => {
        const o = {
          ...x,
        };
        if (
          list.length !== 0 &&
          list.find((y) => y.GroupId === o.GroupId && y.LevelId !== o.LevelId)
        ) {
          list.find((y) => y.GroupId === o.GroupId).Name += ", " + o.Name;
        } else {
          list.push(o);
        }
        return null;
      });
    }
    list.sort((a, b) => (a.GroupId > b.GroupId ? 1 : -1));
    //return list;
    // let levelGridList={
    //   AllowanceId:this.state.activeTab,
    //   levelList:list
    // };
    this.setState({ levelGridList: list });
  };

  handleEditLevel = (data) => {
    let selectedLevels = [];
    let activeAllowanceExpense = this.state.allowanceExpenseRate.filter(
      (x) => x.AllowanceId === this.state.activeTab
    )[0];
    activeAllowanceExpense.LevelRates.map((x) => {
      if (x.GroupId === data.GroupId)
        selectedLevels.push({
          Id: x.GroupId,
          label: x.Name,
          value: x.LevelId,
        });

      return null;
    });
    this.setState({
      selectedLevels: selectedLevels,
      selectedLevelAmount: data.Amount,
      isLevelSelected: true,
      isLevelEdit: true,
      selectedLevelGroupId: data.GroupId,
    });
  };

  handleDeleteLevel = (data) => {
    var stateCopy = Object.assign([], this.state.allowanceExpenseRate);
    stateCopy.filter(
      (x) => x.AllowanceId === this.state.activeTab
    )[0].LevelRates = stateCopy
      .filter((x) => x.AllowanceId === this.state.activeTab)[0]
      .LevelRates.filter((x) => x.GroupId !== data.GroupId);
    this.setState(
      {
        levelList: stateCopy,
      },
      () => this.getLevelGridList()
    );
  };

  getLevelSelectList = () => {
    const levelDrpdwn = [];
    // let levels = [{ Value: 0, Text: "All other" }].concat(this.props.levels);
    let levels =
      this.state.selectedLevels.length === 0
        ? [{ value: 0, label: "All other" }].concat(this.state.levels)
        : this.state.selectedLevels[0].value === 0
        ? []
        : this.state.levels.concat();
    let currentRate = this.state.allowanceExpenseRate.filter(
      (x) => x.AllowanceId === this.state.activeTab
    );
    levels.map((x) => {
      if (
        this.state.allowanceExpenseRate.length === 0 ||
        currentRate.length === 0
      ) {
        levelDrpdwn.push(x);
      } else {
        if (currentRate[0].LevelRates.length === 0) {
          levelDrpdwn.push(x);
        } else {
          if (this.state.isLevelEdit) {
            let selectedLevels = this.state.selectedLevels.map((x) => {
              return {
                LevelId: x.value,
                Name: x.label,
                Amount: this.state.selectedLevelAmount,
                GroupId: x.Id,
              };
            });
            var stateCopy = Object.assign([], this.state.allowanceExpenseRate);
            let mergedList = stateCopy
              .filter((x) => x.AllowanceId === this.state.activeTab)[0]
              .LevelRates.filter(
                (x) => x.GroupId !== this.state.selectedLevelGroupId
              )
              .concat(selectedLevels);

            if (isEmpty(mergedList.find((y) => y.LevelId === x.value)))
              levelDrpdwn.push(x);
          } else {
            if (
              isEmpty(
                currentRate[0].LevelRates.find((y) => y.LevelId === x.value)
              )
            )
              levelDrpdwn.push(x);
          }
        }
      }
    });
    return levelDrpdwn;
  };

  removeAllowancExpense = (id) => {
    let { allowanceExpenses, activeTab, allowanceExpenseRate } = this.state;
    let newAllowanceExpense = allowanceExpenses.filter((x) => x.Value !== id);
    let newAllowanceExpenseRate = allowanceExpenseRate.filter(
      (x) => x.AllowanceId !== id
    );
    let activeTabId = activeTab;
    if (activeTab === id) {
      let indexOfEl = allowanceExpenses.findIndex((x) => x.Value == id);
      activeTabId = allowanceExpenses[indexOfEl + 1].Value;
    }
    this.setState(
      {
        allowanceExpenses: newAllowanceExpense,
        allowanceExpenseRate: newAllowanceExpenseRate,
      },
      () => this.handleTabChange(activeTabId)
    );
    //this.handleTabChange(activeTabId);
  };
  handleInputChange = (name, value) => {
    this.setFormState(name, value);
    let errors = {};
    if (isEmpty(value)) {
      errors.CategoryName = "Required";
    }
    this.setState({ errors });
  };
  handleRadioChange = (event) => {
    let { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [name]: parseInt(value),
      },
    }));
  };
  setFormState = (name, value) => {
    this.setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };
  handleSubmit = (event) => {
    const { travelCategory } = this.props;
    event.preventDefault();
    const { formData } = this.state;
    let errors = {};
    if (isEmpty(formData.CategoryName)) {
      errors.CategoryName = "Required";
    } else {
      if (
        travelCategory &&
        travelCategory.find(
          (x) =>
            x.CategoryId !== formData.CategoryId &&
            x.CategoryName === formData.CategoryName
        )
      ) {
        errors.CategoryName =
          "Category Name already taken. Please choose another name!";
      } else if (this.state.allowanceExpenseRate.length <= 0) {
        errors.AllowanceExpense = "Required";
        this.setState({
          warningMessage: "Please add atleast one Allowance/Expense",
          isModalShow: true,
        });
      } else {
        let rateErrors = this.isValidForm();

        if (!isEmpty(rateErrors)) {
          errors = { ...errors, ...rateErrors };
          if (!isEmpty(rateErrors.PositionRates))
            this.setState({
              warningMessage: "Please add atleast one rate",
              isModalShow: true,
            });
        }
      }
    }
    if (isEmpty(errors)) {
      this.props.onSubmit(formData, this.state.allowanceExpenseRate);
    }
    this.setState({ errors });
  };
  render() {
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
      allowanceExpense,
      travelCategory,
    } = this.props;
    const {
      allowanceExpenseRate,
      formData,
      errors,
      activeTab,
      allowanceExpenses,
      currentTabRateType,
    } = this.state;
    const plus = (
      <div className="flex">
        <FiPlus />
        <span>Add new</span>
      </div>
    );
    const levelDrpdwn = this.getLevelSelectList();

    const filterDrpdwn = [];
    this.state.allowanceExpenseSelectList.map((x) => {
      if (this.state.allowanceExpenseRate.length === 0) {
        filterDrpdwn.push(x);
      } else {
        if (
          isEmpty(
            this.state.allowanceExpenseRate.find(
              (y) => y.AllowanceId === x.value
            )
          )
        )
          filterDrpdwn.push(x);
      }
      return null;
    });

    return (
      <div>
        <form
          onSubmit={(values) => this.handleSubmit(values)}
          className="travel-category-form"
        >
          <input type="hidden" name="CategoryId"></input>
          <div className="form-body">
            <div className="form-row">
              <FormGroup
                name="CategoryName"
                type="text"
                error={errors.CategoryName}
                label="Category name"
                value={formData.CategoryName}
                onChange={this.handleInputChange}
                width="250px"
              />
              {/* <Field
                name="CategoryName"
                component={renderTextField}
                label=""
                validate={[required]}
                type="text"
                width="250px"
              /> */}
            </div>

            <div className="form-row">
              <div className="filters-input__item">
                <RadioGroup
                  name="TravelCategoryType"
                  onChange={this.handleRadioChange}
                  value={formData.TravelCategoryType}
                  className="action-radio"
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="Domestic"
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio />}
                    label="International"
                  />
                </RadioGroup>
              </div>
            </div>

            <div className="form-row">
              <div className="form-label">
                <span>Description</span>
              </div>
              <FormGroup
                formName="textarea"
                name="Description"
                error={errors.Description}
                value={formData.Description}
                onChange={this.handleInputChange}
                rows={6}
                cols={60}
              />
              {/* <Field
                name="Description"
                component={renderTextArea}
                rows={6}
                cols={60}
              /> */}
            </div>
            <div className="form-row">
              <div className="form-label">
                <span>Allowances and expenses applicable to this category</span>
              </div>
              <Tabs type="block" noBackground activeTab={activeTab}>
                {allowanceExpenses.map((x, i) => {
                  return x.Value === 0 ? (
                    <Tab
                      label={plus}
                      name={x.Value}
                      id={x.Value}
                      key={i}
                      isActive={activeTab === x.Value}
                      onClick={(id) => this.handleTabChange(x.Value)}
                    >
                      <div className="form-row">
                        <div className="filters-input__item">
                          <FormGroup
                            formName="reactselect"
                            label="Expense and allowances"
                            name="allowanceExpense"
                            onChange={this.onSelectChange}
                            value={this.state.allowanceExpense}
                            width="200px"
                            options={filterDrpdwn}
                          />
                          {/* <AutoSelect
                            onChange={this.onSelectChange}
                            value={this.state.allowanceExpense}
                            options={filterDrpdwn}
                            label="Expense and allowances"
                            name="allowanceExpense"
                            width="200px"
                          /> */}
                        </div>
                      </div>
                    </Tab>
                  ) : (
                    <Tab
                      label={
                        <div className="tab-title">
                          <span>{x.Text}</span>
                          <IoIosClose
                            onClick={() => this.removeAllowancExpense(x.Value)}
                            id="closeIcon"
                          />
                        </div>
                      }
                      key={i}
                      name={x.Value}
                      id={x.Value}
                      isActive={activeTab === x.Value}
                      onClick={(id) => this.handleTabChange(x.Value)}
                    >
                      <>
                        <div className="form-row">
                          <RadioGroup
                            name={`RateType${x.Value}`}
                            onChange={(event) =>
                              this.handleRateTypeChange(event)
                            }
                            value={
                              this.state.allowanceExpenseRate.find(
                                (y) => y.AllowanceId === x.Value
                              ).IsFlatRate
                                ? "1"
                                : "2"
                            }
                          >
                            <FormControlLabel
                              value="1"
                              control={<Radio />}
                              label="Flat rate for all"
                            />
                            <FormControlLabel
                              value="2"
                              control={<Radio />}
                              label="Rate based on position"
                            />
                          </RadioGroup>
                        </div>
                        {this.state.currentTabRateType === "1" ? (
                          <div className="form-row">
                            <FormGroup
                              name={`FlatRate-${x.Value}`}
                              label="Rate"
                              value={this.getInputFlatRate(x.Value)}
                              onChange={this.onFlatRateChange}
                              width="250px"
                              type="number"
                              width="100px"
                              error={errors[`FlatRate-${x.Value}`]}
                            />
                            {/* <Field
                            name={`FlatRate-${x.Value}`}
                            component={renderTextField}
                            label=""
                            selectedValue={this.getInputFlatRate(x.Value)}
                            validate={[requiredRate]}
                            onChange={() => this.onFlatRateChange()}
                            type="number"
                            width="100px"
                          /> */}
                          </div>
                        ) : (
                          <div className="level-section">
                            <div className="flex">
                              <div className="filters-input__item level-select">
                                {/* <div className="form-label">
                                <span>Select position</span>
                              </div> */}
                                <Select
                                  closeMenuOnSelect={false}
                                  isMulti
                                  value={this.state.selectedLevels}
                                  options={levelDrpdwn}
                                  className="multiselect"
                                  onChange={this.onLevelChange}
                                  placeholder="Select position..."
                                />
                              </div>
                              {this.state.isLevelSelected ? (
                                <div className="filters-input__item level-input">
                                  <FormGroup
                                    name={`PositionRate-${x.Value}`}
                                    type="number"
                                    size="med"
                                    error={this.state.fieldError}
                                    label="Amount"
                                    value={this.state.selectedLevelAmount}
                                    onChange={this.onPositionRateChange}
                                  />
                                  {/* <InputGroup
                                  name={`PositionRate-${x.Value}`}
                                  // component={renderTextField}
                                  label="Amount"
                                  error={this.state.fieldError}
                                  // validate={[requiredRate]}
                                  value={this.state.selectedLevelAmount}
                                  type="number"
                                  onChange={() => this.onPositionRateChange()}
                                /> */}
                                  <button
                                    type="button"
                                    className="footer__save button button-white"
                                    onClick={this.handleLevelSave}
                                  >
                                    Add
                                  </button>
                                </div>
                              ) : null}
                            </div>
                            <div className="form-row">
                              <div className="position-grid">
                                <div className="position-grid__header">
                                  <div>
                                    <span>Position</span>
                                  </div>
                                  <div>
                                    <span>Rate</span>
                                  </div>
                                  <div>
                                    <span></span>
                                  </div>
                                </div>
                                <div className="position-grid__body">
                                  {this.state.levelGridList.map((p) => {
                                    return (
                                      <div className="grid-row" key={p.Id}>
                                        <div>
                                          <span>{p.Name}</span>
                                        </div>
                                        <div className="grid-row__amount">
                                          <span>{p.Amount}</span>
                                        </div>
                                        <div>
                                          <FaRegEdit
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              this.handleEditLevel(p)
                                            }
                                          />
                                        </div>
                                        <div>
                                          <AiOutlineDelete
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              this.handleDeleteLevel(p)
                                            }
                                          />
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    </Tab>
                  );
                })}
              </Tabs>
            </div>
            <div className="form-row footer-grp button-group justify-center">
              <button
                type="button"
                className="footer__cancel button button-white"
                disabled={submitting}
                onClick={() => this.props.handleModalShow(false, "normal")}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="footer__save button button-primary"
                disabled={submitting}
              >
                Save
              </button>
              {/* <button
                type="button"
                className="footer__cancel btn btn-del"
                disabled={submitting}
                onClick={this.handleReset}
              >
                Reset
              </button> */}
            </div>
          </div>
        </form>
        <Modal
          title={""}
          open={this.state.isModalShow}
          onModalClose={this.closeModal}
          type="dangerContent"
          className="modal-warn"
          title="Warning"
          content={this.state.warningMessage}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allowanceExpense: state.travelCategoryReducer.allowanceExpense,
  levels: state.travelCategoryReducer.levels,
  //formData: state.travelCategoryReducer.travelCategoryFormData
  initialValues: state.travelCategoryReducer.travelCategoryFormData,
  travelCategory: state.travelCategoryReducer.travelCategory,
});

export default connect(mapStateToProps, {})(TravelCategoryForm);
