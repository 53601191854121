import React, { useState } from "react";
import FormGroup from "components/form";
import { connect } from "react-redux";
import isEmpty from "helpers/isEmpty";

const CustomFieldSection = (props) => {
  const { data, onInputChange, onEnter, isValidUser, board } = props;
  const [edited, setEdited] = useState(false);

  const onBlur = (name, value) => {
    console.log(name, value, edited);
    edited && onEnter && onEnter({ key: "Enter" }, name, value);
    setEdited(false);
  };
  const onChange = (name, value) => {
    setEdited(true);
    onInputChange(name, value);
  };
  const handleKeyUp = (e, name) => {
    if (e.key === "Enter") {
      onEnter(e, "CustomField1", data.TmTaskDetailsDto.CustomField1);
      setEdited(false);
    }
  };

  const showCustomFields = () => {
    return (
      !isEmpty(board.FieldLabel1) ||
      !isEmpty(board.FieldLabel2) ||
      !isEmpty(board.FieldLabel3)
    );
  };
  return showCustomFields() ? (
    <div className="taskmodal__customFields">
      <h4>Custom Fields</h4>

      <div className="taskmodal__customFields-controls ">
        {!isEmpty(board.FieldLabel1) && (
          <FormGroup
            name="CustomField1"
            disabled={!isValidUser()}
            label={board.FieldLabel1}
            placeholder={board.FieldLabel1}
            onChange={onChange}
            value={data.TmTaskDetailsDto.CustomField1 || ""}
            onKeyUp={(e) => handleKeyUp(e, "CustomField1")}
            onBlur={onBlur}
          />
        )}
        {!isEmpty(board.FieldLabel2) && (
          <FormGroup
            name="CustomField2"
            disabled={!isValidUser()}
            label={board.FieldLabel2}
            placeholder={board.FieldLabel2}
            onChange={onChange}
            value={data.TmTaskDetailsDto.CustomField2 || ""}
            onKeyUp={(e) => handleKeyUp(e, "CustomField2")}
            onBlur={onBlur}
          />
        )}
        {!isEmpty(board.FieldLabel3) && (
          <FormGroup
            name="CustomField3"
            disabled={!isValidUser()}
            label={board.FieldLabel3}
            placeholder={board.FieldLabel3}
            onChange={onChange}
            value={data.TmTaskDetailsDto.CustomField3 || ""}
            onKeyUp={(e) => handleKeyUp(e, "CustomField3")}
            onBlur={onBlur}
          />
        )}
      </div>
    </div>
  ) : null;
};
const mapStateToProps = (state) => ({ board: state.taskReducer.board });
export default connect(mapStateToProps, {})(CustomFieldSection);
