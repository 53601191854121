import React, { useEffect } from 'react'
import FormGroup from 'components/formGroup'
import FormItem from 'components/formGroup/formItem';
import Button from 'components/button';
import { useSelector, useDispatch } from 'react-redux';
import { addRosterShiftPattern, updateRosterShiftPattern } from '../helpers/action';
import { useForm } from 'react-hook-form';

function AddShiftTemplate(props) {
    const { onModalClose, isEdit = false, editData = null, } = props;
    const {
        shiftList,
        teamList,
    } = useSelector((state: any) => state.roosterReducer);
    const method = useForm()
    const {
        watch,
        setValue,
    } = method
    console.log({ editData })
    const dispatch = useDispatch();
    const onSubmit = (data) => {
        const final = {
            Id: editData?.Id ?? 0,
            ...data,
            TeamId: data?.TeamId ? data?.TeamId.value : null
        }
        let res = null;
        if (isEdit) {
            res = dispatch(updateRosterShiftPattern(final))
        } else {
            res = dispatch(addRosterShiftPattern(final))
        }

        if (res) {
            onModalClose && onModalClose()
        }
    }
    useEffect(() => {
        if (isEdit && editData) {
            editData && Object.keys(editData).map(item => {
                if (item === 'TeamId') {
                    const teamFind = teamList.find(team => team.Id === editData[item])
                    setValue("TeamId", teamFind ? { label: teamFind.Name, value: teamFind.Id } : {})
                    return;
                }
                setValue(item, editData[item])
            });
        }
    }, [isEdit, editData])
    return (
        <div className="shifts-add" >
            <FormGroup method={method} onSubmit={onSubmit}>
                <div className="shifts-add__row">
                    <FormItem
                        label="Shift Pattern Name"
                        name="Name"
                        rules={{ required: 'Shift Pattern Name is required!' }}
                        width="250px"
                    />
                </div>
                <div className="shifts-add__row">
                    <FormItem
                        label="Description"
                        name="Description"
                        width="350px"
                    />
                </div>
                
                <div className="shifts-add__row">
                    <FormItem
                        label="No of Days in Pattern"
                        name="TotalNoOfDays"
                        // options={[]}
                        type="number"
                        rules={{
                            required: 'Total No Of Days Required',
                            validate: {
                                min: v => v > 0 || "Total No Of Days should be greater than 0",
                                max: v => v < 100 || "Total No Of Days should be less than 100",
                            }
                        }}
                        // formName={"reactselect"}
                        width="150px"
                    />
                </div>
                <div className="shifts-add__row shifts-add__row-btns flex">
                    <Button onClick={() => onModalClose()} bg="subtle" title="Cancel" />
                    <Button htmlButtonType="submit" bg="primary" title="Save" />
                </div>
            </FormGroup>
        </div>
    )
}

export default AddShiftTemplate
