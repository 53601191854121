import React from 'react'
import Button from 'components/button'
import FormGroup from 'components/form'
import Modal from 'components/modal';
import { GetEmployeeList } from "actions/commonAction";
import {
  UpdateOnboardingEmployee,
  GetOnboardingEmployeeDetail,
  GetEmployeeEmailPreview
} from "../action";
import { connect } from 'react-redux';
import { DisplayClientSideMessage } from "actions/commonAction";
class WelcomeMessage extends React.Component {
  state = {
    paragraph: "",
    messageTemplate: [],
    messageTemplates: [],
    errors: {},
    WelcomeMessage: null,
    previewMail: false,
  }
  async componentDidMount () {
    // if (!onboardingEmployeeDetail) {
    await this.props.GetOnboardingEmployeeDetail(this.props.match.params.id);
    //   onboardingEmployeeDetail = this.props.onboardingEmployeeDetail;
    // }
    let { onboardingEmployeeDetail } = this.props
    // onboardingEmployeeDetail =  this.props.onboardingEmployeeDetail;
    if (onboardingEmployeeDetail) {
      const { EmployeeOnBoardingDto: {
        ManagerId,
        WelcomeMessage
      } } = onboardingEmployeeDetail;
      this.setState({ WelcomeMessage, dataToSubmit: onboardingEmployeeDetail.EmployeeOnBoardingDto, ManagerId })
    }
    this.props.changeActiveTab('1');

  }

  handleFieldChange = (name, value) => {
    this.setState({ [name]: value })
  }

  handleFormSubmit = (stage) => {
    const { dataToSubmit, WelcomeMessage, ManagerId } = this.state;
    let data = {
      ...dataToSubmit,
      WelcomeMessage,
    }
    if (ManagerId == 0) {
      this.props.DisplayClientSideMessage('Fill in the details on Date and Place tab');
      return;
    } else 
      this.props.handleSubmit(data, '1', stage);
  }

  onClose = () => {
    this.props.history.push('/admin/employee_onboarding/');
  }

  handlePreviewMail = async () => {
    await this.props.GetOnboardingEmployeeDetail(this.props.match.params.id)
    let { onboardingEmployeeDetail } = this.props;
    if (onboardingEmployeeDetail) {
      let EmployeeOnBoardingDto = {
        ...onboardingEmployeeDetail.EmployeeOnBoardingDto,
        WelcomeMessage: this.state.WelcomeMessage,
      }
      let data = {
        AddressDto: onboardingEmployeeDetail.AddressDto,
        EmployeeDto: onboardingEmployeeDetail.EmployeeDto,
        EmployeeOnBoardingDto,
        EmployeePhotoDto: onboardingEmployeeDetail.EmployeePhotoDto,
      };
      await this.props.GetEmployeeEmailPreview(data);
      this.setState({ previewMail: true })
      

    }
    // if (onboardingEmployeeDetail) {
    //   data.app = "s"
    // }
      // data.EmployeeOnBoardingDto.WelcomeMessage = this.state.WelcomeMessage;

  }
  onModalClose = () => {
    this.setState({ previewMail: false });
  }

  render() {
    const {
      messageTemplate, messageTemplates, errors,
      WelcomeMessage, previewMail
    } = this.state;
    const { employeePreviewEmail } = this.props;
    return (<div className="date-and-place">
      {employeePreviewEmail && 
      <Modal 
      open={previewMail}
      onModalClose={this.onModalClose}
      title="E-mail Previewer"
      width="800px"
      className="previewMail"
      ><p 
            style={{height:'auto'}}
            dangerouslySetInnerHTML={{ __html: employeePreviewEmail }}>
          </p>
          </Modal>
          }
      <div className="date-and-place__container">
        <div className="date-and-place__body">
          <div className="date-and-place__header">
            WELCOME MESSAGE
          </div>
          <div className="date-and-place__header">
            <FormGroup
              formName="reactselect"
              onChange={(name, value) => this.handleFieldChange(name, value)}
              name="messageTemplate"
              value={messageTemplate}
              options={messageTemplates}
              // loading={jobProfileList.loading}
              loadingType="skeleton"
              placeholder="Message template"
              // disabled={isViewMode}
              // validators={["required"]}
              // error={errors?.messageTemplate}
              width="250px"
              // label="Salary template"
            />
          </div>
          <div className="date-and-place mb-20">
            <FormGroup
              formName="texteditor"
              value={WelcomeMessage}
              toolbarId={"welcomeMessage"}
              placeholder={"Write a welcome message"}
              name="WelcomeMessage"
              // validators={['required']}
              onChange={(name, value) => {
                this.handleFieldChange(name, value)
                // this.props.handleComponentFieldChange(
                //   profileBlockType,
                //   x.id,
                //   name,
                //   value
                // );
              }}
              errors={errors && errors.WelcomeMessage}
              width="60%"
              
            />
          </div>
          <div className="date-and-place__footer">
            <Button bg="primary" title="Save and Send"  onClick={() => this.handleFormSubmit(3)}/>
            <Button bg="primary" title="Save"  onClick={() => this.handleFormSubmit(2)}/>
            <Button bg="primary" title="Preview"  onClick={() => this.handlePreviewMail()}/>
            <Button bg="subtle" onClick={()=> this.onClose()} title="Cancel" />
          </div>
        </div>
      </div>
    </div>)
  }
}


WelcomeMessage = connect((state) => ({
  employeeListSelect: state.commonReducer.employeeListSelect,
  companyInformation: state.commonReducer.companyInformation,
  onboardingEmployeeDetail: state.employeeOnboarding.onboardingEmployeeDetail,
  companyInformation: state.commonReducer.companyInformation,
  employeePreviewEmail: state.employeeOnboarding.employeePreviewEmail,

}),
  {
    GetEmployeeList,
    UpdateOnboardingEmployee,
    GetOnboardingEmployeeDetail,
    GetEmployeeEmailPreview,
    DisplayClientSideMessage,
  }
)(WelcomeMessage);

export default WelcomeMessage