import types from "constants/types";

const initialState = {
  sbiLoanSettingList: [],
  sbiLoanTypeList: [],
  sbiSettingApprovalFlow: [],
  // employeeListForSbiLoanTest: [],
  sbiLoanStatusHistory: [],
  exportSbiLoanStatusHistory: [],
  sbiLoanApproversList: [],
  forwardSbiLoan: [],
  sbiLoanTypes: [],
};

export default function (state: any = initialState, action: any) {
  switch (action.type) {
    case types.GET_SBI_LOAN_SETTING_LIST: {
      return {
        ...state,
        sbiLoanSettingList: action.payload,
      };
    }
    case types.GET_SBI_LOAN_TYPE_LIST: {
      return {
        ...state,
        sbiLoanTypeList: action.payload,
      };
    }
    case types.GET_SBI_SETTINGS_APPROVAL_FLOW: {
      return {
        ...state,
        sbiSettingApprovalFlow: action.payload,
      };
    }
    // case types.GET_SBI_ALL_EMPLOYEES_SPECIFIC: {
    //   return {
    //     ...state,
    //     employeeListForSbiLoanTest: action.payload,
    //   };
    // }
    case types.EXPORT_SBI_LOAN_STATUS_HISTORY: {
      return {
        ...state,
        exportSbiLoanStatusHistory: action.payload,
      };
    }
    case types.GET_SBI_LOAN_STATUS_HISTORY: {
      return {
        ...state,
        sbiLoanStatusHistory: action.payload,
      };
    }
    case types.GET_SBI_LOAN_APPROVERS_LIST: {
      return {
        ...state,
        sbiLoanApproversList: action.payload,
      };
    }
    case types.FORWARD_SBI_LOAN: {
      return {
        ...state,
        forwardSbiLoan: action.payload,
      };
    }
    case types.REJECT_SBI_LOAN: {
      return {
        ...state,
        rejectSbiLoan: action.payload,
      }
    }
    case types.GET_SBI_LOAN_TYPES: {
      return {
        ...state,
        sbiLoanTypes: action.payload,
      };
    }
    default:
      return state;
  }
}
