import React, { Component } from "react";
import { connect } from "react-redux";
import SearchAndFilter from "./searchAndFilter";
import MyLoanListingTable from "./table";
import Breadcrumb from "../../common/breadcrumb";
import Loaders from "components/loaders";
import RouteBaseClass from "components/routeBaseClass";
import Title from "../../common/title";
import ExportExcel from "components/common/exportExcel";
import { getEmployeeForwaredListToReview } from "../helpers/action";
import "../style.scss";
import isEmpty from "helpers/isEmpty";
import moment from 'moment';
export class index extends RouteBaseClass {
  constructor(props) {
    super(props, ["Employee", "Administrator"]);
    this.state = {
      isLoading: true,
      StartDate: "",
      EndDate: "",
      PageIndex: 0,
      PageSize: 10,
      searchEmployee: "",
      LoanType: "",
      LoanStatus: "",
      EmployeeName: "",
      exportName: "Loan Requests",
      exportData: [],
      exportColumns: [
        { label: "Employee Name", style: {font: {bold: true}}, width: {wpx: 250}, value: "EmployeeName" },
        { label: "Loan Type", style: {font: {bold: true}}, value: "LoanTypeName" },
        { label: "Loan Purpose", value: "LoanPurpose" },
        { label: "Requested on", value: "RequestedOn" },
        { label: "Forwarded to", value: "ForwardedToName" },
        { label: "Amount", value: "RequestAmount" },
      ],
    };
  }

  // async componentDidMount() {
  //   await this.getUnsubmittedActivities();
  // }

  componentDidMount() {
    this.getEmployeeForwaredListToReview();
  }

  getEmployeeForwaredListToReview = async () => {
    const {
      PageIndex,
      PageSize,
      EmployeeName,
      LoanType,
      StartDate,
      EndDate,
      LoanStatus,
    } = this.state;
    const {
      user: { EmployeeId },
    } = this.props;
    let data = {
      EmployeeId: 0,
      Status1: 0,
      Status2: !isEmpty(LoanStatus) ? LoanStatus.value : 0,
      EmployeeName,
      LoanType: !isEmpty(LoanType) ? LoanType.value : "",
      IsSelfForward: false,
      StartDate,
      EndDate,
      PageIndex,
      PageSize,
    };
    await this.props.getEmployeeForwaredListToReview(data);
    this.setState({ isLoading: false });
  };

  handleRowChange = (num) => {
    this.setState({ PageSize: num }, async () => {
      await this.getEmployeeForwaredListToReview();
    });
  };

  handleIndexChange = (num) => {
    this.setState({ PageIndex: num }, async () => {
      await this.getEmployeeForwaredListToReview();
    });
  };

  handleTabChange = (tab) => {
    this.setState({ PageIndex: 0 }, async () => {
      await this.getEmployeeForwaredListToReview();
    });
  };

  handleInput = (name, value) => {
    this.setState({ [name]: value, PageIndex: 0 }, async () => {
      await this.getEmployeeForwaredListToReview();
    });
  };

  handleDateRangePicker = (name, date) => {
    const { startDate, endDate } = date;
    this.setState(
      { StartDate: startDate, EndDate: endDate, PageIndex: 0 },
      async () => {
        await this.getEmployeeForwaredListToReview();
      }
    );
  };

  handleExcelExport = async () => {
    const {
      PageIndex,
      EmployeeName,
      LoanType,
      StartDate,
      EndDate,
      LoanStatus,
    } = this.state;
    let searchData = {
      EmployeeId: 0,
      Status1: 0,
      Status2: !isEmpty(LoanStatus) ? LoanStatus.value : 0,
      EmployeeName,
      LoanType: !isEmpty(LoanType) ? LoanType.value : "",
      IsSelfForward: false,
      StartDate,
      EndDate,
      PageIndex,
      PageSize: 99999,
    };
    let result = await this.props.getEmployeeForwaredListToReview(
      searchData,
      true
    );
    if (result.Status) {
      let data = result.Data.map((x) => {
        x.RequestedOn = moment(
          new Date(x.RequestedOn),
          "YYYYMMDDHHmmss"
        ).format("MMMM D, YYYY");
        return x;
      });
      this.setState({ exportData: data });
    }
  };

  render() {
    const {
      isLoading,
      PageIndex,
      PageSize,
      exportName,
      exportColumns,
      exportData,
    } = this.state;
    const {
      forwardedLoanListToReview,
    } = this.props;
    return (
      <div className="common-container homeloan-appliedlist">
        {/* <Title name="My Loan Requests" parentProps={this.props} /> */}
        <div className="tasktracker-title-section">
          {/* <Breadcrumb name="Loan Management" /> */}
        </div>
        <SearchAndFilter
          handleAutoSelect={this.handleInput}
          parentState={this.state}
          handleDateRangePicker={this.handleDateRangePicker}
          handleExcelExport={this.handleExcelExport}
          exportName={exportName}
          exportData={exportData}
          exportColumns={exportColumns}
          // isFinance={isFinance}
          // activeStageId={activeStageId}
          // EmployeeName={EmployeeName}
        />
        {isLoading ? (
          <Loaders loading={isLoading} type="skeleton" name="custom-table" />
        ) : (
          <MyLoanListingTable
            data={forwardedLoanListToReview}
            pageIndex={PageIndex}
            rowsPerPage={PageSize}
            handleRowChange={this.handleRowChange}
            handleIndexChange={this.handleIndexChange}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  unSubmittedActivities: state.taskTrackerReducer.unSubmittedActivities,
  unSubmittedActivitiesExportExcel:
    state.taskTrackerReducer.unSubmittedActivitiesExportExcel,
  employeeAppliedLoanList: state.loanReducer.employeeAppliedLoanList,
  user: state.auth.user,
  forwardedLoanListToReview: state.loanReducer.forwardedLoanListToReview,
});

const mapDispatchToProps = {
  // GetUnsubmittedActivities,
  getEmployeeForwaredListToReview,
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
