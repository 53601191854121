import {
  GET_HOME_LOAN_PURPOSE_LIST,
  GET_CONSTRUCTION_STAGES,
  SAVE_HOME_LOAN_REQUEST,
  GET_HOME_LOAN_DETAILS,
  GET_VEHICLE_LOAN_PURPOSE_LIST,
  SAVE_VEHICLE_LOAN_REQUEST,
  GET_VEHICLE_LOAN_DETAILS,
  GET_STAFFOD_LOAN_PURPOSE_LIST,
  SAVE_STAFFOD_LOAN_REQUEST,
  GET_STAFFOD_LOAN_DETAILS,
  SAVE_STAFF_PERSONAL_LOAN_REQUEST,
  GET_STAFF_PERSONAL_LOAN_DETAILS,
  GET_EMPLOYEE_OUTSTANDING_LOAN,
  GET_PAYROLL_LOAN_HEADINGS,
  PULL_EMPLOYEE_OUTSTANDING_LOAN,
  SAVE_EMPLOYEE_OUTSTANDING_LOAN,
  SAVE_MULTIPLE_OUTSTANDING_LOAN,
  EMPLOYEE_DETAIL_FOR_LOAN,
  CHANGE_LOAN_RECOMMENDATION,
  GET_LOAN_LIST_FOR_ADMIN,
  GET_PRIME_LOAN_SALARY_HEAD,
  EMPLOYEE_LOAN_PULLED_STATUS,
  DELETE_PRIME_OUTSTANDING_LOAN,
  SAVE_MULTIPLE_PRIMELOAN_SALARY_HEAD,
  EMPLOYEE_APPLIED_LOAN_LIST,
  GET_LOAN_APPROVER_LIST,
  FORWARD_LOAN_TO_HR,
  FORWARD_LOAN_TO_FINANCE,
  FORWARD_LOAN_TO_CAD,
  GET_FORWARED_LOAN_LIST_TO_REVIEW,
  SET_USER_LOAN_MODULE_ROLE,
  APPROVE_OR_REJECT_LOAN_STATUS,
  ASSIGN_FORWARED_LOAN,
  PRIME_LOAN_COMMENTS,
  ADD_PRIME_LOAN_COMMENT,
  DELETE_PRIME_LOAN_COMMENTS,
  PRIME_LOAN_PRINT_DETAIL,
} from "actions/types";

const initialState = {
  saveHomeLoan: false,
  employeeLoanPulledStatus: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_HOME_LOAN_PURPOSE_LIST:
      return {
        ...state,
        homeLoanPurposeList: action.payload,
      };
    case GET_CONSTRUCTION_STAGES:
      return {
        ...state,
        constructionStages: action.payload,
      };
    case SAVE_HOME_LOAN_REQUEST:
      return {
        ...state,
        saveHomeLoan: action.payload,
      };
    case GET_HOME_LOAN_DETAILS:
      return {
        ...state,
        homeLoanDetail: action.payload,
      };
    case GET_VEHICLE_LOAN_PURPOSE_LIST:
      return {
        ...state,
        vehicleLoanPurposeList: action.payload,
      };
    case SAVE_VEHICLE_LOAN_REQUEST:
      return {
        ...state,
        vehicleLoanSaved: action.payload,
      };
    case GET_VEHICLE_LOAN_DETAILS:
      return {
        ...state,
        vehicleLoanDetail: action.payload,
      };
    case GET_STAFFOD_LOAN_PURPOSE_LIST:
      return {
        ...state,
        staffODLoanPurposeList: action.payload,
      };
    case SAVE_STAFFOD_LOAN_REQUEST:
      return {
        ...state,
        staffODLoanSaved: action.payload,
      };
    case GET_STAFFOD_LOAN_DETAILS:
      return {
        ...state,
        staffODLoanDetail: action.payload,
      };
    case SAVE_STAFF_PERSONAL_LOAN_REQUEST:
      return {
        ...state,
        staffPersonalLoanSaved: action.payload,
      };
    case GET_STAFF_PERSONAL_LOAN_DETAILS:
      return {
        ...state,
        staffPersonalLoanDetail: action.payload,
      };
    case GET_EMPLOYEE_OUTSTANDING_LOAN:
    case GET_EMPLOYEE_OUTSTANDING_LOAN:
      return {
        ...state,
        employeeOutstandingLoan: action.payload, // employee outstanding loan fetchedn initially
      };
    case GET_PAYROLL_LOAN_HEADINGS:
      return {
        ...state,
        payrollLoanHeadings: action.payload, // employee outstanding loan fetchedn initially
      };
    case PULL_EMPLOYEE_OUTSTANDING_LOAN:
      return {
        ...state,
        employeePayrollOutstandingLoan: action.payload, // employee outstanding loan fetched from payroll
      };
    case SAVE_EMPLOYEE_OUTSTANDING_LOAN:
      return {
        ...state,
        employeeOutstandingLoanSaved: action.payload,
      };
    case SAVE_MULTIPLE_OUTSTANDING_LOAN:
      return {
        ...state,
        multipleOutstandingLoanSaved: action.payload,
      };
    case EMPLOYEE_DETAIL_FOR_LOAN:
      return {
        ...state,
        employeeDetailForLoan: action.payload,
      };
    case CHANGE_LOAN_RECOMMENDATION:
      return {
        ...state,
        Recommendation: action.payload,
      };
    case GET_LOAN_LIST_FOR_ADMIN:
      return {
        ...state,
        loanListForAdmin: action.payload,
      };
    case GET_PRIME_LOAN_SALARY_HEAD:
      return {
        ...state,
        employeePrimeLoanSalary: action.payload,
      };
    case EMPLOYEE_LOAN_PULLED_STATUS:
      return {
        ...state,
        employeeLoanPulledStatus: action.payload,
      };
    case DELETE_PRIME_OUTSTANDING_LOAN:
      return {
        ...state,
        primeOutstandingLoanDeleted: action.payload,
      };
    case SAVE_MULTIPLE_PRIMELOAN_SALARY_HEAD:
      return {
        ...state,
        multiplePrimeLoanSalaryHeadSaved: action.payload,
      };
    case EMPLOYEE_APPLIED_LOAN_LIST:
      return {
        ...state,
        employeeAppliedLoanList: action.payload,
      };
    case GET_LOAN_APPROVER_LIST:
      return {
        ...state,
        loanApproverList: action.payload,
      };
    case FORWARD_LOAN_TO_HR:
      return {
        ...state,
        forwaredToHR: action.payload,
      };
    case FORWARD_LOAN_TO_FINANCE:
      return {
        ...state,
        forwaredToFinance: action.payload,
      };
    case FORWARD_LOAN_TO_CAD:
      return {
        ...state,
        forwaredToCAD: action.payload,
      };
    case GET_FORWARED_LOAN_LIST_TO_REVIEW:
      return {
        ...state,
        forwardedLoanListToReview: action.payload,
      };
    case SET_USER_LOAN_MODULE_ROLE:
      return {
        ...state,
        isHR: action.payload,
      };
    case APPROVE_OR_REJECT_LOAN_STATUS:
      return {
        ...state,
        loanApprovedStatus: action.payload,
      };
    case ASSIGN_FORWARED_LOAN:
      return {
        ...state,
        isLoanAssigned: action.payload,
      };
    case PRIME_LOAN_COMMENTS:
      return {
        ...state,
        loanComments: action.payload,
      };
    case ADD_PRIME_LOAN_COMMENT:
      return {
        ...state,
        isCommentAdded: action.payload,
      };
    case DELETE_PRIME_LOAN_COMMENTS:
      return {
        ...state,
        isCommentDeleted: action.payload,
      };
    case PRIME_LOAN_PRINT_DETAIL:
      return {
        ...state,
        primeLoanPrintDetail: action.payload,
      };
    default:
      return state;
  }
}
