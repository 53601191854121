import React, { Component } from "react";
import { trash } from "assets/images/svgs/icons/trash";
import { email } from "assets/images/svgs/icons/email";
import emailOpen from "assets/images/svgs/icons/emailOpen";
import isEmpty from "isEmpty";
import { getEmployeeNotices } from "../action";
import { connect } from "react-redux";
import { formatedShortMonthDayYear } from "helpers/dateFormat";

export class News extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height:false
    };
  }
  componentDidMount() {
    this.props.getEmployeeNotices();
  }
  render() {
    const { employeeNotices } = this.props;
    return (
      <div className="news">
        <h3 className="mypage-section-title">Notices</h3>
        {isEmpty(employeeNotices) ? (
          <div className="no-found-container">
            <span className="no-found">No Notices Found</span>
          </div>
        ) : (
          <div className="news-container">
            <ul className="news-list">
              {employeeNotices && employeeNotices.map(item=><RenderNews item={item} />)}
            </ul>
          </div>
        )}
      </div>
    );
  }
}


const RenderNews = ({item}) =>{
  const [height,setHeight] = React.useState(false);
  return(
    <li className="news-list__item">
    <div className="newsItem">
      <div className="newsItem__notice">
        <p className="notice-title">{item.CategoryName}</p>
        <span className="notice-time">
          {formatedShortMonthDayYear(item.PublishDate)}
        </span>
      </div>
      <div className="newsItem__data">
        <div className="newsItem-title">
          <h4>{item.Title}</h4>
        </div>
        <div className="newsItem-content">
          <p 
            style={{height:height?'auto':48}}
            dangerouslySetInnerHTML={{ __html: item.Body }}>
            {/* {props.Body} */}
          </p>
          <div  
              onClick={()=>setHeight(!height)}
              className="newsItem-content-btn">{height ? "Hide News":"View News"}</div>
        </div>
      </div>
      <div className="newsItem__icons">
        <div className="newsItem__icons-item">
          <div className="svg-icon email-icon">{email()}</div>
        </div>
        <div className="newsItem__icons-item">
          <div className="svg-icon email-open-icon">{emailOpen()}</div>
        </div>
        <div className="newsItem__icons-item">
          <div className="svg-icon trash-icon">{trash()}</div>
        </div>
      </div>
    </div>
  </li>
  )
}

const mapDispatchToProps = {
  getEmployeeNotices,
};

const mapStateToProps = (state) => ({
  employeeNotices: state.employeeReducer.employeeNotices,
});

export default connect(mapStateToProps, mapDispatchToProps)(News);
