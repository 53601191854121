import React, {useRef,useState,useEffect} from 'react'
import Avatar from "react-avatar"; 
import useOutsideClick from 'helpers/customHooks/outsideClickHook';
import classnames from 'classnames';
import isEmpty from 'isEmpty'
import {getUploadPath} from 'constants/userDetails'


const EmployeeAvatar = ({employee}) => {
    const [show,setShow] = useState(false);
    
    const empRef:any = useRef(null);
    const empCardRef:any = useRef(null);
    useOutsideClick(empRef,setShow)
    const onCardClick = (e) =>{
        setShow(true)
        // if(empRef){
        //     var rect =empRef.getBoundingClientRect();
        // }
    }
    const dropClass = classnames({
        "team-card":true,
        "team-card-active":show
    });
    useEffect(()=>{
        if(show){
            if(empCardRef.current){
                const rect= empRef.current.getBoundingClientRect();
                const b = (rect.x + rect.width) < 0 
                || (rect.y + rect.height) < 0
                || (rect.x > window.innerWidth || rect.y > window.innerHeight);
                
            }
        }

    },[show])
    let gender = employee.UrlPhoto === "\\images\\female.png" ? 'female' : 'male';
    const photo = isEmpty(employee.UrlPhoto) || employee.UrlPhoto === "\\images\\female.png" || employee.UrlPhoto === "\\images\\male.png" ? null : employee.UrlPhoto
    return (
        <div 
            className="company-logo-container"
            onClick = {e => onCardClick(e)}
            style={{position:'relative'}}
            ref={empRef}>

                {
                    photo ? (
                        <Avatar
                        className="team-image"
                        name={employee.Name}
                        size={'65'}
                        round={'3'}
                        maxInitials={2}
                        textSizeRatio={4}
                        fgColor={"#005cb9"}
                        src={getUploadPath(employee.UrlPhoto)}
                    />
                    ) :(
                        <Avatar
                            className="team-image"
                            name={employee.Name}
                            size={'65'}
                            round={'3'}
                            color = {gender ==='female'?"#ffefee":"#e6f1ff"}
                            maxInitials={2}
                            textSizeRatio={4}
                            fgColor={"#005cb9"}
                        />
                    )
                }

            {
                show? 
                    <div ref={empCardRef} className={dropClass}>
                        <div className="team-card-image">
                            <Avatar
                                    className="team-image"
                                    name={employee.Name}
                                    size={'80'}
                                    round={true}
                                    maxInitials={2}
                                    textSizeRatio={4}
                                    fgColor={"#005cb9"}
                                    src={getUploadPath(employee.UrlPhoto)}
                                />
                        </div>
                        <div className="team-card-content">
                            <div className="team-card-title">
                            <h3>{employee.Name}</h3>
                            </div>
                            <p className="mb-sm">{employee.Department}</p>
                            <span className="team-card-text">{employee.Designation}</span>
                            <span className="team-card-text mb-sm">
                            {employee.Branch}
                            </span>
                            <span className="team-card-text">{employee.CIEmail}</span>
                            <span className="team-card-text">{employee.CIMobileNo}</span>
                            <span className="team-card-text">{employee.CIPhoneNo}</span>
                            {/* <a href="#" className="team-card-link">
                            <span>Send Message</span>
                            </a> */}
                        </div>
                    </div>
                : null
            }
        </div>
    )
}

export default EmployeeAvatar
