import React from "react";
import {IoMdCheckmark} from "react-icons/io";
import {MdNotInterested} from "react-icons/md";
import {withRouter} from "react-router-dom";
import TimeAgo from "react-timeago";
import {JobApplyPath, JobCreatePath} from "routes/commonLinks";
import {JobStageList, JobStages} from "../../enums";

function JobDetails(props: any) {
  const {
    data,
    onStageClick,
    seletedJobStage,
    onJobClick,
    isClickableTitle = false,
  } = props;

  const handleStageClick = async (jobStage) => {
    if (jobStage != JobStages.Openings && jobStage != JobStages.Sourced)
      onStageClick && (await onStageClick(data.Jobid, jobStage, data));
  };

  let branchAndDepartment = "";
  if (data.Branch && data.Department) {
    branchAndDepartment = data.Department + ", " + data.Branch;
  } else if (data.Branch && !data.Department) {
    branchAndDepartment = data.Branch;
  } else if (data.Department && !data.Branch) {
    branchAndDepartment = data.Department;
  }

  return (
    <div className="details-container">
      <div className="jobdetail-header">
        <div className="flex details-header">
          <div className="header__title">
            <div className="header__title-detail">
              <h3
                className="tertiary-title"
                onClick={() => isClickableTitle && onJobClick(data)}
              >
                {data.ExternalJobTitle}
              </h3>
              <p className="light-para">{branchAndDepartment}</p>
            </div>

            <div
              onClick={() =>
                props.history.push({
                  pathname: JobCreatePath,
                  data: {jobID: data.Jobid, isJobId: true}, // your data array of objects
                })
              }
              className="header__title-edit"
            >
              Edit job
            </div>
          </div>
        </div>
        <div className="details-time space-between">
          {data.PublisheDate && data.IsPublished ? (
            <span className="flex span-with-icon">
              <IoMdCheckmark />
              <p className="light-para">Published on {data.PublisheDate}</p>
            </span>
          ) : (
            <span className="flex span-with-icon icon-red">
              {/* <MdNotInterested /> */}
              <p className="light-para">Not published yet</p>
            </span>
          )}
          <span className="flex">
            <p className="light-para">Application ends on {data.EndDate}</p>
            {data.LastApplidate && (
              <p className="light-para" style={{marginLeft: 20}}>
                {"Last candidate:"}
                {data.LastApplidate && <TimeAgo date={data.LastApplidate} />}
              </p>
            )}
          </span>
        </div>
      </div>
      <div className="details-body">
        {/* {data.PublisheDate && !isClickableTitle ? (
          <div
            className="header__title-edit"
            onClick={() =>
              props.history.push({
                pathname: JobApplyPath,
                state: { jobDetails },
              })
            }
          >
            Add Candidate
          </div>
        ) : null} */}
        <div className="details-uncollapseable">
          {JobStageList &&
            JobStageList.map((caj, index) => {
              let dynamicClass =
                caj.value === -1 ? "details__block openings" : "details__block";
              let dynamicNumClass = "details__block-num";
              let dynamicLabelClass = "details__block-title";

              if (seletedJobStage && caj.value == seletedJobStage) {
                dynamicClass = "details__block active";
                dynamicNumClass = "active-num";
                dynamicLabelClass = "active-title";
              }

              return (
                <div
                  className={dynamicClass}
                  onClick={() => handleStageClick(caj.value)}
                  key={index}
                >
                  <span className={dynamicNumClass}>
                    {getStageValueofJob(caj.value, data)}
                  </span>
                  <h5 className={dynamicLabelClass}>{caj.label}</h5>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

function getStageValueofJob(value, data) {
  switch (value) {
    case JobStages.Openings:
      return data.NumberOfOpenings;

    case JobStages.Sourced:
      return data.Sourced;

    case JobStages.Applied:
      return data.Applied;

    case JobStages.Shortlisted:
      return data.Shortlist;

    case JobStages.Written_Exam:
      return data.WrittenExam;

    case JobStages.Interview:
      return data.Interview;

    case JobStages.Job_Offer:
      return data.JobOffer;

    case JobStages.Hired:
      return data.Hired;

    default:
      return "NAN";
  }
}

export default withRouter(JobDetails);
