import {GetEmployeeList} from "actions/commonAction";
import Loader from "components/loaders";
import Modal from "components/modal";
import {ADMIN_ROLE_ID, SETTLEMENT_REQUEST_APPROVAL_FLOW_TYPE} from "constants/constants";
import {RoleId} from "constants/userDetails";
import {AnimateShowHide} from "helpers/animation";
import isEmpty from "isEmpty";
import React from "react";
import {connect} from "react-redux";
import CommonFilters from "../../../employee/commonFilters";
import CommonCommentsHistory from "../../commonLayout/commentsHistory";
import CommonHeaderSection from "../../../admin/commonHeaderSection";
import {GetApprovalFlowSteps} from "../../travelInformation/actions";
import {
  approveSettlementRequest,
  getApprovalAuthorities,
  getApprovalHistory,
  getPendingSettlement,
  getSettlementRequest,
  getTravelCategoryList,
} from "../actions";
import TravelSettlementReport from "./report/travelSettlementReport";
import SettlementRequestBlock from "./settlementRequestBlock";

class SettlementRequests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settlementId: null,
      rowsPerPage: 10,
      pageIndex: 0,
      isModalShow: false,
      statusId: 0,
      searchEmployeeText: -1,
      branchId: -1,
      searchFlowSteps: "",
      fromDate: null,
      toDate: null,
      flag: "",
      isEditPage: false,
      isViewPage: false,
    };
    this.handleRowChange = this.handleRowChange.bind(this);
    this.handleIndexChange = this.handleIndexChange.bind(this);
    this.handleReviewSettlement = this.handleReviewSettlement.bind(this);
  }
  async componentDidMount() {
    //if (!this.props.initialLoad) {
    this.initialize();
    //this.props.getTravelCategoryList();
    let {getTravelCategoryList, GetApprovalFlowSteps} = this.props;
    let ajaxApi = [
      getTravelCategoryList(),
      GetApprovalFlowSteps(SETTLEMENT_REQUEST_APPROVAL_FLOW_TYPE),
    ];
    Promise.all(
      ajaxApi.map(async (item) => {
        return await item;
      })
    );
    //}
    //await this.props.GetApprovalFlowSteps(3);
  }
  componentDidUpdate = (prevProps, prevState) => {
    const {rowsPerPage, pageIndex} = this.state;
    if (rowsPerPage !== prevState.rowsPerPage || pageIndex !== prevState.pageIndex) {
      this.initialize();
    }
  };
  handleRowChange(num) {
    this.setState({rowsPerPage: num});
  }
  handleIndexChange(num) {
    this.setState({pageIndex: num});
  }
  handleReviewSettlement(data) {
    this.getApprovalDetails(data);
    this.setState({
      settlementId: data.SettlementId,
      statusId: data.StatusId,
    });
  }
  handleApproveClick = (data) => {
    this.getApprovalDetails(data);
    this.setState({
      settlementId: data.SettlementId,
      statusId: data.StatusId,
      isModalShow: true,
      isEditPage: data.StatusId !== 0 && data.StatusName !== "Approved",
      isViewPage: data.StatusId !== 0 && data.StatusName === "Approved",
    });
  };
  handleApprove = async (data) => {
    let actionData = {
      SettlementId: this.state.settlementId,
      StatusId: data.statusId,
      ActionRemarks: data.remarks,
      ApprovalEmployeeId: !isEmpty(data.employee) ? parseInt(data.employee.value) : 0,
    };
    await this.props.approveSettlementRequest(actionData);
    if (this.props.isSettlementSaveSuccess) this.initialize();
  };
  closeModal = () => {
    this.setState({isModalShow: false});
  };
  getApprovalDetails = (data) => {
    debugger
    this.props.getApprovalAuthorities(data.StatusId, data.EmployeeId);
    if (data.StatusId !== 0) {
      this.props.getApprovalHistory(data.SettlementId);
    }
  };
  initialize = () => {
    const {
      pageIndex,
      rowsPerPage,
      searchEmployeeText,
      branchId,
      searchFlowSteps,
      fromDate,
      toDate,
      flag,
    } = this.state;
    const data = {
      PageIndex: pageIndex,
      PageSize: rowsPerPage,
      SearchEmployeeText: searchEmployeeText,
      BranchId: branchId,
      StepName: searchFlowSteps,
      FromDate: fromDate,
      ToDate: toDate,
      Flag: flag,
    };
    this.props.getSettlementRequest(data);
  };

  handleSearchTextChange = (text) => {
    this.setState({searchEmployeeText: text}, () => {
      this.initialize();
    });
  };

  handleBranchChange = (branchId) => {
    this.setState({branchId: branchId}, () => {
      this.initialize();
    });
  };

  handleFilterChange = (name, selected) => {
    this.setState({[name]: selected}, () => {
      this.initialize();
    });
  };
  handleDateRangeChange = (range) => {
    this.setState({fromDate: range.fromDate, toDate: range.toDate}, () => {
      this.initialize();
    });
  };
  renderBody() {
    let {
      spinnerLoading,
      loading,
      isSettlementReportActive,
      approvalAuthorities,
      approvalHistory,
      settlementReport,
      settlementRequest,
      settlementRequestSearchedPage,
    } = this.props.travelSettlementReducer;
    const {settlementId, statusId, isModalShow, isEditPage, isViewPage} = this.state;

    return (
      <div className="settlement-requests">
        {settlementRequest.loading ? (
          <Loader
            type="skeleton"
            name="card"
            cardType="travel"
            noRows={10}
            loading={settlementRequest.loading}
          />
        ) : (
          !isSettlementReportActive && (
            <SettlementRequestBlock
              rows={settlementRequest.value}
              pageIndex={this.state.pageIndex}
              rowsPerPage={this.state.rowsPerPage}
              handleRowChange={this.handleRowChange}
              handleIndexChange={this.handleIndexChange}
              handleReviewSettlement={this.handleReviewSettlement}
              handleApproveClick={this.handleApproveClick}
              history={this.props.history}
              searchedPage={settlementRequestSearchedPage}
            />
          )
        )}
        {/* <Table
          pageIndex={this.state.pageIndex}
          rowsPerPage={this.state.rowsPerPage}
          handleRowChange={this.handleRowChange}
          handleIndexChange={this.handleIndexChange}
          handleReviewSettlement={this.handleReviewSettlement}
          handleApproveClick={this.handleApproveClick}
        /> */}

        <AnimateShowHide
          show={isSettlementReportActive}
          config={{duration: 700}}
          fromStyle={{
            opacity: 0,
            transform: "translate3d(0,-500%,0)",
          }}
          toStyle={{
            opacity: isSettlementReportActive ? 1 : 0,
            transform: isSettlementReportActive
              ? "translate3d(0,0,0)"
              : "translate3d(0,-500%,0)",
          }}
        >
          {!settlementReport.loading && !isEmpty(settlementReport.value) ? (
            <TravelSettlementReport
              statusId={statusId}
              settlementId={settlementId}
              getSettlementRequest={this.initialize}
            />
          ) : null}
        </AnimateShowHide>

        <Loader open={spinnerLoading} />
        <Modal open={isModalShow} onModalClose={this.closeModal} title="Comments History">
          <CommonCommentsHistory
            isModal={true}
            RequestId={settlementId}
            StatusId={statusId}
            onModalClose={this.closeModal}
            func={(data) => this.handleApprove && this.handleApprove(data)}
            approvalAuthorities={approvalAuthorities}
            approvalHistory={approvalHistory}
            isViewMode={isViewPage}
            isEditPage={isEditPage}
          />
        </Modal>
      </div>
    );
  }
  render() {
    const selectedTabId = !isEmpty(this.props.location.state)
      ? this.props.location.state.selectedTabId
      : null;
    const roleId = RoleId();
    return (
      <>
        {roleId == ADMIN_ROLE_ID ? (
          <CommonHeaderSection
            renderBody={this.renderBody()}
            selectedTabId={selectedTabId}
            pathName="Settlement Requests"
            showFilterSection={true}
            handleSearchTextChange={(text) => this.handleSearchTextChange(text)}
            handleBranchChange={(branchId) => this.handleBranchChange(branchId)}
            handleFilterChange={this.handleFilterChange}
            handleDateRangeChange={this.handleDateRangeChange}
          />
        ) : (
          <div className="travel__filter-body">
            <CommonFilters
              handleSearchTextChange={(text) => this.handleSearchTextChange(text)}
              handleBranchChange={(branchId) => this.handleBranchChange(branchId)}
              handleFilterChange={this.handleFilterChange}
              handleDateRangeChange={this.handleDateRangeChange}
              selectedTabId={selectedTabId}
            />
            <div className="travel-requestMainBody">{this.renderBody()}</div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  travelSettlementReducer: state.travelSettlementReducer,
  settlementRequest: state.travelSettlementReducer.settlementRequest,
  travelCategoryList: state.travelInformationReducer.travelCategoryList,
  initialLoad: state.travelSettlementReducer.settlementRequestInitialLoad,
  isSettlementSaveSuccess: state.travelSettlementReducer.isSettlementSaveSuccess,
});

export default connect(mapStateToProps, {
  getPendingSettlement,
  getTravelCategoryList,
  getSettlementRequest,
  getApprovalAuthorities,
  getApprovalHistory,
  approveSettlementRequest,
  GetApprovalFlowSteps,
  GetEmployeeList,
})(SettlementRequests);
