const prefix = "/tm";
export const taskManagement = {
  board: `${prefix}/boards`,
  recurringBoard: `${prefix}/boards/recurring`,
  duplicateBoard: `${prefix}/boards/duplicate`,
  boardLabel: `${prefix}/board-label`,
  boardDetails: `${prefix}/board-details`,
  boardMembers: `${prefix}/boards/member`,
  archive: `${prefix}/boards/archive`,
  activity: `${prefix}/boards/activity`,
  activityViewed: `${prefix}/boards/activity-viewed`,
  task: {
    tableView: `${prefix}/tasks/table-view`,
    boardView: `${prefix}/tasks/board-view`,
    toDo: `${prefix}/tasks/to-do`,
    taskDetail: `${prefix}/tasks-detail`,
    updateDetails: `${prefix}/tasks/update-details`,
  },

  Comment: {
    getCommentByTask: `${prefix}/comments/task`,
    comment: `${prefix}/comments`,
    addReaction: `${prefix}/comments/add-reaction`,
    removeReaction: `${prefix}/comments/remove-reaction`,
  },
};
