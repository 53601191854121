import isEmpty from 'isEmpty';

export const validateForm = async toValidateData => {
  let isAllRequiredFieldValid = true;
  let { travelInformationState, isAsPlanned } = toValidateData;
  if (!isAsPlanned && !isEmpty(travelInformationState)) {
    const { selectedTravelDestList, travelTypeId } = travelInformationState;

    let isEmptyField =
      (await selectedTravelDestList) &&
      selectedTravelDestList.some(data => {
        let destination = data.travelDestination;

        const districtId =
          travelTypeId == 1 && !isEmpty(destination.selectedDistrict)
            ? destination.selectedDistrict.value
            : null;

        let transferModes = "";
        destination.selectedModeOfTransport.map((mode, index) => {
          if (index > 0) {
            transferModes = transferModes + "," + mode.value;
          } else {
            transferModes = mode.value;
          }
        });

        return !(
          (travelTypeId == 2 || districtId > 0) &&
          !isEmpty(destination.placeName) &&
          !isEmpty(transferModes) &&
          !isEmpty(destination.startDateEng) &&
          !isEmpty(destination.endDateEng) 
          // &&(!isEmpty(destination.hostName) || !isEmpty(destination.hotelName)) &&
          // !isEmpty(destination.phoneNumber)
        );
      });
    isAllRequiredFieldValid = !isEmptyField;
  }
  return isAllRequiredFieldValid;
};
