import React, {Component} from "react";
import {PDFViewer, StyleSheet} from "@react-pdf/renderer"; //https://react-pdf.org/components
import {connect} from "react-redux";
import {MdCloudDownload} from "react-icons/md";
import isEmpty from "isEmpty";
import {DisplayClientSideMessage} from "actions/commonAction";
import moment from "moment";
import {FaCheckCircle} from "react-icons/fa";
import "./style.scss";
import Modal from "components/modal";
import { EmployeeID } from "constants/userDetails";
import Button from "components/button";
import EmployeeInfo from "./employeeInfo";
import PayVerification from "./payVerification";
import {Tabs, Tab} from "components/tabs/index.d";
import RequestedForm from "./requestedForm";
import Breadcrumb from "../../../../common/breadcrumb";
import {
  getConsumerLoanDetails,
  getEmployeeInfo,
  getSocialLoanDetails,
} from "../../helpers/action";
import {getSbiLoanApprovers} from "../../../../sbiLoan/helpers/action";
import ForwardLoan from "../../../../sbiLoan/loanStatusHistories/forwardLoan";
import LoanForward from "./loanForward";
import CommentsView from "./commentsView";

const tabName = ["Request Form", "Pay Verification - HR", "Loan Forward", "Loan Sanction"];

export class HomeLoanForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: tabName[0],
      comments: [],
      isLoading: true,
      openModel: false,
      tabs: [
        {
          name: tabName[0],
          label: tabName[0],
          key: 0,
          component: <RequestedForm {...props} />,
        },
        {
          name: tabName[1],
          label: tabName[1],
          key: 1,
          component: <PayVerification {...props} />,
        },
        {
          name: tabName[2],
          label: tabName[2],
          key: 2,
          component: <LoanForward {...props} />,
        },
        {
          name: tabName[3],
          label: tabName[3],
          key: 3,
          component: <CommentsView {...props} />,
        },
      ],
      rejectButton: false,
    };
  }

  async componentDidMount() {
    const {
      match,
      location: {state},
    } = this.props;
    const {
      LoanId,
      EmployeeId,
      LoanType,
      CurrentStep,
      FlowType,
      ProcessFlowStepId,
      IsSelfForward,
      Status,
    } = state;
    const {id} = match.params;
    await this.props.getEmployeeInfo(EmployeeId);
    if (LoanType == 1)
      await this.props.getConsumerLoanDetails(LoanId);
    else if (LoanType == 2)
      await this.props.getSocialLoanDetails(LoanId);
    // const {
    //   location: {state},
    // } = this.props;
    // const {LoanId, EmployeeId, LoanType, CurrentStep, FlowType} = state;
    if (ProcessFlowStepId !==10 && ProcessFlowStepId !== -1)
      await this.props.getSbiLoanApprovers({
        EmployeeId,
        LoanType,
        FlowType,
        CurrentStep: ProcessFlowStepId,
      });

    let forwardSelectedData = {
      EmployeeId,
      ProcessFlowStepId,
      CurrentStep,
      LoanId,
      LoanType,
    };
    this.setState({
      isLoading: false,
      EmployeeId,
      forwardSelectedData,
      LoanId,
      IsSelfForward,
      Status,
    });
  }
  onTabChange = (activeTab) => {
    this.setState({activeTab}, async () => {
      // const {comments, totalLoanComments} = await this.formatSavedLoanComments(
      //   this.state.LoanType,
      //   this.state.LoanId
      // );
      // this.formatCommentsbyTabId(activeTab, totalLoanComments);
    });
  };

  handleDataModal = async (reject) => {
    // setForwardSelectedData(data);
    // const {
    //   location: {state},
    // } = this.props;
    // const { LoanId, EmployeeId, LoanType, CurrentStep, FlowType } = state;
    
    // await this.props.getSbiLoanApprovers({
    //   EmployeeId,
    //   LoanType,
    //   FlowType,
    //   CurrentStep,
    // });
    if (reject)
      this.setState({ rejectButton: true },
        this.setState({openModel: true}));
    else this.setState({ openModel: true })
    // dispatch(
    //   getSbiLoanApprovers({
    //     EmployeeId: userId !== 0 ? userId : 10,
    //     LoanType: 1,
    //     FlowType: data.FlowType,
    //     CurrentStep: data.CurrentStep,
    //   })
    // );
  };

  render() {
    let {
      activeTab,
      tabs,
      LoanId,
      EmployeeId,
      isLoading,
      forwardSelectedData,
      openModel,
      Status,
      IsSelfForward,
      rejectButton,
    } = this.state;
    const {approversList, history} = this.props;
    const empId = EmployeeID();
    const isSelfApplied = EmployeeID() === EmployeeId;
    // const isApprover = approversList && approversList.find((a) => a.EmployeeId == empId);
    const isApprover = (Status < 10 && Status !== -1) && !IsSelfForward;
    console.log(
      "isApprover",
      isApprover,
      Status,
      IsSelfForward,
      isSelfApplied,
      EmployeeId,
      EmployeeID()
    );
    if (isLoading) return null;
    return (
      <div className="consumerloan-container dashboard">
        <div className="tasktracker-title-section">
          <Breadcrumb name="Loan View" />
        </div>
        <div className="homeloan homeloan-view mb-bg">
          <div className="homeloan-body">
            {/* <Modal
              open={openLoanPdf}
              className="pdf-modal"
              onModalClose={() => this.setState({openLoanPdf: false})}
            >
              <PDFViewer style={styles.container}>
                {LoanType == getLoanTypeText(1)
                  ? LoanPdfTemplate(loanPdfData)
                  : StaffOverDraftPDF(loanPdfData)}
              </PDFViewer>
            </Modal> */}
            <div className="flex justify-between">
              <div className="consumer-loan homeloan-section__employeeInfo">
                <EmployeeInfo />
              </div>
              {isApprover && !isSelfApplied ? (
                <div className="homeloan-section__forwardbutton flex justify-between">
                  <Button
                    bg="danger"
                    // icon={<MdAdd />}
                    buttonType="icon-button"
                    title="Reject"
                    // isDisabled={loanDetails?.Status != 2}
                    onClick={() => this.handleDataModal(true)}
                    // onClick={() => this.setState({openModel: true})}
                  />
                  <Button
                    bg="green"
                    // icon={<MdAdd />}
                    buttonType="icon-button"
                    title={forwardSelectedData.ProcessFlowStepId == 10
                    ? "Approve" : "Forward"}
                    // isDisabled={loanDetails?.Status != 2}
                    onClick={() => this.handleDataModal(false)}
                    // onClick={() => this.setState({openModel: true})}
                  />
                </div>
              ) : (
                <div></div>
              )}
            </div>
            {openModel && (
              <Modal
                title={
                  rejectButton ? 'Reject' :
                  forwardSelectedData.ProcessFlowStepId == 10
                    ? "Approve"
                    : forwardSelectedData.ProcessFlowStepId == 2
                    ? "Recommend and Forward"
                    : "Verify and Forward"
                }
                open={openModel}
                onModalClose={() => this.setState({openModel: false, rejectButton: false})}
                type=""
                className="modal-allowance-request allowance-request-form"
              >
                <ForwardLoan
                  approversList={approversList}
                  setOpenModel={() => this.setState({openModel: false})}
                  forwardSelectedData={forwardSelectedData}
                  history={history}
                  rejectButton={rejectButton}
                  isApprove={forwardSelectedData.ProcessFlowStepId == 10}
                  isTable={false}
                />
              </Modal>
            )}
            <div className="homeloan-section-content mt-sm">
              <Tabs noBackground activeTab={activeTab}>
                {tabs.map((tab, index) => {
                  if (isSelfApplied && index > 0) return null;
                  else
                    return (
                      <Tab
                        label={tab.label}
                        name={tab.name}
                        id={tab.name}
                        onTabClick={() => this.onTabChange(tab.name)}
                      >
                        {tab.component ? tab.component : <span>{tab.label}</span>}
                      </Tab>
                    );
                })}
              </Tabs>
            </div>
          </div>
          <div className="homeloan-view-footer">
            <div className="homeloan-section-footer-item">
              <Button
                bg="subtle"
                onClick={() => this.props.history.goBack()}
                title="Close"
              />
            </div>
            <div className="homeloan-section-footer-item wd-90"></div>
            {/* {this.renderFooter()} */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  homeLoanDetail: state.loanReducer.homeLoanDetail,
  approversList: state.sbiLoanSettingReducer.sbiLoanApproversList,
});

const mapDispatchToProps = {
  getConsumerLoanDetails,
  getEmployeeInfo,
  getSbiLoanApprovers,
  getSocialLoanDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeLoanForm);
