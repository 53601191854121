import React from "react";
import Form from "components/form";
import isEmpty from "isEmpty";
import Button from "components/button";

function AssignApprover({ hrEmpDetails, ForwardTo, handleInput, multiple, closeModal, handleSubmit, approverTitle, forwardTitle }) {
  return (
    <>
      <div className="form-row ml-sm mr-sm items-start pt-sm pr-sm pl-sm pb-sm">
        {isEmpty(hrEmpDetails) ? (
          <span>There is no Employee Assigned for this task yet.</span>
        ) : (
          <Form
            formName="checkBoxGroup"
            name="ForwardTo"
            label={approverTitle}
            checkGroupArray={hrEmpDetails}
            checkedGroupValue={ForwardTo}
            onChange={(name, value) => handleInput(name, value)}
            multiple={false}
            // loading={leaveRequestApprover.loading}
            // loadingType="skeleton"
            validators={["required"]}
            // alignment="horizontal"
            // error={errors && errors.ForwardTo}
            // disabled={isApprover || this.state.isApproverThere}
            width="100%"
          />
        )}
        {/* <div className="jobdesc-btn"> */}

        {/* <div className="button-group flex">
        <Button bg="primary" onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button bg="primary" color="green" onClick={() => closeModal()}>
          Forward
        </Button>
      </div> */}
        {/* </div> */}
      </div>
      <div className="leave-request-form__footer flex justify-between">
        <button className="button  button-white" onClick={() => closeModal()}>
          <span> Cancel</span>
        </button>

        <button
          className="button  button-primary"
          onClick={() => handleSubmit()}
          disabled={isEmpty(hrEmpDetails)}
        >
          <span>{forwardTitle ? "Assign" : "Forward"}</span>
        </button>
      </div>
    </>
  );
}

export default AssignApprover;
