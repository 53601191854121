import React, { useEffect, useState }  from 'react'
import Form from 'components/form';
import Button from 'components/button';
import isEmpty from 'isEmpty';
import {FaTimesCircle,FaPlusCircle} from 'react-icons/fa';
import FroalaEditor from 'react-froala-wysiwyg';
import { useDispatch,useSelector } from 'react-redux';
import {addCourse, updateCourse} from '../helpers/action';
import { TrendingUpRounded } from '@material-ui/icons';
import { MdAdd, MdEdit } from 'react-icons/md';
import {getConfig} from '../helpers/froalaConfig';
// import 'font-awesome/css/font-awesome.css';

// declare let FroalaEditor:any;
const initState = {
    Title:'',
    ServerFileName:null,
    Author:'',
    Description:'',
    CoverPicture:[],
    Duration:null
}

function MainPageForm(props) {
    const [state,setState] = useState(initState);
    const [isEdit,setIsEdit] = useState(false);
    const dispatch = useDispatch();
    const {coursePages,course} = useSelector((state:any)=>state.lmsReducer)
    const {courseId} = props.match.params;
    useEffect(()=>{
        if(courseId){
            setIsEdit(true)
        }
    },[courseId])
    useEffect(()=>{
        if(course && courseId){
            setState(prev=>{
                return {
                    ...prev,
                    Title:course.Title,
                    Description:course.Description,
                    Author:course.Author,
                    ServerFileName:course.ServerFileName,
                    Duration:course.Duration,
                }
            })
        }
    },[course])
    const onChange = (name,value) =>{
        setState((prev)=>({
            ...prev,
            [name]:value
        }))
    }
    // useEffect(()=>{
    //     (function () {
    //         new FroalaEditor("#edit")
    //     })()
    // },[])
    const config={
        placeholder: "Edit Me",
        events : {
          'focus' : function(e, editor) {
            console.log(editor.selection.get());
          }
        }
    }
    const submitCourse = async () =>{
        const data = {
            ...state,
            CoverPicture:!isEmpty(state.CoverPicture) ? state.CoverPicture[0] : null,
            IsFileEdit:!isEmpty(state.CoverPicture)
        } 
        const fd = new FormData();
        Object.keys(data).map(item=>{
            fd.append(item,data[item]);
        })
        // console.log([...fd])
        if(isEdit){
            fd.append('Id',course.Id);
            await dispatch(updateCourse(fd))
            setState({...state})
            return;
        }
        await dispatch(addCourse(fd,props.history))
    }
    return (
        <div className="courseForm__form-main">
            <div className="courseForm__form">
                <Form
                    name="Title"
                    label="Add a course title"
                    onChange={onChange}
                    value={state.Title}
                />
            </div>
            <div className="courseForm__form">
                <div className="courseForm__form-label">Description</div>
                <div id='edit'></div>
                <FroalaEditor
                    model={state.Description}
                    // config={config}
                    // config={getConfig()}
                    onModelChange={(model) =>onChange("Description",model)}
                />
            </div>
            <div className="courseForm__form">
                <Form
                    name="Author"
                    label="Author"
                    onChange={onChange}
                    value={state.Author}
                />
            </div>
            <div className="courseForm__form">
                <Form
                    name="Duration"
                    label="Duration"
                    onChange={onChange}
                    value={state.Duration}
                />
            </div>
            <div className="courseForm__form">
                <div className="courseForm__form-label">Cover</div>
                {
                    !isEmpty(state.CoverPicture) ? (
                        <div className="courseForm__form-image">
                            <img src={URL.createObjectURL(state.CoverPicture[0])} alt="" />
                            <div onClick={() => setState(prev => ({ ...prev, CoverPicture: [] }))} className="courseForm__form-icon">
                                <FaTimesCircle />
                            </div>
                        </div>
                    ) : (
                        <Form
                            name="CoverPicture"
                            // label="Cover"
                            formName="dragAndDropUpload"
                            multiple={false}
                            value={state.CoverPicture}
                            onChange={onChange}
                        />
                    )
                }
            </div>
            <div className="courseForm__form">
                <Button bg="primary" onClick={()=>submitCourse()} size="large" title={isEdit ? "Uppdate Course" :"Add Course"} buttonType="icon-button" icon={isEdit ? <MdEdit /> :<MdAdd />} />
            </div>
            {/* <div className="courseForm__form">
                <Button bg="primary" size="large" title="Add page" buttonType="icon-button" icon={<span>+</span>} />
            </div> */}
        </div>
    )
}

export default MainPageForm
