import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "components/button";
import DetailCard from './detailCard';
import ReferenceForm from './referenceForm';
import { InputValidator } from "helpers/inputValidator";
import isEmpty from "isEmpty";
import { MdAdd } from "react-icons/md";
import  {
    currentlySelectedSection,
    deleteEmployeeReferenceDetails,
    getEmployeeReferenceList
} from '../action';

const CountryData = [
    {
        label: 'Nepal',
        value: 'Nepal'
    },
    {
        label: 'India',
        value: 'India'
    }
]
class Reference extends Component{
    constructor(props) {
        super(props);
        this.state = {
            title: 'Reference',
            showAddSection: false,
            editView: false,
            errors: {},
        }
    }

    handleInput = (name, value) => {
        console.log()
        this.setState(
            { [name]: value, },
            () => {
                this.updateErrors();
            }
        );
    }

    updateErrors = async () => {
        const { errors } = this.state;
        if (!isEmpty(errors)) {
        let errors = await InputValidator(document, this.state);
        this.setState({ errors });
        }
    };

    deleteEmployeeReference = async (id) => {
        const {
            deleteEmployeeReferenceDetails,
            getEmployeeReferenceList,
            employeeId
        } = this.props;
        this.setState({ showAddSection: false });
        await deleteEmployeeReferenceDetails(id);
        if (this.props.employeeReferenceDeleted)
            getEmployeeReferenceList(employeeId)
    }

    toggleAddSecton = (showAddSection, editView, title) => {
        this.setState({ showAddSection, editView, editViewData: null });
        this.props.currentlySelectedSection(title)
    }

    toggleEditView = (flag, editViewData, title) => {
        this.setState({ showAddSection: false, editView: editViewData && editViewData.ReferenceId, editViewData });
        this.props.currentlySelectedSection(title);
    }

    render () {

        const { employeeReferenceList, currentlySelectedEduSection } = this.props;
        const { title } = this.state;
        return (
            <div className="education__education">
                <div className="education-heading">
                    <h4>{title}</h4>
                    <Button 
                        onClick={() => this.toggleAddSecton(true, false, title)}
                        bg="white" 
                        buttonType="icon-button"
                        leftIcon={<MdAdd />} 
                        title="Add" /> 
                </div>
                {this.state.showAddSection && currentlySelectedEduSection === title &&
                <ReferenceForm 
                    closeAddSection={() => this.toggleAddSecton(false, false, title)}
                    employeeId={this.props.employeeId}
                />
                }
                <div className="education__education__details">
                        {employeeReferenceList.length > 0 ? employeeReferenceList.map(edu => 
                        <>
                            <DetailCard
                                key={edu.ReferenceId}
                                detail={edu}
                                toggleEditView={(data, title) => this.toggleEditView(true, data, title)}
                                deleteEmployeeItem={(referenceId) => this.deleteEmployeeReference(referenceId)}
                                editView={true}
                                title={title}
                                editViewId={this.state.editView}
                                currentlySelectedEduSection={currentlySelectedEduSection}
                            />
                            {this.state.editView == edu.ReferenceId && currentlySelectedEduSection === title &&
                            <ReferenceForm
                                editView={true}
                                editViewData={edu}
                                toggleEditView={() => this.toggleEditView(false, null, title)}
                                employeeId={this.props.employeeId}
                            />}
                        </>
                        ):  <div className="education__detail__details">
                        <div className="education-detail">
                                <div className="education-detail__primary">
                                    <p className="noData">No Data Found</p>
                                </div>
                        </div>
                    </div>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    employeeReferenceList: state.employeeProfile.employeeReferenceList || [],
    employeeReferenceDeleted: state.employeeProfile.employeeReferenceDeleted,
    currentlySelectedEduSection: state.employeeProfile.currentlySelectedEduSection,
});

const mapDispatchToProps = {
    currentlySelectedSection,
    deleteEmployeeReferenceDetails,
    getEmployeeReferenceList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reference);