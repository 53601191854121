import React from "react";
import { connect } from "react-redux";
// import {useSelector} from 'react-redux';

function EmployeeInfo(props) {
  // const {employeeProfile} = useSelector(state=>state.employeeProfile);
  // const detail = employeeProfile ? employeeProfile.EmployeePersonalAndJobDetail : {}
  const {employeeInfo,familyDetail} = props;
  console.log(familyDetail)
  return (
    <div className="profile-row">
      <div className="profile-column">
        <div className="profile-block">
          <div className="profile-block__title">
            <h2>EMPLOYEE INFORMATION</h2>
          </div>
          <div className="profile-block__body">
            <div className="placement-details">
              <div className="placement-details__block">
                <div className="placement-details__table">
                  <div className="placement-details__row">
                    <div className="placement-details__col placement-title">
                      Date of Birth
                    </div>
                    <div className="placement-details__col placement-val">
                      {employeeInfo?.DateOfBirth}
                    </div>
                  </div>
                  <div className="placement-details__row">
                    <div className="placement-details__col placement-title">
                      Gender
                    </div>
                    <div className="placement-details__col placement-val">
                      {employeeInfo?.Gender}
                    </div>
                  </div>
                  <div className="placement-details__row">
                    <div className="placement-details__col placement-title">
                      Marital Status
                    </div>
                    <div className="placement-details__col placement-val">
                      {employeeInfo?.MaritalStatus}
                    </div>
                  </div>
                  <div className="placement-details__row">
                    <div className="placement-details__col placement-title">
                      Father
                    </div>
                    <div className="placement-details__col placement-val">
                      {
                        familyDetail?.map(item=>{
                          if(item.Relation === 'Father'){
                            return item.Name
                          }
                        })
                      }
                    </div>
                  </div>
                  <div className="placement-details__row">
                    <div className="placement-details__col placement-title">
                      Mother
                    </div>
                    <div className="placement-details__col placement-val">
                    {
                        familyDetail?.map(item=>{
                          if(item.Relation === 'Mother'){
                            return item.Name
                          }
                        })
                      }
                    </div>
                  </div>
                  <div className="placement-details__row">
                    <div className="placement-details__col placement-title">
                      Spouse
                    </div>
                    <div className="placement-details__col placement-val">
                      {
                        familyDetail?.map(item=>{
                          if(item.Relation === 'Spouse'){
                            return item.Name
                          }
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div className="placement-details__block">
                <div className="placement-details__table">
                  <div className="placement-details__row">
                    <div className="placement-details__col placement-title">
                      Level
                    </div>
                    <div className="placement-details__col placement-val">
                      {employeeInfo?.Level}
                    </div>
                  </div>
                  <div className="placement-details__row">
                    <div className="placement-details__col placement-title">
                      Job Status
                    </div>
                    <div className="placement-details__col placement-val">
                      {employeeInfo?.EmployeeStatus}
                    </div>
                  </div>
                  <div className="placement-details__row">
                    <div className="placement-details__col placement-title">
                      Contract End Date
                    </div>
                    <div className="placement-details__col placement-val">
                      {employeeInfo?.ContractEndDate}
                    </div>
                  </div>
                  <div className="placement-details__row">
                    <div className="placement-details__col placement-title">
                      Hire Method
                    </div>
                    <div className="placement-details__col placement-val">
                      {employeeInfo?.HireMethodName}
                    </div>
                  </div>
                  <div className="placement-details__row">
                    <div className="placement-details__col placement-title">
                      Hire Date
                    </div>
                    <div className="placement-details__col placement-val">
                      {employeeInfo?.HireDate}
                    </div>
                  </div>
                  <div className="placement-details__row">
                    <div className="placement-details__col placement-title">
                      Starting Position
                    </div>
                    <div className="placement-details__col placement-val">
                      {employeeInfo?.Position}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  employeeInfo:
    state.employeeProfile.employeeProfile &&
    state.employeeProfile.employeeProfile.EmployeePersonalAndJobDetail,
  familyDetail: state.employeeProfile.employeeProfile && state.employeeProfile.employeeProfile.Family
});

export default connect(mapStateToProps, null)(EmployeeInfo);
