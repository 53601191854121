import React, { Component } from "react";
import Icons from "components/icons";
import { AnimateShowHide } from "helpers/animation";
import Loader from "../loaders";

const AnimatedForm = () => (BodyComponent) => {
  class AnimatedFormClass extends Component {
    constructor(props) {
      super(props);
      this.child = React.createRef();
      this.state = { showForm: this.props.showForm };
      this.onClose = this.onClose.bind(this);
    }
    onClose() {
      this.setState({ showForm: false });
      setTimeout(() => {
        this.props.handleFormDisplay(false);
      }, 1000);
    }

    onSave = () => {
      this.child.current.handleSave();
    };

    onSaveAndNext = () => {
      this.child.current.handleSaveAndNext();
    };

    onSaveAndFinish = () => {
      this.child.current.handleSaveAndFinish();
    };

    render() {
      let {
        //showForm,
        isFormLoading,
        title,
        // body,
        formExists,
        footerButtons,
        hideFooter,
      } = this.props;

      const { showForm } = this.state;
      let height = document.body.clientHeight;

      return (
        <>
          <Loader type="spinner" loading={isFormLoading} />
          <AnimateShowHide
            show={!showForm}
            config={{ duration: 1000 }}
            fromStyle={{
              opacity: 0,
              transform: "translate3d(0,-150%,0)",
            }}
            toStyle={{
              opacity: 1,
              transform: !showForm
                ? "translate3d(0,-150%,0)"
                : "translate3d(0,0,0)",
            }}
          >
            {formExists ? (
              <BodyComponent
                {...this.props}
                parent={this.props}
                onClose={this.onClose}
              />
            ) : (
              <div
                className="container animated-form"
                style={{ minHeight: height }}
              >
                <div className="animated-form__header">
                  <span>{title}</span>
                  <span className="close-icon" onClick={() => this.onClose()}>
                    <Icons name="Close" />
                  </span>
                </div>
                <div className="animated-form__body">
                  <BodyComponent
                    {...this.props}
                    childRef={this.child}
                    parent={this.props}
                  />
                </div>
                {!hideFooter && <div className="animated-form__footer">
                  <button
                    className="btn btn-del"
                    type="button"
                    onClick={() => this.onClose()}
                  >
                    Cancel
                  </button>

                  {hideFooter ? null : (
                    <>
                      {!footerButtons || footerButtons.includes("save") ? (
                        <button
                          className="btn btn-primary"
                          onClick={this.onSave.bind(this)}
                        >
                          Save
                        </button>
                      ) : null}

                      {footerButtons &&
                        footerButtons.includes("saveAndFinish") && (
                          <button
                            className="btn btn-primary"
                            onClick={this.onSaveAndFinish.bind(this)}
                          >
                            Save and Finish
                          </button>
                        )}

                      {footerButtons && footerButtons.includes("saveAndNext") && (
                        <button
                          className="btn btn-primary"
                          onClick={this.onSaveAndNext.bind(this)}
                        >
                          Save and Next
                        </button>
                      )}
                    </>
                  )}
                </div>}
              </div>
            )}
          </AnimateShowHide>
        </>
      );
    }
  }
  return AnimatedFormClass;
};

export default AnimatedForm;
