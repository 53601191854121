import React, { useState } from 'react'
import Table from 'components/customTable';
import { useDispatch, useSelector } from 'react-redux';
import { deleteRosterTeamRole } from 'scenes/roster/helpers/action';
import AddRole from './addRole';

const ButtonCell = (props) => {
    const [open, setOpen] = useState(false);
    const onModalOpen = () => setOpen(true)
    const onModalClose = () => setOpen(false)
    const dispatch = useDispatch();
    const onDelete = () => {
        dispatch(deleteRosterTeamRole(props?.value));
    }
    return (
        <div className="shifts-table__col flex">
            <div className="shifts-table__links">
                <span onClick={() => onModalOpen()} >Edit</span>
            </div>
            <div className="shifts-table__links">
                <span onClick={() => onDelete()} >Delete</span>
            </div>
            {
                open && <AddRole
                open={open}
                onModalClose={onModalClose}
                title="Edit Employee Team"
                isEdit={true}
                editData={props?.row?.original}
            />
            }
            
        </div>
    )
}


function RolesTable() {
    const {teamRoleList} = useSelector((state:any)=>state.roosterReducer);
    const cols = [
        {
            Header: 'Role Name',
            accessor: 'RoleName',
            width: 160,
        },
        {
            Header: 'Team',
            accessor: 'TeamName',
            width: 160,
        },
        {
            Header: 'Actions',
            accessor: 'Id',
            Cell: ButtonCell,
            width: 160,
        },
    ];
    return (
        <div>
            <Table
                isFixedWidth
                data={teamRoleList ?? []}
                columns={cols}
            />
        </div>
    )
}

export default RolesTable
