import { FAV_LINK, Toggle_FAV_LINK } from "store/types";
import { api, post, get } from "networkService";

export const ToggleFavLink = (param: any) => async (dispatch: Function) => {
  const res: any = await post(api.favLink.toggleFavLink, dispatch, param, false);
  if (res.Status) {
    dispatch({ type: Toggle_FAV_LINK, payload: res.Data });
  }
}

export const GetFavLink = (param: any) => async (dispatch: Function) => {
  dispatch({ type: FAV_LINK, payload: [] });
  const res: any = await get(api.favLink.getFavLinks, dispatch, param);
  dispatch({ type: FAV_LINK, payload: res.Data });
}


