import React from "react";

export default function Address(props: any) {
  const { data } = props || {};
  const {
    CorrespondingProvince,
    CorrespondingDistrict,
    CorrespondingLocalBody,
    CorrespondingWardNo,
    CorrespondingLocality,
  } = data;

  return (
    <div className="">{`${CorrespondingLocalBody}, ${CorrespondingDistrict}, ${CorrespondingProvince}`}</div>
  );
}
