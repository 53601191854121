import React from "react";
import "../style.scss";
import { connect } from "react-redux";
import { currencyCommaSeperator } from "../../../helpers/currencyFormatter";

const costFields = [
  "LoanPurchaseCost",
  "RegistrationFee",
  "TotalConstructionCost",
  "DrawingApprovalCost",
  "ContengencyCost",
  "OtherCost",
];

function LoanRequirements({ homeLoanDetail }) {
  const calculateTotalCost = () => {
    let total = 0;
    if (homeLoanDetail)
      costFields.forEach((cost) => {
        cost = homeLoanDetail[cost] ? parseInt(homeLoanDetail[cost]) : 0;
        total += parseInt(cost);
      });
    return total;
  };

  return (
    <div className="homeloan-view-loanRequirements healthCard__health mt-md">
      <div className="healthCard-heading">
        <h4>Loan Requirement</h4>
      </div>
      <div className="healthCard__health__details">
        <div className="">
          <div className="placement-details__block">
            <div className="placement-details__table">
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Land Purchase Cost
                </div>
                <div className="placement-details__col placement-val">
                  {currencyCommaSeperator(homeLoanDetail?.LoanPurchaseCost) ||
                    "-"}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Registration Fees
                </div>
                <div className="placement-details__col placement-val">
                  {currencyCommaSeperator(homeLoanDetail?.RegistrationFee) ||
                    "-"}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Total Construction Cost
                </div>
                <div className="placement-details__col placement-val">
                  {currencyCommaSeperator(
                    homeLoanDetail?.TotalConstructionCost
                  ) || "-"}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Drawing, Approval etc
                </div>
                <div className="placement-details__col placement-val">
                  {currencyCommaSeperator(
                    homeLoanDetail?.DrawingApprovalCost
                  ) || "-"}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Contingency Cost
                </div>
                <div className="placement-details__col placement-val">
                  {currencyCommaSeperator(homeLoanDetail?.ContengencyCost) ||
                    "-"}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Other Cost
                </div>
                <div className="placement-details__col placement-val">
                  {currencyCommaSeperator(homeLoanDetail?.OtherCost) || "-"}
                </div>
              </div>
            </div>
            <div className="placement-details__row loanview_total">
              <div className="placement-details__col placement-title">
                Total
              </div>
              <div className="placement-details__col placement-val">
                NPR {currencyCommaSeperator(calculateTotalCost())}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  homeLoanDetail: state.loanReducer.homeLoanDetail,
});

export default connect(mapStateToProps, {})(LoanRequirements);
