import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Form from "components/form";
import Modal from "components/modal";
import Button from "components/button";
import { SaveCandidateComment } from "store/actions/recruitment/setting";

function SendComment(props) {
  const { src, data } = props;

  const [state, setState] = useState({
    comment: "",
  });

  const onChange = (name, value) => {
    let nextState = { ...state };
    nextState[name] = value;
    setState(nextState);
  };

  const onSend = async () => {
    const param = {
      id: 0,
      candidateRef_Id: data.CandidateId,
      jobRef_Id: data.JobId,
      comments: state.comment,
    };
    await props.SaveCandidateComment(param, onSuccess);
  };

  const onSuccess = () => {
    props.handleModal(false);
  };

  return (
    <Modal
      title={`Add Comment on ${data.CandidateName}`}
      open={props.open}
      onModalClose={() => props.handleModal(false)}
      className="modal-compose-email"
      width="50%"
    >
      <div className="compose-email">
        <div className="email-section">
          <Form
            formName="textarea"
            onChange={onChange}
            name="comment"
            value={state.comment}
            placeholder="Enter your comment"
          />
        </div>

        <div className="email-section">
          <span />

          <Button bg="primary" title="Save" onClick={onSend} />
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  SaveCandidateComment,
};
export default connect(mapStateToProps, mapDispatchToProps)(SendComment);
