import * as React from "react";
import Workbook from "react-excel-workbook-emptystring";
import isEmpty from "isEmpty";
import { IExcelSheet, IExcelColumn } from "ts/interfaces/excel/exportExcel";
import { BiExport } from "react-icons/bi";

interface IAppProps {
  fileName: string;
  displayText: string;
  onClick?: Function;
  sheets: IExcelSheet[];
  columns?: IExcelColumn[];
  className: string;
  showIcon?: boolean
}

interface State {
  isDisable?: boolean;
}

export default class App extends React.Component<IAppProps, State> {
  public static defaultProps = {
    displayText: "Export",
  };
  private exportBtn: React.RefObject<HTMLButtonElement>;
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      isDisable: false,
    };
    this.exportBtn = React.createRef();
  }

  setButton = (value) => {
    this.setState({ isDisable: value });
  };

  // const sleep = (m) => new Promise((r) => setTimeout(r, m));

  async handleClick() {
    this.setButton(true);
    this.props.onClick && (await this.props.onClick());
    if (!isEmpty(this.props.sheets[0].data))
      this.exportBtn.current && this.exportBtn.current.click();
    this.setButton(false);
  }
  public render() {
    const { isDisable } = this.state;
    const { displayText, fileName, columns, sheets, className, showIcon } = this.props;
    return (
      <>
        <button
          className={className}
          onClick={this.handleClick.bind(this)}
          disabled={isDisable}
        >
          {showIcon && <BiExport />}
          {isDisable ? "Waiting..." : displayText}
        </button>
        <Workbook
          filename={fileName + ".xlsx"}
          element={
            <button
              ref={this.exportBtn}
              style={{ opacity: 0, visibility: "hidden" }}
            />
          }
        >
          {sheets && sheets.length > 0
            ? sheets.map((sheet, index) => {
              const sheetName = sheet.sheetName
                ? sheet.sheetName
                : `Sheet ${index + 1}`;
              return (
                <Workbook.Sheet
                  data={sheet.data ? sheet.data : []}
                  name={sheetName}
                >
                  {columns &&
                    columns.map((column, i) => {
                      return (
                        <Workbook.Column
                          label={column.label ? column.label : ""}
                          value={column.value ? column.value : ""}
                        />
                      );
                    })}
                </Workbook.Sheet>
              );
            })
            : sheets &&
            sheets.length == 0 && (
              <Workbook.Sheet data={[]} name={"Sheet 1"}>
                {columns &&
                  columns.map((column, i) => {
                    return (
                      <Workbook.Column
                        label={column.label ? column.label : ""}
                        value={column.value ? column.value : ""}
                      />
                    );
                  })}
              </Workbook.Sheet>
            )}
          {/* <Workbook.Sheet data={data1} name="Sheet A">
          <Workbook.Column label="Foo" value="foo" />
          <Workbook.Column label="Bar" value="bar" />
        </Workbook.Sheet>
        <Workbook.Sheet data={data2} name="Another sheet">
          <Workbook.Column label="Double aaa" value={(row) => row.aaa * 2} />
          <Workbook.Column
            label="Cubed ccc "
            value={(row) => Math.pow(row.ccc, 3)}
          />
        </Workbook.Sheet> */}
        </Workbook>
      </>
    );
  }
}
