import React, { Component } from 'react'
import Tabs from '../tabs/tabs'
import CommonFilterSelect from './commonFilterSelect'
import AdvancedFilterSelect from './advancedFilterSelect'

export class CommonFilters extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
                employee:{},
                branch:null,
                department:{},
                leaveStatus:{},
                jobStatus:null,
                leaveType:{},
                leaveSource:{},
                level:{},
                position:{},
                state:{},
                subDepartment:{},
                location:{},
                gender:{},
                maritalStatus:{},
        }
        this.onSelectChange=this.onSelectChange.bind(this);
    }
    onSelectChange(name, value) {
        this.setState({ [name]: value });
    }
    render() {
        const {
                employee,
                branch,
                department,
                leaveStatus,
                jobStatus,
                leaveType,
                leaveSource,
                level,
                position,
                state,
                subDepartment,
                location,
                gender,
                maritalStatus
        }  =this.state
        const value = {employee,branch,department,leaveStatus,jobStatus,leaveType,leaveSource};
        const advancedValues ={level,position,state,subDepartment,location,gender,maritalStatus};

        const 
        {
            onApply,
            isJobStatus,
            isLeaveSource,
            isLeaveStatus,
            isLeaveTypes,
            isGender,
            isMaritial,
            extraCommonButton,
            extraSelect,
        }
        = this.props;
        return (
            <Tabs type="tabs-block" isCollapse={true}>
                <div label="Common parameters" name="Common parameters">
                    <div className="filters-input filters">
                        <CommonFilterSelect value={value} 
                                    onChange={this.onSelectChange} 
                                    isJobStatus={isJobStatus}
                                    isLeaveTypes={isLeaveTypes}
                                    isLeaveSource={isLeaveSource}
                                    isLeaveStatus={isLeaveStatus}
                                />
                                {extraSelect && extraSelect}
                        <div className="filters-input__item">
                            <button className="btn btn-primary" onClick={()=>onApply()}>Apply</button>
                            {extraCommonButton && extraCommonButton}
                        </div>
                    </div>
                    
                </div>
                <div label="Advanced parameters" name="Advanced parameters">
                    <div className="filters-input filters">
                        <AdvancedFilterSelect 
                            isGender={isGender}
                            isMaritial={isMaritial}
                            value={advancedValues} 
                            onChange={this.onSelectChange} />
                        <div className="filters-input__item">
                            <button className="btn btn-blue" onClick={()=>onApply()}>Apply</button>
                        </div>
                    </div>
                </div>
            </Tabs>
        )
    }
}

export default CommonFilters
