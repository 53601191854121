import React from 'react'
import FormInput from 'components/form'
import DataSelect from 'components/dataselect';
import { useSelector } from 'react-redux';

function Archive(props) {
    const {
        filter,
        onChange
    } = props;
    const {
        branchList,
        departmentList
    } = useSelector((state: any) => state.commonReducer);
    const branches = branchList ? branchList.map(item => ({ label: item.Text, value: item.Value })) : [];
    const departments = departmentList ? departmentList.map(item => ({ label: item.Text, value: item.Value })) : [];
    return (
        <div className="tasksReport-filters">
            <FormInput
                formName="searchInput"
                name="search"
                placeholder="Search"
                value={filter.search}
                onChange={onChange}
                width="250px"
            />
            <FormInput
                onChange={onChange}
                name="owner"
                placeholder="Select Owner"
                value={filter.owner}
                width="200px"
            />
            <FormInput
                onChange={onChange}
                formName="reactselect"
                value={filter.branchId}
                label="Branch"
                name="branchId"
                options={branches}
                width="200px"
            />
            <FormInput
                onChange={onChange}
                formName="reactselect"
                value={filter.departmentId}
                label="Department"
                name="departmentId"
                options={departments}
                width="200px"
            />
            <FormInput
                formName="reactselect"
                onChange={onChange}
                name="dl"
                value={filter.dl}
                width="200px"
                options={[{ label: 'All', value: '1' }, { label: 'Overdue', value: '2' }, { label: 'Regular', value: '3' }]}
            />
        </div>
    )
}

export default Archive
