import * as React from "react";
import "./App.scss";
import "./Syncfusion.css";
import Routes from "./routes";
import Loading from "./components/common/loading";
import ToastMessages from "./components/common/toastMessages";
import {
  getUser,
  logoutUser,
  loginUser,
  refreshToken,
} from "./actions/authAction";
import { ConnectedRouter } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { useActiveFeatureFlag } from "services/features/useFeature";
import Loaders from "components/loaders";
import {
  GetCompanyHolidaysWhole,
  GetCompanyInformation
} from "actions/commonAction";
import { ConfigProvider } from "react-avatar";
import CheckIdleTime from "components/common/checkIdleTime";
import GlobalErrorBoundry from "./errors/globalErrorBoundry";
import { useDarkMode } from "helpers/customHooks/useDarkMode";
import useCommonAPIs from 'helpers/customHooks/useCommonApis';

export default (props: any) => {
  // const fetchFlags = async () =>{
  //   await store.dispatch(getBaseURL());
  //   await store.dispatch(getActiveFeatureFlag());
  //   await store.dispatch(getFeatureFlag());

  // }
  // const [darkMode, setDarkMode] = useDarkMode();
  const dispatch = useDispatch();
  const { isAuthenticated, user, baseUrl } = useSelector((state: any) => state.auth);
  const { refresh_loading } = useSelector((state: any) => state.loadingReducer);

  useCommonAPIs();

  React.useEffect(() => {
    const handleInvalidToken = (e) => {
      if (e.key === "RefreshToken" && e.oldValue && !e.newValue) {
        // Your logout logic here
        // logoutUser();
        props.history.push("/sign-out");
      }
    };
    window.addEventListener("storage", handleInvalidToken);
    return function cleanup() {
      window.removeEventListener("storage", handleInvalidToken);
    };
  }, [logoutUser]);

  React.useEffect(() => {
    const handleInvalidToken = (e) => {
      if (e.key === "UserDetail" && e.oldValue && e.newValue) {
        if (e.newValue !== e.oldValue) {
          dispatch(refreshToken());
        }
      }
    };
    window.addEventListener("storage", handleInvalidToken);
    return function cleanup() {
      window.removeEventListener("storage", handleInvalidToken);
    };
  }, [loginUser]);

  // const { data, status, error, isFetching } = useActiveFeatureFlag();
  // if (status === "loading") {
  //   return <Loaders type={"spinner"} loading={true} />;
  // }
  // if (error) {
  //   console.log(error);
  // }
  // if (!isEmpty(data)) {
  //   dispatch(activeFlags(data));
  // }
  if (refresh_loading) {
    return null;
  }
  return (
    <ConnectedRouter history={props.history}>
      <GlobalErrorBoundry>
        <React.Suspense fallback={<h2>Loading...</h2>}>
          <ConfigProvider colors={["#0074e9", "#43c463", "#de2e21", "#ffa126"]}>
            <div id="app" className="App">
              <Routes />
              <Loading />
              <ToastMessages />
              <CheckIdleTime />
              {/* <RefreshToken /> */}
              {/* <LoginCheck /> */}
            </div>
          </ConfigProvider>
        </React.Suspense>
      </GlobalErrorBoundry>
    </ConnectedRouter>
  );
};
