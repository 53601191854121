import React from "react";
import { connect } from "react-redux";
import EmployeeSearch from "components/common/employeeSearch";
import Form from "components/form";

function Approvals(props) {
  const handleAutoSelect = (event, value) => {
    props.handleEmployeeSelect(event, value);
  };

  const {
    ReviewerRefId,
    Reviewer2RefId,
    ApproverRefId,
    Approver2RefId,
    errors,
  } = props.data;

  return (
    <div className="form-row">
      <div>
        <label className="approval-label">Project Reviewers</label>
        <div className="approval mt-sm">
          <span className="approval_index">1</span>
          <Form
            formName="reactselect"
            name="ReviewerRefId"
            value={ReviewerRefId}
            options={props.employeeListSelect}
            onChange={handleAutoSelect}
            placeholder="Select..."
            width="220px"
          />
        </div>
        <div className="approval">
          <span className="approval_index">2</span>
          <Form
            formName="reactselect"
            name="Reviewer2RefId"
            value={Reviewer2RefId}
            options={props.employeeListSelect}
            onChange={handleAutoSelect}
            placeholder="Select..."
            width="220px"
          />
        </div>
      </div>
      <div rootClassName="ml-bg">
        <label className="approval-label">Project Approvals</label>
        <div className="approval mt-sm">
          <span className="approval_index">1</span>
          <Form
            formName="reactselect"
            name="ApproverRefId"
            options={props.employeeListSelect}
            value={ApproverRefId}
            onChange={handleAutoSelect}
            placeholder="Select..."
            width="220px"
          />
        </div>
        <div className="approval">
          <span className="approval_index">2</span>
          <Form
            formName="reactselect"
            name="Approver2RefId"
            value={Approver2RefId}
            options={props.employeeListSelect}
            onChange={handleAutoSelect}
            placeholder="Select..."
            width="220px"
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  employeeListSelect: state.commonReducer.employeeListSelect,
});

export default connect(mapStateToProps, null)(Approvals);
