import axios from "axios";
import { Apis } from "src/config/api";
import { getErrorResponse } from "helpers/getApiResponse";
import { loadingHeaders } from "helpers/getHeaders";
//#region leave request
const getLeaveRequestType = async (searchData) => {
  try {
    let { employeeId, enabledOnly, isLeaveRequest } = searchData;
    let result = await axios.get(
      Apis.GetLeaveRequestTypeApi +
        `?EmployeeId=${employeeId}
                  &EnabledOnly=${enabledOnly}&IsLeaveRequest=${isLeaveRequest}`,
      loadingHeaders
    );
    return result.data;
  } catch (err) {
    return getErrorResponse(err?.message);
  }
};
const getLeaveRequestApprover = async (employeeId) => {
  try {
    let result = await axios.get(
      Apis.GetLeaveRequestApprover + `?employeeId=${employeeId}`,
      loadingHeaders
    );
    return result.data;
  } catch (err) {
    return getErrorResponse(err?.message);
  }
};
const createLeaveRequest = async (requestData) => {
  try {
    let result = await axios.post(Apis.LeaveRequestApi, requestData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    return result.data;
  } catch (err) {
    return getErrorResponse(err?.message);
  }
};
const updateLeaveRequest = async (requestData) => {
  try {
    let result = await axios.put(Apis.LeaveRequestApi, requestData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    return result.data;
  } catch (err) {
    return getErrorResponse(err?.message);
  }
};
const getLeaveRequestById = async (leaveRequestId) => {
  try {
    let result = await axios.get(
      Apis.LeaveRequestApi + "/" + leaveRequestId,
      loadingHeaders
    );
    return result.data;
  } catch (err) {
    return getErrorResponse(err?.message);
  }
};
const getTeamMemberLeaveDetails = async (requestData) => {
  try {
    let result = await axios.get(
      Apis.GetTeamMemberLeaveDetails +
        `?leaveRequestId=${requestData.leaveRequestId}&branchDepartment=${requestData.division}`,
      loadingHeaders
    );
    return result.data;
  } catch (err) {
    return getErrorResponse(err?.message);
  }
};

const getLeaveRequestDays = async (data) => {
  try {
    let result = await axios.post(
      Apis.GetLeaveRequestDays,
      data,
      loadingHeaders
    );
    return result.data;
  } catch (err) {
    return getErrorResponse(err?.message);
  }
};
export {
  getLeaveRequestType,
  getLeaveRequestApprover,
  createLeaveRequest,
  updateLeaveRequest,
  getLeaveRequestById,
  getTeamMemberLeaveDetails,
  getLeaveRequestDays
};
