import React, { Component, useEffect, useState } from "react";
// import isEmpty from "~/helpers/isEmpty";
import { withRouter } from "react-router-dom";
import Login from "scenes/auth/login";
import { Switch, useLocation, Route } from "react-router-dom";
import Logout from "scenes/auth/signout";
import Navigate from "./navigate";
import { RouteNest } from "components/routes/nestedRoutes";
import Header from "components/layout/header";
import { useSelector } from "react-redux";
import isEmpty from "helpers/isEmpty";
import Sidebar from "components/layout/sidebar";
import LoadingHOC from "src/hoc/loadingHOC";
// import Try from "./navigate/try";
import EmpHeader from "scenes/common/header";
import AdminLinks from "./adminLinks";
import TravelLinks from "./travelLinks";
import TaskTrackerLinks from "./taskTrackerLinks";
import TasksLinks from "./taskLinks";
import EmployeeLinks from "./employeeLinks";
import Footer from "components/layout/footer";
import { Features } from "helpers/getPermissions";
import Try from "./navigate/try";
import ExportPdf from "components/common/exportPdf";
import SwitchUser from "scenes/auth/switchUser";
// import Logout from 'scenes/auth/logout'
import { roles } from "constants/constants";
import ResetPassword from "scenes/auth/resetPassword";
import classnames from "classnames";
import OnlyDev from "components/common/onlyDev";
import RouteEnum from "constants/routeEnum";
import RecruitLinks from "./recruitLinks";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import routesList, { justLoanModule } from "./routes";
import CreateNewTravelRequest from "scenes/travel/common/travelInformation/createTravelRequest/createNewTravelRequest";
import NotFound from "components/common/404";
import { RestaurantTwoTone } from "@material-ui/icons";
import Button from "components/button";
import { goToOldEmployeeDashabord } from 'constants/constants';
import LoanButton from "components/common/commonLoanModuleButton";
import ResetOldHash from 'scenes/auth/resetOldHash'
import Links from "src/components/layout/sidebar/links";

const Routes = (props) => {
  const pad = { paddingBottom: 0 };
  const layoutClasses = classnames("layout-body", {
    "layout-employee": props.isAuthenticated ? props.user.RoleId === 2 : false,
  });
  const [feat, setFeat] = useState<any>({});
  const location = useLocation();
  const features = Features();
  // useEffect(()=>{
  //   if(features){
  //     setFeat(features)
  //   }
  // },[features])
  const { featureFlags } = useSelector((state: any) => state.permissionReducer);
  useEffect(() => {
    // if(featureFlags){
    //   setFeat(features)
    // }
  }, [featureFlags]);

  const routes = routesList(features);
  return (
    <RouteNest feature={"Default"} path="/" isNotPrivate component={Layout}>
      <Switch>
        <RouteNest
          path={RouteEnum.Signout}
          exact
          title="Logout"
          isNotPrivate
          feature={"Default"}
          component={Logout}
        />
        <RouteNest
          path={RouteEnum.ResetOldHashPath}
          exact
          title="Reset Old Password"
          feature={"Default"}
          component={ResetOldHash}
        />

        {/* <TransitionGroup>
            <CSSTransition key={location.key} classNames="slide" timeout={2000}> */}
        <RouteNest
          path={RouteEnum.LoginPath}
          exact
          title="Login"
          isNotPrivate
          feature={"Default"}
          component={Login}
        />

        <RouteNest
          path={RouteEnum.ResetPassword}
          exact
          title="Reset Password"
          key="reset"
          isNotPrivate
          feature={"Default"}
          component={ResetPassword}
        />
        <RouteNest
          path={RouteEnum.Switchuser}
          exact
          title="Switch User"
          feature={"Default"}
          isNotPrivate
          component={SwitchUser}
        />
        <RouteNest
          path="/try"
          feature={"Default"}
          exact
          title="Try"
          isNotPrivate
          isDev
          component={Try}
        />
        <RouteNest
          path="/"
          feature={"Default"}
          exact
          title="Home"
          component={Navigate}
        />
        <RouteNest
          path={RouteEnum.ExportPdfPath}
          key="export pdf"
          feature={"Default"}
          title="Export PDF"
          component={ExportPdf}
        />
        <RouteNest
          path={`${RouteEnum.Links}/:feature/:category`}
          exact
          title="Links"
          feature={"Default"}
          component={Links}
        />
        {routes.map((route: any, index) => {
          if (route.children) {
            return (
              <RouteNest key={index + Math.round(1)} {...route}>
                {route.children.map((item: any, ind) => {
                  let a = (
                    <RouteNest
                      key={ind + Math.round(1)}
                      {...item}
                      feature={route.feature}
                    />
                  );
                  if (item.isTravelAnimate) {
                    const comp = Object.assign({}, item);
                    item.component = AnimatedTravel;
                    a = (
                      <RouteNest
                        key={ind + Math.round(1)}
                        {...item}
                        feature={route.feature}
                        Comp={comp.component}
                      />
                    );
                  }
                  return a;
                })}
              </RouteNest>
            );
          }
          return <RouteNest key={index + Math.round(1)} {...route} />;
        })}
        {/* <AdminLinks /> */}
        {/* <TravelLinks /> */}
        {/* <TaskTrackerLinks /> */}
        {/* <TasksLinks /> */}
        {/* <EmployeeLinks /> */}
        {/* </CSSTransition>
          </TransitionGroup> */}
        <RouteNest feature={"Default"} exact path="*" component={NotFound} />
        <RouteNest feature={"Default"} component={NotFound} />
      </Switch>
    </RouteNest>
  );
};

const AnimatedTravel = (props) => {
  const { Comp, history, ...rest } = props;
  let currentLink = history.location.pathname;
  return (
    <CreateNewTravelRequest {...rest} history={history} />
    // <div
    //   className={`display-visit ${
    //     currentLink === RouteEnum.CreateTravelRequestPath ||
    //     currentLink === RouteEnum.UpdateTravelRequestPath
    //       ? "travel-fixed-show"
    //       : ""
    //   }`}
    // >
    //   <div className="travel-fixed ">
    //     <div className="travel-fixed-body">
    //       <CreateNewTravelRequest {...rest} history={history} />
    //       {/* <Comp {...rest} history={history} /> */}
    //     </div>
    //   </div>
    // </div>
  );
};

const Layout = (props) => {
  const { isAuthenticated, user } = useSelector((state: any) => state.auth);
  const layoutClasses = classnames("layout-body", {
    "layout-employee": isAuthenticated && user.RoleId === 2,
  });
  const { baseUrl } = props;
  const pad = { paddingBottom: 0 };
  return (
    <div className="layout layout-sidebar">
      <CommonLayout for="Admin" baseUrl={baseUrl} component={Sidebar} />
      <div
        className={layoutClasses}
        style={
          isAuthenticated
            ? user.RoleId === 1
              ? pad
              : { backgroundColor: "#eff3f9" }
            : pad
        }
      >
        {/* <CommonLayout for="Employee" baseUrl={baseUrl} component={LoanButtons} /> */}
        <CommonLayout for="Employee" component={EmpHeader} />
        <CommonLayout for="Admin" component={Header} />
        {props.children}
      </div>
      <CommonLayout for="Employee" component={Footer} />
    </div>
  );
};

interface layoutInterface {
  for: "Employee" | "Admin" | "Both";
  component: any;
  baseUrl?: any,
}

const CommonLayout = (props: layoutInterface) => {
  const user = useSelector((state: any) => state.auth.user);
  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );
  const fea = useSelector(
    (state: any) => state.permissionReducer.activeFeatureFlags
  );
  const [role, setRole] = useState(0);
  const features = Features();
  useEffect(() => {
    if (!isEmpty(user)) {
      setRole(user.RoleId);
    } else {
      setRole(0);
    }
  }, [user]);
  useEffect(() => {
    Features();
    // console.log(feature)
  }, [fea]);
  if (!isAuthenticated) {
    return null;
  }
  const { for: compFor, component, ...rest } = props;
  // let roled:any[number] = roles();
  switch (compFor) {
    case "Employee":
      // return role != 1 && role > 1 && !roled.includes(role) ? <props.component /> : null;
      return role === 2 ? <props.component {...rest} /> : null;
    case "Admin":
      return role !== 2 ? <props.component {...rest} features={features} /> : null;
    case "Both":
      return <props.component />;
    default:
      return null;
  }
};


const LoanButtons = (props) => {
  const { baseUrl } = props;
  return (
    <>
      <Header />
      <div className="space-between p-sm personal">
        <Button
          bg="secondary"
          title="Back to Dashboard"
          onClick={() => goToOldEmployeeDashabord(baseUrl)}
        />
        <LoanButton buttonTitle="New Request" isAssign={false} />
      </div>
    </>
  )
}


export default withRouter(LoadingHOC(Routes));
