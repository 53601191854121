import React, { Component } from "react";
import FormGroup from "components/form";
import CustomDateRange from "components/customdaterange";
import isEmpty from "helpers/isEmpty";
import EmployeeSearch from "components/common/employeeSearch";

export class SearchAndFilter extends Component {
  render() {
    const {
      searchEmployee,
      searchActionStatement,
      fromDate,
      toDate,
      activeJobRole,
      activePosition,
    } = this.props.parentState;
    const { designationList, levelList, handleAutoSelect } = this.props;
    return (
      <div className="filters">
        {/* <InputGroup
            className="search"
            name="searchEmployee"
            value={searchEmployee}
            onChange={this.props.handleInput}
            placeholder="Search Employee"
          /> */}
        <EmployeeSearch
          onChange={handleAutoSelect}
          value={searchEmployee}
          label="Employee"
          name="searchEmployee"
          // width="220px"
        />

        <div className="filters-grid">
          <FormGroup
            className="search"
            name="searchActionStatement"
            value={searchActionStatement}
            onChange={this.props.handleInput}
            placeholder="Search Action Statement"
          />
        </div>

        <div className="filters-grid">
          <CustomDateRange
            startDate={fromDate}
            endDate={toDate}
            handleDateRangePicker={this.props.handleDateRangePicker}
            placeholder="Filter date range"
          />
        </div>

        <div className="filters-grid">
          <FormGroup
            onChange={this.props.handleAutoSelect}
            name="activeJobRole"
            value={activeJobRole}
            options={levelList}
            formName="reactselect"
            placeholder="Filter Job Role"
          />
        </div>

        <div className="filters-grid">
          <FormGroup
            formName="reactselect"
            isAbove
            onChange={this.props.handleAutoSelect}
            name="activePosition"
            value={activePosition}
            options={designationList}
            placeholder="Filter Position"
          />
        </div>
      </div>
    );
  }
}

export default SearchAndFilter;
