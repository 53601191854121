import React, { Component } from "react";
import classnames from "classnames";
import { expandIcon } from "scenes/taskTracker/common/expandIcon";
import NumberToCash from "components/numberToCash";
import { minutesToHrMin } from "../../common/minutesToHrMin";
export class TableRow extends Component {
  constructor(props) {
    super(props);
    this.state = { drop: false };
    this.toggleDetail = this.toggleDetail.bind(this);
  }
  componentDidMount() {
    if (this.props.index == this.props.indexToExpand) {
      this.setState({ drop: true });
    }
  }

  toggleDetail = (id, drop) => {
    this.setState({ drop: !drop });
    var toggleIcon = document.getElementById(`toggleIcon${id}`);
    toggleIcon.classList.toggle("expand");
    var detailsRow = document.querySelectorAll(`.details-${id}`);

    if (detailsRow) {
      for (var i = 0; i < detailsRow.length; i++) {
        detailsRow[i].classList.toggle("row-expand");
      }
    }
  };
  // componentDidMount() {
  //   this.toggleDetail(this.props.index);
  // }

  render() {
    const { props } = this;
    const { drop } = this.state;
    let trClass = "table-row";
    if (drop) {
      trClass = trClass + " expanded__tr";
    }
    return (
      <>
        <tr
          className={trClass}
          role="row"
          key={props.index}
          id={`toggleIcon${props.index}`}
          onClick={() => this.toggleDetail(props.index, drop)}
        >
          <td className="e-rowcell" role="gridcell">
            {props.ActivityDetail &&
              props.ActivityDetail.length > 0 &&
              expandIcon(drop)}
          </td>

          <td className="e-rowcell " role="gridcell">
            <span>{props.MemberName}</span>
          </td>

          <td className="e-rowcell " role="gridcell">
            {/* //Client Name */}
          </td>
          <td className="e-rowcell" role="gridcell">
            {/* //ProjectName */}
          </td>

          <td className="e-rowcell " role="gridcell">
            <span>{minutesToHrMin(props.BillableHours)}</span>
          </td>
          <td className="e-rowcell " role="gridcell">
            {/* //Rate */}
          </td>
          <td className="e-rowcell table-cash" role="gridcell">
            <span>
              <NumberToCash value={props.BillableAmount} toFixed={2} />
            </span>
          </td>
          <td className="e-rowcell " role="gridcell">
            <span>{minutesToHrMin(props.NonBillableHours)}</span>
          </td>
          <td className="e-rowcell " role="gridcell">
            {/* //Type of work */}
          </td>
          <td className="e-rowcell " role="gridcell">
            <span>{minutesToHrMin(props.WorkedHours)}</span>
          </td>
        </tr>

        {props.ActivityDetail &&
          props.ActivityDetail.map((activity, i) => {
            const rowClasses = classnames({
              "table-details": true,
              "custom-row": true,
              "expanded__tr-details": true,
              "row-expand": props.index == props.indexToExpand,
              [`details-${props.index}`]: true,
            });
            return (
              <tr className={rowClasses} key={i}>
                <td></td>
                <td></td>
                <td>
                  <span className="detail">{activity.ClientName}</span>
                </td>
                <td>
                  <span className="detail">
                    <span className="project">
                      <span className="project-icon">P</span>
                      <span>{activity.ProjectName}</span>
                    </span>
                  </span>
                </td>

                <td>
                  <span className="detail">
                    {minutesToHrMin(activity.BillableHours)}
                  </span>
                </td>

                <td className="table-cash">
                  <span className="detail">
                    <NumberToCash value={activity.HourlyRate} toFixed={2} />
                  </span>
                </td>

                <td className="table-cash">
                  <span className="detail ">
                    <NumberToCash value={activity.BillableAmount} toFixed={2} />
                  </span>
                </td>

                <td>
                  <span className="detail ">
                    {minutesToHrMin(activity.NonBillableHours)}
                  </span>
                </td>
                <td>
                  <span className="detail ">{activity.ActivityTypes}</span>
                </td>
                <td>
                  <span className="detail ">
                    {minutesToHrMin(activity.WorkedHours)}
                  </span>
                </td>
              </tr>
            );
          })}
      </>
    );
  }
}

export default TableRow;
