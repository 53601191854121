import React, {useState,useEffect} from 'react'
import './forgot.styles.scss';
import InputGroup from 'components/form';
import userFormValidation from 'validations/useFormValidation';
import isEmpty from 'helpers/isEmpty';
import { changePassword } from 'actions/authAction';
import {useDispatch} from 'react-redux'

const validateForGotPassword = (values) =>{
    let errors = {};
    if(isEmpty(values.old_password)){
        errors.old_password = "Old password is required"
    }
    if(isEmpty(values.password)){
        errors.password = "New password is required"
    }
    if(isEmpty(values.password2)){
        errors.password2 = "Confirm password is required"
    }
    if(values.password != values.password2){
        errors.password = "New password and confirm password are not the same"
    }
    return errors
}

function ChangePassword(props) {
    let INITIAL_STATE = {
        old_password:'',
        password:'',
        password2:''
    }
    const dispatch = useDispatch();
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        clearValues,
        isSubmitting,
      } = userFormValidation(INITIAL_STATE, validateForGotPassword);
      if(isSubmitting){
        if(isEmpty(errors)){
            const data = {
                CurrentPassword:values.old_password,
                NewPassword:values.password,
                ConfirmNewPassword:values.password2,
            }
            dispatch(changePassword(data))
            clearValues()
        }
      }
    return (
        <form onSubmit={handleSubmit}>
        <div className="primary-modal-body">
            
                <div className="primary-modal-container">
                    <div className="change-password">
                        <InputGroup 
                            label="Old Password"
                            name="old_password"
                            onChange={handleChange}
                            handleBlur={handleBlur}
                            type="password"
                            value={values.old_password}
                            required={true}
                            error={errors.old_password}
                            width={350}
                        />
                        <InputGroup 
                            label="New Password"
                            name="password"
                            onChange={handleChange}
                            handleBlur={handleBlur}
                            type="password"
                            value={values.password}
                            required={true}
                            error={errors.password}
                            width={350}
                        />
                        <InputGroup 
                            label="Confirm Password"
                            name="password2"
                            onChange={handleChange}
                            handleBlur={handleBlur}
                            type="password"
                            value={values.password2}
                            required={true}
                            error={errors.password2}
                            width={350}
                        />
                    </div>
                </div>
                <div className="primary-modal-footer">
                    <div className="button-group button-group__right">
                        <button 
                            disabled={isSubmitting}
                            type="submit" 
                            className="button button-primary">Save Password</button>
                        <button 
                            onClick={()=>props.onModalClose()}
                            className="button button-secondary">Cancel</button>
                    </div>
                    
                </div>
        </div>
        </form>
    )
}

export default ChangePassword
