import React, { Component } from "react";
import { AiOutlineFilePdf } from "react-icons/ai";
import { FaArrowCircleRight } from "react-icons/fa";

export class JobHistory extends Component {
  render() {
    const { data } = this.props;
    if (!data) {
      return null;
    }
    return (
      <div className="section">
        <div className="history">
          <div className="bloch__title">Job History</div>
          <div className="history-block">
            {data.map((d, i) => {
              return (
                <div className="history-block-section">
                  <span className="count">{d.SN}</span>
                  <span>{d.Event}</span>
                  {/* <span className="nepDate">2074, Baisakh-1</span> */}
                  <span className="nepDate">{d.FromDate}</span>
                  {/* <span className="engDate">2015,January-1</span> */}
                  <span className="engDate">{d.FromDateEng}</span>
                  <div className="description">
                    <span className="description-item">{d.From}</span>
                    <span className="description-item">
                      <FaArrowCircleRight />
                    </span>
                    <span className="description-item">{d.To}</span>
                    <abbr
                      title="No file available.."
                      className="description-pdf"
                    >
                      <AiOutlineFilePdf />
                    </abbr>
                  </div>
                </div>
              );
            })}

            {/* <div className="history-block-section">
              <span className="count">{data.SN}</span>
              <span>{data.Event}</span>
              <span className="nepDate">2074, Baisakh-1</span>
              <span className="engDate">2015,January-1</span>
              <div className="description">
                <span className="description-item">Accounts</span>
                <span className="description-item">
                  <FaArrowCircleRight />{" "}
                </span>
                <span className="description-item">Finance</span>
                <span className="description-pdf">
                  <AiOutlineFilePdf />
                </span>
              </div>
            </div>
            <div className="history-block-section">
              <span className="count">8</span>
              <span>Branch Transfer</span>
              <span className="nepDate">2074, Baisakh-1</span>
              <span className="engDate">2015,January-1</span>
              <div className="description">
                <span className="description-item">Butwal</span>
                <span className="description-item">
                  <FaArrowCircleRight />{" "}
                </span>
                <span className="description-item">Pokhara</span>
                <span className="description-pdf">
                  <AiOutlineFilePdf />
                </span>
              </div>
            </div>
            <div className="history-block-section">
              <span className="count">9</span>
              <span>Designation Change</span>
              <span className="nepDate">2074, Baisakh-1</span>
              <span className="engDate">2015,January-1</span>
              <div className="description">
                <span className="description-item">Finance Assistant</span>
                <span className="description-item">
                  <FaArrowCircleRight />{" "}
                </span>
                <span className="description-item">Finance Manager</span>
                <span className="description-pdf">
                  <AiOutlineFilePdf />
                </span>
              </div>
            </div>
         */}
          </div>
        </div>
      </div>
    );
  }
}

export default JobHistory;
