import React, { Component } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Filter,
  Inject,
} from "@syncfusion/ej2-react-grids";
import Pagination from "components/common/pagination";
import DisciplinaryTableRow from "./disciplinaryTableRow";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

export class DisciplinaryTable extends Component {
  constructor(props) {
    super(props);
    this.widths = {
      image: 49,
      name: 250,
      idCard: 70,
      jobRolePosition: 250,
      date: 150,
      actionStatement: 200,
      stage: 200,
    };

    this.gridTemplate = this.gridTemplate;
    this.row = [];
  }

  gridTemplate(props) {
    const { history } = this.props;
    return (
      <DisciplinaryTableRow
        {...props}
        handleMoveToNextStage={this.props.handleMoveToNextStage}
        handleDelete={this.props.handleDelete}
        handleEdit={this.props.handleEdit}
        stageId={this.props.stageId}
        {...history}
        ref={(row) => {
          this.row = [...this.row, row];
        }}
      />
    );
  }

  render() {
    const {
      data,
      handleRowChange,
      handleIndexChange,
      pageIndex,
      rowsPerPage,
      stageId,
    } = this.props;
    return (
      <div className="table">
        <div className="arrear-table">
          {/* <Loader open={travelSettlement.loading} /> */}

          <div className="pending-settlement-grid">
            <GridComponent
              dataSource={data}
              className="custom-grid"
              toolbar={this.toolbar}
              filterSettings={{ type: "Menu" }}
              allowFiltering={true}
              //  height={!isEmpty(searchedLeaveAbsence)?"500":'80'}
              id="grid"
              allowExcelExport={true}
              ref={(g) => (this.grid = g)}
              rowTemplate={this.gridTemplate.bind(this)}
              allowSorting={true}
            >
              <ColumnsDirective>
                <ColumnDirective width={this.widths.image} />
                <ColumnDirective
                  field="Name"
                  headerText="Name"
                  width={this.widths.name}
                />
                <ColumnDirective headerText="INO" width={this.widths.idCard} />
                <ColumnDirective
                  field="Level"
                  headerText="Job Role and Position"
                  width={this.widths.jobRolePosition}
                />
                <ColumnDirective
                  field="ReportedDateEng"
                  headerText="Date"
                  width={this.widths.date}
                />
                <ColumnDirective
                  field="CaseTitle"
                  headerText="Action Statement"
                  width={this.widths.actionStatement}
                />
                <ColumnDirective
                  field="Stage"
                  headerText="Stage"
                  width={this.widths.stage}
                />
                {/* <ColumnDirective width={220} /> */}
                {stageId === 0 ? <ColumnDirective width={240} /> : null}
              </ColumnsDirective>
              <Inject services={[Filter]} />
            </GridComponent>

            {data && data.length > 0 && (
              <Pagination
                handleRowChange={handleRowChange}
                handleIndexChange={handleIndexChange}
                pageIndex={pageIndex}
                rowsPerPage={rowsPerPage}
                total={data[0].TotalRows}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default withRouter(connect(mapStateToProps, {})(DisciplinaryTable));
