import React, { useState } from 'react'
import './styles.scss';
import classnames from 'classnames';
import image from 'assets/images/sample.jpg';
import {employeeCourseView, getAssignedCoursesDashboad, getCoursesDashboad} from '../helpers/action';
import { useEffect } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import RouteEnum from 'constants/routeEnum';
import dayjs from 'dayjs';
import { AiOutlineUser } from 'react-icons/ai';
import { FaFeather } from 'react-icons/fa';
var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)


const status = [
    { name: 'Assigned', num: 5, id: 1 },
    { name: 'In Progress', num: 1, id: 2 },
    { name: 'Deadlines', num: 2, id: 3 },
    { name: 'All Courses', num: null, id: 4 },
    { name: 'Completed', num: 1, id: 5 },
]

function Courses(props) {
    const [active, setActive] = useState(4);
    const {courseDashboard, assignedCourseDashboard} = useSelector((state:any)=>state.lmsReducer);
    const {user} = useSelector((state:any)=>state.auth);
    const dispatch = useDispatch();
    
    useEffect(()=>{
        const data = {
            "pageIndex": 0,
            "pageSize": 10,
            "employeeId": 10001,
          }
        dispatch(getAssignedCoursesDashboad(data))
        dispatch(getCoursesDashboad(data))
    },[])
    return (
        <div className="courses">
            <div className="courses-header">
                <div className="courses-header__container">
                    <div className="courses-header__title">Courses</div>
                    <div className="courses-status-tab">
                        <div className="courses-status__list">
                            {
                                status && status.map(item => {
                                    return (
                                        <div
                                            onClick={() => setActive(item.id)}
                                            key={item.id} className={classnames("courses-status__item", { "courses-active": active === item.id })}>
                                            {
                                                item.num && <div className="courses-status__num">{item.num}</div>
                                            }
                                            <div className="courses-status__num">{item.name}</div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                </div>
            </div>
            <div className="courses-body">
                <div className="courses-body__container">
                    <div className="courses-assigned">
                        <div className="course-title">Courses Assigned to You</div>
                        <div className="courses-assigned__container">
                            <ul className="courses-assigned__list">
                                {
                                    assignedCourseDashboard && assignedCourseDashboard.map(item => {
                                        return (
                                            <li className="courses-assigned__item">
                                                <div className="courses-assigned__image">
                                                    <img src={item.ServerFileName} alt="" />
                                                </div>
                                                <div className="courses-assigned__body">
                                                    <div 
                                                        onClick={()=>props.history.push(RouteEnum.lmsCourse +'/'+item.Id)}
                                                        className="courses-assigned__title">
                                                        <span>{item.Title}</span>
                                                    </div>
                                                    <div className="courses-assigned__to">
                                                        <FaFeather />
                                                        <span>{item.Author}</span>
                                                    </div>
                                                    <div className="courses-assigned__type">
                                                        {item.CategoryName}
                                                    </div>
                                                    <div className="courses-assigned__status">
                                                        {
                                                            item.PercentComplete === 0 ? 
                                                            <span className="bold">Not Started</span>
                                                            :
                                                            <span><span className="bold">{parseFloat(item.PercentComplete).toFixed(2)}</span>% Complate</span>
                                                        }
                                                        <span>Due <span className="bold">{dayjs(item.DueDate).format('ll')}</span></span>
                                                    </div>
                                                    <div className="courses-assigned__status-bar">
                                                        <div style={{ width: `${item?.PercentComplete}%` }} className="courses-assigned__status-active"></div>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="courses-public">
                        <div className="course-title">Explore Other Available Courses</div>
                        <div className="courses-public__container">
                            <ul className="courses-assigned__list">
                                {
                                    courseDashboard && courseDashboard.map(item => {
                                        return (
                                            <li className="courses-assigned__item">
                                                <div className="courses-assigned__image">
                                                    <img src={item?.ServerFileName} alt="" />
                                                </div>
                                                <div className="courses-assigned__body">
                                                    <div 
                                                        onClick={()=>{
                                                            if(user?.EmployeeId !== item.EmployeeId){
                                                                dispatch(employeeCourseView({courseId:item.Id}))
                                                            }
                                                            props.history.push(RouteEnum.lmsCourse +'/'+item.Id)
                                                        }}
                                                        className="courses-assigned__title">
                                                        <span>{item?.Title}</span>
                                                    </div>
                                                    <div className="courses-assigned__to">
                                                        <FaFeather /> {item?.Author}
                                                    </div>
                                                    <div className="courses-assigned__type">
                                                        {item.CategoryName}
                                                    </div>
                                                    <div className="courses-assigned__footer">
                                                        <span>{item?.rate}</span>
                                                        <span className="flex courses-assigned__view"> 
                                                            <AiOutlineUser />
                                                            <span>{item?.TotalView}</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Courses
