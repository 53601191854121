import React, { useState, useEffect, useRef } from "react";
import { Tabs, Tab } from "components/tabs/index.d";
import StatusLabelForm from "./statusLabelForm";
import { TMStage } from "scenes/tasks/helpers/enum";
import Button from "components/button";
import { MdClose } from "react-icons/md";
import SvgIcon from "components//icons/svgIcons";
import CustomPopup from "components/common/customPopup";
import classnames from "classnames";
import isEmpty from "helpers/isEmpty";
import TextInput from "./textInput";
import Modal from "components/common/modal";
const colors = [
  "#ff44c8",
  "#ff0c8b",
  "#cb1e52",
  "#865144",
  "#ff5601",
  "#ffca00",
  "#cdb618",
  "#8ad700",
  "#008247",
  "#0087c5",
  "#35ceff",
  "#ae54e3",
  "#808080",
  "#333333",
  "#ff9af6",
  "#ffa8ab",
  "#88338e",
  "#96adbf",
  "#56a2c0",
  "#0f5096",
  "#5754e7",
  "#48109a",
];
const ListItem = (props) => {
  const { value, onSaveLabel } = props;
  useEffect(() => {
    if (value) {
      setState({ ...value });
    }
  }, [value]);

  const [openPicker, setOpenPicker] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [state, setState] = useState({} as any);
  const ref = useRef(null);

  const handleSubmit = (name, value) => {
    setOpenPicker(false);
    if (name === "Color" && state.Color === value) return;
    let nextState = { ...state };
    nextState[name] = value;
    setState(nextState);
    onSaveLabel && onSaveLabel(nextState);
  };

  const handleInputChange = (name, value) => {
    let nextState = { ...state };
    nextState[name] = value;
    setState(nextState);
  };

  const renderColorPicker = () => {
    return (
      ref &&
      ref.current &&
      openPicker && (
        <CustomPopup
          parentRef={ref}
          open={openPicker}
          handleOutsideClick={() => setOpenPicker(false)}
        >
          <div className="board-label__colors">
            <ul className="board-label__colors-list">
              {colors.map((color, i) => {
                return (
                  <li
                    className="board-label__colors-item"
                    style={{ backgroundColor: color }}
                    key={i}
                    onClick={(e) => handleSubmit("Color", color)}
                  ></li>
                );
              })}
            </ul>
          </div>
        </CustomPopup>
      )
    );
  };

  return (
    <li className="board-label__item">
      <div
        className="board-label__item-color"
        ref={ref}
        style={!isEmpty(state.Color) ? { backgroundColor: state.Color } : {}}
        onClick={() => setOpenPicker(!openPicker)}
      >
        {isEmpty(state.Color) && (
          <SvgIcon name="eyeDropper" size="11px" color={state.Color} />
        )}
        {renderColorPicker()}
      </div>
      <div className="board-label__item-title">
        <TextInput
          value={state.Name}
          name="Name"
          handleChange={handleInputChange}
          handleSubmit={handleSubmit}
        />
      </div>
      <div
        className="board-label__item-button"
        onClick={(e) => setOpenConfirmation(true)}
      >
        <MdClose />
      </div>
      <Modal
        type="confirm"
        func={() => props.onDelete && props.onDelete(state.Id)}
        open={openConfirmation}
        onModalClose={() => setOpenConfirmation(false)}
      />
    </li>
  );
};
export default ListItem;
