import {
    EMPLOYEE_ALLOWANCE_HISTORY,
    EMPLOYEE_ALLOWANCE_TYPELIST,
    EMPLOYEE_ALLOWANCE_APPROVER_REVIEWER_LIST,
    SAVE_EMPLOYEE_ALLOWANCE_REQUESTS,
} from "actions/types";

const initialState = {
  employeeAllowanceTypeList:[]
};

export default function (state = initialState, action) {
    switch (action.type) {
        
    case EMPLOYEE_ALLOWANCE_HISTORY:
      return {
        ...state,
        employeeAllowanceHistory: action.payload
      };
    case EMPLOYEE_ALLOWANCE_TYPELIST:
      return {
        ...state,
        employeeAllowanceTypeList: action.payload
      };
    case EMPLOYEE_ALLOWANCE_APPROVER_REVIEWER_LIST:
      return {
        ...state,
        employeeAllowanceApproverReviewList: action.payload
      };

    case SAVE_EMPLOYEE_ALLOWANCE_REQUESTS:
      return {
        ...state,
        isEmployeeAllowanceSaved: action.payload
      };

    default:
        return state;
    }
}
