import React, { useEffect, useState } from 'react'
import Form from 'components/form';


export const CustomCheck = (props) => {
    const {onSelect,setSelectedRow,selectedRow} = props;
    const [checked,setChecked] = useState(false)
    // console.log(selectedRow)
    useEffect(()=>{
        onSelect(props.data,checked)
    },[checked])
    useEffect(()=>{
        if(props && props.data){
            if('isChecked' in props.data){
                if(props.data.isChecked){
                    setChecked(true)
                    onSelect(props.data,true)
                }
            }
        }
    },[])
    
    return(
        <div className="">
            <Form 
                name="checkGroup"
                formName="checkgroup"
                checked={checked}
                value={checked}
                onChange={(e) => {
                    // props.node.selected=e.target.checked
                    setChecked(e.target.checked)
                }}
            />
        </div>
    )
} 