import React from 'react'
import CommonModuleLinks from 'components/common/commonModuleLinks'
import { connect } from 'react-redux';
import isEmpty from 'helpers/isEmpty';

export const modules = (features) => [
    {
        groupTitle: 'Employee Reports',
        feature: features?.CoreHR,
        links: [
            { title: 'Head Count', link: 'CP/Report/NewHrReport/HeadCount.aspx' },
            { title: 'Gender Profile', link: 'CP/Report/NewHrReport/ReportGenderProfile.aspx' },
            { title: 'Genderwise Service Status', link: 'CP/Report/NewHrReport/reportGenderwisestatus.aspx' },
            { title: 'Age Profile', link: 'CP/Report/NewHrReport/ReportAgeProfile.aspx' },
            { title: 'Agewise Employee', link: 'newhr/EmployeeByAge.aspx' },
            { title: 'Employee Refrence', link: 'newhr/EmployeeReference.aspx' },
            { title: 'Pay Branch/Department', link: 'CP/Report/NewHrReport/ReportPayBranchDepartmentWise.aspx' },
            { title: 'Birthdays', link: 'CP/Report/NewHrReport/ReportBirthdays.aspx' },
            { title: 'Additions and Termissions', link: 'CP/Report/NewHrReport/ReportAdditionsTerminations.aspx' },
            { title: 'Employee Count', link: 'newhr/EmployeeCountReport.aspx' },
            { title: 'Employee Current Pay', link: 'newhr/EmployeeCurrentPaySalary.aspx' },
            { title: 'Employee Latest Events', link: 'CP/EmployeeLatestEvent.aspx' },
            { title: 'NRB Employee Count', link: 'CP/NRBEmployeeCountReport.aspx' },
        ]
    },
    {
        groupTitle: 'HR Reports',
        feature: features?.CoreHR,
        links: [
            { title: 'Employee Master', link: 'CP/EmployeeMaster.aspx' },
            { title: 'HR Missing Report', link: 'CP/HRMissingReport.aspx' },
            { title: 'Employee List', link: 'CP/Report/HREmployeeList.aspx' },
            { title: 'Employee HR Details', link: 'CP/Report/EmployeeListDetails.aspx' },
            { title: 'Status Change Report', link: 'CP/Report/HRStatusChange.aspx' },
            { title: 'Contract/Sales End Report', link: 'CP/Report/ContractListDetails.aspx' },
            { title: 'Date of Joining', link: 'CP/Report/HRDateOfJoining.aspx' },
            { title: 'Employee Contacts', link: 'CP/Report/HREmployeeContact.aspx' },
            { title: 'Skill Set report', link: 'CP/Report/SkillsetReport.aspx' },
            { title: 'Training Report', link: 'CP/Report/NewHrReport/TrainingList.aspx' },
            { title: 'Service History Report', link: 'CP/ServiceHistoryLetter.aspx' },
            { title: 'Employee Address Report', link: 'newhr/EmployeeAddressReport.aspx' },
            { title: 'Employee Information Report', link: 'newhr/EmpInfoList.aspx' },
            { title: 'Employee Eductaion Report', link: 'newhr/EmpEducationList.aspx' },
            { title: 'Employee Experience Report', link: 'newhr/EmployeePreviousExperience.aspx' },
            { title: 'Designation Change Report', link: 'newhr/DesignationChangeHistory.aspx' },
            { title: 'Transfer Duration Report', link: 'cp/BranchTransferDurationReport.aspx' },
            { title: 'Employee Qualification Report', link: 'newhr/EmpQualificationAndExperience.aspx' },
        ]
    },
    {
        groupTitle: 'Other Reports',
        feature: features?.CoreHR,
        links: [
            { title: 'Remote Area Employees', link: 'CP/ReportNew/RemoteAreaEmployeeList.aspx' },
            { title: 'New join List', link: 'CP/Report/HRNewJoinList.aspx' },
            { title: 'Service Period', link: 'CP/ServicePeriodList.aspx' },
            { title: 'Check Service Period', link: 'cp/extension/ServiceYearReport.aspx' },
            { title: 'Clerical and Non-Clerical', link: 'CP/Report/ClericalNonClericalReport.aspx' },
        ]
    },
    {
        groupTitle: 'Emp Family Reports',
        feature: features?.CoreHR,
        links: [
            { title: 'Emp Family Members', link: 'CP/Report/EmpFamilyMembersReport.aspx' },
            { title: 'Family List', link: 'CP/familylist.aspx' },
            { title: 'Family List For Insurance', link: 'CP/familylistforinsurence.aspx' },
            { title: 'Employee Family Report', link: 'newhr/EmpFamilyList.aspx' },
        ]
    },
    {
        groupTitle: 'Workforce reports',
        feature: features?.CoreHR,
        links: [
            { title: 'Workforce', link: 'CP/ManageSettings/WorkForcePlanning.aspx' },
            { title: 'Attrition', link: 'NewHr/WorkForceChartDashBorad.aspx' },
        ]
    },
    {
        groupTitle: 'Custom Reports',
        feature: features?.CoreHR,
        links: [
            { title: 'Create Custom Report', link: 'cp/report/query/ReportViewer.aspx' },
            { title: 'General Details', link: 'newhr/GeneralDetails.aspx' },
        ]
    },
    {
        groupTitle: "Travel Reports",
        links: [
            {
                title: "Travel Advance Settlement Report",
                link: "/hr/report/travelAdvanceSettlementReport",
                isReact: true,
            },
        ],
    },
];

function OtherMenus(props) {
    const { empMenuLinks } = props;
    console.clear();
    console.log(empMenuLinks)
    const menuLinks = (features) => {
        let menus = [];
        console.clear();
        console.log(props.empMenuLinks);
        empMenuLinks && empMenuLinks.forEach(item => {
            let menuItem = { groupTitle: item.Title, links: [], feature: features?.CoreHR };
            if (!isEmpty(item.URL)) {
                menuItem.links.push({ title: item.Title, isReact: false, link: item.URL })
            }
            item.Links && item.Links.forEach(linkItem => {
                menuItem.links.push({ title: linkItem.Title, isReact: false, link: linkItem.URL })
            });
            menus.push(menuItem)

        });
        return menus;
    }
    return (
        <div>
            <div className="admin-contain">
                {/* <Title name="HR Common Task" parentProps={props} /> */}
                <CommonModuleLinks baseUrl={props.baseUrl} title="Other Links" localStorageTitle="HRRP" modules={menuLinks} />
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    empMenuLinks: state.commonReducer.empMenuLinks
})

export default connect(mapStateToProps, {})(OtherMenus)
