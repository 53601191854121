import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Table } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import { TableContainer } from "@material-ui/core";
import { TableHead } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import { FaTrash } from "react-icons/fa";
import isEmpty from "isEmpty";
import { getBaseAxios } from "helpers/constants";
import {getUploadPath} from 'constants/userDetails'

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#f9f9f9",
    padding: "25px 0",
    color: "#222",
  },
  body: {
    fontSize: 14,
    fontWeight: 500,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    boederBottom: "2px solid #f3f4f6",
    "&:nth-of-type(odd)": {
      backgroundColor: "#f7f7f7",
    },
  },
}))(TableRow);

const rows = [
  {
    initials: "KP",
    name: "Kriti Pathak",
    email: "kriti@rigo.com",
    access: "Super admin for this job",
  },
  {
    initials: "MD",
    name: "Maiya Dikshit",
    email: "md@rigo.com",
    access: "Standard Access",
  },
];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    width: "100%",
  },
  purple: {
    color: "#444",
    backgroundColor: "#d9ebb0",
    fontSize: 14,
    fontWeight: 500,
    marginRight: 0,
  },
});

export const HiringTeamTable = (props) => {
  const classes = useStyles();
  const { HiringTeam } = props;
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isEmpty(HiringTeam) &&
            HiringTeam.map((row) => (
              <StyledTableRow key={row.Name}>
                <StyledTableCell>
                  <Avatar className={classes.purple}>
                    {!isEmpty(row.ImagePath) ? (
                      <img src={getUploadPath(row.ImagePath)} className="emp-image" />
                    ) : (
                      row.ImagePath
                    )}
                  </Avatar>
                </StyledTableCell>
                <StyledTableCell Name>{row.Name}</StyledTableCell>
                <StyledTableCell>{row.Designation}</StyledTableCell>
                <StyledTableCell>{row.Email}</StyledTableCell>
                <StyledTableCell>
                  {row.HasAllAccess
                    ? "Super admin for this job"
                    : "Standard Access"}
                </StyledTableCell>
                <StyledTableCell>
                  {!isEmpty(row.isCurrent) && row.isCurrent ? null : (
                    <span
                      onClick={() => props.removeUser(row.UserId)}
                      className="change-btn btn-delete"
                    >
                      <FaTrash />
                    </span>
                  )}
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HiringTeamTable;
