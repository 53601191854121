import FormGroup from "components/form";
import Modal from "components/modal";
import { InputValidator } from "helpers/inputValidator";
import isEmpty from "isEmpty";
import React, { Component } from "react";
import { GoPlus } from "react-icons/go";
import shortid from "shortid";
import AddSectionForm from "./addSectionForm";
import CustomProfileBlock from "./customProfileBlock";
import EducationQualification from "./educationAndQualification";
import KnowledgeSkillAbilities from "./knowledgeSkillAbilities";
import PrimaryDutiesResponsibilites from "./pDutiesAndResp";
import ScopeImpact from "./scopeImpact";
import SummaryOfPosition from "./summaryOfPosition";
import {
  GetLevelsWithGroups,
  DisplayClientSideMessage,
  GetJobProfiles,
} from "actions/commonAction";

import { connect } from "react-redux";
import * as jobProfileService from "services/jobProfile";
import RouteEnum from 'constants/routeEnum';
import {
  GetJobProfileById,
  GetDefaultJobProfile,
  GetEducationList,
  GetCertificationList,
  GetKnowledgeList,
  GetSkillsList,
  GetAbilityList,
} from "../../actions";
import {
  SUMMARY_OF_POSITION_ID,
  SUMMARY_OF_POSITION_TYPE,
  PRIMARY_DUITIES_RESPONSIBILTY_ID,
  PRIMARY_DUITIES_RESPONSIBILTY_TYPE,
  EDUCATION_QUALIFICATION_ID,
  EDUCATION_QUALIFICATION_TYPE,
  KNOWLEDGE_SKILL_ABILITY_ID,
  KNOWLEDGE_SKILL_ABILITY_TYPE,
  SCOPE_IMPACT_ID,
  SCOPE_IMPACT_TYPE,
  CUSTOM_PROFILE_BLOCK_TYPE,
  COMPONENT_TYPE,
  JOB_PROFILE_PARAGRAPH_TYPE,
  CUSTOM_PROFILE_BLOCK_ID,
  JOB_PROFILE_DEFAULT_BLOCKS,
} from "constants/constants";
import {
  getProfileBlockComponent,
  getProfileInitialBlocks,
  getSectionsDataForSave,
} from "../../getProfileData";
import ProfileBlockSkeleton from "../../skeleton/profileBlockSkeleton";
class JobProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobProfileId: 0,
      jobProfileName: "",
      jobFamily: {},
      jobRole: {},
      profileBlocks: [],
      initialBlocks: [],
      isSectionFormShow: false,
      errors: {},
      isEditPage: false,
      isInitialDataFetched: false,
    };
  }

  async componentDidMount() {
    await this.initialize();
    const {
      match: { params },
    } = this.props;
    const { search } = this.props.location;
    const splitSearch = !isEmpty(search) && search.split("?")[1].split("=");
    let profileId = !isEmpty(splitSearch) && splitSearch[1];

    if (!isEmpty(params)) {
      profileId = !isEmpty(params) && params.profileId;
    }
    if (!isEmpty(profileId)) await this.props.GetJobProfileById(profileId);
  }

  initialize = async () => {
    const {
      GetDefaultJobProfile,
      GetLevelsWithGroups,
      GetEducationList,
      GetCertificationList,
      GetKnowledgeList,
      GetSkillsList,
      GetAbilityList,
      GetJobProfiles,
    } = this.props;
    let ajaxApi = [
      GetDefaultJobProfile(),
      GetLevelsWithGroups(),
      GetEducationList(),
      GetCertificationList(),
      GetKnowledgeList(),
      GetSkillsList(),
      GetAbilityList(),
      GetJobProfiles(),
    ];
    Promise.all(
      ajaxApi.map(async (item) => {
        return await item;
      })
    );
  };
  async componentDidUpdate(prevProps, prevState) {
    const { defaultJobProfiles, jobProfileDetails } = this.props;
    const { isEditPage } = this.state;
    const {
      match: { params },
    } = this.props;
    const { search } = this.props.location;
    const splitSearch = !isEmpty(search) && search.split("?")[1].split("=");
    let profileId = !isEmpty(splitSearch) && splitSearch[1];
    if (!isEmpty(params)) {
      profileId = !isEmpty(params) && params.profileId;
    }
    if (
      !isEmpty(defaultJobProfiles) &&
      (isEmpty(profileId) || !isEmpty(jobProfileDetails.value)) &&
      (prevProps.defaultJobProfiles !== defaultJobProfiles ||
        prevProps.jobProfileDetails !== jobProfileDetails)
    ) {
      let initialData = !isEmpty(profileId)
        ? jobProfileDetails.value
        : defaultJobProfiles;
      const { profileBlocks, initialBlocks } = getProfileInitialBlocks(
        initialData,
        "jobProfile"
      );
      console.log(profileBlocks);
      this.setState({
        profileBlocks,
        initialBlocks,
        jobProfileId: !isEmpty(search) ? 0 : initialData.ProfileId,
        jobProfileName: initialData.ProfileName,
        jobFamily: {
          label: initialData.LevelName,
          value: initialData.LevelRefId,
        },
        jobRole: {
          value: initialData.LevelGroupRefId,
          label: initialData.LevelGroupName,
        },
        isInitialDataFetched: true,
      });
    }
  }
  handleInput = async (name, value) => {
  
    //const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      this.updateErrors();
    });
  };

  handleProfileBlockTitle = (type, value) => {
    let profileBlocks = this.state.profileBlocks;
    profileBlocks.find((x) => x.type === type).title = value;
    this.setState({
      profileBlocks,
    });
  };

  handleComponentFieldChange = (type, componentId, name, value) => {
    //const { name, value } = event.target;
    let profileBlocks = this.state.profileBlocks;
    let currentComponents = this.state.profileBlocks.find(
      (x) => x.type === type
    ).components;

    let newComponents = currentComponents.map((x) => {
      const o = {
        ...x,
      };
      if (x.id === componentId) {
        o[name] = value;
      }
      return o;
    });
    profileBlocks.find((x) => x.type === type).components = newComponents;
    this.setState({
      profileBlocks,
    });
  };
  handleRemoveComponent = (type, componentId) => {
    let profileBlocks = this.state.profileBlocks;
    let currentComponents = this.state.profileBlocks.find(
      (x) => x.type === type
    ).components;
    let newComponents = currentComponents.filter((x) => x.id !== componentId);
    profileBlocks.find((x) => x.type === type).components = newComponents;
    this.setState({
      profileBlocks,
    });
  };

  handleAddSection = (allSections, customText) => {
    this.handleAddDefaultSection(allSections);
   
    if (!isEmpty(customText)) {
      this.handleAddCustomSection(customText);
    }
  };
  handleAddDefaultSection = (allSections) => {
    const { initialBlocks, profileBlocks } = this.state;
    //const newBlockId = initialBlocks[initialBlocks.length - 1].id + 1;
    allSections.map((x) => {
      if (x.Checked) {
        let component = getProfileBlockComponent(x.Type, "jobProfile");
        initialBlocks.push({
          id: x.Id,
          component,
          type: x.Type,
        });
        profileBlocks.push({
          id: x.Id,
          type: x.Type,
          title: x.Name,
          components:
            x.Type === SCOPE_IMPACT_TYPE
              ? [
                  {
                    id: COMPONENT_TYPE[JOB_PROFILE_PARAGRAPH_TYPE],
                    subtitle: "No title",
                    paragraph: "",
                  },
                ]
              : [
                  // {
                  //   id: 1,
                  //   subtitle: "No title",
                  //   paragraph: "",
                  // },
                ],
        });
      }
    });
    this.setState({ initialBlocks, profileBlocks, isSectionFormShow: false });
  };
  handleAddCustomSection = (sectionName) => {
   
    const { initialBlocks, profileBlocks } = this.state;
    const maxBlockId = Math.max.apply(
      Math,
      initialBlocks.map((x) => {
        return x.id;
      })
    );
    const newBlockId = maxBlockId > 5 ? maxBlockId + 1 : 6;

    initialBlocks.push({
      id: newBlockId,
      type: CUSTOM_PROFILE_BLOCK_TYPE + newBlockId,
      component: CustomProfileBlock,
    });
    profileBlocks.push({
      id: newBlockId,
      type: CUSTOM_PROFILE_BLOCK_TYPE + newBlockId,
      title: sectionName,
      components: [
        {
          id: COMPONENT_TYPE[JOB_PROFILE_PARAGRAPH_TYPE],
          subtitle: "No title",
          paragraph: "",
        },
      ],
    });
    this.setState({ initialBlocks, profileBlocks, isSectionFormShow: false });
  };

  updateErrors = async () => {
    let errors = await InputValidator(document, this.state);
    this.setState({ errors });
  };
  handleAutoSelect = (name, selected) => {
    const { levelsWithGroupsList } = this.props;
    let selectedJobRole = levelsWithGroupsList.find(
      (x) => x.LevelId === selected.value
    );
    let jobRole = {
      value: selectedJobRole.LevelGroupId,
      label: selectedJobRole.GroupName,
    };
    this.setState({ [name]: selected, jobRole });
  };

  handleBlockMove = (oldIndex, newIndex) => {
    let arr = this.state.initialBlocks;
    if (newIndex >= arr.length) {
      var k = newIndex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    this.setState({ initialBlocks: arr });
  };

  closeSectionFormModal = () => {
    this.setState({ isSectionFormShow: false });
  };

  handleRemoveSection = (sectionId) => {
    let { initialBlocks, profileBlocks } = this.state;
    initialBlocks = initialBlocks.filter((x) => x.id !== sectionId);
    profileBlocks = profileBlocks.filter((x) => x.id !== sectionId);
    this.setState({ initialBlocks, profileBlocks });
  };

  handleAddDefaultComponent = (sectionId, componentTitle) => {
    let { profileBlocks } = this.state;
    let stateCopy = profileBlocks.map((x) => {
      const o = {
        ...x,
      };
      if (x.id === sectionId) {
        const maxCompId = isEmpty(x["components"])
          ? 1
          : Math.max.apply(
              Math,
              x["components"].map((x) => {
                return x.id;
              })
            ) + 1;
        o["components"].push({
          id: maxCompId,
          type: JOB_PROFILE_PARAGRAPH_TYPE,
          subtitle: isEmpty(componentTitle) ? "No title" : componentTitle,
          paragraph: "",
        });
      }
      return o;
    });
    this.setState({ profileBlocks: stateCopy });
  };
  handleAddComponent = (components, customSectionText, sectionId) => {
    const { profileBlocks } = this.state;
    let stateCopy = profileBlocks.map((x) => {
      const o = {
        ...x,
      };
      if (x.id === sectionId) {
        components.map((comp) => {
          if (comp.Checked) {
            o["components"].push({
              id: COMPONENT_TYPE[comp.Type],
              type: comp.Type,
              [comp.Type.toLowerCase()]: [],
              subtitle: comp.Name,
              paragraph: "",
            });
          }
        });
      }
      return o;
    });
    this.setState({ profileBlocks: stateCopy });
    !isEmpty(customSectionText) &&
      this.handleAddDefaultComponent(sectionId, customSectionText);
  };

  handleAddSubSections = (sectionId, type, SelectedEduQualList) => {
    let { profileBlocks } = this.state;
    let stateCopy = profileBlocks.map((x) => {
      const o = {
        ...x,
      };
      if (x.id === sectionId) {
        o["components"] = x.components.map((c) => {
          const p = {
            ...c,
          };
          if (c.type === type) {
            p[type.toLowerCase()] = SelectedEduQualList.map((x) => {
              return sectionId === KNOWLEDGE_SKILL_ABILITY_ID
                ? { id: x.Id, name: x.Name, level: x.Level }
                : { id: x.Id, name: x.Name };
            });
          }
          return p;
        });
      }
      return o;
    });

    this.setState({ profileBlocks: stateCopy });
  };
  handleRemoveSubSection = (sectionId, type, id) => {
    let { profileBlocks } = this.state;
    let stateCopy = profileBlocks.map((x) => {
      const o = {
        ...x,
      };
      if (x.id === sectionId) {
        o["components"] = x.components.map((c) => {
          const p = {
            ...c,
          };
          if (c.type === type) {
            p[type.toLowerCase()] = c[type.toLowerCase()].filter(
              (type) => type.id !== id
            );
          }
          return p;
        });
      }
      return o;
    });

    this.setState({ profileBlocks: stateCopy });
  };
  handleFormSubmit = async () => {
    let errors = {};
    const {
      jobProfileId,
      jobProfileName,
      jobFamily,
      profileBlocks,
      initialBlocks,
    } = this.state;
    const { jobProfileList } = this.props;
    if (!jobProfileName) {
      errors.jobProfileName = "Required";
    }
    if (isEmpty(jobFamily.label)) {
      errors.jobFamily = "Required";
    }
    this.setState({ errors });
    let sortedProfileBlocks = [];
    sortedProfileBlocks.push(
      profileBlocks.find((x) => x.type === SUMMARY_OF_POSITION_TYPE)
    );
    initialBlocks.map((x) => {
      sortedProfileBlocks.push(profileBlocks.find((p) => p.type === x.type));
    });
    if (sortedProfileBlocks?.find((x) => isEmpty(x.title))) {
      let message = {
        MessageType: "danger",
        Message: "You cannot save or draft without filling all section names.",
      };
      this.props.DisplayClientSideMessage(message);
      return false;
    }
    console.log(jobProfileList)
    if (isEmpty(errors)) {
      if (
        jobProfileList.value.find(
          (x) => x.value != jobProfileId && x.label === jobProfileName
        )
      ) {
        errors.jobProfileName =
          "Job profile name already taken . Please choose another name!";
        let message = {
          MessageType: "danger",
          Message:
            "Job profile name already taken. Please choose another name!",
        };
        this.props.DisplayClientSideMessage(message);
        return false;
      }
      const data = {
        profileId: jobProfileId,
        profileName: jobProfileName,
        levelRefId: jobFamily.value,
        sections: getSectionsDataForSave(sortedProfileBlocks),
      };
      console.log(data);
    
      let result =
        data.profileId === 0
          ? await jobProfileService.createJobProfile(data)
          : await jobProfileService.updateJobProfile(data);
      if (result.Status) {
        this.props.history.push(RouteEnum.JobProfilePath);
      }
      this.props.DisplayClientSideMessage(result);
    } else {
      let message = {
        MessageType: "danger",
        Message:
          "You cannot save or draft without filling all required fields.",
      };
      this.props.DisplayClientSideMessage(message);
    }
  };
  render() {
    const {
      jobProfileName,
      jobFamily,
      jobRole,
      errors,
      initialBlocks,
      isSectionFormShow,
      profileBlocks,
      isInitialDataFetched,
    } = this.state;
    const isViewMode = false;
    const {
      handleFormDisplay,
      levelsWithGroupsList,
      jobFamilyList,
      defaultJobProfiles,
    } = this.props;
    return (
      <>
        <div className="job-profile-form">
          <div className="job-profile-form__header">
            <span>New Job Profile</span>
            {/* <span className="close-icon" onClick={() => this.props.onClose()}>
              <IoIosCloseCircleOutline />
            </span> */}
          </div>
          <div className="job-profile-form__body">
            <div className="form-row ml-sm mr-sm">
              <FormGroup
                autoFocus
                name="jobProfileName"
                value={jobProfileName}
                onChange={this.handleInput}
                label="Job Profile Name"
                error={errors && errors.jobProfileName}
                width="250px"
                validators={["required"]}
                disabled={isViewMode}
              />
            </div>
            <div className="form-row ml-sm mr-sm">
              <FormGroup
                formName="reactselect"
                onChange={this.handleAutoSelect}
                name="jobFamily"
                value={jobFamily}
                options={jobFamilyList.value}
                loading={jobFamilyList.loading}
                loadingType="skeleton"
                placeholder="Select"
                disabled={isViewMode}
                validators={["required"]}
                error={errors && errors.jobFamily}
                width="250px"
                label="Select Job Family"
              />
              <div className="job-role flex mt-md">
                <label htmlFor="jobRole" className="form-group__label">
                  Job Role
                </label>
                <span className="ml-md text-md">{jobRole.label}</span>
              </div>

              {/* <ValidationComponent
                rootClassName="form-group select"
                error={errors && errors.jobFamily}
                errorClassName="error__message"
              >
                <label htmlFor="ActionTaken" className="form-group__label">
                  Select Job Family
                </label>
                <AutoSelect
                  onChange={this.handleAutoSelect}
                  name="jobFamily"
                  value={jobFamily}
                  options={jobFamilyList}
                  width="250px"
                  placeholder="Select"
                  disabled={isViewMode}
                />
              </ValidationComponent> */}
            </div>
            {!isInitialDataFetched &&
              JOB_PROFILE_DEFAULT_BLOCKS?.map((x, i) => (
                <div className="form-row section">
                  <div className="ml-sm mr-sm wd-100">
                    <ProfileBlockSkeleton height={201} />
                  </div>
                </div>
              ))}

            <div className="form-row section">
              {!isEmpty(profileBlocks) && (
                <SummaryOfPosition
                  handleInput={this.handleInput}
                  state={this.state}
                  isViewMode={isViewMode}
                  handleProfileBlockTitle={this.handleProfileBlockTitle}
                  handleComponentFieldChange={this.handleComponentFieldChange}
                />
              )}
            </div>
            {initialBlocks.map((x, i) => {
              return (
                <div className="form-row section">
                  <x.component
                    handleInput={this.handleInput}
                    state={this.state}
                    isViewMode={isViewMode}
                    handleBlockMove={this.handleBlockMove}
                    index={i}
                    id={x.id}
                    handleProfileBlockTitle={this.handleProfileBlockTitle}
                    handleComponentFieldChange={this.handleComponentFieldChange}
                    handleRemoveComponent={this.handleRemoveComponent}
                    handleRemoveSection={this.handleRemoveSection}
                    handleAddDefaultComponent={this.handleAddDefaultComponent}
                    handleAddComponent={this.handleAddComponent}
                    handleAddSubSections={this.handleAddSubSections}
                    handleRemoveSubSection={this.handleRemoveSubSection}
                    defaultJobProfiles={defaultJobProfiles}
                    profileBlockType={x.type}
                  />
                </div>
              );
            })}
            <div className="form-row ml-sm mr-sm">
              <button
                className="button with-icon button-primary"
                onClick={() => this.setState({ isSectionFormShow: true })}
              >
                <GoPlus />
                <span> Add Section</span>
              </button>
            </div>
          </div>

          <div className="job-profile-form__footer flex justify-end">
            <button
              className="button  button-grey"
              onClick={() => this.props.history.push(RouteEnum.JobProfilePath)}
            >
              <span> Cancel</span>
            </button>

            <button
              className="button  button-orange"
              onClick={() => this.handleFormSubmit()}
            >
              <span> Save as Draft</span>
            </button>
            <button
              className="button  button-primary"
              onClick={() => this.handleFormSubmit()}
            >
              <span> Save and Send for Approval</span>
            </button>
          </div>
        </div>
        {isSectionFormShow ? (
          <Modal
            title="Add Section"
            open={isSectionFormShow}
            onModalClose={this.closeSectionFormModal}
            type=""
            className="modal-add-section"
          >
            <AddSectionForm
              profileBlocks={initialBlocks}
              handleAddSection={this.handleAddSection}
              closeSectionFormModal={this.closeSectionFormModal}
            />
          </Modal>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  levelsWithGroupsList: state.commonReducer.levelsWithGroupsList,
  defaultJobProfiles: state.jobProfileReducer.defaultJobProfiles,
  jobProfileDetails: state.jobProfileReducer.jobProfileDetails,
  jobFamilyList: state.commonReducer.jobFamilyList,
  jobProfileList: state.commonReducer.jobProfileList,
});

const mapDispatchToProps = {
  GetLevelsWithGroups,
  GetJobProfileById,
  GetDefaultJobProfile,
  GetEducationList,
  GetCertificationList,
  GetKnowledgeList,
  GetSkillsList,
  GetAbilityList,
  DisplayClientSideMessage,
  GetJobProfiles,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobProfileForm);
