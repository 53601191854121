import React, { useState } from 'react'
import shortid from 'shortid';
import {radioType} from 'constants/enums';
import Form from 'components/form'
import { FiPlus, FiMinus } from 'react-icons/fi';
import classnames from 'classnames';
const insert = (arr, index, ...newItems) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted items
    ...newItems,
    // part of the array after the specified index
    ...arr.slice(index)
  ]

const RadioOption = (props) => {
    const {
        parentId,
        marks,
        answer,
        index,
        id,
        isChecked,
        changeState,
        type,
        onRadioChange,
        onChange
    } = props;
    const handleChange = (name,value) =>{
        changeState(prev=>(prev.map(item=>{
            if(item.id === parentId){
                return{
                    ...item,
                    radioOptions:item.radioOptions.map(rad=>{
                        if(rad.id===id){
                            return {...rad,[name]:value}
                        }
                        return rad
                    })
                }
            }
            return item
        })))
    }
    const addRadioButton = () =>{
        changeState(prev=>(prev.map(item=>{
            if(item.id === parentId){
                const radioItem = {id:shortid.generate(),isChecked:false,answer:"",marks:0,type:radioType.normal};
                const radioOptions = insert(item.radioOptions,index + 1,radioItem);
                return{
                    ...item,
                    radioOptions:radioOptions
                }
            }
            return item
        })))
    }
    const removeRadioButton = () =>{
        changeState(prev=>(prev.map(item=>{
            if(item.id === parentId){
                return{
                    ...item,
                    radioOptions:item.radioOptions.filter(rad=>rad.id!==id)
                }
            }
            return item
        })))
    }
    // const [marks, setMarks] = useState(0)
    const [radioQuestion, setRadioQuestion] = useState("")
    return (

        <div className="courseForm-question__body-form">
        <div 
            onClick={()=>handleChange('isChecked',!isChecked)}
            className={classnames("courseForm-question__body-circle",{"circle-active":isChecked})}
        >

        </div>
        <div className="courseForm-question__body-input">
            <Form
                name="answer"
                value={answer}
                onChange={handleChange}
            />
        </div>
        <div className="courseForm-question__body-stepper">
            <Form
                name="marks"
                formName="stepper"
                value={marks}
                minValue={"-5"}
                maxValue={"5"}
                onChange={handleChange}
            />
        </div>
        {
            type === radioType.normal && (
                <div className="courseForm-question__body-action">
                    <button 
                        onClick={()=>addRadioButton()}
                        className="btn-round"><FiPlus /></button>
                    <button 
                        onClick={()=>removeRadioButton()}
                        className="btn-round"><FiMinus /> </button>
                </div>
            )
        }

    </div>
    )
}
export default RadioOption
