import React, { useEffect, useState } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import classnames from "classnames";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "react-avatar";
import Button from "components/button";
import isEmpty from "isEmpty";
import { useSelector, useDispatch } from "react-redux";
import { addTask } from "../../../helpers/action";
import Task from "./task";
import AddTask from "./addTask";
import { Droppable, Draggable } from 'react-beautiful-dnd';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  // background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle
});
const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? '#84c5f4' : '#fff',
  // padding: grid / 2
});

const Board = (props) => {
  const {
    header,
    enableAddTask,
    tasks,
    theme,
    board,
    isCollapsed,
    onTaskClicked,
    stage,
  } = props;
  const [collapsed, setCollapsed] = React.useState(true);
  const boardClasses = classnames({
    board: true,
    "board-collapsed": collapsed,
    ["board-" + theme]: !isEmpty(theme),
  });
  React.useEffect(() => {
    setCollapsed(isCollapsed);
  }, []);
  return (
    <div className={boardClasses}>
      <div className="board-block">
        <div className="board-header" onClick={() => setCollapsed(!collapsed)}>
          <div className="board-header__title">
            <h3>{header}</h3>
            <span>({tasks.length})</span>
          </div>
          <div className="board-header__icon">
            <MdKeyboardArrowLeft />
          </div>
        </div>
        {!collapsed ? (
          <Droppable
            // type="COLUMN" 
            // direction="horizontal"
            droppableId={"board-" + stage.toString()}>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} className="board-body">
                <div className="board-body__container">
                  {tasks &&
                    tasks.map((item, index) => {
                      return (

                        <Draggable
                          key={item.TaskId.toString()}
                          draggableId={item.TaskId.toString()}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <Task
                                rowIndex={index}
                                stage={stage}
                                onTaskClicked={onTaskClicked}
                                task={item}
                                progress={20}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  {enableAddTask ? <AddTask board={board} stage={stage} /> : null}
                </div>
              </div>
            )}

          </Droppable>
        ) : null}
      </div>
    </div>
  );
};
Board.defaultProps = {
  isCollapsed: true,
  enableAddTask: false,
};
export default Board;
