import React from 'react';
import FormGroup from 'components/form'
import SvgIcons from 'components/icons/svgIcons';
import useDarkMode from 'helpers/customHooks/useDarkMode';

function ModeSwitch() {
    const [darkMode, setDarkMode] = useDarkMode();
    return (
        <div>
            {/* {
                active ? <FaMoon /> : <FaSun /> 
            } */}
            <FormGroup 
                formName="switch"
                onChange={(name,value)=>setDarkMode(value)}
                name="darkMode"
                value={darkMode}
                leftLabel={<SvgIcons name="sun" size="16px" /> }
                rightLabel={<SvgIcons name="moon" size="16px" />}
                checked={darkMode}
            />
        </div>
    )
}

export default ModeSwitch
