import React, { useState, useEffect } from "react";
import FormGroup from "components/form";

const TaskTitle = (props) => {
  const { task, name, onEnter, isValidUser } = props;
  const [value, setValue] = useState("");

  const [edited, setEdited] = useState(false);
  useEffect(() => {
    if (task) {
      setValue(task[name]);
    }
  }, [task.TaskName]);

  const onChange = (name, value) => {
    setEdited(true);
    setValue(value);
  };
  const onKeyUp = (e, name, value) => {
    if (e.key === "Enter") {
      setEdited(false);
      onEnter && onEnter(e, name, value);
    }
  };

  const onBlur = (name, value) => {
    console.log(name, value, edited);
    edited && onEnter && onEnter({ key: "Enter" }, name, value);
    setEdited(false);
  };

  return (
    <div>
      <FormGroup
        name={name}
        disabled={!isValidUser()}
        value={value}
        placeholder="Task Name"
        onChange={onChange}
        onKeyUp={(e) => onKeyUp(e, name, value)}
        onBlur={onBlur}
      />
      {/* {!show ? (
        <h3
          className="taskmodal__title"
          onClick={() => {
            setValue(task[name]);
            setShow(true);
          }}
        >
          {task[name]}
        </h3>
      ) : (
        
      )} */}
    </div>
  );
};

export default TaskTitle;
