import {
  GET_TIME_REQUEST_DETAIL,
  GET_TIME_REQUEST_APPROVAL_RECOMMENDED,
} from "actions/types";
import axios from "axios";
import { toastMessage } from "actions/validateAction";
import { catchError } from "helpers";
import { loadingHeaders } from "helpers/getHeaders";
import { Date_Format } from "constants/dateFormat";

const urlPrefix = "/v1/leave-time/time/";

export const GetTimeRequestDetailById = (timeRequestId) => async (dispatch) => {
  await axios
    .get(`${urlPrefix}request/${timeRequestId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: GET_TIME_REQUEST_DETAIL,
          payload: response.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const GetTimeApprovalRecommendedForEmployee = (employeeId) => async (
  dispatch
) => {
  dispatch({
    type: GET_TIME_REQUEST_APPROVAL_RECOMMENDED,
    payload: null,
  });
  await axios
    .get(`${urlPrefix}approvals/${employeeId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: GET_TIME_REQUEST_APPROVAL_RECOMMENDED,
          payload: response.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const CreateTimeRequest = (data, props) => async (dispatch) => {
  await axios
    .post(`${urlPrefix}requests`, data)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        props.refreshRequestData && props.refreshRequestData();
        props.onModalClose(false);
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const UpdateTimeRequest = (data, props) => async (dispatch) => {
  await axios
    .put(`${urlPrefix}requests`, data)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        props.refreshRequestData && props.refreshRequestData();
        props.onModalClose(false);
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};
