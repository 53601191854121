import React from "react";
import Form from "components/form";
import Button from "components/button";
import moment from "moment";
import isEmpty from "isEmpty";

export default function RejectNote({
  data,
  onChange,
  onModalClose,
  handleSubmit,
  ...props
}) {
  return (
    <div className="homeloan-newloan">
      <div className="homeloan-newloan-body">
        <div className="homeloan-newloan-row">
          <Form
            name="RejectMessage"
            value={data.RejectMessage}
            onChange={(name, value) => onChange(name, value)}
            // type="number"
            error={data.errors && data.errors.RejectMessage}
            // label={" Name"}
            validators={["required"]}
            // width={"80%"}
          />
        </div>
      </div>
      <div className="homeloan-newloan-footer">
        <Button
          title="Cancel"
          bg="subtle"
          onClick={() => onModalClose()}
        />
        <Button title="Save" bg="primary" onClick={() => handleSubmit()} />
      </div>
    </div>
  );
}
