import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  FaRegUserCircle,
  FaRegComment,
  FaRegSmile,
  FaPlus,
  FaTimes,
} from "react-icons/fa";
import SvgIcons from "components/icons/svgIcons";
import Modal from "components/modal";
import isEmpty from "isEmpty";
import FormGroup from "components/form";
import Icons from "components/icons";
import Button from "components/button";
import { IoIosClose, IoIosAt } from "react-icons/io";
import Avatar from "react-avatar";

import {
  // GetAllEmployeeListWithPhoto,
  GetEmployeeList,
} from "actions/commonAction";
import SearchTeamMember from "scenes/tasks/board/taskBody/common/member/teamMember";
const TeamMember = (props) => {
  const {
    member,
    selectedMembers,
    index,
    onMemberRemoved,
    onAddMembers,
    isValidUser,
  } = props;
  const [open, setOpen] = useState(false);
  const [selectedEmployees, setSlectedEmployees] = useState([]);
  const { user } = props.auth;
  const base = props.auth.baseUrl
    ? props.auth.baseUrl.NetFrameworkAppBaseUrl
    : "";

  const handleSelectedMemberChanged = async (members) => {
    setSlectedEmployees(members);
  };

  useEffect(() => {
    setSlectedEmployees(selectedMembers);
  }, [selectedMembers]);

  return (
    <>
      {member ? (
        <div className="member">
          <Avatar
            className="member-avatar"
            name={member.FullName}
            size={"34"}
            round={true}
            src={`${base}${member.Imagepath}`}
          />
          {isValidUser() && (
            <div className="btn-remove">
              <FaTimes
                size="12"
                style={{ margin: "0px" }}
                color="#fff"
                onClick={() => {
                  onMemberRemoved && onMemberRemoved(member, index);
                }}
              />
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="member" onClick={() => setOpen(true)}>
            {/* <FaRegUserCircle size="34" /> */}
            <div className="member-add">
              <FaPlus size="12" color="#5E6C84" />
            </div>
          </div>
          <Modal
            onModalClose={() => setOpen(false)}
            open={open}
            className="member-modal"
            width="600px"
            title="Add Members"
          >
            <div className="member-modal-body">
              <div className="member-employee">
                <SearchTeamMember
                  disableRemoteSearch={true}
                  selectedMembers={selectedEmployees}
                  onSelectionChange={handleSelectedMemberChanged}
                  onMemberRemoved={handleSelectedMemberChanged}
                />
              </div>
              <div className="member-footer">
                <Button
                  title="Ok"
                  bg="primary"
                  onClick={() => {
                    setSlectedEmployees(selectedEmployees);
                    onAddMembers && onAddMembers(selectedEmployees);
                    setOpen(false);
                  }}
                />
                <Button
                  title="Cancel"
                  bg="subtle"
                  onClick={(e) => setOpen(false)}
                />
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  employeeList: state.commonReducer.employeeList,
  employeeListSelect: state.commonReducer.employeeListSelect,
  auth: state.auth,
});
const mapDispatchToProps = {
  GetEmployeeList,
};
export default connect(mapStateToProps, mapDispatchToProps)(TeamMember);
