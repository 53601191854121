import {
    GET_PAYROLL_DASHBOARD,
    ADD_LOADING
} from 'actions/types';
import axios from "axios";
import isEmpty from "isEmpty";
import {toastMessage} from 'actions/validateAction';

export const getPayrollDashboard = () => async dispatch => {
    await axios
      .get(
        `/v1/payroll/admin/information`
      )
      .then(response => {
        if (response.data.Status) {
          dispatch({
            type: GET_PAYROLL_DASHBOARD,
            payload: response.data.Data
          });
        } else {
          toastMessage(dispatch, response);
        }
      })
      .catch(err => {
        dispatch({
            type: GET_PAYROLL_DASHBOARD,
            payload: null
        });
        const response = {
          data: {
            MessageType: "Danger",
            Message: err.message?err.message:"Failed to get employee list."
          }
        };
        toastMessage(dispatch, response);
      });
  };
  