import React, { useState, useEffect } from 'react'
import Modal from '../modal';
import Button from '../button'
import { FiPlus, FiX } from 'react-icons/fi';
import FormGroup from '../form'
import SvgIcons from 'components/icons/svgIcons';
import {
    addDashboardMenu,
    removeDashboardMenu,
    getDashboardMenus,
    getDashboardBlocks
} from 'scenes/hr/helpers/action'
import { connect, useDispatch, useSelector } from 'react-redux';
import isEmpty from 'isEmpty'
import { useHistory } from 'react-router-dom';
import { isFeatureEnabledValue } from 'helpers/checkPermissions'

function FavIconModal(props) {

    const getLinks = (data, isCommon) => {
        var final = [];
        if (data) {
            const a = data.filter(item => {
                const feat = item.feature ? item.feature : 'CoreHR'
                if (!isFeatureEnabledValue(feat)) {
                    return false;
                }
                return true;
            }).map(item => {
                let b = item.links.map(link => {
                    return {
                        label: <div data-link={link.title} data-menu={isCommon ? 1 : 2} className="flex">
                            {
                                isCommon ? <SvgIcons name="tasks" color="#3a3d43" size="17px" />
                                    :
                                    <SvgIcons name="fileInvoice" color="#3a3d43" size="14px" />
                            }
                            <span className="ml-sm">
                                {link.title}
                            </span>

                        </div>, value: link.link
                    }
                })
                return b
            });
            final = [].concat.apply([], a);
            return final;
        }
        return final
    }
    const { dashboardName } = props;
    const history = useHistory()
    const common = getLinks(props.common, true);
    const report = getLinks(props.reports, false);
    const final = [...common, ...report]
    const [finalOptions, setFinalOptions] = useState(final)
    const [open, setOpen] = useState(false);
    const [error, setError] = useState('');
    const [link, setLink] = useState<any>({});
    const onOpen = () => setOpen(true)
    const onClose = () => setOpen(false);
    const dispatch = useDispatch();
    const { dashboardMenu } = useSelector((state: any) => state.hrReducer);
    const { baseUrl } = useSelector((state: any) => state.auth)
  
    useEffect(() => {
        (async () => {
            const promiseArray = [
                dispatch(getDashboardMenus()),
                dispatch(getDashboardBlocks()),
            ]
            await Promise.all(promiseArray);
        })();
    }, []);

    const onSave = () => {
        if (link) {
            setError('');
            const { props: ps } = link.label
            const data = {
                dashboardName: dashboardName,
                menuCategory: ps['data-menu'],
                menuName: ps['data-link'],
                menuLink: link.value
            }
            dispatch(addDashboardMenu(data))
            onClose();
            setLink({})
        } else {
            setError('Please choose a link before saving')
        }
    }
    const removeDashboard = (id) => {
        dispatch(removeDashboardMenu(id))
    }
    const buttonData = isEmpty(dashboardMenu) ? [] : dashboardMenu.filter(item => item.DashboardName === dashboardName);
    useEffect(() => {
        if (buttonData) {
            let temp = [...finalOptions]
        }
    }, [buttonData])
    const getOption = () => {
        let a = [...finalOptions]
        a = a && a.map(item => {
            if (item) {
                let b = buttonData.find(item2 => item2.MenuLink === item.value);
                if (b) {

                    let c = { ...item }
                    c.disabled = 'yes';

                    return c;
                }
                return item
            }
        });
        return a;
    }
    // console.log(finalOptions)
    return (
        <>
            {
                buttonData && buttonData.map(item => {
                    const isOld = item.MenuLink.split(".").includes("aspx");
                    const base = baseUrl ? baseUrl.NetFrameworkAppBaseUrl : ''
                    return (
                        <Button
                            buttonType="icon-button"
                            icon={
                                item.MenuCategory === 1 ?
                                    <SvgIcons name="tasks" color="#3a3d43" size="17px" />
                                    : <SvgIcons name="fileInvoice" color="#3a3d43" size="14px" />}
                            title={item.MenuName}
                            rightIcon={
                                <span onClick={(e) => { e.stopPropagation(); removeDashboard(item.Id) }} className="remove-icon">
                                    <FiX />
                                </span>
                            }
                            bg="white"
                            onClick={() => isOld ? window.open(base + item.MenuLink) : history.push(item.MenuLink)}
                        />
                    )
                })
            }
            <Button
                buttonType="icon-button"
                icon={<FiPlus />}
                bg="white"
                onClick={() => onOpen()}
                buttonClass="fav-icon"
            />
            <Modal
                onModalClose={onClose}
                title="Faviorite Icon"
                className="fav-modal"
                open={open}
                width="400px"
            >
                <div className="favModal">
                    <div className="favModal-body">
                        <FormGroup
                            name="link"
                            formName="reactselect"
                            options={getOption()}
                            error={error}
                            label="Search Module"
                            onChange={(name, value) => setLink(value)}
                            value={link}
                        />
                    </div>
                    <div className="favModal-footer">
                        <Button
                            onClick={() => onSave()}
                            title="Save" bg="primary" />
                        <Button
                            onClick={() => onClose()}
                            title="Cancel" bg="subtle" />
                    </div>
                </div>
            </Modal>
        </>
    )
}


export default FavIconModal
