import React, { useEffect, useState } from 'react'
import TeamTable from './teamTable'
import TeamFilters from './teamFilters'
import { useDispatch } from 'react-redux'
import { getRosterPatternList, getRosterTeamEmployee } from 'scenes/roster/helpers/action'
import useDebounce from 'helpers/customHooks/useDebounce'
import { Tabs, Tab } from 'components/tabs/index.d';
import TeamsTab from './teams';

const initialState = {
    employee: '',
    teamId: null,
    roleId: null,
    pageIndex: 0,
    pageSize: 10
}

function Teams() {
    const [state, setState] = useState(initialState);
    useDebounce(() => getTeamsLists(), 1000, [state.employee])
    const dispatch = useDispatch()


    useEffect(() => {
        getTeamsLists()
    }, [state.pageIndex, state.pageSize, state?.teamId, state?.roleId])
    useEffect(() => {
        dispatch(getRosterPatternList())
    }, [])
    const getTeamsLists = () => {
        const data = {
            pageIndex: state.pageIndex,
            pageSize: state.pageSize,
            employee: state.employee,
            teamId: state?.teamId ? state?.teamId?.value : null,
            roleId: state?.roleId ? state?.roleId?.value : null,
        }
        dispatch(getRosterTeamEmployee(data))
    }

    const onChange = (name, value) => {
        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleRowChange = (num) => {
        setState((filter: any) => {
            return {
                ...filter,
                pageSize: num,
                pageIndex: 0
            }
        })
    };

    const handleIndexChange = (num) => {
        setState((filter: any) => {
            return {
                ...filter,
                pageIndex: num
            }
        })
    };
    return (
        <div className="schedule-team">
            <div className="schedule-team__container mt-sm ml-md">
                <div className="schedule-team__heading"></div>
                <Tabs noBackground>
                    <Tab name="Employees" id="1" label="Employees" >
                        <>
                            <TeamFilters onChange={onChange} filter={state} />
                            <TeamTable
                                pageIndex={state.pageIndex}
                                pageSize={state.pageSize}
                                handleRowChange={handleRowChange}
                                handleIndexChange={handleIndexChange}
                            />
                        </>
                    </Tab>
                    <Tab name="Teams" id="1" label="Teams">
                        <TeamsTab />
                    </Tab>
                </Tabs>


            </div>
        </div>
    )
}

export default Teams
