import React, { useState } from "react";
import { Link } from "react-router-dom";
import CommonPagination from "components/table/pagination";
import isEmpty from "isEmpty";
import Jobnotfound from "assets/images/jobsnotfound.svg";
import classNames from "classnames";

const JobDetails = ({
  jobs,
  handleRowChange,
  handleIndexChange,
  pageIndex,
  rowsPerPage,
  onJobSelected,
}) => {
  const [selectedJob, setSelectedJob] = useState(null);
  const handleJobclick = (job) => {
    setSelectedJob(job);
    console.clear();
    console.log(job);
    onJobSelected && onJobSelected(job);
  };

  if (isEmpty(jobs)) {
    return (
      <div className="employees-list">
        <div className="no-found">
          <div className="job-empty">
            <img src={Jobnotfound} alt="Job Not Found" />
            Job Not Found
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {jobs.map((values: any, index: number) => {
        return (
          <div
            key={index}
            className="recruitment__dashboard-item_review-body"
            onClick={(e) => handleJobclick(values)}
          >
            <div
              className={classNames("joblink flex", {
                active: selectedJob && selectedJob.JobId === values.JobId,
              })}
            >
              <span className="titles">{values.ExternalJobTitle}</span>
              <span className="jobapplicants">{values.Applicants}</span>

              <Link to={`/recruit/dashboard/information?jobId=${values.JobId}`}>
                <span className="ats-btn">ATS </span>
              </Link>
            </div>
          </div>
        );
      })}
      <CommonPagination
        handleRowChange={handleRowChange}
        handleIndexChange={handleIndexChange}
        pageIndex={pageIndex}
        rowsPerPage={rowsPerPage}
        total={jobs[0].TotalRows}
      />
    </>
  );
};

export default JobDetails;
