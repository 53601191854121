import { RecruitDashboardInformationPath } from "common-links";
import React from "react";

export default function CandidateName(props: any) {
  const { data } = props || {};
  const { CandidateName } = data;

  const onCandidateNameClicked = () => {
    props.history.push({
      pathname: RecruitDashboardInformationPath,
      search: `?jobId=${data.JobId}&candidateId=${data.CandidateId}`,
      // state: { propsData: data },
    });
    // console.log(props);
    // jobde
    //props.history.push(RecruitDashboardInformationPath);
  };

  return (
    <div className="candidate-name" onClick={onCandidateNameClicked}>
      {CandidateName}
    </div>
  );
}
