import Modal from "components/modal";
import {getBaseAxios} from "helpers/constants";
import isEmpty from "isEmpty";
import React, {Component} from "react";
import {AiFillCloseCircle} from "react-icons/ai";
import {FaRegFilePdf} from "react-icons/fa";
import {getUploadPath} from "constants/userDetails";
import {connect} from "react-redux";
import {folderNameToSaveFile} from "../../constants";
import {UploadFiles} from "../actions";
const BaseUrl = getBaseAxios() + "/";

export class Create_DocumentsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      modalFile: null,
      docInfo: {},
    };
    this.removeFile = this.removeFile.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this.onModalOpen = this.onModalOpen.bind(this);
    this.renderView = this.renderView.bind(this);
  }

  componentDidUpdate() {
    const {isFileUploading} = this.props.documentsState;
    const {isFileUploadSuccess, succesFileInfoList} = this.props;
    if (isFileUploading && isFileUploadSuccess) {
      let fileList = [
        ...succesFileInfoList,
        ...this.props.documentsState.succesFileInfoList,
      ];
      this.props.handleDocumentsTabState &&
        this.props.handleDocumentsTabState("fileList", []);
      this.props.handleDocumentsTabState &&
        this.props.handleDocumentsTabState("succesFileInfoList", fileList);
      this.props.handleDocumentsTabState &&
        this.props.handleDocumentsTabState("isFileUploading", false);
    }
  }

  fileSelectedHandler = (event) => {
    const {fileList} = this.props.documentsState;
    const file = event.target.files[0];
    fileList.push(file);

    this.props.handleDocumentsTabState &&
      this.props.handleDocumentsTabState("fileList", fileList);
  };

  handleFileUpload = () => {
    const {fileList} = this.props.documentsState;
    this.props.handleDocumentsTabState &&
      this.props.handleDocumentsTabState("isFileUploading", true);

    this.props.UploadFiles({fileList, folderNameToSaveFile});
  };

  removeFile(name, type) {
    const {fileList, succesFileInfoList} = this.props.documentsState;
    let newFiles = fileList;
    if (type === "local") {
      newFiles = fileList.filter((item) => item.name !== name);
    }
    let newSuccesFileInfoList = succesFileInfoList.filter(
      (item) => item.OriginalFileName !== name
    );

    this.props.handleDocumentsTabState &&
      this.props.handleDocumentsTabState("fileList", newFiles);
    this.props.handleDocumentsTabState &&
      this.props.handleDocumentsTabState("succesFileInfoList", newSuccesFileInfoList);
  }
  onModalClose() {
    this.setState({modalOpen: false});
  }
  onModalOpen(info, isLocal) {
    // URL.createObjectURL(file)
    // if(info.isPublished){
    //   this.setState({modalFile:})
    // }else{
    //   this.setState({})
    // }
    this.setState({modalOpen: true, modalFile: {file: info, isLocal}});
  }
  renderView() {
    const {
      modalFile,
      modalFile: {file, isLocal},
    } = this.state;

    let imgType = ["jpg", "png", "jpeg", "svg"];
    if (isLocal) {
      if (file.type.split(".").pop().includes("image")) {
        return (
          <div className="file-view">
            <img src={URL.createObjectURL(file)} alt="File" />
          </div>
        );
      }
      return (
        <div className="file-view">
          <iframe
            className={"filetype"}
            width="100%"
            height="600"
            frameborder="0"
            src={URL.createObjectURL(file)}
          ></iframe>
        </div>
      );
    } else {
      // let fileDest = getBaseAxios() + file.FileLocation + file.GeneratedFileName;
      let fileDest = getUploadPath(file.FileLocation + file.GeneratedFileName);
      if (imgType.includes(file.GeneratedFileName.split(".").pop())) {
        console.log("fileDest", fileDest, file);
        return (
          <div className="file-view">
            <img src={fileDest} alt="File" />
          </div>
        );
      } else {
        return (
          <div className="file-view">
            <iframe
              className={"filetype"}
              width="100%"
              height="600"
              frameborder="0"
              src={fileDest}
            ></iframe>
          </div>
        );
      }
    }
  }
  render() {
    const {fileList, succesFileInfoList, isFileUploading} = this.props.documentsState;
    const {isViewMode} = this.props;
    const validImageExtension = ["png", "jpg", "jpeg"];
    return (
      <div className="travel-document-create">
        <div className="travel-create__title">
          <h3>Documents</h3>
        </div>
        <div className="travel-create__body">
          <div className="mt-sm mb-sm flex wd-100">
            {succesFileInfoList && succesFileInfoList.length > 0
              ? succesFileInfoList.map((file, index) => {
                  return (
                    // <Link
                    //   to={file.FileLocation + file.GeneratedFileName}
                    //   className="flex"
                    //   key={index}
                    // >
                    <div className="flex pr-sm">
                      {validImageExtension.find(
                        (x) => x === file.OriginalFileName.split(".").pop()
                      ) ? (
                        <a
                          onClick={() => this.onModalOpen(file, false)}
                          className="flex"
                          key={index}
                        >
                          <span className="document-icon">
                            <FaRegFilePdf />
                          </span>
                          <label className="document-name">
                            {!isEmpty(file.OriginalFileName)
                              ? file.OriginalFileName
                              : null}
                            {/* {file.OriginalFileName} */}
                          </label>
                        </a>
                      ) : (
                        <a
                          // href={
                          //   getBaseAxios() +
                          //   file.FileLocation +
                          //   file.GeneratedFileName
                          // }
                          href={
                            // getBaseAxios() + file.FileLocation + file.GeneratedFileName
                            getUploadPath(file.FileLocation + file.GeneratedFileName)
                          }
                          target="_blank"
                          download={true}
                          className="flex"
                          key={index}
                        >
                          <span className="document-icon">
                            <FaRegFilePdf />
                          </span>
                          <label className="document-name">
                            {!isEmpty(file.OriginalFileName)
                              ? file.OriginalFileName
                              : null}
                            {/* {file.OriginalFileName} */}
                          </label>
                        </a>
                      )}

                      {isViewMode ? null : (
                        <span
                          className="document-icon cancel-icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            this.removeFile(
                              !isEmpty(file.OriginalFileName) ? file.OriginalFileName : ""
                            );
                          }}
                        >
                          <AiFillCloseCircle />
                        </span>
                      )}
                    </div>
                    //</Link>
                  );
                })
              : null}
            {fileList && fileList.length > 0 ? (
              fileList.map((file, index) => {
                return (
                  <>
                    {file && !isEmpty(file.name) ? (
                      <div
                        className="flex pr-sm"
                        key={index}
                        onClick={() => this.onModalOpen(file, true)}
                      >
                        <span className="document-icon">
                          <FaRegFilePdf />
                        </span>
                        <label className="document-name">
                          {!isEmpty(file.name)
                            ? file.name.split(".")[0] + "." + file.name.split(".")[1]
                            : null}
                          {/* {file.name.split} */}
                        </label>
                        {isViewMode ? null : (
                          <span
                            className="document-icon cancel-icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              this.removeFile(
                                !isEmpty(file.name) ? file.name : "",
                                "local"
                              );
                            }}
                          >
                            <AiFillCloseCircle />
                          </span>
                        )}
                      </div>
                    ) : null}
                  </>
                );
              })
            ) : fileList.length === 0 && succesFileInfoList.length === 0 ? (
              <span className="document-no">No Documents Selected</span>
            ) : null}
          </div>
          <div className="badges-group">
            <div className="upload-btn-wrapper">
              <button className="upload-btn">Add document</button>
              <input
                type="file"
                name="file"
                onChange={this.fileSelectedHandler}
                disabled={isViewMode}
              />
            </div>
            {/* {fileList.length > 0 && (
              <button
                className="btn btn-primary"
                onClick={this.handleFileUpload}
              >
                Upload document
              </button>
            )} */}
          </div>
          <Modal
            open={this.state.modalOpen}
            onModalClose={this.onModalClose}
            title="Documents"
            className="travel-request-document"
          >
            {this.state.modalFile && this.renderView()}
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isFileUploadSuccess: state.travelInformationReducer.isFileUploadSuccess,
  succesFileInfoList: state.travelInformationReducer.succesFileInfoList,
});
const mapDispatchToProps = {
  UploadFiles,
};
export default connect(mapStateToProps, mapDispatchToProps)(Create_DocumentsTab);
