import React, { useState, useEffect, useRef } from "react";
import Avatar from "react-avatar";
import { formatedFullDayMMYearTime } from "helpers/dateFormat";
import EmojiButton from "../quillEditor/emojiButton";
import ReactTooltip from "react-tooltip";
import {
  addCommentReaction,
  removeCommentReaction,
  deleteComment,
} from "../../../../../../helpers/action";
import { connect } from "react-redux";
import CommentEditor from "../commentEditor";
import Modal from "components/modal";
import isEmpty from "helpers/isEmpty";

const CommentBody = (props) => {
  const { taskCommentId, taskId, members } = props;
  const [openConfirm, setOpenConfirm] = useState(false);
  const [comment, setComment] = useState("");
  const [editMode, setEditMode] = useState(false);
  const onEmojiSelected = async (item) => {
    let param = {
      TaskId: commentData.TaskId,
      TaskCommentId: commentData.CommentId,
      Reaction: item.code_decimal,
      ReactionName: item.shortname,
    };

    props.addCommentReaction && props.addCommentReaction(param);
  };
  const [commentData, setCommentData] = useState({} as any);
  const base = props.auth.baseUrl
    ? props.auth.baseUrl.NetFrameworkAppBaseUrl
    : "";

  useEffect(() => {
    setCommentData(props.data);
  }, [props.data]);

  const onClickedReaction = async (item) => {
    const { user } = props.auth;
    let index = item.Details.findIndex(
      (x) => x.UserId.toLowerCase() === user.UserID.toLowerCase()
    );

    if (index > -1) {
      const detail = item.Details[index];
      let param = {
        TaskId: commentData.TaskId,
        TaskCommentId: commentData.CommentId,
        Reaction: detail.Reaction,
        ReactionName: detail.ReactionName,
      };
      props.removeCommentReaction && props.removeCommentReaction(param);
    }
  };

  const onDeleteComment = async () => {
    props.deleteComment && props.deleteComment(commentData.CommentId);
  };

  return (
    !isEmpty(commentData) && (
      <>
        <div className="taskmodal__activities-comment">
          {editMode ? (
            <div className="editor">
              <CommentEditor
                enableCancelButton={true}
                alwaysShow={true}
                initialValue={commentData.Comment}
                commentId={commentData.CommentId}
                taskId={commentData.TaskId}
                mentions={members}
                placeHolder="Write a comments....."
                onSucessSubmitting={(val) => {
                  setEditMode(false);
                }}
                // onChange={(name, value) => {
                //   setComment(value);
                // }}
                onCancelClicked={() => {
                  setEditMode(false);
                }}
              />
            </div>
          ) : (
            <div className="comment">
              <div className="comment-header">
                <div className="comment-avatar">
                  <Avatar
                    className="avatar"
                    src={`${base}${commentData.ImagePath}`}
                    name={commentData.CommentedBy}
                    size={"30"}
                    round={true}
                  />
                </div>
                <div className="comment-title">
                  <p>
                   {commentData.CommentedBy}{" "}
                  </p>
                  <span>
                    {formatedFullDayMMYearTime(commentData.CommentedOn)}
                  </span>
                </div>
              </div>
              <div className="comment-body">
                <div
                  dangerouslySetInnerHTML={{ __html: commentData.Comment }}
                ></div>
              </div>
              <div className="comment-footer">
                <EmojiButton
                  rootEl=".modal__content-body"
                  hideOnSelect
                  onEmojiSelected={onEmojiSelected}
                />
                {commentData.Reactions.map((x, index) => (
                  <div
                    key={index}
                    data-tip="React-tooltip"
                    data-for={`tool-${index}`}
                    className="reaction"
                    onClick={() => onClickedReaction(x)}
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: x.Reaction }}
                    ></span>
                    <span>{x.Details.length}</span>
                    <ReactTooltip
                      id={`tool-${index}`}
                      type="dark"
                      effect="float"
                    >
                      <div className="reaction-tooltip">
                        <span
                          dangerouslySetInnerHTML={{ __html: x.Reaction }}
                        ></span>
                        <span>
                          {x.Details.map((y) => y.ReactedBy).join(", ")}
                        </span>
                      </div>
                    </ReactTooltip>
                  </div>
                ))}
                {commentData.CanEditDelete && (
                  <div className="comment-footer-action">
                    <span onClick={() => setEditMode(true)}>Edit</span>

                    <span onClick={() => setOpenConfirm(true)}>Delete</span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <Modal
          type="confirm"
          func={() => onDeleteComment()}
          open={openConfirm}
          onModalClose={() => setOpenConfirm(false)}
        />
      </>
    )
  );
};

const mapStateToProps = (state) => ({ auth: state.auth });
const mapDispatchToProps = {
  addCommentReaction,
  removeCommentReaction,
  deleteComment,
};
export default connect(mapStateToProps, mapDispatchToProps)(CommentBody);
