import FormGroup from "components/form";
import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { RecruitDashboardInformationPath } from "routes/commonLinks";
import {
  GetDashboardDetail,
  SetCurrentCandidateDetail,
} from "store/actions/recruitment/dashboard";
import { jobTypeList, timeOptionList } from "../enums";
import JobDetails from "./jobDetails";
import Breadcrumb from "scenes/common/recruitBreadcrumb";
import { RenderModal } from "./createJob";

export class Openings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedJobType: jobTypeList[0],
      selectedTimeOption: null,
      modalOpen: false,
    };
    this._renderData = this._renderData.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onJobClick = this.onJobClick.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
  }

  componentDidMount() {
    this.getDashboardDetail();
  }

  onChange(name, value) {
    this.setState({ [name]: value }, () => {
      if (value) {
        this.getDashboardDetail();
      }
    });
  }

  getDashboardDetail = () => {
    const { selectedJobType } = this.state;
    this.props.GetDashboardDetail(selectedJobType.value);
  };

  onJobClick(data) {
    this.props.SetCurrentCandidateDetail(data);
    this.props.history.push({
      pathname: RecruitDashboardInformationPath,
      search: `?jobId=${data.Jobid}`,
      // state: { propsData: data },
    });
  }

  onStageClick = (jobId, jobStage, job) => {
    this.props.SetCurrentCandidateDetail(job);
    this.props.history.push({
      pathname: RecruitDashboardInformationPath,
      search: `?jobId=${jobId}&jobStage=${jobStage}`,
      // state: { propsData: job, jobStage: jobStage },
    });
  };
  onModalClose() {
    this.setState({ modalOpen: false });
  }

  _renderData(props) {
    return (
      <div key={props.Jobid} className="panel-section details-section">
        <JobDetails
          data={props}
          isClickableTitle={true}
          onJobClick={this.onJobClick}
          onStageClick={this.onStageClick}
        />
      </div>
    );
  }

  render() {
    const { dashboardDetail } = this.props;
    return (
      <div className="common-container dashboard">
        <Breadcrumb name="Recruitment Dashboard" />
        <header className="dashboard__header">
          <div className="space-between panel-section">
            <h3 className="tertiary-title">Recruitment Home</h3>
            <button
              //   onClick={() => this.props.history.push(JobCreatePath)}
              onClick={() => this.setState({ modalOpen: true })}
              className="btn btn-green"
            >
              Create a new job
            </button>
          </div>
        </header>
        <main className="dashboard__body">
          <div className="select-section flex">
            <label>Your are viewing</label>
            <div className="dashboard-select">
              <FormGroup
                formName="reactselect"
                onChange={this.onChange}
                value={this.state.selectedJobType}
                options={jobTypeList}
                name="selectedJobType"
                width="100%"
                placeholder="Job Status"
              />
            </div>
            <div className="dashboard-select">
              <FormGroup
                formName="reactselect"
                onChange={this.onChange}
                value={this.state.selectedTimeOption}
                options={timeOptionList}
                name="selectedTimeOption"
                width="100%"
                placeholder="When"
              />
            </div>
          </div>
          {dashboardDetail && dashboardDetail.map(this._renderData)}
        </main>
        {
          <RenderModal
            onModalClose={this.onModalClose}
            history={this.props.history}
            modalOpen={this.state.modalOpen}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboardDetail: state.recruitment_dashboardReducer.dashboardDetail,
});

const mapDispatchToProps = {
  GetDashboardDetail,
  SetCurrentCandidateDetail,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Openings)
);
