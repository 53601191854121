import React,{useState,useEffect} from 'react';
import FormGroup from 'components/form';
import {addCluster} from '../helpers/action';
import { useDispatch } from 'react-redux';
import isEmpty from 'isEmpty';

function AddCluster({close,ProvinceId,isEdit,cluster}) {
    const dispatch = useDispatch();
    const [name,setName] = useState("");
    const [error,setError] = useState("");
    const onChange = (e) =>{
        const {name,value} = e.target;
        setName(value)
    }
    const onSave = () =>{
        if(isEmpty(name)){
            setError('Cluster name field is required')
            return;
        }
        const data ={
            ClusterName:name,
            ProvinceRefId:ProvinceId,
            ClusterId:0,
            ClusterHeadId:0
        }
        dispatch(addCluster(data));
        setError('');
        close()
    }
    useEffect(()=>{
        if(isEdit){
            setName(cluster.ClusterName)
        }
    },[])
    const onUpdate = () => {
        if(isEmpty(name)){
            setError('Cluster name field is required')
            return;
        }
        const data ={
            ClusterName:name,
            ProvinceRefId:ProvinceId,
            ClusterId:cluster.ClusterId,
            ClusterHeadId:cluster.ClusterHeadId
        }
        dispatch(addCluster(data,isEdit=isEdit?true:false));
        setError('');
        close()
    }
    return (
        <div className="permission">
            <div className="permission-body">
                <FormGroup 
                    name="cluster_name"
                    label="Cluster Name"
                    onChange={onChange}
                    value ={name}
                    error={error}
                />
            </div>
            <div className="permission-footer">
                <div className="button-group button-group__right">
                    
                    {
                        isEdit?
                        (<button 
                            onClick={()=>onUpdate()}
                            className="button button-primary">Update</button>)
                        :(
                            <button 
                            onClick={()=>onSave()}
                            className="button button-primary">Save</button>
                        )
                    }
                    
                    <button 
                        onClick={()=>close()}
                        className="button">Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default AddCluster
