import React, { Component } from "react";
import { connect } from "react-redux";
import isEmpty from "isEmpty";
import Form from "components/form";
import "../style.scss";
import { InputValidator } from "helpers/inputValidator";
import Documents from "../../helpers/documentUpload";
import Button from "components/button";
import moment from "moment";
import { objectToFormData } from "object-to-formdata";
import { DisplayClientSideMessage } from "actions/commonAction";
import getFullStringUrl from "helpers/getFullStringUrl";
import {hostedUrl} from "constants/urls";
import {Apis} from "src/config/api";
import RouteEnum from "constants/routeEnum";
import classnames from "classnames";
import {
  getVehicleLoanPurposeList,
  saveVehicleLoanRequest,
  getVehicleLoanDetails,
  updateVehicleLoanRequest,
  getQualifiedApprovers,
} from "../../helpers/action";
export class VehicleLoanForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      RequestAmount: "",
      Purpose: "",
      VehicleName: "",
      ModelName: "",
      EngineCapacity: "",
      EngineCapacity: "",
      QuotationPrice: "",
      RegistrationDate: "",
      Files: [],
      errors: {},
      // EditView: false,
      Id: 0,
      ForwardTo: "",
      loanApproverList: [],
      Submitted: false,
      isSelfApplied: true,
    };
  }

  async componentDidMount() {
    let {
      getVehicleLoanPurposeList,
      getVehicleLoanDetails,
      match,
      user: { EmployeeId },
      getQualifiedApprovers,
      loanApproverList,
    } = this.props;
    getVehicleLoanPurposeList();
    const { id } = match.params;
    // if (isEmpty(loanApproverList)) {
      await getQualifiedApprovers(EmployeeId);
    // }
    loanApproverList = this.props.loanApproverList;

    loanApproverList =
      // loanApproverList && loanApproverList.filter((loan) => loan.IsHR == true);
      loanApproverList && loanApproverList.filter((loan) => loan.Role == 1);

    if (id) await getVehicleLoanDetails(id);
    let { vehicleLoanDetail, user } = this.props;
    if (id && vehicleLoanDetail) {
      const { attachments, LoanForwards, EmployeeId } = vehicleLoanDetail;

      let isSelfApplied = user?.EmployeeId == EmployeeId;

      let Files = [];
      attachments &&
        attachments.forEach((file) => {
          Files.push({
            ...file,
            Id: file.AttachmentID,
            OriginalName: file.UserFileName,
            DocumentPath: file.FileLocation,
            DocumentName: file.UserFileName,
            name: file.UserFileName,
            path: file.FileLocation + file.ServerFileName,
            docName: file.UserFileName,
            isFetched: true,
          });
        });
      // let ForwardTo = [];
      // LoanForwards.forEach((loan) => {
      //   ForwardTo.push(loan.ForwardTo);
      // });
      this.setState({
        ...vehicleLoanDetail,
        EditView: true,
        Files,
        Id: id,
        ForwardTo: vehicleLoanDetail.ForwardTo
          ? vehicleLoanDetail.ForwardTo
          : "",
        loanApproverList,
        EmployeeId,
        isSelfApplied,
      });
    } else this.setState({ loanApproverList, EditView: false });
  }

  onChange = (name, value) => {
    if (name == "Files" && !isEmpty(value)) {
      console.log(!this.validateImage(value));
      debugger
      if(!this.validateImage(value)) {
        console.log("onchanged calledn with invalid", name, value);
        return;
      } else {
        console.log("onchanged calledn with name", name, value)
         this.setState({[name]: value}, () => {
           this.updateErrors();
         });
      }
    } else {
        console.log("onchanged calledn with else", name, value);

      //  if (name == "Purpose") this.setState({ PurposeError: false })   
       this.setState({[name]: value}, () => {
         this.updateErrors(name);
       });
    }
  };

  updateErrors = async (name) => {
    let { errors, Purpose } = this.state;
    if (!isEmpty(errors)) {
      let errors = await InputValidator(document, this.state);
      if (Purpose == '') {
        errors = {
          ...errors,
          Purpose: "Required"
        }
      } else {
        errors = {
          ...errors,
          Purpose: false,
        };
      }
      this.setState({ errors });
    }
  };

  handleFormSubmit = async (IsDraft) => {
    let errors = await InputValidator(document, this.state);
    this.setState({ Submitted: true }, async () => {
      if (!isEmpty(errors) && !IsDraft) {
        if (this.state.Purpose == '') {
          errors = {
            ...errors,
            Purpose: true,
          }
        }
        this.setState({ errors });
        return;
      }
      const {
        RequestAmount,
        VehicleName,
        ModelName,
        EngineCapacity,
        CompanyName,
        QuotationPrice,
        Purpose,
        EditView,
        Id,
        Files,
        ForwardTo,
        RegistrationDate,
        isSelfApplied,
      } = this.state;
      let isError = false;
      if (isEmpty(Files) && !IsDraft) {
        this.props.DisplayClientSideMessage(
          "Please upload all of the required documents"
        );
        isError = true;
      }
      if (Purpose == "" && !IsDraft) {
        this.props.DisplayClientSideMessage(
          "Please Select at least one Loan Purpose"
        );
        this.setState({PurposeError: true});
        isError = true;
      }
      if (isError) return;

      const {
        user: { EmployeeId },
        history,
      } = this.props;
      let vehicleLoanData = {
        Id: EditView ? Id : 0,
        EmployeeId: EditView ? this.state.EmployeeId : EmployeeId,
        RequestAmount,
        Purpose,
        VehicleName,
        ModelName,
        EngineCapacity,
        CompanyName,
        QuotationPrice,
        ForwardTo,
        RegistrationDate: RegistrationDate ? new Date(RegistrationDate) : "",
        IsDraft,
      };
      if (ForwardTo == "") vehicleLoanData.ForwardTo = null;
      const options = {
        indices: true,
        nullsAsUndefineds: false,
        booleansAsIntegers: false,
      };
      let AttachmentIds = [];
      Files &&
        Files.forEach((doc, i) => {
          if (doc.AttachmentID) AttachmentIds.push(doc.AttachmentID);
        });
      vehicleLoanData.AttachmentIds = AttachmentIds;
      const formData = objectToFormData(vehicleLoanData, options);
      Files &&
        Files.map((doc, i) => {
          if (!doc.AttachmentID) formData.append(`Files`, doc);
        });
      if (EditView) await this.props.updateVehicleLoanRequest(formData);
      else await this.props.saveVehicleLoanRequest(formData);
      const { vehicleLoanSaved } = this.props;
      if (vehicleLoanSaved) {
        if (isSelfApplied) history.push(RouteEnum.EmployeeLoanList);
        else history.push(RouteEnum.LoanManagement);
        // if (EditView && !isSelfApplied) this.props.history.push(RouteEnum.LoanManagement);
        // else this.props.history.push(RouteEnum.EmployeeLoanList);
      }
    });
  };

  showDocumentModal = (file, flag) => {
    if (flag)
      window.open(window.URL.createObjectURL(file), "_blank");
    else if (file) {
       const param = {
         serverFileName: file.ServerFileName.replace("Uploads/", ""),
         userFileName: file.UserFileName,
         fileType: file.FileType,
       };
      window.open(getFullStringUrl(`${hostedUrl}${Apis.common.downloadFiles}`, param));

      // let fileURI = "";
      // if (!file?.isFetched) fileURI = URL.createObjectURL(file);
      // else fileURI = getUploadPath(`/${file.ServerFileName}`);
      // console.log("fileURI", fileURI);
      // window.open(fileURI, "_blank");
    }
  };
  validateImage = (elem) => {
    debugger
    if (!elem) return false
    const file = elem[0];
    if (file.size > 5000000) {
      this.props.DisplayClientSideMessage("Maximum valid file size is 5 MB.");
    //556000 Byte==556 Kilobyte
      return false
    }
    return true;
  }

  render() {
    let {
      RequestAmount,
      Purpose,
      VehicleName,
      ModelName,
      EngineCapacity,
      CompanyName,
      QuotationPrice,
      RegistrationDate,
      errors,
      EditView,
      loanApproverList,
      ForwardTo,
      Files,
      Submitted,
      isSelfApplied,
      IsDraft,
      PurposeError
    } = this.state;
    const {
      vehicleLoanPurposeList,
      user: { EmployeeId },
      DisplayClientSideMessage,
      history,
    } = this.props;
    if (isEmpty(EditView)) return null;
    var radioClassName = classnames({
      "homeloan-section-content homeloan-checkboxes flex": true,
    });
    loanApproverList = loanApproverList?.filter(
      (appr) => appr.Id != EmployeeId
    );

    return (
      <div className="personal">
        <div className="personal-container">
          {/* <Title name="Vehicle Loan Request" /> */}
          <div className="homeloan homeloan-vehicleloan wd-60">
            <div className="homeloan-body">
              <div className="homeloan-header mb-bg">
                <h3>Staff Vehicle Loan Request</h3>
              </div>
              <div className="homeloan-section-content mb-md">
                <div className="text-md mb-sm">
                  <span> Loan Request Amount *</span>
                </div>
                <Form
                  name="RequestAmount"
                  type="number"
                  value={RequestAmount}
                  onChange={this.onChange}
                  // label="Loan Request Amount"
                  width={"30%"}
                  id={"numbersField"}
                  validators={["required", "posNumbers"]}
                  error={errors.RequestAmount}
                />
              </div>
              <div className="homeloan-header mb-sm">
                <div className="text-md mb-sm">
                  <span>Purpose of Loan *</span>
                </div>
              </div>
              <div className={radioClassName}>
                {vehicleLoanPurposeList &&
                  vehicleLoanPurposeList.map((radio, index) => {
                    return (
                      <>
                        <label class="checkbox-container">
                          {radio.label}
                          <input
                            type="checkbox"
                            checked={radio.value == Purpose}
                            onChange={() => this.onChange("Purpose", radio.value)}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </>
                    );
                  })}
              </div>
              {errors.Purpose && (
                <div className="homeloan__checkbox__required text-red mb-sm">
                  Required
                </div>
              )}
              <div className="homeloan-section-content">
                <div className="homeloan-propertydetail">
                  <div className="homeloan-propertydetail-section flex">
                    <div className="wd-40 mr-bg mb-md">
                      <div className="text-md mb-sm">
                        <span>Vehicle Name *</span>
                      </div>
                      <Form
                        name="VehicleName"
                        value={VehicleName}
                        onChange={this.onChange}
                        validators={["required"]}
                        error={errors.VehicleName}
                      />
                    </div>
                  </div>
                  <div className="homeloan-propertydetail-section flex">
                    <div className="wd-40 mr-bg mb-md">
                      <div className="text-md mb-sm">
                        <span className="text-md mb-sm">Model Number *</span>
                      </div>
                      <Form
                        name="ModelName"
                        value={ModelName}
                        onChange={this.onChange}
                        validators={["required"]}
                        error={errors.ModelName}
                      />
                    </div>
                  </div>
                  <div className="homeloan-propertydetail-section flex">
                    <div className="wd-40 mr-bg mb-md">
                      <div className="text-md mb-sm">
                        <span className="text-md mb-sm">Engine Capacity *</span>
                      </div>
                      <Form
                        name="EngineCapacity"
                        value={EngineCapacity}
                        onChange={this.onChange}
                        validators={["required", "posNumbers"]}
                        type="number"
                        error={errors.EngineCapacity}
                        // width={"50%"}
                      />
                    </div>
                  </div>
                  <div className="homeloan-propertydetail-section flex">
                    <div className="wd-40 mr-bg mb-md">
                      <div className="text-md mb-sm">
                        <span className="text-md mb-sm">
                          Company Name (Vehicle Seller)
                        </span>
                      </div>
                      <Form
                        name="CompanyName"
                        value={CompanyName}
                        onChange={this.onChange}
                        validators={["required"]}
                        error={errors.CompanyName}
                      />
                    </div>
                  </div>
                  <div className="homeloan-propertydetail-section flex">
                    <div className="mr-bg mb-md">
                      <div className="text-md mb-sm">
                        <span className="text-md mb-sm">Quotation Price/Valuation *</span>
                      </div>
                      <Form
                        name="QuotationPrice"
                        value={QuotationPrice}
                        onChange={this.onChange}
                        width={"106%"}
                        alignment={"right"}
                        type="number"
                        validators={["required", "posNumbers"]}
                        error={errors.QuotationPrice}
                      />
                    </div>
                  </div>
                  <div className="homeloan-propertydetail-section flex">
                    <div className="mr-bg mb-md">
                      <div className="text-md mb-sm">
                        <span className="text-md mb-sm">
                          Registration Date (For Old Vehicle)
                        </span>
                      </div>
                      <Form
                        formName="customdateinput"
                        // label="As on Date"
                        value={
                          !isEmpty(RegistrationDate)
                            ? moment(RegistrationDate).format("YYYY/MM/DD")
                            : null
                        }
                        // validators={["required"]}
                        name="RegistrationDate"
                        error={errors && errors.RegistrationDate}
                        onChange={(name, value) => this.onChange(name, value)}
                        placeholder={"YYYY/MM/DD"}
                        maxDate={new Date()}
                        // isNepali={!companyInformation.IsEnglishDate}
                        // isTextInNepali={!companyInformation.IsEnglishDate}
                      />
                    </div>
                  </div>
                  {(!EditView || IsDraft) && (
                    <div className="homeloan-propertydetail-section flex mt-md">
                      <Form
                        formName="checkBoxGroup"
                        name="ForwardTo"
                        label="Human Resource Department"
                        checkGroupArray={loanApproverList}
                        checkedGroupValue={ForwardTo}
                        onChange={(name, value) => this.onChange(name, value)}
                        multiple={false}
                        // loading={leaveRequestApprover.loading}
                        // loadingType="skeleton"
                        validators={["required"]}
                        // alignment="horizontal"
                        error={errors && errors.ForwardTo}
                        disabled={EditView && !IsDraft}
                        width="100%"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="homeloan-section-content">
                {(!isEmpty(Files) || EditView != undefined) && (
                  <Documents
                    data={this.state}
                    onChange={this.onChange}
                    showDocumentModal={this.showDocumentModal}
                    loanType={"vehicle"}
                    DisplayClientSideMessage={DisplayClientSideMessage}
                  />
                )}
              </div>
            </div>
            <div className="homeloan-section-footer flex justify-between">
              <div className="homeloan-section-footer-item">
                <Button bg="subtle" onClick={() => history.goBack()} title="Cancel" />
              </div>
              {/* <div className="homeloan-section-footer-item">
                <Button
                  bg="primary"
                  // onClick={() => this.onClose()}
                  title="Previous"
                />
              </div>
              <div className="homeloan-section-footer-item homeloan-section-footer-next_button center-text">
                <Button
                bg="primary"
                  // onClick={() => this.onClose()}
                  title="Next"
                  />
                </div> */}
              <div className="homeloan-section-footer-item flex">
                {isSelfApplied && (
                  <div className="homeloan-section-footer-item mr-md">
                    <Button
                      bg="primary"
                      onClick={() => this.handleFormSubmit(true)}
                      title="Save as draft"
                    />
                  </div>
                )}
                <Button
                  bg="primary"
                  title="Send Request"
                  onClick={() => this.handleFormSubmit(false)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  auth: state.auth,
  vehicleLoanPurposeList: state.loanReducer.vehicleLoanPurposeList,
  vehicleLoanSaved: state.loanReducer.vehicleLoanSaved,
  loanApproverList: state.loanReducer.loanApproverList,
  vehicleLoanDetail: state.loanReducer.vehicleLoanDetail,
});

const mapDispatchToProps = {
  getVehicleLoanPurposeList,
  saveVehicleLoanRequest,
  getVehicleLoanDetails,
  getQualifiedApprovers,
  updateVehicleLoanRequest,
  DisplayClientSideMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleLoanForm);
