import React, { Component,useState,} from "react";
import { FaFileAlt } from "react-icons/fa";
import JobDescription from "../employeeProfile/placement/responsibility/jobDescription";
import Modal from "components/modal";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {getJobDescriptionByLevel,getJDViewDetails} from '../employeeProfile/action'

function JobProfile(props) {
  const [open,setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const dispatch = useDispatch();
  const {user} = useSelector(state=>state.auth);
  const { data } = props;
  const onOpen = async (mode) => {
    let searchData = {
      EmployeeId: user.EmployeeId,
      JobTitle: "",
      PageIndex: 0,
      PageSize: 10,
    };
    await dispatch(getJDViewDetails(data.DescriptionAttachment));
    // setMode(mode);
    setOpen(true);
  };
  
  return (
    <div className="job_profile">
    <div className="bloch__title">Job Profile</div>
    <div className="job_profile-block">
      <div className="job_profile-block-section">
        <span className="label">Job Family</span>
        <span className="detail">{data.Family}</span>
      </div>
      <div className="job_profile-block-section">
        <span className="label">Job Code</span>
        <span className="detail">{data.Code}</span>
      </div>
      <div className="job_profile-block-section">
        <span className="label">Job Description</span>
        {/* <span className="detail">{data.Description}</span> */}
        <span 
          onClick={()=>onOpen()}
          className="detail detail-icon"><FaFileAlt /></span>
      </div>
      <Modal
        onModalClose={onClose}
        title={"Job Description"}
        className="job-description-modal"
        open={open}
        width="80%"
      >
        <JobDescription
          jobDesc={data.Description}
          closeModal={onClose}
          isEmployee
          {...props} 
        />
      </Modal>
      {/* {data.DescriptionAttachment && (
        <div className="job_profile-block-section">
          <span className="label">Attachment</span>

          <abbr title={data.DescriptionAttachment} className="icon">
            <FaFileAlt />
          </abbr>
        </div>
      )} */}
    </div>
  </div>
  )
}

export default JobProfile
