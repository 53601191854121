import React, { useState } from "react";
import classnames from "classnames";
import { FaFileAlt, FaGraduationCap, FaEdit } from "react-icons/fa";
import { connect } from 'react-redux'
import Button from 'components/button'


function EducationDetail(props) {
        
        const [ viewAll, setViewAll ] = useState(false);
        const travelClasses = () => {
            return classnames({
            badges: true,
            "badges-rounded": true,
            "badges-secondary": true,
            "mr-sm":true
            });
        };
        // const { educationInfo } = props;
        let educationInfo = props.educationInfo;
        let educationToDisplay = [...educationInfo];
        if (!viewAll)
            educationToDisplay = educationToDisplay.slice(0, 2);
        return (
            <div className="profile-row">
            <div className="profile-column">
                <div className="profile-block">
                    <div className="profile-block__title">
                        <h2>EDUCATION, TRAINING & SKILLS</h2>
                    </div>

                    <div className="profile-address__block">
                        {educationInfo.length > 0 ?  educationToDisplay.map((edu, i) => <div key={i + Math.random()} className="profile-block__div">
                            <span className="document-serial">
                                {edu.SN}
                            </span>
                            <div className="profile-contact__label education">
                                {edu.Course}
                            </div>
                            <div className="value2-passout">
                                {edu.PassedYear ? edu.PassedYear : '-'}
                            </div>
                            <div className="value2-institution">
                                {edu.College}&nbsp;{edu.University} {edu.Percentage ? `(${edu.Percentage})` : ''}
                                {edu.College == '' && edu.University == '' && '-'}
                            </div>
                            <span className="document-icon">
                                <FaFileAlt />
                            </span>
                        </div>) : <div className="profile-block__title nodata">
                                    <h2>No Education Record</h2>
                            </div>
                        }
                        {/* <div className="profile-block__div">
                            <span className="document-serial">
                                4
                            </span>
                            <div className="profile-contact__label education">
                                Bacholar in Business Studies
                            </div>
                            <div className="value2-passout">
                                2070 Magh
                            </div>
                            <div className="value2-institution">
                                Global Campus, PU
                                <span>(60%)</span>
                            </div>
                            <span className="document-icon">
                                <FaFileAlt />
                            </span>
                        </div>
                        <div className="profile-block__div">
                            <span className="document-serial">
                                3
                            </span>
                            <div className="profile-contact__label education">
                                Higher Secondary Education (10+2)
                            </div>
                            <div className="value2-passout">
                                2066 Chaitra
                            </div>
                            <div className="value2-institution">
                                Janamaitri Campus, HSEB
                                <span>
                                    (70%)
                                </span>
                            </div>
                            <span className="document-icon">
                                <FaFileAlt />
                            </span>
                        </div> */}
                        
                        {educationInfo && educationInfo.length > 1 && <div className="profile-block__div">
                            <Button 
                                bg="white"
                                onClick={() => setViewAll(!viewAll)}  title={!viewAll ?  "View all" : "Show Less"} />
                        </div>}
                        {educationInfo.length > 0 && <div className="profile-block__div">

                            <div 
                                style={{flexDirection:'row',alignItems:'center'}}
                                className="badges-group">
                                {educationToDisplay.map(edu => <div className={travelClasses()}>
                                    {edu.Course}
                                </div>)}
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
        );
};

const mapStateToProps = state => ({
    educationInfo: state.employeeProfile.employeeProfile && state.employeeProfile.employeeProfile.EducationAndTraining || [],
});

export default connect(mapStateToProps, null)(EducationDetail);