import React from 'react'
import Tabs,{Tab} from 'components/tabs/index.d'
import {GiReceiveMoney} from 'react-icons/gi'
import Button from 'components/button';
import {MdEdit,MdClose} from 'react-icons/md'
import {AiOutlineStop} from 'react-icons/ai'

function Deduction() {
    return (
        <div className="emp-payroll__block">
            <div className="emp-payroll__title">Deductions, Loan & Advances</div>
            <Tabs>
                <Tab label="Active" name="Active">
                    <Active />
                </Tab>
                <Tab label="Disabled" name="Disabled">
                    <Active />
                </Tab>
                <Tab label="No Appelled" name="No Appelled">
                    <Active />
                </Tab>
            </Tabs>
        </div>
    )
}

const activeData = [
    {title:'Provident Fund Deduction',amount:'3,000.00',date:'Baisakh 1, 2076',freq:'Monthly'},
    {title:'CIT Deduction',amount:'500.00',date:'Baisakh 1, 2076',freq:'Monthly'},
]

const Active = () =>{
    return(
        <div className="emp-payroll__tables">
            <div className="emp-payroll__table">
                <div className="emp-payroll__header">
                    <HeaderCol icon={ <GiReceiveMoney />} headings={['Deductions Names','Amount','Effective from','Frequence']} />
                </div>
                <SampleHeaderBody />
            </div>
        </div>
    )
}

const SampleHeaderBody = () =>{
    return(
        <div className="emp-payroll__body">
            {
                activeData.map(item=>{
                    return(
                        <div className="emp-payroll__row">
                            <div className="emp-payroll__col">
                                <div className="flex">
                                    <div className="emp-payroll__circle"></div>
                                    <span className="text-primary">
                                        {item.title}
                                    </span>
                                </div>
                            </div>
                            <div className="emp-payroll__col">
                                <span>{item.amount}</span>
                            </div>
                            <div className="emp-payroll__col">
                                <span>
                                {item.date}
                                </span>
                            </div>
                            <div className="emp-payroll__col">
                                <span>
                                    {item.freq}
                                </span>
                            </div>
                            <div className="emp-payroll__col">
                                <div className="emp-payroll__buttons">
                                    <Button 
                                        buttonType="icon-button"
                                        leftIcon={<MdEdit />}
                                        title={"Edit"}
                                        bg="primary"
                                    />
                                    <Button 
                                        buttonType="icon-button"
                                        leftIcon={<AiOutlineStop />}
                                        title={"Disabled"}
                                        bg="secondary-dark"
                                    />
                                    <Button 
                                        buttonType="icon-button"
                                        leftIcon={<MdClose />}
                                        title={"Remove"}
                                        bg="white"
                                    />
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            
        </div>
    )
}

const HeaderCol = (props) =>{
    const {headings,icon} = props;
    return(
        <div className="emp-payroll__row">
            {
                headings.map((item,key)=>{
                    return(
                        <div className="emp-payroll__header-col">
                            {key == 0 ? icon :null}
                            <span>
                               {item}
                            </span>
                        </div>
                    )
                })
            }
            <div className="emp-payroll__header-col"></div>
        </div>
    )
}

export default Deduction