import React from "react";

export default function WorkExperience(props: any) {
  const { data } = props || {};

  const { ExperienceInDays, ExperienceInMonths, ExperienceInYears } = data;
  let exp = "";

  if (ExperienceInYears) {
    if (ExperienceInYears <= 1) {
      exp = `${ExperienceInYears} year  `;
    } else {
      exp = `${ExperienceInYears} years  `;
    }
  }

  if (ExperienceInMonths > 0) {
    if (ExperienceInMonths == 1) {
      exp = `${exp} ${ExperienceInMonths} month `;
    } else {
      exp = `${exp} ${ExperienceInMonths} months `;
    }
  }

  if (ExperienceInDays > 0) {
    if (ExperienceInDays == 1) {
      exp = `${exp} ${ExperienceInDays} day `;
    } else {
      exp = `${exp} ${ExperienceInDays} days `;
    }
  }

  return <div className="">{exp}</div>;
}
