import React, {useState, useRef, useEffect, useLayoutEffect} from "react";
import {
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from "react-icons/md";
import RouteEnum from "constants/routeEnum";
import {NavLink} from "react-router-dom";
import useOutsideClick from "helpers/outsideClickHook";
import classnames from "classnames";
import {CSSTransition} from "react-transition-group";
import techo from "assets/images/icons/tachometer-alt-fast.svg";
import tasks from "assets/images/icons/tasks.svg";
import scroll from "assets/images/icons/scroll.svg";
import {isFeatureEnabledValue} from "helpers/checkPermissions";
import useEventListener from "helpers/customHooks/useEventListener";

const {REACT_APP_IS_LEAVE_EMPLOYEE_MENU} = process.env;
const navigationContent = [
  {
    navId: 1,
    title: "Dashboard",
    link: RouteEnum.EmployeeDashBoard,
    feature: "CoreHR",
    env: true,
  },
  {
    navId: 2,
    title: "Personal Information",
    link: RouteEnum.PersonalInfo,
    feature: "CoreHR",
    env: true,
  },
  {
    navId: 3,
    title: "Travel",
    link: RouteEnum.TeamTravelRequestPath,
    feature: "TravelManagement",
    env: true,
  },
  // {
  //   navId: 4,
  //   title: "Recruit",
  //   link: RouteEnum.JobListingPath,
  //   feature: "Recruitment",
  //   env: true,
  // },
  {
    navId: 5,
    title: "Job Information",
    link: RouteEnum.JobInfo,
    feature: "JobProfile",
    env: true,
  },
  {
    navId: 6,
    title: "Payroll",
    link: RouteEnum.EmployeePayroll,
    feature: "CoreHR",
    env: true,
  },
  {
    navId: 7,
    title: "Leaves",
    link: RouteEnum.EmployeeLeavesPath,
    feature: "LeaveAndTime",
    env:
      REACT_APP_IS_LEAVE_EMPLOYEE_MENU === "true" ||
      REACT_APP_IS_LEAVE_EMPLOYEE_MENU !== "false",
  },
  {
    navId: 8,
    title: "Time",
    link: RouteEnum.EmployeeTimePath,
    feature: "LeaveAndTime",
    env:
      REACT_APP_IS_LEAVE_EMPLOYEE_MENU === "true" ||
      REACT_APP_IS_LEAVE_EMPLOYEE_MENU !== "false",
  },
  {
    navId: 9,
    title: "Reports",
    feature: "CoreHR",
    env: true,
    link: RouteEnum.EmployeeReportsPath,
  },
  {
    navId: 12,
    title: "LMS",
    feature: "LMS",
    // isDrop:true,
    env: true,
    link: RouteEnum.lmsRoutes,
    // links:[
    //   {
    //     title:"Courses",
    //     link:RouteEnum.lmsRoutes,
    //     icon:techo
    //   },
    //   {
    //     title:"Add Course",
    //     link:RouteEnum.lmsCourseAdd,
    //     icon:techo
    //   },
    //   {
    //     title:"Courses",
    //     link:RouteEnum.lmsCoursePages,
    //     icon:techo
    //   }
    // ]
  },
  {
    navId: 10,
    title: "Task Tracker",
    feature: "TaskTracker",
    isDrop: true,
    env: true,
    links: [
      {
        title: "Activity Dashboard",
        link: RouteEnum.ActivityDashboardPath,
        icon: techo,
      },
      {
        title: "Activity Report",
        link: RouteEnum.ActivityReportPath,
        icon: scroll,
      },
      {
        title: "Activity Not Submitted",
        link: RouteEnum.ActivityNotSubmittedReportPath,
        icon: scroll,
      },
    ],
  },
  // {
  //   navId: 11,
  //   title: "Loans",
  //   feature: "TaskTracker",
  //   isDrop: true,
  //   env: true,
  //   links: [
  //     {
  //       title: "Home Loan Request",
  //       link: RouteEnum.CreateHomeLoanPath,
  //       icon: techo,
  //     },
  //     {
  //       title: "Vehicle Loan Request",
  //       link: RouteEnum.CreateVehicleLoanPath,
  //       icon: scroll,
  //     },
  //     {
  //       title: "Staff OD Request",
  //       link: RouteEnum.CreateStaffODLoanPath,
  //       icon: scroll,
  //     },
  //     {
  //       title: "Staff Personal Loan Request",
  //       link: RouteEnum.CreateStaffPersonalLoanPath,
  //       icon: scroll,
  //     },
  //     {
  //       title: "My Loan Requests",
  //       link: RouteEnum.EmployeeLoanList,
  //       icon: scroll,
  //     },
  //     {
  //       title: "Loan Management",
  //       link: RouteEnum.LoanManagement,
  //       icon: scroll,
  //     },
  //   ],
  // },
  {
    navId: 11,
    title: "Task Management",
    feature: "TaskManagement",
    isDrop: true,
    env: true,
    links: [
      {
        title: "Task Board",
        link: RouteEnum.TaskBoardListLinks,
        icon: scroll,
      },
      {
        title: "Things to Do",
        link: RouteEnum.TaskBoardTodo,
        icon: scroll,
      },
    ],
  },

  {
    navId: 12,
    title: "Other",
    feature: "CoreHR",
    isDrop: false,
    env: true,
    link: RouteEnum.EmployeeOtherMenu,
  },
];
function Navigation({props}) {
  const navRef = useRef(null);
  const dropRef = useRef(null);
  const [drop, setDrop] = useState(false);
  const [hiddenChildren, setHiddenChildren] = useState([]);

  useEffect(() => {
    if (navRef && navRef.current) {
      const ele = navRef.current;
      const isOffset =
        ele.offsetHeight < ele.scrollHeight || ele.offsetWidth < ele.scrollWidth;
    }
  }, [navRef]);
  const onHiddenChild = (child) => {
    setHiddenChildren((prev) => {
      if (prev) {
        const isThere = prev && prev.find((item) => item.navId === child.navId);
        if (isThere) {
          return prev;
        }
        return [...prev, child];
      }
      return [child];
    });
  };
  const removeFromHidden = (child) => {
    setHiddenChildren((prev) => {
      return prev ? prev.filter((item) => item.navId !== child.navId) : [];
    });
  };
  useOutsideClick(dropRef, () => setDrop(false));
  return (
    <div className="header-navigation">
      <nav ref={navRef} className="navbar flex">
        <ul className="navbar-list navbar-nav">
          {navigationContent.map((content, index) => {
            if (!isFeatureEnabledValue(content.feature)) {
              return null;
            }
            if (content.env) {
              return (
                <Tab
                  key={index}
                  removeFromHidden={removeFromHidden}
                  onHiddenChild={onHiddenChild}
                  parent={navRef?.current}
                  content={content}
                />
              );
            }
            return null;
          })}
        </ul>
        {hiddenChildren && hiddenChildren.length > 0 && (
          <div ref={dropRef} className="navbar-down">
            {/* {hiddenChildren.map(item=>item)} */}
            <a
              onClick={() => setDrop(!drop)}
              className={classnames("navbar-list__links", {
                "navbar-list__links-active": drop,
              })}
            >
              {drop ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
            </a>
            {drop && (
              <div className="navbar-dropdown">
                <ul className="navbar-dropdown__list">
                  {hiddenChildren.map((item) => {
                    if (item.isDrop) {
                      return <DropLink content={item} isSideways />;
                    }
                    return (
                      <li key={item.title} className="navbar-dropdown__item">
                        <NavLink to={item.link} className="navbar-dropdown__link">
                          <div className="navbar-dropdown__title">{item.title}</div>
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        )}
      </nav>
    </div>
  );
}

const DropLink = ({content, ...rest}) => {
  const {parent, onHiddenChild, removeFromHidden, isSideways} = rest;
  const [drop, setDrop] = useState(false);
  const onOpen = () => {
    setDrop(!drop);
  };
  const dropRef = useRef(null);
  const onClose = () => {
    setDrop(false);
  };
  useOutsideClick(dropRef, onClose);
  const dropClasses = classnames({
    "navbar-list__item": true,
    "drop-active": drop,
    "drop-side": isSideways,
  });
  const linkItem = dropRef?.current?.getBoundingClientRect();
  const {
    bottom,
    height: ht,
    left,
    right,
    top,
  } = linkItem || {top: 0, left: 0, bottom: 0, right: 0, height: 40};
  const [isHidden, setHidden] = useState(false);
  const [width, height] = useWindowSize();
  useEffect(() => {
    HideChildren();
  }, [dropRef, parent]);

  useEffect(() => {
    HideChildren();
  }, [width, height]);
  const HideChildren = () => {
    if (dropRef && dropRef.current && parent && !isSideways) {
      const child = dropRef.current;
      if (
        child.offsetLeft + child.offsetWidth - parent.offsetLeft > parent.offsetWidth ||
        child.offsetTop - parent.offsetTop > parent.offsetHeight
      ) {
        setHidden(true);
        onHiddenChild(content);
      } else {
        setHidden(false);
        removeFromHidden(content);
      }
    }
  };
  return (
    <li
      ref={dropRef}
      style={{display: isHidden ? "none" : "block"}}
      onClick={() => onOpen()}
      className={dropClasses}
    >
      <a className="navbar-list__links">
        <span>{content.title} </span>
        <span className="navbar-list__toogle-icon">
          {drop ? (
            isSideways ? (
              <MdKeyboardArrowDown />
            ) : (
              <MdKeyboardArrowUp />
            )
          ) : isSideways ? (
            <MdKeyboardArrowRight />
          ) : (
            <MdKeyboardArrowDown />
          )}
        </span>
      </a>
      <CSSTransition
        in={drop}
        timeout={300}
        classNames="dropdown"
        style={isSideways ? {} : {top: top + ht, left, right}}
        appear
        unmountOnExit
      >
        <div className="navbar-dropdown">
          <ul className="navbar-dropdown__list">
            {content.links.map((item) => {
              return (
                <li key={item.title} className="navbar-dropdown__item">
                  <NavLink to={item.link} className="navbar-dropdown__link">
                    {/* <div className="navbar-dropdown__icon">
                      <img src={item.icon} alt="" />
                    </div> */}
                    <div className="navbar-dropdown__title">{item.title}</div>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      </CSSTransition>
    </li>
  );
};

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const Tab = ({key, content, ...rest}) => {
  const {parent, onHiddenChild, removeFromHidden} = rest;
  const [isHidden, setHidden] = useState(false);
  const [width, height] = useWindowSize();
  const navLinkRef = useRef(null);
  useEffect(() => {
    HideChildren();
  }, [navLinkRef, parent]);

  useEffect(() => {
    HideChildren();
  }, [width, height]);

  const HideChildren = () => {
    if (navLinkRef && navLinkRef.current && parent) {
      const child = navLinkRef.current;
      if (
        child.offsetLeft + child.offsetWidth - parent.offsetLeft > parent.offsetWidth ||
        child.offsetTop - parent.offsetTop > parent.offsetHeight
      ) {
        // console.log({child})
        setHidden(true);
        onHiddenChild(content);
      } else {
        setHidden(false);
        removeFromHidden(content);
      }
    }
  };
  if (content.isDrop) {
    return <DropLink content={content} {...rest} />;
  }

  return (
    <li
      ref={navLinkRef}
      style={{display: isHidden ? "none" : "block"}}
      className="navbar-list__item"
    >
      <NavLink
        exact
        className={"navbar-list__links"}
        to={{pathname: content.link, state: {navId: content.navId}}}
        key={key}
        activeClassName="active"
      >
        <span>{content.title}</span>
      </NavLink>
    </li>
  );
};

export default Navigation;
