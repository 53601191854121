import axios from "axios";
// import axios from "../../../axios";
import {
    GET_CALCULATION_HEADER,
    INCOME_CALCULATION_LOADING,
    GET_ERRORS,
    GET_PAYTYPE_VISIBILITY,
    SEARCH_CALCULATION_LIST,
    PAYTYPE_VISIBILITY_LOADING,
    CALCULATION_HEADER_LOADING
} from "../../../actions/types";
import { toastMessage } from "../../../actions/validateAction";

// Get Calculation Header
export const getCalcHeader = id => dispatch => {
    dispatch(setCalcHeaderLoading());
    axios
        .get(`/v1/payroll/calculation-header?payrollPeriodId=${id}`)
        .then(res => {
            // console.log(res)
            // toastMessage(res);
            dispatch({
                type: GET_CALCULATION_HEADER,
                payload: res.data
            });
        })
        .catch(err => {
            console.log(err);
            dispatch({
                type: GET_CALCULATION_HEADER,
                payload: null
            });
        });
};

// Search Income List
export const searchCalcList = data => dispatch => {
    dispatch(setIncomeCalcLoading());
    axios
        .post(`/v1/payroll/calculation-list`, data)
        .then(res => {
            dispatch({
                type: SEARCH_CALCULATION_LIST,
                payload: res.data.Data,
                page: {
                    index: res.data.PageIndex,
                    total: res.data.RecordsTotal
                }
            });
            toastMessage(res);
        })
        .catch(err =>
            dispatch({
                type: SEARCH_CALCULATION_LIST,
                payload: null
            })
        );
};

// Get Pay Type Visibility
export const getPayTypeVisibility = id =>dispatch=>{
    dispatch({type:PAYTYPE_VISIBILITY_LOADING});
    axios
    .get(`/v1/payroll/paytype-visibility?payrollPeriodId=${id}`)
    .then(res => {
        
        dispatch({
            type: GET_PAYTYPE_VISIBILITY,
            payload: res.data,
        });
    })
    .catch(err =>
        dispatch({
            type: GET_PAYTYPE_VISIBILITY,
            payload: null
        })
    );
}

// Set loading state
export const setIncomeCalcLoading = () => {
    return {
        type: INCOME_CALCULATION_LOADING
    };
};

export const setCalcHeaderLoading = () => {
    return {
        type: CALCULATION_HEADER_LOADING
    };
};
