import axios from "axios";
import { EmployeeID } from "constants/userDetails";
import {
  GET_PENDING_SETTLEMENT,
  GET_TRAVEL_REQUEST_DETAILS,
  GET_TRAVEL_CATEGORY_LIST,
  GET_OTHER_EXPENSES_LIST,
  GET_BILLS_AND_CLAIMS_LIST,
  ADD_MESSAGE,
  SET_SPINNER_LOADING,
  GET_CATEGORY_ALL_EXPENSE_LIST,
  EDIT_TRAVEL_REQUESTS,
  GET_HOST_AND_HOTEL,
  GET_MODE_OF_TRANSPORT,
  GET_COUNTRY_LIST,
  GET_CURRENCY_LIST,
  GET_DISTRICT_LIST,
  GET_EMPLOYEE_LIST,
  IS_LOADING,
  CANCEL_TRAVEL_REQUEST,
  HANDLE_SETTLEMENT_ACTIVE,
  SETTLEMENT_LOADING,
  GET_SETTLEMENT_REQUESTS,
  GET_SETTLEMENT_REQUESTS_EXCEL_EXPORT,
  GET_SETTLEMENT_APPROVAL_AUTHORITIES,
  GET_SETTLEMENT_APPROVAL_HISTORY,
  SETTLEMENT_APPROVAL_AUTHORITIES_LOADING,
  SETTLEMENT_APPROVAL_HISTORY_LOADING,
  HANDLE_SETTLEMENT_REPORT_ACTIVE,
  GET_SETTLEMENT_AUDIT_LOG,
  SETTLEMENT_AUDIT_LOG_LOADING,
  SETTLEMENT_REPORT_LOADING,
  GET_SETTLEMENT_REPORT,
  GET_SETTLEMENT_REQUEST_REPORT_PDF,
  SETTLEMENT_REQUEST_LOADING,
  PENDING_SETTLEMENT_LOADING,
  TRAVEL_REQUEST_DETAILS_LOADING,
  HANDLE_SETTLEMENT_SAVE,
  UPDATE_TRAVEL_COUNT,
  REMOVE_OTHER_EXPENSE,
} from "actions/types";
import { toastMessage } from "actions/validateAction";
import isEmpty from "isEmpty";
import { CountRequests } from "../travelInformation/actions";
import { numberToNepaliCurrency } from "helpers/regex";
import { BASE_URL } from "constants/constants";
import { objectToFormData } from "object-to-formdata";
import { Arrays } from "helpers/getTextLabel";
import { loadingHeaders } from "helpers/getHeaders";

//#region pending-settlement
export const getPendingSettlement = (data) => async (dispatch) => {
  dispatch({
    type: PENDING_SETTLEMENT_LOADING,
  });
  const empId = EmployeeID();
  await CountRequests(dispatch);
  axios
    .get(
      `/v1/travel/settlements/pending?pageindex=${data.PageIndex}&pagesize=${data.PageSize}&employeeId=${empId}`, //EmployeeID()}`
      loadingHeaders
    )
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: GET_PENDING_SETTLEMENT,
          payload: response.data.Data,
          page: {
            index: data.PageIndex,
            total: !isEmpty(response.data.Data)
              ? response.data.Data[0].TotalRows
              : 0,
          },
        });
        dispatch({
          type: UPDATE_TRAVEL_COUNT,
          payload: {
            Name: "PendingSettlementRows",
            Value: !isEmpty(response.data.Data)
              ? response.data.Data[0].TotalRows
              : 0,
          },
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_PENDING_SETTLEMENT,
        payload: null,
        page: { index: 0, total: 0 },
      });
      const response = {
        data: {
          type: ADD_MESSAGE,
          Message: "Failed to get pending settlement.",
        },
      };
      toastMessage(dispatch, response);
    });
};
export const getTravelCategoryList = () => (dispatch) => {
  axios
    .get(`/v1/travel/travel-categories/list`, loadingHeaders)
    .then((response) => {
      dispatch({
        type: GET_TRAVEL_CATEGORY_LIST,
        payload: response.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: ADD_MESSAGE,
        payload: "Failed to get travel category.",
      })
    );
};

export const getTravelRequestDetails = (requestId) => (dispatch) => {
  dispatch(setSpinnerLoading(true));
  dispatch({
    type: TRAVEL_REQUEST_DETAILS_LOADING,
  });
  axios
    .get(`/v1/travel/settlements/travel-request-details/${requestId}`)
    .then((response) => {
      console.log("getBillsAndClaimsList 126", response);

      dispatch(
        getBillsAndClaimsList(response.data.Data.TravelAllowanceExpenses, 1)
      );
      dispatch(
        getOtherExpenseList(response.data.Data.TravelSettlementOtherExpensesDto)
      );
      debugger
      dispatch({
        type: GET_TRAVEL_REQUEST_DETAILS,
        payload: response.data.Data,
      });
    })
    .catch((err) => {
      dispatch(setSpinnerLoading(false));
      dispatch({
        type: ADD_MESSAGE,
        payload: "Failed to get travel request details.",
      });
    });
};
export const getAllowanceAndExpensesList = (data) => async (dispatch) => {
  dispatch(setSpinnerLoading(true));
  const { categoryId, fromDate, toDate, totalDays, totalNights } = data;

  axios
    .get(
      `/v1/travel/category-all-expense?categoryId=${categoryId}&startdate=${fromDate}
    &enddate=${toDate}&totalDays=${totalDays}&totalNights=${totalNights}`
    )
    .then((response) => {
      console.log("getBillsAndClaimsList 156", response);
      dispatch(getBillsAndClaimsList(response.data, 2));
      dispatch(setSpinnerLoading(false));
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to get category wise advance and expenses.",
        },
      };
      toastMessage(dispatch, response);
      dispatch(setSpinnerLoading(false));
    });
};

export const getOtherExpenseList = (data) => (dispatch) => {
  debugger
  dispatch({
    type: GET_OTHER_EXPENSES_LIST,
    payload: !isEmpty(data)
      ? data.map((x, i) => {
          return {
            Id: x.Id,
            ExpenseId: x.OtherExpensesRef_Id,
            AllowanceRef_Id: x.AllowanceRef_Id,
            ExpenseName: x.Name,
            Amount: x.Amount,
            Note: x.Note,
            FileList: !isEmpty(x.travelSettlementOtherExpensesDocDto)
              ? x.travelSettlementOtherExpensesDocDto.map((x) => {
                  return {
                    OriginalName: x.OriginalName,
                    DocumentPath: x.DocumentPath,
                    DocumentName: x.DocumentName,
                    expenseId: x.OtherExpensesRef_Id,
                    Id: !isEmpty(x.OtherExpensesRef_Id) ? x.OtherExpensesRef_Id : 0,
                  };
                })
              : [],
            FileUrl:
              x.travelSettlementOtherExpensesDocDto[i].DocumentPath +
              x.travelSettlementOtherExpensesDocDto[i].DocumentName,
          };
        })
      : [],

    // [
    //   {
    //     Id: 1,
    //     ExpenseName: "Car tyre change",
    //     Amount: 5000,
    //     Note: "This is test note",
    //     BillAmount: 2000,
    //     FileList: []
    //   }
    // ]
  });
};

export const getBillsAndClaimsList = (data, formatType) => (dispatch) => {
  debugger
  let payload = !isEmpty(data)
    ? formatType === 1
      ? data.map((x) => {
          return {
            Id: x.Id,
            Name: x.AllowanceName,
            ExpenseId: x.ExpenseRef_Id,
            AllowanceRef_Id: x.AllowanceRef_Id,
            Units: x.Units,
            Rate: x.Rate,
            Amount: x.Amount,
            BillCount: x.TotalBillcount,
            BillList: !isEmpty(x.TravelSettlementBillsDocuments)
              ? x.TravelSettlementBillsDocuments.map((x) => {
                  return {
                    OriginalName: x.OriginalName,
                    DocumentPath: x.DocumentPath,
                    DocumentName: x.DocumentName,
                    expenseId: x.ExpenseRef_Id,
                    Id: !isEmpty(x.ExpenseRef_Id) ? x.ExpenseRef_Id : 0,
                  };
                })
              : [],
            BillAmount: x.TotalBillAmount == 0 ? "" : "" + x.TotalBillAmount,
            IsBillRequired: x.IsBillRequired,
            CalculationMethod: x.CalculationMethod,
          };
        })
      : data.map((x) => {
          return {
            Id: x.allowance_id,
            ExpenseId: x.allowance_id,
            Name: x.allowance_name,
            AllowanceRef_Id: x.allowance_id,
            Units: x.units,
            Rate: x.rate,
            Amount: x.amount,
            BillCount: "",
            BillList: [],
            BillAmount: "",
            IsBillRequired: x.isbillrequired,
            CalculationMethod: x.calculationmethod,
          };
        })
    : [];
  dispatch({
    type: GET_BILLS_AND_CLAIMS_LIST,
    payload,
    // payload: [
    //   {
    //     Id: 1,
    //     Name: "Daily Allowance",
    //     Units: 2,
    //     Rate: 1200,
    //     Amount: 2400,
    //     BillCount: 0,
    //     BillList: [],
    //     BillAmount: ""
    //   },
    //   {
    //     Id: 2,
    //     Name: "Hotel Expense",
    //     Units: 1,
    //     Rate: 3000,
    //     Amount: 3000,
    //     BillCount: 0,
    //     BillList: [],
    //     BillAmount: ""
    //   },
    //   {
    //     Id: 3,
    //     Name: "Out of Pocket Expenses",
    //     Units: 2,
    //     Rate: 500,
    //     Amount: 1000,
    //     BillCount: 0,
    //     BillList: [],
    //     BillAmount: ""
    //   }
    // ]
  });
};

export const saveSettlementDetails = (
  data,
  summaryList,
  billAndClaims,
  otherExpenses
) => (dispatch) => {
  dispatch(setSpinnerLoading(true));
  //let fd = new FormData();

  const object = {
    /**
     * key-value mapping
     * values can be primitives or objects
     */
    TravelSettlementDto: data.TravelSettlementDto,
    TravelSettlementAllowanceExpenseDetailsDto:
      data.TravelSettlementAllowanceExpenseDetailsDto,
    TravelSettlementDestinationsDto: data.TravelSettlementDestinationsDto,
    TravelSettlementBillsDocumentsDto: data.TravelSettlementBillsDocumentsDto,
    TravelSettlementDelegatesDto: data.TravelSettlementDelegatesDto,
    TravelSettlementOtherExpensesDto: data.TravelSettlementOtherExpensesDto,
    TravelSettlementOtherExpensesDocDto:
      data.TravelSettlementOtherExpensesDocDto,
    TravelSettlementApprovalFlowHistoryDto: [],
    JobAttachment: [],
    OtherExpenseAttachment: [],
    BillAttachment: [],
    //Files: data.documentsState.fileList,
  };
  const options = {
    /**
     * include array indices in FormData keys
     * defaults to false
     */
    indices: true,

    /**
     * treat null values like undefined values and ignore them
     * defaults to false
     */
    nullsAsUndefineds: true,

    /**
     * convert true or false to 1 or 0 respectively
     * defaults to false
     */
    booleansAsIntegers: false,
  };

  const fd = objectToFormData(
    object,
    options // optional,
  );
  summaryList.map((x, i) => {
    fd.append(`JobFiles`, x);
  });
  billAndClaims.map((x) => {
    x.BillList.map((file, i) => {
      fd.append(`BillFiles`, file);
    });
  });
  otherExpenses.map((x) => {
    x.FileList.map((file, i) => {
      fd.append(`OtherExpenseFiles`, file);
    });
  });
  //fd.append("TravelSettlemenAllDtos", JSON.stringify(data));

  axios
    .post(`/v1/travel/settlements/request`, fd, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.data.Status) {
        dispatch(getPendingSettlement({ PageSize: 10, PageIndex: 0 }));
        dispatch({
          type: HANDLE_SETTLEMENT_ACTIVE,
          payload: false,
        });
      }
      dispatch(setSpinnerLoading(false));

      toastMessage(dispatch, response);
    })
    .catch((err) => {
      dispatch(setSpinnerLoading(false));
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to upload files.",
        },
      };
      toastMessage(dispatch, response);
    });
};
// Set loading state
export const setSpinnerLoading = (data) => {
  return {
    type: SET_SPINNER_LOADING,
    payload: data,
  };
};

export const handleSettlementActive = (data) => (dispatch) => {
  dispatch({
    type: HANDLE_SETTLEMENT_ACTIVE,
    payload: data,
  });
};

export const fetchTravelInformationData = (travelRequestId) => (dispatch) => {
  dispatch({
    type: IS_LOADING,
    isLoading: true,
  });
  axios
    .all([
      // axios.put(
      //   `/v1/travel/travel-information-edit?requestid=${travelRequestId}`
      // ),
      axios.get(`/v1/common/countries`),
      axios.get(`/v1/common/currencies`),
      axios.get(`/v1/common/districts`),
      axios.get(`/v1/travel/mode-of-transport`),
      axios.get(`/v1/travel/host-and-hotel`),
      axios.get(`/v1/common/employees`),
    ])
    .then(
      axios.spread(
        (
          firstResponse,
          secondResponse,
          thirdResponse,
          fourthResponse,
          fifthResponse,
          sixthResponse
        ) => {
          dispatch({
            type: GET_COUNTRY_LIST,
            payload: firstResponse.data,
          });
          dispatch({
            type: GET_CURRENCY_LIST,
            payload: secondResponse.data,
          });
          dispatch({
            type: GET_DISTRICT_LIST,
            payload: Arrays(thirdResponse.data),
          });
          dispatch({
            type: GET_MODE_OF_TRANSPORT,
            payload: Arrays(fourthResponse.data),
          });
          dispatch({
            type: GET_HOST_AND_HOTEL,
            payload: fifthResponse.data,
          });
          dispatch({
            type: GET_EMPLOYEE_LIST,
            payload: sixthResponse.data,
          });
          dispatch({
            type: IS_LOADING,
            isLoading: false,
          });
        }
      )
    )
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to get travel information data.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const cancelTravelRequest = (requestId) => (dispatch) => {
  dispatch(setSpinnerLoading(true));
  axios
    .put(`/v1/travel/settlements/request/${requestId}/cancel`)
    .then((response) => {
      if (response.data.Status) {
        dispatch({
          type: CANCEL_TRAVEL_REQUEST,
          payload: requestId,
        });
      }
    })
    .catch((err) =>
      dispatch({
        type: ADD_MESSAGE,
        payload: {
          type: "Danger",
          message: "Failed to get cancel travel request.",
          position: "bottom-right",
        },
      })
    );
};

export const removeOtherExpense = (id) => (dispatch) => {
  dispatch({
    type: REMOVE_OTHER_EXPENSE,
    payload: id,
  });
};
export const dispatchBillsAndClaimsList = (data) => (dispatch) => {
  dispatch({
    type: GET_BILLS_AND_CLAIMS_LIST,
    payload: data,
  });
};
export const dispatchOtherExpenseList = (data) => (dispatch) => {
  dispatch({
    type: GET_OTHER_EXPENSES_LIST,
    payload: data,
  });
};
//#endregion

//#region settlement-request
export const getSettlementRequest = (data) => async (dispatch) => {
  dispatch({
    type: SETTLEMENT_REQUEST_LOADING,
  });
  await CountRequests(dispatch);

  axios
    .get(
      `/v1/travel/settlements/request?pageindex=${data.PageIndex}&pagesize=${
        data.PageSize
      }&employeeId=${EmployeeID()}&empName=${
        data.SearchEmployeeText
      }&branchId=${data.BranchId}&fromDate=${data.FromDate}&toDate=${
        data.ToDate
      }&stepName=${data.StepName}`, //EmployeeID()}`
      loadingHeaders
    )
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: GET_SETTLEMENT_REQUESTS,
          payload: response.data.Data,
          page: {
            index: data.PageIndex,
            total: !isEmpty(response.data.Data)
              ? response.data.Data[0].TotalRows
              : 0,
          },
        });
        dispatch({
          type: UPDATE_TRAVEL_COUNT,
          payload: {
            Name: "TotalSettlementRows",
            Value: !isEmpty(response.data.Data)
              ? response.data.Data[0].TotalRows
              : 0,
          },
        });
      }
    })
    .catch((err) => {
      const response = {
        data: {
          type: ADD_MESSAGE,
          Message: "Failed to get settlement requests.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const GetSettlementRequest_ExcelExport = (data) => async (dispatch) => {
  dispatch({
    type: GET_SETTLEMENT_REQUESTS_EXCEL_EXPORT,
    payload: null,
  });

  let pageIndex = 1;
  let pageSize = -1;
  let employeeId = EmployeeID();
  let {
    searchEmployeeText,
    branchId,
    searchFlowSteps,
    flag,
    fromDate,
    toDate,
  } = data;
  if (isEmpty(searchEmployeeText)) {
    searchEmployeeText = -1;
  }
  await axios
    .get(
      `/v1/travel/settlements/request?pageindex=${pageIndex}&pagesize=${pageSize}
      &employeeId=${employeeId}&empName=${searchEmployeeText}
      &branchId=${branchId}&fromDate=${fromDate}&toDate=${toDate}${
        !isEmpty(flag) ? `&flag=` + flag : ""
      }
      &stepName=${searchFlowSteps}`
    )
    .then(async (response) => {
      if (response.data.Status) {
        let filteredData = [];
        Promise.all(
          response.data.Data.map((data) => {
            filteredData.push({
              name: !isEmpty(data.Name)
                ? data.Name + " (" + data.ICardNo + ")"
                : "",
              position: data.Branch + " ," + data.Desingnation,
              placeOfTravel: data.PlaceName,
              dates: data.TravelStartEndDate,
              returnedOn: data.ReturnDate,
              advance: "NRP " + numberToNepaliCurrency(data.AdvanceTakenAmount),
              claim: "NRP " + numberToNepaliCurrency(data.ClaimAmount),
              approvedBy: !isEmpty(data.ApprovedBy) ? data.ApprovedBy : "",
            });
          })
        );
        dispatch({
          type: GET_SETTLEMENT_REQUESTS_EXCEL_EXPORT,
          payload: filteredData,
        });
      } else {
        toastMessage(dispatch, response.data);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          type: ADD_MESSAGE,
          Message: "Failed to get settlement requests for export.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const getApprovalAuthorities = (StatusId, empId) => async (dispatch) => {
  dispatch({ type: SETTLEMENT_APPROVAL_AUTHORITIES_LOADING });
  // const { pageNumber, pageSize } = data;
  // const empId = EmployeeID();

  axios
    .get(
      `/v1/travel/settlements/approval-authorities?currentStep=${StatusId}&employeeId=${empId}`
    )
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: GET_SETTLEMENT_APPROVAL_AUTHORITIES,
          payload: response.data,
        });
      } else {
        const res = {
          data: {
            MessageType: "Danger",
            Message: "Failed to get my approval authorities.",
          },
        };
        toastMessage(dispatch, res);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to get my approval authorities.",
        },
      };
      toastMessage(dispatch, response);
      dispatch({
        type: GET_SETTLEMENT_APPROVAL_AUTHORITIES,
        payload: null,
      });
    });
};

export const getApprovalHistory = (settlementId) => async (dispatch) => {
  dispatch({ type: SETTLEMENT_APPROVAL_HISTORY_LOADING });
  // const { pageNumber, pageSize } = data;
  axios
    .get(`v1/travel/settlements/${settlementId}/approval-history`)
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: GET_SETTLEMENT_APPROVAL_HISTORY,
          payload: response.data.Data,
        });
      } else {
        const res = {
          data: {
            MessageType: "Danger",
            Message: "Failed to get my approval authorities.",
          },
        };
        toastMessage(dispatch, res);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to get travel approval history.",
        },
      };
      toastMessage(dispatch, response);
      dispatch({
        type: GET_SETTLEMENT_APPROVAL_HISTORY,
        payload: null,
      });
    });
};

export const approveSettlementRequest = (data) => async (dispatch) => {
  dispatch(setSpinnerLoading(true));
  await axios
    .put(
      `/v1/travel/settlements/request/approve?settlementId=${data.SettlementId}`,
      data
    )
    .then((response) => {
      if (response.data.Status) {
        //dispatch(getSettlementRequest());
        dispatch({
          type: HANDLE_SETTLEMENT_SAVE,
          payload: true,
        });
      }
      dispatch(setSpinnerLoading(false));
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      dispatch(setSpinnerLoading(false));
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to approve settlement requests.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const getSettlementReport = (SettlementId) => async (dispatch) => {
  dispatch({ type: SETTLEMENT_REPORT_LOADING });
  dispatch(setSpinnerLoading(true));
  axios
    .get(`/v1/travel/settlements/${SettlementId}/report`)
    .then((response) => {
      dispatch({
        type: GET_SETTLEMENT_REPORT,
        payload: response.data.Data,
      });
      dispatch(
        getSettlementReportBillAndClaims(
          response.data.Data.TravelSettlementAllowanceExpenseDetailsDto
        )
      );
      dispatch(
        getSettlementReportOtherExpenses(
          response.data.Data.TravelSettlementOtherExpensesDto
        )
      );
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to get settlement audit log.",
        },
      };
      toastMessage(dispatch, response);
      dispatch({
        type: GET_SETTLEMENT_REPORT,
        payload: null,
      });
    });
};

export const getSettlementAuditLog = (SettlementId) => async (dispatch) => {
  dispatch({ type: SETTLEMENT_AUDIT_LOG_LOADING });

  axios
    .get(`/v1/travel/settlements/${SettlementId}/audit-log`)
    .then((response) => {
      dispatch({
        type: GET_SETTLEMENT_AUDIT_LOG,
        payload: response.data.Data,
      });
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to get settlement audit log.",
        },
      };
      toastMessage(dispatch, response);
      dispatch({
        type: GET_SETTLEMENT_AUDIT_LOG,
        payload: null,
      });
    });
};

export const getSettlementReportBillAndClaims = (data) => (dispatch) => {
  debugger
  const payload = data.map((x) => {
    return {
      Id: x.Id,
      AllowanceRef_Id: x.AllowanceRef_Id,
      SettlementId: x.SettlementRef_Id,
      Name: x.AllowanceName,
      Units: x.Units,
      Rate: x.Rate,
      Amount: x.Amount,
      BillCount: x.BillCount,
      BillList: [],
      BillAmount: x.BillAmount,
      IsBillRequired: x.IsBillRequired,
      CalculationMethod: x.CalculationMethod,
    };
  });
  dispatch({
    type: GET_BILLS_AND_CLAIMS_LIST,
    payload,
  });
};

export const getSettlementReportOtherExpenses = (data) => (dispatch) => {
  dispatch({
    type: GET_OTHER_EXPENSES_LIST,
    payload: !isEmpty(data)
      ? data.map((x) => {
          return {
            ExpenseName: x.Name,
            Amount: x.Amount,
          };
        })
      : [],
  });
};

export const saveSettlementReportDetails = (data) => async (dispatch) => {
  dispatch(setSpinnerLoading(true));
  // let fd = new FormData();

  // fd.append("TravelSettlemenAllDtos", JSON.stringify(data));
  const object = {
    /**
     * key-value mapping
     * values can be primitives or objects
     */
    TravelSettlementDto: data.TravelSettlementDto,
    TravelSettlementAllowanceExpenseDetailsDto:
      data.TravelSettlementAllowanceExpenseDetailsDto,
    TravelSettlementDestinationsDto: data.TravelSettlementDestinationsDto,
    TravelSettlementBillsDocumentsDto: data.TravelSettlementBillsDocumentsDto,
    TravelSettlementDelegatesDto: data.TravelSettlementDelegatesDto,
    TravelSettlementOtherExpensesDto: data.TravelSettlementOtherExpensesDto,
    TravelSettlementOtherExpensesDocDto:
      data.TravelSettlementOtherExpensesDocDto,
    TravelSettlementApprovalFlowHistoryDto: [],
    JobAttachment: [],
    OtherExpenseAttachment: [],
    BillAttachment: [],
    //Files: data.documentsState.fileList,
  };
  const options = {
    /**
     * include array indices in FormData keys
     * defaults to false
     */
    indices: true,

    /**
     * treat null values like undefined values and ignore them
     * defaults to false
     */
    nullsAsUndefineds: true,

    /**
     * convert true or false to 1 or 0 respectively
     * defaults to false
     */
    booleansAsIntegers: false,
  };

  const fd = objectToFormData(
    object,
    options // optional,
  );

  await axios
    .put(`/v1/travel/settlements/request`, fd, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((response) => {
      // dispatch(
      //   getSettlementRequest({
      //     PageSize: 10,
      //     PageIndex: 0,
      //     SearchEmployeeText: -1,
      //     BranchId: -1
      //   })
      // );

      dispatch({
        type: HANDLE_SETTLEMENT_REPORT_ACTIVE,
        payload: false,
      });
      dispatch({
        type: HANDLE_SETTLEMENT_SAVE,
        payload: true,
      });
      data.getSettlementRequest();
      toastMessage(dispatch, response);
      dispatch(setSpinnerLoading(false));
    })
    .catch((err) => {
      dispatch(setSpinnerLoading(false));
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to save settlement.",
        },
      };
      toastMessage(dispatch, response);
    });
};
export const handleSettlementReportActive = (data) => (dispatch) => {
  dispatch({
    type: HANDLE_SETTLEMENT_REPORT_ACTIVE,
    payload: data,
  });
};

export const GetSettlementRequestReport = (id) => async (dispatch) => {
  dispatch({
    type: GET_SETTLEMENT_REQUEST_REPORT_PDF,
    payload: null,
  });
  await axios
    .get(`/v1/travel/settlements/${id}/print`)
    .then((response) => {
      if (response.data.Status) {
        dispatch({
          type: GET_SETTLEMENT_REQUEST_REPORT_PDF,
          payload: response.data.Data,
        });
      } else {
        toastMessage(dispatch, response.data);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to get settlement request report.",
        },
      };
      toastMessage(dispatch, response.data);
    });
};
//#endregion
