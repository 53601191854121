import MomentUtils from "@date-io/moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DataSelect from "components/dataselect";
import FormGroup from "components/form";
import { ValidationComponent } from "helpers/getComponents";
import isEmpty from "isEmpty";
import React, { useEffect } from "react";
import { connect } from "react-redux";
// import DatePicker from '../../../../Components/Calendar/DatePicker'
import { getJobCategory } from "../helpers/actions";
import Form from "components/form";
import { Date_Format } from "helpers/dateFormat";

function PositionalForm({
  data,
  onChange,
  jobCategory,
  getJobCategory,
  branch,
  levels,
  department,
  jobStatus,
  salaryPayDuration,
  errors,
  companyInformation,
}) {
  useEffect(() => {
    getJobCategory();
  }, []);
  const { loading, value: catValue } = jobCategory;
  const onDataChange = (name, value) => {
    // const { name, value } = e.target;
    console.log("onDataChange", name, value);
    onChange(name, value);
  };
  const onSelectChange = (name, value) => {
    if (value) onChange(name, value.value);
    else onChange(name, null);
  };
  const onDateChange = (name, value) => {
    onChange(name, value);
  };
  const inputWidth = "calc(100% - 225px)";
  const getValue = (data, val) => {
    let a = data?.value?.find((item) => item.Value == val);
    if (!isEmpty(a)) {
      return { label: a.Text, value: a.Value };
    }
    return [];
  };

  const getDropDownValues = (data) => {
    let a = [];
    let d = data?.value?.forEach((item) =>
      a.push({ label: item.Text, value: item.Value })
    );
    return a;
  };

  const getKeyValue = (data, val) => {
    let a = data && data.value && data?.value?.find((item) => item.Key === val);
    if (!isEmpty(a)) {
      return { label: a.Value, value: a.Key };
    }
    return [];
  };

  const getAutoSelectKeyValue = (data, val) => {
    return data ? data.find((item) => item.value == val) : null;
  };
  // let formattedStart = Date_Format(data.StartDateEng);
  // let formattedEnd = Date_Format(data.EndDateEng);
  return (
    <div className="form-block">
      <div className="form-block__title">
        <h3>Position Details</h3>
      </div>
      <div className="form-block__form">
        <FormGroup
          name="InternalJobTitle"
          value={data.InternalJobTitle}
          label="Internal Job Title"
          onChange={onDataChange}
          className="inline-form-group"
          error={errors.InternalJobTitle}
        />
        <FormGroup
          name="InternalId"
          value={data.InternalId}
          disabled
          label="Internal ID"
          onChange={onDataChange}
          className="inline-form-group"
        />
        <FormGroup
          name="ExternalJobTitle"
          value={data.ExternalJobTitle}
          label="External Job Title"
          onChange={onDataChange}
          className="inline-form-group"
          error={errors.ExternalJobTitle}
        />
        <ValidationComponent
          rootClassName="inline-form-group select"
          error={errors.LevelId}
          errorClassName="error__message"
        >
          <label htmlFor="level">Level</label>
          <FormGroup
            formName="reactselect"
            onChange={onSelectChange}
            name="LevelId"
            value={getValue(levels, data.LevelId)}
            options={getDropDownValues(levels)}
            width="300px"
            actionType="levels"
          />
        </ValidationComponent>
        <ValidationComponent
          rootClassName="inline-form-group select"
          error={errors.DivisionId}
          errorClassName="error__message"
        >
          <label htmlFor="division">Division</label>
          <FormGroup
            formName="reactselect"
            onChange={onSelectChange}
            name="DivisionId"
            options={getDropDownValues(department)}
            value={getValue(department, data.DivisionId)}
            width="300px"
            actionType="department"
          />
        </ValidationComponent>
        <ValidationComponent
          rootClassName="inline-form-group select"
          error={errors.BranchId}
          errorClassName="error__message"
        >
          <label htmlFor="level">Branch</label>
          <FormGroup
            formName="reactselect"
            onChange={onSelectChange}
            name="BranchId"
            value={getValue(branch, data.BranchId)}
            options={getDropDownValues(branch)}
            width="300px"
            actionType="branch"
          />
        </ValidationComponent>
        <div className="inline-form-group select">
          <label htmlFor="level"></label>
          <div style={{ width: inputWidth }}>
            <FormGroup
              formName="checkgroup"
              name="IsAnywhere"
              value={data.IsAnywhere}
              label="Anywhere"
              className="IsAnywhere-check"
              checked={data.IsAnywhere}
              onChange={(e) =>
                onChange("IsAnywhere", e.target.checked ? true : false)
              }
              labelPosition={"right"}
            />
             <FormGroup
              formName="checkgroup"
              name="EnableEmployeeId"
              value={data.EnableEmployeeId}
              label="Enable Employee Id"
              className="IsAnywhere-check"
              checked={data.EnableEmployeeId}
              onChange={(e) =>
                onChange("EnableEmployeeId", e.target.checked ? true : false)
              }
              labelPosition={"right"}
            />
          </div>
        </div>
        <div className="hr"></div>
        <ValidationComponent
          rootClassName="inline-form-group select"
          error={errors.StartDateEng}
          errorClassName="error__message"
        >
          <label htmlFor="level">Job Start Date</label>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className="pickers recruit-picker">
              <KeyboardDatePicker
                value={data.StartDateEng}
                onChange={(date) => onChange("StartDateEng", date)}
              />
              {/* <Form
                    formName="customdateinput"
                    name="StartDateEng"
                    onChange={(name, value) =>
                      onChange(name, value)
                    }
                    value={data.StartDateEng}
                    placeholder="Job Upto.."
                    // isNepali={!companyInformation?.IsEnglishDate}
                    // isTextInNepali={!companyInformation?.IsEnglishDate}
                  /> */}
            </div>
          </MuiPickersUtilsProvider>
        </ValidationComponent>
        <ValidationComponent
          rootClassName="inline-form-group select"
          error={errors.EndDateEng}
          errorClassName="error__message"
        >
          <label htmlFor="level">Job End Date</label>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className="pickers recruit-picker">
              {/* <Form
                    formName="customdateinput"
                    name="EndDateEng"
                    onChange={(name, value) =>
                      onChange(name, value)
                    }
                    value={data.EndDateEng}
                    placeholder="Job Upto.."
                    // isNepali={!companyInformation?.IsEnglishDate}
                    // isTextInNepali={!companyInformation?.IsEnglishDate}
                  /> */}
              <KeyboardDatePicker
                value={data.EndDateEng}
                onChange={(date) => onChange("EndDateEng", date)}
              />
            </div>
          </MuiPickersUtilsProvider>
        </ValidationComponent>
        <div className="hr"></div>
        <ValidationComponent
          rootClassName="inline-form-group select"
          error={errors.EmployementTypeId}
          errorClassName="error__message"
        >
          <FormGroup
            formName="reactselect"
            onChange={onSelectChange}
            name="EmployementTypeId"
            value={getAutoSelectKeyValue(jobStatus, data.EmployementTypeId)}
            options={jobStatus}
            width="500px"
            //error={errors.LevelId}
            label="Job Type"
            className="inline-form-group"
          />
        </ValidationComponent>
        <ValidationComponent
          rootClassName="inline-form-group select"
          error={errors.CategoryId}
          errorClassName="error__message"
        >
          <FormGroup
            formName="reactselect"
            onChange={onSelectChange}
            name="CategoryId"
            value={getKeyValue(jobCategory, data.CategoryId)}
            options={catValue}
            width="500px"
            isArray
            loading={loading}
            loadingType="skeleton"
            isArrayKeys
            label="Category"
            //error={errors.LevelId}
            className="inline-form-group"
          />
        </ValidationComponent>
        <FormGroup
          name="NumberOfOpenings"
          value={data.NumberOfOpenings}
          label="Openings"
          type="number"
          onChange={onDataChange}
          className="inline-form-group"
          error={errors.NumberOfOpenings}
          className="inline-form-group"
        />
        <div className="inline-form-group select">
          <label htmlFor="IsPayShow">Pay</label>
          <div style={{ width: inputWidth }}>
            <FormGroup
              formName="checkgroup"
              name="IsPayShow"
              value={!data.IsPayShow}
              label="Do not show"
              className="IsExperience-check"
              checked={!data.IsPayShow}
              onChange={(e) =>
                onDataChange("IsPayShow", e.target.checked ? false : true)
              }
              labelPosition={"right"}
            />
          </div>
        </div>
        {data.IsPayShow ? (
          <div className="inline-form-group select">
            <label htmlFor="pay"></label>
            <div>
              <div className="flex small-inputs">
                <input
                  type="number"
                  className="form-group__input"
                  onChange={(e) =>
                    onDataChange("SalaryRangeFrom", e.target.value)
                  }
                  value={data.SalaryRangeFrom}
                  name="SalaryRangeFrom"
                />
                <span className="to">to</span>
                <input
                  type="number"
                  className="form-group__input"
                  onChange={(e) =>
                    onDataChange("SalaryRangeTo", e.target.value)
                  }
                  value={data.SalaryRangeTo}
                  name="SalaryRangeTo"
                />
              </div>
              <FormGroup
                formName="reactselect"
                onChange={onSelectChange}
                name="SalaryDurationType"
                value={getAutoSelectKeyValue(
                  salaryPayDuration,
                  data.SalaryDurationType
                )}
                options={salaryPayDuration}
                width="140px"
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    jobCategory: state.createJobReducer.jobCategory,
    branch: state.selectReducer.branch,
    levels: state.selectReducer.levels,
    department: state.selectReducer.department,
    jobStatus: state.commonReducer.jobStatusList,
    salaryPayDuration: state.commonReducer.salaryPayDurationList,
    companyInformation: state.commonReducer.companyInformation,
  };
};
// connect
export default connect(mapStateToProps, { getJobCategory })(PositionalForm);
