import React from 'react'
import Table from 'components/customTable';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs'
import Pagination from "components/common/pagination";

var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)


const LastActivityDate = ({ value }) => {
    return (
        <div className="">
            {
                //@ts-ignore
                value && dayjs(value).fromNow(true)
            }
            {
                value && ' ago'
            }
        </div>
    )
}

function TaskReportTable(props) {
    const {
        isComplete,
        handleRowChange,
        handleIndexChange,
        pageIndex,
        pageSize,
    } = props;
    const { boardDetails } = useSelector((state: any) => state.taskReducer)
    let beforecolumns = [
        {
            Header: 'Board Name',
            accessor: 'BoardName',
            width: 200,
            isSort: true
        },
        {
            Header: 'Owner',
            accessor: 'Owner',
            width: 140,
            isSort: true
        },
        {
            Header: 'Members',
            accessor: 'TotalMembers',
            width: 125,
        },
        {
            Header: 'Start Date',
            accessor: 'StartDate',
            width: 150,
            isSort: true
        },
        {
            Header: 'End Date',
            accessor: 'EndDate',
            width: 150,
            isSort: true
        },
        {
            Header: 'Progress',
            accessor: 'Progress',
            width: 140,
        },
        {
            Header: 'To Do',
            accessor: 'ToDo',
            width: 140,
        },
        {
            Header: 'In Progress',
            accessor: 'InProgress',
            width: 140,
        },
        {
            Header: 'Completed',
            accessor: 'Completed',
            width: 140,
        },
        {
            Header: 'On Hold',
            accessor: 'OnHold',
            width: 140,
        },
        {
            Header: 'Cancelled',
            accessor: 'Cancelled',
            width: 140,
        },
        {
            Header: 'Last Activity',
            accessor: 'LastActivityDate',
            width: 140,
            Cell: LastActivityDate
        },
    ];
    const completeCol = [
        {
            Header: 'Completion Date',
            accessor: 'CompletedDate',
            width: 170,
        },
        {
            Header: 'Efficiency',
            accessor: 'Efficiency',
            width: 150,
        },
    ]
    console.log({ isComplete })
    if (isComplete) {
        beforecolumns.splice(4, 0, ...completeCol);
    }

    return (
        <div className="tasksReport-container">

            <div className="tasksReport-table">
                <Table
                    isFixedWidth
                    columns={beforecolumns} // Columns Settings
                    data={boardDetails} // Data list
                />
            </div>
            <div className="">
                {boardDetails && boardDetails.length > 0 && (
                    <Pagination
                        handleRowChange={handleRowChange}
                        handleIndexChange={handleIndexChange}
                        pageIndex={pageIndex}
                        rowsPerPage={pageSize}
                        total={boardDetails[0].TotalRows}
                    />
                )}
            </div>
        </div>
    )
}

export default TaskReportTable
