import {
  GET_INTERVIEW_MEDIUM,
  GET_INTERVIEW_MEDIUM_LOADING,
  SEND_INTERVIEW_INVITATION_LOADING,
  GET_SCHEDULED_INTERVIEW,
  GET_SCHEDULED_INTERVIEW_LOADING,
} from "store/types";
import { api, get, post, deletion, get_by_id } from "networkService";
import { toastMessage } from "actions/validateAction";

export const GetInterviewMedium = () => async (dispatch) => {
  try {
    dispatch({ type: GET_INTERVIEW_MEDIUM_LOADING, payload: true });
    const res: any = await get(
      api.recruitment.interview.jobInterViewMedium,
      dispatch,
      {}
    );

    dispatch({ type: GET_INTERVIEW_MEDIUM, payload: res.Data });
  } catch (ex) {
    dispatch({ type: GET_INTERVIEW_MEDIUM_LOADING, payload: false });
  }
};

export const PostScheduleInterview = (param, onSuccess) => async (dispatch) => {
  dispatch({ type: SEND_INTERVIEW_INVITATION_LOADING, payload: true });
  const res: any = await post(
    api.recruitment.interview.schedule,
    dispatch,
    param
  );
  dispatch({ type: SEND_INTERVIEW_INVITATION_LOADING, payload: false });
  onSuccess && onSuccess(res.Data);
};

export const GetScheduledInterview = (param) => async (dispatch) => {
  //get-schedule-candidate-interview
  try {
    dispatch({ type: GET_SCHEDULED_INTERVIEW_LOADING, payload: true });

    const res: any = await get(
      api.recruitment.interview.getSchedule,
      dispatch,
      param
    );

    dispatch({ type: GET_SCHEDULED_INTERVIEW, payload: res.Data });
  } catch (ex) {
    dispatch({ type: GET_SCHEDULED_INTERVIEW_LOADING, payload: false });
  }
};

export const GetScheduledInterviewTemplate = (id) => async (dispatch) => {
  const res: any = await get_by_id(
    api.recruitment.email.getScheduledInterviewTemplate,
    dispatch,
    id
  );

  return res.Data;
};
