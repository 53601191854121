import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton';


function CardSkeleton(props) {
    const renderCard = () => {
        switch (props.cardType) {
            case 'leave':
                return LeaveCard;
            case 'employee':
                return EmployeeCard;
            case 'block':
                return BlockCard;
            case 'travel':
                return TravelCard;
            case 'dashboard':
                return Dashboard;
            case 'dashboard2':
                return Dashboard2;
            default:
                return LeaveCard;
        }
    }
    const Component = renderCard()
    return <Component {...props} />
}

const LeaveCard = () => {
    return (
        <div className="skeleton-card">
            <Skeleton animation="wave" height={20} width="40%" style={{ marginBottom: 6, borderRadius: 2 }} />
            <div className="skeleton-card__flex">
                <Skeleton animation="wave" height={15} width="100px" />
                <Skeleton animation="wave" height={15} width="120px" />
                <Skeleton animation="wave" height={15} width="50px" />
            </div>
            <div className="skeleton-card__flex">
                <Skeleton animation="wave" height={15} width="50px" />
                <div className="skeleton-card__avatar">
                    <Skeleton animation="wave" variant="circle" width={25} height={25} />
                    <Skeleton animation="wave" height={15} width="100px" />
                </div>

            </div>
            <div className="skeleton-card__flex">
                <Skeleton animation="wave" height={15} width="50px" />
                <div className="skeleton-card__avatar">
                    <Skeleton animation="wave" variant="circle" width={25} height={25} />
                    <Skeleton animation="wave" height={15} width="100px" />
                </div>
            </div>
        </div>
    )
}

const Dashboard = (props) =>{
    const {noRows} = props;
    return(
        <div className="skeleton-card__container flex">
            {
                [...Array(noRows)].map((e, i) => (
                    <div className="skeleton-card skeleton-card-dashboard">
                        <div className="skeleton-card-dashboard__header">
                            <Skeleton animation="wave" height={27} width="120px" />
                        </div>
                        <div className="skeleton-card-dashboard__body">
                            <Skeleton animation="wave" variant="circle" width={40} height={40} />
                            <div className="">
                                <Skeleton animation="wave" height={45} width="25px" />
                                <Skeleton animation="wave" height={22} width="60px" />
                            </div>
                            
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

const Dashboard2 = (props) =>{
    const {noRows} = props;
    return(
        <div className="skeleton-card__container flex">
                {
                    [...Array(noRows)].map((e, i) => (
                        <div className="skeleton-card skeleton-card-dashboard">
                            <div className="skeleton-card-dashboard__header">
                                <Skeleton animation="wave" height={20} width="60px" />
                                <Skeleton animation="wave" variant="circle" width={25} height={25} />
                            </div>
                            <div className="skeleton-card-dashboard__body">
                                <div className="">
                                    <Skeleton animation="wave" height={20} width="90px" />
                                    <Skeleton animation="wave" height={20} width="120px" />
                                </div>
                                <Skeleton animation="wave" variant="circle" width={25} height={25} />
                            </div>
                        </div>
                    ))
                }
            </div>
    )
}

const EmployeeCard = () => {
    return (
        <div className="skeleton-card skeleton-card-employee">
            <div className="skeleton-card-employee__avatar">
                <Skeleton animation="wave" variant="circle" width={75} height={75} />
            </div>
            <Skeleton animation="wave" height={27} width="120px" />
            <Skeleton animation="wave" height={20} width="80px" />
            <Skeleton animation="wave" height={16} width="60px" />
            <Skeleton animation="wave" height={16} width="150px" />
            <Skeleton animation="wave" height={30} width="70px" style={{ marginTop: 30 }} />
        </div>
    )
}

export const BlockCard = (props) => {
    const { noRows } = props;
    return (
        <div className="skeleton-card skeleton-card-block">
            {
                [...Array(noRows)].map((e, i) => (
                    <div key={e} className="skeleton-card__row">
                        <div className="skeleton-card__label">
                            <Skeleton animation="wave" height={20} width="100px" />
                        </div>
                        <div className="skeleton-card__value">
                            <Skeleton animation="wave" height={20} width="150px" />
                        </div>
                    </div>
                )
                )
            }
        </div>
    )
}

export const TravelCard = (props: any) => {
    const { noRows } = props;
    return (
        <div className="skeleton-card__container">
            {
                [...Array(noRows)].map((e, i) => (
                    <div className="skeleton-card skeleton-card-travel">
                        <div className="skeleton-card-travel__header">
                            <div className="skeleton-card-travel__header-col">
                                <div className="skeleton-card-travel__header-name">
                                    <Skeleton animation="wave" variant="circle" width={50} height={50} />
                                    <div className="">
                                        <Skeleton animation="wave" height={25} width="150px" />
                                        <Skeleton animation="wave" height={22} width="120px" />
                                    </div>
                                </div>
                            </div>
                            <div className="skeleton-card-travel__header-col">
                                <Skeleton animation="wave" height={22} width="130px" />
                            </div>
                            <div className="skeleton-card-travel__header-col">
                                <Skeleton animation="wave" height={22} width="170px" />
                            </div>
                            <div className="skeleton-card-travel__header-col">
                                <Skeleton animation="wave" height={22} width="140px" />
                            </div>
                            <div className="skeleton-card-travel__header-col">
                                <Skeleton animation="wave" height={45} width="80px" style={{ borderRadius: '10rem' }} />
                            </div>
                        </div>
                        <div className="skeleton-card-travel__body">
                            <div className="space-between">
                                <div className="">
                                    <Skeleton animation="wave" height={20} width="120px" />
                                    <Skeleton animation="wave" height={20} width="150px" />
                                    <Skeleton animation="wave" height={20} width="120px" />
                                </div>
                                <div className="">
                                    <Skeleton animation="wave" height={20} width="200px" />
                                    <Skeleton animation="wave" height={20} width="200px" />
                                </div>
                            </div>
                        </div>
                        <div className="skeleton-card-travel__footer">
                            <div className="space-between">
                                <div className="flex">
                                    <Skeleton animation="wave" height={50} width="100px" style={{ marginRight: 10 }} />
                                    <Skeleton animation="wave" height={50} width="100px" />
                                </div>
                                <div className="flex">
                                    <div className="flex">
                                        <Skeleton animation="wave" variant="circle" width={32} height={32} />
                                        <Skeleton animation="wave" height={20} width="90px" />
                                    </div>
                                    <div className="flex">
                                        <Skeleton animation="wave" variant="circle" width={32} height={32} />
                                        <Skeleton animation="wave" height={20} width="90px" />
                                    </div>
                                    <div className="flex">
                                        <Skeleton animation="wave" variant="circle" width={32} height={32} />
                                        <Skeleton animation="wave" height={20} width="90px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>

    )
}

CardSkeleton.defaultProps = {
    cardType: 'leave',
    noRows: 6
}

export default CardSkeleton
