import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {Table,TableBody,TableCell,TableHead,TableRow} from '@material-ui/core';
import isEmpty from 'isEmpty';
import PropTypes from 'prop-types';
import { Arrays } from 'helpers/getTextLabel';

export const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#F5F5F8',
      color: '#353F5A',
      padding:'10px 20px'
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
export const StyledTableRow = withStyles((theme) => ({
    root: {
        padding:'10px 20px',
        backgroundColor:'#fff'
    //   '&:nth-of-type(odd)': {
    //     backgroundColor: theme.palette.background.default,
    //   },
    },
  }))(TableRow);

export const CustomTableBody = (props) =>{
    
    return (
        <TableBody {...props}>
            <RenderRows {...props} />
        </TableBody>
    )
}

const RenderRows = ({children,...rest})=>{
    if(!isEmpty(rest.template)){
        return (
            children && children.map(({props:{children:childs,...restProps},...rest})=>{
                return <StyledTableRow {...rest} >
                    <RenderColumn children={childs} {...rest} />
                </StyledTableRow>
            })
        )
    }
    return children
}

const RenderColumn = ({children,...rest}) =>{
    if(children){
        return (
            children && children.map(({props:{children:childs,...restProps},...rest})=>{
                return <StyledTableCell {...rest} >
                    {childs}
                </StyledTableCell>
            })
        )
    }
    return null
}

export const CustomTableHeader = ({headings}) =>{
    if(headings){
        return(
            <TableHead>
                <TableRow>
                    {
                        headings.map(item=><StyledTableCell key={item}>{item}</StyledTableCell>)
                    }
                </TableRow>
            </TableHead>
        );
    }
    return null 
}

CustomTableHeader.propTypes = {
    headings:PropTypes.string,
}

const RenderData  = (props) =>{
    if(props.data){
        return (
            <CustomTableBody {...props}>
                {
                    props.data.map(props.template)
                }
            </CustomTableBody>
        )
    }
    return null;
}

export const CustomTable = ({children,headings,data,...rest}) =>{
    return (
        <Table {...rest}>
            {
                !isEmpty(headings) ? <CustomTableHeader headings={headings} /> : null
            }
            {!isEmpty(data)?<RenderData {...rest} data={data} />:children}
        </Table>
    )
}

//@ts-ignore
CustomTable.propTypes = {
    headings:PropTypes.string,
    data:PropTypes.object,
    children:PropTypes.any,
    template:PropTypes.func,
}

function index() {
    return (
        <div>
            Table
        </div>
    )
}

export default index;