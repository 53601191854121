import React from "react";
import CreatableSelect from 'react-select/creatable';

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: "white" }),
    multiValue: (styles, { data }) => {
        return {
            ...styles,
            borderRadius:'12rem',
            backgroundColor: '#2680c2'
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: '#fff',
        padding: "6px",
        paddingLeft: "10px"
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        borderRadius:'100%',
        width: 20,
        height: 20,
        color: "#2680c2",
        background: "#fff",
        padding:0,
        justifyContent:'center',
        margin:"auto 6px",
        ":hover": {
            backgroundColor: '#f1f1f1',
            color: "#2680c2"
        }
    })
};

function Tags({ data, onChange }) {
    const onSelectChange = (name, value) => {
        // onChange(name, value);
        var a;
        let val = value.map(item=>item.label)
        onChange(name, val.join(","));
    };
    const tagsData = data && data.split(",").map(tag=>{
        return {label:tag,value:tag}
    })
    
    return (
        <div className="form-block">
            <div className="form-block__title">
                <h3>Tags</h3>
            </div>
            <div className="form-block__form">
                <div style={{ maxWidth: 500 }}>
                    <CreatableSelect
                        defaultValue={tagsData}
                        isMulti
                        name="tags"
                        onChange={(value)=>onSelectChange('tags',value)}
                        options={[
                            { label: "Tag", value: 'Tag' },
                            { label: "Tag2", value: 'Tag2' },
                            { label: "Tag3", value: 'Tag3' }
                        ]}
                        className="tags-multi-select"
                        styles={colourStyles}
                        classNamePrefix="select"
                    />
                </div>
            </div>
        </div>
    );
}

export default Tags;
