import isEmpty from "helpers/isEmpty";
import React from "react";

function ExperieceCell(props) {
  const { data } = props || {};

  if (!data) return null;
  if (isEmpty(data.ExperienceInYears) && isEmpty(data.ExperienceInMonths))
    return null;

  return (
    <div>
      <span>{data?.ExperienceInYears}</span>:
      <span>{data?.ExperienceInMonths} </span> |
      <span> {data?.TotalOrgWorked}</span>
    </div>
  );
}

export default ExperieceCell;
