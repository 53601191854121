import React, { Component } from "react";
// import InputGroup from "../../../../Components/Form/InputGroup";
// import CustomDateRange from "../../../../components/customdaterange";

import Form from "components/form";

export class SearchAndFilter extends Component {
  render() {
    const {
      searchClient,
      searchSubmitter,
      fromDate,
      toDate,
      searchActivityType,
      activeActivityType,
    } = this.props.parentState;
    return (
      <div className="filters">
        <div className="filters-grid">
          {/* <InputGroup
            name="searchClient"
            value={searchClient}
            onChange={this.props.handleInput}
            placeholder="Search Client"
          /> */}
          <Form
            formName="textinput"
            name="searchClient"
            value={searchClient}
            onChange={this.props.handleInput}
            placeholder="Search Client"
          />
        </div>

        <div className="filters-grid">
          <Form
            formName="textinput"
            name="searchSubmitter"
            value={searchSubmitter}
            onChange={this.props.handleInput}
            placeholder="Search Submitter"
          />
        </div>

        <div className="filters-grid">
          {/* <InputGroup
            name="searchActivityType"
            value={searchActivityType}
            onChange={this.props.handleInput}
            placeholder="Search Activity Type"
          /> */}

          <Form
            formName="textinput"
            name="searchActivityType"
            value={searchActivityType}
            onChange={this.props.handleInput}
            placeholder="Search Activity Type"
          />
        </div>

        <div className="filters-grid">
          <Form
            formName="reactdaterange"
            startDate={fromDate}
            endDate={toDate}
            onChange={this.props.handleDateRangePicker}
            placeholder="Date filter"
          />
          {/* <div style={{ width: "220px" }}>
            <CustomDateRange
              startDate={fromDate}
              endDate={toDate}
              handleDateRangePicker={this.props.handleDateRangePicker}
              placeholder="Date filter"
            />
          </div> */}
        </div>
      </div>
    );
  }
}

export default SearchAndFilter;
