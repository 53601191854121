import types from 'constants/types';

const initialState = {
    locationList:[],
    shiftList:[],
    patternList:[],
    patternDetails:[],
    shiftDropdownList:[],
    scheduleEmployeeList:[],
    scheduleAllEmployeeList:[],
    scheduleAllEmployeeListwithWorkingHours:[],
    scheduleList:[],
    teamList:[],
    teamEmployeeList:[],
    teamRoleList:[],
}

export default function (state: any = initialState, action: any) {
    switch (action.type) {
        case types.GET_ROOSTER_LOCATION_LIST: {
            return{
                ...state,
                locationList:action.payload
            }
        }
        case types.GET_ROOSTER_TEAM_EMPLOYEE:{
            return{
                ...state,
                teamEmployeeList:action.payload
            }
        }
        case types.GET_ROOSTER_SCHEDULE_ALL_EMPLOYEE_LIST:{
            return{
                ...state,
                scheduleAllEmployeeList:action.payload
            }
        }
        case types.GET_ROOSTER_SCHEDULE_EMPLOYEE_WITH_WORKING_HOURS:{
            return{
                ...state,
                scheduleAllEmployeeListwithWorkingHours:action.payload
            }
        }
        case types.ADD_ROOSTER_SCHEDULE_EMPLOYEE:{
            return {
                ...state,
                // scheduleEmployeeList:state.scheduleEmployeeList && state.scheduleEmployeeList.map(item=>{
                //     const find = action.payload.find(act=>act?.ShiftScheduleEmployeeId === item?.ShiftScheduleEmployeeId)
                //     return find ? find : item
                // })
                scheduleEmployeeList: [
                    ...state.scheduleEmployeeList,
                    ...action.payload
                ]
            }
        }
        case types.ADD_ROOSTER_SCHEDULE_EMPLOYEE_ALL:{
            return {
                ...state,
                scheduleEmployeeList: [
                    ...state.scheduleEmployeeList,
                    ...action.payload
                ]
            }
        }
        case types.UPDATE_ROOSTER_TEAM : {
            return{
                ...state,
                teamList:state.teamList.map(item=>{
                    if(item.Id === action.payload.Id){
                        return {value:action.payload.Id,label:action.payload.Name,...action.payload}
                    }
                    return item;
                })
            }
        }
        case types.ADD_ROOSTER_TEAM : {
            return{
                ...state,
                teamList:[...state.teamList,action.payload]
            }
        }
        case types.DELETE_ROOSTER_TEAM : {
            return{
                ...state,
                teamList: state.teamList.filter(item=>item.Id !== action.payload)
            }
        }
        case types.UPDATE_ROOSTER_TEAM_EMPLOYEE:{
            return{
                ...state,
                teamEmployeeList:state.teamEmployeeList?.map(item=>{
                    if(item.Id === action.payload.Id){
                        return {...action.payload,TotalRows:item?.TotalRows}
                    }
                    return item
                })
            }
        }
        case types.ADD_ROOSTER_TEAM_EMPLOYEE:{
            return{
                ...state,
                teamEmployeeList:state.teamEmployeeList?.map(item=>{
                    if(item.EmployeeId === action.payload.EmployeeId){
                        return {TotalRows:item?.TotalRows,RowNumber:item?.RowNumber,...action.payload}
                    }
                    return item
                })
            }
        }
        
        case types.GET_ROOSTER_SHIFT_PATTERN_DETAIL_BY_MASTER:{
            return{
                ...state,
                patternDetails:action.payload
            }
        }
        case types.ADD_ROOSTER_SHIFT_PATTERN_DETAIL:{
            return{
                ...state,
                patternDetails:action.payload
            }
        }
        case types.GET_ROOSTER_SHIFTS_LIST: {
            return{
                ...state,
                shiftList:action.payload
            }
        }
        case types.ADD_ROOSTER_SHIFT: {
            return{
                ...state,
                shiftList:[action.payload,...state.shiftList]
            }
        }

        case types.DELETE_ROOSTER_SHIFT:{
            return{
                ...state,
                shiftList:state.shiftList.filter(item=>item.Id !== action.payload)
            }
        }
        case types.DELETE_ROOSTER_SCHEDULE:{
            return{
                ...state,
                scheduleList:state.scheduleList.filter(item=>item.ShiftScheduleId !== action.payload)
            }
        }
        case types.GET_ROOSTER_SHIFTS_DROPDOWN_LIST: {
            return{
                ...state,
                shiftDropdownList:action.payload
            }
        }
        case types.ADD_ROOSTER_SHIFT_PATTERN: {
            return{
                ...state,
                patternList:[...state.patternList,action.payload]
            }
        }
        case types.UPDATE_ROOSTER_SHIFT_PATTERN: {
            return{
                ...state,
                patternList: state.patternList.map(item=>{
                    if(item.Id === action.payload.Id){
                        return {...action.payload,TotalRows:item?.TotalRows}
                    }
                    return item
                })
            }
        }
        case types.UPDATE_ROOSTER_SHIFT: {
            return{
                ...state,
                shiftList: state.shiftList.map(item=>{
                    if(item.Id === action.payload.Id){
                        return action.payload
                    }
                    return item
                })
            }
        }
        
        case types.DELETE_ROOSTER_SHIFT_PATTERN:{
            return{
                ...state,
                patternList:state.patternList.filter(item=>item.Id !== action.payload)
            }
        }
        case types.GET_ROOSTER_PATTERN_LIST: {
            return{
                ...state,
                patternList:action.payload
            }
        }
        case types.GET_ROOSTER_SCHEDULE_LIST: {
            return{
                ...state,
                scheduleList:action.payload
            }
        }
        case types.GET_ROOSTER_SCHEDULE_EMPLOYEE_LIST: {
            return{
                ...state,
                scheduleEmployeeList:action.payload
            }
        }
        case types.GET_ROOSTER_TEAM_LIST: {
            return{
                ...state,
                teamList:action.payload ? action.payload.map(item=>({value:item.Id,label:item.Name,...item})) : []
            }
        }
        case types.GET_ROOSTER_TEAM_ROLE_LIST: {
            return{
                ...state,
                teamRoleList:action.payload ? action.payload.map(item=>({value:item.Id,label:item.RoleName,...item})) : []
            }
        }
        case types.ADD_ROOSTER_TEAM_ROLE:{
            return{
                ...state,
                teamRoleList:state?.teamRoleList && [action.payload,...state?.teamRoleList]
            }
        }
        case types.UPDATE_ROOSTER_TEAM_ROLE:{
            return{
                ...state,
                teamRoleList:state?.teamRoleList && state?.teamRoleList.map(item=>{
                    if(item.Id !== action.payload?.Id){
                        return item;
                    }
                    return action.payload
                })
            }
        }
        case types.DELETE_ROOSTER_TEAM_ROLE:{
            return{
                ...state,
                teamRoleList:state?.teamRoleList && state?.teamRoleList.filter(item=>item.Id !== action.payload)
            }
        }
        default:
      return state;
    }
}