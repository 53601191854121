export const ApprovalProcessDropdownItem = [
  {label: "Branch Initiator", value: 1},
  {label: "Branch Head", value: 2},
  {label: "Province Head", value: 3},
  {label: "Corporate Office", value: 4},
];

export const StepList = [
  {label: "Start", value: 1},
  {label: "Step 2", value: 2},
  {label: "Step 3", value: 3},
  {label: "Step 4", value: 4},
  {label: "Step 5", value: 5},
  {label: "Step 6", value: 6},
  {label: "Step 7", value: 7},
  {label: "Step 8", value: 8},
  {label: "Step 9", value: 9},
  {label: "End", value: 10},
];

export const AuthorityList = [
  {label: "Branch Initiator", value: 1},
  {label: "Branch Head", value: 2},
  {label: "Province Head", value: 3},
  {label: "Corporate Office", value: 4},
  {label: "Department Head", value: 5},
  {label: "Multiple Specific Person", value: 6},
];
