import React,{useState,useEffect} from 'react'
import FormGroup from 'components/formGroup'
import FormItem from 'components/formGroup/formItem';
import Button from 'components/button';
import { MdAdd } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { getRosterShiftPatternDetailsByMaster, addRosterShiftPatternDetail } from '../helpers/action';
import { useForm } from 'react-hook-form';

function AddShiftPattern(props) {
    const {onModalClose,shiftData} = props;
    const dispatch = useDispatch();
    const {patternDetails} = useSelector((state:any)=>state.roosterReducer)
    const [days,setDays] = useState(0)
    const method = useForm()
    const {setValue} = method
    React.useEffect(() => {
        if(shiftData){
            dispatch(getRosterShiftPatternDetailsByMaster(shiftData?.Id))
        }
    }, [shiftData]);
    const onSubmit = (data) => {
        if(data){
            const finalData = []
            Object.keys(data).map((key,i)=>{
                const num = key.split("-")[1]
                const id = patternDetails ? patternDetails[i]?.Id : 0
                finalData.push({
                        Id:id,
                        Day:num,
                        IsWorkDay:data[key]?.value,
                        shiftPatternMasterId:shiftData?.Id
                    })
            })
            const res = dispatch(addRosterShiftPatternDetail(shiftData?.Id,finalData));
            if(res){
                onModalClose()
            }
        }
        
    }
    useEffect(()=>{
        if(shiftData){
            setDays(shiftData?.TotalNoOfDays)
        }
    },[shiftData,patternDetails])
    useEffect(()=>{
        if(patternDetails && patternDetails.length > 0 && days > 0){
            // setDays(patternDetails.length)
            patternDetails.map(item=>{
                const shift = item.IsWorkDay ?{label:'Working Day',value:true}:{label:'Day Off',value:false}
                setValue("shift-"+(item?.Day),shift)
            })
        }
    },[patternDetails,days])
    return (
        <div className="shifts-add" >
            {/* <div className="shifts-add__title">
                Add Shift Pattern
            </div> */}
            <FormGroup method={method} onSubmit={onSubmit}>
                <div className="shifts-form__row">
                    <div className="shifts-form__head">Day</div>
                    <div className="shifts-form__head">Shift</div>
                </div>
                {
                    days > 0 && [...Array(days)].map((e,i)=>(
                        <div className="shifts-form__row">
                            <div className="shifts-form__row-label">Day {i+1}</div>
                            <FormItem 
                                name={"shift-"+(i+1)}
                                formName="reactselect"
                                rules={{ required: 'Shift Type is required!' }}
                                options={[{label:'Working Day',value:true},{label:'Day Off',value:false}]}
                            />
                        </div>
                    ))
                }
                <div className="shifts-add__row shifts-form__row-btns flex">
                    <Button onClick={()=>onModalClose()} htmlButtonType="button" bg="subtle" title="Cancel" />
                    <Button htmlButtonType="submit" bg="primary"  title="Save" />
                </div>
            </FormGroup>
        </div>
    )
}

export default AddShiftPattern
