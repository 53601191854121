import React from "react";
import Modal from "components/modal";
import TaskModalContent from "./taskModalContent";

const TaskModal = ({ open, onClose, task, rowIndex, stage, board }) => {
  const onModalClose = () => onClose(false);
  return (
    <Modal
      // title="Header"
      onModalClose={onModalClose}
      open={open}
      width="75%"
      className="task-modal"
    >
      <div className="task-modal__body">
        <TaskModalContent
          task={task}
          rowIndex={rowIndex}
          stage={stage}
          board={board}
        />
      </div>
    </Modal>
  );
};
export default TaskModal;
