import * as React from "react";
import classnames from "classnames";
import isEmpty from "isEmpty";
import { checkRadioInterface } from "ts/interfaces/formInterface";

const CheckGroup: React.FunctionComponent<checkRadioInterface> = (props) => {
  const {
    name,
    value,
    error,
    label,
    checked,
    className,
    onChange,
    labelPosition,
    disabled,
  } = props;
  var inputclass = classnames({
    "custom-check": true,
    error: !isEmpty(error) && error,
    [className]: className,
    // [size]:size,
    "label-right": labelPosition === "right",
  });
  var handleChange = (e) => {
    console.log(e);
    onChange(e);
  };
  return (
    <label className={inputclass}>
      <span>{label}</span>
      <input
        type="checkbox"
        name={name}
        checked={checked}
        // onChange={handleChange}
        onChange={(event) => onChange(event)}
        value={value}
        disabled={disabled}
      />
      <div className="checkmark"></div>
    </label>
  );
};
CheckGroup.defaultProps = {
  disabled: false,
};

export default CheckGroup;
