import Form from "components/form";
import React from "react";

function PropertyDetails({ data, onChange, constructionStages, errors }) {
  return (
    <>
      <div className="homeloan-propertydetail">
        <div className="homeloan-propertydetail-section flex">
          <div className="wd-50 mr-bg mb-md">
            <Form
              name="LandArea"
              value={data.LandArea}
              onChange={onChange}
              label={"Land Area"}
              validators={["required"]}
              error={data.errors.LandArea}
              // width={"40%"}
            />
          </div>
          <div className="wd-50 mr-bg mb-md">
            <Form
              name="KittaNumber"
              value={data.KittaNumber}
              onChange={onChange}
              label={"Kitta Number"}
              validators={["required"]}
              error={data.errors.KittaNumber}
            />
          </div>
        </div>
        <div className="homeloan-propertydetail-section flex">
          <div className="wd-50 mr-bg mb-md">
            <Form
              name="BuildingArea"
              value={data.BuildingArea}
              onChange={onChange}
              label={"Building (Square Feet)"}
              validators={["required"]}
              error={data.errors.BuildingArea}
              // width={"40%"}
            />
          </div>
          <div className="wd-50 mr-bg mb-md">
            <Form
              name="NumberofStoreys"
              type="number"
              value={data.NumberofStoreys}
              onChange={onChange}
              label={"No of Storeys"}
              width={"50%"}
              validators={["required", "number"]}
              error={data.errors.NumberofStoreys}
            />
          </div>
        </div>
        <div className="homeloan-propertydetail-section flex">
          <div className="wd-50 mr-bg mb-md">
            <Form
              type="number"
              name="EstimatedValue"
              value={data.EstimatedValue}
              onChange={onChange}
              label={"Estimated Value"}
              width={"50%"}
              validators={["required", "number"]}
              error={data.errors.EstimatedValue}
            />
          </div>
        </div>
      </div>
      <div className={`homeloan-header ${errors.constructionStagesError ? '' : ' mb-sm'}`}>
        <span>Construction Stage</span>
      </div>
      {errors && errors.constructionStagesError && (
        <div className="homeloan__checkbox__required text-red mb-sm">Required</div>
      )}
      <div className="homeloan-propertydetail flex">
        <div className="homeloan-propertydetail-form homeloan-checkboxes-area mb-md flex">
          {constructionStages &&
            constructionStages.map((radio, index) => {
              return (
                <label class="checkbox-container">
                  {radio.label}
                  <input
                    type="checkbox"
                    checked={radio.value == data.ConstructionStage}
                    onChange={() => onChange("ConstructionStage", radio.value)}
                  />
                  <span class="checkmark"></span>
                </label>
              );
            })}
        </div>
      </div>
    </>
  );
}

export default PropertyDetails;
