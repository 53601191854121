import React, { Component } from 'react'
import {withRouter,Link} from 'react-router-dom';
import {MdAutorenew} from 'react-icons/md'
import {getCalcHeader} from './action'
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    Freeze, Inject, 
    Resize,
    Grid,
} from "@syncfusion/ej2-react-grids";
import classnames from "classnames";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEmpty from "isEmpty";
import Loader from '../../../components/loaders'
import getFormattedNumber from '../../../helpers/getFormattedNumber'
import CommonPagination from '../../../components/common/pagination'



export class Table extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            rows:[]
        }
        this.template = this.gridTemplate;
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.searchSourse=this.searchSourse.bind(this);
        this._renderRow=this._renderRow.bind(this);
        this.getColumn=this.getColumn.bind(this)
    }
    componentDidMount() {
        const { selectedPayPeriod } = this.props.selectReducer;
        if(!isEmpty(selectedPayPeriod.Value) ){
            this.props.getCalcHeader(parseInt(selectedPayPeriod.Value));
        }
        
    }
    componentDidUpdate = (prevProps, prevState) => {
        const { selectedPayPeriod } = this.props.selectReducer;
        if(!isEmpty(selectedPayPeriod.Value) ){
            const { selectedPayPeriod:prevPayrollPeriod } = prevProps.selectReducer;
            if(selectedPayPeriod !== prevPayrollPeriod){
                console.log('here')
                this.props.getCalcHeader(parseInt(selectedPayPeriod.Value));
            }
        }
    };
    _renderRow(row){
        const {calculation_header} = this.props.incomeReducer;
        let tds =calculation_header.map(item=>{
            return <td name={item.HeaderName}>-</td>
        });
        let newTds=tds;
        let newRow = row.forEach((data,ind)=>{
            let {col,header} = this.searchSourse(data);
            newTds= newTds.map((td,index)=>{
                if(td.props.name === header.HeaderName){
                    
                    let ele= React.cloneElement(td, {
                        key: index,
                        name:td.props.name,
                        className:"align-right",
                        children: (<span>{getFormattedNumber(col.Amount)}</span>)
                        // children: <span>{col.Amount}</span>
                    })
                    // let ele = <td name={td.props.name}>{col.Amount}</td>
                    return ele
                }else{
                    let ele= React.cloneElement(td, {
                        key: index,
                        name:td.props.name,
                        className:"align-right",
                        children: td.props.children
                        // children: <span>{col.Amount}</span>
                    })
                    return ele
                }
                
            })

        });
        return newTds
    }
    getColumn(rwo,tdName){
        
    }
    searchSourse(col){
        const {calculation_header} = this.props.incomeReducer;
        let a = calculation_header.find(item=>{
            return item.Type===col.Type && item.SourceId===col.SourceId;
        });
        if(!isEmpty(a)){
            return {col,header:a}
        }else{
            return {col:null,header:null}
        }
        // return a;
    }
    gridTemplate(props) {
        return (
            <tr className="leave-row bordered income-row">
            <td className="">
                <a href="#">
                    <span>
                        {props.EmployeeId}
                    </span>
                </a>
                
            </td>
            <td className="">
                <a href="#">
                    <span>
                        {props.Name}
                    </span>
                </a>
                
            </td>
            {
                this._renderRow(props.CalculationValues)
            }
            <td>{
                    props.EIN?
                    <a href="#" className="flex">
                        <span style={{fontSize:20,marginRight:4}}>
                            <MdAutorenew />
                        </span>
                        Regenerate
                    </a>
                    :null
                }
            </td>
        </tr>
        );
    }
    handleChangePage = (event, newPage) => {
        this.props.handleIndexChange(newPage);
    };

    handleChangeRowsPerPage = event => {
        this.props.handleIndexChange(0);
        this.props.handleRowChange(event.target.value);
    };
    customAttributes(a){
console.log(a)
    }
 
    render() {
        const {calculation_header,calcHeaderLoading,searchedIncomeList,loading,searchedPage } = this.props.incomeReducer;
        
        const { selectedPayPeriod } = this.props.selectReducer;
        console.log("selectedPayPeriod", this.props)
        let href =(id,type)=>{
            let val = {
                payroll:selectedPayPeriod,
                incomeId:`${id}:${type}`
            }
            // console.log(JSON.stringify(val))
            return this.props.history.createHref({
                pathname:'/payroll/salary_adjustment',
                // search:`?income=${JSON.stringify(val)}`,
                search:`?income=${id}:${type}&payroll=${selectedPayPeriod.Value}`,
                state:{value:`${id}:${type}`}
            })
        };
        let link = <Link to="/payroll/salary_adjustment" params={{ testvalue: "hello" }} target="_blank">Create Idea</Link>
        // var href = '/react/payroll/salary_adjustment';
        return (
            <div className="arrear-table income-table">
                {
                    calculation_header === null || calcHeaderLoading || loading ?
                    <Loader open={calcHeaderLoading || loading} />
                    :null
                }
                    <GridComponent 
                            // frozenColumns={1}
                            dataSource={searchedIncomeList}  
                            rowTemplate={this.template.bind(this)}>
                        <ColumnsDirective>
                        <ColumnDirective field='EIN'  width='60'/>
                        <ColumnDirective field='Name' width='140'/>
                        {
                            calculation_header.map(item=>{
                                if(item.HeaderName.toLowerCase() === 'basic salary'){
                                    return(
                                        <ColumnDirective 
                                                field={item.HeaderName} width={120} 
                                                onClick={()=>console.log('here')}
                                                headerText={item.HeaderName
                                                        // `
                                                        //     <a 
                                                        //         style="color:#666;text-decoration: none;"
                                                        //         href=${href(item.SourceId,item.Type)}
                                                        //         target="_blank">${item.HeaderName}
                                                        //         </a>
                                                        // `
                                                            } 
                                                            // href="/react/payroll/salary_adjustment" 
                                                            />   
                                    )
                                }else{
                                    return(
                                        <ColumnDirective field={item.HeaderName} width={120} headerText={item.HeaderName} />   
                                    )
                                }
                                
                            })
                        }
                        </ColumnsDirective>
                        <Inject services={[Freeze, Resize,]} />
                    </GridComponent>
                
                {isEmpty(searchedIncomeList) ? null : (
                    <CommonPagination 
                        handleRowChange={this.props.handleRowChange}
                        handleIndexChange={this.props.handleIndexChange}
                        pageIndex={this.props.pageIndex}
                        searchedPage={searchedPage}
                        rowsPerPage={this.props.rowsPerPage}
                    />
                )}
            </div>
        )
    }
}

Table.propTypes = {
    incomeReducer: PropTypes.object.isRequired,
    selectReducer:PropTypes.object.isRequired,
    getCalcHeader: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    incomeReducer: state.incomeReducer,
    selectReducer:state.selectReducer
});

export default withRouter(connect(mapStateToProps, { getCalcHeader })(Table));
