import React, { Component } from 'react'
import Tabs from '../../../components/tabs/tabs'
import CheckGroup from '../../../components/form/checkGroup'
import Table from './table'
import Filters from './filters'
import {searchStopPayment} from './action'
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Form from './form'
import './style.scss'

export class Stop_Payment extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            leftValue:'',
            rowsPerPage:10,
            pageIndex:0,
            archive:false,
        }
        this.onChange=this.onChange.bind(this);
        this.handleRowChange=this.handleRowChange.bind(this)
        this.handleIndexChange=this.handleIndexChange.bind((this))

    }
    componentDidMount() {
        this.onFilter();
    }
    onChange(e){
        this.setState({[e.target.name]:e.target.value})
    }
    handleRowChange(num){
        this.setState({rowsPerPage:num}, () => {
            this.onFilter();
        })
    }
    handleIndexChange(num){
        this.setState({pageIndex:num}, () => {
            this.onFilter();
        })
    }

    onFilter = () => {
        const nowPayroll = this.props.selectReducer.selectedPayPeriod.Value;
        const { archive, pageIndex, rowsPerPage } = this.state;
        const searchData ={
            ShowArchive: archive,
            PayrollPeriodId: parseInt(nowPayroll),
            PageIndex: pageIndex,
            PageSize: rowsPerPage
        }
        this.props.searchStopPayment(searchData)
    }

    componentDidUpdate = (prevProps, prevState) => {
        
        const prevPayroll = prevProps.selectReducer.selectedPayPeriod.Value;
        const nowPayroll = this.props.selectReducer.selectedPayPeriod.Value;
        const {archive} = this.state;
        if(prevPayroll !== nowPayroll || archive !== prevState.archive){
            const searchData ={
                ShowArchive: archive,
                PayrollPeriodId: parseInt(nowPayroll),
                PageIndex: this.state.pageIndex,
                PageSize: this.state.rowsPerPage
            }
            this.props.searchStopPayment(searchData)
        }
    };
    
    render() {
        const {
            searchStopPayment,
            searchedPage,
            loading
        } = this.props.stopPaymentReducer;
        if (loading || searchStopPayment === null) return null;
        return (
            <div className="payroll-body">
                <div className="panel blue space-between payroll-panel">
                    <div className="flex">
                        <h4>Stop Payment</h4>
                    </div>
                    <div className="flex">
                        <Form onFilter={this.onFilter} />
                        <CheckGroup 
                            name="archive"
                            value={this.state.archive}
                            label="Archive"
                            className="archive-check"
                            checked={this.state.archive}
                            onChange={(e)=>this.setState({archive:e.target.checked?true:false})}
                            labelPosition={"right"}
                        />
                    </div>
                    
                </div>
                <Table 
                    pageIndex={this.state.pageIndex} 
                    rowsPerPage={this.state.rowsPerPage} 
                    handleRowChange={this.handleRowChange}
                    handleIndexChange={this.handleIndexChange}
                    onFilter={this.onFilter}
                />
            </div>
        )
    }
}


Stop_Payment.propTypes = {
    stopPaymentReducer: PropTypes.object.isRequired,
    selectReducer:PropTypes.object.isRequired,
    searchStopPayment:PropTypes.func,
};
    
const mapStateToProps = state => ({
    stopPaymentReducer: state.stopPaymentReducer,
    selectReducer: state.selectReducer,
});
    
export default connect(mapStateToProps,{searchStopPayment})(Stop_Payment);
