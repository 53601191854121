import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import "./tasktodo.styles.scss";
import { taskToDo } from "../helpers/action";
import moment from "moment";
import TodoHeader from "./todoHeader";
import Todo from "./todo";
import task from "../board/taskBody/boardView/task";
import isEmpty from "helpers/isEmpty";

function TaskTodo(props: any) {
  const [todos, setTodos] = useState([]);

  const [data, setData] = useState({
    LastWeekTasks: [],
    ThisWeekTasks: [],
    NextWeekTasks: [],
    WithoutDateTasks: [],
  });
  const today = moment();
  const fromDate = today.startOf("week").format("MM/DD/YYYY");
  const toDate = today.endOf("week").format("MM/DD/YYYY");
  const [totalData, setTotalData] = useState(0);
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    data && handleTodoHeaderClick("thisWeek");
  }, [data]);

  const fetchData = async () => {
    const dataRes = await props.taskToDo(fromDate, toDate);
    setData(dataRes);
  };

  const handleTodoHeaderClick = (type) => {
    let tasks = [];
    let total = 0;
    if (type === "thisWeek") {
      tasks = data.ThisWeekTasks;
    } else if (type === "previous") {
      tasks = data.LastWeekTasks;
    } else {
      tasks = data.NextWeekTasks;
    }

    let list = [];
    const currentDate = moment(new Date(), "DD/MM/YYYY");
    var earlier = tasks.filter(
      (x) => currentDate.diff(moment(x.StartDate), "days") > 0
    );
    if (earlier.length > 0) {
      total += earlier.length;
      list.push({ title: "Earlier", data: earlier });
    }
    var today = tasks.filter(
      (x) =>
        moment(x.StartDate).format("MM/DD/YYYY") ===
        currentDate.format("MM/DD/YYYY")
    );
    if (today.length > 0) {
      total += today.length;
      list.push({ title: "Today", data: today });
    }
    var upcomings = tasks.filter((x) => moment(x.StartDate) > currentDate);
    if (upcomings.length > 0) {
      total += upcomings.length;
      list.push({ title: "Upcoming", data: upcomings });
    }
    var withoutStartDate = tasks.filter((x) => isEmpty(x.StartDate));
    if (withoutStartDate.length > 0) {
      total += withoutStartDate.length;
      list.push({ title: "Without a Date", data: withoutStartDate });
    }
    if (data.WithoutDateTasks.length > 0) {
      // total += data.WithoutDateTasks.length;
      list.push({ title: "Without a Date", data: data.WithoutDateTasks });
    }
    setTodos(list);
    setTotalData(total);
  };

  return (
    <div className="task-todo">
      <TodoHeader
        handleClick={handleTodoHeaderClick}
        data={data}
        fromDate={fromDate}
        toDate={toDate}
      />
      <div className="task-todo-body">
        {/* <h3 className="task-todo-body__title">Hi Sumnima</h3> */}
        <p className="task-todo-body__num">You have {totalData} assignment</p>
        <div className="task-todo-assignments">
          {todos.map((item, index) => (
            <Todo key={index} title={item.title} todos={item.data} />
          ))}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  taskToDo,
};
export default connect(mapStateToProps, mapDispatchToProps)(TaskTodo);
