import React, { useState, useCallback, useRef } from "react";
import {
  useTransition,
  useSpring,
  useChain,
  config,
  animated,
} from "react-spring";
import classnames from "classnames";
import isEmpty from "isEmpty";

export const AnimateShowHide = ({
  show,
  children,
  config,
  fromStyle,
  className,
  toStyle,
}) => {
  const open = show;
  const springRef = useRef();
  const { size, opacity, ...rest } = useSpring({
    ref: springRef,
    config: config, //{ duration: 1000 },
    from: fromStyle,
    // {
    //   opacity: 0,
    //   transform: "translate3d(0,-150%,0)"
    // },
    to: toStyle,
    //  {
    //   opacity: 1,
    //   transform: open ? "translate3d(0,0,0)" : "translate3d(0,-150%,0)"
    // }
  });
  useChain([springRef], [0, open ? 0.1 : 0.6]);
  let animClasses = classnames({
    "animation-overlay": true,
    [className]: !isEmpty(className),
  });
  //document.body.style.overflow = show ? "hidden" : "visible";
  return (
    <>
      <animated.div style={{ ...rest }} className={animClasses}>
        {children}
      </animated.div>
    </>
  );
};
