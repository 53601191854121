import React from "react";

function TrainingInformation(props) {
  const { data } = props;

  return (
    <div className="information-wholeInfo-nav-block">
      <div className="information-wholeInfo-nav-block-title">
        <span>TRAINING</span>
      </div>

      {data &&
        data.map((d, i) => {
          return (
            <div className="information-wholeInfo-nav-block-subtitle" key={i}>
              <div className="information-wholeInfo-nav-block-subtitle-left">
                <span className="fonts-light_medium">{d.Year}</span>
              </div>

              <div className="information-wholeInfo-nav-block-subtitle-right">
                <div className="information-wholeInfo-nav-block-subtitle-description">
                  <span className="fonts-dark_medium">{d.TrainingName}</span>
                  <span className="fonts-light_medium">at</span>
                  <span className="fonts-dark_medium">{d.InstitutionName}</span>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default TrainingInformation;
