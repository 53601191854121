import React from "react";
import { getBaseAxios } from "helpers/constants";
import { MdArrowForward } from "react-icons/md";
import { connect } from "react-redux";
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
} from "react-share";
import {
  JobApplyPath,
  JobDetailsPath,
  JobListingPath,
} from "routes/commonLinks";
import { withRouter } from "react-router-dom";
import workImage from "src/assets/images/work.png";
import moneyImage from "src/assets/images/money.png";
import businessmanImage from "src/assets/images/businessman.png";
import universityImage from "src/assets/images/university.png";

export class CareerAdditionalDetails extends React.Component {
  render() {
    let { jobDetails } = this.props.jobListingReducer;
    let { CompanyLogo } = this.props.companyInformation;
    //const shareLink = getBaseAxios() + JobDetailsPath + jobDetails.Jobid;
    const shareLink = window.location.href;

    return (
      <div className="career-additional">
        <div className="career-additional__header">
          <div className="comp-logo">
            <img src={CompanyLogo} />
          </div>
          <div className="header-left-info">
            <span className="emp-status">{jobDetails.EmployementType}</span>
            <span className="position">{jobDetails.ExternalJobTitle}</span>
            <span className="publish-date">
              Published on {jobDetails.PublisheDate}
            </span>
            <span
              className="all-job"
              onClick={() => this.props.history.push(JobListingPath)}
            >
              View all Jobs
              <MdArrowForward className="view-all-icon" />
            </span>
          </div>
          <div className="header-right-info">
            <div className="deadline">
              <span className="deadline__header">Application ends</span>
              <span className="deadline__value"> {jobDetails.EndDate}</span>
            </div>
            <button
              className="btn btn-apply"
              onClick={() =>
                this.props.history.push({
                  pathname: JobApplyPath,
                  state: { jobDetails },
                })
              }
            >
              Apply Now
              <MdArrowForward className="left-arrow" />
            </button>
          </div>
        </div>
        <div className="career-additional__body">
          <div className="body-row">
            <div className="body-col left-col">
              <div className="body-col__header">
                <span>Job Description</span>
              </div>
              <div
                className="body-col__body"
                dangerouslySetInnerHTML={{ __html: jobDetails.JobDescription }}
              ></div>
            </div>
            <div className="body-col right-col">
              <div className="job-info">
                <div className="body-col__header">
                  <span>Job Information</span>
                </div>
                <div className="body-col__body">
                  <div className="col-row">
                    <div className="req-icons">
                      <img src={workImage} />
                    </div>
                    <div className="job-req">
                      <span className="job-req__header">Job Area</span>
                      <span className="job-req__body">Finanace, Account</span>
                    </div>
                  </div>
                  <div className="col-row">
                    <div className="req-icons">
                      <img src={universityImage} />
                    </div>
                    <div className="job-req">
                      <span className="job-req__header">Qualification</span>
                      <span className="job-req__body">
                        {jobDetails.Education}
                      </span>
                    </div>
                  </div>
                  <div className="col-row">
                    <div className="req-icons">
                      <img src={businessmanImage} />
                    </div>
                    <div className="job-req">
                      <span className="job-req__header">Experience</span>
                      <span className="job-req__body">
                        {jobDetails.Experience} Year
                      </span>
                    </div>
                  </div>
                  {jobDetails.IsPayShow ? (
                    <div className="col-row">
                      <div className="req-icons">
                        <img src={moneyImage} />
                      </div>
                      <div className="job-req">
                        <span className="job-req__header">Offered Salary</span>
                        <span className="job-req__body">
                          Rs.{jobDetails.SalaryRangeFrom} -{" "}
                          {jobDetails.SalaryRangeTo}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="apply-info">
                <div className="deadline">
                  <span className="deadline__header">Application ends</span>
                  <span className="deadline__value"> {jobDetails.EndDate}</span>
                </div>
                <button
                  className="btn btn-apply"
                  onClick={() =>
                    this.props.history.push({
                      pathname: JobApplyPath,
                      state: { jobDetails },
                    })
                  }
                >
                  Apply Now
                  <MdArrowForward className="left-arrow" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="career-additional__footer">
          <div className="items">
            <span>Share this Job</span>
          </div>
          <div className="items">
            <FacebookShareButton url={shareLink}>
              <FacebookIcon size={200} round className="social-icon" />
            </FacebookShareButton>
          </div>
          <div className="items">
            <TwitterShareButton url={shareLink}>
              <TwitterIcon size={200} round className="social-icon" />
            </TwitterShareButton>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  jobListingReducer: state.jobListingReducer,
  companyInformation: state.commonReducer.companyInformation,
});

export default withRouter(
  connect(mapStateToProps, {})(CareerAdditionalDetails)
);
