import * as React from "react";
interface IAppProps {
  overTimeData: any;
}

const App: React.FunctionComponent<IAppProps> = (props) => {
  const { overTimeData } = props;
  return (
    <div className="emp-onleave">
      <div className="emp-onleave__header flex justify-between">
        <span className="header-title">
          Overtime History
        </span>
      </div>
      <div className="emp-onleave__body flex-column">
          <div className="flex mt-md">
            <span className="ml-sm wd-60">
              This month
            </span>
            <span className="ml-sm bold-500">
              {overTimeData && overTimeData.ThisMonth}
            </span>
            <span className="ml-xsm">
              hrs
            </span>
          </div>
          <div className="flex mt-md">
            <span className="ml-sm wd-60">
              Previous month
            </span>
            <span className="ml-sm bold-500">
            {overTimeData && overTimeData.PreviousMonth}
            </span>
            <span className="ml-xsm">
              hrs
            </span>
          </div>
      </div>
    </div>
  );
};

export default App;
