import {
  GET_HOME_LOAN_PURPOSE_LIST,
  GET_CONSTRUCTION_STAGES,
  SAVE_HOME_LOAN_REQUEST,
  GET_HOME_LOAN_DETAILS,
  GET_VEHICLE_LOAN_PURPOSE_LIST,
  SAVE_VEHICLE_LOAN_REQUEST,
  GET_VEHICLE_LOAN_DETAILS,
  GET_STAFFOD_LOAN_PURPOSE_LIST,
  GET_STAFFOD_LOAN_DETAILS,
  SAVE_STAFFOD_LOAN_REQUEST,
  GET_STAFF_PERSONAL_LOAN_DETAILS,
  SAVE_STAFF_PERSONAL_LOAN_REQUEST,
  GET_EMPLOYEE_OUTSTANDING_LOAN,
  GET_PAYROLL_LOAN_HEADINGS,
  PULL_EMPLOYEE_OUTSTANDING_LOAN,
  SAVE_EMPLOYEE_OUTSTANDING_LOAN,
  SAVE_MULTIPLE_OUTSTANDING_LOAN,
  EMPLOYEE_DETAIL_FOR_LOAN,
  CHANGE_LOAN_RECOMMENDATION,
  GET_LOAN_LIST_FOR_ADMIN,
  GET_PRIME_LOAN_SALARY_HEAD,
  EMPLOYEE_LOAN_PULLED_STATUS,
  DELETE_PRIME_OUTSTANDING_LOAN,
  SAVE_MULTIPLE_PRIMELOAN_SALARY_HEAD,
  EMPLOYEE_APPLIED_LOAN_LIST,
  GET_LOAN_APPROVER_LIST,
  GET_FORWARED_LOAN_LIST_TO_REVIEW,
  FORWARD_LOAN_TO_FINANCE,
  FORWARD_LOAN_TO_HR,
  FORWARD_LOAN_TO_CAD,
  SET_USER_LOAN_MODULE_ROLE,
  APPROVE_OR_REJECT_LOAN_STATUS,
  ASSIGN_FORWARED_LOAN,
  ADD_PRIME_LOAN_COMMENT,
  PRIME_LOAN_COMMENTS,
  DELETE_PRIME_LOAN_COMMENTS,
  PRIME_LOAN_PRINT_DETAIL,
} from "actions/types";
import axios from "axios";
import { toastMessage } from "actions/validateAction";
import { getErrorResponse } from "helpers/getApiResponse";

export const getHomeLoanPurposeList = () => async (dispatch) => {
  await axios
    .get("/v1/loan/prime-home-loan-purposes")
    .then((response) => {
      if (response.data) {
        dispatch({
          type: GET_HOME_LOAN_PURPOSE_LIST,
          payload: response.data.map((x) => {
            return { value: x.Key, label: x.Value };
          }),
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to post dashboard menus",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const getVehicleLoanPurposeList = () => async (dispatch) => {
  await axios
    .get("/v1/loan/prime-vehicle-loan-purposes")
    .then((response) => {
      if (response.data) {
        dispatch({
          type: GET_VEHICLE_LOAN_PURPOSE_LIST,
          payload: response.data.map((x) => {
            return { value: x.Key, label: x.Value };
          }),
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to post dashboard menus",
        },
      };
      toastMessage(dispatch, response);
    });
};
export const getStaffODLoanPurposeList = () => async (dispatch) => {
  await axios
    .get("/v1/loan/prime-staff-loan-purposes")
    .then((response) => {
      if (response.data) {
        dispatch({
          type: GET_STAFFOD_LOAN_PURPOSE_LIST,
          payload: response.data.map((x) => {
            return { value: x.Key, label: x.Value };
          }),
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to post dashboard menus",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const getConstructionStages = (data) => async (dispatch) => {
  await axios
    .get("/v1/loan/prime-home-construction-stages")
    .then((response) => {
      if (response.data) {
        dispatch({
          type: GET_CONSTRUCTION_STAGES,
          payload: response.data.map((x) => {
            return { value: x.Key, label: x.Value };
          }),
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to post dashboard menus",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const saveHomeLoanRequest = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_HOME_LOAN_REQUEST,
    payload: false,
  });
  await axios
    .post("/v1/loan/prime-home-loans", data)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: SAVE_HOME_LOAN_REQUEST,
          payload: true,
        });
        toastMessage(dispatch, response);
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to save Home Loan Request",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const updateHomeLoanRequest = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_HOME_LOAN_REQUEST,
    payload: false,
  });
  await axios
    .put("/v1/loan/prime-home-loans", data)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: SAVE_HOME_LOAN_REQUEST,
          payload: true,
        });
        toastMessage(dispatch, response);
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to update Home Loan Request",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const getHomeLoanDetails = (homeLoanId) => async (dispatch) => {
  await axios
    .get(`/v1/loan/prime-home-loans/detail/${homeLoanId}`)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: GET_HOME_LOAN_DETAILS,
          payload: response.data.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Get Home Loan Detail",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const saveVehicleLoanRequest = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_VEHICLE_LOAN_REQUEST,
    payload: false,
  });
  await axios
    .post("/v1/loan/prime-vehicle-loans", data)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: SAVE_VEHICLE_LOAN_REQUEST,
          payload: true,
        });
        toastMessage(dispatch, response);
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to save Vehicle Loan Request",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const getVehicleLoanDetails = (vehicleLoanId) => async (dispatch) => {
  await axios
    .get(`/v1/loan/prime-vehicle-loans/detail/${vehicleLoanId}`)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: GET_VEHICLE_LOAN_DETAILS,
          payload: response.data.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Get Home Loan Detail",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const updateVehicleLoanRequest = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_VEHICLE_LOAN_REQUEST,
    payload: false,
  });
  await axios
    .put("/v1/loan/prime-vehicle-loans", data)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: SAVE_VEHICLE_LOAN_REQUEST,
          payload: true,
        });
        toastMessage(dispatch, response);
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to update Home Loan Request",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const saveStaffODLoanRequest = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_STAFFOD_LOAN_REQUEST,
    payload: false,
  });
  await axios
    .post("/v1/loan/prime-staff-od-loans", data)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: SAVE_STAFFOD_LOAN_REQUEST,
          payload: true,
        });
        toastMessage(dispatch, response);
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to Save Staff OD Loan Request",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const updateStaffODLoanRequest = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_STAFFOD_LOAN_REQUEST,
    payload: false,
  });
  await axios
    .put("/v1/loan/prime-staff-od-loans", data)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: SAVE_STAFFOD_LOAN_REQUEST,
          payload: true,
        });
        toastMessage(dispatch, response);
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to Save Staff OD Loan Request",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const getStaffODLoanDetails = (loanId) => async (dispatch) => {
  await axios
    .get(`/v1/loan/prime-staff-od-loans/detail/${loanId}`)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: GET_STAFFOD_LOAN_DETAILS,
          payload: response.data.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Get Home Loan Detail",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const saveStaffPersonalLoanRequest =
  (data, loanType) => async (dispatch) => {
    let apiURI = "/v1/loan/prime-staff-personal-loans";
    if (loanType == "2" || loanType == "PrimeStaffAdvanceSalary")
      apiURI = "/v1/loan/prime-staff-advance-salaries";
    dispatch({
      type: SAVE_STAFF_PERSONAL_LOAN_REQUEST,
      payload: false,
    });
    await axios
      .post(apiURI, data)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: SAVE_STAFF_PERSONAL_LOAN_REQUEST,
            payload: true,
          });
          toastMessage(dispatch, response);
        } else {
          toastMessage(dispatch, response);
        }
      })
      .catch((err) => {
        const response = {
          data: {
            MessageType: "Danger",
            Message: err.message
              ? err.message
              : "Failed to Save Staff OD Loan Request",
          },
        };
        toastMessage(dispatch, response);
      });
  };

export const updateStaffPersonalLoanRequest =
  (data, loanType) => async (dispatch) => {
    let apiURI = "/v1/loan/prime-staff-personal-loans";
    if (loanType == "2" || loanType == "PrimeStaffAdvanceSalary")
      apiURI = "/v1/loan/prime-staff-advance-salaries";
    dispatch({
      type: SAVE_STAFF_PERSONAL_LOAN_REQUEST,
      payload: false,
    });
    await axios
      .put(apiURI, data)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: SAVE_STAFF_PERSONAL_LOAN_REQUEST,
            payload: true,
          });
          toastMessage(dispatch, response);
        } else {
          toastMessage(dispatch, response);
        }
      })
      .catch((err) => {
        const response = {
          data: {
            MessageType: "Danger",
            Message: err.message
              ? err.message
              : "Failed to Save Staff OD Loan Request",
          },
        };
        toastMessage(dispatch, response);
      });
  };

export const getStaffPersonalLoanDetails =
  (loanId, loanType) => async (dispatch) => {
    let apiURI = "/v1/loan/prime-staff-personal-loans/detail/";
    if (loanType == "2" || loanType == "PrimeStaffAdvanceSalary")
      apiURI = "/v1/loan/prime-staff-advance-salaries/detail/";
    await axios
      .get(`${apiURI}${loanId}`)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: GET_STAFF_PERSONAL_LOAN_DETAILS,
            payload: response.data.Data,
          });
        } else {
          toastMessage(dispatch, response);
        }
      })
      .catch((err) => {
        const response = {
          data: {
            MessageType: "Danger",
            Message: err.message ? err.message : "Get Home Loan Detail",
          },
        };
        toastMessage(dispatch, response);
      });
  };

export const getEmployeeOutstandingLoan =
  ({ employeeId, loanType, loanId }) =>
  async (dispatch) => {
    await axios
      .get(
        `/v1/loan/prime-outstanding-loans/${employeeId}?loanType=${loanType}&loanId=${loanId}`
      )
      .then((response) => {
        if (response.data) {
          dispatch({
            type: GET_EMPLOYEE_OUTSTANDING_LOAN,
            payload: response.data.Data,
          });
        } else {
          toastMessage(dispatch, response);
        }
      })
      .catch((err) => {
        const response = {
          data: {
            MessageType: "Danger",
            Message: err.message
              ? err.message
              : "Get Employee Outstanding Loan List",
          },
        };
        toastMessage(dispatch, response);
      });
  };

export const getPayrollLoanHeadings = () => async (dispatch) => {
  await axios
    .get(`/v1/loan/prime-loan-list`)
    .then((response) => {
      if (response.data) {
        let formattedData = [];
        response.data.Data.forEach((data) => {
          formattedData.push({
            label: data.LoanName,
            value: data.Id,
          });
        });
        dispatch({
          type: GET_PAYROLL_LOAN_HEADINGS,
          payload: formattedData,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Get Employee Outstanding Loan List",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const setEmployeeLoanPulledStatus = (data) => (dispatch) => {
  dispatch({
    type: EMPLOYEE_LOAN_PULLED_STATUS,
    payload: data,
  });
};

export const pullEmployeeOutstandingLoan =
  ({ EmployeeId }) =>
  async (dispatch) => {
    await axios
      .get(`/v1/loan/prime-outstanding-loans/payroll/${EmployeeId}`)
      .then((response) => {
        if (response.data) {
          let mappedData = [];
          response.data.Data.map((data) => {
            mappedData.push({
              ...data,
              Id: Math.floor(100000000 + Math.random() * 900000000),
            });
          });
          dispatch({
            type: PULL_EMPLOYEE_OUTSTANDING_LOAN,
            payload: mappedData,
          });
          dispatch({
            type: EMPLOYEE_LOAN_PULLED_STATUS,
            payload: true,
          });
        } else {
          toastMessage(dispatch, response);
        }
      })
      .catch((err) => {
        const response = {
          data: {
            MessageType: "Danger",
            Message: err.message
              ? err.message
              : "Fail to fetch Outstanding Loan Data",
          },
        };
        toastMessage(dispatch, response);
      });
  };

export const changeEmployeeOutstandingLoanManually =
  (data) => async (dispatch) => {
    dispatch({
      type: PULL_EMPLOYEE_OUTSTANDING_LOAN,
      payload: data,
    });
  };

export const getEmployeeDetailForLoan = (employeeId) => async (dispatch) => {
  await axios
    .get(`/v1/core-hr/employees/company/detail?employeeId=${employeeId}`)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: EMPLOYEE_DETAIL_FOR_LOAN,
          payload: response.data.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Get Home Loan Detail",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const getEmployeeAppliedLoanList = (employeeId) => async (dispatch) => {
  await axios
    .get(`/v1/loan/prime-employee-loans/list/${employeeId}`)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: EMPLOYEE_APPLIED_LOAN_LIST,
          payload: response.data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to Fetch Employee Applied Loans",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const saveOutstandingLoans = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_EMPLOYEE_OUTSTANDING_LOAN,
    payload: false,
  });
  await axios
    .post(`/v1/loan/prime-outstanding-loans`, data)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: SAVE_EMPLOYEE_OUTSTANDING_LOAN,
          payload: response.data.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to Save Outstanding Loan",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const updateOutstandingLoans = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_EMPLOYEE_OUTSTANDING_LOAN,
    payload: false,
  });
  await axios
    .put(`/v1/loan/prime-outstanding-loans`, data)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: SAVE_EMPLOYEE_OUTSTANDING_LOAN,
          payload: response.data.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to Save Outstanding Loan",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const saveMultipleOutstandingLoan = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_MULTIPLE_OUTSTANDING_LOAN,
    payload: false,
  });
  await axios
    .post(`/v1/loan/prime-multiple-outstanding-loans`, data)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: SAVE_MULTIPLE_OUTSTANDING_LOAN,
          payload: true,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to Save Outstanding Loan",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const changeRecommendation = (data) => (dispatch) => {
  dispatch({
    type: CHANGE_LOAN_RECOMMENDATION,
    payload: data,
  });
};

export const saveMultiplePrimeLoanSalaryHead = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_MULTIPLE_PRIMELOAN_SALARY_HEAD,
    payload: false,
  });
  await axios
    .post(`/v1/loan/prime-multiple-salaryhead`, data)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: SAVE_MULTIPLE_PRIMELOAN_SALARY_HEAD,
          payload: true,
        });
      } else {
        dispatch({
          type: SAVE_MULTIPLE_PRIMELOAN_SALARY_HEAD,
          payload: false,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to Save Outstanding Loan",
        },
      };
      dispatch({
        type: SAVE_MULTIPLE_PRIMELOAN_SALARY_HEAD,
        payload: false,
      });
      toastMessage(dispatch, response);
    });
};

export const getLoanListForAdmin = (data) => async (dispatch) => {
  await axios
    .post(`/v1/loan/prime-home-loans/list`, data)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: GET_LOAN_LIST_FOR_ADMIN,
          payload: response.data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to Save Outstanding Loan",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const getVehicleLoanListForAdmin = (data) => async (dispatch) => {
  await axios
    .post(`/v1/loan/prime-vehicle-loans/list`, data)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: GET_LOAN_LIST_FOR_ADMIN,
          payload: response.data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to Fetch Vehicle Loan",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const getStaffODListForAdmin = (data) => async (dispatch) => {
  await axios
    .post(`/v1/loan/prime-staff-od-loans/list`, data)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: GET_LOAN_LIST_FOR_ADMIN,
          payload: response.data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to Fetch Vehicle Loan",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const getStaffPersonalLoanListForAdmin =
  (data, loanType) => async (dispatch) => {
    let apiURI = "/v1/loan/prime-staff-personal-loans/list";
    if (loanType == "2") apiURI = "/v1/loan/prime-staff-advance-salaries/list";
    await axios
      .post(apiURI, data)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: GET_LOAN_LIST_FOR_ADMIN,
            payload: response.data,
          });
        } else {
          toastMessage(dispatch, response);
        }
      })
      .catch((err) => {
        const response = {
          data: {
            MessageType: "Danger",
            Message: err.message ? err.message : "Failed to Fetch Vehicle Loan",
          },
        };
        toastMessage(dispatch, response);
      });
  };

export const getPrimeLoanSalaryHead = (EmployeeId) => async (dispatch) => {
  dispatch({
    type: GET_PRIME_LOAN_SALARY_HEAD,
    payload: [],
  });
  await axios
    .get(`/v1/loan/prime-loan-salaryhead/payroll/${EmployeeId}`)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: GET_PRIME_LOAN_SALARY_HEAD,
          payload: response.data.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to Fetch Salary Head",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const getPrimeLoanSavedSalaryHead = (data) => async (dispatch) => {
  dispatch({
    type: GET_PRIME_LOAN_SALARY_HEAD,
    payload: [],
  });
  await axios
    .get(
      `/v1/loan/prime-loan-salaryhead?loanType=${data.LoanType}&loanId=${data.LoanId}`
    )
    .then((response) => {
      if (response.data) {
        dispatch({
          type: GET_PRIME_LOAN_SALARY_HEAD,
          payload: response.data.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to Fetch Salary Head",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const deletePrimeOutstandingLoan = (loanId) => async (dispatch) => {
  await axios
    .delete(`/v1/loan/prime-outstanding-loans/${loanId}`)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: DELETE_PRIME_OUTSTANDING_LOAN,
          payload: true,
        });
      } else {
        dispatch({
          type: DELETE_PRIME_OUTSTANDING_LOAN,
          payload: false,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to Fetch Salary Head",
        },
      };
      dispatch({
        type: DELETE_PRIME_OUTSTANDING_LOAN,
        payload: false,
      });
      toastMessage(dispatch, response);
    });
};

export const getQualifiedApprovers = (EmployeeId) => async (dispatch) => {
  await axios
    .get(`/v1/loan/prime-loan-approvers`)
    .then((response) => {
      if (response.data) {
        let formattedData = [];
        let isHR = false;
        response.data.Data.forEach((emp) => {
          formattedData.push({
            FullName: emp.EmployeeName,
            Imagepath: emp.UrlPhoto,
            // Designation: emp.DesignationName,
            Department: emp.DepartmentName,
            Id: emp.EmployeeId,
            IsHR: emp.IsHR,
            Role: emp.Role
          });
          if (emp.EmployeeId == EmployeeId) {
            isHR = emp.IsHR;
          }
        });
        dispatch({
          type: SET_USER_LOAN_MODULE_ROLE,
          payload: isHR,
        });
        dispatch({
          type: GET_LOAN_APPROVER_LIST,
          payload: formattedData,
        });
      } else {
        dispatch({
          type: GET_LOAN_APPROVER_LIST,
          payload: null,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to Fetch Loan Approver List Head",
        },
      };
      dispatch({
        type: DELETE_PRIME_OUTSTANDING_LOAN,
        payload: false,
      });
      toastMessage(dispatch, response);
    });
};

export const getEmployeeForwaredListToReview =
  (data, excel) => async (dispatch) => {
    if (excel) {
      try {
        let result = await axios.post(`/v1/loan/prime-loan-forward/list`, data);
        return result.data;
      } catch (err) {
        return getErrorResponse(err?.message);
      }
    }
    await axios
      .post(`/v1/loan/prime-loan-forward/list`, data)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: GET_FORWARED_LOAN_LIST_TO_REVIEW,
            payload: response.data,
          });
        } else {
          toastMessage(dispatch, response);
        }
      })
      .catch((err) => {
        const response = {
          data: {
            MessageType: "Danger",
            Message: err.message ? err.message : "Failed to Fetch Vehicle Loan",
          },
        };
        toastMessage(dispatch, response);
      });
  };

export const forwardLoanToHR = (data) => async (dispatch) => {
  await axios
    .put(`/v1/loan/prime-loan-forwards/hr`, data)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: FORWARD_LOAN_TO_HR,
          payload: response.data,
        });
        toastMessage(dispatch, response);
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to Forward Loan To HR",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const forwardLoanToCAD = (data) => async (dispatch) => {
  debugger
  await axios
    .put(`/v1/loan/prime-loan-forwards/cad`, data)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: FORWARD_LOAN_TO_CAD,
          payload: response.data,
        });
        toastMessage(dispatch, response);
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to Forward Loan To HR",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const forwardLoanToFinance = (data) => async (dispatch) => {
  await axios
    .put(`/v1/loan/prime-loan-forwards/finance`, data)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: FORWARD_LOAN_TO_FINANCE,
          payload: response.data,
        });
        toastMessage(dispatch, response);
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to Forward Loan To Finance",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const approveOrRejectLoan =
  ({ isApprove, loanId, loanType, RejectMessage }) =>
  async (dispatch) => {
    let apiURI = "";
    if (loanType == "PrimeHomeLoan")
      apiURI = "/v1/loan/prime-home-loans/approve-reject";
    else if (loanType == "PrimeVehicleLoan")
      apiURI = "/v1/loan/prime-vehicle-loans/approve-reject";
    else if (loanType == "PrimeStaffPersonalLoan")
      apiURI = "/v1/loan/prime-staff-personal-loans/approve-reject";
    else if (loanType == "PrimeStaffODLoan")
      apiURI = "/v1/loan/prime-staff-od-loans/approve-reject";
    else if (loanType == "PrimeStaffAdvanceSalary")
      apiURI = "/v1/loan/prime-staff-advance-salaries/approve-reject";
    await axios
      .put(
        `${apiURI}?isApprove=${isApprove}&loanId=${loanId}&rejectMessage=${RejectMessage}`
      )
      .then((response) => {
        if (response.data) {
          dispatch({
            type: APPROVE_OR_REJECT_LOAN_STATUS,
            payload: response.data,
          });
          toastMessage(dispatch, response);
        } else {
          dispatch({
            type: APPROVE_OR_REJECT_LOAN_STATUS,
            payload: false,
          });
          toastMessage(dispatch, response);
        }
      })
      .catch((err) => {
        const response = {
          data: {
            MessageType: "Danger",
            Message: err.message
              ? err.message
              : "Failed to Forward Loan To Finance",
          },
        };
        dispatch({
          type: APPROVE_OR_REJECT_LOAN_STATUS,
          payload: false,
        });
        toastMessage(dispatch, response);
      });
  };

export const assignForwaredLoan =
  ({ LoanId, EmployeeId, LoanType, Status, RequesterId }) =>
  async (dispatch) => {
    await axios
      .put(
        `/v1/loan/prime-loan-forwards/assign?loanId=${LoanId}&employeeId=${EmployeeId}&loanType=${LoanType}&status=${Status}&requesterId=${RequesterId}`
      )
      .then((response) => {
        if (response.data) {
          dispatch({
            type: ASSIGN_FORWARED_LOAN,
            payload: response.data.Status,
          });
          toastMessage(dispatch, response);
        } else {
          dispatch({
            type: ASSIGN_FORWARED_LOAN,
            payload: false,
          });
          toastMessage(dispatch, response);
        }
      })
      .catch((err) => {
        const response = {
          data: {
            MessageType: "Danger",
            Message: err.message
              ? err.message
              : "Failed to Forward Loan To Finance",
          },
        };
        dispatch({
          type: ASSIGN_FORWARED_LOAN,
          payload: false,
        });
        toastMessage(dispatch, response);
      });
  };

export const addPrimeLoanComment = (data) => async (dispatch) => {
  dispatch({
    type: ADD_PRIME_LOAN_COMMENT,
    payload: false,
  });
  await axios
    .post("/v1/loan/prime-comments", data)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: ADD_PRIME_LOAN_COMMENT,
          payload: true,
        });
      } else {
        dispatch({
          type: ADD_PRIME_LOAN_COMMENT,
          payload: false,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to Forward Loan To Finance",
        },
      };
      dispatch({
        type: ADD_PRIME_LOAN_COMMENT,
        payload: false,
      });
      toastMessage(dispatch, response);
    });
};

export const getPrimeLoanComments =
  ({ LoanType, LoanId }) =>
  async (dispatch) => {
    await axios
      .get(
        `/v1/loan/prime-comment-loans/list?loanType=${LoanType}&loanId=${LoanId}`
      )
      .then((response) => {
        if (response.data) {
          dispatch({
            type: PRIME_LOAN_COMMENTS,
            payload: response.data.Data,
          });
        } else {
          dispatch({
            type: PRIME_LOAN_COMMENTS,
            payload: false,
          });
          toastMessage(dispatch, response);
        }
      })
      .catch((err) => {
        const response = {
          data: {
            MessageType: "Danger",
            Message: err.message
              ? err.message
              : "Failed to Forward Loan To Finance",
          },
        };
        dispatch({
          type: PRIME_LOAN_COMMENTS,
          payload: false,
        });
        toastMessage(dispatch, response);
      });
  };

export const getLoanPDFData =
  ({ LoanType, LoanId }) =>
  async (dispatch) => {
    await axios
      .get(
        `/v1/loan/prime-loan-forward/print-detail?loanId=${LoanId}&loanType=${LoanType}`
      )
      .then((response) => {
        if (response?.data?.Status) {
          dispatch({
            type: PRIME_LOAN_PRINT_DETAIL,
            payload: response.data.Data,
          });
        } else {
          dispatch({
            type: PRIME_LOAN_PRINT_DETAIL,
            payload: null,
          });
          toastMessage(dispatch, response);
        }
      })
      .catch((err) => {
        const response = {
          data: {
            MessageType: "Danger",
            Message: err.message ? err.message : "Failed to Fetch Loan Detail",
          },
        };
        dispatch({
          type: PRIME_LOAN_PRINT_DETAIL,
          payload: null,
        });
        toastMessage(dispatch, response);
      });
  };

export const deletePrimeLoanComments = (commentId) => async (dispatch) => {
  await axios
    .delete(`/v1/loan/prime-comments/${commentId}`)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: DELETE_PRIME_LOAN_COMMENTS,
          payload: true,
        });
      } else {
        dispatch({
          type: DELETE_PRIME_LOAN_COMMENTS,
          payload: false,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to Delete Prime loan comment",
        },
      };
      dispatch({
        type: DELETE_PRIME_LOAN_COMMENTS,
        payload: false,
      });
      toastMessage(dispatch, response);
    });
};
