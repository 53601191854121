import {
    GET_EMPLOYEE_DASHBOARD_DETAIL, 
    IS_EMPLOYEE_DETAIL_LOADING
} from "actions/types";

const initialState = {};

export default function(state = initialState, action) {
    switch (action.type) {
    case IS_EMPLOYEE_DETAIL_LOADING: {
        return {
        ...state,
        isLoading: true
        };
    }
    case GET_EMPLOYEE_DASHBOARD_DETAIL: {
        return {
        ...state,
        employeeDetail: action.payload
        };
    }
    default:
        return state;
    }
}
