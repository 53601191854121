import React, { Component } from "react";

export class CompensationHistory extends Component {
  render() {
    return (
      <div className="segment">
        <div className="segment-history">
          <div className="segment-title">Compensation History</div>

          <div className="table-payslip segment-semi_width">
            <div className="table-row-history">
              <span>3</span>
              <span className="bold">Performance based increment</span>
              <span>2076, Baisakh-1</span>
              <span>40,000.00 {'->'} 50,000.00</span>
            </div>
            <div className="table-row-history">
              <span>2</span>
              <span className="bold">Performance based increment</span>
              <span>2073, Shrawan-1</span>
              <span>30,000.00 {'->'} 40,000.00</span>
            </div>
            <div className="table-row-history">
              <span>1</span>
              <span className="bold">Probation Complete</span>
              <span>2073, Shrawan-1</span>
              <span>20,000.00 {'->'} 30,000.00</span>
            </div>

            <div className="table-row-history">
              <span></span>
              <span className="table-row-blue">View All</span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CompensationHistory;
