import React, { Component } from "react";
// import InputGroup from "../../../../Components/Form/InputGroup";
// import CustomDateRange from "../../../../Components/CustomDateRange";
import Form from "components/form";

export class SearchAndFilter extends Component {
  render() {
    const {
      searchEmployee,
      searchClient,
      searchProject,
      fromDate,
      toDate,
    } = this.props;
    return (
      <div className="filters">
        <div className="filters-grid">
          {/* <InputGroup
            name="searchEmployee"
            value={searchEmployee}
            onChange={this.props.handleInput}
            placeholder="Search Employee"
          /> */}
          <Form
            formName="textinput"
            name="searchEmployee"
            value={searchEmployee}
            onChange={this.props.handleInput}
            placeholder="Search Employee"
          />
        </div>

        <div className="filters-grid">
          {/* <InputGroup
            name="searchClient"
            value={searchClient}
            onChange={this.props.handleInput}
            placeholder="Search Client"
          /> */}
          <Form
            formName="textinput"
            name="searchClient"
            value={searchClient}
            onChange={this.props.handleInput}
            placeholder="Search Client"
          />
        </div>

        <div className="filters-grid">
          {/* <InputGroup
            name="searchProject"
            value={searchProject}
            onChange={this.props.handleInput}
            placeholder="Search Project"
          /> */}
          <Form
            formName="textinput"
            name="searchProject"
            value={searchProject}
            onChange={this.props.handleInput}
            placeholder="Search Project"
          />
        </div>

        <div className="filters-grid">
          {/* <div style={{ width: "220px" }}>
            <CustomDateRange
              startDate={fromDate}
              endDate={toDate}
              handleDateRangePicker={this.props.handleDateRangePicker}
              placeholder="Date range"
            />
          </div> */}
          <Form
            formName="reactdaterange"
            name="startEndDate"
            startDate={fromDate}
            endDate={toDate}
            onChange={this.props.handleDateRangePicker}
            placeholder="Date range"
          />
        </div>
      </div>
    );
  }
}

export default SearchAndFilter;
