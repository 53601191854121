import React, { Component } from 'react'
import { AgGridReact } from 'ag-grid-react';
import CommonPagination from "components/common/pagination";
import Icons from 'components/icons'
import SvgIcons from 'components/icons/svgIcons'
import Button from 'components/button'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { ChangeDetectionStrategyType } from 'ag-grid-react/lib/changeDetectionService'
import { useDispatch } from 'react-redux';
import { rejectOvertime } from './helpers/action'
import * as employeeRequestService from "services/employeeRequests";
import GetDetailsPage from "scenes/employee/requests/common/getDetailsPage";
import Modal from 'components/modal';
import OvertimeRequestForm from "scenes/employee/overtimeRequest";

const Status = (props) => {

  const isActive = props.data.Status === 'Approved';
  const isPending = props.data.Status === 'Pending';
  const isForwarded = props.data.Status === 'Forwarded';
  const isRejected = props.data.Status === 'Rejected';
  const isRecommended = props.data.Status === 'Recommended';
  return (
    <div className={`working-state ${isActive ? 'working' : isPending ? 'pending' : isForwarded ? 'forwarded' : isRecommended ? 'recom' : 'retired'}`}>
      {
        isActive ?
          (<><Icons name="Check" justSVG /></>) :
          isPending ? <SvgIcons color="#ffa126" size="17" name="clock" /> :
            isForwarded ? <SvgIcons color="#40a3ff" size="17" name="forwarded" /> :
              isRecommended ? <SvgIcons color="#0052ab" size="17" name="thumbs-up" /> :
                (<><Icons name="Close" justSVG /></>)
      }
      {props.data.Status}
    </div>
  )
}


const DateCommon = (props) => {

  return (
    <div className="">
      {props.data.RequestedOn}
      {/* {props.data.StartDate}  -  { props.data.EndDate} */}
    </div>
  )
}

const Buttons = (props) => {
  const isEdit = props.data.Status === 'Approved' || props.data.Status === 'Rejected';
  const isForwarded = props.data.Status === 'Forwarded';
  let [isDetailsPageShow, setDetailsPageShow] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const onModalSet = (val) => setOpen(val);
  const rowData = Object.assign({ RequestType: 'Overtime', OvetimeType: props.data.OverTime }, props.data);
  // console.log(rowData)
  // RequestId
  const onApprove = async () => {
    let requestData = {
      requestId: props.data.RequestId,
      requestType: 'Overtime',
      reason: "",
    };
    let result = await employeeRequestService.approveReviewRequests(
      requestData
    );
    if (result.Status) {
      await props.colDef.initialize();
    }
  }
  const onDropClick = (e) => {
    const { eGridCell, eGridCell: { offsetParent: row } } = props;
    // if(row.style)
    let rows = document.getElementsByClassName("ag-row");
    //@ts-ignore
    rows.forEach(item => {
      if (item.style.zIndex) {
        item.style.zIndex = null;
      }
    })
    row.style.zIndex = 10;
  }
  const dispatch = useDispatch()
  const onReject = () => {
    dispatch(rejectOvertime(rowData.RequestId, props.colDef.initialize))
  }

  return (
    <div className="flex allowance-buttons" >
      {
        isEdit ? (
          <Button
            title="Edit"
            buttonType="icon-button"
            onClick={() => onModalSet(true)}
            leftIcon={<Icons name="Edit" justSVG />}
            bg="primary" />
        ) : isForwarded ?
            <Button title="Details"
              onClick={() => {
                setDetailsPageShow(true);
              }}
              leftIcon={<SvgIcons size="17" name="list" />}
              buttonType="icon-button" bg="subtle" />
            : (
              <Button title="Approve"
                onClick={()=>onApprove()}
                leftIcon={<Icons name="CircleCheck" justSVG />}
                buttonType="icon-button" bg="success" />
            )
      }
      <Modal
        title="Overtime Request"
        open={open}
        onModalClose={() => onModalSet(false)}
        type=""
        className="time-request-modal over-time"
      >
        <OvertimeRequestForm
          onModalClose={() => onModalSet(false)}
          overtimeRequestId={rowData}
          editMode={true}
          isAdmin
          refreshRequestData={props.colDef.initialize}
        />
      </Modal>

      <Button
        customDropIcon={<Icons name="Ellipse" justSVG />}
        buttonType="drop-button"
        buttonClass="allowance-buttons__drop"
        onDropClick={(e) => onDropClick(e)}
        dropComponent={<ul>
          <li className="flex" onClick={() => onReject()}>
            <Icons name="Close" justSVG />
            <span>Reject</span>
          </li>
        </ul>}
        dropAbove={rowData.RowNum !==1}
        justDrop={true}
        bg="subtle"
      />
      {isDetailsPageShow && (
        <GetDetailsPage
          selectedRow={rowData}
          closeDetailsModal={() => setDetailsPageShow(false)}
          isViewMode={true}
          isAdmin={true}
          // isReview={isReview}
          refreshRequestData={props.colDef.initialize}
          requestData={props.agGridReact.props.rowData}
          page={'Admin '}
        />
      )}
    </div>
  )
}
class Tables extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        {
          field: 'EmployeeName',
          headerName: "Name",
          pinned: 'left',
        },
        {
          field: 'OverTime',
          headerName: 'Overtime',
        },
        { field: 'RequestedOn', headerName: 'Date', width: 200, cellRenderer: 'DateCommon' },
        { headerName: 'Requested Time', width: 130, field: 'StartTime' },
        { headerName: 'Recorded Time ', width: 130, field: 'EndTime' },
        { headerName: 'Requested', width: 130, field: 'RequestedTimeInMin' },
        { headerName: 'Approved', width: 130, field: 'ApprovedTimeInMIn' },
        { field: 'Reason' },
        { field: 'Status', cellRenderer: 'Status', },
        { field: 'RequestId', headerName: "", cellRenderer: 'Buttons', width: 200, initialize: this.props.initialize },
      ],
      domLayout: 'autoHeight',
      defaultColDef: {
        width: 145,
        resizable: false,
      },
      rowSelection: 'multiple',
      // loadingCellRenderer: 'customLoadingCellRenderer',
      // loadingCellRendererParams: { loadingMessage: 'One moment please...' },
      // frameworkComponents: {
      //     profileImage: ProfileImage,
      //     // customLoadingCellRenderer: CustomLoadingCellRenderer,
      //     State
      // },
      frameworkComponents: {
        // customLoadingCellRenderer: CustomLoadingCellRenderer,
        Status,
        DateCommon,
        Buttons
      },
      getRowHeight: function (params) {
        return 58;
      },
      rowData: [],
    };
  }

  public gridApi;
  public gridColumnApi;

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.setAutoHeight()
    const updateData = data => {
      this.setState({ rowData: data });
    };

    if (this.props.data) {
      updateData(this.props.data)
    }

  };
  setAutoHeight = () => {
    this.gridApi.setDomLayout('autoHeight');
    const grid: any = document.querySelector('#myGrid');
    if (grid) grid.style.height = '';
  };

  getSelectedRowData = () => {
    let selectedNodes = this.gridApi.getSelectedNodes();
    let selectedData = selectedNodes.map(node => node.data);
    const ids = selectedData.map(item => item.RequestId);
    this.props.postToPay(ids)
  };

  render() {
    const {
      data,
      handleRowChange,
      handleIndexChange,
      pageIndex,
      rowsPerPage,
      history,
      activeTab,
    } = this.props;
    const isApprovedTab = activeTab === '2';
    return (
      <>
        { isApprovedTab ? <Button title="Post to pay" onClick={() => this.getSelectedRowData()} bg="primary" /> : null}
        <div className="table empTable">

          <div className="arrear-table">
            <div
              id="myGrid"
              style={{
                height: '100%',
                width: '100%',
              }}
              className="ag-theme-alpine"
            >
              <AgGridReact
                columnDefs={this.state.columnDefs}
                defaultColDef={this.state.defaultColDef}
                frameworkComponents={this.state.frameworkComponents}
                rowDataChangeDetectionStrategy={ChangeDetectionStrategyType.IdentityCheck}
                onGridReady={this.onGridReady}
                getRowHeight={this.state.getRowHeight}
                rowSelection={this.state.rowSelection}
                rowMultiSelectWithClick={isApprovedTab}
                // loadingCellRenderer={this.state.loadingCellRenderer}
                // loadingCellRendererParams={this.state.loadingCellRendererParams}
                rowData={data}
              //   rowData={this.props.data}
              />
            </div>
            {data && data.length > 0 && (
              <CommonPagination
                handleRowChange={handleRowChange}
                handleIndexChange={handleIndexChange}
                pageIndex={pageIndex}
                rowsPerPage={rowsPerPage}
                total={data[0].TotalRow}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}
export default Tables
