import * as React from "react";
import CommonFilters from "../common/commonFilters";
import TeamReviewBlock from "./reviewBlock";
import { GetEmployeeReviewRequest } from "../actions";
import { connect } from "react-redux";
import isEmpty from "isEmpty";
import { DisplayClientSideMessage } from "actions/commonAction";
import * as employeeRequestService from "services/employeeRequests";

export interface IAppProps {
  GetEmployeeReviewRequest: Function;
  employeeReviewRequests: any[];
  location: any;
  DisplayClientSideMessage: Function;
}

interface State {
  pageNumber: number;
  pageSize: number;
  employeeName: string;
  requestType: string;
  requestStartDate: string;
  requestEndDate: string;
  eventStartDate: string;
  eventEndDate: string;
}
class App extends React.Component<IAppProps, State> {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 0,
      pageSize: 10,
      employeeName: "",
      requestType: "",
      requestStartDate: null,
      requestEndDate: null,
      eventStartDate: null,
      eventEndDate: null,
    };
  }
  componentDidMount() {
    if (!isEmpty(this.props?.location?.state?.RequestType)) {
      const requestType = this.props?.location?.state.RequestType;
      this.setState({ requestType }, () => this.initialize());
    } else {
      this.initialize();
    }
  }
  handleRowChange = (num) => {
    this.setState({ pageSize: num }, () => {
      this.initialize();
    });
  };
  handleIndexChange = (num) => {
    this.setState({ pageNumber: num }, () => {
      this.initialize();
    });
  };
  handleFilterParamChange = (name, value) => {
    this.setState({ [name]: value } as Pick<State, keyof State>, () => {
      this.initialize();
    });
  };
  handleDateRangeChange = (name, startDate, endDate) => {
    this.setState(
      {
        [(name + "StartDate") as any]: startDate,
        [(name + "EndDate") as any]: endDate,
      } as Pick<State, keyof State>,
      () => {
        this.initialize();
      }
    );
  };
  initialize = async () => {
    const {
      pageNumber,
      pageSize,
      employeeName,
      requestType,
      requestStartDate,
      requestEndDate,
      eventStartDate,
      eventEndDate,
    } = this.state;
    let searchData = {
      pageNumber,
      pageSize,
      employeeName,
      requestType,
      requestStartDate,
      requestEndDate,
      eventStartDate,
      eventEndDate,
    };
    await this.props.GetEmployeeReviewRequest(searchData);
  };
  handleApproveRequest = async (leaveRequestDetails) => {
    const { RequestId, RequestType } = leaveRequestDetails;
    let requestData = {
      requestId: RequestId,
      requestType: RequestType,
      //reason: comment,
      reason: "",
    };

    let result = await employeeRequestService.approveReviewRequests(
      requestData
    );
    if (result.Status) {
      await this.initialize();
    }
    this.props.DisplayClientSideMessage(result);
  };
  public render() {
    const { employeeReviewRequests } = this.props;
    return (
      <div className="review-request">
        <CommonFilters
          handleFilterParamChange={this.handleFilterParamChange}
          handleDateRangeChange={this.handleDateRangeChange}
          {...this.props}
          showEmployeeFilter={true}
        />
        <div className="review-request__block employee-request-block">
          <TeamReviewBlock
            request={employeeReviewRequests}
            handleRowChange={this.handleRowChange}
            handleIndexChange={this.handleIndexChange}
            pageIndex={this.state.pageNumber}
            rowsPerPage={this.state.pageSize}
            refreshRequestData={this.initialize}
            handleApproveRequest={this.handleApproveRequest}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeReviewRequests: state.employeeRequestReducer.employeeReviewRequests,
});

const mapDispatchToProps = {
  GetEmployeeReviewRequest,
  DisplayClientSideMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
