import React, { useEffect } from 'react'
import FormGroup from 'components/formGroup'
import FormItem from 'components/formGroup/formItem';
import { useDispatch, useSelector } from 'react-redux';
import { addRosterShift, updateRosterShift } from '../helpers/action';
import { useForm } from 'react-hook-form';
import Button from 'components/button';

function SumHours(smon, fmon) {
    var diff = 0;
    if (smon && fmon) {
        smon = ConvertToSeconds(smon);
        fmon = ConvertToSeconds(fmon);
        diff = Math.abs(fmon - smon);
        return secondsTohhmmss(diff);
    }

    function ConvertToSeconds(time) {
        var splitTime = time.split(":");
        return splitTime[0] * 3600 + splitTime[1] * 60;
    }

    function secondsTohhmmss(secs) {
        var hours = secs / 3600;
        var seconds = secs % 3600;
        var minutes = seconds / 60;
        return Math.floor(hours) + ":" + minutes;
    }
}

const convertToTime = (time) => {
    if (time) {
        const [t, l] = time.split(" ");
        if (l === 'AM') {
            const [hr, min] = t.split(":")
            return ('0' +( parseInt(hr) === 12 ? 0 :parseInt(hr))).slice(-2) + ':' + ('0' + parseInt(min)).slice(-2);
        }
        if (l === 'PM') {
            const [hr, min] = t.split(":")
            const newHr = parseInt(hr) === 12 ? parseInt(hr) : parseInt(hr) + 12;
            return newHr + ':' + min;
        }
    }
}

function AddShift(props) {
    const { onModalClose, isEdit = false, editData = null,getShiftList } = props;
    const method = useForm()
    const {
        watch,
        setValue,
    } = method
    const {
        shiftList,
        teamList
    } = useSelector((state: any) => state.roosterReducer)
    const dispatch = useDispatch();
    const begin = watch("BeginsAt")
    const end = watch("EndsAt")
    const diff = SumHours(begin, end);
    const onSubmit = async (data) => {
        const final = {
            Id: editData?.Id ?? 0,
            Duration: diff,
            ...data
        }
        let res = null;
        if (isEdit) {
            res = await dispatch(updateRosterShift(final))
        }
        else {
            res = await dispatch(addRosterShift(final))
        }
        if (res && res?.Status) {
            onModalClose && onModalClose()
            getShiftList()
        }
    }
    useEffect(() => {
        if (isEdit && editData) {
            editData && Object.keys(editData).map(item => {
                if (item === 'Teams') {
                    const ids = editData[item]?.map(item => item.TeamId)
                    console.log({ ids })
                    setValue("Teams", ids)
                    return;
                }
                if (item === 'BeginsAt' || item === 'EndsAt') {
                    setValue(item, editData[item] && convertToTime(editData[item]))
                    return;
                }
                setValue(item, editData[item])
            });
        }
    }, [isEdit, editData])
    return (
        <div className="shifts-add" >
            <FormGroup method={method} onSubmit={onSubmit}>
                <div className="shifts-add__row">
                    <FormItem
                        label="Shift Name  *"
                        name="Name"
                        rules={{ required: "Shift Name is required!" }}
                    />
                </div>
                <div className="shifts-add__row flex">
                    <FormItem
                        label="Begin Time *"
                        name="BeginsAt"
                        type="time"
                        width="150px"
                        rules={{ required: "Begin Time is required!" }}
                    />
                    <span>To</span>
                    <FormItem
                        label="End Time *"
                        name="EndsAt"
                        type="time"
                        rules={{ required: "End Time is required!" }}
                        width="150px"
                    />
                    {
                        diff && <span>{diff} hours</span>
                    }
                </div>
                <div className="shifts-add__row shifts-add__row-checks">
                    <FormItem
                        label="Applicable to  *"
                        name="Teams"
                        rules={{ required: "Applicable to is required!" }}
                        formName="checkBoxList"
                        items={teamList ? teamList.map(item => ({ label: item.Name, value: item.Id })) : []}
                    // items={teamList}
                    />
                </div>
                <div className="shifts-add__btns">
                    <Button title="Save" htmlButtonType="submit" bg="primary" />
                    <Button title="Cancel" bg="subtle" onClick={() => onModalClose()} />
                </div>
            </FormGroup>
        </div>
    )
}


export default AddShift