import React from 'react'
import { useSelector } from 'react-redux';

function Pending(props) {
    const leaveTimeDashboard = useSelector(state => state.leaveTimeReducer.leaveTimeDashboard );
    const {PendingRequest}  = leaveTimeDashboard;
    return (
        <div className="pending">
            {
                leaveTimeDashboard && PendingRequest?(
                    <ul className="pending-list">
                        {
                            PendingRequest.map(pen=>(
                                <li className="pending-list__item">
                                    <span className="pending-name">{pen.Title}</span>
                                    <span className="pending-value">{pen.Total}</span>
                                </li>
                            ))
                        }
                        
                    </ul>
                ):
                <span className="no-found">No Pending Found</span>
            }
            
        </div>
    )
}

export default Pending
