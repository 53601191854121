import React, { Component } from "react";
import { PieChart, Pie, Sector, Cell, Legend } from "recharts";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

class CustomPieChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      x: 70,
      y: 90,
      innerRadius: 0,
      outerRadius: 70,
    };
    this.renderColorfulLegendText = this.renderColorfulLegendText.bind(this);
  }
  componentDidMount() {
    const { data, innerRadius, outerRadius, x, y } = this.props;
    this.setState({
      data: data ? data : [],
      innerRadius: innerRadius ? innerRadius : 0,
      outerRadius: outerRadius ? outerRadius : 70,
      x: x ? x : 70,
      y: y ? y : 90,
    });
  }
  renderColorfulLegendText(value, entry) {
    const { showPercentageOnly, showValueOnly } = this.props;
    const {
      color,
      payload: { percent },
    } = entry;
    const totalValue = entry.payload.payload.value;

    return (
      <span className="legend-label">
        <span style={{ backgroundColor: color }} className="legend-bg"></span>
        <span className="legend-label-name">{value}</span>
        {showPercentageOnly ? (
          <span className="legend-label-value">{`${(percent * 100).toFixed(
            0
          )}%`}</span>
        ) : showValueOnly ? (
          <span className="legend-label-name">{totalValue}</span>
        ) : (
          <>
            {percent && (
              <span className="legend-label-value">{`${(percent * 100).toFixed(
                0
              )}%`}</span>
            )}
            {totalValue && (
              <span className="legend-label-name">
                {" (" + totalValue + ")"}
              </span>
            )}
          </>
        )}
      </span>
    );
  }
  render() {
    const { data, innerRadius, outerRadius, x, y } = this.state;
    return (
      <div className="pie-chart">
        <PieChart width={350} height={200}>
          <Pie
            data={data}
            cx={x}
            cy={y}
            labelLine={false}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            // label={renderCustomizedLabel}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Legend
            formatter={this.renderColorfulLegendText}
            wrapperStyle
            width={200}
            // cx={1200}
          />
        </PieChart>
        <div className="pie-chart-labels"></div>
      </div>
    );
  }
}

export default CustomPieChart;
