import React from 'react'
import man from 'assets/images/man.png'
import Avatar from "react-avatar";
import { useSelector } from 'react-redux';
import isEmpty from 'isEmpty';
import { useHistory } from 'react-router-dom';
import RouteEnum from 'constants/routeEnum'
import {Link} from 'react-router-dom'
import {getUploadPath} from 'constants/userDetails'


function EmployeeList(props) {
    const {type} = props;
    const hrDashboard = useSelector(state => state.hrReducer.hrDashboard );
    return (
        <div className="employees-list">
            {
                hrDashboard && !isEmpty(hrDashboard[type]) ? 
                    hrDashboard[type].map(renderEmployee) : 
                    <div className="no-found">No Employee Found</div>
            }
        </div>
    )
}

export const NewJoin = () =>{
    const hrDashboard = useSelector(state => state.hrReducer.hrDashboard );
    const history = useHistory();

    return(
        <div className="employees-list">
                {
                    hrDashboard && !isEmpty(hrDashboard.NewJoiningEmployee) ? 
                        hrDashboard.NewJoiningEmployee.map(item=>{
                            return(
                                <div className="employees-list__item employee">
                                    <div className="employee-avatar">
                                        <Avatar
                                            className="employee-logo"
                                            name={item.Name}
                                            size={40}
                                            round={true}
                                            src={getUploadPath(item.UrlPhoto)}
                                        />
                                    </div>
                                    <div className="employee-value">
                                        <EmployeeLink id={item.EmployeeId} name={item.Name}  />
                                        {/* <h5 
                                             onClick={() => history.push('/profile')}
                                            className="employee-name"></h5> */}
                                        <div className="flex">
                                            <span className="position">{item.ToBranch}</span>
                                            <span className="branch">{item.ToDesignation}</span>
                                        </div>
                                        {/* <div className="flex">
                                            <span className="position">{item.FromDesignation}</span>
                                            <span className="branch">{item.ToDesignation}</span>
                                        </div> */}
                                    </div>
                                </div>
                            )
                        }) : 
                        <div className="no-found">No Employee Found</div>
                }
        </div>
    )

}

export const Retiring = () =>{
    const hrDashboard = useSelector(state => state.hrReducer.hrDashboard );
    return(
        <div className="employees-list">
                {
                    hrDashboard && !isEmpty(hrDashboard.RetiringEmployee) ? 
                        hrDashboard.RetiringEmployee.map(item=>{
                            return(
                                <div className="employees-list__item employee">
                                    <div className="employee-avatar">
                                        <Avatar
                                            className="employee-logo"
                                            name={item.Name}
                                            size={40}
                                            round={true}
                                            src={getUploadPath(item.UrlPhoto)}
                                        />
                                    </div>
                                    <div className="employee-value">
                                        <EmployeeLink id={item.EmployeeId} name={item.Name}  />
                                        <div className="flex">
                                            <span className="position">{item.ToBranch}</span>
                                            <span className="branch">{item.ToDesignation}</span>
                                        </div>
                                        {/* <div className="flex">
                                            <span className="position">{item.FromDesignation}</span>
                                            <span className="branch">{item.ToDesignation}</span>
                                        </div> */}
                                    </div>
                                </div>
                            )
                        }) : 
                        <div className="no-found">No Employee Found</div>
                }
        </div>
    )

}

export const LatestEvent = () =>{
    const hrDashboard = useSelector(state => state.hrReducer.hrDashboard );
    return(
        <div className="employees-list">
                {
                    hrDashboard && !isEmpty(hrDashboard.EmployeeLatestEvent) ? 
                        hrDashboard.EmployeeLatestEvent.slice(0,10).map(item=>{
                            return(
                                <div className="employees-list__item employee">
                                    <div className="employee-avatar">
                                        <Avatar
                                            className="employee-logo"
                                            name={item.Name}
                                            size={40}
                                            round={true}
                                            src={getUploadPath(item.UrlPhoto)}
                                        />
                                    </div>
                                    <div className="employee-value">
                                        {/* <a href={`https://hrmsnow.staging.rigoerp.com/employee/profile/${item.EmployeeId}`}>
                                            <span>{item.Name}</span>
                                        </a> */}
                                        <EmployeeLink  id={item.EmployeeId} name={item.Name} />
                                        <div className="flex">
                                            <span className="position">{item.LatestEventName}</span>
                                            
                                        </div>
                                        <div>
                                        <span className="branch text-pink">{item.StatusName}</span>
                                        </div>
                                        {/* <div className="flex">
                                            <span className="position">{item.FromDesignation}</span>
                                            <span className="branch">{item.ToDesignation}</span>
                                        </div> */}
                                    </div>
                                </div>
                            )
                        }) : 
                        <div className="no-found">No Employee Found</div>
                }
        </div>
    )

}

export const ContractExpiring = () =>{
    const hrDashboard = useSelector(state => state.hrReducer.hrDashboard );
    return(
        <div className="employees-list">
                {
                    hrDashboard && !isEmpty(hrDashboard.EmployeeContractExpiring) ? 
                        hrDashboard.EmployeeContractExpiring.map(item=>{
                            return(
                                <div className="employees-list__item employee">
                                    <div className="employee-avatar">
                                        <Avatar
                                            className="employee-logo"
                                            name={item.Name}
                                            size={40}
                                            round={true}
                                            src={getUploadPath(item.UrlPhoto)}
                                        />
                                    </div>
                                    <div className="employee-value">
                                        <EmployeeLink id={item.EmployeeId} name={item.Name}  />
                                        <div className="flex">
                                            <span className="position">{item.ToBranch}</span>
                                            <span className="branch">{item.ToDesignation}</span>
                                        </div>
                                        {/* <div className="flex">
                                            <span className="position">{item.FromDesignation}</span>
                                            <span className="branch">{item.ToDesignation}</span>
                                        </div> */}
                                    </div>
                                </div>
                            )
                        }) : 
                        <div className="no-found">No Employee Found</div>
                }
        </div>
    )

}


export const renderEmployee = (props,index)=>{
    return(
        <div key={index + Math.random()} className="employees-list__item employee">
            <div className="employee-avatar">
                <Avatar
                    className="employee-logo"
                    name={props.Name}
                    size={40}
                    round={true}
                    src={getUploadPath(props.UrlPhoto)}
                />
            </div>
            <div className="employee-value">
                {/* <h5 className="employee-name">{props.Name}</h5> */}
                <EmployeeLink id={props.EmployeeId} name={props.Name}  />
                <div className="flex">
                    <span className="position">{props.ToBranch}</span>
                    <span className="branch">{props.ToDesignation}</span>
                </div>
                {/* <div className="flex">
                    <span className="position">{props.FromDesignation}</span>
                    <span className="branch">{props.ToDesignation}</span>
                </div> */}
            </div>
        </div>
    )
}

const EmployeeLink = ({id,name})=>{
    return(
        // <a 
        //     target="_blank"
        //     href={`/newhr/EmployeeDetails.aspx?Id=${id}`} 
        //     className="employee-name">
        //     <span>{name}</span>
        // </a>
        <Link to={`${RouteEnum.EmployeeProfile}/${id}`}>
            <span>{name}</span>
        </Link>
    )
}

export default EmployeeList
