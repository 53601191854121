import React, { useState, useEffect } from 'react';
import thumbsDown from 'assets/images/icons/thumbsDownColor.svg'
import thumbsUp from 'assets/images/icons/thumbsUpColor.svg'
import comment from 'assets/images/icons/commentBlue.svg'
import man from 'assets/images/man.png';
import Avatar from 'react-avatar';
import { FaTrash, FaEdit, FaFileInvoice } from "react-icons/fa";
import Icons from 'components/icons';
import FormGroup from 'components/form';
import Button from 'components/button';
import Modal from 'components/modal';
import { connect } from "react-redux"
import Form from "components/form";
import classnames from 'classnames';
import IncomeHeadOptions from './incomeHeadOptions';
import isEmpty from "isEmpty";
import { InputValidator } from "helpers/inputValidator";
import { MdAdd } from "react-icons/md";
import AnimatedForm from "components/common/animatedForm";
import {
  DisplayClientSideMessage,
  getLevels,
  GetPayrollIncomeHeads,
} from "actions/commonAction";
import {
  GetDistributedSalary,
  AddSalaryTemplate,
  GetAllSalaryTemplate,
  GetSalaryTemplateDetails,
  UpdateSalaryTemplate,
  DeleteSalaryTemplate
} from "../salaryTemplateAction";
import '../style.scss';



const initialState = {
  date: '',
  open: false,
  templateName: null,
  employeeLevel: null,
  templateOptions: [{
    label: '1',
    value: '1001',
  }, {
    label: '2',
    value: '2001',
  }, {
    label: '3',
    value: '3001',
  }, {
    label: '4',
    value: '4001',
  }],
  templateOption: [{
    label: '',
    value: '',
  }],
  isApplicableIncome: false,
  salaryDistribution: [],
  grossPay: null,
  totalPay: null,
  gross: true,
  basic: false,
  levelLists: null,
  incomeHeadOpen: false,
  payrollIncomeHeads: [],
  openNewTemplate: false,
  templateLists: null,
  errors: null,
  editView: false,
  templateId: 0,
  totalPercentage: 0,
  distributionChanged: false,
};
interface IAppProps {
  employeeId?: any,
  employeeFamiliesDetail?: any,
  getLevels: any,
  GetPayrollIncomeHeads: any,
  levelList: any,
  payrollIncomeHeads: any,
  GetDistributedSalary: any,
  DisplayClientSideMessage: any,
  distributedIncomeByAmount: any,
  AddSalaryTemplate: any,
  saveSalaryTemplate: any,
  GetAllSalaryTemplate: any,
  salaryTemplateList: any,
  GetSalaryTemplateDetails: any,
  salaryTemplateDetail: any,
  UpdateSalaryTemplate: any,
  DeleteSalaryTemplate: any,
  handleFormDisplay: any,
  showForm: any,
  closeForm: any,
  editViewData?: any,
  editView: any,
  editedPayrollIncomeHeads: any,
}

interface State {
  date: any,
  open: any,
  templateName: any,
  employeeLevel: any,
  templateOptions: any,
  templateOption: any,
  isApplicableIncome: any,
  salaryDistribution: any,
  grossPay: any,
  totalPay: any,
  gross: any,
  basic: any,
  levelLists?: any,
  incomeHeadOpen?: any,
  payrollIncomeHeads: any,
  openNewTemplate: boolean,
  templateLists: any,
  errors?: any,
  editView?: boolean
  templateId?: any,
  totalPercentage: any,
  distributionChanged: any,
}

class index extends React.Component<IAppProps, State> {

  state = initialState

  currencyCommaSeperator = (num) => {
    if (isNaN(num)) return "0";
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  }
  
  componentDidMount = async () => {
    const { editView, editViewData, getLevels,
      GetPayrollIncomeHeads, GetAllSalaryTemplate } = this.props
    await getLevels();
    if (!editView) {
      await GetPayrollIncomeHeads();
    }
    // await GetAllSalaryTemplate();
    let { levelList, editedPayrollIncomeHeads,
      payrollIncomeHeads, salaryTemplateList, } = this.props;

    let levelLists = levelList.map((x) => {
      return { value: x.Value, label: x.Text };
    })
    if (!editView && payrollIncomeHeads) {
      payrollIncomeHeads = payrollIncomeHeads.map(p => {
        return {...p, checked: true}
      })
    };
    if (editView) {
      this.setState({ ...editViewData, levelLists,
        payrollIncomeHeads: editedPayrollIncomeHeads,
        editView: true })
    } else
      this.setState({ levelLists, payrollIncomeHeads });

  }
  componentDidUpdate(prevProps,prevState){
    let { levelList, payrollIncomeHeads, salaryTemplateList } = this.props;
    if(levelList && levelList !== prevProps.levelList){
      let levelLists = levelList.map((x) => {
        return { value: x.Value, label: x.Text };
      })
      this.setState({ levelLists })
    }
    if(payrollIncomeHeads && payrollIncomeHeads !== prevProps.payrollIncomeHeads){
      payrollIncomeHeads = payrollIncomeHeads.map(p => {
        return {...p, checked: false}
      })
      this.setState({ payrollIncomeHeads },()=>console.log(this.state))
    }
    // if(salaryTemplateList && salaryTemplateList !== prevProps.salaryTemplateList){
    //   this.setState({ templateLists : salaryTemplateList },()=>console.log(this.state))
    // }
  }

  handleFieldChange = (name, value) => {
    if (name == 'gross' || name == 'basic') {
      let alterField = name == 'gross' ? 'basic' : 'gross';
      let { salaryDistribution } = this.state;
      let selectedSalary = salaryDistribution.find((s) => s.abbreviation == 'BS');
      if (name == 'basic') {
        selectedSalary['percentages'] = 100;
        salaryDistribution = salaryDistribution.map(sal => {
          if (sal.abbreviation == 'BS') return {
              ...sal,
              percentages: 100
            }
          else return {
              ...sal,
              percentages: sal.percentage || sal.percentages
            }
        });
      }
      this.changeDistributionPercentage('percentages', '100', selectedSalary);
      this.setState({ [name]: true, [alterField] : false, salaryDistribution, distributionChanged: true } as Pick<State, keyof State>, () => {
        this.updateErrors()
      });
    } else {
      this.setState({ [name]: value, /* totalPercentage: 0, */ distributionChanged: true } as Pick<State, keyof State> , () => {
        this.updateErrors();
      });
    }
  }

  updateErrors = async () => {
    const { errors } = this.state;
    if (!isEmpty(errors)) {
    let errors = await InputValidator(document, this.state);
    this.setState({ errors });
    }
  };

  changeDistributionPercentage = async (name, value, dist) => {
    let { salaryDistribution } = this.state;
    let selectedSalary = salaryDistribution.find((s) => s.incomeId == dist.incomeId);
    selectedSalary[name] = value ? parseFloat(value) : '';
    // selectedSalary['percentages'] = value ? parseFloat(value) : '';
    // salaryDistribution.forEach(s => {
      //   totalPercentage = totalPercentage + (s.percentages ? parseFloat(s.percentages) : 0);
      // });
    let totalPercentage = 0;
    let coDependentId = [];
    let basicPercent;

    await salaryDistribution.forEach(sal => {
      if(sal.incomeId == dist.parentIncomeId && dist.parentIncomeId !== 0) coDependentId.push(sal.incomeId);
      if (sal.abbreviation == 'BS') basicPercent = sal.percentages;
    }) 
    if (dist.abbreviation == 'BS') {
      let calcPercent;
      salaryDistribution = salaryDistribution.map(sal => {
        if (sal.type !== 'Fixed') {
          let calcPercent = sal.percentage;
          
          salaryDistribution.forEach(s => {
            if(s.parentIncomeId == sal.incomeId) {
              calcPercent += s.percentages
            }
          });
          

          return {
            ...sal,
            percentages: parseFloat((calcPercent * parseFloat(value) / 100).toString()).toFixed(2)
          }
        } else {
          return sal
        }
      })
      // salaryDistribution = salaryDistribution.map(sal => {
      //   if (sal.abbreviation !== 'BS') {
      //     calcPercent = sal.percentage ? parseFloat(((sal.percentage * basicPercent)/100).toFixed(2)) : value;
      //     totalPercentage += calcPercent;
      //     if (coDependentId.includes(sal.incomeId)) {
      //         let parentIncome = salaryDistribution.find(s => (s.parentIncomeId == sal.incomeId && sal.incomeId !== 0));
      //         // let parentIncome = salaryDistribution.find(s => s.parentIncomeId == sal.incomeId);
      
      //       calcPercent = ((sal.percentages ? sal.percentages : 0) + (parentIncome.percentages ? parentIncome.percentages : 0) * basicPercent)/100
      //         // calcPercent = (sal.percentage + parentIncome.percentages)/100;
      //       // parentIncome.percentages = calcPercent;
      
      //     }
      
      //     return {
      //       ...sal,
      //       percentages: parseFloat(calcPercent).toFixed(2)
      //     }
      //   } else {
      //     basicPercent = parseFloat(sal.percentages).toFixed(2);
      //     totalPercentage = totalPercentage + (basicPercent ? parseFloat(basicPercent) : 0);
      //     return {
      //       ...sal,
      //       percentages: parseFloat(value)
      //     };
      //   }
      // })
    } else {
      let calcDependentPercent = 0;
      // if (selectedSalary.parentIncomeId !== 0) {
        salaryDistribution.find(s => {
          if(coDependentId.includes(s.incomeId)) {
            calcDependentPercent += s.percentage;
          }
        });

        salaryDistribution = salaryDistribution.map(sal => {
          if (coDependentId.includes(sal.incomeId)) {
            
            return {
              ...sal,
              percentages: parseFloat(((calcDependentPercent + parseFloat(value))*basicPercent / 100).toString()).toFixed(2)
            }
          } else {
            return sal
          }
        })
        
        
        // let selectedSalary = salaryDistribution.find((s) => s.parentIncomeId == dist.parentIncomeId);
        // selectedSalary[name] = value ? ((parseFloat(value) + parseFloat(selectedSalary.percentage ? selectedSalary.percentage : 0)) * basicPercent)/100: '';
      // } else {
      }
      salaryDistribution.forEach(s => {
        totalPercentage = totalPercentage + (s.percentages ? parseFloat(s.percentages) : 0);
      });
    this.setState({ distributionChanged: true, salaryDistribution, totalPercentage: Math.round(totalPercentage * 100)/100 });
    // this.distributeSelectedSalary();
  }

  distributeSelectedSalary = () => {
    const { gross, basic, grossPay, salaryDistribution, totalPercentage } = this.state;
    let totalBal = parseInt(grossPay);
    if(isEmpty(totalBal)  || totalBal <= 0 || isNaN(totalBal)) {
      this.props.DisplayClientSideMessage('Salary to distribute is required')
      return
    };

      this.setState({ /* salaryDistribution, */ totalPay: totalBal }, async () => {
        let data = {
          isGrossPay: gross,
          payAmount: totalBal,
          salaryArray: []
        }
        let totalIncomePercent = 0;
        salaryDistribution.forEach(sal => {
          totalIncomePercent = totalIncomePercent + (sal.percentages ? parseFloat(sal.percentages) : 0)
        });
        
        if (totalIncomePercent != 100 && gross == true) {
          this.props.DisplayClientSideMessage('Select Income Heads and adjust their Income percentage before distribution')
          return
        }
        salaryDistribution.forEach(sal => {
          data.salaryArray.push({
              "templateRefId": sal.templateRefId,
              "incomeId": sal.incomeId,
              "percentage": sal.percentages,
              "amount": 0,
              "title": sal.title,
              "type": sal.type,
              "abbreviation": sal.abbreviation,
              "calculation": sal.calculation,
              parentIncomeId: sal.parentIncomeId
          })
        })
        
        await this.props.GetDistributedSalary(data);
        const { distributedIncomeByAmount } = this.props;
        let newSalDist = [];
        if (distributedIncomeByAmount) {
          
          salaryDistribution.forEach(sal => {
            distributedIncomeByAmount.SalaryTemplateDetailDto.forEach(dis => {
            if (sal.incomeId == dis.IncomeId) {
              sal.amount = dis.Amount.toFixed(2);
              newSalDist.push(sal);
            }
          });
        });
      }
        this.setState({ totalPay: distributedIncomeByAmount.TotalAmount, distributionChanged: false, /* totalPercent: distributedIncomeByAmount.PercentageSum, */ salaryDistribution: newSalDist });
      });
    // }
  }

  onIncomeHeadChecked = (e, selected) => {
    const { payrollIncomeHeads } = this.state;
    let selectedSalary = payrollIncomeHeads.find((s) => s.IncomeId == selected.IncomeId);
    selectedSalary['checked'] = e.target.checked;
    if(selected.Abbreviation == 'PF' || selected.Abbreviation == "SSF") {
      if (e.target.checked == true) {
        let oppSite = selected.Abbreviation == 'PF' ? 'SSF' : 'PF';
        let selectedSalary = payrollIncomeHeads.find((s) => s.Abbreviation == oppSite);
        selectedSalary['checked'] = !e.target.checked;
      }
    }
    this.setState({ payrollIncomeHeads, distributionChanged: true });
  }

  addIncomeHeads = async () => {
    const { salaryDistribution, payrollIncomeHeads, editView, templateId } = this.state;
    let salaryArray = []
    let totalPercentage = 0;
    await payrollIncomeHeads.forEach((pay, i) => {
      if(pay.checked == true || i == 0) {
        let ratePercent = pay.RatePercent ? pay.RatePercent : 0;
        let amount = 0;
        if (editView) {
          let selectedSalaryDist = salaryDistribution.find(s => s.incomeId == pay.IncomeId);
          if (selectedSalaryDist) {
            ratePercent = selectedSalaryDist.percentages;
            amount = selectedSalaryDist.amount;
          }
        }

        salaryArray.push({
          title: pay.Title,
          type: pay.Type,
          percentage: pay.RatePercent,
          percentages: ratePercent,
          amount,
          incomeId: pay.IncomeId,
          abbreviation: pay.Abbreviation,
          calculation: pay.Calculation,
          templateRefId: templateId,
          parentIncomeId: pay.ParentIncomeId
        });
        totalPercentage = totalPercentage + (ratePercent ? parseFloat(ratePercent) : 0);
      }
    });
    this.setState({ distributionChanged: true, salaryDistribution: salaryArray, incomeHeadOpen: false, totalPercentage: Math.round(totalPercentage * 100)/ 100 });
  }

  handleFormSubmit = async () => {
    let { templateOptions, templateOption, grossPay,
      salaryDistribution, gross, basic, totalPay, templateName,
      totalPercentage, distributionChanged,
      employeeLevel, levelLists, incomeHeadOpen, payrollIncomeHeads,
      openNewTemplate, editView, templateId } = this.state;

      let errors = await InputValidator(document, this.state);
      if (!isEmpty(errors)) {
        this.setState({ errors });
        return;
      }

      if(isEmpty(grossPay)  || grossPay <= 0 || isNaN(grossPay)) {
        this.props.DisplayClientSideMessage('Salary to distribute is required')
        return
      };
      // let totalIncomePercent = 0;
      // salaryDistribution.forEach(sal => {
      //   totalIncomePercent = totalIncomePercent + (sal.percentages ? sal.percentages : 0)
      // });
      if (distributionChanged == true) {
        this.props.DisplayClientSideMessage('Re-distribute salary income before saving')
        return
      }
      if ((totalPercentage != 100 && gross == true)) {
        this.props.DisplayClientSideMessage('Adjust Income percentage before distribution')
        return
      }
      totalPercentage = 0;
      salaryDistribution = salaryDistribution.map(sal => {
        totalPercentage += parseFloat(sal.percentages);
        return {
          ...sal,
          percentage: parseFloat(sal.percentages)
        }
      });

      if ((totalPercentage != 100 && gross == true)) {
        this.props.DisplayClientSideMessage('Adjust Income percentage before distribution')
        return
      }
    
      let templateData = {
        "salaryTemplateDto": {
          "templateId": editView ? templateId : 0,
          "templateName": templateName,
          "levelId": employeeLevel.value,
          "isOnGrossDistribution": gross,
          "grossPay": grossPay,
          "basicPay": grossPay,
        },
        "salaryTemplateDetailDto": salaryDistribution
      }
      
      if (editView) await this.props.UpdateSalaryTemplate(templateData);
      else await this.props.AddSalaryTemplate(templateData);
      const { saveSalaryTemplate } = this.props;
      if (saveSalaryTemplate) {
        // this.setState({ openNewTemplate: false });
        this.props.closeForm()
        this.props.GetAllSalaryTemplate();
        this.setState({
          errors: {},
        })
      }
  }


  render() {
    const { templateOptions, templateOption, grossPay,
      salaryDistribution, gross, basic, totalPay, templateName,
      employeeLevel, levelLists, incomeHeadOpen, payrollIncomeHeads,
      openNewTemplate, templateLists, errors, editView, totalPercentage
    } = this.state;

    const { salaryTemplateList } = this.props;

    return (
      <div className="salaryTemplate">
        <div className="salaryTemplate-form">
          <div className="salaryTemplate-form__header">
            Pay Template
          </div>
          <div className="salaryTemplate-form__body">
            <div className="form-row">
              <FormGroup
                  autoFocus
                  name="templateName"
                  value={templateName}
                  onChange={this.handleFieldChange}
                  label="Template Name"
                  error={errors?.templateName}
                  width="500px"
                  validators={["required"]}
                  // disabled={isViewMode}
                />
            </div>
            <div className="form-row salaryTemplate-placeunder">
                Place Under
            </div>
            <div className="form-row">
              <FormGroup
                formName="reactselect"
                onChange={(name, value) => this.handleFieldChange(name, value)}
                // onChange={() => console.log("clos")}
                name="employeeLevel"
                value={employeeLevel}
                options={levelLists}
                // loading={jobProfileList.loading}
                loadingType="skeleton"
                placeholder="Select"
                // disabled={isViewMode}
                validators={["required"]}
                error={errors?.employeeLevel}
                width="500px"
                label="Level"
              />
            </div>
            <div className="form-row applicable-incomes">
            <div className="salaryTemplate-header">
              <Button
                title="Select Income Heads"
                bg="primary"
                onClick={() => this.setState({ incomeHeadOpen: true })}
              />
            </div>
              <Modal
                open={incomeHeadOpen}
                onModalClose={() => this.setState({ incomeHeadOpen: false })}
                title="Applicable Income"
                >
                  <IncomeHeadOptions 
                    title="Applicable Incomes" 
                    onModalClose={() => this.setState({ incomeHeadOpen: false })}
                    incomeHeads={payrollIncomeHeads}
                    onIncomeHeadChecked={this.onIncomeHeadChecked}
                    addIncomeHeads={this.addIncomeHeads}
                  />
              </Modal>
            </div>
            <div className="form-row flex">
              <FormGroup
                formName="radiogroup"
                onChange={(name, value) => this.handleFieldChange(name, !value)}
                name="gross"
                value={gross}
                // options={templateOptions}
                checked={gross}
                // loading={jobProfileList.loading}
                loadingType="skeleton"
                placeholder="Select"
                // disabled={isViewMode}
                // validators={["required"]}
                // error={errors?.gross}
                width="200px"
                multiple
                label="Gross distribution"
              />
              <FormGroup
                formName="radiogroup"
                onChange={(name, value) => this.handleFieldChange(name, value)}
                name="basic"
                value={basic}
                // options={templateOptions}
                checked={basic}
                // loading={jobProfileList.loading}
                loadingType="skeleton"
                placeholder="Select"
                // disabled={isViewMode}
                // validators={["required"]}
                // error={errors?.basic}
                width="200px"
                multiple
                label="Calculation on basic"
              />
            </div>
            <div className="form-row flex">
              <FormGroup
                  name="grossPay"
                  value={grossPay}
                  onChange={this.handleFieldChange}
                  label={gross ? "Gross Pay" : "Basic Pay"}
                  error={errors?.grossPay}
                  type="number"
                  width="200px"
                  validators={["required"]}
                  // disabled={isViewMode}
                  minValue={"0"}
                />
              <Button
                title={gross ? "Distribute" : "Calculate"}
                bg="primary"
                onClick={() => this.distributeSelectedSalary()}
              />
            </div>
            <div className="salary-distribution">
              <div className="salary-distribution-mainheader">
                <span className="salaryTemplate-table-header">
                  Pay Name
                </span>
                <span className="salaryTemplate-table-header">
                  Type
                </span>
                <span className="salaryTemplate-table-header">
                  Percentage
                </span>
                <span className="salaryTemplate-table-header">
                  Amount
                </span>
              </div>
              <div className="salary-distribution-mainheader">
                {salaryDistribution.map(s => <><span className="salaryTemplate-table-body ">
                  {s.title}
                </span>
                <span className="salaryTemplate-table-body ">
                  {s.type}{s.percentage > 0 ? `(${s.percentage})%` : ''}
                </span>
                <span className="salaryTemplate-table-body salaryTemplate-amount flex">
                  <FormGroup
                    // autoFocus
                    type="number"
                    name="percentages"
                    value={s.percentages}
                    onChange={(name, value) => this.changeDistributionPercentage(name, value, s)}
                    minValue={"0"}
                    // label="Template Name"
                    // error={errors?.jobTitle}
                    // width="500px"
                    // validators={["required"]}
                    disabled={s.type !== 'Fixed' || (s.abbreviation == 'BS' && gross == false)}
                  />
                </span>
                <span className="salaryTemplate-table-body salaryTemplate-amount flex">
                  Rs. {this.currencyCommaSeperator(s.amount)}
                </span>
                </>)}
              </div>
              <div className="salary-distribution-mainheader">
                <span className="salaryTemplate-table-body ">
                  Total
                </span>
                <span className="salaryTemplate-table-body ">
                </span>
                <span className="salaryTemplate-table-body salaryTemplate-amount salaryTemplate-percentage flex">
                  {gross ? totalPercentage : null}
                </span>
                <span className="salaryTemplate-table-body salaryTemplate-amount flex">
                  Rs. {this.currencyCommaSeperator(Math.floor(totalPay).toFixed(2)) || 0}
                </span>
              </div>
            </div>
          </div>
          <div className="salaryTemplate-form__footer flex justify-end">
              <Button
                bg="highlight"
                title="Cancel"
                onClick={this.props.closeForm}
              />
              <Button
                bg="primary"
                title={editView ? "Update" : "Save"}
                onClick={() => this.handleFormSubmit()}
              />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  employeeFamiliesDetail: state.employeeProfile.employeeFamiliesDetail,
  levelList: state.commonReducer.levelList,
  payrollIncomeHeads: state.commonReducer.payrollIncomeHeads,
  distributedIncomeByAmount: state.salaryTemplateReducer.distributedIncomeByAmount,
  saveSalaryTemplate: state.salaryTemplateReducer.saveSalaryTemplate,
  salaryTemplateList: state.salaryTemplateReducer.salaryTemplateList,
  salaryTemplateDetail: state.salaryTemplateReducer.salaryTemplateDetail,
});

const mapDispatchToProps = {
  getLevels,
  GetPayrollIncomeHeads,
  GetDistributedSalary,
  DisplayClientSideMessage,
  AddSalaryTemplate,
  GetAllSalaryTemplate,
  GetSalaryTemplateDetails,
  UpdateSalaryTemplate,
  DeleteSalaryTemplate
};

export default AnimatedForm()(connect(mapStateToProps, mapDispatchToProps)(index));