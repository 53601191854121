import React, { Component } from "react";

// interface RouteBaseClass {
//   authorizedRoles: "Administrator" | "Employee" | "Anonymous";
// }
// class RouteBaseClass extends Component {
//   constructor(props: any, authorizedRoles: any) {
//     super(props);
//     if (authorizedRoles && authorizedRoles.length > 0) {
//       const userDetail = JSON.parse(localStorage.UserDetail);
//       const userRole = userDetail.RoleName;
//       const isValid = authorizedRoles.find(
//         (f: any) => f === userRole || f === "Anonymous"
//       );

//       if (!isValid) {
//         props.history.replace("/unauthorized");
//       }
//     } else {
//       props.history.replace("/unauthorized");
//     }
//   }
// }

class RouteBaseClass extends Component {
  constructor(props: any, authorizedRoles: any) {
    super(props);
    if (authorizedRoles && authorizedRoles.length > 0) {
      const userDetail = JSON.parse(localStorage.UserDetail);
      const userRole = userDetail.RoleName;
      // const isValid = authorizedRoles.find(
      //   (f: any) => f === userRole || f === "Anonymous"
      // );
      const EMP = 'Employee'
      if(userRole === EMP){
        if(!authorizedRoles.includes(EMP)){
            props.history.replace("/unauthorized");
        }
      }
    } else {
      props.history.replace("/unauthorized");
    }
  }
}




export default RouteBaseClass;
