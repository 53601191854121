import Http from 'src/utils/http/http';
import Action from 'src/utils/http/actionUtility';
import environment from 'environment';
import types from 'constants/types';
import RouteEnum from 'constants/routeEnum';

export const getCourseEnrollment = () =>async dispatch =>{
    const res = Http.get(environment.api.getCourseEnrollment)
    const actionConfig = {
		dispatch,
		actionType:types.GET_COURSE_ENROLLMENT,
		effect:res,
        isSelect:true,
        isValueKey:true,
        isJustData:true,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}


export const getShowCoursesTo = () =>async dispatch =>{
    const res = Http.get(environment.api.getShowCoursesTo)
    const actionConfig = {
		dispatch,
		actionType:types.GET_SHOW_COURSE_TO,
		effect:res,
        isSelect:true,
        isValueKey:true,
        isJustData:true,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}


export const getCourseNavigationPolicies = () =>async dispatch =>{
    const res = Http.get(environment.api.getCourseNavigationPolicies)
    const actionConfig = {
		dispatch,
		actionType:types.GET_COURSE_COURSE_NAVIGATION_POLICY,
		effect:res,
        isSelect:true,
        isValueKey:true,
        isJustData:true,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}


export const getCoursesOptions = (courseId) =>async dispatch =>{
    const res = Http.get(environment.api.courseOptions +'/?courseId=' +courseId)
    const actionConfig = {
		dispatch,
		actionType:types.GET_COURSE_OPTIONS,
		effect:res,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}


export const addCoursesOptions = (data) =>async dispatch =>{
    const res = Http.post(environment.api.courseOptions,data)
    const actionConfig = {
		dispatch,
		actionType:types.ADD_COURSE_OPTIONS,
		effect:res,
        message:'Course Options added successfully',
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}


export const updateCoursesOptions = (data) =>async dispatch =>{
    const res = Http.put(environment.api.courseOptions,data)
    const actionConfig = {
		dispatch,
		actionType:types.ADD_COURSE_OPTIONS,
		effect:res,
        message:'Course Options updated successfully',
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}


export const getCourse = (courseId) =>async dispatch =>{
    const res = Http.get(environment.api.courses + '/' +courseId)
    const actionConfig = {
		dispatch,
		actionType:types.GET_COURSE,
		effect:res,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}


export const getEmployeeCourse = (courseId,empId) =>async dispatch =>{
    const res = Http.get(environment.api.courseDetails + `/?courseId=${courseId}&employeeId=${empId}`)
    const actionConfig = {
		dispatch,
		actionType:types.GET_COURSE,
		effect:res,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}



export const getCoursePages = (courseId) =>async dispatch =>{
    const res = Http.get(environment.api.coursePages + '?courseId=' +courseId)
    const actionConfig = {
		dispatch,
		actionType:types.GET_COURSE_PAGES,
		effect:res,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}


export const getCoursePage = (pageId) =>async dispatch =>{
    const res = Http.get(environment.api.coursePages + '/' +pageId)
    const actionConfig = {
		dispatch,
		actionType:types.GET_COURSE_PAGE,
		effect:res,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}


export const addCoursePage = (data,history=null) =>async dispatch =>{
    const res = Http.post(environment.api.coursePages,data)
    const actionConfig = {
		dispatch,
		actionType:types.ADD_COURSE_PAGE,
		effect:res,
        isLoading:true
	}
    const response = await Action.createThunkEffect(actionConfig);
    if(response && response.Status){
        history.push(RouteEnum.lmsCourseAdd + '/' + response.Data.CourseId  +'/' +response.Data.Id )
    }
}


export const updateCoursePage = (data) =>async dispatch =>{
    const res = Http.put(environment.api.coursePages,data)
    const actionConfig = {
		dispatch,
		actionType:types.UPDATE_COURSE_PAGE,
		effect:res,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}

export const updateMultipleCoursePage = (data) =>async dispatch =>{
    const res = Http.put(environment.api.courseMultiplePages,data)
    const actionConfig = {
		dispatch,
		actionType:types.GET_COURSE_PAGES,
		effect:res,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}


export const deleteCoursePage = (pageId) =>async dispatch =>{
    const res = Http.delete(environment.api.coursePages + '/' +pageId)
    const actionConfig = {
		dispatch,
		actionType:types.DELETE_COURSE_PAGE,
		effect:res,
        type:'delete',
        id:pageId,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}


export const addCourse = (data,history=null) => async dispatch => {
    const res = Http.post(environment.api.courses,data);
    const actionConfig = {
		dispatch,
		actionType:types.Add_COURSE,
        message:'Sucessully Added Course',
        type:'post',
		effect:res,
        isLoading:true
	}
    const response = await Action.createThunkEffect(actionConfig);
    if(response && response.Status){
        history.push(RouteEnum.lmsCourseAdd +'/' +response.Data.Id)
    }
}


export const updateCourse = (data) => async dispatch => {
    const res = Http.put(environment.api.courses,data);
    const actionConfig = {
		dispatch,
		actionType:types.Update_COURSE,
        message:'Sucessully Updated Course',
		effect:res,
        isLoading:true
	}
    const response = await Action.createThunkEffect(actionConfig);
    if(response && response.Status){
        // history.push(RouteEnum.Login)
    }
}


export const getCourseList = (filters) => async dispatch => {
    const res = Http.post(environment.api.courseList,filters);
    const actionConfig = {
		dispatch,
		actionType:types.GET_COURSE_LIST,
		effect:res,
        isLoading:true
	}
    const response = await Action.createThunkEffect(actionConfig);
}


export const getCourseCategories = () => async dispatch => {
    const res = Http.get(environment.api.courseCategories);
    const actionConfig = {
		dispatch,
		actionType:types.GET_COURSE_CATEGORIES,
		effect:res,
        isSelect:true,
        isLoading:true
	}
    const response = await Action.createThunkEffect(actionConfig);
}


export const getAssignedCourses = (id) => async dispatch => {
    const res = Http.get(environment.api.assignCourses+'?courseId='+id);
    const actionConfig = {
		dispatch,
		actionType:types.GET_ASSIGNED_COURSE,
		effect:res,
        isLoading:true
	}
    const response = await Action.createThunkEffect(actionConfig);
}

export const assignCourse = (data) => async dispatch => {
    const res = Http.post(environment.api.assignCourses,data);
    const actionConfig = {
		dispatch,
        message:'Assigned course to user successfully',
		actionType:types.ASSIGN_COURSES,
		effect:res,
        isLoading:true
	}
    const response = await Action.createThunkEffect(actionConfig);
}

export const getCourseContent = (id) => async dispatch => {
    const res = Http.get(environment.api.courseContent+'?courseOutlineId='+id);
    const actionConfig = {
		dispatch,
		actionType:types.GET_COURSE_CONTENT,
		effect:res,
        isLoading:true
	}
    const response = await Action.createThunkEffect(actionConfig);
}


export const addCourseContent = (data) => async dispatch => {
    const res = Http.post(environment.api.courseContent,data);
    const actionConfig = {
		dispatch,
		actionType:types.ADD_COURSE_CONTENT,
		effect:res,
        isLoading:true
	}
    const response = await Action.createThunkEffect(actionConfig);
}


export const updateCourseStatus = (courseId,status) => async dispatch => {
    const res = Http.put(environment.api.updateCourseState+`?courseId=${courseId}&status=${status}`);
    const actionConfig = {
		dispatch,
		actionType:types.EDIT_COURSE_STATUS,
		effect:res,
        extraPayload:{courseId,status},
        isLoading:true
	}
    const response = await Action.createThunkEffect(actionConfig);
}

export const employeeCourseView = (data) => async dispatch => {
    const res = Http.post(environment.api.courseView,data);
    const actionConfig = {
		dispatch,
		actionType:'types.EDIT_COURSE_STATUS',
		effect:res,
        isLoading:true
	}
    const response = await Action.createThunkEffect(actionConfig);
}


export const updateCourseContent = (data) => async dispatch => {
    const res = Http.put(environment.api.courseContent,data);
    const actionConfig = {
		dispatch,
		actionType:types.UPDATE_COURSE_CONTENT,
		effect:res,
        isLoading:true
	}
    const response = await Action.createThunkEffect(actionConfig);
}


export const deleteCourseContent = (id) => async dispatch => {
    const res = Http.delete(environment.api.courseContent +'?id=' + id);
    const actionConfig = {
		dispatch,
        id,
        type:'delete',
		actionType:types.DELETE_COURSE_CONTENT,
		effect:res,
        isLoading:true
	}
    const response = await Action.createThunkEffect(actionConfig);
}


export const getAssignedCoursesDashboad = (data) =>async dispatch =>{
    const res = Http.post(environment.api.assignCoursesDashboard,data)
    const actionConfig = {
		dispatch,
		actionType:types.GET_ASSIGNED_COURSE_DASHBOARD,
		effect:res,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}


export const getCoursesDashboad = (data) =>async dispatch =>{
    const res = Http.post(environment.api.coursesDashboard,data)
    const actionConfig = {
		dispatch,
		actionType:types.GET_COURSE_DASHBOARD,
		effect:res,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}

export const getQuestionDetails = (coursePageId) =>async dispatch =>{
    const res = Http.get(environment.api.getQuestionAnswers +'?coursePageId=' + coursePageId)
    const actionConfig = {
		dispatch,
		actionType:types.GET_COURSE_QUESTION_DETAILS,
		effect:res,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}

export const getBranchTable = (data) =>async dispatch =>{
    const res = Http.post(environment.api.getBranchDetails,data)
    const actionConfig = {
		dispatch,
		actionType:types.GET_BRANCHES_DETAILS,
		effect:res,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}

export const getDepartmentTable = (data) =>async dispatch =>{
    const res = Http.post(environment.api.getDepartmentDetails,data)
    const actionConfig = {
		dispatch,
		actionType:types.GET_DEPARTMENT_DETAILS,
		effect:res,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}


export const addCourseQuestion = (data) => async dispatch => {
    const res = Http.post(environment.api.courseQuestions,data);
    const actionConfig = {
		dispatch,
		actionType:types.ADD_COURSE_QUESTION_DETAILS,
		effect:res,
        isLoading:true
	}
    const response = await Action.createThunkEffect(actionConfig);
}

export const deleteCourseQuestion = (id) => async dispatch => {
    const res = Http.delete(environment.api.courseQuestions + '/' + id);
    const actionConfig = {
		dispatch,
        id,
        type:'delete',
		actionType:types.DELETE_COURSE_QUESTION_DETAILS,
		effect:res,
        isLoading:true
	}
    const response = await Action.createThunkEffect(actionConfig);
}


export const postAnswers = (data) =>async dispatch =>{
    const res = Http.post(environment.api.addAnswers,data)
    const actionConfig = {
		dispatch,
		actionType:types.ADD_ANSWER,
		effect:res,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}

export const getAnswers = (empId,pageId) =>async dispatch =>{
    const res = Http.get(environment.api.addAnswers +`/${empId}?coursePageId=${pageId}`)
    const actionConfig = {
		dispatch,
		actionType:types.GET_ANSWERS,
		effect:res,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}


export const getCourseCompleted = (empId,courseId) =>async dispatch =>{
    const res = Http.get(environment.api.courseCompleted +`?employeeId=${empId}&courseId=${courseId}`)
    const actionConfig = {
		dispatch,
		actionType:types.GET_COURSE_COMPLETED,
		effect:res,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}

export const addCourseCompleted = (data) =>async dispatch =>{
    const res = Http.post(environment.api.courseCompleted,data)
    const actionConfig = {
		dispatch,
		actionType:types.ADD_COURSE_COMPLETED,
		effect:res,
        isLoading:true
	}
    await Action.createThunkEffect(actionConfig);
}