import React from 'react'
import CommonModuleLinks from 'components/common/commonModuleLinks'

export const modules = (features) => [
    {
        groupTitle:'Import',
        feature:features?.CoreHR,
        links: [
            { title:'Income Import', link:'CP/Extension/VariableSalaryImport.aspx'},
            { title:'Deduction Import', link:'CP/Extension/VariableDeductionImport.aspx'},
            { title:'Deemed Income Import', link:'CP/Extension/DeemedSalaryImport.aspx'},
            { title:'Simple Intrest Load Import', link:'newhr/SILoanImport.aspx'},
            { title:'EMI Loan Import', link:'newhr/EMILoanImport.aspx'},
            { title:'Variable Loan Import', link:'cp/Extension/variableloandisplay.aspx'},
            { title:'Loan Repayment Import', link:'newhr/LoanRepayment.aspx'},
            { title:'Tax Status Import', link:'newhr/EmpMaritalAndTaxStatusDetails.aspx'},
        ]
    },
    {
        groupTitle:'Adjustment',
        feature:features?.CoreHR,
        links: [
            { title:'Income Adjustment', link:'CP/IncomeDeductionAdjustment.aspx'},
            { title:'Mass Increment', link:'CP/MassIncrement.aspx'},
            { title:'Loan Adjustment', link:'CP/LoanAdjustment.aspx'},
            { title:'EMI Adjustment', link:'CP/EMIAdjustment.aspx'},
            { title:'Advanced Adjustment', link:'CP/AdvanceAdjustment.aspx'},
            { title:'Festival Income Adjustment', link:'CP/FestivalIncomeAdjustment.aspx'},
            { title:'Past Regular Adjustment', link:'CP/pastregularincomeadjustment.aspx'},
        ]
    },
    {
        groupTitle:'Tax Planning',
        feature:features?.CoreHR,
        links: [
            { title:'Optimum CIT', link:'CP/OptimumPFAndCIT.aspx'},
            { title:'Define CIT', link:'CP/PrivisionalCIT.aspx'},
            { title:'Define RF Fund', link:'CP/PrivisionalCIT2.aspx'},
            { title:'Charity and Donation', link:'newhr/CharityList.aspx'},
            { title:'Life/Health Insurance', link:'CP/InsuranceList.aspx'},
            { title:'Medical Insurance', link:'CP/DependentMedicalInsurance.aspx'},
            { title:'Accidental Insurance', link:'CP/AccidentalInsurance.aspx'},
            { title:'Medical Tax Credit', link:'CP/ManageMedicalTaxCredit.aspx'},
        ]
    },
]

function CommonTasks(props) {
    return (
        <div>
            <CommonModuleLinks baseUrl={props.baseUrl} title="Payroll Common Tasks" localStorageTitle="PCT" modules={modules} />
        </div>
    )
}

export default CommonTasks
