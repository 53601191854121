import React, { Component } from "react";
import { Tabs, Tab } from "components/tabs/index.d";
import ClientProjectFee from "./clientProjectFee";
import TeamAndContribution from "./teamAndContribution";
import Approvals from "./approvals";
import ContractHistory from "./contractHistory";
import CounterParties from "./counterParties";

const tabs = [
  { id: 1, label: "Fees" },
  { id: 2, label: "Team and Contribution" },
  { id: 3, label: "Approvals" },
  { id: 4, label: "Counterparties" },
  { id: 5, label: "Contract History" },
];
export class ClientProjectTabs extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     // // tabs: [
  //     // //   { id: 1, label: "Fees" },
  //     // //   { id: 2, label: "Team and Contribution" },
  //     // //   { id: 3, label: "Approvals" },
  //     // //   { id: 4, label: "Counterparties" },
  //     // //   { id: 5, label: "Contract History" },
  //     // // ],
  //     activeTabId: props.activeTabId || 1,
  //   };
  // }

 

  render() {
    const { activeTabId } = this.props;
    return (
      <Tabs>
        {tabs.map((tab, index) => {
          return (
            <Tab
              key={index}
              label={tab.label}
              name={tab.id}
              id={tab.id}
              isActive={activeTabId === tab.id}
              onClick={() => this.props.handleTabChange(tab.id)}
            >
              {activeTabId == 1 ? (
                <ClientProjectFee {...this.props} />
              ) : activeTabId == 2 ? (
                <TeamAndContribution {...this.props} />
              ) : activeTabId == 3 ? (
                <Approvals {...this.props} />
              ) : activeTabId == 4 ? (
                <CounterParties {...this.props} />
              ) : (
                <ContractHistory {...this.props} />
              )}
            </Tab>
          );
        })}
      </Tabs>
    );
  }
}

export default ClientProjectTabs;
