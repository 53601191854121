import isEmpty from "helpers/isEmpty";
import React, { useState } from "react";

function CustomInformation(props) {
  const { title, data } = props;

  return data && data.length > 0 ? (
    <div className="information-wholeInfo-nav-block">
      <div className="information-wholeInfo-nav-block-title">
        <span>{title}</span>
      </div>

      {data.map((d, i) =>
        isEmpty(d.value) ? null : (
          <div className="information-wholeInfo-nav-block-subtitle" key={i}>
            <div className="information-wholeInfo-nav-block-subtitle-left">
              <span className="fonts-light_medium">{d.label}</span>
            </div>

            <div className="information-wholeInfo-nav-block-subtitle-right">
              <div className="information-wholeInfo-nav-block-subtitle-description">
                <span className="fonts-dark_medium">{d.value}</span>

                {d.light && (
                  <span className="fonts-light_medium">{d.light}</span>
                )}
              </div>
            </div>
          </div>
        )
      )}
    </div>
  ) : null;
}

export default CustomInformation;
