import classnames from "classnames";
import * as React from "react";
import { NavLink } from "react-router-dom";

interface IAppProps {
  data: any;
  match: any;
}

const App: React.FunctionComponent<IAppProps> = (props) => {
  const { data, match } = props;
  console.log("data.type", data, props);
  const tabClass = classnames({
    "toptab-item": true,
    flex: true,
    [data.type]: data.type,
  });
  return (
    <NavLink
      to={{
        pathname: match.path + data.link,
        state: { selectedTabId: data.id },
      }}
      className={tabClass}
      activeClassName="selected"
    >
      <div className="toptab-contain">
        <div className="toptab-content">
          <h2 className="toptab-title">{data.title}</h2>
        </div>
      </div>
      {data.id < 3 && <span className="list-count">{data.count}</span>}
    </NavLink>
  );
};

export default App;
