import {
  GET_JOBPROFILE_KSA_EDUCATION_LIST,
  GET_JOBPROFILE_KSA_CERTIFICATION_LIST,
  GET_JOBPROFILE_KSA_SKILL_TYPE,
  GET_JOBPROFILE_KSA_BY_TYPE,
  GET_JOBPROFILE_KSA_EDUCATION_LEVEL,
  GET_JOBPROFILE_KSA_EDUCATION_TYPE,
  GET_JOBPROFILE_KSA_CERTIFICATION_TYPE,
  GET_JOBPROFILE_KSA_EDUCATION_BY_ID,
  GET_JOBPROFILE_KSA_CERTIFICATION_BY_ID,
  GET_JOBPROFILE_KSA__BY_ID,
} from "actions/types";

const initialState = {
  educationList: null,
  educationById: null,
  educationLevels: [],
  educationTypes: [],
  certificationList: null,
  certificationById: null,
  certificationTypes: [],
  ksaType:1,
  ksaSkillType: [],
  ksaListByType: null,
  ksaDataById: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_JOBPROFILE_KSA_EDUCATION_LIST:
      return {
        ...state,
        educationList: action.payload,
      };
    case GET_JOBPROFILE_KSA_EDUCATION_LEVEL:
      return {
        ...state,
        educationLevels: action.payload,
      };
    case GET_JOBPROFILE_KSA_EDUCATION_TYPE:
      return {
        ...state,
        educationTypes: action.payload,
      };
    case GET_JOBPROFILE_KSA_EDUCATION_BY_ID:
      return {
        ...state,
        educationById: action.payload,
      };

    case GET_JOBPROFILE_KSA_CERTIFICATION_LIST:
      return {
        ...state,
        certificationList: action.payload,
      };
    case GET_JOBPROFILE_KSA_CERTIFICATION_BY_ID:
      return {
        ...state,
        certificationById: action.payload,
      };

    case GET_JOBPROFILE_KSA_CERTIFICATION_TYPE:
      return {
        ...state,
        certificationTypes: action.payload,
      };

    case GET_JOBPROFILE_KSA_SKILL_TYPE:
      return {
        ...state,
        ksaSkillType: action.payload,
      };

    case GET_JOBPROFILE_KSA_BY_TYPE:
      return {
        ...state,
        ksaType:action.ksaType,
        ksaListByType: action.payload,
      };

    case GET_JOBPROFILE_KSA__BY_ID:
      return {
        ...state,
        ksaDataById: action.payload,
      };

    default:
      return state;
  }
}
