import React from 'react'
import {useController} from 'react-hook-form';
import { wholeFormInterface } from 'ts/interfaces/formInterface';
import Form from '../form';

function FormItem(props:wholeFormInterface) {
    const { name, formName, label, multiple,error, rules, control, defaultValue = formName === 'switch' ? false : '', ...inputProps } = props;
    const { field } = useController({ name, control, rules, defaultValue });
    const isError = Boolean(error);
    const extraProps:any = {};
    if(formName === 'switch'){
        extraProps.checked = field.value 
    }
    if(formName === 'checkBoxList'){
        extraProps.selectedItems = field.value 
    }
    if(formName === 'radioList'){
        extraProps.checked = field.value 
    }
    if(formName === 'checkBoxGroup'){
        extraProps.checkedGroupValue = field.value 
    }
    const onChange = (name,value) =>{
        if(formName === "checkgroup"){
            field.onChange(name.target.checked)
            return;
        }
        if(formName === "chipsinput"){
            field.onChange(multiple?value:value?.value)
            return;
        }
        if(formName === "switch"){
            field.onChange(value === "" ? false : value)
            return;
        }
        field.onChange(value)
    }
    return (
        <>
            <Form 
                onChange={onChange}
                error={error}
                value={field.value}
                onBlur={field.onBlur}
                {...props}
                {...extraProps}
            />
        </>
    )
}

FormItem.defaultProps = {
    formName: "textinput",
    name: "",
    label: "",
    autoFocus: false,
    alignment: "vertical",
    validators: [],
  };

export default FormItem
