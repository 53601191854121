import axios from 'axios'
import isEmpty from 'isEmpty'

import {
    GET_INCOME_OR_DEDCUTIONS,
    SEARCHED_SALARY_ADJUSTMENT,
    CLEAR_ERRORS,
    EDIT_SALARY_ADJUST,
    INCOME_OR_DEDCUTIONS_LOADING,
    GET_ERRORS,
    DELETE_SALARY_ADJUSTMENT,
    SALARY_ADJUSTMENT_LOADING,
    ADD_MESSAGE
  } from '../../../actions/types';
import {toastMessage} from '../../../actions/validateAction'


  // Get Filtered Leave
  export const searchSalaryAdjustment= (data) => dispatch => {
    dispatch(setSalaryLoading());
    axios
      .post(`/v1/payroll/salary-adjustment/search`,data)
      .then(res =>{
        toastMessage(dispatch,res);

        dispatch({
          type: SEARCHED_SALARY_ADJUSTMENT,
          payload: res.data.Data,
          page:{index:res.data.PageIndex,total:res.data.RecordsTotal}
        });
      }
        
      )
      .catch(err =>
        dispatch({
          type: SEARCHED_SALARY_ADJUSTMENT,
          payload: null
        })
      );
  };

  // Get Branch
export const getIncomeOrDeduction = id => dispatch => {
    dispatch(setIncomeOrDeductionsLoading());
    axios
      .get(`/v1/payroll/income-or-deductions?payrollPeriodId=${id}`)
      .then(res =>{
        toastMessage(dispatch,res);

        dispatch({
          type: GET_INCOME_OR_DEDCUTIONS,
          payload: res.data
        });
      }
        
      )
      .catch(err =>
        dispatch({
          type: GET_INCOME_OR_DEDCUTIONS,
          payload: null
        })
      );
  };

    // Get Branch
export const updateSalaryAdjust = data => dispatch => {
  // dispatch(setSalaryLoading());
  axios
    .post(`/v1/payroll/salary-adjustment`,data)
    .then(res =>{
      // console.log(res)
      toastMessage(dispatch,res);
      dispatch({
        type: EDIT_SALARY_ADJUST,
        payload: res.data.Data[0],
        id:res.data.Data[0].EmployeeId
      })

    }
      
    )
    .catch(err =>
      dispatch({
        type: EDIT_SALARY_ADJUST,
        payload: null
      })
    );
};

  //   Delete Salary Adjustment
  export const deleteSalaryAdjustment = data => dispatch => {
  
    axios
      .delete(`/v1/payroll/salary-adjustment`, { data })
      .then(res =>{
        toastMessage(dispatch,res);
        dispatch({
          // type: DELETE_SALARY_ADJUSTMENT,
          type: EDIT_SALARY_ADJUST,
          payload: res.data.Data[0],
          id:res.data.Data[0].EmployeeId
        });
      }
      
      )
      .catch(err =>
        dispatch({
          type: EDIT_SALARY_ADJUST,
          payload: null
        })
      );
  };

  // Set loading state
export const setSalaryLoading = () => {
    return {
      type: SALARY_ADJUSTMENT_LOADING
    };
  };
 
export const setIncomeOrDeductionsLoading=()=>{
  return {
    type: INCOME_OR_DEDCUTIONS_LOADING
  };
}

  // Clear errors
  export const clearErrors = () => {
    return {
      type: CLEAR_ERRORS
    };
  };
  