import {
  GET_DISTRIBUTED_INCOME_BY_AMOUNT,
  SAVE_SALARY_TEMPLATE,
  SALARY_TEMPLATE_LIST,
  SALARY_TEMPLATE_DETAILS,
  DELETE_SAVE_SALARY_TEMPLATE
} from "actions/types";
import axios from "axios";
import isEmpty from "isEmpty";
import { toastMessage } from "actions/validateAction";
import RouteEnum from 'constants/routeEnum'
import { getErrorResponse } from "helpers/getApiResponse";

export const GetDistributedSalary = (data) => async (dispatch) => {
    await axios
      .post(`/v1/onBoarding/distributeSalary?isGrossPay=${data.isGrossPay}&payAmount=${data.payAmount}`, data.salaryArray)
      .then((res) => {
        if (res.data.Status) {
          let data = res.data.Data;
          dispatch({
            type: GET_DISTRIBUTED_INCOME_BY_AMOUNT,
            payload: data,
          });
        }
      })
      .catch((err) => {
        const res = {
          data: {
            MessageType: "Danger",
            Message: err.message
              ? err.message
              : "Failed to get Salary Distribution",
          },
        };
        toastMessage(dispatch, res);
      });
};

export const AddSalaryTemplate = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_SALARY_TEMPLATE,
    payload: false,
  });
    await axios
      .post(`/v1/onBoarding/templates`, data)
      .then((res) => {
        if (res.data.Status) {
          let data = res.data.Data;
          dispatch({
            type: SAVE_SALARY_TEMPLATE,
            payload: true,
          });
          toastMessage(dispatch, res);
        }
      })
      .catch((err) => {
        const res = {
          data: {
            MessageType: "Danger",
            Message: err.message
              ? err.message
              : "Failed to save salary Template.",
          },
        };
        toastMessage(dispatch, res);
      });
};

export const GetAllSalaryTemplate = () => async (dispatch) => {
    await axios
      .get(`/v1/onBoarding/templates`)
      .then((res) => {
        if (res.data.Status) {
          let data = res.data.Data;
          let formattedData = []
          data.map((d) => {
            formattedData.push({
              label: d.TemplateName, value: d.TemplateId,
              level: d.Level, GrossPay: d.GrossPay
            });
          });
          dispatch({
            type: SALARY_TEMPLATE_LIST,
            payload: formattedData,
          });
        }
      })
      .catch((err) => {
        const res = {
          data: {
            MessageType: "Danger",
            Message: err.message
              ? err.message
              : "Failed to save salary Template.",
          },
        };
        toastMessage(dispatch, res);
      });
};

export const GetSalaryTemplateDetails = (templateId) => async (dispatch) => {
    await axios
      .get(`/v1/onBoarding/templates/${templateId}`)
      .then((res) => {
        if (res.data.Status) {
          let data = res.data.Data;
          dispatch({
            type: SALARY_TEMPLATE_DETAILS,
            payload: data,
          });
        }
      })
      .catch((err) => {
        const res = {
          data: {
            MessageType: "Danger",
            Message: err.message
              ? err.message
              : "Failed to save salary Template.",
          },
        };
        toastMessage(dispatch, res);
      });
};

export const UpdateSalaryTemplate = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_SALARY_TEMPLATE,
    payload: false,
  });
    await axios
      .put(`/v1/onBoarding/templates`, data)
      .then((res) => {
        if (res.data.Status) {
          let data = res.data.Data;
          dispatch({
            type: SAVE_SALARY_TEMPLATE,
            payload: true,
          });
          toastMessage(dispatch, res);
        }
      })
      .catch((err) => {
        const res = {
          data: {
            MessageType: "Danger",
            Message: err.message
              ? err.message
              : "Failed to save salary Template.",
          },
        };
        toastMessage(dispatch, res);
      });
};

export const DeleteSalaryTemplate = (templateId) => async (dispatch) => {
    dispatch({
      type: DELETE_SAVE_SALARY_TEMPLATE,
      payload: false,
    });
    await axios
      .delete(`/v1/onBoarding/templates/${templateId}`)
      .then((res) => {
        if (res.data.Status) {
          let data = res.data.Data;
          dispatch({
            type: DELETE_SAVE_SALARY_TEMPLATE,
            payload: true,
          });
          toastMessage(dispatch, res);
        }
      })
      .catch((err) => {
        const res = {
          data: {
            MessageType: "Danger",
            Message: err.message
              ? err.message
              : "Failed to delte salary Template.",
          },
        };
        toastMessage(dispatch, res);
      });
};

