import {
  CHECK_CLOCK_IN_OUT_AUTHORITY,
  GET_ATTENDANCE_BY_DATE,
  GET_ATTENDANCE_REPORT,
  SAVE_ATTENDANCE_TIME,
  SAVE_ATTENDANCE_NOTE,
} from "actions/types";
import { Date_Format } from "helpers/dateFormat";
import isEmpty from "helpers/isEmpty";

const initialState = {
  attendanceAuthority: null,
  attendanceReport: [],
  attendanceByDate: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHECK_CLOCK_IN_OUT_AUTHORITY:
      return {
        ...state,
        attendanceAuthority: action.payload,
      };
    case GET_ATTENDANCE_BY_DATE: {
      return {
        ...state,
        attendanceByDate: action.payload,
      };
    }
    case GET_ATTENDANCE_REPORT:
      return {
        ...state,
        attendanceReport: action.payload,
      };
    // case SAVE_ATTENDANCE_TIME: {
    //   let attendanceByDate = [...state.attendanceByDate];

    //   let ta = attendanceByDate.find(
    //     (f) => Date_Format(f.DateEng) == Date_Format(new Date())
    //   );

    //   attendanceByDate = attendanceByDate.filter(
    //     (f) => Date_Format(f.DateEng) !== Date_Format(new Date())
    //   );

    //   let todayAttendance = Object.assign({}, ta);
    //   if (isEmpty(todayAttendance)) {
    //     todayAttendance["DateEng"] = new Date();
    //   }

    //   if (action.isCheckIn) {
    //     todayAttendance["InTime"] = action.clockTime;
    //   } else {
    //     todayAttendance["OutTime"] = action.clockTime;
    //   }
    //   attendanceByDate.push(todayAttendance);

    //   return {
    //     ...state,
    //     attendanceByDate: attendanceByDate,
    //   };
    // }

    case SAVE_ATTENDANCE_NOTE: {
      let attendanceByDate = [...state.attendanceByDate];
      let ta = attendanceByDate.find(
        (f) => Date_Format(f.DateEng) == Date_Format(new Date())
      );
      attendanceByDate = attendanceByDate.filter(
        (f) => Date_Format(f.DateEng) !== Date_Format(new Date())
      );

      let todayAttendance = Object.assign({}, ta);
      if (isEmpty(todayAttendance)) {
        todayAttendance["DateEng"] = new Date();
      }

      if (action.isCheckIn) {
        todayAttendance["InNote"] = action.clockNote;
      } else {
        todayAttendance["OutNote"] = action.clockNote;
      }
      attendanceByDate.push(todayAttendance);

      return {
        ...state,
        attendanceByDate: attendanceByDate,
      };
    }

    default:
      return state;
  }
}
