import * as React from "react";
import Tab from "./tab";
import { connect } from "react-redux";

interface IAppProps {
  tabContent: any;
  match: any;
}

const App: React.FunctionComponent<IAppProps> = (props) => {
  const { tabContent, match } = props;
  return (
    <div className="common-header-tab">
      <div className="toptab">
        {tabContent.map((tab, key) => {
          return <Tab key={key} data={tab} match={props.match} />;
        })}
        <div className="unallocated-space"></div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  //travelListCount: state.travelInformationReducer.travelListCount,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(App);
