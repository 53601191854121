import React from "react";
import { FaEdit } from "react-icons/fa";
import Button from "components/button";

function DetailCard(props) {
    let fatherName = null;
    let motherName = null;
    let grandFatherName = null;
    let maritalStatus = null;
    let spouseName = null;
    let childList = [];

    if (props.detail) {
        props.detail.forEach((f) => {
            if (f.Relation === 'Father'){
                fatherName = f.Name
            } else if (f.Relation == 'Mother') {
                motherName = f.Name
            } else if (f.Relation == 'GrandFather') {
                grandFatherName = f.Name
                
            } else if (f.Relation == 'Spouse') {
                maritalStatus = 'Married';
                spouseName = f.Name
                
            } else {
                childList.push({
                    Name: f.Name,
                    type: f.Relation
                })
            }
        });
    }
    const changeBgColor = () => {
            if (props.isEditView)
                return 'health-detailcard health-detailcard-withBg';
            else
                return 'health-detailcard';
    }

    return (
    <div className={changeBgColor()}>
        <div className="profile-block__body">
            <div className="placement-details">
                <div className="placement-details__block">
                    <div className="placement-details__table">
                    <div className="placement-details__row">
                        <div className="placement-details__col placement-title">
                            Father's Name
                        </div>
                        <div className="placement-details__col placement-val">
                            {fatherName || '-'}
                        </div>
                    </div>
                    <div className="placement-details__row">
                        <div className="placement-details__col placement-title">
                            Mother's Name
                        </div>
                        <div className="placement-details__col placement-val">
                            {motherName || '-'}
                        </div>
                    </div>
                    <div className="placement-details__row">
                        <div className="placement-details__col placement-title">
                            Grandfather's Name
                        </div>
                        <div className="placement-details__col placement-val">
                        {grandFatherName || '-'}
                        </div>
                    </div>
                    <div className="placement-details__row">
                        <div className="placement-details__col placement-title">
                            Marital Status
                        </div>
                        <div className="placement-details__col placement-val">
                            {maritalStatus || 'Single'}
                        </div>
                    </div>
                    </div>
                </div>

                <div className="placement-details__block">
                    <div className="placement-details__table">
                    <div className="placement-details__row">
                        <div className="placement-details__col placement-title">
                            Spouse
                        </div>
                        <div className="placement-details__col placement-val">
                            {spouseName || '-'}
                        </div>
                    </div>
                    <div className="placement-details__row">
                        <div className="placement-details__col placement-title">
                            Children
                        </div>
                        <div className="placement-details__col placement-val">
                            {childList.map( child =>
                                <><span>{child.Name} ({child.type})</span><br></br></>
                            )}
                        </div>
                    </div>
                </div>
                </div>
                <div className="healthCard-detail__secondary healthCard-detail__toolbars">
                    {props.isEditView ?
                    <Button
                    onClick={() => props.toggleEditView(false)}
                    bg="white"
                    >
                        Cancel
                    </Button> :
                        <span onClick={() => props.toggleEditView(true)} className="healthCard-detail__edit">
                            <FaEdit />
                        </span>
                    }
                </div>
            </div>
        
        </div>
    </div>
    );
}

export default DetailCard;